import { Box } from '@mui/material';
import { useMemo } from 'react';
import { ADULT_TOOTH_LIST, CHILD_TOOTH_LIST, DENTAL_GROUP, DENTAL_GROUP_DETAIL } from '../../common/constants';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useDiagnosisDialogSelector } from './state';
import { useFetchDiagnosisCodeSystem } from './fetcher';
import { useDentalDetailStateSelector } from '../../state';

const DiagnosisSetup = () => {
  const { intl } = useGeneralHook();
  const onAddDiagnosis = useDiagnosisDialogSelector.use.addDiagnosis();
  const setDiagnosisSetting = useDiagnosisDialogSelector.use.setDiagnosisSetting();
  const examTarget = useDentalDetailStateSelector.use.examTarget();
  const positionOptions = useMemo(
    () =>
      Object.values(examTarget === 'ADULT' ? ADULT_TOOTH_LIST : CHILD_TOOTH_LIST).map((position) => ({
        value: position,
        label: intl.formatMessage(
          { id: 'dental.dentalDetail.statusAndDiagnosis.dentalPosition.value' },
          { position: position },
        ),
      })),
    [intl, examTarget],
  );
  const { data: diagnosisCodeSystems } = useFetchDiagnosisCodeSystem();
  const dentalGroupOptions = useMemo(() => Object.values(DENTAL_GROUP), []);
  const allDentalGroupDetailOptions = useMemo(() => Object.values(DENTAL_GROUP_DETAIL), []);
  const onReloadData = (formData) =>
    setDiagnosisSetting({
      dentalGroup: formData?.dentalGroup,
      dentalGroupDetail: formData?.dentalGroupDetail,
      position: formData?.position,
    });

  return (
    <Box sx={{ padding: '8px 12px 12px 8px', border: '1px solid #CFD8DC', borderRadius: '4px' }}>
      <SchemaElement
        fieldName="diagnosisSetting"
        propsElement={{
          type: 'section',
          schema: {
            fields: ({ valuesField, methods }) => {
              const dentalGroupDetailOptions = allDentalGroupDetailOptions?.filter(
                (option) => option.target.value === valuesField?.dentalGroup,
              );
              return {
                position: {
                  type: 'select',
                  options: positionOptions,
                  label: intl.formatMessage({
                    id: 'dental.dentalDetail.statusAndDiagnosis.input.position.label',
                  }),
                  propsWrapper: {
                    xs: 3,
                    sx: {
                      '& .MuiInputBase-input.Mui-disabled': {
                        borderRadius: '4px',
                        border: '1px solid #CFD8DC',
                        background: '#ECEFF1',
                      },
                    },
                  },
                  disableClearBtn: true,
                  rawOptions: true,
                  onChange: () => onReloadData(valuesField),
                },
                dentalGroup: {
                  type: 'select',
                  options: dentalGroupOptions,
                  label: intl.formatMessage({
                    id: 'dental.dentalDetail.statusAndDiagnosis.input.target.label',
                  }),
                  propsWrapper: {
                    xs: 3,
                    sx: {
                      '& .MuiInputBase-input.Mui-disabled': {
                        borderRadius: '4px',
                        border: '1px solid #CFD8DC',
                        background: '#ECEFF1',
                      },
                    },
                  },
                  disableClearBtn: true,
                  onChange: () => {
                    methods.setValue('diagnosisSetting.dentalGroupDetail', null);
                    onReloadData(valuesField);
                  },
                },
                dentalGroupDetail: {
                  type: 'select',
                  options: dentalGroupDetailOptions,
                  label: intl.formatMessage({
                    id: 'dental.dentalDetail.statusAndDiagnosis.input.detailTarget.label',
                  }),
                  propsWrapper: {
                    xs: 4,
                  },
                  disableClearBtn: true,
                  disabled: !dentalGroupDetailOptions?.length,
                  onChange: () => onReloadData(valuesField),
                  register: {
                    required: dentalGroupDetailOptions?.length > 0,
                  },
                },
                diagnosticStatus: {
                  type: 'select',
                  options: diagnosisCodeSystems,
                  label: intl.formatMessage({
                    id: 'dental.dentalDetail.statusAndDiagnosis.input.diagnosisStatus.label',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'dental.dentalDetail.statusAndDiagnosis.input.diagnosisStatus.placeholder',
                  }),
                  propsWrapper: { xs: 5 },
                  disableClearBtn: true,
                  rawOptions: true,
                },
                otherDiagnosticStatus: {
                  type: 'text-field',
                  label: intl.formatMessage({
                    id: 'dental.dentalDetail.statusAndDiagnosis.input.otherDiagnosisStatus.label',
                  }),
                  placeholder: intl.formatMessage({
                    id: 'dental.dentalDetail.statusAndDiagnosis.input.otherDiagnosisStatus.placeholder',
                  }),
                  propsWrapper: { xs: 5 },
                  autoFocus: true,
                },
                submit: {
                  type: 'button',
                  fullWidth: true,
                  propsWrapper: { xs: 2, sx: { marginTop: '22px' } },
                  label: intl.formatMessage({
                    id: 'dental.dentalDetail.statusAndDiagnosis.button.addDiagnosis.label',
                  }),
                  disabled: !valuesField?.diagnosticStatus && !valuesField?.otherDiagnosticStatus,
                  onClick: () => {
                    const code = valuesField?.diagnosticStatus;
                    if (valuesField?.diagnosticStatus) {
                      onAddDiagnosis({
                        ...valuesField,
                        display: diagnosisCodeSystems?.find((codeSystem) => codeSystem.value === code)?.label,
                        code,
                      });
                    }
                    if (valuesField?.otherDiagnosticStatus) {
                      onAddDiagnosis({ ...valuesField, display: valuesField?.otherDiagnosticStatus });
                    }

                    methods.setValue('diagnosisSetting.otherDiagnosticStatus', '');
                  },
                },
              };
            },
            ui: [
              {
                id: 'default',
                fields: ['position', 'dentalGroup', 'dentalGroupDetail'],
              },
              {
                id: 'avatar',
                fields: ['diagnosticStatus', 'otherDiagnosticStatus', 'submit'],
              },
            ],
          },
          propsWrapper: {
            sx: { '& .MuiPaper-root': { padding: 0, backgroundColor: 'transparent', boxShadow: 'none' } },
          },
        }}
      />
    </Box>
  );
};

export default DiagnosisSetup;
