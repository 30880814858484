import { Box, Typography } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import { INDICATION_REQUEST_STATUS } from 'modules/common/constants';
import SchemaForm from 'modules/common/SchemaForm';
import IndexListTable from './IndexListTable';
import { IndicationRequest } from 'modules/schema';
import { useFetch } from 'modules/common/hook';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { axiosThunk } from 'modules/common/redux/axios';
import { setLoading } from 'modules/common/redux/commonReducer';
import { isEmpty } from 'lodash';
import NoDataBox from 'modules/common/component/NoDataBox';

interface Props {
  onSubmitSuccess?: () => void;
  indicationRequest?: IndicationRequest;
  onClose?: () => void;
  revalidateIndicationList: () => void;
}

const TestServiceTab: React.FunctionComponent<Props> = ({
  onSubmitSuccess,
  indicationRequest,
  onClose,
  revalidateIndicationList,
}) => {
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const { data: indicationLabList, revalidate } = useFetch(
    API_SERVER.indicationLaboratory.getList(indicationRequest?.id!),
    { enabled: indicationRequest?.id },
  );
  const readonly =
    (indicationRequest?.status === INDICATION_REQUEST_STATUS.FINISHED.value &&
      indicationRequest?.endTime?.isBefore(moment().subtract(1, 'day'))) ||
    indicationRequest?.status === INDICATION_REQUEST_STATUS.CANCELLED.value ||
    isEmpty(indicationLabList?.indicationLaboratoryList);

  // handle finish, save infomation form
  const onSubmit = async (value) => {
    if (indicationLabList?.indicationRequest?.status !== 'FINISHED') {
      const confirm =
        value.status === 'final'
          ? true
          : await confirmDialog.promptConfirmation({
              title: intl.formatMessage({ id: 'confirmCompleteTitle' }),
              content: intl.formatMessage(
                {
                  id: 'confirmComplete',
                },
                { name: value?.indicationName },
              ),
            });
      if (!confirm) {
        confirmDialog.close();
        return;
      }
    }
    try {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url:
            indicationLabList?.indicationRequest?.status === 'FINISHED'
              ? API_SERVER.indicationRequest.updateIndicationLaboratories()
              : API_SERVER.indicationRequest.finishIndicationLaboratories(),
          method: 'put',
          data: {
            data: value.items?.map((i) => {
              return {
                evaluate: i.evaluate,
                id: i.id,
                indicationRequestId: i.indicationRequestId,
                isChoose: i.isChoose,
                result: i.listSelect
                  ? i.laboratoryReferenceDetailObject.laboratorySelectionList.find(
                      (itemResult) => itemResult.id === i.listSelect,
                    ).name
                  : i.result,
              };
            }),
          },
        }),
      );
      dispatch(setLoading(false));
      revalidate();
      onSubmitSuccess && onSubmitSuccess();
      openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      onClose && onClose();
      revalidateIndicationList && revalidateIndicationList();
    } catch (e) {
      openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
      confirmDialog.close();
    }
  };

  if (!indicationLabList) {
    return <LoadingIcon />;
  }

  return (
    <>
      <Box display="flex">
        <Box minWidth={350}>
          <Typography variant="subtitle1" color="textSecondary">
            <FormattedMessage id="serviceName" />
          </Typography>
          <Typography variant="body1">{indicationRequest?.serviceName}</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1" color="textSecondary">
            <FormattedMessage id="note" />
          </Typography>
          <Typography variant="subtitle1">{indicationRequest?.note}</Typography>
        </Box>
      </Box>
      {!isEmpty(indicationLabList?.indicationLaboratoryList) ? (
        <SchemaForm
          formData={{ items: indicationLabList?.indicationLaboratoryList }}
          formProps={{ style: { marginTop: 16 } }}
          onSubmit={onSubmit}
          hideSubmitButton
          schema={{
            fields: ({ formProps: { intl } }) => {
              return {
                items: {
                  unregister: true,
                  type: () => <IndexListTable readonly={readonly} />,
                },
                submit: {
                  hidden: readonly,
                  type: 'submitButton',
                  label: intl.formatMessage({
                    id: indicationRequest?.status === INDICATION_REQUEST_STATUS.FINISHED.value ? 'save' : 'complete',
                  }),
                  propsWrapper: { justifyContent: 'flex-end', display: 'flex' },
                },
              };
            },
          }}
        />
      ) : (
        <NoDataBox />
      )}
    </>
  );
};

export default TestServiceTab;
