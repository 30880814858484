import axios, { AxiosRequestConfig } from 'axios';
import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { AppState } from '../../../redux/reducer';
import { logoutThunk } from '../../authen/redux/authenReducer';
import { some, SS_TOKEN } from '../constants';
import { setNetworkError } from './commonReducer';

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
  },
);

const request = axios.create({
  headers: {
    'accept-language': 'vi',
    'Content-Type': 'application/json',
  },
  method: 'get',
});

const removeBlankString = (params) => {
  for (const key of Object.keys(params)) {
    if (params[key] === '') {
      delete params[key];
    }
  }
};

export function axiosThunk(
  options: AxiosRequestConfig<any>,
  noneNull?: boolean,
): ThunkAction<Promise<some>, AppState, null, Action<string>> {
  return async (dispatch, getState) => {
    while (true) {
      const token = sessionStorage.getItem(SS_TOKEN) || '';
      try {
        const data = options.data?.constructor.name === 'Array' ? [...options.data] : { ...options.data };
        if (noneNull) {
          removeBlankString(data);
        }
        const json = await request({
          ...options,
          data,
          headers: {
            Authorization: `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Content-Type': 'application/json',
            'Accept-Language': getState().intl.locale,
            Timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            ...(options.headers as any),
          },
        });
        if (json.status === 200) {
          return json;
        }
      } catch (e: any) {
        if (e.status === 401) {
          dispatch(logoutThunk());
        }
        if (e.code !== 'ERR_NETWORK') {
          throw e;
        }
      }

      let hasInternet = true;
      try {
        await fetch('https://www.google.com', { mode: 'no-cors' });
      } catch (_) {
        hasInternet = false;
      }
      dispatch(setNetworkError(hasInternet ? 'serverProblem' : 'unstableNetwork', true));
      do {
        await new Promise((resolve) => setTimeout(resolve, 250));
        if (!getState().common.openErrorDialog) {
          break;
        }
      } while (getState().common.networkErrorMsg);
      if (!getState().common.openErrorDialog) {
        break;
      }
    }
    return {};
  };
}
