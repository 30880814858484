import { z } from 'zod';
import { SpecializedDepartmentSchema } from './SpecializedDepartment';

export const MedicalSuppliesSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    name: z.string(),
    commercialName: z.string(),
    unit: z.string(),
    packageMethod: z.string(),
    groupCode: z.string(),
    specializedDepartmentId: z.number(),
    winningBidDecision: z.string(),
    paymentPercent: z.number(),
    purchasePrice: z.number(),
    supplyPrice: z.number(),
    maximumPrice: z.number(),
    vat: z.number(),
    currency: z.string(),
    systemCategoryId: z.number(),
    status: z.string(),
    specializedDepartment: SpecializedDepartmentSchema,
  })
  .partial();

export type MedicalSupplies = z.infer<typeof MedicalSuppliesSchema>;
