import { Close, Delete } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, Tooltip, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import LoadingIcon from '../../../../common/component/LoadingIcon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { FormattedMessage } from 'react-intl';
import { PRIMARY } from '../../../../../colors';
import ArticleIcon from '@mui/icons-material/Article';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { AttachedDocument, StorageResource } from 'modules/schema';
import { API_SERVER } from 'modules/common/api';
import axios from 'axios';
import { SS_TOKEN } from 'modules/common/constants';
import { setLoading } from 'modules/common/redux/commonReducer';

interface Props {
  data: StorageResource;
  onDeleteFile: (docRef: AttachedDocument) => void;
  readOnly?: boolean;
  enableDelete?: boolean;
}
const DocumentBox = (props: Props) => {
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const { dispatch, openSnackbar } = useGeneralHook();
  const [base64File, setBase64File] = useState<string | ''>('');

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }, []);

  const getLink = React.useCallback(
    async (value) => {
      try {
        dispatch(setLoading(true));
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        const response = await axios.get(API_SERVER.storageResource.doawloadFile(value?.id), {
          headers: { Authorization: `Bearer ${token}` },
          responseType: 'blob',
        });

        if (response.status === 200) {
          const contentType = response.headers['content-type'] || 'application/octet-stream';
          const blob = new Blob([response.data], { type: contentType });
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = value.fileName;
          link.click();
        } else {
          throw new Error('Unexpected response status: ' + response.status);
        }
      } catch (error) {
        console.error('Download failed:', error);
        openSnackbar({
          message: <FormattedMessage id="downloadFail" />,
          type: 400,
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, openSnackbar],
  );

  const isImageType =
    props.data?.fileName?.endsWith('.jpg') ||
    props.data?.fileName?.endsWith('.jpeg') ||
    props.data?.fileName?.endsWith('.png');

  const isDocType = props.data?.fileName?.endsWith('.doc') || props.data?.fileName?.endsWith('.docx');
  const isUnknownType = props.data?.fileName?.endsWith('unknown');

  function renderIconFile() {
    switch (props?.data?.fileName?.split('.')?.pop()?.toLowerCase()) {
      case 'pdf':
        return <PictureAsPdfIcon color="primary" />;
      case 'doc':
      case 'docx':
        return <ArticleIcon color="primary" />;
      case 'jpeg':
      case 'jpg':
      case 'png':
        return <ImageIcon color="primary" />;
      default:
        return <AttachFileIcon color="primary" />;
    }
  }

  const getDataImageView = React.useCallback(
    async (value) => {
      try {
        dispatch(setLoading(true));
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        const response = await axios.get(API_SERVER.s3Resource.download(value?.id), {
          headers: { 'Content-type': 'application/json', Authorization: `Bearer ${token}` },
          responseType: 'arraybuffer',
        });
        const uint8Array = new Uint8Array(response.data);
        const reader = new FileReader();
        if (props.data?.fileName?.endsWith('.jpg') || props.data?.fileName?.endsWith('.jpeg')) {
          const blob = new Blob([uint8Array], { type: 'image/jpeg' });
          reader.readAsDataURL(blob);
        } else if (props.data?.fileName?.endsWith('.pdf')) {
          const blob = new Blob([uint8Array], { type: 'application/pdf' });
          reader.readAsDataURL(blob);
        } else if (props.data?.fileName?.endsWith('.png')) {
          const blob = new Blob([uint8Array], { type: 'image/png' });
          reader.readAsDataURL(blob);
        } else if (props.data?.fileName?.endsWith('.doc') || props.data?.fileName?.endsWith('.docx')) {
          const blob = new Blob([uint8Array], { type: 'application/msword' });
          reader.readAsDataURL(blob);
        }
        reader.onload = () => {
          const base64String = reader?.result as string;
          setBase64File(base64String);
        };
      } catch (e: any) {
        openSnackbar({
          message: <FormattedMessage id="downloadFail" />,
          type: 400,
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, props.data?.fileName, openSnackbar],
  );

  return (
    <>
      <Box
        display="flex"
        borderBottom={1}
        borderColor="divider"
        alignItems="center"
        sx={{
          '&:hover': {
            bgcolor: PRIMARY[50],
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          style={{ cursor: isDocType || isUnknownType ? 'unset' : 'pointer' }}
          onClick={() => {
            // if (
            //   props.data?.fileName?.endsWith('.jpg') ||
            //   props.data?.fileName?.endsWith('.jpeg') ||
            //   props.data?.fileName?.endsWith('.pdf') ||
            //   props.data?.fileName?.endsWith('.png')
            // ) {
            setOpen(true);
            getDataImageView(props?.data);
            // }
          }}
        >
          {renderIconFile()}
          <Tooltip title={props?.data?.fileName || ''} placement="top">
            <Typography
              variant="body1"
              noWrap
              style={{ flex: 1, marginLeft: 8, width: '100px' }}
              fontWeight={500}
              fontSize={'14px'}
            >
              {props.data.fileName}
            </Typography>
          </Tooltip>
        </Box>

        <IconButtonTitle
          title="download"
          size="small"
          onClick={() => {
            getLink(props.data);
          }}
        >
          <DownloadIcon />
        </IconButtonTitle>
        {props.enableDelete && (
          <IconButtonTitle title="delete" size="small" onClick={() => props.onDeleteFile(props.data)}>
            <Delete />
          </IconButtonTitle>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setBase64File('');
        }}
        maxWidth="xl"
        PaperProps={{
          style: { overflow: 'auto' },
        }}
      >
        <IconButton
          style={{ position: 'absolute', top: 8, right: 8, zIndex: 100, background: 'white' }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
        {props.data?.fileName?.endsWith('.pdf') ? (
          <Box position="relative">
            <Box padding={2} paddingBottom={0}>
              <Document
                loading={<LoadingIcon style={{ width: 500, height: 500 }} />}
                file={base64File}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(e) => console.log(e)}
                options={{
                  cMapUrl: 'cmaps/',
                  cMapPacked: true,
                }}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                bgcolor: 'background.paper',
                position: 'sticky',
                bottom: 0,
                paddingX: 2,
                paddingY: 1,
              }}
            >
              <Typography variant="body1">
                {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
              </Typography>
              <Button
                type="button"
                disabled={pageNumber <= 1}
                onClick={() => {
                  setPageNumber((prevPageNumber) => prevPageNumber - 1);
                }}
              >
                <ArrowBackIcon />
              </Button>
              <Button
                type="button"
                style={{ marginLeft: 12 }}
                disabled={pageNumber >= numPages}
                onClick={() => {
                  setPageNumber((prevPageNumber) => prevPageNumber + 1);
                }}
              >
                <ArrowForwardIcon />
              </Button>
            </Box>
          </Box>
        ) : isImageType ? (
          <img
            src={base64File}
            alt={props?.data?.fileName}
            style={{ objectFit: 'contain', height: '100%', maxHeight: '100%' }}
          />
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};
export default React.memo(DocumentBox);
