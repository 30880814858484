import { z } from 'zod';
import { IndicationRequestSchema } from './IndicationRequest';

export const TherapyMedicationSchema = z
  .object({
    id: z.number(),
    therapySessionId: z.number(),
    medicationId: z.number(),
    medicationName: z.string(),
    medicationIngredient: z.string(),
    medicationUnit: z.string(),
    quantity: z.number(),
    isFree: z.boolean(),
    indicationRequest: IndicationRequestSchema,
    intendedRoute: z.string(),
    note: z.string(),
    indicationRequestId: z.number(),
    isPaid: z.boolean(),
  })
  .partial();

export type TherapyMedication = z.infer<typeof TherapyMedicationSchema>;
