import { Box, Paper } from '@mui/material';
import React, { useCallback } from 'react';
import { UseFormReturn } from 'react-hook-form';
import { IDENTITY_API_SERVER } from '../../common/api';
import { some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { setLoading } from '../../common/redux/commonReducer';
import { fetchThunk } from '../../common/redux/thunk';
import ChangePasswordBox from '../component/changePassword/ChangePasswordBox';

interface Props {}

const ChangePasswordPage = (props: Props) => {
  const { dispatch, openSnackbar, intl } = useGeneralHook();

  const onSubmit = useCallback(
    async (formVal: some, methods: UseFormReturn) => {
      try {
        dispatch(setLoading(true));
        await dispatch(fetchThunk(IDENTITY_API_SERVER.user.userChangePassword, 'put', formVal));
        openSnackbar({ message: intl.formatMessage({ id: 'changePassword.success' }) });
      } catch (e: any) {
        if (e?.errors) {
          e?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'changePassword.fail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, openSnackbar],
  );

  return (
    <Paper variant="outlined" style={{ flex: 1 }}>
      <Box padding={3} paddingY={3} width={300}>
        <ChangePasswordBox onSubmit={onSubmit} />
      </Box>
    </Paper>
  );
};
export default ChangePasswordPage;
