import makeStyles from '@mui/styles/makeStyles';
import { PropsArrayElement } from 'modules/common/SchemaForm/element/array-element/ArrayElement';
import { memo, useState } from 'react';
import { some } from 'modules/common/constants';
import MediCard from 'modules/common/component/MediCard';
import { alpha, Box, ButtonBase, Grid, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import { mergeFieldName } from 'modules/common/SchemaForm/utils';
import NoDataBox from 'modules/common/component/NoDataBox';
import ImagePreview from 'modules/common/component/ImagePreview';
import ImageZoomIn from 'modules/common/component/ImageZoomIn';

const useStyles = makeStyles(() => ({
  image: {
    '&:hover': {
      '& .action-area': {
        opacity: 1,
      },
    },
    '& .action-area': {
      opacity: 0,
      transition: '0.3s all',
    },
  },
}));

interface Props extends PropsArrayElement {}

const ImageBox = (props: Props) => {
  const classes = useStyles();
  const { methodsArray, name } = props;
  const { fields } = methodsArray;
  const [open, setOpen] = useState<undefined | number>(undefined);
  const readOnly = true;
  return (
    <>
      <MediCard title={'subclinical.image'}>
        <Box padding={2}>
          <Grid container spacing={2}>
            {fields?.length > 0
              ? fields?.map((item: some, index: number) => {
                  return (
                    <Grid item key={item.fileId} xs={2}>
                      <Box position="relative" className={classes.image}>
                        <ButtonBase
                          style={{ background: grey[300], height: 180, width: '100%' }}
                          onClick={() => setOpen(index)}
                        >
                          <ImagePreview
                            alt=""
                            imageId={item.fileId}
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </ButtonBase>
                        <Box
                          className="action-area"
                          position="absolute"
                          bottom={0}
                          right={0}
                          left={0}
                          top={0}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box display="flex">
                            <IconButton
                              size="small"
                              style={{ background: alpha(grey[100], 0.8) }}
                              onClick={() => setOpen(index)}
                            >
                              <ZoomInIcon color="inherit" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                      <SchemaElement
                        fieldName={mergeFieldName({ name: 'comment', parent: name, index })}
                        propsElement={{
                          type: 'text-field',
                          variant: 'standard',
                          style: { marginTop: 4 },
                          inputProps: { style: { textAlign: 'center' } },
                          readOnly: readOnly,
                          noHelperText: true,
                          InputProps: { disableUnderline: readOnly },
                        }}
                      />
                    </Grid>
                  );
                })
              : readOnly && (
                  <Box display={'flex'} justifyContent="center" width="100%">
                    <NoDataBox />
                  </Box>
                )}
          </Grid>
        </Box>
      </MediCard>
      <ImageZoomIn
        open={open !== undefined}
        onClose={() => setOpen(undefined)}
        images={fields.map((item: some) => ({
          imageId: item.fileId,
        }))}
        index={open}
      />
    </>
  );
};

export default memo(ImageBox);
