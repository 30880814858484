import { BE_DATE_FORMAT, some } from '../../../common/constants';

import { Button, Paper } from '@mui/material';
import {
  Appointments,
  AppointmentTooltip,
  DateNavigator,
  DayView,
  GroupingPanel,
  Resources,
  Scheduler,
  TodayButton,
  Toolbar,
} from '@devexpress/dx-react-scheduler-material-ui';
import { AppointmentModel, GroupingState, IntegratedGrouping, ViewState } from '@devexpress/dx-react-scheduler';
import React, { useEffect, useMemo, useRef } from 'react';
import Stack from '@mui/material/Stack';
import SchedulerNavigationHeader from './scheduler/SchedulerNavigationHeader';
import { NUMBER_PATIENT_COLUMN_ID, TIME_DURATION, UNKNOWN_DOCTOR_ID } from './constants';
import { handleNumberOfPatientInTimeRange, scrollToAppointment } from './utils';
import AppointmentsContainer from './scheduler/AppointmentsContainer';
import moment from 'moment';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import AppointmentContentComponent from './scheduler/AppointmentContentComponent';
import AppointmentHeaderTooltip from './scheduler/AppointmentHeaderTooltip';
import GroupPanelCell from './scheduler/GroupPanelCell';
import AppointmentInfoBox from './AppointmentInfoBox';
import TimeTableCellComponent from './scheduler/TimeTableCellComponent';
import { waitForElementToExist } from '../../../common/utils';
import { Appointment, UserEmployeeRole } from 'modules/schema';
import { Option } from 'modules/common/type';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';

interface Props {
  picOtions?: (Option & UserEmployeeRole)[];
  appointments?: Appointment[];
  onUpdate: (value: Appointment) => void;
  onDelete: (value: Appointment) => void;
  setParams: (value: some) => void;
  params: some;
  selectedAppointment: Pick<AppointmentModel, 'id'> | null;
  setSelectedAppointment: (data: Pick<AppointmentModel, 'id'> | null) => void;
}

const TableCalendarDay = (props: Props) => {
  const { intl } = useGeneralHook();
  const users = useSelector((state: AppState) => state.common.users);

  const {
    picOtions = [],
    appointments = [],
    onUpdate,
    onDelete,
    setParams,
    params,
    selectedAppointment,
    setSelectedAppointment,
  } = props;
  const scrollInfoRef = useRef({ isDown: false, startX: 0, scrollLeft: 0 });
  useEffect(() => {
    waitForElementToExist('div.MainLayout-container').then((element) => {
      element.addEventListener('mousedown', (event) => {
        scrollInfoRef.current.isDown = true;
        scrollInfoRef.current.startX = event.pageX - element.offsetLeft;
        scrollInfoRef.current.scrollLeft = element.scrollLeft;
      });
      element.addEventListener('mouseleave', (event) => {
        scrollInfoRef.current.isDown = false;
      });
      element.addEventListener('mouseup', (event) => {
        scrollInfoRef.current.isDown = false;
      });
      element.addEventListener('mousemove', (event) => {
        if (!scrollInfoRef.current.isDown) return;
        event.preventDefault();
        const x = event.pageX - element.offsetLeft;
        const walk = x - scrollInfoRef.current.startX;
        element.scrollLeft = scrollInfoRef.current.scrollLeft - walk;
      });
    });
  }, []);

  const doctors = useMemo(() => {
    const picUserIds = params?.picUserIds?.filter((ele) => !!ele);
    return [
      { id: NUMBER_PATIENT_COLUMN_ID, text: intl.formatMessage({ id: 'calendar.numberPatent' }), color: 'white' },
      ...picOtions
        .filter((ele) => (picUserIds && picUserIds?.length > 0 ? picUserIds.includes(ele.id) : true))
        .map((ele) => ({
          id: ele?.id!,
          text: ele?.employeeFullName!,
          color: 'white',
        })),
      { id: UNKNOWN_DOCTOR_ID, text: intl.formatMessage({ id: 'calendar.unsignedDoctor' }), color: 'white' },
    ];
  }, [picOtions, intl, params]);

  const appointmentMapped = useMemo(
    () =>
      appointments.map((ele) => {
        return {
          members: [
            ele?.picUserId && doctors?.some((doctor) => doctor?.id === ele?.picUserId)
              ? ele.picUserId
              : UNKNOWN_DOCTOR_ID,
          ],
          startDate: ele?.startTime?.toDate()!,
          endDate: ele?.endTime?.toDate(),
          title: ele?.name,
          id: ele?.id,
          picName: Object.values(users).find((item) => item.employeeId === ele.picUserId)?.employeeFullName,
          ...ele,
        };
      }),
    [appointments, doctors, users],
  );

  const mappedData = useMemo<AppointmentModel[]>(() => {
    return [
      ...appointmentMapped,
      ...handleNumberOfPatientInTimeRange(
        appointments,
        { startHour: 0, endHour: 24 },
        new Date(params.date),
        TIME_DURATION,
      ),
    ];
  }, [appointmentMapped, appointments, params.date]);

  useEffect(() => {
    if (selectedAppointment || (appointments && appointments.length > 0)) {
      const scrollAppointments =
        (selectedAppointment as AppointmentModel) ||
        appointments.sort((a, b) => a?.startTime?.milliseconds()! - b?.startTime?.milliseconds()!)[0];
      scrollToAppointment(scrollAppointments);
    }
  }, [appointments, selectedAppointment]);
  return (
    <Paper
      sx={{
        '& .Label-label .Label-text': {
          fontWeight: 500,
          fontSize: '17px',
        },
        '& .MainLayout-container': {
          height: '85vh',
        },
      }}
    >
      <Scheduler data={mappedData} locale="vi-VN">
        <ViewState
          currentDate={params?.date}
          onCurrentDateChange={(date) => {
            setSelectedAppointment(null);
            setParams({ ...params, date: moment(date).format(BE_DATE_FORMAT) });
          }}
        />
        <GroupingState
          grouping={[
            {
              resourceName: 'members',
            },
          ]}
        />
        <Toolbar
          rootComponent={({ children }) => {
            return (
              <Stack
                direction="row"
                bgcolor="#F4F8FF"
                px="20px"
                py="10px"
                justifyContent="space-between"
                marginBottom="20px"
              >
                {children}
              </Stack>
            );
          }}
          flexibleSpaceComponent={() => null}
        />
        <DateNavigator
          rootComponent={(props) => <SchedulerNavigationHeader {...props} date={new Date(params?.date)} />}
        />
        <TodayButton
          buttonComponent={(props) => (
            <Button sx={{ paddingX: '40px', paddingY: '11px' }} onClick={() => props.setCurrentDate(new Date())}>
              {intl.formatMessage({ id: 'calendar.appointment.button.today' })}
            </Button>
          )}
        />
        <DayView
          startDayHour={0}
          endDayHour={24}
          dayScaleCellComponent={() => null}
          cellDuration={TIME_DURATION}
          dayScaleEmptyCellComponent={() => {
            return <div style={{ width: '80px' }}></div>;
          }}
          timeTableCellComponent={TimeTableCellComponent}
        />
        <Appointments
          containerComponent={AppointmentsContainer}
          appointmentContentComponent={AppointmentContentComponent}
        />
        <AppointmentTooltip
          headerComponent={(props) => <AppointmentHeaderTooltip {...props} onUpdate={onUpdate} onDelete={onDelete} />}
          contentComponent={({ appointmentData }) =>
            appointmentData ? <AppointmentInfoBox data={appointmentData as Appointment} /> : null
          }
        />
        <Resources
          data={[
            {
              fieldName: 'members',
              title: 'Members',
              instances: doctors,
              allowMultiple: true,
            },
          ]}
          mainResourceName="members"
        />
        <IntegratedGrouping />
        <GroupingPanel cellComponent={GroupPanelCell} />
      </Scheduler>
    </Paper>
  );
};
export default TableCalendarDay;
