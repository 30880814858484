export const APPOINTMENT_TYPES = {
  THERAPY: {
    value: 'THERAPY',
    label: 'calendar.appointment.type.therapy',
    color: '#FFA000',
    lightColor: '#FFFDE7',
  },
  PATIENT: {
    value: 'PATIENT',
    label: 'calendar.appointment.type.personal',
    color: '#5794FF',
    lightColor: '#F4F8FF',
  },
  APPOINTMENT_GROUP: {
    value: 'APPOINTMENT_GROUP',
    label: 'calendar.appointment.type.group',
    color: '#66BB6A',
    lightColor: '#E8F5E9',
  },
} as const;

export const UNKNOWN_DOCTOR_ID = 'unknown';

export const NUMBER_PATIENT_COLUMN_ID = 'patientNumber';

export const TIME_DURATION = 15;

export const SCHEDULE_RANGE_UNIT = {
  week: { value: 'week', label: 'appointment.reception.byRangeUnit.label.week' },
  month: { value: 'month', label: 'appointment.reception.byRangeUnit.label.month' },
  quarter: { value: 'quarter', label: 'appointment.reception.byRangeUnit.label.quarter' },
  year: { value: 'year', label: 'appointment.reception.byRangeUnit.label.year' },
} as const;

export type ScheduleType = 'byRange' | 'byDayOfMonth';
