import { useCallback } from 'react';
import { STORAGE_RESOURCE_TYPE } from '../apiConstants';
import useGeneralHook from './useGeneralHook';
import { setLoading } from '../redux/commonReducer';
import { SS_TOKEN } from '../constants';
import axios from 'axios';
import { API_SERVER } from '../api';

interface StorageResourceDTO {
  file: File;
  type: keyof typeof STORAGE_RESOURCE_TYPE;
  referenceId: number;
  description?: string;
}

interface Params {
  onSuccess?: () => void;
}

function useUpdateMultipleFiles(params?: Params) {
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  return useCallback(
    async (files: StorageResourceDTO[]) => {
      dispatch(setLoading(true));
      try {
        let multipartFormData = new FormData();
        const types: StorageResourceDTO['type'][] = [];
        const referenceIds: number[] = [];
        const descriptions: string[] = [];
        files.forEach((file) => {
          multipartFormData.append('files', file.file);
          types.push(file.type);
          referenceIds.push(file.referenceId);
          descriptions.push(file.description || ' ');
        });

        const token = sessionStorage.getItem(SS_TOKEN) || '';
        await axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );

        params?.onSuccess && params?.onSuccess();
        openSnackbar({ message: intl.formatMessage({ id: 'exam.addNewSucceeds' }) });
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, params, openSnackbar, intl],
  );
}

export default useUpdateMultipleFiles;
