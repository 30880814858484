import { useRef } from 'react';
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

interface Props {
  name?: string;
  placeholder?: string;
  readOnly?: boolean;
  height?: string;
  setContents?: string;
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
}

const SunEditorInput = (props: Props) => {
  const { name, placeholder, readOnly, height, setContents, onChange, onBlur } = props;

  // config editor
  const editor = useRef(null);
  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  return (
    <SunEditor
      name={name}
      placeholder={placeholder}
      readOnly={readOnly}
      height={height ? height : '100%'}
      setContents={setContents}
      onChange={(value) => onChange && onChange(value)}
      onBlur={(event, value) => onBlur && onBlur(value)}
      getSunEditorInstance={getSunEditorInstance}
      setDefaultStyle={'font-family:Roboto,Helvetica,Arial,sans-serif; font-size:16px'}
      setOptions={{
        buttonList: [
          ['bold', 'underline', 'italic', 'fontColor'],
          ['align', 'list', 'table', 'fullScreen'],
        ],
      }}
    />
  );
};

export default SunEditorInput;
