import { Box } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_TIME_FORMAT, some } from 'modules/common/constants';
import TherapyIndicationList from '../TherapyIndication/TherapyIndicationList';
import TherapySessionResult from './TherapySessionResult';
import TherapySessionDocument from './TherapySessionDocument';
import TherapySessionInfo from './TherapySessionInfo';
import '../style.scss';
import { FormProvider, useForm } from 'react-hook-form';
import * as React from 'react';
import { axiosThunk } from 'modules/common/redux/axios';
import { setLoading } from 'modules/common/redux/commonReducer';
import TherapyMedication from '../TherapyMedication/TherapyMedication';
import { formatGeneralInfo } from './utils';
import { useCallback, useEffect, useState } from 'react';
import moment from 'moment/moment';
import TherapyMedicalSuppliesList from '../TherapyMedicalSupplies/TherapyMedicalSuppliesList';
import { fetchThunk } from '../../../../common/redux/thunk';
import EditNextTherapySessionTimeDialog from './EditNextTherapySessionTimeDialog';
import { mutate } from 'swr';

interface Props {
  therapy: some;
  onSaveTherapySession: (therapySession: some) => void;
  picOptions: some[];
  editable: boolean;
  dataTherapySession: some;
  dataTherapySessionMutate: (data, shouldValidate) => void;
  revalidateTherapySession: () => void;
  setEditable: (editable: boolean) => void;
}

export interface TherapySessionDetailForm {
  id?: number;
  name?: string;
  startTime?: string;
  endTime?: string;
  picName?: string;
  picUserId?: string;
  status?: string;
  orderNumber?: string;
  scope?: string;
  isFree?: string;
  price?: string;
  therapyId?: string;
  indicationList?: some[];
  medicationList?: some[];
  generalInfo: some;
  nextTherapySessionTime: {
    time: string;
    date: Date;
  };
}

const TherapySessionDetail: React.FunctionComponent<Props> = (props) => {
  const {
    therapy,
    onSaveTherapySession,
    picOptions,
    dataTherapySession,
    editable,
    dataTherapySessionMutate,
    revalidateTherapySession,
    setEditable,
  } = props;
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const [openEditNextSessionDialog, setOpenEditNextSessionDialog] = useState(false);

  const nextSession = therapy?.therapySessionList?.find(
    (session) =>
      session.orderNumber > dataTherapySession.orderNumber &&
      (session.status === 'NEW' || session.status === 'PROCESSING') &&
      (!session.startTime || moment(session.startTime, BE_DATE_TIME_FORMAT).isAfter(new Date())),
  );

  const { promptConfirmation, close } = confirmDialog;

  const requestService = async (v) => {
    let newIndicationRequestList = [...dataTherapySession?.indicationRequestList, v];
    await dataTherapySessionMutate(
      {
        ...dataTherapySession,
        indicationRequestList: newIndicationRequestList,
      },
      false,
    );
  };

  const onUpdateIndication = async (indication) => {
    const temp = [...dataTherapySession?.indicationRequestList];
    const index = temp.findIndex((e) => e.id === indication.id);
    temp[index] = indication;
    await dataTherapySessionMutate({ ...dataTherapySession, indicationRequestList: temp }, false);
  };

  const onDeleteIndication = async (indication) => {
    const temp = [...dataTherapySession?.indicationRequestList];
    const index = temp.findIndex((e) => e.id === indication.id);
    temp.splice(index, 1);

    await dataTherapySessionMutate({ ...dataTherapySession, indicationRequestList: temp }, false);
  };

  const onSelectedMedicalSupplies = async (v) => {
    let newMedicalSuppliesList = [...dataTherapySession?.medicalSuppliesList, v];
    await dataTherapySessionMutate(
      {
        ...dataTherapySession,
        medicalSuppliesList: newMedicalSuppliesList,
      },
      false,
    );
  };

  const onUpdateMedicalSupplies = async (medicalSupplies) => {
    const temp = [...dataTherapySession?.medicalSuppliesList];
    const index = temp.findIndex((e) => e.id === medicalSupplies.id);
    temp[index] = medicalSupplies;
    await dataTherapySessionMutate({ ...dataTherapySession, medicalSuppliesList: temp }, false);
  };

  const onDeleteMedicalSupplies = async (medicalSupplies) => {
    const temp = [...dataTherapySession?.medicalSuppliesList];
    const index = temp.findIndex((e) => e.id === medicalSupplies.id);
    temp.splice(index, 1);

    await dataTherapySessionMutate({ ...dataTherapySession, medicalSuppliesList: temp }, false);
  };

  const formMethods = useForm<TherapySessionDetailForm>({ defaultValues: { generalInfo: {} } });

  // clone data therapy session
  const cloneDataSession = useCallback(
    async (cloneData) => {
      if (cloneData?.id !== dataTherapySession?.id) {
        const confirm = await promptConfirmation({
          warning: false,
          title: intl.formatMessage({ id: 'common.title.confirm' }),
          content: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.clone.alert' }),
          cancelId: 'no',
          okId: 'yes',
        });
        if (confirm) {
          try {
            dispatch(setLoading(true));
            await dispatch(
              fetchThunk(
                API_SERVER.therapySession.clone(dataTherapySession?.id),
                'post',
                {
                  therapySessionId: cloneData?.id,
                },
                'application/json-patch+json',
              ),
            );
            revalidateTherapySession();

            openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
          } catch (e: any) {
            if (e?.errors) {
              e?.errors?.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
            } else {
              openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
            }
          } finally {
            dispatch(setLoading(false));
          }
        }
        close();
      }
    },
    [close, dataTherapySession?.id, dispatch, intl, openSnackbar, promptConfirmation, revalidateTherapySession],
  );

  useEffect(() => {
    if (dataTherapySession) {
      const startTime = moment(dataTherapySession?.startTime, BE_DATE_TIME_FORMAT);
      const date = startTime?.isValid() ? startTime.toDate() : null;
      formMethods.reset({
        generalInfo: {
          id: dataTherapySession?.id,
          name: dataTherapySession?.name,
          date: date || '',
          startTime: startTime.isValid() ? startTime.format(FE_TIME_FORMAT) : '',
          picUserId: dataTherapySession?.picUserId,
          picName: dataTherapySession?.picName,
          status: dataTherapySession?.status,
          description: dataTherapySession?.description,
          isFree: dataTherapySession?.isFree,
          conclude: dataTherapySession?.conclude,
        },
      });
    }
  }, [dataTherapySession, formMethods]);

  const onSelectMedication = async (medication) => {
    const temp = [...dataTherapySession?.medicationList, medication];
    await dataTherapySessionMutate({ ...dataTherapySession, medicationList: temp }, false);
  };

  const onDeleteMedication = async (medication) => {
    const temp = [...dataTherapySession?.medicationList];
    const index = temp.findIndex((e) => e.id === medication.id);
    temp.splice(index, 1);
    await dataTherapySessionMutate({ ...dataTherapySession, medicationList: temp }, false);
  };

  const onUpdateMedication = async (medication) => {
    const temp = [...dataTherapySession?.medicationList];
    const index = temp.findIndex((e) => e.id === medication.id);
    temp[index] = medication;
    await dataTherapySessionMutate({ ...dataTherapySession, medicationList: temp }, false);
  };

  const { handleSubmit, trigger, formState } = formMethods;

  const onHandleSubmit = useCallback(
    async (formData) => {
      const { generalInfo } = formData;
      // Save general Info
      dispatch(setLoading(true));
      try {
        // save TherapySession
        const { data: therapySession } = await dispatch(
          axiosThunk({
            url: API_SERVER.therapySession.update(generalInfo.id),
            method: 'put',
            data: formatGeneralInfo(generalInfo),
          }),
        );
        await dataTherapySessionMutate(therapySession, false);
        await onSaveTherapySession(therapySession);

        openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.success' }) });
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.failed' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dataTherapySessionMutate, dispatch, intl, onSaveTherapySession, openSnackbar],
  );

  const onNextTimeSessionButtonSubmit = useCallback(
    async (formData) => {
      // Save general Info
      dispatch(setLoading(true));
      try {
        // set time for next session
        const nextTherapySessionTime = formData.nextTherapySessionTime;
        if (nextSession && nextTherapySessionTime?.time && nextTherapySessionTime?.date) {
          const dateTimeFormat = `${FE_TIME_FORMAT} ${BE_DATE_FORMAT}`;
          const dateFormat = moment(nextTherapySessionTime?.date).format(BE_DATE_FORMAT);
          const startDate = moment(`${nextTherapySessionTime?.time} ${dateFormat}`, dateTimeFormat);
          const endDate = startDate.clone().add(2, 'hour');
          await dispatch(
            axiosThunk({
              url: API_SERVER.therapySession.update(nextSession.id),
              method: 'put',
              data: {
                startTime: startDate?.format(BE_DATE_TIME_FORMAT),
                endTime: endDate?.format(BE_DATE_TIME_FORMAT),
                picUserId: nextTherapySessionTime?.picUserId,
                picName: nextTherapySessionTime?.picName,
              },
            }),
          );
          await mutate(API_SERVER.therapySession.detail(nextSession.id).url);
        }
        await revalidateTherapySession();
        setOpenEditNextSessionDialog(false);
        openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.success' }) });
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.failed' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, nextSession, openSnackbar, revalidateTherapySession],
  );
  const onSaveButtonClick = async () => {
    const formData = formMethods.getValues();

    await trigger();
    if (Object.keys(formState.errors).length === 0) {
      await handleSubmit(onHandleSubmit)();
      if (formData.generalInfo.status === 'FINISHED' && dataTherapySession?.status !== 'FINISHED' && nextSession) {
        setOpenEditNextSessionDialog(true);
        if (nextSession && moment(nextSession.startTime, BE_DATE_TIME_FORMAT).isAfter(new Date())) {
          const startTime = moment(nextSession.startTime, BE_DATE_TIME_FORMAT);
          formMethods.setValue('nextTherapySessionTime.date', startTime.toDate());
          formMethods.setValue('nextTherapySessionTime.time', startTime.format(FE_TIME_FORMAT));
        }
      } else {
        revalidateTherapySession();
      }
    }
    if (formData.generalInfo.status === 'FINISHED') {
      setEditable(false);
    }
  };

  // thu phi ngoai goi buoi tri lieu
  const handleFee = async (checked: boolean) => {
    if (dataTherapySession) {
      dispatch(setLoading(true));
      try {
        const { data: therapySession } = await dispatch(
          axiosThunk({
            url: API_SERVER.therapySession.update(dataTherapySession.id),
            method: 'put',
            data: formatGeneralInfo({
              ...dataTherapySession,
              isFree: !checked,
            }),
          }),
        );
        dataTherapySessionMutate(therapySession, true);
        onSaveTherapySession(therapySession);
        openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.success' }) });
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.failed' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    }
  };

  return (
    <Box className="pd10">
      <FormProvider {...formMethods}>
        <Box component="form" bgcolor="white">
          {/* thông tin buổi trị liệu */}
          <TherapySessionInfo
            therapy={therapy}
            dataTherapySession={dataTherapySession}
            editable={editable}
            picOptions={picOptions}
            formMethods={formMethods}
            cloneDataSession={cloneDataSession}
            handleFee={handleFee}
            onSaveButtonClick={onSaveButtonClick}
          />
          {/* chỉ định */}
          <TherapyIndicationList
            indicationList={dataTherapySession?.indicationRequestList}
            onRequestService={requestService}
            onUpdateIndication={onUpdateIndication}
            onDeleteIndication={onDeleteIndication}
            therapySessionId={dataTherapySession?.id}
            revalidateTherapySession={revalidateTherapySession}
            editable={editable}
            therapy={therapy}
            therapySession={dataTherapySession}
          />
          {/*/!* thuốc sử dụng *!/*/}
          <TherapyMedication
            therapySessionId={dataTherapySession?.id}
            indicationOptions={dataTherapySession?.indicationRequestList?.filter((i) => i?.status !== 'DELETED')}
            onSelectedMedication={onSelectMedication}
            onDeleteMedication={onDeleteMedication}
            onUpdateMedication={onUpdateMedication}
            formData={{
              medicationList: dataTherapySession?.medicationList,
            }}
            editable={editable}
          />
          {/*/!* vật tư y tế *!/*/}
          <TherapyMedicalSuppliesList
            onUpdateMedicalSupplies={onUpdateMedicalSupplies}
            onDeleteMedicalSupplies={onDeleteMedicalSupplies}
            medicalSuppliesList={dataTherapySession?.medicalSuppliesList}
            indicationList={dataTherapySession?.indicationRequestList?.filter((i) => i?.status !== 'DELETED')}
            therapySessionId={dataTherapySession?.id}
            onSelectedMedicalSupplies={onSelectedMedicalSupplies}
            editable={editable}
          />
          {/* kết quả buổi trị liệu */}
          <TherapySessionResult dataTherapySession={dataTherapySession} editable={editable} />
          {/* file đính kèm */}
          <TherapySessionDocument dataTherapySession={dataTherapySession} editable={editable} therapy={therapy} />

          <EditNextTherapySessionTimeDialog
            open={openEditNextSessionDialog}
            onClose={() => {
              setOpenEditNextSessionDialog(false);
              revalidateTherapySession();
            }}
            onSaveButtonClick={onNextTimeSessionButtonSubmit}
            nextSession={nextSession}
            picOptions={picOptions}
          />
        </Box>
      </FormProvider>
    </Box>
  );
};

export default TherapySessionDetail;
