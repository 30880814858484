import * as React from 'react';
import { Box } from '@mui/material';
import EncounterReportTab from '../component/report/workReportTabs/EncounterReportTab';
import AppointmentReportTab from '../component/report/workReportTabs/AppointmentReportTab';
import IndicationReportTab from '../component/report/workReportTabs/IndicationReportTab';
import SupplyReportTab from '../../common/page/report/work/medicalSupplies/SupplyReportTab';
import MedicalReportTab from '../../common/page/report/work/medicationUsage/MedicalReportTab';
import Tabs from 'modules/common/component/Tabs';
import { ROLES, SERVICE_TYPE, SYSTEM_CATEGORY_SCOPE } from 'modules/common/constants';
import TherapyWorkReport from '../../common/page/report/work/therapy/DetailedReport';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { SPECIALIST } from 'modules/layout/constants';
import { GroupOption } from 'modules/common/type';
import { flatMap } from 'lodash';

interface Props {}

const AdminWorkReport: React.FunctionComponent<Props> = (props) => {
  const { data: therapyCategoryData } = useFetch(API_SERVER.therapyManagement.getList(), { globalLoading: false });
  const { data: serviceCategoryData = [] } = useFetch(
    API_SERVER.service.getAllSystemCategoryList({
      scope: [SYSTEM_CATEGORY_SCOPE.healthCheck],
    }),
    { globalLoading: false },
  );

  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const [serviceType] = React.useState<keyof typeof SERVICE_TYPE>(
    isDentalClinic ? SERVICE_TYPE.DENTAL.value : SERVICE_TYPE.THERAPY.value,
  );
  const serviceOptions = React.useMemo<GroupOption[]>(
    () =>
      serviceType === 'THERAPY'
        ? flatMap(therapyCategoryData, (category) => {
            return (
              category?.items?.map((therapy) => ({
                value: therapy?.id!,
                label: therapy?.name!,
                group: category?.name!,
              })) || []
            );
          })
        : flatMap(serviceCategoryData, (category) => {
            return (
              category?.items?.map((item) => ({
                value: item?.id!,
                label: item?.name!,
                group: category?.name!,
              })) || []
            );
          }),
    [serviceType, therapyCategoryData, serviceCategoryData],
  );
  return (
    <Box sx={{ padding: '10px 16px' }}>
      <Tabs
        searchUrlKey="tab"
        tabs={[
          {
            title: 'report.workReport.tab.title.appointmentReport',
            key: 'appointmentReport',
            content: <AppointmentReportTab />,
            roles: [ROLES.ADMIN.value, ROLES.COORDINATOR.value, ROLES.RECEPTIONIST.value, ROLES.GENERAL_DOCTOR.value],
          },
          {
            title: 'report.workReport.tab.title.encounterReport',
            key: 'encounterReport',
            content: <EncounterReportTab />,
            roles: [
              ROLES.ADMIN.value,
              ROLES.DOCTOR.value,
              ROLES.GENERAL_DOCTOR.value,
              ROLES.RECEPTIONIST.value,
              ROLES.COORDINATOR.value,
            ],
          },
          {
            title: 'report.workReport.tab.title.indicationRequestReport',
            key: 'indicationRequestReport',
            content: <IndicationReportTab />,
            roles: [ROLES.ADMIN.value, ROLES.DOCTOR.value, ROLES.GENERAL_DOCTOR.value, ROLES.COORDINATOR.value],
          },
          {
            title: 'supplyBook.printLabel',
            key: 'supplyReport',
            content: <SupplyReportTab />,
            roles: [
              ROLES.ADMIN.value,
              ROLES.DOCTOR.value,
              ROLES.GENERAL_DOCTOR.value,
              ROLES.RADIOLOGIST.value,
              ROLES.CLINICAL_PATHOLOGIST.value,
              ROLES.LABORATORY_TECHNOLOGIST.value,
              ROLES.RADIOGRAPHER.value,
            ],
          },
          {
            title: 'navMenu.drugDispenseBook',
            key: 'medicalReport',
            content: <MedicalReportTab />,
            roles: [
              ROLES.ADMIN.value,
              ROLES.DOCTOR.value,
              ROLES.GENERAL_DOCTOR.value,
              ROLES.RADIOLOGIST.value,
              ROLES.RADIOGRAPHER.value,
            ],
          },
          {
            title: 'therapy.workReport.label.name',
            key: 'therapyWorkReport',
            content: <TherapyWorkReport type={serviceType} serviceOptions={serviceOptions} />,
            roles: [ROLES.ADMIN.value],
          },
        ]}
        title=""
      />
    </Box>
  );
};

export default AdminWorkReport;
