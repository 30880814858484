import { POSITIVE_DECIMAL_NUMBER_REGEX } from '../../../../common/regex';
import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, roomsData, departments, isInsurance } = formProps || {};
  const list = valuesField?.listData?.filter((v) => v.id !== valuesField?.id) || [];

  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'serviceCode' }),
      placeholder: intl.formatMessage({ id: 'serviceCodeEnter' }),
      propsWrapper: { xs: 6 },
      autoFocus: true,
      register: {
        required: isInsurance,
      },
      required: isInsurance,
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'indication.name' }),
      placeholder: intl.formatMessage({ id: 'indication.nameEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
        validate: (value) =>
          list.find((v) => v.code === value) ? intl.formatMessage({ id: 'indication.duplicateCode' }) : true,
      },
    },
    groupCode: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'groupCode' }),
      placeholder: intl.formatMessage({ id: 'groupCodeEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: isInsurance,
      },
      required: isInsurance,
    },
    department: {
      type: 'select',
      label: intl.formatMessage({ id: 'departmentCode' }),
      placeholder: intl.formatMessage({ id: 'departmentCodeEnter' }),
      propsWrapper: { xs: 6 },
      rawOptions: true,
      options: departments,
      register: {
        required: true,
      },
      required: true,
    },
    room: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'indication.room' }),
      placeholder: intl.formatMessage({ id: 'indication.pickRoom' }),
      options: roomsData,
      propsWrapper: { xs: 6 },
      isOptionEqualToValue: (options, selected) => options.value === selected.value,
    },
    price: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'indication.price' }),
      placeholder: intl.formatMessage({ id: 'indication.priceEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    vat: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'vatPercent' }),
      placeholder: intl.formatMessage({ id: 'vatEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        max: {
          value: 100,
          message: intl.formatMessage({ id: 'validation.invalid' }),
        },
        pattern: {
          value: POSITIVE_DECIMAL_NUMBER_REGEX,
          message: intl.formatMessage({ id: 'validation.invalid' }),
        },
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'info' }),
      fields: ['code', 'name', 'groupCode', 'department', 'room'],
    },
    {
      id: 'price',
      title: intl.formatMessage({ id: 'price' }),
      fields: ['price', 'vat'],
    },
  ];
};

export const serviceFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
