import { Paper, Typography } from '@mui/material';
import moment from 'moment';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { BE_DATE_FORMAT, some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import usePaginationHook from '../../common/hook/usePaginationHook';
import FilterBox from '../component/exportInventory/FilterBox';
import FormDataDialog from '../component/exportInventory/FormDataDialog';
import ExportInventoryTable from '../component/exportInventory/ExportInventoryTable';
import { formatDateFilterField } from '../../common/utils';
import { useFetch } from 'modules/common/hook';

interface Props {}

const ExportInventoryPage: React.FunctionComponent<Props> = (props: Props) => {
  const { API_SERVER } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const [formData, setFormData] = useState<some | undefined>();
  const { params } = hookPagination;

  const { data = [] } = useFetch(
    API_SERVER.exportInventory.getList(formatDateFilterField(params, 'exportDateFrom', 'exportDateTo')),
    { revalidateOnMount: true, revalidateOnFocus: true },
  );

  // Get inventory export by id
  const { data: inventoryExportDetail } = useFetch(API_SERVER.exportInventory.getById(formData?.id), {
    globalLoading: true,
    enabled: formData?.id,
  });

  return (
    <>
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterBox
          hookPagination={hookPagination}
          onCreate={() => setFormData({ importDate: moment().format(BE_DATE_FORMAT) })}
          title={
            <>
              <Typography variant="h6">
                <FormattedMessage id="navMenu.exportInventory" />
              </Typography>
            </>
          }
        />
        <ExportInventoryTable data={data} onViewDetail={setFormData} hookPagination={hookPagination} />
      </Paper>
      {inventoryExportDetail && (
        <FormDataDialog
          formData={inventoryExportDetail}
          open={!!formData}
          onClose={() => {
            setFormData(undefined);
          }}
        />
      )}
    </>
  );
};
export default ExportInventoryPage;
