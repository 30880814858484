import { DatePicker, DatePickerProps } from '@mui/lab';
import { TextField } from '@mui/material';
import moment from 'moment';
import React, { memo } from 'react';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from '../../../constants';

export interface PropsDatePickerElement extends Omit<DatePickerProps, 'defaultValue' | 'onChange' | 'value'> {
  onChange?: (value?: string) => void;
  value?: string;
  required?: boolean;
  error?: boolean;
  isSubmitting?: boolean;
  name?: string;
  formatDate?: string;
}

interface Props extends PropsDatePickerElement {}

const DatePickerElement = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { name, error, isSubmitting, required, value = null, onChange, formatDate, ...rest } = props;
  return (
    <DatePicker
      mask="__/__/____"
      inputFormat="dd/MM/yyyy"
      value={
        moment(value, formatDate || BE_DATE_FORMAT, true).isValid()
          ? moment(value, formatDate || BE_DATE_FORMAT, true).toDate()
          : null
      }
      views={['year', 'month', 'day']}
      onChange={(date: any, value) => {
        let tmp;
        if (date) {
          const md = moment(date);
          tmp = md.isValid() ? md.format(formatDate || BE_DATE_FORMAT) : '';
        } else if (value) {
          const md = moment(value, FE_DATE_FORMAT, true);
          tmp = md.isValid() ? md.format(formatDate || BE_DATE_FORMAT) : '';
        } else if (value === null) {
          tmp = null;
        }
        onChange && onChange(tmp || null);
      }}
      allowSameDateSelection
      inputRef={ref}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            required: required,
          }}
          variant="outlined"
          fullWidth
          error={!!error}
        />
      )}
      {...(rest as any)}
    />
  );
});
export default memo(DatePickerElement);
