import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';

export const PrescriptionDoctorSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    mobilePhone: z.string(),
    picName: z.string(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
  })
  .partial();

export type PrescriptionDoctor = z.infer<typeof PrescriptionDoctorSchema>;
