import { Box } from '@mui/material';
import Header from './Header';
import Body from './Body';
import { some } from '../../../../../../common/constants';
import { useRef } from 'react';
import DialogAction from './DialogAction';

interface Props {
  onClose: () => void;
  therapy: some;
  therapySession: some;
}

const PrintDialogContent = ({ onClose, therapy, therapySession }: Props) => {
  const printContentRef = useRef(null);
  return (
    <Box paddingTop="̣9px" paddingBottom="20px">
      <Box ref={printContentRef}>
        <Header />
        <Body therapy={therapy} therapySession={therapySession} />
      </Box>
      <DialogAction onClose={onClose} printContentRef={printContentRef} />
    </Box>
  );
};

export default PrintDialogContent;
