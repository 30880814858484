import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { some } from '../../../../common/constants';
import SchemaForm from '../../../../common/SchemaForm';
import { FilterParamsEmployee, searchExamPriceSchema } from './schema';

interface Props {
  onSubmit?: (formVal: FilterParamsEmployee, methods: UseFormReturn) => void;
  onAddNew?: () => void;
  pagin?: React.ReactNode;
  formData: some;
}

const SearchExamPrice: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, onAddNew, pagin, formData } = props;
  return (
    <SchemaForm
      schema={searchExamPriceSchema}
      formData={formData}
      onSubmit={onSubmit}
      hideSubmitButton
      onAddNew={onAddNew}
      pagin={pagin}
    />
  );
};

export default SearchExamPrice;
