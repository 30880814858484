import { Box } from '@mui/material';
import Header from './Header';
import { useRef } from 'react';
import DialogAction from './DialogAction';
import Body from './Body';
import { some } from '../../../../../common/constants';

interface Props {
  onClose: () => void;
  therapyDetail: some;
  paymentDetail: some;
}
const PrintFormContent = ({ onClose, therapyDetail, paymentDetail }: Props) => {
  const printContentRef = useRef(null);
  return (
    <Box paddingTop="̣9px" paddingBottom="20px">
      <Box ref={printContentRef}>
        <Header />
        <Body therapyDetail={therapyDetail} paymentDetail={paymentDetail} />
      </Box>
      <DialogAction onClose={onClose} printContentRef={printContentRef} />
    </Box>
  );
};

export default PrintFormContent;
