import {
  ENCOUNTER_STATUS,
  PATIENT_TYPE,
  SPECIALIZED_REPORT_TYPE,
  SYSTEM_CATEGORY_SCOPE,
} from 'modules/common/apiConstants';
import { FilterType } from 'modules/common/component/filter/type';
import { BE_DATE_TIME_FORMAT, PAYMENT_PLAN, ROLES } from 'modules/common/constants';
import { formatEndDate } from 'modules/common/utils';
import moment from 'moment';

export const DEFAULT_VALUES = {
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatEndDate(moment()),
  keyword: '',
  type: SPECIALIZED_REPORT_TYPE.DENTAL,
};

export const FILTER_CONFIGS: FilterType[] = [
  {
    type: 'ENCOUNTER_STATUS',
    options: [
      ENCOUNTER_STATUS.FINISHED,
      ENCOUNTER_STATUS.INPROGRESS,
      ENCOUNTER_STATUS.ARRIVED,
      ENCOUNTER_STATUS.CANCELLED,
    ],
  },
  {
    type: 'PAYMENT_PLAN',
    options: [PAYMENT_PLAN.FEE, PAYMENT_PLAN.FREE, PAYMENT_PLAN.OTHER],
  },
  {
    type: 'PATIENT_TYPE',
    options: [PATIENT_TYPE.RE_EXAMINATION, PATIENT_TYPE.NEW],
    xs: 6,
  },
  {
    type: 'EMPLOYEE',
    roles: [ROLES.DOCTOR.value, ROLES.GENERAL_DOCTOR.value],
    groupByRole: false,
  },
  {
    type: 'SERVICE',
    scopes: [SYSTEM_CATEGORY_SCOPE.HEALTH_CHECK_SERVICE.value],
    title: 'filter.popup.title.encounterService',
    placeholder: 'filter.popup.placeholder.encounterService',
  },
  {
    type: 'ICD10',
  },
];
