import { Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import FilterPatientManagementBox from '../component/patientManagement/FilterPatientManagementBox';
import useGeneralHook from '../../common/hook/useGeneralHook';
import usePaginationHook from '../../common/hook/usePaginationHook';
import PatientManagementTable from '../component/patientManagement/PatientManagementTable';
import { useCallback, useMemo, useState } from 'react';
import PatientDialog from '../component/patientManagement/PatientDialog';
import moment from 'moment';
import { BE_DATE_FORMAT, some } from '../../common/constants';
import { axiosThunk } from '../../common/redux/axios';
import { API_SERVER } from '../../common/api';
import { GENDER_OPTIONS } from '../../reception/constants';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { PATIENT_ADDRESS_TYPE } from '../component/patientManagement/constant';
import { keyBy } from 'lodash';
import { setLoading } from '../../common/redux/commonReducer';
import { AxiosError } from 'axios/index';
import PatientManagementFormDialog from '../component/patientManagement/PatientImportFormDialog';
import fileDownload from 'js-file-download';
import { useFetch } from 'modules/common/hook';
import { PATIENT_CONTACT_TYPE } from 'modules/common/apiConstants';

const formatOption = (option: { value: string; label: string }) => {
  if (!option) return null;
  return option?.label + '|' + option?.value;
};

const PatientManagementPage = () => {
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const { promptConfirmation, close } = confirmDialog;
  const { params } = hookPagination;
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
  const [showErrorFileMax, setShowErrorFileMax] = React.useState<boolean>(false);
  const [{ selectedPatientId, mode, showDialog }, setPatientDialogState] = useState<{
    selectedPatientId: number;
    mode: 'view' | 'edit';
    showDialog: boolean;
  }>({
    selectedPatientId: 0,
    mode: 'view',
    showDialog: false,
  });

  const ethnics = useSelector((state: AppState) => state?.common?.ethnicList || []);
  const ethnicMap = useMemo(() => keyBy(ethnics, 'label'), [ethnics]);
  const countries = useSelector((state: AppState) => state?.common?.countryList || []);
  const countryMap = useMemo(() => keyBy(countries, 'label'), [countries]);
  const provinces = useSelector((state: AppState) => state?.common.provinceData || []);
  const provinceMap = useMemo(
    () => keyBy(provinces, (province) => province?.label + '|' + province?.value),
    [provinces],
  );
  const { data, revalidate, isValidating } = useFetch(API_SERVER.patient.searchPatient(params));

  const dataFormatted = useMemo(
    () =>
      data?.content?.map((patient) => {
        const birthday = moment(patient?.dob, BE_DATE_FORMAT);
        const contactInfo = patient?.patientContactInfoList?.[0];
        const workAddress = patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.work);
        const homeAddress = patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.home);
        const district = homeAddress?.district
          ? { value: homeAddress?.district?.split('|')[1], label: homeAddress?.district?.split('|')[0] }
          : null;

        return {
          ...patient,
          code: patient?.code?.split('-')?.[1],
          dob: birthday,
          gender: GENDER_OPTIONS.find((gender) => gender?.value?.toLowerCase() === patient?.gender?.toLowerCase())
            ?.value,
          ethnic: ethnicMap[patient?.ethnic!],
          jobAddress: workAddress?.address,
          address: homeAddress?.address,
          nationality: countryMap[patient?.nationality!],
          province: provinceMap[homeAddress?.province!],
          contactName: contactInfo?.name,
          contactTelephone: contactInfo?.mobilePhone,
          contactAddress: contactInfo?.address,
          district: district,
        };
      }) || [],
    [countryMap, ethnicMap, data, provinceMap],
  );

  const createOrUpdatePatient = async (formData) => {
    const currentYear = moment().year();
    const dob = moment(formData?.age ? new Date(currentYear - Math.abs(formData?.age), 0, 1) : formData?.dob);
    const data = {
      note: formData?.note,
      dob: dob.format(BE_DATE_FORMAT),
      email: formData.email,
      ethnic: formData?.ethnic?.label,
      gender: formData?.gender?.toUpperCase(),
      identifierCode: formData?.identifierCode,
      job: formData?.job,
      name: formData?.name,
      nationality: formData?.nationality?.label,
      mobilePhone: formData?.mobilePhone,
      patientAddressList: [
        {
          address: formData?.jobAddress,
          type: PATIENT_ADDRESS_TYPE.work,
        },
        {
          address: formData?.address,
          district: formatOption(formData?.district),
          province: formatOption(formData?.province),
          type: PATIENT_ADDRESS_TYPE.home,
        },
      ],
      patientContactInfoList: [
        {
          type: PATIENT_CONTACT_TYPE.PERSON_IN_CONTACT,
          address: formData?.contactAddress,
          name: formData?.contactName,
          mobilePhone:
            parseInt(formData?.contactTelephone) < 0 ? formData?.contactTelephone.slice(1) : formData?.contactTelephone,
        },
      ],
    };
    dispatch(setLoading(true));
    try {
      const method = formData?.id ? 'put' : 'post';
      const url = formData?.id ? API_SERVER.patient.edit(formData.id) : API_SERVER.patient.create();

      await dispatch(axiosThunk({ url, method, data }));
      openSnackbar({ message: intl.formatMessage({ id: 'patient.management.snackbar.submit.success' }) });
      setPatientDialogState({ selectedPatientId: 0, mode: 'view', showDialog: false });
      revalidate();
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'patient.management.snackbar.submit.failed' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const deletePatient = async (patient) => {
    if (!patient || !patient?.id) return;
    const confirm = await promptConfirmation({
      warning: true,
      title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
      content: intl.formatMessage({ id: 'confirmDelete' }, { name: patient?.name }),
    });
    if (confirm) {
      try {
        await dispatch(
          axiosThunk({
            url: API_SERVER.patient.delete(patient.id),
            method: 'delete',
          }),
        );
        openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        revalidate();
      } catch (error) {
        if (error instanceof AxiosError && error?.response)
          error.response?.data?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
      }
    }
    close();
  };

  const onSelectPatient = useCallback(
    async (patient) => {
      if (patient && patient.id) {
        setPatientDialogState({ selectedPatientId: patient.id || 0, mode: 'view', showDialog: true });
      }
    },
    [setPatientDialogState],
  );

  const onCreateHandle = () => {
    setPatientDialogState({ selectedPatientId: 0, mode: 'edit', showDialog: true });
  };

  const onExportHandle = async () => {
    resetMessage();
    dispatch(setLoading(true));
    await dispatch(
      axiosThunk({
        url: API_SERVER.patient.exportPatientsInfo(params),
        method: 'get',
        responseType: 'blob',
      }),
    )
      .then((response) => {
        let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const effectiveFileName = fileName || 'MEDi-List-patients.xlsx';
        fileDownload(response.data, effectiveFileName);
        dispatch(setLoading(false));
      })
      .catch((e: AxiosError<some>) => {
        dispatch(setLoading(false));
        openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
      });
  };

  const onImportHandle = () => {
    setOpenPopup(true);
  };

  const onUpdatePatient = useCallback(
    (patient) => {
      if (patient && patient.id) {
        setPatientDialogState({ selectedPatientId: patient.id, mode: 'edit', showDialog: true });
      }
    },
    [setPatientDialogState],
  );

  const closePopupImport = () => {
    setOpenPopup(false);
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setShowErrorFileMax(false);
  };

  const resetMessage = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setShowErrorFileMax(false);
  };

  const onShowErrorFileMax = async () => {
    setShowErrorFileMax(true);
  };

  const onUploadFile = useCallback(
    async (file: File | null) => {
      resetMessage();
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: API_SERVER.patient.uploadPatientInfoFile(),
          method: 'post',
          responseType: 'blob',
          headers: {
            'Content-type': 'multipart/form-data',
          },
          data: { data: file },
        }),
      )
        .then((response) => {
          let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
          const effectiveFileName = fileName || 'MEDi-Import-patient-error.xlsx';
          dispatch(setLoading(false));
          if (response.data.size === 0) {
            setShowSuccessMessage(true);
            return;
          }
          setShowErrorMessage(true);
          fileDownload(response.data, effectiveFileName);
        })
        .catch((e: AxiosError<some>) => {
          dispatch(setLoading(false));
          openSnackbar({ message: intl.formatMessage({ id: 'report.uploadFail' }), type: 'error' });
        });
    },
    [dispatch, intl, openSnackbar],
  );

  const onDownLoadFile = async () => {
    resetMessage();
    dispatch(setLoading(true));
    await dispatch(
      axiosThunk({
        url: API_SERVER.patient.downloadPatientInfoTemplateFile,
        method: 'get',
        responseType: 'blob',
      }),
    )
      .then((response) => {
        let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const effectiveFileName = fileName || 'MEDi-Import-patient-template.xlsx';
        fileDownload(response.data, effectiveFileName);
        dispatch(setLoading(false));
      })
      .catch((e: AxiosError<some>) => {
        dispatch(setLoading(false));
        openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
      });
  };

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterPatientManagementBox
        hookPagination={hookPagination}
        onCreateHandle={onCreateHandle}
        onImportHandle={onImportHandle}
        onExportHandle={onExportHandle}
        pagin={
          <>
            <Typography variant="h6">
              <FormattedMessage id="patient.management.page.title" />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="foundResult"
                values={{
                  num: (
                    <Typography variant="inherit" component="span" color="primary">
                      {dataFormatted?.length || 0}
                    </Typography>
                  ),
                  total: (
                    <Typography variant="inherit" component="span" color="primary">
                      {data?.pagination?.totalElements || 0}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </>
        }
      />
      <PatientManagementTable
        data={dataFormatted}
        total={data?.pagination?.totalElements || 0}
        hookPagination={hookPagination}
        loading={isValidating}
        onSelectPatient={onSelectPatient}
        onUpdatePatient={onUpdatePatient}
        onDeletePatient={deletePatient}
      />
      <PatientDialog
        formData={dataFormatted.find((ele) => ele.id === selectedPatientId)}
        open={showDialog}
        onClose={() => setPatientDialogState((current) => ({ ...current, showDialog: false }))}
        onSubmit={createOrUpdatePatient}
        mode={mode}
      />
      <PatientManagementFormDialog
        open={openPopup}
        onClose={closePopupImport}
        onUploadFile={onUploadFile}
        onDownLoadFile={onDownLoadFile}
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
        showErrorFileMax={showErrorFileMax}
        onShowErrorFileMax={onShowErrorFileMax}
      />
    </Paper>
  );
};

export default PatientManagementPage;
