import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useCallback, useState } from 'react';
import {
  BE_DATE_TIME_FORMAT,
  DIAGNOSTIC_IMAGE_TYPE,
  FE_DATE_TIME_FORMAT,
  some,
  SS_TOKEN,
} from 'modules/common/constants';
import useSWR from 'swr';
import { setLoading } from 'modules/common/redux/commonReducer';
import moment from 'moment/moment';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Delete, Edit, Print } from '@mui/icons-material';
import { MedicalIcon } from 'modules/../svg';
import { FormattedMessage } from 'react-intl';
import CameraImageGalleryDialog from './CameraImageGalleryDialog';
import CameraPrintFormDialog from './CameraPrintFormDialog';
import { API_SERVER } from 'modules/common/api';
import { axiosThunk } from 'modules/common/redux/axios';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import * as React from 'react';
import ImageBox from './ImageBox';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useParams } from 'react-router';
import { useFetch } from 'modules/common/hook';

interface Props {
  therapy: some;
  diagnosticImageId?: number;
  therapySession: some;
  editable: boolean;
}

const TherapyDiagnosticImage = (props: Props) => {
  const { therapy = {}, therapySession, editable } = props;
  const { encounterId } = useParams<{ encounterId }>();
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const [selectedTherapyImage, setSelectedTherapyImage] = useState<some | null>(null);
  const [openDialog, setOpenDialog] = useState<'printDialog' | 'therapyImageDialog' | null>();
  const { data: therapySingleImages = [], revalidate: therapySingleImagesRevalidate } = useFetch(
    therapySession?.id
      ? API_SERVER.storageResource.getList({
          type: STORAGE_RESOURCE_TYPE.therapyDiagnosticImage,
          referenceId: therapySession?.id,
        })
      : API_SERVER.storageResource.getList({
          type: STORAGE_RESOURCE_TYPE.encounterDiagnosticImage,
          referenceId: parseInt(encounterId),
        }),
  );

  const { data: therapyImages = [], revalidate: therapyImagesRevalidate } = useSWR(
    encounterId
      ? API_SERVER.diagnosticImage.getDiagnosticImages({ imageType: 'ENCOUNTER', referenceId: parseInt(encounterId) })
          .url
      : therapySession?.id && API_SERVER.diagnosticImage.getTherapyDiagnosticImages(therapySession?.id).url,
    async (url) => {
      const res = await dispatch(axiosThunk({ url }));
      return res?.data;
    },
  );

  const onSubmitTherapyImageForm = useCallback(
    async (data) => {
      const url = data?.id ? API_SERVER.diagnosticImage.update(data.id) : API_SERVER.diagnosticImage.create();

      const method = data?.id ? 'put' : 'post';

      try {
        dispatch(setLoading(true));
        const { data: therapyImage } = await dispatch(
          axiosThunk({
            url,
            method,
            data: {
              ...data,
              referenceId: encounterId ? encounterId : therapySession?.id,
              type: encounterId ? DIAGNOSTIC_IMAGE_TYPE.ENCOUNTER.value : DIAGNOSTIC_IMAGE_TYPE.THERAPY_SESSION.value,
            },
          }),
        );

        const checkedImages = data?.images?.filter((ele) => !!ele?.checked);
        const files = await Promise.all(
          checkedImages
            ?.filter((ele) => !!ele?.imageSrc)
            ?.map((ele, i) => {
              return fetch(ele?.imageSrc)
                .then((res) => res.blob())
                .then(
                  (res) =>
                    new File([res], 'screen ' + i + '.jpg', {
                      lastModified: new Date().getTime(),
                      type: 'image/jpeg',
                    }),
                );
            }) || [],
        );

        let createImageRequest;
        if (files && files.length > 0) {
          let multipartFormData = new FormData();
          files.forEach((ele) => multipartFormData.append('files', ele));
          const types =
            files?.map((ele) =>
              encounterId
                ? STORAGE_RESOURCE_TYPE.encounterDiagNosticImageAlbum
                : STORAGE_RESOURCE_TYPE.therapyDiagnosticImageAlbum,
            ) || [];
          const referenceIds = files?.map((ele) => therapyImage?.id) || [];
          const descriptions = files?.map((ele, index) => checkedImages?.[index]?.description || ' ') || [];
          const token = sessionStorage.getItem(SS_TOKEN) || '';
          createImageRequest = axios.post(
            API_SERVER.storageResource.uploadMultiFile({
              types: types.join(','),
              referenceIds: referenceIds.join(','),
              descriptions: descriptions.join(','),
            }),
            multipartFormData,
            { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
          );
        }
        let updateImageRequest;
        const updateImages = data?.images?.filter((ele) => !!ele?.id);
        if (updateImages && updateImages.length > 0) {
          updateImageRequest = dispatch(
            axiosThunk({
              url: API_SERVER.storageResource.updateDescription(),
              method: 'put',
              data: { data: updateImages?.map((ele) => ({ id: ele?.id, description: ele?.description })) },
            }),
          );
        }

        let deleteImageRequest;
        const deletedImageIds = data?.images?.filter((image) => image.deleted)?.map((ele) => ele?.id);
        if (deletedImageIds && deletedImageIds.length > 0) {
          deleteImageRequest = dispatch(
            axiosThunk({
              url: API_SERVER.storageResource.deleteMultiple(),
              method: 'delete',
              data: { data: deletedImageIds },
            }),
          );
        }

        await Promise.all([createImageRequest, updateImageRequest, deleteImageRequest]);
        await therapyImagesRevalidate();
        setOpenDialog(null);
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editSucceeds' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editFails' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, encounterId, intl, openSnackbar, therapyImagesRevalidate, therapySession?.id],
  );

  const onSaveSingleImage = useCallback(
    async (files: FileList) => {
      dispatch(setLoading(true));
      try {
        let multipartFormData = new FormData();
        const types: string[] = [];
        const referenceIds: string[] = [];
        const descriptions: string[] = [];
        for (let i = 0; i < files.length; i++) {
          multipartFormData.append('files', files[i]);
          types.push(
            encounterId ? STORAGE_RESOURCE_TYPE.encounterDiagnosticImage : STORAGE_RESOURCE_TYPE.therapyDiagnosticImage,
          );
          referenceIds.push(encounterId ? encounterId : therapySession?.id);
          descriptions.push(' ');
        }
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        await axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );

        await therapySingleImagesRevalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'exam.addNewSucceeds' }) });
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, encounterId, intl, openSnackbar, therapySession?.id, therapySingleImagesRevalidate],
  );

  const onDeleteTherapyImage = useCallback(
    async (value: some, index) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: `CĐHA-${index + 1}` }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.diagnosticImage.delete(value?.id), method: 'delete' }));
          await therapyImagesRevalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, therapyImagesRevalidate],
  );

  const onDeleteSingleImage = useCallback(
    async (id: number, index) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: `CĐHA-${index + 1}` }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.storageResource.delete(id), method: 'delete' }));
          await therapySingleImagesRevalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, therapySingleImagesRevalidate],
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      {therapyImages
        ?.sort((a, b) => {
          return moment(a?.createdTime, BE_DATE_TIME_FORMAT).isAfter(moment(b?.createdTime, BE_DATE_TIME_FORMAT));
        })
        ?.map((item, index) => {
          return (
            <Box
              key={item.id}
              color="inherit"
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                textAlign: 'start',
                minHeight: '32px',
                padding: 1,
                bgcolor: 'secondary.light',
                alignItems: 'center',
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle2" color="textPrimary" flex={1}>
                {moment(item.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT)}
                &emsp;CĐHA-
                {index + 1}
              </Typography>
              <IconButton
                style={{ padding: 4, marginRight: 8 }}
                onClick={() => {
                  setSelectedTherapyImage(item);
                  setOpenDialog('printDialog');
                }}
              >
                <Print />
              </IconButton>
              {editable && (
                <>
                  <IconButton
                    style={{ padding: 4, marginRight: 8 }}
                    onClick={() => {
                      setSelectedTherapyImage(item);
                      setOpenDialog('therapyImageDialog');
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <IconButton
                    style={{ padding: 4 }}
                    onClick={() => {
                      onDeleteTherapyImage(item, index);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </>
              )}
            </Box>
          );
        })}
      {editable && (
        <Stack direction="row" gap={2}>
          <Button
            color="inherit"
            startIcon={<MedicalIcon />}
            sx={{ width: 'fit-content' }}
            onClick={() => setOpenDialog('therapyImageDialog')}
          >
            <FormattedMessage id="camera.connection" />
          </Button>
          <IconButton
            component="label"
            sx={{
              borderRadius: '4px',
              backgroundColor: '#CDDFFF',
              color: '#0052E0',
              fontSize: '14px',
              fontWeight: 500,
              fontFamily: 'Roboto',
              letterSpacing: '0.02857em',
              width: '181px',
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              style={{ objectFit: 'cover', position: 'relative' }}
              gap={1}
            >
              <input
                accept="image/*"
                hidden
                type="file"
                multiple
                onChange={(event) => {
                  if (event.target.files && event.target.files?.length > 0) {
                    onSaveSingleImage(event.target.files);
                  }
                }}
              />
              <AddPhotoAlternateIcon color="primary" />
              <FormattedMessage id="therapy.detail.label.attackImage" />
            </Stack>
          </IconButton>
        </Stack>
      )}
      <CameraImageGalleryDialog
        therapy={therapy}
        open={openDialog === 'therapyImageDialog'}
        formData={selectedTherapyImage}
        onClose={() => {
          setOpenDialog(null);
          setSelectedTherapyImage(null);
        }}
        onSave={onSubmitTherapyImageForm}
        onPrintFormClickHandler={() => {
          setOpenDialog('printDialog');
        }}
      />
      {selectedTherapyImage && (
        <CameraPrintFormDialog
          therapyImage={selectedTherapyImage}
          therapy={therapy}
          open={openDialog === 'printDialog'}
          onClose={() => {
            setOpenDialog(null);
            setSelectedTherapyImage(null);
          }}
        />
      )}
      <ImageBox
        images={therapySingleImages}
        onDeleteTherapyImage={onDeleteSingleImage}
        editable={editable}
        revalidate={therapySingleImagesRevalidate}
      />
    </Box>
  );
};

export default TherapyDiagnosticImage;
