import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import DialogCustom from 'modules/common/component/DialogCustom';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
const COMPACT_TYPE = 'DRUG_USAGE_IMAGE_DOCTOR__PRINT_COMPACT_TYPE';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    '& .hiddenPage': { display: 'none' },
    [`@media print`]: {
      '& .newPage': { pageBreakBefore: 'always' },
      '& .hiddenPage': { display: 'block' },
      padding: 0,
    },
  },
});

interface TestReceiptContentProps {
  patient: some;
  indicationMedications?: some[];
  indicationRequest: some;
  compact: boolean;
}
interface Props {
  open: boolean;
  onClose?: () => void;
  patient: some;
  indicationMedications?: some[];
  indicationRequest: some;
}

const PrescriptionPrintExamDoctorContent = React.forwardRef((props: TestReceiptContentProps, ref) => {
  const { patient, indicationMedications, compact, indicationRequest } = props;
  const { appState } = useGeneralHook();
  const { currentLocation } = appState.authen;
  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase' }} marginBottom={3}>
            <FormattedMessage id="drugUsage.printLabel" />
          </Typography>
        </HeaderPrintForm>
        <Grid container>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 200 }} fontSize="22px">
                <FormattedMessage id="therapy.label.customer.code" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" fontSize="22px">
                {patient?.code?.split('-')?.[1]}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 200 }} fontSize="22px">
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }} fontSize="22px">
                {patient?.name}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 200 }} fontSize="22px">
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" fontSize="18px">
                {patient.gender && <FormattedMessage id={patient?.gender?.toLowerCase()} />}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 200 }} fontSize="22px">
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" fontSize="20px">
                {patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 200 }} fontSize="22px">
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" fontSize="22px">
                {moment(patient.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [patient]);

  const testInfo = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 16 }}>
          <Typography variant="subtitle1" color="textSecondary" fontSize="22px">
            <FormattedMessage id="drugUsage.drugIndication" />
          </Typography>
        </Box>
        {indicationMedications?.map((item: some, index: number) => {
          return (
            <Box key={item.id} style={{ marginTop: 4, marginBottom: 12 }}>
              <Typography variant="subtitle1" fontSize="22px">
                {index + 1}.&nbsp;{item.medicationName}
                <Typography variant="body1" component="span" fontSize="20px">
                  ({item.medicationIngredient}, {item?.medication?.dose})
                </Typography>
              </Typography>
              <Box display="flex">
                <Typography variant="body1" component="span" fontSize="22px">
                  <FormattedMessage id="drugUsage.quantity" />
                  :&nbsp;{item.quantity}
                </Typography>
              </Box>
            </Box>
          );
        })}
      </>
    );
  }, [indicationMedications]);

  const footer = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 15 }}>
          <Grid container>
            {compact ? (
              <Grid item xs={6} />
            ) : (
              <>
                <Grid item xs={3} textAlign="center">
                  <Typography variant="body1">&nbsp;</Typography>
                  <Typography variant="h6">
                    <FormattedMessage id="therapy.indication.request.printForm.patientConfirm" />
                  </Typography>
                  <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                    <FormattedMessage id="medicalPrint.helperText" />
                  </Typography>
                  <Typography variant="body1">{patient.name}</Typography>
                </Grid>
                <Grid item xs={3} textAlign="center">
                  <Typography variant="body2">&nbsp;</Typography>
                  <Typography variant="h6">
                    <FormattedMessage id="drugUsage.employee" />
                  </Typography>{' '}
                  <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                    <FormattedMessage id="medicalPrint.helperText" />
                  </Typography>
                </Grid>
              </>
            )}
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id={compact ? 'printForm.dateShort' : 'printForm.date'}
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="printForm.doctor" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
              <Typography variant="body1">{indicationRequest.doctor}</Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [compact, indicationRequest.doctor, patient.name]);

  if (!currentLocation) {
    return null;
  }
  return (
    <Box style={{ pageBreakAfter: 'always' }}>
      {header}
      <Box marginY={1}>
        <Divider />
      </Box>
      {testInfo}
      {footer}
    </Box>
  );
});

const DrugUsagePrintDialog = (props: Props) => {
  const { onClose, open, indicationMedications, patient, indicationRequest } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [compact, setCompact] = React.useState(localStorage.getItem(COMPACT_TYPE) === 'compact' || false);

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: '80vw' },
      }}
      keepMounted={false}
      title={'printForm.prescription'}
      footer={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={compact}
                onChange={(e) => {
                  setCompact(e.target.checked);
                  localStorage.setItem(COMPACT_TYPE, e.target.checked ? 'compact' : 'expansion');
                }}
              />
            }
            label={<FormattedMessage id="printCompact" />}
          />
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="drugUsage.print" />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />
        </Box>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <PrescriptionPrintExamDoctorContent
          indicationMedications={indicationMedications}
          compact={compact}
          patient={patient}
          indicationRequest={indicationRequest}
        />
      </Box>
    </DialogCustom>
  );
};

export default DrugUsagePrintDialog;
