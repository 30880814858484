import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, ClickAwayListener, Grow, List, ListItem, ListItemIcon, Paper, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import CancelReasonEncounterDialog from 'modules/reception/component/encounter/CancelReasonEncounterDialog';
import ReceiveWizardDialog from 'modules/reception/component/encounter/ReceiveWizardDialog';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';
import { DoctorExchange, PrintRegistration } from '../../../../svg';
import PrintEncounterRegistration from '../../../reception/component/encounter/PrintEncounterRegistration';
import moment from 'moment';
import { Encounter } from 'modules/schema';
import { useUpdateMutate } from 'modules/common/hook/useMutate';
import { ENCOUNTER_STATUS, PAYMENT_STATUS } from 'modules/common/apiConstants';
import { useUserRoles } from 'modules/common/hook/useUserRoles';
import { EncounterFormMode } from 'modules/reception/component/encounter/ReceiveWizardDialog/type';
import EditPICDialog from 'modules/common/component/EditPICDialog';
import { API_SERVER } from 'modules/common/api';
import DialogCustom from 'modules/common/component/DialogCustom';
import VitalSignForm from 'modules/nurse/encounterDetail/VitalSignForm';
import { useHistory } from 'react-router';
import { ROUTES } from 'modules/layout/router';

const useStyles = makeStyles((theme: Theme) => ({
  paper: { padding: 0, overflow: 'hidden' },
  list: { padding: 0 },
  listIcon: { minWidth: 36 },
}));

interface IEncounterListProps {
  encounters?: Encounter[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  refresh: () => void;
}

const EncounterList: React.FunctionComponent<IEncounterListProps> = ({
  refresh,
  encounters,
  loading,
  hookPagination,
  total,
}) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const classes = useStyles();
  const history = useHistory();
  const menuRef = React.useRef<HTMLDivElement | null>(null);
  const [rightClickData, setRightClickData] = React.useState<
    | {
        x: number;
        y: number;
      }
    | undefined
  >(undefined);
  const [selectedEncounter, setSelectedEncounter] = React.useState<Encounter | undefined>(undefined);

  const showEditPICButton = React.useMemo(
    () =>
      selectedEncounter &&
      (!selectedEncounter.endTime ||
        (selectedEncounter.endTime && selectedEncounter.endTime.isAfter(moment().add(-1, 'day')))),
    [selectedEncounter],
  );
  const [openEncounterForm, setOpenEncounterForm] = React.useState(false);
  const [encounterFormMode, setEncounterFormMode] =
    React.useState<Exclude<EncounterFormMode, 'CREATE'>>('CREATE_WITH_OLD_PATIENT');
  const [openPrintForm, setOpenPrintForm] = React.useState(false);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [openObservationDialog, setOpenObservationDialog] = React.useState(false);
  const [openChangePICDialog, setOpenChangePICDialog] = React.useState(false);
  const doctors = useUserRoles('DOCTOR', 'GENERAL_DOCTOR');

  const onCancelEncounterMutate = useUpdateMutate({
    onSuccess: () => {
      setOpenCancelDialog(false);
      refresh();
    },
  });

  const onChangePICMutate = useUpdateMutate({
    onSuccess: () => {
      setOpenChangePICDialog(false);
      refresh();
    },
  });

  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounter.table.label.sequenceNumber',
        dataIndex: 'sequenceNumber',
        align: 'left',
        width: 90,
      },
      {
        title: 'encounterId',
        render: (record) => {
          return <Typography>{record?.code}</Typography>;
        },
        align: 'right',
      },
      {
        title: 'patientCodeShort',
        render: (record) => {
          return <Typography>{record?.patient?.code?.split('-')[1]}</Typography>;
        },
        align: 'right',
      },
      {
        title: 'patientName',
        render: (record) => {
          return <Typography>{record?.patient?.name}</Typography>;
        },
      },
      {
        title: 'birthdayShort',
        render: (record) => {
          return <Typography>{moment(record?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>;
        },
        width: 120,
      },
      {
        title: 'telephone',
        render: (record) => {
          return <Typography>{record?.patient?.mobilePhone}</Typography>;
        },
      },
      {
        title: 'encounterDate',
        dataIndex: 'date',
        render: (record) => {
          return <Typography>{record?.createdTime?.format(FE_DATE_FORMAT)}</Typography>;
        },
      },
      {
        title: 'encounterDoctor',
        render: (record) => {
          return record?.picName;
        },
      },
      {
        title: 'checkUpType',
        dataIndex: 'serviceName',
      },
      {
        title: 'status',
        render: (record) => {
          const status = ENCOUNTER_STATUS[record.status!];
          return (
            <Typography noWrap sx={{ color: status?.color }}>
              <FormattedMessage id={status?.label} />
            </Typography>
          );
        },
      },
      {
        title: 'cashier.paymentStatus',
        dataIndex: 'paymentStatus',
        minWidth: 180,
        render: (record) => {
          const payment = PAYMENT_STATUS[record.payment?.status!];
          return (
            <Box color={payment?.color}>
              <FormattedMessage id={payment?.label || ' '} />
            </Box>
          );
        },
      },
    ] as Columns<Encounter>[];
  }, []);

  return (
    <>
      <TableCustom
        dataSource={encounters}
        loading={loading}
        columns={columns}
        rowProps={(record) => {
          return {
            onContextMenu: (e) => {
              e.preventDefault();
              setRightClickData({
                x: e.clientX - 2,
                y: e.clientY - 4,
              });
              setSelectedEncounter(record);
            },
            selected: selectedEncounter?.id === record?.id,
            onClick: async () => {
              history.push(ROUTES.episodeOfCareDetail.gen(record?.id!, record?.patientId!));
            },
            style: { cursor: 'pointer' },
          };
        }}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
      <ClickAwayListener
        onClickAway={() => {
          setRightClickData(undefined);
        }}
      >
        <Grow in={!!rightClickData} key={`${rightClickData?.y}-${rightClickData?.x}`}>
          <Paper
            ref={menuRef}
            elevation={3}
            className={classes.paper}
            style={
              rightClickData && menuRef?.current
                ? {
                    position: 'fixed',
                    top:
                      window.innerHeight - rightClickData?.y - menuRef?.current?.offsetHeight > 0
                        ? rightClickData?.y
                        : rightClickData?.y - menuRef?.current?.offsetHeight,
                    left: rightClickData?.x,
                    overflow: 'hidden',
                    zIndex: 99,
                  }
                : {
                    display: 'none',
                  }
            }
          >
            <List className={classes.list}>
              <ListItem button dense onClick={() => setOpenPrintForm(true)}>
                <ListItemIcon className={classes.listIcon}>
                  <PrintRegistration fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="encounter.registration.button.printRegistration" />
              </ListItem>
              <ListItem
                button
                dense
                onClick={() => {
                  setOpenEncounterForm(true);
                  setEncounterFormMode('CREATE_WITH_OLD_PATIENT');
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  <AddCircleIcon fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="encounterList.addEncounter" />
              </ListItem>
              <ListItem
                button
                dense
                onClick={() => {
                  setOpenEncounterForm(true);
                  setEncounterFormMode('UPDATE');
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="encounterList.editEncounter" />
              </ListItem>
              <ListItem button dense onClick={() => setOpenObservationDialog(true)}>
                <ListItemIcon className={classes.listIcon}>
                  <MedicalInformationIcon fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="encounterList.vitalSignEdit" />
              </ListItem>
              {showEditPICButton && (
                <ListItem button dense onClick={() => setOpenChangePICDialog(true)}>
                  <ListItemIcon className={classes.listIcon}>
                    <DoctorExchange />
                  </ListItemIcon>
                  <FormattedMessage id="reception.encounterList.editPIC.button.title" />
                </ListItem>
              )}
              <ListItem
                button
                dense
                disabled={selectedEncounter?.status !== ENCOUNTER_STATUS.ARRIVED.value}
                onClick={() => setOpenCancelDialog(true)}
              >
                <ListItemIcon className={classes.listIcon}>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="encounterList.cancel" />
              </ListItem>
            </List>
          </Paper>
        </Grow>
      </ClickAwayListener>
      {selectedEncounter && selectedEncounter?.id && (
        <>
          <ReceiveWizardDialog
            encounterId={selectedEncounter.id}
            mode={encounterFormMode}
            open={openEncounterForm}
            close={() => {
              setOpenEncounterForm(false);
              setSelectedEncounter(undefined);
            }}
            refresh={refresh}
          />
          <CancelReasonEncounterDialog
            open={openCancelDialog}
            onClose={() => setOpenCancelDialog(false)}
            formData={{ cancelReason: selectedEncounter?.cancelReason }}
            onSubmit={(data) =>
              onCancelEncounterMutate({
                url: API_SERVER.encounter.cancelEncounter(selectedEncounter?.id!),
                method: 'put',
                data: { data: data?.cancelReason },
              })
            }
          />
          <DialogCustom
            open={openObservationDialog}
            onClose={() => setOpenObservationDialog(false)}
            maxWidth="xl"
            title={'encounterList.vitalSignEdit'}
          >
            <Box padding={2}>
              <VitalSignForm
                encounterId={selectedEncounter.id}
                readOnly={selectedEncounter.status === ENCOUNTER_STATUS.FINISHED.value}
                onClose={() => setOpenObservationDialog(false)}
              />
            </Box>
          </DialogCustom>
          <PrintEncounterRegistration
            encounter={selectedEncounter}
            open={openPrintForm}
            onClose={() => setOpenPrintForm(false)}
          />
          <EditPICDialog
            currentPICId={selectedEncounter.picUserId!}
            dialogTitleKey="encounter.encounterDetail.editPIC.dialog.title"
            doctors={doctors}
            onClose={() => setOpenChangePICDialog(false)}
            onUpdatePIC={(data) =>
              onChangePICMutate({
                url: API_SERVER.encounter.changePIC(selectedEncounter.id!),
                method: 'PUT',
                data: data,
              })
            }
            open={openChangePICDialog}
          />
        </>
      )}
    </>
  );
};

export default EncounterList;
