import findDeep from 'deepdash/es/findDeep';
import { useEffect, useState } from 'react';
import {
  ALL_DISCOUNT_RATE_URL,
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  CHARGE_ITEM_TYPES,
  DISCOUNT_AMOUNT_URL,
  DISCOUNT_RATE_URL,
  FE_TIME_FORMAT,
  PATIENT_DE_URL,
  PATIENT_PAY_URL,
  some,
  SS_PAY_URL,
  VALUE_INTERPRETATION,
  VAT_URL,
} from './constants';
import moment from 'moment';
import { NumericFormat, numericFormatter } from 'react-number-format';
import { StoreApi, UseBoundStore } from 'zustand';
import { PATIENT_ADDRESS_TYPE, PATIENT_CONTACT_TYPE } from './apiConstants';
import { StorageResource } from 'modules/schema';
import { Moment } from 'moment';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ArticleIcon from '@mui/icons-material/Article';

export function extractOrganizationId(json: some) {
  const organizationRef: string = json.entry[0]?.resource.organization.reference;

  const organizationId = organizationRef.substring(organizationRef.lastIndexOf('/'));

  return organizationId;
}

export function useDebounce(value: string, delay: number) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export function getId(reference: string) {
  if (!reference) {
    return reference;
  }
  const start = reference.indexOf('/') + 1;
  return reference.substring(start);
}

export function getIdFromTransactionLocation(location: string) {
  const startIndex = location.indexOf('/') + 1;
  const endIndex = location.indexOf('/', startIndex);

  return location.substring(startIndex, endIndex);
}

export function roundNumberProp(object: some) {
  const keys = Object.keys(object);
  for (let key of keys) {
    const value = object[key];
    if (typeof value === 'number') {
      object[key] = Math.round(value);
    }
  }
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function displayRange(high, low) {
  if (high === undefined && low === undefined) {
    return '';
  }

  if (high === undefined) {
    return `≥ ${low}`;
  }
  if (low === undefined) {
    return `≤ ${high}`;
  }

  return `${low} - ${high}`;
}

export function displayRangeCompare(high, low) {
  if (high === undefined && low === undefined) {
    return '';
  }

  if (high === undefined) {
    return `≥ ${low}`;
  }
  if (low === undefined) {
    return `≤ ${high}`;
  }

  return `${low} ≤ ${high}`;
}

export function getInterpretationFromObservation(observation?: some) {
  let interpretation: any = null;
  const node = findDeep(
    observation?.interpretation,
    (val) => val && val.system === 'http://terminology.hl7.org/CodeSystem/v3-ObservationInterpretation',
    { leavesOnly: false },
  );
  if (node?.value?.code === 'L') {
    interpretation = VALUE_INTERPRETATION.low;
  } else if (node?.value?.code === 'H') {
    interpretation = VALUE_INTERPRETATION.high;
  } else if (node?.value?.code === 'A') {
    interpretation = VALUE_INTERPRETATION.abnormal;
  } else if (node?.value?.code === 'N') {
    interpretation = VALUE_INTERPRETATION.normal;
  }
  return interpretation;
}

export function getChargeItemInfo(
  chargeItem: some,
  medicationMapper: Map<string, some>,
  deviceMapper: Map<string, some>,
  healthcareServiceMapper: Map<string, some>,
  activityDefinitionMapper: Map<string, some>,
) {
  const code = chargeItem.code;
  let name = '';
  let healthcareServiceEncounterId = '';
  let serviceRequestId = '';
  let chargeItemType = '';
  let regCode = '';
  if (
    !!findDeep(
      code,
      (val) => {
        return val?.system === 'http://hl7.org/fhir/payment-categoriezed-drug' && val?.code === '0';
      },
      { leavesOnly: false },
    )
  ) {
    // insured medication charge item
    const medicationRef = chargeItem.productReference.reference;
    const medication = medicationMapper.get(medicationRef);
    name = medication?.code.coding[0]?.display;
    chargeItemType = CHARGE_ITEM_TYPES.drug;
    regCode = medication?.identifier[0]?.value;
  } else if (
    !!findDeep(
      code,
      (val) => {
        return val?.system === 'http://hl7.org/fhir/drug-list-type' && val?.code === '2';
      },
      { leavesOnly: false },
    )
  ) {
    // medication used charge item
    const medicationRef = chargeItem.productReference.reference;
    const medication = medicationMapper.get(medicationRef);
    name = medication?.code.coding[0]?.display;
    chargeItemType = CHARGE_ITEM_TYPES.drug;
    regCode = medication?.identifier[0]?.value;
  } else if (
    !!findDeep(
      code,
      (val) => {
        return val?.system === 'http://snomed.info/sct' && val?.code === '57134006';
      },
      { leavesOnly: false },
    )
  ) {
    // medical supply charge item
    const deviceRef = chargeItem.productReference.reference;
    const device = deviceMapper.get(deviceRef);

    name = device?.deviceName[0]?.name;
    chargeItemType = CHARGE_ITEM_TYPES.supply;
  } else if (
    !!findDeep(
      code,
      (val) => {
        return val?.system === 'http://snomed.info/sct' && val?.code === '224891009';
      },
      { leavesOnly: false },
    )
  ) {
    const healthcareServiceRef = chargeItem.supportingInformation[0]?.reference;
    const healthcareService = healthcareServiceMapper.get(healthcareServiceRef);

    name = healthcareService?.name;
    healthcareServiceEncounterId = getId(chargeItem.context.reference);
    chargeItemType = CHARGE_ITEM_TYPES.healthcareService;
    regCode = healthcareService?.identifier?.[0]?.value;
  } else {
    // other type: indication, procedure
    const activityDefRef = findDeep(
      chargeItem.supportingInformation,
      (val) => typeof val === 'string' && val.startsWith('ActivityDefinition'),
    )?.value;
    const activityDef = activityDefinitionMapper.get(activityDefRef);

    name = activityDef?.title;
    regCode = activityDef?.identifier[0].value;
    if (
      !!findDeep(
        code,
        (val) => {
          return val?.system === 'http://snomed.info/sct' && val?.code === '387713003';
        },
        { leavesOnly: false },
      )
    ) {
      chargeItemType = CHARGE_ITEM_TYPES.procedure;
    } else if (
      !!findDeep(
        code,
        (val) => {
          return val?.system === 'http://snomed.info/sct' && val?.code === '15220000';
        },
        { leavesOnly: false },
      )
    ) {
      serviceRequestId = getId(chargeItem.supportingInformation[0]?.reference);
      chargeItemType = CHARGE_ITEM_TYPES.labTest;
    } else if (
      !!findDeep(
        code,
        (val) => {
          return val?.system === 'http://snomed.info/sct' && val?.code === '363679005';
        },
        { leavesOnly: false },
      )
    ) {
      serviceRequestId = getId(chargeItem.supportingInformation[0]?.reference);
      chargeItemType = CHARGE_ITEM_TYPES.imageTest;
    } else if (
      !!findDeep(
        code,
        (val) => {
          return val?.system === 'http://snomed.info/sct' && val?.code === '394841004';
        },
        { leavesOnly: false },
      )
    ) {
      serviceRequestId = getId(chargeItem.supportingInformation[0]?.reference);
      chargeItemType = CHARGE_ITEM_TYPES.otherService;
    }
  }

  return { name, healthcareServiceEncounterId, serviceRequestId, chargeItemType, regCode };
}

export function getUnitPrice(chargeItem: some, account: some) {
  const insuredPrice =
    findDeep(chargeItem.extension, (val) => val?.url === SS_PAY_URL, { leavesOnly: false })?.value.valueMoney.value ||
    0;

  const deductionPrice =
    findDeep(chargeItem.extension, (val) => val?.url === PATIENT_DE_URL, { leavesOnly: false })?.value.valueMoney
      .value || 0;
  const copay =
    findDeep(chargeItem.extension, (val) => val?.url === PATIENT_PAY_URL, { leavesOnly: false })?.value.valueMoney
      .value || 0;
  const taxRate = findDeep(chargeItem.extension, (val) => val?.url === VAT_URL, { leavesOnly: false })?.value
    .valueDecimal;

  const discountValue = findDeep(chargeItem.extension, (val) => val?.url === DISCOUNT_AMOUNT_URL, { leavesOnly: false })
    ?.value.valueMoney.value;

  const discountRatioValue = Math.round(
    (findDeep(chargeItem.extension, (val) => val?.url === DISCOUNT_RATE_URL, {
      leavesOnly: false,
    })?.value.valueDecimal || 0) * 100,
  );
  const allDiscountRate = findDeep(account.extension, (val) => val?.url === ALL_DISCOUNT_RATE_URL, {
    leavesOnly: false,
  })?.value.valueDecimal;

  const discountType = discountValue === undefined ? 'ratio' : 'price';

  const unitPrice = copay + deductionPrice + insuredPrice;

  const discountPrice = discountType === 'ratio' ? (unitPrice * (discountRatioValue || 0)) / 100 : discountValue || 0;

  // all DOES NOT mean all type of discounts. It means the discount for all items in the encounter
  const allDiscountPrice = unitPrice * (allDiscountRate || 0);
  const preTaxPrice = unitPrice - discountPrice - allDiscountPrice;
  const patientPayTaxPrice = taxRate !== undefined ? taxRate * preTaxPrice : null;
  const patientPayPrice = unitPrice + patientPayTaxPrice - discountPrice - allDiscountPrice;
  return {
    unitPrice,
    insuredPrice,
    deductionPrice,
    copay,
    patientPayPrice,
    discountRatioValue,
    discountValue,
    discountType,
    discountPrice,
    allDiscountPrice,
    taxRate,
    preTaxPrice,
  };
}

export function checkUnitPrice(list: some[], allDiscount?: number): string[] {
  if (!allDiscount) {
    return [];
  }
  const retval: string[] = [];
  for (let one of list) {
    const discountPrice =
      one.discountType === 'ratio' ? (one.unitPrice * (one.discountRatioValue || 0)) / 100 : one.discountValue || 0;
    const allDiscountPrice = (one.unitPrice * (allDiscount || 0)) / 100;

    const preTaxPrice = one.unitPrice - discountPrice - allDiscountPrice;
    if (preTaxPrice < 0) {
      retval.push(one.name);
    }
  }
  return retval;
}

export const validateNotFutureDate = (value, intl) => {
  if (value) {
    if (!moment(value, BE_DATE_FORMAT, true).isValid()) {
      return intl.formatMessage({ id: 'validation.invalidDate' });
    }
    if (!moment(value).isBefore(moment())) {
      return intl.formatMessage({ id: 'calendar.dateInvalidFuture' });
    }
    return null;
  }
};

export const validateDateRange = (fromDate, toDate, intl) => {
  if (fromDate && toDate && moment(fromDate).isAfter(moment(toDate))) {
    return intl.formatMessage({ id: 'validation.fromDateAfterToDate' });
  }
  return null;
};

export function waitForElementToExist(selector): Promise<HTMLElement> {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        resolve(document.querySelector(selector));
        observer.disconnect();
      }
    });

    observer.observe(document.body, {
      subtree: true,
      childList: true,
    });
  });
}

// Numeric Format Text
export const NumericFormatText = (value) => {
  return <NumericFormat thousandSeparator valueIsNumericString displayType="text" value={value} decimalScale={2} />;
};

export const numericFormat = (value: number | string) => {
  const numStr = typeof value === 'string' ? value : String(value);
  return numericFormatter(numStr, {
    thousandSeparator: ',',
    decimalScale: 2,
  });
};

export const formatDateFilterField = (data, startDateFieldName, endDateFieldName) => {
  if (!data) return null;
  return {
    ...data,
    [startDateFieldName]:
      data[startDateFieldName] && moment(data[startDateFieldName]).startOf('day').utc().format(BE_DATE_TIME_FORMAT),
    [endDateFieldName]:
      data[endDateFieldName] && moment(data[endDateFieldName]).endOf('day').utc().format(BE_DATE_TIME_FORMAT),
  };
};

export function formatStartDate<T extends moment.MomentInput, R = T extends undefined ? undefined : string>(
  startDate: T,
): R {
  if (!startDate) {
    return undefined as unknown as R;
  }
  return moment(startDate).startOf('day').utc().format(BE_DATE_TIME_FORMAT) as unknown as R;
}

export function formatEndDate<T extends moment.MomentInput, R = T extends undefined ? undefined : string>(endDate: T) {
  if (!endDate) {
    return undefined as unknown as R;
  }
  return moment(endDate).endOf('day').utc().format(BE_DATE_TIME_FORMAT) as unknown as R;
}

export const formatOption = (option: { value: string; label: string }) => {
  if (!option) return null;
  return option?.label + '|' + option?.value;
};

export const getText = (html) => {
  var divContainer = document.createElement('div');
  divContainer.innerHTML = html;
  return divContainer.textContent || divContainer.innerText || '';
};

type WithSelectors<S> = S extends { getState: () => infer T } ? S & { use: { [K in keyof T]: () => T[K] } } : never;

export const createSelectors = <S extends UseBoundStore<StoreApi<object>>>(_store: S) => {
  let store = _store as WithSelectors<typeof _store>;
  store.use = {};
  for (let k of Object.keys(store.getState())) {
    (store.use as any)[k] = () => store((s) => s[k as keyof typeof s]);
  }

  return store;
};

export const mergeDateAndTime = (time: string, date: string) => {
  return moment(`${time} ${date}`, `${FE_TIME_FORMAT} ${BE_DATE_FORMAT}`);
};

export const formatToShortNumber = (number: number, locale: string = 'vi') => {
  const formater = Intl.NumberFormat(locale, { notation: 'compact' });
  return formater.format(number);
};

export const formatPatientFormData = (formData) => {
  const currentYear = moment().year();
  const dob = moment(formData?.age ? new Date(currentYear - Math.abs(formData?.age), 0, 1) : formData?.dob);
  return {
    note: formData?.note,
    dob: dob.format(BE_DATE_FORMAT),
    email: formData.email,
    ethnic: formData?.ethnic?.label,
    gender: formData?.gender?.toUpperCase(),
    identifierCode: formData?.identifierCode,
    job: formData?.job,
    name: formData?.name,
    nationality: formData?.nationality?.label,
    mobilePhone: formData?.mobilePhone,
    patientAddressList: [
      {
        address: formData?.jobAddress,
        type: PATIENT_ADDRESS_TYPE.WORK,
      },
      {
        address: formData?.address,
        district: formatOption(formData?.district),
        province: formatOption(formData?.province),
        type: PATIENT_ADDRESS_TYPE.HOME,
      },
    ],
    patientContactInfoList: [
      {
        type: PATIENT_CONTACT_TYPE.PERSON_IN_CONTACT,
        address: formData?.contactAddress,
        name: formData?.contactName,
        mobilePhone:
          parseInt(formData?.contactTelephone) < 0 ? formData?.contactTelephone.slice(1) : formData?.contactTelephone,
      },
    ],
  };
};

// render icon file
export const renderIconFile = (data: StorageResource) => {
  switch (data?.fileName?.split('.')?.pop()?.toLowerCase()) {
    case 'pdf':
      return <PictureAsPdfIcon color="primary" />;
    case 'doc':
    case 'docx':
      return <ArticleIcon color="primary" />;
    case 'jpeg':
    case 'jpg':
    case 'png':
      return <ImageIcon color="primary" />;
    default:
      return <AttachFileIcon color="primary" />;
  }
};

export async function convertToFile(url: string, fileName: string, type?: string) {
  return fetch(url)
    .then((res) => res.blob())
    .then(
      (blob) =>
        new File([blob], fileName, {
          lastModified: new Date().getTime(),
          type: type,
        }),
    );
}

export function calculateBMI(bodyWeight: number, bodyHeight: number): number {
  if (bodyHeight === 0) {
    return NaN;
  }
  return bodyWeight / ((bodyHeight / 100) * (bodyHeight / 100));
}

export function getDateRangeList(
  fromDate: moment.Moment,
  toDate: moment.Moment,
  period: moment.unitOfTime.DurationConstructor,
) {
  const result: Moment[] = [];
  const temp = fromDate.clone();
  while (temp.isSameOrBefore(toDate, period)) {
    result.push(temp.clone());

    switch (period) {
      case 'month':
        temp.add(1, period).startOf('month');
        break;
      case 'year':
        temp.add(1, period).startOf('year');
        break;
      default:
        temp.add(1, period);
        break;
    }
  }
  return result;
}

// validate filter date report
export const isValidDate = (fromDate: string, toDate: string, type: string) => {
  if (fromDate && toDate && moment(fromDate) < moment(toDate)) {
    if (
      type === 'TABLE' &&
      moment(toDate) < moment().add(+1, 'day') &&
      moment(toDate) <
        moment(fromDate)
          .add(+1, 'year')
          .add(+1, 'day')
    ) {
      return true;
    }
    if (type !== 'TABLE') {
      return true;
    }
    return false;
  }
  return false;
};

// validate max date report chart
export const maxDate = (timeReportType: string, fromDate: string, fromDateOrigin: string, toDateOrigin: string) => {
  if (
    timeReportType === 'DAY' &&
    (moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'month') ||
      moment(toDateOrigin) < moment(fromDateOrigin).add(+1, 'month')) &&
    moment(fromDateOrigin).add(+1, 'month') < moment()
  ) {
    return moment(fromDate)
      .add(+1, 'month')
      .add(+1, 'day');
  }
  if (
    timeReportType === 'MONTH' &&
    (moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'year') ||
      moment(toDateOrigin) < moment(fromDateOrigin).add(+1, 'year')) &&
    moment(fromDateOrigin).add(+1, 'year') < moment()
  ) {
    return moment(fromDate)
      .add(+1, 'year')
      .add(+1, 'day');
  }
  if (
    timeReportType === 'WEEK' &&
    (moment(fromDateOrigin) < moment(toDateOrigin).add(-3, 'months') ||
      moment(toDateOrigin) < moment(fromDateOrigin).add(+3, 'months')) &&
    moment(fromDateOrigin).add(+3, 'months') < moment()
  ) {
    return moment(fromDate)
      .add(+3, 'months')
      .add(+1, 'day');
  }
  return moment();
};

// validate max date report table
export const maxDateTable = (fromDate: string, fromDateOrigin: string, toDateOrigin: string) => {
  if (
    (moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'year') ||
      moment(toDateOrigin) < moment(fromDateOrigin).add(+1, 'year')) &&
    moment(fromDateOrigin).add(+1, 'year') < moment()
  ) {
    return moment(fromDate)
      .add(+1, 'year')
      .add(+1, 'day');
  }
  return moment();
};
