import * as React from 'react';
import { IndicationHistory } from 'modules/schema';
import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { FE_DATE_FORMAT } from 'modules/common/constants';

interface Props {
  indicationHistories: IndicationHistory[];
  unit: string;
}

const IndexGraph: React.FunctionComponent<Props> = ({ indicationHistories, unit }) => {
  const options = {
    chart: {
      type: 'line',
    },
    title: {
      text: '',
    },
    subtitle: {
      text: unit,
      align: 'left',
    },
    series: [
      {
        data: indicationHistories?.map((history) => parseFloat(history?.result || '0')),
      },
    ],
    yAxis: {
      title: {
        enabled: false,
      },
    },
    xAxis: {
      categories: indicationHistories?.map((history) => history?.dateTimeFinish?.format(FE_DATE_FORMAT)),
      title: {
        enabled: false,
      },
    },
    legend: {
      enabled: false,
    },
  };
  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default IndexGraph;
