import { OutlinedTextFieldProps, TextField, TextFieldProps } from '@mui/material';
import React, { memo } from 'react';

export interface Props extends Omit<OutlinedTextFieldProps, 'variant'> {
  id?: string;
  required?: boolean;
  variant?: TextFieldProps['variant'];
}

const FreeTextField = React.forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { id, required, ...rest } = props;

  return (
    <TextField
      ref={ref}
      inputProps={{
        autoComplete: 'off',
      }}
      size="small"
      fullWidth
      variant="outlined"
      {...rest}
      InputLabelProps={{
        shrink: true,
        required,
      }}
    />
  );
});

export default memo(FreeTextField);
