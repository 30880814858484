import { Dialog } from '@mui/material';
import * as React from 'react';
import { some } from '../../../../common/constants';
import PatientFormContent from './PatientFormContent';

interface Props {
  open: boolean;
  onClose: () => void;
  formData?: some;
  mode: 'view' | 'edit';
  onSubmit: (formVal: some) => void;
}

const PatientDialog = ({ open, onClose, formData, mode, onSubmit }: Props) => {
  const formDataCustom = React.useMemo(() => {
    return {
      ...formData,
      age: null,
    };
  }, [formData]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      keepMounted={false}
      PaperProps={{
        style: { position: 'relative', overflow: 'hidden', minWidth: 900 },
      }}
    >
      <PatientFormContent open={open} onClose={onClose} formData={formDataCustom} mode={mode} onSubmit={onSubmit} />
    </Dialog>
  );
};

export default PatientDialog;
