import { Box, Button, Card, Grid, Stack, Typography } from '@mui/material';
import Report from 'modules/common/component/report';
import Filter from 'modules/common/component/filter';
import { useMemo, useRef, useState } from 'react';
import DateInput from 'modules/common/component/form/DateInput';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';
import { DEFAULT_VALUES, FILTER_CONFIGS } from './const';
import {
  NumericFormatText,
  formatEndDate,
  formatStartDate,
  isValidDate,
  maxDateTable,
  numericFormat,
} from 'modules/common/utils';
import SelectInput from 'modules/common/component/form/SelectInput';
import { PAYMENT_STATUS, SERVICE_TYPE_ALL } from 'modules/common/apiConstants';
import { Patient } from 'modules/schema';
import { FormattedMessage, useIntl } from 'react-intl';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { ValueType } from 'modules/common/type';
import ReactToPrint from 'react-to-print';
import { Print } from '@mui/icons-material';
import MediCard from 'modules/common/component/MediCard';
import TableCustom from 'modules/common/component/TableCustom';
import { BUSINESS_API } from 'modules/common/service';

export const FILTER_KEY = 'REVENUE_CUSTOMER_REPORT_CHART_FILTER';
export const SELECT_COLUMNS_KEY = 'REVENUE_CUSTOMER_REPORT_CHART_SELECT_COLUMNS';

interface Props {
  typeOption: ValueType<typeof SERVICE_TYPE_ALL>[];
}
interface FormSchema {
  type?: ValueType<typeof SERVICE_TYPE_ALL>;
  fromDate: string;
  toDate: string;
  keyword?: string;
  paymentStatusList?: (keyof typeof PAYMENT_STATUS)[];
}

const RevenueChartCustomer: React.FunctionComponent<Props> = ({ typeOption }: Props) => {
  const refPrintComponent = useRef(null);
  const intl = useIntl();
  const [patientRevenueList, setPatientRevenueList] = useState<Patient[]>();
  const [tableTitle, setTableTitle] = useState<string>('therapy.report.revenue.table.header.revenueDetailAll');
  const [searchParams, setSearchParams] = useState<FormSchema>({
    fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
    toDate: formatEndDate(moment().add(+1, 'day')),
    keyword: '',
  });

  const form = useForm<FormSchema>({
    defaultValues: { ...DEFAULT_VALUES, type: typeOption[0] },
  });

  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate =
    moment(form.watch('toDate')) < moment()
      ? formatEndDate(moment(form.watch('toDate')).add(-1, 'day'))
      : formatEndDate(form.watch('toDate'));
  const type = form.watch('type');

  // get data revenue customer group
  const { data: reportData } = useFetch(
    BUSINESS_API.getRevenueChartPatient({
      ...searchParams,
      type: type?.value ? type?.value : typeOption[0].value,
      page: 0,
      pageSize: 100000,
      fromDate,
      toDate,
    }),
    {
      globalLoading: true,
      revalidateOnFocus: false,
      enabled: isValidDate(fromDate, toDate, 'TABLE'),
    },
  );

  const getChartColor = (index) => {
    if (index === 0) {
      return '#EF5350';
    } else if (index === 1) {
      return '#FFC107';
    } else if (index === 2) {
      return '#43A047';
    } else if (index === 3) {
      return '#307BFF';
    } else if (index === 4) {
      return '#546E7A';
    } else if (index === 5) {
      return '#CDDFFF';
    } else return '#EF5350';
  };

  // map chart data revenue customer group
  const mapData =
    reportData?.map((ele, index) => {
      const fromAmount: number = +(ele.fromAmountPaid || 0);
      const toAmount: number = +(ele.toAmountPaid || 0);
      const name =
        fromAmount === 0
          ? '≤ ' + numericFormat(toAmount) + ' '
          : numericFormat(fromAmount) + ' - ' + numericFormat(toAmount) + ' ';
      return {
        ...ele,
        name,
        y: ele.percent,
        sliced: true,
        selected: true,
        color: getChartColor(index),
      };
    }) || [];

  const listAll = reportData?.reduce<Patient[]>((val, cur) => {
    return [...val, ...(cur.patientList || [])];
  }, []);

  const tableDatasource = useMemo(() => {
    return patientRevenueList && patientRevenueList?.length > 0 ? patientRevenueList : listAll;
  }, [patientRevenueList, listAll]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title title="report.revenue.chart.customer" />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={() => {
          setSearchParams((prev) => ({
            ...prev,
          }));
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                clearButton
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
                type="TABLE"
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                maxDate={maxDateTable(fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                type="TABLE"
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton onClick={() => form.reset()} />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Stack direction="column" gap={1}>
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" color="primary" startIcon={<Print />}>
                    <FormattedMessage id={'printReport'} />
                  </Button>
                )}
                content={() => refPrintComponent.current}
              />
            </Stack>
          </Stack>
        </Stack>
        <Grid container ref={refPrintComponent}>
          <Grid item xs={8}>
            <Card variant="outlined" style={{ margin: '10px' }}>
              <Box marginTop="20px">
                <Report.PieChart
                  xAxisTitle="report.revenue.chart.title.group.customer"
                  yAxisTitle="therapy.label.revenuePercent"
                  series={[
                    {
                      type: 'pie',
                      name: intl.formatMessage({
                        id: 'therapy.label.revenuePercent',
                      }),
                      data: mapData,
                      point: {
                        events: {
                          click: function (event) {
                            if (!this.selected) {
                              const options: any = this.options;
                              setPatientRevenueList(options?.patientList || []);
                              setTableTitle('therapy.report.revenue.table.header.revenueDetail');
                            } else {
                              setPatientRevenueList([]);
                              setTableTitle('therapy.report.revenue.table.header.revenueDetailAll');
                            }
                          },
                        },
                      },
                    },
                  ]}
                />
              </Box>
            </Card>
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              '>div, table': { height: '100%' },
            }}
          >
            <MediCard title={tableTitle} style={{ marginTop: '9px', overflow: 'scroll', position: 'relative' }}>
              <Box marginTop="10px" maxHeight="375px" minHeight="375px">
                <TableCustom
                  dataSource={tableDatasource}
                  boxProps={{ sx: { position: 'relative' } }}
                  columns={[
                    {
                      dataIndex: 'name',
                      title: 'therapy.label.customerName',
                      headerProps: {
                        sx: {
                          position: 'sticky',
                        },
                      },
                    },
                    {
                      title: 'therapy.cashier.dialog.label.paymentHistory.amountPaid',
                      render: (record) => {
                        return <Typography variant="body1">{NumericFormatText(record?.amountPaid || 0)}</Typography>;
                      },
                      headerProps: {
                        sx: {
                          position: 'sticky',
                        },
                      },
                    },
                  ]}
                />
              </Box>
            </MediCard>
          </Grid>
        </Grid>
      </Filter>
    </Stack>
  );
};

export default RevenueChartCustomer;
