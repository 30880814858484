import { AssignmentTurnedIn, Edit, NoteAdd } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PrintIcon from '@mui/icons-material/Print';
import { Checkbox, Collapse } from '@mui/material';
import { Box } from '@mui/system';
import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { TableCustomCell, TableCustomRow } from '../../../common/component/TableCustom/element';
import { FE_DATE_FORMAT, INDICATION_REQUEST_STATUS, INDICATION_REQUEST_TYPE } from '../../../common/constants';
import DialogLabDoctorIndication from '../../page/DialogLabDoctorIndication';
import DocumentDialog from './DocumentDialog';
import { API_SERVER } from 'modules/common/api';
import { Encounter, IndicationRequest } from 'modules/schema';
import { useFetch, useDialog, useUpdateMutate } from 'modules/common/hook';
import { ENCOUNTER_STATUS, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { ROUTES } from 'modules/layout/router';
import { push } from 'connected-react-router';
import IndicationPrintForm from '../indication/EncounterIndicationPrintForm';

interface Props {
  encounter: Encounter;
  columns: Columns[];
}
const EncounterRow: React.FunctionComponent<Props> = (props) => {
  const { encounter, columns } = props;
  const { dispatch, intl } = useGeneralHook();
  const [selectedIndication, setSelectedIndication] = React.useState<IndicationRequest | undefined>(undefined);
  const [open, setOpen] = React.useState(false);
  const [openEditIndicationDialog, onOpenEditIndicationDialog, onCloseEditIndicationDialog] = useDialog();
  const [openAttachmentsDialog, onOpenAttachmentsDialog, onCloseAttachmentsDialog] = useDialog();
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const [selectedIndications, setSelectedIndications] = React.useState<IndicationRequest[] | undefined>(undefined);

  const {
    data: indicationRequests,
    isValidating,
    revalidate,
  } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(
      encounter?.id!,
      INDICATION_REQUEST_TYPE.ENCOUNTER,
      SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
    ),
    { enabled: open },
  );

  React.useEffect(() => {
    if (indicationRequests && indicationRequests.length > 0) {
      setSelectedIndications(
        indicationRequests?.filter((indication) => indication.status === INDICATION_REQUEST_STATUS.FINISHED.value),
      );
    }
  }, [indicationRequests]);
  const onCompleteMutate = useUpdateMutate({ onSuccess: revalidate });

  const COLUMNS = React.useMemo(
    () =>
      [
        {
          title: 'labDoctor.testName',
          render: (record) => record?.serviceName,
        },
        {
          title: 'indicationDate',
          render: (record) => record?.createdTime?.format(FE_DATE_FORMAT),
        },
        {
          title: 'doctorOfIndication',
          render: (record) => record?.picName,
        },
        {
          title: 'interpreterOfIndication',
          render: (record) => record?.executeName,
        },
        {
          title: 'status',
          dataIndex: 'status',
          render: (record) => (
            <Box component="span" color={INDICATION_REQUEST_STATUS?.[record.status!]?.color}>
              <FormattedMessage id={INDICATION_REQUEST_STATUS[record?.status!]?.label} />
            </Box>
          ),
        },
        {
          title: 'action',
          width: 100,
          align: 'center',
          render: (record) => (
            <>
              <IconButtonTitle
                size="small"
                title="update"
                onClick={(e) => {
                  e.stopPropagation();
                  setSelectedIndication(record);
                  onOpenEditIndicationDialog();
                }}
              >
                <Edit />
              </IconButtonTitle>
              <IconButtonTitle
                title="complete"
                onClick={(e) => {
                  e.stopPropagation();
                  onCompleteMutate(
                    {
                      url: API_SERVER.indicationRequest.finishIndicationRequest(record?.id!),
                      method: 'PUT',
                    },
                    { confirmMessage: intl.formatMessage({ id: 'confirmComplete' }, { name: record?.serviceName }) },
                  );
                }}
                disabled={record.status === INDICATION_REQUEST_STATUS.FINISHED.value}
              >
                <AssignmentTurnedIn />
              </IconButtonTitle>
            </>
          ),
        },
      ] as Columns<IndicationRequest>[],
    [intl, onCompleteMutate, onOpenEditIndicationDialog],
  );

  return (
    <>
      <TableCustomRow>
        <TableCustomCell style={{ width: 50 }}>
          <IconButtonTitle
            aria-label="expand data"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButtonTitle>
        </TableCustomCell>
        {columns.map((column, index) => {
          return (
            <TableCustomCell key={index} align={column.align}>
              {column.render ? column.render(encounter, index) : get(encounter, column.dataIndex || 0)}
            </TableCustomCell>
          );
        })}
        <TableCustomCell>{encounter?.indicationRequestQuantity || 0}</TableCustomCell>
        <TableCustomCell style={{ width: 100 }} align="center">
          <IconButtonTitle
            title="addFile"
            size="small"
            onClick={onOpenAttachmentsDialog}
            disabled={
              encounter.status !== ENCOUNTER_STATUS.INPROGRESS.value ||
              (encounter?.indicationRequestQuantity ? encounter?.indicationRequestQuantity === 0 : true)
            }
          >
            <NoteAdd />
          </IconButtonTitle>
          <IconButtonTitle
            disabled={!encounter.indicationRequestFinishedQuantity}
            aria-label="expand data"
            title="print"
            size="small"
            onClick={onOpenPrintDialog}
          >
            <PrintIcon />
          </IconButtonTitle>
        </TableCustomCell>
      </TableCustomRow>
      <TableCustomRow style={{ padding: 0 }}>
        <TableCustomCell style={{ padding: 0, border: 'none' }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} bgcolor="white">
              <TableCustom
                loading={isValidating}
                dataSource={indicationRequests?.filter((item) => item.status !== INDICATION_REQUEST_STATUS.WAIT.value)}
                containerProps={{
                  sx: {
                    '& th,tr,td': {
                      padding: '4px 8px',
                      backgroundColor: 'unset',
                      '&:hover': {
                        backgroundColor: 'unset',
                      },
                    },
                    overflow: 'visible',
                    minWidth: 220,
                  },
                }}
                rowProps={(record) => ({
                  onClick: async (e) => {
                    dispatch(push(ROUTES.detailIndication.gen(record.id!, record.patientId!, record.referenceId!)));
                  },
                  style: { cursor: 'pointer' },
                })}
                rowSelection={{
                  selectedRowKeys: selectedIndications,
                  hidden: (record: IndicationRequest) => record.status !== INDICATION_REQUEST_STATUS.FINISHED.value,
                  onChange: ({ selectedRows }) => setSelectedIndications(selectedRows),
                  render: ({ record, selected, onChange }) =>
                    record.status === INDICATION_REQUEST_STATUS.FINISHED.value && (
                      <Checkbox
                        style={{ padding: 0 }}
                        checked={selected}
                        color="primary"
                        onChange={(e) => onChange(e.target.checked)}
                      />
                    ),
                }}
                columns={COLUMNS}
              />
            </Box>
          </Collapse>
        </TableCustomCell>
      </TableCustomRow>
      {selectedIndication && (
        <DialogLabDoctorIndication
          encounter={encounter}
          indicationRequest={selectedIndication}
          open={openEditIndicationDialog}
          onClose={onCloseEditIndicationDialog}
          revalidateIndicationList={() => {}}
        />
      )}
      {encounter && (
        <>
          <DocumentDialog encounter={encounter} open={openAttachmentsDialog} onClose={onCloseAttachmentsDialog} />
          <IndicationPrintForm
            encounter={encounter}
            onClose={onClosePrintDialog}
            open={openPrintDialog}
            selectedIndications={selectedIndications}
          />
        </>
      )}
    </>
  );
};
export default EncounterRow;
