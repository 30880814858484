import { mapValues } from 'lodash';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { z } from 'zod';

export const SEARCH_PARAMS_VALUE_TYPE = {
  ARRAY: 'ARRAY',
  SINGLE: 'SIGGLE',
};

export const SearchParamsSchema = {
  string: z.string().default('').catch('').describe(SEARCH_PARAMS_VALUE_TYPE.SINGLE),
  number: z
    .preprocess((val) => parseInt(String(val)), z.number())
    .default(NaN)
    .catch(NaN)
    .describe(SEARCH_PARAMS_VALUE_TYPE.SINGLE),
  stringArray: z.array(z.string()).default([]).catch([]).describe(SEARCH_PARAMS_VALUE_TYPE.ARRAY),
  numberArray: z
    .array(
      z
        .preprocess((val) => parseInt(String(val)), z.number())
        .default(NaN)
        .catch(NaN),
    )
    .catch([])
    .default([])
    .transform((value) => value.filter((ele) => !Number.isNaN(ele)))
    .describe(SEARCH_PARAMS_VALUE_TYPE.ARRAY),
};

export type SearchParamsTypeSchema =
  | typeof SearchParamsSchema.number
  | typeof SearchParamsSchema.numberArray
  | typeof SearchParamsSchema.string
  | typeof SearchParamsSchema.stringArray;

export type SearchParamsValue = string | number | string[] | number[];

type SearchParams = Record<string, SearchParamsValue>;

const useURL = () => {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const parseSearchParams = useCallback(
    (searchSchema: Record<string, SearchParamsTypeSchema>) => {
      return mapValues(searchSchema, (schema, key) => {
        if (schema.description === SEARCH_PARAMS_VALUE_TYPE.SINGLE) {
          return schema.parse(urlSearchParams.get(key));
        }
        return schema.parse(urlSearchParams.getAll(key));
      });
    },
    [urlSearchParams],
  );

  const addSearchParams = useCallback(
    (searchParams: SearchParams) => {
      Object.keys(searchParams).forEach((key) => {
        const value = searchParams[key];
        if (typeof value === 'string') {
          urlSearchParams.append(key, value);
        } else if (typeof value === 'number') {
          urlSearchParams.append(key, String(value));
        } else {
          value.forEach((ele: string | number) => urlSearchParams.append(key, String(ele)));
        }
      });
      history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
    },
    [history, location.pathname, urlSearchParams],
  );
  const removeSearchParams = useCallback(
    (searchParamKeys: string[]) => {
      searchParamKeys.forEach((key) => urlSearchParams.delete(key));
      history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
    },
    [history, location.pathname, urlSearchParams],
  );
  const replaceSearchParams = useCallback(
    (searchParams: SearchParams) => {
      Object.keys(searchParams).forEach((key) => {
        urlSearchParams.delete(key);
        const value = searchParams[key];
        if (typeof value === 'string') {
          urlSearchParams.append(key, value);
        } else if (typeof value === 'number') {
          urlSearchParams.append(key, String(value));
        } else {
          value.forEach((ele: string | number) => urlSearchParams.append(key, String(ele)));
        }
      });
      history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
    },
    [history, location.pathname, urlSearchParams],
  );

  const removeAllSearchParams = useCallback(() => {
    history.push({ pathname: location.pathname, search: '' });
  }, [history, location.pathname]);

  return {
    history,
    location,
    urlSearchParams,
    addSearchParams,
    removeSearchParams,
    replaceSearchParams,
    parseSearchParams,
    removeAllSearchParams,
  };
};

export default useURL;
