import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { DENTAL_DIGAGNOSIS_BASIC_TYPE } from 'modules/common/constants';

export const DentalDiagnosisBasicSchema = z
  .object({
    id: z.number(),
    patientId: z.number(),
    dentalExamId: z.number(),
    name: z.string(),
    type: createObjectKeysEnumSchema(DENTAL_DIGAGNOSIS_BASIC_TYPE),
  })
  .partial();

export type DentalDiagnosisBasic = z.infer<typeof DentalDiagnosisBasicSchema>;
