import { Close } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

export interface NumberFieldCustomElementProps extends Omit<TextFieldProps, 'defaultValue' | 'onChange'> {
  id?: string;
  defaultValue?: any;
  readOnly?: boolean;
  showClearBtn?: boolean;
  onChange?: any;
}

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

// format number phan nghin, so thap phan
const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      valueIsNumericString
      // prefix="$"
    />
  );
});

const NumberFieldCustomElement = React.forwardRef<HTMLDivElement, NumberFieldCustomElementProps>(
  (props: NumberFieldCustomElementProps, ref: any) => {
    const { id, readOnly, required, showClearBtn, value, onChange, sx, ...rest } = props;

    return (
      <TextField
        ref={ref}
        inputProps={{
          autoComplete: 'off',
        }}
        size="small"
        fullWidth
        variant="outlined"
        {...rest}
        sx={{
          '&:hover': {
            '& .clear-btn': {
              display: 'flex',
            },
          },
          ...sx,
        }}
        value={value}
        onChange={onChange}
        InputProps={{
          readOnly: readOnly,
          disabled: rest.disabled,
          ...rest?.InputProps,
          endAdornment: (
            <>
              {value && !readOnly && !rest.disabled && showClearBtn ? (
                <InputAdornment position="end" sx={{ display: 'none' }} className="clear-btn">
                  <IconButton size="small" onClick={() => onChange && onChange('')}>
                    <Close />
                  </IconButton>
                </InputAdornment>
              ) : null}
              {rest?.InputProps?.endAdornment}
            </>
          ),
          inputComponent: NumericFormatCustom as any,
        }}
        InputLabelProps={{
          shrink: true,
          required: required,
          ...rest.InputLabelProps,
        }}
      />
    );
  },
);

export default NumberFieldCustomElement;
