import { EMAIL_REGEX } from '../../../../common/regex';
import { ISchemaFields, ISchemaForm, SchemaType } from '../../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl } = formProps || {};
  // const isEdit = !!valuesField?.id;
  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'employee.staffId' }),
      placeholder: intl.formatMessage({ id: 'employee.staffIdEnter' }),
      propsWrapper: { xs: 6 },
      autoFocus: true,
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'name' }),
      placeholder: intl.formatMessage({ id: 'enterUserName' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    email: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'email' }),
      placeholder: intl.formatMessage({ id: 'enterEmail' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
        pattern: {
          value: EMAIL_REGEX,
          message: intl.formatMessage({ id: 'emailValid' }),
        },
      },
    },
    phoneNumber: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'telephone' }),
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    address: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'address' }),
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    branch: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'employee.unit' }),
      placeholder: intl.formatMessage({ id: 'employee.unitEnter' }),
      propsWrapper: { xs: 6 },
    },
    contractInfo: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'employee.contractInfo' }),
      placeholder: intl.formatMessage({ id: 'employee.contractInfoEnter' }),
      propsWrapper: { xs: 6 },
    },
    position: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'employee.label.position' }),
      placeholder: intl.formatMessage({ id: 'employee.label.positionEnter' }),
      propsWrapper: { xs: 6 },
    },
    active: {
      type: 'switch',
      defaultValue: false,
      label: intl.formatMessage({ id: valuesField?.active ? 'employee.active' : 'employee.deactive' }),
      style: { marginTop: 16 },
      propsWrapper: { xs: 6 },
    },
  } as SchemaType;
};

export const schemaFormAdminUser: ISchemaForm = {
  fields: fieldSchema,
};
