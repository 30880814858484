import { createSelectors } from 'modules/common/utils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';

interface State {
  dialogOpen: boolean;
  onOpenDialog: () => void;
  onCloseDialog: () => void;
}
const useIndicationPrintFormDialogState = create(
  immer<State>((set) => ({
    dialogOpen: false,
    onOpenDialog: () =>
      set((state) => {
        state.dialogOpen = true;
      }),
    onCloseDialog: () =>
      set((state) => {
        state.dialogOpen = false;
      }),
  })),
);

export const useIndicationPrintFormDialogSelectors = createSelectors(useIndicationPrintFormDialogState);
