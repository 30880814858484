import React, { useState } from 'react';
import { Box, ClickAwayListener } from '@mui/material';
import { Appointments as AppointmentsBase } from '@devexpress/dx-react-scheduler';

const AppointmentsContainer: React.ComponentType<AppointmentsBase.ContainerProps> = ({ style, children }) => {
  const [clicked, setClicked] = useState(false);
  return (
    <ClickAwayListener onClickAway={() => setClicked(false)}>
      <Box
        sx={{
          ...style,
          height: `calc(${style.height}px - 2px)`,
          width: `calc(${style.width} + 9px)`,
          overflow: 'hidden',
          zIndex: clicked ? 1000 : 0,
        }}
        onClick={() => setClicked(true)}
      >
        {children}
      </Box>
    </ClickAwayListener>
  );
};

export default AppointmentsContainer;
