import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { ATTEND_PERSON_TYPE } from '../common/constants';

export const AttendPersonSchema = z
  .object({
    id: z.number(),
    referenceId: z.number(),
    roleName: z.string(),
    userId: z.number(),
    employeeName: z.string(),
    mission: z.string(),
    type: createObjectKeysEnumSchema(ATTEND_PERSON_TYPE),
  })
  .partial();

export type AttendPerson = z.infer<typeof AttendPersonSchema>;
