import GraphicEqIcon from '@mui/icons-material/GraphicEq';
import MicIcon from '@mui/icons-material/Mic';
import * as React from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import useGeneralHook from '../hook/useGeneralHook';

interface ISpeechButtonProps {
  onStopRecording: (transcript: string) => void;
  fieldName: string;
}

function convertLanguage(s: string) {
  if (s === 'vi') {
    return 'vi-VN';
  } else if (s === 'id') {
    return 'id';
  } else {
    return 'en-US';
  }
}
let current = [''];

const SpeechButton: React.FunctionComponent<ISpeechButtonProps> = ({ onStopRecording, fieldName }) => {
  const { appState } = useGeneralHook();
  const { listening, resetTranscript, browserSupportsSpeechRecognition, finalTranscript } = useSpeechRecognition();
  const onClick = React.useCallback(
    (e) => {
      e.stopPropagation();
      if (!listening) {
        SpeechRecognition.startListening({ language: convertLanguage(appState.intl.locale) });
        current[0] = fieldName;
      }
    },
    [appState.intl.locale, fieldName, listening],
  );

  React.useEffect(() => {
    if (!listening && current[0] === fieldName) {
      if (finalTranscript) {
        onStopRecording(finalTranscript);
        resetTranscript();
      }
    }
  }, [fieldName, finalTranscript, listening, onStopRecording, resetTranscript]);

  if (!browserSupportsSpeechRecognition) {
    return <></>;
  }

  return (
    <span onClick={onClick}>
      {listening && current[0] === fieldName ? (
        <GraphicEqIcon
          style={{
            color: 'white',
          }}
        />
      ) : (
        <MicIcon
          style={{
            color: 'white',
          }}
        />
      )}
    </span>
  );
};

export default SpeechButton;
