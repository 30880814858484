import { Box, InputAdornment } from '@mui/material';
import { Add, Search } from '@mui/icons-material';
import React from 'react';
import { ILayoutFields, ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';

export interface FilterParams {
  name?: string;
}

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl, onAddNew } = formProps || {};
  return {
    name: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'indication.search' }),
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Search color="inherit" />
          </InputAdornment>
        ),
      },
      showClearBtn: true,
      noHelperText: true,
      propsWrapper: { xs: true },
    },
    search: {
      type: 'submitButton',
      color: 'inherit',
      label: intl.formatMessage({ id: 'search' }),
      propsWrapper: { xs: undefined },
    },
    add: {
      type: 'button',
      startIcon: <Add />,
      onClick: onAddNew,
      color: 'primary',
      label: intl.formatMessage({ id: 'add' }),
      propsWrapper: { xs: undefined },
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'layout',
      propsGridContainer: { spacing: 1 },
      fields: ['name', 'search', 'add'],
    },
    { id: 'submit', fields: ['save'] },
  ];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement } = props;
  return (
    <>
      <Box display="flex">
        <Box flex={1} />
        <Box flex={1}>{listElement[0]}</Box>
      </Box>
    </>
  );
};

export const searchIndicationSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
