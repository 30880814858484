export const RECEIPT_TYPE = [
  { value: 'DOCTOR', label: 'doctor-prescription' },
  { value: 'RETAIL', label: 'retail' },
];

export const SELLING_STATUS = [
  { value: 'PAID', label: 'paid' },
  { value: 'CANCELLED', label: 'cancelled' },
];

export const PAYMENT_METHOD = [
  { value: 0, label: 'cash' },
  { value: 2, label: 'bank_transfer' },
  { value: 1, label: 'card' },
];

export const PAYMENT_METHOD_ENUM = [
  { value: 'CASH', label: 'cash' },
  { value: 'BANK_TRANSFER', label: 'bank_transfer' },
  { value: 'CARD', label: 'card' },
];

export const DISCOUNT_TYPE = [
  { value: 'CASH', label: 'cash', name: 'VNĐ' },
  { value: 'PERCENT', label: 'percent', name: '%' },
];
