import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import { Box, Stack } from '@mui/material';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const { intl, onDeleteExternal, onChangeMedicationInfoExternal, readOnly } = formProps || {};

  return {
    medicationListExternal: {
      type: 'array',
      hid: true,
      disableCloseBtn: true,
      addable: false,
      paperItemProps: { style: { padding: 0 }, variant: 'elevation', elevation: 0 },
      gridItemProps: { sx: { paddingTop: '0px !important' } },
      schema: {
        fields: ({ formProps, valuesField }) => {
          const { arrayData, methodsArray } = formProps;
          const isFirstElement = arrayData.index === 0;

          return {
            id: {
              type: 'hidden',
            },
            medicationName: {
              type: 'text-field',
              label: isFirstElement ? intl.formatMessage({ id: 'encounterPrescription.drugName' }) : null,
              placeholder: intl.formatMessage({ id: 'dental.prescription.placeHolder.drugNameEnterOut' }),
              propsWrapper: { xs: 2.4 },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  onChangeMedicationInfoExternal({ ...valuesField, medicationName: e.target.value?.trim() });
                }
              },
            },
            quantity: {
              type: 'text-field',
              label: isFirstElement ? intl.formatMessage({ id: 'quantity' }) : null,
              placeholder: intl.formatMessage({ id: 'dental.prescription.placeHolder.enterQuantity' }),
              propsWrapper: { xs: 2.4 },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  onChangeMedicationInfoExternal({ ...valuesField, quantity: e.target.value?.trim() });
                }
              },
            },
            unit: {
              type: 'text-field',
              label: isFirstElement ? intl.formatMessage({ id: 'indication.unit' }) : null,
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 2.4 },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  onChangeMedicationInfoExternal({ ...valuesField, unit: e.target.value?.trim() });
                }
              },
            },
            intendedRoute: {
              type: 'text-field',
              label: isFirstElement ? intl.formatMessage({ id: 'therapy.detail.label.intendedRoute' }) : null,
              placeholder: intl.formatMessage({ id: 'dental.prescription.placeHolder.enterRoute' }),
              propsWrapper: { xs: 2.4 },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  onChangeMedicationInfoExternal({ ...valuesField, intendedRoute: e.target.value?.trim() });
                }
              },
            },
            usage: {
              type: 'text-field',
              label: isFirstElement ? intl.formatMessage({ id: 'encounterPrescription.instruction' }) : null,
              placeholder: intl.formatMessage({ id: 'encounterPrescription.instructionEnter' }),
              propsWrapper: { xs: 2.4 },
              register: {
                maxLength: {
                  value: 1000,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
              },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  onChangeMedicationInfoExternal({ ...valuesField, usage: e.target.value?.trim() });
                }
              },
            },
            delete: {
              unregister: true,
              type: () => {
                return (
                  <IconButtonTitle
                    title="delete"
                    size="small"
                    disabled={readOnly || arrayData.value?.isPaid}
                    onClick={() => {
                      methodsArray?.remove(arrayData?.index);
                      onDeleteExternal(valuesField);
                    }}
                    sx={{ marginTop: isFirstElement ? '20px' : 0 }}
                  >
                    <Delete />
                  </IconButtonTitle>
                );
              },
              propsWrapper: {
                xs: true,
              },
            },
          };
        },
        ui: [
          {
            id: 'info',
            propsWrapper: { xs: true, paddingTop: 2.5 },
            fields: ['group', 'name', 'inventory', 'ingredient', 'dose', 'price'],
          },
          {
            id: 'edit',
            propsWrapper: { xs: true },
            fields: ['medicationName', 'quantity', 'unit', 'intendedRoute', 'usage'],
          },
          {
            id: 'delete',
            propsWrapper: { xs: true, sx: { display: 'flex', alignItems: 'center' } },
            fields: ['delete'],
          },
        ],
        layout: ({ listElement, valuesField }) => {
          return (
            <>
              {valuesField?.medicationListExternal.length > 0 && (
                <Box mb={1} style={{ borderRadius: 4 }}>
                  <Stack direction="row" alignContent="center" justifyContent="space-between">
                    <Box flex={1}>{listElement[1]}</Box>
                    <Box marginX={2}>{listElement[2]}</Box>
                  </Stack>
                </Box>
              )}
            </>
          );
        },
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  return [
    {
      id: 'medication',
      fields: ['medicationListExternal'],
    },
  ];
};

export const addExternalMedicineBox: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: ({ listElement }) => {
    return <>{listElement[0]}</>;
  },
};
