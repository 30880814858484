import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';

export const AppointmentTherapySchema = z
  .object({
    id: z.number(),
    name: z.string(),
    mobilePhone: z.string(),
    address: z.string(),
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    endTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    nameTherapy: z.string(),
    description: z.string(),
    picUserId: z.number(),
    picName: z.string(),
    picEmployeeResourceId: z.string(),
  })
  .partial();

export type AppointmentTherapy = z.infer<typeof AppointmentTherapySchema>;
