import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createSelectors } from 'modules/common/utils';
import { DentalExam } from '../../../../schema';
import moment from 'moment';
import { EXAM_TARGETS } from './common/constants';

interface IDentalDetailState {
  readonly: boolean;
  dentalExamInfo: DentalExam | null;
  examTarget: keyof typeof EXAM_TARGETS;
  setDentalExamInfo: (dentalExamInfo: DentalExam, currentUserId: number) => void;
  setExamTarget: (examTarget: keyof typeof EXAM_TARGETS) => void;
}

export const useDentalDetailState = create(
  immer<IDentalDetailState>((set) => ({
    readonly: false,
    dentalExamInfo: null,
    examTarget: 'ADULT',
    setDentalExamInfo(dentalExam, currentUserId) {
      set((state) => {
        state.dentalExamInfo = dentalExam;
        state.examTarget = (dentalExam?.type as keyof typeof EXAM_TARGETS) || 'ADULT';
        state.readonly =
          (dentalExam?.dentalExamStatus === 'FINISHED' &&
            moment(dentalExam?.createdTime).isBefore(moment().subtract(1, 'day'))) ||
          dentalExam?.dentalExamStatus === 'CANCELLED' ||
          `${dentalExam.picUserId}` !== `${currentUserId}`;
      });
    },
    setExamTarget: (examTarget) =>
      set((state) => {
        state.examTarget = examTarget;
      }),
  })),
);

export const useDentalDetailStateSelector = createSelectors(useDentalDetailState);
