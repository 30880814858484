import { Delete } from '@mui/icons-material';
import { Alert, InputLabel, Stack, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import { DRUG_OPTIONS } from 'modules/admin/constants';
import { MEDICATION_UNIT } from 'modules/admin/component/inventory/constant';
import { DECIMAL_NUMBER_REGEX, NUMBER_REGEX_NO_ZERO_HEADER } from 'modules/common/regex';
import { Option } from 'modules/common/type';

interface PropsSchema {
  specializedDepartmentOptions: Option;
}

export const fieldSchema: ISchemaFields<PropsSchema> = ({ valuesField, formProps }) => {
  const { intl, specializedDepartmentOptions, appState } = formProps || {};

  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.code' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.codeEnter' }),
      propsWrapper: { xs: 6 },
    },
    registrationNumber: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.registerNumber' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.registerNumberEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.drugName' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.drugNameEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    numeratorUnit: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.numeratorUnit' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.unitEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    codeGroup: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.groupCode' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.groupCodeEnter' }),
      propsWrapper: { xs: 6 },
    },
    specializedDepartmentId: {
      type: 'select',
      label: intl.formatMessage({ id: 'pharmacy.departmentCode' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.departmentCodeEnter' }),
      rawOptions: true,
      options: specializedDepartmentOptions,
      propsWrapper: { xs: 6 },
    },
    'medicationKnowledge.packaging': {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.packagingFormat' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.packagingFormatEnter' }),
      propsWrapper: { xs: 6 },
    },
    form: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.dosageForm' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.dosageFormEnter' }),
      propsWrapper: { xs: 6 },
    },
    'medicationKnowledge.expiration': {
      type: 'text-field',
      label: intl.formatMessage({ id: 'expiredDate' }),
      placeholder: intl.formatMessage({ id: 'expiredDateEnter' }),
      propsWrapper: { xs: 6 },
    },
    country: {
      type: 'select',
      label: intl.formatMessage({ id: 'pharmacy.produceCountry' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.produceCountryEnter' }),
      options: appState.common.countryList,
      rawOptions: true,
      propsWrapper: { xs: 6 },
    },
    intendedRoute: {
      type: 'auto-complete',
      multiple: true,
      limitTags: 2,
      label: intl.formatMessage({ id: 'pharmacy.intendedRoute' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.intendedRouteEnter' }),
      options: appState.common.intendedRoutes,
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    'medicationKnowledge.productType': {
      type: 'select',
      label: intl.formatMessage({ id: 'pharmacy.formType' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.formTypeEnter' }),
      register: {
        required: true,
      },
      options: DRUG_OPTIONS,
      propsWrapper: { xs: 6 },
    },
    ingredient: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.ingredients' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.ingredientsEnter' }),
      register: { required: true },
      propsWrapper: { xs: 6 },
    },
    dose: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.amount' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.amountEnter' }),
      propsWrapper: { xs: 6 },
    },
    'manufacturer.name': {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.producer' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.producerEnter' }),
      propsWrapper: { xs: 6 },
    },
    'manufacturer.address': {
      type: 'text-field',
      label: intl.formatMessage({ id: 'address' }),
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      propsWrapper: { xs: 6 },
    },
    'registration.name': {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.registerCompany' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.registerCompanyEnter' }),
      propsWrapper: { xs: 6 },
    },
    'registration.address': {
      type: 'text-field',
      label: intl.formatMessage({ id: 'address' }),
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      propsWrapper: { xs: 6 },
    },
    'distributor.name': {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.distributor' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.distributorEnter' }),
      propsWrapper: { xs: 6 },
    },
    'distributor.address': {
      type: 'text-field',
      label: intl.formatMessage({ id: 'address' }),
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      propsWrapper: { xs: 6 },
    },
    label_amount: {
      unregister: true,
      type: () => {
        return (
          <div style={{ height: 53 }}>
            <InputLabel>
              <FormattedMessage id="quantity" />
            </InputLabel>
            <Typography mt={1.5} textAlign="center">
              {1}
            </Typography>
          </div>
        );
      },
      propsWrapper: {
        xs: 2,
        style: { justifyContent: 'center', display: 'flex' },
      },
      noHelperText: true,
    },
    basicUnit: {
      type: 'select',
      options: MEDICATION_UNIT.filter(
        (v) =>
          !valuesField?.unitConverterList ||
          valuesField?.unitConverterList?.filter((u) => u.fromUnit === v.value).length <= 0,
      ),
      label: intl.formatMessage({ id: 'inventory.unit' }),
      placeholder: intl.formatMessage({ id: 'select' }),
      propsWrapper: { xs: 3 },
      register: {
        required: {
          value: valuesField.unitConverterList?.length > 0 || valuesField.basicPrice,
          message: intl.formatMessage({ id: 'required' }),
        },
      },
      readOnly: valuesField?.isLockBasicUnit,
    },
    basicPrice: {
      type: 'number-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'pharmacy.sellingPrice' }),
      placeholder: intl.formatMessage({ id: 'enter' }),
      propsWrapper: { xs: 3 },
      register: {
        required: {
          value: valuesField.unitConverterList?.length > 0 || valuesField.basicUnit,
          message: intl.formatMessage({ id: 'required' }),
        },
        maxLength: {
          value: 16,
          message: intl.formatMessage({ id: 'common.text.length.invalid' }),
        },
        pattern: DECIMAL_NUMBER_REGEX,
      },
    },
    unitConverterList: {
      type: 'array',
      disableCloseBtn: true,
      hiddenNodata: true,
      labelAdd: intl.formatMessage({ id: 'pharmacy.addUnit' }),
      paperItemProps: { style: { padding: 0 }, variant: 'elevation', elevation: 0 },
      defaultData: {
        amount: null,
        fromUnit: null,
        price: null,
        factor: null,
        unit: null,
      },
      schema: {
        fields: ({ formProps, valuesField: unitConverterForm }) => {
          const { arrayData, methodsArray } = formProps;
          return {
            amount: {
              unregister: true,
              type: () => {
                return (
                  <div style={{ height: 53 }}>
                    <InputLabel>
                      <FormattedMessage id="quantity" />
                    </InputLabel>
                    <Typography mt={1.5} textAlign="center">
                      {1}
                    </Typography>
                  </div>
                );
              },
              propsWrapper: {
                xs: 2,
                style: { justifyContent: 'center', display: 'flex' },
              },
              noHelperText: true,
            },
            fromUnit: {
              type: 'select',
              options: MEDICATION_UNIT.filter(
                (v) =>
                  (v.value !== valuesField.basicUnit &&
                    valuesField?.unitConverterList.filter((u) => u.fromUnit === v.value).length <= 0) ||
                  v.value === unitConverterForm.fromUnit,
              ),
              label: intl.formatMessage({ id: 'inventory.unit' }),
              placeholder: intl.formatMessage({ id: 'select' }),
              propsWrapper: { xs: 5 },
              register: {
                required: true,
              },
              noHelperText: true,
            },
            price: {
              type: 'number-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'pharmacy.sellingPrice' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 5 },
              register: {
                required: true,
                maxLength: {
                  value: 16,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
                pattern: DECIMAL_NUMBER_REGEX,
              },
              // noHelperText: true,
            },
            label_1: {
              unregister: true,
              type: () => {
                return (
                  <div style={{ height: 53 }}>
                    <InputLabel>
                      <FormattedMessage id="pharmacy.equivalent" />
                    </InputLabel>
                    <Typography mt={1.5} textAlign="center">
                      {'='}
                    </Typography>
                  </div>
                );
              },
              propsWrapper: {
                xs: true,
              },
            },
            factor: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'quantity' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              register: {
                required: true,
                max: {
                  value: 1000000000,
                  message: intl.formatMessage({ id: 'validation.invalid' }),
                },
                pattern: {
                  value: NUMBER_REGEX_NO_ZERO_HEADER,
                  message: intl.formatMessage({ id: 'inventory.basePrice.invalid' }),
                },
                validate: {
                  duplicate: (value) =>
                    valuesField?.unitConverterList.filter((u) => String(u.factor) === String(value)).length > 1
                      ? intl.formatMessage({ id: 'inventory.unitConverter.duplicate.quantity' })
                      : true,
                },
              },
              // noHelperText: true,
              tooltipError: true,
              propsWrapper: {
                xs: 6,
              },
            },
            unit: {
              unregister: true,
              type: () => {
                const label = MEDICATION_UNIT.find((v) => v.value === valuesField.basicUnit)?.label;
                return (
                  <div style={{ height: 53 }}>
                    <InputLabel>
                      <FormattedMessage id="pharmacy.unitText" />
                    </InputLabel>
                    <Typography mt={1.5}>{label && <FormattedMessage id={label} />}</Typography>
                  </div>
                );
              },
              propsWrapper: {
                xs: 6,
              },
            },
            delete: {
              unregister: true,
              type: () => {
                return (
                  <IconButtonTitle title="delete" size="small" onClick={() => methodsArray?.remove(arrayData?.index)}>
                    <Delete />
                  </IconButtonTitle>
                );
              },
              propsWrapper: {
                xs: true,
              },
            },
            alert: {
              hidden: parseInt(unitConverterForm.factor) !== 1,
              type: 'alert-warning',
              propsWrapper: { xs: 11.4 },
              label: 'dugDatabase.alert.waring.duplicateUnit',
            },
          };
        },
        ui: [
          {
            id: 'base',
            propsWrapper: { xs: true },
            paperProps: {
              sx: {
                border: 'none',
                bgcolor: 'grey.200',
                px: 2,
                py: 1,
              },
            },
            paper: true,
            fields: ['amount', 'fromUnit', 'price'],
          },
          {
            id: 'label_1',
            propsWrapper: { xs: undefined },
            paperProps: {
              sx: {
                border: 'none',
                bgcolor: 'grey.200',
                px: 1.5,
                py: 1.7,
              },
            },
            paper: true,
            fields: ['label_1'],
          },
          {
            id: 'base',
            propsWrapper: { xs: 4 },
            paperProps: {
              sx: {
                border: 'none',
                bgcolor: 'grey.200',
                px: 2,
                py: 1,
              },
            },
            paper: true,
            fields: ['factor', 'unit'],
          },
          {
            id: 'delete',
            propsWrapper: { xs: undefined, sx: { display: 'flex', alignItems: 'center' } },
            fields: ['delete'],
          },
          {
            id: 'alert',
            propsWrapper: { xs: 12 },
            fields: ['alert'],
          },
        ],
      },
    },
    publicPrice: {
      type: 'number-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'pharmacy.declarePrice' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.declarePriceEnter' }),
      propsWrapper: { xs: 6 },
    },
    approvalDate: {
      type: 'datePicker',
      label: intl.formatMessage({ id: 'pharmacy.applicableDate' }),
      propsWrapper: { xs: 6 },
      noHelperText: true,
    },
  };
};

const ui: IUiFields = ({ valuesField, formProps }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'info' }),
      fields: [
        'code',
        'registrationNumber',
        'name',
        'numeratorUnit',
        'codeGroup',
        'specializedDepartmentId',
        'medicationKnowledge.packaging',
        'form',
        'medicationKnowledge.expiration',
        'country',
        'intendedRoute',
        'medicationKnowledge.productType',
        'ingredient',
        'dose',
      ],
    },
    {
      id: 'price',
      title: intl.formatMessage({ id: 'pharmacy.organizationInfo' }),
      fields: [
        'manufacturer.name',
        'manufacturer.address',
        'registration.name',
        'registration.address',
        'distributor.name',
        'distributor.address',
      ],
    },
    {
      id: 'unitConversion',
      title: (
        <Box>
          <Typography variant="h6" style={{ flex: 1 }}>
            <FormattedMessage id="pharmacy.unitConversion" />
          </Typography>
          {valuesField?.importedInventoryStatus && (
            <Stack sx={{ width: '100%' }} spacing={2} marginTop={1.5}>
              <Alert severity="warning" sx={{ flexDirection: 'row' }}>
                <Typography
                  dangerouslySetInnerHTML={{
                    __html: intl.formatMessage({ id: 'dugDatabase.alert.waring.fromUnitExist' }),
                  }}
                ></Typography>
              </Alert>
            </Stack>
          )}
          <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 8 }}>
            <FormattedMessage id="pharmacy.baseUnit" />
          </Typography>
        </Box>
      ),
      fields: ['basicUnit', 'basicPrice'],
    },

    {
      id: 'unitConversion',
      title: (
        <Box display={'flex'}>
          <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 8 }}>
            <FormattedMessage id="dugDatabase.title.conversionUnit" />
          </Typography>
        </Box>
      ),

      fields: ['unitConverterList', 'alert'],
    },
    {
      id: 'price',
      title: intl.formatMessage({ id: 'pharmacy.price' }),
      fields: ['publicPrice', 'approvalDate'],
    },
  ];
};

export const drugStoreFormSchema: ISchemaForm<PropsSchema> = {
  fields: fieldSchema,
  ui: ui,
  changeDataBeforeSubmit: (values) => {
    return { ...values, intendedRoute: values.intendedRoute?.map((v) => v.value) };
  },
};
