import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { some } from 'modules/common/constants';
import SchemaForm from 'modules/common/SchemaForm';
import { FilterParams, searchSupplySchema } from './schema';

interface Props {
  onSubmit?: (formVal: FilterParams, methods: UseFormReturn) => void;
  onAddNew?: () => void;
  formData: some;
  onImportExchange?: () => void;
  onImportDrug?: () => void;
  renderImportCSVButton?: () => void;
}

const SearchDrug: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, onAddNew, formData, onImportExchange, onImportDrug, renderImportCSVButton } = props;
  return (
    <SchemaForm
      schema={searchSupplySchema}
      formData={formData}
      onSubmit={onSubmit}
      hideSubmitButton
      onAddNew={onAddNew}
      onImportExchange={onImportExchange}
      onImportDrug={onImportDrug}
      renderImportCSVButton={renderImportCSVButton}
    />
  );
};

export default SearchDrug;
