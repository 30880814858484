import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { some } from '../../../../common/constants';
import SchemaForm from '../../../../common/SchemaForm';
import { FilterParams, searchImageIndicationSchema } from './schema';

interface Props {
  onSubmit?: (formVal: FilterParams, methods: UseFormReturn) => void;
  onAddNew?: () => void;
  formData: some;
}

const SearchTherapy: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, onAddNew, formData } = props;
  return (
    <SchemaForm
      schema={searchImageIndicationSchema}
      formData={formData}
      onSubmit={onSubmit}
      hideSubmitButton
      onAddNew={onAddNew}
    />
  );
};

export default SearchTherapy;
