import { Box, Button, DialogActions, DialogContent, Stack, Tooltip, Typography } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { DoctorExchange } from '../../../../svg';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import { Encounter } from 'modules/schema';
import { ENCOUNTER_STATUS, PATIENT_TYPE } from 'modules/common/apiConstants';
import EditPICDialog from 'modules/common/component/EditPICDialog';
import { useUserRoles } from 'modules/common/hook/useUserRoles';
import { API_SERVER } from 'modules/common/api';
import { useUpdateMutate } from 'modules/common/hook/useMutate';
import DialogCustom from 'modules/common/component/DialogCustom';
import { useHistory } from 'react-router';
import { ROUTES } from 'modules/layout/router';
import CancelIcon from '@mui/icons-material/Cancel';
import { FormProvider, useForm } from 'react-hook-form';
import TextInput from 'modules/common/component/form/TextInput';
import { mutate } from 'swr';

interface IEncounterListProps {
  encounters?: Encounter[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  refresh: () => void;
}

interface CancelExamBlockFormProps {
  isComplete: boolean;
  reason: string | null;
}

const EncounterList: React.FunctionComponent<IEncounterListProps> = ({
  encounters,
  loading,
  hookPagination,
  total,
  refresh,
}) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const { confirmDialog, appState, intl } = useGeneralHook();
  const [selectedEncounter, setSelectedEncounter] = React.useState<Encounter | undefined>(undefined);
  const [openChangePICDialog, setOpenChangePICDialog] = React.useState(false);
  const [openRejectDialog, setOpenRejectDialog] = React.useState(false);
  const [rejectReason, setRejectReason] = React.useState('');
  const doctors = useUserRoles('DOCTOR', 'GENERAL_DOCTOR');
  const currentUserId = appState.authen.user?.user_id;
  const history = useHistory();
  const [openCompleteDialog, onOpenCompleteDialog] = React.useState(false);
  const formMethods = useForm<CancelExamBlockFormProps>({
    defaultValues: {
      isComplete: false,
      reason: null,
    },
  });
  const onChangePICMutate = useUpdateMutate({
    onSuccess: () => {
      setOpenChangePICDialog(false);
      refresh();
    },
  });

  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounter.table.label.sequenceNumber',
        dataIndex: 'sequenceNumber',
        align: 'left',
        width: 90,
      },
      {
        title: 'encounterId',
        render: (record) => {
          return <Typography>{record?.code}</Typography>;
        },
        align: 'right',
      },
      {
        title: 'patientCodeShort',
        render: (record) => {
          return <Typography>{record?.patient?.code?.split('-')[1]}</Typography>;
        },
        align: 'right',
      },
      {
        title: 'patientName',
        render: (record) => {
          return <Typography>{record?.patient?.name}</Typography>;
        },
      },
      {
        title: 'birthdayShort',
        render: (record) => {
          return <Typography>{moment(record?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>;
        },
        width: 120,
      },
      {
        title: 'telephone',
        render: (record) => {
          return <Typography>{record?.patient?.mobilePhone}</Typography>;
        },
      },
      {
        title: 'encounterDate',
        dataIndex: 'date',
        render: (record) => {
          return <Typography>{record?.createdTime?.format(FE_DATE_FORMAT)}</Typography>;
        },
      },
      {
        title: 'encounterGroup',
        render: (record) => {
          return <FormattedMessage id={PATIENT_TYPE[record?.patientType!]?.label || ' '} />;
        },
      },
      {
        title: 'encounterDoctor',
        render: (record) => {
          return record?.picName;
        },
      },
      {
        title: 'checkUpType',
        dataIndex: 'serviceName',
      },
      {
        title: 'diagnosis',
        width: 100,
        render: (record) => {
          const diagnosis = record?.icdDiagnosisList?.map((icd) => icd?.code)?.join(', ') || '';
          return (
            <Tooltip title={diagnosis}>
              <Typography noWrap sx={{ maxWidth: 120 }}>
                {diagnosis}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        title: 'status',
        render: (record) => {
          const status = ENCOUNTER_STATUS[record.status!];
          return (
            <Typography
              noWrap
              sx={{
                color: status?.color,
              }}
            >
              <FormattedMessage id={status?.label} />
            </Typography>
          );
        },
      },

      {
        title: 'action',
        align: 'right',
        width: '100px',
        render: (record) => {
          // Người phụ trách trùng với User hiện tại và Ca khám chưa kết thúc hoặc đã kết thúc không quá 24h
          const showEditPICButton =
            currentUserId === record?.picUserId &&
            record &&
            (!record.endTime || (record.endTime && record.endTime.isAfter(moment().add(-1, 'day'))));
          const disabled = record.status === ENCOUNTER_STATUS.FINISHED.value || !showEditPICButton;
          const disabledCancel = record.status !== ENCOUNTER_STATUS.ARRIVED.value || !showEditPICButton;

          return (
            <>
              <IconButtonTitle
                sx={{
                  '& path': {
                    fill: !disabled ? '#0962ff' : '#847c7c',
                  },
                }}
                disabled={disabled}
              >
                <DoctorExchange
                  onClick={(event) => {
                    event.stopPropagation();
                    setOpenChangePICDialog(true);
                    setSelectedEncounter(record);
                  }}
                  title={intl.formatMessage({ id: 'encounterList.examDoctor.action.picButton' })}
                />
              </IconButtonTitle>
              <IconButtonTitle
                sx={{
                  '& path': {
                    fill: !disabledCancel ? '#0962ff' : '#847c7c',
                  },
                }}
                disabled={disabledCancel}
                title={intl.formatMessage({ id: 'patient.management.dialog.button.cancel' })}
              >
                <CancelIcon
                  onClick={(event) => {
                    event.stopPropagation();
                    onOpenCompleteDialog(true);
                    setSelectedEncounter(record);
                  }}
                />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns<Encounter>[];
  }, [currentUserId, intl]);

  const onReceiveEncounterMutate = useUpdateMutate();
  const cancelMutate = useUpdateMutate({
    onSuccess: () => {
      mutate(API_SERVER.encounter.get(selectedEncounter?.id!).url);
      onOpenCompleteDialog(false);
      refresh();
    },
  });
  return (
    <>
      <TableCustom
        dataSource={encounters}
        loading={loading}
        columns={columns}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        rowProps={(record) => ({
          onClick: async () => {
            if (record?.status === ENCOUNTER_STATUS.ARRIVED.value) {
              if (currentUserId !== record?.picUserId) {
                setRejectReason(
                  appState.authen.user?.role.indexOf('NURSE')
                    ? 'encounterDetail.wrongAssignment'
                    : 'encounterDetail.nurse.wrongAssignment',
                );
                setOpenRejectDialog(true);
                return;
              }
              if (record?.createdTime?.format(FE_DATE_FORMAT) !== moment().format(FE_DATE_FORMAT)) {
                setRejectReason('encounterDetail.outTime');
                setOpenRejectDialog(true);
                return;
              }
              const confirm = await confirmDialog.promptConfirmation({
                okId: intl.formatMessage({ id: 'encounterDetail.confirmReceiveEncounterMessage' }),
                title: intl.formatMessage({ id: 'confirm' }),
                content: intl.formatMessage({ id: 'encounterDetail.confirmReceiveEncounter' }),
              });
              if (confirm) {
                await onReceiveEncounterMutate({
                  url: API_SERVER.encounter.receiveEncounter(record?.id!),
                  method: 'PUT',
                });
                confirmDialog.close();
              } else {
                confirmDialog.close();
                return;
              }
            }
            history.push(ROUTES.encounterDetail.gen(record?.id!, record?.patientId!));
          },
          style: { cursor: 'pointer' },
        })}
      />
      <DialogCustom
        open={openRejectDialog}
        onClose={() => {
          setOpenRejectDialog(false);
          setSelectedEncounter(undefined);
        }}
        PaperProps={{
          style: {
            minWidth: 420,
          },
        }}
        title={'encounterDetail.confirmReceiveEncounterMessage'}
      >
        <DialogContent style={{ padding: 16 }}>
          <Typography variant="body1" component="div">
            <FormattedMessage id={rejectReason} />
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenRejectDialog(false)}
            style={{ minWidth: 100 }}
          >
            <FormattedMessage id={'ok'} />
          </Button>
        </DialogActions>
      </DialogCustom>
      {selectedEncounter && selectedEncounter?.picUserId && (
        <EditPICDialog
          currentPICId={selectedEncounter?.picUserId}
          dialogTitleKey="encounter.encounterDetail.editPIC.dialog.title"
          doctors={doctors}
          onClose={() => setOpenChangePICDialog(false)}
          onUpdatePIC={(data) =>
            onChangePICMutate({
              url: API_SERVER.encounter.changePIC(selectedEncounter.id!),
              method: 'PUT',
              data: data,
            })
          }
          open={openChangePICDialog}
        />
      )}
      <FormProvider {...formMethods}>
        <DialogCustom
          open={openCompleteDialog}
          onClose={() => {
            onOpenCompleteDialog(false);
            setSelectedEncounter(undefined);
          }}
          PaperProps={{
            style: { minWidth: 480 },
          }}
          title={intl.formatMessage({ id: 'confirmCancelTitle' })}
        >
          <Box padding={2}>
            <Typography paddingBottom={1}>
              {intl.formatMessage(
                { id: 'encounter.confirmation.popup.cancel' },
                { name: selectedEncounter?.patient?.name },
              )}
            </Typography>
            <TextInput
              label="cancelReason"
              form={formMethods}
              name="reason"
              placeholder={intl.formatMessage({ id: 'encounterDetail.inCompleteReason' })}
              rows={3}
              required
            />
            <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ padding: '5px 20px' }}
                type="button"
                onClick={() => onOpenCompleteDialog(false)}
              >
                <Typography>
                  <FormattedMessage id="encounter.encounterDetail.editPIC.editButton.title" />
                </Typography>
              </Button>
              <Button
                sx={{ padding: '5px 20px' }}
                type="button"
                onClick={() => {
                  cancelMutate({
                    url: API_SERVER.encounter.cancelEncounter(selectedEncounter?.id!),
                    method: 'PUT',
                    data: {
                      isComplete: false,
                      reason: formMethods.getValues().reason,
                    },
                  });
                }}
                disabled={!formMethods.watch('reason')}
              >
                <Typography>
                  <FormattedMessage id="status.cancelled" />
                </Typography>
              </Button>
            </Stack>
          </Box>
        </DialogCustom>
      </FormProvider>
    </>
  );
};

export default EncounterList;
