import { Columns } from '../../../../../../common/component/TableCustom';

export const THERAPY_SESSION_DETAIL_COLUMNS: Columns[] = [
  {
    dataIndex: 'name',
    title: 'therapy.indication.request.printForm.therapySessionName',
    default: true,
  },
  {
    dataIndex: 'startTime',
    title: 'therapy.indication.request.printForm.startTime',
    default: true,
  },
  {
    dataIndex: 'picName',
    title: 'therapy.indication.request.printForm.pic',
    default: true,
  },
  {
    dataIndex: 'description',
    title: 'therapy.indication.request.printForm.contentOfTherapySession',
    props: { sx: { wordBreak: 'break-all' } },
    default: true,
  },
  {
    dataIndex: 'status',
    title: 'therapy.indication.request.printForm.therapySessionStatus',
    default: true,
  },
];

export const THERAPY_SESSION_INDICATION_COLUMNS: Columns[] = [
  {
    dataIndex: 'serviceName',
    title: 'therapy.indication.request.printForm.indicationName',
    default: true,
  },
  {
    dataIndex: 'scope',
    title: 'therapy.indication.request.printForm.indicationType',
    default: true,
  },
  {
    dataIndex: 'executeName',
    title: 'therapy.indication.request.printForm.indicationPic',
    default: true,
  },
  {
    dataIndex: 'status',
    title: 'therapy.indication.request.printForm.indicationStatus',
    default: true,
  },
  {
    dataIndex: 'isFree',
    title: 'therapy.indication.request.printForm.outPackage',
    default: true,
  },
];

export const THERAPY_SESSION_MEDICATION_COLUMNS: Columns[] = [
  {
    dataIndex: 'medicationName',
    title: 'therapy.indication.request.printForm.medicationName',
    default: true,
  },
  {
    dataIndex: 'quantity',
    title: 'therapy.indication.request.printForm.medicationQuantity',
    default: true,
  },
  {
    dataIndex: 'indentedRoute',
    title: 'therapy.indication.request.printForm.medicationIndentedRoute',
    default: true,
  },
  {
    dataIndex: 'isFree',
    title: 'therapy.indication.request.printForm.medicationOutPackage',
    default: true,
  },
  {
    dataIndex: 'note',
    title: 'therapy.indication.request.printForm.medicationNote',
    props: { sx: { wordBreak: 'break-all' } },
    default: true,
  },
];

export const THERAPY_SESSION_SUPPLIES_COLUMNS: Columns[] = [
  {
    dataIndex: 'medicalSuppliesGroup',
    title: 'therapy.indication.request.printForm.suppliesGroup',
    default: true,
  },
  {
    dataIndex: 'medicalSuppliesName',
    title: 'therapy.indication.request.printForm.suppliesName',
    default: true,
  },
  {
    dataIndex: 'quantity',
    title: 'therapy.indication.request.printForm.suppliesQuantity',
    default: true,
  },
  {
    dataIndex: 'indicationRequest.serviceName',
    title: 'therapy.indication.request.printForm.indication',
    default: true,
  },
  {
    dataIndex: 'isFree',
    title: 'therapy.indication.request.printForm.medicationOutPackage',
    default: true,
  },
];
