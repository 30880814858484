import { Box, Paper } from '@mui/material';
import * as React from 'react';
import { PRIMARY } from '../../../../colors';

interface IVItalSignItemProps {
  icon: React.ReactNode;
  control: React.ReactNode;
  label?: React.ReactNode;
}

const VItalSignItem: React.FunctionComponent<IVItalSignItemProps> = ({ icon, control, label }) => {
  return (
    <Paper elevation={0} style={{ padding: 8, background: PRIMARY[100] }}>
      <Box display="flex" alignItems="center" gap={1}>
        <Box style={{ overflow: 'hidden', flexShrink: 0 }}>{icon}</Box>
        <Box alignSelf="center" minWidth={80}>
          {label}
        </Box>
        <Box flex={1}>{control}</Box>
      </Box>
    </Paper>
  );
};

export default VItalSignItem;
