import { Box, Button, Stack } from '@mui/material';
import Report from 'modules/common/component/report';
import Filter from 'modules/common/component/filter';
import { DEFAULT_VALUES, FILTER_CONFIGS } from 'modules/common/page/report/work/appointment/consts';
import { useEffect, useMemo, useRef, useState } from 'react';
import DateInput from 'modules/common/component/form/DateInput';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';
import { groupBy } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { TIME_REPORT_FILTER } from 'modules/common/component/report/consts';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'modules/common/constants';
import { formatEndDate, formatStartDate, getDateRangeList, isValidDate } from 'modules/common/utils';
import { FormSchema, SearchParams } from 'modules/common/page/report/work/appointment/type';
import SelectInput from 'modules/common/component/form/SelectInput';
import ReactToPrint from 'react-to-print';
import { Print } from '@mui/icons-material';
import { ValueType } from 'modules/common/type';
import { SPECIALIZED_REPORT_TYPE } from 'modules/common/apiConstants';
import { WORK_API } from 'modules/common/service';

export const FILTER_KEY = 'APPOINTMENT_REPORT_CHART_FILTER';
export const SELECT_COLUMNS_KEY = 'APPOINTMENT_REPORT_CHART_SELECT_COLUMNS';

interface Props {
  typeOption: ValueType<typeof SPECIALIZED_REPORT_TYPE>[];
}

const AppointmentsReportChart: React.FunctionComponent<Props> = ({ typeOption }) => {
  const [searchParams, setSearchParams] = useState<SearchParams>({ ...DEFAULT_VALUES, type: typeOption[0] });
  const [seriesData, setSeriesData] = useState<number[]>([]);
  const form = useForm<FormSchema & { timeReportType: keyof typeof TIME_REPORT_FILTER }>({
    defaultValues: {
      fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
      toDate: formatStartDate(moment().add(+1, 'day')),
      timeReportType: TIME_REPORT_FILTER.DAY.value,
      type: typeOption[0],
    },
    mode: 'onChange',
  });

  const refPrintComponent = useRef(null);
  const intl = useIntl();
  const type = form.watch('type');
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate = formatEndDate(moment(toDateOrigin).add(-1, 'day'));
  const timeReportType = form.watch('timeReportType');
  const formData = form.getValues();
  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      type: type ? type.value : typeOption[0].value,
      fromDate,
      toDate,
    };
  }, [fromDate, searchParams, toDate, type, typeOption]);

  // Get data appointment
  const { data: reportData, isValidating } = useFetch(WORK_API.getAppointmentList(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'CHART'),
  });

  // Data appointment chart for day/month
  const dateRangeList = useMemo(
    () =>
      getDateRangeList(
        moment(fromDate, BE_DATE_TIME_FORMAT),
        moment(toDate, BE_DATE_TIME_FORMAT),
        timeReportType === 'DAY' ? 'day' : 'month',
      ).map((date) => date.format(timeReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT)),
    [fromDate, timeReportType, toDate],
  );

  // Data appointment chart
  useEffect(() => {
    const timeFormat = timeReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT;
    if (!isValidating && reportData && isValidDate(fromDate, toDate, 'CHART')) {
      const reportMap = groupBy(reportData, (item) => item.createdTime?.format(timeFormat));
      setSeriesData(dateRangeList.map((date) => reportMap[date]?.length || 0));
    } else {
      setSeriesData([]);
    }
  }, [dateRangeList, fromDate, isValidating, reportData, timeReportType, toDate]);

  useEffect(() => {
    if (
      timeReportType === 'DAY' &&
      moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'month') &&
      moment(toDateOrigin) <= moment()
    ) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'month')
            .add(+1, 'day'),
        ),
      });
    }
    if (
      timeReportType === 'MONTH' &&
      moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'year') &&
      moment(toDateOrigin) <= moment()
    ) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'year')
            .add(+1, 'day'),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateOrigin, timeReportType, toDateOrigin]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title title="report.appointment.title.chart" />
      <Filter
        filters={FILTER_CONFIGS}
        popupWidth="600px"
        onSave={(filterValue) => {
          setSearchParams((prev) => ({
            ...prev,
            patientTypes: filterValue.PATIENT_TYPE,
            types: filterValue.APPOINTMENT_CHART_TYPE,
            status: filterValue.APPOINTMENT_STATUS,
          }));
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                minDate={moment(fromDate).add(+1, 'day')}
              />
              <Box marginTop={'-17px'}>
                <Report.DayMonthRadio
                  form={form}
                  name="timeReportType"
                  onChange={(value) => {
                    if (value === 'DAY') {
                      form.reset({
                        fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
                        toDate: formatStartDate(moment().add(+1, 'day')),
                        timeReportType: TIME_REPORT_FILTER.DAY.value,
                      });
                    }
                    if (value === 'MONTH') {
                      form.reset({
                        fromDate: moment().add(-1, 'year').format(BE_DATE_TIME_FORMAT),
                        toDate: formatStartDate(moment().add(+1, 'day')),
                        timeReportType: TIME_REPORT_FILTER.MONTH.value,
                      });
                    }
                  }}
                />
              </Box>
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() =>
                    form.reset({
                      ...DEFAULT_VALUES,
                      toDate: formatStartDate(moment().add(+1, 'day')),
                    })
                  }
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />}>
                  <FormattedMessage id={'printReport'} />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Stack>
        </Stack>
        <Report.ColumnChart
          refPrintComponent={refPrintComponent}
          xAxisTitle="common.time"
          yAxisTitle="report.chart.title.numberOfCustomerRegistration"
          categories={dateRangeList}
          series={[
            {
              type: 'column',
              name: intl.formatMessage({ id: 'serviceName' }),
              data: seriesData,
              color: '#003CA6',
              showInLegend: false,
            },
          ]}
        />
      </Filter>
    </Stack>
  );
};

export default AppointmentsReportChart;
