import { Stack, createFilterOptions } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import AutocompleteInput from '../../form/AutocompleteInput';
import useFilterContext from '../useFilterContext';
import { FilterProps, Icd10Filter } from '../type';
import { CodeSystem } from 'modules/schema';
import ChipList from '../CustomeChip';

const filterOptions = createFilterOptions<CodeSystem>({
  limit: 100,
  stringify: (option) => option?.label || '',
});

const Icd10 = ({ filter: { type, placeholder } }: FilterProps<Icd10Filter>) => {
  const { form } = useFilterContext();
  const icd10Options = useSelector((state: AppState) => state.common.icd10s);
  const selectedIcd10s = form.watch(type);
  return (
    <Stack direction="column" gap="10px" flex={1}>
      <AutocompleteInput
        form={form}
        name={type}
        options={icd10Options}
        filterOptions={filterOptions}
        getValue="value"
        getLabel="label"
        placeholder={placeholder}
        hideError
        multiple
        renderTags={() => null}
      />

      {selectedIcd10s && selectedIcd10s.length > 0 && (
        <Stack direction="row" gap="10px" flexWrap="wrap" sx={{ '& .MuiChip-label': { maxWidth: '250px' } }}>
          <ChipList
            items={selectedIcd10s}
            labelKey="label"
            valueKey="value"
            onDelete={(value) =>
              form.setValue(
                type,
                selectedIcd10s.filter((ele) => ele.value !== value),
              )
            }
          />
        </Stack>
      )}
    </Stack>
  );
};

export default Icd10;
