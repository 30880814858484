import { FormProvider, useForm } from 'react-hook-form';
import { Box } from '@mui/material';
import { useParams } from 'react-router-dom';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import DiagnosisSetup from './DiagnosisSetup';
import DialogAction from './DialogAction';
import DiagnosisTable from './DiagnosisTable';
import { DIAGNOSTIC_IMAGE_TYPE, some, SS_TOKEN, THERAPY_REFERENCE_TYPE } from 'modules/common/constants';
import AttachmentsDocument from './AttachmentsDocument';
import Note from './Note';
import QuickServices from './QuickServices';
import { useDiagnosisDialogSelector, useDiagnosisDialogState } from './state';
import { useEffect } from 'react';
import AttachmentTable from './AttachmentTable';
import { useFetchDentalInfo } from './fetcher';
import { axiosThunk } from 'modules/common/redux/axios';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import { API_SERVER } from 'modules/common/api';
import { setLoading } from 'modules/common/redux/commonReducer';
import axios from 'axios';
import { mutate } from 'swr';
import Preview from './Preview';
import CameraImageList from './CameraImageList';
import { DiagnosticImage, DiagnosticImageSchema, StorageResource } from 'modules/schema';

interface Image {
  imageSrc?: string;
  id?: string;
  description?: string;
  checked?: boolean;
  deleted?: boolean;
}
type DiagnosisImageForm = DiagnosticImage & { state?: 'add' | 'delete' | 'update' } & { images: Image[] };

const DialogContent = () => {
  const formMethods = useForm<some>();
  const diagnosisSetting = useDiagnosisDialogState((state) => state.diagnosisSetting);
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const dentalDetail = useDiagnosisDialogSelector.use.dentalDetail();
  const { patientId, dentalSessionId } = useParams<{ patientId; dentalSessionId }>();
  const attachments = useDiagnosisDialogSelector.use.attachments();
  const onCloseDialog = useDiagnosisDialogSelector.use.onCloseDialog();
  const services = useDiagnosisDialogSelector.use.services();
  const therapyDefinitions = useDiagnosisDialogSelector.use.therapyDefinitions();
  const notes = useDiagnosisDialogSelector.use.notes();
  const diagnosisImages = useDiagnosisDialogSelector.use.diagnosisImages();

  const { isValidating } = useFetchDentalInfo(formMethods);
  useEffect(() => {
    dispatch(setLoading(isValidating));
  }, [isValidating, dispatch]);
  useEffect(() => {
    formMethods.setValue('diagnosisSetting.position', diagnosisSetting?.position);
    formMethods.setValue('diagnosisSetting.dentalGroup', diagnosisSetting?.dentalGroup);
    formMethods.setValue('diagnosisSetting.dentalGroupDetail', diagnosisSetting?.dentalGroupDetail);
  }, [diagnosisSetting, formMethods]);

  const onUpdateDiagnosis = async (data) => {
    dispatch(setLoading(true));
    const { diagnosisSetting } = data;
    try {
      const { data: dentalInfo } = await dispatch(
        axiosThunk({
          url: API_SERVER.dental.updateDental(),
          method: 'put',
          data: {
            dentalExamId: parseInt(dentalSessionId),
            patientId: parseInt(patientId),
            position: diagnosisSetting?.position,
            objectType: diagnosisSetting?.dentalGroup,
            note: data?.note,
            dentalDiagnosisVOList: dentalDetail.dentalDiagnosisList?.map((diagnosis) => {
              return {
                id: diagnosis.state === 'add' ? null : diagnosis.id,
                patientId: parseInt(patientId),
                dentalExamId: parseInt(dentalSessionId),
                position: diagnosis?.position,
                objectType: diagnosis?.objectType,
                objectDetail: diagnosis?.objectDetail,
                code: diagnosis?.code,
                display: diagnosis?.display,
                status: diagnosis?.status,
                note: notes[diagnosis.id!],
              };
            }),
            serviceIdList: services?.map((service) => service.id),
            therapyDefinitionIdList: therapyDefinitions?.map((therapyDefinition) => therapyDefinition.id),
          },
        }),
      );
      const addedAttachments = attachments?.filter((attachment) => attachment.state === 'add');
      let uploadFilesRequest;
      if (addedAttachments.length > 0) {
        let multipartFormData = new FormData();
        addedAttachments.forEach((attachment) => multipartFormData.append('files', attachment.file as Blob));
        const types = addedAttachments?.map((ele) => STORAGE_RESOURCE_TYPE.dentalAdult) || [];
        const referenceIds = addedAttachments?.map((ele) => dentalInfo?.id) || [];
        const descriptions = addedAttachments?.map((ele, index) => ' ') || [];
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        uploadFilesRequest = axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );
      }
      let deleteFilesRequest;
      const deletedAttachments = attachments?.filter((attachment) => attachment.state === 'delete');
      if (deletedAttachments.length > 0) {
        deleteFilesRequest = dispatch(
          axiosThunk({
            url: API_SERVER.storageResource.deleteMultiple(),
            method: 'delete',
            data: { data: deletedAttachments?.map((file) => file.id) },
          }),
        );
      }

      const [diagnosisAdded, diagnosisUpdated, diagnosisDeleted] = diagnosisImages.reduce<
        [DiagnosisImageForm[], DiagnosisImageForm[], DiagnosisImageForm[]]
      >(
        (result, cur) => {
          const [addedArr, updatedArr, deleteArr] = result;
          if (cur.state === 'add') {
            return [[...addedArr, cur], updatedArr, deleteArr];
          } else if (cur.state === 'update') {
            return [addedArr, [...updatedArr, cur], deleteArr];
          } else if (cur.state === 'delete') {
            return [addedArr, updatedArr, [...deleteArr, cur]];
          }
          return [addedArr, updatedArr, deleteArr];
        },
        [[], [], []],
      );

      const diagnosisImageAddedRequests = diagnosisAdded.map((diagnosisImage) =>
        dispatch(
          axiosThunk({
            url: API_SERVER.diagnosticImage.create(),
            method: 'post',
            data: {
              examDepartment: diagnosisImage?.examDepartment,
              conclusion: diagnosisImage?.conclusion,
              referenceId: dentalInfo?.id,
              type: DIAGNOSTIC_IMAGE_TYPE.DENTAL_DIAGNOSIS.value,
            },
          }),
        ),
      );

      const diagnosisImageUpdateRequests = diagnosisUpdated.map((diagnosisImage) =>
        dispatch(
          axiosThunk({
            url: API_SERVER.diagnosticImage.update(diagnosisImage.id!),
            method: 'put',
            data: {
              examDepartment: diagnosisImage?.examDepartment,
              conclusion: diagnosisImage?.conclusion,
              referenceId: dentalInfo?.id,
              type: DIAGNOSTIC_IMAGE_TYPE.DENTAL_DIAGNOSIS.value,
            },
          }),
        ),
      );
      const diagnosisImageDeletedRequest = diagnosisDeleted.map((diagnosisImage) =>
        dispatch(
          axiosThunk({
            url: API_SERVER.diagnosticImage.delete(diagnosisImage.id!),
            method: 'delete',
          }),
        ),
      );

      await Promise.all([
        uploadFilesRequest,
        deleteFilesRequest,
        ...diagnosisImageAddedRequests,
        ...diagnosisImageUpdateRequests,
        ...diagnosisImageDeletedRequest,
      ]);

      type DiagnosisImage = { file: File; diagnosisImageId: number; description?: string };
      const addedResponses = await Promise.all([...diagnosisImageAddedRequests]);
      const files = await Promise.all<DiagnosisImage>(
        addedResponses
          .map((response) => DiagnosticImageSchema.parse(response.data))
          .reduce<Promise<DiagnosisImage>[]>((arr, cur, index) => {
            return [
              ...arr,
              ...diagnosisAdded[index].images
                .filter((image) => image.checked)
                .map((image) => {
                  return fetch(image?.imageSrc!)
                    .then((res) => res.blob())
                    .then((res) => ({
                      file: new File([res], 'screen ' + index + '.jpg', {
                        lastModified: new Date().getTime(),
                        type: 'image/jpeg',
                      }),
                      diagnosisImageId: cur.id!,
                      description: image?.description,
                    }));
                }),
            ];
          }, []),
      );

      let addedImageRequest;
      if (files && files.length > 0) {
        let multipartFormData = new FormData();
        files.forEach((ele) => multipartFormData.append('files', ele.file));
        let types: 'DENTAL_DIAGNOSIS_IMAGE_ALBUM'[] = [];
        let referenceIds: number[] = [];
        let descriptions: string[] = [];
        files.forEach((ele) => {
          types.push(STORAGE_RESOURCE_TYPE.dentalDiagnosisImageAlbum);
          referenceIds.push(ele.diagnosisImageId);
          descriptions.push(ele.description || ' ');
        });

        const token = sessionStorage.getItem(SS_TOKEN) || '';
        addedImageRequest = axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );
      }

      let updateImageRequest;
      const updatedResponse = await Promise.all(diagnosisImageUpdateRequests);
      const updateImages = updatedResponse
        .map((response) => DiagnosticImageSchema.parse(response.data))
        .reduce<StorageResource[]>((arr, cur, index) => {
          return [
            ...arr,
            ...(diagnosisUpdated[index]?.images
              ?.filter((ele) => !ele.deleted)
              ?.map((image) => ({
                id: parseInt(image.id!),
                description: image?.description,
              })) || []),
          ];
        }, []);
      if (updateImages && updateImages.length > 0) {
        updateImageRequest = dispatch(
          axiosThunk({
            url: API_SERVER.storageResource.updateDescription(),
            method: 'put',
            data: { data: updateImages?.map((ele) => ({ id: ele?.id, description: ele?.description })) },
          }),
        );
      }

      let deleteImageRequest;
      const deleteImageIds = updatedResponse
        .map((response) => DiagnosticImageSchema.parse(response.data))
        .reduce<number[]>((arr, cur, index) => {
          return [
            ...arr,
            ...(diagnosisUpdated[index]?.images?.filter((ele) => ele.deleted)?.map((image) => parseInt(image.id!)) ||
              []),
          ];
        }, []);
      if (deleteImageIds && deleteImageIds.length > 0) {
        deleteImageRequest = dispatch(
          axiosThunk({
            url: API_SERVER.storageResource.deleteMultiple(),
            method: 'delete',
            data: { data: deleteImageIds },
          }),
        );
      }

      await Promise.all([addedImageRequest, updateImageRequest, deleteImageRequest]);

      openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
      // revalidate odontogram
      await mutate(
        API_SERVER.dental.getListOdontogram({
          dentalExamId: parseInt(dentalSessionId),
          patientId: parseInt(patientId),
        }).url,
      );
      // revalidate diagnosis
      await mutate(
        API_SERVER.dental.getDentalDiagnosis({
          dentalExamId: parseInt(dentalSessionId),
          patientId: parseInt(patientId),
        }).url,
      );
      // revalidate indicationRequest
      await mutate(
        API_SERVER.indicationRequest.getIndicationRequestByReferenceId(parseInt(dentalSessionId), 'DENTAL_EXAM').url,
      );

      // revalidate therapyRequest
      (await dentalSessionId) &&
        mutate(API_SERVER.therapy.getTherapyByReference(dentalSessionId, { type: THERAPY_REFERENCE_TYPE.DENTAL }).url);
    } catch (e) {
      openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
      onCloseDialog();
    }
  };

  return (
    <Box padding="15px 20px">
      <FormProvider {...formMethods}>
        <form onSubmit={formMethods.handleSubmit(onUpdateDiagnosis)}>
          <Box>
            <DiagnosisSetup />
            <DiagnosisTable />
            <CameraImageList />
            <AttachmentsDocument />
            <AttachmentTable />
            <Note />
            <QuickServices />
          </Box>
          <DialogAction />
        </form>
      </FormProvider>
      <Preview />
    </Box>
  );
};

export default DialogContent;
