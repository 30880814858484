import { Table, TableBody, TableContainer, TableHead, TablePagination, Typography } from '@mui/material';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, DENTAL_EXAM_STATUS, FE_DATE_FORMAT } from 'modules/common/constants';
import Row from './Row';
import NoDataBox from 'modules/common/component/NoDataBox';
import { TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import TablePaginationActionsCustom from 'modules/common/component/TableCustom/TablePaginationActionsCustom';
import { DentalExam } from 'modules/schema';
import { Columns } from 'modules/common/component/TableCustom';

interface IEncounterListProps {
  data?: DentalExam[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
}

const DentalList: React.FunctionComponent<IEncounterListProps> = ({ data, loading, hookPagination, total }) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const { intl } = useGeneralHook();

  const columns: Columns<DentalExam>[] = React.useMemo(() => {
    return [
      {
        title: 'encounterId',
        dataIndex: 'code',
        align: 'right',
      },
      {
        title: 'patientCodeShort',
        render: (record) => {
          return <Typography>{record?.patient?.code?.split('-')?.[1] || record?.patient?.resourceId}</Typography>;
        },
        align: 'right',
      },
      {
        title: 'patientName',
        dataIndex: 'patient.name',
      },
      {
        title: 'birthdayShort',
        render: (record) => (
          <Typography>
            {record.patient?.dob && moment(record.patient.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
        width: 120,
      },
      {
        title: 'telephone',
        dataIndex: 'patient.mobilePhone',
      },
      {
        title: 'encounterDate',
        render: (record) => (
          <Typography>
            {record.createdTime && moment(record.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
      },
      {
        title: 'encounterDoctor',
        dataIndex: 'picName',
      },
      {
        title: 'checkUpType',
        dataIndex: 'serviceName',
      },
      {
        title: 'status',
        render: (record) => {
          const status = DENTAL_EXAM_STATUS[record?.status!];
          return (
            <Typography
              noWrap
              sx={{
                color: status?.sx || 'primary.main',
                fontWeight: '500',
              }}
            >
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
      },
      {
        title: 'checkupRoom',
        dataIndex: 'physicsRoomName',
      },
    ];
  }, []);

  return (
    <>
      <TableContainer style={{ height: '100%', flex: 1 }}>
        {loading ? (
          <LoadingIcon />
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableCustomRow>
                <TableHeaderCell style={{ width: 50 }}></TableHeaderCell>
                {columns.map((item, index) => (
                  <TableHeaderCell key={index} sx={{ textAlign: item.align }}>
                    {item.title && <FormattedMessage id={item.title as string} />}
                  </TableHeaderCell>
                ))}
                <TableHeaderCell>
                  <FormattedMessage id="indication.number" />
                </TableHeaderCell>
              </TableCustomRow>
            </TableHead>
            <TableBody>
              {data && data?.length > 0 ? (
                data?.map((row, index) => <Row key={index} dataRow={row} columns={columns} />)
              ) : (
                <tr>
                  <td colSpan={12}>
                    <NoDataBox />
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        data-tour="step-5"
        style={{ padding: 4 }}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        count={total || 0}
        labelRowsPerPage={intl.formatMessage({ id: 'labelRowPerPage' })}
        ActionsComponent={TablePaginationActionsCustom}
      />
    </>
  );
};

export default DentalList;
