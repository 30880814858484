import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../common/component/DialogCustom';
import { some } from '../../../common/constants';
import SchemaForm from '../../../common/SchemaForm';

interface Props {
  onSubmit?: (formVal: some) => void;
  open?: boolean;
  onClose?: () => void;
  receiptToCancel?: some;
}

const CancelConfirmationDialog: React.FunctionComponent<Props> = (props) => {
  const { receiptToCancel, onClose, onSubmit, open } = props;
  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 460 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={'confirmCancelTitle'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaForm
          onSubmit={onSubmit}
          formData={receiptToCancel}
          onCancel={onClose}
          formId="exam-price"
          formProps={{
            autoComplete: 'nope',
            autoCorrect: 'off',
          }}
          schema={{
            fields: ({ valuesField, formProps }) => {
              const { intl } = formProps || {};
              return {
                label: {
                  type: () => {
                    return (
                      <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: 16 }}>
                        {intl.formatMessage({ id: 'sellingProduct.label.cancel.confirm' }, { code: valuesField.code })}
                      </Typography>
                    );
                  },
                  unregister: true,
                },
                reason: {
                  type: 'text-field',
                  label: '',
                  placeholder: intl.formatMessage({ id: 'sellingProduct.label.reasonCancel.enter' }),
                  multiline: true,
                  rows: 4,
                  propsWrapper: {
                    style: { paddingTop: '0' },
                  },
                  required: false,
                  register: {
                    maxLength: {
                      value: 10000,
                      message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                    },
                  },
                },
              };
            },
            ui: [
              {
                id: 'info',
                fields: ['label', 'reason'],
              },
            ],
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default CancelConfirmationDialog;
