import { Box, Stack } from '@mui/material';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { useCallback, useMemo } from 'react';
import { DENTAL_DIAGNOSIS_STATUS, DENTAL_GROUP, DENTAL_GROUP_DETAIL } from '../../common/constants';
import { FormattedMessage } from 'react-intl';
import { State, useDiagnosisDialogSelector, useDiagnosisDialogState } from './state';
import { FE_DATE_FORMAT } from 'modules/common/constants';
import moment from 'moment';
import DiagnosisNoteDialog from './DiagnosisNoteDialog';
import { DentalDiagnosis } from 'modules/schema';

const DiagnosisTable = () => {
  const { confirmDialog, intl } = useGeneralHook();
  const onDeleteDiagnosis = useDiagnosisDialogSelector.use.deleteDiagnosis();
  const completeDentalDiagnosis = useDiagnosisDialogSelector.use.completeDentalDiagnosis();
  const dentalDiagnosisList = useDiagnosisDialogState((state) => state.dentalDetail.dentalDiagnosisList);
  const onDiagnosisNoteDialogOpen = useDiagnosisDialogSelector.use.onDiagnosisNoteDialogOpen();

  const dataTableFormatted = useMemo(
    () =>
      dentalDiagnosisList
        ?.filter((diagnosis) => diagnosis?.status !== DENTAL_DIAGNOSIS_STATUS.DELETED.value)
        .map((diagnosis) => ({
          ...diagnosis,
          target:
            intl.formatMessage({ id: DENTAL_GROUP[diagnosis.objectType!]?.label }) + ' ' + (diagnosis?.position || ''),
          dentalGroupDetail: diagnosis.objectDetail
            ? intl.formatMessage({ id: DENTAL_GROUP_DETAIL[diagnosis.objectDetail]?.label })
            : '',
          diagnosticStatus: diagnosis?.display,
          createdDate: moment(diagnosis?.createdTime).format(FE_DATE_FORMAT),
        })),
    [dentalDiagnosisList, intl],
  );

  const onCompleteButtonClick = useCallback(
    async (dentalDiagnosisId: number, diagnosisName: string) => {
      const confirm = await confirmDialog.promptConfirmation({
        title: <FormattedMessage id="confirm" />,
        content: (
          <FormattedMessage
            id="dental.dentalDetail.indicationTable.completeDiagnosis.message"
            values={{ name: diagnosisName }}
          />
        ),
      });
      if (confirm) {
        completeDentalDiagnosis(dentalDiagnosisId);
      }
      confirmDialog.close();
    },
    [completeDentalDiagnosis, confirmDialog],
  );

  const onDeleteButtonClick = async (diagnosisId: number) => {
    const confirm = await confirmDialog.promptConfirmation({
      title: intl.formatMessage({ id: 'confirm' }),
      content: intl.formatMessage({ id: 'dental.dentalDetail.statusAndDiagnosis.deleteDiagnosis.message' }),
    });
    if (!confirm) {
      confirmDialog.close();
      return;
    } else {
      confirmDialog.close();
    }
    onDeleteDiagnosis(diagnosisId);
  };
  return (
    <Box marginTop={2}>
      <TableCustom
        containerProps={{
          sx: {
            '& th,tr ': {
              padding: '2px 8px',
              backgroundColor: '#F4F8FF',
              boxShadow: '0px 4px 8px 0px #E0ECFF',
              color: 'unset',
              '& span': {
                color: '#78909C',
                fontFamily: 'Roboto',
                fontSize: '12px',
                fontStyle: 'normal',
                fontWeight: '700',
                lineHeight: '16px',
                letterSpacing: '0.4px',
                textTransform: 'uppercase',
              },
            },
            '& td': {
              backgroundColor: '#F4F8FF',
            },
            width: '100%',
            overflow: 'visible',
            marginTop: '5px',
          },
        }}
        dataSource={dataTableFormatted}
        columns={
          [
            {
              title: 'dental.dentalDetail.statusAndDiagnosis.target.label',
              dataIndex: 'target',
              width: 200,
            },
            {
              title: 'dental.dentalDetail.statusAndDiagnosis.dentalGroupDetail.label',
              dataIndex: 'dentalGroupDetail',
              width: '18%',
            },
            {
              title: 'dental.dentalDetail.statusAndDiagnosis.diagnosticStatus.label',
              dataIndex: 'diagnosticStatus',
            },
            {
              title: 'dental.dentalDetail.statusAndDiagnosis.createdDate.label',
              dataIndex: 'createdDate',
            },
            {
              title: 'action',
              align: 'center',
              width: 150,
              render: (record) => (
                <Stack direction="row" justifyContent="center" alignItems="center">
                  <IconButtonTitle title="delete" size="small" onClick={() => onDeleteButtonClick(record.id!)}>
                    <DeleteIcon />
                  </IconButtonTitle>
                  <IconButtonTitle title="note" size="small" onClick={() => onDiagnosisNoteDialogOpen(record.id!)}>
                    <NoteAddIcon />
                  </IconButtonTitle>
                  {record?.state! !== 'add' && (
                    <IconButtonTitle
                      title="complete"
                      size="small"
                      onClick={() => onCompleteButtonClick(record.id!, record.display!)}
                    >
                      <AssignmentTurnedInIcon
                        sx={{ color: record.status === DENTAL_DIAGNOSIS_STATUS.FINISHED.value ? '#43A047' : '#707275' }}
                      />
                    </IconButtonTitle>
                  )}
                </Stack>
              ),
            },
          ] as Columns<DentalDiagnosis & State>[]
        }
      />
      <DiagnosisNoteDialog />
    </Box>
  );
};

export default DiagnosisTable;
