import { Box, Grid, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE, PAYMENT_PLAN, some } from '../../../common/constants';
import { DentalExam, Therapy } from 'modules/schema';

interface Props {
  exam?: Therapy | DentalExam;
  indicationRequest?: some;
}

const PatientInfoBox: React.FunctionComponent<Props> = ({ exam, indicationRequest }) => {
  const patientCode = exam?.patient?.code?.split('-')?.[1] || '';
  return (
    <Grid container spacing={1}>
      <Grid item xl={1.5} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="cashierPrint.encounterId" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {exam?.code}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="therapy.label.customer.code.kh" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {exam?.patient ? patientCode : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>{' '}
      <Grid item xl={2} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="birthdayShort" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {exam?.patient ? (
              moment(exam?.patient.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="age_" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {exam?.patient ? (
              exam?.patient.dob &&
              `${moment().diff(exam?.patient.dob, 'years', false)} (${
                moment().diff(exam?.patient.dob, 'months', false) % 12
              })`
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={2} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="gender" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {exam?.patient ? (
              exam?.patient.gender && <FormattedMessage id={exam?.patient.gender?.toLowerCase() || ' '} />
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="telephone" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {exam?.patient ? exam?.patient.mobilePhone : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={2} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="address" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {exam?.patient ? (
              exam?.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="category" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {indicationRequest ? (
              <FormattedMessage id={PAYMENT_PLAN?.[indicationRequest.paymentPlan]?.label || ' '} />
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={4} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="indication" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {indicationRequest ? indicationRequest.serviceName : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PatientInfoBox;
