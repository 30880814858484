import { useSelector } from 'react-redux';
import { ROLES } from '../constants';
import { AppState } from 'redux/reducer';
import { flatMapDeep, uniq } from 'lodash';
import { UserEmployeeRole } from 'modules/schema';
import { useMemo } from 'react';

// if not provide roles will return all UserRoles
export const useUserRoles = (...roles: (keyof typeof ROLES)[]) => {
  const roleMap = useSelector((state: AppState) => state.common.roles);
  return useMemo(() => {
    if (!roles || roles.length <= 0) return flatMapDeep(Object.values(roleMap));
    return uniq(roles).reduce<UserEmployeeRole[]>((arr, role) => [...arr, ...roleMap[role]], []);

    // use ...roles in dependencies to prevent unneccesary caculate
    //  if call useUserRoles("ADMIN")
    // => roles = ["ADMIN"] (new array)
    // => if just use roles so dependencies in useMeno change
    // => unnecessary recalculate

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleMap, ...roles]);
};

export const useUserRoleOptions = (...roles: (keyof typeof ROLES)[]) => {
  const roleMap = useSelector((state: AppState) => state.common.roles);
  return useMemo(() => {
    if (!roles || roles.length <= 0) return flatMapDeep(Object.values(roleMap));
    return (
      uniq(roles)
        .reduce<UserEmployeeRole[]>((arr, role) => [...arr, ...roleMap[role]], [])
        ?.map((user) => ({ value: user.id!, label: user.employeeFullName!, group: user.roleName! })) || []
    );

    // use ...roles in dependencies to prevent unneccesary caculate
    //  if call useUserRoles("ADMIN")
    // => roles = ["ADMIN"] (new array)
    // => if just use roles so dependencies in useMeno change
    // => unnecessary recalculate

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roleMap, ...roles]);
};
