import { Box, Button, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaForm from 'modules/common/SchemaForm';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import { FormattedMessage } from 'react-intl';
import TableList from './TableList';

export interface PrescriptionForm {
  id?: string;
  list: some[];
  presNote?: string;
  name?: string;
}
interface Props {
  readOnly?: boolean;
  formData?: PrescriptionForm;
  footer?: React.ReactNode;
  submit: (value) => void;
  listTemplate?: some[];
  drugData: some[];
}

const PrescriptionFormData: React.FunctionComponent<Props> = (props) => {
  const { readOnly, formData, footer, submit, listTemplate, drugData } = props;
  const { intl } = useGeneralHook();
  const refFormData = React.useRef<any>(formData);
  const methods = useForm<PrescriptionForm>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
    defaultValues: formData,
  });
  const { handleSubmit, reset, control } = methods;

  const arrayMethods = useFieldArray({
    control: control,
    name: 'list',
    keyName: 'key',
  });
  const { append, fields } = arrayMethods;

  React.useEffect(() => {
    if (JSON.stringify(refFormData.current) !== JSON.stringify(formData)) {
      reset(formData);
    }
    refFormData.current = formData;
  }, [formData, reset]);

  return (
    <FormProvider {...methods}>
      <Box component="form" onSubmit={handleSubmit(submit)} bgcolor="white">
        <Box marginTop={2} marginBottom={0.5}>
          <SchemaElement
            fieldName={'name'}
            shouldUnregister={true}
            propsElement={{
              type: 'text-field',
              label: intl.formatMessage({ id: 'encounterPrescription.templateName' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              register: {
                required: true,
                validate: (value) => {
                  if (
                    !formData?.id &&
                    listTemplate &&
                    listTemplate
                      ?.filter((v) => v.id !== formData?.id)
                      ?.findIndex((v) => v.name?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) !== -1
                  ) {
                    return intl.formatMessage({ id: 'uniqName' });
                  }
                  return true;
                },
              },
            }}
          />
        </Box>
        <Box>
          {!readOnly && (
            <Box position="sticky" paddingTop={2} top={0} bgcolor={'background.paper'} zIndex={2}>
              <SchemaForm
                key={formData?.id}
                hideSubmitButton
                schema={{
                  fields: ({ valuesField }) => {
                    return {
                      title: {
                        type: () => (
                          <Typography variant="h6">
                            <FormattedMessage id="encounterPrescription.addSingleMedicationRequest" />
                          </Typography>
                        ),
                        unregister: true,
                      },
                      group: {
                        type: 'auto-complete',
                        label: intl.formatMessage({ id: 'encounterPrescription.groupDrug' }),
                        placeholder: intl.formatMessage({ id: 'encounterPrescription.groupDrugEnter' }),
                        noHelperText: true,
                        propsWrapper: { xs: 4 },
                        options: drugData,
                        getOptionLabel: (option) => option.title,
                        isOptionEqualToValue: (option, selected) => option.id === selected.id,
                      },
                      name: {
                        type: 'auto-complete',
                        label: intl.formatMessage({ id: 'encounterPrescription.drugName' }),
                        placeholder: intl.formatMessage({ id: 'encounterPrescription.drugNameEnter' }),
                        noHelperText: true,
                        propsWrapper: { xs: 8 },
                        options: valuesField?.group
                          ? valuesField.group?.items?.map((v) => ({
                              ...v,
                              medicationId: v?.id,
                              groupName: valuesField.group?.title,
                              quantity: 1,
                              instruction: null,
                              intendedRoute: v.intendedRouteOption ? v.intendedRouteOption[0] : null,
                            }))
                          : drugData.reduce((val: some[], cur: some) => {
                              return [
                                ...val,
                                ...(cur?.items || [])?.map((v) => ({
                                  ...v,
                                  medicationId: v?.id,
                                  group: cur.title,
                                  groupName: cur.title,
                                  quantity: 1,
                                  instruction: null,
                                  intendedRoute: v.intendedRouteOption ? v.intendedRouteOption[0] : null,
                                })),
                              ];
                            }, []),
                        groupBy: (option) => option.group,
                        onChange: (value) => {
                          if (value) {
                            append(value);
                          }
                        },
                        getOptionLabel: (option) => {
                          return option ? `${option.name} (${option.ingredient})` : ' ';
                        },
                        isOptionEqualToValue: (option, selected) => option.id === selected.id,
                        getOptionDisabled: (option) =>
                          fields.some((v: some) => String(v.medicationId) === String(option.id)),
                        disableCloseOnSelect: true,
                        renderOption: (_, option) => (
                          <Tooltip
                            arrow
                            placement="left-start"
                            title={
                              <Box>
                                <Typography variant="body1">
                                  <FormattedMessage id="encounterPrescription.registerNumber" />
                                  :&nbsp;
                                  <Typography variant="subtitle1" component="span">
                                    {option.registrationNumber}
                                  </Typography>
                                </Typography>
                                <Typography variant="body1">
                                  <FormattedMessage id="encounterPrescription.groupDrug" />
                                  :&nbsp;
                                  <Typography variant="subtitle1" component="span">
                                    {option.groupName}
                                  </Typography>
                                </Typography>
                                <Typography variant="body1">
                                  <FormattedMessage id="encounterPrescription.drugName" />
                                  :&nbsp;
                                  <Typography variant="subtitle1" component="span">
                                    {option.name}
                                  </Typography>
                                </Typography>
                                <Typography variant="body1">
                                  <FormattedMessage id="encounterPrescription.ingredients" />
                                  :&nbsp;
                                  <Typography variant="subtitle1" component="span">
                                    {option.ingredient}
                                  </Typography>
                                </Typography>
                                <Typography variant="body1">
                                  <FormattedMessage id="encounterPrescription.unit" />
                                  :&nbsp;
                                  <Typography variant="subtitle1" component="span">
                                    {option.numeratorUnit}
                                  </Typography>
                                </Typography>
                              </Box>
                            }
                          >
                            <li {..._}>
                              <Typography variant="subtitle2">
                                {option ? `${option.name} (${option.ingredient})` : ' '}
                              </Typography>
                            </li>
                          </Tooltip>
                        ),
                      },
                    };
                  },
                }}
              />
            </Box>
          )}
          <TableList readOnly={readOnly} arrayMethods={arrayMethods} />
          <Box marginTop={2}>
            <SchemaElement
              fieldName={'presNote'}
              propsElement={{
                readOnly,
                type: 'text-field',
                multiline: true,
                rows: 5,
                label: intl.formatMessage({ id: 'encounterPrescription.note' }),
                placeholder: intl.formatMessage({ id: 'encounterPrescription.noteEnter' }),
                noHelperText: false,
              }}
            />
          </Box>
          <Box paddingY={2} gap={2} display="flex" justifyContent={'flex-end'}>
            {footer}
            {!readOnly && (
              <Button type="submit" variant="contained" style={{ width: 180 }}>
                <FormattedMessage id="save" />
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </FormProvider>
  );
};

export default PrescriptionFormData;
