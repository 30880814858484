import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { CARE_PLAN_SCOPE, CARE_PLAN_TYPE } from 'modules/common/apiConstants';

export const CarePlanSchema = z
  .object({
    id: z.number(),
    referenceId: z.number(),
    scope: createObjectKeysEnumSchema(CARE_PLAN_SCOPE),
    type: createObjectKeysEnumSchema(CARE_PLAN_TYPE),
    hospitalCode: z.string(),
    hospitalName: z.string(),
    note: z.string(),
    groupId: z.number(),
  })
  .partial();

export type CarePlan = z.infer<typeof CarePlanSchema>;
