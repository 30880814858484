import moment from 'moment';
import { ISchemaFields, ISchemaForm } from 'modules/common/SchemaForm/utils';
import {
  BE_DATE_FORMAT,
  FE_TIME_FORMAT,
  FE_TIME_ZONE_FORMAT,
  THERAPY_PROCEDURES_ANESTHESIA_OPTIONS,
} from 'modules/common/constants';
import ParticipantBox from './ParticipantDialog/ParticipantBox';

export const fieldSchema: ISchemaFields = ({ methods, formProps, valuesField }) => {
  const { intl, loading, formData } = formProps || {};

  return {
    indicationRequestId: {
      type: 'hidden',
    },
    date: {
      type: 'datePicker',
      label: intl.formatMessage({ id: 'procedure.date' }),
      propsWrapper: { xs: 4 },
      register: {
        validate: (value) => {
          return value
            ? moment(value, BE_DATE_FORMAT, true).isValid()
              ? true
              : intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
        required: !!valuesField.startTime || !!valuesField.endTime,
      },
      required: !!valuesField.startTime || !!valuesField.endTime,
    },
    startTime: {
      type: 'timePicker',
      label: intl.formatMessage({ id: 'procedure.startDate' }),
      propsWrapper: { xs: 4 },
      register: {
        validate: (value) => {
          return !!valuesField.date
            ? valuesField.endTime && value && moment(valuesField.endTime, 'HH:mm').isAfter(moment(value, 'HH:mm'))
              ? true
              : intl.formatMessage({ id: 'procedure.timeInvalid' })
            : true;
        },
        required: !!valuesField.date || !!valuesField.endTime,
      },
      required: !!valuesField.date || !!valuesField.endTime,
    },
    endTime: {
      type: 'timePicker',
      label: intl.formatMessage({ id: 'procedure.endDate' }),
      propsWrapper: { xs: 4 },
      register: {
        validate: (value) => {
          return value
            ? moment(value, FE_TIME_FORMAT, true).isValid()
              ? true
              : intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
        required: !!valuesField.date || !!valuesField.startTime,
      },
      required: !!valuesField.date || !!valuesField.startTime,
    },
    participants: {
      type: ParticipantBox,
      loading: loading,
      control: methods.control,
      isCreate: !formData?.procedureId,
      unregister: true,
      name: 'participants',
      indicationRequestId: valuesField?.indicationRequestId,
    },
    anesthesiaMethod: {
      type: 'select',
      label: intl.formatMessage({ id: 'procedure.anesthesia' }),
      placeholder: intl.formatMessage({ id: 'procedure.anesthesiaEnter' }),
      options: THERAPY_PROCEDURES_ANESTHESIA_OPTIONS,
      defaultValue: 'NOT_AVAILABLE',
      disableClearBtn: true,
      propsWrapper: { xs: 4 },
    },
    diagram: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'procedure.action' }),
      placeholder: intl.formatMessage({ id: 'procedure.actionEnter' }),
      multiline: true,
      rows: 3,
    },
    diagnosis: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'procedure.diagnosisAfter' }),
      placeholder: intl.formatMessage({ id: 'procedure.diagnosisEnter' }),
      multiline: true,
      rows: 3,
      propsWrapper: { xs: 6 },
    },
    note: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'note' }),
      placeholder: intl.formatMessage({ id: 'noteEnter' }),
      multiline: true,
      rows: 3,
      propsWrapper: { xs: 6 },
    },
  };
};

export const procedureFormSchema: ISchemaForm = {
  fields: fieldSchema,
  changeDataBeforeSubmit: (data) => ({
    ...data,
    startTime: data.date
      ? data.date + (data.startTime ? 'T' + moment(data.startTime, FE_TIME_FORMAT).format(FE_TIME_ZONE_FORMAT) : '')
      : null,
    endTime: data.date
      ? data.date + (data.endTime ? 'T' + moment(data.endTime, FE_TIME_FORMAT).format(FE_TIME_ZONE_FORMAT) : '')
      : null,
  }),
};
