import * as React from 'react';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { Box, Button, Checkbox, Grid, Typography } from '@mui/material';
import SchemaForm from '../../../../common/SchemaForm';
import {
  some,
  SYSTEM_CATEGORY_SCOPE,
  SYSTEM_CATEGORY_SCOPE_LABEL,
  INDICATION_REQUEST_STATUS,
} from '../../../../common/constants';
import { setLoading } from '../../../../common/redux/commonReducer';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import Delete from '@mui/icons-material/Delete';
import { API_SERVER } from '../../../../common/api';
import IndicationTableList from './IndicationTableList';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { fetchThunk } from '../../../../common/redux/thunk';
import NoteFormDialog from '../../detail/testService/NoteFormDialog';
import Print from '@mui/icons-material/Print';
import IndicationRequestPrintForm from './IndicationRequestPrintForm';
import { useState } from 'react';
import { MedicineBold } from '../../../../../svg';
import { useFetch } from 'modules/common/hook';

interface Props {
  therapySessionId: number;
  onDataLoading?: boolean;
  indicationList?: some[];
  onRequestService: (indication: some) => void;
  onUpdateIndication: (indication: some) => void;
  onDeleteIndication: (indication: some) => void;
  editable: boolean;
  revalidateTherapySession: () => void;
  therapy: some;
  therapySession: some;
}

const TherapyIndicationList: React.FunctionComponent<Props> = (props) => {
  const {
    therapySessionId,
    indicationList,
    onRequestService,
    onUpdateIndication,
    onDeleteIndication,
    editable,
    revalidateTherapySession,
    therapy,
    therapySession,
  } = props;
  const { dispatch, intl, openSnackbar, confirmDialog } = useGeneralHook();
  const [noteIndication, setNoteIndication] = React.useState<some>();
  const [openIndicationRequestPrintForm, setOpenIndicationRequestPrintForm] = useState(false);
  const { data: serviceCategoryList = [] } = useFetch(
    API_SERVER.service.getAllSystemCategoryList({
      scope: [
        SYSTEM_CATEGORY_SCOPE.surgical,
        SYSTEM_CATEGORY_SCOPE.laboratory,
        SYSTEM_CATEGORY_SCOPE.other,
        SYSTEM_CATEGORY_SCOPE.radiology,
      ],
    }),
  );

  const existingServiceId: number[] | undefined = React.useMemo(() => {
    return indicationList?.filter((i) => i?.status !== 'DELETED').map((v) => v.serviceId);
  }, [indicationList]);

  const serviceAggregateList = React.useMemo(() => {
    if (!serviceCategoryList) {
      return [];
    }
    const mapServiceType = new Map<string, some[]>();
    serviceCategoryList.forEach((group) => {
      if (!group.parentId) {
        const listCategoryFiltered = serviceCategoryList.filter((g) => g.parentId === group.id);
        listCategoryFiltered.forEach((g) => {
          mapServiceType.set(group?.scope || '', [
            ...(mapServiceType.get(group?.scope || '') || []),
            {
              ...g,
              label: group.name,
            },
          ]);
        });
      }
    });
    const result: some[] = [];
    for (let key of mapServiceType.keys()) {
      const label = SYSTEM_CATEGORY_SCOPE_LABEL.find((v) => v.value === key)?.label;
      result.push({
        serviceType: key,
        name: intl.formatMessage({ id: label || ' ' }),
        groups: mapServiceType.get(key),
      });
    }
    return result;
  }, [serviceCategoryList, intl]);

  const requestedIndicationRequest =
    indicationList?.filter((indication) => indication?.status === INDICATION_REQUEST_STATUS.REQUESTED.value) || [];

  const showPrintIndicationButton = indicationList && indicationList.length && requestedIndicationRequest.length > 0;

  const onRequestIndication = React.useCallback(
    async (service: some) => {
      if (service) {
        const serviceRequest = {
          serviceId: service.id,
          serviceName: service.name,
          referenceId: therapySessionId,
          isFree: true,
          type: 'THERAPY_SESSION',
        };
        try {
          dispatch(setLoading(true));
          const res = await dispatch(
            fetchThunk(API_SERVER.therapyIndication.request, 'post', serviceRequest, 'application/json-patch+json'),
          );
          onRequestService(res);

          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
    },
    [dispatch, intl, onRequestService, openSnackbar, therapySessionId],
  );

  const onChangeIsFree = React.useCallback(
    async (indication: some) => {
      try {
        const res = await dispatch(
          fetchThunk(
            API_SERVER.therapyIndication.update(indication?.id),
            'put',
            indication,
            'application/json-patch+json',
          ),
        );
        onUpdateIndication(res);

        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      }
    },
    [dispatch, intl, onUpdateIndication, openSnackbar],
  );

  const onDelete = React.useCallback(
    async (indication: some) => {
      try {
        const confirm = await confirmDialog.promptConfirmation({
          title: intl.formatMessage({ id: 'confirm' }),
          content: intl.formatMessage({ id: 'therapyIndication.request.confirmDelete' }),
        });
        if (!confirm) {
          confirmDialog.close();
          return;
        }

        await dispatch(
          fetchThunk(API_SERVER.therapyIndication.delete(indication?.id), 'delete', 'application/json-patch+json'),
        );
        onDeleteIndication(indication);

        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, onDeleteIndication, openSnackbar],
  );

  const onComplete = React.useCallback(
    async (indication: some) => {
      try {
        const confirm = await confirmDialog.promptConfirmation({
          title: intl.formatMessage({ id: 'confirm' }),
          content: intl.formatMessage({ id: 'therapy.detail.indication.text.finishConfirm' }),
        });
        if (!confirm) {
          confirmDialog.close();
          return;
        }

        const res = await dispatch(
          fetchThunk(API_SERVER.therapyIndication.finish(indication?.id), 'put', {}, 'application/json-patch+json'),
        );
        onUpdateIndication(res);
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, onUpdateIndication, openSnackbar],
  );

  const onRequest = React.useCallback(
    async (indication: some) => {
      try {
        const confirm = await confirmDialog.promptConfirmation({
          title: intl.formatMessage({ id: 'confirm' }),
          content: intl.formatMessage({ id: 'therapy.detail.indication.text.requestConfirm' }),
        });
        if (!confirm) {
          confirmDialog.close();
          return;
        }

        await dispatch(
          fetchThunk(
            API_SERVER.therapyIndication.requestIndication(indication?.id),
            'put',
            {},
            'application/json-patch+json',
          ),
        );
        await revalidateTherapySession();

        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, revalidateTherapySession],
  );

  const onUpdateNote = React.useCallback(
    async (indication: some) => {
      try {
        const res = await dispatch(
          fetchThunk(
            API_SERVER.therapyIndication.update(indication?.id),
            'put',
            {
              ...indication,
              note: indication.note,
            },
            'application/json-patch+json',
          ),
        );
        onUpdateIndication(res);
        setNoteIndication(undefined);
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      }
    },
    [dispatch, intl, onUpdateIndication, openSnackbar],
  );

  const readOnly = !editable;

  return (
    <Box bgcolor="white">
      <Typography className="title">
        <FormattedMessage id="therapy.detail.label.instruction" />
      </Typography>
      <Box padding={1} className="therapy-indication">
        {!readOnly && (
          <Box marginBottom={2} bgcolor={'#F4F8FF'} padding={2}>
            <SchemaForm
              hideSubmitButton
              schema={{
                fields: ({ valuesField, methods }) => {
                  const { setValue } = methods;

                  const serviceTypeOptions = serviceAggregateList;
                  const groupOptions = valuesField.serviceType
                    ? valuesField?.serviceType?.groups
                    : serviceAggregateList.reduce((val: some[], cur: some) => {
                        return [
                          ...val,
                          ...(cur.groups.map((v) => ({
                            ...v,
                            group: cur.name,
                          })) || []),
                        ];
                      }, []);

                  const serviceOptions = valuesField.group
                    ? valuesField?.group?.items?.map((one) => ({
                        ...one,
                        group: valuesField.group.name,
                      }))
                    : groupOptions.reduce((val: some[], cur: some) => {
                        return [
                          ...val,
                          ...(cur.items?.map((v) => ({
                            ...v,
                            group: cur.name,
                          })) || []),
                        ];
                      }, []);

                  return {
                    serviceType: {
                      type: 'auto-complete',
                      options: serviceTypeOptions,
                      getOptionLabel: (option: some) => option.name || '',
                      label: intl.formatMessage({ id: 'therapyIndication.header.label.serviceType' }),
                      placeholder: intl.formatMessage({ id: 'therapyIndication.header.label.serviceTypeSelect' }),
                      onChange: (e, v, r) => {
                        setValue('group', null);
                        setValue('service', null);
                      },
                      propsWrapper: { xs: 3 },
                    },
                    group: {
                      type: 'auto-complete',
                      options: groupOptions || [],
                      getOptionLabel: (option) => option.name,
                      isOptionEqualToValue: (opt, value) => opt.id === value.id,
                      label: intl.formatMessage({ id: 'procedure.priceType' }),
                      placeholder: intl.formatMessage({ id: 'encounterList.groupPriceTypeEnter' }),
                      groupBy: (option) => option.label,
                      onChange: (e, v, r) => {
                        setValue('service', null);
                      },
                      propsWrapper: { xs: 4 },
                    },
                    service: {
                      type: 'auto-complete',
                      propsWrapper: { xs: 5 },
                      options: serviceOptions || [],
                      disableCloseOnSelect: true,
                      getOptionLabel: (option) => option.name,
                      isOptionEqualToValue: (opt, value) => opt.id === value.id,
                      groupBy: (option) => option.group,
                      label: intl.formatMessage({ id: 'serviceName' }),
                      placeholder: intl.formatMessage({ id: 'serviceNameSelect' }),
                      onChange: (v) => {
                        onRequestIndication(v);
                      },
                      renderOption: (props, option) => {
                        return (
                          <li {...props} key={option?.id}>
                            <Box alignItems="center" display="flex">
                              <Checkbox
                                size="small"
                                color="primary"
                                style={{ marginRight: 8, padding: 0 }}
                                checked={existingServiceId && existingServiceId?.indexOf(option.id) > -1}
                              />
                              <Typography variant="body2" component="div">
                                {option.name}
                              </Typography>
                            </Box>
                          </li>
                        );
                      },
                      // getOptionDisabled: (option) => existingServiceId && existingServiceId?.indexOf(option.id) > -1,
                    },
                  };
                },
              }}
            />
          </Box>
        )}
        <Box>
          <Grid container spacing={2} justifyContent={'flex-end'}>
            <Grid item xs={12}>
              <IndicationTableList
                revalidateTherapySession={revalidateTherapySession}
                readOnly={readOnly}
                // loading={isValidating && isFirstLoad}
                data={indicationList?.filter((i) => i?.status !== 'DELETED').sort((e1, e2) => e2?.id - e1?.id)}
                onChangeIsFreeStatus={onChangeIsFree}
                actionNode={(record) => (
                  <>
                    {record?.status === 'WAIT' && (
                      <IconButtonTitle
                        key="request"
                        disableRipple
                        title={'request'}
                        size="small"
                        disabled={readOnly}
                        onClick={async () => {
                          onRequest(record);
                        }}
                        sx={{ '&.Mui-disabled path': { fill: '#A6AFBD' } }}
                      >
                        <MedicineBold />
                      </IconButtonTitle>
                    )}
                    <IconButtonTitle
                      key="delete-btn"
                      title="delete"
                      size="small"
                      onClick={async () => {
                        onDelete(record);
                      }}
                      disabled={record.status === 'FINISHED' || record.isPaid || readOnly}
                    >
                      <Delete fontSize="small" />
                    </IconButtonTitle>
                    <IconButtonTitle
                      title="note"
                      key="note-btn"
                      size="small"
                      onClick={() => setNoteIndication({ ...record })}
                      disabled={readOnly}
                    >
                      {record.note ? (
                        <DescriptionSharpIcon fontSize="small" />
                      ) : (
                        <InsertDriveFileSharpIcon fontSize="small" />
                      )}
                    </IconButtonTitle>

                    {record?.status === 'FINISHED' ? (
                      <IconButtonTitle disableRipple size="small">
                        <AssignmentTurnedInIcon color="success" />
                      </IconButtonTitle>
                    ) : (
                      <IconButtonTitle
                        key="completed"
                        disableRipple
                        title={'complete'}
                        size="small"
                        disabled={readOnly}
                        onClick={async () => {
                          onComplete(record);
                        }}
                      >
                        <AssignmentTurnedInIcon />
                      </IconButtonTitle>
                    )}
                  </>
                )}
              />
            </Grid>
            <Grid
              item
              style={{
                justifyContent: 'flex-end',
                display: 'flex',
              }}
            >
              <Button
                variant="contained"
                color="primary"
                startIcon={<Print color="inherit" />}
                style={{ width: 180 }}
                onClick={() => {
                  setOpenIndicationRequestPrintForm(true);
                }}
                disabled={!showPrintIndicationButton}
              >
                <Typography variant="body2">
                  <FormattedMessage id="printForm.printRequired" />
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <NoteFormDialog
        open={!!noteIndication}
        onClose={() => {
          setNoteIndication(undefined);
        }}
        formData={noteIndication}
        onSubmit={onUpdateNote}
        readOnly={noteIndication?.status === 'FINISHED'}
      />
      <IndicationRequestPrintForm
        open={openIndicationRequestPrintForm}
        onClose={() => setOpenIndicationRequestPrintForm(false)}
        therapy={therapy}
        therapySession={therapySession}
        indicationList={requestedIndicationRequest}
      />
    </Box>
  );
};

export default TherapyIndicationList;
