import { Paper } from '@mui/material';
import * as React from 'react';
import { INDICATION_REQUEST_TYPE, some } from '../../common/constants';
import usePaginationHook from '../../common/hook/usePaginationHook';
import FilterIndicationBox from '../component/indication/FilterEncounterBox';
import IndicationList from '../component/indication/IndicationList';
import { useFetch } from 'modules/common/hook';
import { formatDateFilterField } from 'modules/common/utils';
import PageHeader from 'modules/common/component/PageHeader';
import { API_SERVER } from 'modules/common/api';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';

interface Props {}

const ImageDoctorIndicationList: React.FunctionComponent<Props> = () => {
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;

  const { data, isValidating } = useFetch(
    API_SERVER.indicationRequest.getList({
      ...formatDateFilterField({ ...params, sortBy: 'createdTime' }, 'fromDate', 'toDate'),
      scope: SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
      type: INDICATION_REQUEST_TYPE.ENCOUNTER,
    }),
  );

  const changeStatusTest = React.useCallback((value: some) => {}, []);

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterIndicationBox
        hookPagination={hookPagination}
        dataLoading={isValidating}
        pagin={
          <PageHeader
            titleKey="labDoctor.list"
            pagination={{
              size: data?.content.length || 0,
              totalElements: data?.pagination.totalElements || 0,
            }}
          />
        }
      />
      <IndicationList
        data={data?.content}
        changeStatusTest={changeStatusTest}
        total={data?.pagination.totalElements || 0}
        loading={isValidating}
        hookPagination={hookPagination}
      />
    </Paper>
  );
};

export default ImageDoctorIndicationList;
