import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import TableCustom, { Columns } from '../../../../../common/component/TableCustom';
import { some } from '../../../../../common/constants';
import CancelIcon from '@mui/icons-material/Cancel';
import { THERAPY_STATUS } from '../../../../constants';
import { setLoading } from '../../../../../common/redux/commonReducer';
import { axiosThunk } from '../../../../../common/redux/axios';
import { API_SERVER } from '../../../../../common/api';
import { AxiosError } from 'axios/index';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { ROUTES } from '../../../../../layout/router';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import { NumericFormatText } from 'modules/common/utils';
import NoteFormDialog from '../../../../../examDoctor/component/detail/testService/NoteFormDialog';

interface Props {
  data?: some;
  onChangeStatus: (item: some) => void;
  readOnly?: boolean;
  revalidate: () => Promise<boolean>;
  isAuthor?: boolean;
}

const TherapyTable: React.FunctionComponent<Props> = (props) => {
  const { data, onChangeStatus, readOnly, revalidate, isAuthor } = props;
  const [noteData, setNoteData] = React.useState<some | undefined>();
  const { intl, dispatch, openSnackbar } = useGeneralHook();

  const updateNote = React.useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: API_SERVER.therapy.note(value?.id),
          method: 'put',
          data: {
            data: value.note,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: 'encounterInfo.takeNoteSuccess' }) });
          //revalidate();
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({
                message: v.message,
                type: 'error',
              });
            });
        })
        .finally(() => {
          dispatch(setLoading(false));
          setNoteData(undefined);
          revalidate();
        });
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: 'therapy.name',
        dataIndex: 'name',
        render: (record) => {
          return (
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              color="primary"
              onClick={() => {
                if (!readOnly) {
                  window.open(`${ROUTES.therapyDetail.gen(record?.id)}`, '_blank');
                }
              }}
            >
              {record.name}
            </Typography>
          );
        },
      },
      {
        title: 'therapy.quantity',
        align: 'left',
        dataIndex: 'numberSession',
        render: (record) => record.numberSession && <FormattedNumber value={record.numberSession} />,
      },
      {
        title: 'therapy.packagePrice',
        align: 'left',
        dataIndex: 'packagePrice',
        render: (record) => {
          return <Typography variant="body1">{NumericFormatText(record.packagePrice)}</Typography>;
        },
      },
      {
        title: 'therapy.totalPriceAllSessions',
        align: 'left',
        dataIndex: 'totalPrice',
        render: (record) => {
          return <Typography variant="body1">{NumericFormatText(record.totalPrice)}</Typography>;
        },
      },
      {
        title: 'therapy.description',
        align: 'left',
        dataIndex: 'description',
        render: (record) => <Typography variant="body1">{record.description}</Typography>,
      },
      {
        title: 'therapy.status',
        align: 'left',
        dataIndex: 'status',
        render: (record) => {
          const label = THERAPY_STATUS.find((v) => v.value === record.status)?.label;
          return (
            <Typography
              noWrap
              sx={{
                color:
                  record.status === 'PROCESSING'
                    ? 'warning.main'
                    : record.status === 'FINISHED'
                    ? 'success.main'
                    : record.status === 'CANCELLED'
                    ? 'error.main'
                    : 'primary.main',
              }}
            >
              <FormattedMessage id={label} />
            </Typography>
          );
        },
      },
      {
        title: 'action',
        align: 'right',
        width: 120,
        hidden: !isAuthor,
        render: (record) => {
          return (
            <>
              {record.status !== 'CANCELLED' && record.status !== 'FINISHED' && (
                <IconButtonTitle
                  disabled={record.status === 'CANCELLED' || readOnly}
                  title="encounterInfo.cancelTherapy"
                  size="small"
                  onClick={() => {
                    onChangeStatus({
                      ...record,
                      status: 'CANCELLED',
                    });
                  }}
                >
                  <CancelIcon sx={{ color: '#999797FF', width: '100%' }} />
                </IconButtonTitle>
              )}
              <IconButtonTitle
                disabled={readOnly}
                title="encounterInfo.takeNote"
                size="small"
                onClick={() => {
                  setNoteData({
                    ...record,
                    readOnly: record.status === 'CANCELLED',
                  });
                }}
              >
                {record.note ? (
                  <DescriptionSharpIcon fontSize="small" />
                ) : (
                  <InsertDriveFileSharpIcon fontSize="small" />
                )}
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns[];
  }, [isAuthor, onChangeStatus, readOnly]);

  if (!data) {
    return null;
  }

  return (
    <Box padding={1}>
      <TableCustom dataSource={data.therapies} columns={columns} />
      <NoteFormDialog
        open={!!noteData}
        onClose={() => {
          setNoteData(undefined);
        }}
        readOnly={readOnly || noteData?.readOnly}
        formData={noteData}
        onSubmit={updateNote}
      />
    </Box>
  );
};

export default TherapyTable;
