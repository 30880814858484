import { useCallback, useEffect, useRef, useState } from 'react';
import Webcam from 'react-webcam';

function useWebcam() {
  const [screenShots, setScreenshot] = useState<string[]>([]);
  const webcamRef = useRef<Webcam | null>(null);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const [capturing, setCapturing] = useState<boolean>(false);

  const onCapture = useCallback((callback?: (screenShot: string) => void) => {
    if (webcamRef.current) {
      const imageSrc = webcamRef.current.getScreenshot();
      if (imageSrc) {
        setScreenshot((prev) => [...prev, imageSrc]);
        callback && callback(imageSrc);
      }
    }
  }, []);

  const onStopAutoCapture = useCallback(() => {
    if (intervalRef.current) {
      setCapturing(false);
      clearInterval(intervalRef.current);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (intervalRef.current) {
        onStopAutoCapture();
      }
    };
  }, [onStopAutoCapture]);
  const onAutoCapture = useCallback(
    (duration: number, callback?: (screenShot: string) => void) => {
      if (webcamRef.current) {
        setCapturing(true);
        intervalRef.current = setInterval(() => {
          onCapture(callback);
        }, duration);
      }
    },
    [onCapture],
  );

  return { screenShots, capturing, ref: webcamRef, onCapture, onAutoCapture, onStopAutoCapture };
}

export default useWebcam;
