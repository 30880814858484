import { Print } from '@mui/icons-material';
import { Button, Stack, styled } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import { useUpdateMutate } from 'modules/common/hook/useMutate';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { mutate } from 'swr';
import { useDentalDetailStateSelector } from '../state';
import { DENTAL_EXAM_STATUS } from 'modules/common/constants';
import CheckIcon from '@mui/icons-material/Check';
import DentalPrintForm from '../DentalPrintForm';
import { useDialog } from 'modules/common/hook/useDialog';

const ButtonStyled = styled(Button)({
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '18px',
  letterSpacing: '0.2px',
  padding: '10px 16px',
});

const DentalExamActions = () => {
  const { dentalSessionId } = useParams<{ dentalSessionId }>();
  const readOnly = useDentalDetailStateSelector.use.readonly();
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const isFinished = useDentalDetailStateSelector<boolean>((state) => {
    if (!state.dentalExamInfo) return false;
    return state.dentalExamInfo.status === DENTAL_EXAM_STATUS.FINISHED.value;
  });

  const updateMutate = useUpdateMutate({
    confirmMessage: 'dental.dentalDetail.dentalExamAction.confirmMessage.complete',
    onSuccess() {
      mutate(API_SERVER.dental.getDentalExam(dentalSessionId).url);
    },
  });
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignContent="center" gap={4}>
        <ButtonStyled
          fullWidth
          onClick={() => updateMutate({ url: API_SERVER.dental.complete(dentalSessionId), method: 'put' })}
          disabled={isFinished || readOnly}
          sx={{ color: isFinished ? '#43A047 !important' : 'white' }}
          startIcon={isFinished ? <CheckIcon sx={{ color: '#43A047' }} /> : null}
        >
          {isFinished ? (
            <FormattedMessage id={DENTAL_EXAM_STATUS.FINISHED.label} />
          ) : (
            <FormattedMessage id="dental.dentalDetail.dentalExamAction.button.label.complete" />
          )}
        </ButtonStyled>
        <ButtonStyled color="inherit" fullWidth startIcon={<Print />} onClick={onOpenPrintDialog}>
          <FormattedMessage id="dental.dentalDetail.dentalExamAction.button.label.printForm" />
        </ButtonStyled>
      </Stack>
      <DentalPrintForm isHospitalTranfer={false} open={openPrintDialog} onClose={onClosePrintDialog} />
    </>
  );
};

export default DentalExamActions;
