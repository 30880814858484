import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { flatMapDeep, uniq } from 'lodash';
import { PhysicsRoom } from 'modules/schema';
import { useMemo } from 'react';
import { PHYSICS_ROOM_CODE } from '../apiConstants';

export const usePhysicRooms = (...roomCodes: (keyof typeof PHYSICS_ROOM_CODE)[]) => {
  const roomMap = useSelector((state: AppState) => state.common.physicRooms);
  return useMemo(() => {
    if (!roomCodes || roomCodes.length <= 0) return flatMapDeep(Object.values(roomMap));
    return uniq(roomCodes).reduce<PhysicsRoom[]>((arr, code) => [...arr, ...roomMap[code]], []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomMap, ...roomCodes]);
};
