import * as React from 'react';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { Box, Grid } from '@mui/material';
import SchemaForm from 'modules/common/SchemaForm';
import { some } from 'modules/common/constants';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import Delete from '@mui/icons-material/Delete';
import { API_SERVER } from 'modules/common/api';
import MedicalSuppliesTableList from './MedicalSuppliesTableList';
import { fetchThunk } from 'modules/common/redux/thunk';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useFetch } from 'modules/common/hook';

interface Props {
  indicationRequestId: string;
}

const TherapyMedicalSuppliesList: React.FunctionComponent<Props> = (props) => {
  const { indicationRequestId } = props;
  const { dispatch, intl, openSnackbar, confirmDialog } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;

  // get list medical supplies
  const { data: medicalSuppliesCategoryList = [] } = useFetch(API_SERVER.medicalSupplies.getList());

  // get list indication medical supplies
  const { data: dataIndicationMedicalSupplies, revalidate } = useFetch(
    API_SERVER.indicationMedicalSupplies.get({ indicationRequestId: parseInt(indicationRequestId) }),
  );

  // create indication medical supplies
  const onSelectMedicalSupplies = React.useCallback(
    async (medicalSupplies: some) => {
      if (medicalSupplies) {
        const medicalSuppliesAdd = {
          medicalSuppliesId: medicalSupplies.id,
          isFree: true,
          quantity: 1,
          indicationRequestId,
        };
        try {
          dispatch(setLoading(true));
          await dispatch(
            fetchThunk(
              API_SERVER.indicationMedicalSupplies.create(),
              'post',
              medicalSuppliesAdd,
              'application/json-patch+json',
            ),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        } catch (e) {
          openSnackbar({
            message: intl.formatMessage({ id: 'createFail' }),
            type: 'error',
          });
        } finally {
          dispatch(setLoading(false));
        }
      }
    },
    [dispatch, indicationRequestId, intl, openSnackbar, revalidate],
  );

  // update indication medical supplies
  const updateMedicalSupplies = React.useCallback(
    async (medicalSupplies: some) => {
      const medicalSuppliesUpdate = {
        medicalSuppliesId: medicalSupplies.medicalSuppliesId,
        isFree: medicalSupplies.isFree,
        quantity: medicalSupplies.quantity,
        indicationRequestId,
      };
      try {
        await dispatch(
          fetchThunk(
            API_SERVER.indicationMedicalSupplies.update(medicalSupplies.id),
            'put',
            medicalSuppliesUpdate,
            'application/json-patch+json',
          ),
        );
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({
          message: intl.formatMessage({ id: 'updateFail' }),
          type: 'error',
        });
      }
    },
    [dispatch, indicationRequestId, intl, openSnackbar, revalidate],
  );

  // switch free indication medical supplies
  const onChangeIsFree = React.useCallback(
    async (supplies: some) => {
      const medicalSuppliesUpdate = {
        medicalSuppliesId: supplies.medicalSuppliesId,
        isFree: supplies.isFree,
        quantity: supplies.quantity,
        indicationRequestId,
      };
      try {
        await dispatch(
          fetchThunk(
            API_SERVER.indicationMedicalSupplies.update(supplies.id),
            'put',
            medicalSuppliesUpdate,
            'application/json-patch+json',
          ),
        );
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({
          message: intl.formatMessage({ id: 'updateFail' }),
          type: 'error',
        });
      }
    },
    [dispatch, indicationRequestId, intl, openSnackbar, revalidate],
  );

  // delete indication medical supplies
  const onDelete = React.useCallback(
    async (supplies: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: supplies.medicalSuppliesName }),
      });
      if (confirm) {
        try {
          await dispatch(
            fetchThunk(
              API_SERVER.indicationMedicalSupplies.delete(supplies?.id),
              'delete',
              'application/json-patch+json',
            ),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({
            message: intl.formatMessage({ id: 'deleteFail' }),
            type: 'error',
          });
        }
        confirmDialog.close();
      }
      close();
    },
    [promptConfirmation, intl, close, confirmDialog, dispatch, revalidate, openSnackbar],
  );

  return (
    <Box bgcolor="white" className="mb20">
      <Box padding={1}>
        <Box marginBottom={2} bgcolor={'#F4F8FF'} padding={2}>
          <SchemaForm
            hideSubmitButton
            schema={{
              fields: ({ valuesField, methods }) => {
                const { setValue } = methods;
                const getMedicationItemOptions = () => {
                  const arr: any[] = [];
                  let categoryList: any[] = [];
                  categoryList = valuesField?.category
                    ? medicalSuppliesCategoryList.filter((v) => v.id === valuesField?.category?.id) || []
                    : medicalSuppliesCategoryList || [];
                  categoryList?.forEach((i) => {
                    i?.items?.forEach((i1: some) => {
                      const obj = {
                        ...i1,
                        group: i.name,
                      };
                      arr.push(obj);
                    });
                  });
                  return arr;
                };
                const categoryOptions = medicalSuppliesCategoryList;

                return {
                  category: {
                    type: 'auto-complete',
                    options: categoryOptions,
                    getOptionLabel: (option: some) => option.name || '',
                    label: intl.formatMessage({ id: 'therapy.medicalSupplies.category' }),
                    placeholder: intl.formatMessage({ id: 'therapy.medicalSupplies.categoryEnter' }),
                    onChange: () => {
                      setValue('medicalSupplies', null);
                    },
                    propsWrapper: { xs: 6 },
                  },
                  medicalSupplies: {
                    type: 'auto-complete',
                    options: getMedicationItemOptions,
                    getOptionLabel: (option) => option.name,
                    isOptionEqualToValue: (opt, value) => opt.id === value.id,
                    label: intl.formatMessage({ id: 'therapy.medicalSupplies.name' }),
                    placeholder: intl.formatMessage({ id: 'therapy.medicalSupplies.nameEnter' }),
                    groupBy: (option) => option.group,
                    onChange: (value) => {
                      if (value && value.id) {
                        onSelectMedicalSupplies(value);
                      }
                    },
                    propsWrapper: { xs: 6 },
                  },
                };
              },
            }}
          />
        </Box>
        <Box paddingBottom={2}>
          <Grid container spacing={2} justifyContent={'flex-end'}>
            <Grid item xs={12}>
              <MedicalSuppliesTableList
                data={dataIndicationMedicalSupplies}
                onChangeIsFreeStatus={onChangeIsFree}
                updateMedicalSupplies={updateMedicalSupplies}
                actionNode={(record) => (
                  <>
                    <IconButtonTitle
                      key="delete-btn"
                      title="delete"
                      size="small"
                      onClick={async () => {
                        onDelete(record);
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButtonTitle>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default TherapyMedicalSuppliesList;
