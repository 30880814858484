import { Box, Typography } from '@mui/material';
import TableCustom, { Columns } from '../../../../common/component/TableCustom';
import * as React from 'react';
import { useMemo } from 'react';
import { IndicationLaboratoryList } from 'modules/schema';
import { displayRange } from 'modules/common/utils';
import { VALUE_EVALUATE } from 'modules/common/constants';

interface Props {
  indicationLabs: IndicationLaboratoryList[];
}

export const INDICATION_LAB_LIST_PRINT_FORM_COLUMNS: Columns[] = [
  {
    dataIndex: 'serviceIndex',
    title: 'stt',
    default: true,
    width: 40,
  },
  {
    dataIndex: 'laboratoryReferenceName',
    title: 'indication.indicationList.lab.printForm.indicationName.title',
    default: true,
    props: (record) => ({
      colSpan: record.serviceIndex ? 4 : 1,
      align: record.serviceIndex ? 'center' : 'left',
    }),
  },
  {
    dataIndex: 'result',
    title: 'indication.indicationList.lab.printForm.result.title',
    default: true,
    align: 'center',
    props: (record) => ({
      colSpan: record?.serviceIndex ? 0 : 1,
    }),
    render: (record) => {
      return (
        <Typography
          variant={'body1'}
          style={{
            fontWeight: record.evaluate && record.evaluate !== VALUE_EVALUATE.normal ? 700 : 'unset',
          }}
        >
          {record?.result}
        </Typography>
      );
    },
  },
  {
    dataIndex: 'laboratoryReferenceDetailObject.unit',
    title: 'indication.indicationList.lab.printForm.unit.title',
    default: true,
    align: 'center',
    props: (record) => ({
      colSpan: record?.serviceIndex ? 0 : 1,
    }),
  },
  {
    title: 'indication.indicationList.lab.printForm.referenceValue.title',
    default: true,
    align: 'center',
    props: (record) => ({
      colSpan: record?.serviceIndex ? 0 : 1,
    }),
    render: (record) => {
      return record?.laboratoryReferenceDetailObject?.laboratoryConditionList
        ?.map((v) =>
          v.name ? `${v.name} : ${displayRange(v.highIndex, v.lowIndex)}` : `${displayRange(v.highIndex, v.lowIndex)}`,
        )
        .join('; ');
    },
  },
];
const IndicationTables = ({ indicationLabs }: Props) => {
  const data = useMemo(() => {
    return (
      indicationLabs?.map((indicationLab) => [
        { serviceIndex: 1, laboratoryReferenceName: indicationLab?.indicationRequest?.serviceName },
        ...(indicationLab?.indicationLaboratoryList || []),
      ]) || []
    );
  }, [indicationLabs]);

  return (
    <Box>
      {indicationLabs.map((indicationLab, index) => {
        return (
          <Box>
            <Typography fontWeight={500}>{indicationLab?.indicationRequest?.systemCategoryName}</Typography>
            <TableCustom
              containerProps={{
                sx: {
                  '& th,tr,td': {
                    padding: '2px 8px',
                    backgroundColor: 'white',
                    border: '1px solid #000',
                    color: 'unset',
                  },
                  width: '100%',
                  overflow: 'visible',
                  marginTop: '5px',
                },
              }}
              dataSource={data[index] || []}
              columns={INDICATION_LAB_LIST_PRINT_FORM_COLUMNS}
              hideColumnIndex
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default IndicationTables;
