import findDeep from 'deepdash/es/findDeep';
import moment, { Moment } from 'moment';
import { some } from '../common/constants';
import { ENTER_REASON_OPTIONS, CATEGORY_INSURANCE } from './constants';
import { PatientCategory } from './model';

export function determineCategory(coverage?: some | null): PatientCategory {
  if (!coverage || coverage.status !== 'active') {
    return CATEGORY_INSURANCE.uninsured as PatientCategory;
  } else if (
    !!findDeep(
      coverage.class,
      (val) => {
        return val?.system === 'http://snomed.info/sct' && val?.code === '276208004';
      },
      { leavesOnly: false },
    )?.value
  ) {
    return CATEGORY_INSURANCE.noCard as PatientCategory;
  } else {
    return CATEGORY_INSURANCE.insured as PatientCategory;
  }
}

export function determineCategory2(type?: some) {
  if (!type) {
    return ENTER_REASON_OPTIONS[4];
  } else if (
    !!findDeep(
      type,
      (val) => val?.system === 'http://terminology.hl7.org/CodeSystem/benefit-network' && val?.code === 'in',
      { leavesOnly: false },
    )?.value
  ) {
    return ENTER_REASON_OPTIONS[0];
  } else if (
    !!findDeep(
      type,
      (val) => val?.system === 'http://terminology.hl7.org/CodeSystem/benefit-network' && val?.code === 'out',
      { leavesOnly: false },
    )?.value
  ) {
    return ENTER_REASON_OPTIONS[1];
  } else if (
    !!findDeep(type, (val) => val?.system === 'http://snomed.info/sct' && val?.code === '25876001', {
      leavesOnly: false,
    })?.value
  ) {
    return ENTER_REASON_OPTIONS[2];
  } else if (
    !!findDeep(type, (val) => val?.system === 'http://snomed.info/sct' && val?.code === '3457005', {
      leavesOnly: false,
    })?.value
  ) {
    return ENTER_REASON_OPTIONS[3];
  } else {
    return ENTER_REASON_OPTIONS[4];
  }
}

export function createPatientRequestBody(formData: some, id?: string) {
  const gender = formData?.gender || '';
  return {
    id,
    resourceType: 'Patient',
    name: {
      text: formData?.name || '',
    },
    telecom: {
      system: 'phone',
      value: formData?.telephone || '',
    },
    birthDate: formData?.birthday || '',
    identifier: [
      {
        type: {
          text: 'CMT/CCCD',
        },
        value: formData?.identification || '',
      },
    ],
    gender,
    address: [
      {
        use: 'home',
        line: [formData.address || ''],
      },
    ],
  };
}

export function createOrganizationRequestBody(formData: some) {
  return {
    resourceType: 'Organization',
    active: true,
    type: [
      {
        coding: [
          {
            system: 'http://snomed.info/sct',
            code: '116154003',
            display: 'Patient',
          },
        ],
      },
    ],
    contact: [
      {
        purpose: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/contactentity-type',
              code: 'PATINF',
              display: 'Patient',
            },
          ],
        },
        name: {
          text: formData.name,
        },
        address: [
          {
            text: formData.address,
          },
        ],
        telecom: [
          {
            system: 'phone',
            value: formData.telephone,
          },
        ],
      },
    ],
  };
}

export function createAccountRequestBody(patientId: string) {
  return {
    resourceType: 'Account',
    subject: [
      {
        reference: `Patient/${patientId}`,
      },
    ],
  };
}

export function createChargeItemRequestBody(itemId: string, accountId: string, priceMode: string, encounterId: string) {
  return {
    resourceType: 'ChargeItem',
    definitionCanonical: [`ChargeItemDefinition/${itemId}`],
    account: [
      {
        reference: `Account/${accountId}`,
      },
    ],
    context: { reference: `Encounter/${encounterId}` },
    note: [{ text: priceMode }],
  };
}

export function createEncounterRequestBody(
  patientId: string,
  accountId: string,
  locationId: string,
  reason: string,
  appointmentId?: string,
) {
  const appointment = appointmentId ? { appointment: [{ reference: `Appointment/${appointmentId}` }] } : {};

  return {
    resourceType: 'Encounter',
    subject: {
      reference: `Patient/${patientId}`,
    },
    account: [
      {
        reference: `Account/${accountId}`,
      },
    ],
    ...appointment,
    location: [
      {
        location: {
          reference: `Location/${locationId}`,
          status: 'planned',
        },
      },
    ],
    period: {
      start: moment().format(),
    },
    reasonCode: [
      {
        text: reason || '',
      },
    ],
    status: 'arrived',
  };
}

export function createRegisterInsuranceInfoReqBody(insuranceId: string, patientId: string) {
  return {
    resourceType: 'Coverage',
    status: 'active',
    type: {
      coding: [
        {
          system: 'http://terminology.hl7.org/CodeSystem/v3-ActCode',
          code: 'PUBLICPOL',
          display: 'public healthcare',
        },
      ],
    },
    identifier: [
      {
        type: {
          coding: [
            {
              system: 'http://terminology.hl7.org/CodeSystem/v2-0203',
              code: 'NIIP',
              display: 'National Insurance Payor Identifier (Payor)',
            },
          ],
        },
        value: insuranceId,
      },
    ],
    beneficiary: {
      reference: `Patient/${patientId}`,
    },
  };
}

export function editAppointmentRequestBody(date: string, reason?: string, reasonPre?: string) {
  return [
    { op: 'replace', path: '/start', value: date },
    reason !== reasonPre && {
      op: !reason ? 'remove' : reasonPre ? 'replace' : 'add',
      path: '/reasonCode',
      value: [{ text: reason }],
    },
  ]?.filter(Boolean);
}

export function createAppointmentRequestBody(patientId: string, locationId: string, date: string, reason: string) {
  return {
    resourceType: 'Appointment',
    participant: [
      {
        actor: {
          reference: `Patient/${patientId}`,
        },
        required: 'required',
        status: 'accepted',
      },
      {
        actor: {
          reference: `Location/${locationId}`,
        },
        required: 'required',
        status: 'accepted',
      },
    ],
    start: date,
    reasonCode: [
      {
        text: reason || '',
      },
    ],
    status: 'booked',
  };
}

export function createAppointmentGroupRequestBody(orgId: string, locationId: string, date: string, reason: string) {
  return {
    resourceType: 'Appointment',
    participant: [
      {
        actor: {
          reference: `Location/${locationId}`,
        },
        required: 'required',
        status: 'accepted',
      },
    ],
    start: date,
    reasonCode: [
      {
        text: reason,
      },
    ],
    status: 'booked',
    supportingInformation: [
      {
        reference: `Organization/${orgId}`,
      },
    ],
  };
}

export function createCoverageEligibilityResponseRequestBody(
  converageId: string,
  start: string,
  end: string,
  registeredHospital: string,
  registeredHospitalCode: string,
) {
  return {
    resourceType: 'CoverageEligibilityResponse',
    insurance: [
      {
        coverage: {
          reference: `Coverage/${converageId}`,
        },
        benefitPeriod: {
          start,
          end,
        },
        item: [
          {
            network: {
              text: `${registeredHospital}|${registeredHospitalCode}`,
            },
          },
        ],
      },
    ],
  };
}

interface AppointmentItem {
  date: Moment;
  appointments?: some[];
}

export const MOCK_DATA_CALENDAR: AppointmentItem[] = [
  { date: moment().subtract(2, 'days'), appointments: [] },
  { date: moment().subtract(1, 'days'), appointments: [] },
  { date: moment(), appointments: [] },
  { date: moment().add(1, 'days'), appointments: [] },
  { date: moment().add(2, 'days'), appointments: [] },
  { date: moment().add(3, 'days'), appointments: [] },
  { date: moment().add(4, 'days'), appointments: [] },
  { date: moment().add(5, 'days'), appointments: [] },
  { date: moment().add(6, 'days'), appointments: [] },
  { date: moment().add(7, 'days'), appointments: [] },
];

export const DISPLAY_DURATION = 4; // khi sửa cái này phải sửa cả MOCK_DATA_CALENDAR sao cho MOCK_DATA_CALENDAR.length >= DISPLAY_DURATION+5

export function getMonthYear(start: Moment, end: Moment, locale: string) {
  if (start.year() === end.year()) {
    if (start.month() === end.month()) {
      return start.locale(locale).format('MMMM yyyy');
    }
    return `${start.locale(locale).format('MMMM')} - ${end.locale(locale).format('MMMM')} ${start.format('yyyy')}`;
  }
  return `${start.locale(locale).format('MMMM yyyy')} - ${end.locale(locale).format('MMMM yyyy')}`;
}

export const mapSearchDataAppointment = (entries: some[]) => {
  const patientMapper = new Map<string, some>();
  const returnValue: some[] = [];
  if (entries) {
    entries.forEach((entry) => {
      if (entry.resource?.resourceType === 'Patient') {
        patientMapper.set(`Patient/${entry.resource?.id}`, entry.resource);
      }
      if (entry.resource?.resourceType === 'Organization') {
        patientMapper.set(`Organization/${entry.resource?.id}`, entry.resource);
      }
    });

    const appointmentEncounters = entries
      .filter((entry) => entry.resource?.resourceType === 'Appointment')
      .map((entry) => {
        const patientResource = patientMapper.get(entry.resource?.participant[0]?.actor.reference);
        const organization = patientMapper.get(entry.resource?.supportingInformation?.[0]?.reference);

        const returnValue = patientResource
          ? {
              patientResourceId: patientResource?.id,
              name: patientResource?.name?.[0]?.text,
              birthDate: patientResource?.birthDate,
              telephone: patientResource?.telecom?.find((v) => v.system === 'phone')?.value || '',
              address: patientResource?.address?.[0]?.line?.[0] || '',
              reasons: entry.resource?.reasonCode,
              start: entry.resource?.start,
              status: entry.resource?.status,
              id: entry.resource?.id,
            }
          : organization
          ? {
              orgResourceId: organization.id,
              name: organization.contact[0]?.name?.text,
              telephone: organization.contact[0]?.telecom?.find((v) => v.system === 'phone')?.value || '',
              address: organization.contact[0]?.address?.text || '',
              reasons: entry.resource?.reasonCode,
              start: entry.resource?.start,
              status: entry.resource?.status,
              id: entry.resource?.id,
            }
          : {};
        return returnValue;
      });
    returnValue.push(...appointmentEncounters);
  }
  return returnValue;
};
