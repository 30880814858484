import { z } from 'zod';
import { TherapyDefinitionDetailSchema } from './TherapyDefinitionDetail';

export const TherapyDefinitionSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    name: z.string(),
    description: z.string(),
    groupId: z.number(),
    systemCategoryId: z.number(),
    packagePrice: z.number(),
    totalPrice: z.number(),
    status: z.string(),
    detailList: z.array(TherapyDefinitionDetailSchema),
    retailPrice: z.number(),
  })
  .partial();

export type TherapyDefinition = z.infer<typeof TherapyDefinitionSchema>;
