import { FormControl, FormControlLabel, FormControlLabelProps, InputLabel, Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React from 'react';

const switchStyle = makeStyles(() => ({
  root: {
    marginTop: 20,
  },
}));

export interface PropsSwitchElement
  extends Omit<FormControlLabelProps, 'label' | 'control' | 'defaultValue' | 'onChange'> {
  label?: React.ReactNode;
  onChange?: (value: boolean) => void;
  error?: boolean;
  required?: boolean;
}
export interface Props extends PropsSwitchElement {}

const SwitchElement = React.forwardRef<any, Props>((props: Props, ref) => {
  const { labelPlacement, label = '', onChange, value, error, required, ...rest } = props;
  const classes = switchStyle();

  return (
    <FormControl required={required} error={!!error} component="fieldset">
      <FormControlLabel
        label={
          <InputLabel
            style={{
              marginBottom: labelPlacement === 'top' ? 4 : 0,
              height: 'auto',
            }}
            required={required}
          >
            {label}
          </InputLabel>
        }
        control={<Switch color="primary" inputRef={ref} />}
        labelPlacement={labelPlacement}
        classes={labelPlacement && ['end', 'start']?.includes(labelPlacement) ? classes : {}}
        {...rest}
        checked={value as any}
        onChange={(e, checked) => {
          onChange && onChange(checked);
        }}
      />
    </FormControl>
  );
});

export default SwitchElement;
