import { Paper, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { mappedBody } from '../../common/api';
import { some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import usePaginationHook from '../../common/hook/usePaginationHook';
import { axiosThunk } from '../../common/redux/axios';
import { setLoading } from '../../common/redux/commonReducer';
import AlertConfigDialog from '../component/inventory/AlertConfigDialog';
import FilterBox from '../component/inventory/FilterBox';
import InventoryTable from '../component/inventory/InventoryTable';
import { formatDateFilterField } from '../../common/utils';
import { useFetch } from 'modules/common/hook';
import { Inventory } from 'modules/schema';

interface Props {}

const InventoryPage: React.FunctionComponent<Props> = (props: Props) => {
  const { dispatch, API_SERVER, confirmDialog, intl, openSnackbar } = useGeneralHook();
  const { close } = confirmDialog;
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;
  const [formData, setFormData] = useState<Inventory | undefined>();

  const { data, isValidating, revalidate } = useFetch(
    API_SERVER.inventory.index(formatDateFilterField(params, 'expiredDateFrom', 'expiredDateTo')),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    },
  );

  const onSubmitAlertConfig = useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: API_SERVER.inventoryAlertConfig?.update(value?.medicationId),
          method: 'put',
          data: mappedBody(value),
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: 'common.message.success' }) });
          setFormData(undefined);
          dispatch(setLoading(false));
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
        });
      close();
    },
    [API_SERVER.inventoryAlertConfig, close, dispatch, intl, openSnackbar],
  );

  return (
    <>
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterBox
          hookPagination={hookPagination}
          pagin={
            <>
              <Typography variant="h6">
                <FormattedMessage id="inventory.title" />
              </Typography>
            </>
          }
        />
        <InventoryTable
          data={data || []}
          loading={isValidating}
          onNoti={setFormData}
          hookPagination={hookPagination}
          revalidateTableData={revalidate}
        />
      </Paper>

      <AlertConfigDialog
        formData={formData}
        open={!!formData}
        onClose={async () => {
          setFormData(undefined);
        }}
        onSubmit={onSubmitAlertConfig}
      />
    </>
  );
};
export default InventoryPage;
