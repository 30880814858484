import { Box } from '@mui/material';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';

const Note = () => {
  const { intl } = useGeneralHook();
  return (
    <Box marginTop={2}>
      <SchemaElement
        fieldName="note"
        propsElement={{
          type: 'text-field',
          multiline: true,
          rows: 2,
          label: intl.formatMessage({ id: 'dental.dentalDetail.statusAndDiagnosis.input.note.label' }),
          placeholder: intl.formatMessage({ id: 'dental.dentalDetail.statusAndDiagnosis.input.note.placeholder' }),
          style: { marginTop: '7px' },
        }}
      />
    </Box>
  );
};

export default Note;
