import TableCustom, { Columns } from '../TableCustom';
import { some } from 'modules/common/constants';

interface Props {
  data: some[];
  columns: Columns[];
}

const PrintFormTable = ({ data, columns }: Props) => {
  return (
    <TableCustom
      containerProps={{
        sx: {
          '& th,tr,td': {
            padding: '2px 8px',
            backgroundColor: 'white',
            border: '1px solid #000',
            color: 'unset',
          },
          width: '100%',
          overflow: 'visible',
          marginTop: '5px',
        },
      }}
      dataSource={data}
      columns={columns}
    />
  );
};

export default PrintFormTable;
