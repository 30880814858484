import { ExpandLess } from '@mui/icons-material';
import { Box, Button, Collapse, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  IconBloodPressure,
  IconBMI,
  IconHeartBeat,
  IconHeight,
  IconLung,
  IconSPO2,
  IconTemperature,
  IconWeight,
} from '../../../../svg';
import { SMALL_DECIMAL_NUMBER_REGEX } from 'modules/common/regex';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import { VITAL_SIGN_CODE } from 'modules/examDoctor/constants';
import VitalSignItem from './VitalSignItem';

const NUMBER_REGEX = /^[0-9]{1,3}([.][0-9]{1,2})?$/;

interface Props {
  readOnly?: boolean;
  submit: (e) => void;
  expand?: boolean;
  bmi: string;
}

const VitalSignBox: React.FunctionComponent<Props> = (props) => {
  const { readOnly, submit, expand: defaultExpand = false, bmi } = props;
  const [expand, setExpand] = React.useState(defaultExpand);
  const formMethods = useForm();
  const { formState } = formMethods;

  return (
    <>
      <Button
        variant="text"
        endIcon={
          <ExpandLess
            style={{
              transition: '0.2s all',
              transform: expand ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        }
        sx={{ paddingX: 0 }}
        onClick={() => setExpand((old) => !old)}
      >
        <Typography variant="subtitle1">
          <FormattedMessage id="encounterDetail.vitalSign" />
        </Typography>
      </Button>
      <Collapse in={expand}>
        <Box marginTop={2}>
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconTemperature style={{ height: 32, width: 32 }} />}
                label={
                  <Typography
                    variant="subtitle1"
                    color={!!formState.errors.bodyTemperature ? 'error' : 'textSecondary'}
                  >
                    <FormattedMessage id="encounterDetail.temperature" />
                  </Typography>
                }
                control={
                  <>
                    <SchemaElement
                      fieldName="bodyTemperature"
                      propsElement={{
                        type: 'text-field',
                        register: { pattern: SMALL_DECIMAL_NUMBER_REGEX },
                        variant: 'standard',
                        noHelperText: true,
                        InputProps: {
                          style: { marginTop: 0, background: 'inherit' },
                          readOnly: readOnly,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="caption">{VITAL_SIGN_CODE.temperature.unit}</Typography>
                            </InputAdornment>
                          ),
                        },
                        onBlur: (e) => {
                          !readOnly &&
                            submit({
                              bodyTemperature: e.target.value,
                            });
                        },
                      }}
                    />
                  </>
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconBloodPressure style={{ height: 32, width: 32 }} />}
                label={
                  <Typography
                    variant="subtitle1"
                    color={
                      !!formState.errors.systolicBloodPressure || !!formState.errors.diastolicBloodPressure
                        ? 'error'
                        : 'textSecondary'
                    }
                  >
                    <FormattedMessage id="encounterDetail.bloodPressure" />
                  </Typography>
                }
                control={
                  <Box display="flex" alignItems="center">
                    <SchemaElement
                      fieldName="systolicBloodPressure"
                      propsElement={{
                        type: 'text-field',
                        register: { pattern: NUMBER_REGEX },
                        variant: 'standard',
                        noHelperText: true,
                        InputProps: {
                          style: { marginTop: 0, background: 'inherit' },
                          readOnly: readOnly,
                        },
                        inputProps: {
                          style: { paddingLeft: 0, paddingRight: 0 },
                        },
                        onBlur: (e) => {
                          !readOnly &&
                            submit({
                              systolicBloodPressure: e.target.value,
                            });
                        },
                      }}
                    />
                    <Box style={{ position: 'relative', top: -2 }} marginX={0.5}>
                      /
                    </Box>
                    <SchemaElement
                      fieldName="diastolicBloodPressure"
                      propsElement={{
                        type: 'text-field',
                        style: { minWidth: 95 },
                        register: { pattern: NUMBER_REGEX },
                        variant: 'standard',
                        noHelperText: true,
                        InputProps: {
                          style: { marginTop: 0, background: 'inherit' },
                          readOnly: readOnly,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="caption">{VITAL_SIGN_CODE.bloodPressure.unit}</Typography>
                            </InputAdornment>
                          ),
                        },
                        inputProps: {
                          style: { paddingLeft: 0, paddingRight: 0 },
                        },
                        onBlur: (e) => {
                          !readOnly &&
                            submit({
                              diastolicBloodPressure: e.target.value,
                            });
                        },
                      }}
                    />
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconLung style={{ height: 32, width: 32 }} />}
                label={
                  <Typography
                    variant="subtitle1"
                    color={!!formState.errors.respiratoryRate ? 'error' : 'textSecondary'}
                  >
                    <FormattedMessage id="encounterDetail.breathBeat" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="respiratoryRate"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.breath.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                      onBlur: (e) => {
                        !readOnly &&
                          submit({
                            respiratoryRate: e.target.value,
                          });
                      },
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconHeartBeat style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={!!formState.errors.heartRate ? 'error' : 'textSecondary'}>
                    <FormattedMessage id="encounterDetail.heartBeat" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="heartRate"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.heartBeat.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                      onBlur: (e) => {
                        !readOnly &&
                          submit({
                            heartRate: e.target.value,
                          });
                      },
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconHeight style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={!!formState.errors.bodyHeight ? 'error' : 'textSecondary'}>
                    <FormattedMessage id="encounterDetail.height" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="bodyHeight"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.height.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                      onBlur: (e) => {
                        !readOnly &&
                          submit({
                            bodyHeight: e.target.value,
                          });
                      },
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconWeight style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={!!formState.errors.bodyWeight ? 'error' : 'textSecondary'}>
                    <FormattedMessage id="encounterDetail.weight" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="bodyWeight"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.weight.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                      onBlur: (e) => {
                        !readOnly &&
                          submit({
                            bodyWeight: e.target.value,
                          });
                      },
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconBMI style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={'textSecondary'}>
                    <FormattedMessage id="encounterDetail.BMI" />
                  </Typography>
                }
                control={
                  <TextField
                    value={bmi}
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { marginTop: 0, background: 'inherit' },
                      readOnly: true,
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconSPO2 style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={!!formState.errors.spo2 ? 'error' : 'textSecondary'}>
                    <FormattedMessage id="encounterDetail.SpO2" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="spo2"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.spo2.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                      onBlur: (e) => {
                        !readOnly &&
                          submit({
                            spo2: e.target.value,
                          });
                      },
                    }}
                  />
                }
              />
            </Grid>
          </Grid>
        </Box>
      </Collapse>
    </>
  );
};

export default VitalSignBox;
