import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { CombinedState, applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from '@redux-saga/core';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage/session';
import createRootReducer, { AppState } from './reducer';
import rootSaga from './sagas';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

export const history = createBrowserHistory();

const composeEnhancers =
  (typeof window !== 'undefined' && (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

export default function configureStore(preloadedState: any) {
  const sagaMiddleware = createSagaMiddleware();

  const persistedReducer = persistReducer<CombinedState<AppState>>(
    { key: 'root', storage, stateReconciler: hardSet },
    createRootReducer(history),
  );

  const store = createStore(
    persistedReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(routerMiddleware(history), thunk, sagaMiddleware)),
  );

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}

export const { store, persistor } = configureStore({});
