import { Box } from '@mui/system';
import TextValue from '../../../../examDoctor/component/therapy/TherapySessionDetail/PrintTherapySessionDialog/PrintDialogContent/TextValue';
import Stack from '@mui/material/Stack';
import { Therapy } from 'modules/schema';

interface Props {
  therapy: Therapy;
  compact: boolean;
}
const ExtraInfo = ({ therapy, compact }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      {!compact && (
        <Box flex={1.5}>
          <TextValue label="doctorOfIndication" value={therapy?.patient?.formatCode || ''} labelWidth="175px" />
          <TextValue label="referenceIndication.specimenNumber" value="" labelWidth="175px" />
          <TextValue label="printForm.samplingTime" value="" labelWidth="175px" />
          <TextValue label="printForm.approveTime" value="" labelWidth="175px" />
        </Box>
      )}
      <Box flex={1}>
        <TextValue labelWidth="130px" label="preliminaryDiagnosis" value="" />
      </Box>
    </Stack>
  );
};

export default ExtraInfo;
