import { Box, Chip, Popover, Stack } from '@mui/material';
import { drop } from 'lodash';
import { useEffect, useState } from 'react';

interface ChipListProps<T> {
  items: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  onDelete: (value: T[keyof T]) => void;
  maxVisible?: number;
}

interface ChipPopoverProps<T> {
  open: boolean;
  anchorEl: HTMLDivElement | null;
  onClose: () => void;
  items: T[];
  labelKey: keyof T;
  valueKey: keyof T;
  onDelete: (value: T[keyof T]) => void;
}

const ChipList = <T extends {}>({ items, labelKey, valueKey, onDelete, maxVisible = 2 }: ChipListProps<T>) => {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack direction="row" gap="10px" flexWrap="wrap" sx={{ '& .MuiChip-label': { maxWidth: '250px' } }}>
        {items?.slice(0, maxVisible)?.map((item) => (
          <Chip label={item[labelKey]} key={item[valueKey] as React.Key} onDelete={() => onDelete(item[valueKey])} />
        ))}
        {items?.length > maxVisible && (
          <Box
            key="rest-of-items"
            sx={{
              backgroundColor: '#D7DCE5',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.1px',
              cursor: 'pointer',
              borderRadius: '20px',
              padding: '4px 12px',
            }}
            onClick={(event: React.MouseEvent<HTMLDivElement>) => setAnchorEl(event.currentTarget)}
          >
            +{items.length - maxVisible}
          </Box>
        )}
      </Stack>
      <ChipPopover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        items={items}
        labelKey={labelKey}
        valueKey={valueKey}
        onDelete={onDelete}
      />
    </>
  );
};

const ChipPopover = <T extends {}>({
  open,
  anchorEl,
  onClose,
  items,
  labelKey,
  valueKey,
  onDelete,
}: ChipPopoverProps<T>) => {
  useEffect(() => {
    if (items.length <= 2 && open) {
      onClose();
    }
  }, [items.length, open, onClose]);
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      PaperProps={{
        sx: {
          boxShadow: '0px 4px 8px 0px #E0ECFF',
          borderRadius: '8px',
        },
      }}
    >
      <Stack
        direction="column"
        alignItems="flex-start"
        justifyContent="flex-start"
        gap="10px"
        padding="20px"
        boxSizing="border-box"
      >
        {drop(items, 2).map((item) => (
          <Chip
            key={item[valueKey] as React.Key}
            label={item[labelKey]}
            onDelete={() => onDelete(item[valueKey])}
            sx={{ justifyContent: 'space-between' }}
          />
        ))}
      </Stack>
    </Popover>
  );
};

export default ChipList;
