import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../common/api';
import { some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { setLoading } from '../../common/redux/commonReducer';
import RoomFormDialog from '../component/room/RoomFormDialog';
import RoomListTable from '../component/room/RoomListTable';
import { axiosThunk } from '../../common/redux/axios';
import { useFetch } from 'modules/common/hook';

export interface SearchParamsFilterRoom {
  name: string;
}
interface Props {}

const RoomAdminPage: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();

  const [searchParams, setSearchParams] = React.useState<SearchParamsFilterRoom>({ name: '' });
  const [formData, setFormData] = React.useState<some | undefined>();

  const { data: physicsRoom = [], revalidate, isValidating } = useFetch(API_SERVER.physicsRoom.getList(searchParams));

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'room.deleteTitle' }),
        content: intl.formatMessage({ id: 'room.deleteContent' }, { name: value.name }),
      });
      if (confirm) {
        try {
          await dispatch(axiosThunk({ url: API_SERVER.physicsRoom.delete(value.id), method: 'delete' }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, revalidate],
  );

  const onCreateOrUpdateRoom = React.useCallback(
    async (value: some) => {
      if (!value.id) {
        // create new
        dispatch(setLoading(true));

        try {
          await dispatch(
            axiosThunk({
              url: API_SERVER.physicsRoom.create(value.categoryId),
              method: 'post',
              data: value,
            }),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      } else {
        // edit
        dispatch(setLoading(true));

        try {
          await dispatch(
            axiosThunk({
              url: API_SERVER.physicsRoom.update(value.id),
              method: 'put',
              data: value,
            }),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
    },
    [dispatch, revalidate, openSnackbar, intl],
  );

  return (
    <Paper variant="outlined" style={{ flex: 1, overflow: 'hidden' }}>
      <RoomListTable
        data={physicsRoom}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        setFormData={setFormData}
        onDelete={onDelete}
        loading={isValidating && !physicsRoom}
      />
      <RoomFormDialog
        open={!!formData}
        formData={formData}
        onSubmit={onCreateOrUpdateRoom}
        onClose={() => {
          setFormData(undefined);
        }}
      />
    </Paper>
  );
};

export default RoomAdminPage;
