import { Delete } from '@mui/icons-material';
import { Grid, Switch, Tooltip } from '@mui/material';
import { find, flatMap, get, groupBy, isEmpty, map } from 'lodash';
import { API_SERVER } from 'modules/common/api';
import { GROUP_VERSION, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { SYSTEM_CATEGORY_SCOPE_LABEL } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { NumericFormatText } from 'modules/common/utils';
import React, { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useFetch } from 'modules/common/hook';
import { IndicationRequest, Service } from 'modules/schema';
import { useForm } from 'react-hook-form';

type GroupItemType = { id: number; name: string; serviceType: string };
type IndicationRequestType = Omit<IndicationRequest, 'createdTime' | 'startTime' | 'endTime'>;

interface SchemaFormIndication {
  groupType:
    | (Omit<
        Service,
        'specializedDepartment' | 'physicsRoom' | 'medicalEquipment' | 'laboratoryReferences' | 'systemCategory'
      > &
        GroupItemType)
    | null;
  indication: IndicationRequestType[] | [];
}

interface Props {
  indicationRequestData: IndicationRequest[];
  setIndicationRequestData: (item) => void;
}

const IndicationRequestService = ({ indicationRequestData, setIndicationRequestData }: Props) => {
  const { intl, appState } = useGeneralHook();

  // default values form
  const form = useForm<SchemaFormIndication>({
    defaultValues: { groupType: null, indication: [] },
  });

  // get data service category
  const { data: serviceCategoryList } = useFetch(
    API_SERVER.service.getAllSystemCategoryList({
      scope: [
        SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE.value,
        SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
        SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value,
        SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
      ],
    }),
  );

  // service type options
  const isBasicVersion = appState.authen.user?.group_version === GROUP_VERSION.BASIC;
  const serviceTypeOption = React.useMemo<Service[]>(() => {
    if (!serviceCategoryList) {
      return [];
    }
    const serviceCategoryListVersion = isBasicVersion
      ? serviceCategoryList.filter(
          (i) =>
            i.scope !== SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value &&
            i.scope !== SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
        )
      : serviceCategoryList;
    const groupedCategories = groupBy(
      serviceCategoryListVersion.filter((item) => item.type === 'GROUP'),
      'scope',
    );
    const result = map(groupedCategories, (categories, key) => {
      const itemsService = flatMap(categories, (category) =>
        category?.items?.map((item) => ({
          ...item,
          group: category.name,
          scope: category.scope,
        })),
      );
      const label = get(find(SYSTEM_CATEGORY_SCOPE_LABEL, { value: key }), 'label', '');
      const formattedName = intl.formatMessage({ id: label });
      const groups = categories.map((category) => ({
        ...category,
        label: categories[0].name,
      }));
      return {
        serviceType: key,
        name: formattedName,
        groups,
        label: formattedName,
        items: itemsService
          .map((item) => ({ ...item, groupsName: formattedName, groupsKey: key }))
          ?.filter((item) => item && item.name),
      };
    });
    return result;
  }, [serviceCategoryList, isBasicVersion, intl]);

  // indication options
  const groupType = form.watch('groupType');
  const indicationOption = useMemo(() => {
    return groupType !== undefined && groupType?.serviceType
      ? flatMap(serviceTypeOption, 'items')
          .filter((value) => value?.groupsKey === groupType?.serviceType)
          .map((item) => ({ ...item, isFree: false }))
      : flatMap(serviceTypeOption, 'items').map((item) => ({ ...item, isFree: false }));
  }, [serviceTypeOption, groupType]);

  // select indication request
  const onSelectIndicationRequest = useCallback(() => {
    const service = form.getValues('indication');
    setIndicationRequestData(service);
  }, [form, setIndicationRequestData]);

  // update free switch button
  const onUpdateFreeIndication = useCallback(
    (e, record) => {
      const index = indicationRequestData.findIndex((i) => i.id === record.id);
      const newIndicationRequestData = [...indicationRequestData];
      newIndicationRequestData[index] = {
        ...newIndicationRequestData[index],
        isFree: !e.target.checked,
      };
      setIndicationRequestData(newIndicationRequestData);
    },
    [indicationRequestData, setIndicationRequestData],
  );

  // delete indication request record
  const onDelete = useCallback(
    (record) => {
      const newServiceData = indicationRequestData.filter((i) => i.id !== record.id);
      setIndicationRequestData(newServiceData);
      if (isEmpty(newServiceData)) {
        form.reset();
      } else {
        form.setValue('indication', newServiceData);
      }
    },
    [form, indicationRequestData, setIndicationRequestData],
  );

  // config columns
  const columns = useMemo(() => {
    return [
      {
        type: 'INDEX',
        width: '30px',
      },
      {
        title: 'indication.plan',
        render: (record) => {
          return intl.formatMessage({ id: SYSTEM_CATEGORY_SCOPE[record.scope!].label || ' ' });
        },
      },
      {
        title: 'therapyIndication.header.label.serviceName',
        render: 'name',
      },
      {
        title: 'therapyIndication.header.label.price',
        dataIndex: 'price',
        render: (data) => {
          return NumericFormatText(data.price);
        },
      },
      {
        title: 'dental.dentalDetail.indicationTable.fee.label',
        render: (record) => {
          return (
            <Tooltip title={<FormattedMessage id="freeSwitch" />} arrow>
              <Switch
                size="small"
                color="primary"
                checked={!record?.isFree}
                disabled={record.isPaid}
                onChange={(e) => onUpdateFreeIndication(e, record)}
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'action',
        render: (record) => (
          <IconButtonTitle key="delete-btn" title="delete" size="small" onClick={() => onDelete(record)}>
            <Delete fontSize="small" sx={{ color: '#78909C' }} />
          </IconButtonTitle>
        ),
      },
    ] as Column<IndicationRequest>[];
  }, [intl, onDelete, onUpdateFreeIndication]);

  return (
    <>
      <Grid container justifyContent={'space-between'}>
        <Grid item xs={5.8}>
          <AutocompleteInput
            key="groupType"
            form={form}
            name="groupType"
            getValue="name"
            options={serviceTypeOption}
            rawLabel
            label={intl.formatMessage({ id: 'therapyIndication.header.label.serviceType' })}
            placeholder="therapyIndication.header.label.serviceTypeSelect"
            getLabel="name"
            clearButton
          />
        </Grid>
        <Grid item xs={5.8}>
          <AutocompleteInput
            key={'indication'}
            form={form}
            name="indication"
            getValue="id"
            options={indicationOption}
            label={intl.formatMessage({ id: 'serviceName' })}
            placeholder="serviceNameSelect"
            getLabel="name"
            groupBy="group"
            onBlur={onSelectIndicationRequest}
            renderTags={() => null}
            hideError
            multiple
            rawLabel
            disableCloseOnSelect={true}
          />
        </Grid>
      </Grid>
      <Table columns={columns} dataKey={'id'} data={indicationRequestData} />
    </>
  );
};

export default IndicationRequestService;
