import { z } from 'zod';
import { PaymentAdvanceSchema } from './PaymentAdvance';

export const PrePaymentSchema = z
  .object({
    id: z.number(),
    totalAmount: z.number(),
    amountPaid: z.number(),
    amount: z.number(),
    paymentId: z.number(),
    refundAmount: z.number(),
    groupId: z.number(),
    paymentAdvances: z.array(PaymentAdvanceSchema),
  })
  .partial();

export type PrePayment = z.infer<typeof PrePaymentSchema>;
