import { Box, Button, Stack } from '@mui/material';
import Report from 'modules/common/component/report';
import { DEFAULT_VALUES, FILTER_CONFIGS } from 'modules/common/page/report/business/debtReport/consts';
import { useEffect, useMemo, useRef, useState } from 'react';
import DateInput from 'modules/common/component/form/DateInput';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';
import { groupBy, map, sum } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { TIME_REPORT_FILTER } from 'modules/common/component/report/consts';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'modules/common/constants';
import { formatEndDate, formatStartDate, getDateRangeList, isValidDate, maxDate } from 'modules/common/utils';
import { FormSchema } from 'modules/common/page/report/business/debtReport/type';
import { SERVICE_TYPE_ALL } from 'modules/common/apiConstants';
import SelectInput from 'modules/common/component/form/SelectInput';
import { Print } from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import Filter from 'modules/common/component/filter';
import { Option, ValueType } from 'modules/common/type';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { SPECIALIST } from 'modules/layout/constants';
import { BUSINESS_API } from 'modules/common/service';

export const FILTER_KEY = 'DEBT_REPORT_CHART_FILTER';
export const SELECT_COLUMNS_KEY = 'DEBT_REPORT_CHART_SELECT_COLUMNS';

const DebtReportChart = () => {
  const refPrintComponent = useRef(null);
  const intl = useIntl();
  const [debtAmountData, setDebtAmountData] = useState<number[]>([]);
  const [amountPaidData, setAmountPaidData] = useState<number[]>([]);
  const [finalTotalAmountData, setFinalTotalAmountData] = useState<number[]>([]);
  const [searchParams, setSearchParams] = useState<{
    fromDate: string;
    toDate: string;
    keyword: string;
    paymentTypeList?: ValueType<typeof SERVICE_TYPE_ALL>[];
  }>(DEFAULT_VALUES);

  const form = useForm<FormSchema & { timeReportType: keyof typeof TIME_REPORT_FILTER }>({
    defaultValues: {
      ...DEFAULT_VALUES,
      toDate: formatEndDate(moment().add(+1, 'day')),
      timeReportType: TIME_REPORT_FILTER.DAY.value,
    },
    mode: 'onChange',
  });

  const timeReportType = form.watch('timeReportType');
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate = formatEndDate(moment(toDateOrigin).add(-1, 'day'));
  const formData = form.getValues();
  const type = form.watch('paymentTypeList');

  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      paymentTypeList: type ? map(type, 'value') : map([SERVICE_TYPE_ALL.ENCOUNTER, SERVICE_TYPE_ALL.THERAPY], 'value'),
      fromDate,
      toDate,
    };
  }, [fromDate, searchParams, toDate, type]);

  const { data: reportData, isValidating } = useFetch(BUSINESS_API.getDebtList({ ...formatSearchParams }), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'CHART'),
  });

  const dateRangeList = useMemo(
    () =>
      getDateRangeList(
        moment(fromDate, BE_DATE_TIME_FORMAT),
        moment(toDate, BE_DATE_TIME_FORMAT),
        timeReportType === 'DAY' ? 'day' : 'month',
      ).map((date) => date.format(timeReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT)),
    [fromDate, timeReportType, toDate],
  );

  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const typeOption: Option[] = useMemo(() => {
    return isDentalClinic
      ? [SERVICE_TYPE_ALL.DENTAL, SERVICE_TYPE_ALL.THERAPY]
      : [SERVICE_TYPE_ALL.ENCOUNTER, SERVICE_TYPE_ALL.THERAPY];
  }, [isDentalClinic]);

  useEffect(() => {
    if (timeReportType === 'DAY' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'month')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'month')
            .add(+1, 'day'),
        ),
      });
    }
    if (timeReportType === 'MONTH' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'year')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'year')
            .add(+1, 'day'),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateOrigin, timeReportType, toDateOrigin]);

  useEffect(() => {
    const timeFormat = timeReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT;
    if (!isValidating && reportData && isValidDate(fromDate, toDate, 'CHART')) {
      const reportMap = groupBy(reportData, (item) => item.createdTime?.format(timeFormat));
      setDebtAmountData(
        dateRangeList.map((date) => sum(reportMap[date]?.map((obj) => (obj.debtAmount || 0) - (obj.prepayment || 0)))),
      );
      setAmountPaidData(dateRangeList.map((date) => sum(reportMap[date]?.map((obj) => obj?.amountPaid || 0))));
      setFinalTotalAmountData(
        dateRangeList.map((date) => sum(reportMap[date]?.map((obj) => obj?.finalTotalAmount) || 0)),
      );
    } else {
      setDebtAmountData([]);
      setAmountPaidData([]);
      setFinalTotalAmountData([]);
    }
  }, [dateRangeList, fromDate, isValidating, reportData, timeReportType, toDate]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title title="report.businessReport.tab.title.debtReport" />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue) => {
          setSearchParams((prev) => ({
            ...prev,
            debtAmountZero: filterValue.DEBT_STATUS,
          }));
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="paymentTypeList"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                multiple
                showCheckBox
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                maxDate={maxDate(timeReportType, fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
              />
              <Box marginTop={'-17px'}>
                <Report.DayMonthRadio
                  form={form}
                  name="timeReportType"
                  onChange={(value) => {
                    if (value === 'DAY') {
                      form.reset({
                        fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment().add(+1, 'day')),
                        timeReportType: TIME_REPORT_FILTER.DAY.value,
                      });
                    }
                    if (value === 'MONTH') {
                      form.reset({
                        fromDate: moment().add(-1, 'year').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment()),
                        timeReportType: TIME_REPORT_FILTER.MONTH.value,
                      });
                    }
                  }}
                />
              </Box>
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() =>
                    form.reset({
                      ...DEFAULT_VALUES,
                      toDate: formatEndDate(moment().add(+1, 'day')),
                    })
                  }
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />}>
                  <FormattedMessage id={'printReport'} />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Stack>
        </Stack>
      </Filter>
      <Report.ColumnChart
        refPrintComponent={refPrintComponent}
        xAxisTitle="common.time"
        yAxisTitle="millionMoney"
        categories={dateRangeList}
        staking
        formatYValue
        formatYAxis
        series={[
          {
            type: 'column',
            name: intl.formatMessage({ id: 'therapy.therapyDetail.printForm.title.debtAmount' }),
            data: debtAmountData,
            color: '#003CA6',
            borderRadius: 5,
          },
          {
            type: 'column',
            name: intl.formatMessage({
              id: 'therapy.therapyDetail.printForm.title.paidAmount',
            }),
            data: amountPaidData,
            color: '#43A047',
            borderRadius: 5,
          },
          {
            type: 'line',
            name: intl.formatMessage({
              id: 'report.debt.table.title.finalTotalAmount',
            }),
            data: finalTotalAmountData,
            color: '#307BFF',
          },
        ]}
      />
    </Stack>
  );
};

export default DebtReportChart;
