import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_TIME_FORMAT, some } from '../../../../common/constants';
import moment from 'moment';

export const formatGeneralInfo = (generalInfo: some) => {
  if (!generalInfo) return;
  const dateTimeFormat = `${FE_TIME_FORMAT} ${BE_DATE_FORMAT}`;
  const dateFormat = moment(generalInfo?.date).format(BE_DATE_FORMAT);
  const startDate = generalInfo?.date ? moment(`${generalInfo?.startTime} ${dateFormat}`, dateTimeFormat) : null;

  return {
    id: generalInfo?.id,
    name: generalInfo?.name,
    startTime: startDate?.format(BE_DATE_TIME_FORMAT),
    picUserId: generalInfo?.picUserId,
    picName: generalInfo?.picName,
    status: generalInfo?.status,
    isFree: generalInfo?.isFree,
    conclude: generalInfo?.conclude,
    description: generalInfo?.description,
    attendPersons: generalInfo?.attendPersons,
  };
};
