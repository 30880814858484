import * as React from 'react';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import SchemaForm from '../../../common/SchemaForm';
import { schemaFilterEncounterForm } from './schema';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';

interface Props {
  pagin?: React.ReactNode;
  hookPagination: HookPaginationProps;
}

const FilterEncounterBox: React.FunctionComponent<Props> = (props) => {
  const { pagin, hookPagination } = props;
  const { params, setParams, clearParams } = hookPagination;

  return (
    <SchemaForm
      schema={schemaFilterEncounterForm}
      onSubmit={setParams}
      formData={params}
      clearParams={() =>
        clearParams({
          status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
        })
      }
      hideSubmitButton
      pagin={pagin}
    />
  );
};

export default FilterEncounterBox;
