export const MEDICATION_UNIT = [
  { value: 'CASE', label: 'case' },
  { value: 'BOX', label: 'box' },
  { value: 'BLISTER', label: 'blister' },
  { value: 'PILL', label: 'pill' },
  { value: 'PACK', label: 'pack' },
  { value: 'VIAL', label: 'vial' },
  { value: 'TUBE', label: 'tube' },
  { value: 'UNIT', label: 'unit' },
  { value: 'PIECE', label: 'piece' }, //
  { value: 'BOTTLE', label: 'bottle' }, //
  { value: 'PIPE', label: 'pipe' }, //
  { value: 'POTION', label: 'potion' }, //
  { value: 'PATCH', label: 'patch' }, //
  { value: 'CYLINDER', label: 'cylinder' }, //
  { value: 'GRAM', label: 'gram' },
  { value: 'PACKAGE', label: 'package' },
];

export const INVENTORY_IMPORT_STATUS = [
  { value: 'NEW', label: 'new' },
  { value: 'PROCESSING', label: 'processing' },
  { value: 'IMPORTED', label: 'imported' },
  { value: 'CANCELLED', label: 'cancelled' },
];

export const INVENTORY_EXPORT_STATUS = [
  { value: 'EXPORTED', label: 'inventory.export.status.exported' },
  { value: 'CANCELLED', label: 'inventory.export.status.cancelled' },
];

export const INVENTORY_EXPORT_TYPE = [{ value: 'SALES_EXPORT', label: 'inventory.export.type.saleReceipt' }];
