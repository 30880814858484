import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_FORMAT } from '../common/constants';
import { InventoryExportDetailSchema } from './InventoryExportDetail';
import { SaleReceiptSchema } from './SaleReceipt';

export const InventoryExportSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    clinicId: z.number(),
    note: z.string(),
    exportDate: createDateTimeSchema(BE_DATE_FORMAT),
    saleReceiptId: z.number(),
    status: z.string(),
    type: z.string(),
    exportBy: z.string(),
    inventoryExportDetails: z.array(InventoryExportDetailSchema),
    saleReceipt: SaleReceiptSchema,
  })
  .partial();

export type InventoryExport = z.infer<typeof InventoryExportSchema>;
