import { SS_TOKEN } from '../constants';
import { API_SERVER } from '../api';
import axios from 'axios';
import { useCallback } from 'react';
import { STORAGE_RESOURCE_TYPE } from '../apiConstants';

const useUploadFile = (onSuccess?: () => void) => {
  return useCallback(
    async ({
      docRefId,
      type,
      description,
      file,
    }: {
      docRefId: number;
      type: keyof typeof STORAGE_RESOURCE_TYPE;
      description?: string;
      file: File;
    }) => {
      const formData = new FormData();
      formData.append('file', file);

      const token = sessionStorage.getItem(SS_TOKEN) || '';
      try {
        await axios.post(
          API_SERVER.storageResource.upload({
            type: type,
            referenceId: docRefId,
            description: description,
          }),
          formData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );
        if (onSuccess) onSuccess();
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    },
    [onSuccess],
  );
};

export default useUploadFile;
