import { Paper, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import { INDICATION_REQUEST_TYPE } from 'modules/common/constants';
import IndicationList from 'modules/labDoctor/component/indication/IndicationList';
import { useFetch, useDialog, useUpdateMutate } from 'modules/common/hook';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { IndicationRequest } from 'modules/schema';
import { formatDateFilterField } from 'modules/common/utils';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import FilterIndicationBox from '../component/indication/FilterEncounterBox';
import PrintFormDialog from '../component/indication/PrintFormDialog';
import DialogLabDoctorIndication from './DialogLabDoctorIndication';

interface Props {}

const LabDoctorIndicationList: React.FunctionComponent<Props> = () => {
  const [openEditIndicationDialog, onOpenEditIndicationDialog, onCloseEditIndicationDialog] = useDialog();
  const intl = useIntl();
  const { openSnackbar, confirmDialog } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;
  const [selectionIndication, setSelectionIndication] = React.useState<IndicationRequest[]>([]);
  const [open, setOpen] = React.useState(false);
  const [dataDetail, setDataDetail] = React.useState<IndicationRequest | undefined>(undefined);

  // get list indication
  const {
    data,
    isValidating,
    revalidate: revalidateIndicationList,
  } = useFetch(
    API_SERVER.indicationRequest.getList({
      ...formatDateFilterField({ ...params, sortBy: 'createdTime' }, 'fromDate', 'toDate'),
      scope: SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
      type: INDICATION_REQUEST_TYPE.ENCOUNTER,
    }),
  );

  // get data encounter detail
  const { data: encounter } = useFetch(API_SERVER.encounter.get(dataDetail?.referenceId || 0), {
    enabled: dataDetail?.referenceId,
  });

  // complete indication
  const completeIndication = useUpdateMutate({
    onSuccess: () => {
      revalidateIndicationList();
    },
  });
  const onComplete = React.useCallback(
    async (indication) => {
      try {
        const confirm = await confirmDialog.promptConfirmation({
          title: intl.formatMessage({ id: 'confirm' }),
          content: intl.formatMessage({ id: 'therapy.detail.indication.text.finishConfirm' }),
        });
        if (!confirm) {
          confirmDialog.close();
          return;
        }
        completeIndication({
          url: API_SERVER.therapyIndication.finish(indication?.id),
          method: 'PUT',
        });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, completeIndication, openSnackbar],
  );

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterIndicationBox
        hookPagination={hookPagination}
        onPrint={() => {
          setOpen(true);
        }}
        selectionIndication={selectionIndication}
        pagin={
          <>
            <Typography variant="h6">
              <FormattedMessage id="labDoctor.list" />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="foundResult"
                values={{
                  num: (
                    <Typography variant="inherit" component="span" color="primary">
                      {data?.content?.length || 0}
                    </Typography>
                  ),
                  total: (
                    <Typography variant="inherit" component="span" color="primary">
                      {data?.pagination.totalElements || 0}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </>
        }
      />
      <IndicationList
        onComplete={onComplete}
        data={data?.content}
        total={data?.pagination.totalElements || 0}
        loading={isValidating}
        hookPagination={hookPagination}
        selectionIndication={selectionIndication}
        setSelectionIndication={setSelectionIndication}
        setDataDetail={setDataDetail}
        onOpenEditIndicationDialog={onOpenEditIndicationDialog}
      />
      {dataDetail && encounter && (
        <DialogLabDoctorIndication
          open={openEditIndicationDialog}
          onClose={onCloseEditIndicationDialog}
          encounter={encounter}
          indicationRequest={dataDetail}
          revalidateIndicationList={revalidateIndicationList}
        />
      )}
      {open && selectionIndication.length > 0 && (
        <PrintFormDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          selectionIndication={selectionIndication}
        />
      )}
    </Paper>
  );
};

export default LabDoctorIndicationList;
