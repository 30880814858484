import { Box, Dialog } from '@mui/material';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaForm from 'modules/common/SchemaForm';
import NewEncounterWizardTitle from './NewEncounterWizardTitle';
import { receivePatientFormSchema } from './schema';
import './style.scss';
import { useUserRoles } from 'modules/common/hook/useUserRoles';
import { API_SERVER } from 'modules/common/api';
import { GroupOption } from 'modules/common/type';
import { useCreateMutate, useUpdateMutate } from 'modules/common/hook/useMutate';
import { useFetch } from 'modules/common/hook/useFetch';
import { formatEncounterDataToForm, formatPatientDataToForm, formatEncounterData } from './utils';
import { EncounterFormMode } from './type';
import { IndicationRequest } from 'modules/schema';
import { INDICATION_REQUEST_TYPE } from 'modules/common/apiConstants';
import { isEmpty } from 'lodash';

type Props = {
  mode: EncounterFormMode;
  encounterId?: number;
  open: boolean;
  close(): void;
  refresh(): void;
};

const ReceiveWizardForm: React.FunctionComponent<Props> = (props) => {
  const [indicationRequestData, setIndicationRequestData] = React.useState<IndicationRequest[]>([]);
  const { close, refresh, mode, encounterId } = props;
  const { appState, intl } = useGeneralHook();
  const formMethods = useForm({
    defaultValues: {},
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const doctors = useUserRoles('DOCTOR', 'GENERAL_DOCTOR');
  const { data: systemCategories } = useFetch(
    API_SERVER.systemCategory.getList({ type: 'PRICE_LIST', scope: 'HEALTH_CHECK_SERVICE' }),
  );

  //TODO: hard code name params ==> need to change to code
  const { data: physicsRooms } = useFetch(
    API_SERVER.systemCategory.getList({ type: 'GROUP', scope: 'PHYSICS_ROOM', name: 'Khám bệnh', isShowList: true }),
  );
  const systemCategoriesOptions = React.useMemo(
    () =>
      systemCategories?.map((systemCategory) => ({ value: systemCategory?.id!, label: systemCategory?.name! })) || [],
    [systemCategories],
  );
  const doctorOptions = React.useMemo(
    () => doctors?.map((doctor) => ({ value: doctor?.id!, label: doctor?.employeeFullName! })) || [],
    [doctors],
  );
  const { data: services } = useFetch(
    API_SERVER.systemCategory.getList({ type: 'GROUP', scope: 'HEALTH_CHECK_SERVICE', isShowList: true }),
  );
  const physicsRoomOptions = React.useMemo(
    () =>
      physicsRooms?.[0]?.items?.map((physicsRoom) => ({
        value: physicsRoom?.id!,
        label: physicsRoom?.name!,
        status: physicsRoom.status,
      })) || [],
    [physicsRooms],
  );

  const createEncounterMutate = useCreateMutate();
  const updateEncounterMutate = useUpdateMutate();

  const fetchEncounterData = mode === 'UPDATE' || mode === 'CREATE_WITH_OLD_PATIENT';
  const { data: encounter } = useFetch(API_SERVER.encounter.get(encounterId!), {
    enabled: fetchEncounterData && encounterId,
  });

  const { data: serviceId } = useFetch(API_SERVER.service.getServiceById(encounter?.serviceId!), {
    enabled: encounter?.serviceId && fetchEncounterData,
  });

  const serviceOptions = useMemo(() => {
    const arr =
      services?.reduce<(GroupOption & { parentId: number; price: number })[]>(
        (pre, cur) => [
          ...pre,
          ...(cur?.items?.map((item) => ({
            value: item?.id!,
            label: item?.name!,
            group: cur?.name!,
            parentId: cur?.parentId!,
            price: item?.price || 0,
          })) || []),
        ],
        [],
      ) || [];
    const exists = arr.some((item) => item.value === serviceId?.id);
    if (!exists) {
      return [
        ...arr,
        {
          value: serviceId?.id!,
          label: serviceId?.name!,
          group: serviceId?.systemCategory?.name!,
          parentId: serviceId?.systemCategory?.parentId!,
          price: serviceId?.price || 0,
        },
      ];
    }
    return arr;
  }, [serviceId, services]);

  React.useEffect(() => {
    if (encounter) {
      if (mode === 'UPDATE') {
        formMethods.reset(formatEncounterDataToForm(encounter, appState, systemCategoriesOptions, serviceOptions));
      } else if (mode === 'CREATE_WITH_OLD_PATIENT') {
        formMethods.reset(formatPatientDataToForm(encounter?.patient!, appState));
      }
    }
  }, [appState, encounter, formMethods, mode, serviceOptions, systemCategoriesOptions]);

  // map indication request data
  const indicationRequests = indicationRequestData.map((i) => {
    return {
      isFree: i.isFree,
      quantity: 1,
      referenceId: -1,
      serviceId: i.id,
      type: INDICATION_REQUEST_TYPE.ENCOUNTER.value,
    };
  });

  const onSubmit = React.useCallback(
    async (data) => {
      if (mode === 'CREATE' || mode === 'CREATE_WITH_OLD_PATIENT') {
        await createEncounterMutate({
          url: API_SERVER.encounter.create(),
          method: 'POST',
          data: !isEmpty(indicationRequests)
            ? {
                ...formatEncounterData(data, doctors),
                indicationRequests,
              }
            : formatEncounterData(data, doctors),
        });
      } else {
        await updateEncounterMutate({
          url: API_SERVER.encounter.update(encounterId!),
          method: 'PUT',
          data: formatEncounterData(data, doctors),
        });
      }
      refresh();
      if (data?.createConsecutively) {
        if (mode === 'CREATE') {
          formMethods.reset({ createConsecutively: data.createConsecutively });
        } else if (mode === 'CREATE_WITH_OLD_PATIENT') {
          formMethods.reset(formatPatientDataToForm(encounter?.patient!, appState));
        }
        setIndicationRequestData([]);
      } else {
        close();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      appState,
      close,
      createEncounterMutate,
      doctors,
      encounter?.patient,
      formMethods,
      mode,
      refresh,
      updateEncounterMutate,
    ],
  );

  return (
    <>
      <NewEncounterWizardTitle close={close} mode={mode} />
      <Box overflow="auto" className="receive-wizard-dialog">
        <SchemaForm
          schema={receivePatientFormSchema}
          onSubmit={onSubmit}
          formId="encounter-form"
          onCancel={close}
          hiddenSearch={false}
          methods={formMethods}
          encounter={encounter}
          onRefund={() => {}}
          doctorOptions={doctorOptions}
          systemCategoriesOptions={systemCategoriesOptions}
          serviceOptions={serviceOptions}
          physicsRoomOptions={physicsRoomOptions}
          encounterMode={mode}
          submitLabel={intl.formatMessage({ id: 'createEncounter' })}
          indicationRequestData={indicationRequestData}
          setIndicationRequestData={setIndicationRequestData}
        />
      </Box>
    </>
  );
};

const ReceiveWizardDialog: React.FunctionComponent<Props> = (props) => {
  const { open } = props;
  return (
    <Dialog
      open={open}
      maxWidth="md"
      keepMounted={false}
      PaperProps={{
        style: { minWidth: 680, position: 'relative', overflow: 'hidden' },
      }}
    >
      <ReceiveWizardForm {...props} />
    </Dialog>
  );
};

export default ReceiveWizardDialog;
