import { z } from 'zod';

export const RegionSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    nameEn: z.string(),
    code: z.string(),
  })
  .partial();

export type Region = z.infer<typeof RegionSchema>;
