import { Box, Grid } from '@mui/material';
import Stack from '@mui/material/Stack';
import Odontogram from '../component/dental/DentalDetail/Odontogram';
import ConditionDiagnosis from '../component/dental/DentalDetail/ConditionDiagnosis';
import Indications from '../component/dental/DentalDetail/Indications';
import TherapyRequests from '../component/dental/DentalDetail/TherapyRequests';
import GeneralInfo from '../component/dental/DentalDetail/GeneralInfo';
import DentalHistory from '../component/dental/DentalDetail/DentalHistory';
import MedicalHistory from '../component/dental/DentalDetail/MedicalHistory';
import ExaminationHistoryTable from '../component/dental/DentalDetail/ExaminationHistory';
import MedicalSupply from '../component/dental/DentalDetail/MedicalSupply';
import DrugUse from '../component/dental/DentalDetail/DrugUse';
import CollapseSection, { CollapseProps } from '../component/dental/DentalDetail/common/CollapseSection';
import { useParams } from 'react-router';
import Prescription from '../component/dental/DentalDetail/Prescription';
import { API_SERVER } from '../../common/api';
import useGeneralHook from '../../common/hook/useGeneralHook';
import * as React from 'react';
import { useDentalDetailStateSelector } from '../component/dental/DentalDetail/state';
import DentalExamActions from '../component/dental/DentalDetail/DentalExamActions';
import CarePlanSession from '../component/dental/DentalDetail/CarePlanSession';
import { useFetch } from 'modules/common/hook';

const LEFT_SECTIONS: CollapseProps[] = [
  { titleKey: 'dental.dentalDetail.sections.odontogram.title', children: <Odontogram /> },
  { titleKey: 'dental.dentalDetail.sections.conditionDiagnosis.title', children: <ConditionDiagnosis /> },
  { titleKey: 'dental.dentalDetail.sections.indications.title', children: <Indications /> },
  { titleKey: 'dental.dentalDetail.sections.therapyRequests.title', children: <TherapyRequests /> },
  { titleKey: 'encounterDetail.prescription', children: <Prescription /> },
];
const RIGHT_SECTIONS: CollapseProps[] = [
  { titleKey: 'dental.dentalDetail.sections.generalInfo.title', children: <GeneralInfo /> },
  { titleKey: 'dental.dentalDetail.sections.dentalHistory.title', children: <DentalHistory /> },
  { titleKey: 'dental.dentalDetail.sections.medicalHistory.title', children: <MedicalHistory /> },
  { titleKey: 'dental.dentalDetail.sections.examinationHistory.title', children: <ExaminationHistoryTable /> },
  { titleKey: 'dental.dentalDetail.sections.drugUse.title', children: <DrugUse /> },
  { titleKey: 'dental.dentalDetail.sections.medicalSupply.title', children: <MedicalSupply /> },
  {
    titleKey: 'dental.dentalDetail.sections.solution.title',
    children: <CarePlanSession readOnly={false} encounter={{}} />,
  },
];

const DentalDetailPage = () => {
  const { appState } = useGeneralHook();
  const { dentalSessionId } = useParams<{ patientId; dentalSessionId }>();
  const setDentalExamInfo = useDentalDetailStateSelector.use.setDentalExamInfo();
  useFetch(API_SERVER.dental.getDentalExam(dentalSessionId), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    onSuccess: (data) => {
      appState.authen.user?.user_id && setDentalExamInfo(data, appState.authen.user?.user_id);
    },
  });

  return (
    <Box margin={2}>
      <Grid container spacing={2}>
        <Grid item xs={8}>
          <Stack gap={3} sx={{ backgroundColor: 'white', padding: 1 }}>
            {LEFT_SECTIONS.map((sectionProps, index) => {
              return <CollapseSection key={index} {...sectionProps} />;
            })}
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Stack gap={2}>
            <DentalExamActions />
            {RIGHT_SECTIONS.map((sectionProps, index) => {
              return <CollapseSection key={index} {...sectionProps} />;
            })}
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DentalDetailPage;
