import { Stack } from '@mui/material';
import ExamHistoryBlock from 'modules/common/component/Encounter/block/ExamHistoryBlock';

const ExamHistorySession = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" gap="10px">
      <ExamHistoryBlock />
    </Stack>
  );
};

export default ExamHistorySession;
