import { PAYMENT_PLAN, some } from '../../../../common/constants';
import * as React from 'react';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { FormProvider, useForm } from 'react-hook-form';
import { NumericFormatText } from '../../../../common/utils';
import { Box, Button, InputAdornment, Typography } from '@mui/material';
import SchemaElement from '../../../../common/SchemaForm/SchemaElement';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import Print from '@mui/icons-material/Print';
import TablePrice from './TablePrice';
import { useCallback, useState } from 'react';
import { setLoading } from '../../../../common/redux/commonReducer';
import MediCard from '../../../../common/component/MediCard';
import PaymentMethodDialog from './PaymentConfirmDialog';
import { fetchThunk } from '../../../../common/redux/thunk';
import { API_SERVER } from '../../../../common/api';
import BillFormPrintDialog from './PrintForm/BillingFormPrintDialog';
import Stack from '@mui/material/Stack';
import SummaryPaymentPrintForm from './SummaryPaymentPrintForm';
import TablePrepayment from './TablePrepayment';
import PrepaymentAddDialog from './PrepaymentAddDialog';
import { Add, Autorenew } from '@mui/icons-material';
import { useUpdateMutate } from 'modules/common/hook/useMutate';
import { mutate } from 'swr';
import { useParams } from 'react-router';
import { PAYMENT_DETAIL_TYPE, PAYMENT_METHOD } from 'modules/common/apiConstants';

interface ITablePriceBoxProps {
  paymentDetail?: some;
  therapyDetail?: some;
  prepaymentDetail?: some;
  paymentAdvanceList?: some;
  revalidate: () => void;
  loading?: boolean;
}

const TablePriceBox: React.FunctionComponent<ITablePriceBoxProps> = (props) => {
  const { paymentDetail, therapyDetail, prepaymentDetail, paymentAdvanceList, revalidate, loading } = props;
  const { intl, confirmDialog, dispatch, openSnackbar } = useGeneralHook();
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
  const [openMethodDialog, setOpenMethodDialog] = React.useState(false);

  const [selectedPrepaymentList, setSelectedPrepaymentList] = React.useState<some[]>([]);
  const [openSummaryPaymentPrintForn, setOpenSummaryPaymentPrintForm] = useState(false);
  const [openPrepaidAddDialog, setOpenPrepaidAddDialog] = useState(false);
  const { promptConfirmation, close } = confirmDialog;
  const { paymentId } = useParams<{ paymentId: any }>();

  const methods = useForm({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const { setValue, watch, getValues, reset } = methods;
  const reCalculatePaymentDetail = useCallback((paymentDetail, discountPercent) => {
    //Số tiền CK theo % tổng hóa đơn = Số lượng * Đơn giá * CK tổng hóa đơn
    paymentDetail.receiptDiscountAmount =
      (paymentDetail.quantity * paymentDetail.unitPrice * (discountPercent || 0)) / 100;

    //Số tiền CK = Số tiền CK theo % tổng hóa đơn + CK đơn giá* Số lượng
    const discountAmount =
      paymentDetail.discountType === 'PERCENT'
        ? (paymentDetail.quantity * paymentDetail.unitPrice * paymentDetail.discount) / 100
        : paymentDetail.quantity * paymentDetail.discount;
    paymentDetail.discountAmount = paymentDetail.receiptDiscountAmount + discountAmount;

    //Thành tiền = Đơn giá*Số lượng - Số tiền CK
    paymentDetail.totalAmount = paymentDetail.quantity * paymentDetail.unitPrice - paymentDetail.discountAmount;

    // Thuế VAT = Thành tiền * VAT%
    paymentDetail.vatAmount = (paymentDetail.totalAmount * (paymentDetail.vat || 0)) / 100;

    // Khách hàng trả = Thành tiền + Thuế VAT
    paymentDetail.patientPay = paymentDetail.totalAmount + paymentDetail.vatAmount;

    return paymentDetail;
  }, []);

  React.useEffect(() => {
    let tmp = paymentDetail?.paymentDetails?.map((p, index) => {
      const paymentType =
        p?.packageScope === 'OUT_PACKAGE' && p?.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value
          ? PAYMENT_DETAIL_TYPE.THERAPY_SESSION_OUT_PACKAGE
          : PAYMENT_DETAIL_TYPE?.[p.type];

      const calculatedData = reCalculatePaymentDetail(p, paymentDetail?.discountPercent);
      let referenceObj;
      if (paymentType === PAYMENT_DETAIL_TYPE.THERAPY) {
        referenceObj = therapyDetail;
      } else if (
        paymentType === PAYMENT_DETAIL_TYPE.THERAPY_SESSION ||
        paymentType === PAYMENT_DETAIL_TYPE.THERAPY_SESSION_OUT_PACKAGE
      ) {
        referenceObj = therapyDetail?.therapySessionList?.find((t) => t.id === calculatedData.referenceId);
      }
      return {
        ...calculatedData,
        index,
        status: p.refundId ? 'REFUNDED' : p.status,
        referenceObj,
        therapyPaymentType: paymentDetail?.isPackagePaid === false ? 'SEPARATE' : 'PACKAGE',
        discountType: calculatedData.discountType || 'PERCENT',
        type: paymentType?.value,
        paymentType,
      };
    });
    reset({
      paymentList: tmp,
      paymentAdvanceList,
      prepaymentDetail,
      discountPercent: paymentDetail?.discountPercent || 0,
      paymentId: paymentDetail?.id,
    });
  }, [paymentDetail, therapyDetail, paymentAdvanceList, prepaymentDetail, reset, reCalculatePaymentDetail]);

  const reCalculate = useCallback(
    (paymentDetail, event?) => {
      if (event) {
        const name = event.target.name.split('.')?.[2];
        if (name === 'unitPrice' || name === 'discount' || name === 'vat') {
          paymentDetail = { ...paymentDetail, [name]: event.target.value ? event.target.value : 0 };
        } else {
          paymentDetail = { ...paymentDetail, [name]: event.target.value };
        }
      }
      const temp = [...getValues('paymentList')];
      const index = temp.findIndex((d) => d.id === paymentDetail.id);
      temp[index] = reCalculatePaymentDetail(paymentDetail, getValues('discountPercent'));
      setValue('paymentList', [...temp]);
    },
    [getValues, reCalculatePaymentDetail, setValue],
  );

  const reCalculateAll = useCallback(
    (event) => {
      setValue('discountPercent', event.target.value);
      const temp = getValues('paymentList')?.map((p) => {
        return reCalculatePaymentDetail(p, event.target.value);
      });
      setValue('paymentList', [...temp]);
    },
    [getValues, reCalculatePaymentDetail, setValue],
  );

  const setSelection = useCallback(
    (paymentDetail, checked) => {
      if (paymentDetail) {
        const temp = [...getValues('paymentList')];
        const index = temp.findIndex((d) => d.id === paymentDetail.id);
        paymentDetail.checked = checked;
        temp[index] = paymentDetail;
        setValue('paymentList', [...temp]);
      }
    },
    [getValues, setValue],
  );

  const setPaymentType = useCallback(
    (paymentDetail, val) => {
      const temp = [...getValues('paymentList')];
      const index = temp.findIndex((d) => d.id === paymentDetail.id);
      paymentDetail.isPackagePaid = val === 'PACKAGE';
      paymentDetail.therapyPaymentType = val;
      temp[index] = paymentDetail;
      setValue('paymentList', [...temp]);
    },
    [setValue, getValues],
  );

  const calculateSummaryData = useCallback((listPaymentDetail, discountPercent) => {
    let result: some = {};
    let dataSource;
    const therapyPaymentDetail = listPaymentDetail?.find((d) => d.type === PAYMENT_DETAIL_TYPE.THERAPY.value);
    if (therapyPaymentDetail?.therapyPaymentType === 'PACKAGE') {
      dataSource = listPaymentDetail?.filter((d) => d.type !== PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value);
    } else {
      dataSource = listPaymentDetail?.filter((d) => d.type !== PAYMENT_DETAIL_TYPE.THERAPY.value);
    }

    const selectedList = dataSource?.filter((d) => d?.checked);
    const paidList = dataSource?.filter((d) => d?.status === 'PAID');
    const unpaidList = dataSource?.filter((d) => d?.status === 'UNPAID');
    const refundList = dataSource?.filter((d) => d?.status === 'REFUNDED');

    // Thành tiền theo đơn giá = SUM of Đơn giá * Số lượng by each item Thu phí in the table above
    result.totalAmountAllItem = dataSource?.reduce((a, b) => a + b.unitPrice * b.quantity, 0);

    // Số tiền CK trên % tổng HĐ = Thành tiền theo đơn giá * Số tiền CK trên % tổng HĐ
    result.totalReceiptDiscountAmount = (result.totalAmountAllItem * (discountPercent || 0)) / 100;

    // Tổng số tiền được CK = SUM of Số tiền CK by all item Thu phí in the table above
    result.totalDiscountAmount = dataSource?.reduce((a, b) => a + b.discountAmount, 0);

    //Thành tiền sau CK và VAT = SUM of Khách hàng trả
    result.totalPatientPay = dataSource?.reduce((a, b) => a + b.patientPay, 0);

    //Tổng tiền các item đã chọn = SUM of Khách hàng trả + checked = true
    result.totalPatientPayChecked = selectedList?.reduce((a, b) => a + b.patientPay, 0);

    // Khách hàng đã trả = SUM of Khách hàng trả by item Thu phí with Status = Đã thanh toán/Đã hoàn tiền in the table above
    result.totalPatientPaid =
      paidList?.reduce((a, b) => a + b.patientPay, 0) + refundList?.reduce((a, b) => a + b.patientPay, 0);

    // Tổng CK trên DV đã thanh toán = SUM of Số tiền CK by all item Thu phí that has been Đã thanh toán/PAID in the table above
    result.totalDiscountAmountPaid = paidList?.reduce((a, b) => a + b.discountAmount, 0);

    // khách hàng đã được hoàn tiền
    result.patientRefunded = 0;

    // khách hàng sẽ được hoàn tiền
    result.patientWillRefunded = 0;

    // khách hàng phải trả = SUM of Khách hàng trả by all item Thu phí that is Chưa thanh toán in the table above
    result.patientMustPay = unpaidList?.reduce((a, b) => a + b.patientPay, 0);

    result.hasBilledItem = paidList?.length > 0 || refundList?.length > 0;

    return result;
  }, []);

  const onPayment = React.useCallback(
    async (params: some) => {
      const { dataSource, paymentMethod, note, prepayment } = params;

      const confirm = await promptConfirmation({
        title: <FormattedMessage id="confirm" />,
        content: <FormattedMessage id="therapy.cashier.payment.message.confirm" />,
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          const summaryData = calculateSummaryData(dataSource?.paymentList, dataSource.discountPercent);

          const paymentMethodEnum = PAYMENT_METHOD[paymentMethod as string].value;
          const paymentDetails = dataSource?.paymentList?.filter((d) => d.checked);
          await dispatch(
            fetchThunk(API_SERVER.payment.pay(dataSource?.paymentId), 'post', {
              isPackagePaid: paymentDetails?.[0]?.therapyPaymentType === 'PACKAGE',
              paymentDetails,
              note,
              amountPaid: summaryData?.totalPatientPayChecked,
              discountPercent: dataSource?.discountPercent,
              paymentMethod: paymentMethodEnum,
              prepayment,
            }),
          );
          revalidate();

          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e: any) {
          if (e?.errors) {
            e?.errors?.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [promptConfirmation, close, dispatch, calculateSummaryData, revalidate, openSnackbar, intl],
  );

  const onAddPaymentAdvance = React.useCallback(
    async (paymentAdvanceDetail) => {
      try {
        dispatch(setLoading(true));
        const paymentMethodEnum =
          paymentAdvanceDetail?.paymentMethod && PAYMENT_METHOD[paymentAdvanceDetail?.paymentMethod as string].value;
        await dispatch(
          fetchThunk(
            API_SERVER.prepayment.createPaymentAdvance,
            'post',
            {
              ...paymentAdvanceDetail,
              paymentMethod: paymentMethodEnum,
              prepaymentId: prepaymentDetail?.id,
              paymentId: paymentDetail?.id,
            },
            'application/json-patch+json',
          ),
        );
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
      } catch (e: any) {
        if (e?.errors) {
          e?.errors?.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, paymentDetail?.id, prepaymentDetail?.id, revalidate],
  );

  const updatePaymentDetailMutate = useUpdateMutate({
    onSuccess: () => mutate(API_SERVER.payment.detail(paymentId, { type: 'THERAPY' }).url),
  });
  const onUpdatePayment = useCallback(async () => {
    const formData = getValues();

    const isPackagePaid =
      formData?.paymentList?.find((payment) => payment.type === PAYMENT_DETAIL_TYPE.THERAPY.value)
        .therapyPaymentType === 'PACKAGE';
    const paymentDetails = isPackagePaid
      ? formData?.paymentList?.filter(
          (payment) => payment?.type !== PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value && payment?.status === 'UNPAID',
        )
      : formData?.paymentList?.filter(
          (payment) => payment?.type !== PAYMENT_DETAIL_TYPE.THERAPY.value && payment?.status === 'UNPAID',
        );
    await updatePaymentDetailMutate({
      url: API_SERVER.payment.calculate(formData?.paymentId),
      method: 'PUT',
      data: {
        isPackagePaid,
        paymentDetails: paymentDetails?.map((detail) => ({
          id: detail?.id,
          quantity: detail.quantity,
          unitPrice: detail?.unitPrice,
          vat: detail?.vat,
          discount: detail?.discount,
          discountType: detail?.discountType,
        })),
        discountPercent: formData?.discountPercent || 0,
      },
    });
  }, [getValues, updatePaymentDetailMutate]);

  const FooterBox = React.useMemo(() => {
    const dataSource = getValues('paymentList');
    const discountPercent = getValues('discountPercent');
    const summaryData = calculateSummaryData(dataSource, discountPercent);

    return (
      <>
        <Box display="flex" justifyContent="flex-end" paddingBottom={3} marginRight={3}>
          <Box display="flex" flexDirection="column" gap={1} width={420} marginTop={2}>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1">
                <FormattedMessage id={'cashier.totalPrice'} />
              </Typography>
              <Typography variant="subtitle1">{NumericFormatText(summaryData?.totalAmountAllItem || 0)}</Typography>
            </Box>{' '}
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Typography variant="subtitle1">
                <FormattedMessage id={'cashier.totalDiscountBill'} />
              </Typography>
              {summaryData?.hasBilledItem || therapyDetail?.paymentPlan === PAYMENT_PLAN.FREE.value ? (
                <Typography variant="subtitle1">
                  {discountPercent && (
                    <>
                      <FormattedNumber value={discountPercent} />%
                    </>
                  )}
                </Typography>
              ) : (
                <SchemaElement
                  fieldName={'discountPercent'}
                  propsElement={{
                    type: 'text-field',
                    placeholder: intl.formatMessage({
                      id: 'cashier.enter',
                    }),
                    inputType: 'number',
                    style: { width: 80 },
                    propsWrapper: { xs: undefined },
                    noHelperText: true,
                    InputProps: {
                      endAdornment: <InputAdornment position="end">%</InputAdornment>,
                    },
                    register: { max: 100, min: 0 },
                    onChange: (event) => {
                      reCalculateAll(event);
                    },
                    onBlur: onUpdatePayment,
                  }}
                />
              )}
            </Box>{' '}
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1">
                <FormattedMessage id={'cashier.billDiscountAmount'} />
              </Typography>
              <Typography variant="subtitle1">
                {NumericFormatText(summaryData?.totalReceiptDiscountAmount || 0)}
              </Typography>
            </Box>{' '}
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1">
                <FormattedMessage id={'cashier.totalDiscount'} />
              </Typography>
              <Typography variant="subtitle1">{NumericFormatText(summaryData?.totalDiscountAmount || 0)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1" color="primary">
                <FormattedMessage id={'cashier.totalPriceAfterDiscountAndVat'} />
              </Typography>
              <Typography variant="subtitle1">{NumericFormatText(summaryData?.totalPatientPay)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1">
                <FormattedMessage id={'customerPaid'} />
              </Typography>
              <Typography variant="subtitle1">{NumericFormatText(summaryData?.totalPatientPaid || 0)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1">
                <FormattedMessage id={'therapy.prepaid.summary.prepayment.amount'} />
              </Typography>
              <Typography variant="subtitle1">{NumericFormatText(prepaymentDetail?.amount || 0)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="subtitle1">
                <FormattedMessage id={'cashier.discountItem'} />
              </Typography>
              <Typography variant="subtitle1">{NumericFormatText(summaryData?.totalDiscountAmountPaid)}</Typography>
            </Box>
            <Box display="flex" justifyContent="space-between" paddingBottom={1} borderBottom="1px solid grey">
              <Typography variant="subtitle1">
                <FormattedMessage id={'customerHasBeenRefunded'} />
              </Typography>
              <Typography variant="subtitle1">
                {NumericFormatText((paymentDetail?.refundAmount || 0) + (prepaymentDetail?.refundAmount || 0))}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="space-between">
              <Typography variant="h6" color="primary">
                <FormattedMessage id={'therapy.payment.summaryPrintForm.title.customerPay'} />
              </Typography>
              <Typography variant="subtitle1">{NumericFormatText(summaryData?.patientMustPay || 0)}</Typography>
            </Box>
            <Box display="flex" gap={2}>
              <Button
                variant="contained"
                color="inherit"
                style={{ flex: 1 }}
                disabled={!dataSource?.find((p) => p.checked)}
                onClick={() => setOpenPrintDialog(true)}
                startIcon={<Print />}
              >
                <FormattedMessage id="printReceipt" />
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ flex: 1 }}
                disabled={!dataSource?.find((p) => p.checked)}
                onClick={() => setOpenMethodDialog(true)}
              >
                <FormattedMessage id="pay" />
              </Button>
            </Box>
          </Box>
        </Box>

        <BillFormPrintDialog
          open={openPrintDialog}
          therapyDetail={therapyDetail}
          dataSource={watch()}
          onClose={() => {
            setOpenPrintDialog(false);
          }}
        />
        <PaymentMethodDialog
          open={openMethodDialog}
          onClose={() => {
            setOpenMethodDialog(false);
          }}
          onSubmit={(params) => {
            const dataSource = watch();
            setOpenMethodDialog(false);
            onPayment({ dataSource, ...params });
          }}
          paymentDetail={getValues()}
        />
        <PrepaymentAddDialog
          open={openPrepaidAddDialog}
          onClose={() => {
            setOpenPrepaidAddDialog(false);
          }}
          onSubmit={(paymentAdvanceDetail) => {
            onAddPaymentAdvance(paymentAdvanceDetail);
            setOpenPrepaidAddDialog(false);
          }}
        />
      </>
    );
  }, [
    getValues,
    calculateSummaryData,
    therapyDetail,
    intl,
    onUpdatePayment,
    prepaymentDetail?.amount,
    prepaymentDetail?.refundAmount,
    paymentDetail?.refundAmount,
    openPrintDialog,
    watch,
    openMethodDialog,
    openPrepaidAddDialog,
    reCalculateAll,
    onPayment,
    onAddPaymentAdvance,
  ]);

  return (
    <>
      <Box marginY={1} display="flex" justifyContent="space-between">
        <FormProvider {...methods}>
          <form style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <MediCard
              sx={{ marginBottom: '20px' }}
              title={
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ lineHeight: '30px' }}>
                    <FormattedMessage id="prepaid" />
                  </Typography>
                  <Button
                    variant="contained"
                    color="inherit"
                    onClick={() => setOpenPrepaidAddDialog(true)}
                    startIcon={<Add />}
                  >
                    <FormattedMessage id="prepaidAdd" />
                  </Button>
                </Stack>
              }
            >
              <TablePrepayment
                formData={watch()}
                paymentAdvanceList={watch('paymentAdvanceList')}
                prepaymentDetail={watch('prepaymentDetail')}
                therapyDetail={therapyDetail}
                methods={methods}
                loading={loading}
                revalidate={revalidate}
                setSelectedPrepaymentList={setSelectedPrepaymentList}
                selectedPrepaymentList={selectedPrepaymentList}
              ></TablePrepayment>
            </MediCard>

            <MediCard
              title={
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ lineHeight: '30px' }}>
                    <FormattedMessage id="pay" />
                  </Typography>
                  <Box display="flex" gap={2}>
                    <Button
                      variant="contained"
                      color="inherit"
                      onClick={() => setOpenSummaryPaymentPrintForm(true)}
                      startIcon={<Print />}
                    >
                      <FormattedMessage id="therapy.payment.printForm.label.printButton" />
                    </Button>
                    <Button
                      onClick={() => {
                        revalidate();
                      }}
                      startIcon={<Autorenew />}
                      color="inherit"
                    >
                      <FormattedMessage id="refresh" />
                    </Button>
                  </Box>
                </Stack>
              }
            >
              <TablePrice
                dataSource={watch()}
                therapyDetail={therapyDetail}
                paymentList={watch('paymentList')}
                reCalculate={reCalculate}
                setSelection={setSelection}
                setPaymentType={setPaymentType}
                methods={methods}
                loading={loading}
                revalidate={revalidate}
                onUpdatePayment={onUpdatePayment}
              ></TablePrice>
            </MediCard>
            {FooterBox}
          </form>
        </FormProvider>

        <BillFormPrintDialog
          open={openPrintDialog}
          therapyDetail={therapyDetail}
          dataSource={watch()}
          onClose={() => {
            setOpenPrintDialog(false);
          }}
        />
        {therapyDetail && paymentDetail && (
          <SummaryPaymentPrintForm
            open={openSummaryPaymentPrintForn}
            onClose={() => setOpenSummaryPaymentPrintForm(false)}
            therapyDetail={therapyDetail}
            paymentDetail={paymentDetail}
          />
        )}
      </Box>
    </>
  );
};

export default TablePriceBox;
