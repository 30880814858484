import { Grid, Tooltip } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { GENDER } from 'modules/common/apiConstants';
import { TypographyCustom } from './styled';
import { Encounter } from 'modules/schema';
import { ROUTES } from 'modules/layout/router';
import { duplicateTab } from 'modules/common/component/Encounter/utils';

interface PatientInfoBlockProps {
  encounter: Encounter;
}

const PatientInfoBlock = ({ encounter }: PatientInfoBlockProps) => {
  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    duplicateTab(ROUTES.patientProfile.gen(encounter?.patientId!));
  };

  return (
    <Grid container sx={{ cursor: 'default' }}>
      <Grid item xs={4} color={'#455A64'}>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="dental.patient.label.fullName" />
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="birthdayShort" />
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="age_" />
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="gender" />
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="patientCode" />
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="telephone" />
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="encounterId" />
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="examDoctor" />
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="serviceName" />
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          <FormattedMessage id="dental.patient.label.reason" />
        </TypographyCustom>
      </Grid>
      <Grid item xs={8}>
        <Tooltip title={encounter?.patient?.name || ''}>
          <TypographyCustom
            variant="subtitle2"
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
              maxWidth: '200px',
            }}
          >
            {encounter?.patient?.name}
          </TypographyCustom>
        </Tooltip>

        <TypographyCustom variant="subtitle2">
          {moment(encounter?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          {encounter?.patient?.age} ({encounter?.patient?.month})
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">
          {encounter?.patient?.gender && <FormattedMessage id={GENDER[encounter?.patient?.gender!]?.label} />}
        </TypographyCustom>
        <TypographyCustom
          variant="subtitle2"
          color={'primary'}
          onClick={handleClick}
          sx={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: '700' }}
        >
          {encounter?.patient?.formatCode}
        </TypographyCustom>
        <TypographyCustom variant="subtitle2">{encounter?.patient?.mobilePhone}</TypographyCustom>
        <TypographyCustom variant="subtitle2">{encounter?.code}</TypographyCustom>
        <TypographyCustom variant="subtitle2">{encounter?.picName}</TypographyCustom>
        <TypographyCustom variant="subtitle2">{encounter?.serviceName}</TypographyCustom>
        <TypographyCustom variant="subtitle2">{encounter?.reason}</TypographyCustom>
      </Grid>
    </Grid>
  );
};

export default PatientInfoBlock;
