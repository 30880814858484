import { Delete } from '@mui/icons-material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { Box, Grid, IconButton } from '@mui/material';
import { StorageResource } from 'modules/schema';
import { CSSProperties, memo, useState } from 'react';
import ImagePreview from './ImagePreview';
import ImageZoomIn from './ImageZoomIn';
import { useDialog } from '../hook';

interface Props {
  images: StorageResource[] | undefined;
  onDeleteImage: (image: StorageResource, index: number) => void;
  editable: boolean;
  imageWidth?: CSSProperties['width'];
  imageHeight?: CSSProperties['height'];
  lastItem?: React.ReactElement;
}

const ImageList = ({ images, onDeleteImage, editable, imageHeight, imageWidth, lastItem }: Props) => {
  const [selectedIndex, setSelectedIndex] = useState<undefined | number>(undefined);
  const [open, onOpenDialog, onCloseDialog] = useDialog();

  return (
    <Box>
      <Grid container direction="row" gap={2}>
        {images?.map((image, index) => {
          return (
            <Grid
              item
              key={image?.id}
              position="relative"
              boxShadow="0px 4px 8px 0px #E0ECFF"
              sx={{ cursor: 'pointer' }}
              onClick={() => {
                setSelectedIndex(index);
                onOpenDialog();
              }}
            >
              <ImagePreview
                alt={image.fileName}
                imageId={image.id!}
                style={{
                  height: imageHeight || '100%',
                  width: imageWidth || '100%',
                  objectFit: 'cover',
                }}
              />
              <Box
                sx={{ opacity: '0', transition: 'all .3s', '&:hover': { opacity: '0.8' } }}
                className="action-area"
                position="absolute"
                left={0}
                top={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                height="100%"
                width="100%"
                bgcolor="#7f7f7f"
              >
                {editable && (
                  <IconButton
                    size="small"
                    style={{ background: 'white', marginRight: 16 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      onDeleteImage(image, index);
                    }}
                  >
                    <Delete color="inherit" fontSize="small" />
                  </IconButton>
                )}
                <IconButton
                  size="small"
                  style={{ background: 'white' }}
                  onClick={() => {
                    setSelectedIndex(index);
                    onOpenDialog();
                  }}
                >
                  <ZoomInIcon color="inherit" />
                </IconButton>
              </Box>
            </Grid>
          );
        })}
        {lastItem && <Grid item>{lastItem}</Grid>}
      </Grid>
      <ImageZoomIn
        open={open}
        onClose={onCloseDialog}
        images={images?.map((item) => ({
          imageId: item.id,
        }))}
        index={selectedIndex}
      />
    </Box>
  );
};

export default memo(ImageList);
