import Print from '@mui/icons-material/Print';
import { Box, Typography } from '@mui/material';
import * as React from 'react';
import DialogCustom from 'modules/common/component/DialogCustom';
import TableCustom from 'modules/common/component/TableCustom';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, FE_TIME_FORMAT, some } from 'modules/common/constants';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { PAYMENT_METHOD_ENUM } from '../../../admin/component/saleManagement/constant';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { NumericFormatText } from '../../../common/utils';
import { PAYMENT_HISTORY_TYPE } from 'modules/common/apiConstants';

interface Props {
  open: boolean;
  onClose?: () => void;
  data: some;
  onOpenPrepaymentRefundPrint: (record) => void;
  onOpenPaymentHistoryPrint: (record) => void;
  onOpenPaymentRefundHistoryPrint: (record) => void;
}

const FormPaymentHistoryContent = (props: Props) => {
  const { data, onOpenPrepaymentRefundPrint, onOpenPaymentHistoryPrint, onOpenPaymentRefundHistoryPrint } = props;

  const testInfo = React.useMemo(() => {
    return (
      <>
        <TableCustom
          dataSource={data?.data}
          columns={[
            {
              dataIndex: 'transactionTime',
              title: 'therapy.cashier.dialog.label.paymentHistory.transactionTime',
              render: (record) => (
                <Typography>
                  {record.transactionTime &&
                    moment(record.transactionTime, BE_DATE_FORMAT).format(FE_DATE_FORMAT) +
                      ' - ' +
                      moment(record.transactionTime, BE_DATE_TIME_FORMAT).format(FE_TIME_FORMAT)}
                </Typography>
              ),
            },
            {
              dataIndex: 'type',
              title: 'therapy.cashier.dialog.label.paymentHistory.paymentType',
              render: (record) => (
                <FormattedMessage id={PAYMENT_HISTORY_TYPE[record?.type]?.label || ' '}></FormattedMessage>
              ),
            },
            {
              dataIndex: 'paymentMethod',
              title: 'therapy.cashier.dialog.label.paymentHistory.paymentMethod',
              render: (record) => (
                <FormattedMessage
                  id={
                    record?.type === PAYMENT_HISTORY_TYPE.PREPAYMENT.value
                      ? 'therapy.cashier.payment.prepayment.type'
                      : PAYMENT_METHOD_ENUM.find((v) => v.value === record?.paymentMethod)?.label || ' '
                  }
                ></FormattedMessage>
              ),
            },
            {
              dataIndex: 'amountPaid',
              title: 'therapy.cashier.dialog.label.paymentHistory.amountPaid',
              render: (record) => {
                return <Typography variant="body1">{NumericFormatText(record.amountPaid)}</Typography>;
              },
            },
            {
              title: 'action',
              align: 'right',
              width: 120,
              render: (record) => {
                return (
                  <>
                    {record?.type !== PAYMENT_HISTORY_TYPE.PAYMENT_ADVANCE.value && (
                      <IconButtonTitle
                        title="print"
                        size="small"
                        onClick={() => {
                          if (record.type === PAYMENT_HISTORY_TYPE.PAYMENT_ADVANCE_REFUND.value) {
                            onOpenPrepaymentRefundPrint(record);
                          } else if (
                            record.type === PAYMENT_HISTORY_TYPE.PAYMENT.value ||
                            record.type === PAYMENT_HISTORY_TYPE.PREPAYMENT.value
                          ) {
                            onOpenPaymentHistoryPrint(record);
                          } else if (record.type === PAYMENT_HISTORY_TYPE.REFUND.value) {
                            onOpenPaymentRefundHistoryPrint(record);
                          }
                        }}
                        style={{ margin: '0px 8px' }}
                      >
                        <Print color={record.validation === false ? 'error' : undefined} />
                      </IconButtonTitle>
                    )}
                  </>
                );
              },
            },
          ]}
        />
      </>
    );
  }, [data?.data, onOpenPaymentHistoryPrint, onOpenPaymentRefundHistoryPrint, onOpenPrepaymentRefundPrint]);

  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }}>{testInfo}</Box>
    </>
  );
};

const PaymentHistoryFormDialog = (props: Props) => {
  const { onClose, open } = props;

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { width: '80vw' },
      }}
      maxWidth="xl"
      title={'therapy.cashier.dialog.label.paymentHistory'}
    >
      <Box>
        <table style={{ width: '100%' }}>
          <tbody>
            <FormPaymentHistoryContent {...props} />
          </tbody>
        </table>
      </Box>
    </DialogCustom>
  );
};

export default PaymentHistoryFormDialog;
