import { Close, Delete } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import { useCallback, useState } from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DownloadIcon from '@mui/icons-material/Download';
import { FormattedMessage } from 'react-intl';
import { AttachedDocument, StorageResource } from 'modules/schema';
import { API_SERVER } from 'modules/common/api';
import axios from 'axios';
import { SS_TOKEN } from 'modules/common/constants';
import { setLoading } from 'modules/common/redux/commonReducer';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { PRIMARY } from 'colors';
import { useDownloadFile } from 'modules/common/hook';
import { renderIconFile } from 'modules/common/utils';

interface Props {
  data: StorageResource;
  onDeleteFile: (doc: AttachedDocument) => void;
  enableDelete?: boolean;
}
const DocumentBox = ({ data, onDeleteFile, enableDelete }: Props) => {
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const { dispatch, openSnackbar } = useGeneralHook();
  const [base64File, setBase64File] = useState<string | ''>('');

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }, []);

  // download file
  const onDownloadFile = useDownloadFile(API_SERVER.storageResource.doawloadFile(data?.id!));

  // file type
  const isImageType =
    data?.fileName?.endsWith('.jpg') || data?.fileName?.endsWith('.jpeg') || data?.fileName?.endsWith('.png');
  const isDocType = data?.fileName?.endsWith('.doc') || data?.fileName?.endsWith('.docx');

  // get data image preview
  const getDataImagePreview = useCallback(
    async (value) => {
      try {
        dispatch(setLoading(true));
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        const response = await axios.get(API_SERVER.s3Resource.download(value?.id), {
          headers: { 'Content-type': 'application/json', Authorization: `Bearer ${token}` },
          responseType: 'arraybuffer',
        });
        const uint8Array = new Uint8Array(response.data);
        const reader = new FileReader();
        if (data?.fileName?.endsWith('.jpg') || data?.fileName?.endsWith('.jpeg')) {
          const blob = new Blob([uint8Array], { type: 'image/jpeg' });
          reader.readAsDataURL(blob);
        } else if (data?.fileName?.endsWith('.pdf')) {
          const blob = new Blob([uint8Array], { type: 'application/pdf' });
          reader.readAsDataURL(blob);
        } else if (data?.fileName?.endsWith('.png')) {
          const blob = new Blob([uint8Array], { type: 'image/png' });
          reader.readAsDataURL(blob);
        }
        reader.onload = () => {
          const base64String = reader?.result as string;
          setBase64File(base64String);
        };
      } catch (e: any) {
        openSnackbar({
          message: <FormattedMessage id="downloadFail" />,
          type: 400,
        });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, data?.fileName, openSnackbar],
  );

  return (
    <>
      <Box
        display="flex"
        borderBottom={1}
        borderColor="divider"
        alignItems="center"
        sx={{
          '&:hover': {
            bgcolor: PRIMARY[50],
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          style={{ cursor: isDocType ? 'unset' : 'pointer' }}
          onClick={() => {
            if (isImageType || data?.fileName?.endsWith('.pdf')) {
              setOpen(true);
              getDataImagePreview(data);
            }
          }}
        >
          {renderIconFile(data)}
          <Typography variant="body1" noWrap style={{ flex: 1, marginLeft: 8 }}>
            {data.fileName}
          </Typography>
        </Box>
        <IconButtonTitle title="download" size="small" onClick={onDownloadFile}>
          <DownloadIcon />
        </IconButtonTitle>
        {enableDelete && (
          <IconButtonTitle title="delete" size="small" onClick={() => onDeleteFile(data)}>
            <Delete />
          </IconButtonTitle>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
          setBase64File('');
        }}
        maxWidth="xl"
        PaperProps={{
          style: { overflow: 'auto' },
        }}
      >
        <IconButton
          style={{ position: 'absolute', top: 8, right: 8, zIndex: 100, background: 'white' }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
        {data?.fileName?.endsWith('.pdf') ? (
          <Box position="relative">
            <Box padding={2} paddingBottom={0}>
              <Document
                loading={<LoadingIcon style={{ width: 500, height: 500 }} />}
                file={base64File}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(e) => console.log(e)}
                options={{
                  cMapUrl: 'cmaps/',
                  cMapPacked: true,
                }}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                bgcolor: 'background.paper',
                position: 'sticky',
                bottom: 0,
                paddingX: 2,
                paddingY: 1,
              }}
            >
              <Typography variant="body1">
                {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
              </Typography>
              <Button
                type="button"
                disabled={pageNumber <= 1}
                onClick={() => {
                  setPageNumber((prevPageNumber) => prevPageNumber - 1);
                }}
              >
                <ArrowBackIcon />
              </Button>
              <Button
                type="button"
                style={{ marginLeft: 12 }}
                disabled={pageNumber >= numPages}
                onClick={() => {
                  setPageNumber((prevPageNumber) => prevPageNumber + 1);
                }}
              >
                <ArrowForwardIcon />
              </Button>
            </Box>
          </Box>
        ) : isImageType ? (
          <img
            src={base64File}
            alt={data?.fileName}
            style={{ objectFit: 'contain', height: '100%', maxHeight: '100%' }}
          />
        ) : (
          <></>
        )}
      </Dialog>
    </>
  );
};
export default DocumentBox;
