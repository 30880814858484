import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from '../../../common/component/TableCustom/element';
import { LAB_TYPE_RESULT_CORE_SERVICE, some } from '../../../common/constants';

export const TableCellPadding = withStyles({
  root: {
    padding: 8,
  },
})(TableCell);

interface Props {
  data?: some[];
  setFormData: (item: some) => void;
  onDelete: (item: some) => void;
  loading?: boolean;
}

function Row(props: { row: some; setFormData: (item: some) => void; onDelete: (item: some) => void }) {
  const { row, setFormData, onDelete } = props;
  const [open, setOpen] = React.useState(false);

  const shouldShowTable =
    row?.resultType === LAB_TYPE_RESULT_CORE_SERVICE.quantity || row?.resultType === LAB_TYPE_RESULT_CORE_SERVICE.list;

  return (
    <React.Fragment>
      <TableCustomRow>
        <TableCustomCell style={{ width: 70 }}>
          {shouldShowTable && (
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              <KeyboardArrowDownIcon
                style={{
                  transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'all 200ms',
                  fontSize: 24,
                }}
              />
            </IconButton>
          )}
        </TableCustomCell>
        <TableCustomCell>{row.code}</TableCustomCell>
        <TableCustomCell>{row.name}</TableCustomCell>
        <TableCustomCell>
          {row.resultType && <FormattedMessage id={`referenceIndication.${row.resultType}`} />}
        </TableCustomCell>
        <TableCustomCell align="right">{row.unit}</TableCustomCell>
        <TableCustomCell align="right">
          <IconButton
            size="small"
            onClick={() => {
              setFormData(row);
            }}
            style={{ margin: '0px 8px' }}
          >
            <EditIcon color={row.validation === false ? 'error' : undefined} />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              onDelete(row);
            }}
          >
            <DeleteIcon />
          </IconButton>
        </TableCustomCell>
      </TableCustomRow>
      <TableCustomRow>
        <TableCustomCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} bgcolor="white">
              {shouldShowTable ? (
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCellPadding style={{ width: 54 }} />
                      <TableCellPadding style={{ width: '31%' }}>
                        <FormattedMessage id="referenceIndication.condition" />
                      </TableCellPadding>
                      <TableCellPadding>
                        <FormattedMessage id="referenceIndication.value" />
                      </TableCellPadding>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.laboratoryConditionList?.map((record: some, index) => (
                      <TableRow key={index}>
                        <TableCellPadding />
                        <TableCellPadding>{record.name}</TableCellPadding>
                        <TableCellPadding>
                          {record.lowIndex}&nbsp;≤&nbsp;{record.highIndex}
                        </TableCellPadding>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              ) : null}
            </Box>
          </Collapse>
        </TableCustomCell>
      </TableCustomRow>
    </React.Fragment>
  );
}

const ReferenceListTable: React.FunctionComponent<Props> = (props) => {
  const { setFormData, onDelete, data } = props;

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableCustomRow>
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell>
              <FormattedMessage id="referenceIndication.code" />
            </TableHeaderCell>
            <TableHeaderCell style={{ width: '30%' }}>
              <FormattedMessage id="referenceIndication.name" />
            </TableHeaderCell>
            <TableHeaderCell>
              <FormattedMessage id="referenceIndication.typeResult" />
            </TableHeaderCell>
            <TableHeaderCell align="right">
              <FormattedMessage id="referenceIndication.unit" />
            </TableHeaderCell>
            <TableHeaderCell align="right">
              <FormattedMessage id="action" />
            </TableHeaderCell>
          </TableCustomRow>
        </TableHead>
        <TableBody>
          {data?.map((row, index) => (
            <Row key={index} row={row} onDelete={onDelete} setFormData={setFormData} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReferenceListTable;
