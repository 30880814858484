import { VALUE_EVALUATE } from 'modules/common/constants';

export const OPTIONS_REVIEW = [
  {
    value: VALUE_EVALUATE.low,
    label: 'referenceIndication.Low',
  },
  {
    value: VALUE_EVALUATE.normal,
    label: 'referenceIndication.Normal',
  },
  {
    value: VALUE_EVALUATE.high,
    label: 'referenceIndication.High',
  },
];

export const OPTIONS_REVIEW_LIST = [
  {
    value: VALUE_EVALUATE.normal,
    label: 'referenceIndication.Normal',
  },
  {
    value: VALUE_EVALUATE.abnormal,
    label: 'referenceIndication.Abnormal',
  },
];
