import { Box, LinearProgress } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import { MEDICATION_UNIT } from 'modules/common/apiConstants';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { FE_DATE_FORMAT } from 'modules/common/constants';
import { useFetch } from 'modules/common/hook';
import { NumericFormatText } from 'modules/common/utils';
import { InventoryImportDetail } from 'modules/schema';
import { SaleImportReport } from 'modules/schema/SaleImportReport';
import { FormattedMessage } from 'react-intl';

const COLUMNS: Column<InventoryImportDetail>[] = [
  { type: 'INDEX', width: '30px', sticky: true },
  {
    title: 'inventory.drugCode',
    render: (record) => record?.medication?.code,
  },
  {
    title: 'inventory.drugName',
    render: (record) => record?.medication?.name,
  },
  {
    title: 'supplierLabel',
    render: (record) => record?.medicationSupplier?.name,
  },
  {
    title: 'inventory.lot',
    render: (record) => record.lot,
  },
  {
    title: 'expiredDate',
    render: (record) => record?.expiredDate?.format(FE_DATE_FORMAT),
  },
  {
    title: 'quantity',
    render: (record) => record.quantity,
  },
  {
    title: 'indication.unit',
    render: (record) => record.unit && <FormattedMessage id={MEDICATION_UNIT[record.unit!].label} />,
  },
  {
    title: 'sellingProduct.title.unitPrice',
    render: (record) => NumericFormatText(record.unitPrice || 0),
  },
  {
    title: 'totalAmount',
    render: (record) => NumericFormatText(record.totalAmount || 0),
  },
];

interface Props {
  saleImport: SaleImportReport;
}

export const ExpenseDetailTableSub = ({ saleImport }: Props) => {
  const { data, isValidating } = useFetch(API_SERVER.importInventory.getById(saleImport.id!), {
    enabled: saleImport.id,
  });
  if (isValidating) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return <Table columns={COLUMNS} data={data?.inventoryImportDetails} dataKey={'id'} subTableStyle />;
};
