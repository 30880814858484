import { IconButton } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import { useState } from 'react';
import useFilterContext from '../filter/useFilterContext';

interface Props {
  onClick: () => void;
}

const ReportRefreshButton = ({ onClick }: Props) => {
  const [temp, setTemp] = useState<boolean>(false);
  const { defaultValues, onFilterChange } = useFilterContext();
  return (
    <IconButton
      onClick={() => {
        onClick();
        setTemp((prev) => !prev);
        onFilterChange(defaultValues);
      }}
      sx={{
        transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
        transform: temp ? 'rotate(0deg)' : 'rotate(180deg)',
        alignSelf: 'end',
        padding: '4px',
      }}
    >
      <CachedIcon />
    </IconButton>
  );
};

export default ReportRefreshButton;
