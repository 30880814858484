import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import { API_SERVER } from 'modules/common/api';
import DialogCustom from 'modules/common/component/DialogCustom';
import { FE_TIME_DATE_FORMAT, INDICATION_REQUEST_STATUS, INDICATION_REQUEST_TYPE } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { Encounter, IndicationRequest } from 'modules/schema';
import PrintContent from './PrintContent';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { useFetch } from 'modules/common/hook';
import { MEDICATION_USAGE_TYPE, SYSTEM_CATEGORY_SCOPE, PRESCRIPTION_TYPE } from 'modules/common/apiConstants';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';

const COMPACT_TYPE = 'MEDICAL_PRINT_COMPACT_TYPE';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
    },
  },
});

interface TestReceiptContentProps {
  encounter?: Encounter;
  compact: boolean;
}
interface Props {
  open: boolean;
  onClose?: () => void;
  encounter?: Encounter;
}

const Table = ({ columns, data }: { columns: Columns[]; data?: any[] }) => {
  return (
    <TableCustom
      containerProps={{
        sx: {
          '& th,tr,td': {
            padding: '2px 8px',
            backgroundColor: 'white',
            border: '0.5px solid',
            color: 'unset',
          },
          overflow: 'visible',
        },
      }}
      dataSource={data}
      columns={columns}
    />
  );
};
const Title = ({ titleKey }: { titleKey: string }) => {
  return (
    <Typography variant="h4" sx={{ textTransform: 'uppercase', textAlign: 'center', marginBottom: 2 }}>
      <FormattedMessage id={titleKey} />
    </Typography>
  );
};

const getFinishedIndication = (indications: IndicationRequest[] | undefined) =>
  indications?.filter((indication) => indication.status === INDICATION_REQUEST_STATUS.FINISHED.value);

const FormPrintContent = (props: TestReceiptContentProps) => {
  const { encounter, compact } = props;
  const { isBasic, appState } = useGeneralHook();
  const intendedRoutes = useSelector((state: AppState) => state.common.intendedRoutes);

  const { data: testIndications } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(encounter?.id!, INDICATION_REQUEST_TYPE.ENCOUNTER, [
      SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
      SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
    ]),
  );
  const { data: procedureIndications } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(encounter?.id!, INDICATION_REQUEST_TYPE.ENCOUNTER, [
      SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE.value,
    ]),
  );
  const { data: otherIndications } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(encounter?.id!, INDICATION_REQUEST_TYPE.ENCOUNTER, [
      SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value,
    ]),
  );
  const { data: prescription } = useFetch(
    API_SERVER.dental.getDentalPrescriptions({
      referenceId: encounter?.id!,
      type: PRESCRIPTION_TYPE.ENCOUNTER,
    }),
  );
  const { data: medicationUsages } = useFetch(
    API_SERVER.medicationUsage.getList(encounter?.id!, { type: MEDICATION_USAGE_TYPE.ENCOUNTER }),
  );
  const allIntendedRoutes = appState.common.intendedRoutes;
  const cancelMedicationUsages = medicationUsages?.filter((medication) => medication?.cancellationQuantity);
  const TEST_COLUMNS = React.useMemo(
    () =>
      [
        {
          title: 'printForm.contentOfIndication',
          render: (record) => record?.serviceName,
        },

        {
          hidden: compact,
          title: 'printForm.executionPlace',
          render: (record) => record?.physicsRoomName,
        },
        {
          title: 'doctorOfIndication',
          render: (record) => record?.picName,
        },
        {
          title: 'printForm.samplingTime',
          hidden: compact,
        },
        {
          title: 'printForm.sampler',
          hidden: compact,
        },
        {
          title: 'printForm.note',
          render: (record) => record?.note,
          minWidth: '180px',
        },
      ] as Columns<IndicationRequest>[],
    [compact],
  );
  const PROCEDURE_COLUMNS = React.useMemo(
    () =>
      [
        {
          title: 'printForm.contentOfIndication',
          render: (record) => record?.serviceName,
        },
        {
          hidden: compact,
          title: 'printForm.executionPlace',
          render(record) {
            return record?.physicsRoomName || '';
          },
        },
        {
          hidden: compact,
          title: 'printForm.indicationTime',
          render(record) {
            return <Typography>{record?.startTime?.format(FE_TIME_DATE_FORMAT)}</Typography>;
          },
        },
        {
          title: 'doctorOfIndication',
          dataIndex: 'requester',
          render(record) {
            return <Typography>{record?.picName}</Typography>;
          },
        },
        {
          title: 'printForm.note',
          render(record) {
            return <Typography>{record?.note}</Typography>;
          },
          minWidth: '200px',
        },
      ] as Columns<IndicationRequest>[],
    [compact],
  );
  const OTHER_COLUMNS = React.useMemo(() => {
    return [
      {
        title: 'service.requestType',
        dataIndex: 'serviceName',
      },
      {
        hidden: compact,
        title: 'printForm.executionPlace',
        render(record) {
          return record?.physicsRoomName
            ? (record?.physicsRoomName || '') + (record?.executeName ? ` - ${record?.executeName}` : '')
            : '';
        },
      },
      {
        hidden: compact,
        title: 'printForm.indicationTime',
        render(record) {
          return record?.startTime?.format(FE_TIME_DATE_FORMAT);
        },
      },
      {
        title: 'doctorOfIndication',
        dataIndex: 'picName',
      },
      {
        title: 'printForm.note',
        dataIndex: 'note',
        minWidth: '200px',
      },
    ] as Columns<IndicationRequest>[];
  }, [compact]);
  return (
    <PrintContent encounter={encounter} compact={compact}>
      <Box paddingY={1} marginBottom={2}>
        <Divider />
      </Box>
      {!isBasic && (
        <Box>
          <Title titleKey="printForm.subClinicalRequestForm" />
          <Table columns={TEST_COLUMNS} data={getFinishedIndication(testIndications)} />
          <Box paddingY={4}>
            <Divider />
          </Box>
        </Box>
      )}
      <Box>
        <Title titleKey="printForm.requestFormProduce" />
        <Table columns={PROCEDURE_COLUMNS} data={getFinishedIndication(procedureIndications)} />
        <Box paddingY={4}>
          <Divider />
        </Box>
      </Box>
      <Box>
        <Title titleKey="printForm.requestOtherService" />
        <Table columns={OTHER_COLUMNS} data={getFinishedIndication(otherIndications)} />
        <Box paddingY={4}>
          <Divider />
        </Box>
      </Box>
      <Box>
        <Title titleKey="printForm.prescription" />
        {prescription?.prescriptionDetailList && prescription?.prescriptionDetailList?.length > 0 ? (
          prescription?.prescriptionDetailList?.map((prescriptionDetail, index) => {
            const intendedRoute = allIntendedRoutes.find((i) => i.value === prescriptionDetail.intendedRoute);
            const hasIngredient = Boolean(prescriptionDetail?.medication?.ingredient);
            const hasDose = Boolean(prescriptionDetail?.medication?.dose);

            let displayText = prescriptionDetail.medication?.name;

            if (hasIngredient || hasDose) {
              displayText += ' (';
              if (hasIngredient) {
                displayText += prescriptionDetail?.medication?.ingredient || '';
              }
              if (hasIngredient && hasDose) {
                displayText += ', ';
              }
              if (hasDose) {
                displayText += prescriptionDetail?.medication?.dose || '';
              }
              displayText += ')';
            }
            return (
              <Grid item xs={12} key={prescriptionDetail?.id!}>
                <Typography variant="subtitle1">
                  {index + 1}.&nbsp;{displayText}&nbsp;
                </Typography>
                <Box display="flex" flexWrap="wrap" paddingLeft={2} gap={2}>
                  <Typography variant="body1">
                    <FormattedMessage id="quantity" />
                    :&nbsp;
                    {prescriptionDetail?.quantity || 0}
                  </Typography>
                  <Typography variant="body1">
                    <FormattedMessage id="encounterPrescription.unit" />
                    :&nbsp;{prescriptionDetail?.medication?.numeratorUnit}
                  </Typography>
                  <Typography variant="body1">
                    <FormattedMessage id="encounterPrescription.intendedRoute" />
                    :&nbsp;{intendedRoute?.label}
                  </Typography>
                  <Typography variant="body1">
                    <FormattedMessage id="encounterPrescription.instruction" />
                    :&nbsp;{prescriptionDetail?.usage}
                  </Typography>
                </Box>
              </Grid>
            );
          })
        ) : (
          <Typography variant="subtitle1" style={{ fontStyle: 'italic' }} component="span">
            <FormattedMessage id="encounterPrescription.noPrescription" />
          </Typography>
        )}
        <Box paddingY={4}>
          <Divider />
        </Box>
        <Typography variant="subtitle1">
          <FormattedMessage id="encounterPrescription.note" />
          :&nbsp;
          <Typography variant="subtitle1" style={{ fontStyle: 'italic' }} component="span">
            {prescription?.advice && extractHTMLContent(prescription?.advice)}
          </Typography>
          <br />
          <Typography variant="subtitle1" style={{ fontStyle: 'italic' }} component="span">
            <FormattedMessage id="encounterPrescription.noteHelper" />
          </Typography>
        </Typography>
        <Box paddingY={4}>
          <Divider />
        </Box>
      </Box>
      <Box>
        <Title titleKey="drugUsage.printTitle" />
        {medicationUsages?.length ? (
          <>
            <Box style={{ pageBreakInside: 'avoid', marginTop: 16 }}>
              <Typography variant="subtitle1" color="textSecondary">
                <FormattedMessage id="drugUsage.drugIndication" />
              </Typography>
            </Box>
            {medicationUsages.map((medicationUsage, index: number) => {
              const medication = medicationUsage?.medication;
              const hasIngredient = Boolean(medication?.ingredient);
              const hasDose = Boolean(medication?.dose);

              let displayText = medication?.name;

              if (hasIngredient || hasDose) {
                displayText += ' (';
                if (hasIngredient) {
                  displayText += medication?.ingredient || '';
                }
                if (hasIngredient && hasDose) {
                  displayText += ', ';
                }
                if (hasDose) {
                  displayText += medication?.dose || '';
                }
                displayText += ')';
              }
              return (
                <Box key={medicationUsage?.id} style={{ marginTop: 4, marginBottom: 12 }}>
                  <Typography variant="subtitle1">
                    {index + 1}.&nbsp;{displayText}
                  </Typography>
                  <Box display="flex" flexWrap={'wrap'} gap={4}>
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.quantity" />
                      :&nbsp;{medicationUsage.quantity}
                    </Typography>
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.unit" />
                      :&nbsp;{medication?.numeratorUnit}
                    </Typography>
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.intendedRoute" />
                      :&nbsp;
                      {intendedRoutes?.find((route) => route?.value === medicationUsage.intendedRoute)?.label}
                    </Typography>{' '}
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.indication" />
                      :&nbsp;{medicationUsage.indicationRequest?.serviceName}
                    </Typography>
                  </Box>
                  <Typography variant="body1" component="span">
                    <FormattedMessage id="drugUsage.note" />
                    :&nbsp;{medicationUsage.note}
                  </Typography>
                </Box>
              );
            })}
          </>
        ) : (
          <Typography variant="body1" color="textSecondary">
            <FormattedMessage id="drugUsage.noUsed" />
          </Typography>
        )}
        {cancelMedicationUsages && cancelMedicationUsages.length > 0 ? (
          <>
            <Box marginY={1}>
              <Divider />
            </Box>{' '}
            <Box style={{ pageBreakInside: 'avoid', marginTop: 16 }}>
              <Typography variant="subtitle1" color="textSecondary">
                <FormattedMessage id="drugUsage.noteCancelDrug" />
              </Typography>
            </Box>
            {cancelMedicationUsages?.map((medicationUsage, index: number) => {
              const medication = medicationUsage?.medication;
              return (
                <Box key={medicationUsage?.id} style={{ marginTop: 4, marginBottom: 12 }}>
                  <Typography variant="subtitle1">
                    {index + 1}.&nbsp;{medication?.name}{' '}
                    <Typography variant="body1" component="span">
                      ({medication?.ingredient}, {medication?.dose})
                    </Typography>
                  </Typography>
                  <Box display="flex" flexWrap={'wrap'} gap={4}>
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.quantity" />
                      :&nbsp;{medicationUsage.quantity}
                    </Typography>
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.unit" />
                      :&nbsp;{medication?.numeratorUnit}
                    </Typography>
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.intendedRoute" />
                      :&nbsp;
                      {intendedRoutes?.find((route) => route?.value === medicationUsage.intendedRoute)?.label}
                    </Typography>{' '}
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.indication" />
                      :&nbsp;{medicationUsage.indicationRequest?.serviceName}
                    </Typography>
                  </Box>
                  <Typography variant="body1" component="span">
                    <FormattedMessage id="drugUsage.note" />
                    :&nbsp;{medicationUsage.cancellationReason}
                  </Typography>
                </Box>
              );
            })}
          </>
        ) : null}
      </Box>
    </PrintContent>
  );
};

const MedicalRecordPrintFormDialog = ({ onClose, open, encounter }: Props) => {
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [compact, setCompact] = React.useState(localStorage.getItem(COMPACT_TYPE) === 'compact' || false);

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: '80vw' },
      }}
      title={'medicalRecord'}
      footer={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={compact}
                onChange={(e) => {
                  setCompact(e.target.checked);
                  localStorage.setItem(COMPACT_TYPE, e.target.checked ? 'compact' : 'expansion');
                }}
              />
            }
            label={<FormattedMessage id="printCompact" />}
          />

          <ReactToPrint
            documentTitle=""
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="encounterDetail.printMedicalForm" />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />
        </Box>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <FormPrintContent encounter={encounter} compact={compact} />
      </Box>
    </DialogCustom>
  );
};

export default MedicalRecordPrintFormDialog;
