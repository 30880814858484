import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../common/api';
import usePaginationHook from '../../common/hook/usePaginationHook';
import ReceiveWizardDialog from '../../reception/component/encounter/ReceiveWizardDialog';
import EncounterList from '../component/encounter/EncounterList';
import FilterEncounterBox from '../component/encounter/FilterEncounterBox';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import { formatDateFilterField } from 'modules/common/utils';
import { useFetch } from 'modules/common/hook/useFetch';
import PageHeader from 'modules/common/component/PageHeader';

interface Props {}

const BasicFrontEncounterListPage: React.FunctionComponent<Props> = () => {
  const orderEncounter = useSelector((appState: AppState) => appState.common.orderEncounter);
  const [openRegisterDialog, setOpenRegisterDialog] = React.useState(false);

  const hookPagination = usePaginationHook({
    defaultFilter: {
      status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
      orderBy: orderEncounter,
    },
  });

  const { params } = hookPagination;

  const {
    data: encounterPage,
    isValidating,
    revalidate,
  } = useFetch(API_SERVER.encounter.payment(formatDateFilterField(params, 'fromDate', 'toDate')));

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterEncounterBox
        dataLoading={isValidating}
        hookPagination={hookPagination}
        onRegisterButtonClick={() => setOpenRegisterDialog(true)}
        pagin={
          <PageHeader
            titleKey="encounterList"
            pagination={{
              size: encounterPage?.content.length || 0,
              totalElements: encounterPage?.pagination.totalElements || 0,
            }}
          />
        }
      />
      <EncounterList
        refresh={revalidate}
        encounters={encounterPage?.content}
        total={encounterPage?.pagination?.totalElements || 0}
        hookPagination={hookPagination}
        loading={isValidating}
      />
      <ReceiveWizardDialog
        open={openRegisterDialog}
        mode="CREATE"
        close={() => setOpenRegisterDialog(false)}
        refresh={revalidate}
      />
    </Paper>
  );
};

export default BasicFrontEncounterListPage;
