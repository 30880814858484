import { BE_DATE_TIME_FORMAT, INDICATION_REQUEST_STATUS, PAYMENT_PLAN, ROLES } from 'modules/common/constants';
import { formatEndDate } from 'modules/common/utils';
import moment from 'moment';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { FilterType } from 'modules/common/component/filter/type';

export const FILTER_KEY = 'INDICATION_REPORT_FILTER';
export const SELECT_COLUMNS_KEY = 'INDICATION_REPORT_SELECT_COLUMNS';

export const DEFAULT_VALUES = {
  scopes: [],
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatEndDate(moment()),
  keyword: '',
  type: [],
};

export const FILTER_CONFIGS: FilterType[] = [
  {
    type: 'INDICATION_REQUEST_STATUS',
    options: [INDICATION_REQUEST_STATUS.FINISHED, INDICATION_REQUEST_STATUS.REQUESTED],
  },
  {
    type: 'PAYMENT_PLAN',
    options: [PAYMENT_PLAN.FEE, PAYMENT_PLAN.FREE],
    title: 'tollStatus',
  },
  {
    type: 'EMPLOYEE',
    roles: [
      ROLES.DOCTOR.value,
      ROLES.GENERAL_DOCTOR.value,
      ROLES.NURSE.value,
      ROLES.CLINICAL_PATHOLOGIST.value,
      ROLES.RADIOLOGIST.value,
      ROLES.RADIOGRAPHER.value,
      ROLES.LABORATORY_TECHNOLOGIST.value,
    ],
    groupByRole: false,
  },
  {
    type: 'SERVICE',
    scopes: [
      SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE.value,
      SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
      SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
      SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value,
    ],
  },
];
