import * as React from 'react';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaUserForm } from './schema';

interface Props {
  hookPagination: HookPaginationProps;
  onAddNew: () => void;
  pagin?: React.ReactNode;
}

const SearchUserAdmin: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, onAddNew, pagin } = props;
  const { params, setParams, clearParams } = hookPagination;
  return (
    <SchemaForm
      schema={schemaUserForm}
      onSubmit={setParams}
      clearParams={clearParams}
      formData={params}
      hideSubmitButton
      onAddNew={onAddNew}
      pagin={pagin}
    />
  );
};

export default SearchUserAdmin;
