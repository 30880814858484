import DialogCustom from '../../../../common/component/DialogCustom';
import PrintDialogContent from './PrintDialogContent';
import { IndicationRequest } from 'modules/schema';

interface Props {
  open: boolean;
  onClose: () => void;
  indicationList: IndicationRequest[];
}
const IndicationPrintForm = ({ open, onClose, indicationList }: Props) => {
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="indication.indicationList.lab.printForm.title"
      PaperProps={{
        style: { width: '1000px' },
      }}
      maxWidth="xl"
    >
      <PrintDialogContent onClose={onClose} indicationList={indicationList} />
    </DialogCustom>
  );
};

export default IndicationPrintForm;
