import { TableCell, TableRow, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { NumericFormatText } from '../../utils';
import { Column } from './type';

export type TableSummaryRowProps<T extends object> = {
  columns: Column<T>[];
  startIndex?: number;
  messageKey?: string;
  record: T;
  useRender?: string[];
};

const summaryRowStyle = {
  lineHeight: '16px',
  fontWeight: '500',
  fontSize: '14px',
  fontFamily: 'Roboto',
  letterSpacing: '0.4px',
  padding: '10px',
  textTransform: 'capitalize',
  border: 'none',
  borderTop: '5px solid white',
  backgroundColor: '#F4F8FF',
};

export function TableSummaryRow<T extends object>({
  columns,
  startIndex = 0,
  messageKey,
  record,
  useRender = [],
}: TableSummaryRowProps<T>) {
  const messageI18n = messageKey ? messageKey : 'common.table.summary';
  return (
    <TableRow>
      {columns.map((c, index) => {
        if (index === startIndex) {
          return (
            <TableCell key={index} colSpan={1} sx={{ ...summaryRowStyle, textAlign: 'center' }}>
              <Typography variant="subtitle1">
                <FormattedMessage id={messageI18n} />
                :&nbsp;
              </Typography>
            </TableCell>
          );
        } else if (
          (!c?.type || c?.type === 'NORMAL') &&
          typeof c.render === 'function' &&
          c?.fieldName &&
          useRender.includes(c.fieldName as string)
        ) {
          return (
            <TableCell key={index} colSpan={1} sx={summaryRowStyle}>
              <Typography variant="subtitle1">{c.render(record, index)}</Typography>
            </TableCell>
          );
        } else if (c?.fieldName && record.hasOwnProperty(c.fieldName as keyof T)) {
          return (
            <TableCell key={index} colSpan={1} sx={summaryRowStyle}>
              <Typography variant="subtitle1">{NumericFormatText(record[c.fieldName as keyof T])}</Typography>
            </TableCell>
          );
        } else {
          return <TableCell key={index} colSpan={1} sx={summaryRowStyle}></TableCell>;
        }
      })}
    </TableRow>
  );
}
