import { CameraAlt, Close, Delete } from '@mui/icons-material';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Webcam from 'react-webcam';
import { TwinCameraIcon } from '../../../../../../svg';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE, some } from '../../../../../common/constants';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import SelectCustom from '../../../../../common/SchemaForm/element/select/SelectCustom';
import NoDataBox from '../../../../../common/component/NoDataBox';
import ImageGalleryDialog from './ImageGalleryDialog';

interface Props {
  patientData: some;
  open: boolean;
  readOnly?: boolean;
  onClose: () => void;
  onSave: (value: some[]) => void;
}
const ContentBox = (props: Props) => {
  const { patientData = {}, onClose, onSave, readOnly } = props;
  const [indexImage, setIndexImage] = useState<undefined | number>(undefined);
  const [images, setImages] = useState<{ imageSrc?: string; comment?: string; checked?: boolean }[]>([]);
  const { intl, confirmDialog } = useGeneralHook();

  const webcamRef = useRef<any>(null);

  const [automaticDuration, setAutomaticDuration] = useState(1000);
  const [automation, setAutomation] = useState(false);
  const refInterval = useRef<any>(null);

  const captureScreen = useCallback(() => {
    if (images?.length >= 100) {
      refInterval.current && clearInterval(refInterval.current);
      setAutomation(false);
      return;
    }
    const imageSrc = webcamRef.current.getScreenshot();
    setImages((old) => [...old, { imageSrc, checked: true, comment: '' }]);
  }, [images?.length]);

  useEffect(() => {
    if (automation) {
      refInterval.current = setInterval(() => {
        captureScreen();
      }, automaticDuration);
    } else {
      refInterval.current && clearInterval(refInterval.current);
    }
    return () => {
      clearInterval(refInterval.current);
    };
  }, [automaticDuration, automation, captureScreen]);

  const closeDialog = async () => {
    setAutomation(false);
    refInterval.current && clearInterval(refInterval.current);
    if (images.length > 0) {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        content: intl.formatMessage({ id: 'youHaveUnsavedData' }),
        cancelId: 'cancel',
        okId: 'ok',
      });
      if (confirm) {
        onClose();
      }
      confirmDialog.close();
    } else {
      onClose();
    }
  };

  return (
    <>
      <Box
        bgcolor="primary.50"
        style={{
          position: 'sticky',
          top: 0,
          padding: '12px 16px',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box flex={1}>
          <Typography variant="h6" color="primary">
            <FormattedMessage id={'camera.title'} />
          </Typography>
        </Box>
        <Box justifySelf="flex-end">
          <IconButton onClick={closeDialog} size="small" tabIndex={-1}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <Box bgcolor="white" padding={2} flex={1} display="flex" flexDirection="column" height={'calc(100% - 120px)'}>
        <Box
          padding={2}
          paddingRight={0}
          gap={2}
          display="flex"
          flex={1}
          overflow="hidden"
          border={1}
          borderColor="divider"
          borderRadius={2}
          height={'100%'}
        >
          {!readOnly && (
            <Box flex={1} overflow="auto">
              <Box position="relative" overflow="hidden" width="100%">
                <Webcam width={'100%'} height={'100%'} ref={webcamRef} screenshotFormat="image/jpeg" />
                <ButtonBase
                  sx={{
                    '&:hover': {
                      opacity: 1,
                    },
                    opacity: 0,
                    transition: 'all 0.3s',
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    right: '0',
                    bottom: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                  onClick={captureScreen}
                >
                  <AddAPhotoOutlinedIcon style={{ color: 'white', width: 80, height: 'auto' }} />
                </ButtonBase>
              </Box>
              <Box display="flex" marginTop={2}>
                <Box flex={1}>
                  <Typography variant="body2">
                    <FormattedMessage id={'name'} />
                    :&nbsp;:&nbsp;
                    <Typography variant="subtitle2" component="span">
                      {patientData.name}
                    </Typography>
                  </Typography>
                  <Typography variant="body2">
                    <FormattedMessage id={'gender'} />
                    :&nbsp;:&nbsp;
                    <Typography variant="subtitle2" component="span">
                      {patientData.gender && <FormattedMessage id={patientData?.gender?.toLowerCase() || ' '} />}
                    </Typography>
                  </Typography>
                  <Typography variant="body2">
                    <FormattedMessage id={'birthdayShort'} />
                    :&nbsp;:&nbsp;
                    <Typography variant="subtitle2" component="span">
                      {patientData.dob && moment(patientData.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                    </Typography>
                  </Typography>
                  <Typography variant="body2">
                    <FormattedMessage id={'address'} />
                    :&nbsp;:&nbsp;
                    <Typography variant="subtitle2" component="span">
                      {patientData?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address}
                    </Typography>
                  </Typography>
                </Box>
                <Box flex={1}>
                  <Button sx={{ height: 40 }} fullWidth startIcon={<CameraAlt />} onClick={captureScreen} size="large">
                    <FormattedMessage id="camera.takeAShot" />
                  </Button>
                  <Typography variant="subtitle1" marginTop={3} color="textSecondary">
                    <FormattedMessage id={'camera.setting'} />
                  </Typography>
                  <SelectCustom
                    disableClearBtn={true}
                    options={[
                      { value: 1000, label: 'camera.duration.1' },
                      { value: 2000, label: 'camera.duration.2' },
                      { value: 3000, label: 'camera.duration.3' },
                      { value: 4000, label: 'camera.duration.4' },
                      { value: 5000, label: 'camera.duration.5' },
                    ]}
                    value={automaticDuration}
                    onChange={(event) => {
                      setAutomaticDuration(event);
                    }}
                  />{' '}
                  <Button
                    fullWidth
                    startIcon={<TwinCameraIcon />}
                    color={automation ? 'error' : 'primary'}
                    sx={{ marginTop: 1.5, height: 40 }}
                    onClick={() => {
                      setAutomation((old) => !old);
                    }}
                  >
                    <FormattedMessage id={automation ? 'camera.stop' : 'camera.takeMultipleShot'} />
                  </Button>
                </Box>
              </Box>
            </Box>
          )}
          <Box overflow="auto" display="flex" flexDirection={'column'} gap={2} width={220}>
            <Typography variant="subtitle1">
              <FormattedMessage id="camera.listImage" />
            </Typography>
            {images?.length > 0 ? (
              images.map((item: any, index) => {
                return (
                  <Box
                    position="relative"
                    key={index}
                    display="flex"
                    padding={1}
                    gap={1}
                    bgcolor="background.default"
                    borderRadius={0.5}
                  >
                    <Box flex={2}>
                      <Box style={{ height: 120, width: '100%', cursor: 'pointer' }}>
                        <Avatar
                          src={item.imageSrc}
                          alt=""
                          variant="rounded"
                          style={{ height: 120, width: '100%', cursor: 'pointer', objectFit: 'contain' }}
                          onClick={() => setIndexImage(index)}
                        />
                      </Box>
                      <Box display="flex" marginTop={1}>
                        {!readOnly && (
                          <Checkbox
                            checked={item.checked}
                            onChange={(e) => {
                              setImages((old) =>
                                old.map((one, idx) => {
                                  if (index === idx) {
                                    return { ...one, checked: e.target.checked };
                                  }
                                  return one;
                                }),
                              );
                            }}
                            style={{ height: 32, width: 32 }}
                          />
                        )}

                        <TextField
                          fullWidth
                          placeholder={intl.formatMessage({ id: 'camera.position' })}
                          value={item.comment || ''}
                          onChange={(e) => {
                            setImages((old) =>
                              old.map((one, idx) => {
                                if (index === idx) {
                                  return { ...one, comment: e.target.value };
                                }
                                return one;
                              }),
                            );
                          }}
                          multiline
                          rows={1}
                          InputProps={{
                            readOnly: readOnly,
                            style: { minHeight: 'unset' },
                          }}
                          inputProps={{
                            style: { minHeight: 'unset' },
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <NoDataBox imageStyle={{ width: '100%' }} />
            )}
          </Box>
        </Box>
        <Box display="flex" justifyContent="flex-end" alignItems={'center'} marginTop={2} gap={2}>
          {!readOnly && (
            <>
              <FormControlLabel
                control={<Checkbox color="primary" style={{ padding: 2 }} />}
                label={<FormattedMessage id="camera.selectAll" />}
                checked={images.every((v) => v.checked === true)}
                onChange={(event: any) => {
                  const checked = event.target.checked;
                  setImages((old) =>
                    old.map((one) => {
                      return { ...one, checked };
                    }),
                  );
                }}
              />
              <Button
                startIcon={<Delete />}
                disabled={images.filter((v) => v.checked)?.length === 0}
                onClick={() => {
                  setImages((old) =>
                    old.filter((one) => {
                      return !one.checked;
                    }),
                  );
                }}
                size="large"
              >
                <FormattedMessage id="camera.deleteSelected" />
              </Button>
            </>
          )}
          <Button
            variant="outlined"
            onClick={() => {
              closeDialog();
            }}
            size="large"
          >
            <FormattedMessage id="cancel" />
          </Button>
          {!readOnly && (
            <Button
              type="submit"
              size="large"
              onClick={() => {
                onSave(images.filter((v) => v.checked));
              }}
            >
              <FormattedMessage id="save" />
            </Button>
          )}
        </Box>
      </Box>
      <ImageGalleryDialog
        open={indexImage !== undefined}
        onClose={() => setIndexImage(undefined)}
        images={images.map((item: some) => ({
          render: (
            <Avatar
              src={item.imageSrc}
              alt=""
              variant="rounded"
              imgProps={{
                style: { objectFit: 'contain' },
              }}
              style={{ height: '100%', width: '100%', objectFit: 'contain' }}
            />
          ),
        }))}
        index={indexImage}
      />
    </>
  );
};

const CameraImageGalleryDialog = (props: Props) => {
  const { open } = props;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          position: 'relative',
          maxWidth: 'unset',
          width: 900,
          height: 850,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <ContentBox {...props} />
    </Dialog>
  );
};

export default CameraImageGalleryDialog;
