import { Box, Button, Typography } from '@mui/material';
import SchemaFormMain from 'modules/common/SchemaForm';
import DialogCustom from 'modules/common/component/DialogCustom';
import { useDialog, useUpdateMutate } from 'modules/common/hook';
import { NumericFormatText } from 'modules/common/utils';
import { useForm, useWatch } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { PayFormData, IFormData, caculatePrice, caculateSumPrice } from './utils';
import React, { useCallback, useMemo } from 'react';
import { Dictionary } from 'lodash';
import { Payment, PaymentDetail } from 'modules/schema';
import { PAYMENT_METHOD, PAYMENT_METHOD_NOT_FULL, PAYMENT_TYPE } from 'modules/common/apiConstants';
import { mutate } from 'swr';
import { API_SERVER } from 'modules/common/api';

interface Props {
  payment?: Payment;
  paymentDetailMap: Dictionary<PaymentDetail>;
  encounterId?: number;
}

const PayButton = ({ paymentDetailMap, payment, encounterId }: Props) => {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const discountPercent = useWatch<IFormData, 'discountPercent'>({ name: 'discountPercent' });
  const methods = useForm<FormData>({ mode: 'onChange' });
  const paymentList = useWatch<IFormData, `paymentList`>({ name: 'paymentList' });
  const checkedPayments = useMemo(() => paymentList?.filter((payment) => payment?.checked), [paymentList]);
  const finalCheckedPrice = React.useMemo<ReturnType<typeof caculatePrice>>(
    () => caculateSumPrice(checkedPayments, discountPercent, paymentDetailMap),
    // Warning: Sử dụng json.stringify(paymentList)
    // vì khi input của field thay đổi thì paymentList không tạo array mới mà chỉ update field
    // nên sẽ useMemo không được gọi lại nếu chỉ paymentList
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [discountPercent, paymentDetailMap, JSON.stringify(checkedPayments)],
  );
  const formData = useMemo(
    () => ({
      paymentMethod: PAYMENT_METHOD_NOT_FULL.CASH.value,
      paymentMethodChecked: true,
      prepayment: Math.min(payment?.prepayment || 0, finalCheckedPrice?.patientPay),
    }),
    [finalCheckedPrice?.patientPay, payment?.prepayment],
  );
  const payMutate = useUpdateMutate({
    confirmMessage: 'therapy.cashier.payment.message.confirm',
    onSuccess: () => {
      mutate(API_SERVER.payment.detail(payment?.id!, { type: PAYMENT_TYPE.ENCOUNTER }).url);
      mutate(API_SERVER.paymentHistory.getList(payment?.id!).url);
      mutate(API_SERVER.prepayment.getPrepayment({ paymentId: payment?.id! }).url);
      mutate(API_SERVER.encounter.get(encounterId!).url);
      onCloseDialog();
    },
  });
  const onPay = useCallback(
    (formData: PayFormData) => {
      const prepaymentFull = formData?.prepaymentChecked && finalCheckedPrice?.patientPay === formData?.prepayment;
      return payMutate({
        url: API_SERVER.payment.pay(payment?.id!),
        method: 'POST',
        data: {
          discountPercent,
          prepayment: formData?.prepayment,
          paymentMethod: prepaymentFull ? PAYMENT_METHOD.PREPAYMENT.value : formData?.paymentMethod,
          amountPaid: formData?.amountPaid,
          note: formData?.note,
          paymentDetails: paymentList
            ?.filter((payment) => payment?.checked)
            ?.map((payment) => ({
              id: payment?.paymentDetailId,
              quantity: paymentDetailMap[payment?.paymentDetailId]?.quantity,
              unitPrice: payment?.unitPrice,
              vat: payment?.vat,
              discount: payment?.discount,
              discountType: payment?.discountType,
            })),
        },
      });
    },
    [discountPercent, finalCheckedPrice, payMutate, payment?.id, paymentDetailMap, paymentList],
  );

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        style={{ flex: 1 }}
        disabled={paymentList?.every((paymentItem) => !paymentItem?.checked)}
        onClick={onOpenDialog}
      >
        <FormattedMessage id="pay" />
      </Button>
      {payment && (
        <DialogCustom
          open={openDialog}
          onClose={onCloseDialog}
          title={'therapy.cashier.dialog.title.paymentConform'}
          maxWidth="sm"
          PaperProps={{
            style: { minWidth: 480 },
          }}
        >
          <Box padding={2}>
            <SchemaFormMain
              onCancel={onCloseDialog}
              onSubmit={onPay}
              formData={formData}
              methods={methods}
              schema={{
                fields: ({ formProps: { intl, appState }, valuesField, methods: { setValue } }) => {
                  const prepaymentFull =
                    valuesField?.prepaymentChecked && finalCheckedPrice?.patientPay === valuesField?.prepayment;
                  return {
                    labelAmountPaid: {
                      type: () => {
                        return (
                          <>
                            <Typography variant="h6" color="success.main">
                              <FormattedMessage id="therapy.payment.paymentConfirm.label.totalAmount" />
                            </Typography>
                            <Typography variant="h6">{NumericFormatText(finalCheckedPrice?.patientPay)}</Typography>
                          </>
                        );
                      },
                      unregister: true,
                      propsWrapper: { xs: 12 },
                    },
                    labelSelectSof: {
                      type: () => {
                        return (
                          <>
                            <Typography variant="body1">
                              <FormattedMessage id="therapy.payment.paymentConfirm.label.sofSelect" />
                            </Typography>
                          </>
                        );
                      },
                      unregister: true,
                      propsWrapper: { xs: 12 },
                    },
                    requiredSelectSofWarning: {
                      unregister: true,
                      hidden: valuesField?.prepaymentChecked || valuesField?.paymentMethodChecked,
                      type: () => {
                        return (
                          <Typography
                            sx={{
                              color: 'error.main',
                              marginTop: '-10px',
                            }}
                          >
                            {intl.formatMessage({
                              id: 'therapy.cashier.paymentConfirmDialog.message.validateSelectSof.required',
                            })}
                          </Typography>
                        );
                      },
                      propsWrapper: { xs: 12 },
                    },
                    prepaymentChecked: {
                      type: 'checkbox',
                      label: intl.formatMessage({ id: 'therapy.payment.paymentConfirm.label.sofPrepayment' }),
                      labelPlacement: 'end',
                      disabled: (payment?.prepayment || 0) <= 0,
                      required: !valuesField?.paymentMethodChecked,
                      register: {
                        validate: (value) => {
                          return value || valuesField?.paymentMethodChecked
                            ? true
                            : intl.formatMessage({
                                id: 'therapy.cashier.paymentConfirmDialog.message.validateSelectSof.required',
                              });
                        },
                      },
                      propsWrapper: {
                        xs: 12,
                        sx: {
                          paddingTop: '5px !important',
                          marginTop: '0',
                          '& label': { marginTop: '0' },
                          '& .MuiFormHelperText-root': { display: 'none' },
                        },
                      },
                    },
                    prepayment: {
                      type: 'number-field',
                      inputType: 'number',
                      placeholder: intl.formatMessage({ id: 'enter' }),
                      disabled: !valuesField?.prepaymentChecked,
                      register: {
                        required: valuesField?.prepaymentChecked,
                        max: {
                          value: Math.min(payment?.prepayment || 0, finalCheckedPrice?.patientPay),
                          message: intl.formatMessage({ id: 'validation.invalid' }),
                        },
                        min: {
                          value: 0,
                          message: intl.formatMessage({ id: 'validation.invalid' }),
                        },
                      },
                      propsWrapper: { xs: 6, sx: { paddingTop: '5px !important' } },
                    },
                    empty: {
                      type: () => {
                        return <></>;
                      },
                      unregister: true,
                      propsWrapper: { xs: 6 },
                    },
                    paymentMethodChecked: {
                      disabled: prepaymentFull,
                      type: 'checkbox',
                      label: intl.formatMessage({ id: 'therapy.payment.paymentConfirm.label.directPayment' }),
                      labelPlacement: 'end',
                      required: !valuesField?.prepaymentChecked,
                      register: {
                        validate: (value) => {
                          return value || valuesField?.prepaymentChecked
                            ? true
                            : intl.formatMessage({
                                id: 'therapy.cashier.paymentConfirmDialog.message.validateSelectSof.required',
                              });
                        },
                      },
                      propsWrapper: {
                        xs: 6,
                        sx: {
                          paddingTop: '5px !important',
                          marginTop: '0',
                          '& label': { marginTop: '0' },
                          '& .MuiFormHelperText-root': { display: 'none' },
                        },
                      },
                    },
                    labelAmountRemain: {
                      disabled: prepaymentFull,
                      type: () => {
                        return (
                          <>
                            <Typography
                              variant="subtitle1"
                              style={{ minWidth: 160, color: '#607D8B', fontWeight: 500, fontSize: '14px' }}
                            >
                              <FormattedMessage id="therapy.payment.paymentConfirm.label.directPaymentAmount" />
                            </Typography>
                          </>
                        );
                      },
                      unregister: true,
                      propsWrapper: {
                        xs: 6,
                        sx: {
                          paddingTop: '10px !important',
                          marginTop: '0',
                        },
                      },
                    },
                    paymentMethod: {
                      type: 'select',
                      placeholder: intl.formatMessage({ id: 'select' }),
                      options: Object.values(PAYMENT_METHOD_NOT_FULL),
                      disableClearBtn: true,
                      required: valuesField?.paymentMethodChecked,
                      noHelperText: true,
                      propsWrapper: { xs: 6, sx: { paddingTop: '5px !important' } },
                      disabled: !valuesField?.paymentMethodChecked || prepaymentFull,
                    },
                    valueAmountRemain: {
                      disabled: prepaymentFull,
                      type: () => (
                        <Typography variant="body1" style={{ minWidth: 160 }}>
                          {NumericFormatText(
                            finalCheckedPrice?.patientPay -
                              (valuesField?.prepaymentChecked ? valuesField?.prepayment || 0 : 0),
                          )}
                        </Typography>
                      ),
                      unregister: true,
                      propsWrapper: {
                        xs: 6,
                        sx: {
                          paddingTop: '5px !important',
                          marginTop: '0',
                        },
                      },
                    },
                    note: {
                      type: 'text-field',
                      label: intl.formatMessage({ id: 'note' }),
                      placeholder: intl.formatMessage({ id: 'noteEnter' }),
                      rows: 3,
                      multiline: true,
                    },
                  };
                },
                changeDataBeforeSubmit: (value) => {
                  const prepayment = value?.prepaymentChecked ? value?.prepayment || 0 : 0;
                  return {
                    ...value,
                    prepayment,
                    amountPaid: finalCheckedPrice?.patientPay,
                  };
                },
              }}
            />
          </Box>
        </DialogCustom>
      )}
    </>
  );
};

export default PayButton;
