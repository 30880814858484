import { Add, Remove } from '@mui/icons-material';
import { Box, Button, ButtonBaseProps, InputAdornment, TextField } from '@mui/material';
import { debounce, isNaN } from 'lodash';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { NUMBER_REGEX } from '../regex';

interface Props {
  value?: number;
  onChange?: (value: number) => void;
  defaultValue?: number;
  addProps?: (value: number) => Omit<ButtonBaseProps, 'color'>;
  reduceProps?: (value: number) => Omit<ButtonBaseProps, 'color'>;
  readOnly?: boolean;
  min?: number;
  max?: number;
  decimal?: boolean;
  hideAdornment?: boolean;
}

const QuantityBox: React.FunctionComponent<Props> = (props) => {
  const {
    value,
    onChange,
    defaultValue = 0,
    reduceProps,
    addProps,
    readOnly,
    min = 0,
    max,
    decimal,
    hideAdornment,
  } = props;
  const [quantity, setQuantity] = React.useState<any>(value || defaultValue);

  const reducePropsTmp = React.useMemo(() => {
    return reduceProps ? reduceProps(quantity) : {};
  }, [quantity, reduceProps]);

  const addPropsTmp = React.useMemo(() => {
    return addProps ? addProps(quantity) : {};
  }, [addProps, quantity]);

  const onChangeTmp = React.useCallback(
    (value) => {
      onChange && onChange(value);
    },
    [onChange],
  );

  const onFinalChange = React.useCallback(() => {
    const valueTmp = quantity ? Number(quantity) : undefined;

    if (valueTmp !== undefined && typeof valueTmp === 'number' && valueTmp !== value && !isNaN(valueTmp)) {
      if (valueTmp >= min && (max ? valueTmp <= max : true)) {
        if (decimal) {
          onChangeTmp(valueTmp);
        } else if (NUMBER_REGEX.test(quantity)) {
          onChangeTmp(valueTmp);
        } else {
          setQuantity(value);
        }
      } else {
        setQuantity(value);
      }
    } else {
      setQuantity(value);
    }
  }, [decimal, max, min, onChangeTmp, quantity, value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onChangeDebounce = React.useCallback(
    debounce(
      (value) => {
        onChangeTmp(value);
      },
      500,
      {
        trailing: true,
        leading: false,
      },
    ),
    [onChangeTmp],
  );

  React.useEffect(() => {
    setQuantity(value || defaultValue);
  }, [defaultValue, value]);

  if (readOnly) {
    return <FormattedNumber value={value || 0} />;
  }
  return (
    <Box display="flex" justifyContent="center" margin="auto">
      <TextField
        value={quantity}
        onChange={(e) => {
          if (decimal || NUMBER_REGEX.test(e.target.value)) {
            setQuantity(e.target.value);
          }
        }}
        onBlur={() => {
          onFinalChange();
        }}
        onKeyUp={(event) => {
          if (event.keyCode === 13) {
            // Cancel the default action, if needed
            event.preventDefault();
            // Trigger the button element with a click
            onFinalChange();
          }
        }}
        inputProps={{
          style: { textAlign: 'center' },
        }}
        InputProps={
          hideAdornment
            ? {}
            : {
                startAdornment: (
                  <InputAdornment position="start">
                    <Button
                      size="small"
                      title="decrease"
                      color="inherit"
                      variant="text"
                      {...reducePropsTmp}
                      style={{ borderRadius: 0, padding: 0, minWidth: 0, ...reducePropsTmp.style }}
                      disabled={quantity <= (min || 0) || reducePropsTmp.disabled}
                      onClick={() => {
                        const tmp = Number(quantity) - 1;
                        if (tmp >= min) {
                          setQuantity(tmp);
                          onChangeDebounce(tmp);
                        }
                      }}
                    >
                      <Remove color="action" />
                    </Button>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <Button
                      size="small"
                      title="increase"
                      color="inherit"
                      variant="text"
                      {...addPropsTmp}
                      style={{ borderRadius: 0, padding: 0, minWidth: 0, ...reducePropsTmp.style }}
                      disabled={max ? quantity >= max : reducePropsTmp.disabled}
                      onClick={() => {
                        const tmp = Number(quantity) + 1;
                        if (max ? tmp <= max : true) {
                          setQuantity(tmp);
                          onChangeDebounce(tmp);
                        }
                      }}
                    >
                      <Add color="action" />
                    </Button>
                  </InputAdornment>
                ),
              }
        }
      />
    </Box>
  );
};
export default QuantityBox;
