import { CORE, GATEWAY_HOST, stringifyUrl } from 'modules/common/api';
import {
  PAYMENT_HISTORY_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
  SERVICE_TYPE_ALL,
  SERVICE_TYPE_INCOME,
} from 'modules/common/apiConstants';
import { TIME_REPORT_FILTER } from 'modules/common/component/report/consts';
import { PAYMENT_PLAN } from 'modules/common/constants';
import { Pageable } from 'modules/common/type';
import {
  DistributionCustomerByRevenueSchema,
  PaymentDetailSchema,
  PaymentSchema,
  ReportDebtSchema,
  RevenueReportSchema,
} from 'modules/schema';
import { createPageSchema } from 'modules/schema/common';
import { RevenueExpenditureNewSchema } from 'modules/schema/RevenueExpenditureNew';
import { z } from 'zod';

export const BUSINESS_API = {
  getCashBookPaging: (
    params: {
      fromDate?: string;
      toDate?: string;
      keyword?: string;
      paymentTypes?: (keyof typeof SERVICE_TYPE_INCOME)[];
      paymentMethods?: (keyof typeof PAYMENT_METHOD)[];
      paymentHistoryTypes?: (keyof typeof PAYMENT_HISTORY_TYPE)[];
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/cashbook?${stringifyUrl(params)}`,
    schema: createPageSchema(RevenueExpenditureNewSchema),
  }),
  getCashBookList: (
    params: {
      fromDate?: string;
      toDate?: string;
      keyword?: string;
      paymentTypes?: (keyof typeof SERVICE_TYPE_INCOME)[];
      paymentMethods?: (keyof typeof PAYMENT_METHOD)[];
      paymentHistoryTypes?: (keyof typeof PAYMENT_HISTORY_TYPE)[];
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/cashbook/list?${stringifyUrl(params)}`,
    schema: z.array(RevenueExpenditureNewSchema),
  }),
  getCashBookExcel: (
    params: {
      fromDate?: string;
      toDate?: string;
      keyword?: string;
      paymentTypes?: (keyof typeof SERVICE_TYPE_INCOME)[];
      paymentMethods?: (keyof typeof PAYMENT_METHOD)[];
      paymentHistoryTypes?: (keyof typeof PAYMENT_HISTORY_TYPE)[];
    } & Pageable,
  ) => `${GATEWAY_HOST}/${CORE}/v1.0/report/business/cashbook/excel?${stringifyUrl(params)}`,
  getDebtPaging: (
    params: {
      paymentTypeList?: (keyof typeof SERVICE_TYPE_ALL)[];
      keyword?: string;
      fromDate: string;
      toDate: string;
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/debt?${stringifyUrl(params)}`,
    schema: createPageSchema(ReportDebtSchema),
  }),
  getDebtList: (
    params: {
      paymentTypeList?: (keyof typeof SERVICE_TYPE_ALL)[];
      keyword?: string;
      fromDate: string;
      toDate: string;
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/debt/list?${stringifyUrl(params)}`,
    schema: z.array(ReportDebtSchema),
  }),
  getDebtExcel: (
    params: {
      paymentTypeList?: (keyof typeof SERVICE_TYPE_ALL)[];
      keyword?: string;
      fromDate: string;
      toDate: string;
    } & Pageable,
  ) => `${GATEWAY_HOST}/${CORE}/v1.0/report/business/debt/excel?${stringifyUrl(params)}`,

  getRevenueChart: (
    params: {
      fromDate: string;
      toDate: string;
      type?: (keyof typeof SERVICE_TYPE_ALL)[];
      groupReportType?: keyof typeof TIME_REPORT_FILTER;
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/revenue/expected?${stringifyUrl(params)}`,
    schema: z.array(RevenueReportSchema),
  }),

  getRevenueChartPatient: (
    params: {
      fromDate: string;
      toDate: string;
      type?: keyof typeof SERVICE_TYPE_ALL;
      groupReportType?: keyof typeof TIME_REPORT_FILTER;
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/revenue/patient?${stringifyUrl(params)}`,
    schema: z.array(DistributionCustomerByRevenueSchema),
  }),

  getRevenuePaging: (
    params: {
      fromDate: string;
      toDate: string;
      paymentTypeList?: (keyof typeof PAYMENT_TYPE)[];
      paymentStatusList?: (keyof typeof PAYMENT_STATUS)[];
      keyword?: string;
      paymentPlanList?: (keyof typeof PAYMENT_PLAN)[];
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/revenue?${stringifyUrl(params)}`,
    schema: createPageSchema(PaymentSchema),
  }),
  getRevenueExcel: (
    params: {
      fromDate: string;
      toDate: string;
      paymentTypeList?: (keyof typeof PAYMENT_TYPE)[];
      paymentStatusList?: (keyof typeof PAYMENT_STATUS)[];
      keyword?: string;
      paymentPlanList?: (keyof typeof PAYMENT_PLAN)[];
    } & Pageable,
  ) => `${GATEWAY_HOST}/${CORE}/v1.0/report/business/revenue/excel?${stringifyUrl(params)}`,
  getRevenueList: (
    params: {
      type?: (keyof typeof SERVICE_TYPE_ALL)[];
      fromDate: string;
      toDate: string;
      paymentTypeList?: (keyof typeof PAYMENT_TYPE)[];
      paymentStatusList?: (keyof typeof PAYMENT_STATUS)[];
      keyword?: string;
      paymentPlanList?: (keyof typeof PAYMENT_PLAN)[];
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/revenue/list?${stringifyUrl(params)}`,
    schema: z.array(PaymentSchema),
  }),

  getRevenueDetailPaging: (
    params: {
      fromDate: string;
      toDate: string;
      paymentTypeList?: (keyof typeof PAYMENT_TYPE)[];
      paymentStatusList?: (keyof typeof PAYMENT_STATUS)[];
      keyword?: string;
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/revenue/detail?${stringifyUrl(params)}`,
    schema: createPageSchema(PaymentDetailSchema),
  }),
  getRevenueDetailExcel: (
    params: {
      fromDate: string;
      toDate: string;
      paymentTypeList?: (keyof typeof PAYMENT_TYPE)[];
      paymentStatusList?: (keyof typeof PAYMENT_STATUS)[];
      keyword?: string;
    } & Pageable,
  ) => `${GATEWAY_HOST}/${CORE}/v1.0/report/business/revenue/detail/excel?${stringifyUrl(params)}`,
  getRevenueDetailList: (
    params: {
      fromDate: string;
      toDate: string;
      paymentTypeList?: (keyof typeof PAYMENT_TYPE)[];
      paymentStatusList?: (keyof typeof PAYMENT_STATUS)[];
      keyword?: string;
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/business/revenue/detail/list?${stringifyUrl(params)}`,
    schema: z.array(PaymentDetailSchema),
  }),
};
