import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import TextInput from '../form/TextInput';
import { InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  form: UseFormReturn<TFieldValues>;
  name: TName;
  onSearch: () => void;
  placeholder?: string;
}

export const ReportSearchInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  form,
  name,
  onSearch,
  placeholder,
}: Props<TFieldValues, TName>) => {
  return (
    <TextInput
      form={form}
      name={name}
      hideError
      placeholder={placeholder || 'common.placeholder.search'}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          onSearch();
        }
      }}
      onBlur={onSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      sx={{ width: '300px', minHeight: '27px' }}
    />
  );
};
