import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { GENDER, PAYMENT_HISTORY_TYPE, PAYMENT_METHOD } from 'modules/common/apiConstants';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  FE_DATE_FORMAT,
  FE_DATE_TIME_FORMAT,
  PAYMENT_PLAN,
} from 'modules/common/constants';
import moment from 'moment';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import PrintIcon from '@mui/icons-material/Print';
import DialogCustom from 'modules/common/component/DialogCustom';
import ReactToPrint from 'react-to-print';
import { Print } from '@mui/icons-material';
import { Encounter, PaymentDetail, PaymentHistory } from 'modules/schema';
import { useFetch, useDialog } from 'modules/common/hook';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import React, { useMemo } from 'react';
import { NumericFormatText, numericFormat } from 'modules/common/utils';
import { API_SERVER } from 'modules/common/api';

interface Props {
  encounter?: Encounter;
  paymentHistory: PaymentHistory;
}

interface DialogContentProps extends Props {
  title: string;
}

const DialogContent = ({ paymentHistory, encounter, title }: DialogContentProps) => {
  const { patient } = encounter || {};
  const { appState, intl } = useGeneralHook();
  const { data: paymentHistoryDetail } = useFetch(
    API_SERVER.paymentHistory.getDetail(paymentHistory.parentId || paymentHistory?.id!),
    { shouldRetryOnError: false, enabled: paymentHistory.type !== PAYMENT_HISTORY_TYPE.PAYMENT_ADVANCE_REFUND.value },
  );

  const data = useMemo<any>(() => {
    if (paymentHistory.type !== PAYMENT_HISTORY_TYPE.PAYMENT_ADVANCE_REFUND.value) {
      return paymentHistoryDetail?.paymentDetails;
    } else {
      return [paymentHistory];
    }
  }, [paymentHistory, paymentHistoryDetail?.paymentDetails]);

  const COLUMNS = useMemo<Columns<any>[]>(() => {
    if (paymentHistory.type === PAYMENT_HISTORY_TYPE.PAYMENT_ADVANCE_REFUND.value) {
      return [
        {
          title: 'therapy.prepayment.prepaymentRefundPrint.header.createdTime',
          width: '30%',
          render: (record) => (
            <Typography variant="body1">
              {record?.transactionTime &&
                moment(record?.transactionTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT)}
            </Typography>
          ),
        },
        {
          title: 'therapy.prepayment.prepaymentRefundPrint.header.refundAmount',
          width: '30%',
          render: (record) => <Typography variant="body1">{NumericFormatText(record.amountPaid)}</Typography>,
        },
        {
          title: 'therapy.prepayment.prepaymentRefundPrint.header.paymentMethod',
          width: '40%',
          render: (record) => {
            return (
              <Typography variant="body1">
                {intl.formatMessage({
                  id: PAYMENT_METHOD[record?.paymentMethod!]?.label || ' ',
                })}
              </Typography>
            );
          },
        },
      ] as Columns<PaymentHistory>[];
    } else if (
      paymentHistory.type === PAYMENT_HISTORY_TYPE.PAYMENT.value ||
      paymentHistory.type === PAYMENT_HISTORY_TYPE.PREPAYMENT.value
    ) {
      return [
        {
          title: 'cashier.service',
          width: 190,
          render: (record) => record?.content,
        },
        {
          title: 'printForm.SL',
          dataIndex: 'quantity',
          align: 'center',
          render: (record) => {
            if (record?.quantity) {
              return (record.quantity || 0) > 9 ? record.quantity : `0${record.quantity}`;
            }
            return '';
          },
        },

        {
          title: 'therapy.cashier.paymentHistory.printDialog.header.unit',
          dataIndex: 'unit',
          align: 'center',
          lastCell: {
            colSpan: 4,
          },
        },

        {
          title: 'cashier.price',
          dataIndex: 'unitPrice',
          render: (record) => NumericFormatText(record.unitPrice || 0),
          align: 'right',
          lastCell: {
            style: { paddingTop: '10px' },
            render: () => {
              return (
                <>
                  <Typography variant="subtitle2" color="textSecondary" textAlign={'end'} fontSize="19px">
                    <FormattedMessage id="total" />
                  </Typography>
                </>
              );
            },
          },
        },
        {
          title: 'cashier.discountAmount',
          dataIndex: 'discountAmount',
          render: (record) => NumericFormatText(record.totalDiscountAmount),
          align: 'right',
          lastCell: {
            style: { paddingTop: '10px' },
            align: 'right',
            colSpan: 1,
            render: () => {
              return (
                <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                  {NumericFormatText(
                    paymentHistoryDetail?.paymentDetails?.reduce(
                      (sum, detail) => sum + (detail.totalDiscountAmount || 0),
                      0,
                    ) || 0,
                  )}
                </Typography>
              );
            },
          },
        },
        {
          title: 'totalPrice',
          align: 'right',
          render: (record) => NumericFormatText((record.totalAmount || 0) - (record.totalDiscountAmount || 0)),
          lastCell: {
            style: { paddingTop: '10px' },
            align: 'right',
            colSpan: 1,
            render: () => {
              return (
                <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                  {NumericFormatText(
                    paymentHistoryDetail?.paymentDetails?.reduce(
                      (sum, detail) => sum + ((detail.totalAmount || 0) - (detail.totalDiscountAmount || 0)),
                      0,
                    ) || 0,
                  )}
                </Typography>
              );
            },
          },
        },
        {
          title: 'cashier.vatRate',
          align: 'right',
          lastCell: {
            style: { paddingTop: '10px' },
            colSpan: 1,
          },
          render: (record) =>
            record.vat !== undefined ? (
              <FormattedNumber value={record.vat} maximumFractionDigits={0} />
            ) : (
              <FormattedMessage id="cashier.noVat" />
            ),
        },
        {
          title: 'cashier.vatValue',
          align: 'right',
          render: (record) =>
            record.vatAmount !== undefined ? (
              <FormattedNumber value={record.vatAmount} maximumFractionDigits={0} />
            ) : null,
          lastCell: {
            style: { paddingTop: '10px' },
            align: 'right',
            colSpan: 1,
            render: () => {
              return (
                <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                  <FormattedNumber
                    value={
                      paymentHistoryDetail?.paymentDetails?.reduce((sum, detail) => sum + (detail.vatAmount || 0), 0) ||
                      0
                    }
                  />
                </Typography>
              );
            },
          },
        },
        {
          title: 'therapy.label.customer.pay',
          align: 'right',
          render: (record) => NumericFormatText(record.amountPaid),
          lastCell: {
            style: { paddingTop: '10px' },
            align: 'right',
            colSpan: 1,
            render: () => {
              return (
                <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                  {NumericFormatText(
                    paymentHistoryDetail?.paymentDetails?.reduce((sum, detail) => sum + (detail.amountPaid || 0), 0) ||
                      0,
                  )}
                </Typography>
              );
            },
          },
        },
      ] as Columns<PaymentDetail>[];
    } else if (paymentHistory.type === PAYMENT_HISTORY_TYPE.REFUND.value) {
      return [
        {
          title: 'cashier.service',
          width: 190,
          dataIndex: 'content',
        },
        {
          title: 'printForm.SL',
          dataIndex: 'quantity',
          align: 'center',
          render: (record) => ((record.quantity || 0) > 9 ? record.quantity : `0${record.quantity}`),
        },

        {
          title: 'therapy.cashier.paymentHistory.printDialog.header.unit',
          dataIndex: 'unit',
          align: 'center',
          lastCell: {
            colSpan: 4,
          },
        },

        {
          title: 'cashier.price',
          dataIndex: 'unitPrice',
          render: (record) => NumericFormatText(record.unitPrice),
          align: 'right',
          lastCell: {
            render: () => {
              return (
                <>
                  <Typography variant="subtitle2" color="textSecondary" textAlign={'end'} fontSize="19px">
                    <FormattedMessage id="total" />
                  </Typography>
                </>
              );
            },
          },
        },
        {
          title: 'cashier.discountAmount',
          dataIndex: 'discountAmount',
          render: (record) => NumericFormatText(record.discountAmount),
          align: 'right',
          lastCell: {
            align: 'right',
            colSpan: 1,
            render: () => {
              return (
                <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                  {NumericFormatText(
                    paymentHistoryDetail?.paymentDetails?.reduce(
                      (sum, detail) => sum + (detail.discountAmount || 0),
                      0,
                    ) || 0,
                  )}
                </Typography>
              );
            },
          },
        },
        {
          title: 'totalPrice',
          align: 'right',
          render: (record) => NumericFormatText(record.totalAmount),
          lastCell: {
            align: 'right',
            colSpan: 1,
            render: () => {
              return (
                <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                  {NumericFormatText(
                    paymentHistoryDetail?.paymentDetails?.reduce((sum, detail) => sum + (detail.totalAmount || 0), 0) ||
                      0,
                  )}
                </Typography>
              );
            },
          },
        },
        {
          title: 'cashier.vatRate',
          align: 'right',
          lastCell: {
            colSpan: 1,
          },
          render: (record) =>
            record.vat !== undefined ? (
              <FormattedNumber value={record.vat} maximumFractionDigits={0} />
            ) : (
              <FormattedMessage id="cashier.noVat" />
            ),
        },
        {
          title: 'cashier.vatValue',
          align: 'right',
          render: (record) =>
            record.vatAmount !== undefined ? (
              <FormattedNumber value={record.vatAmount} maximumFractionDigits={0} />
            ) : null,
          lastCell: {
            align: 'right',
            colSpan: 1,
            render: () => {
              return (
                <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                  <FormattedNumber
                    value={
                      paymentHistoryDetail?.paymentDetails?.reduce((sum, detail) => sum + (detail.vatAmount || 0), 0) ||
                      0
                    }
                  />
                </Typography>
              );
            },
          },
        },
        {
          title: 'therapy.cashier.paymentRefundHistory.printDialog.header.paidAmount',
          align: 'right',
          render: (record) => NumericFormatText(Math.abs(record.amountPaid || 0)),
          lastCell: {
            align: 'right',
            colSpan: 1,
            render: () => {
              return (
                <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                  {NumericFormatText(
                    Math.abs(
                      paymentHistoryDetail?.paymentDetails?.reduce(
                        (sum, detail) => sum + (detail.amountPaid || 0),
                        0,
                      ) || 0,
                    ),
                  )}
                </Typography>
              );
            },
          },
        },
      ] as Columns<PaymentDetail>[];
    }
    return [];
  }, [intl, paymentHistory.type, paymentHistoryDetail?.paymentDetails]);

  return (
    <Stack style={{ pageBreakAfter: 'always' }} sx={{ padding: 2, '& p': { fontSize: '18px', marginBottom: '10px' } }}>
      <Box>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase', marginBottom: '25px' }}>
            <FormattedMessage id={title} />
          </Typography>
        </HeaderPrintForm>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ columnCount: 2 }}>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="cashierPrint.encounterId" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{encounter?.code}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="therapy.label.customer.code" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{patient?.formatCode}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {patient?.name}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="category" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                <FormattedMessage id={PAYMENT_PLAN[encounter?.paymentPlan!]?.label || ' '} />
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{patient?.homeAddress?.address}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">{moment(patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                <FormattedMessage id={GENDER[patient?.gender!]?.label || ' '} />
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="telephone" />
              </Typography>
              :&nbsp; <Typography variant="body1">{patient?.mobilePhone}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginY={2}>
        <Divider />
      </Box>
      <TableCustom
        dataSource={data}
        containerProps={{
          sx: {
            '& th,tr,td': {
              padding: '2px 8px',
              backgroundColor: 'white',
              border: '1px solid #000',
              color: 'unset',
            },
            width: '100%',
            overflow: 'visible',
            marginTop: '5px',
            '& .MuiTypography-root': {
              fontSize: '18px',
              lineHeight: '26px',
              marginTop: '10px',
            },
          },
        }}
        columns={COLUMNS}
      />
      {paymentHistoryDetail?.paymentHistories?.length === 2 && (
        <Grid container spacing={1} style={{ paddingRight: '6px', marginTop: 1 }}>
          <Grid item xs={12} sx={{ columnCount: 1 }}>
            <Box display="flex" justifyContent="flex-end">
              <Typography fontSize="19px" variant="subtitle1" style={{ minWidth: 200 }}>
                <FormattedMessage id="therapy.payment.paymentConfirm.label.sofPrepayment" />
              </Typography>
              :&nbsp;
              <Typography fontSize="19px" variant="subtitle1" style={{ minWidth: 130 }} align="right">
                {numericFormat(
                  paymentHistoryDetail?.paymentHistories?.find((p) => p.type === 'PREPAYMENT')?.amountPaid || 0,
                )}
              </Typography>
            </Box>
            <Box display="flex" justifyContent="flex-end">
              <Typography fontSize="19px" variant="subtitle1" style={{ minWidth: 200 }}>
                <FormattedMessage id="therapy.payment.paymentConfirm.label.directPayment" />
              </Typography>
              :&nbsp;
              <Typography fontSize="19px" variant="subtitle1" style={{ minWidth: 130 }} align="right">
                {numericFormat(
                  paymentHistoryDetail?.paymentHistories?.find((p) => p.type === PAYMENT_HISTORY_TYPE.PAYMENT.value)
                    ?.amountPaid || 0,
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid item xs={12} sx={{ columnCount: 2 }}>
        <Stack
          sx={{ pageBreakInside: 'avoid', marginTop: 1, alignSelf: 'flex-end', width: '500px' }}
          alignItems="center"
        >
          <Typography variant="body1" fontStyle="italic"></Typography>
          <Typography variant="h6">
            <FormattedMessage id="therapy.indication.request.printForm.patientConfirm" />
          </Typography>
          <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
            <FormattedMessage id="medicalPrint.helperText" />
          </Typography>{' '}
          <Typography variant="body1">{encounter?.patient?.name}</Typography>
        </Stack>

        <Stack
          sx={{ pageBreakInside: 'avoid', marginTop: 1, alignSelf: 'flex-end', width: '500px' }}
          alignItems="center"
        >
          <Typography variant="body1" fontStyle="italic">
            <FormattedMessage
              id={'printForm.dateShort'}
              values={{
                hour: moment().hour(),
                min: moment().minute(),
                day: moment().date(),
                month: moment().month() + 1,
                year: moment().year(),
              }}
            />
          </Typography>
          <Typography variant="h6">
            <FormattedMessage id="printForm.cashier" />
          </Typography>
          <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
            <FormattedMessage id="medicalPrint.helperText" />
          </Typography>{' '}
          <Typography variant="body1">{appState.authen.user?.employee_name}</Typography>
        </Stack>
      </Grid>
    </Stack>
  );
};

const PaymentHistoryPrintButton = ({ paymentHistory, encounter }: Props) => {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const refPrintComponent = React.useRef(null);
  const title = useMemo(() => {
    if (paymentHistory.type === PAYMENT_HISTORY_TYPE.PAYMENT_ADVANCE_REFUND.value) {
      return 'therapy.prepayment.prepaymentRefundPrint.header.prepaymentRefund';
    } else if (
      paymentHistory.type === PAYMENT_HISTORY_TYPE.PAYMENT.value ||
      paymentHistory.type === PAYMENT_HISTORY_TYPE.PREPAYMENT.value
    ) {
      return 'therapy.cashier.paymentHistory.printDialog.title.receipt';
    } else if (paymentHistory.type === PAYMENT_HISTORY_TYPE.REFUND.value) {
      return 'therapy.cashier.paymentRefundHistory.printDialog.title.receipt';
    }
    return '';
  }, [paymentHistory.type]);

  return (
    <>
      <IconButtonTitle size="small" title="print" onClick={onOpenDialog}>
        <PrintIcon />
      </IconButtonTitle>
      <DialogCustom
        open={openDialog}
        onClose={onCloseDialog}
        PaperProps={{
          style: { minWidth: '60vw', maxWidth: '80vw' },
        }}
        title={title}
        footer={
          <Stack direction="row" justifyContent="flex-end" width="100%">
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                  <FormattedMessage id="printReceipt" />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Stack>
        }
      >
        <Box ref={refPrintComponent}>
          <DialogContent encounter={encounter} paymentHistory={paymentHistory} title={title} />
        </Box>
      </DialogCustom>
    </>
  );
};

export default PaymentHistoryPrintButton;
