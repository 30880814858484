import DialogCustom from 'modules/common/component/DialogCustom';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import PrintIcon from '@mui/icons-material/Print';
import { useDialog } from 'modules/common/hook';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import ReactToPrint from 'react-to-print';
import { FormattedMessage } from 'react-intl';
import { Print } from '@mui/icons-material';
import React from 'react';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import moment from 'moment';
import { Encounter, PaymentAdvance } from 'modules/schema';
import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  FE_DATE_FORMAT,
  FE_DATE_TIME_FORMAT,
  PAYMENT_PLAN,
} from 'modules/common/constants';
import { GENDER, PAYMENT_METHOD } from 'modules/common/apiConstants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import TableCustom from 'modules/common/component/TableCustom';
import { NumericFormatText } from 'modules/common/utils';

interface Props {
  encounter?: Encounter;
  paymentAdvance: PaymentAdvance;
}

const PaymentAdvancePrintButton = ({ encounter, paymentAdvance }: Props) => {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const { patient } = encounter || {};
  const { appState } = useGeneralHook();
  const refPrintComponent = React.useRef(null);
  return (
    <>
      <IconButtonTitle size="small" title="print" onClick={onOpenDialog}>
        <PrintIcon />
      </IconButtonTitle>
      <DialogCustom
        open={openDialog}
        onClose={onCloseDialog}
        PaperProps={{
          style: { minWidth: '60vw', maxWidth: '80vw' },
        }}
        title={'therapy.prepayment.paymentAdvancePrint.header.prepayment'}
        footer={
          <Stack direction="row" justifyContent="flex-end" width="100%">
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                  <FormattedMessage id="printReceipt" />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Stack>
        }
      >
        <Box ref={refPrintComponent}>
          <Stack
            style={{ pageBreakAfter: 'always' }}
            sx={{ padding: 2, '& p': { fontSize: '18px', marginBottom: '10px' } }}
          >
            <Box>
              <HeaderPrintForm>
                <Typography variant="h4" style={{ textTransform: 'uppercase', marginBottom: '25px' }}>
                  <FormattedMessage id="therapy.prepayment.paymentAdvancePrint.header.prepayment" />
                </Typography>
              </HeaderPrintForm>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ columnCount: 2 }}>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="therapy.code" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1">{encounter?.code}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="therapy.label.customer.code" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1">{patient?.formatCode}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="name" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                      {patient?.name}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="category" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1">
                      <FormattedMessage id={PAYMENT_PLAN[encounter?.paymentPlan!]?.label || ' '} />
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="address" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1">{patient?.homeAddress?.address}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="birthdayShort" />
                    </Typography>
                    :&nbsp;{' '}
                    <Typography variant="body1">
                      {moment(patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="gender" />
                    </Typography>
                    :&nbsp;{' '}
                    <Typography variant="body1">
                      <FormattedMessage id={GENDER[patient?.gender!]?.label || ' '} />
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="telephone" />
                    </Typography>
                    :&nbsp; <Typography variant="body1">{patient?.mobilePhone}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box marginY={2}>
              <Divider />
            </Box>
            <TableCustom
              dataSource={[paymentAdvance]}
              containerProps={{
                sx: {
                  '& th,tr,td': {
                    padding: '2px 8px',
                    backgroundColor: 'white',
                    border: '1px solid #000',
                    color: 'unset',
                  },
                  width: '100%',
                  overflow: 'visible',
                  marginTop: '5px',
                  '& .MuiTypography-root': {
                    fontSize: '18px',
                    lineHeight: '26px',
                    marginTop: '10px',
                  },
                },
              }}
              columns={[
                {
                  title: 'therapy.prepaid.table.header.createdTime',
                  width: '30%',
                  render: (record: PaymentAdvance) => (
                    <Typography variant="body1">
                      {record?.confirmedTime &&
                        moment(record?.confirmedTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT)}
                    </Typography>
                  ),
                },
                {
                  title: 'therapy.prepaid.table.header.prepaidAmount',
                  width: '30%',
                  render: (record: PaymentAdvance) => (
                    <Typography variant="body1">{NumericFormatText(record.amount || 0)}</Typography>
                  ),
                },
                {
                  title: 'cashier.paymentMethod',
                  width: '40%',
                  render: (record: PaymentAdvance) => {
                    return (
                      <Typography variant="body1">
                        <FormattedMessage id={PAYMENT_METHOD[record?.paymentMethod!]?.label || ' '} />
                      </Typography>
                    );
                  },
                },
              ]}
            />
            <Stack
              sx={{ pageBreakInside: 'avoid', marginTop: 1, alignSelf: 'flex-end', width: '500px' }}
              alignItems="center"
            >
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id={'printForm.dateShort'}
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="printForm.cashier" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>{' '}
              <Typography variant="body1">{appState.authen.user?.employee_name}</Typography>
            </Stack>
          </Stack>
        </Box>
      </DialogCustom>
    </>
  );
};

export default PaymentAdvancePrintButton;
