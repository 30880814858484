import moment from 'moment/moment';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { validateDateRange, validateNotFutureDate } from '../../../common/utils';
import { RECEIPT_TYPE } from '../saleManagement/constant';

export interface ReportProps {
  dateTime: string;
  title: string;
  value: number;
}

export const getGroupDataKey = (period, startTime, intl) => {
  if (period === 'week') {
    const startOfWeek = moment(startTime).format(FE_DATE_FORMAT);
    const endOfWeek = startTime.clone().add(6, 'day').format(FE_DATE_FORMAT);
    return `${startOfWeek}-${endOfWeek}`;
  } else if (period === 'month') {
    return `${intl.formatMessage({
      id: 'filterLabelMonth',
    })} ${startTime.format('MM')}`;
  }
  return moment(startTime).format(FE_DATE_FORMAT);
};

export const groupDataByTime = (period, data: ReportProps[], intl) => {
  const result = {};
  data.forEach((el, index) => {
    const dateTime = moment(el?.dateTime, BE_DATE_TIME_FORMAT, true);
    let periodStartTime = dateTime.startOf(period);
    const key = getGroupDataKey(period, periodStartTime, intl);
    if (!result[key]) {
      result[key] = [];
    }
    result[key].push(el);
  });
  return result;
};

export const getListDateInRange = (date1, date2, period, intl) => {
  if (!date1 || !date2) {
    return [];
  }
  const d1 = moment(date1, BE_DATE_FORMAT);
  const d2 = moment(date2, BE_DATE_FORMAT);

  let date = d1.startOf(period);
  const dates: any[] = [];
  while (date <= d2) {
    dates.push(getGroupDataKey(period, date, intl));
    date = date.add(1, period);
  }
  return dates;
};

export const validateFromDate = (fromDate, toDate, intl) => {
  return validateNotFutureDate(fromDate, intl) || validateDateRange(fromDate, toDate, intl);
};

export const validateToDate = (toDate, intl) => {
  return validateNotFutureDate(toDate, intl);
};

export const getReceiptTypeText = (value, intl) => {
  return intl.formatMessage({ id: RECEIPT_TYPE.find((rt) => rt.value === value)?.label });
};

export const validateOneYearDate = (startDate: moment.Moment, endDate: moment.Moment): boolean => {
  const daysDiff = endDate?.diff(startDate, 'days');
  const oneYearInDays = 365;
  return daysDiff <= oneYearInDays;
};
