import DialogCustom from 'modules/common/component/DialogCustom';
import PrintDialogContent from './PrintDialogContent';
import { useIndicationPrintFormDialogSelectors } from './state';

const IndicationRequestPrintForm = () => {
  const openDialog = useIndicationPrintFormDialogSelectors.use.dialogOpen();
  const onCloseDialog = useIndicationPrintFormDialogSelectors.use.onCloseDialog();
  return (
    <DialogCustom
      open={openDialog}
      onClose={onCloseDialog}
      title="therapy.exam.therapyDetail.label.printForm.indicationRequest.title"
      PaperProps={{
        style: { width: '70vw' },
      }}
      maxWidth="xl"
    >
      <PrintDialogContent />
    </DialogCustom>
  );
};

export default IndicationRequestPrintForm;
