import { z } from 'zod';

export const AppointmentGroupSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    mobilePhone: z.string(),
    address: z.string(),
    groupId: z.number(),
  })
  .partial();

export type AppointmentGroup = z.infer<typeof AppointmentGroupSchema>;
