import { Button } from '@mui/material';
import { useCreateMutate, useDialog } from 'modules/common/hook';
import EditPrepaymentDialog from './EditPaymentAdvanceDialog';
import { FormattedMessage } from 'react-intl';
import { Add } from '@mui/icons-material';
import { PrePayment } from 'modules/schema';
import { API_SERVER } from 'modules/common/api';
import { useCallback } from 'react';

interface Props {
  prepayment?: PrePayment;
  paymentAdvanceRevalidate: () => void;
}

const AddPaymentAdvanceButton = ({ prepayment, paymentAdvanceRevalidate }: Props) => {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const createPaymentAdvanceMutate = useCreateMutate({
    onSuccess: () => {
      paymentAdvanceRevalidate();
      onCloseDialog();
    },
  });
  const onCreatePaymentAdvance = useCallback(
    ({ prepaymentAmount }: { prepaymentAmount: number }) => {
      if (prepayment) {
        createPaymentAdvanceMutate({
          url: API_SERVER.prepayment.createPaymentAdvance,
          method: 'POST',
          data: { amount: prepaymentAmount, paymentId: prepayment?.paymentId, prepaymentId: prepayment?.id },
        });
      }
    },
    [createPaymentAdvanceMutate, prepayment],
  );

  return (
    <>
      <Button variant="contained" color="inherit" onClick={onOpenDialog} startIcon={<Add />}>
        <FormattedMessage id="prepaidAdd" />
      </Button>
      <EditPrepaymentDialog onClose={onCloseDialog} open={openDialog} onSubmit={onCreatePaymentAdvance} />
    </>
  );
};

export default AddPaymentAdvanceButton;
