import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { IndicationRequest, Medication } from 'modules/schema';
import DialogCustom from 'modules/common/component/DialogCustom';
import SchemaFormMain from 'modules/common/SchemaForm';
import { some } from 'modules/common/constants';

interface Props {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (value: some) => void;
  indicationRequests: IndicationRequest[];
  medication: Medication;
}

const AddDrugUsageDialog = (props: Props) => {
  const { onClose, open, indicationRequests, medication, onSubmit } = props;

  const dataIndicationOption = React.useMemo(
    () => indicationRequests?.map((item) => ({ ...item, value: item?.serviceId, label: item?.serviceName })),
    [indicationRequests],
  );

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 480 },
      }}
      title={'drugUsage.addInfo'}
    >
      <Box padding={2}>
        <Box display="flex" marginBottom={2}>
          <Typography variant="subtitle1" sx={{ whiteSpace: 'nowrap' }}>
            <FormattedMessage id="drugUsage.drugName" />
            :&nbsp;
          </Typography>
          <Typography variant="body1">{`${medication?.name}(${medication?.ingredient})`}</Typography>
        </Box>
        <SchemaFormMain
          onSubmit={onSubmit}
          onCancel={onClose}
          schema={{
            fields: ({ formProps: { intl } }) => {
              return {
                indicationRequest: {
                  type: 'auto-complete',
                  label: intl.formatMessage({ id: 'encounterDetail.indicationShort' }),
                  placeholder: intl.formatMessage({ id: 'drugUsage.indicationEnter' }),
                  options: dataIndicationOption,
                  register: { required: true },
                },
                intendedRoute: {
                  type: 'auto-complete',
                  label: intl.formatMessage({ id: 'drugUsage.intendedRoute' }),
                  placeholder: intl.formatMessage({ id: 'drugUsage.intendedRouteEnter' }),
                  options: medication.intendedRoute,
                  register: { required: true },
                },
              };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default AddDrugUsageDialog;
