import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, FormControlLabel, Grid, Radio, RadioGroup, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import { API_SERVER } from 'modules/common/api';
import DialogCustom from 'modules/common/component/DialogCustom';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import TableCustom from 'modules/common/component/TableCustom';
import {
  BE_DATE_FORMAT,
  FE_DATE_FORMAT,
  INDICATION_REQUEST_TYPE,
  LAB_TYPE_RESULT,
  PAYMENT_PLAN,
  VALUE_EVALUATE,
  some,
} from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { displayRange } from 'modules/common/utils';
import { Encounter, IndicationLaboratoryList, IndicationRequest } from 'modules/schema';
import { useFetch, useSpecializedDepartmentPIC } from 'modules/common/hook';
import { LAB_DEPARTMENT_CODE, PATIENT_ADDRESS_TYPE } from 'modules/common/apiConstants';
import { isEmpty } from 'lodash';

const COMPACT_TYPE = 'LAB_DOCTOR_PRINT_COMPACT_TYPE';
const LOCAL_STORAGE_TYPE_PRINT = 'printFormGroupingIndicationLab';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      '& .newPage': { pageBreakBefore: 'always' },
      '& .diver-page': { display: 'none' },
      padding: 0,
    },
  },
  table: {
    padding: 1,
    '& th,td': {
      border: `0.5px solid black`,
      backgroundColor: 'unset',
      color: 'unset',
      padding: '2px 4px',
    },
    '& thead': {
      '& th': {
        // textTransform: 'unset !important',
      },
    },
    '& tr': {
      backgroundColor: 'unset',
    },
    '& .MuiTypography-caption': {
      fontWeight: 500,
      fontSize: 14,
      color: '#607d8b',
    },

    '& .extendRow,.extendCell': {
      border: 'none',
    },
  },
});

interface TestReceiptContentProps {
  patientData: Encounter;
  data: some;
  compact: boolean;
  typePrint: string;
}
interface Props {
  open: boolean;
  onClose?: () => void;
  selectionIndication: IndicationRequest[];
}

const IndicationInfo = ({ indicationInfo }) => {
  const classes = useStyles();

  return (
    <Box>
      <Box marginY={1}>
        <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
          {indicationInfo.planDefTitle}
        </Typography>
      </Box>
      <TableCustom
        showAll
        hiddenFirstColumnCollapse
        hideColumnIndex
        dataSource={indicationInfo.indications || []}
        containerProps={{ className: classes.table }}
        columns={[
          {
            title: (
              <Typography variant="subtitle1">
                <FormattedMessage id={'stt'} />
              </Typography>
            ),
            dataIndex: 'index',
            align: 'center',
            width: 70,
          },
          {
            title: (
              <Typography variant="subtitle1">
                <FormattedMessage id={'referenceIndication.name'} />
              </Typography>
            ),
            props: (record) => ({
              colSpan: record.children?.length || record?.title ? 4 : 1,
              align: record.children?.length || record?.title ? 'center' : 'left',
            }),
            render: (record) => {
              return record?.children?.length ? record.serviceName : record.laboratoryReferenceName;
            },
          },
          {
            title: (
              <Typography variant="subtitle1">
                <FormattedMessage id={'referenceIndication.result'} />
              </Typography>
            ),
            dataIndex: 'result',
            align: 'center',
            props: (record) => ({
              colSpan: record?.children?.length ? 0 : 1,
            }),
            width: 100,
            render: (record) => {
              return (
                <Typography
                  variant={'body1'}
                  style={{
                    fontWeight: record.evaluate && record.evaluate !== VALUE_EVALUATE.normal ? 700 : 'unset',
                  }}
                >
                  {record.laboratoryReferenceDetailObject?.resultType === LAB_TYPE_RESULT.quantity
                    ? record.result
                    : record.laboratoryReferenceDetailObject?.resultType === LAB_TYPE_RESULT.list
                    ? record.listSelect || record.result || ''
                    : record.result}
                </Typography>
              );
            },
          },

          {
            title: (
              <Typography variant="subtitle1">
                <FormattedMessage id={'referenceIndication.unit'} />
              </Typography>
            ),
            props: (record) => ({
              colSpan: record.children?.length ? 0 : 1,
            }),
            dataIndex: 'unit',
            align: 'center',
            width: 100,
            render: (record) => {
              return record.laboratoryReferenceDetailObject?.unit;
            },
          },
          {
            title: (
              <Typography variant="subtitle1">
                <FormattedMessage id={'referenceIndication.referenceValue'} />
              </Typography>
            ),
            props: (record) => ({
              colSpan: record.children?.length ? 0 : 1,
            }),
            align: 'center',
            dataIndex: 'referenceData',
            width: 260,
            render: (record) => {
              return record?.laboratoryReferenceDetailObject?.laboratoryConditionList
                ?.map((v) =>
                  v.name
                    ? `${v.name} : ${displayRange(v.highIndex, v.lowIndex)}`
                    : `${displayRange(v.highIndex, v.lowIndex)}`,
                )
                .join('; ');
            },
          },
        ]}
      />
    </Box>
  );
};

const FormPrintContent = (props: TestReceiptContentProps) => {
  const { patientData, data, compact, typePrint } = props;
  const { appState } = useGeneralHook();
  const headDoctor = useSpecializedDepartmentPIC(LAB_DEPARTMENT_CODE);

  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
            <FormattedMessage id="referenceIndication.labPrintForm" />
          </Typography>
        </HeaderPrintForm>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ columnCount: 2 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 170 }}>
                <FormattedMessage id="patientCode" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{patientData?.patient?.formatCode}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 170 }}>
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {patientData?.patient?.name}
              </Typography>
            </Box>
            {!compact && (
              <>
                <Box display="flex">
                  <Typography variant="subtitle1" style={{ minWidth: 170 }}>
                    <FormattedMessage id="insuranceNumber" />
                  </Typography>
                  :&nbsp;
                </Box>
                <Box display="flex">
                  <Typography variant="subtitle1" style={{ minWidth: 170 }}>
                    <FormattedMessage id="category" />
                  </Typography>
                  :&nbsp;
                  <Typography variant="body1">
                    {patientData?.paymentPlan && (
                      <FormattedMessage id={PAYMENT_PLAN[patientData?.paymentPlan!]?.label} />
                    )}
                  </Typography>
                </Box>
              </>
            )}
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 170 }}>
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                {
                  patientData.patient?.patientAddressList?.find((ele) => ele.type === PATIENT_ADDRESS_TYPE.HOME)
                    ?.address
                }
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 170 }}>
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {moment(patientData?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 170 }}>
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {patientData?.patient?.gender && <FormattedMessage id={patientData?.patient?.gender} />}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 170 }}>
                <FormattedMessage id="telephone" />
              </Typography>
              :&nbsp; <Typography variant="body1">{patientData?.patient?.mobilePhone}</Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [
    compact,
    patientData.patient?.dob,
    patientData.patient?.formatCode,
    patientData.patient?.gender,
    patientData.patient?.mobilePhone,
    patientData.patient?.name,
    patientData.patient?.patientAddressList,
    patientData?.paymentPlan,
  ]);

  const encounterInfo = React.useMemo(() => {
    if (compact) {
      return (
        <Typography variant="subtitle1" component="span">
          <FormattedMessage id="preliminaryDiagnosis" /> :&nbsp;
          <Typography variant="body1" component="span">
            {patientData?.provisionalDiagnosis && extractHTMLContent(patientData?.provisionalDiagnosis)}
          </Typography>
        </Typography>
      );
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Box display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 170 }}>
              <FormattedMessage id="printForm.placeOfIndication" />
            </Typography>
            :&nbsp;
            <Typography variant="body1">{patientData?.physicsRoom?.name}</Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 170 }}>
              <FormattedMessage id="examDoctor" />
            </Typography>
            :&nbsp;
            <Typography variant="body1">{patientData?.picName}</Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 170 }}>
              <FormattedMessage id="referenceIndication.specimenNumber" />
            </Typography>
            :&nbsp;
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 170 }}>
              <FormattedMessage id="printForm.samplingTime" />
            </Typography>
            :&nbsp;
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 170 }}>
              <FormattedMessage id="printForm.approveTime" />
            </Typography>
            :&nbsp;
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Typography variant="subtitle1" component="span">
            <FormattedMessage id="encounterDetail.clinicalSymptoms" /> :&nbsp;
            <Typography variant="body1" component="span">
              {patientData?.clinicalExamination && extractHTMLContent(patientData?.clinicalExamination)}
            </Typography>
          </Typography>
        </Grid>
      </Grid>
    );
  }, [compact, patientData]);

  const footer = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 12 }}>
          <Grid container spacing={1}>
            {compact ? (
              <Grid item xs={6} />
            ) : (
              <Grid item xs={6} textAlign="center">
                <Typography variant="body1" fontStyle="italic">
                  <FormattedMessage
                    id={compact ? 'printForm.dateShort' : 'printForm.date'}
                    values={{
                      hour: moment().hour(),
                      min: moment().minute(),
                      day: moment().date(),
                      month: moment().month() + 1,
                      year: moment().year(),
                    }}
                  />
                </Typography>
                <Typography variant="h6">
                  <FormattedMessage id="printForm.labDoctor" />
                </Typography>
                <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                  <FormattedMessage id="medicalPrint.helperText" />
                </Typography>
                <Typography variant="body1">{headDoctor?.employeeFullName}</Typography>
              </Grid>
            )}
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id={compact ? 'printForm.dateShort' : 'printForm.date'}
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="printForm.labDoctor2" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
              <Typography variant="body1">{appState.authen?.user?.employee_name}</Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [appState.authen?.user?.employee_name, compact, headDoctor?.employeeFullName]);

  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }}>
        {header}
        <Box paddingY={1}>
          <Divider />
        </Box>
        {encounterInfo}
        {typePrint === 'separate' ? (
          <IndicationInfo indicationInfo={data} />
        ) : (
          <>
            {data.list?.map((indicationInfo, index) => (
              <IndicationInfo key={index} indicationInfo={indicationInfo} />
            ))}
          </>
        )}
        {footer}
      </Box>
    </>
  );
};

const PrintFormDialog = (props: Props) => {
  const { onClose, open, selectionIndication } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [compact, setCompact] = React.useState(localStorage.getItem(COMPACT_TYPE) === 'compact' || false);
  const [typePrint, setTypePrint] = React.useState<string>(sessionStorage.getItem(LOCAL_STORAGE_TYPE_PRINT) || 'group');

  // get patient data
  const { data: patientDataMapping } = useFetch(API_SERVER.encounter.get(selectionIndication?.[0]?.referenceId!), {
    globalLoading: true,
  });

  // get an list indication laboratory by encounter id
  const { data: dataLab, isValidating } = useFetch(
    API_SERVER.indicationLaboratory.getListByRefereceId({
      referenceId: patientDataMapping?.id!,
      type: INDICATION_REQUEST_TYPE.ENCOUNTER,
    }),
    { enabled: patientDataMapping?.id },
  );

  // map data indication laboratory by encounter id
  const groupedMappedData = React.useMemo(() => {
    const indicationLaboratories: IndicationLaboratoryList[] = [];
    dataLab?.map((i) => {
      selectionIndication.map((item) => {
        if (i.indicationRequest?.id === item.id) {
          return indicationLaboratories.push(i);
        }
        return item;
      });
      return i;
    });
    const groups: some[] = [];
    const map: Map<string, some> = new Map();
    for (let oneIndication of indicationLaboratories) {
      let obj: some = {};
      if (!isEmpty(oneIndication?.indicationLaboratoryList)) {
        if (oneIndication?.indicationLaboratoryList!?.length > 1) {
          obj = {
            ...oneIndication?.indicationRequest,
            children: oneIndication?.indicationLaboratoryList,
          };
        } else {
          obj = {
            ...oneIndication?.indicationRequest,
            ...oneIndication?.indicationLaboratoryList![0],
          };
        }
      }
      let group = map.get(obj?.systemCategoryName!);
      if (!group) {
        group = { planDefTitle: obj?.systemCategoryName, indications: [] };
        map.set(obj?.systemCategoryName!, group);
        groups.push(group);
      }
      group.indications.push(obj);
    }
    return typePrint === 'group'
      ? [
          {
            list: groups,
          },
        ]
      : groups;
  }, [dataLab, selectionIndication, typePrint]);

  return (
    <DialogCustom
      open={open && !isValidating}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: '80vw' },
      }}
      keepMounted={false}
      title={'medicalRecord'}
      footer={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={compact}
                onChange={(e) => {
                  setCompact(e.target.checked);
                  localStorage.setItem(COMPACT_TYPE, e.target.checked ? 'compact' : 'expansion');
                }}
              />
            }
            label={<FormattedMessage id="printCompact" />}
          />
          <Box display="flex" alignItems="center">
            <RadioGroup
              row
              value={typePrint}
              onChange={(event) => {
                const val = (event.target as HTMLInputElement).value;
                setTypePrint(val);
                sessionStorage.setItem(LOCAL_STORAGE_TYPE_PRINT, val);
              }}
            >
              <FormControlLabel
                value="group"
                control={<Radio />}
                label={<FormattedMessage id="printForm.grouping" />}
              />
              <FormControlLabel
                value="separate"
                control={<Radio />}
                label={<FormattedMessage id="printForm.separating" />}
              />
            </RadioGroup>
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                  <FormattedMessage id="labDoctor.formPrint" />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />{' '}
          </Box>
        </Box>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        {groupedMappedData?.map((item: some, index: number) => {
          return (
            <Box
              className={`${index ? 'newPage' : ''} ${item.hidden ? 'hiddenPage' : ''}`}
              key={index}
              sx={{ position: 'relative', display: 'block', minHeight: '100%' }}
            >
              <table>
                <tbody>
                  <FormPrintContent
                    typePrint={typePrint}
                    data={item}
                    patientData={patientDataMapping || {}}
                    compact={compact}
                  />
                </tbody>
              </table>
              {index !== groupedMappedData.length - 1 && <Divider className="diver-page" sx={{ marginY: 4 }} />}
            </Box>
          );
        })}
      </Box>
    </DialogCustom>
  );
};

export default PrintFormDialog;
