import { some, SYSTEM_CATEGORY_SCOPE, SYSTEM_CATEGORY_SCOPE_LABEL } from 'modules/common/constants';
import * as React from 'react';
import { Switch, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import LabResultDialog from './IndicationDetail/LabResultDialog';
import ImageResultDialog from './IndicationDetail/ImageResultDialog';
import ProcedureDetailDialog from './IndicationDetail/ProcedureDetail/ProcedureDetailDialog';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { THERAPY_INDICATION_STATUS } from '../constants';
import { axiosThunk } from 'modules/common/redux/axios';
import { API_SERVER } from 'modules/common/api';
import { AxiosError } from 'axios/index';
import { setLoading } from 'modules/common/redux/commonReducer';
import { NumericFormatText } from 'modules/common/utils';

interface Props {
  data?: some[];
  loading?: boolean;
  readOnly?: boolean;
  actionNode?: (item: some, index: number) => React.ReactNode;
  updateQuantity?: (item: some) => void;
  updatePaymentPercent?: (item: some) => void;
  selection?: some[];
  setSelection?: (item: some[]) => void;
  //
  onChangeIsFreeStatus: (item: some) => void;
  revalidateTherapySession: () => void;
}

const IndicationTableList: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    selection,
    loading,
    setSelection,
    actionNode,
    readOnly,
    onChangeIsFreeStatus,
    revalidateTherapySession,
  } = props;
  const [labResultIndication, setLabResultIndication] = React.useState<some>();
  const [imageResultIndication, setImageResultIndication] = React.useState<some>();
  const [procedureIndication, setProcedureIndication] = React.useState<some>();
  const { intl, dispatch, openSnackbar } = useGeneralHook();

  const onSubmitIndicationProcedure = React.useCallback(
    async (indicationProcedure: some) => {
      await dispatch(
        axiosThunk({
          url: API_SERVER.indicationProcedures.update,
          method: 'put',
          data: {
            ...indicationProcedure,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
          // revalidate();
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors?.forEach((v) => {
              openSnackbar({
                message: v.message,
                type: 'error',
              });
            });
        })
        .finally(() => {
          revalidateTherapySession();
          dispatch(setLoading(false));
          setProcedureIndication(undefined);
        });
    },
    [dispatch, intl, openSnackbar, revalidateTherapySession],
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: 'therapyIndication.header.label.serviceName',
        render: (record) => {
          return (
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: record.scope !== SYSTEM_CATEGORY_SCOPE.other ? 'underline' : 'none',
                cursor: record.scope !== SYSTEM_CATEGORY_SCOPE.other ? 'pointer' : 'auto',
              }}
              color={record.scope !== SYSTEM_CATEGORY_SCOPE.other ? 'primary' : ''}
              onClick={() => {
                if (record.scope === SYSTEM_CATEGORY_SCOPE.laboratory) {
                  setLabResultIndication(record);
                } else if (record.scope === SYSTEM_CATEGORY_SCOPE.radiology) {
                  setImageResultIndication(record);
                } else if (record.scope === SYSTEM_CATEGORY_SCOPE.surgical) {
                  setProcedureIndication(record);
                }
              }}
            >
              {record.serviceName}
            </Typography>
          );
        },
      },
      {
        title: 'therapyIndication.header.label.serviceType',
        dataIndex: 'scope',
        render: (record) => {
          const label = SYSTEM_CATEGORY_SCOPE_LABEL.find((v) => v.value === record.scope)?.label;
          return <Typography>{intl.formatMessage({ id: label || ' ' })}</Typography>;
        },
      },
      {
        title: 'therapyIndication.header.label.picName',
        dataIndex: 'executeName',
      },
      {
        title: 'therapyIndication.header.label.price',
        dataIndex: 'price',
        render: (data) => {
          return <Typography variant="body1">{NumericFormatText(data.price)}</Typography>;
        },
      },
      {
        title: 'status',
        // dataIndex: 'status',
        render: (record) => {
          const status = THERAPY_INDICATION_STATUS.find((s) => s.value === record.status);
          return (
            <Typography
              noWrap
              sx={{
                color: status?.color || 'primary.main',
                fontWeight: '500',
              }}
            >
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
      },
      {
        title: 'therapyIndication.header.label.isFree',
        dataIndex: 'isFree',
        render: (record) => {
          return (
            <Switch
              checked={!record.isFree}
              onChange={(e) =>
                onChangeIsFreeStatus({
                  ...record,
                  isFree: e.target.checked ? false : true,
                })
              }
              disabled={readOnly || record.isPaid}
            />
          );
        },
      },
      !!actionNode && {
        title: 'action',
        align: 'right',
        width: 200,
        render: (record, index) => {
          return actionNode && actionNode(record, index);
        },
      },
    ] as Columns[];
  }, [actionNode, intl, readOnly, onChangeIsFreeStatus]);

  return (
    <>
      {!!setSelection ? (
        <TableCustom
          dataSource={data}
          loading={loading}
          columns={columns}
          rowSelection={{
            selectedRowKeys: selection,
            onChange: ({ selectedRows }) => setSelection(selectedRows),
          }}
        />
      ) : (
        <TableCustom dataSource={data} loading={loading} columns={columns} />
      )}

      {!!labResultIndication && (
        <LabResultDialog
          onClose={() => {
            setLabResultIndication(undefined);
          }}
          open={!!labResultIndication}
          indication={labResultIndication}
        ></LabResultDialog>
      )}
      {!!imageResultIndication && (
        <ImageResultDialog
          onClose={() => {
            setImageResultIndication(undefined);
          }}
          indication={imageResultIndication}
          open={!!imageResultIndication}
        ></ImageResultDialog>
      )}
      {!!procedureIndication && (
        <ProcedureDetailDialog
          onClose={() => {
            setProcedureIndication(undefined);
          }}
          formData={procedureIndication}
          open={!!procedureIndication}
          onSubmit={onSubmitIndicationProcedure}
        ></ProcedureDetailDialog>
      )}
    </>
  );
};

export default IndicationTableList;
