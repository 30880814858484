import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { LICENSE_TYPES } from '../../constants';
import { SpecializedDepartment } from 'modules/schema';

interface Props {
  data?: SpecializedDepartment[];
  onEdit: (item: SpecializedDepartment) => void;
  onDelete: (item: SpecializedDepartment) => void;
  loading?: boolean;
}

const DepartmentTable: React.FunctionComponent<Props> = (props) => {
  const { data, onEdit, onDelete, loading } = props;

  const columns = React.useMemo(() => {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'department.name',
        dataIndex: 'name',
      },
      {
        title: 'department.code',
        render: (record) => {
          return record.code;
        },
      },
      {
        title: 'department.licenseType',
        render: (record) => {
          return record.licenseType === LICENSE_TYPES.own ? (
            <FormattedMessage id="department.ownLicense" />
          ) : (
            <FormattedMessage id="department.collaborateLicense" />
          );
        },
      },
      {
        title: 'action',
        align: 'right',
        width: 120,
        render: (record) => {
          return (
            <>
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  onEdit(record);
                }}
                style={{ marginRight: 8 }}
              >
                <EditIcon />
              </IconButtonTitle>
              <IconButtonTitle
                title="delete"
                size="small"
                onClick={() => {
                  onDelete(record);
                }}
              >
                <DeleteIcon />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns<SpecializedDepartment>[];
  }, [onDelete, onEdit]);

  return <TableCustom dataSource={data} loading={loading} columns={columns} />;
};

export default DepartmentTable;
