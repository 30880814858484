import DialogCustom from '../../../../common/component/DialogCustom';
import PrintDialogContent from './PrintDialogContent';
import { Encounter, IndicationRequest } from 'modules/schema';

interface Props {
  open: boolean;
  onClose: () => void;
  encounter: Encounter;
  selectedIndications: IndicationRequest[] | undefined;
}
// selectedIndication === undefined => print All completed indication
// selectedIndication === [...] => print all selectedIndiation
const IndicationPrintForm = ({ open, onClose, encounter, selectedIndications }: Props) => {
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="indication.indicationList.lab.printForm.title"
      PaperProps={{
        style: { width: '1000px' },
      }}
      maxWidth="xl"
    >
      <PrintDialogContent onClose={onClose} encounter={encounter} selectedIndications={selectedIndications} />
    </DialogCustom>
  );
};

export default IndicationPrintForm;
