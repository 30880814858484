import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_FORMAT } from '../common/constants';
import { InventoryImportDetailSchema } from './InventoryImportDetail';

export const InventoryImportSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    clinicId: z.number(),
    importDate: createDateTimeSchema(BE_DATE_FORMAT),
    status: z.string(),
    importBy: z.string(),
    updatedTime: createDateTimeSchema(BE_DATE_FORMAT),
    note: z.string(),
    totalAmount: z.number(),
    inventoryImportDetails: z.array(InventoryImportDetailSchema),
  })
  .partial();

export type InventoryImport = z.infer<typeof InventoryImportSchema>;
