import { Box } from '@mui/material';
import SchemaForm from '../../../../../common/SchemaForm';
import Action from './Action';
import * as React from 'react';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { useEditPICSelectors } from '../state';
import { API_SERVER } from 'modules/common/api';
import { axiosThunk } from 'modules/common/redux/axios';
import { useMemo } from 'react';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useUserRoles } from 'modules/common/hook';

interface Props {
  refreshData?: () => void;
}
const DialogContent = ({ refreshData }: Props) => {
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const { encounterId, doctorName, doctorId, encounterType, doctorResourceId } = useEditPICSelectors.use.commonInfo()!;
  const onCloseDialog = useEditPICSelectors.use.onCloseDialog();

  const doctors = useUserRoles('DOCTOR', 'GENERAL_DOCTOR');

  const picOptions = useMemo(
    () =>
      doctors
        ?.filter((doctor) => doctor.resourceId !== doctorResourceId)
        ?.filter((doctor) => doctor?.id !== parseInt(doctorId || '0'))
        ?.map((doctor) => ({ value: doctor?.resourceId, label: doctor?.employeeFullName, userId: doctor?.id })) || [],
    [doctorResourceId, doctorId, doctors],
  );

  const onChangePIC = async (formData) => {
    const picUser = picOptions.find((el) => el.value === formData.newPIC);
    try {
      dispatch(setLoading(true));
      if (encounterType === 'ENCOUNTER') {
        await dispatch(
          axiosThunk({
            url: API_SERVER.encounter.changePIC(parseInt(encounterId)),
            method: 'PUT',
            data: {
              picUserId: picUser?.userId,
              picName: picUser?.label,
              note: formData?.note,
            },
          }),
        );
      } else if (encounterType === 'DENTAL') {
        await dispatch(
          axiosThunk({
            url: API_SERVER.dentalExam.changePicUser(encounterId),
            method: 'PUT',
            headers: {
              'Content-type': 'application/fhir+json',
            },
            data: {
              picName: picUser?.label,
              picUserId: picUser?.userId,
              note: formData?.note,
            },
          }),
        );
      }
      refreshData && refreshData();
      openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      onCloseDialog();
    } catch (e) {
      openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  };
  return (
    <Box padding={2}>
      <Box>
        <SchemaForm
          hideSubmitButton
          onSubmit={onChangePIC}
          schema={{
            fields: ({ formProps: { intl }, valuesField }) => {
              return {
                currentPIC: {
                  type: 'label',
                  label: intl.formatMessage({ id: 'encounter.encounterDetail.editPIC.currentPIC.label' }),
                  value: doctorName,
                },
                newPIC: {
                  type: 'select',
                  options: picOptions,
                  label: intl.formatMessage({ id: 'encounter.encounterDetail.editPIC.newPIC.label' }),
                  placeholder: intl.formatMessage({ id: 'encounter.encounterDetail.editPIC.newPIC.placeholder' }),
                  propsWrapper: { xs: 12 },
                  register: {
                    required: true,
                  },
                },
                note: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'note' }),
                  placeholder: intl.formatMessage({ id: 'enter' }),
                  rows: 3,
                  multiline: true,
                },
                action: {
                  type: Action,
                },
              };
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default DialogContent;
