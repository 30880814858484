import { Box, Fade, Stack, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { BLOCK_KEY, ENCOUNTER_DETAIL_SESSIONS, SESSION_KEY } from '../const';
import { EncounterSession, EncounterTab } from '../type';
import styled from '@emotion/styled';
import { scrollInfoElement } from '../utils';
import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

const SessionTabs = ({ session }: { session: EncounterSession }) => {
  const history = useHistory();
  const location = useLocation();

  const onBlockSelect = useCallback(
    (tab: EncounterTab) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.delete(SESSION_KEY);
      urlSearchParams.delete(BLOCK_KEY);
      urlSearchParams.append(SESSION_KEY, session.key);
      urlSearchParams.append(BLOCK_KEY, tab.key);
      history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
      scrollInfoElement(session.key);
    },
    [history, location.pathname, location.search, session.key],
  );
  return (
    <Stack sx={{ padding: 0 }}>
      {session.tabs.map((tab) => (
        <Box
          key={tab.key}
          onClick={(event) => {
            event.stopPropagation();
            onBlockSelect(tab);
          }}
          sx={{
            transition: 'all 0.3s',
            '&:hover': {
              backgroundColor: '#CDDFFF',
            },
          }}
        >
          <Typography
            sx={{
              padding: '10px 20px',
              color: '#000',
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: 'Roboto',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.1px',
              borderRadius: '50%',
              cursor: 'pointer',
            }}
          >
            <FormattedMessage id={tab.title} />
          </Typography>
        </Box>
      ))}
    </Stack>
  );
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#FFF',
    borderRadius: '5px',
    padding: '0px',
  },
}));

const EncounterNav = () => {
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const selectedSession = useMemo(() => urlSearchParams.get(SESSION_KEY), [urlSearchParams]);

  const onSessionSelect = useCallback(
    (session: EncounterSession) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.delete(SESSION_KEY);
      urlSearchParams.delete(BLOCK_KEY);
      urlSearchParams.append(SESSION_KEY, session.key);
      urlSearchParams.append(BLOCK_KEY, session.tabs[0].key);
      history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
      scrollInfoElement(session.key);
    },
    [history, location.pathname, location.search],
  );
  return (
    <Stack direction="row" justifyContent="center" alignItems="center" gap="20px">
      {ENCOUNTER_DETAIL_SESSIONS?.map((session, index) => (
        <Stack key={session.key} direction="row" alignItems="center" justifyContent="center" gap="20px">
          <LightTooltip
            title={<SessionTabs session={session} />}
            TransitionComponent={Fade}
            TransitionProps={{ timeout: 500 }}
          >
            <Stack
              key={session.title}
              direction="row"
              justifyContent="center"
              alignItems="center"
              gap="10px"
              sx={{ cursor: 'pointer' }}
              onClick={() => onSessionSelect(session)}
            >
              <Typography
                sx={{
                  color: selectedSession === session.key ? '#F4F8FF' : '#000',
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.1px',
                  borderRadius: '50%',
                  width: '26px',
                  height: '26px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: selectedSession === session.key ? '#307BFF' : '#CDDFFF',
                }}
              >
                {index + 1}
              </Typography>
              <Typography
                sx={{
                  color: '#263238',
                  fontFeatureSettings: "'clig' off, 'liga' off",
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 500,
                  lineHeight: '24px',
                  letterSpacing: '0.1px',
                }}
              >
                <FormattedMessage id={session.title} />
              </Typography>
            </Stack>
          </LightTooltip>

          {index !== ENCOUNTER_DETAIL_SESSIONS.length - 1 ? (
            <Box sx={{ width: '25px', height: '2px', borderRadius: '10px', backgroundColor: '#307BFF' }} />
          ) : null}
        </Stack>
      ))}
    </Stack>
  );
};

export default EncounterNav;
