import { blueGrey, grey, red } from '@mui/material/colors';
import { alpha, darken, unstable_createMuiStrictModeTheme as createMuiTheme } from '@mui/material/styles';
import shadows from '@mui/material/styles/shadows';
import { PRIMARY, SECONDARY } from './colors';

export const MUITheme = createMuiTheme({
  components: {
    MuiDivider: {
      styleOverrides: {
        root: { height: 1 },
      },
    },
    MuiTypography: {
      styleOverrides: {},
    },
    MuiInputAdornment: {
      styleOverrides: {
        positionStart: {
          marginLeft: 8,
          marginRight: 0,
        },
        positionEnd: {
          marginLeft: 0,
          marginRight: 12,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '0.5px solid #e0e0e0',
        },
        stickyHeader: {
          backgroundColor: 'unset',
        },
      },
    },
    MuiTablePagination: {
      styleOverrides: {
        root: { justifyContent: 'flex-end', overflow: 'unset', display: 'flex', border: 'none', marginBottom: '-12px' },
        selectRoot: {
          margin: '0 16px 0 8px',
          minWidth: '64px',
        },
        selectIcon: {
          top: 'calc(50% - 14px)',
        },
        menuItem: { fontSize: 12 },
        input: {
          '& .MuiTablePagination-select': {
            textAlign: 'left',
            textAlignLast: 'left',
            background: 'inherit',
            border: `0.5px solid ${grey[400]}`,
            borderRadius: '2px',
            fontSize: 14,
            padding: '3px 12px',
          },
        },
        actions: {
          marginLeft: '10px',
          '& .MuiIconButton-root': {
            padding: '6px',
          },
        },
        spacer: {
          flex: 'unset',
        },
        // even: {
        //   background: "white",
        // },
        // odd: {
        //   background: BLUE_200,
        // },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 0,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
        variant: 'contained',
        color: 'primary',
      },
      styleOverrides: {
        root: {
          borderRadius: 4,
          fontSize: 14,
          padding: '4px 16px',
        },
        outlined: {
          padding: '3px 16px',
        },
        containedInherit: {
          backgroundColor: SECONDARY.light,
          color: PRIMARY.main,
          fill: PRIMARY.main,
          '&:hover': {
            backgroundColor: darken(SECONDARY.light, 0.05),
          },
        },
        // containedSecondary: {
        //   backgroundColor: PRIMARY.dark,
        //   color: 'white',
        //   fill: PRIMARY.dark,
        //   '&:hover': {
        //     backgroundColor: darken(PRIMARY.dark, 0.05),
        //   },
        // },
        containedPrimary: {
          backgroundColor: PRIMARY.main,
          color: 'white',
          fill: PRIMARY.main,
          '&:hover': {
            backgroundColor: darken(PRIMARY.main, 0.05),
          },
        },
        textInherit: {
          color: PRIMARY.light,
        },
        textPrimary: {
          color: PRIMARY.main,
        },
        textSecondary: {
          color: PRIMARY.dark,
        },
      },
    },

    MuiSelect: {
      styleOverrides: {
        icon: {
          right: 8,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        rounded: {
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'column',
        },
      },
    },
    MuiFormLabel: {
      defaultProps: { sx: { color: 'text.primary' } },
      styleOverrides: {
        root: {
          fontSize: 14,
          marginBottom: 8,
          lineHeight: 1,
          fontWeight: 500,
          color: '#607d8b',
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          color: '#607d8b',
        },
        shrink: {
          transform: 'unset',
          position: 'relative',
          overflow: 'unset',
        },
        asterisk: {
          color: red[600],
          '&::before': {
            color: blueGrey[500],
            content: '" ("',
          },
          '&::after': {
            color: blueGrey[500],
            content: '" )"',
          },
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '24px !important',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: { height: 8 },
        contained: { marginLeft: 0 },
      },
    },
    MuiAutocomplete: {
      defaultProps: {},
      styleOverrides: {
        inputRoot: { padding: 0, backgroundColor: 'white' },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: { padding: 0 },
        adornedEnd: { padding: 0 },
        adornedStart: { padding: 0 },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          padding: '0px !important',
        },
        input: {
          padding: '4px 8px !important',
          minHeight: 24,
        },
        inputMultiline: {
          height: 'auto !important',
          padding: '8px 8px !important',
        },
        root: {
          borderRadius: 4,
          fontSize: 14,
          backgroundColor: 'white',
          '&.MuiOutlinedInput-root.Mui-focused fieldset': {
            boxShadow: `${alpha(PRIMARY.main, 0.25)} 0 0 0 0.2rem`,
            borderWidth: 1,
          },
          '& fieldset': {
            top: 0,
            '& legend': {
              display: 'none',
            },
          },
        },
      },
    },
  },
  palette: {
    primary: {
      light: PRIMARY.light,
      main: PRIMARY.main,
      dark: PRIMARY.dark,
      100: PRIMARY[100],
      50: PRIMARY[50],
      contrastText: '#ffffff',
    },
    secondary: {
      light: SECONDARY.light,
      main: PRIMARY.dark,
      // dark: SECONDARY.dark,
      contrastText: '#ffffff',
    },
    text: {
      primary: '#263238',
      secondary: '#455A64',
    },
    error: {
      light: '#EF5350',
      main: '#E53935',
      dark: '#C62828',
      contrastText: '#ffffff',
    },
    warning: {
      light: '#FFC107',
      main: '#FFB300',
      dark: '#FFA000',
      contrastText: '#ffffff',
    },
    success: {
      light: '#66BB6A',
      main: '#43A047',
      dark: '#2E7D32',
      contrastText: '#ffffff',
    },
    info: {
      light: '#7EADFF',
      main: '#5794FF',
      dark: '#0962FF',
      contrastText: '#ffffff',
    },
    action: {
      hover: '#eff5ff',
      hoverOpacity: 0.1,
      selected: darken('#eff5ff', 0.1),
      selectedOpacity: 0.25,
    },
    background: {
      default: '#E0ECFF',
      paper: '#ffffff',
    },
  },
  shadows: [
    'none',
    '0px 2px 4px #E0ECFF',
    '0px 4px 8px #E0ECFF',
    ' 0px 4px 16px #E0ECFF',
    '0px 8px 24px #F4F8FF',
    '0px 16px 32px #F4F8FF',
    ...shadows.slice(6),
  ] as any,
  typography: {
    htmlFontSize: 14,
    fontSize: 14,
    button: { textTransform: 'none' },
    h1: { fontSize: 96, lineHeight: '112px', fontWeight: 300 },
    h2: { fontSize: 60, lineHeight: '72px', fontWeight: 300 },
    h3: { fontSize: 48, lineHeight: '56px', fontWeight: 400 },
    h4: { fontSize: 34, lineHeight: '36px', fontWeight: 400 },
    h5: { fontSize: 24, lineHeight: '24px', fontWeight: 400 },
    h6: { fontSize: 20, lineHeight: '24px', fontWeight: 500 },
    subtitle1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 500,
    },
    subtitle2: {
      fontSize: '14px',
      lineHeight: '20px',
      fontWeight: 500,
    },
    body1: {
      fontSize: '16px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    body2: {
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: 400,
    },
    caption: {
      fontSize: '12px',
      lineHeight: '14px',
      fontWeight: 500,
    },
  },
});
