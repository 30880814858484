import { ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return {
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.groupName' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.groupNameEnter' }),
      autoFocus: true,
      register: {
        required: true,
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  return [
    {
      id: 'info',
      fields: ['name'],
    },
  ];
};

export const groupDrugStoreFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
