import { some, SYSTEM_CATEGORY_SCOPE_LABEL } from 'modules/common/constants';
import * as React from 'react';
import { Typography } from '@mui/material';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { NumericFormatText } from 'modules/common/utils';

interface Props {
  data?: some[];
  loading?: boolean;
  updateQuantity?: (item: some) => void;
  updatePaymentPercent?: (item: some) => void;
  actionNode?: (item: some, index: number) => React.ReactNode;
}

const IndicationTableList: React.FunctionComponent<Props> = (props) => {
  const { data, loading, actionNode } = props;
  const { intl } = useGeneralHook();

  const columns = React.useMemo(() => {
    return [
      {
        title: 'therapyIndication.header.label.serviceName',
        dataIndex: 'serviceName',
      },
      {
        title: 'therapyIndication.header.label.serviceType',
        dataIndex: 'serviceScope',
        render: (record) => {
          const label = SYSTEM_CATEGORY_SCOPE_LABEL.find((v) => v.value === record.serviceScope)?.label;
          return <Typography>{intl.formatMessage({ id: label || ' ' })}</Typography>;
        },
      },
      {
        title: 'dental.dentalDetail.indicationTable.price.label',
        render: (record) => NumericFormatText(record.price),
        iconTooltip: true,
        titleToolTip: 'title.tooltip.price.therapy',
      },
      !!actionNode && {
        title: 'action',
        align: 'left',
        width: 200,
        render: (record, index) => {
          return actionNode && actionNode(record, index);
        },
      },
    ] as Columns[];
  }, [actionNode, intl]);

  return <TableCustom dataSource={data} loading={loading} columns={columns} />;
};

export default IndicationTableList;
