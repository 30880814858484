import { Box } from '@mui/system';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { Encounter } from 'modules/schema';
import { Typography } from '@mui/material';
import moment from 'moment';
import { useHistory } from 'react-router';
import { ROUTES } from 'modules/layout/router';
import { PAYMENT_STATUS } from 'modules/common/apiConstants';

interface Props {
  encounters?: Encounter[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
}

const EpisodeOfCareListCashier: React.FunctionComponent<Props> = ({ encounters, loading, hookPagination, total }) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const history = useHistory();
  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounterId',
        render: (record) => {
          return <Typography>{record?.code}</Typography>;
        },
      },
      {
        title: 'patientCodeShort',
        render: (record) => {
          return <Typography>{record?.patient?.code?.split('-')[1]}</Typography>;
        },
      },
      {
        title: 'patientName',
        render: (record) => {
          return <Typography>{record?.patient?.name}</Typography>;
        },
      },
      {
        title: 'birthdayShort',
        render: (record) => {
          return <Typography>{moment(record?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>;
        },
        width: 120,
      },
      {
        title: 'telephone',
        render: (record) => {
          return <Typography>{record?.patient?.mobilePhone}</Typography>;
        },
      },
      {
        title: 'encounterDate',
        dataIndex: 'date',
        render: (record) => {
          return <Typography>{record?.createdTime?.format(FE_DATE_FORMAT)}</Typography>;
        },
      },
      {
        title: 'status',
        render: (record) => {
          const paymentStatus = PAYMENT_STATUS[record.payment?.status!];
          return (
            <Box color={paymentStatus?.color}>
              <FormattedMessage id={paymentStatus?.label || ' '} />
            </Box>
          );
        },
      },
    ] as Columns<Encounter>[];
  }, []);

  return (
    <TableCustom
      dataSource={encounters}
      loading={loading}
      columns={columns}
      paginationProps={{
        count: total,
        page: pagination.page || 0,
        rowsPerPage: pagination.pageSize,
        onPageChange: onPageChange,
        onRowsPerPageChange: onRowsPerPageChange,
      }}
      rowProps={(record) => ({
        onClick: async () => {
          history.push(ROUTES.episodeOfCareDetail.gen(record?.id!, record?.patientId!));
        },
        style: { cursor: 'pointer' },
      })}
    />
  );
};

export default EpisodeOfCareListCashier;
