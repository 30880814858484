import { z } from 'zod';
import { MedicationSchema } from './Medication';
import { MedicationSupplierSchema } from './MedicationSupplier';
import { createDateTimeSchema } from './common';
import { BE_DATE_FORMAT } from '../common/constants';

export const InventoryImportDetailSchema = z
  .object({
    id: z.number(),
    medication: MedicationSchema,
    medicationSupplier: MedicationSupplierSchema,
    lot: z.string(),
    expiredDate: createDateTimeSchema(BE_DATE_FORMAT),
    quantity: z.number(),
    unit: z.string(),
    unitPrice: z.number(),
    totalAmount: z.number(),
    isLocked: z.boolean(),
  })
  .partial();

export type InventoryImportDetail = z.infer<typeof InventoryImportDetailSchema>;
