import VisibilityOff from '@mui/icons-material/VisibilityOffRounded';
import Visibility from '@mui/icons-material/VisibilityRounded';
import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import React, { useCallback, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { LogoSVG } from '../../../svg';
import DoctorSVG from '../../../svg/login_image.jpg';
import { IDENTITY_API_SERVER, NOTIFICATION_API_SERVER } from '../../common/api';
import { SS_TOKEN } from '../../common/constants';
import jwt_decode from 'jwt-decode';
import useGeneralHook from '../../common/hook/useGeneralHook';
import {
  loadDepartmentCodes,
  loadICD10Data,
  loadIntentedRoutes,
  setLoading,
  setOrderEncounter,
  loadCurrencyOptions,
  setGroupCurrency,
  loadDentalIllnessOption,
  loadRoles,
  loadSpecializedDepartments,
  loadPhysicRooms,
  loadEvaluationForm,
  loadNational,
  loadMedicalExaminationForm,
  loadPrescriptionFormTemplate,
  loadMedicationInstructions,
} from '../../common/redux/commonReducer';
import { fetchThunk } from '../../common/redux/thunk';
import SchemaForm from '../../common/SchemaForm';
import LanguageSelect from '../../intl/component/LanguageSelect';
import { JwtDecoded, authenIn, saveUserInfo } from '../redux/authenReducer';
import { ROUTES } from '../../layout/router';
import { RawLink } from '../../common/component/elements';
import { getMachineId } from '../../layout/utils';
import { getMessagingToken } from '../../notification/firebase';
import { ORDER_TYPE } from 'modules/common/apiConstants';

// import { getMessaging, getToken, onMessage } from 'firebase/messaging';

export interface LoginForm {
  username: string;
  password: string;
  clinicCompanyID: string;
}

const LoginPage: React.FC<{}> = () => {
  const [fcmToken, setFcmToken] = React.useState('');
  const clinicCompanyID = localStorage.getItem('clinic-company-id');

  useEffect(() => {
    getMessagingToken()?.then((token) => {
      setFcmToken(token || '');
    });
  }, []);

  const { dispatch, openSnackbar, intl } = useGeneralHook();

  const login = useCallback(
    async (data: LoginForm) => {
      try {
        dispatch(setLoading(true));
        const loginRes = await dispatch(
          fetchThunk(
            IDENTITY_API_SERVER.user.login,
            'post',
            JSON.stringify({
              username: data.username,
              password: data.password,
              organizationCode: data.clinicCompanyID,
              deviceId: getMachineId(),
            }),
          ),
        );
        let jwtDecoded: JwtDecoded = jwt_decode(loginRes.accessToken);
        sessionStorage.setItem(SS_TOKEN, loginRes.accessToken);
        localStorage.setItem('clinic-company-id', data.clinicCompanyID);
        if (fcmToken) {
          try {
            dispatch(
              fetchThunk(
                NOTIFICATION_API_SERVER.notification.updateFCMToken,
                'post',
                JSON.stringify({
                  token: fcmToken,
                }),
              ),
            );
          } catch (e) {
            console.log('Fail to update current firebase token');
          }
        }
        const locations = await dispatch(fetchThunk(IDENTITY_API_SERVER.group.getGroup().url));
        dispatch(saveUserInfo(jwtDecoded, locations));
        dispatch(loadRoles());
        dispatch(loadPhysicRooms());
        dispatch(loadSpecializedDepartments());
        dispatch(loadICD10Data());
        dispatch(loadIntentedRoutes());
        dispatch(loadEvaluationForm());
        dispatch(loadDepartmentCodes());
        dispatch(loadNational());
        dispatch(loadCurrencyOptions());
        dispatch(setOrderEncounter(ORDER_TYPE.DESC));
        dispatch(setGroupCurrency(jwtDecoded.group_currency));
        dispatch(loadDentalIllnessOption());
        dispatch(loadMedicalExaminationForm());
        dispatch(loadPrescriptionFormTemplate());
        dispatch(loadMedicationInstructions());
        dispatch(setLoading(false));
        dispatch(authenIn());
      } catch (e: any) {
        dispatch(setLoading(false));
        if (e && !e?.errors) {
          openSnackbar({ message: e, type: 'error' });
        } else if (e?.errors) {
          e?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'login.unknownError' }), type: 'error' });
        }
      }
    },
    [dispatch, fcmToken, intl, openSnackbar],
  );

  return (
    <Box display="flex" alignItems="stretch" minHeight="100vh">
      <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
        <img src={DoctorSVG} alt="" width={550} />
      </Box>
      <Box flex={1} display="flex" alignItems="center">
        <Box width={300} marginLeft={10}>
          <Typography variant="h5">
            <FormattedMessage id="login" />
          </Typography>
          <Typography variant="subtitle2" color="primary" style={{ marginTop: 24, marginBottom: 8 }}>
            <FormattedMessage id="login.welcome" />
          </Typography>
          <LogoSVG style={{ height: 46, width: 'auto' }} />
          <Box marginTop="42px">
            <SchemaForm
              onSubmit={login}
              hideSubmitButton
              schema={{
                fields: ({ formProps: { intl, state, setState } }) => ({
                  clinicCompanyID: {
                    type: 'text-field',
                    label: <FormattedMessage id="clinicCompanyID" />,
                    placeholder: intl.formatMessage({ id: 'clinicCompanyIDEnter' }),
                    register: {
                      required: true,
                    },
                    defaultValue: clinicCompanyID,
                  },
                  username: {
                    type: 'text-field',
                    label: <FormattedMessage id="username" />,
                    placeholder: intl.formatMessage({ id: 'usernameEnter' }),
                    register: {
                      required: true,
                    },
                  },
                  password: {
                    type: 'text-field',
                    label: <FormattedMessage id="password" />,
                    placeholder: intl.formatMessage({ id: 'passwordEnter' }),
                    register: {
                      required: true,
                    },
                    inputType: state.showPass ? 'text' : 'password',
                    id: 'password',
                    autoComplete: 'current-password',
                    InputProps: {
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => setState({ showPass: !state.showPass })}
                          >
                            {state.showPass ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    },
                  },
                  submit: {
                    type: 'submitButton',
                    fullWidth: true,
                    label: <FormattedMessage id="login" />,
                  },
                  resetPassword: {
                    type: () => {
                      return (
                        <Box display={'flex'} justifyContent="center">
                          <RawLink to={{ pathname: ROUTES.resetPassword }}>
                            <Typography variant="subtitle1" sx={{ color: '#307BFF' }}>
                              <FormattedMessage id="forgotPassword.label" />
                            </Typography>
                          </RawLink>
                        </Box>
                      );
                    },
                  },
                }),
              }}
            />
          </Box>
        </Box>
      </Box>
      <Box position={'absolute'} top={10} right={40}>
        <LanguageSelect />
      </Box>
    </Box>
  );
};

export default LoginPage;
