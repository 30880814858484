import { Box } from '@mui/system';
import * as React from 'react';
import { API_SERVER } from 'modules/common/api';
import NoDataBox from 'modules/common/component/NoDataBox';
import { INDICATION_REQUEST_TYPE } from 'modules/common/constants';
import LabTable from './LabTable';
import { EncounterDetailCommonProps } from 'modules/examDoctor/type';
import { useFetch } from 'modules/common/hook';
import { IndicationLaboratoryList } from 'modules/schema';
import { isEmpty } from 'lodash';
import MediCard from 'modules/common/component/MediCard';
import { Typography } from '@mui/material';
import { PRIMARY } from 'colors';
import { PAYMENT_DETAIL_TYPE } from 'modules/common/apiConstants';
import DocumentCard from './DocumentCard';

const LabResult: React.FunctionComponent<EncounterDetailCommonProps> = ({ encounter }) => {
  const { data: dataLab } = useFetch(
    API_SERVER.indicationLaboratory.getListByRefereceId({
      referenceId: encounter?.id!,
      type: INDICATION_REQUEST_TYPE.ENCOUNTER,
    }),
  );

  // get data attached document
  const { data: attachedDocuments } = useFetch(
    API_SERVER.document.getByEncounterId({
      referenceId: encounter?.id!,
      type: PAYMENT_DETAIL_TYPE.INDICATION_REQUEST.value,
    }),
    { globalLoading: true },
  );

  return (
    <>
      {!isEmpty(dataLab) ? (
        dataLab?.map((item: IndicationLaboratoryList, index) => {
          return <LabTable key={index} data={item} patientId={encounter?.patientId!} />;
        })
      ) : (
        <Box display="flex" justifyContent={'center'}>
          <NoDataBox />
        </Box>
      )}
      {attachedDocuments?.map((val: any, index: number) => (
        <MediCard style={{ marginBottom: 16, padding: 8 }}>
          <Box padding={1} bgcolor={PRIMARY[50]}>
            <Typography variant="subtitle1" color="textSecondary">
              {val.name}
            </Typography>
            <DocumentCard data={val} key={val.id} encounter={encounter} />
          </Box>
        </MediCard>
      ))}
    </>
  );
};

export default LabResult;
