import { ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, isInsurance, departments } = formProps || {};
  const list = valuesField?.listData?.filter((v) => v.id !== valuesField?.id) || [];
  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'exam.codeLabel' }),
      placeholder: intl.formatMessage({ id: 'exam.codeLabelEnter' }),
      autoFocus: true,
      register: {
        required: isInsurance,
        validate: (value) =>
          value?.trim() && list.find((v) => v.code === value) ? intl.formatMessage({ id: 'exam.duplicateCode' }) : true,
      },
      required: isInsurance,
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'exam.nameService' }),
      placeholder: intl.formatMessage({ id: 'exam.nameServiceEnter' }),
      register: {
        required: true,
      },
    },
    department: {
      type: 'select',
      label: intl.formatMessage({ id: 'exam.departmentCode' }),
      placeholder: intl.formatMessage({ id: 'exam.departmentCodeEnter' }),
      options: departments,
      rawOptions: true,
      register: {
        required: true,
      },
      required: true,
    },
    basePrice: {
      type: 'number-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'exam.price' }),
      placeholder: intl.formatMessage({ id: 'exam.priceEnter' }),
      register: {
        required: true,
        // pattern: { value: NUMBER_REGEX, message: intl.formatMessage({ id: 'inValidFormat' }) },
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      fields: ['code', 'name', 'department'],
    },
    {
      id: 'price',
      title: intl.formatMessage({ id: 'rawPrice' }),
      fields: ['basePrice'],
    },
  ];
};

export const examPriceFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
