import moment from 'moment';
import * as React from 'react';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import SchemaForm from 'modules/common/SchemaForm';
import { Typography } from '@mui/material';

interface Props {
  hookPagination: HookPaginationProps;
}

const DetailedFilterBox: React.FunctionComponent<Props> = ({ hookPagination }) => {
  const { params, setParams, clearParams } = hookPagination;

  return (
    <SchemaForm
      schema={{
        fields: ({ valuesField, formProps }) => {
          const { intl } = formProps || {};

          return {
            createdTime: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'encounter.calendar.label.dateSent' }),
              propsWrapper: { xs: 1.5 },
              // maxDate: moment(valuesField.bookingDate).toDate(),
              // minDate: valuesField.bookingDate
              //   ? moment(valuesField.bookingDate).subtract(366, 'days').toDate()
              //   : moment().toDate(),
              // register: {
              //   validate: (value) => validateFromDate(value, valuesField.bookingDate, intl) || true,
              // },
              tooltipError: true,
            },
            bookingDate: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'encounterDate' }),
              propsWrapper: { xs: 1.5 },
              // maxDate: valuesField.createdTime
              //   ? moment(valuesField.createdTime).add(1, 'year').isAfter(moment())
              //     ? moment().toDate()
              //     : moment(valuesField.bookingDate).add(366, 'days').toDate()
              //   : null,
              // register: {
              //   validate: (value) => validateToDate(value, intl) || true,
              // },
              tooltipError: true,
            },
            customerName: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'sellingProduct.title.customerName' }),
              placeholder: intl.formatMessage({ id: 'encounter.calendar.placeHolder.enterCustomerName' }),
              propsWrapper: { xs: 1.5 },
            },
            phoneNumber: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'therapy.debtReport.label.phoneNumber' }),
              placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
              propsWrapper: { xs: 1.5 },
              inputType: 'number',
            },
            search: {
              type: 'submitButton',
              color: 'inherit',
              label: intl.formatMessage({ id: 'search' }),
              propsWrapper: { xs: 1 },
              style: { minWidth: 120, marginTop: 20 },
            },
            reset: {
              type: 'button',
              onClick: () => clearParams && clearParams(),
              color: 'inherit',
              label: intl.formatMessage({ id: 'reset' }),
              propsWrapper: { xs: 1 },
              style: { minWidth: 120, marginTop: 20 },
            },
            dateOutRange: {
              unregister: true,
              hidden:
                !valuesField.createdTime ||
                !valuesField.bookingDate ||
                moment(valuesField.createdTime).add(1, 'year').isAfter(moment(valuesField.bookingDate)),
              type: () => {
                return (
                  <Typography color="primary" style={{ color: 'red', marginTop: '-15px', marginBottom: '10px' }}>
                    {intl.formatMessage({ id: 'validation.outOfQuarter2' })}
                  </Typography>
                );
              },
            },
          };
        },
      }}
      onSubmit={setParams}
      formData={params}
      hideSubmitButton
    />
  );
};

export default DetailedFilterBox;
