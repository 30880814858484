import { z } from 'zod';

export const PatientContactInfoSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    type: z.string(),
    homePhone: z.string(),
    email: z.string(),
    relationship: z.string(),
    address: z.string(),
    village: z.string(),
    line: z.string(),
    rt: z.string(),
    rw: z.string(),
    province: z.string(),
    state: z.string(),
    district: z.string(),
    country: z.string(),
    gender: z.string(),
    postalCode: z.string(),
    mobilePhone: z.string(),
  })
  .partial();

export type PatientContactInfo = z.infer<typeof PatientContactInfoSchema>;
