import { Columns } from 'modules/common/component/TableCustom';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { IndicationRequest, Therapy } from 'modules/schema';
import moment from 'moment';

export const INDICATION_REQUEST_COLUMNS: Columns<IndicationRequest>[] = [
  {
    dataIndex: 'serviceName',
    title: 'dental.dentalDetail.printForm.serviceName.title',
  },
  {
    dataIndex: 'systemCategoryName',
    title: 'dental.dentalDetail.printForm.category.title',
  },
  {
    title: 'dental.dentalDetail.printForm.indicationDate.title',
    render: (record) => {
      return <div>{record.createdTime?.format(FE_DATE_FORMAT)}</div>;
    },
  },
];

export const INDICATION_THERAPY_COLUMNS: Columns<Therapy>[] = [
  {
    dataIndex: 'name',
    title: 'dental.dentalDetail.printForm.serviceName.title',
  },
  {
    title: 'dental.dentalDetail.printForm.indicationDate.title',
    render: (record) => {
      return <div>{moment(record.createdTime, BE_DATE_TIME_FORMAT)?.format(FE_DATE_FORMAT)}</div>;
    },
  },
];

export const PRESCRIPTION_COLUMNS: Columns[] = [
  {
    dataIndex: 'medicationName',
    title: 'dental.dentalDetail.printForm.medicationName.title',
  },
  {
    dataIndex: 'quantity',
    title: 'dental.dentalDetail.printForm.quantity.title',
  },
  {
    dataIndex: 'intendedRoute',
    title: 'dental.dentalDetail.printForm.intendedRoute.title',
  },
  {
    dataIndex: 'usage',
    title: 'dental.dentalDetail.printForm.usage.title',
  },
];

export const STATUS_AND_DIAGNOSIS_COLUMNS: Columns[] = [
  {
    dataIndex: 'position',
    title: 'dental.dentalDetail.printForm.position.title',
  },
  {
    dataIndex: 'dentalGroupDetail',
    title: 'dental.dentalDetail.printForm.diagnosisTarget.title',
  },
  {
    dataIndex: 'diagnosticStatus',
    title: 'dental.dentalDetail.printForm.diagnosis.title',
  },
  {
    dataIndex: 'createdDate',
    title: 'dental.dentalDetail.printForm.indicationDate.title',
  },
];
