import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import TextValue from '../../../../examDoctor/component/therapy/TherapySessionDetail/PrintTherapySessionDialog/PrintDialogContent/TextValue';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE, PAYMENT_PLAN } from '../../../../common/constants';
import { Therapy } from 'modules/schema';

interface Props {
  therapy: Therapy;
  compact: boolean;
}

const PatientInfo = ({ therapy, compact }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box flex={1.5}>
        <TextValue label="patientId" value={therapy?.patient?.code?.split('-')[1] || ''} labelWidth="110px" />
        <TextValue label="name" value={therapy?.patient?.name || ''} labelWidth="110px" />
        <TextValue label="telephone" value={therapy?.patient?.mobilePhone || ''} labelWidth="110px" />
        {!compact && (
          <TextValue
            label="category"
            value={PAYMENT_PLAN[therapy?.paymentPlan!].label}
            localizationValue
            labelWidth="110px"
          />
        )}
      </Box>
      <Box flex={1}>
        <TextValue
          labelWidth="90px"
          label="address"
          value={
            therapy?.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address || ''
          }
        />
        <TextValue
          label="birthdayShort"
          value={moment(therapy?.patient?.dob, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          labelWidth="90px"
        />
        <TextValue
          label="gender"
          value={therapy?.patient?.gender?.toLowerCase() || ''}
          localizationValue
          labelWidth="90px"
        />
      </Box>
    </Stack>
  );
};

export default PatientInfo;
