import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';
import { RECEIPT_TYPE } from 'modules/common/apiConstants';

export const SaleProfitReportSchema = z
  .object({
    id: z.number(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    code: z.string(),
    receiptType: createObjectKeysEnumSchema(RECEIPT_TYPE),
    customerName: z.string(),
    phoneNumber: z.string(),
    amountPaid: z.number(),
    profitTotal: z.number(),
    dateTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    value: z.number(),
  })
  .partial();

export type SaleProfitReport = z.infer<typeof SaleProfitReportSchema>;
