import { Typography, TypographyProps } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import { Property } from 'csstype';

interface Props {
  label: string;
  value?: string;
  localizationValue?: boolean;
  labelWidth?: Property.Width;
  labelProps?: TypographyProps;
  valueProps?: TypographyProps;
}

const TextValue = ({ label, labelWidth, value = ' ', localizationValue = false, labelProps, valueProps }: Props) => {
  return (
    <Stack direction="row">
      <Typography
        {...labelProps}
        sx={{
          fontSize: '16px',
          fontFamily: 'Roboto',
          fontStyle: 'normal',
          fontWeight: '500',
          lineHeight: '24px',
          letterSpacing: '0.15px',
          color: '#546E7A',
          flexShrink: '0',
          width: labelWidth,
          ...labelProps?.sx,
        }}
      >
        <FormattedMessage id={label} />
      </Typography>
      &nbsp;:&nbsp;
      {value && (
        <Typography
          {...valueProps}
          sx={{
            fontSize: '16px',
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            wordBreak: 'break-word',
            color: '#263238',
            wordWrap: 'break-word',
            ...valueProps?.sx,
          }}
        >
          {localizationValue ? <FormattedMessage id={value} /> : value}
        </Typography>
      )}
    </Stack>
  );
};

export default TextValue;
