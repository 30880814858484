import { useForm } from 'react-hook-form';
import EncounterDetailBlock from '../../EncounterDetailBlock';
import { EncounterDetailProps } from '../../type';
import { Stack } from '@mui/material';
import IconButton from '../../../IconButton';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import SelectInput from '../../../form/SelectInput';
import { useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { MedicalExaminationForm } from 'modules/schema/MedicalExaminationForm';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import ImageButton from './ImageButton';
import DiagnosticImagesButton from './DiagnosticImagesButton';
import { useDebouncedCallback } from 'use-debounce';
import { useContext, useEffect } from 'react';
import { EncounterContext } from '../../const';
import SunEditorInput from 'modules/common/component/form/SunEditorInput';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

interface Form {
  examinationForm: MedicalExaminationForm | null;
  clinicalExamination: string | undefined;
}

const ClinicalExamination = ({ encounter, readonly }: EncounterDetailProps) => {
  const { intl } = useGeneralHook();
  const form = useForm<Form>({
    defaultValues: { examinationForm: null, clinicalExamination: encounter?.clinicalExamination },
  });

  const clinicalExamination = form.getValues('clinicalExamination');

  const encounterContext = useContext(EncounterContext);
  useEffect(() => {
    form.reset({ clinicalExamination: encounter.clinicalExamination });
  }, [encounter.clinicalExamination, form]);
  const medicalExaminationFormMap = useSelector((state: AppState) => state.common.medicalExaminationForms);
  const updateClinicalExaminationMutate = useUpdateMutate({
    globalLoading: false,
    onSuccess: () => encounterContext?.setCounter(encounterContext.counter + 1),
  });
  const onUpdateClinicalExamination = useDebouncedCallback(async (value: MedicalExaminationForm) => {
    if (clinicalExamination !== value.symptom) {
      try {
        updateClinicalExaminationMutate({
          url: API_SERVER.encounter.updateInfoBasic(encounter.id!),
          method: 'PUT',
          data: {
            clinicalExamination: value.symptom ? value.symptom : undefined,
            differentialDiagnosis: value.differentialDiagnosis ? value.differentialDiagnosis : undefined,
            provisionalDiagnosis: value.provisionalDiagnosis ? value.provisionalDiagnosis : undefined,
            medicalHistory: value.medicalHistory ? value.medicalHistory : undefined,
            illnessesHistory: value.illnessesHistory ? value.illnessesHistory : undefined,
          },
        });
        form.reset({
          ...form.getValues(),
          clinicalExamination: value.symptom,
        });
      } catch (error) {
        form.reset();
      }
    }
  }, 2000);

  return (
    <EncounterDetailBlock
      title="encounter.encounterDetail.block.title.clinicalSymptoms"
      rightAction={
        <Stack direction="row" alignItems="center" justifyContent="center" gap="10px">
          <ImageButton encounter={encounter} readonly={readonly} />
          <DiagnosticImagesButton encounter={encounter} readonly={readonly} />
          {!readonly && (
            <IconButton icon={<KeyboardVoiceIcon />} sx={{ color: '#0962FF' }} tooltipTitle="common.title.record" />
          )}
        </Stack>
      }
    >
      <Stack direction="column" gap={1}>
        <SelectInput
          form={form}
          name="examinationForm"
          options={Object.values(medicalExaminationFormMap)}
          getValue="id"
          renderLabel="name"
          rawOptionLabel
          onChange={(selectedValue) => {
            const symptom = selectedValue as MedicalExaminationForm;
            form.setValue('examinationForm', null);
            form.setValue('clinicalExamination', symptom?.symptom);
            onUpdateClinicalExamination(symptom);
          }}
          disabled={readonly}
          clearButton
          hideError
          placeholder="encounter.encounterDetail.input.placeholder.medicalExamination"
        />
        <SunEditorInput
          name="clinicalExamination"
          placeholder={intl.formatMessage({ id: 'enter' })}
          readOnly={readonly}
          height="240px"
          onChange={(value) => onUpdateClinicalExamination({ symptom: value })}
          setContents={form.getValues('clinicalExamination')}
        />
      </Stack>
    </EncounterDetailBlock>
  );
};

export default ClinicalExamination;
