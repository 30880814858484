import { Box, Button, Paper, Popover, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import moment from 'moment';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { mappedBody } from 'modules/common/api';
import { BE_DATE_FORMAT, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import { axiosThunk } from 'modules/common/redux/axios';
import FilterBox from 'modules/admin/component/importInventory/FilterBox';
import FormDataDialog from 'modules/admin/component/importInventory/FormDataDialog';
import InventoryTable from 'modules/admin/component/importInventory/InventoryTable';
import { setLoading } from 'modules/common/redux/commonReducer';
import { formatDateFilterField } from 'modules/common/utils';
import { useFetch } from 'modules/common/hook';
import useId from '@mui/material/utils/useId';
import fileDownload from 'js-file-download';
import ImportFileDialog from 'modules/common/component/ImportFileDialog';
import FormDataDialogEdit from '../component/importInventory/FormDataDialogEdit';

interface Props {}

const ImportInventoryPage: FunctionComponent<Props> = (props: Props) => {
  const { dispatch, openSnackbar, intl, API_SERVER, confirmDialog } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const hookPagination = usePaginationHook();
  const [formData, setFormData] = useState<some | undefined>();
  const { params } = hookPagination;
  const [openPopupImportInventory, setOpenPopupImportInventory] = useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState<boolean>(false);
  const [showErrorFileMax, setShowErrorFileMax] = useState<boolean>(false);
  const [showErrorFileMaxContent, setShowErrorFileMaxContent] = useState<string>('');
  const [popoverElement, setPopoverElement] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(popoverElement);
  const id = useId();

  const onCreate = (e: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverElement(e.currentTarget);
  };

  const handleClose = () => {
    setPopoverElement(null);
  };

  const { data = [], revalidate } = useFetch(
    API_SERVER.importInventory.getList(formatDateFilterField(params, 'importDateFrom', 'importDateTo')),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  // Get inventory import by id
  const { data: inventoryImportDetail } = useFetch(API_SERVER.importInventory.getById(formData?.id), {
    globalLoading: true,
    enabled: formData?.id,
  });

  const createUpdateStatus = inventoryImportDetail && formData?.id;
  const tableStatus = !inventoryImportDetail && !formData?.id;

  // Get all data medication import
  const { data: medicationImportData = [], revalidate: medicationImportRevalidate } = useFetch(
    API_SERVER.medication.import(),
    {
      enabled: !!formData,
    },
  );
  window.onfocus = medicationImportRevalidate;

  // Get all data supplier
  const { data: supplierData } = useFetch(API_SERVER.supplier.get({ pageSize: 100, page: 0 }), {
    globalLoading: true,
  });

  const createUpdateForm = useCallback(
    async (value) => {
      let confirm: any = true;
      if (value?.id) {
        confirm = await promptConfirmation({
          warning: false,
          title: intl.formatMessage({ id: 'common.title.confirm' }),
          content: intl.formatMessage({ id: 'overwriteConfirm' }),
          cancelId: 'no',
          okId: 'yes',
        });
      }
      if (confirm) {
        delete value?.inventoryInfoData;
        dispatch(setLoading(true));
        await dispatch(
          axiosThunk({
            url: value?.id ? API_SERVER.importInventory?.update(value?.id) : API_SERVER.importInventory?.index(),
            method: value?.id ? 'put' : 'post',
            data: mappedBody({
              ...value,
              importDate: value?.importDate?.format(BE_DATE_FORMAT),
              inventoryImportDetails: value.inventoryImportDetails.map((v) => {
                return {
                  ...v,
                  expiredDate: moment(v.expiredDate).format(BE_DATE_FORMAT),
                  medicationId: v.medication?.id,
                  supplierId: v.medicationSupplier?.id,
                  lot: v?.lot?.lot || v?.lot,
                };
              }),
            }),
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
            revalidate();
            setFormData(undefined);
          })
          .catch((e: AxiosError<some>) => {
            if (e?.response)
              e?.response?.data?.errors?.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });
      }
      close();
      dispatch(setLoading(false));
    },
    [API_SERVER.importInventory, close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const onDelete = useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'common.title.confirm' }),
        content:
          value.status === 'IMPORTED'
            ? intl.formatMessage({ id: 'inventory.dialog.content.cancel.import' })
            : intl.formatMessage({ id: 'confirmDelete' }, { name: value.code }),
      });
      dispatch(setLoading(true));
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.importInventory?.update(value?.id),
            method: 'delete',
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
            revalidate();
            setFormData(undefined);
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });
      }
      close();
      dispatch(setLoading(false));
    },
    [API_SERVER.importInventory, close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const onProcessImport = useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: false,
        title: intl.formatMessage({ id: 'common.title.confirm' }),
        content: intl.formatMessage({ id: 'inventory.dialog.content.process.import' }, { code: value.code }),
      });
      if (confirm) {
        dispatch(setLoading(true));
        await dispatch(axiosThunk({ url: API_SERVER.importInventory?.processImport(value?.id), method: 'post' }))
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'common.message.success' }) });
            revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });
      }
      close();
      dispatch(setLoading(false));
    },
    [API_SERVER.importInventory, close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const openPopupImportInventoryFunc = () => {
    setOpenPopupImportInventory(true);
  };

  const closePopupImportInventoryFunc = () => {
    setOpenPopupImportInventory(false);
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setShowErrorFileMax(false);
  };

  const resetMessage = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setShowErrorFileMax(false);
  };

  const onShowErrorFileMax = async () => {
    setShowErrorFileMax(true);
  };

  // upload file inventory data
  const onUploadFileInventory = useCallback(
    async (file: File | null) => {
      resetMessage();
      dispatch(setLoading(true));
      try {
        const response = await dispatch(
          axiosThunk({
            url: API_SERVER.importInventory.uploadTemplate(),
            method: 'post',
            responseType: 'blob',
            headers: {
              'Content-type': 'multipart/form-data',
            },
            data: { data: file },
            timeout: 900000, // 15m
          }),
        );
        let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const effectiveFileName = fileName || 'import-exchange-unit.xlsx';
        dispatch(setLoading(false));
        if (response.data.size === 0) {
          setShowSuccessMessage(true);
          return revalidate();
        }
        setShowErrorMessage(true);
        fileDownload(response.data, effectiveFileName);
      } catch (e: any) {
        dispatch(setLoading(false));
        if (e.response?.status === 400) {
          const fr = new FileReader();
          fr.onload = function (e) {
            if (e.target?.result) {
              setShowErrorFileMaxContent(JSON.parse(e.target?.result.toString())?.errors[0]?.message);
            }
          };
          fr.readAsText(e?.response.data);
          return setShowErrorFileMax(true);
        }
        openSnackbar({ message: intl.formatMessage({ id: 'report.uploadFail' }), type: 'error' });
      }
    },
    [API_SERVER.importInventory, dispatch, intl, openSnackbar, revalidate],
  );

  // download file inventory data
  const onDownLoadFileInventory = async () => {
    resetMessage();
    dispatch(setLoading(true));
    await dispatch(
      axiosThunk({
        url: API_SERVER.importInventory.downloadTemplate,
        method: 'get',
        responseType: 'blob',
      }),
    )
      .then((response) => {
        let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const effectiveFileName = fileName || 'import-exchange-unit.xlsx';
        fileDownload(response.data, effectiveFileName);
        dispatch(setLoading(false));
      })
      .catch((e: AxiosError<some>) => {
        dispatch(setLoading(false));
        openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
      });
  };

  useEffect(() => {
    setFormData({
      readOnly: formData?.readOnly && formData.readOnly,
      ...inventoryImportDetail,
    });
  }, [formData?.readOnly, inventoryImportDetail]);

  return (
    <>
      {tableStatus && (
        <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
          <FilterBox
            hookPagination={hookPagination}
            onCreate={onCreate}
            pagin={
              <>
                <Typography variant="h6">
                  <FormattedMessage id="navMenu.importInventory" />
                </Typography>
              </>
            }
          />
          <InventoryTable
            data={data}
            onUpdate={setFormData}
            onDelete={onDelete}
            onProcessImport={onProcessImport}
            hookPagination={hookPagination}
          />
        </Paper>
      )}
      {createUpdateStatus && (
        <FormDataDialogEdit
          medicationImportData={medicationImportData || []}
          supplierData={supplierData?.content || []}
          formData={formData}
          onClose={async () => {
            if (formData?.readOnly) {
              setFormData(undefined);
            } else {
              const confirm = await promptConfirmation({
                warning: false,
                title: intl.formatMessage({ id: 'common.title.confirm' }),
                content: intl.formatMessage({ id: 'confirmClose' }),
                cancelId: 'no',
                okId: 'yes',
              });
              if (confirm) {
                setFormData(undefined);
              }
              close();
            }
          }}
          onSubmit={createUpdateForm}
        />
      )}
      {!formData?.id && formData?.updatedTime && (
        <FormDataDialog
          medicationImportData={medicationImportData || []}
          supplierData={supplierData?.content || []}
          formData={formData}
          open={!!formData}
          onClose={async () => {
            if (formData?.readOnly) {
              setFormData(undefined);
            } else {
              const confirm = await promptConfirmation({
                warning: false,
                title: intl.formatMessage({ id: 'common.title.confirm' }),
                content: intl.formatMessage({ id: 'confirmClose' }),
                cancelId: 'no',
                okId: 'yes',
              });
              if (confirm) {
                setFormData(undefined);
              }
              close();
            }
          }}
          onSubmit={createUpdateForm}
        />
      )}
      <Popover
        id={id}
        open={open}
        anchorEl={popoverElement}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Box width="200px">
          <Button
            variant="text"
            onClick={() => setFormData({ updatedTime: moment().format(BE_DATE_FORMAT) })}
            fullWidth
            sx={{ padding: '10px 20px', justifyContent: 'flex-start' }}
          >
            {intl.formatMessage({ id: 'inventory.label.importSimple' })}
          </Button>
          <Button
            variant="text"
            onClick={openPopupImportInventoryFunc}
            fullWidth
            sx={{ padding: '10px 20px', justifyContent: 'flex-start' }}
          >
            {intl.formatMessage({ id: 'inventory.label.importFile' })}
          </Button>
        </Box>
      </Popover>
      <ImportFileDialog
        open={openPopupImportInventory}
        onClose={closePopupImportInventoryFunc}
        onUploadFile={onUploadFileInventory}
        onDownLoadFile={onDownLoadFileInventory}
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
        showErrorFileMax={showErrorFileMax}
        onShowErrorFileMax={onShowErrorFileMax}
        showErrorFileMaxContent={showErrorFileMaxContent}
        title="inventory.title.importFile"
        description="inventory.description.importFile"
      />
    </>
  );
};
export default ImportInventoryPage;
