import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from '@mui/material';
import { get } from 'lodash';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { TableCustomCell, TableCustomRow } from 'modules/common/component/TableCustom/element';
import { some } from 'modules/common/constants';
import * as React from 'react';
import { DentalExam } from 'modules/schema';
import IndicationTable from './IndicationTable';

interface Props {
  dataRow: DentalExam;
  columns: some[];
}
const Row: React.FunctionComponent<Props> = (props) => {
  const { dataRow, columns } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableCustomRow>
        <TableCustomCell style={{ width: 50 }}>
          <IconButtonTitle
            aria-label="expand data"
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButtonTitle>
        </TableCustomCell>{' '}
        {columns.map((column, index) => {
          return (
            <TableCustomCell key={index} align={column.align}>
              {column.render ? column.render(dataRow) : get(dataRow, column.dataIndex)}
            </TableCustomCell>
          );
        })}
        <TableCustomCell>{dataRow.indicationRequestQuantity || 0}</TableCustomCell>
      </TableCustomRow>
      <TableCustomRow style={{ padding: 0 }}>
        <TableCustomCell style={{ padding: 0, border: 'none' }} colSpan={13}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <IndicationTable dentalExamId={dataRow.id!} />
          </Collapse>
        </TableCustomCell>
      </TableCustomRow>
    </>
  );
};
export default Row;
