import { Box } from '@mui/material';
import * as React from 'react';
import { IDENTITY_API_SERVER } from '../../../common/api';
import { some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { setLoading } from '../../../common/redux/commonReducer';
import { fetchThunk } from '../../../common/redux/thunk';
import SchemaForm from '../../../common/SchemaForm';
import { useFetch } from 'modules/common/hook';

interface Props {}

const ClinicsAdminInfo: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const onSubmit = React.useCallback(
    async (value: some) => {
      try {
        dispatch(setLoading(true));

        await dispatch(
          fetchThunk(IDENTITY_API_SERVER.management_agency.update(value), 'put', value, 'application/json-patch+json'),
        );

        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, openSnackbar],
  );

  const { data, isValidating } = useFetch(IDENTITY_API_SERVER.management_agency.getInfo());

  const formData = React.useMemo(() => {
    if (!data) {
      return null;
    }

    return {
      id: data.id,
      specializedManagement: data.specializedManagement || '',
      directManagement: data.directManagement || '',
      address: data.address,
    };
  }, [data]);

  React.useEffect(() => {
    dispatch(setLoading(isValidating));
  }, [dispatch, isValidating]);

  return (
    <Box width={320}>
      <SchemaForm
        hideSubmitButton
        onSubmit={onSubmit}
        formData={formData}
        schema={{
          fields: ({ formProps: { intl }, valuesField }) => {
            return {
              specializedManagement: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.managingOrg' }),
                placeholder: intl.formatMessage({ id: 'clinics.managingOrgEnter' }),
                register: {
                  required: true,
                },
              },
              directManagement: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.directManage' }),
                placeholder: intl.formatMessage({ id: 'clinics.directManageEnter' }),
                register: {
                  required: true,
                },
              },
              address: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.directManageAddress' }),
                placeholder: intl.formatMessage({ id: 'clinics.directManageAddressEnter' }),
                register: {
                  required: true,
                },
              },
              submit: {
                type: 'submitButton',
                fullWidth: true,
                label: intl.formatMessage({ id: 'update' }),
              },
            };
          },
        }}
      />
    </Box>
  );
};

export default ClinicsAdminInfo;
