import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { some } from '../../../../../common/constants';
import { INDICATION_REQUEST_PRINT_TYPE, INDICATION_WITHOUT_COMPACT_MODE_DETAIL_COLUMNS } from './constant';
import { groupBy } from 'lodash';
import TableCustom from '../../../../../common/component/TableCustom';
import * as React from 'react';

const TitleStyled = styled(Typography)({
  color: '#263238',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  '@media print': {
    fontSize: '20px',
  },
});

interface Props {
  indicationRequests: some[];
  printType: INDICATION_REQUEST_PRINT_TYPE;
}
const IndicationRequestTable = ({ indicationRequests }: Props) => {
  const requestName = Object.keys(groupBy(indicationRequests, 'systemCategoryName')).join(',');
  return (
    <Box>
      <TitleStyled>
        <FormattedMessage id="therapy.therapyDetail.indicationRequest.printForm.request.title" />
        &nbsp;:&nbsp;
        {requestName}
        <TableCustom
          containerProps={{
            sx: {
              '& th,tr,td': {
                padding: '5px 9px',
                backgroundColor: 'white',
                border: '1px solid #000',
                color: 'unset',
                fontFamily: 'Roboto',
                '& p': {
                  '@media print': {
                    fontSize: '20px',
                  },
                },
                '& span': {
                  '@media print': {
                    fontSize: '17px',
                  },
                },
              },
              width: '100%',
              marginTop: '10px',
            },
          }}
          dataSource={indicationRequests}
          columns={INDICATION_WITHOUT_COMPACT_MODE_DETAIL_COLUMNS}
        />
      </TitleStyled>
    </Box>
  );
};

export default IndicationRequestTable;
