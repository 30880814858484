import { Box, Typography } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../../common/api';
import DialogCustom from '../../../common/component/DialogCustom';
import { BE_DATE_FORMAT, FE_TIME_FORMAT, some } from '../../../common/constants';
import SchemaForm from '../../../common/SchemaForm';
import { schema } from './schema';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import { SCHEDULE_RANGE_UNIT, ScheduleType } from './constants';
import { mergeDateAndTime } from 'modules/common/utils';
import { Appointment } from 'modules/schema';
import { useCreateMutate, useUpdateMutate } from 'modules/common/hook/useMutate';

const formateScheduleSetting = (
  autoSchedule: boolean,
  amountOfAppointment: number,
  scheduleType: ScheduleType,
  byRangeNumber: number,
  byRangeUnit: keyof typeof SCHEDULE_RANGE_UNIT,
  byDayOfMonthNumber: number,
  currentDateAppointment: string,
) => {
  if (!autoSchedule) return [currentDateAppointment];
  const date = moment(currentDateAppointment, BE_DATE_FORMAT);
  let nextAppoitments;
  if (scheduleType === 'byRange') {
    nextAppoitments = [...Array(amountOfAppointment).keys()].map((_, index) =>
      date
        .clone()
        .add(byRangeNumber * (index + 1), byRangeUnit)
        .format(BE_DATE_FORMAT),
    );
  } else {
    nextAppoitments = [...Array(amountOfAppointment).keys()].map((_, index) => {
      const temp = date.clone().add(1 + index, 'month');
      if (temp.daysInMonth() < byDayOfMonthNumber) {
        return temp.set('date', temp.daysInMonth()).format(BE_DATE_FORMAT);
      }
      return temp.set('date', byDayOfMonthNumber).format(BE_DATE_FORMAT);
    });
  }
  return [date.format(BE_DATE_FORMAT), ...nextAppoitments];
};

interface Props {
  formData?: Appointment;
  open: boolean;
  close(): void;
  revalidateAll?: () => void;
  disableSearch?: boolean;
  examDoctorsNurses?: some[];
  setSelectedAppointment?: (appointment: Pick<AppointmentModel, 'id'> | null) => void;
  type?: string;
}

const CalendarNewAppointmentForm: React.FunctionComponent<Props> = ({
  close,
  formData,
  revalidateAll,
  disableSearch,
  examDoctorsNurses,
  setSelectedAppointment,
  type,
}) => {
  const updateAppointmentMutate = useUpdateMutate({
    successMessage: 'updateAppointment.success',
    failedMessage: 'updateAppointment.failure',
    onSuccess: () => {
      revalidateAll && revalidateAll();
      close();
    },
  });

  const createAppointmentMutate = useCreateMutate({
    successMessage: 'createAppointment.success',
    failedMessage: 'createAppointment.failure',
    onSuccess: () => {
      revalidateAll && revalidateAll();
      close();
    },
  });

  const onSubmitForm = (data: some) => {
    const dates = formateScheduleSetting(
      data.autoSchedule,
      parseInt(data.amountOfAppointment),
      data.scheduleType,
      parseInt(data.byRangeNumber),
      data.byRangeUnit,
      parseInt(data.byDayOfMonthDay),
      data.date,
    );
    const formatFormData = {
      data: dates?.map((date) => ({
        startTime: mergeDateAndTime(data?.startHour, date),
        endTime: mergeDateAndTime(data?.endHour, date),
        picUserId: data?.picUserId,
        picName: examDoctorsNurses?.find((user) => user.id === data?.picUserId)?.employeeFullName,
        reason: data?.reason,
        name: data?.name,
        mobilePhone: data?.mobilePhone,
        quantity: 1,
        address: data?.address,
        patientId: data?.referenceId,
        gender: data?.gender,
        dob: moment(data?.dob).format(BE_DATE_FORMAT),
      })),
    };

    const formatFormDataGroup = {
      data: dates?.map((date) => ({
        startTime: mergeDateAndTime(data?.startHour, date),
        endTime: mergeDateAndTime(data?.endHour, date),
        picUserId: data?.picUserId,
        picName: examDoctorsNurses?.find((user) => user.id === data?.picUserId)?.employeeFullName,
        reason: data?.reason,
        name: data?.name,
        mobilePhone: data?.mobilePhone,
        quantity: data?.quantity,
        address: data?.address,
        appointmentGroupId: data?.referenceId,
        gender: data?.gender,
        dob: moment(data?.dob).format(BE_DATE_FORMAT),
        isGroup: true,
      })),
    };

    if (data?.id) {
      return updateAppointmentMutate({
        url: API_SERVER.appointment.update(data?.id),
        data: type && type !== '' ? formatFormDataGroup?.data?.[0] : formatFormData?.data?.[0],
        method: 'put',
      });
    }
    createAppointmentMutate({
      url: API_SERVER.appointment.createMultiple(),
      data: type && type !== '' ? formatFormDataGroup : formatFormData,
      method: 'post',
    });
  };

  return (
    <SchemaForm
      schema={schema}
      onSubmit={onSubmitForm}
      onCancel={close}
      submitLabel={<FormattedMessage id={formData?.id ? 'save' : 'calendar.book'} />}
      formData={{
        ...formData,
        scheduletype: 'byRange',
        byRangeNumber: 1,
        byDayOfMonthDay: 1,
        dob: formData?.dob ? moment(formData?.dob, BE_DATE_FORMAT) : null,
        type: type,
      }}
      disableSearch={disableSearch || formData?.id}
      formId="formId"
      examDoctorsNurses={examDoctorsNurses}
    />
  );
};

const FormAppointmentDialog: React.FunctionComponent<Props> = (props) => {
  const { open, close, formData } = props;

  const formatedFormData = {
    ...formData,
    date: formData?.startTime?.format(BE_DATE_FORMAT),
    startHour: formData?.startTime?.format(FE_TIME_FORMAT),
    endHour: formData?.endTime?.format(FE_TIME_FORMAT),
    dob: formData?.dob,
  };

  return (
    <DialogCustom
      open={open}
      maxWidth="md"
      keepMounted={false}
      onClose={close}
      PaperProps={{
        style: { width: 560 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'calendar.update' : 'calendar.add'} />
        </Typography>
      }
    >
      <Box p={2}>
        <CalendarNewAppointmentForm {...props} formData={formatedFormData} />
      </Box>
    </DialogCustom>
  );
};

export default React.memo(FormAppointmentDialog);
