import Print from '@mui/icons-material/Print';
import { Box, Button, FormControlLabel, Switch } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import PrintContent from './PrintContent';
import { Encounter } from 'modules/schema';
import DialogCustom from 'modules/common/component/DialogCustom';

const COMPACT_TYPE = 'MEDICAL_PRINT_COMPACT_TYPE';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
      '& th,tr,td': {
        backgroundColor: 'unset',
      },
    },
  },
});

interface Props {
  open: boolean;
  onClose?: () => void;
  encounter?: Encounter;
}

const PrintMedicalFormDialog = ({ onClose, open, encounter }: Props) => {
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [compact, setCompact] = React.useState(localStorage.getItem(COMPACT_TYPE) === 'compact' || false);

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: '80vw' },
      }}
      title={'medicalRecord'}
      footer={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={compact}
                onChange={(e) => {
                  setCompact(e.target.checked);
                  localStorage.setItem(COMPACT_TYPE, e.target.checked ? 'compact' : 'expansion');
                }}
              />
            }
            label={<FormattedMessage id="printCompact" />}
          />

          <ReactToPrint
            documentTitle=""
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="encounterDetail.printMedicalForm" />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />
        </Box>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <PrintContent compact={compact} encounter={encounter} />
      </Box>
    </DialogCustom>
  );
};

export default PrintMedicalFormDialog;
