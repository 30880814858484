import { Stack } from '@mui/material';
import EncounterDetailBlock from 'modules/common/component/Encounter/EncounterDetailBlock';
import PatientInfoBlock from 'modules/common/component/Encounter/block/PatientInfo/PatientInfoBlock';
import VitalSignBlock from 'modules/common/component/Encounter/block/VitalSign/VitalSignBlock';
import MedicalHistoryBlock from 'modules/common/component/Encounter/block/MedicalHistoryBlock';
import { Encounter } from 'modules/schema';

interface GeneralInfoSessionProps {
  encounter: Encounter;
  readonly: boolean;
}

const GeneralInfoSession = ({ encounter, readonly }: GeneralInfoSessionProps) => {
  return (
    <Stack direction="row" justifyContent="center" gap="10px">
      <EncounterDetailBlock title="encounter.encounterDetail.block.title.generalInfo">
        <PatientInfoBlock encounter={encounter} />
      </EncounterDetailBlock>
      <EncounterDetailBlock title="encounter.encounterDetail.block.title.vital">
        <VitalSignBlock encounter={encounter} readonly={readonly} />
      </EncounterDetailBlock>
      <EncounterDetailBlock title="encounter.encounterDetail.block.title.medicalHistory">
        <MedicalHistoryBlock encounter={encounter} readonly={readonly} />
      </EncounterDetailBlock>
    </Stack>
  );
};

export default GeneralInfoSession;
