import { some } from '../../../../common/constants';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../../../redux/reducer';
import { AnyAction } from 'redux';
import { fetchThunk } from '../../../../common/redux/thunk';
import { API_SERVER } from '../../../../common/api';

export async function sendToBackend(
  data: some[],
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  appState: AppState,
  categoryId: number,
) {
  await dispatch(fetchThunk(API_SERVER.service.import(categoryId), 'post', { data }, 'application/json-patch+json'));
}
