import { Add } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Box, Button, ButtonProps, Grid, GridProps, IconButton, Paper, PaperProps, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { memo, useMemo } from 'react';
import { FieldValues, useFieldArray, UseFieldArrayReturn, useFormContext, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { some } from '../../../constants';
import useGeneralHook from '../../../hook/useGeneralHook';
import SchemaView from '../../SchemaView';
import { ISchemaForm, mergeFieldName } from '../../utils';

const useStyles = makeStyles(() => ({
  paper: {
    padding: 16,
    position: 'relative',
  },
}));

export interface PropsArrayElement extends some {
  schema?: ISchemaForm;
  disableCloseBtn?: (item: some, index: number) => boolean | boolean;
  label?: React.ReactNode;
  paper?: boolean;
  shouldUnregister?: boolean;
  paperProps?: PaperProps;
  gridItemProps?: GridProps;
  gridContainerProps?: GridProps;
  propsGridContainer?: PaperProps;
  paperItemProps?: PaperProps;
  appendButtonProps?: ButtonProps;
  labelAdd?: React.ReactNode;
  hiddenLabelAdd?: boolean;
  onChange?: (value) => void;
  hideInitialElement?: boolean;
  readOnly?: boolean;
  hiddenNodata?: boolean;
  defaultData?: some;
  keyName?: string;
  addable?: string;
  rawComponent?: (params: {
    formProps: some;
    methods: UseFormReturn<FieldValues, object>;
    name: string;
    methodsArray: UseFieldArrayReturn<FieldValues, any, 'id'>;
  }) => React.ReactElement | any;
}

interface Props extends PropsArrayElement {
  name: any;
}

function ArrayElement(props: Props) {
  const {
    name,
    schema,
    disableCloseBtn,
    label,
    paper,
    paperProps,
    gridItemProps,
    gridContainerProps,
    paperItemProps,
    appendButtonProps,
    labelAdd,
    hiddenLabelAdd = false,
    addable = true,
    onChange,
    shouldUnregister,
    rawComponent,
    propsGridContainer,
    hideInitialElement,
    hiddenNodata,
    defaultData,
    keyName,
    ...rest
  } = props;
  const generalHook = useGeneralHook();
  const classes = useStyles();
  const methods = useFormContext();
  const { control } = methods;
  const methodsArray = useFieldArray({
    control,
    name,
    shouldUnregister: !!shouldUnregister,
    keyName: keyName,
  });
  const { fields, append, remove } = methodsArray;

  React.useEffect(() => {
    onChange && onChange(fields);
  }, [fields, onChange]);

  React.useLayoutEffect(() => {
    if (fields.length === 0 && !hideInitialElement) {
      append({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contentRender = useMemo(() => {
    let content = !!rawComponent ? (
      typeof rawComponent === 'function' ? (
        rawComponent({
          formProps: { ...generalHook, ...rest },
          methods,
          name,
          methodsArray,
        })
      ) : (
        React.createElement(rawComponent as any, {
          formProps: { ...generalHook, ...rest },
          methods,
          name,
          methodsArray,
        })
      )
    ) : (
      <>
        {label && (
          <Box marginBottom={1.5}>
            <Typography variant="h6">{label}</Typography>
          </Box>
        )}
        <Grid container spacing={2} wrap="wrap" {...gridContainerProps}>
          {fields?.length > 0
            ? fields.map((item: some, index: number) => {
                const disabled = typeof disableCloseBtn === 'function' ? disableCloseBtn(item, index) : disableCloseBtn;
                return (
                  <Grid key={item.id} item xs={12} {...gridItemProps}>
                    <Paper className={classes.paper} variant="outlined" {...paperItemProps}>
                      {!disabled && (
                        <IconButton
                          size="small"
                          onClick={() => {
                            remove(index);
                          }}
                          style={{
                            position: 'absolute',
                            zIndex: 999,
                            top: 4,
                            right: 4,
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                      {schema && (
                        <SchemaView
                          fieldName={mergeFieldName({ name, index })}
                          schema={schema}
                          propsGridContainer={propsGridContainer}
                          formProps={{
                            ...generalHook,
                            ...rest,
                            arrayData: { value: item, index: index },
                            methodsArray,
                          }}
                        />
                      )}
                    </Paper>
                  </Grid>
                );
              })
            : !hiddenNodata && (
                <Grid xs={12}>
                  <Box display={'flex'} alignItems={'center'} justifyContent={'center'} p={2}>
                    <FormattedMessage id="noData" />
                  </Box>
                </Grid>
              )}
        </Grid>

        {!rest.readOnly && addable && hiddenLabelAdd === false && (
          <Grid xs={12}>
            <Button
              variant="text"
              color="primary"
              style={{ marginTop: 8 }}
              startIcon={<Add />}
              {...appendButtonProps}
              onClick={() => {
                append(defaultData || {});
              }}
            >
              {labelAdd || <FormattedMessage id="append" />}
            </Button>
          </Grid>
        )}
      </>
    );
    if (paper) {
      content = (
        <Paper className={classes.paper} {...paperProps}>
          {content}
        </Paper>
      );
    }
    return content;
  }, [
    addable,
    append,
    appendButtonProps,
    classes.paper,
    defaultData,
    disableCloseBtn,
    fields,
    generalHook,
    gridContainerProps,
    gridItemProps,
    hiddenLabelAdd,
    hiddenNodata,
    label,
    labelAdd,
    methods,
    methodsArray,
    name,
    paper,
    paperItemProps,
    paperProps,
    propsGridContainer,
    rawComponent,
    remove,
    rest,
    schema,
  ]);

  return contentRender;
}

export default memo(ArrayElement);
