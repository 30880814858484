import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import { ISchemaFields, ISchemaForm, SchemaType } from '../../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl, state, setState } = formProps || {};

  return {
    password: {
      type: 'text-field',
      inputProps: {
        form: {
          autocomplete: 'off',
        },
      },
      inputType: state?.showNewPassword ? 'text' : 'password',
      label: intl.formatMessage({ id: 'newPassword' }),
      placeholder: intl.formatMessage({ id: 'newPasswordEnter' }),
      register: {
        validate: {
          required: (value) => (!value ? intl.formatMessage({ id: 'required' }) : true),
          lessThan: (value) => (value.length < 8 || value.length > 24 ? intl.formatMessage({ id: 'minLength' }) : true),
        },
      },
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setState({ showNewPassword: !state.showNewPassword })}
            >
              {state.showNewPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ),
        style: { borderRadius: 4, overflow: 'hidden' },
      },
    },
    rePassword: {
      type: 'text-field',
      inputType: state?.showRePassword ? 'text' : 'password',
      label: intl.formatMessage({ id: 'rePassword' }),
      placeholder: intl.formatMessage({ id: 'rePassword.enter' }),
      inputProps: {
        form: {
          autocomplete: 'off',
        },
      },
      register: {
        validate: {
          required: (value) => (!value ? intl.formatMessage({ id: 'required' }) : true),
          lessThan: (value) => (value.length < 8 || value.length > 24 ? intl.formatMessage({ id: 'minLength' }) : true),
          positive: (value) =>
            valuesField?.password !== value && valuesField?.password
              ? intl.formatMessage({ id: 'notMatchingPassword' })
              : true,
        },
      },
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setState({ showRePassword: !state.showRePassword })}
            >
              {state.showRePassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ),
        style: { borderRadius: 4, overflow: 'hidden' },
      },
    },
  } as SchemaType;
};

export const schemaFormAdminAccount: ISchemaForm = {
  fields: fieldSchema,
};
