import { ExpandLess } from '@mui/icons-material';
import { Collapse, IconButton, Skeleton, Typography } from '@mui/material';
import { Box } from '@mui/system';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, FE_DATE_TIME_FORMAT, PAYMENT_PLAN } from '../../../common/constants';
import { Encounter } from 'modules/schema';
import { GENDER, PATIENT_ADDRESS_TYPE } from 'modules/common/apiConstants';
import EditPICDialog from 'modules/common/component/EditPICDialog';
import { useDialog, useUpdateMutate, useUserRoles } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { mutate } from 'swr';
import { ROUTES } from 'modules/layout/router';

interface IBasicEncounterInfoProps {
  encounter?: Encounter;
  editable?: boolean;
}

const BasicEncounterInfo: React.FunctionComponent<IBasicEncounterInfoProps> = ({ encounter, editable }) => {
  const [expand, setExpand] = React.useState(false);
  const dob = moment(encounter?.patient?.dob, BE_DATE_FORMAT);
  const [openChangePICDialog, onOpenChangePICDialog, onCloseChangePICDialog] = useDialog();
  const doctors = useUserRoles('DOCTOR', 'GENERAL_DOCTOR');
  const onChangePICMutate = useUpdateMutate({
    onSuccess: () => {
      onCloseChangePICDialog();
      mutate(API_SERVER.encounter.get(encounter?.id!).url);
    },
  });

  const handleClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.preventDefault();
    duplicateTab(ROUTES.patientProfile.gen(encounter?.patientId!));
  };

  const duplicateTab = (url: string) => {
    const newTab = window.open('', '_blank');
    if (newTab) {
      newTab.location.href = url;
    }
  };
  return (
    <>
      <Box display={'flex'} gap={2}>
        <Typography variant="subtitle1" color="primary" noWrap>
          {encounter ? encounter.patient?.name : <Skeleton width={100} />}
        </Typography>
        <Typography variant="body2" color="textSecondary" noWrap>
          <FormattedMessage id="birthdayShort" />
          :&nbsp;
          <Typography variant="subtitle2" color="textPrimary" component={'span'}>
            {encounter ? dob.format(FE_DATE_FORMAT) : <Skeleton width={100} />}
          </Typography>
        </Typography>
        <Typography variant="body2" color="textSecondary" noWrap>
          <FormattedMessage id="age_" />
          :&nbsp;
          <Typography variant="subtitle2" color="textPrimary" component={'span'}>
            {encounter ? (
              encounter?.patient?.dob &&
              `${moment().diff(dob, 'years', false)} (${moment().diff(dob, 'months', false) % 12})`
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" color="textSecondary" noWrap>
          <FormattedMessage id="gender" />
          :&nbsp;
          <Typography variant="subtitle2" color="textPrimary" component={'span'}>
            {encounter ? (
              <FormattedMessage id={GENDER[encounter?.patient?.gender!]?.label || ' '} />
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Typography>
        <Typography variant="body2" color="textSecondary" noWrap>
          <FormattedMessage id="examDoctor" />
          :&nbsp;
          <Typography
            variant="subtitle2"
            color="textPrimary"
            component={'span'}
            onClick={onOpenChangePICDialog}
            sx={{
              textDecoration: editable ? 'underline' : 'unset',
              color: editable ? '#0052E0' : 'unset',
              cursor: editable ? 'pointer' : 's-resize',
            }}
          >
            {encounter ? encounter.picName : <Skeleton width={100} />}
          </Typography>
        </Typography>
        <Typography variant="body2" color="textSecondary" noWrap>
          <FormattedMessage id="encounterId" />
          :&nbsp;
          <Typography variant="subtitle2" color="textPrimary" component={'span'}>
            {encounter ? encounter.code : <Skeleton width={100} />}
          </Typography>
        </Typography>
        <Typography variant="body2" color="textSecondary" noWrap>
          <FormattedMessage id="patientId" />
          :&nbsp;
          <Typography
            variant="subtitle2"
            color="textPrimary"
            component={'span'}
            onClick={handleClick}
            sx={{ textDecoration: 'underline', color: '#0052E0', cursor: 'pointer' }}
          >
            {encounter ? encounter?.patient?.code?.split('-')[1] : <Skeleton width={100} />}
          </Typography>
        </Typography>
        <Box flex={1} />
        <IconButton sx={{ p: 0 }} onClick={() => setExpand((old) => !old)}>
          <ExpandLess
            style={{
              transition: '0.2s all',
              transform: expand ? 'rotate(0deg)' : 'rotate(180deg)',
            }}
          />
        </IconButton>
      </Box>
      <Collapse in={expand}>
        <Box display={'flex'} gap={2}>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="serviceName" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {encounter ? encounter.serviceName : <Skeleton width={100} />}
            </Typography>
          </Typography>{' '}
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="encounterHistory.day" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {encounter ? encounter?.startTime?.format(FE_DATE_TIME_FORMAT) : <Skeleton width={100} />}
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="telephone" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {encounter ? encounter.patient?.mobilePhone : <Skeleton width={100} />}
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="address" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {encounter ? (
                encounter.patient?.patientAddressList?.find((ele) => ele.type === PATIENT_ADDRESS_TYPE.HOME)?.address
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="medicalPrint.room" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {encounter ? encounter.physicsRoom?.name : <Skeleton width={100} />}
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="category" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              <FormattedMessage id={PAYMENT_PLAN[encounter?.paymentPlan!]?.label || ' '} />
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="reason" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {encounter ? encounter.reason : <Skeleton width={100} />}
            </Typography>
          </Typography>
        </Box>
        {encounter && (
          <EditPICDialog
            currentPICId={encounter?.picUserId!}
            doctors={doctors}
            onClose={onCloseChangePICDialog}
            open={openChangePICDialog}
            dialogTitleKey="encounter.encounterDetail.editPIC.dialog.title"
            onUpdatePIC={(data) =>
              onChangePICMutate({
                url: API_SERVER.encounter.changePIC(encounter.id!),
                method: 'PUT',
                data: data,
              })
            }
          />
        )}
      </Collapse>
    </>
  );
};

export default BasicEncounterInfo;
