import { styled } from '@mui/system';
import { Box, Divider, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import PatientInfo from './PatientInfo';
import { some } from '../../../../../common/constants';
import TherapyInfo from './TherapyInfo';
import TherapyPayment from './TherapyPayment';
import Summary from './Summary';
import DoctorSign from './DoctorSign';

interface Props {
  therapyDetail: some;
  paymentDetail: some;
}

const PrintFormTitle = styled(Typography)({
  color: '#263238',
  textAlign: 'center',
  fontSize: '28px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '36px',
  textTransform: 'uppercase',
});

const DividerStyled = styled(Divider)({
  marginTop: '20px',
  marginBottom: '10px',
});
const Body = ({ therapyDetail, paymentDetail }: Props) => {
  return (
    <Box sx={{ padding: '9px 24px 48px 24px' }}>
      <Stack marginBottom={3}>
        <PrintFormTitle>
          <FormattedMessage id="cashier.printTableBill.title" />
        </PrintFormTitle>
      </Stack>
      <PatientInfo therapy={therapyDetail} />
      <DividerStyled />
      <TherapyInfo therapy={therapyDetail} />
      <DividerStyled />
      <TherapyPayment paymentDetail={paymentDetail} />
      <Summary paymentDetail={paymentDetail} />
      <DoctorSign />
    </Box>
  );
};

export default Body;
