export const LICENSE_TYPES = {
  own: 'OWN',
  collaborate: 'COLLABORATE',
};

export const DRUG = {
  normal: { value: 'normal', label: 'pharmacy.normalType' },
  narcotic: { value: 'narcotic', label: 'pharmacy.narcoticType' },
  psychotropic: { value: 'psychotropic-precursor', label: 'pharmacy.psychotropicType' },
};

export const DRUG_OPTIONS = [DRUG.narcotic, DRUG.psychotropic, DRUG.normal];

export const MEDI_LICENSE_TYPE = [
  { label: 'basic', value: 'BASIC' },
  { label: 'standard', value: 'STANDARD' },
];

export const EMPLOYEE_STATUS = {
  active: 'ACTIVE',
  inactive: 'INACTIVE',
};

export const RECEIPT_ID = 'receiptId';
