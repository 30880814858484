import { GridOn, Add, SaveAlt, Search } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Collapse, InputAdornment } from '@mui/material';
import React from 'react';
import { ILayoutFields, ISchemaFields, IUiFields } from '../../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, clearParams, state, setState, onCreateHandle, onExportHandle, onImportHandle, hideExport } =
    formProps || {};

  return {
    export: {
      type: 'button',
      startIcon: <GridOn />,
      color: 'inherit',
      onClick: onExportHandle,
      label: intl.formatMessage({ id: 'patient.management.filterForm.title.export' }),
      propsWrapper: { xs: undefined },
      hidden: !hideExport,
    },
    import: {
      type: 'button',
      startIcon: <SaveAlt />,
      color: 'inherit',

      onClick: onImportHandle,
      label: intl.formatMessage({ id: 'patient.management.filterForm.title.import' }),
      propsWrapper: { xs: undefined },
    },
    create: {
      type: 'button',
      startIcon: <Add />,
      onClick: onCreateHandle,
      label: intl.formatMessage({ id: 'patient.management.filterForm.title.create' }),
      propsWrapper: { xs: undefined },
    },
    phoneNumber: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'patient.management.filterForm.placeholder.tel' }),
      noHelperText: true,
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Search color="inherit" />
          </InputAdornment>
        ),
      },
      showClearBtn: true,
      propsWrapper: { xs: 3 },
    },
    search: {
      type: 'submitButton',
      color: 'inherit',
      label: intl.formatMessage({ id: 'search' }),
      propsWrapper: { xs: undefined },
    },
    expand: {
      type: 'button',
      endIcon: (
        <ExpandLessIcon
          style={{
            transition: '0.2s all',
            transform: state?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      ),
      color: 'primary',
      variant: 'text',
      onClick: () => setState({ expand: !state.expand }),
      label: intl.formatMessage({ id: 'patient.management.filterForm.title.expand' }),
      propsWrapper: { xs: undefined },
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'patient.management.filterForm.title.patientName' }),
      placeholder: intl.formatMessage({ id: 'patient.management.filterForm.placeholder.patientName' }),
      noHelperText: true,
      propsWrapper: { xs: 2.4 },
    },
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'patient.management.filterForm.title.patientCode' }),
      placeholder: intl.formatMessage({ id: 'patient.management.filterForm.placeholder.patientCode' }),
      noHelperText: true,
      propsWrapper: { xs: 2.4 },
    },
    yearOfBirth: {
      type: 'datePicker',
      views: ['year'],
      label: intl.formatMessage({ id: 'patient.management.filterForm.title.birthday' }),
      placeholder: intl.formatMessage({ id: 'patient.management.filterForm.placeholder.birthday' }),
      noHelperText: true,
      propsWrapper: { xs: 1 },
      inputFormat: 'yyyy',
      mask: '____',
      formatDate: 'yyyy',
    },
    identityCode: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'patient.management.filterForm.title.CCCD' }),
      placeholder: intl.formatMessage({ id: 'patient.management.filterForm.placeholder.CCCD' }),
      noHelperText: true,
      propsWrapper: { xs: 2.4 },
    },
    reset: {
      type: 'button',
      onClick: () => clearParams && clearParams(),
      color: 'inherit',
      label: intl.formatMessage({ id: 'patient.management.filterForm.title.reset' }),
      propsWrapper: { xs: undefined },
      style: { minWidth: 120, marginTop: 20 },
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'layout',
      propsGridContainer: { spacing: 1, justifyContent: 'flex-end' },
      fields: ['export', 'import', 'create'],
    },
    {
      id: 'expand',
      fields: ['phoneNumber', 'search', 'expand'],
    },

    { id: 'search', fields: ['name', 'code', 'yearOfBirth', 'identityCode', 'reset'] },
  ];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement, formProps } = props;
  const { state } = formProps;
  return (
    <>
      <Box display="flex">
        <Box flex={1.5}>{formProps?.pagin}</Box>
        <Box flex={1}>{listElement[0]}</Box>
      </Box>
      <Box sx={{ marginTop: '10px', '& .MuiGrid-container': { justifyContent: 'end' } }}>{listElement[1]}</Box>
      <Collapse in={state?.expand} style={{ marginTop: 16 }}>
        {listElement[2]}
      </Collapse>
    </>
  );
};

const schema = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
  changeDataBeforeSubmit: (data) => ({
    ...data,
    yearOfBirth: data.yearOfBirth || null,
  }),
};

export default schema;
