import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useDiagnosisDialogSelector } from './state';
import { FormattedMessage } from 'react-intl';

const DialogAction = () => {
  const onCloseDialog = useDiagnosisDialogSelector.use.onCloseDialog();
  return (
    <Stack direction="row" justifyContent="flex-end" gap={2} marginTop={3}>
      <Button sx={{ width: 100 }} color="inherit" onClick={onCloseDialog}>
        <FormattedMessage id="dental.dentalDetail.statusAndDiagnosis.button.cancel.label" />
      </Button>
      <Button sx={{ width: 100 }} type="submit">
        <FormattedMessage id="dental.dentalDetail.statusAndDiagnosis.button.update.label" />
      </Button>
    </Stack>
  );
};

export default DialogAction;
