export const EXAM_TARGETS = {
  CHILD: {
    value: 'CHILD',
    label: 'dental.dentalDetail.odontogram.label.children',
  },
  ADULT: {
    value: 'ADULT',
    label: 'dental.dentalDetail.odontogram.label.adult',
  },
} as const;

export const DENTAL_GROUP = {
  TOOTH: {
    value: 'TOOTH',
    label: 'dental.dentalDetail.statusAndDiagnosis.dentalGroup.teeth.label',
  },
  GUMS: {
    value: 'GUMS',
    label: 'dental.dentalDetail.statusAndDiagnosis.dentalGroup.gums.label',
  },
  JAW: {
    value: 'JAW',
    label: 'dental.dentalDetail.statusAndDiagnosis.dentalGroup.jaw.label',
  },
  MOUTH: {
    value: 'MOUTH',
    label: 'dental.dentalDetail.statusAndDiagnosis.dentalGroup.mouth.label',
  },
  TONGUE: {
    value: 'TONGUE',
    label: 'dental.dentalDetail.statusAndDiagnosis.dentalGroup.tougue.label',
  },
  PERIODONTAL: {
    value: 'PERIODONTAL',
    label: 'dental.dentalDetail.statusAndDiagnosis.dentalGroup.periodontal.label',
  },
  GENERAL: {
    value: 'GENERAL',
    label: 'dental.dentalDetail.statusAndDiagnosis.dentalGroup.general.label',
  },
} as const;

export const DENTAL_GROUP_DETAIL = {
  TOOTH_OUTSIDE: {
    value: 'TOOTH_OUTSIDE',
    label: 'dental.dentalDetail.statusAndDiagnosis.groupDetail.teethOuter.label',
    target: DENTAL_GROUP.TOOTH,
  },
  TOOTH_INSIDE: {
    value: 'TOOTH_INSIDE',
    label: 'dental.dentalDetail.statusAndDiagnosis.groupDetail.teethInside.label',
    target: DENTAL_GROUP.TOOTH,
  },
  TOOTH_OCCLUSAL: {
    value: 'TOOTH_OCCLUSAL',
    label: 'dental.dentalDetail.statusAndDiagnosis.groupDetail.teethChewing.label',
    target: DENTAL_GROUP.TOOTH,
  },
  TOOTH_ALL: {
    value: 'TOOTH_ALL',
    label: 'dental.dentalDetail.statusAndDiagnosis.groupDetail.teethAll.label',
    target: DENTAL_GROUP.TOOTH,
  },
  JAW_UPPER: {
    value: 'JAW_UPPER',
    label: 'dental.dentalDetail.statusAndDiagnosis.groupDetail.jawUpper.label',
    target: DENTAL_GROUP.JAW,
  },
  JAW_BOTTOM: {
    value: 'JAW_BOTTOM',
    label: 'dental.dentalDetail.statusAndDiagnosis.groupDetail.jawBottom.label',
    target: DENTAL_GROUP.JAW,
  },
  JAW_ALL: {
    value: 'JAW_ALL',
    label: 'dental.dentalDetail.statusAndDiagnosis.groupDetail.jawAll.label',
    target: DENTAL_GROUP.JAW,
  },
  TONGUE_UPPER: {
    value: 'TONGUE_UPPER',
    label: 'dental.dentalDetail.statusAndDiagnosis.groupDetail.tongueUpper.label',
    target: DENTAL_GROUP.TONGUE,
  },
  TONGUE_BOTTOM: {
    value: 'TONGUE_BOTTOM',
    label: 'dental.dentalDetail.statusAndDiagnosis.groupDetail.tongueBottom.label',
    target: DENTAL_GROUP.TONGUE,
  },
} as const;

export const DENTAL_DIAGNOSIS_STATUS = {
  PROCESSING: {
    value: 'PROCESSING',
    label: 'Mới',
  },
  FINISHED: {
    value: 'FINISHED',
    label: 'Đang diễn ra',
  },
  DELETED: {
    value: 'DELETED',
    label: 'Đã hủy',
  },
} as const;

export const ADULT_TOOTH_MAP = {
  top: [18, 17, 16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 27, 28],
  bottom: [48, 47, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36, 37, 38],
} as const;

export const ADULT_TOOTH_LIST = [...ADULT_TOOTH_MAP.top, ...ADULT_TOOTH_MAP.bottom].sort((a, b) => a - b);

export const CHILD_TOOTH_MAP = {
  top: [55, 54, 53, 52, 51, 61, 62, 63, 64, 65],
  bottom: [85, 84, 83, 82, 81, 71, 72, 73, 74, 75],
} as const;

export const CHILD_TOOTH_LIST = [...CHILD_TOOTH_MAP.top, ...CHILD_TOOTH_MAP.bottom].sort((a, b) => a - b);
