import {
  Box,
  ButtonBase,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  Paper,
  Popover,
  Tab,
  Tabs,
  Typography,
} from '@mui/material';
import { Add, Delete, Edit } from '@mui/icons-material';
import ArrowDropDownRoundedIcon from '@mui/icons-material/ArrowDropDownRounded';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from '../../../common/constants';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  list: { padding: 0 },
  listItemActive: {
    // backgroundColor: LIGHT_GREY,
  },
  listIcon: { justifyContent: 'flex-end' },
}));

interface Props {
  data?: some;
  onCreateOrUpdateCategory: (value?: some) => void;
  onDeleteCategory: (value: some) => void;
  categoryId?: number;
  setCategoryId: React.Dispatch<React.SetStateAction<number | undefined>>;
}

const TabCategory: React.FunctionComponent<Props> = (props) => {
  const { data, onCreateOrUpdateCategory, categoryId, setCategoryId, onDeleteCategory } = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const [rightClickData, setRightClickData] = React.useState<some | undefined>(undefined);

  return (
    <Box>
      <Box padding={2} paddingBottom={0}>
        <Typography variant="h6">
          <FormattedMessage id="imageIndication.list" />
        </Typography>
      </Box>
      <Tabs
        value={categoryId || null}
        onChange={(e, newValue) => {
          if (newValue) {
            setCategoryId(newValue);
          }
        }}
        variant="scrollable"
        indicatorColor="secondary"
        textColor="secondary"
        style={{ padding: '0px 8px' }}
      >
        {data?.map((tab: some) => {
          return (
            <Tab
              id={`tab-${tab?.id}`}
              key={tab?.id}
              value={tab?.id}
              style={{ minWidth: 100 }}
              disableTouchRipple
              label={
                <Box display="flex" alignItems="center" justifyContent="space-between">
                  <Typography variant="subtitle2">{tab.name}</Typography> &nbsp;
                  <ButtonBase
                    style={{ borderRadius: 4 }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setAnchorEl(anchorEl ? null : document.getElementById(`tab-${tab?.id}`));
                      setRightClickData(tab);
                    }}
                    component="div"
                  >
                    <ArrowDropDownRoundedIcon
                      style={{
                        transform: open && rightClickData?.id === tab?.id ? 'rotate(180deg)' : undefined,
                        transition: 'all 0.3s',
                      }}
                    />
                  </ButtonBase>
                </Box>
              }
              onContextMenu={(e) => {
                e.preventDefault();
                setAnchorEl(anchorEl ? null : e.currentTarget);
                setRightClickData(tab);
              }}
            />
          );
        })}
        <Tab
          key={'add'}
          value={null}
          style={{ minWidth: 100 }}
          onClick={() => {
            onCreateOrUpdateCategory({});
          }}
          label={
            <Box display="flex" alignItems="center">
              <Add />
              &nbsp;
              <Typography variant="subtitle2">
                <FormattedMessage id="add" />
              </Typography>
            </Box>
          }
        />
      </Tabs>
      <Divider />
      <Popover
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={() => {
          setAnchorEl(null);
        }}
      >
        <Paper elevation={3}>
          <List className={classes.list}>
            <ListItem
              button
              onClick={() => {
                setAnchorEl(null);
                rightClickData && onCreateOrUpdateCategory(rightClickData);
              }}
            >
              <Typography variant="body2" noWrap style={{ flex: 1 }}>
                <FormattedMessage id="priceList.edit" />
              </Typography>
              <ListItemIcon className={classes.listIcon}>
                <Edit fontSize="small" />
              </ListItemIcon>
            </ListItem>
            <ListItem
              button
              className={classes.listItemActive}
              onClick={() => {
                setAnchorEl(null);
                rightClickData && onDeleteCategory(rightClickData);
              }}
            >
              <Typography variant="body2" noWrap style={{ flex: 1 }}>
                <FormattedMessage id="priceList.delete" />
              </Typography>
              <ListItemIcon className={classes.listIcon}>
                <Delete fontSize="small" />
              </ListItemIcon>
            </ListItem>
          </List>
        </Paper>
      </Popover>
    </Box>
  );
};

export default TabCategory;
