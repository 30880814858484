import DialogCustom from 'modules/common/component/DialogCustom';
import DialogContent from './DialogContent';
import { useDiagnosisDialogSelector } from './state';

const StatusAndDiagnosisDialog = () => {
  const onCloseDialog = useDiagnosisDialogSelector.use.onCloseDialog();
  const openDialog = useDiagnosisDialogSelector.use.open();
  return (
    <DialogCustom
      open={openDialog}
      onClose={onCloseDialog}
      title="dental.dentalDetail.statusAndDiagnosis.dialog.title"
      PaperProps={{
        style: { width: '1000px' },
      }}
      maxWidth="xl"
      sx={{ '& .MuiBox-root.dialog_header': { backgroundColor: '#094EFF', h6: { color: '#E0ECFF' } } }}
    >
      <DialogContent />
    </DialogCustom>
  );
};

export default StatusAndDiagnosisDialog;
