import { Paper } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { API_SERVER } from '../../common/api';
import usePaginationHook from '../../common/hook/usePaginationHook';
import EncounterList from '../component/listpage/EncounterList';
import FilterEncounterBox from '../component/listpage/FilterEncounterBox';
import { useFetch } from 'modules/common/hook/useFetch';
import { formatDateFilterField } from 'modules/common/utils';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import PageHeader from 'modules/common/component/PageHeader';

const ExamDoctorEncounterListPage = () => {
  const orderEncounter = useSelector((appState: AppState) => appState.common.orderEncounter);

  const hookPagination = usePaginationHook({
    defaultFilter: {
      status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
      orderBy: orderEncounter,
    },
  });
  const { params } = hookPagination;

  const {
    data: encounterPage,
    isValidating,
    revalidate,
  } = useFetch(API_SERVER.encounter.search(formatDateFilterField(params, 'fromDate', 'toDate')));

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterEncounterBox
        hookPagination={hookPagination}
        dataLoading={isValidating}
        pagin={
          <PageHeader
            titleKey="encounterList"
            pagination={{
              size: encounterPage?.content.length || 0,
              totalElements: encounterPage?.pagination.totalElements || 0,
            }}
          />
        }
      />
      <EncounterList
        encounters={encounterPage?.content}
        total={encounterPage?.pagination.totalElements || 0}
        hookPagination={hookPagination}
        loading={isValidating}
        refresh={revalidate}
      />
    </Paper>
  );
};

export default ExamDoctorEncounterListPage;
