import * as React from 'react';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFilterEncounterForm } from './schema';
import { ENCOUNTER_STATUS, PAYMENT_STATUS } from 'modules/common/apiConstants';

interface Props {
  hookPagination: HookPaginationProps;
  pagin?: React.ReactNode;
}

const FilterEncounterForCashier: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, pagin } = props;
  const { params, setParams, clearParams } = hookPagination;
  return (
    <SchemaForm
      schema={schemaFilterEncounterForm}
      onSubmit={setParams}
      formData={params}
      clearParams={() => {
        clearParams({
          status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
          paymentStatus: [PAYMENT_STATUS.PAID.value, PAYMENT_STATUS.UNPAID.value, PAYMENT_STATUS.PREPAID.value],
        });
      }}
      hideSubmitButton
      pagin={pagin}
    />
  );
};

export default FilterEncounterForCashier;
