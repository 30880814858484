export const USER_ROLE_LIST = [
  {
    id: 1,
    value: 'MEDRING_ADMIN',
    label: 'account.medring.superAdmin',
    isBasic: false,
    isStandard: false,
    discription: 'Quản trị viên nhà cung cấp phần mềm MEDRING',
  },
  {
    id: 2,
    value: 'MEDRING_MANAGER',
    label: 'account.medring.manager',
    isBasic: false,
    isStandard: false,
    discription: 'Quản lý nhà cung cấp phần mềm MEDRING',
  },
  {
    id: 3,
    value: 'MEDRING_USER',
    label: 'account.medring.user',
    isBasic: false,
    isStandard: false,
    discription: 'Người dùng nhà cung cấp phần mềm MEDRING',
  },
  {
    id: 4,
    value: 'ADMIN',
    label: 'account.admin',
    isBasic: true,
    isStandard: true,
    discription: 'Quản trị viên hệ thống phòng khám - Dùng ở bản Standard|Basic',
  },
  {
    id: 5,
    value: 'DOCTOR',
    label: 'account.examDoctor',
    isBasic: true,
    isStandard: true,
    discription: 'Bác sĩ khám - Dùng ở bản Standard|Basic',
  },
  {
    id: 6,
    value: 'GENERAL_DOCTOR',
    label: 'account.general',
    isBasic: true,
    isStandard: false,
    discription: 'Bác sĩ tổng quát - Dùng ở bản Basic',
  },
  {
    id: 7,
    value: 'NURSE',
    label: 'account.nurse',
    isBasic: false,
    isStandard: true,
    discription: 'Điểu dưỡng - Dùng ở bản Standard',
  },
  {
    id: 8,
    value: 'PHARMACIST',
    label: 'account.pharmacist',
    isBasic: true,
    isStandard: true,
    discription: 'Dược sĩ/Nhân viên bán - Dùng ở bản Standard|Basic',
  },
  {
    id: 9,
    value: 'CASHIER',
    label: 'account.cashier',
    isBasic: false,
    isStandard: true,
    discription: 'Thu ngân - Dùng ở bản Standard',
  },
  {
    id: 10,
    value: 'COORDINATOR',
    label: 'account.frontOffice',
    isBasic: true,
    isStandard: false,
    discription: 'Điều phối viên (Thu ngân/Lễ tân) - Dùng ở bản Basic',
  },
  {
    id: 11,
    value: 'RADIOGRAPHER',
    label: 'account.imageTechnician',
    isBasic: false,
    isStandard: true,
    discription: 'Kĩ thuật viên chuẩn đoán hình ảnh - Dùng ở bản Standard',
  },
  {
    id: 12,
    value: 'LABORATORY_TECHNOLOGIST',
    label: 'account.laboratory',
    isBasic: false,
    isStandard: true,
    discription: 'Kỹ thuật viên xét nghiệm - Dùng ở bản Standard',
  },
  {
    id: 13,
    value: 'RADIOLOGIST',
    label: 'account.imageSpecialist',
    isBasic: false,
    isStandard: true,
    discription: 'Bác sĩ chuẩn đoán hình ảnh - Dùng ở bản Standard',
  },
  {
    id: 14,
    value: 'CLINICAL_PATHOLOGIST',
    label: 'account.labDoctor',
    isBasic: false,
    isStandard: true,
    discription: 'Bác sĩ xét nghiệm - Dùng ở bản Standard',
  },
  {
    id: 15,
    value: 'RECEPTIONIST',
    label: 'account.reception',
    isBasic: false,
    isStandard: true,
    discription: 'Lễ tân - Dùng ở bản Standard',
  },
];

export const APPOINTMENT_TARGET_TYPE = {
  PATIENT: 'PATIENT',
  APPOINTMENT_GROUP: 'APPOINTMENT_GROUP',
} as const;

export const APPOINTMENT_STATUS = {
  BOOKED: {
    value: 'BOOKED',
    label: 'appointmentBook.book',
  },
  ARRIVED: {
    value: 'ARRIVED',
    label: 'appointmentBook.arrived',
  },
  CANCELLED: {
    value: 'CANCELLED',
    label: 'appointmentBook.cancel',
  },
} as const;
