import { Box, Stack, Switch, Tooltip, Typography } from '@mui/material';
import { EncounterDetailProps } from '../../type';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { useCreateMutate, useDeleteMutate, useDialog, useFetch, useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import {
  INDICATION_REQUEST_STATUS,
  INDICATION_REQUEST_TYPE,
  SYSTEM_CATEGORY_SCOPE_LABEL,
} from 'modules/common/constants';
import { NoNote, SendRequest } from 'svg';
import { Delete } from '@mui/icons-material';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { find, flatMap, get, groupBy, isEmpty, map, uniqBy } from 'lodash';
import { CodeSystem, IndicationRequest, Service } from 'modules/schema';
import { useCallback, useEffect, useMemo, useState } from 'react';
import NoteFormDialog from './NoteFormDialog';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import { useFieldArray, useForm } from 'react-hook-form';
import { NumericFormatText } from 'modules/common/utils';
import { FormattedMessage } from 'react-intl';
import { GROUP_VERSION, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import ImageResultDialog from 'modules/examDoctor/component/therapy/TherapyIndication/IndicationDetail/ImageResultDialog';
import ProcedureDetailDialog from 'modules/examDoctor/component/therapy/TherapyIndication/IndicationDetail/ProcedureDetail/ProcedureDetailDialog';
import IconButton from 'modules/common/component/IconButton';
import PrintIcon from '@mui/icons-material/Print';
import PrintIndicationDialog from './PrintDialog';
import { useRef } from 'react';
import LabResultEncounterDialog from './LabResultEncounterDialog';
import AddIndicationDialog from './AddIndicationDialog';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';

type GroupItemType = { id: number; name: string; serviceType: string };
type IndicationRequestType = Omit<IndicationRequest, 'createdTime' | 'startTime' | 'endTime'> & { name: string };
interface SchemaFormIndication {
  groupType:
    | (Omit<
        Service,
        'specializedDepartment' | 'physicsRoom' | 'medicalEquipment' | 'laboratoryReferences' | 'systemCategory'
      > &
        GroupItemType)
    | null;
  indication: IndicationRequestType[] | [];
  indicationList: IndicationRequestType[];
  indicationCategory: CodeSystem | null;
}
const IndicationSession = ({ encounter, readonly }: EncounterDetailProps) => {
  const { intl, appState, confirmDialog, isBasic } = useGeneralHook();
  const basicMedi = appState.authen.user?.group_version === GROUP_VERSION.BASIC;
  const [selectedIndication, setSelectedIndication] = useState<IndicationRequest | null>(null);
  const [selection, setSelection] = useState<IndicationRequest[]>([]);
  const form = useForm<SchemaFormIndication>({
    defaultValues: { groupType: null, indication: [], indicationList: [], indicationCategory: null },
  });
  const { fields, replace } = useFieldArray({
    control: form.control,
    name: 'indicationList',
    keyName: 'key',
  });

  // const encounterContext = useContext(EncounterContext);
  const { data, revalidate } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(encounter?.id!, INDICATION_REQUEST_TYPE.ENCOUNTER),
  );
  const [noteIndication, setNoteIndication] = useState<Service>();
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const [openDialogPrint, onOpenDialogPrint, onCloseDialogPrint] = useDialog();
  const [openAddDialogPrint, onOpenAddDialogPrint, onCloseAddDialogPrint] = useDialog();
  const [indicationAdd, setIndicationAdd] = useState<IndicationRequestType[]>([]);
  const { data: serviceCategoryList } = useFetch(
    API_SERVER.service.getAllSystemCategoryList({
      scope: [
        SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE.value,
        SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
        SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value,
        SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
      ],
    }),
  );

  const serviceTypeOption = useMemo<Service[]>(() => {
    if (!serviceCategoryList) {
      return [];
    }
    const serviceCategoryListVersion = basicMedi
      ? serviceCategoryList.filter(
          (i) =>
            i.scope !== SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value &&
            i.scope !== SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
        )
      : serviceCategoryList;
    const groupedCategories = groupBy(
      serviceCategoryListVersion.filter((item) => item.type === 'GROUP'),
      'scope',
    );
    const result = map(groupedCategories, (categories, key) => {
      const itemsService = flatMap(categories, (category) =>
        category?.items?.map((item) => ({
          ...item,
          group: category.name,
          scope: category.scope,
        })),
      );
      const label = get(find(SYSTEM_CATEGORY_SCOPE_LABEL, { value: key }), 'label', '');
      const formattedName = intl.formatMessage({ id: label });
      const groups = categories.map((category) => ({
        ...category,
        label: categories[0].name,
      }));
      return {
        serviceType: key,
        name: formattedName,
        groups,
        label: formattedName,
        items: itemsService
          .map((item) => ({ ...item, groupsName: formattedName, groupsKey: key }))
          ?.filter((item) => item && item.name),
      };
    });
    return result;
  }, [serviceCategoryList, basicMedi, intl]);

  useEffect(() => {
    if (data) {
      replace(data);
    }
  }, [data, replace]);

  const onAddIndicationRequest = useCreateMutate({
    onSuccess: () => {
      onCloseAddDialogPrint();
      revalidate();
      form.resetField('indication');
    },
  });

  const onSelectIndicationRequest = useCallback(async () => {
    const service = form.getValues('indication');
    if (service.length !== 0) {
      setIndicationAdd(service);
      onOpenAddDialogPrint();
    }
  }, [form, onOpenAddDialogPrint]);

  const onDeleteIndicationRequest = useDeleteMutate({
    onSuccess: revalidate,
    confirmMessage: 'therapyIndication.request.confirmDelete',
    okeText: 'yes',
    cancelText: 'no',
  });
  const onDelete = useCallback(
    async (indication: IndicationRequest) => {
      onDeleteIndicationRequest({
        url: API_SERVER.therapyIndication.delete(indication?.id!),
        method: 'DELETE',
      });
    },
    [onDeleteIndicationRequest],
  );

  const onCompleteIndicationRequest = useUpdateMutate({
    onSuccess: revalidate,
    confirmMessage: 'therapy.detail.indication.text.finishConfirm',
  });
  const onComplete = useCallback(
    async (indication: IndicationRequest) => {
      onCompleteIndicationRequest({
        url: API_SERVER.therapyIndication.finish(indication?.id!),
        method: 'PUT',
        data: {},
      });
    },
    [onCompleteIndicationRequest],
  );

  const onRequestMutate = useUpdateMutate({
    onSuccess: revalidate,
    confirmMessage: 'therapy.detail.indication.text.requestConfirm',
  });
  const onRequest = useCallback(
    async (indication: IndicationRequest) => {
      onRequestMutate({
        url: API_SERVER.therapyIndication.requestIndication(indication?.id!),
        method: 'PUT',
        data: {},
      });
    },
    [onRequestMutate],
  );

  const onUpdateNoteMutate = useUpdateMutate({ onSuccess: revalidate, onFinally: onCloseDialog });
  const onUpdateNote = useCallback(
    async (note: string) => {
      if (selectedIndication) {
        onUpdateNoteMutate({
          url: API_SERVER.indicationRequest.note(selectedIndication?.id!),
          method: 'PUT',
          data: { data: note },
        });
      }
    },
    [onUpdateNoteMutate, selectedIndication],
  );

  const onUpdateFreeIndicationMutate = useUpdateMutate({ onSuccess: revalidate });
  const [labResultIndication, setLabResultIndication] = useState<IndicationRequest>();
  const [imageResultIndication, setImageResultIndication] = useState<IndicationRequest>();
  const [procedureIndication, setProcedureIndication] = useState<IndicationRequest>();

  const columns = useMemo(() => {
    return [
      {
        type: 'INDEX',
        // width: '2%',
        headerCellProps: { sx: { fontSize: '16px' } },
      },
      {
        title: 'therapyIndication.header.label.serviceName',
        dataIndex: 'serviceName',
        width: '25%',
        align: 'left',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => {
          return (
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: record.scope !== SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value ? 'underline' : 'none',
                cursor: record.scope !== SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value ? 'pointer' : 'auto',
              }}
              color={record.scope !== SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value ? 'primary' : ''}
              onClick={() => {
                if (record.scope === SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value) {
                  setLabResultIndication(record);
                } else if (record.scope === SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value) {
                  setImageResultIndication(record);
                } else if (record.scope === SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE.value) {
                  setProcedureIndication(record);
                }
              }}
            >
              {record.serviceName}
            </Typography>
          );
        },
      },
      {
        title: 'therapyIndication.header.label.serviceType',
        width: '15%',
        dataIndex: 'scope',
        align: 'left',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => {
          return (
            <Typography
              sx={{
                fontSize: '16px',
                cursor: 'default',
              }}
            >
              {intl.formatMessage({ id: SYSTEM_CATEGORY_SCOPE[record.scope!].label || ' ' })}
            </Typography>
          );
        },
      },
      {
        title: 'status',
        width: '9%',
        align: 'left',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => {
          const status = INDICATION_REQUEST_STATUS[record.status!];
          return (
            <Typography
              noWrap
              sx={{
                color: status?.color || 'primary.main',
                fontWeight: '500',
                fontSize: '16px',
                cursor: 'default',
              }}
            >
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
      },
      {
        title: 'therapyIndication.header.label.picName',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => {
          return (
            <Typography
              noWrap
              sx={{
                fontSize: '16px',
                cursor: 'default',
              }}
            >
              {record.scope === SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value ? record.picName : record.executeName}
            </Typography>
          );
        },
        width: '15%',
        align: 'left',
      },
      {
        title: 'therapyIndication.header.label.price',
        fieldName: 'price',
        width: '10%',
        align: 'left',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (data) => {
          return (
            <Typography
              sx={{
                fontSize: '16px',
                cursor: 'default',
              }}
            >
              {NumericFormatText(data.price)}
            </Typography>
          );
        },
      },
      {
        title: 'dental.dentalDetail.indicationTable.fee.label',
        width: '10%',
        align: 'left',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => {
          return (
            <Tooltip
              title={<FormattedMessage id={record.isPaid ? 'report.debt.table.title.amountPaid' : 'freeSwitch'} />}
              disableInteractive
            >
              <span>
                <Switch
                  size="small"
                  color="primary"
                  checked={!record?.isFree}
                  disabled={record.isPaid || readonly}
                  onChange={async (event) => {
                    await onUpdateFreeIndicationMutate({
                      url: API_SERVER.indicationRequest.updateIndicationRequest(record.id!),
                      method: 'PUT',
                      data: { ...record, isFree: !event.target.checked },
                    });
                  }}
                />
              </span>
            </Tooltip>
          );
        },
      },
      {
        title: 'action',
        // align: 'right',
        width: '10%',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => (
          <>
            {record?.status === 'WAIT' && !readonly && (
              <IconButtonTitle
                key="request"
                disableRipple
                title={'request'}
                size="small"
                onClick={async () => {
                  onRequest(record);
                }}
              >
                <SendRequest />
              </IconButtonTitle>
            )}
            {record.status !== 'FINISHED' && !record.isPaid && !readonly && (
              <IconButtonTitle
                key="delete-btn"
                title="delete"
                size="small"
                onClick={async () => {
                  onDelete(record);
                }}
              >
                <Delete fontSize="small" sx={{ color: record.isPaid || readonly ? '#E0E0E0' : '#78909C' }} />
              </IconButtonTitle>
            )}
            <IconButtonTitle
              title="note"
              key="note-btn"
              size="small"
              onClick={() => {
                setNoteIndication({ ...record });
                setSelectedIndication(record);
                onOpenDialog();
              }}
              disabled={readonly}
              sx={{ '& svg': { width: '24px' } }}
            >
              {record.note ? (
                <StickyNote2Icon sx={{ color: '#78909C' }} fontSize="small" />
              ) : (
                <NoNote fontSize="small" />
              )}
            </IconButtonTitle>

            {record?.status === 'FINISHED' ? (
              <IconButtonTitle disableRipple size="small">
                <AssignmentTurnedInIcon sx={{ color: '#43A047' }} />
              </IconButtonTitle>
            ) : (
              <IconButtonTitle
                key="completed"
                disableRipple
                title={'complete'}
                size="small"
                disabled={readonly}
                onClick={async () => {
                  onComplete(record);
                }}
              >
                <AssignmentTurnedInIcon sx={{ color: '#78909C' }} />
              </IconButtonTitle>
            )}
          </>
        ),
      },
    ] as Columns<typeof fields[number]>[];
  }, [intl, onComplete, onDelete, onOpenDialog, onRequest, onUpdateFreeIndicationMutate, readonly]);

  const groupType = form.watch('groupType');
  const indicationCategory = form.watch('indicationCategory');
  useEffect(() => {
    form.resetField('indicationCategory');
    form.resetField('indication');
  }, [form, groupType]);

  const indicationOption = useMemo(() => {
    return groupType !== undefined && groupType?.serviceType
      ? flatMap(serviceTypeOption, 'items').filter((value) => value?.groupsKey === groupType?.serviceType)
      : flatMap(serviceTypeOption, 'items');
  }, [serviceTypeOption, groupType]);

  const onSubmitIndicationProcedureMutate = useUpdateMutate({
    onSuccess: () => {
      revalidate();
      setProcedureIndication(undefined);
    },
  });
  const onSubmitIndicationProcedure = useCallback(
    async (indicationProcedure: IndicationRequest) => {
      onSubmitIndicationProcedureMutate({
        url: API_SERVER.indicationProcedures.update,
        method: 'put',
        data: {
          ...indicationProcedure,
        },
      });
    },
    [onSubmitIndicationProcedureMutate],
  );

  const indicationContentType = localStorage.getItem('indication-content-type');
  const myRef = useRef<HTMLElement | null>(null);

  // scroll to indication tab
  useEffect(() => {
    if (
      indicationContentType === 'INDICATION_LAB_FINISH' ||
      indicationContentType === 'INDICATION_IMAGE_FINISH' ||
      indicationContentType === 'INDICATION_PROCEDURE_FINISH'
    ) {
      myRef.current?.scrollIntoView({
        behavior: 'smooth',
      });
    }
    return () => {
      localStorage.removeItem('indication-content-type');
    };
  }, [indicationContentType]);

  const optionCategory = useMemo(() => {
    return uniqBy(Object.values(indicationOption), 'group').map((item) => ({
      value: item.group,
      label: item.group,
    }));
  }, [indicationOption]);

  const onCompleteIndicationsMutate = useUpdateMutate({
    onSuccess: () => {
      revalidate();
    },
  });

  // complete multiple indications
  const onCompleteIndications = useCallback(
    async (indications: IndicationRequest[]) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmCompleteTitle' }),
        okId: intl.formatMessage({ id: 'complete' }),
        content: intl.formatMessage(
          { id: 'confirm.indications.complete' },
          { count_selected_indication: indications?.length },
        ),
      });
      if (confirm) {
        onCompleteIndicationsMutate({
          url: API_SERVER.indicationRequest.completeMultiple,
          method: 'put',
          data: {
            data: indications?.map((i) => i.id),
          },
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, onCompleteIndicationsMutate],
  );

  const onDeleteIndicationsMutate = useDeleteMutate({
    onSuccess: () => {
      revalidate();
    },
  });

  // delete multiple indications
  const onDeleteIndications = useCallback(
    async (indications: IndicationRequest[]) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage(
          { id: 'confirm.indications.delete' },
          { count_selected_indication: indications?.length },
        ),
      });
      if (confirm) {
        onDeleteIndicationsMutate({
          url: API_SERVER.indicationRequest.deleteMultiple,
          method: 'delete',
          data: {
            data: indications?.map((i) => i.id),
          },
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, onDeleteIndicationsMutate],
  );

  return (
    <Box id="indication-session" ref={myRef}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <Box width="300px">
            <AutocompleteInput
              key="groupType"
              form={form}
              name="groupType"
              getValue="name"
              options={serviceTypeOption}
              rawLabel
              label={intl.formatMessage({ id: 'therapyIndication.header.label.serviceType' })}
              placeholder="therapyIndication.header.label.serviceTypeSelect"
              getLabel="name"
              clearButton
              disabled={readonly}
            />
          </Box>
          <Box width="300px">
            <AutocompleteInput
              key="indicationCategory"
              form={form}
              name="indicationCategory"
              options={optionCategory}
              getLabel="value"
              getValue="value"
              placeholder="indication.request.title.category.encounter.placeholder"
              hideError
              label="indication.request.title.category.encounter"
              disabled={readonly}
              clearButton
            />
          </Box>
          <Box width="300px">
            <AutocompleteInput
              key={'indication'}
              form={form}
              name="indication"
              getValue="id"
              options={
                indicationCategory
                  ? Object.values(indicationOption).filter((item) => item.group === indicationCategory?.value)
                  : Object.values(indicationOption)
              }
              label={intl.formatMessage({ id: 'serviceName' })}
              placeholder="serviceNameSelect"
              getLabel="name"
              onBlur={onSelectIndicationRequest}
              renderTags={() => null}
              hideError
              multiple
              showCheck
              rawLabel
              disableCloseOnSelect={true}
              disabled={readonly}
            />
          </Box>
        </Stack>
        <Stack direction="row" gap={1}>
          <IconButton
            disabled={fields.filter((item) => item.status === INDICATION_REQUEST_STATUS.REQUESTED.value).length === 0}
            icon={
              <PrintIcon
                sx={{
                  color:
                    fields.filter((item) => item.status === INDICATION_REQUEST_STATUS.REQUESTED.value).length !== 0
                      ? '#307BFF'
                      : '#9E9ED0',
                }}
              />
            }
            onClick={onOpenDialogPrint}
          />
          {isBasic && (
            <>
              <IconButton
                disabled={
                  fields.filter((item) => item.status === INDICATION_REQUEST_STATUS.REQUESTED.value).length === 0 ||
                  isEmpty(selection)
                }
                icon={
                  <AssignmentTurnedInIcon
                    sx={{
                      color:
                        fields.filter((item) => item.status === INDICATION_REQUEST_STATUS.REQUESTED.value).length !==
                          0 && !isEmpty(selection)
                          ? '#307BFF'
                          : '#9E9ED0',
                    }}
                  />
                }
                onClick={() => {
                  onCompleteIndications(selection);
                  setSelection([]);
                }}
              />
              <IconButton
                disabled={
                  fields.filter((item) => item.status === INDICATION_REQUEST_STATUS.REQUESTED.value).length === 0 ||
                  isEmpty(selection)
                }
                icon={
                  <Delete
                    sx={{
                      color:
                        fields.filter((item) => item.status === INDICATION_REQUEST_STATUS.REQUESTED.value).length !==
                          0 && !isEmpty(selection)
                          ? '#307BFF'
                          : '#9E9ED0',
                    }}
                  />
                }
                onClick={() => {
                  onDeleteIndications(selection);
                  setSelection([]);
                }}
              />
            </>
          )}
        </Stack>
      </Stack>
      {isBasic ? (
        <TableCustom
          columns={columns}
          dataSource={fields}
          rowSelection={{
            selectedRowKeys: selection,
            onChange: ({ selectedRows }) => setSelection(selectedRows),
            hidden: (record: IndicationRequest) => record.status === INDICATION_REQUEST_STATUS.FINISHED.value,
            hideSelectAll:
              fields.filter((item) => item.status === INDICATION_REQUEST_STATUS.REQUESTED.value).length === 0,
          }}
        />
      ) : (
        <TableCustom columns={columns} dataSource={fields} />
      )}
      <NoteFormDialog
        open={openDialog}
        onClose={() => {
          onCloseDialog();
        }}
        formData={noteIndication}
        onSubmit={onUpdateNote}
        readOnly={readonly || noteIndication?.status === INDICATION_REQUEST_STATUS.FINISHED.value}
      />
      {!!labResultIndication && (
        <LabResultEncounterDialog
          onClose={() => {
            setLabResultIndication(undefined);
          }}
          open={!!labResultIndication}
          indication={labResultIndication}
          patientId={encounter.patientId!}
        />
      )}
      {!!imageResultIndication && (
        <ImageResultDialog
          onClose={() => {
            setImageResultIndication(undefined);
          }}
          indication={imageResultIndication}
          open={!!imageResultIndication}
        />
      )}
      {!!procedureIndication && (
        <ProcedureDetailDialog
          onClose={() => {
            setProcedureIndication(undefined);
          }}
          formData={procedureIndication}
          open={!!procedureIndication}
          onSubmit={onSubmitIndicationProcedure}
          readOnly={readonly}
        />
      )}

      <PrintIndicationDialog
        open={openDialogPrint}
        groupedServices={data?.filter((item) => item.status === INDICATION_REQUEST_STATUS.REQUESTED.value) || []}
        onClose={() => {
          onCloseDialogPrint();
        }}
      />

      {!!indicationAdd && (
        <AddIndicationDialog
          open={openAddDialogPrint}
          onClose={() => {
            onCloseAddDialogPrint();
            form.resetField('indication');
            setIndicationAdd([]);
          }}
          indicationAdd={indicationAdd}
          onSubmit={(value) => {
            const dataService = value
              .filter((item) => item.quantity !== 0 && item.id !== undefined && encounter.id !== undefined)
              .reduce((acc, item) => {
                const newItems = Array(item.quantity)
                  .fill(null)
                  .map(() => ({
                    serviceId: item.id as number,
                    serviceName: item.serviceName as string,
                    status: INDICATION_REQUEST_STATUS.REQUESTED.value,
                    referenceId: encounter.id as number,
                    isFree: false,
                    type: INDICATION_REQUEST_TYPE.ENCOUNTER,
                    quantity: 1,
                  }));
                return acc.concat(newItems);
              }, [] as Array<{ serviceId: number; serviceName: string; status: string; referenceId: number; isFree: boolean; type: string; quantity: number }>);

            onAddIndicationRequest({
              url: API_SERVER.indicationRequest.createMultiple(),
              method: 'post',
              data: dataService,
            });
          }}
        />
      )}
    </Box>
  );
};

export default IndicationSession;
