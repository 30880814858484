import { PHYSICS_ROOM_CODE } from 'modules/common/apiConstants';
import { z } from 'zod';

export const PhysicsRoomSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    description: z.string(),
    status: z.string(),
    groupId: z.number(),
    systemCategoryId: z.number(),
    systemCategoryCode: z.string(),
  })
  .partial()
  .transform((physicRoom) => {
    return {
      ...physicRoom,
      ...(physicRoom?.systemCategoryCode
        ? {
            code: Object.values(PHYSICS_ROOM_CODE).find((roomCode) => roomCode.code === physicRoom?.systemCategoryCode)
              ?.value,
          }
        : {}),
    };
  });

export type PhysicsRoom = z.infer<typeof PhysicsRoomSchema>;
