import { Box } from '@mui/material';
import IndicationsFilterBox from './IndicationsFilterBox';
import IndicationsTable from './IndicationsTable';

const Indications = () => {
  return (
    <Box>
      <IndicationsFilterBox />
      <IndicationsTable />
    </Box>
  );
};

export default Indications;
