import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { GENDER } from 'modules/common/apiConstants';

export const RequestAppointmentSchema = z
  .object({
    id: z.number(),
    customerName: z.string(),
    phoneNumber: z.string(),
    bookingStartTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    bookingEndTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    picUserId: z.number(),
    note: z.string(),
    status: z.string(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    dob: z.string(),
    gender: createObjectKeysEnumSchema(GENDER),
  })
  .partial();

export type RequestAppointment = z.infer<typeof RequestAppointmentSchema>;
