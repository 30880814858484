import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT, PATIENT_TYPE, PAYMENT_PLAN } from 'modules/common/constants';
import { ObservationSchema } from './Observation';
import { PatientSchema } from './Patient';
import { ENCOUNTER_STATUS, ICD_TYPE } from 'modules/common/apiConstants';
import { PhysicsRoomSchema } from './PhysicsRoom';
import { PaymentSchema } from './Payment';
import { IcdDiagnosisSchema } from './IcdDiagnosis';

export const EncounterSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    sequenceNumber: z.string(),
    socialSecurityNumber: z.string(),
    benefitRate: z.number(),
    paymentPlan: createObjectKeysEnumSchema(PAYMENT_PLAN),
    patientId: z.number(),
    reason: z.string(),
    cancelReason: z.string(),
    hospitalizationReason: z.string(),
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    endTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    systemCategoryId: z.number(),
    parentSystemCategoryId: z.number(),
    serviceId: z.number(),
    serviceName: z.string(),
    picUserId: z.number(),
    picName: z.string(),
    status: createObjectKeysEnumSchema(ENCOUNTER_STATUS),
    groupId: z.number(),
    paymentId: z.number(),
    paymentDetailId: z.number(),
    differentialDiagnosis: z.string(),
    provisionalDiagnosis: z.string(),
    medicalHistory: z.string(),
    illnessesHistory: z.string(),
    clinicalExamination: z.string(),
    observation: ObservationSchema,
    patient: PatientSchema,
    patientType: createObjectKeysEnumSchema(PATIENT_TYPE),
    physicsRoom: PhysicsRoomSchema,
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    payment: PaymentSchema,
    icdDiagnosisList: z.array(IcdDiagnosisSchema),
    indicationRequestQuantity: z.number(),
    indicationRequestFinishedQuantity: z.number(),
  })
  .partial()
  .transform((encounter) => ({
    ...encounter,
    ...(encounter?.icdDiagnosisList
      ? {
          icd10: encounter?.icdDiagnosisList?.find((icd) => icd?.type === ICD_TYPE.PRIMARY_ICD10),
          otherIcd10s: encounter?.icdDiagnosisList?.filter((icd) => icd?.type === ICD_TYPE.SECOND_ICD10),
        }
      : {}),
  }));

export type Encounter = z.infer<typeof EncounterSchema>;
