import { Box, Button, Typography } from '@mui/material';
import { chain } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../common/component/DialogCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import GroupDrugDataFormDialog from './GroupDrugDataFormDialog';
import DrugTableCSV from './DrugTableCSV';
import DrugDataFormDialogCSV from './DrugDataFormDialogCSV';

interface Props {
  onSubmit: (formVal: some[]) => void;
  open: boolean;
  onClose: () => void;
  dataCSV?: some[];
}

const CSVPreviewDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, dataCSV, onSubmit } = props;
  const [data, setData] = React.useState<some[]>([]);
  const [formData, setFormData] = React.useState<some | undefined>();
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();

  const { appState } = useGeneralHook();
  const allIntendedRoutes = appState.common.intendedRoutes;

  const onDelete = React.useCallback((value: some) => {
    setData((old) => old.filter((val) => val.id !== value.id));
  }, []);

  const onDeleteMultiple = React.useCallback((value: some) => {
    setData((old) => old.filter((val) => value.list?.findIndex((one) => one.id === val.id) === -1));
  }, []);

  const onDeleteGroup = React.useCallback((value: some) => {
    setData((old) => old.filter((val) => val.group !== value.name));
  }, []);

  const onSubmitForm = React.useCallback(({ listData, ...value }: some) => {
    if (value.id) {
      setData((old) =>
        old.map((val) => {
          if (val.id === value.id) {
            return value;
          }
          return val;
        }),
      );
    } else {
      setData((old) => [...old, { ...value, group: listData?.[0]?.group }]);
    }
    setFormData(undefined);
  }, []);

  const onSubmitFormGroup = React.useCallback((value: some) => {
    setData((old) =>
      old.map((val) => {
        if (val.group === value.group) {
          return { ...val, group: value.name };
        }
        return val;
      }),
    );
    setFormDataGroup(undefined);
  }, []);

  const getValidate = React.useCallback((value: some) => {
    if (
      !value.group ||
      !value.registerNumber ||
      !value.drugName ||
      !value.unit ||
      !value.formType ||
      !value.ingredients ||
      value.intendedRoute.length === 0
    ) {
      return false;
    }
    return true;
  }, []);

  const dataMapped = React.useMemo(() => {
    return chain(data.map((val) => ({ ...val, validation: getValidate(val) })))
      .groupBy('group')
      .map((value, key) => ({
        group: key,
        name: key,
        items: value,
      }))
      .value()
      .sort((a: some, b: some) => a.group.localeCompare(b.group));
  }, [data, getValidate]);

  const setInitialData = React.useCallback(() => {
    setData(
      dataCSV
        ? dataCSV
            .slice(1)
            .filter((value, index) => value.data[3] && value.data[3].trim() !== '')
            .map((value, index) => {
              const formType = value.data[12];
              const approvalMoment = moment(value.data[22], FE_DATE_FORMAT);
              const declarePrice = parseFloat(value.data[21]);
              const temp = {
                id: index + 1,
                group: value.data[0],
                code: value.data[1],
                registerNumber: value.data[2],
                drugName: value.data[3]?.trim(),
                unit: value.data[4],
                groupCode: value.data[5],
                department: value.data[6],
                packagingFormat: value.data[7],
                dosageForm: value.data[8],
                expiredDate: value.data[9],
                produceCountry: value.data[10],
                intendedRoute: allIntendedRoutes.filter((one) =>
                  value.data?.[11]?.split(';').find((oneSplit) => oneSplit.trim() === one.value),
                ),
                formType:
                  formType === 'T'
                    ? 'normal'
                    : formType === 'N'
                    ? 'narcotic'
                    : formType === 'H'
                    ? 'psychotropic-precursor'
                    : '',
                ingredients: value.data[13],
                amount: value.data[14],
                producer: value.data[15],
                producerAddress: value.data[16],
                registerCompany: value.data[17],
                registerCompanyAddress: value.data[18],
                distributor: value.data[19],
                distributorAddress: value.data[20],
                declarePrice: isNaN(declarePrice) ? 0 : declarePrice,
                applicableDate: approvalMoment.isValid() ? approvalMoment.format(BE_DATE_FORMAT) : undefined,
              };
              return { ...temp, isValid: getValidate(temp) };
            })
        : [],
    );
  }, [dataCSV, allIntendedRoutes, getValidate]);

  return (
    <>
      <DialogCustom
        open={!!open}
        maxWidth="xs"
        keepMounted={false}
        onClose={onClose}
        TransitionProps={{
          onEnter: setInitialData,
        }}
        PaperProps={{
          style: { minWidth: '70vw', overflow: 'hidden' },
        }}
        disableCloseOutSide
        title={
          <Typography variant="h6" color="primary">
            <FormattedMessage id={'CSVPreview'} />
          </Typography>
        }
      >
        <Box padding={2} overflow="auto">
          {dataMapped?.length ? (
            dataMapped?.map((value: some) => {
              return (
                <DrugTableCSV
                  key={value.id}
                  setFormData={setFormData}
                  setFormDataGroup={setFormDataGroup}
                  onDelete={onDelete}
                  onDeleteGroup={onDeleteGroup}
                  onDeleteMultiple={onDeleteMultiple}
                  data={value}
                  isPreview
                />
              );
            })
          ) : (
            <FormattedMessage id="noData" />
          )}
        </Box>
        <Box padding={2} display="flex" justifyContent="flex-end">
          <Button variant="outlined" color="primary" style={{ minWidth: 100, marginRight: 16 }} onClick={onClose}>
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ minWidth: 100 }}
            disabled={dataMapped.length === 0 || !!data.find((val) => !getValidate(val))}
            onClick={() => {
              onSubmit && onSubmit(data);
            }}
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Box>
      </DialogCustom>
      <DrugDataFormDialogCSV
        open={!!formData}
        formData={formData}
        onSubmit={onSubmitForm}
        onClose={() => {
          setFormData(undefined);
        }}
      />
      <GroupDrugDataFormDialog
        open={!!formDataGroup}
        formData={formDataGroup}
        onSubmit={onSubmitFormGroup}
        onClose={() => {
          setFormDataGroup(undefined);
        }}
      />
    </>
  );
};
export default CSVPreviewDialog;
