import * as React from 'react';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFilterEncounterForm } from './schema';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';

interface Props {
  pagin?: React.ReactNode;
  hookPagination: HookPaginationProps;
  dataLoading: boolean;
}

const FilterEncounterBox: React.FunctionComponent<Props> = (props) => {
  const { pagin, hookPagination, dataLoading } = props;
  const { params, setParams, clearParams } = hookPagination;
  const { appState } = useGeneralHook();
  const orderBy = appState.common.orderEncounter;

  return (
    <SchemaForm
      schema={schemaFilterEncounterForm}
      onSubmit={(value) => setParams({ ...value, orderBy })}
      formData={params}
      clearParams={() =>
        clearParams({
          status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
        })
      }
      hideSubmitButton
      pagin={pagin}
      dataLoading={dataLoading}
    />
  );
};

export default FilterEncounterBox;
