import { Box } from '@mui/material';
import { useCallback } from 'react';
import { API_SERVER } from 'modules/common/api';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import { SS_TOKEN, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import { fetchThunk } from 'modules/common/redux/thunk';
import DocumentCard from './DocumentCard';
import axios from 'axios';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import { useFetch } from 'modules/common/hook';
interface Props {
  role: 'exam' | 'lab' | 'image';
  dataTherapySession?: some;
  editable: boolean;
  therapy: some;
}
const AttachedDocumentBox = (props: Props) => {
  const { dataTherapySession, therapy } = props;
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();

  // Get files from S3 resource
  const {
    data: dataStorageResources,
    isValidating,
    revalidate,
  } = useFetch(
    API_SERVER.storageResource.getList({
      referenceId: dataTherapySession?.id || 0,
      type: STORAGE_RESOURCE_TYPE.therapySession,
    }),

    { dedupingInterval: 1, revalidateOnFocus: false, enabled: dataTherapySession?.id },
  );

  // upload file
  const uploadFileToDoc = useCallback(
    async (files: FileList) => {
      dispatch(setLoading(true));
      try {
        let multipartFormData = new FormData();
        const types: string[] = [];
        const referenceIds: string[] = [];
        const descriptions: string[] = [];
        for (let i = 0; i < files.length; i++) {
          multipartFormData.append('files', files[i]);
          types.push(STORAGE_RESOURCE_TYPE.therapySession);
          referenceIds.push(dataTherapySession?.id);
          descriptions.push(' ');
        }
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        const res: any = await axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );
        revalidate();
        if (res?.status === 200) {
          openSnackbar({ message: intl.formatMessage({ id: 'exam.addNewSucceeds' }) });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dataTherapySession?.id, dispatch, intl, openSnackbar, revalidate],
  );

  // delete file
  const deleteFile = useCallback(
    async (docRef: some, index: number) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: index && docRef?.fileName }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(fetchThunk(API_SERVER.storageResource.delete(docRef.id), 'delete'));
          await revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, revalidate],
  );

  if (isValidating) {
    return <LoadingIcon />;
  }
  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <DocumentCard
        key={dataTherapySession?.id}
        uploadFileToDoc={uploadFileToDoc}
        deleteFile={deleteFile}
        dataStorageResources={dataStorageResources}
        therapy={therapy}
      />
    </Box>
  );
};
export default AttachedDocumentBox;
