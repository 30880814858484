import { Box } from '@mui/material';
import { ReportTitle } from './ReportTitle';
import { ReactNode } from 'react';
import { ReportSwitchLayout } from './ReportSwitchLayout';
import { SelectColumnButton } from './SelectColumnButton';
import { ExportDataButton } from './ExportDataButton';
import { PrintDataButton } from './PrintDataButton';
import { ReportSearchInput } from './ReportSearchInput';
import ReportRefreshButton from './ReportRefreshButton';
import { FilterPrintFormat } from './FilterPrintFormat';
import ReportDayMonthRadio from './ReportDayMonthRadio';
import ReportColumnChart from './ReportColumnChart';
import ReportPieChart from './ReportPieChart';

interface Props {
  children: ReactNode;
}

export interface CompoundReportProps {
  Title: typeof ReportTitle;
  SwitchLayout: typeof ReportSwitchLayout;
  SelectColumnButton: typeof SelectColumnButton;
  ExportDataButton: typeof ExportDataButton;
  PrintDataButton: typeof PrintDataButton;
  SearchInput: typeof ReportSearchInput;
  RefreshButton: typeof ReportRefreshButton;
  FilterPrintFormat: typeof FilterPrintFormat;
  DayMonthRadio: typeof ReportDayMonthRadio;
  ColumnChart: typeof ReportColumnChart;
  PieChart: typeof ReportPieChart;
}

const Report: React.FC<Props> & CompoundReportProps = ({ children }) => {
  return <Box padding="20px">{children}</Box>;
};

Report.Title = ReportTitle;
Report.ExportDataButton = ExportDataButton;
Report.PrintDataButton = PrintDataButton;
Report.SelectColumnButton = SelectColumnButton;
Report.SwitchLayout = ReportSwitchLayout;
Report.SearchInput = ReportSearchInput;
Report.RefreshButton = ReportRefreshButton;
Report.FilterPrintFormat = FilterPrintFormat;
Report.DayMonthRadio = ReportDayMonthRadio;
Report.ColumnChart = ReportColumnChart;
Report.PieChart = ReportPieChart;

export default Report;
