import { Box, IconButton, Stack, Typography, createFilterOptions } from '@mui/material';
import AutocompleteInput, { AutocompleteInputProps } from './AutocompleteInput';
import { FieldPath, FieldValues, PathValue, UnpackNestedValue } from 'react-hook-form';
import { AppState } from 'redux/reducer';
import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { API_SERVER } from 'modules/common/api';
import { trim } from 'lodash';
import { loadMedicationInstructions } from 'modules/common/redux/commonReducer';
import { useDeleteMutate, useUpdateMutate } from 'modules/common/hook';
import { useIntl } from 'react-intl';
import CloseIcon from '@mui/icons-material/Close';

type OptionType = string | { label: string; value: string };
const filter = createFilterOptions<OptionType>();
type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  showError?: boolean;
} & Omit<
  AutocompleteInputProps<OptionType, undefined, undefined, undefined, TFieldValues, TName>,
  | 'options'
  | 'getValue'
  | 'getLabel'
  | 'renderLabel'
  | 'autoComplete'
  | 'handleHomeEndKeys'
  | 'autoHighlight'
  | 'filterOptions'
>;

const MedicationInstructionInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
) => {
  const medicationInstructions = useSelector((state: AppState) => state.common.medicationInstructions);
  const options = useMemo(() => medicationInstructions.map((ele) => ele.name!), [medicationInstructions]);
  const baseOptions = useMemo(
    () => medicationInstructions.filter((ele) => ele.groupId === 1).map((ele) => ele.name),
    [medicationInstructions],
  );
  const dispatch = useDispatch();
  const intl = useIntl();

  const deleteMutate = useDeleteMutate({
    onSuccess: () => {
      dispatch(loadMedicationInstructions());
    },
  });
  const addMutate = useUpdateMutate();
  const onAddInstruction = useCallback(
    async (instruction: string) => {
      await addMutate({
        url: API_SERVER.medicationInstruction.createMedicationInstruction(),
        method: 'POST',
        data: {
          name: trim(instruction),
        },
      });
      props.form.setValue(props.name, trim(instruction) as UnpackNestedValue<PathValue<TFieldValues, TName>>);
      await dispatch(loadMedicationInstructions());
    },
    [addMutate, dispatch, props.form, props.name],
  );
  const onDeleteInstruction = useCallback(
    async (option: string) => {
      const current = medicationInstructions.find((ele) => trim(ele.name) === option);
      if (current) {
        try {
          await deleteMutate(
            {
              url: API_SERVER.medicationInstruction.deleteMedicationInstruction(current.id!),
              method: 'DELETE',
            },
            {
              confirmMessage: intl.formatMessage(
                { id: 'medicationInstruction.input.confirmMessage' },
                { name: current.name },
              ),
            },
          );
          if (props.form.getValues(props.name) === option) {
            props.form.setValue(props.name, '' as UnpackNestedValue<PathValue<TFieldValues, TName>>);
          }
        } catch (error) {}
      }
    },
    [deleteMutate, intl, medicationInstructions, props.form, props.name],
  );
  return (
    <AutocompleteInput
      {...props}
      options={options as OptionType[]}
      getValue={(option) => (typeof option === 'string' ? option : option.value)}
      getLabel={(option) => (typeof option === 'string' ? option : option.value)}
      renderLabel={(option) => {
        if (typeof option === 'string') {
          return (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              width="300px"
              sx={{
                paddingY: '3px',
                paddingX: '16px',
                '& button': {
                  visibility: 'hidden',
                },
                '&:hover button': {
                  visibility: 'visible',
                },
              }}
            >
              <Typography>{option}</Typography>
              {!baseOptions.some((ele) => ele === option) && (
                <IconButton
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteInstruction(option);
                  }}
                  sx={{ padding: '4px' }}
                >
                  <CloseIcon sx={{ height: '20px', width: '20px' }} />
                </IconButton>
              )}
            </Stack>
          );
        }
        return (
          <Box
            onClick={() => {
              onAddInstruction(option.value);
            }}
            sx={{ width: '100%', padding: '4px', fontSize: '16px' }}
          >
            <Typography>{option.label}</Typography>
          </Box>
        );
      }}
      autoComplete
      handleHomeEndKeys
      autoHighlight
      filterOptions={(options, params) => {
        let filtered = filter(options, params);
        if (trim(params.inputValue) !== '') {
          if (!filtered.filter((ele) => typeof ele === 'string').some((option) => option === params.inputValue)) {
            filtered.push({
              label: `${intl.formatMessage({ id: 'add' })} "${params.inputValue}"`,
              value: params.inputValue,
            });
          }
        }
        return filtered;
      }}
      componentsProps={{
        ...props.componentsProps,
        paper: {
          sx: {
            '& .MuiAutocomplete-listbox li': {
              padding: 0,
            },
          },
        },
      }}
      onChange={async (...params) => {
        const [, option] = params;
        if (typeof option === 'object') {
          if (option) {
            await props.form.setValue(props.name, option.value as UnpackNestedValue<PathValue<TFieldValues, TName>>);
          }
        }
        props.onChange && props.onChange(...params);
      }}
      liStyle={{ padding: 0 }}
      showError
      hideError
      sx={{ '& .MuiAutocomplete-inputRoot': { backgroundColor: 'transparent' } }}
      placeholder="encounterPrescription.instruction"
      InputProps={{ variant: 'standard' }}
    />
  );
};

export default MedicationInstructionInput;
