import findDeep from 'deepdash/es/findDeep';
import { some } from '../common/constants';
import { EMPLOYEE_STATUS } from './constants';

export const mapSearchDataPractitioner = (entries: some[]) => {
  const returnValue: some[] = [];
  if (entries) {
    const data = entries.map((entry) => {
      return {
        name: entry.fullName,
        code: entry.code,
        email: entry.email,
        phoneNumber: entry.phoneNumber,
        id: entry.id,
        active: entry.status === EMPLOYEE_STATUS.active,
        address: entry.address,
        branch: entry.branch,
        contractInfo: entry.contractInfo,
        position: entry.position,
      };
    });
    returnValue.push(...data);
  }
  return returnValue.sort((a, b) => b.id - a.id);
};

export const mapSearchDataPractitionerRole = (entries: some[]) => {
  const practitionerMapper = new Map<string, some>();
  const returnValue: some[] = [];
  if (entries) {
    entries.forEach((entry) => {
      if (entry.resource.resourceType === 'Practitioner') {
        practitionerMapper.set(`Practitioner/${entry.resource.id}`, entry.resource);
      }
    });

    const data = entries
      .filter((entry) => entry.resource.resourceType === 'PractitionerRole')
      .map((entry) => {
        const practitioner = practitionerMapper.get(entry.resource.practitioner?.reference)!;
        // const email = practitioner.telecom?.find((v) => v.system === 'email')?.value;
        const name = practitioner.name?.[0]?.text;

        return {
          role: entry.resource.code?.[0]?.coding?.[0],
          id: entry.resource.id,
          employee: { label: name, value: practitioner.id },
          licenseNumber: entry.resource.identifier?.[0]?.value,
          specialty: entry.resource.specialty?.[0]?.text,
          accountId: entry.resource.username,
          authenId: entry.resource.authenId,
        };
      });
    returnValue.push(...data);
  }
  return returnValue.sort((a, b) => b.id - a.id);
};

export function collectRoleId(res: some) {
  const entries = res.entry || [];
  const retval: string[] = [];
  for (let entry of entries) {
    if (entry.resource.resourceType === 'PractitionerRole') {
      retval.push(entry.resource.id);
    }
  }
  return retval;
}

export function getDepartmentString(orgResource?: some) {
  if (!orgResource) {
    return '';
  }

  const code = findDeep(orgResource.identifier, (val) => val?.system === 'http://hl7.org/fhir/department-code', {
    leavesOnly: false,
  })?.value.value;
  return code + ' - ' + orgResource.alias?.[0];
}
