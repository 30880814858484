import { useDiagnosisDialogSelector, useDiagnosisDialogState } from './state';
import { UseFormReturn } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { CODE_SYSTEM, SYSTEM_CATEGORY_SCOPE, THERAPY_DEFINITION_STATUS } from 'modules/common/constants';
import { API_SERVER } from 'modules/common/api';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import { useFetch } from 'modules/common/hook';

export const useFetchAttachments = () => {
  const dentalInfoId = useDiagnosisDialogState((state) => state.dentalDetail.id);

  const setAttachments = useDiagnosisDialogSelector.use.setAttachment();
  return useFetch(
    API_SERVER.storageResource.getList({ referenceId: dentalInfoId, type: STORAGE_RESOURCE_TYPE.dentalAdult }),
    {
      onSuccess: setAttachments,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      enabled: dentalInfoId,
    },
  );
};

export const useFetchDentalInfo = (formMethods: UseFormReturn) => {
  const { patientId, dentalSessionId } = useParams<{ patientId; dentalSessionId }>();
  const setDentalDetail = useDiagnosisDialogSelector.use.setDentalDetail();
  const diagnosisSetting = useDiagnosisDialogState((state) => state.diagnosisSetting);
  return useFetch(
    patientId &&
      dentalSessionId &&
      diagnosisSetting &&
      API_SERVER.dental.getDentalInfo({
        dentalExamId: dentalSessionId,
        patientId: patientId,
        objectType: diagnosisSetting.dentalGroup!,
        objectDetail: diagnosisSetting?.dentalGroupDetail,
        position: diagnosisSetting?.position,
      }),

    {
      onSuccess: (data) => {
        setDentalDetail(data);
        formMethods.setValue('note', data.note);
      },
      shouldRetryOnError: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    },
  );
};

export const useFetchServices = () => {
  return useFetch(
    API_SERVER.service.getAllSystemCategoryList({
      scope: [
        SYSTEM_CATEGORY_SCOPE.surgical,
        SYSTEM_CATEGORY_SCOPE.laboratory,
        SYSTEM_CATEGORY_SCOPE.other,
        SYSTEM_CATEGORY_SCOPE.radiology,
      ],
    }),
  );
};

export const useFetchDiagnosisCodeSystem = () => {
  return useFetch(API_SERVER.code_system.getList({ type: CODE_SYSTEM.dentalDiagnosis }));
};

export const useFetchTherapyDefinitions = () => {
  return useFetch(API_SERVER.therapyManagement.getList({ status: THERAPY_DEFINITION_STATUS.ACTIVE }));
};
