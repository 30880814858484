import React, { useState } from 'react';
import { Box, Collapse, IconButton, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormattedMessage } from 'react-intl';

export type CollapseProps = React.PropsWithChildren<{
  titleKey: string;
  rightComponent?: React.ReactNode;
  open?: boolean;
}>;

const CollapseSection = ({ titleKey, rightComponent, open = true, children }: CollapseProps) => {
  const [openCollapse, setOpenCollapse] = useState(open);
  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          padding: '6px 16px',
          backgroundColor: '#F4F8FF',
          cursor: 'pointer',
        }}
        onClick={() => setOpenCollapse((oldState) => !oldState)}
      >
        <Stack direction="row" justifyContent="center" alignItems="center">
          <IconButton sx={{ padding: '4px' }}>
            <ArrowDropDownIcon
              sx={{
                transition: '0.2s all',
                transform: openCollapse ? 'rotate(0deg)' : 'rotate(180deg)',
                '& path': { fill: '#0052E0' },
              }}
            />
          </IconButton>
          <Typography
            sx={{ color: '#003CA6', fontSize: '16px', fontWeight: 500, lineHeight: '24px', letterSpacing: '0.15px' }}
            onClick={(event) => event.stopPropagation()}
          >
            <FormattedMessage id={titleKey} />
          </Typography>
        </Stack>
        {rightComponent}
      </Stack>
      <Collapse in={openCollapse}>
        <Box>{children}</Box>
      </Collapse>
    </Box>
  );
};

export default CollapseSection;
