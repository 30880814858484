import { Button, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import { useEditPICSelectors } from '../state';

const Action = () => {
  const onCloseDialog = useEditPICSelectors.use.onCloseDialog();
  return (
    <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
      <Button variant="outlined" color="primary" sx={{ padding: '5px 20px' }} type="button" onClick={onCloseDialog}>
        <Typography>
          <FormattedMessage id="encounter.encounterDetail.editPIC.editButton.title" />
        </Typography>
      </Button>
      <Button sx={{ padding: '5px 20px' }} type="submit">
        <Typography>
          <FormattedMessage id="encounter.encounterDetail.editPIC.saveButton.title" />
        </Typography>
      </Button>
    </Stack>
  );
};

export default Action;
