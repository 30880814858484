import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import Close from '@mui/icons-material/Close';
import Delete from '@mui/icons-material/Delete';
import WarningRounded from '@mui/icons-material/WarningRounded';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import {
  alpha,
  Box,
  Button,
  ButtonBase,
  Dialog,
  FormControl,
  FormControlLabel,
  InputLabel,
  Theme,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import LogoImage from '../../../component/LogoImage';
import useGeneralHook from '../../../hook/useGeneralHook';
import { QRCodeCanvas } from 'qrcode.react';
import DownloadIcon from '@mui/icons-material/Download';
import { SS_TOKEN } from 'modules/common/constants';
import axios from 'axios';
import { API_SERVER } from 'modules/common/api';

export interface UploadImageElementProps {
  readOnly?: boolean;
  disabled?: boolean;
  accept?: string | undefined;
  value?: string;
  onChange?: (value?: string) => void;
  label?: React.ReactNode;
  placeholder?: React.ReactNode;
  required?: boolean;
  error?: boolean;
  limitSizeImage?: number;
  style?: React.CSSProperties;
  avatarStyle?: React.CSSProperties;
  hideMessage?: boolean;
}

const UploadImageElement = React.forwardRef<HTMLInputElement, UploadImageElementProps>(
  (props: UploadImageElementProps, ref) => {
    const {
      onChange,
      readOnly,
      accept,
      value,
      required,
      label,
      placeholder,
      error,
      limitSizeImage,
      style,
      avatarStyle,
      disabled,
      hideMessage,
    } = props;
    const { setLoading, openSnackbar, intl } = useGeneralHook();
    const [open, setOpen] = useState<boolean>(false);
    const [openWarning, setOpenWarning] = useState<boolean>(false);
    const disabledTmp = readOnly || disabled;
    const qrcode = localStorage.getItem('qrcode');

    // upload logo
    const sendFile = useCallback(
      async (files: FileList) => {
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        const formData = new FormData();
        formData.append('file', files[0]);
        formData.append('description', '');
        formData.append('type', 'GROUP_AVATAR');
        formData.append('referenceId', '46');
        try {
          setLoading(true);
          const res = await axios.post(API_SERVER.s3Resource.upload, formData, {
            headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` },
          });
          onChange && onChange(res?.data?.id);
          setLoading(false);
        } catch (err) {
          console.error('Unable to copy to clipboard.', err);
          openSnackbar({ message: intl.formatMessage({ id: 'login.badLogin' }), type: 'error' });
        }
      },
      [intl, onChange, openSnackbar, setLoading],
    );

    // download QR Code
    const downloadQRCode = () => {
      const canvas = document.querySelector('#qrcode-canvas') as HTMLCanvasElement;
      if (!canvas) throw new Error('<canvas> not found in the DOM');
      const pngUrl = canvas.toDataURL('image/png').replace('image/png', 'image/octet-stream');
      const downloadLink = document.createElement('a');
      downloadLink.href = pngUrl;
      downloadLink.download = 'QR code.png';
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    return (
      <>
        <FormControl required={required} error={!!error} component="fieldset" fullWidth>
          <FormControlLabel
            style={{ alignItems: 'flex-start', margin: 0, cursor: value ? 'pointer' : 'unset', ...style }}
            control={
              <Box display="flex" alignItems="center">
                <Box
                  position="relative"
                  sx={{
                    '&:hover': {
                      '& .action-area': {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  <ButtonBase
                    sx={{
                      bgcolor: 'background.paper',
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                      overflow: 'hidden',
                    }}
                    onClick={() => value && setOpen(true)}
                  >
                    <LogoImage
                      alt=""
                      src={value}
                      style={{
                        height: 128,
                        width: 128,
                        objectFit: 'contain',
                        ...avatarStyle,
                      }}
                    />
                  </ButtonBase>
                  <Box
                    className="action-area"
                    position="absolute"
                    bottom={0}
                    right={0}
                    left={0}
                    top={0}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ opacity: value ? 0 : 1, transition: '0.3s all' }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="center">
                      {disabledTmp ? (
                        <ButtonBase
                          sx={{
                            bgcolor: 'background.paper',
                            padding: 1,
                            borderRadius: '50%',
                          }}
                          onClick={() => setOpen(true)}
                        >
                          <ZoomInIcon color="inherit" />
                        </ButtonBase>
                      ) : (
                        <Box display="flex">
                          {value && (
                            <ButtonBase
                              sx={{
                                bgcolor: 'background.paper',
                                padding: 1,
                                borderRadius: '50%',
                                marginRight: 1,
                              }}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                onChange && onChange('');
                              }}
                            >
                              <Delete color="inherit" fontSize="small" />
                            </ButtonBase>
                          )}
                          <ButtonBase
                            sx={{
                              bgcolor: 'background.paper',
                              padding: 1,
                              borderRadius: '50%',
                            }}
                            component="label"
                          >
                            <AddAPhotoIcon
                              style={{
                                top: '50%',
                                left: '50%',
                                transform: 'tranlate(-50%,-50%)',
                                backgroundColor: 'grey.100',
                              }}
                              color="primary"
                            />
                            <input
                              accept={accept || 'image/*'}
                              ref={ref}
                              hidden
                              type="file"
                              multiple
                              onChange={(event) => {
                                if (event.target.files && event.target.files?.length > 0) {
                                  sendFile(event.target.files);
                                }
                              }}
                            />
                          </ButtonBase>
                        </Box>
                      )}
                    </Box>
                    {!value && (
                      <Typography variant="body2" color="textSecondary" style={{ marginTop: 16 }} component="div">
                        {placeholder}
                      </Typography>
                    )}
                  </Box>
                </Box>
                {limitSizeImage && !hideMessage && (
                  <Typography variant="caption" color="textSecondary" sx={{ marginLeft: 2 }}>
                    <FormattedMessage id={'limitSizeImageNote'} values={{ num: limitSizeImage }} />
                  </Typography>
                )}
              </Box>
            }
            label={
              <InputLabel
                required={required}
                style={{
                  maxWidth: 'unset',
                  overflow: 'unset',
                }}
              >
                {label}
              </InputLabel>
            }
            labelPlacement={'top'}
          />
        </FormControl>
        <FormControl required={required} error={!!error} component="fieldset" fullWidth>
          <FormControlLabel
            style={{ alignItems: 'flex-start', margin: 0, cursor: value ? 'pointer' : 'unset', ...style }}
            control={
              <Box display="flex" alignItems="center">
                <Box
                  position="relative"
                  sx={{
                    '&:hover': {
                      '& .action-area': {
                        opacity: 1,
                      },
                    },
                  }}
                >
                  <ButtonBase
                    sx={{
                      bgcolor: 'background.paper',
                      border: 1,
                      borderColor: 'divider',
                      borderRadius: 1,
                      overflow: 'hidden',
                    }}
                  >
                    <QRCodeCanvas id="qrcode-canvas" level="H" size={271} value={qrcode || ''} />
                  </ButtonBase>
                  <Box
                    className="action-area"
                    position="absolute"
                    bottom={0}
                    right={0}
                    left={0}
                    top={0}
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ opacity: value ? 0 : 1, transition: '0.3s all' }}
                  >
                    <Box display="flex" alignItems="center" justifyContent="center">
                      <Box display="flex">
                        <Tooltip title={intl.formatMessage({ id: 'download' })}>
                          <ButtonBase
                            sx={{
                              bgcolor: 'background.paper',
                              padding: 1,
                              borderRadius: '50%',
                            }}
                            onClick={downloadQRCode}
                          >
                            <DownloadIcon color="inherit" fontSize="small" />
                          </ButtonBase>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            }
            label={
              <InputLabel
                required={required}
                style={{
                  maxWidth: 'unset',
                  overflow: 'unset',
                  marginTop: '10px',
                }}
              >
                <FormattedMessage id={'clinics.info.label.qrcode'} />
              </InputLabel>
            }
            labelPlacement={'top'}
          />
        </FormControl>
        <Dialog
          open={openWarning}
          onClose={() => {
            setOpenWarning(false);
          }}
          PaperProps={{
            style: {
              minWidth: 250,
              textAlign: 'center',
              padding: '16px 24px',
              alignItems: 'center',
              display: 'flex',
              flexDirection: 'column',
            },
          }}
        >
          <WarningRounded color="error" style={{ height: 160, width: 'auto' }} />
          <Typography variant="body1">
            <FormattedMessage id={'limitSizeImage'} values={{ num: limitSizeImage }} />
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => setOpenWarning(false)}
            style={{ marginTop: 16 }}
            fullWidth
          >
            <FormattedMessage id={'ok'} />
          </Button>
        </Dialog>
        <Dialog
          open={open}
          fullScreen
          PaperProps={{
            style: {
              alignItems: 'center',
              justifyContent: 'center',
              background: 'unset',
            },
          }}
          onClose={() => setOpen(false)}
        >
          <ButtonBase
            sx={{
              padding: 1,
              borderRadius: '50%',
              position: 'absolute',
              top: 8,
              right: 8,
              bgcolor: (theme: Theme) => alpha(theme.palette.common.black, 0.7),
            }}
            onClick={() => setOpen(false)}
          >
            <Close style={{ color: 'white' }} />
          </ButtonBase>
          <Box position="relative" marginX="auto" maxWidth={'70vw'}>
            {value && (
              <LogoImage
                alt=""
                src={value}
                style={{
                  maxWidth: '70vw',
                  maxHeight: '80vh',
                  objectFit: 'cover',
                }}
              />
            )}
          </Box>
        </Dialog>
      </>
    );
  },
);

export default UploadImageElement;
