import { some } from 'modules/common/constants';
import * as React from 'react';
import { Switch, Typography } from '@mui/material';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import QuantityBox from 'modules/common/component/QuantityBox';

interface Props {
  data?: some[];
  loading?: boolean;
  actionNode?: (item: some, index: number) => React.ReactNode;
  updateQuantity?: (item: some) => void;
  updateMedicalSupplies?: (item: some) => void;
  selection?: some[];
  setSelection?: (item: some[]) => void;
  onChangeIsFreeStatus: (item: some) => void;
  indicationList?: some[];
}

const MedicalSuppliesTableList: React.FunctionComponent<Props> = (props) => {
  const { data, selection, loading, setSelection, actionNode, onChangeIsFreeStatus, updateMedicalSupplies } = props;

  const columns = React.useMemo(() => {
    return [
      {
        title: 'therapy.medicalSupplies.category',
        render: (record) => {
          return <Typography>{record.medicalSuppliesGroup}</Typography>;
        },
        width: 300,
      },
      {
        title: 'therapy.medicalSupplies.name',
        dataIndex: 'medicalSuppliesName',
      },
      {
        title: 'therapy.medicalSupplies.quantity',
        dataIndex: 'quantity',
        width: 150,
        render: (record) => {
          return (
            <QuantityBox
              min={1}
              readOnly={record?.isDisable}
              reduceProps={() => ({ disabled: record?.isDisable })}
              addProps={() => ({ disabled: record?.isDisable })}
              value={record.quantity}
              onChange={(value) => {
                updateMedicalSupplies && updateMedicalSupplies({ ...record, quantity: value });
              }}
            />
          );
        },
      },
      {
        title: 'therapyIndication.header.label.isFree',
        align: 'center',
        dataIndex: 'isFree',
        width: 300,
        render: (record) => {
          return (
            <Switch
              checked={!record.isFree}
              disabled={record?.isDisable}
              onChange={(e) =>
                onChangeIsFreeStatus({
                  ...record,
                  isFree: e.target.checked ? false : true,
                })
              }
            />
          );
        },
      },

      !!actionNode && {
        title: 'action',
        align: 'right',
        width: 100,
        render: (record, index) => {
          return actionNode && actionNode(record, index);
        },
      },
    ] as Columns[];
  }, [actionNode, updateMedicalSupplies, onChangeIsFreeStatus]);

  return (
    <>
      {!!setSelection ? (
        <TableCustom
          dataSource={data}
          loading={loading}
          columns={columns}
          rowSelection={{
            selectedRowKeys: selection,
            onChange: ({ selectedRows }) => setSelection(selectedRows),
          }}
        />
      ) : (
        <TableCustom dataSource={data} loading={loading} columns={columns} />
      )}
    </>
  );
};

export default MedicalSuppliesTableList;
