import { Typography } from '@mui/material';

import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { DentalExam } from 'modules/schema';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, DENTAL_EXAM_STATUS, FE_DATE_FORMAT } from 'modules/common/constants';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';

interface IEncounterListProps {
  data?: DentalExam[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  refresh: () => void;
}

const DentalList: React.FunctionComponent<IEncounterListProps> = ({
  refresh,
  data,
  loading,
  hookPagination,
  total,
}) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;

  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounter.table.label.sequenceNumber',
        dataIndex: 'sequenceNumber',
        align: 'left',
        width: 90,
      },
      {
        title: 'encounterId',
        dataIndex: 'code',
        align: 'right',
      },

      {
        title: 'patientCodeShort',
        render: (record) => {
          return <Typography>{record?.patient?.code?.split('-')?.[1] || record?.patient?.resourceId}</Typography>;
        },
        align: 'right',
      },
      {
        title: 'patientName',
        dataIndex: 'patient.name',
      },

      {
        title: 'birthdayShort',
        render: (record) => (
          <Typography>
            {record.patient?.dob && moment(record.patient.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
        width: 120,
      },

      {
        title: 'telephone',
        dataIndex: 'patient.mobilePhone',
      },

      {
        title: 'encounterDate',
        render: (record) => (
          <Typography>
            {record.createdTime && moment(record.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
      },
      {
        title: 'encounterDoctor',
        dataIndex: 'picName',
      },
      {
        title: 'checkUpType',
        dataIndex: 'serviceName',
      },
      {
        title: 'status',
        render: (record) => {
          const status = DENTAL_EXAM_STATUS[record?.status!];
          return (
            <Typography
              noWrap
              sx={{
                color: status?.sx || 'primary.main',
                // fontWeight: '500',
              }}
            >
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
      },

      {
        title: 'checkupRoom',
        dataIndex: 'physicsRoomName',
      },
    ] as Columns<DentalExam>[];
  }, []);

  return (
    <TableCustom
      dataSource={data}
      loading={loading}
      columns={columns}
      paginationProps={{
        count: total,
        page: pagination.page,
        rowsPerPage: pagination.pageSize,
        onPageChange: onPageChange,
        onRowsPerPageChange: onRowsPerPageChange,
      }}
    />
  );
};

export default DentalList;
