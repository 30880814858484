import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { PAYMENT_HISTORY_TYPE, PAYMENT_METHOD, SERVICE_TYPE_ALL } from 'modules/common/apiConstants';

export const RevenueExpenditureNewSchema = z
  .object({
    id: z.number(),
    parentId: z.number(),
    paymentMethod: createObjectKeysEnumSchema(PAYMENT_METHOD),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    incomeAmount: z.number(),
    outcomeAmount: z.number(),
    employeeName: z.string(),
    patientName: z.string(),
    paymentHistoryType: createObjectKeysEnumSchema(PAYMENT_HISTORY_TYPE),
    paymentType: createObjectKeysEnumSchema(SERVICE_TYPE_ALL),
    referenceCode: z.string(),
    mobilePhone: z.string(),
  })
  .partial();

export type RevenueExpenditureNew = z.infer<typeof RevenueExpenditureNewSchema>;
