import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import { Box, Button, Collapse, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import MediCard from 'modules/common/component/MediCard';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import { axiosThunk } from 'modules/common/redux/axios';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useEffect } from 'react';
import { NumericFormatText } from 'modules/common/utils';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { useFetch } from 'modules/common/hook';

interface Props {
  data: some;
  setFormDataGroup: (item: some) => void;
  onDeleteGroup: (item: some) => void;
  loading?: boolean;
  isPreview?: boolean;
  isInsurance?: boolean;
  revalidateCategory: () => void;
  refreshMedicationCategoryId?: number | null;
  setRefreshMedicationCategoryId?(categoryId: number | null): void;
}

const DrugTable: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    revalidateCategory,
    setFormDataGroup,
    onDeleteGroup,
    isPreview,
    refreshMedicationCategoryId,
    setRefreshMedicationCategoryId,
  } = props;
  const { dispatch, openSnackbar, confirmDialog, locationId, intl, API_SERVER } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const { params, setParams } = usePaginationHook();
  const [open, setOpen] = React.useState(false);
  const [selection, setSelection] = React.useState<some[]>([]);
  const specializedDepartmentsMap = useSelector((state: AppState) => state.common.specializedDepartments);

  const {
    data: listData = [],
    revalidate,
    isValidating,
  } = useFetch(API_SERVER.medication.index({ categoryId: data.id, name: params.name }), { enabled: open });

  useEffect(() => {
    if (data.id === refreshMedicationCategoryId) {
      revalidate().then(() => setRefreshMedicationCategoryId && setRefreshMedicationCategoryId(null));
    }
  }, [data.id, refreshMedicationCategoryId, revalidate, setRefreshMedicationCategoryId]);

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.code }),
      });
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.medication.update(value.id, { categoryId: data?.id }),
            method: 'delete',
            data: value,
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
            revalidate();
            revalidateCategory();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });
      }
      close();
    },
    [
      API_SERVER.medication,
      close,
      data?.id,
      dispatch,
      intl,
      openSnackbar,
      promptConfirmation,
      revalidate,
      revalidateCategory,
    ],
  );

  const onDeleteMultiple = React.useCallback(
    async (data) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: `${selection.length} item` }),
      });
      if (confirm && selection?.length > 0) {
        dispatch(setLoading(true));
        await dispatch(
          axiosThunk({
            url: API_SERVER.medication.index(locationId).url,
            method: 'delete',
            data: { ids: selection.map((v) => v.id), categoryId: data?.id },
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
            revalidate();
            revalidateCategory();
            setSelection([]);
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors?.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });

        dispatch(setLoading(false));
      }
      close();
    },
    [
      API_SERVER.medication,
      close,
      dispatch,
      intl,
      locationId,
      openSnackbar,
      promptConfirmation,
      revalidate,
      revalidateCategory,
      selection,
    ],
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: 'pharmacy.code',
        dataIndex: 'code',
      },
      {
        title: 'pharmacy.drugName',
        dataIndex: 'name',
      },
      {
        title: 'pharmacy.registerNumber',
        dataIndex: 'registrationNumber',
      },
      {
        title: 'pharmacy.unitShort',
        dataIndex: 'numeratorUnit',
      },
      {
        title: 'pharmacy.intendedRoute',
        dataIndex: 'intendedRoute.label',
        render: (record) => (
          <Box component="ul" margin={0} paddingLeft={2}>
            {record.intendedRoute &&
              record.intendedRoute
                //.filter((one) => one.value)
                .map((route) => (
                  <Typography component={'li'}>{` ${route.label || ''} - ${route.value || ''}`}</Typography>
                ))}
          </Box>
        ),
      },
      {
        title: 'pharmacy.groupCode',
        dataIndex: 'codeGroup',
      },
      {
        title: 'pharmacy.departmentCode',
        render: (record) =>
          Object.values(specializedDepartmentsMap).find((ele) => ele.id === record.specializedDepartmentId)?.code,
      },
      {
        title: 'pharmacy.ingredients',
        dataIndex: 'ingredient',
      },
      {
        title: 'pharmacy.declarePrice',
        dataIndex: 'publicPrice',
        align: 'right',
        render: (record) => NumericFormatText(record.publicPrice),
      },
      {
        title: 'action',
        align: 'right',
        width: 100,
        render: (record) => {
          return (
            <>
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  setParams({ ...params, medicationId: record?.id, medicationCategoryId: data?.id });
                }}
                style={{ margin: '0px 8px' }}
              >
                <EditIcon color={record.validation === false ? 'error' : undefined} />
              </IconButtonTitle>
              <IconButtonTitle
                title="delete"
                size="small"
                onClick={() => {
                  onDelete({
                    ...record,
                    listId: data?.id,
                    listData: data?.items,
                    allItems: data?.allItems,
                  });
                  setSelection([]);
                }}
              >
                <DeleteIcon />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns[];
  }, [specializedDepartmentsMap, setParams, params, data?.id, data?.items, data?.allItems, onDelete]);

  if (!data || (data?.total === 0 && !!params.name)) {
    return null;
  }

  return (
    <>
      <MediCard
        style={{ margin: '8px 0px', color: 'white' }}
        title={
          <Box
            display="flex"
            alignItems="center"
            onClick={() => {
              setOpen((old) => !old);
            }}
            style={{ cursor: 'pointer' }}
          >
            <Box flex={1} display="flex" alignItems="center">
              <ExpandLessIcon
                style={{
                  transition: '0.2s all',
                  transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                  marginRight: 16,
                }}
              />
              <Typography variant="subtitle1" color="inherit">
                {data.name}&nbsp;({data?.total || 0})
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButtonTitle
                title="create"
                size="small"
                color="inherit"
                onClick={(e) => {
                  setParams({
                    medicationCategoryId: data.id,
                  });
                }}
              >
                <NoteAddIcon />
              </IconButtonTitle>
              <IconButtonTitle
                title="update"
                size="small"
                color="inherit"
                onClick={(e) => {
                  setFormDataGroup(data);
                }}
                style={{ margin: '0px 8px' }}
              >
                <EditIcon />
              </IconButtonTitle>
              <IconButtonTitle
                title="delete"
                size="small"
                color="inherit"
                disabled={data?.total > 0 ? !selection.length : !!data?.total && !isPreview}
                onClick={(e) => {
                  e.stopPropagation();
                  if (data?.total > 0) {
                    onDeleteMultiple(data);
                  } else {
                    onDeleteGroup(data);
                  }
                }}
              >
                <DeleteIcon />
              </IconButtonTitle>
            </Box>
          </Box>
        }
      >
        <Collapse in={open} unmountOnExit>
          <Box padding={1}>
            <TableCustom
              dataSource={listData}
              loading={isValidating}
              columns={columns}
              rowSelection={{
                selectedRowKeys: selection,
                onChange: ({ selectedRows }) => setSelection(selectedRows),
              }}
              caption={
                <Button
                  size="large"
                  fullWidth
                  variant="text"
                  startIcon={<NoteAddIcon />}
                  color="primary"
                  onClick={() =>
                    setParams({
                      medicationCategoryId: data.id,
                    })
                  }
                >
                  <Typography variant="subtitle2">
                    <FormattedMessage id="pharmacy.addNew" />
                  </Typography>
                </Button>
              }
            />
          </Box>
        </Collapse>
      </MediCard>
    </>
  );
};

export default DrugTable;
