import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import moment from 'moment';
import { ROUTES } from '../../../layout/router';
import Stack from '@mui/material/Stack';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';

interface Props {
  dataTherapyDetail?: some;
  handleFinishTherapy?: () => void;
  handleCancelTherapy?: () => void;
}

const TherapyPatientInfo: React.FunctionComponent<Props> = (props) => {
  const { dataTherapyDetail, handleFinishTherapy, handleCancelTherapy } = props;
  // age patient
  const ageMonth = () => {
    const yearNow = new Date().getFullYear();
    const yearDetail = new Date(dataTherapyDetail?.patient?.dob).getFullYear();
    return yearNow - yearDetail;
  };

  return (
    <>
      <Box className="box-child pd10">
        <Box display={'flex'} gap={2}>
          <Typography variant="subtitle1" color="primary" noWrap>
            {dataTherapyDetail?.patient?.name}
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="birthdayShort" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {moment(dataTherapyDetail?.patient?.dob).format('DD/MM/YYYY')}
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="telephone" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {dataTherapyDetail?.patient?.mobilePhone}
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="age_" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {ageMonth()}
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="gender" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {dataTherapyDetail?.patient?.gender === 'FEMALE' ? 'Nữ' : 'Nam'}
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="calendar.label.personInCharge" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {dataTherapyDetail?.picName}
            </Typography>
          </Typography>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="therapy.label.customer.code" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {dataTherapyDetail?.patient?.code.split('-')[1]}
            </Typography>
          </Typography>
          {dataTherapyDetail?.encounterResourceId && (
            <Typography variant="body2" color="textSecondary" noWrap>
              <FormattedMessage id="cashierPrint.encounterId" />
              :&nbsp;
              <Typography
                variant="subtitle2"
                color="rgb(0, 82, 224)"
                component={'span'}
                sx={{ textDecoration: 'underline', cursor: 'pointer' }}
                onClick={() => {
                  window.open(
                    ROUTES.encounterDetail.gen(dataTherapyDetail.encounterResourceId, dataTherapyDetail.patient.id),
                    '_blank',
                  );
                }}
              >
                {dataTherapyDetail.encounterResourceId}
              </Typography>
            </Typography>
          )}
          <Box flex={1} />
        </Box>
        <Box>
          <Typography variant="body2" color="textSecondary" noWrap>
            <FormattedMessage id="therapy.register.reason" />
            :&nbsp;
            <Typography variant="subtitle2" color="textPrimary" component={'span'}>
              {dataTherapyDetail?.registerReason}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box className="box-child pd10">
        <Box className="flex-space-between">
          <Box display={'flex'} gap={2}>
            <Typography
              variant="body2"
              color="textSecondary"
              noWrap
              sx={{ textOverflow: 'unset', minWidth: 'fit-content' }}
            >
              <FormattedMessage id="therapy.detail.label.therapyNumber" />
              :&nbsp;
              <Typography variant="subtitle2" color="textPrimary" component={'span'}>
                {dataTherapyDetail?.code}
              </Typography>
            </Typography>
            <Typography variant="body2" color="textSecondary" sx={{ textOverflow: 'unset', wordBreak: 'break-all' }}>
              <FormattedMessage id="diagnosis" />
              :&nbsp;
              <Typography variant="subtitle2" color="textPrimary" component={'span'}>
                {dataTherapyDetail?.diagnosis && extractHTMLContent(dataTherapyDetail?.diagnosis)}
              </Typography>
            </Typography>
          </Box>
          <Stack direction="row" flexWrap="nowrap">
            {dataTherapyDetail?.status !== 'FINISHED' && dataTherapyDetail?.status !== 'CANCELLED' && (
              <>
                <Button
                  style={{ height: 32, minWidth: 180, marginRight: 10 }}
                  onClick={handleCancelTherapy}
                  variant="outlined"
                >
                  <FormattedMessage id="encounterInfo.cancelTherapy" />
                </Button>
                <Button style={{ height: 32, minWidth: 180 }} onClick={handleFinishTherapy}>
                  <FormattedMessage id="therapy.detail.label.done" />
                </Button>
              </>
            )}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default TherapyPatientInfo;
