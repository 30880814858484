import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { AppState } from '../../../../../../redux/reducer';
import { setLoading } from '../../../../../common/redux/commonReducer';
import RouteLeavingGuard from '../../../../../common/component/RouteLeavingGuard';
import SchemaForm from '../../../../../common/SchemaForm';
import PrintFormDialog from './PrintFormDialog';
import schema from './schema';
import { INDICATION_REQUEST_STATUS, some } from '../../../../../common/constants';
import { useParams } from 'react-router';
import { API_SERVER } from '../../../../../common/api';
import { axiosThunk } from '../../../../../common/redux/axios';
import { useEffect } from 'react';
import { useFetch } from 'modules/common/hook';
import { IndicationImage, IndicationRequest } from 'modules/schema';

interface Props {
  indicationRequest?: IndicationRequest;
  patient: some;
  readOnly?: boolean;
  indicationRequestRevalidate: () => void;
}

const IndicationImageDoctorTab: React.FunctionComponent<Props> = (props) => {
  const { patient, readOnly, indicationRequest, indicationRequestRevalidate } = props;
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const evaluationFormMap = useSelector((state: AppState) => state.common.evaluationForms);
  const refSchemaForm = React.useRef<UseFormReturn<any>>();
  const [open, setOpen] = React.useState(false);
  const { indicationRequestId } = useParams<{ indicationRequestId: string }>();

  const formMethods = useForm<IndicationImage>({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const { data: indicationImage, revalidate: indicationImageRevalidate } = useFetch(
    API_SERVER.indicationImage.get(parseInt(indicationRequestId)),
  );
  useEffect(() => {
    formMethods.reset({ ...(indicationImage || []), indicationRequestId: parseInt(indicationRequestId) });
  }, [formMethods, indicationImage, indicationRequestId]);

  const { reset } = formMethods;

  const onComplete = React.useCallback(
    async (value: some) => {
      try {
        if (indicationRequest?.status !== INDICATION_REQUEST_STATUS.FINISHED.value) {
          const confirm = await confirmDialog.promptConfirmation({
            title: intl.formatMessage({ id: 'confirmCompleteTitle' }),
            content: intl.formatMessage(
              {
                id: 'confirmComplete',
              },
              { name: value?.indicationName },
            ),
          });

          if (confirm) {
            dispatch(setLoading(true));

            await dispatch(
              axiosThunk({
                url: API_SERVER.indicationImage.finishIndication(),
                method: 'put',
                data: {
                  ...value,
                  examDepartment: value?.examDepartment || '',
                  conclusion: value?.conclusion || '',
                },
              }),
            );
            await indicationRequestRevalidate();
            openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.success' }) });
          }
        }
      } catch (e) {
        reset(value);
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.fail' }), type: 'error' });
      } finally {
        confirmDialog.close();
        dispatch(setLoading(false));
      }
    },
    [indicationRequest?.status, confirmDialog, intl, dispatch, indicationRequestRevalidate, openSnackbar, reset],
  );

  const onSave = React.useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      try {
        await dispatch(
          axiosThunk({
            url: API_SERVER.indicationImage.update(),
            method: 'put',
            data: {
              ...value,
              examDepartment: value?.examDepartment || '',
              conclusion: value?.conclusion || '',
            },
          }),
        );
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editSucceeds' }) });
        indicationImageRevalidate();
      } catch (e) {
        reset(value);
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editFails' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, openSnackbar, intl, indicationImageRevalidate, reset],
  );

  return (
    <Box>
      <RouteLeavingGuard
        when={Object.values(formMethods?.formState.dirtyFields)?.length > 0 ? formMethods?.formState.isDirty : false}
        message={intl.formatMessage({ id: 'subclinical.youHaveUnsavedData' }, { name: indicationRequest?.serviceName })}
      />
      <Box marginBottom={2}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="serviceName" />
            </Typography>
            <Typography variant="body2">{indicationRequest?.serviceName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="doctorOfIndication" />
            </Typography>
            <Typography variant="body2">{indicationRequest?.picName}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="note" />
            </Typography>
            <Typography variant="body2">{indicationRequest?.note}</Typography>
          </Grid>
        </Grid>
      </Box>
      <SchemaForm
        ref={refSchemaForm}
        schema={schema}
        evaluationFormMap={evaluationFormMap}
        formId="schemaClinical"
        methods={formMethods}
        readOnly={readOnly}
        patientData={patient}
        indicationRequest={indicationRequest}
        onSubmit={onComplete}
        onPrint={() => {
          setOpen(true);
        }}
        onSave={onSave}
      />
      {indicationRequest && (
        <PrintFormDialog
          open={open}
          onClose={() => {
            setOpen(false);
          }}
          indicationRequest={indicationRequest}
          patient={patient}
          indicationImage={indicationImage}
        />
      )}
    </Box>
  );
};

export default IndicationImageDoctorTab;
