import { Box, Button, IconButton, List, ListItem, ListItemText, Stack, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { IndicationRequest } from 'modules/schema';
import DialogCustom from 'modules/common/component/DialogCustom';
import { Add, Remove } from '@mui/icons-material';

type IndicationRequestType = Omit<IndicationRequest, 'createdTime' | 'startTime' | 'endTime'> & { name: string };

interface Props {
  open: boolean;
  onClose?: () => void;
  onSubmit: (value: IndicationRequestType[]) => void;
  indicationAdd: IndicationRequestType[];
}

const AddIndicationDialog = (props: Props) => {
  const { onClose, open, indicationAdd, onSubmit } = props;
  const [items, setItems] = React.useState<IndicationRequestType[]>([]);

  React.useEffect(() => {
    setItems(indicationAdd.map((item) => ({ ...item, quantity: 1 })));
  }, [indicationAdd]);

  const handleIncrease = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) => (item.id === id ? { ...item, quantity: (item.quantity || 0) + 1 } : item)),
    );
  };

  const handleDecrease = (id) => {
    setItems((prevItems) =>
      prevItems.map((item) =>
        item.id === id && item.quantity && item.quantity > 0 ? { ...item, quantity: item.quantity - 1 } : item,
      ),
    );
  };

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 600 },
      }}
      title={'encounter.title.indication.request.diaglog'}
      footer={
        <Box display="flex" alignItems="flex-end" justifyContent="flex-end">
          <Stack paddingX={1}>
            <Button variant="outlined" onClick={onClose}>
              <FormattedMessage id="status.cancelled" />
            </Button>
          </Stack>
          <Stack paddingX={1}>
            <Button variant="contained" onClick={() => onSubmit(items)}>
              <FormattedMessage id="request" />
            </Button>
          </Stack>
        </Box>
      }
    >
      <Box padding={2}>
        <List dense={false}>
          {items.map((item, index) => (
            <ListItem
              key={item.id}
              secondaryAction={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <IconButton edge="end" onClick={() => item.quantity !== 0 && handleDecrease(item.id)}>
                    <Remove color="primary" />
                  </IconButton>
                  <Typography sx={{ mx: 2 }}>{item?.quantity}</Typography>
                  <IconButton edge="start" onClick={() => item.quantity! < 10 && handleIncrease(item.id)}>
                    <Add color="primary" />
                  </IconButton>
                </Box>
              }
            >
              <ListItemText
                primary={`${index + 1}. ${item.name}`}
                sx={{
                  maxWidth: 450,
                }}
              />
            </ListItem>
          ))}
        </List>
      </Box>
    </DialogCustom>
  );
};

export default AddIndicationDialog;
