import { Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { BE_DATE_FORMAT } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { NUMBER_REGEX } from '../../../common/regex';
import SchemaForm from '../../../common/SchemaForm';
import { omitBy } from 'lodash';
import { Option } from 'modules/common/type';
import { UserEmployeeRole } from 'modules/schema';
import { CalendarType } from 'modules/reception/page/AppointmentCalendar';

interface Props {
  hookPagination: HookPaginationProps;
  type?: CalendarType;
  doctorAndNurseOptions: (Option & UserEmployeeRole)[];
}

const FilterCalendarDay: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, type = 'DAY', doctorAndNurseOptions } = props;
  const { params, setParams, clearParams } = hookPagination;
  return (
    <SchemaForm
      formProps={{ style: { flex: 1 } }}
      schema={{
        fields: ({ formProps }) => {
          const { intl } = formProps || {};
          return {
            date: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'calendar.date' }),
              register: {
                required: true,
                validate: (value) => {
                  return value
                    ? moment(value, BE_DATE_FORMAT, true).isValid()
                      ? true
                      : intl.formatMessage({ id: 'validation.invalidDate' })
                    : true;
                },
              },
              hidden: type !== 'DAY',
              propsWrapper: { xs: 2 },
            },
            dateRange: {
              type: 'dateRange',
              label: intl.formatMessage({ id: 'calendar.date' }),
              register: {
                required: true,
              },
              hidden: type !== 'WEEK',
              propsWrapper: { xs: 4 },
            },
            picUserIds: {
              type: 'select',
              label: intl.formatMessage({ id: 'calendar.label.personInCharge' }),
              placeholder: intl.formatMessage({ id: 'calendar.label.allPersonInCharge' }),
              options: doctorAndNurseOptions,
              propsWrapper: { xs: 2 },
              multiple: true,
              acceptNone: true,
              rawOptions: true,
              displayEmpty: true,
            },
            mobilePhone: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'searchByTel' }),
              placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="inherit" />
                  </InputAdornment>
                ),
              },
              showClearBtn: true,
              register: { pattern: { value: NUMBER_REGEX, message: intl.formatMessage({ id: 'telephoneInvalid' }) } },
              propsWrapper: { xs: 3 },
            },
            search: {
              type: 'submitButton',
              color: 'inherit',
              label: intl.formatMessage({ id: 'search' }),
              propsWrapper: { xs: undefined },
              style: { minWidth: 120, marginTop: 20 },
            },
            reset: {
              type: 'button',
              onClick: () => clearParams && clearParams(),
              color: 'inherit',
              label: intl.formatMessage({ id: 'reset' }),
              propsWrapper: { xs: undefined },
              style: { minWidth: 120, marginTop: 20 },
            },
          };
        },
      }}
      onSubmit={(value) => {
        if (value.picUserIds && value.picUserIds.length > 0) {
          setParams(value);
        } else {
          setParams(omitBy(value, 'picUserIds'));
        }
      }}
      formData={params}
      hideSubmitButton
    />
  );
};

export default FilterCalendarDay;
