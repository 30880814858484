import { Delete } from '@mui/icons-material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { alpha, Box, Button, ButtonBase, Grid, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { PropsArrayElement } from '../../../../../common/SchemaForm/element/array-element/ArrayElement';
import { setLoading } from '../../../../../common/redux/commonReducer';
import { API_SERVER } from '../../../../../common/api';
import { some, SS_TOKEN } from '../../../../../common/constants';
import MediCard from '../../../../../common/component/MediCard';
import { MedicalIcon } from '../../../../../../svg';
import NoDataBox from '../../../../../common/component/NoDataBox';
import CameraImageGalleryDialog from './CameraImageGalleryDialog';
import ImageGalleryDialog from './ImageGalleryDialog';
import { STORAGE_RESOURCE_TYPE } from '../../../../../reception/constants';
import { useParams } from 'react-router';
import { axiosThunk } from '../../../../../common/redux/axios';
import axios from 'axios/index';
import ImagePreview from '../../../../../common/component/ImagePreview';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import Stack from '@mui/material/Stack';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import * as React from 'react';
import { useFetch } from 'modules/common/hook';

const useStyles = makeStyles(() => ({
  image: {
    '&:hover': {
      '& .action-area': {
        opacity: 1,
      },
    },
    '& .action-area': {
      opacity: 0,
      transition: '0.3s all',
    },
  },
}));
interface Props extends PropsArrayElement {}

const ImageBox = (props: Props) => {
  const classes = useStyles();
  const { formProps } = props;
  const { indicationRequestId } = useParams<{ indicationRequestId: string }>();
  const { dispatch, openSnackbar, intl, readOnly, patientData } = formProps;
  const { confirmDialog } = useGeneralHook();
  const [open, setOpen] = useState<undefined | number>(undefined);
  const [openCamera, setOpenCamera] = useState<boolean>(false);

  const { data: images, revalidate: imagesRevalidate } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.therapyIndicationDiagnosticImage,
      referenceId: parseInt(indicationRequestId),
    }),
  );

  const onSaveImage = async (images) => {
    try {
      dispatch(setLoading(true));
      const files = await Promise.all(
        images
          ?.filter((ele) => !!ele?.imageSrc)
          ?.map((ele, i) => {
            return fetch(ele?.imageSrc)
              .then((res) => res.blob())
              .then(
                (res) =>
                  new File([res], 'screen ' + i + '.jpg', {
                    lastModified: new Date().getTime(),
                    type: 'image/jpeg',
                  }),
              );
          }) || [],
      );
      if (files && files.length > 0) {
        let multipartFormData = new FormData();
        files.forEach((ele) => multipartFormData.append('files', ele));
        const types = files?.map((ele) => STORAGE_RESOURCE_TYPE.therapyIndicationDiagnosticImage) || [];
        const referenceIds = files?.map((ele) => indicationRequestId) || [];
        const descriptions = files?.map((ele, index) => images?.[index]?.comment || ' ') || [];
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        await axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );
      }
      await imagesRevalidate();
      setOpenCamera(false);
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editFails' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onDeleteImage = useCallback(
    async (id: number) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDeleteFile' }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.storageResource.delete(id), method: 'delete' }));
          await imagesRevalidate();
          await openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, imagesRevalidate, openSnackbar],
  );

  const onAttachImage = useCallback(
    async (files: FileList) => {
      dispatch(setLoading(true));
      try {
        let multipartFormData = new FormData();
        const types: string[] = [];
        const referenceIds: string[] = [];
        const descriptions: string[] = [];
        for (let i = 0; i < files.length; i++) {
          multipartFormData.append('files', files[i]);
          types.push(STORAGE_RESOURCE_TYPE.therapyIndicationDiagnosticImage);
          referenceIds.push(indicationRequestId);
          descriptions.push(' ');
        }
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        await axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );

        await imagesRevalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'exam.addNewSucceeds' }) });
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, imagesRevalidate, indicationRequestId, intl, openSnackbar],
  );

  return (
    <>
      <MediCard title={'subclinical.image'}>
        <Box padding={2}>
          {!readOnly && (
            <Stack direction="row" marginBottom={2} gap={2}>
              <Button
                color="inherit"
                startIcon={<MedicalIcon />}
                sx={{ width: 'fit-content' }}
                onClick={() => setOpenCamera(true)}
              >
                <FormattedMessage id="camera.connection" />
              </Button>
              <IconButton
                component="label"
                sx={{
                  borderRadius: '4px',
                  backgroundColor: '#CDDFFF',
                  color: '#0052E0',
                  fontSize: '14px',
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  letterSpacing: '0.02857em',
                  width: '181px',
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ objectFit: 'cover', position: 'relative' }}
                  gap={1}
                >
                  <input
                    accept="image/*"
                    hidden
                    type="file"
                    multiple
                    onChange={(event) => {
                      if (event.target.files && event.target.files?.length > 0) {
                        onAttachImage(event.target.files);
                      }
                    }}
                  />
                  <AddPhotoAlternateIcon color="primary" />
                  <FormattedMessage id="therapy.detail.label.attackImage" />
                </Stack>
              </IconButton>
            </Stack>
          )}
          <Grid container spacing={2}>
            {images && images?.length > 0
              ? images?.map((item: some, index: number) => {
                  return (
                    <Grid item key={item.mediaId} xs={2}>
                      <Box position="relative" className={classes.image}>
                        <ButtonBase
                          style={{ background: grey[300], height: 180, width: '100%' }}
                          onClick={() => setOpen(index)}
                        >
                          <ImagePreview
                            alt=""
                            imageId={item.id}
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </ButtonBase>
                        <Box
                          className="action-area"
                          position="absolute"
                          bottom={0}
                          right={0}
                          left={0}
                          top={0}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box display="flex">
                            {!readOnly && (
                              <IconButton
                                size="small"
                                style={{ background: alpha(grey[100], 0.8), marginRight: 16 }}
                                onClick={(e) => {
                                  onDeleteImage(item?.id);
                                }}
                              >
                                <Delete color="inherit" fontSize="small" />
                              </IconButton>
                            )}
                            <IconButton
                              size="small"
                              style={{ background: alpha(grey[100], 0.8) }}
                              onClick={() => setOpen(index)}
                            >
                              <ZoomInIcon color="inherit" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                      <Stack alignItems="center">{item?.description}</Stack>
                    </Grid>
                  );
                })
              : readOnly && (
                  <Box display={'flex'} justifyContent="center" width="100%">
                    <NoDataBox />
                  </Box>
                )}
          </Grid>
        </Box>
      </MediCard>
      <ImageGalleryDialog open={open !== undefined} onClose={() => setOpen(undefined)} images={images} index={open} />
      <CameraImageGalleryDialog
        patientData={patientData}
        open={openCamera}
        onClose={() => setOpenCamera(false)}
        onSave={onSaveImage}
      />
    </>
  );
};

export default memo(ImageBox);
