import { Box, InputAdornment, Typography } from '@mui/material';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import { PAYMENT_STATUS } from 'modules/common/apiConstants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { NumericFormatText } from 'modules/common/utils';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { IFormData, caculatePrice, caculateSumPrice } from './utils';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { keyBy } from 'lodash';
import PayButton from './PayButton';
import BillingFormButton from './BillingFormButton';
import { Encounter, Payment } from 'modules/schema';

interface Props {
  encounter?: Encounter;
  payment?: Payment;
}

const FinalPriceInfo = ({ encounter, payment }: Props) => {
  const { intl } = useGeneralHook();
  const paymentDetailMap = useMemo(() => keyBy(payment?.paymentDetails, 'id'), [payment?.paymentDetails]);
  const paymentList = useWatch<IFormData, `paymentList`>({ name: 'paymentList' });
  const discountPercent = useWatch<IFormData, 'discountPercent'>({ name: 'discountPercent' });
  const finalPrice = React.useMemo<ReturnType<typeof caculatePrice>>(
    () => caculateSumPrice(paymentList, discountPercent, paymentDetailMap),
    // Warning: Sử dụng json.stringify(paymentList)
    // vì khi input của field thay đổi thì paymentList không tạo array mới mà chỉ update field
    // nên sẽ useMemo không được gọi lại nếu chỉ paymentList
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [discountPercent, paymentDetailMap, JSON.stringify(paymentList)],
  );

  return (
    <Box display="flex" justifyContent="flex-end" paddingBottom={3} marginRight={3}>
      <Box display="flex" flexDirection="column" gap={1} width={420} marginTop={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">
            <FormattedMessage id={'cashier.totalPrice'} />
          </Typography>
          <Typography variant="subtitle1">{NumericFormatText(finalPrice?.baseAmount)}</Typography>
        </Box>{' '}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="subtitle1">
            <FormattedMessage id={'cashier.totalDiscountBill'} />
          </Typography>
          {payment?.paymentDetails?.some(
            (paymentDetail) =>
              paymentDetail?.status === PAYMENT_STATUS.PAID.value ||
              paymentDetail?.status === PAYMENT_STATUS.REFUNDED.value,
          ) ? (
            <Typography variant="subtitle1">
              {discountPercent && <FormattedNumber value={discountPercent} />} %
            </Typography>
          ) : (
            <SchemaElement
              fieldName={'discountPercent'}
              propsElement={{
                type: 'text-field',
                placeholder: intl.formatMessage({
                  id: 'cashier.enter',
                }),
                inputType: 'number',
                style: { width: 80 },
                propsWrapper: { xs: undefined },
                noHelperText: true,
                InputProps: {
                  endAdornment: <InputAdornment position="end">%</InputAdornment>,
                },
                register: { max: 100, min: 0 },
              }}
            />
          )}
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">
            <FormattedMessage id={'cashier.billDiscountAmount'} />
          </Typography>
          <Typography variant="subtitle1">{NumericFormatText(finalPrice?.receiptDiscountAmount)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">
            <FormattedMessage id={'cashier.totalDiscount'} />
          </Typography>
          <Typography variant="subtitle1">{NumericFormatText(finalPrice?.finalDiscountAmount)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1" color="primary">
            <FormattedMessage id={'cashier.totalPriceAfterDiscountAndVat'} />
          </Typography>
          <Typography variant="subtitle1">{NumericFormatText(finalPrice?.patientPay)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">
            <FormattedMessage id={'customerPaid'} />
          </Typography>
          <Typography variant="subtitle1">{NumericFormatText(finalPrice?.customerPaid)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">
            <FormattedMessage id={'therapy.prepaid.summary.prepayment.amount'} />
          </Typography>
          <Typography variant="subtitle1">{NumericFormatText(encounter?.payment?.prepayment)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="subtitle1">
            <FormattedMessage id={'cashier.discountItem'} />
          </Typography>
          <Typography variant="subtitle1">{NumericFormatText(finalPrice?.discountOnPaid)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" paddingBottom={1} borderBottom="1px solid grey">
          <Typography variant="subtitle1">
            <FormattedMessage id={'customerHasBeenRefunded'} />
          </Typography>
          <Typography variant="subtitle1">{NumericFormatText(encounter?.payment?.refundAmount)}</Typography>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6" color="primary">
            <FormattedMessage id={'therapy.payment.summaryPrintForm.title.customerPay'} />
          </Typography>
          <Typography variant="subtitle1">
            {NumericFormatText(finalPrice?.patientPay - finalPrice?.customerPaid)}
          </Typography>
        </Box>
        <Box display="flex" gap={2}>
          <BillingFormButton encounter={encounter} paymentDetailMap={paymentDetailMap} />
          <PayButton paymentDetailMap={paymentDetailMap} payment={payment} encounterId={encounter?.id} />
        </Box>
      </Box>
    </Box>
  );
};

export default FinalPriceInfo;
