import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import { LAB_TYPE_RESULT_CORE_SERVICE, VALUE_EVALUATE, some } from 'modules/common/constants';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import { mergeFieldName } from 'modules/common/SchemaForm/utils';
import { displayRange } from 'modules/common/utils';
import { OPTIONS_REVIEW, OPTIONS_REVIEW_LIST } from 'modules/labDoctor/constants';
import { DECIMAL_NUMBER_REGEX } from 'modules/common/regex';

export const TableCellPadding = withStyles({
  root: {
    padding: '8px 16px',
  },
})(TableCell);

const Row: React.FunctionComponent<{ row: some; name: string; index: number }> = (props) => {
  const { row, name, index } = props;
  const [open, setOpen] = React.useState(false);
  const intl = useIntl();
  const methods = useFormContext();
  const { watch, setValue, unregister } = methods;
  const resultSwitch = watch(mergeFieldName({ parent: name, name: 'isChoose', index }));

  // map data result
  const dataResult = (data: some[]) => {
    const arr = data?.map((i: some) => {
      return {
        value: i.name,
        label: i.name,
      };
    });
    return arr;
  };

  return (
    <>
      {row?.laboratoryReferenceDetailObject?.resultType === LAB_TYPE_RESULT_CORE_SERVICE.quantity ? (
        <>
          <TableCustomRow>
            <TableCustomCell style={{ width: 70 }}>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                <KeyboardArrowDownIcon
                  style={{
                    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 200ms',
                    fontSize: 24,
                  }}
                />
              </IconButton>
            </TableCustomCell>
            <TableCustomCell colSpan={2}>{row.laboratoryReferenceName}</TableCustomCell>
            <TableCustomCell>
              <SchemaElement
                fieldName={mergeFieldName({ parent: name, name: 'result', index })}
                propsElement={{
                  type: 'text-field',
                  placeholder: intl.formatMessage({ id: 'referenceIndication.resultEnter' }),
                  noHelperText: true,
                  register: {
                    pattern: DECIMAL_NUMBER_REGEX,
                  },
                  onBlur: (e) => {
                    const value = Number(e.target.value);
                    if (e.target.value?.trim() && !Number.isNaN(value)) {
                      let select;
                      let tmp;
                      if (row?.laboratoryReferenceDetailObject?.laboratoryConditionList?.length === 1) {
                        tmp = row?.laboratoryReferenceDetailObject?.laboratoryConditionList[0];
                      } else {
                        tmp = row?.laboratoryReferenceDetailObject?.laboratoryConditionList?.find(
                          (v) =>
                            v.condition === (row?.gender === 'male' ? 'Nam' : 'Nữ') ||
                            v.condition === (row?.gender === 'male' ? 'Male' : 'Female'),
                        );
                      }
                      if (tmp) {
                        if (tmp.lowIndex !== undefined && tmp.highIndex !== undefined) {
                          if (value >= tmp.lowIndex && value <= tmp.highIndex) {
                            select = VALUE_EVALUATE.normal;
                          } else if (value < tmp.lowIndex) {
                            select = VALUE_EVALUATE.low;
                          } else if (value > tmp.highIndex) {
                            select = VALUE_EVALUATE.high;
                          }
                        } else if (tmp.lowIndex === undefined && tmp.highIndex !== undefined) {
                          if (value <= tmp.highIndex) {
                            select = VALUE_EVALUATE.normal;
                          } else {
                            select = VALUE_EVALUATE.high;
                          }
                        } else if (tmp.lowIndex !== undefined && tmp.highIndex === undefined) {
                          if (value >= tmp.lowIndex) {
                            select = VALUE_EVALUATE.normal;
                          } else {
                            select = VALUE_EVALUATE.low;
                          }
                        }
                        setValue(mergeFieldName({ parent: name, name: 'evaluate', index }), select);
                      }
                    }
                  },
                }}
              />
            </TableCustomCell>
            <TableCustomCell>{row.laboratoryReferenceDetailObject.unit}</TableCustomCell>
            <TableCustomCell>
              <SchemaElement
                fieldName={mergeFieldName({ parent: name, name: 'evaluate', index })}
                propsElement={{
                  type: 'select',
                  input: null,
                  noHelperText: true,
                  placeholder: intl.formatMessage({ id: 'referenceIndication.review' }),
                  disableClearBtn: true,
                  options: OPTIONS_REVIEW,
                }}
              />
            </TableCustomCell>
          </TableCustomRow>
          <TableCustomRow>
            <TableCustomCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1} bgcolor="white">
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCellPadding style={{ width: 46 }} />
                        <TableCellPadding style={{ width: '31%' }}>
                          <FormattedMessage id="referenceIndication.condition" />
                        </TableCellPadding>
                        <TableCellPadding>
                          <FormattedMessage id="referenceIndication.value" />
                        </TableCellPadding>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.laboratoryReferenceDetailObject.laboratoryConditionList?.map(
                        (record: some, index: number) => (
                          <TableRow key={index}>
                            <TableCellPadding />
                            <TableCellPadding>{record.name}</TableCellPadding>
                            <TableCellPadding>{displayRange(record.highIndex, record.lowIndex)}</TableCellPadding>
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCustomCell>
          </TableCustomRow>
        </>
      ) : row?.laboratoryReferenceDetailObject?.resultType === LAB_TYPE_RESULT_CORE_SERVICE.list ? (
        <>
          <TableCustomRow>
            <TableCustomCell style={{ width: 70 }}>
              <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
                <KeyboardArrowDownIcon
                  style={{
                    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 200ms',
                    fontSize: 24,
                  }}
                />
              </IconButton>
            </TableCustomCell>
            <TableCustomCell>{row.laboratoryReferenceName}</TableCustomCell>
            <TableCustomCell>
              <Tooltip arrow placement="top" title={intl.formatMessage({ id: 'referenceIndication.switchTypeResult' })}>
                <Box>
                  <SchemaElement
                    fieldName={mergeFieldName({ parent: name, name: 'isChoose', index })}
                    propsElement={{
                      type: 'switch',
                      noHelperText: true,
                      onChange: (value) => {
                        setValue(mergeFieldName({ parent: name, name: 'select', index }), null);
                        if (value) {
                          unregister(mergeFieldName({ parent: name, name: 'result', index }));
                        }
                      },
                    }}
                  />
                </Box>
              </Tooltip>
            </TableCustomCell>
            <TableCustomCell>
              {resultSwitch ? (
                <SchemaElement
                  fieldName={mergeFieldName({ parent: name, name: 'result', index })}
                  propsElement={{
                    type: 'select',
                    input: null,
                    noHelperText: true,
                    placeholder: intl.formatMessage({ id: 'referenceIndication.resultPick' }),
                    options: dataResult(row.laboratoryReferenceDetailObject.laboratorySelectionList) || [],
                    rawOptions: true,
                    onChange: () => {
                      setValue(mergeFieldName({ parent: name, name: 'select', index }), '');
                    },
                  }}
                />
              ) : (
                <SchemaElement
                  fieldName={mergeFieldName({ parent: name, name: 'result', index })}
                  propsElement={{
                    type: 'text-field',
                    placeholder: intl.formatMessage({ id: 'referenceIndication.resultEnter' }),
                    noHelperText: true,
                    register: {
                      pattern: DECIMAL_NUMBER_REGEX,
                    },
                  }}
                />
              )}
            </TableCustomCell>
            <TableCustomCell>{row.laboratoryReferenceDetailObject.unit}</TableCustomCell>
            <TableCustomCell>
              <SchemaElement
                fieldName={mergeFieldName({ parent: name, name: 'evaluate', index })}
                propsElement={{
                  type: 'select',
                  input: null,
                  noHelperText: true,
                  placeholder: intl.formatMessage({ id: 'referenceIndication.review' }),
                  disableClearBtn: true,
                  onChange: () => {
                    setValue(mergeFieldName({ parent: name, name: 'review', index }), '');
                  },
                  options: resultSwitch ? OPTIONS_REVIEW_LIST : OPTIONS_REVIEW,
                }}
              />
            </TableCustomCell>
          </TableCustomRow>
          <TableCustomRow>
            <TableCustomCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box margin={1} bgcolor="white">
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        <TableCellPadding style={{ width: 46 }} />
                        <TableCellPadding style={{ width: '31%' }}>
                          <FormattedMessage id="referenceIndication.condition" />
                        </TableCellPadding>
                        <TableCellPadding>
                          <FormattedMessage id="referenceIndication.value" />
                        </TableCellPadding>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {row.laboratoryReferenceDetailObject.laboratoryConditionList?.map(
                        (record: some, index: number) => (
                          <TableRow key={index}>
                            <TableCellPadding />
                            <TableCellPadding>{record.name}</TableCellPadding>
                            <TableCellPadding>{displayRange(record.highIndex, record.lowIndex)}</TableCellPadding>
                          </TableRow>
                        ),
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCustomCell>
          </TableCustomRow>
        </>
      ) : (
        <>
          <TableCustomRow>
            <TableCustomCell style={{ width: 70 }}></TableCustomCell>
            <TableCustomCell colSpan={2}>{row.laboratoryReferenceName}</TableCustomCell>
            <TableCustomCell colSpan={2}>
              <SchemaElement
                fieldName={mergeFieldName({ parent: name, name: 'result', index })}
                propsElement={{
                  type: 'text-field',
                  placeholder: intl.formatMessage({ id: 'referenceIndication.resultEnter' }),
                  noHelperText: true,
                }}
              />
            </TableCustomCell>
            <TableCustomCell colSpan={2}></TableCustomCell>
          </TableCustomRow>
        </>
      )}
    </>
  );
};

const IndexListTable: React.FunctionComponent = () => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'items',
  });

  return (
    <TableContainer>
      <Table stickyHeader>
        <TableHead>
          <TableCustomRow>
            <TableHeaderCell style={{ width: 70 }}></TableHeaderCell>
            <TableHeaderCell>
              <FormattedMessage id="referenceIndication.name" />
            </TableHeaderCell>
            <TableHeaderCell style={{ width: '5%' }}></TableHeaderCell>
            <TableHeaderCell style={{ width: '25%' }}>
              <FormattedMessage id="referenceIndication.result" />
            </TableHeaderCell>
            <TableHeaderCell style={{ width: '15%' }}>
              <FormattedMessage id="referenceIndication.unit" />
            </TableHeaderCell>
            <TableHeaderCell style={{ width: '25%' }}>
              <FormattedMessage id="referenceIndication.review" />
            </TableHeaderCell>
          </TableCustomRow>
        </TableHead>
        <TableBody>
          {fields?.map((row, index) => (
            <Row key={index} row={row} name={'items'} index={index} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default IndexListTable;
