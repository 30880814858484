import { Box, Stack, Tooltip, Typography } from '@mui/material';
import DateInput from 'modules/common/component/form/DateInput';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Filter from 'modules/common/component/filter';
import Report from 'modules/common/component/report';
import { useDialog, useFetch } from 'modules/common/hook';
import Table, { TableOptions } from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { RevenueExpenditureNew } from 'modules/schema/RevenueExpenditureNew';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { API_SERVER } from 'modules/common/api';
import {
  PAYMENT_ADVANCE_STATUS,
  PAYMENT_HISTORY_TYPE,
  PAYMENT_METHOD,
  SERVICE_TYPE_INCOME,
} from 'modules/common/apiConstants';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReportFilterOption } from 'modules/common/component/report/type';
import { DEFAULT_CONFIG } from 'modules/common/component/filter/const';
import SelectInput from 'modules/common/component/form/SelectInput';
import { FormSchema, SearchParams } from './type';
import { DEFAULT_VALUES, FILTER_CONFIGS } from './consts';
import DialogCustom from 'modules/common/component/DialogCustom';
import TextValue from 'modules/common/component/PrintForm/TextValue';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { axiosThunk } from 'modules/common/redux/axios';
import { PaymentDetail, PaymentHistoryDetail } from 'modules/schema';
import { formatEndDate, formatStartDate, isValidDate, maxDateTable, NumericFormatText } from 'modules/common/utils';
import { map } from 'lodash';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import InfoIcon from '@mui/icons-material/Info';
import { BUSINESS_API } from 'modules/common/service';

export const FILTER_KEY = 'INCOME_REPORT_FILTER';
export const FILTER_KEY_VALUE = 'INCOME_REPORT_FILTER_VALUE';
export const SELECT_COLUMNS_KEY = 'INCOME_REPORT_SELECT_COLUMNS';

interface Props {}

const IncomeReportTable: React.FunctionComponent<Props> = () => {
  const filterConfig = JSON.parse(localStorage.getItem(FILTER_KEY_VALUE)!);
  const intl = useIntl();
  const { dispatch } = useGeneralHook();
  const { page, pageSize, paginationProps, resetPage } = useTablePagination();
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const [openDetailDialog, onOpenDetailDialog, onCloseDetailDialog] = useDialog();
  const [searchParams, setSearchParams] = React.useState<SearchParams>(DEFAULT_VALUES);
  const [filterReportOptions, setFilterReportOptions] = React.useState<ReportFilterOption[]>([]);
  const [paymentHistoryDetailData, setPaymentHistoryDetailData] = useState<PaymentHistoryDetail>();
  const [recordData, setRecordData] = useState<RevenueExpenditureNew>();
  const [summaryData, setSummaryData] = useState<RevenueExpenditureNew>({});

  // Get data payment history detail
  const paymentHistoryDetail = async (id) => {
    const { data } = await dispatch(
      axiosThunk({
        url: API_SERVER.paymentHistory.getDetail(id).url,
        method: 'get',
      }),
    );
    setPaymentHistoryDetailData(data);
    onOpenDetailDialog();
  };

  const COLUMNS: Column<RevenueExpenditureNew>[] = [
    { type: 'INDEX', width: '30px', sticky: true, align: 'center' },
    {
      title: 'revenueReport.date',
      render: (record) => record.createdTime?.format(FE_DATE_FORMAT),
      renderTitle: (
        <Tooltip title={intl.formatMessage({ id: 'report.income.date.title.tooltip' })}>
          <InfoIcon
            sx={{ marginBottom: '-5px', paddingLeft: '5px', height: '20px', width: '20px', color: '#7eadff' }}
          />
        </Tooltip>
      ),
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'therapy.cashier.dialog.label.paymentHistory.paymentType',
      render: (record) => <FormattedMessage id={PAYMENT_HISTORY_TYPE?.[record?.paymentHistoryType!]?.label || ' '} />,
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'therapy.therapyReport.detail.filter.label.examCode',
      render: (record) => (
        <Typography
          onClick={() => {
            paymentHistoryDetail(record?.parentId ? record.parentId : record?.id);
            setRecordData(record);
          }}
          color={'#0052E0'}
          fontSize={'14px'}
          sx={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {record?.referenceCode}
        </Typography>
      ),
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'sellingProduct.title.customerName',
      render: 'patientName',
      selectColumnConfig: {
        defaultChecked: true,
      },
      fieldName: 'patientName',
      enableEllipsis: true,
      maxWidth: '100px',
    },
    {
      title: 'label.report.revenueExpenditure.incomeMoney',
      fieldName: 'incomeAmount',
      render: (record) => NumericFormatText(record.incomeAmount),
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'label.report.revenueExpenditure.outcomeMoney',
      fieldName: 'outcomeAmount',
      render: (record) => NumericFormatText(record.outcomeAmount),
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'cashier.paymentMethod',
      render: (record) => <FormattedMessage id={PAYMENT_METHOD?.[record?.paymentMethod!]?.label || ' '} />,
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'paymentReport.cashierName',
      render: 'employeeName',
      selectColumnConfig: {
        defaultChecked: true,
      },
      fieldName: 'employeeName',
      enableEllipsis: true,
      maxWidth: '100px',
    },
  ];

  const COLUMNS_PAYMENT_DETAIL: Columns<PaymentDetail>[] = [
    {
      title: 'detail',
      dataIndex: 'content',
    },
    {
      title: 'quantity',
      dataIndex: 'quantity',
    },
    {
      title: 'indication.unit',
      render: (record) => record?.unit || <FormattedMessage id="cashier.time" />,
    },
    {
      title: 'price',
      render: (record) => NumericFormatText(record?.unitPrice),
    },
    {
      title: 'cashier.discountAmount',
      render: (record) => NumericFormatText(record?.discountAmount! + record?.billDiscountAmount!),
    },
    {
      title: 'totalPrice',
      render: (record) => NumericFormatText(record?.amountPaid),
    },
    {
      title: 'vatPercent',
      dataIndex: 'vat',
    },
    {
      title: 'label.report.revenueExpenditure.total',
      render: (record) => NumericFormatText(record?.totalAmount),
    },
    {
      title: 'note',
      dataIndex: 'note',
    },
  ];

  const COLUMNS_PAYMENT_DETAIL_REFUND: Columns<PaymentDetail>[] = [
    {
      title: 'cashier.service',
      dataIndex: 'content',
    },
    {
      title: 'printForm.SL',
      dataIndex: 'quantity',
    },
    {
      title: 'pharmacy.unitShort',
      render: (record) => record?.unit || <FormattedMessage id="cashier.time" />,
    },
    {
      title: 'price',
      render: (record) => NumericFormatText(record?.unitPrice),
    },
    {
      title: 'cashier.discountAmount',
      render: (record) => NumericFormatText(record?.discountAmount! + record?.billDiscountAmount!),
    },
    {
      title: 'totalPrice',
      render: (record) => NumericFormatText(record?.totalAmount),
    },
    {
      title: 'vatPercent',
      render: (record) => NumericFormatText(record?.vat),
    },
    {
      title: 'cashier.vatValue',
      render: (record) => NumericFormatText(record?.vatAmount),
    },
    {
      title: 'therapy.cashier.paymentRefundHistory.printDialog.header.paidAmount',
      render: (record) => NumericFormatText(Math.abs(record?.amountPaid!)),
    },
  ];

  const [selectedColumns, setSelectedColumn] = React.useState<Column<RevenueExpenditureNew>[]>(
    COLUMNS?.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );

  const form = useForm<FormSchema>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate =
    moment(toDateOrigin) < moment() ? formatEndDate(moment(toDateOrigin).add(-1, 'day')) : formatEndDate(moment());
  const paymentTypes = form.watch('type');
  const allOptions = intl.formatMessage({ id: 'all' });

  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      paymentTypes: paymentTypes
        ? map(paymentTypes, 'value')
        : map([SERVICE_TYPE_INCOME.ENCOUNTER, SERVICE_TYPE_INCOME.THERAPY], 'value'),
      page: page,
      pageSize: pageSize,
      fromDate: fromDate,
      toDate: toDate,
    };
  }, [searchParams, paymentTypes, page, pageSize, fromDate, toDate]);

  // Get data revenue & expenditure
  const { data: reportPage, isValidating } = useFetch(BUSINESS_API.getCashBookPaging(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'TABLE'),
  });

  useEffect(() => {
    if (reportPage && reportPage.summary && Object.keys(reportPage.summary).length > 0 && !isValidating) {
      setSummaryData(reportPage.summary);
    }
  }, [reportPage, isValidating]);

  const TABLE_OPTIONS = useMemo(() => {
    let tableOptions: TableOptions = {
      position: 'top',
      startIndex: COLUMNS.length === selectedColumns.length ? 4 : 0,
      record: summaryData,
    };
    return tableOptions;
  }, [summaryData, COLUMNS.length, selectedColumns.length]);

  // Get print data revenue & expenditure
  const { data: printDataPage } = useFetch(
    BUSINESS_API.getCashBookPaging({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    {
      globalLoading: true,
      revalidateOnFocus: false,
      enabled: openPrintDialog && isValidDate(fromDate, toDate, 'TABLE'),
    },
  );

  // Export data revenue & expenditure
  const exportUrl = React.useMemo(
    () => BUSINESS_API.getCashBookExcel({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    [formatSearchParams],
  );

  React.useEffect(() => {
    setFilterReportOptions([
      { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      {
        title: DEFAULT_CONFIG.SERVICE.title,
        value:
          paymentTypes && paymentTypes.length !== 0
            ? paymentTypes?.map((ele) => intl.formatMessage({ id: ele.label })).join(', ')
            : allOptions,
      },
      {
        title: DEFAULT_CONFIG.PAYMENT_METHODS.title,
        value:
          filterConfig?.PAYMENT_METHODS?.length === 0
            ? allOptions
            : filterConfig?.PAYMENT_METHODS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
      },
      {
        title: 'account.cashier',
        value:
          filterConfig?.EMPLOYEE?.length === 0
            ? allOptions
            : filterConfig?.EMPLOYEE.map((ele) => intl.formatMessage({ id: ele.employeeFullName })).join(', '),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions, fromDate, intl, paymentTypes, toDate]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title
        title="report.businessReport.tab.title.incomeReport"
        showSubTitle
        foundResultAmount={reportPage?.content?.length || 0}
        totalElementAmount={reportPage?.pagination.totalElements}
        renderRight={
          <Stack direction="row" alignItems="center" justifyContent="center" gap="16px" paddingTop={2.5}>
            <Report.SearchInput
              form={form}
              name="keyword"
              onSearch={() => {
                setSearchParams((prev) => ({ ...prev, keyword: form.getValues('keyword') }));
                resetPage();
              }}
            />
          </Stack>
        }
      />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue, filterConfig) => {
          if (fromDate && toDate) {
            let paymentHistoryTypes;
            if (filterValue.TRANSACTION_TYPE.length > 0) {
              paymentHistoryTypes = filterValue.TRANSACTION_TYPE;
            } else {
              paymentHistoryTypes = null;
            }
            setSearchParams((prev: any) => ({
              ...prev,
              paymentMethods: filterValue.PAYMENT_METHODS,
              cashierEmployeeIds: filterValue.EMPLOYEE_CASHIER,
              paymentHistoryTypes: paymentHistoryTypes,
            }));
            resetPage();
          }
          setFilterReportOptions([
            { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            {
              title: DEFAULT_CONFIG.SERVICE.title,
              value:
                paymentTypes && paymentTypes.length !== 0
                  ? paymentTypes?.map((ele) => intl.formatMessage({ id: ele.label })).join(', ')
                  : allOptions,
            },
            {
              title: DEFAULT_CONFIG.PAYMENT_METHODS.title,
              value:
                filterConfig?.PAYMENT_METHODS?.length === 0
                  ? allOptions
                  : filterConfig?.PAYMENT_METHODS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
            {
              title: 'account.cashier',
              value:
                filterConfig?.EMPLOYEE?.length === 0
                  ? allOptions
                  : filterConfig?.EMPLOYEE.map((ele) => intl.formatMessage({ id: ele.employeeFullName })).join(', '),
            },
          ]);
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                options={Object.values(SERVICE_TYPE_INCOME)}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                multiple
                showCheckBox
                onChange={resetPage}
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
                type="TABLE"
                onChange={resetPage}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                maxDate={maxDateTable(fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                type="TABLE"
                onChange={resetPage}
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() => {
                    form.reset();
                    resetPage();
                    setSearchParams(DEFAULT_VALUES);
                  }}
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <Report.SelectColumnButton
              columns={COLUMNS}
              onSave={setSelectedColumn}
              localStorageKey={SELECT_COLUMNS_KEY}
            />
            <Report.ExportDataButton exportUrl={exportUrl} />
            <Report.PrintDataButton
              title="navMenu.paymentBook"
              openDialog={openPrintDialog}
              onOpenDialog={onOpenPrintDialog}
              onCloseDialog={onClosePrintDialog}
              content={
                <Stack direction="column" gap={1}>
                  <Report.FilterPrintFormat filterOptions={filterReportOptions} />
                  <Table
                    data={printDataPage?.content}
                    columns={selectedColumns}
                    dataKey="id"
                    rowProps={{ sx: { pageBreakAfter: 'auto' } }}
                  />
                </Stack>
              }
            />
            <DialogCustom
              open={openDetailDialog}
              onClose={onCloseDetailDialog}
              PaperProps={{
                style: { width: '80vw' },
              }}
              maxWidth="xl"
              title={'label.report.revenueExpenditure.revenueExpenditureDetail'}
            >
              <Box padding={'9px 64px 48px 64px'}>
                <Typography fontSize={'28px'} textTransform={'uppercase'} textAlign={'center'} margin={'20px 0'}>
                  <FormattedMessage id="label.report.revenueExpenditure.transactionDetail" />
                </Typography>
                <Stack direction="row" justifyContent="space-between" marginBottom={'30px'}>
                  <Box flex={1.5}>
                    <TextValue
                      label="sellingProduct.title.customerName"
                      value={recordData?.patientName}
                      labelWidth="140px"
                    />
                    <TextValue label="telephone" value={recordData?.mobilePhone} labelWidth="140px" />
                    <TextValue
                      label="therapy.therapyReport.detail.filter.label.examCode"
                      value={recordData?.referenceCode}
                      labelWidth="140px"
                    />
                    <TextValue
                      label="therapy.cashier.dialog.label.paymentHistory.transactionTime"
                      value={moment(paymentHistoryDetailData?.paymentHistories![0]?.transactionTime).format(
                        FE_DATE_FORMAT,
                      )}
                      labelWidth="140px"
                    />
                  </Box>
                </Stack>
                <TableCustom
                  containerProps={{
                    sx: {
                      '& th,tr,td': {
                        padding: '9px 8px',
                        backgroundColor: 'white',
                        border: '0.5px solid',
                        color: 'unset',
                      },
                      'tbody>tr td:nth-child(2)': {
                        padding: '1px 5px',
                      },
                      width: '100%',
                      overflow: 'visible',
                      marginTop: '8px',
                    },
                  }}
                  dataSource={paymentHistoryDetailData?.paymentDetails}
                  columns={
                    paymentHistoryDetailData?.paymentDetails?.find(
                      (i) => i.status === PAYMENT_ADVANCE_STATUS.REFUNDED.value,
                    )
                      ? COLUMNS_PAYMENT_DETAIL_REFUND
                      : COLUMNS_PAYMENT_DETAIL
                  }
                />
              </Box>
            </DialogCustom>
          </Stack>
        </Stack>
      </Filter>
      <Table
        columns={selectedColumns}
        options={TABLE_OPTIONS}
        dataKey="id"
        data={reportPage?.content}
        paginationProps={paginationProps}
        totalElements={reportPage?.pagination.totalElements}
        stickyHeader
        maxHeigth="100%"
      />
    </Stack>
  );
};

export default IncomeReportTable;
