import { FormControl as FormControlMUI, FormHelperText, InputLabel } from '@mui/material';
import { ReactElement } from 'react';
import { FieldError } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { OverrideFormControlProps } from './type';

export interface Props extends OverrideFormControlProps {
  htmlFor?: string;
  label?: string;
  rawLabel?: boolean;
  rawError?: boolean;
  error?: FieldError;
  required?: boolean;
  children: ReactElement;
  hideError?: boolean;
  fullWidth?: boolean;
  disabled?: boolean;
}

const FormControl = ({
  label,
  rawLabel = false,
  error,
  required = false,
  children,
  htmlFor,
  controlProps,
  labelProps,
  helpTextProps,
  hideError = false,
  fullWidth = true,
  disabled = false,
  rawError = false,
}: Props) => {
  const intl = useIntl();
  return (
    <FormControlMUI
      fullWidth={fullWidth}
      required={required}
      {...controlProps}
      error={typeof error === 'boolean' ? !!error : !!error?.message}
      disabled={disabled}
    >
      {label === null || label === undefined ? null : (
        <InputLabel
          htmlFor={htmlFor}
          {...labelProps}
          sx={{
            color: '#607D8B',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.15px',
            marginBottom: '4px',
            height: '24px',
          }}
        >
          {rawLabel ? label : <FormattedMessage id={label} />}
        </InputLabel>
      )}
      {children}
      {!hideError && (
        <FormHelperText {...helpTextProps} sx={{ height: '14px' }}>
          {/* {error ? (rawError ? error.message : intl.formatMessage({ id: error.message })) : null} */}
          {error?.message && intl.formatMessage({ id: error.message })}
        </FormHelperText>
      )}
    </FormControlMUI>
  );
};

export default FormControl;
