import { z } from 'zod';
import { IndicationRequestSchema } from './IndicationRequest';
import { IndicationLaboratorySchema } from './IndicationLaboratory';

export const IndicationLaboratoryListSchema = z
  .object({
    indicationRequest: IndicationRequestSchema,
    indicationLaboratoryList: z.array(IndicationLaboratorySchema),
  })
  .partial();

export type IndicationLaboratoryList = z.infer<typeof IndicationLaboratoryListSchema>;
