import {
  Box,
  Button,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { API_SERVER } from 'modules/common/api';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { keyBy } from 'lodash';
import { DentalToothDetailSchema, StorageResource } from 'modules/schema';
import { z } from 'zod';
import { ODONTOGRAM_TYPES } from './constants';
import { EXAM_TARGETS, DENTAL_DIAGNOSIS_STATUS, ADULT_TOOTH_MAP, CHILD_TOOTH_MAP } from '../common/constants';
import { axiosThunk } from 'modules/common/redux/axios';
import { useParams } from 'react-router';
import { useDiagnosisDialogSelector } from './StatusAndDiagnosisDialog/state';
import StatusAndDiagnosisDialog from './StatusAndDiagnosisDialog';
import { styled } from '@mui/system';
import DentalDiagnosticImage from './DentalDiagnosticImage';
import { useDentalDetailStateSelector } from '../state';
import { useFetchDentalInfoBoard } from '../common/fetcher';
import { useFetch } from 'modules/common/hook';

const createOdontogramRow = (toothMap: typeof ADULT_TOOTH_MAP | typeof CHILD_TOOTH_MAP) =>
  [
    {
      type: ODONTOGRAM_TYPES.outsideSurface,
      position: toothMap.top,
    },
    {
      type: ODONTOGRAM_TYPES.occlusalSurface,
      position: toothMap.top,
    },
    {
      type: ODONTOGRAM_TYPES.insideSurface,
      position: toothMap.top,
    },
    {
      type: ODONTOGRAM_TYPES.insideSurface,
      position: toothMap.bottom,
    },
    {
      type: ODONTOGRAM_TYPES.occlusalSurface,
      position: toothMap.bottom,
    },
    {
      type: ODONTOGRAM_TYPES.outsideSurface,
      position: toothMap.bottom,
    },
  ] as const;

const ButtonPositionStyled = styled(Button)({
  padding: '5px',
  minWidth: 'unset',
  border: '1px solid #ECEFF1',
  fontSize: '12px',
  lineHeight: '16px',
  fontStyle: 'normal',
  letterSpacing: '0.4',
  color: '#000',
});

const ButtonStyled = styled(Button)({
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  minWidth: '120px',
});

const Odontogram = () => {
  const { dispatch, appState } = useGeneralHook();
  const examTarget = useDentalDetailStateSelector.use.examTarget();
  const setExamTarget = useDentalDetailStateSelector.use.setExamTarget();
  const { patientId, dentalSessionId } = useParams<{ patientId; dentalSessionId }>();
  const onOpenDiagnosisDialog = useDiagnosisDialogSelector.use.onOpenDialog();
  const setDentalExamInfo = useDentalDetailStateSelector.use.setDentalExamInfo();
  const { revalidate } = useFetchDentalInfoBoard(dentalSessionId, patientId);
  const readOnly = useDentalDetailStateSelector.use.readonly();

  useFetch(API_SERVER.dental.getDentalExam(dentalSessionId), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    onSuccess: (data) => {
      appState.authen.user?.user_id && setDentalExamInfo(data, appState.authen.user?.user_id);
    },
  });

  const { data: odontogramData, mutate } = useFetch(
    API_SERVER.dental.getListOdontogram({ dentalExamId: dentalSessionId, patientId }),
  );

  const toothMap = examTarget === 'ADULT' ? ADULT_TOOTH_MAP : CHILD_TOOTH_MAP;
  const odontogramRow = createOdontogramRow(examTarget === 'ADULT' ? ADULT_TOOTH_MAP : CHILD_TOOTH_MAP);

  const onChangeExamTarget = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const type = event.target.value as keyof typeof EXAM_TARGETS;
    setExamTarget(type);
    const { data } = await dispatch(
      axiosThunk({
        url: API_SERVER.dental.updateListOdontogram(),
        method: 'put',
        data: { dentalExamId: parseInt(dentalSessionId), patientId: parseInt(patientId), type: type },
      }),
    );
    mutate(z.array(DentalToothDetailSchema).parse(data));
    revalidate();
  };

  const { data: s3ResourceData } = useFetch(
    API_SERVER.storageResource.getList({ type: examTarget === 'CHILD' ? 'DENTAL_CHILD' : 'DENTAL_ADULT' }),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  const odontogramDataMapped = useMemo(
    () =>
      odontogramData?.reduce((result, current) => {
        const processingDiagnosisList = current.dentalDiagnosisList?.filter(
          (diagnosis) => diagnosis.status === DENTAL_DIAGNOSIS_STATUS.PROCESSING.value,
        );
        return {
          ...result,
          [current?.position || 0]: {
            ...current,
            insideSurfaceImages: processingDiagnosisList
              ?.filter((diagnosis) => diagnosis?.insideSurface)
              .map((diagnosis) => diagnosis.insideSurface),
            occlusalSurfaceImages: processingDiagnosisList
              ?.filter((diagnosis) => diagnosis?.occlusalSurface)
              .map((diagnosis) => diagnosis.occlusalSurface),
            outsideSurfaceImages: processingDiagnosisList
              ?.filter((diagnosis) => diagnosis?.outsideSurface)
              .map((diagnosis) => diagnosis.outsideSurface),
          },
        };
      }, {}),
    [odontogramData],
  );

  const s3ResourceDataMapped = useMemo(() => keyBy<StorageResource>(s3ResourceData, 'fileName'), [s3ResourceData]);

  return (
    <Box sx={{ marginTop: 1 }}>
      <TableContainer component={Paper} sx={{ boxShadow: '0px 4px 8px 0px #E0ECFF', borderRadius: 1 }}>
        <FormControl sx={{ padding: 1, paddingLeft: 3, backgroundColor: '#F4F8FF', marginBottom: 1 }}>
          <RadioGroup
            row
            name="examTarget"
            defaultValue={EXAM_TARGETS.ADULT.value}
            onChange={onChangeExamTarget}
            value={examTarget}
          >
            {[EXAM_TARGETS.ADULT, EXAM_TARGETS.CHILD].map(({ label, value }, index) => (
              <FormControlLabel
                key={index}
                value={value}
                control={<Radio disabled={readOnly} />}
                label={<FormattedMessage id={label} />}
              />
            ))}
          </RadioGroup>
        </FormControl>
        <Table sx={{ minWidth: 650 }}>
          <TableHead>
            <TableRow>
              <TableCell align="center" sx={{ padding: 1, borderBottom: 'unset' }}></TableCell>
              {toothMap.top.map((position) => (
                <TableCell align="center" key={position} sx={{ padding: 1, borderBottom: 'unset' }}>
                  <ButtonPositionStyled
                    variant="outlined"
                    onClick={() => {
                      if (readOnly) return;
                      onOpenDiagnosisDialog({
                        position: position,
                        dentalGroup: 'TOOTH',
                        dentalGroupDetail: 'TOOTH_ALL',
                      });
                    }}
                  >
                    {position}
                  </ButtonPositionStyled>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {odontogramRow.map(({ type, position }, index) => (
              <TableRow key={index}>
                <TableCell
                  align="center"
                  sx={{
                    fontSize: '14px',
                    fontWeight: '500',
                    lineHeight: '24px',
                    letterSpacing: '0.1px',
                    borderBottom: 'unset',
                  }}
                >
                  <FormattedMessage id={type.label} />
                </TableCell>
                {position?.map((positionIndex) => {
                  const teethInfo = odontogramDataMapped?.[positionIndex];
                  const defaultImageName = teethInfo?.[type.value];
                  const otherImageNames = teethInfo?.[`${type.value}Images`];
                  const defaultImage = s3ResourceDataMapped?.[defaultImageName];
                  const otherImages = otherImageNames?.map((imageName) => s3ResourceDataMapped?.[imageName]);
                  return (
                    <TableCell
                      align="center"
                      key={positionIndex}
                      sx={{
                        '@media screen and (max-width: 1536px)': {
                          padding: '4px',
                        },
                        padding: 1,
                        borderBottom: 'unset',
                      }}
                    >
                      <Box
                        position="relative"
                        sx={{ transition: 'all 0.3s', cursor: 'pointer', '&:hover': { transform: 'scale(1.2)' } }}
                        onClick={() => {
                          if (readOnly) return;
                          if (!defaultImage) return null;
                          if (type.value === ODONTOGRAM_TYPES.insideSurface.value) {
                            onOpenDiagnosisDialog({
                              position: positionIndex,
                              dentalGroup: 'TOOTH',
                              dentalGroupDetail: 'TOOTH_INSIDE',
                            });
                          }
                          if (type.value === ODONTOGRAM_TYPES.outsideSurface.value) {
                            onOpenDiagnosisDialog({
                              position: positionIndex,
                              dentalGroup: 'TOOTH',
                              dentalGroupDetail: 'TOOTH_OUTSIDE',
                            });
                          }
                          if (type.value === ODONTOGRAM_TYPES.occlusalSurface.value) {
                            onOpenDiagnosisDialog({
                              position: positionIndex,
                              dentalGroup: 'TOOTH',
                              dentalGroupDetail: 'TOOTH_OCCLUSAL',
                            });
                          }
                        }}
                      >
                        <img src={defaultImage?.uri} alt={defaultImage?.fileName} />
                        {otherImages?.map((image) => (
                          <img
                            src={image?.uri}
                            alt={image?.fileName || ''}
                            style={{ position: 'absolute', left: '50%', transform: 'translateX(-50%' }}
                          />
                        ))}
                      </Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            ))}
            <TableRow>
              <TableCell align="center" sx={{ padding: 1, borderBottom: 'unset' }}></TableCell>
              {toothMap.bottom.map((position) => (
                <TableCell align="center" key={position} sx={{ padding: 1, borderBottom: 'unset' }}>
                  <ButtonPositionStyled
                    variant="outlined"
                    onClick={() => {
                      if (readOnly) return;
                      onOpenDiagnosisDialog({
                        position: position,
                        dentalGroup: 'TOOTH',
                        dentalGroupDetail: 'TOOTH_ALL',
                      });
                    }}
                  >
                    {position}
                  </ButtonPositionStyled>
                </TableCell>
              ))}
            </TableRow>
          </TableBody>
        </Table>
        {!readOnly && (
          <Stack direction="row" justifyContent="center" alignItems="center" margin={3} gap={2}>
            <ButtonStyled
              color="inherit"
              onClick={() => {
                onOpenDiagnosisDialog({
                  dentalGroup: 'JAW',
                  dentalGroupDetail: 'JAW_ALL',
                });
              }}
            >
              <FormattedMessage id="dental.dentalDetail.odontogram.button.allJaw.title" />
            </ButtonStyled>
            <ButtonStyled
              color="inherit"
              onClick={() => {
                onOpenDiagnosisDialog({
                  dentalGroup: 'JAW',
                  dentalGroupDetail: 'JAW_UPPER',
                });
              }}
            >
              <FormattedMessage id="dental.dentalDetail.odontogram.button.topJaw.title" />
            </ButtonStyled>
            <ButtonStyled
              color="inherit"
              onClick={() => {
                onOpenDiagnosisDialog({
                  dentalGroup: 'JAW',
                  dentalGroupDetail: 'JAW_BOTTOM',
                });
              }}
            >
              <FormattedMessage id="dental.dentalDetail.odontogram.button.bottomJaw.title" />
            </ButtonStyled>
            <ButtonStyled
              color="inherit"
              onClick={() => {
                onOpenDiagnosisDialog({
                  dentalGroup: 'MOUTH',
                });
              }}
            >
              <FormattedMessage id="dental.dentalDetail.odontogram.button.mouth.title" />
            </ButtonStyled>
            <ButtonStyled
              color="inherit"
              onClick={() => {
                onOpenDiagnosisDialog({
                  dentalGroup: 'GUMS',
                });
              }}
            >
              <FormattedMessage id="dental.dentalDetail.odontogram.button.gums.title" />
            </ButtonStyled>
          </Stack>
        )}
      </TableContainer>
      <DentalDiagnosticImage />
      <StatusAndDiagnosisDialog />
    </Box>
  );
};

export default Odontogram;
