import { Box, Collapse, Stack, Typography } from '@mui/material';
import IconButtonTitle from './IconButtonTitle';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormattedMessage } from 'react-intl';
import { trim } from 'lodash';
import useURL from '../hook/useURL';
import useGeneralHook from '../hook/useGeneralHook';

interface ITab {
  key: string;
  title: string;
  content: ReactNode;
  roles?: string[];
}

interface Props {
  title?: string;
  tabs: ITab[];
  allowCollapse?: boolean;
  defaultCollapse?: boolean;
  onTabClick?: (tab: ITab) => void;
  searchUrlKey?: string;
}

const hasAccess = (userRoles: string[], requiredRoles?: string[]): boolean => {
  if (!requiredRoles || requiredRoles.length === 0) return true;
  return requiredRoles.some((role) => userRoles.includes(role));
};

const Tabs = ({ title, tabs, allowCollapse = false, defaultCollapse = true, onTabClick, searchUrlKey }: Props) => {
  const [expand, setExpand] = useState(defaultCollapse);
  const { replaceSearchParams, urlSearchParams } = useURL();
  const { appState } = useGeneralHook();
  const userRoles = appState.authen.user?.role;

  const accessibleTabs = tabs.filter((tab) => hasAccess(userRoles!, tab.roles));

  const [selectedTab, setSelectedTab] = useState<ITab>(accessibleTabs[0]);

  const onTabSelect = useCallback(
    (tab: ITab) => {
      setSelectedTab(tab);
      onTabClick && onTabClick(tab);
      const searchKey = trim(searchUrlKey);
      if (searchKey) {
        replaceSearchParams({ [searchKey]: tab.key });
      }
    },
    [onTabClick, replaceSearchParams, searchUrlKey],
  );

  useEffect(() => {
    const searchKey = trim(searchUrlKey);
    if (searchKey) {
      const currentTabKey = urlSearchParams.get(searchKey);
      if (currentTabKey) {
        const selectedTab = accessibleTabs.find((tab) => tab.key === currentTabKey);
        if (selectedTab) {
          setSelectedTab(selectedTab);
        }
      }
    } else if (accessibleTabs.length > 0) {
      setSelectedTab(accessibleTabs[0]);
    }
  }, [searchUrlKey, accessibleTabs, urlSearchParams]);

  return (
    <Box>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" justifyContent="row" alignItems="center" gap="10px">
          <Typography
            sx={{
              color: '#307BFF',
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.15px',
              paddingLeft: '15px',
            }}
          >
            {title}
          </Typography>
          <Stack direction="row" justifyContent="row" alignItems="center" gap="5px">
            {accessibleTabs.map((tab) => (
              <Box
                key={tab.key}
                sx={{
                  padding: '10px 30px',
                  borderRadius: '8px 8px 0px 0px',
                  borderBottom: selectedTab.key === tab.key ? '2px solid #fff' : '2px solid #E0ECFF',
                  background: '#FFF',
                  cursor: 'pointer',
                  '&:hover': {
                    background: '#CDDFFF',
                  },
                }}
                onClick={() => onTabSelect(tab)}
              >
                <Typography
                  sx={{
                    color: selectedTab.key === tab.key ? '#003CA6' : '#607D8B',
                    fontFamily: 'Roboto',
                    fontSize: '15px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '24px',
                    letterSpacing: '0.15px',
                  }}
                >
                  <FormattedMessage id={tab.title} />
                </Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
        {allowCollapse && (
          <IconButtonTitle
            onClick={() => setExpand((prev) => !prev)}
            sx={{ transform: expand ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 200ms' }}
          >
            <ArrowDropDownIcon fontSize="large" sx={{ color: '#0052E0' }} />
          </IconButtonTitle>
        )}
      </Stack>
      {allowCollapse ? (
        <Collapse in={expand}>
          <Box sx={{ borderRadius: '5px', backgroundColor: '#fff' }}>{selectedTab?.content}</Box>
        </Collapse>
      ) : (
        <Box sx={{ borderRadius: '5px', backgroundColor: '#fff' }}>{selectedTab?.content}</Box>
      )}
    </Box>
  );
};

export default Tabs;
