import { Box, CardContent, Stack, Typography } from '@mui/material';
import { AppointmentUpcoming } from 'modules/schema';
import { FormattedMessage } from 'react-intl';
import { FE_TIME_FORMAT } from 'modules/common/constants';
import { PAYMENT_TYPE } from 'modules/common/apiConstants';
import Calendar from 'modules/common/component/Calendar';

interface Props {
  appoimentUpcoming?: AppointmentUpcoming;
}
const PatientAppointmentItem: React.FunctionComponent<Props> = ({ appoimentUpcoming }) => {
  const getCalendarColor = () => {
    if (appoimentUpcoming?.type === PAYMENT_TYPE.THERAPY) {
      return '#FFA000';
    }
    return '#5794FF';
  };

  return (
    <Box sx={{ border: '1px solid #ECEFF1', padding: '10px 20px', borderRadius: '8px' }}>
      <Stack>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex'! }}>
            {appoimentUpcoming?.startTime && (
              <Calendar time={appoimentUpcoming?.startTime} color={getCalendarColor()} />
            )}
            <Box sx={{ paddingLeft: '35px' }}>
              <CardContent>
                <Box display="flex" alignItems={'baseline'}>
                  <Typography
                    variant="body2"
                    style={{ minWidth: 100 }}
                    fontWeight={500}
                    color={'#607D8B'}
                    fontSize={'14px'}
                  >
                    <FormattedMessage id="appointment.time" />
                  </Typography>
                  &nbsp;
                  <Typography variant="body1" fontWeight={500} color={'#0c0d0f'}>
                    {appoimentUpcoming?.startTime?.format(FE_TIME_FORMAT)}
                  </Typography>
                </Box>
                <Box display="flex" alignItems={'baseline'}>
                  <Typography
                    variant="body2"
                    style={{ minWidth: 100 }}
                    fontWeight={500}
                    color={'#607D8B'}
                    fontSize={'14px'}
                  >
                    <FormattedMessage id="note" />
                  </Typography>
                  &nbsp;
                  <Typography variant="body1">{appoimentUpcoming?.note}</Typography>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Box>
      </Stack>
    </Box>
  );
};
export default PatientAppointmentItem;
