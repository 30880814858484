import { Stack } from '@mui/material';
import { FilterTitle } from './style';
import { FormattedMessage } from 'react-intl';
import { ReactNode } from 'react';

interface Props {
  title: string;
  children: ReactNode;
}

function FilterGroup({ title, children }: Props) {
  return (
    <Stack direction="row" alignItems="flex-start">
      <FilterTitle>
        <FormattedMessage id={title} />
      </FilterTitle>
      {children}
    </Stack>
  );
}

export default FilterGroup;
