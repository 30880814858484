import { delay, fork } from '@redux-saga/core/effects';

const DEMO = window.location.hostname.startsWith('demo');

export default function* rootSaga() {
  if (DEMO) {
    yield fork(pollActive);
  }
  yield 1;
}

function* pollActive() {
  while (true) {
    yield delay(5 * 60 * 1000);
  }
}
