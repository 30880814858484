import { get } from 'lodash';
import { Columns } from '.';
import { some } from '../../constants';

export const getArrayDepth = (columns: Columns[]) => {
  return 1 + Math.max(...columns.map((v) => (v.children ? getArrayDepth(v.children) : 0)), 0);
};

export const getColSpan = (columns: Columns): number => {
  let sum = 0;
  if (columns.children && columns.children.length > 0) {
    sum = sum + columns.children.reduce((v, c) => v + getColSpan(c), 0);
  } else {
    sum = sum + 1;
  }
  return sum;
};

export const getColumns = (columns: Columns[]): Columns[] => {
  let tmp: Columns[] = [];
  columns.forEach((column: Columns) => {
    if (column.children) {
      tmp = [...tmp, ...getColumns(column.children)];
    } else {
      tmp = [...tmp, column];
    }
  });

  return tmp;
};

export const getIndexFixedColumnRightHeader = (columnsEle: Columns[]) => {
  const index = columnsEle.findIndex((v) => v.fixed === 'right');
  return index;
};

export const getIndexFixedColumnLeftHeader = (columnsEle: Columns[]) => {
  const columnsTmp = [...columnsEle]?.reverse();
  const index = columnsTmp.findIndex((v) => v.fixed === 'left');
  return columnsTmp.length - index - 1;
};

export const getWidthHeader = (col: Columns, columnsEle: Columns[]) => {
  const { fixed, title, parentWidth } = col;
  let width = parentWidth || 0;
  if (fixed) {
    const columnsTmp = fixed === 'left' ? columnsEle : columnsEle?.reverse();
    for (let i = 0; i < columnsTmp.length; i += 1) {
      if (title === columnsTmp[i]?.title) {
        break;
      }
      const tmp = columnsTmp[i]?.width;
      const fixedTmp = columnsTmp[i]?.fixed;
      if (typeof tmp === 'number' && fixedTmp) {
        width += tmp || 0;
      }
    }
  }
  return width;
};

const getColumnsHeader = (columns: Columns[], floor: number = 0, tmp?: any, parentWidth?: number, parent?: Columns) => {
  columns.forEach((val: Columns) => {
    const { children = [], ...column } = val;
    const depth = getArrayDepth(columns);
    const colSpan = getColSpan(val);
    tmp = {
      ...tmp,
      [floor]: [
        ...(get(tmp, floor, []) as any),
        {
          colSpan: colSpan === 1 ? undefined : colSpan,
          rowSpan: children.length > 0 ? 1 : depth,
          parentWidth: parentWidth,
          ...column,
        },
      ],
    };
    if (children.length > 0) {
      tmp = getColumnsHeader(
        children,
        floor + (column.rowSpan ? column.rowSpan - 1 : 0 || 0) + 1,
        tmp,
        (parentWidth || 0) + getWidthHeader(val, [...get(tmp, floor, [])]),
      );
    }
  });
  return tmp;
};

const getFixedMark = (columns: Columns[]) => {
  const leftIndexFixed = getIndexFixedColumnLeftHeader(columns);
  const rightIndexFixed = getIndexFixedColumnRightHeader(columns);
  return columns.map((column, index) => {
    return {
      ...column,
      leftIndexFixed: leftIndexFixed === index,
      rightIndexFixed: rightIndexFixed === index,
      children:
        column.children &&
        (leftIndexFixed === index || rightIndexFixed === index ? getFixedMark(column.children) : column.children),
    };
  });
};
export const renderColumnHeader = (columns: Columns[]) => {
  return Object.values(getColumnsHeader(getFixedMark(columns))) as Columns[][];
};

export const getFlatDataSource = (columns: some[]) => {
  return columns.reduce((val: some[], current: some) => {
    return [...val, current, ...getFlatDataSource(current.children || [])];
  }, []);
};
