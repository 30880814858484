import { SERVICE_CATEGORY_LIST } from 'modules/common/constants';
import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl } = formProps || {};

  return {
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'exam.nameLabel' }),
      placeholder: intl.formatMessage({ id: 'exam.nameLabelEnter' }),
      propsWrapper: { xs: true },
      readOnly: valuesField.type === SERVICE_CATEGORY_LIST.insured,
      register: {
        required: true,
      },
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'info',
      fields: ['name'],
    },
  ];
};

export const groupExamPriceFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
