import { Box } from '@mui/material';
import * as React from 'react';
import DialogCustom from '../../../common/component/DialogCustom';
import { some } from '../../../common/constants';
import { EMAIL_REGEX } from '../../../common/regex';
import SchemaForm from '../../../common/SchemaForm';

interface Props {
  onSubmit?: (formVal: some) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
}

const FormDataDialog: React.FunctionComponent<Props> = (props) => {
  const { formData, onClose, onSubmit, open } = props;

  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 680 },
      }}
      title={formData?.id ? 'supplier.update' : 'supplier.create'}
    >
      <Box padding={2}>
        <SchemaForm
          onSubmit={onSubmit}
          initialData={formData}
          onCancel={onClose}
          formProps={{
            style: {
              flex: 1,
            },
          }}
          schema={{
            fields: ({ formProps }) => {
              const { intl } = formProps || {};
              return {
                name: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'supplier.name' }),
                  placeholder: intl.formatMessage({ id: 'enter' }),
                  propsWrapper: { xs: 6 },
                  register: {
                    validate: (value) => (value?.trim() ? true : intl.formatMessage({ id: 'required' })),
                  },
                  required: true,
                },
                representationPerson: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'supplier.presentative' }),
                  placeholder: intl.formatMessage({ id: 'enter' }),
                  propsWrapper: { xs: 6 },
                },
                address: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'address' }),
                  placeholder: intl.formatMessage({ id: 'enter' }),
                  propsWrapper: { xs: 6 },
                },
                phoneNumber: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'telephone' }),
                  placeholder: intl.formatMessage({ id: 'enter' }),
                  propsWrapper: { xs: 6 },
                },
                email: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'email' }),
                  placeholder: intl.formatMessage({ id: 'enter' }),
                  propsWrapper: { xs: 6 },
                  register: {
                    pattern: {
                      value: EMAIL_REGEX,
                      message: intl.formatMessage({ id: 'emailValid' }),
                    },
                  },
                },
                taxNumber: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'supplier.taxCode' }),
                  placeholder: intl.formatMessage({ id: 'enter' }),
                  propsWrapper: { xs: 6 },
                },
              };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default FormDataDialog;
