import { Appointments as AppointmentsBase } from '@devexpress/dx-react-scheduler';
import { APPOINTMENT_TYPES, NUMBER_PATIENT_COLUMN_ID } from '../constants';
import { Box, Typography } from '@mui/material';
import React, { useMemo } from 'react';

const AppointmentContentComponent = ({ data }: AppointmentsBase.AppointmentContentProps) => {
  const isNumberPatientCell = data.members?.some((ele) => ele === NUMBER_PATIENT_COLUMN_ID);
  const color = useMemo(() => {
    return data.appointmentGroupId
      ? APPOINTMENT_TYPES.APPOINTMENT_GROUP.color
      : data.patientId
      ? APPOINTMENT_TYPES.PATIENT.color
      : data.type === 'THERAPY'
      ? APPOINTMENT_TYPES.THERAPY.color
      : '';
  }, [data]);

  const lightColor = useMemo(() => {
    return data.appointmentGroupId
      ? APPOINTMENT_TYPES.APPOINTMENT_GROUP.lightColor
      : data.patientId
      ? APPOINTMENT_TYPES.PATIENT.lightColor
      : data.type === 'THERAPY'
      ? APPOINTMENT_TYPES.THERAPY.lightColor
      : '';
  }, [data]);
  return (
    <Box
      onClick={(event) => {
        if (isNumberPatientCell) {
          // Prevent show Tooltip with number patient cell
          event.stopPropagation();
        }
      }}
      id={String(data?.id)}
      sx={{
        width: '100%',
        height: '100%',
        padding: '8px',
        ...(isNumberPatientCell
          ? {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }
          : {
              bgcolor: lightColor,
              border: '1px solid gray',
              borderLeft: `4px solid ${color}`,
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            }),
      }}
    >
      <Typography
        sx={{
          fontSize: '16px',
          lineHeight: '20px',
          letterSpacing: '0.4px',
          color: isNumberPatientCell ? '#5794FF' : 'unset',
          fontWeight: isNumberPatientCell ? '500' : '400',
          textTransform: 'capitalize',
        }}
      >
        {data?.title}
      </Typography>
      {isNumberPatientCell || (
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '14px',
            lineHeight: '16px',
            letterSpacing: '0.4px',
            color: '#263238',
            textTransform: 'uppercase',
          }}
        >
          {data?.mobilePhone}
        </Typography>
      )}
    </Box>
  );
};

export default AppointmentContentComponent;
