import { Search } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Collapse, Divider, InputAdornment } from '@mui/material';
import { ILayoutFields, ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import { DENTAL_STATUS } from 'modules/reception/constants';
import { Sort } from '../../../../../svg';
import { BE_DATE_FORMAT, ORDER_BE, YEAR_FORMAT } from '../../../../common/constants';
import moment from 'moment/moment';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const { intl, clearParams, state, setState, onDataLoading } = formProps || {};
  const { setValue } = methods;
  return {
    patientName: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'sale.customerEnter' }),
      propsWrapper: { xs: true },
      label: intl.formatMessage({ id: 'sellingProduct.title.customerName' }),
      noHelperText: true,
    },
    patientMobilePhone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      propsWrapper: { xs: true },
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Search color="inherit" />
          </InputAdornment>
        ),
      },
      showClearBtn: true,
    },
    status: {
      type: 'select',
      hasAllOptions: true,
      label: intl.formatMessage({ id: 'status' }),
      options: DENTAL_STATUS,
      noHelperText: true,
      propsWrapper: { xs: true },
    },
    patientYearOfBirth: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'enterBirthYear' }),
      label: intl.formatMessage({ id: 'birthYear' }),
      propsWrapper: { xs: 3, xl: true },
      disableFuture: true,
      views: ['year'],
      inputFormat: 'yyyy',
      mask: '____',
    },
    picName: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'encounterPractitioner' }),
      placeholder: intl.formatMessage({ id: 'enterDoctorName' }),
      propsWrapper: { xs: 3, xl: true },
    },
    fromDate: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'encounterDateFrom' }),
      propsWrapper: { xs: 2.4 },
      autoOk: true,
      maxDate: valuesField.endDate ? moment(valuesField.endDate).toDate() : null,
      register: {
        validate: (value) => {
          return value
            ? moment(value, BE_DATE_FORMAT, true).isValid()
              ? true
              : intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
      },
      noHelperText: true,
      required: false,
    },
    toDate: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'encounterDateTo' }),
      propsWrapper: { xs: 2.4 },
      autoOk: true,
      minDate: valuesField.startDate ? moment(valuesField.startDate).toDate() : null,
      register: {
        validate: (value) => {
          return value
            ? moment(value, BE_DATE_FORMAT, true).isValid()
              ? true
              : intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
      },
      noHelperText: true,
      required: false,
    },
    search: {
      type: 'submitButton',
      color: 'inherit',
      label: intl.formatMessage({ id: 'search' }),
      propsWrapper: { xs: undefined },
    },
    expand: {
      type: 'button',
      endIcon: (
        <ExpandLessIcon
          style={{
            transition: '0.2s all',
            transform: valuesField?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      ),
      color: 'primary',
      variant: 'text',
      onClick: () => setState({ expand: !state.expand }),
      label: intl.formatMessage({ id: 'advance' }),
      propsWrapper: { xs: undefined },
    },
    reset: {
      type: 'button',
      onClick: () => clearParams && clearParams(),
      color: 'inherit',
      label: intl.formatMessage({ id: 'reset' }),
      propsWrapper: { xs: undefined },
      style: { minWidth: 120, marginTop: 20 },
    },
    orderBy: {
      type: 'submitButton',
      onClick: () => {
        if (!valuesField?.orderBy || valuesField.orderBy === ORDER_BE.asc) {
          setValue('orderBy', ORDER_BE.desc);
          return;
        }
        setValue('orderBy', ORDER_BE.asc);
      },
      disabled: onDataLoading,
      label: intl.formatMessage({ id: 'encounterList.label.input.sort' }),
      style: { minWidth: 120 },
      propsWrapper: { xs: undefined },
      color: 'inherit',
      startIcon: (
        <Box
          sx={{
            transition: 'all 0.3s ease',
            transform: valuesField.orderBy === ORDER_BE.asc ? 'rotate(180deg)' : 'rotate(0deg)',
            '& path': {
              fill: onDataLoading ? '#A8A8AA' : '#0962FF',
            },
          }}
        >
          <Sort />
        </Box>
      ),
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'layout',
      propsGridContainer: { spacing: 1, justifyContent: 'flex-end' },
      fields: ['patientMobilePhone', 'search', 'orderBy', 'expand'],
    },
    {
      id: 'expand',
      fields: ['status', 'patientName', 'patientYearOfBirth', 'picName', 'fromDate', 'toDate', 'reset'],
    },

    { id: 'submit', fields: ['save'] },
  ];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement, formProps } = props;
  const { state } = formProps;
  return (
    <>
      <Box display="flex">
        <Box flex={1}>{formProps?.pagin}</Box>
        <Box flex={1}>{listElement[0]}</Box>
      </Box>
      <Collapse in={state?.expand} style={{ marginTop: 16 }}>
        {listElement[1]}
        <Divider style={{ marginBottom: 16, marginTop: 16 }} />
      </Collapse>
    </>
  );
};

export const schemaFilterDentalForm: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
  changeDataBeforeSubmit: (values) => {
    return {
      ...values,
      patientYearOfBirth: values?.patientYearOfBirth
        ? moment(values?.patientYearOfBirth, BE_DATE_FORMAT).format(YEAR_FORMAT)
        : null,
    };
  },
};
