import { Box, Button, Stack } from '@mui/material';
import Report from 'modules/common/component/report';
import Filter from 'modules/common/component/filter';
import { DEFAULT_VALUES, FILTER_CONFIGS } from './consts';
import { useEffect, useMemo, useRef, useState } from 'react';
import DateInput from 'modules/common/component/form/DateInput';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';
import { groupBy, map, sum } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { TIME_REPORT_FILTER } from 'modules/common/component/report/consts';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'modules/common/constants';
import { formatEndDate, formatStartDate, getDateRangeList, isValidDate, maxDate } from 'modules/common/utils';
import { FormSchema, SearchParams } from 'modules/common/page/report/business/incomeReport/type';
import SelectInput from 'modules/common/component/form/SelectInput';
import { SERVICE_TYPE_INCOME } from 'modules/common/apiConstants';
import ReactToPrint from 'react-to-print';
import { Print } from '@mui/icons-material';
import { BUSINESS_API } from 'modules/common/service';

export const FILTER_KEY = 'INCOME_REPORT_CHART_FILTER';
export const SELECT_COLUMNS_KEY = 'INCOME_REPORT_CHART_SELECT_COLUMNS';

const IncomeReportChart = () => {
  const [searchParams, setSearchParams] = useState<SearchParams>(DEFAULT_VALUES);
  const [seriesDataIncome, setSeriesDataIncome] = useState<number[]>([]);
  const [seriesDataOutcome, setSeriesDataOutcome] = useState<number[]>([]);
  const form = useForm<FormSchema & { timeReportType: keyof typeof TIME_REPORT_FILTER }>({
    defaultValues: {
      fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
      toDate: formatEndDate(moment().add(+1, 'day')),
      timeReportType: TIME_REPORT_FILTER.DAY.value,
      type: [],
    },
    mode: 'onChange',
  });

  const refPrintComponent = useRef(null);
  const intl = useIntl();
  const paymentTypes = form.watch('type');
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate = formatEndDate(moment(toDateOrigin).add(-1, 'day'));
  const formData = form.getValues();
  const timeReportType = form.watch('timeReportType');
  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      fromDate: fromDate,
      toDate: toDate,
      paymentTypes: paymentTypes
        ? map(paymentTypes, 'value')
        : map([SERVICE_TYPE_INCOME.ENCOUNTER, SERVICE_TYPE_INCOME.THERAPY], 'value'),
    };
  }, [fromDate, paymentTypes, searchParams, toDate]);

  // Get data revenue expenditure Chart
  const { data: reportData, isValidating } = useFetch(BUSINESS_API.getCashBookList(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'CHART'),
  });

  // Data chart day/month
  const dateRangeList = useMemo(
    () =>
      getDateRangeList(
        moment(fromDate, BE_DATE_TIME_FORMAT),
        moment(toDate, BE_DATE_TIME_FORMAT),
        timeReportType === 'DAY' ? 'day' : 'month',
      ).map((date) => date.format(timeReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT)),
    [fromDate, timeReportType, toDate],
  );

  // Data chart income, outcome
  useEffect(() => {
    const timeFormat = timeReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT;
    if (!isValidating && reportData && isValidDate(fromDate, toDate, 'CHART')) {
      const reportMapIncome = groupBy(
        reportData.filter((i) => i.incomeAmount),
        (item) => moment(item?.createdTime)?.format(timeFormat),
      );
      const reportMapOutcome = groupBy(
        reportData.filter((i) => i.outcomeAmount),
        (item) => moment(item?.createdTime)?.format(timeFormat),
      );
      setSeriesDataIncome(
        dateRangeList.map((date) => sum(reportMapIncome[date]?.map((obj) => obj?.incomeAmount)) || 0),
      );
      setSeriesDataOutcome(
        dateRangeList.map((date) => sum(reportMapOutcome[date]?.map((obj) => obj?.outcomeAmount)) || 0),
      );
    } else {
      setSeriesDataIncome([]);
      setSeriesDataOutcome([]);
    }
  }, [dateRangeList, fromDate, isValidating, reportData, timeReportType, toDate]);

  useEffect(() => {
    if (timeReportType === 'DAY' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'month')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'month')
            .add(+1, 'day'),
        ),
      });
    }
    if (timeReportType === 'MONTH' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'year')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'year')
            .add(+1, 'day'),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateOrigin, timeReportType, toDateOrigin]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title title="report.businessReport.tab.title.incomeReport" />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue) => {
          setSearchParams((prev) => ({
            ...prev,
            paymentMethods: filterValue.PAYMENT_METHODS,
            cashierEmployeeIds: filterValue.EMPLOYEE,
          }));
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                options={Object.values(SERVICE_TYPE_INCOME)}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                multiple
                showCheckBox
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                maxDate={maxDate(timeReportType, fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
              />
              <Box marginTop={'-17px'}>
                <Report.DayMonthRadio
                  form={form}
                  name="timeReportType"
                  onChange={(value) => {
                    if (value === 'DAY') {
                      form.reset({
                        fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment().add(+1, 'day')),
                        timeReportType: TIME_REPORT_FILTER.DAY.value,
                      });
                    }
                    if (value === 'MONTH') {
                      form.reset({
                        fromDate: moment().add(-1, 'year').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment()),
                        timeReportType: TIME_REPORT_FILTER.MONTH.value,
                      });
                    }
                  }}
                />
              </Box>
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() =>
                    form.reset({
                      ...DEFAULT_VALUES,
                      toDate: formatEndDate(moment().add(+1, 'day')),
                    })
                  }
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />}>
                  <FormattedMessage id={'printReport'} />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Stack>
        </Stack>
        <Report.ColumnChart
          refPrintComponent={refPrintComponent}
          xAxisTitle="common.time"
          yAxisTitle="millionMoney"
          categories={dateRangeList}
          formatYValue
          formatYAxis
          series={[
            {
              type: 'column',
              name: intl.formatMessage({ id: 'label.report.revenueExpenditure.numberIncomeMoney' }),
              data: seriesDataIncome,
              color: '#003CA6',
            },
            {
              name: intl.formatMessage({ id: 'label.report.revenueExpenditure.numberOutcomeMoney' }),
              type: 'column',
              data: seriesDataOutcome,
              color: '#43A047',
            },
          ]}
        />
      </Filter>
    </Stack>
  );
};

export default IncomeReportChart;
