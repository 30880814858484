import { Button, ButtonGroup } from '@mui/material';
import { useState } from 'react';
import { Chart, Table } from 'svg';
import { REPORT_VIEW_MODE } from './consts';

interface Props {
  onChange: (viewMode: keyof typeof REPORT_VIEW_MODE) => void;
  defaultMode?: keyof typeof REPORT_VIEW_MODE;
}

export const ReportSwitchLayout = ({ onChange, defaultMode = 'TABLE' }: Props) => {
  const [selectedViewMode, setSelectedViewMode] = useState<keyof typeof REPORT_VIEW_MODE>(defaultMode);
  return (
    <ButtonGroup>
      <Button
        onClick={() => {
          onChange(REPORT_VIEW_MODE.TABLE);
          setSelectedViewMode(REPORT_VIEW_MODE.TABLE);
        }}
        sx={{
          padding: '8px',
          borderRadius: '5px 0px 0px 5px',
          '& path': { fill: selectedViewMode === REPORT_VIEW_MODE.TABLE ? 'white' : '#0052E0' },
        }}
        variant={selectedViewMode === REPORT_VIEW_MODE.TABLE ? 'contained' : 'outlined'}
      >
        <Table />
      </Button>
      <Button
        onClick={() => {
          onChange(REPORT_VIEW_MODE.CHART);
          setSelectedViewMode(REPORT_VIEW_MODE.CHART);
        }}
        sx={{
          padding: '8px',
          borderRadius: '0px 5px 5px 0px',
          '& path': { fill: selectedViewMode === REPORT_VIEW_MODE.CHART ? 'white' : '#0052E0' },
        }}
        variant={selectedViewMode === REPORT_VIEW_MODE.CHART ? 'contained' : 'outlined'}
      >
        <Chart />
      </Button>
    </ButtonGroup>
  );
};
