import { z } from 'zod';
import { DentalDiagnosisSchema } from './DentalDiagnosis';

export const DentalToothDetailSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    position: z.number(),
    type: z.string(),
    outsideSurface: z.string(),
    insideSurface: z.string(),
    occlusalSurface: z.string(),
    dentalToothModelId: z.number(),
    dentalDiagnosisList: z.array(DentalDiagnosisSchema),
  })
  .partial();

export type DentalToothDetail = z.infer<typeof DentalToothDetailSchema>;
