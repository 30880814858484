import { Box, Typography } from '@mui/material';
import { NUMBER_PATIENT_COLUMN_ID } from '../constants';
import React from 'react';
import { GroupingPanel as GroupingPanelBase } from '@devexpress/dx-react-scheduler';

const GroupPanelCell = ({ group, height }: GroupingPanelBase.CellProps) => {
  return (
    <Box
      component="td"
      width={group?.id === NUMBER_PATIENT_COLUMN_ID ? '55px' : '200px'}
      height={height}
      py="20px"
      bgcolor="#F4F8FF"
      borderRight="1px solid #CCCCCC"
      borderTop="1px solid #CCCCCC"
      position="sticky"
    >
      <Typography
        sx={{
          fontWeight: 600,
          fontSize: '15px',
          lineHeight: '130%',
          color: '#212134',
          textTransform: 'capitalize',
          textAlign: 'center',
        }}
      >
        {group.text}
      </Typography>
    </Box>
  );
};

export default GroupPanelCell;
