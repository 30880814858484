import { DECIMAL_NUMBER_REGEX } from '../../../../common/regex';
import { ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';
import { Box, Checkbox, Divider, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';
import React from 'react';
import { NumericFormatText } from 'modules/common/utils';
import { some } from 'modules/common/constants';
import IndicationTableList from './IndicationTableList';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const { intl, serviceTypeList } = formProps || {};
  const list = valuesField?.listData?.filter((v) => v.id !== valuesField?.id) || [];

  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'therapy.code' }),
      placeholder: intl.formatMessage({ id: 'therapy.codeEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        validate: (value) =>
          list.find((v) => v.name === value) ? intl.formatMessage({ id: 'therapy.duplicateName' }) : true,
      },
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'therapy.name' }),
      placeholder: intl.formatMessage({ id: 'therapy.nameEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    description: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'therapy.decryption' }),
      placeholder: intl.formatMessage({ id: 'therapy.decryption' }),
      propsWrapper: { xs: 12 },
    },
    detailList: {
      type: 'array',
      disableCloseBtn: true,
      hiddenNodata: true,
      labelAdd: intl.formatMessage({ id: 'therapy.addSession' }),
      paperItemProps: { style: { padding: 0 }, variant: 'elevation', elevation: 0 },
      schema: {
        fields: ({ formProps, valuesField: unitConverterForm, methods }) => {
          const { arrayData, methodsArray } = formProps;
          const { update } = methodsArray;
          const { setValue } = methods;
          const groups = valuesField.detailList[arrayData?.index]?.serviceType
            ? valuesField.detailList[arrayData?.index]?.serviceType?.groups
            : serviceTypeList?.reduce((val: some[], cur: some) => {
                return [
                  ...val,
                  ...(cur.groups.map((v) => ({
                    ...v,
                  })) || []),
                ];
              }, []);
          const indicationOptions = groups?.reduce((val: some[], cur: some) => {
            return [
              ...val,
              ...(cur.items?.map((v) => ({
                ...v,
                group: cur.name,
                scope: cur.scope,
              })) || []),
            ];
          }, []);

          return {
            id: {
              type: 'hidden',
            },
            name: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'therapy.sessionName' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 3.5 },
              onBlur: (value) => {
                if (value.target.value) {
                  update(arrayData.index, {
                    ...arrayData?.value,
                    name: value.target.value,
                  });
                }
              },
              register: {
                required: true,
              },
            },
            description: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'therapy.sessionContent' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 3.5 },
              onBlur: (value) => {
                if (value.target.value) {
                  update(arrayData.index, {
                    ...arrayData?.value,
                    description: value.target.value,
                  });
                }
              },
            },
            price: {
              type: 'text-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'therapy.price' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 3.5 },
              register: {
                maxLength: {
                  value: 16,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
                pattern: DECIMAL_NUMBER_REGEX,
              },
              onBlur: (value) => {
                if (value.target.value) {
                  update(arrayData.index, {
                    ...arrayData?.value,
                    price: value.target.value,
                  });
                }
              },
            },
            delete: {
              unregister: true,
              type: () => {
                return (
                  <IconButtonTitle title="delete" size="small" onClick={() => methodsArray?.remove(arrayData?.index)}>
                    <Delete />
                  </IconButtonTitle>
                );
              },
              propsWrapper: {
                xs: 0.75,
                marginTop: '20px',
              },
            },
            copy: {
              unregister: true,
              type: () => {
                return (
                  <IconButtonTitle
                    title="copy"
                    size="small"
                    onClick={() => {
                      methodsArray?.append(valuesField.detailList[arrayData?.index]);
                    }}
                  >
                    <ContentCopyIcon />
                  </IconButtonTitle>
                );
              },
              propsWrapper: {
                xs: 0.75,
                marginTop: '20px',
              },
            },
            serviceType: {
              type: 'auto-complete',
              options: serviceTypeList,
              getOptionLabel: (option: some) => option.name || '',
              label: intl.formatMessage({ id: 'therapyIndication.header.label.serviceType' }),
              placeholder: intl.formatMessage({ id: 'therapyIndication.header.label.serviceTypeSelect' }),
              onChange: (e, v, r) => {
                setValue('service', null);
              },
              propsWrapper: { xs: 4 },
            },
            service: {
              type: 'auto-complete',
              propsWrapper: { xs: 7.65 },
              options: indicationOptions || [],
              disableCloseOnSelect: true,
              getOptionLabel: (option) => option.name,
              isOptionEqualToValue: (opt, value) => opt.id === value.id,
              groupBy: (option) => option.group,
              label: intl.formatMessage({ id: 'serviceName' }),
              placeholder: intl.formatMessage({ id: 'serviceNameSelect' }),
              onChange: (v) => {
                const indicationList = arrayData?.value?.indicationList ? arrayData?.value?.indicationList : [];
                if (v) {
                  indicationList.push({
                    id: v.id,
                    price: v.price,
                    serviceId: v.id,
                    serviceName: v.name,
                    serviceScope: v.scope,
                  });
                }
              },
              renderOption: (props, option) => {
                return (
                  <li {...props} key={option?.id}>
                    <Box alignItems="center" display="flex">
                      <Checkbox
                        size="small"
                        color="primary"
                        style={{ marginRight: 8, padding: 0 }}
                        checked={
                          arrayData?.value?.indicationList &&
                          arrayData?.value?.indicationList?.filter((v) => v.serviceId === option?.id)?.length > 0
                            ? true
                            : false
                        }
                      />
                      <Typography variant="body2" component="div">
                        {option.name}
                      </Typography>
                    </Box>
                  </li>
                );
              },
            },
            indicationTable: {
              type: () => {
                return (
                  <IndicationTableList
                    data={arrayData?.value?.indicationList?.sort((e1, e2) => e1?.id - e2?.id)}
                    actionNode={(record) => (
                      <IconButtonTitle
                        key="delete-btn"
                        title="delete"
                        size="small"
                        onClick={async () => {
                          const newIndicationList = arrayData?.value?.indicationList?.filter(
                            (v) => v.serviceId !== record.serviceId,
                          );
                          update(arrayData.index, {
                            ...arrayData?.value,
                            indicationList: newIndicationList,
                          });
                        }}
                      >
                        <Delete fontSize="small" />
                      </IconButtonTitle>
                    )}
                  />
                );
              },
              propsWrapper: { xs: 12, minWidth: 900, sx: { paddingTop: '0px !important' } },
            },
          };
        },
        ui: [
          {
            id: 'base',
            propsWrapper: { xs: 12, sx: { paddingTop: 1 } },
            paperProps: {
              sx: {
                border: 'none',
                bgcolor: 'grey.200',
                px: 2,
                paddingTop: 2,
              },
            },
            paper: true,
            fields: ['name', 'description', 'price', 'copy', 'delete'],
          },
          {
            id: 'indication',
            propsWrapper: {
              xs: 12,
              sx: {
                paddingTop: '0px !important',
                '& .MuiGrid-root': { bgcolor: '#F4F8FF', width: '99%', marginLeft: '5px' },
              },
            },
            paperProps: {
              sx: {
                border: 'none',
                bgcolor: 'grey.200',
                px: 2,
                py: 2.5,
              },
            },
            paper: true,
            fields: ['serviceType', 'service', 'indicationTable'],
          },
        ],
      },
    },
    packagePrice: {
      type: 'text-field',
      inputType: 'number',
      placeholder: intl.formatMessage({ id: 'enter' }),
      propsWrapper: { xs: 6 },
      register: {
        maxLength: {
          value: 16,
          message: intl.formatMessage({ id: 'common.text.length.invalid' }),
        },
        pattern: DECIMAL_NUMBER_REGEX,
      },
    },
    retailPrice: {
      type: 'number-field',
      inputType: 'number',
      placeholder: intl.formatMessage({ id: 'enter' }),
      style: { xs: 6, width: '250px' },
      register: {
        maxLength: {
          value: 16,
          message: intl.formatMessage({ id: 'common.text.length.invalid' }),
        },
        pattern: DECIMAL_NUMBER_REGEX,
      },
    },
    cancel: {
      type: 'button',
      variant: 'outlined',
      label: intl.formatMessage({ id: 'cancelled' }),
      propsWrapper: { xs: undefined },
      onClick: () => {},
    },
    submit: {
      type: 'submitButton',
      label: intl.formatMessage({ id: 'save' }),
      propsWrapper: { xs: undefined },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'info' }),
      fields: ['code', 'name', 'description'],
    },
    {
      id: 'session',
      title: intl.formatMessage({ id: 'therapy.sessions' }),
      fields: ['detailList'],
    },
    { id: 'right', fields: ['packagePrice', 'retailPrice'] },
    {
      id: 'submit',
      fields: ['cancel', 'submit'],
      propsGridContainer: { justifyContent: 'flex-end' },
    },
  ];
};

export const indicationFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: ({ listElement, valuesField, fields }) => {
    const totalPrice = valuesField.detailList?.reduce((v, c) => v + Number(c.price || 0), 0) || 0;
    return (
      <>
        {listElement[0]}
        <Box mt={2}>{listElement[1]}</Box>
        <Box display={'flex'} gap={2} mt={2}></Box>
        <Box
          gap={1}
          display="flex"
          width={420}
          marginLeft={'540px'}
          flexDirection={'column'}
          style={{ paddingBottom: '20px' }}
        >
          <Box display={'flex'} justifyContent="left" alignItems={'center'}>
            <Typography variant="subtitle1" width={240} color={'#607d8b'}>
              <FormattedMessage id="therapy.totalPriceForm" />
            </Typography>
            <Typography variant="subtitle1">{NumericFormatText(totalPrice)}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="subtitle1" color={'#607d8b'}>
              <FormattedMessage id="therapy.allInPriceForm" />
            </Typography>
            <Box display={'flex'} gap={1}>
              {fields.right.packagePrice}
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box display={'flex'} justifyContent="space-between" alignItems={'center'} style={{ paddingTop: '20px' }}>
          <Typography variant="h6">
            <FormattedMessage id="therapy.outPackagePrice.title" />
            <Typography variant="body1" sx={{ fontStyle: 'italic' }}>
              <FormattedMessage id="therapy.outPackagePrice.subTitle" />
            </Typography>
          </Typography>
          <Box display={'flex'}>{fields.right?.retailPrice}</Box>
        </Box>
        <Box style={{ marginTop: '15px' }}>{listElement[4]}</Box>
      </>
    );
  },
};
