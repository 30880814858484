import { Box } from '@mui/material';
import Tabs from 'modules/common/component/Tabs';
import * as React from 'react';
import IncomeReportTab from '../component/report/businessReportTabs/IncomeReportTab';
import DebtReportTab from '../component/report/businessReportTabs/DebtReportTab';
import RevenueReportTab from '../component/report/businessReportTabs/RevenueReportTab';
import { ROLES } from 'modules/common/constants';

interface Props {}

const AdminBusinessReportPage: React.FunctionComponent<Props> = (props) => {
  return (
    <Box sx={{ padding: '10px 16px' }}>
      <Tabs
        searchUrlKey="tab"
        tabs={[
          {
            title: 'report.businessReport.tab.title.incomeReport',
            key: 'incomeReportTab',
            content: <IncomeReportTab />,
            roles: [ROLES.ADMIN.value, ROLES.COORDINATOR.value, ROLES.CASHIER.value, ROLES.GENERAL_DOCTOR.value],
          },
          {
            title: 'report.businessReport.tab.title.debtReport',
            key: 'debtReportTab',
            content: <DebtReportTab />,
            roles: [ROLES.ADMIN.value, ROLES.COORDINATOR.value, ROLES.CASHIER.value, ROLES.GENERAL_DOCTOR.value],
          },
          {
            title: 'report.businessReport.tab.title.revenueReport',
            key: 'revenueReportTab',
            content: <RevenueReportTab />,
            roles: [ROLES.ADMIN.value, ROLES.GENERAL_DOCTOR.value],
          },
        ]}
        title=""
      />
    </Box>
  );
};

export default AdminBusinessReportPage;
