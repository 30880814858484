import { ExpandLess, GridOn, Search } from '@mui/icons-material';
import { Box, Collapse, InputAdornment } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import SchemaForm from 'modules/common/SchemaForm';
import { axiosThunk } from 'modules/common/redux/axios';
import fileDownload from 'js-file-download';
import { useFetch } from 'modules/common/hook';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';

interface Props {
  pagin?: React.ReactNode;
  hookPagination: HookPaginationProps;
  onCreate: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const FilterBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, pagin, onCreate } = props;
  const { params, setParams, clearParams } = hookPagination;
  const { dispatch, locationId, API_SERVER, openSnackbar, intl } = useGeneralHook();

  const { data: medications = [] } = useFetch(API_SERVER.medication.index(locationId), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    dedupingInterval: 60000,
  });

  const { data: supplierOption } = useFetch(API_SERVER.supplier.get({ pageSize: 10000 }), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    dedupingInterval: 60000,
  });

  const exportExcelReport = async (valueFields) => {
    openSnackbar({ message: intl.formatMessage({ id: 'common.message.processing' }) });
    try {
      const response = await dispatch(
        axiosThunk({
          url: API_SERVER.importInventory.exportReport(valueFields),
          method: 'get',
          responseType: 'blob',
        }),
      );
      let fileName = response.headers['content-disposition']?.split('filename=')[1]?.split(';')[0];
      const effectiveFileName = fileName || 'inventory_import_report.xlsx';
      fileDownload(response.data, effectiveFileName);
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
    }
  };

  return (
    <SchemaForm
      schema={{
        fields: ({ formProps: { intl, state, setState }, valuesField }) => {
          return {
            code: {
              type: 'text-field',
              placeholder: intl.formatMessage({ id: 'inventory.searchByTicketCode' }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="inherit" />
                  </InputAdornment>
                ),
              },
              showClearBtn: true,
              propsWrapper: { xs: true },
            },
            search: {
              type: 'submitButton',
              color: 'inherit',
              label: intl.formatMessage({ id: 'search' }),
              propsWrapper: { xs: undefined },
            },
            export: {
              type: 'button',
              label: intl.formatMessage({ id: 'inventory.export.excel.button' }),
              propsWrapper: { xs: undefined },
              startIcon: <GridOn />,
              onClick: () => {
                setParams(valuesField);
                exportExcelReport(valuesField);
              },
            },
            expand: {
              type: 'button',
              endIcon: (
                <ExpandLess
                  style={{
                    transition: '0.2s all',
                    transform: state?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              ),
              color: 'primary',
              variant: 'text',
              onClick: () => setState({ expand: !state.expand }),
              label: intl.formatMessage({ id: 'advance' }),
              propsWrapper: { xs: undefined },
            },
            create: {
              type: 'button',
              startIcon: <AddIcon />,
              endIcon: <ExpandMoreIcon />,
              color: 'primary',
              onClick: (e) => onCreate(e),
              label: intl.formatMessage({ id: 'inventory.create' }),
              propsWrapper: { xs: undefined },
            },
            medicationId: {
              type: 'auto-complete',
              label: intl.formatMessage({ id: 'inventory.drugName' }),
              placeholder: intl.formatMessage({ id: 'select' }),
              options: medications?.map((v) => ({
                id: v.id,
                name: v.name,
                code: v.code,
                manufacturer: v.manufacturer,
                ingredient: v.ingredient,
              })),

              getOptionLabel: (option) => {
                return `${option.id} - ${option.name} (${option.ingredient})`;
              },
              propsWrapper: { xs: true },
            },
            lot: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'inventory.lot' }),
              placeholder: intl.formatMessage({ id: 'inventory.lotEnter' }),
              propsWrapper: { xs: true },
            },
            supplierId: {
              type: 'auto-complete',
              label: intl.formatMessage({ id: 'inventory.supplier' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              options: supplierOption?.content,
              labelKey: 'name',
              propsWrapper: { xs: true },
            },
            importDateFrom: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'importDateFrom' }),
              propsWrapper: { xs: true },
              maxDate: valuesField.importDateTo && moment(valuesField.importDateTo).toDate(),
            },
            importDateTo: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'importDateTo' }),
              propsWrapper: { xs: true },
              minDate: valuesField.importDateFrom && moment(valuesField.importDateFrom).toDate(),
            },
          };
        },
        ui: () => {
          return [
            {
              id: 'layout',
              propsGridContainer: { spacing: 1, justifyContent: 'flex-end' },
              fields: ['code', 'search', 'expand', 'export', 'create'],
            },
            {
              id: 'expand',
              fields: ['medicationId', 'lot', 'supplierId', 'importDateFrom', 'importDateTo'],
            },
          ];
        },
        layout: ({ formProps: { state }, listElement }) => {
          return (
            <>
              <Box display="flex" justifyContent={'space-between'}>
                <Box>{pagin}</Box>
                <Box width={900}>{listElement[0]}</Box>
              </Box>
              <Collapse in={state?.expand} style={{ marginTop: 16 }}>
                {listElement[1]}
              </Collapse>
            </>
          );
        },
      }}
      onSubmit={setParams}
      formData={params}
      clearParams={clearParams}
      hideSubmitButton
    />
  );
};

export default FilterBox;
