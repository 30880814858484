import { Stack, Typography } from '@mui/material';
import useFilterContext from '../useFilterContext';
import SelectInput from '../../form/SelectInput';
import { useUserRoles } from 'modules/common/hook';
import { ROLES } from 'modules/common/constants';
import { FormattedMessage } from 'react-intl';
import { EmployeeFilter, FilterProps } from '../type';
import ChipList from '../CustomeChip';

const Employee = ({ filter: { placeholder, roles, groupByRole, type } }: FilterProps<EmployeeFilter>) => {
  const { form } = useFilterContext();
  const users = useUserRoles(...roles);
  const selectedEmployees = form.watch(type);

  return (
    <Stack direction="column" gap="10px" flex={1}>
      <SelectInput
        form={form}
        name={type}
        options={users}
        getValue="id"
        renderLabel="employeeFullName"
        rawOptionLabel
        placeholder={placeholder}
        groupBy={groupByRole ? 'roleName' : undefined}
        multiple
        hideError
        renderValue={() => (
          <Typography
            sx={{
              color: '#AEB3B5',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              letterSpacing: '0.15px',
              lineHeight: 'unset',
            }}
          >
            <FormattedMessage id={placeholder} />
          </Typography>
        )}
        renderGroup={(optionKey) => ROLES[optionKey].label}
        showCheckBox={true}
      />
      {selectedEmployees && selectedEmployees.length > 0 && (
        <ChipList
          items={selectedEmployees}
          labelKey="employeeFullName"
          valueKey="id"
          onDelete={(employee) =>
            form.setValue(
              type,
              selectedEmployees.filter((ele) => ele.id !== employee),
            )
          }
        />
      )}
    </Stack>
  );
};

export default Employee;
