import { Box, styled } from '@mui/system';
import { Divider, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import PatientInfo from './PatientInfo';
import { some } from '../../../../../../common/constants';
import * as React from 'react';
import TherapyInfo from './TherapyInfo';
import TherapySessionDetailInfo from './TherapySessionDetailInfo';

interface Props {
  therapy: some;
  therapySession: some;
}

const PrintFormTitle = styled(Typography)({
  color: '#263238',
  textAlign: 'center',
  fontSize: '28px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '36px',
  textTransform: 'uppercase',
});

const DividerStyled = styled(Divider)({
  marginTop: '20px',
  marginBottom: '10px',
});

const Body = ({ therapy, therapySession }: Props) => {
  return (
    <Box sx={{ padding: '9px 64px 48px 64px' }}>
      <Stack marginBottom={3}>
        <PrintFormTitle>
          <FormattedMessage id="therapy.exam.therapyDetail.label.printForm" />
        </PrintFormTitle>
      </Stack>
      <PatientInfo therapy={therapy} />
      <DividerStyled />
      <TherapyInfo therapy={therapy} />
      <DividerStyled />
      <TherapySessionDetailInfo therapySession={therapySession} />
    </Box>
  );
};

export default Body;
