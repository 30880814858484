import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { mutate } from 'swr';
import { API_SERVER } from 'modules/common/api';
import MediCard from 'modules/common/component/MediCard';
import RouteLeavingGuard from 'modules/common/component/RouteLeavingGuard';
import SpeechButton from 'modules/common/component/SpeechButton';
import { PAYMENT_PLAN, THERAPY_REFERENCE_TYPE } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import FormControlAutoComplete from 'modules/common/SchemaForm/element/autocomplete/FormControlAutoComplete';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import { empty } from 'modules/examDoctor/component/reportForm/utils';
import VitalSignBox from 'modules/examDoctor/component/detail/VitalSignBox';
import SelectPrimaryICDBox from './SelectPrimaryICDBox';
import SelectSecondaryICDBox from './SelectSecondaryICDBox';
import TherapyEncounterBox from './therapy/TherapyEncounterBox';
import { setLoading } from 'modules/common/redux/commonReducer';
import { axiosThunk } from 'modules/common/redux/axios';
import TherapyTable from './therapy/TherapyTable';
import { getText } from 'modules/common/utils';
import { EncounterDetailCommonProps } from 'modules/examDoctor/type';
import { useFetch, useUpdateMutate } from 'modules/common/hook';
import { Therapy } from 'modules/schema';
import CarePlanSession from 'modules/reception/component/dental/DentalDetail/CarePlanSession';
import TherapyDiagnosticImage from 'modules/examDoctor/component/therapy/TherapyDiagnosticImage/index';
import moment from 'moment';

export const FORM_ID = 'encouter_info';

export const calculateBMI = (weightValue, heightValue) => {
  return weightValue && heightValue
    ? Number(Number(weightValue) / (((Number(heightValue) / 100) * Number(heightValue)) / 100)).toFixed(2)
    : '';
};

const EncounterInfoBox = ({ encounter, editable }: EncounterDetailCommonProps) => {
  const encounterId = encounter?.id!;
  const patientData: any = {};
  const { intl, dispatch, openSnackbar, confirmDialog, appState } = useGeneralHook();
  const userId = appState.authen.user?.user_id;
  const readOnly = React.useMemo(() => {
    return (
      (encounter?.status === 'FINISHED' && moment(encounter?.endTime).isBefore(moment().subtract(1, 'day'))) ||
      encounter?.status === 'ERROR' ||
      `${userId}` !== `${encounter?.picUserId}`
    );
  }, [userId, encounter?.endTime, encounter?.picUserId, encounter?.status]);

  const formMethods = useForm();
  const { handleSubmit, formState, reset, watch } = formMethods;
  const saveObservationMutate = useUpdateMutate({
    onSuccess: () => encounterId && mutate(API_SERVER.observation.getByEncounterId(encounterId).url),
    globalLoading: false,
  });

  const saveEncounterMutate = useUpdateMutate({
    globalLoading: false,
  });

  const weightValue = watch('bodyWeight');
  const heightValue = watch('bodyHeight');

  const bmi = React.useMemo(() => {
    return calculateBMI(weightValue, heightValue);
  }, [weightValue, heightValue]);

  // update basic field encounter
  const submit = async () => {
    if (
      formMethods.getValues('clinicalExamination') !== (encounter?.clinicalExamination || null) ||
      formMethods.getValues('differentialDiagnosis') !== (encounter?.differentialDiagnosis || null) ||
      formMethods.getValues('illnessesHistory') !== (encounter?.illnessesHistory || null) ||
      formMethods.getValues('medicalHistory') !== (encounter?.medicalHistory || null) ||
      formMethods.getValues('provisionalDiagnosis') !== (encounter?.provisionalDiagnosis || null)
    ) {
      await saveEncounterMutate({
        url: API_SERVER.encounter.updateInfoBasic(encounterId),
        method: 'PUT',
        data: {
          clinicalExamination: formMethods.getValues('clinicalExamination'),
          differentialDiagnosis: formMethods.getValues('differentialDiagnosis'),
          illnessesHistory: formMethods.getValues('illnessesHistory'),
          medicalHistory: formMethods.getValues('medicalHistory'),
          provisionalDiagnosis: formMethods.getValues('provisionalDiagnosis'),
        },
      });

      mutate(
        API_SERVER.encounter.get(encounterId).url,
        {
          ...encounter,
          illnessesHistory: formMethods.getValues('illnessesHistory'),
          differentialDiagnosis: formMethods.getValues('differentialDiagnosis'),
          clinicalExamination: formMethods.getValues('clinicalExamination'),
          medicalHistory: formMethods.getValues('medicalHistory'),
          provisionalDiagnosis: formMethods.getValues('provisionalDiagnosis'),
        },
        false,
      );
    }
  };

  const { data: observation } = useFetch(API_SERVER.observation.getByEncounterId(encounterId), {
    shouldRetryOnError: false,
    enabled: encounterId,
  });

  // update chi so sinh ton
  const submitObservation = React.useCallback(
    (data) => {
      if (
        formMethods.getValues('bodyHeight') !== observation?.bodyHeight ||
        formMethods.getValues('bodyWeight') !== observation?.bodyWeight ||
        formMethods.getValues('bodyTemperature') !== observation?.bodyTemperature ||
        formMethods.getValues('diastolicBloodPressure') !== observation?.diastolicBloodPressure ||
        formMethods.getValues('heartRate') !== observation?.heartRate ||
        formMethods.getValues('respiratoryRate') !== observation?.respiratoryRate ||
        formMethods.getValues('spo2') !== observation?.spo2 ||
        formMethods.getValues('systolicBloodPressure') !== observation?.systolicBloodPressure
      ) {
        saveObservationMutate({
          url: API_SERVER.observation.save(),
          method: 'post',
          data: {
            encounterId,
            bodyTemperature: formMethods.getValues('bodyTemperature'),
            heartRate: formMethods.getValues('heartRate'),
            respiratoryRate: formMethods.getValues('respiratoryRate'),
            systolicBloodPressure: formMethods.getValues('systolicBloodPressure'),
            diastolicBloodPressure: formMethods.getValues('diastolicBloodPressure'),
            bodyHeight: formMethods.getValues('bodyHeight'),
            bodyWeight: formMethods.getValues('bodyWeight'),
            spo2: formMethods.getValues('spo2'),
          },
        });
      }
    },
    [
      encounterId,
      formMethods,
      observation?.bodyHeight,
      observation?.bodyTemperature,
      observation?.bodyWeight,
      observation?.diastolicBloodPressure,
      observation?.heartRate,
      observation?.respiratoryRate,
      observation?.spo2,
      observation?.systolicBloodPressure,
      saveObservationMutate,
    ],
  );

  // get data chi so sinh ton

  const when = React.useMemo(
    () => Object.keys(formState.dirtyFields)?.length > 0 && !!formState.isDirty,
    [formState.dirtyFields, formState.isDirty],
  );

  // get data mau phieu kham benh
  const { data } = useFetch(API_SERVER.medicalExaminationForm.getList());

  const [tick, setTick] = React.useState(false); // required to force rerender for speech button

  // them mau phieu kham benh
  const addTemplate = async (data) => {
    const values = formMethods.getValues();
    if (empty(values)) {
      formMethods.setValue('illnessesHistory', data.illnessesHistory, { shouldDirty: true });
      formMethods.setValue('medicalHistory', data.medicalHistory, { shouldDirty: true });
      formMethods.setValue('clinicalExamination', data.symptom, {
        shouldDirty: true,
      });
      formMethods.setValue('provisionalDiagnosis', data.provisionalDiagnosis, {
        shouldDirty: true,
      });
      formMethods.setValue('differentialDiagnosis', data.differentialDiagnosis, { shouldDirty: true });
      formMethods.setValue('carePlanOtherValue', data.carePlanOtherValue, { shouldDirty: true });
    } else {
      const confirm = await confirmDialog.promptConfirmation({
        title: intl.formatMessage({ id: 'confirmOverwriteTitle' }),
        content: intl.formatMessage({ id: 'confirmOverwrite' }, { name: data.name }),
      });
      if (confirm) {
        formMethods.setValue('illnessesHistory', data.illnessesHistory, { shouldDirty: true });
        formMethods.setValue('medicalHistory', data.medicalHistory, { shouldDirty: true });
        formMethods.setValue('clinicalExamination', data.symptom, {
          shouldDirty: true,
        });
        formMethods.setValue('provisionalDiagnosis', data.provisionalDiagnosis, {
          shouldDirty: true,
        });
        formMethods.setValue('differentialDiagnosis', data.differentialDiagnosis, { shouldDirty: true });
        formMethods.setValue('carePlanOtherValue', data.carePlanOtherValue, { shouldDirty: true });
      }
      confirmDialog.close();
    }
    setTick((old) => !old);
    submit();
  };

  // get data lieu trinh
  const { data: therapyData = [], revalidate } = useFetch(
    API_SERVER.therapy.getTherapyByReference(encounterId, { type: THERAPY_REFERENCE_TYPE.ENCOUNTER }),
    { enabled: encounterId },
  );

  // map data lieu trinh
  const therapyMappedData = React.useMemo(() => {
    if (therapyData.length === 0) {
      return [];
    }

    const returnValue: Therapy[] = [];
    for (let i = 0; i < therapyData.length; i++) {
      returnValue.push({
        id: therapyData[i].id,
        name: therapyData[i].name,
        numberSession: therapyData[i].numberSession,
        packagePrice: therapyData[i].packagePrice,
        totalPrice: therapyData[i].totalPrice,
        description: therapyData[i].description,
        note: therapyData[i].note,
        status: therapyData[i].status,
      });
    }
    returnValue.sort((a, b) => a.id! - b.id!);
    return { therapies: returnValue };
  }, [therapyData]);

  // huy lieu trinh
  const changeTherapyStatus = React.useCallback(
    async (value) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmCancelTitle' }),
        content: intl.formatMessage({ id: 'therapy.therapyDetail.deleteAction.confirmMessage.therapy' }),
        cancelId: 'no',
        okId: 'yes',
      });
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.therapy.cancel(value.id),
            method: 'put',
            data: {
              data: value?.note,
            },
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'encounterInfo.cancelSuccess' }) });
          })
          .catch((e) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({
                  message: v.message,
                  type: 'error',
                });
              });
          })
          .finally(() => {
            dispatch(setLoading(false));
            revalidate();
            confirmDialog.close();
          });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, revalidate],
  );

  // them lieu trinh
  const onRequestTherapy = React.useCallback(
    async (value) => {
      dispatch(setLoading(true));
      const differentialDiagnosis = formMethods.getValues('differentialDiagnosis')
        ? getText(formMethods.getValues('differentialDiagnosis'))
        : '';
      await dispatch(
        axiosThunk({
          url: API_SERVER.therapy.assignmentReference(),
          method: 'post',
          data: {
            referenceId: encounterId,
            type: 'ENCOUNTER',
            patientId: encounter?.patient?.id,
            userId: encounter?.picUserId,
            therapyDefinitionId: value?.therapy?.id,
            diagnosis: differentialDiagnosis,
            paymentPlan: PAYMENT_PLAN[encounter?.paymentPlan!]?.value,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: 'encounterInfo.requestSuccess' }) });
        })
        .catch((e) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({
                message: v.message,
                type: 'error',
              });
            });
        })
        .finally(() => {
          dispatch(setLoading(false));
          revalidate();
        });
    },
    [
      dispatch,
      encounter?.patient?.id,
      encounter?.paymentPlan,
      encounter?.picUserId,
      encounterId,
      formMethods,
      intl,
      openSnackbar,
      revalidate,
    ],
  );

  // reset data chi so sinh ton, basic field
  React.useEffect(() => {
    reset({
      ...observation,
      ...encounter,
    });
  }, [encounter, observation, reset]);

  return (
    <>
      <RouteLeavingGuard when={when} message={intl.formatMessage({ id: 'youHaveUnsavedData' })} />
      <FormProvider {...formMethods}>
        <Box component="form" onSubmit={handleSubmit(submit)} bgcolor="white">
          <input type="submit" id={FORM_ID} style={{ display: 'none' }} />
          <Grid container spacing={2}>
            <Grid item md={12}>
              <VitalSignBox readOnly={readOnly} submit={submitObservation} bmi={bmi} />
            </Grid>
            <Grid
              item
              md={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 16,
              }}
            >
              <Typography variant="subtitle1" color="textSecondary" component="div">
                <FormattedMessage id="encounterForm" />
              </Typography>
              <Box flex={1}>
                <FormControlAutoComplete
                  key={`${tick}`}
                  placeholder={intl.formatMessage({ id: 'encounterForm.pick' })}
                  options={data}
                  getOptionLabel={(opt) => opt.name}
                  inputStyle={{ width: 450 }}
                  disableClearable
                  readOnly={readOnly}
                  onChange={(_, data) => {
                    addTemplate(data);
                  }}
                />
              </Box>
            </Grid>
            <Grid item xs={6}>
              <MediCard
                title={'encounterDetail.illnessHistory'}
                hasCollapse
                speech={
                  <SpeechButton
                    fieldName="illnessesHistory"
                    onStopRecording={(transcript) => {
                      formMethods.setValue(
                        'illnessesHistory',
                        (formMethods.getValues('illnessesHistory') || '') + ' ' + transcript,
                      );
                    }}
                  />
                }
              >
                <Box padding={1}>
                  <SchemaElement
                    fieldName="illnessesHistory"
                    propsElement={{
                      type: 'text-editor',
                      placeholder: intl.formatMessage({ id: 'enter' }),
                      noHelperText: true,
                      readOnly: readOnly,
                      height: 240,
                      onBlur: () => {
                        submit();
                      },
                    }}
                  />
                </Box>
              </MediCard>
            </Grid>
            <Grid item xs={6}>
              <MediCard
                title={'encounterDetail.medicalHistory'}
                hasCollapse
                speech={
                  <SpeechButton
                    fieldName="medicalHistory"
                    onStopRecording={(transcript) => {
                      formMethods.setValue(
                        'medicalHistory',
                        (formMethods.getValues('medicalHistory') || '') + ' ' + transcript,
                      );
                    }}
                  />
                }
              >
                <Box padding={1}>
                  <SchemaElement
                    fieldName="medicalHistory"
                    propsElement={{
                      type: 'text-editor',
                      placeholder: intl.formatMessage({ id: 'enter' }),
                      noHelperText: true,
                      readOnly: readOnly,
                      height: 240,
                      onBlur: () => {
                        submit();
                      },
                    }}
                  />
                </Box>
              </MediCard>
            </Grid>
            <Grid item xs={6}>
              <MediCard
                title={'encounterDetail.clinicalSymptoms'}
                speech={
                  <SpeechButton
                    fieldName="clinicalExamination"
                    onStopRecording={(transcript) => {
                      formMethods.setValue(
                        'clinicalExamination',
                        (formMethods.getValues('clinicalExamination') || '') + ' ' + transcript,
                      );
                      setTick((o) => !o);
                    }}
                  />
                }
              >
                <Box padding={1} display="flex" flexDirection={'column'} gap={1}>
                  <SchemaElement
                    fieldName="clinicalExamination"
                    propsElement={{
                      type: 'text-editor',
                      noHelperText: true,
                      placeholder: intl.formatMessage({ id: 'enter' }),
                      readOnly: readOnly,
                      height: 240,
                      onBlur: () => {
                        submit();
                      },
                    }}
                  />
                  <TherapyDiagnosticImage therapy={encounter} therapySession={{}} editable={!readOnly} />
                </Box>
              </MediCard>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MediCard
                    title={'preliminaryDiagnosis'}
                    speech={
                      <SpeechButton
                        fieldName="provisionalDiagnosis"
                        onStopRecording={(transcript) => {
                          formMethods.setValue(
                            'provisionalDiagnosis',
                            (formMethods.getValues('provisionalDiagnosis') || '') + ' ' + transcript,
                          );
                        }}
                      />
                    }
                  >
                    <Box padding={1}>
                      <SchemaElement
                        fieldName="provisionalDiagnosis"
                        propsElement={{
                          type: 'text-editor',
                          placeholder: intl.formatMessage({ id: 'enter' }),
                          noHelperText: true,
                          readOnly: readOnly,
                          height: 160,
                          onBlur: () => {
                            submit();
                          },
                        }}
                      />
                    </Box>
                  </MediCard>
                </Grid>{' '}
                <Grid item xs={12}>
                  <MediCard
                    title={'encounterDetail.diagnosis'}
                    speech={
                      <SpeechButton
                        fieldName="differentialDiagnosis"
                        onStopRecording={(transcript) => {
                          formMethods.setValue(
                            'differentialDiagnosis',
                            (formMethods.getValues('differentialDiagnosis') || '') + ' ' + transcript,
                          );
                        }}
                      />
                    }
                  >
                    <Box padding={1}>
                      <SchemaElement
                        fieldName="differentialDiagnosis"
                        propsElement={{
                          type: 'text-editor',
                          placeholder: intl.formatMessage({ id: 'enter' }),
                          noHelperText: true,
                          height: 240,
                          readOnly: readOnly,
                          onBlur: () => {
                            submit();
                          },
                        }}
                      />
                    </Box>
                  </MediCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SelectPrimaryICDBox
                    title={
                      <Typography variant="subtitle2" style={{ color: 'white' }}>
                        <FormattedMessage id="encounterInfo.codeICD10" />
                      </Typography>
                    }
                    style={{ minHeight: readOnly ? 'unset' : 154 }}
                    readOnly={readOnly}
                    encounter={encounter}
                  />
                </Grid>
                <Grid item xs={12}>
                  <SelectSecondaryICDBox
                    title={
                      <Typography variant="subtitle2" style={{ color: 'white' }}>
                        <FormattedMessage id="encounterInfo.codeICD10Support" />
                      </Typography>
                    }
                    style={{ minHeight: readOnly ? 'unset' : 154 }}
                    readOnly={readOnly}
                    encounter={encounter}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MediCard title={'encounterInfo.solution'}>
                    <Box padding={1}>
                      <CarePlanSession readOnly={readOnly} encounter={encounter} />
                    </Box>
                  </MediCard>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <MediCard title={'encounterInfo.therapy'}>
                    <Box padding={1}>
                      {!!!readOnly && (
                        <TherapyEncounterBox
                          patientData={patientData}
                          readOnly={readOnly}
                          encounterId={encounterId}
                          onRequestTherapy={onRequestTherapy}
                        />
                      )}
                    </Box>
                    <Box padding={1}>
                      <TherapyTable
                        data={therapyMappedData}
                        readOnly={readOnly}
                        isAuthor={appState.authen.user?.user_id === encounter?.picUserId}
                        onChangeStatus={changeTherapyStatus}
                        revalidate={revalidate}
                      />
                    </Box>
                  </MediCard>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </FormProvider>
    </>
  );
};

export default EncounterInfoBox;
