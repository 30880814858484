import { Box, Button, Grid, InputAdornment, TextField, Typography } from '@mui/material';
import * as React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import {
  IconBloodPressure,
  IconBMI,
  IconHeartBeat,
  IconHeight,
  IconLung,
  IconSPO2,
  IconTemperature,
  IconWeight,
} from '../../../svg';
import RouteLeavingGuard from '../../common/component/RouteLeavingGuard';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { SMALL_DECIMAL_NUMBER_REGEX } from '../../common/regex';
import SchemaElement from '../../common/SchemaForm/SchemaElement';
import { default as VitalSignItem, default as VItalSignItem } from '../../examDoctor/component/detail/VitalSignItem';
import { VITAL_SIGN_CODE } from '../../examDoctor/constants';
import { API_SERVER } from 'modules/common/api';
import { useFetch } from 'modules/common/hook/useFetch';
import { useUpdateMutate } from 'modules/common/hook/useMutate';

const NUMBER_REGEX = /^[0-9]{1,3}([.][0-9]{1,2})?$/;

export const calculateBMI = (weightValue, heightValue) => {
  return weightValue && heightValue
    ? Number(Number(weightValue) / (((Number(heightValue) / 100) * Number(heightValue)) / 100)).toFixed(2)
    : '';
};

interface Props {
  readOnly?: boolean;
  encounterId: number;
  onClose?: () => void;
}

const VitalSignForm: React.FunctionComponent<Props> = (props) => {
  const { readOnly, encounterId, onClose } = props;
  const { intl } = useGeneralHook();
  const formMethods = useForm();
  const { handleSubmit, formState, watch, reset } = formMethods;

  const weightValue = watch('bodyWeight');
  const heightValue = watch('bodyHeight');

  const bmi = React.useMemo(() => {
    return calculateBMI(weightValue, heightValue);
  }, [weightValue, heightValue]);

  const when = React.useMemo(
    () => Object.keys(formState.dirtyFields)?.length > 0 && !!formState.isDirty,
    [formState.dirtyFields, formState.isDirty],
  );

  const saveObservationMutate = useUpdateMutate({ onSuccess: onClose });

  const onSubmit = React.useCallback(
    (data) => {
      saveObservationMutate({
        url: API_SERVER.observation.save(),
        method: 'post',
        data: {
          encounterId,
          bodyTemperature: parseFloat(data?.bodyTemperature),
          systolicBloodPressure: parseFloat(data?.systolicBloodPressure),
          diastolicBloodPressure: parseFloat(data?.diastolicBloodPressure),
          respiratoryRate: parseFloat(data?.respiratoryRate),
          heartRate: parseFloat(data?.heartRate),
          bodyHeight: parseFloat(data?.bodyHeight),
          bodyWeight: parseFloat(data?.bodyWeight),
          spo2: parseFloat(data?.spo2),
        },
      });
    },
    [encounterId, saveObservationMutate],
  );

  const { data: observation } = useFetch(API_SERVER.observation.getByEncounterId(encounterId), {
    shouldRetryOnError: false,
    enabled: encounterId,
  });

  React.useEffect(() => {
    if (!observation) return;
    reset(observation);
  }, [observation, reset]);

  return (
    <>
      <RouteLeavingGuard when={when} message={intl.formatMessage({ id: 'youHaveUnsavedData' })} />
      <FormProvider {...formMethods}>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} bgcolor="white">
          <Grid container spacing={2}>
            <Grid item xs={6} md={3}>
              <VItalSignItem
                icon={<IconTemperature style={{ height: 32, width: 32 }} />}
                label={
                  <Typography
                    variant="subtitle1"
                    color={!!formState.errors.bodyTemperature ? 'error' : 'textSecondary'}
                  >
                    <FormattedMessage id="encounterDetail.temperature" />
                  </Typography>
                }
                control={
                  <>
                    <SchemaElement
                      fieldName="bodyTemperature"
                      propsElement={{
                        type: 'text-field',
                        register: { pattern: SMALL_DECIMAL_NUMBER_REGEX },
                        variant: 'standard',
                        noHelperText: true,
                        InputProps: {
                          style: { marginTop: 0, background: 'inherit' },
                          readOnly: readOnly,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="caption">{VITAL_SIGN_CODE.temperature.unit}</Typography>
                            </InputAdornment>
                          ),
                        },
                      }}
                    />
                  </>
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconBloodPressure style={{ height: 32, width: 32 }} />}
                label={
                  <Typography
                    variant="subtitle1"
                    color={
                      !!formState.errors.systolicBloodPressure || !!formState.errors.diastolicBloodPressure
                        ? 'error'
                        : 'textSecondary'
                    }
                  >
                    <FormattedMessage id="encounterDetail.bloodPressure" />
                  </Typography>
                }
                control={
                  <Box display="flex" alignItems="center">
                    <SchemaElement
                      fieldName="systolicBloodPressure"
                      propsElement={{
                        type: 'text-field',
                        register: { pattern: NUMBER_REGEX },
                        variant: 'standard',
                        noHelperText: true,
                        InputProps: {
                          style: { marginTop: 0, background: 'inherit' },
                          readOnly: readOnly,
                        },
                        inputProps: {
                          style: { paddingLeft: 0, paddingRight: 0 },
                        },
                      }}
                    />
                    <Box style={{ position: 'relative', top: -2 }} marginX={0.5}>
                      /
                    </Box>
                    <SchemaElement
                      fieldName="diastolicBloodPressure"
                      propsElement={{
                        type: 'text-field',
                        style: { minWidth: 95 },
                        register: { pattern: NUMBER_REGEX },
                        variant: 'standard',
                        noHelperText: true,
                        InputProps: {
                          style: { marginTop: 0, background: 'inherit' },
                          readOnly: readOnly,
                          endAdornment: (
                            <InputAdornment position="end">
                              <Typography variant="caption">{VITAL_SIGN_CODE.bloodPressure.unit}</Typography>
                            </InputAdornment>
                          ),
                        },
                        inputProps: {
                          style: { paddingLeft: 0, paddingRight: 0 },
                        },
                      }}
                    />
                  </Box>
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconLung style={{ height: 32, width: 32 }} />}
                label={
                  <Typography
                    variant="subtitle1"
                    color={!!formState.errors.respiratoryRate ? 'error' : 'textSecondary'}
                  >
                    <FormattedMessage id="encounterDetail.breathBeat" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="respiratoryRate"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.breath.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconHeartBeat style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={!!formState.errors.heartRate ? 'error' : 'textSecondary'}>
                    <FormattedMessage id="encounterDetail.heartBeat" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="heartRate"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.heartBeat.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconHeight style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={!!formState.errors.bodyHeight ? 'error' : 'textSecondary'}>
                    <FormattedMessage id="encounterDetail.height" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="bodyHeight"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.height.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconWeight style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={!!formState.errors.bodyWeight ? 'error' : 'textSecondary'}>
                    <FormattedMessage id="encounterDetail.weight" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="bodyWeight"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.weight.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconBMI style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={'textSecondary'}>
                    <FormattedMessage id="encounterDetail.BMI" />
                  </Typography>
                }
                control={
                  <TextField
                    value={bmi}
                    variant="standard"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    InputProps={{
                      style: { marginTop: 0, background: 'inherit' },
                      readOnly: true,
                    }}
                  />
                }
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <VitalSignItem
                icon={<IconSPO2 style={{ height: 32, width: 32 }} />}
                label={
                  <Typography variant="subtitle1" color={!!formState.errors.spo2 ? 'error' : 'textSecondary'}>
                    <FormattedMessage id="encounterDetail.SpO2" />
                  </Typography>
                }
                control={
                  <SchemaElement
                    fieldName="spo2"
                    propsElement={{
                      type: 'text-field',
                      register: { pattern: NUMBER_REGEX },
                      InputLabelProps: { shrink: true },
                      variant: 'standard',
                      noHelperText: true,
                      InputProps: {
                        style: { marginTop: 0, background: 'inherit' },
                        readOnly: readOnly,
                        endAdornment: (
                          <InputAdornment position="end">
                            <Typography variant="caption">{VITAL_SIGN_CODE.spo2.unit}</Typography>
                          </InputAdornment>
                        ),
                      },
                    }}
                  />
                }
              />
            </Grid>
          </Grid>{' '}
          <Box textAlign="right" paddingTop={2} bgcolor="white" display="flex" gap={2} justifyContent="flex-end">
            {onClose && (
              <Button variant="outlined" color="primary" type="submit" style={{ minWidth: 180 }} onClick={onClose}>
                <FormattedMessage id="cancel" />
              </Button>
            )}
            {!readOnly && (
              <Button variant="contained" color="primary" type="submit" style={{ minWidth: 180 }}>
                <FormattedMessage id="save" />
              </Button>
            )}
          </Box>
        </Box>
      </FormProvider>
    </>
  );
};

export default VitalSignForm;
