import { Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const FilterTitle = styled(Typography)({
  color: '#607D8B',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  whiteSpace: 'nowrap',
  minWidth: '200px',
});

export const FilterValue = styled(Typography)({
  color: '#263238',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.25px',
  cursor: 'pointer',
});
