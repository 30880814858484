import { Box, Typography } from '@mui/material';
import { push } from 'connected-react-router';
import { API_SERVER } from 'modules/common/api';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, INDICATION_REQUEST_STATUS } from 'modules/common/constants';
import { useFetch } from 'modules/common/hook';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { ROUTES } from 'modules/layout/router';
import { IndicationRequest } from 'modules/schema';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

interface Props {
  dentalExamId: number;
}

const IndicationTable = ({ dentalExamId }: Props) => {
  const { dispatch } = useGeneralHook();

  const { data: indicationRequests, isValidating } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(dentalExamId, 'DENTAL_EXAM', 'RADIOLOGY_SERVICE'),
  );

  const COLUMNS: Columns<IndicationRequest>[] = [
    {
      title: 'labDoctor.testName',
      dataIndex: 'serviceName',
    },
    {
      title: 'indicationDate',
      render: (record) => (
        <Typography>
          {record.createdTime && moment(record.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
        </Typography>
      ),
    },
    {
      title: 'doctorOfIndication',
      dataIndex: 'picName',
    },
    {
      title: 'interpreterOfIndication',
      dataIndex: 'executeName',
    },
    {
      title: 'status',
      dataIndex: 'status',
      render: (record) => (
        <Box component="span" color={INDICATION_REQUEST_STATUS[record.status!].color}>
          <FormattedMessage id={`imageIndication.status.${record.status}`} />
        </Box>
      ),
    },
  ];

  return (
    <Box margin={1} bgcolor="white">
      <TableCustom
        loading={isValidating}
        dataSource={indicationRequests?.filter(
          (indication) => indication.status !== INDICATION_REQUEST_STATUS.WAIT.value,
        )}
        containerProps={{
          sx: {
            '& th,tr,td': {
              padding: '4px 8px',
              backgroundColor: 'unset',
              '&:hover': {
                backgroundColor: 'unset',
              },
            },
            overflow: 'visible',
            minWidth: 220,
          },
        }}
        rowProps={(record) => ({
          onClick: async (e) => {
            dispatch(push(ROUTES.detailIndicationDental.gen(record.id + '', record.referenceId + '')));
          },
          style: { cursor: 'pointer' },
        })}
        columns={COLUMNS}
      />
    </Box>
  );
};

export default IndicationTable;
