import { Button, FormHelperText, Grid, Tooltip, Typography } from '@mui/material';
import { get, pickBy } from 'lodash';
import _ from 'lodash/fp';
import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { useHotkeys } from 'react-hotkeys-hook';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import ArrayElement from './element/array-element/ArrayElement';
import AutoCompleteElement from './element/autocomplete/AutoCompleteElement';
import FormControlAutoComplete from './element/autocomplete/FormControlAutoComplete';
import CheckBoxElement from './element/checkbox/CheckBoxElement';
import DateRangePickerElement from './element/date-range/DateRangePickerElement';
import DatePickerElement from './element/datepicker-element/DatePickerElement';
import DateTimePickerElement from './element/datepickerTime-element/DateTimePickerElement';
import MultipleCheckBoxElement from './element/multiple-checkbox/MultipleCheckBoxElement';
import MultipleRadioElement from './element/multiple-radio/MultipleRadioElement';
import RadioElement from './element/radio/RadioElement';
import SectionElement from './element/section-element/SectionElement';
import SelectElement from './element/select/SelectElement';
import SwitchElement from './element/switch/SwitchElement';
import TextEditorElement from './element/text-editor/TextEditorElement';
import TextFieldElement from './element/text-field/TextFieldElement';
import TimePickerElement from './element/timepicker-element/TimePickerElement';
import UploadImageElement from './element/uploadImage/UploadImageElement';
import { ElementFormProps, ElementType, FreeElementSchemaProps } from './utils';
import LabelElement from './element/label/LabelElement';
import NumberFieldCustomElement from './element/number-field-custom/NumberFieldCustomElement';
import TimePickerDesktopElement from './element/timepicker-element/TimePickerDesktopElement';
import DatePickerDesktopElement from './element/datepicker-element/DatePickerDesktopElement';
import AlertWarningElement from './element/alert/AlertWarningElement';

interface Props {
  fieldName: string;
  rawElement?: boolean;
  shouldUnregister?: boolean;
  propsElement: ElementFormProps;
}

SchemaElement.defaultProps = {};

function SchemaElement(props: Props | some) {
  const { fieldName, rawElement, shouldUnregister, propsElement } = props;
  const {
    type,
    component,
    unregister,
    xs,
    register: registerElement = {},
    propsWrapper,
    defaultValue,
    inputType,
    tooltipError,
    submitLabel,
    typeButton,
    noHelperText,
    hotKeys,
    hidden,
    helperProps,
    ...rest
  } = propsElement;

  const methods = useFormContext();
  const {
    control,
    register,
    formState: { errors },
    setFocus,
  } = methods;

  useHotkeys(
    hotKeys,
    () => {
      hotKeys && setFocus(fieldName);
    },
    { enableOnTags: ['INPUT', 'SELECT', 'TEXTAREA'] },
  );

  const { valueAsDate, valueAsNumber, pattern, setValueAs, ...restRegis } = registerElement;

  const required =
    'required' in restRegis
      ? typeof restRegis?.required === 'boolean'
        ? restRegis?.required
        : restRegis?.required?.value
      : rest.required;

  const getElement = React.useCallback(() => {
    let element;
    if (!type) {
      return [];
    }
    switch (type as ElementType) {
      case 'hidden':
        element = <input type="hidden" {...register(fieldName)} />;
        break;
      case 'text-field':
        element = unregister ? (
          <TextFieldElement
            fullWidth
            onChange={(e) => {
              rest?.onChange && rest?.onChange(e.target.value);
            }}
            type={inputType}
          />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <>
                <TextFieldElement
                  inputRef={ref}
                  fullWidth
                  required={required}
                  name={name}
                  {...rest}
                  // value={value || ''}//In case value is 0 this will show empty string =>wrong
                  value={typeof value === 'string' || typeof value === 'number' ? value : ''}
                  onChange={(...params) => {
                    onChange(...params);
                    rest.onChange && rest.onChange(...params);
                  }}
                  type={inputType}
                  error={!!error}
                />
              </>
            )}
          />
        );
        break;
      case 'number-field':
        element = unregister ? (
          <NumberFieldCustomElement
            fullWidth
            onChange={(e) => {
              rest?.onChange && rest?.onChange(e.target.value);
            }}
            // type={inputType}
          />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <>
                <NumberFieldCustomElement
                  inputRef={ref}
                  fullWidth
                  required={required}
                  name={name}
                  {...rest}
                  // value={value || ''}//In case value is 0 this will show empty string =>wrong
                  value={typeof value === 'string' || typeof value === 'number' ? value : ''}
                  onChange={(event) => {
                    event.target.value = event.target.value ? parseInt(event.target.value) : '';
                    onChange(event);
                    rest.onChange && rest.onChange(event);
                  }}
                  // type={inputType}
                  error={!!error}
                />
              </>
            )}
          />
        );
        break;
      case 'label':
        element = <LabelElement {...rest} />;
        break;
      case 'alert-warning':
        element = <AlertWarningElement {...rest} />;
        break;
      case 'checkbox':
        element = unregister ? (
          <CheckBoxElement {...rest} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || false}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <CheckBoxElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChange(...params);
                  rest.onChange && rest.onChange(...params);
                }}
                {...field}
                error={!!error}
              />
            )}
          />
        );
        break;
      case 'multiple-checkbox':
        element = unregister ? (
          <MultipleCheckBoxElement {...rest} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || []}
            render={({ field: { onChange, ref, ...field }, fieldState: { error } }) => (
              <MultipleCheckBoxElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChange(...params);
                  rest.onChange && rest.onChange(...params);
                }}
                {...field}
                error={!!error}
              />
            )}
          />
        );
        break;
      case 'radio':
        element = unregister ? (
          <RadioElement {...rest} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || false}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <RadioElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChange(...params);
                  rest.onChange && rest.onChange(...params);
                }}
                {...field}
                error={!!error}
              />
            )}
          />
        );
        break;
      case 'multiple-radio':
        element = unregister ? (
          <MultipleRadioElement {...rest} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <MultipleRadioElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChange(...params);
                  rest.onChange && rest.onChange(...params);
                }}
                {...field}
                error={!!error}
              />
            )}
          />
        );
        break;
      case 'select':
        element = unregister ? (
          <SelectElement {...rest} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => {
              return (
                <SelectElement
                  required={required}
                  {...rest}
                  onChange={(...params) => {
                    onChange(...params);
                    rest.onChange && rest.onChange(...params);
                  }}
                  {...field}
                  error={!!error}
                />
              );
            }}
          />
        );
        break;
      case 'switch':
        element = unregister ? (
          <SwitchElement {...rest} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || false}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <SwitchElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChange(...params);
                  rest.onChange && rest.onChange(...params);
                }}
                {...field}
                error={!!error}
              />
            )}
          />
        );
        break;
      case 'uploadImage':
        element = unregister ? (
          <UploadImageElement {...rest} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue}
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => (
              <UploadImageElement
                required={required}
                name={name}
                {...rest}
                value={value}
                onChange={(...params) => {
                  onChange(...params);
                  rest.onChange && rest.onChange(...params);
                }}
                error={!!error}
              />
            )}
          />
        );
        break;

      case 'auto-complete':
        element = unregister ? (
          <FormControlAutoComplete fullWidth {...rest} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue}
            render={(propsRender) => <AutoCompleteElement required={required} {...rest} {...propsRender} />}
          />
        );
        break;
      case 'timePicker':
        element = unregister ? (
          <TimePickerElement {...(rest as any)} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || null}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => {
              return (
                <TimePickerElement
                  required={required}
                  {...rest}
                  onChange={(...params) => {
                    onChange(...params);
                    rest.onChange && rest.onChange(...params);
                  }}
                  {...field}
                  error={!!error}
                />
              );
            }}
          />
        );
        break;
      case 'timePickerDesktop':
        element = unregister ? (
          <TimePickerDesktopElement {...(rest as any)} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || null}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => {
              return (
                <TimePickerDesktopElement
                  required={required}
                  {...rest}
                  onChange={(...params) => {
                    onChange(...params);
                    rest.onChange && rest.onChange(...params);
                  }}
                  {...field}
                  error={!!error}
                />
              );
            }}
          />
        );
        break;
      case 'datePicker':
        element = unregister ? (
          <DatePickerElement {...(rest as any)} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || null}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => {
              return (
                <DatePickerElement
                  required={required}
                  {...rest}
                  onChange={(...params) => {
                    onChange(...params);
                    rest.onChange && rest.onChange(...params);
                  }}
                  {...field}
                  error={!!error}
                />
              );
            }}
          />
        );
        break;
      case 'datePickerDesktop':
        element = unregister ? (
          <DatePickerDesktopElement {...(rest as any)} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || null}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => {
              return (
                <DatePickerDesktopElement
                  required={required}
                  {...rest}
                  onChange={(...params) => {
                    onChange(...params);
                    rest.onChange && rest.onChange(...params);
                  }}
                  {...field}
                  error={!!error}
                />
              );
            }}
          />
        );
        break;
      case 'dateRange':
        element = unregister ? (
          <DateRangePickerElement {...(rest as any)} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || null}
            render={({ field: { value, onChange, ...field }, fieldState: { error } }) => (
              <DateRangePickerElement
                required={required}
                {...field}
                {...rest}
                value={[value?.start || null, value?.end || null]}
                onChange={(value: any) => {
                  const tmp = {
                    start: value?.[0] || null,
                    end: value?.[1] || null,
                  };
                  onChange(tmp);
                  rest.onChange && rest.onChange(tmp);
                }}
                error={!!error}
              />
            )}
          />
        );
        break;
      case 'dateTimePicker':
        element = unregister ? (
          <DatePickerElement {...(rest as any)} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || null}
            render={({ field: { onChange, ...field }, fieldState: { error } }) => (
              <DateTimePickerElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChange(...params);
                  rest.onChange && rest.onChange(...params);
                }}
                {...field}
                error={!!error}
              />
            )}
          />
        );
        break;

      case 'text-editor':
        element = unregister ? (
          <TextEditorElement {...rest} />
        ) : (
          <Controller
            name={fieldName}
            control={control}
            shouldUnregister={shouldUnregister}
            rules={registerElement}
            defaultValue={defaultValue || null}
            render={({ field: { onChange, ...field }, fieldState: { error }, formState: { isSubmitting } }) => (
              <TextEditorElement
                required={required}
                {...rest}
                onChange={(...params) => {
                  onChange(...params);
                  rest.onChange && rest.onChange(...params);
                }}
                isSubmitting={isSubmitting}
                {...field}
                onBlur={rest?.onBlur}
                error={!!error}
              />
            )}
          />
        );
        break;
      case 'array':
        element = <ArrayElement {...rest} name={fieldName} noHelperText={noHelperText} />;
        break;
      case 'section':
        element = <SectionElement {...rest} name={fieldName} noHelperText={noHelperText} />;
        break;
      case 'submitButton':
        element = (
          <Button type={typeButton || 'submit'} {...rest}>
            {rest.label || submitLabel || <FormattedMessage id="save" />}
          </Button>
        );
        break;
      case 'button':
        element = (
          <Button type={typeButton || 'button'} {...rest}>
            {rest.label}
          </Button>
        );
        break;
      case 'raw':
        element = typeof component === 'function' && component(rest);
        break;
      default:
        try {
          element = unregister ? (
            typeof type === 'function' ? (
              type(rest)
            ) : (
              React.createElement(type as any, rest)
            )
          ) : (
            <Controller
              name={fieldName}
              defaultValue={defaultValue}
              render={(propsRender) => (
                <>
                  {React.createElement(
                    type as any,
                    {
                      ...propsRender,
                      ...rest,
                    } as FreeElementSchemaProps,
                  )}
                </>
              )}
              rules={registerElement}
            />
          );
        } catch (e) {}
        break;
    }

    return element;
  }, [
    type,
    register,
    fieldName,
    unregister,
    inputType,
    control,
    shouldUnregister,
    registerElement,
    defaultValue,
    rest,
    noHelperText,
    typeButton,
    submitLabel,
    component,
    required,
  ]);

  const errorMessage = React.useCallback(() => {
    const tmp = pickBy(get(errors, fieldName), (value, key) => {
      return key !== 'ref';
    });
    const type_tmp = tmp?.type;
    const message = tmp?.message;
    delete tmp.type;
    delete tmp.message;
    return { ...tmp, message: message || type_tmp };
  }, [errors, fieldName]);

  // const tmp = fieldName ? watch(fieldName, undefined) : watch();

  // const previousValue = React.useRef(undefined);

  // React.useEffect(() => {
  //   if (
  //     !isEqual(tmp || null, previousValue.current || null) &&
  //     onChangeField &&
  //     typeof previousValue.current !== 'undefined'
  //   ) {
  //     onChangeField(tmp);
  //   }
  //   previousValue.current = tmp || null;
  // }, [fieldName, onChangeField, tmp, watch]);

  const content = React.useCallback(() => {
    const messageError = rest?.readOnly ? (
      <FormattedMessage id="readOnly" />
    ) : (
      errorMessage() &&
      _.entries(errorMessage()).map(
        ([type_error, message]: [string, unknown]) =>
          typeof message === 'string' &&
          (message ? type_error !== 'type' : true) && (
            <span key={type_error}>
              {message === 'required' ? <FormattedMessage id="required" /> : message}
              <br />
            </span>
          ),
      )
    );
    return (
      <>
        {getElement()}
        {type !== 'submitButton' && type !== 'button' && typeof type === 'string' && !noHelperText && (
          <Tooltip title={tooltipError ? messageError : ''} arrow>
            <FormHelperText style={{ height: 8 }} error={!rest?.readOnly} component="div">
              <Typography variant="caption" color="inherit" noWrap component="div" {...helperProps}>
                {messageError}
              </Typography>
            </FormHelperText>
          </Tooltip>
        )}
      </>
    );
  }, [errorMessage, getElement, helperProps, noHelperText, rest?.readOnly, tooltipError, type]);

  if (hidden) {
    return null;
  }
  if (type === 'hidden') {
    return content();
  }
  if (rawElement) {
    return <div {...propsWrapper}>{content()}</div>;
  }
  return (
    <Grid item xs={xs || 12} {...propsWrapper}>
      {content()}
    </Grid>
  );
}

export default SchemaElement;
