import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { INDICATION_LABORATORY_EVALUATE } from 'modules/common/apiConstants';

export const IndicationHistorySchema = z
  .object({
    id: z.number(),
    dateTimeFinish: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    result: z.string(),
    evaluate: createObjectKeysEnumSchema(INDICATION_LABORATORY_EVALUATE),
  })
  .partial();

export type IndicationHistory = z.infer<typeof IndicationHistorySchema>;
