import { Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import * as React from 'react';
import { useParams } from 'react-router';
import { styled } from '@mui/system';
import { useFetchDentalExam } from '../common/fetcher';
import { useSelector } from 'react-redux';
import { AppState } from '../../../../../../redux/reducer';
import { useEditPICSelectors } from '../../../encounter/EditPICDialog/state';
import EditPICDialog from '../../../encounter/EditPICDialog';

const GeneralInfo = () => {
  const { dentalSessionId } = useParams<{ dentalSessionId }>();
  const { data: dentalExam, revalidate } = useFetchDentalExam(dentalSessionId);

  const TitleStyled = styled(Typography)({
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: '#263238',
    padding: '2px',
  });

  const ValueStyled = styled(Typography)({
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 'bold',
    lineHeight: '16px',
    letterSpacing: '0.2px',
    color: '#263238',
    padding: '2px',
  });

  const isCurrentDoctor = useSelector((state: AppState) => {
    return state?.authen?.user?.user_id === dentalExam?.picUserId;
  });
  const showEditPICButton =
    isCurrentDoctor &&
    (!dentalExam?.endTime ||
      (dentalExam.endTime && moment(dentalExam.endTime, BE_DATE_TIME_FORMAT).isAfter(moment().add(-1, 'day'))));
  const onOpenEditPICDialog = useEditPICSelectors.use.onOpenDialog();

  return (
    <Grid container sx={{ backgroundColor: 'white', padding: '12px 24px' }}>
      <Grid item xs={4}>
        <Stack gap="4px">
          <TitleStyled>
            <FormattedMessage id="dental.patient.label.fullName" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="birthdayShort" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="age_" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="gender" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="patientId" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="telephone" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="cashierPrint.encounterId" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="examDoctor" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="serviceName" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="dental.dentalDetail.sections.examinationHistories.examinationDay.title" />
          </TitleStyled>
          <TitleStyled>
            <FormattedMessage id="dental.patient.label.reason" />
          </TitleStyled>
        </Stack>
      </Grid>
      <Grid item xs={8}>
        <Stack gap="4px">
          <ValueStyled>{dentalExam?.patient?.name}</ValueStyled>
          <ValueStyled>
            {dentalExam?.patient?.dob && moment(dentalExam?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
          </ValueStyled>
          <ValueStyled>
            {moment(dentalExam?.patient?.dob, BE_DATE_FORMAT)?.isValid()
              ? `${moment().diff(dentalExam?.patient?.dob, 'years', false)} (${
                  moment().diff(dentalExam?.patient?.dob, 'months', false) % 12
                })`
              : ''}
          </ValueStyled>
          <ValueStyled>
            {dentalExam?.patient?.gender && <FormattedMessage id={dentalExam?.patient?.gender?.toLowerCase()} />}
          </ValueStyled>
          <ValueStyled>{dentalExam?.patient?.code?.split('-')?.[1] || ''}</ValueStyled>
          <ValueStyled>{dentalExam?.patient?.mobilePhone}</ValueStyled>
          <ValueStyled>{dentalExam?.code}</ValueStyled>
          <ValueStyled
            onClick={
              showEditPICButton
                ? () => {
                    onOpenEditPICDialog({
                      encounterId: String(dentalExam?.id),
                      doctorName: String(dentalExam?.picName || ''),
                      doctorId: String(dentalExam?.picUserId),
                      encounterType: 'DENTAL',
                    });
                  }
                : () => {}
            }
            sx={{
              textDecoration: showEditPICButton ? 'underline' : 'unset',
              color: showEditPICButton ? '#0052E0' : 'unset',
              cursor: showEditPICButton ? 'pointer' : 's-resize',
            }}
          >
            {dentalExam?.picName}
          </ValueStyled>
          <ValueStyled>{dentalExam?.serviceName}</ValueStyled>
          <ValueStyled>
            {dentalExam?.createdTime &&
              dentalExam?.createdTime &&
              moment(dentalExam?.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          </ValueStyled>
          <ValueStyled>{dentalExam?.registerReason}</ValueStyled>
        </Stack>
      </Grid>
      <EditPICDialog refreshData={revalidate} />
    </Grid>
  );
};

export default GeneralInfo;
