import { z } from 'zod';
import { MedicationSchema } from './Medication';

export const PrescriptionDetailSchema = z
  .object({
    id: z.number(),
    prescriptionId: z.number(),
    medicationId: z.number(),
    medicationName: z.string(),
    quantity: z.number(),
    unit: z.string(),
    dayNumber: z.number(),
    type: z.string(),
    unitPerDay: z.number(),
    intendedRoute: z.string(),
    usage: z.string(),
    medication: MedicationSchema,
  })
  .partial();

export type PrescriptionDetail = z.infer<typeof PrescriptionDetailSchema>;
