import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../../common/api';
import { some, SYSTEM_CATEGORY_SCOPE, SYSTEM_CATEGORY_TYPE } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { setLoading } from '../../../common/redux/commonReducer';
import GroupProcedureFormDialog from './GroupProcedureFormDialog';
import ProcedureFormDialog from './ProcedureFormDialog';
import ProcedureListTable from './ProcedureListTable';
import { FilterParams } from './SearchProcedure/schema';
import { axiosThunk } from '../../../common/redux/axios';
import { useFetch } from 'modules/common/hook';
import usePriceManage from 'modules/common/hook/usePriceManage';

interface Props {
  categoryId: number;
}

const ProcedureMainBox: React.FunctionComponent<Props> = (props) => {
  const { categoryId } = props;
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const [formData, setFormData] = React.useState<some | undefined>();
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();
  const [searchParams, setSearchParams] = React.useState<FilterParams>({});
  const {
    data = [],
    isValidating,
    revalidate,
  } = useFetch(
    API_SERVER.service.getSystemCategoryList({
      ...searchParams,
      parentId: categoryId,
      scope: SYSTEM_CATEGORY_SCOPE.surgical,
    }),
  );

  const { onDeleteServices } = usePriceManage({ revalidate, setFormData });

  const onSubmitFormData = React.useCallback(
    async (value: some) => {
      const mappedData = {
        code: value.code,
        internationalCode: value.internationalCode,
        name: value.name,
        price: value.price,
        specializedDepartmentId: value.department,
        systemCategoryId: value?.systemCategoryId,
        physicsRoomId: value.room?.value,
      };
      if (!value.id) {
        // create new
        dispatch(setLoading(true));
        try {
          await dispatch(axiosThunk({ url: API_SERVER.service.create(), method: 'post', data: mappedData }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      } else {
        // edit
        dispatch(setLoading(true));
        try {
          await dispatch(axiosThunk({ url: API_SERVER.service.update(value.id), method: 'put', data: mappedData }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );
  const onSubmitFormDataGroup = React.useCallback(
    async (value: some) => {
      const data = {
        ...value,
        type: SYSTEM_CATEGORY_TYPE.group,
        scope: SYSTEM_CATEGORY_SCOPE.surgical,
        parentId: categoryId,
      };
      try {
        dispatch(setLoading(true));
        if (value.id) {
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.update(value.id), method: 'put', data }));
        } else {
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.create(), method: 'post', data }));
        }
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e: any) {
        const errors = e?.response?.data?.errors;
        if (errors) {
          errors?.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
        setFormDataGroup(undefined);
      }
    },
    [categoryId, dispatch, intl, openSnackbar, revalidate],
  );

  const onDeletePlanDefinition = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog?.promptConfirmation({
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
        warning: true,
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.delete(value.id), method: 'delete' }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog?.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar],
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog?.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'procedure.deleteTitle' }),
        content: intl.formatMessage({ id: 'procedure.deleteContent' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.service.delete(value.id), method: 'delete' }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog?.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar],
  );

  return (
    <Paper style={{ flex: 1, overflow: 'auto' }}>
      <ProcedureListTable
        loading={isValidating && !data}
        setFormData={setFormData}
        setFormDataGroup={setFormDataGroup}
        onDelete={onDelete}
        onDeleteGroup={onDeletePlanDefinition}
        onDeleteServices={onDeleteServices}
        data={data}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        revalidate={revalidate}
        categoryId={categoryId}
      />
      <ProcedureFormDialog
        open={!!formData}
        formData={formData}
        onSubmit={onSubmitFormData}
        onClose={() => {
          setFormData(undefined);
        }}
      />
      <GroupProcedureFormDialog
        open={!!formDataGroup}
        formData={formDataGroup}
        onSubmit={onSubmitFormDataGroup}
        onClose={() => {
          setFormDataGroup(undefined);
        }}
      />
    </Paper>
  );
};

export default ProcedureMainBox;
