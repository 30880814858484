import * as React from 'react';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFilterTherapyForm } from './schema';
import { setOrderTherapy } from '../../../../common/redux/commonReducer';

interface Props {
  pagin?: React.ReactNode;
  onRegister?: () => void;
  hookPagination: HookPaginationProps;
  onDataLoading: boolean;
}

const FilterTherapyBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, pagin, onDataLoading, onRegister } = props;
  const { params, setParams, clearParams } = hookPagination;
  const { dispatch } = useGeneralHook();

  return (
    <SchemaForm
      schema={schemaFilterTherapyForm}
      onSubmit={(value) => {
        dispatch(setOrderTherapy(value.orderBy));
        setParams(value);
      }}
      formData={params}
      clearParams={clearParams}
      hideSubmitButton
      pagin={pagin}
      onRegister={onRegister}
      onDataLoading={onDataLoading}
    />
  );
};

export default FilterTherapyBox;
