import TableCustom, { Columns } from '../../../common/component/TableCustom';
import * as React from 'react';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, some } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import { ROUTES } from 'modules/layout/router';
import { useHistory } from 'react-router';

interface Props {
  data?: some[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  onSelectPatient: (patient: some) => void;
  onDeletePatient: (patient: some) => void;
  onUpdatePatient: (patient: some) => void;
}

const PatientManagementTable = ({
  data,
  loading,
  hookPagination,
  total,
  onSelectPatient,
  onDeletePatient,
  onUpdatePatient,
}: Props) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const history = useHistory();
  const dataSource = data?.map((ele) => {
    const birthday = moment(ele?.dob, BE_DATE_TIME_FORMAT);
    return {
      ...ele,
      dob: birthday.isValid() ? birthday.format(FE_DATE_FORMAT) : '',
      age: birthday.isValid() ? moment(new Date()).diff(birthday, 'year') : '',
    };
  });

  const columns = React.useMemo(() => {
    return [
      {
        title: 'patient.management.table.title.patientCode',
        dataIndex: 'code',
        align: 'left',
        width: '130px',
        render: (patient) => {
          return (
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              color="primary"
              onClick={() => history.push(ROUTES.patientProfile.gen(patient.id!))}
            >
              {patient.code}
            </Typography>
          );
        },
      },
      {
        title: 'patient.management.table.title.patientName',
        align: 'left',
        dataIndex: 'name',
        width: '200px',
      },
      {
        title: 'patient.management.table.title.birthDay',
        dataIndex: 'dob',
        align: 'left',
        width: '150px',
      },

      {
        title: 'patient.management.table.title.age',
        dataIndex: 'age',
        width: 100,
        align: 'left',
      },
      {
        title: 'patient.management.table.title.gender',
        dataIndex: 'gender',
        align: 'left',
        render: (patient) => {
          return <Typography>{patient?.gender && <FormattedMessage id={patient?.gender || ' '} />}</Typography>;
        },
      },
      {
        title: 'patient.management.table.title.tel',
        dataIndex: 'mobilePhone',
        align: 'left',
      },
      {
        title: 'patient.management.table.title.email',
        dataIndex: 'email',
        align: 'left',
      },
      {
        title: 'patient.management.table.title.address',
        dataIndex: 'address',
        align: 'left',
      },
      {
        title: 'patient.management.table.title.CCCD',
        dataIndex: 'identifierCode',
        align: 'left',
      },
      {
        title: 'action',
        width: '8%',
        render: (patient) => {
          return (
            <>
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  onUpdatePatient(patient);
                }}
                style={{ marginRight: 8 }}
              >
                <EditIcon />
              </IconButtonTitle>
              <IconButtonTitle title="delete" size="small" onClick={() => onDeletePatient(patient)}>
                <DeleteIcon />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns[];
  }, [history, onDeletePatient, onUpdatePatient]);
  return (
    <TableCustom
      dataSource={dataSource}
      loading={loading}
      columns={columns}
      paginationProps={{
        count: total,
        page: pagination.page,
        rowsPerPage: pagination.pageSize,
        onPageChange: onPageChange,
        onRowsPerPageChange: onRowsPerPageChange,
      }}
    />
  );
};

export default PatientManagementTable;
