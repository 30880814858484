import { INDICATION_REQUEST_STATUS, some } from '../../../../../common/constants';
import { useMemo, useRef, useState } from 'react';
import { Box, Divider } from '@mui/material';
import Header from './Header';
import Body from './Body';
import DialogAction from './DialogAction';
import { groupBy } from 'lodash';
import { styled } from '@mui/system';
import { INDICATION_REQUEST_PRINT_TYPE, LOCAL_STORAGE_INDICATION_PRINT_TYPE } from './constant';

interface Props {
  onClose: () => void;
  therapy: some;
  therapySession: some;
  indicationList: some[];
}

const DividerStyled = styled(Divider)({
  marginTop: '30px',
  marginBottom: '15px',
  '@media print': {
    display: 'none',
  },
});

const PrintDialogContent = ({ onClose, therapy, therapySession, indicationList }: Props) => {
  const printContentRef = useRef(null);
  const [printType, setPrintType] = useState<INDICATION_REQUEST_PRINT_TYPE>(
    (sessionStorage.getItem(LOCAL_STORAGE_INDICATION_PRINT_TYPE) as INDICATION_REQUEST_PRINT_TYPE) || 'GROUP',
  );
  const indicationMap = useMemo(() => {
    return groupBy(indicationList, printType === 'GROUP' ? 'scope' : 'systemCategoryId');
  }, [indicationList, printType]);

  return (
    <Box paddingTop="̣9px">
      <Box ref={printContentRef}>
        {Object.keys(indicationMap).map((key) => {
          const indications =
            indicationMap[key]?.filter(
              (indication) => INDICATION_REQUEST_STATUS.REQUESTED.value === indication?.status,
            ) || [];
          return (
            <Box sx={{ pageBreakBefore: 'always' }}>
              <Header />
              <Body therapy={therapy} indicationList={indications} printType={printType} />
              <DividerStyled />
            </Box>
          );
        })}
      </Box>
      <DialogAction printContentRef={printContentRef} printType={printType} onPrintTypeChange={setPrintType} />
    </Box>
  );
};

export default PrintDialogContent;
