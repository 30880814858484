import {
  Box,
  Button,
  Collapse,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from 'modules/common/component/DialogCustom';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, some } from 'modules/common/constants';
import { PRESCRIPTION_DETAIL_TYPE, PRESCRIPTION_TYPE } from 'modules/common/apiConstants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaForm from 'modules/common/SchemaForm';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import { isEmpty } from 'lodash';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import { formatDateFilterField } from 'modules/common/utils';
import { Prescription, PrescriptionDoctor, PrescriptionSchema } from 'modules/schema';
import { useFetch } from 'modules/common/hook';
import moment from 'moment';

const Row: React.FunctionComponent<{
  row: PrescriptionDoctor | undefined;
  selection: PrescriptionDoctor | undefined;
  setSelection(data: PrescriptionDoctor): void;
  group_specialization: some;
}> = (props) => {
  const { row, selection, setSelection, group_specialization } = props;
  const [open, setOpen] = React.useState(false);
  const [prescriptionData, setPrescriptionData] = React.useState<Prescription | undefined>();
  const { dispatch, axiosThunk, API_SERVER } = useGeneralHook();

  // get data prescription detail
  const prescriptionDetail = async (id: number) => {
    setOpen(!open);
    if (!open) {
      const { data } = await dispatch(
        axiosThunk({
          url: API_SERVER.sale.prescriptionDetail({
            id,
            type: group_specialization.isDental ? PRESCRIPTION_TYPE.DENTAL_EXAM : PRESCRIPTION_TYPE.ENCOUNTER,
          }).url,
        }),
      );
      const paresedPrescription = PrescriptionSchema.safeParse(data);
      setPrescriptionData(paresedPrescription?.success ? paresedPrescription.data : undefined);
    }
  };

  return (
    <>
      <TableCustomRow>
        <TableCustomCell>
          <IconButton aria-label="expand row" size="small" onClick={() => prescriptionDetail(row?.id!)}>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell>
          <Typography>{row?.name}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography>{row?.mobilePhone}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography>{row?.picName}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography>{row?.createdTime && row.createdTime.format(FE_DATE_FORMAT)}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Radio checked={selection?.id === row?.id} onChange={() => setSelection(row!)} />
        </TableCustomCell>
      </TableCustomRow>
      <TableRow style={{ backgroundColor: 'white', padding: 0 }}>
        <TableCustomCell style={{ padding: '0px', border: 'none', backgroundColor: 'inherit' }} colSpan={14}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases" style={{ marginTop: 4 }}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell />
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="sale.product" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="drugUsage.ingredients" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="sale.dose" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="quantity" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="pharmacy.numeratorUnit" />
                  </TableHeaderCell>
                  <TableHeaderCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {prescriptionData?.prescriptionDetailList
                  ?.filter((item) => item.type !== PRESCRIPTION_DETAIL_TYPE.MEDICATION_EXTERNAL)
                  ?.map((record) => {
                    return (
                      <TableRow key={record?.id}>
                        <TableCustomCell />
                        <TableCustomCell>{record?.medication?.name}</TableCustomCell>
                        <TableCustomCell>{record?.medication?.ingredient}</TableCustomCell>
                        <TableCustomCell>{record?.medication?.dose}</TableCustomCell>
                        <TableCustomCell>{record?.quantity}</TableCustomCell>
                        <TableCustomCell>{record?.unit || record?.medication?.numeratorUnit}</TableCustomCell>
                        <TableCustomCell />
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCustomCell>
      </TableRow>
    </>
  );
};
interface Props {
  onSubmit: (formVal: some) => void;
  open?: boolean;
  onClose: () => void;
  group_specialization: some;
}
const Content = (props: Props) => {
  const { onSubmit, onClose, group_specialization } = props;
  const { API_SERVER } = useGeneralHook();
  const [selection, setSelection] = React.useState<PrescriptionDoctor>();
  const hookPagination = usePaginationHook({
    disablePagination: true,
    disableLink: true,
    defaultFilter: {
      fromDate: moment(),
    },
  });
  const { params, setParams, clearParams } = hookPagination;

  const filter = React.useMemo(() => {
    return formatDateFilterField(
      {
        ...params,
        type: group_specialization.isDental ? PRESCRIPTION_TYPE.DENTAL_EXAM : PRESCRIPTION_TYPE.ENCOUNTER,
        fromDate: params.fromDate && moment(params.fromDate).startOf('day').utc().format(BE_DATE_TIME_FORMAT),
        toDate: params.fromDate && moment(params.fromDate).endOf('day').utc().format(BE_DATE_TIME_FORMAT),
      },
      '',
      '',
    );
  }, [group_specialization.isDental, params]);

  // get data prescription list
  const { data, isValidating } = useFetch(API_SERVER.sale.listPrescriptionSpecialist(filter));

  return (
    <Box p={2} height="70vh" display={'flex'} flexDirection="column">
      <SchemaForm
        hideSubmitButton
        formData={params}
        onSubmit={setParams}
        schema={{
          fields: ({ formProps: { intl }, valuesField }) => {
            return {
              patientName: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'patientName' }),
                placeholder: intl.formatMessage({ id: 'enterPatientName' }),
                propsWrapper: { xs: true },
              },
              mobilePhone: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'telephone' }),
                placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
                propsWrapper: { xs: true },
                inputType: 'number',
              },
              picName: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'sale.doctor' }),
                placeholder: intl.formatMessage({ id: 'enterDoctorName' }),
                propsWrapper: { xs: true },
              },
              fromDate: {
                type: 'datePicker',
                label: intl.formatMessage({ id: 'sale.prescriptionDate' }),
                propsWrapper: { xs: true },
                defaultValue: moment(),
              },
              search: {
                type: 'submitButton',
                color: 'inherit',
                label: intl.formatMessage({ id: 'search' }),
                propsWrapper: { xs: undefined },
                style: { minWidth: 90, marginTop: 20 },
              },
            };
          },
        }}
      />
      {isValidating ? (
        <LoadingIcon />
      ) : (
        <Box
          position="relative"
          overflow="inherit"
          display="flex"
          flexDirection="column"
          height="-webkit-fill-available"
          flex={1}
        >
          <TableContainer style={{ height: '100%' }}>
            <Table>
              <TableHead>
                <TableCustomRow>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="sale.customer" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="telephone" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="sale.doctor" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="sale.prescriptionDate" />
                  </TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableCustomRow>
              </TableHead>
              <TableBody>
                {!isEmpty(data) ? (
                  data
                    ?.filter((item) => item)
                    ?.map((row) => (
                      <Row
                        key={row?.id}
                        row={row}
                        selection={selection}
                        setSelection={setSelection}
                        group_specialization={group_specialization}
                      />
                    ))
                ) : (
                  <>
                    <TableCustomRow>
                      <TableCustomCell colSpan={14}>
                        <Box
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minHeight: '100%',
                          }}
                        >
                          <Typography variant="inherit" color="textSecondary">
                            <FormattedMessage id="noData" />
                          </Typography>
                        </Box>
                      </TableCustomCell>
                    </TableCustomRow>
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
      <Box display={'flex'} gap={2} justifyContent="flex-end" padding={'10px 0'}>
        <Button
          variant="outlined"
          onClick={() => {
            onClose();
            clearParams();
          }}
        >
          <FormattedMessage id="cancel" />
        </Button>
        <Button variant="contained" disabled={!selection} onClick={() => selection && onSubmit(selection)}>
          <FormattedMessage id="apply" />
        </Button>
      </Box>
    </Box>
  );
};
const DoctorPrescriptionSpecialistDialog: React.FunctionComponent<Props> = (props) => {
  const { onClose, open } = props;

  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 980 },
      }}
      title={'sale.listPrescription'}
    >
      <Content {...props} />
    </DialogCustom>
  );
};

export default DoctorPrescriptionSpecialistDialog;
