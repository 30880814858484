import * as React from 'react';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFilterEncounterForm } from './schema';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook/useFetch';
import { API_SERVER } from 'modules/common/api';

interface Props {
  pagin?: React.ReactNode;
  onRegisterButtonClick?: () => void;
  hookPagination: HookPaginationProps;
  dataLoading: boolean;
}

const FilterEncounterBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, pagin, onRegisterButtonClick, dataLoading } = props;
  const { params, setParams, clearParams } = hookPagination;
  const { appState } = useGeneralHook();
  const orderBy = appState.common.orderEncounter;
  //TODO: hard code name params ==> need to change to code
  const { data: physicsRooms } = useFetch(
    API_SERVER.systemCategory.getList({ type: 'GROUP', scope: 'PHYSICS_ROOM', name: 'Khám bệnh', isShowList: true }),
  );
  const physicsRoomOptions = React.useMemo(
    () => physicsRooms?.[0]?.items?.map((physicsRoom) => ({ value: physicsRoom?.id, label: physicsRoom?.name })),
    [physicsRooms],
  );

  return (
    <SchemaForm
      schema={schemaFilterEncounterForm}
      onSubmit={(value) => setParams({ ...value, orderBy })}
      formData={params}
      clearParams={() =>
        clearParams({
          status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
        })
      }
      hideSubmitButton
      pagin={pagin}
      onRegisterButtonClick={onRegisterButtonClick}
      dataLoading={dataLoading}
      physicsRoomOptions={physicsRoomOptions}
    />
  );
};

export default FilterEncounterBox;
