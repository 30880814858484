import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useGeneralHook from '../hook/useGeneralHook';
import LogoImage from './LogoImage';

interface Props {
  children?: any;
}
const HeaderPrintForm = (props: Props) => {
  const { appState } = useGeneralHook();
  const { currentLocation } = appState.authen;
  return (
    <>
      <Box display={'flex'}>
        <LogoImage
          src={currentLocation?.avatarId}
          className="logo"
          sx={{
            width: '135px',
            height: 'auto',
            objectFit: 'contain',
            marginRight: '18px',
          }}
        />
        <Box display={'flex'} flexDirection="column" gap={1}>
          <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
            {currentLocation?.name}
          </Typography>
          <Box display={'flex'} flexDirection="column" gap={0.5}>
            <Box display={'flex'} gap={2}>
              <Box display={'flex'}>
                <Typography variant="subtitle1" color="textSecondary" width={62} flexShrink={0}>
                  <FormattedMessage id="medicalPrint.hotline" />
                </Typography>
                :&nbsp;
                <Typography variant="body1" component={'span'}>
                  {currentLocation?.phone}
                </Typography>
              </Box>
              <Box display={'flex'}>
                <Typography variant="subtitle1" color="textSecondary" width={62} flexShrink={0}>
                  <FormattedMessage id="clinics.website" />
                </Typography>
                :&nbsp;
                <Typography variant="body1" component={'span'}>
                  {currentLocation?.website}
                </Typography>
              </Box>
            </Box>
            <Box display={'flex'}>
              <Typography variant="subtitle1" color="textSecondary" width={62} flexShrink={0}>
                <FormattedMessage id="email" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" component={'span'}>
                {currentLocation?.email}
              </Typography>
            </Box>
            <Box display={'flex'}>
              <Typography variant="subtitle1" color="textSecondary" width={62} flexShrink={0}>
                <FormattedMessage id="clinics.address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" component={'span'}>
                {currentLocation?.address}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      {props.children && (
        <Box display={'flex'} justifyContent="center" padding={1}>
          {props.children}
        </Box>
      )}
    </>
  );
};
export default HeaderPrintForm;
