import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';
import { IndicationRequestSchema } from './IndicationRequest';
import { MedicalSuppliesSchema } from './MedicalSupplies';
import { TherapyMedicationSchema } from './TherapyMedication';
import { PaymentDetailSchema } from './PaymentDetail';

export const TherapySessionSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    endTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    picName: z.string(),
    picUserId: z.number(),
    description: z.string(),
    status: z.string(),
    orderNumber: z.number(),
    packageScope: z.string(),
    isFree: z.boolean(),
    price: z.number(),
    therapyId: z.number(),
    conclude: z.string(),
    paymentDetailId: z.number(),
    indicationRequestList: z.array(IndicationRequestSchema),
    diagnosticImageList: z.array(MedicalSuppliesSchema),
    medicationList: z.array(TherapyMedicationSchema),
    paymentDetail: PaymentDetailSchema,
  })
  .partial();

export type TherapySession = z.infer<typeof TherapySessionSchema>;
