import { DIAGNOSTIC_IMAGE_TYPE } from 'modules/common/constants';
import { Encounter, Patient } from 'modules/schema';
import { useForm } from 'react-hook-form';
import { FORM_DEFAULT_VALUE } from './const';
import React, { useCallback } from 'react';
import { DiagnosticForm } from './type';
import DialogCustom from '../DialogCustom';
import DialogContent from './DialogContent';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { STORAGE_RESOURCE_TYPE } from 'modules/common/apiConstants';
import { EncounterContext } from '../Encounter/const';

interface Props {
  open: boolean;
  onClose: () => void;
  referenceId: number;
  type: keyof typeof DIAGNOSTIC_IMAGE_TYPE;
  patient?: Patient;
  storeResourceType: keyof typeof STORAGE_RESOURCE_TYPE;
  extracInfo?: {
    diagnosis?: string;
    picName?: string;
  };
  readonly: boolean;
  encounter: Encounter;
}

const DiagnosticImageDialog = ({
  open,
  onClose,
  referenceId,
  type,
  patient,
  storeResourceType,
  extracInfo,
  readonly,
  encounter,
}: Props) => {
  const { confirmDialog, intl } = useGeneralHook();
  const form = useForm<DiagnosticForm>({
    defaultValues: FORM_DEFAULT_VALUE,
  });
  const onCloseDialog = useCallback(() => {
    if (Object.keys(form.formState.dirtyFields).length > 0) {
      confirmDialog
        .promptConfirmation({
          title: intl.formatMessage({ id: 'encounter.calendar.label.request.reject' }),
          content: intl.formatMessage({ id: 'common.haveUnsavedData' }),
        })
        .then((confirm) => {
          if (confirm) {
            form.reset(FORM_DEFAULT_VALUE);
            onClose();
          }
        })
        .finally(confirmDialog.close);
    } else {
      form.reset(FORM_DEFAULT_VALUE);
      onClose();
    }
  }, [confirmDialog, form, intl, onClose]);

  const encounterContext = React.useContext(EncounterContext);
  React.useEffect(() => {
    if (encounterContext?.counter! > 0 && open) {
      encounterContext?.revalidateEncounter();
      encounterContext?.setCounter(0);
    }
  }, [encounterContext, open]);

  return (
    <DialogCustom
      open={open}
      onClose={onCloseDialog}
      title="camera.title"
      PaperProps={{ sx: { maxWidth: 'unset', height: '900px' } }}
    >
      <DialogContent
        onClose={onCloseDialog}
        referenceId={referenceId}
        type={type}
        form={form}
        patient={patient}
        storeResourceType={storeResourceType}
        extracInfo={extracInfo}
        readonly={readonly}
        encounter={encounter}
      />
    </DialogCustom>
  );
};

export default DiagnosticImageDialog;
