import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { FE_DATE_TIME_FORMAT, INDICATION_REQUEST_STATUS, INDICATION_REQUEST_TYPE, some } from '../../common/constants';
import ParticipantTab from '../component/therapy/detailTabs/attendPersons/ParticipantTab';
import { API_SERVER } from '../../common/api';
import PatientInfoBox from '../component/therapy/PatientInfoBox';
import IndicationLab from '../component/therapy/detailTabs/laboratory/IndicationLab';
import IndicationImageDoctorTab from '../component/therapy/detailTabs/image/IndicationImageDoctorTab';
import IndicationMedicationTab from '../component/therapy/detailTabs/medication/IndicationMedicationTab';
import MedicalSuppliesTab from '../component/therapy/detailTabs/medicalSupplies/MedicalSuppliesTab';
import { useFetch } from 'modules/common/hook';

const TABS = [
  { id: 'imageIndication' },
  { id: 'participant' },
  { id: 'navMenu.supply' },
  { id: 'encounterDetail.subclinicalResult' },
  { id: 'drugUsage' },
];

interface Props {}

const ImageDoctorIndicationTherapyDetail: React.FunctionComponent<Props> = (props) => {
  const { indicationRequestId, therapyId } = useParams<{ indicationRequestId: string; therapyId: string }>();
  const [tabValue, setTabValue] = React.useState(0);
  const { data: indicationRequest, revalidate: indicationRequestRevalidate } = useFetch(
    API_SERVER.indicationRequest.get(parseInt(indicationRequestId)),
  );
  const readonly = indicationRequest?.status === INDICATION_REQUEST_STATUS.FINISHED.value;
  const { data: therapy } = useFetch(API_SERVER.therapy.detail(parseInt(therapyId)));

  return (
    <Paper elevation={1} style={{ overflow: 'auto', position: 'relative', flex: 1 }}>
      <Box bgcolor={'white'} borderBottom={`1px solid ${grey[400]}`}>
        <Box padding={2} paddingBottom={1}>
          <PatientInfoBox exam={therapy} indicationRequest={indicationRequest} />
        </Box>
        <Box paddingX={2}>
          <Typography variant="h6" color="primary">
            {therapy?.patient?.name}
            &emsp;-&emsp;
            <Typography variant="subtitle1" color="textSecondary" component="span">
              <FormattedMessage id="indicationDate" />
              &nbsp;
              {indicationRequest && moment(indicationRequest?.createdTime).format(FE_DATE_TIME_FORMAT)}
            </Typography>
          </Typography>
          <Typography variant="body2" marginTop={1}>
            <FormattedMessage id="reason" />
            :&nbsp;
            <Typography variant="body2" component="span">
              {indicationRequest?.note}
            </Typography>
          </Typography>
        </Box>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
          }}
          variant="scrollable"
          indicatorColor="secondary"
          textColor="secondary"
          style={{ padding: '0px 8px' }}
        >
          {TABS.map((tab: some, index: number) => {
            return (
              <Tab
                key={index}
                value={index}
                style={{ minWidth: 100 }}
                label={
                  <Typography variant="subtitle2">
                    <FormattedMessage id={tab.id} />
                  </Typography>
                }
              />
            );
          })}
        </Tabs>
      </Box>
      <Box padding={2} overflow="auto">
        <div style={{ display: tabValue === 0 ? 'unset' : 'none' }}>
          {tabValue === 0 && (
            <IndicationImageDoctorTab
              patient={therapy?.patient!}
              indicationRequest={indicationRequest}
              indicationRequestRevalidate={indicationRequestRevalidate}
            />
          )}
        </div>
        <div style={{ display: tabValue === 1 ? 'unset' : 'none' }}>
          {tabValue === 1 && <ParticipantTab id={parseInt(indicationRequestId)} />}
        </div>
        <div style={{ display: tabValue === 2 ? 'unset' : 'none' }}>{tabValue === 2 && <MedicalSuppliesTab />}</div>
        <div>
          {tabValue === 3 && (
            <IndicationLab referenceId={parseInt(therapyId)} type={INDICATION_REQUEST_TYPE.THERAPY_SESSION} />
          )}
        </div>
        <div style={{ display: tabValue === 4 ? 'unset' : 'none' }}>
          {tabValue === 4 && indicationRequest && (
            <IndicationMedicationTab
              patient={therapy?.patient!}
              readonly={readonly}
              indicationRequest={indicationRequest}
            />
          )}
        </div>
      </Box>
    </Paper>
  );
};

export default ImageDoctorIndicationTherapyDetail;
