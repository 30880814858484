import { z } from 'zod';

import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';

export const ReportImportExpenseSchema = z
  .object({
    importCode: z.string(),
    medicationName: z.string(),
    medicationCode: z.string(),
    importDate: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    importBy: z.string(),
    supplierName: z.string(),
    lot: z.string(),
    expiredDate: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    quantity: z.number(),
    unit: z.string(),
    unitPrice: z.number(),
    totalAmount: z.number(),
  })
  .partial();

export type ReportImportExpense = z.infer<typeof ReportImportExpenseSchema>;
