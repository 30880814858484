import { Button, FormControlLabel, Radio, RadioGroup, Switch } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import {
  INDICATION_REQUEST_LIST_PRINT_TYPE,
  LOCAL_STORAGE_INDICATION_LIST_COMPACT_MODE,
  LOCAL_STORAGE_INDICATION_LIST_PRINT_TYPE,
} from './constant';

interface Props {
  onClose: () => void;
  printContentRef: React.MutableRefObject<null>;
  printType: INDICATION_REQUEST_LIST_PRINT_TYPE;
  onPrintTypeChange: (type: INDICATION_REQUEST_LIST_PRINT_TYPE) => void;
  compactMode: boolean;
  onCompactModeChange: (compactMode: boolean) => void;
}

const DialogAction = ({
  onClose,
  printContentRef,
  printType,
  onPrintTypeChange,
  compactMode,
  onCompactModeChange,
}: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      width="100%"
      position="absolute"
      bottom={0}
      zIndex={100}
      sx={{ backgroundColor: '#F4F8FF', padding: '15px 24px' }}
    >
      <FormControlLabel
        control={
          <Switch
            checked={compactMode}
            onChange={(e) => {
              onCompactModeChange(e.target.checked);
              localStorage.setItem(LOCAL_STORAGE_INDICATION_LIST_COMPACT_MODE, String(e.target.checked));
            }}
          />
        }
        label={<FormattedMessage id="printCompact" />}
      />
      <Stack direction="row" justifyContent="flex-end" gap="20px">
        <RadioGroup
          row
          value={printType}
          onChange={(event) => {
            const val = (event.target as HTMLInputElement).value as INDICATION_REQUEST_LIST_PRINT_TYPE;
            onPrintTypeChange(val);
            sessionStorage.setItem(LOCAL_STORAGE_INDICATION_LIST_PRINT_TYPE, val);
          }}
        >
          <FormControlLabel
            value="GROUP"
            control={<Radio />}
            label={<FormattedMessage id="therapy.exam.therapyDetail.label.printForm.group.title" />}
          />
          <FormControlLabel
            value="SEPARATE"
            control={<Radio />}
            label={<FormattedMessage id="therapy.exam.therapyDetail.label.printForm.separate.title" />}
          />
        </RadioGroup>
        <ReactToPrint
          trigger={() => (
            <Button sx={{ padding: '0 10px' }} startIcon={<PrintIcon />}>
              <FormattedMessage id="print.kqxn.button" />
            </Button>
          )}
          content={() => printContentRef.current}
        />
      </Stack>
    </Stack>
  );
};

export default DialogAction;
