import { some } from '../../../common/constants';
import { chain } from 'lodash';

export const mappedMedicationsFormDataToBEData = (medicationsFormData: some[]) => {
  return chain(medicationsFormData)
    .groupBy('group')
    .map((value, key) => ({
      categoryName: key,
      medicationVoList: value?.map((medication) => ({
        code: medication?.code,
        registrationNumber: medication?.registerNumber,
        name: medication?.drugName,
        numeratorUnit: medication?.unit,
        codeGroup: medication?.groupCode,
        ingredient: medication?.ingredients,
        dose: medication?.amount,
        country: medication?.produceCountry,
        publicPrice: medication?.declarePrice,
        approvalDate: medication?.applicableDate,
        countryCode: medication?.produceCountry,
        organizationId: medication?.department,
        medicationKnowledge: {
          packaging: medication?.packagingFormat,
          doseForm: medication?.dosageForm,
          expiration: medication?.expiredDate,
          productType: medication?.formType,
        },
        registration: {
          name: medication?.registerCompany,
          address: medication?.registerCompanyAddress,
        },
        distributor: {
          name: medication?.distributor,
          address: medication?.distributorAddress,
        },
        manufacturer: {
          name: medication?.producer,
          address: medication?.producerAddress,
        },
        intendedRoute: medication?.intendedRoute?.map((ele) => ele.value),
      })),
    }))
    .value();
};
