import useFilterContext from '../useFilterContext';
import { Stack, createFilterOptions } from '@mui/material';
import { FilterProps, ServiceFilter } from '../type';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { useMemo } from 'react';
import { SYSTEM_CATEGORY_TYPE } from 'modules/common/constants';
import AutocompleteInput from '../../form/AutocompleteInput';
import { Service as TService } from 'modules/schema';
import ChipList from '../CustomeChip';

const filterOptions = createFilterOptions<TService>({
  limit: 100,
  stringify: (option) => option?.name + ' ' + option?.systemCategory?.name || '',
});

const Service = ({ filter: { type, scopes, placeholder } }: FilterProps<ServiceFilter>) => {
  const { form } = useFilterContext();
  const { data: systemCategories } = useFetch(
    API_SERVER.service.getAllSystemCategoryList({
      scope: scopes,
    }),
    { revalidateOnFocus: false },
  );
  const options = useMemo(
    () =>
      systemCategories
        ?.filter((category) => category.type === SYSTEM_CATEGORY_TYPE.group)
        ?.flatMap((category) => category.items || []),
    [systemCategories],
  );
  const selectedServices = form.watch(type);
  return (
    <Stack direction="column" gap="10px" flex={1}>
      <AutocompleteInput
        form={form}
        name={type}
        options={options}
        filterOptions={filterOptions}
        getValue="id"
        getLabel="name"
        placeholder={placeholder}
        hideError
        multiple
        renderTags={() => null}
        groupBy={(option) => option.systemCategory?.name}
      />
      {selectedServices && selectedServices.length > 0 && (
        <ChipList
          items={selectedServices}
          labelKey="name"
          valueKey="id"
          onDelete={(data) =>
            form.setValue(
              type,
              selectedServices.filter((ele) => ele.id !== data),
            )
          }
        />
      )}
    </Stack>
  );
};

export default Service;
