import EditIcon from '@mui/icons-material/Edit';
import { Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { some } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
interface Props {
  data?: some;
  hookPagination: HookPaginationProps;
  onEdit: (item: some) => void;
  loading?: boolean;
}

const EmployeeTable: React.FunctionComponent<Props> = (props) => {
  const { data, hookPagination, onEdit, loading } = props;

  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const columns = React.useMemo(() => {
    return [
      {
        title: 'name',
        dataIndex: 'name',
      },
      {
        title: 'email',
        dataIndex: 'email',
      },
      {
        title: 'telephone',
        dataIndex: 'phoneNumber',
      },
      {
        title: 'employee.label.position',
        dataIndex: 'position',
      },
      {
        title: 'status',
        render: (record) => {
          return (
            <Typography variant="body1" color={record.active ? 'success.main' : 'warning.main'}>
              <FormattedMessage id={record.active ? 'employee.active' : 'employee.deactive'} />
            </Typography>
          );
        },
      },
      {
        title: 'action',
        align: 'right',
        width: 120,
        render: (record) => {
          return (
            <IconButtonTitle
              title="update"
              size="small"
              onClick={() => {
                onEdit(record);
              }}
            >
              <EditIcon />
            </IconButtonTitle>
          );
        },
      },
    ] as Columns[];
  }, [onEdit]);

  return (
    <TableCustom
      dataSource={data?.items}
      loading={loading}
      columns={columns}
      paginationProps={{
        count: data?.total || 0,
        page: pagination.page,
        rowsPerPage: pagination.pageSize,
        onPageChange: onPageChange,
        onRowsPerPageChange: onRowsPerPageChange,
      }}
    />
  );
};

export default EmployeeTable;
