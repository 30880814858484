import { Box, Grid, InputLabel, Switch, TextField, Tooltip, Typography } from '@mui/material';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PRIMARY } from 'colors';
import QuantityBox from 'modules/common/component/QuantityBox';
import { some } from 'modules/common/constants';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import IconButtonTitle from '../../../../../common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';
import * as React from 'react';

interface Props {
  indicationMedications?: some;
  onUpdateIndicationMedication: (value: some) => void;
  onDeleteIndicationMedication: (value: some) => void;
}

function TableList(props: Props) {
  const { intl } = useGeneralHook();
  const { indicationMedications, onUpdateIndicationMedication, onDeleteIndicationMedication } = props;

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {indicationMedications?.map((item: some, index: number) => {
        return (
          <Box key={item.id} display={'flex'} bgcolor={PRIMARY[50]} alignItems={'center'}>
            <Box display="flex" padding={2} flex={1} gap={3}>
              <Box flex={1}>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.drugName" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medicationName}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.unit" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medicationUnit}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.ingredients" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medicationIngredient}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="sale.dose" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medication?.dose}
                  </Typography>
                </Typography>
              </Box>
              <Box>
                {item?.isDisable ? (
                  <Grid container spacing={2} width={820}>
                    <Grid item xs={3}>
                      <InputLabel>
                        <FormattedMessage id="drugUsage.quantity" />
                      </InputLabel>
                      {item.quantity}
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container spacing={2} width={820}>
                    <Grid item xs={3} textAlign="center">
                      <InputLabel required>
                        <FormattedMessage id="drugUsage.quantity" />
                      </InputLabel>
                      <QuantityBox
                        decimal
                        value={item?.quantity}
                        onChange={(quantity) => {
                          if (quantity !== item?.quantity)
                            onUpdateIndicationMedication({ ...item, quantity: quantity });
                        }}
                      />
                    </Grid>
                    <Grid item xs={9} textAlign="center">
                      <TextField
                        fullWidth
                        label={intl.formatMessage({ id: 'drugUsage.note' })}
                        placeholder={intl.formatMessage({ id: 'drugUsage.noteEnter' })}
                        defaultValue={item?.note}
                        onBlur={(e) => {
                          if (e.target.value !== item.note) {
                            onUpdateIndicationMedication({ ...item, note: e.target.value });
                          }
                        }}
                      />
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Box>
            {item?.isDisable || (
              <Box display="flex" flexDirection="column" gap={1}>
                <Box>
                  <IconButtonTitle
                    size="small"
                    title="delete"
                    disabled={false}
                    onClick={() => {
                      onDeleteIndicationMedication(item);
                    }}
                  >
                    <Delete />
                  </IconButtonTitle>
                </Box>
                <Tooltip title={<FormattedMessage id="freeSwitch" />} arrow>
                  <Switch
                    size="small"
                    color="primary"
                    checked={!item?.isFree}
                    onChange={(e, checked) => {
                      onUpdateIndicationMedication({ ...item, isFree: !checked });
                    }}
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
}

export default memo(TableList);
