import React, { ReactElement, useState } from 'react';
import { some } from '../../constants';

interface Props {
  children(state: some, setState: React.Dispatch<React.SetStateAction<some>>): ReactElement;
  defaultState?: some;
}
const TableRowWrapper = (props: Props) => {
  const { children, defaultState } = props;
  const [state, setState] = useState<some>(defaultState || {});
  return <React.Fragment>{children(state, setState)}</React.Fragment>;
};
export default TableRowWrapper;
