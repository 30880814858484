export const DEFAULT_DIAGNOSTIC_NAME = 'PHIẾU KẾT QUẢ CHẨN ĐOÁN HÌNH ẢNH';

export const PREFIX_DIAGNOSTIC_NAME = 'PHIẾU KẾT QUẢ ';

export const AUTO_SNAPSHOT_DURATIONS = [
  { value: 1000, label: 'camera.duration.1' },
  { value: 2000, label: 'camera.duration.2' },
  { value: 3000, label: 'camera.duration.3' },
  { value: 4000, label: 'camera.duration.4' },
  { value: 5000, label: 'camera.duration.5' },
];

export const FORM_DEFAULT_VALUE = {
  name: DEFAULT_DIAGNOSTIC_NAME,
  examDepartment: '',
  conclusion: '',
  images: [],
  evaluationForm: null,
  autoSnapDuration: AUTO_SNAPSHOT_DURATIONS[0],
};
