import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';
import { PAYMENT_HISTORY_TYPE, PAYMENT_METHOD } from 'modules/common/apiConstants';

export const PaymentHistorySchema = z
  .object({
    id: z.number(),
    parentId: z.number(),
    paymentId: z.number(),
    therapyId: z.number(),
    amountPaid: z.number(),
    type: createObjectKeysEnumSchema(PAYMENT_HISTORY_TYPE),
    paymentMethod: createObjectKeysEnumSchema(PAYMENT_METHOD),
    transactionTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    note: z.string(),
  })
  .partial();

export type PaymentHistory = z.infer<typeof PaymentHistorySchema>;
