import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';

export const MessageFullSchema = z.object({
  id: z.number(),
  subject: z.string(),
  message: z.string(),
  isRead: z.boolean(),
  createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
});

export type MessageFull = z.infer<typeof MessageFullSchema>;
