import { formatEndDate } from 'modules/common/utils';
import moment from 'moment';
import { FilterType } from 'modules/common/component/filter/type';
import { DEBT_STATUS } from 'modules/common/apiConstants';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';

export const DEFAULT_VALUES = {
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatEndDate(moment()),
  keyword: '',
  paymentTypeList: [],
};

export const FILTER_CONFIGS: FilterType[] = [
  {
    type: 'DEBT_STATUS',
    options: [DEBT_STATUS.FREE_DEBT, DEBT_STATUS.STILL_DEBT],
    xs: 6,
  },
];
