import { Box } from '@mui/material';
import Tabs from 'modules/common/component/Tabs';
import * as React from 'react';
import { ROLES } from 'modules/common/constants';
import ExpenseReportTab from './expense/ExpenseTab';
import SaleRevenueTab from './revenue/SaleRevenueTab';
import ProfitReportTab from './profit/ProfitTab';

interface Props {}

const SaleReportPage: React.FunctionComponent<Props> = (props) => {
  return (
    <Box sx={{ padding: '10px 16px' }}>
      <Tabs
        searchUrlKey="tab"
        tabs={[
          {
            title: 'sale.report.revenue.title',
            key: 'saleRevenueReportTab',
            content: <SaleRevenueTab />,
            roles: [ROLES.ADMIN.value, ROLES.PHARMACIST.value, ROLES.COORDINATOR.value],
          },
          {
            title: 'sale.report.expense.title',
            key: 'SaleImportReportTab',
            content: <ExpenseReportTab />,
            roles: [ROLES.ADMIN.value],
          },
          {
            title: 'sale.report.profit.title',
            key: 'saleProfitReportTab',
            content: <ProfitReportTab />,
            roles: [ROLES.ADMIN.value],
          },
        ]}
        title=""
      />
    </Box>
  );
};

export default SaleReportPage;
