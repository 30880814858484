import { DIAGNOSTIC_IMAGE_TYPE, FE_DATE_FORMAT, FE_TIME_FORMAT } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { DiagnosticImage, DiagnosticImageSchema, Encounter, Patient } from 'modules/schema';
import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { DiagnosticForm } from 'modules/common/component/DiagnosticImageDialog/type';
import { useDeleteMutate, useDialog, useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { useCallback, useEffect, useState } from 'react';
import { AppState } from 'redux/reducer';
import { useSelector } from 'react-redux';
import { AUTO_SNAPSHOT_DURATIONS, FORM_DEFAULT_VALUE, PREFIX_DIAGNOSTIC_NAME } from './const';
import { Box, Button, ButtonBase, Checkbox, IconButton, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { CameraAlt, Delete, Print } from '@mui/icons-material';
import TextInput from 'modules/common/component/form/TextInput';
import Webcam from 'react-webcam';
import SelectInput from 'modules/common/component/form/SelectInput';
import MediCard from 'modules/common/component/MediCard';
import PatientInfo from 'modules/common/component/DiagnosticImageDialog/PatientInfo';
import { ENCOUNTER_STATUS, STORAGE_RESOURCE_TYPE } from 'modules/common/apiConstants';
import ImagePreview from 'modules/common/component/ImagePreview';
import { setLoading } from 'modules/common/redux/commonReducer';
import { axiosThunk } from 'modules/common/redux/axios';
import { MedicalIcon, TwinCameraIcon } from 'svg';
import { convertToFile } from 'modules/common/utils';
import NoDataBox from 'modules/common/component/NoDataBox';
import PrintFormDialog from 'modules/common/component/DiagnosticImageDialog/PrintFormDialog';
import useUpdateMultipleFiles from 'modules/common/hook/useUploadMultipleFiles';
import useWebcam from 'modules/common/hook/useWebcam';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import SunEditorInput from '../form/SunEditorInput';

interface Props {
  onClose: () => void;
  referenceId: number;
  type: keyof typeof DIAGNOSTIC_IMAGE_TYPE;
  patient?: Patient;
  form: UseFormReturn<DiagnosticForm>;
  storeResourceType: keyof typeof STORAGE_RESOURCE_TYPE;
  extracInfo?: {
    diagnosis?: string;
    picName?: string;
  };
  readonly: boolean;
  encounter: Encounter;
}

const DialogContent = ({
  onClose,
  referenceId,
  type,
  form,
  patient,
  storeResourceType,
  extracInfo,
  readonly,
  encounter,
}: Props) => {
  const examDepartment = form.watch('examDepartment');
  const conclusion = form.watch('conclusion');
  const { confirmDialog, intl, dispatch, openSnackbar } = useGeneralHook();
  const [selectedDiagnosticImage, setSelectedDiagnosticImage] = useState<DiagnosticImage | null>(null);
  const { data: encounterData } = useFetch(API_SERVER.encounter.get(encounter?.id!), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: encounter?.id,
  });
  const statusFinished = encounterData?.status === ENCOUNTER_STATUS.FINISHED.value;

  const { data: diagnosticImages, revalidate } = useFetch(
    API_SERVER.diagnosticImage.getDiagnosticImages({
      referenceId,
      imageType: type,
    }),
  );
  const diagnosticImagesReverse = diagnosticImages?.slice().reverse();
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const { data: images, revalidate: iamgesRevalidate } = useFetch(
    API_SERVER.storageResource.getList({
      type: storeResourceType,
      referenceId: selectedDiagnosticImage?.id || 0,
    }),
    {
      enabled: selectedDiagnosticImage?.id,
    },
  );
  const { ref, capturing, onCapture, onAutoCapture, onStopAutoCapture } = useWebcam();
  const evaluationFormMap = useSelector((appState: AppState) => appState.common.evaluationForms);
  const { fields, append, replace, update } = useFieldArray({
    control: form.control,
    name: 'images',
    keyName: 'key',
  });

  useEffect(() => {
    if (ref.current) {
      const listener = (event: KeyboardEvent) => {
        if (event.ctrlKey && event.key === 'i') {
          onCapture((screenShot) => append({ url: screenShot, checked: true, description: '' }));
        }
      };
      document.addEventListener('keydown', listener);
      return () => {
        document.removeEventListener('keydown', listener);
      };
    }
  }, [append, onCapture, ref]);

  const onSelectEvaluationForm = useCallback(() => {
    const evaluationForm = form.getValues('evaluationForm');
    form.setValue('examDepartment', evaluationForm?.examDepartment || '');
    form.setValue('conclusion', evaluationForm?.conclusion || '');
    form.setValue('name', PREFIX_DIAGNOSTIC_NAME + evaluationForm?.name);
    form.setValue('evaluationForm', null);
  }, [form]);

  useEffect(() => {
    if (selectedDiagnosticImage) {
      form.reset({
        examDepartment: selectedDiagnosticImage?.examDepartment || '',
        conclusion: selectedDiagnosticImage?.conclusion || '',
        name: selectedDiagnosticImage?.name || '',
        images:
          images?.map((image) => ({
            imageId: image.id,
            checked: true,
            description: image.description || '',
          })) || [],
        evaluationForm: null,
        autoSnapDuration: AUTO_SNAPSHOT_DURATIONS[0],
      });
    } else {
      form.reset(FORM_DEFAULT_VALUE);
    }
  }, [confirmDialog, form, images, intl, selectedDiagnosticImage]);

  const onSelectDiagnosticImage = useCallback(
    (diagnostic: DiagnosticImage | null) => {
      if (Object.keys(form.formState.dirtyFields).length > 0) {
        confirmDialog
          .promptConfirmation({
            title: intl.formatMessage({ id: 'encounter.calendar.label.request.reject' }),
            content: intl.formatMessage({ id: 'common.haveUnsavedData' }),
          })
          .then((confirm) => {
            if (confirm) {
              setSelectedDiagnosticImage(diagnostic);
            }
          })
          .finally(confirmDialog.close);
      } else {
        setSelectedDiagnosticImage(diagnostic);
      }
    },
    [confirmDialog, form.formState.dirtyFields, intl],
  );
  const deleteDiagnosticImageMutate = useDeleteMutate({ onSuccess: revalidate, confirmMessage: 'confirmDeleteTitle' });
  const onUploadMultipleFiles = useUpdateMultipleFiles();

  const onCreateDiagnosticImage = useCallback(async () => {
    const formData = form.getValues();
    dispatch(setLoading(true));
    try {
      const { data } = await dispatch(
        axiosThunk({
          url: API_SERVER.diagnosticImage.create(),
          method: 'POST',
          data: {
            examDepartment: formData.examDepartment,
            conclusion: formData.conclusion,
            referenceId,
            type,
            name: formData.name,
          },
        }),
      );
      revalidate();
      const parsedData = DiagnosticImageSchema.safeParse(data);
      if (parsedData.success) {
        if (formData.images.length > 0) {
          const files = await Promise.all(
            formData.images.map((image, index) =>
              convertToFile(image.url!, 'screen ' + index + '.jpg', 'image/jpeg').then((file) => ({
                file,
                type: storeResourceType,
                referenceId: parsedData.data.id!,
                description: image.description,
              })),
            ),
          );
          await onUploadMultipleFiles(files);
        }
        setSelectedDiagnosticImage(parsedData.data);
      }
      openSnackbar({ message: intl.formatMessage({ id: 'createdSuccess' }) });
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'createdFailed' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  }, [dispatch, form, intl, onUploadMultipleFiles, openSnackbar, referenceId, revalidate, storeResourceType, type]);

  const onUpdateDiagnosticImage = useCallback(async () => {
    if (Object.keys(form.formState.dirtyFields).length > 0 || examDepartment || conclusion) {
      const formData = form.getValues();
      dispatch(setLoading(true));
      const { dirtyFields } = form.formState;
      try {
        if (examDepartment || conclusion || dirtyFields.name) {
          await dispatch(
            axiosThunk({
              url: API_SERVER.diagnosticImage.update(selectedDiagnosticImage?.id!),
              method: 'PUT',
              data: {
                examDepartment: formData.examDepartment,
                conclusion: formData.conclusion,
                name: formData.name,
                referenceId,
                type,
              },
            }),
          ).then((res) => setSelectedDiagnosticImage(res?.data));
          revalidate();
        }

        if (dirtyFields.images?.some((image) => image.description)) {
          const deletedFiles =
            images?.filter((image) => !formData.images.some((item) => item.imageId === image.id)) || [];
          const deleteRequests = deletedFiles.map((file) =>
            dispatch(axiosThunk({ url: API_SERVER.storageResource.delete(file.id!), method: 'DELETE' })),
          );
          const updateDescriptionRequests = form.getValues('images').map((file) =>
            dispatch(
              axiosThunk({
                url: API_SERVER.storageResource.updateDescription(),
                method: 'PUT',
                data: {
                  data: formData.images.map((image) => ({
                    id: image.imageId,
                    description: image.description,
                  })),
                },
              }),
            ),
          );
          await Promise.all([...deleteRequests, ...updateDescriptionRequests]);
          iamgesRevalidate();
        }

        form.reset(formData);
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    }
  }, [
    conclusion,
    dispatch,
    examDepartment,
    form,
    iamgesRevalidate,
    images,
    intl,
    openSnackbar,
    referenceId,
    revalidate,
    selectedDiagnosticImage?.id,
    type,
  ]);

  const onSaveButtonClick = useCallback(() => {
    if (selectedDiagnosticImage) {
      onUpdateDiagnosticImage();
    } else {
      onCreateDiagnosticImage();
    }
  }, [onCreateDiagnosticImage, onUpdateDiagnosticImage, selectedDiagnosticImage]);

  useEffect(() => {
    if (statusFinished && diagnosticImages && diagnosticImages.length >= 0) {
      setSelectedDiagnosticImage(diagnosticImages[0]);
    }
  }, [diagnosticImages, statusFinished]);

  useEffect(() => {
    if (
      (readonly || statusFinished) &&
      diagnosticImagesReverse &&
      diagnosticImagesReverse.length >= 0 &&
      selectedDiagnosticImage === null
    ) {
      setSelectedDiagnosticImage(diagnosticImagesReverse[0]);
    }
  }, [diagnosticImages, diagnosticImagesReverse, readonly, selectedDiagnosticImage, statusFinished]);

  return (
    <Stack direction="column" height="100%">
      <Stack direction="row" gap="10px" padding={2} flex={1}>
        <Stack direction="column" gap={2}>
          {!readonly && !statusFinished && (
            <Button
              startIcon={<MedicalIcon />}
              sx={{
                height: '35px',
                backgroundColor: '#CDDFFF',
                color: '#307BFF',
                '&:hover': {
                  backgroundColor: '#DBDFE5',
                },
              }}
              onClick={() => onSelectDiagnosticImage(null)}
            >
              <FormattedMessage id="camera.connection" />
            </Button>
          )}
          <Stack
            sx={{
              backgroundColor: '#ECEFF1',
              border: '1px solid #CFD8DC',
              borderRadius: '4px',
              overflow: 'hidden',
              flex: 1,
              width: '205px',
            }}
          >
            {diagnosticImagesReverse && diagnosticImagesReverse.length > 0 ? (
              diagnosticImagesReverse?.map((diagnostic) => {
                const isSelected = selectedDiagnosticImage?.id === diagnostic?.id;
                return (
                  <Stack
                    key={diagnostic.id}
                    direction="row"
                    alignContent="center"
                    justifyContent="space-between"
                    padding="10px"
                    gap="20px"
                    sx={{
                      backgroundColor: isSelected ? '#0052E0' : 'white',
                      cursor: 'pointer',
                      '&:hover': {
                        backgroundColor: isSelected ? '#0052E0' : '#E0ECFF',
                      },
                    }}
                    onClick={() => onSelectDiagnosticImage(diagnostic)}
                  >
                    <Typography
                      sx={{
                        color: isSelected ? 'white' : '#003CA6',
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        lineHeight: '24px',
                        letterSpacing: '0.1px',
                      }}
                    >
                      <Typography component="span" fontWeight={500}>
                        {diagnostic.createdTime?.format(FE_DATE_FORMAT)}
                      </Typography>
                      <Typography component="span" sx={{ fontWeight: 400, marginLeft: '10px' }}>
                        {diagnostic.createdTime?.format(FE_TIME_FORMAT)}
                      </Typography>
                    </Typography>
                    {!readonly && (
                      <IconButton
                        sx={{ padding: 0, color: isSelected ? 'white' : '' }}
                        onClick={(event) => {
                          event.stopPropagation();
                          deleteDiagnosticImageMutate({
                            url: API_SERVER.diagnosticImage.delete(diagnostic.id!),
                            method: 'DELETE',
                          });
                          if (diagnostic.id === selectedDiagnosticImage?.id) {
                            setSelectedDiagnosticImage(null);
                          }
                        }}
                      >
                        <Delete />
                      </IconButton>
                    )}
                  </Stack>
                );
              })
            ) : (
              <NoDataBox imageStyle={{ width: '100%' }} />
            )}
          </Stack>
        </Stack>
        <Stack direction="column" gap={2} width="1082px">
          <TextInput form={form} name="name" hideError InputProps={{ sx: { height: '35px' } }} disabled={readonly} />
          <Stack direction="column" gap={2} border="1px solid #CFD8DC" borderRadius="4px" padding={1} flex={1}>
            <Stack direction="row" gap="16px">
              {!selectedDiagnosticImage && (
                <Stack gap={2}>
                  <Box position="relative" overflow="hidden" width="100%">
                    <Webcam width="500px" ref={ref} screenshotFormat="image/jpeg" />
                    <ButtonBase
                      sx={{
                        '&:hover': {
                          opacity: 1,
                        },
                        opacity: 0,
                        transition: 'all 0.3s',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        right: '0',
                        bottom: '0',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      onClick={() =>
                        onCapture((screenShot) =>
                          append({
                            url: screenShot,
                            checked: true,
                            description: '',
                          }),
                        )
                      }
                    >
                      <AddAPhotoOutlinedIcon style={{ color: 'white', width: 80, height: 'auto' }} />
                    </ButtonBase>
                  </Box>
                  <Stack direction="row" justifyContent="space-between">
                    {patient && <PatientInfo patient={patient} />}
                    <Stack direction="column" gap={3} width="230px">
                      <Button
                        sx={{ height: '40px' }}
                        startIcon={<CameraAlt />}
                        onClick={() =>
                          onCapture((screenShot) =>
                            append({
                              url: screenShot,
                              checked: true,
                              description: '',
                            }),
                          )
                        }
                      >
                        <FormattedMessage id="camera.takeAShot" />
                      </Button>
                      <Stack direction="column" gap={1}>
                        <SelectInput
                          form={form}
                          name="autoSnapDuration"
                          options={AUTO_SNAPSHOT_DURATIONS}
                          getValue="value"
                          renderLabel="label"
                          label="camera.setting"
                          hideError
                        />
                        <Button
                          sx={{ height: '40px' }}
                          startIcon={<TwinCameraIcon />}
                          onClick={() =>
                            capturing
                              ? onStopAutoCapture()
                              : onAutoCapture(form.getValues('autoSnapDuration').value, (screenShot) =>
                                  append({ url: screenShot, checked: true, description: '' }),
                                )
                          }
                          color={capturing ? 'error' : 'primary'}
                        >
                          <FormattedMessage id={capturing ? 'camera.stop' : 'camera.takeMultipleShot'} />
                        </Button>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
              )}
              <Stack flex={1}>
                <MediCard title="result" hasCollapse={false}>
                  <Stack direction="column" gap="10px" padding="16px">
                    {!readonly && (
                      <SelectInput
                        form={form}
                        name="evaluationForm"
                        options={Object.values(evaluationFormMap)}
                        rawOptionLabel
                        getValue="id"
                        renderLabel="name"
                        label="encounterForm.template"
                        placeholder="encounterForm.templateEnter"
                        hideError
                        onChange={onSelectEvaluationForm}
                      />
                    )}
                    <SunEditorInput
                      name="examDepartment"
                      readOnly={readonly}
                      height="210px"
                      onChange={(value) => form.setValue('examDepartment', value)}
                      setContents={selectedDiagnosticImage ? examDepartment : form.getValues('examDepartment') || ''}
                    />
                    <SunEditorInput
                      name="conclusion"
                      readOnly={readonly}
                      height="210px"
                      onChange={(value) => form.setValue('conclusion', value)}
                      setContents={selectedDiagnosticImage ? conclusion : form.getValues('conclusion') || ''}
                    />
                  </Stack>
                </MediCard>
              </Stack>
            </Stack>
            {fields && fields.length > 0 ? (
              <Stack direction="column" gap={1}>
                {!readonly && (
                  <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1}>
                    <Checkbox
                      sx={{ padding: 0 }}
                      checked={fields.every((field) => field.checked)}
                      onChange={(event) =>
                        replace(
                          fields.map((field) => ({
                            ...field,
                            checked: event.target.checked,
                          })),
                        )
                      }
                    />
                    <FormattedMessage id="camera.selectAll" />
                    {fields?.findIndex((i) => i.checked) !== -1 && (
                      <IconButton sx={{ padding: 0 }} onClick={() => replace(fields.filter((field) => !field.checked))}>
                        <Delete sx={{ color: '#307BFF' }} />
                      </IconButton>
                    )}
                  </Stack>
                )}
                <Stack direction="row" flexWrap="nowrap" gap={1} overflow="auto" maxWidth="1080px" marginBottom={1}>
                  {fields.map((field, index) => (
                    <Stack
                      direction="column"
                      key={field.imageId || field.key}
                      sx={{ backgroundColor: '#E0ECFF', borderRadius: '4px', padding: 1 }}
                      width="150px"
                      minWidth="150px"
                      boxSizing="border-box"
                      gap={1}
                    >
                      <Box sx={{ width: '100%', height: '100px' }}>
                        {field.imageId ? (
                          <ImagePreview imageId={field.imageId} alt={field.description} width="100%" height="100%" />
                        ) : (
                          <img alt="" src={field.url} width="100%" height="100%" />
                        )}
                      </Box>

                      <Stack direction="row" justifyItems="center" alignItems="center">
                        {!readonly && (
                          <Checkbox
                            checked={form.getValues(`images.${index}.checked`)}
                            onChange={(event) =>
                              update(index, {
                                ...field,
                                checked: event.target.checked,
                              })
                            }
                            sx={{ padding: 0.5 }}
                          />
                        )}
                        <TextInput form={form} name={`images.${index}.description`} hideError disabled={readonly} />
                      </Stack>
                    </Stack>
                  ))}
                </Stack>
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="flex-end" paddingRight="20px" paddingY="10px" gap={2}>
        <Button
          type="button"
          startIcon={<Print />}
          sx={{
            backgroundColor: '#CDDFFF',
            color: '#307BFF',
            '&:hover': {
              backgroundColor: '#DBDFE5',
            },
          }}
          disabled={Object.keys(form.formState.dirtyFields).length > 0}
          onClick={onOpenPrintDialog}
        >
          <FormattedMessage id="report.imagePrint" />
        </Button>
        {!readonly && (
          <Button onClick={onSaveButtonClick} sx={{ width: '100px' }}>
            <FormattedMessage id="save" />
          </Button>
        )}
      </Stack>
      {selectedDiagnosticImage && patient && (
        <PrintFormDialog
          open={openPrintDialog}
          onClose={onClosePrintDialog}
          diagnosticImage={selectedDiagnosticImage}
          extracInfo={extracInfo}
          images={images || []}
          patient={patient}
          encounter={encounter}
        />
      )}
    </Stack>
  );
};

export default DialogContent;
