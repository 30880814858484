import { useFetch } from 'modules/common/hook';
import useFilterContext from 'modules/common/component/filter/useFilterContext';
import { API_SERVER } from 'modules/common/api';
import { FilterProps, MedicationFilter } from 'modules/common/component/filter/type';
import { createFilterOptions, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ChipList from '../CustomeChip';
import AutocompleteInput from '../../form/AutocompleteInput';
import { Medication as TMedication } from 'modules/schema';

const filterOptions = createFilterOptions<TMedication>({
  limit: 100,
  stringify: (option) => option?.name || '',
});
const Medication = ({ filter: { placeholder, type } }: FilterProps<MedicationFilter>) => {
  const { form } = useFilterContext();
  const { data } = useFetch(API_SERVER.medication.index(), {
    revalidateOnFocus: false,
  });

  const selectedMedication = form.watch(type);
  return (
    <Stack direction="column" gap="10px" flex={1}>
      <AutocompleteInput
        form={form}
        name={type}
        options={data}
        getValue="id"
        getLabel="name"
        placeholder={placeholder}
        multiple
        hideError
        filterOptions={filterOptions}
        renderValue={() => (
          <Typography
            sx={{
              color: '#AEB3B5',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              letterSpacing: '0.15px',
              lineHeight: 'unset',
            }}
          >
            <FormattedMessage id={placeholder} />
          </Typography>
        )}
        renderTags={() => null}
      />
      {selectedMedication && selectedMedication.length > 0 && (
        <ChipList
          items={selectedMedication}
          labelKey="name"
          valueKey="id"
          onDelete={(data) =>
            form.setValue(
              type,
              selectedMedication.filter((ele) => ele.id !== data),
            )
          }
        />
      )}
    </Stack>
  );
};

export default Medication;
