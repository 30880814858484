import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import Close from '@mui/icons-material/Close';
import {
  FormControl,
  FormControlProps,
  IconButton,
  InputAdornment,
  InputBaseProps,
  InputLabel,
  ListItemIcon,
  MenuItem,
  OutlinedInput,
  Select,
  SelectProps,
  Typography,
} from '@mui/material';
import { isEqual } from 'lodash';
import React, { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { some } from '../../../constants';

interface IOption {
  value?: string | number | readonly string[] | undefined;
  disabled?: boolean;
  label?: React.ReactNode | string | number;
}

export interface SelectCustomProps extends Omit<SelectProps, 'defaultValue' | 'value' | 'onChange' | 'ref'> {
  label?: React.ReactNode;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  required?: boolean;
  options?: (IOption | some)[];
  placeholder?: string;
  rawOptions?: boolean;
  disableClearBtn?: boolean;
  InputProps?: InputBaseProps;
  loadOptions?: () => Promise<some[]>;
  value: any;
  onChange?: (val: any) => void;
  formControlProps?: FormControlProps;
  hasAllOptions?: boolean;
  acceptNone?: boolean;
  hideIconSelect?: boolean;
}

const SelectCustom = React.forwardRef<HTMLDivElement | null, SelectCustomProps>((props: SelectCustomProps, ref) => {
  const {
    label,
    required,
    options,
    placeholder,
    rawOptions,
    error,
    InputProps,
    loadOptions,
    value,
    onChange,
    disableClearBtn: disableCloseBtn,
    formControlProps,
    hasAllOptions,
    multiple,
    acceptNone,
    displayEmpty = false,
    hideIconSelect = false,
    ...rest
  } = props;
  const intl = useIntl();
  const [optionsTmp, setOption] = React.useState<typeof options>(options);

  const isAllSelected = hasAllOptions
    ? multiple && !!optionsTmp
      ? value?.length === optionsTmp?.length || !value?.length
      : value === ''
    : false;

  const handleChange = (valueSelect: any) => {
    if (!onChange) {
      return;
    }
    if (multiple) {
      if (valueSelect.length === 0) {
        if (acceptNone) {
          onChange([]);
        } else {
          onChange((optionsTmp || []).map((v) => v.value));
        }
        return;
      }
      if (isAllSelected) {
        onChange(optionsTmp?.filter((v) => !valueSelect.includes(v.value)).map((v) => v.value));
      } else if (hasAllOptions && (valueSelect || []).includes('all')) {
        onChange((optionsTmp || []).map((v) => v.value));
      } else {
        onChange(valueSelect);
      }
      return;
    } else {
      onChange(valueSelect);
    }
  };

  const onLoadOptions = React.useCallback(async () => {
    if (loadOptions) {
      const data = await loadOptions();
      if (data && data.length > 0) {
        setOption(data);
      }
    }
  }, [loadOptions]);

  React.useEffect(() => {
    if (!isEqual(options, optionsTmp)) {
      setOption(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  React.useEffect(() => {
    onLoadOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const valueSelect = isAllSelected ? ['all'] : value;
  return (
    <FormControl error={!!error} fullWidth ref={ref} {...formControlProps}>
      {label && (
        <InputLabel required={required} shrink>
          {label}
        </InputLabel>
      )}
      <Select
        value={valueSelect}
        onChange={(event) => handleChange(event.target.value)}
        fullWidth
        displayEmpty={displayEmpty}
        inputProps={hideIconSelect ? { IconComponent: () => null } : undefined} // this part
        endAdornment={
          ((multiple && value?.length > 0 && !isAllSelected) || (!multiple && value !== ' ')) &&
          !rest.readOnly &&
          !rest.disabled &&
          !disableCloseBtn ? (
            <InputAdornment
              position="end"
              sx={{ display: 'none', position: 'absolute', right: 16 }}
              className="clear-btn"
            >
              <IconButton size="small" onClick={() => handleChange(multiple ? (hasAllOptions ? ['all'] : []) : null)}>
                <Close />
              </IconButton>
            </InputAdornment>
          ) : null
        }
        input={<OutlinedInput label="Name" />}
        multiple={multiple}
        sx={{
          '&:hover': {
            '& .clear-btn': {
              display: 'flex',
            },
          },
          ...rest.sx,
        }}
        renderValue={(selected) => {
          if ((selected === ' ' && hasAllOptions) || isAllSelected) {
            return intl.formatMessage({ id: 'all' });
          }
          if (multiple ? !selected?.length : selected === ' ') {
            return (
              <Typography variant="inherit" color="action.disabled" component="span">
                {placeholder}
              </Typography>
            );
          }

          return rawOptions
            ? multiple
              ? selected
                  .map((v) => {
                    const label = optionsTmp?.find((item) => item.value === v)?.label;
                    return label;
                  })
                  .join(', ')
              : optionsTmp?.find((item) => item.value === selected)?.label
            : multiple
            ? selected
                .map((v) => {
                  const label = optionsTmp?.find((item) => item.value === v)?.label;
                  return label && intl.formatMessage({ id: label });
                })
                .join(', ')
            : optionsTmp?.find((item) => item.value === selected)?.label &&
              intl.formatMessage({ id: optionsTmp?.find((item) => item.value === selected)?.label });
        }}
        {...rest}
        style={{ height: 32, ...rest.style }}
      >
        {placeholder && (
          <MenuItem value=" " disabled={true} style={{ opacity: '.5' }}>
            <Typography variant="inherit" style={{ opacity: '.5' }} component="span">
              {placeholder}
            </Typography>
          </MenuItem>
        )}
        {hasAllOptions && (
          <MenuItem value={multiple ? 'all' : ' '}>
            <FormattedMessage id={'all'} />
          </MenuItem>
        )}

        {Array.isArray(optionsTmp) &&
          optionsTmp?.map((option: IOption, index: number) => {
            const { label, disabled, value: tmp, ...rest } = option;
            return (
              <MenuItem key={index} value={tmp} disabled={disabled} {...rest}>
                {multiple && (
                  <ListItemIcon>
                    {(value as any).indexOf(tmp) > -1 || isAllSelected ? (
                      <CheckBoxIcon color="primary" />
                    ) : (
                      <CheckBoxOutlineBlankIcon />
                    )}
                  </ListItemIcon>
                )}{' '}
                {typeof label === 'string' ? rawOptions ? label : label ? <FormattedMessage id={label} /> : '' : label}
              </MenuItem>
            );
          })}
      </Select>
    </FormControl>
  );
});

export default memo(SelectCustom);
