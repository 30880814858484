import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';

export const ReportProfitDetailSchema = z
  .object({
    id: z.number(),
    medicationId: z.string(),
    saleReceiptCode: z.string(),
    inventoryActivityType: z.string(),
    medicationName: z.string(),
    createdDate: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    receiptType: z.string(),
    lot: z.string(),
    quantity: z.number(),
    unit: z.string(),
    amountPaid: z.number(),
    importAmount: z.number(),
    profitAmount: z.number(),
    averagePrice: z.number(),
  })
  .partial();

export type ReportProfitDetail = z.infer<typeof ReportProfitDetailSchema>;
