import { z } from 'zod';

export const UnitSchema = z
  .object({
    id: z.number(),
    fullName: z.string(),
    fullNameEn: z.string(),
    shortName: z.string(),
    shortNameEn: z.string(),
    code: z.string(),
  })
  .partial();

export type Unit = z.infer<typeof UnitSchema>;
