import { BE_DATE_TIME_FORMAT, DENTAL_OBJECT_DETAIL, DENTAL_OBJECT_TYPE } from 'modules/common/constants';
import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { DENTAL_DIAGNOSIS_STATUS } from 'modules/reception/component/dental/DentalDetail/common/constants';

export const DentalDiagnosisSchema = z
  .object({
    id: z.number(),
    patientId: z.number(),
    dentalToothDetailId: z.number(),
    position: z.number(),
    objectType: createObjectKeysEnumSchema(DENTAL_OBJECT_TYPE),
    objectDetail: createObjectKeysEnumSchema(DENTAL_OBJECT_DETAIL),
    dentalExamId: z.number(),
    display: z.string(),
    code: z.string(),
    image: z.string(),
    status: createObjectKeysEnumSchema(DENTAL_DIAGNOSIS_STATUS),
    groupId: z.number(),
    outsideSurface: z.string(),
    insideSurface: z.string(),
    occlusalSurface: z.string(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    note: z.string(),
  })
  .partial();

export type DentalDiagnosis = z.infer<typeof DentalDiagnosisSchema>;
