import { z } from 'zod';
import { DentalDiagnosisSchema } from './DentalDiagnosis';

export const DentalInfoSchema = z
  .object({
    id: z.number(),
    patientId: z.number(),
    position: z.number(),
    objectType: z.string(),
    dentalExamId: z.number(),
    note: z.string(),
    groupId: z.number(),
    dentalDiagnosisList: z.array(DentalDiagnosisSchema),
  })
  .partial();

export type DentalInfo = z.infer<typeof DentalInfoSchema>;
