import { AxiosRequestConfig } from 'axios';
import { useCallback } from 'react';
import { axiosThunk } from '../redux/axios';
import { setLoading } from '../redux/commonReducer';
import useGeneralHook from './useGeneralHook';

interface MutateConfig {
  confirmMessage?: string;
  globalLoading?: boolean;
  successMessage: string;
  failedMessage: string;
  onSuccess?: (res: any) => void;
  onError?: (error: any) => void;
  onFinally?: () => void;
  okeText?: string;
  cancelText?: string;
  errorApi?: boolean;
}

export const DEFAULT_MUTATE_MESSAGE_KEY = {
  update: {
    successMessage: 'updateSuccess',
    failedMessage: 'updateFail',
  },
  delete: {
    successMessage: 'deleteSuccess',
    failedMessage: 'deleteFail',
  },
  create: {
    successMessage: 'createdSuccess',
    failedMessage: 'createdFailed',
  },
};

export const useUpdateMutate = (mutateConfig?: Partial<MutateConfig>) => {
  return useMutate({ ...DEFAULT_MUTATE_MESSAGE_KEY.update, ...mutateConfig });
};

export const useDeleteMutate = (mutateConfig?: Partial<MutateConfig>) => {
  return useMutate({ ...DEFAULT_MUTATE_MESSAGE_KEY.delete, ...mutateConfig });
};

export const useCreateMutate = (mutateConfig?: Partial<MutateConfig>) => {
  return useMutate({ ...DEFAULT_MUTATE_MESSAGE_KEY.create, ...mutateConfig });
};

function extractMessage(data: any): string {
  if (data && Array.isArray(data.errors) && data.errors.length > 0) {
    return data.errors[0].message || '';
  }
  if (data && typeof data === 'object' && data.message) {
    return data.message;
  }
  return '';
}

export const useMutate = (firstMutateConfig: MutateConfig) => {
  const { confirmDialog, intl, dispatch, openSnackbar } = useGeneralHook();

  return useCallback(
    async (requestConfig: AxiosRequestConfig, secondMutateConfig?: Partial<MutateConfig>) => {
      const {
        confirmMessage,
        globalLoading = true,
        successMessage,
        failedMessage,
        onError,
        onFinally,
        onSuccess,
        okeText,
        cancelText,
      } = secondMutateConfig ? { ...firstMutateConfig, ...secondMutateConfig } : firstMutateConfig;
      try {
        let confirm = confirmMessage
          ? await confirmDialog.promptConfirmation({
              title: intl.formatMessage({ id: 'confirm' }),
              content: intl.formatMessage({ id: confirmMessage }),
              okId: okeText && intl.formatMessage({ id: okeText }),
              cancelId: cancelText && intl.formatMessage({ id: cancelText }),
            })
          : true;

        if (confirm) {
          globalLoading && dispatch(setLoading(true));
          const { data } = await dispatch(axiosThunk(requestConfig));
          openSnackbar({ message: intl.formatMessage({ id: successMessage }) });
          onSuccess && onSuccess(data);
        }
      } catch (e) {
        const errorMessage = extractMessage(JSON.parse((e as any)?.response?.request?.response));

        openSnackbar({ message: errorMessage || intl.formatMessage({ id: failedMessage }), type: 'error' });
        onError && onError(e);
      } finally {
        confirmMessage && confirmDialog.close();
        globalLoading && dispatch(setLoading(false));
        onFinally && onFinally();
      }
    },
    [confirmDialog, dispatch, firstMutateConfig, intl, openSnackbar],
  );
};
