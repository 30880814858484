import { Box, Checkbox, Grid, GridProps } from '@mui/material';
import { CheckboxTypeFilter, FormFilter } from './type';
import { Controller, FieldArrayWithId, useFieldArray } from 'react-hook-form';
import useFilterContext from './useFilterContext';
import { FilterValue } from './style';

interface Props<T extends CheckboxTypeFilter = CheckboxTypeFilter> {
  name: CheckboxTypeFilter;
  getOptionLabel: (field: FieldArrayWithId<FormFilter, T, 'id'>) => string;
  xs: GridProps['xs'];
}

const CheckboxList = ({ name, getOptionLabel, xs }: Props) => {
  const { form } = useFilterContext();
  const { fields } = useFieldArray({ control: form.control, name });
  return (
    <Grid container direction="row" flexWrap="wrap" rowSpacing={1} columnSpacing={1} alignItems="center">
      {fields.map((field, index) => (
        <Grid item xs={xs} alignSelf="flex-start" key={index}>
          <Box component="label" display="flex" flexDirection="row" gap="8px" alignItems="flex-start">
            <Controller
              name={`${name}.${index}.checked`}
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <Checkbox sx={{ padding: 0 }} checked={value} onChange={(event) => onChange(event.target.checked)} />
              )}
            />
            <FilterValue>{getOptionLabel(field)}</FilterValue>
          </Box>
        </Grid>
      ))}
    </Grid>
  );
};

export default CheckboxList;
