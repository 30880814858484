import DialogCustom from '../../../../../common/component/DialogCustom';
import { some } from '../../../../../common/constants';
import DialogContent from './DialogContent';

interface Props {
  open: boolean;
  onClose: () => void;
  onSaveButtonClick: (formData: some) => void;
  nextSession: some;
  picOptions: some[];
}

const EditNextTherapySessionTimeDialog = ({ open, onClose, onSaveButtonClick, nextSession, picOptions }: Props) => {
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="therapy.therapyDetail.editNextSession.dialog.title"
      PaperProps={{
        style: { width: '500px' },
      }}
      maxWidth="xl"
    >
      <DialogContent
        onCancelButtonClick={onClose}
        onSaveButtonClick={onSaveButtonClick}
        nextSession={nextSession}
        picOptions={picOptions}
      />
    </DialogCustom>
  );
};

export default EditNextTherapySessionTimeDialog;
