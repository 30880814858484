import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { TherapyDefinition, SystemCategory } from 'modules/schema';
import { Box, Stack } from '@mui/material';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';

interface Props {
  addTherapy: (therapyDefinition: TherapyDefinition) => void;
  readonly: boolean;
}

interface Form {
  therapyDefinitionCategory: SystemCategory | null;
  therapyDefinition: TherapyDefinition | null;
}

const TherapyFilter = ({ addTherapy, readonly }: Props) => {
  const intl = useIntl();
  const formMethods = useForm<Form>({
    defaultValues: {
      therapyDefinitionCategory: null,
      therapyDefinition: null,
    },
  });
  const { setValue } = formMethods;
  const [selectedCategory, setSelectedCategory] = useState<SystemCategory | null>(null);
  const { data: therapyDefinitionCategories } = useFetch(API_SERVER.therapyManagement.getList({ status: 'ACTIVE' }));
  const [therapyDefinitionOptions, setTherapyDefinitionOptions] = useState<TherapyDefinition[]>([]);

  useEffect(() => {
    if (!selectedCategory) {
      setTherapyDefinitionOptions(therapyDefinitionCategories?.flatMap((ele) => ele.items || []) || []);
    } else {
      setTherapyDefinitionOptions(selectedCategory.items || []);
    }
  }, [selectedCategory, therapyDefinitionCategories]);
  return (
    <Stack direction="row" gap={2}>
      <Box width="300px">
        <AutocompleteInput
          form={formMethods}
          name="therapyDefinitionCategory"
          label={intl.formatMessage({ id: 'encounterInfo.therapyCategory' })}
          placeholder={intl.formatMessage({ id: 'encounterInfo.therapyCategoryEnter' })}
          options={therapyDefinitionCategories}
          getLabel="name"
          getValue="id"
          clearButton
          onChange={() => {
            setValue('therapyDefinition', null);
            setSelectedCategory(formMethods.getValues('therapyDefinitionCategory'));
          }}
          disabled={readonly}
        />
      </Box>
      <Box width="300px">
        <AutocompleteInput
          key="therapy"
          form={formMethods}
          name="therapyDefinition"
          label={intl.formatMessage({ id: 'encounterInfo.therapySelect' })}
          placeholder={intl.formatMessage({ id: 'encounterInfo.therapyEnter' })}
          options={therapyDefinitionOptions}
          getLabel="name"
          getValue="id"
          clearButton
          groupBy={(option) => therapyDefinitionCategories?.find((ele) => ele.id === option.systemCategoryId)?.name}
          onChange={() => {
            const therapyDefinition = formMethods.getValues('therapyDefinition');
            if (therapyDefinition) {
              addTherapy(therapyDefinition);
            }
          }}
          disabled={readonly}
        />
      </Box>
    </Stack>
  );
};

export default TherapyFilter;
