import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  DATE_TIME_FORMAT,
  FE_DATE_FORMAT,
  FE_TIME_FORMAT,
  FE_TIME_ZONE_FORMAT,
  INS_DATE_FORMAT,
  INS_DATE_TIME_FORMAT,
  MONTH_YEAR_FORMAT,
  QUARTER_YEAR_FORMAT,
  WEEK_NUMBER_YEAR_FORMAT,
  YEAR_FORMAT,
} from '../common/constants';
import { z, ZodTypeAny } from 'zod';
import moment, { MomentInput } from 'moment';
import { Pagination, PaginationSchema } from './Pagination';

export type DateTimeType =
  | typeof FE_DATE_FORMAT
  | typeof BE_DATE_FORMAT
  | typeof INS_DATE_FORMAT
  | typeof INS_DATE_TIME_FORMAT
  | typeof FE_TIME_FORMAT
  | typeof DATE_TIME_FORMAT
  | typeof BE_DATE_TIME_FORMAT
  | typeof YEAR_FORMAT
  | typeof FE_TIME_ZONE_FORMAT
  | typeof MONTH_YEAR_FORMAT
  | typeof QUARTER_YEAR_FORMAT
  | typeof WEEK_NUMBER_YEAR_FORMAT;

export function createDateTimeSchema(dateTimeFormat: DateTimeType) {
  return z
    .custom<moment.Moment>((value) => moment(value as MomentInput, dateTimeFormat).isValid(), 'Invalid date')
    .transform((value) => moment(value, dateTimeFormat));
}

export function createPageSchema<T extends ZodTypeAny>(schema: T) {
  return z
    .object({
      content: z.array(schema),
      pagination: PaginationSchema,
      summary: schema.optional(),
    })
    .partial({ summary: true });
}

export type Page<T extends z.infer<ZodTypeAny>> = {
  content: T[];
  pagination: Pagination;
};

const keys = <K extends string>(r: Record<K, any>): K[] => Object.keys(r) as K[];

export function createObjectKeysEnumSchema<K extends string>(obj: Record<K, any>) {
  return z.enum([keys(obj)[0], ...keys(obj)]);
}

export const createDateRangeSchema = (dateFormat: DateTimeType = BE_DATE_TIME_FORMAT) => {
  return z
    .object({
      fromDate: z.string().refine((value) => moment(value, dateFormat).isValid, { message: 'validation.invalidDate' }),
      toDate: z.string().refine((value) => moment(value, dateFormat).isValid, { message: 'validation.invalidDate' }),
    })
    .refine(
      (value) => {
        if (moment(value.toDate, dateFormat).isBefore(moment(value.fromDate, dateFormat))) {
          return false;
        }
        return true;
      },
      { message: 'validation.fromDateAfterToDate', path: ['fromDate'] },
    )
    .refine(
      (value) => {
        if (moment(value.toDate, dateFormat).isBefore(moment(value.fromDate, dateFormat))) {
          return false;
        }
        return true;
      },
      { message: 'validation.fromDateAfterToDate', path: ['toDate'] },
    );
};
