import { Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NoDataImage } from '../../../svg';

interface Props {
  style?: React.CSSProperties;
  imageStyle?: React.CSSProperties;
  message?: string;
}
const NoDataBox = (props: Props) => {
  const { style, message, imageStyle } = props;
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 16,
        minHeight: 320,
        ...style,
      }}
    >
      <img src={NoDataImage} alt="noData" style={{ maxHeight: 320, ...imageStyle }} />
      <Typography variant="body1" marginTop={2}>
        <FormattedMessage id={message || 'noData'} />
      </Typography>
    </div>
  );
};
export default NoDataBox;
