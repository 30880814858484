import { Box } from '@mui/material';
import SchemaForm from '../../../../../common/SchemaForm';
import * as React from 'react';
import { dentalDrugUsageFormSchema } from './schema';
import { API_SERVER } from '../../../../../common/api';
import { some } from 'modules/common/constants';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { setLoading } from '../../../../../common/redux/commonReducer';
import { useParams } from 'react-router';
import { axiosThunk } from '../../../../../common/redux/axios';
import { useFetchIndicationRequest } from '../common/fetcher';
import { useDentalDetailStateSelector } from '../state';
import { MEDICATION_CATEGORY_SCOPE, MEDICATION_USAGE_TYPE } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';

const DrugUse = () => {
  const { dispatch, intl, openSnackbar, appState } = useGeneralHook();
  const { dentalSessionId } = useParams<{ dentalSessionId; patientId: string }>();
  const readOnly = useDentalDetailStateSelector.use.readonly();

  const { data: medicationCategoryList = [] } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({ scope: MEDICATION_CATEGORY_SCOPE.CLINIC }),
  );

  const { data: medicationList, revalidate } = useFetch(
    API_SERVER.medicationUsage.getList(dentalSessionId, { type: MEDICATION_USAGE_TYPE.DENTAL_EXAM }),
  );

  const { data: indicationList = [] } = useFetchIndicationRequest(dentalSessionId);

  const mappedDataMedicationList = React.useMemo(() => {
    let temp: any[] = [];
    medicationList?.forEach((i) => {
      const intendedRouteLabel = appState.common.intendedRoutes.find((v) => v.value === i.intendedRoute)?.label;
      const obj = {
        ...i,
        intendedRoute: intendedRouteLabel
          ? {
              value: i.intendedRoute,
              label: intendedRouteLabel,
            }
          : '',
      };
      temp.push(obj);
    });
    temp.sort((a, b) => a.id - b.id);
    return { medicationList: temp };
  }, [appState.common.intendedRoutes, medicationList]);

  const onSelectMedication = React.useCallback(
    async (medication: some) => {
      if (medication) {
        const medicationAdd = {
          medicationId: medication.id,
          isFree: true,
          quantity: 1,
          referenceId: dentalSessionId,
          type: MEDICATION_USAGE_TYPE.DENTAL_EXAM,
        };
        try {
          await dispatch(axiosThunk({ url: API_SERVER.medicationUsage.create(), method: 'post', data: medicationAdd }));
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
          revalidate();
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
    },
    [dentalSessionId, dispatch, openSnackbar, intl, revalidate],
  );

  const onDelete = React.useCallback(
    async (medication: some) => {
      try {
        await dispatch(axiosThunk({ url: API_SERVER.medicationUsage.index(medication?.id), method: 'delete' }));
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, openSnackbar],
  );

  const onChangeMedicationInfo = React.useCallback(
    async (medication: some) => {
      try {
        const medicationData: some = {
          ...medication,
          intendedRoute: medication?.intendedRoute?.value,
          indicationRequestId: medication?.indicationRequest?.id,
        };

        await dispatch(
          axiosThunk({
            url: API_SERVER.medicationUsage.index(medicationData?.id),
            method: 'put',
            data: medicationData,
          }),
        );
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        revalidate();
      } catch (e: any) {
        if (e?.response?.data?.errors) {
          e?.response?.data?.errors?.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        // dispatch(setLoading(false));
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <>
      <Box bgcolor="white" className="mb10">
        <Box padding={1} className="drug-use">
          <SchemaForm
            readOnly={readOnly}
            schema={dentalDrugUsageFormSchema}
            formData={mappedDataMedicationList}
            medicationCategoryList={medicationCategoryList}
            indicationOptions={indicationList?.filter((i) => i?.status !== 'DELETED')}
            onSelectMedication={onSelectMedication}
            onDelete={onDelete}
            onChangeMedicationInfo={onChangeMedicationInfo}
            formId="exam-price"
            formProps={{
              autoComplete: 'nope',
              autoCorrect: 'off',
            }}
            noHelperText={true}
          />
        </Box>
      </Box>
    </>
  );
};

export default DrugUse;
