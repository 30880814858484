import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { chain } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import DialogCustom from 'modules/common/component/DialogCustom';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  FE_DATE_FORMAT,
  PATIENT_ADDRESS_TYPE,
  some,
} from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import PrescriptionPrintDataTable from './PrescriptionPrintDataTable';
import printStyles from './PrintPrescriptionStyle.module.scss';
import { Encounter, Prescription } from 'modules/schema';
import { MEDICATION_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import * as _ from 'lodash';
import { useParams } from 'react-router';
import { useFetchDentalExam } from 'modules/reception/component/dental/DentalDetail/common/fetcher';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { EncounterContext } from '../../../const';

const COMPACT_TYPE = 'PRESCRIPTION_PRINT_COMPACT_TYPE';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    // height: '100%',
    display: 'block',
    padding: '24px 16px',
    '& .hiddenPage': { display: 'none' },
    [`@media print`]: {
      '& .newPage': { pageBreakBefore: 'always' },
      '& .hiddenPage': { display: 'block' },
      '& .diver-page': { display: 'none' },
      padding: 0,
    },
  },
});

interface TestReceiptContentProps {
  encounter: Encounter;
  compact: boolean;
  presNote?: string;
  prescription?: Prescription[];
  type?: string;
}
interface Props {
  open: boolean;
  onClose: () => void;
  presNote?: string;
  prescription: Prescription;
}
interface GroupProps {
  prescription: Prescription[];
  presNote?: string;
  compact?: boolean;
}

export const PrescriptionPrintExamDoctorContent = (props: TestReceiptContentProps) => {
  const { compact, encounter, presNote, prescription, type } = props;

  const { appState } = useGeneralHook();
  const { currentLocation } = appState.authen;
  const { dentalSessionId } = useParams<{ dentalSessionId }>();
  const { data: dentalExam } = useFetchDentalExam(dentalSessionId);

  const pattern = /^.*?-(.*)$/;
  const match = encounter?.patient?.code?.match(pattern);

  const renderHeader = React.useMemo(() => {
    if (type === 'normal') {
      return 'printForm.prescription.normal';
    } else if (type === 'narcotic') {
      return 'printForm.prescription.narcotic';
    } else if (type === 'psychotropic-precursor') {
      return 'printForm.prescription.psychotropic-precursor';
    } else {
      return 'printForm.prescription.normal';
    }
  }, [type]);

  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase', paddingTop: '0px', paddingBottom: '8px' }}>
            <FormattedMessage id={renderHeader} />
          </Typography>
        </HeaderPrintForm>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 150 }} className={printStyles['LargeFontSize']}>
                <FormattedMessage id="patientCode" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" className={printStyles['LargeFontSize']}>
                {match && match[1] ? match[1] : encounter?.patient?.code || dentalExam?.patient?.formatCode || ''}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 150 }} className={printStyles['LargeFontSize']}>
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography
                variant="body1"
                style={{ textTransform: 'uppercase' }}
                className={printStyles['LargeFontSize']}
              >
                {encounter?.patient?.name || dentalExam?.patient?.name || ''}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 150 }} className={printStyles['LargeFontSize']}>
                <FormattedMessage id="telephone" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" className={printStyles['LargeFontSize']}>
                {encounter?.patient?.mobilePhone || dentalExam?.patient?.mobilePhone || ''}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 91 }} className={printStyles['LargeFontSize']}>
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" className={printStyles['LargeFontSize']}>
                {encounter?.patient?.homeAddress?.address ||
                  dentalExam?.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)
                    ?.address ||
                  ''}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 91 }} className={printStyles['LargeFontSize']}>
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1" className={printStyles['LargeFontSize']}>
                {encounter?.patient?.dob && moment(encounter?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                {dentalExam?.patient?.dob &&
                  moment(dentalExam?.patient?.dob, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 91 }} className={printStyles['LargeFontSize']}>
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1" className={printStyles['LargeFontSize']}>
                {encounter?.patient?.gender && <FormattedMessage id={encounter?.patient?.gender} />}
                {dentalExam?.patient?.gender?.toLowerCase() || ''}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [renderHeader, match, encounter, dentalExam]);

  const encounterInfo = React.useMemo(() => {
    const RenderData = ({ data }) => {
      return (
        <>
          {data?.map((item, index) => (
            <Box key={index}>
              &nbsp;-&nbsp;{item.code}&nbsp;-&nbsp;{item.display}
            </Box>
          ))}
          <Box>
            &nbsp;{encounter?.differentialDiagnosis && '- ' + extractHTMLContent(encounter?.differentialDiagnosis)}
          </Box>
        </>
      );
    };
    return (
      <Grid container>
        {!compact && (
          <Grid item xs={12} display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 150 }} className={printStyles['LargeFontSize']}>
              <FormattedMessage id="printForm.placeOfIndication" />
            </Typography>
            :&nbsp;
            <Typography variant="body1" className={printStyles['LargeFontSize']}>
              {encounter?.physicsRoom?.name}
            </Typography>
          </Grid>
        )}
        <Grid item xs={12} display="flex">
          <Typography variant="subtitle1" style={{ minWidth: 150 }} className={printStyles['LargeFontSize']}>
            <FormattedMessage id="encounter.encounterDetail.block.title.diagnosis" />
          </Typography>
          :&nbsp;
          <Box>
            <Typography variant="body1" component="span" className={printStyles['LargeFontSize']}>
              <RenderData data={encounter?.icdDiagnosisList} />
            </Typography>
          </Box>
        </Grid>
      </Grid>
    );
  }, [compact, encounter]);

  const testInfo = React.useMemo(() => {
    return (
      <>
        <PrescriptionPrintDataTable data={prescription} />
        <Box marginY={1}>
          <Typography variant="subtitle1" className={printStyles['LargeFontSize']}>
            <FormattedMessage id="encounterPrescription.note" />
            :&nbsp;
            <Typography
              variant="subtitle1"
              style={{ fontStyle: 'italic' }}
              component="span"
              className={printStyles['LargeFontSize']}
            >
              {extractHTMLContent(presNote)}
            </Typography>
          </Typography>
          <Typography
            variant="subtitle1"
            style={{ fontStyle: 'italic' }}
            component="span"
            className={printStyles['LargeFontSize']}
          >
            <FormattedMessage id="encounterPrescription.noteHelper" />
          </Typography>
        </Box>
      </>
    );
  }, [prescription, presNote]);

  const footer = React.useMemo(() => {
    return (
      <Box style={{ pageBreakInside: 'avoid', marginTop: 16 }}>
        <Grid container>
          {compact ? (
            <Grid item xs={6} />
          ) : (
            <>
              <Grid item xs={3} textAlign="center">
                <Typography variant="body1">&nbsp;</Typography>
                <Typography variant="subtitle1">
                  <FormattedMessage id="printForm.patientConfirm" />
                </Typography>
                <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                  <FormattedMessage id="medicalPrint.helperText" />
                </Typography>
                <Typography variant="body1">{encounter?.patient?.name || dentalExam?.patient?.name || ''}</Typography>
              </Grid>
              <Grid item xs={3} textAlign="center">
                <Typography variant="body1">&nbsp;</Typography>
                <Typography variant="subtitle1">
                  <FormattedMessage id="printForm.pharmacyConfirm" />
                </Typography>
                <Typography variant="body1" color="textSecondary" fontStyle="italic">
                  <FormattedMessage id="medicalPrint.helperText" />
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={6} textAlign="center">
            <Typography variant="body1" fontStyle="italic">
              <FormattedMessage
                id={'printForm.dateShort'}
                values={{
                  day: encounter?.createdTime?.date(),
                  month: encounter?.createdTime?.format('M'),
                  year: encounter?.createdTime?.year(),
                }}
              />
            </Typography>
            <Typography variant="subtitle1">
              <FormattedMessage id="encounterPrescription.doctor" />
            </Typography>
            <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
              <FormattedMessage id="medicalPrint.helperText" />
            </Typography>
            <Typography variant="body1">{encounter?.picName || dentalExam?.picName}</Typography>
          </Grid>
        </Grid>
      </Box>
    );
  }, [compact, encounter, dentalExam]);

  React.useEffect(() => {
    const css = `@page { size: ${1000 * 0.75}px  ${1290 * 0.75}px; margin: 0cm 0.6cm; }`;
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.media = 'print';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    return () => {
      head.removeChild(style);
    };
  }, []);

  if (!currentLocation) {
    return null;
  }

  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }}>
        {header}
        <Box marginY={1}>
          <Divider />
        </Box>
        {encounterInfo}
        {testInfo}
        {footer}
      </Box>
    </>
  );
};

export const PrescriptionContentGroup = (props: GroupProps) => {
  const { prescription, presNote, compact } = props;

  const groupedServicesMapping = React.useMemo(() => {
    return chain(prescription)
      .groupBy('medicationKnowledgeType')
      .map((value, key) => ({
        group: key,
        items: value,
      }))
      .value()
      .reverse()
      .reduce((val: some[], cur: some, index: number) => {
        if (cur.group === 'narcotic' || cur.group === 'psychotropic-precursor') {
          return [...val, { ...cur, page: index + 1, insured: true, hidden: false, index }];
        }
        return [
          ...val,
          { ...cur, items: cur.items.filter((v) => !v.insuredPay), insured: false, page: index + 1, index },
        ];
      }, [])
      .filter((v) => v.items.length > 0);
  }, [prescription]);

  return (
    <Box>
      {groupedServicesMapping?.length > 0 ? (
        <>
          {groupedServicesMapping?.map((item: some, index: number) => {
            return (
              <Box
                className={`${index ? 'newPage' : ''} ${item.hidden ? 'hiddenPage' : ''}`}
                key={index}
                sx={{ position: 'relative', display: 'block', minHeight: '100%' }}
              >
                <Box style={{ pageBreakInside: 'avoid' }}>
                  <Grid container>
                    <Grid item xs={12} marginY={1}>
                      <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
                        <FormattedMessage id={`printForm.prescription.${item.group}`} />
                      </Typography>
                    </Grid>
                    {item.items?.map((item, index) => {
                      return (
                        <Grid item xs={12} key={item.id}>
                          <Typography variant="subtitle1">
                            {index + 1}.&nbsp;{item.name}&nbsp;
                            {!compact && (
                              <Typography variant="body1" component="span">
                                ({item.ingredients}, {item.dose})
                              </Typography>
                            )}
                          </Typography>
                          <Box display="flex" flexWrap="wrap" paddingLeft={2} gap={2}>
                            <Typography variant="body1">
                              <FormattedMessage id="quantity" />
                              :&nbsp;
                              {item.quantity || 0}
                            </Typography>
                            <Typography variant="body1">
                              <FormattedMessage id="encounterPrescription.unit" />
                              :&nbsp;{item.unit}
                            </Typography>
                            <Typography variant="body1">
                              <FormattedMessage id="encounterPrescription.intendedRoute" />
                              :&nbsp;{item.intendedRoute?.label}
                            </Typography>
                            <Typography variant="body1">
                              <FormattedMessage id="encounterPrescription.instruction" />
                              :&nbsp;{item.instruction}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Box>
                {item.index !== groupedServicesMapping.length - 1 && <Divider sx={{ marginY: 2 }} />}
              </Box>
            );
          })}
          <Divider sx={{ marginY: 4 }} />
          <Typography variant="subtitle1">
            <FormattedMessage id="encounterPrescription.note" />
            :&nbsp;
            <Typography variant="subtitle1" style={{ fontStyle: 'italic' }} component="span">
              {presNote}
            </Typography>
          </Typography>
          <Typography variant="subtitle1" style={{ fontStyle: 'italic' }} component="span">
            <FormattedMessage id="encounterPrescription.noteHelper" />
          </Typography>
        </>
      ) : (
        <Typography variant="subtitle1">
          <FormattedMessage id="encounterPrescription.noPrescription" />
        </Typography>
      )}
    </Box>
  );
};

const PrescriptionPrintExamDoctorDialog = (props: Props) => {
  const { onClose, open, presNote, prescription } = props;
  const { appState } = useGeneralHook();
  const allIntendedRoutes = appState.common.intendedRoutes;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [compact, setCompact] = React.useState(localStorage.getItem(COMPACT_TYPE) === 'compact' || false);
  const encounterContext = React.useContext(EncounterContext);

  const { data: medicationCategoryList } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({ scope: MEDICATION_CATEGORY_SCOPE.INVENTORY }),
    { enabled: open },
  );
  const medications = medicationCategoryList?.flatMap((medication) => medication?.medicationList);

  const prescriptionTable = prescription?.prescriptionDetailList?.map((prescriptionDetail) => {
    const foundMedication =
      medications?.find((medication) => medication?.id === prescriptionDetail?.medicationId) || {};
    return (
      foundMedication && {
        ...foundMedication,
        quantity: prescriptionDetail.quantity,
        usage: prescriptionDetail.usage,
        prescriptionDetail: prescriptionDetail,
        intendedRoute: allIntendedRoutes.find((i) => i.value === prescriptionDetail.intendedRoute),
      }
    );
  });

  const groupedByProductType = _.groupBy(prescriptionTable, (item) => {
    return item?.medicationKnowledge?.productType ?? 'normal';
  });
  const keyGouped = Object?.keys(groupedByProductType);

  // When the dialog is open check counter > 0 then call the API get encounter data and reset the counter.
  React.useEffect(() => {
    if (encounterContext?.counter! > 0 && open) {
      encounterContext?.revalidateEncounter();
      encounterContext?.setCounter(0);
    }
  }, [encounterContext, open, props]);

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: '80vw', width: '80vw' },
      }}
      keepMounted={false}
      title={'printForm.prescription'}
      footer={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={compact}
                onChange={(e) => {
                  setCompact(e.target.checked);
                  localStorage.setItem(COMPACT_TYPE, e.target.checked ? 'compact' : 'expansion');
                }}
              />
            }
            label={<FormattedMessage id="printCompact" />}
          />
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="encounterPrescription.print" />
              </Button>
            )}
            content={() => refPrintComponent?.current}
          />
        </Box>
      }
    >
      <Box ref={refPrintComponent}>
        {keyGouped?.map((key) => {
          const data = groupedByProductType[key];
          if (data) {
            return (
              <Box className={classes.print} key={key}>
                <PrescriptionPrintExamDoctorContent
                  type={key}
                  prescription={data}
                  encounter={encounterContext?.encounter!}
                  compact={compact}
                  presNote={presNote}
                />
              </Box>
            );
          }
          return null;
        })}
      </Box>
    </DialogCustom>
  );
};

export default PrescriptionPrintExamDoctorDialog;
