import { Box, Button, Stack } from '@mui/material';
import { DEFAULT_VALUES, FILTER_CONFIGS } from './const';
import { FormSchema, SearchParams } from './type';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';
import { groupBy, map } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import Report from 'modules/common/component/report';
import Filter from 'modules/common/component/filter';
import DateInput from 'modules/common/component/form/DateInput';
import { FE_DATE_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import { useSelector } from 'react-redux';
import { SPECIALIST } from 'modules/layout/constants';
import { AppState } from 'redux/reducer';
import { INDICATION_REQUEST_TYPE } from 'modules/common/apiConstants';
import { Option } from 'modules/common/type';
import SelectInput from 'modules/common/component/form/SelectInput';
import ReactToPrint from 'react-to-print';
import { Print } from '@mui/icons-material';
import { formatEndDate, formatStartDate, isValidDate, maxDate } from 'modules/common/utils';
import { WORK_API } from 'modules/common/service';

export const FILTER_KEY = 'INDICATION_SERVICE_CHART_FILTER';

export const SELECT_COLUMNS_KEY = 'INDICATION_SERVICE_CHART_SELECT_COLUMNS';

interface Props {
  typeOption: Option[];
  scopesOption: Option[];
}
const IndicationServiceChart: React.FunctionComponent<Props> = ({ typeOption, scopesOption }) => {
  const refPrintComponent = useRef(null);
  const [searchParams, setSearchParams] = useState<SearchParams>(DEFAULT_VALUES);
  const form = useForm<FormSchema>({
    defaultValues: {
      ...DEFAULT_VALUES,
      toDate: formatEndDate(moment().add(+1, 'day')),
    },
    mode: 'onChange',
  });
  const intl = useIntl();
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate = formatEndDate(moment(toDateOrigin).add(-1, 'day'));
  const formData = form.getValues();
  const scopes = form.watch('scopes');
  const type = form.watch('type');
  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      type: type
        ? map(type, 'value')
        : isDentalClinic
        ? [INDICATION_REQUEST_TYPE.DENTAL_EXAM.value, INDICATION_REQUEST_TYPE.THERAPY_SESSION.value]
        : [INDICATION_REQUEST_TYPE.ENCOUNTER.value, INDICATION_REQUEST_TYPE.THERAPY_SESSION.value],
      scopes: scopes ? map(scopes, 'value') : [],
      page: 0,
      pageSize: 100000,
    };
  }, [searchParams, type, isDentalClinic, scopes]);

  const { data: reportData } = useFetch(WORK_API.getIndicationList(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'CHART'),
  });
  const reportMap = useMemo(() => groupBy(reportData || [], 'systemCategoryId'), [reportData]);
  useEffect(() => {
    setSearchParams((prev) => ({ ...prev, fromDate, toDate }));
  }, [fromDate, toDate]);

  useEffect(() => {
    if (moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'month')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'month')
            .add(+1, 'day'),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateOrigin, toDateOrigin]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title title="report.workReport.chart.title.indication.serviceChart" />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue, filterConfig) => {
          setSearchParams((prev) => ({
            ...prev,
            isFrees: filterConfig.PAYMENT_PLAN.map((ele) => (ele.value === PAYMENT_PLAN.FREE.value ? true : false)),
            executeUserIds: filterValue.EMPLOYEE,
            serviceIds: filterValue.SERVICE,
            status: filterValue.INDICATION_REQUEST_STATUS,
          }));
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                key="type"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                multiple
                showCheckBox
              />
              <SelectInput
                form={form}
                name="scopes"
                key="scopes"
                options={scopesOption}
                getValue="value"
                renderLabel="label"
                label="indication.plan"
                placeholder="indication.planEnter"
                multiple
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                format={FE_DATE_FORMAT}
                required
                maxDate={moment() && moment(toDate)}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                maxDate={maxDate('DAY', fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                format={FE_DATE_FORMAT}
                required
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() =>
                    form.reset({
                      ...DEFAULT_VALUES,
                      toDate: formatEndDate(moment().add(+1, 'day')),
                    })
                  }
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />}>
                  <FormattedMessage id={'printReport'} />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Stack>
        </Stack>
        <Report.ColumnChart
          refPrintComponent={refPrintComponent}
          xAxisTitle="serviceName"
          yAxisTitle="report.chart.title.numberOfIndication"
          categories={Object.values(reportMap).map((encounters) => encounters[0]?.systemCategoryName || '')}
          series={[
            {
              type: 'column',
              name: intl.formatMessage({ id: 'serviceName' }),
              data: Object.values(reportMap).map((encounters) => encounters.length),
              color: '#307BFF',
              borderRadius: 5,
              showInLegend: false,
            },
          ]}
        />
      </Filter>
    </Stack>
  );
};

export default IndicationServiceChart;
