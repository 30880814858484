import { z } from 'zod';

export const MedicationOrganizationSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    address: z.string(),
  })
  .partial();

export type MedicationOrganization = z.infer<typeof MedicationOrganizationSchema>;
