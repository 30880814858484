import { Box } from '@mui/system';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE, some } from '../../../../../../common/constants';
import Stack from '@mui/material/Stack';
import TextValue from './TextValue';
import moment from 'moment';

interface Props {
  therapy: some;
}

const PatientInfo = ({ therapy }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box flex={1.5}>
        <TextValue
          label="therapy.label.customer.code"
          value={therapy?.patient?.code?.split('-')[1]}
          labelWidth="120px"
        />
        <TextValue label="name" value={therapy?.patient?.name} labelWidth="120px" />
      </Box>
      <Box flex={1}>
        <TextValue
          label="birthdayShort"
          value={moment(therapy?.patient?.dob, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          labelWidth="80px"
        />
        <TextValue label="gender" value={therapy?.patient?.gender?.toLowerCase()} localizationValue labelWidth="80px" />
      </Box>
      <Box flex={1} paddingLeft="auto">
        <TextValue
          label="address"
          value={therapy?.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address}
        />
      </Box>
    </Stack>
  );
};

export default PatientInfo;
