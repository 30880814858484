import { Box, Stack, Typography } from '@mui/material';
import DateInput from 'modules/common/component/form/DateInput';
import { formatEndDate, formatStartDate, isValidDate } from 'modules/common/utils';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Filter from 'modules/common/component/filter';
import Report from 'modules/common/component/report';
import { useDialog, useFetch } from 'modules/common/hook';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { APPOINTMENT_STATUS, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { INDICATION_REQUEST_TYPE, PATIENT_TYPE, SPECIALIZED_REPORT_TYPE } from 'modules/common/apiConstants';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReportFilterOption } from 'modules/common/component/report/type';
import { DEFAULT_CONFIG } from 'modules/common/component/filter/const';
import { APPOINTMENT_TYPES } from 'modules/reception/component/calendar/constants';
import { Appointment } from 'modules/schema';
import SelectInput from 'modules/common/component/form/SelectInput';
import { ValueType } from 'modules/common/type';
import { DEFAULT_VALUES, FILTER_CONFIGS } from './consts';
import { WORK_API } from 'modules/common/service';
import { intersection } from 'lodash';
import { VIEW_PATIENT } from '../../const';
import { duplicateTab } from 'modules/common/component/Encounter/utils';
import { ROUTES } from 'modules/layout/router';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';

interface Props {
  typeOption: ValueType<typeof SPECIALIZED_REPORT_TYPE>[];
}

interface FormSchema {
  type?: ValueType<typeof SPECIALIZED_REPORT_TYPE>;
  fromDate: string;
  toDate: string;
  keyword: string;
}

const FILTER_KEY = 'APPOINTMENT_REPORT_FILTER';
const FILTER_KEY_VALUE = 'APPOINTMENT_REPORT_FILTER_VALUE';
const SELECT_COLUMNS_KEY = 'APPOINTMENT_REPORT_SELECT_COLUMNS';

interface SearchParams extends FormSchema {
  patientTypes?: (keyof typeof PATIENT_TYPE)[];
  status?: (keyof typeof APPOINTMENT_STATUS)[];
  types?: ValueType<typeof INDICATION_REQUEST_TYPE>;
}

const AppointmentsReportTable: React.FunctionComponent<Props> = ({ typeOption }) => {
  const filterConfig = JSON.parse(localStorage.getItem(FILTER_KEY_VALUE)!);
  const intl = useIntl();
  const allOptions = intl.formatMessage({ id: 'all' });
  const { page, pageSize, paginationProps, resetPage } = useTablePagination();
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const [searchParams, setSearchParams] = useState<SearchParams>({ ...DEFAULT_VALUES, type: typeOption[0] });
  const [filterReportOptions, setFilterReportOptions] = useState<ReportFilterOption[]>([]);
  const roles = useSelector((state: AppState) => state.authen.user?.role);
  const COLUMNS: Column<Appointment>[] = [
    { type: 'INDEX', width: '2%', sticky: true, align: 'center' },
    {
      title: 'appointmentBook.date',
      render: (record) => record.startTime && record.startTime.format(FE_DATE_FORMAT),
      width: '8%',
      sticky: true,
      selectColumnConfig: {
        defaultChecked: true,
        disabled: true,
      },
    },
    {
      title: 'appointmentBook.isNewPatient',
      render: (record) => {
        return record.patientType && <FormattedMessage id={PATIENT_TYPE[record.patientType]?.label} />;
      },
      width: '10%',
      sticky: true,
      selectColumnConfig: {
        defaultChecked: true,
        disabled: true,
      },
    },
    {
      title: 'patientName',
      render: 'name',
      width: '10%',
      maxWidth: '150px',
      sticky: true,
      selectColumnConfig: {
        defaultChecked: true,
        disabled: true,
      },
      fieldName: 'name',
      enableEllipsis: true,
    },
    {
      title: 'patientCode',
      render: (record) => {
        let codes = record?.code?.split('-')[1];
        if (intersection(VIEW_PATIENT, roles).length > 0) {
          return (
            <Typography
              fontSize={'14px'}
              onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                event.preventDefault();
                duplicateTab(ROUTES.patientProfile.gen(record?.patientId!));
              }}
              color={'primary'}
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {codes}
            </Typography>
          );
        } else {
          return <Typography fontSize={'14px'}> {codes}</Typography>;
        }
      },
      width: '10%',
      sticky: true,
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'appointmentBook.type',
      render: (record) => {
        const label = record.appointmentGroupId
          ? APPOINTMENT_TYPES.APPOINTMENT_GROUP.label
          : APPOINTMENT_TYPES.PATIENT.label;
        return <FormattedMessage id={label} />;
      },
      width: '8%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'status',
      render: (record) => {
        return <FormattedMessage id={APPOINTMENT_STATUS[record?.status!]?.label} />;
      },
      width: '8%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'reason',
      render: 'reason',
      width: '10%',
      maxWidth: '160px',
      selectColumnConfig: {
        defaultChecked: true,
      },
      fieldName: 'reason',
      enableEllipsis: true,
    },
    {
      title: 'telephone',
      render: 'mobilePhone',
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'address',
      render: (record) => (
        <Typography fontSize={'14px'} sx={{ wordBreak: 'break-word' }}>
          {record.address}
        </Typography>
      ),
      width: '12%',
      maxWidth: '150px',
      selectColumnConfig: {
        defaultChecked: true,
      },
      fieldName: 'address',
      enableEllipsis: true,
    },
  ];

  const [selectedColumns, setSelectedColumn] = useState<Column<Appointment>[]>(
    COLUMNS?.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );

  const form = useForm<FormSchema>({
    defaultValues: { ...DEFAULT_VALUES, type: typeOption[0] },
    mode: 'onChange',
  });
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate = formatEndDate(moment(toDateOrigin).add(-1, 'day'));
  const type = form.watch('type');
  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      page: page,
      pageSize: pageSize,
      fromDate: fromDate,
      toDate: toDate,
      type: type ? type.value : typeOption[0].value,
    };
  }, [fromDate, page, pageSize, searchParams, toDate, type, typeOption]);

  const { data: reportPage } = useFetch(WORK_API.getAppointmentPaging(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'CHART'),
  });
  const { data: printDataPage } = useFetch(
    WORK_API.getAppointmentPaging({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    {
      globalLoading: true,
      revalidateOnFocus: false,
      enabled: openPrintDialog && isValidDate(fromDate, toDate, 'CHART'),
    },
  );

  React.useEffect(() => {
    setFilterReportOptions([
      { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      {
        title: 'serviceTypes',
        value: type ? intl.formatMessage({ id: type?.label }) : allOptions,
      },
      {
        title: DEFAULT_CONFIG.APPOINTMENT_STATUS.title,
        value:
          filterConfig?.APPOINTMENT_STATUS?.length === 0
            ? allOptions
            : filterConfig?.APPOINTMENT_STATUS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
      },
      {
        title: DEFAULT_CONFIG.PATIENT_TYPE.title,
        value:
          filterConfig?.PATIENT_TYPE?.length === 0
            ? allOptions
            : filterConfig?.PATIENT_TYPE.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
      },
      {
        title: DEFAULT_CONFIG.APPOINTMENT_CHART_TYPE.title,
        value: allOptions,
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions, fromDate, intl, toDate, type]);

  const exportUrl = React.useMemo(
    () => WORK_API.getAppointmentExcel({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    [formatSearchParams],
  );

  return (
    <Stack direction="column" gap={2}>
      <Report.Title
        title="report.workReport.tab.title.appointmentReport"
        showSubTitle
        foundResultAmount={reportPage?.content.length || 0}
        totalElementAmount={reportPage?.pagination.totalElements}
        renderRight={
          <Stack direction="row" alignItems="center" justifyContent="center" gap="16px" paddingTop={2.5}>
            <Report.SearchInput
              form={form}
              name="keyword"
              onSearch={() => {
                setSearchParams((prev) => ({ ...prev, keyword: form.getValues('keyword') }));
                resetPage();
              }}
            />
          </Stack>
        }
      />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue, filterConfig) => {
          setSearchParams((prev) => ({
            ...prev,
            patientTypes: filterValue.PATIENT_TYPE,
            status: filterValue.APPOINTMENT_STATUS,
          }));
          setFilterReportOptions([
            { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            {
              title: 'serviceTypes',
              value: type ? intl.formatMessage({ id: type?.label }) : allOptions,
            },
            {
              title: DEFAULT_CONFIG.APPOINTMENT_STATUS.title,
              value:
                filterConfig.APPOINTMENT_STATUS?.length === 0
                  ? allOptions
                  : filterConfig.APPOINTMENT_STATUS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
            {
              title: DEFAULT_CONFIG.PATIENT_TYPE.title,
              value:
                filterConfig.PATIENT_TYPE?.length === 0
                  ? allOptions
                  : filterConfig.PATIENT_TYPE.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
            {
              title: DEFAULT_CONFIG.APPOINTMENT_CHART_TYPE.title,
              value:
                filterConfig.APPOINTMENT_CHART_TYPE?.length === 0
                  ? allOptions
                  : filterConfig.APPOINTMENT_CHART_TYPE.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
          ]);
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                onChange={resetPage}
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
                onChange={resetPage}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                minDate={moment(fromDate).add(+1, 'day')}
                onChange={resetPage}
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() => {
                    form.reset();
                    setSearchParams(DEFAULT_VALUES);
                  }}
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <Report.SelectColumnButton
              columns={COLUMNS}
              onSave={setSelectedColumn}
              localStorageKey={SELECT_COLUMNS_KEY}
            />
            <Report.ExportDataButton exportUrl={exportUrl} />
            <Report.PrintDataButton
              title="report.workReport.tab.title.appointmentReport"
              openDialog={openPrintDialog}
              onOpenDialog={onOpenPrintDialog}
              onCloseDialog={onClosePrintDialog}
              content={
                <Stack direction="column" gap={1}>
                  <Report.FilterPrintFormat filterOptions={filterReportOptions} />
                  <Table
                    data={printDataPage?.content}
                    columns={selectedColumns}
                    dataKey="id"
                    rowProps={{ sx: { pageBreakAfter: 'auto' } }}
                  />
                </Stack>
              }
            />
          </Stack>
        </Stack>
      </Filter>
      <Table
        columns={selectedColumns}
        dataKey="id"
        data={reportPage?.content}
        paginationProps={paginationProps}
        totalElements={reportPage?.pagination.totalElements}
        stickyHeader
        maxHeigth="100%"
      />
    </Stack>
  );
};

export default AppointmentsReportTable;
