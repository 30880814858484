import { Box } from '@mui/material';
import { useCreateMutate, useFetch, useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { PAYMENT_PLAN, THERAPY_REFERENCE_TYPE } from 'modules/common/constants';
import { Encounter, TherapyDefinition } from 'modules/schema';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import React, { useCallback } from 'react';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useForm } from 'react-hook-form';
import TherapyFilter from './TherapyFilter';
import TherapyTable from './TherapyTable';
import { EncounterContext } from '../../const';

interface Props {
  encounter: Encounter;
  readonly: boolean;
}

const TherapyBlock = ({ encounter, readonly }: Props) => {
  const formMethods = useForm();
  const { reset } = formMethods;
  const { intl, dispatch, appState } = useGeneralHook();

  const { data: therapyData, revalidate } = useFetch(
    API_SERVER.therapy.getTherapyByReference(encounter?.id!, { type: THERAPY_REFERENCE_TYPE.ENCOUNTER }),
    { enabled: encounter?.id },
  );

  const encounterContext = React.useContext(EncounterContext);
  // When the dialog is open check counter > 0 then call the API get encounter data and reset the counter.
  React.useEffect(() => {
    if (encounterContext?.counter! > 0) {
      encounterContext?.revalidateEncounter();
      encounterContext?.setCounter(0);
    }
  }, [encounterContext]);

  const createTherapyMutate = useCreateMutate({
    onSuccess: revalidate,
  });
  const addTherapy = useCallback(
    async (therapyDefinition: TherapyDefinition) => {
      dispatch(setLoading(true));
      createTherapyMutate({
        url: API_SERVER.therapy.assignmentReference(),
        method: 'post',
        data: {
          referenceId: encounter?.id,
          type: THERAPY_REFERENCE_TYPE.ENCOUNTER,
          patientId: encounter?.patient?.id,
          userId: encounter?.picUserId,
          therapyDefinitionId: therapyDefinition?.id,
          diagnosis: encounterContext?.encounter?.differentialDiagnosis,
          paymentPlan: PAYMENT_PLAN[encounter?.paymentPlan!]?.value,
        },
      });
      reset();
    },
    [
      dispatch,
      createTherapyMutate,
      encounter?.id,
      encounter?.patient?.id,
      encounter?.picUserId,
      encounter?.paymentPlan,
      encounterContext?.encounter?.differentialDiagnosis,
      reset,
    ],
  );

  // huy lieu trinh
  const cancelTherapyMutate = useUpdateMutate({
    onSuccess: revalidate,
  });
  const cancelTherapy = useCallback(
    async (value) => {
      cancelTherapyMutate(
        {
          url: API_SERVER.therapy.cancel(value.id),
          method: 'put',
          data: {
            data: value?.note,
          },
        },
        { confirmMessage: intl.formatMessage({ id: 'therapy.therapyDetail.deleteAction.confirmMessage.therapy' }) },
      );
    },
    [cancelTherapyMutate, intl],
  );

  return (
    <Box width={'100%'}>
      <Box padding={1}>
        <TherapyFilter addTherapy={addTherapy} readonly={readonly} />
      </Box>
      <Box padding={1}>
        <TherapyTable
          data={therapyData?.sort((a, b) => a.id! - b.id!) || []}
          readOnly={readonly}
          isAuthor={appState.authen.user?.user_id === encounter?.picUserId}
          cancelTherapy={cancelTherapy}
          revalidate={revalidate}
        />
      </Box>
    </Box>
  );
};

export default TherapyBlock;
