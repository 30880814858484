import { APPOINTMENT_CHART_TYPE, PATIENT_TYPE } from 'modules/common/apiConstants';
import { FilterType } from 'modules/common/component/filter/type';
import { APPOINTMENT_STATUS, BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { formatStartDate } from 'modules/common/utils';
import moment from 'moment';

export const DEFAULT_VALUES = {
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatStartDate(moment().add(+1, 'day')),
  keyword: '',
};

export const FILTER_CONFIGS: FilterType[] = [
  {
    type: 'APPOINTMENT_STATUS',
    options: [APPOINTMENT_STATUS.BOOKED, APPOINTMENT_STATUS.CANCELLED],
    xs: 6,
  },
  {
    type: 'PATIENT_TYPE',
    options: [PATIENT_TYPE.RE_EXAMINATION, PATIENT_TYPE.NEW],
    xs: 6,
  },
  {
    type: 'APPOINTMENT_CHART_TYPE',
    options: [APPOINTMENT_CHART_TYPE.EXAM, APPOINTMENT_CHART_TYPE.THERAPY],
  },
];
