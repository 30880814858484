import { Box, Grid, Skeleton, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Encounter, IndicationRequest } from 'modules/schema';
import moment from 'moment';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';

interface Props {
  data?: IndicationRequest;
  encounter?: Encounter;
}

const PatientInfoBox: React.FunctionComponent<Props> = ({ data, encounter }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xl={1.5} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="encounterId" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? data.referenceCode : <Skeleton width={100} />}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="patientCode" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? encounter?.patient?.formatCode || data?.patientId : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>{' '}
      <Grid item xl={2} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="birthdayShort" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? (
              moment(encounter?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="age_" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter &&
              encounter?.patient?.dob &&
              `${moment()?.diff(encounter?.patient?.dob, 'years')} (${
                moment()?.diff(encounter?.patient?.dob, 'months') % 12
              })`}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={2} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="gender" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? (
              encounter?.patient?.gender && <FormattedMessage id={encounter.patient.gender} />
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="telephone" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? encounter?.patient?.mobilePhone : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={2} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="address" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter?.patient ? encounter?.patient?.homeAddress?.address : <Skeleton width={100} />}{' '}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="category" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter && encounter?.paymentPlan ? (
              <FormattedMessage id={PAYMENT_PLAN?.[encounter?.paymentPlan]?.label || ' '} />
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={4} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="indication" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? data?.serviceName : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PatientInfoBox;
