import { Close } from '@mui/icons-material';
import { Box, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import SchemaForm from '../../../../common/SchemaForm';
import { receivePatientFormSchema } from './schema';
import { Encounter } from 'modules/schema';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { GroupOption, Option } from 'modules/common/type';
import { formatEncounterData } from './utils';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';

interface Props {
  open: boolean;
  close(): void;
  encounter: Encounter;
  systemCategoryOptions: Option[];
  serviceOptions: (GroupOption & { price: number; systemCategoryId: number; parentId: number })[];
}

const EncounterContent: React.FunctionComponent<Props> = ({
  close,
  encounter,
  systemCategoryOptions,
  serviceOptions,
}) => {
  const methodsForm = useForm();
  const { appState, intl } = useGeneralHook();
  return (
    <>
      <Box overflow="auto">
        <DialogTitle sx={{ bgcolor: 'primary.50' }} style={{ position: 'sticky', top: 0, padding: 16, zIndex: 2 }}>
          <Box>
            <Typography variant="h6" color="primary">
              <FormattedMessage id={'encounterList.detail'} />
            </Typography>
          </Box>
          <Box position="absolute" top={6} right={0} marginX={2} marginY={1}>
            <IconButton onClick={close} size="small">
              <Close fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <SchemaForm
          methods={methodsForm}
          schema={receivePatientFormSchema}
          hideSubmitButton
          formData={formatEncounterData(encounter, appState, systemCategoryOptions, serviceOptions, intl)}
          formId="encounter-detail"
          readOnly
          noHelperText
        />
      </Box>
    </>
  );
};

const EncounterDialog: React.FunctionComponent<Props> = (props) => {
  const { open, close, encounter, serviceOptions, systemCategoryOptions } = props;

  const { data: fullEncounterData } = useFetch(API_SERVER.encounter.get(encounter.id!), { globalLoading: true });
  return (
    <Dialog
      open={open}
      onClose={close}
      maxWidth="md"
      keepMounted={false}
      PaperProps={{
        style: { position: 'relative', overflow: 'hidden' },
      }}
    >
      {fullEncounterData && (
        <EncounterContent
          close={close}
          encounter={fullEncounterData}
          open={open}
          serviceOptions={serviceOptions}
          systemCategoryOptions={systemCategoryOptions}
        />
      )}
    </Dialog>
  );
};

export default EncounterDialog;
