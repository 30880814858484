import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import DialogCustom from '../../../../common/component/DialogCustom';
import { some } from '../../../../common/constants';
import SchemaForm from '../../../../common/SchemaForm';
import { importExchangeUnitSchema } from './schema';
import { PriorityHighIcon, UploadExchangeIcon } from '../../../../../svg';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
  onDownLoadFile?: () => void;
  onUploadFile: (file: File | null) => void;
  showSuccessMessage?: boolean;
  showErrorMessage?: boolean;
  showErrorFileMax?: boolean;
  onShowErrorFileMax: () => void;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose, onDownLoadFile } = props;
  return (
    <>
      <SchemaForm
        schema={importExchangeUnitSchema}
        onSubmit={onSubmit}
        formData={formData}
        onCancel={onClose}
        formId="import-exchange"
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
        hideSubmitButton
        onDownLoadFile={onDownLoadFile}
      />
    </>
  );
};

const PatientManagementFormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, onUploadFile, showSuccessMessage, showErrorMessage, showErrorFileMax, onShowErrorFileMax } =
    props;
  const intl = useIntl();
  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 910 },
      }}
      title={
        <Typography variant="h5" color="primary">
          <FormattedMessage id="patient.management.dialog.importTitle" />
        </Typography>
      }
    >
      <Box padding={2}>
        <Grid container spacing={1}>
          <Grid item xs={1.5} textAlign={'center'}>
            <PriorityHighIcon color={'primary'} style={{ width: 50, height: 60, marginTop: 90, marginLeft: 25 }} />
          </Grid>
          <Grid item xs={10.5}>
            <Typography variant="h6" paddingTop={2}>
              <FormattedMessage id="dugDatabase.importUnitExchange.message.instructTitle" />
            </Typography>
            <Typography
              variant="subtitle1"
              paddingTop={1}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'patient.management.dialog.instructStep' }),
              }}
            ></Typography>
            <Typography
              variant="subtitle1"
              paddingTop={2}
              dangerouslySetInnerHTML={{
                __html: intl.formatMessage({ id: 'dugDatabase.importUnitExchange.message.instructSystem' }),
              }}
            ></Typography>
          </Grid>
        </Grid>
        <Box padding={1}>
          {!!showSuccessMessage && (
            <Typography variant="subtitle1" paddingTop={2} style={{ color: '#43A047', textAlign: 'center' }}>
              <FormattedMessage id="patient.management.dialog.instructSuccessMessage" />
            </Typography>
          )}
          {!!showErrorMessage && (
            <Typography variant="subtitle1" paddingTop={2} style={{ color: '#FF0000', textAlign: 'center' }}>
              <FormattedMessage id="patient.management.dialog.instructErrorMessage" />
            </Typography>
          )}
          {!!showErrorFileMax && (
            <Typography variant="subtitle1" paddingTop={2} style={{ color: '#FF0000', textAlign: 'center' }}>
              <FormattedMessage id="patient.management.dialog.instructFileMaxLength" />
            </Typography>
          )}
        </Box>
        <Box padding={1} paddingTop={4} style={{ display: 'flex', justifyContent: 'end' }}>
          <Button
            startIcon={<UploadExchangeIcon />}
            component="label"
            variant="text"
            style={{ color: '#FFFFFF', backgroundColor: '#0052E0', marginRight: 40 }}
          >
            <FormattedMessage id="dugDatabase.importUnitExchange.message.upload" />
            <input
              accept={'.xlsx'}
              hidden
              type="file"
              onChange={(event) => {
                const files = event.target.files;
                if (files && files?.length > 0) {
                  if (Array.from(files).every((file) => file.size <= 5000000)) {
                    onUploadFile(files.item(0));
                  } else {
                    onShowErrorFileMax();
                  }
                }
                //clear input file
                event.target.value = '';
              }}
            />
          </Button>
          <SchemaFormContent {...props} />
        </Box>
      </Box>
    </DialogCustom>
  );
};
export default PatientManagementFormDialog;
