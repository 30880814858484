import { Box, Button, Stack } from '@mui/material';
import Report from 'modules/common/component/report';
import Filter from 'modules/common/component/filter';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import DateInput from 'modules/common/component/form/DateInput';
import { useForm } from 'react-hook-form';
import moment, { Moment } from 'moment';
import { useFetch } from 'modules/common/hook';
import { TIME_REPORT_FILTER } from 'modules/common/component/report/consts';
import { formatEndDate, formatStartDate, getDateRangeList, isValidDate, maxDate } from 'modules/common/utils';
import { FILTER_CONFIGS, DEFAULT_VALUES, FILTER_KEY, FormSchemaChart } from './const';
import { FormattedMessage, useIntl } from 'react-intl';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'modules/common/constants';
import { groupBy, map, sum } from 'lodash';
import { Print } from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import { SALE_API } from 'modules/common/service';
import { SaleImportReport } from 'modules/schema';

interface DateRange {
  start: Moment;
  end: Moment;
}

function calculateTotalAmounts(dateRanges: DateRange[], data: SaleImportReport[]): number[] {
  return dateRanges.map((range) => {
    const total = data.reduce((sum, item) => {
      const importDate = moment(item.importDate, 'YYYY-MM-DD');
      if (importDate.isBetween(range.start, range.end, 'day', '[]')) {
        return sum + (item.totalAmount || 0);
      }
      return sum;
    }, 0);
    return total;
  });
}

function getWeeklyDateGroups(fromDate: Moment, toDate: Moment): DateRange[] {
  const result: DateRange[] = [];
  let start = fromDate.clone();

  while (start.isBefore(toDate)) {
    const end = moment.min(start.clone().endOf('week'), toDate);
    result.push({ start: start.clone(), end: end.clone() });

    start = end.clone().add(1, 'day');
  }

  return result;
}

interface Props {}
const ExpenseChart: React.FunctionComponent<Props> = (Props) => {
  const [searchParams, setSearchParams] = useState<FormSchemaChart>({
    fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
    toDate: formatEndDate(moment().add(+1, 'day')),
    groupReportType: TIME_REPORT_FILTER.DAY.value,
    type: [],
  });
  const form = useForm<FormSchemaChart & { timeReportType: keyof typeof TIME_REPORT_FILTER }>({
    defaultValues: {
      fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
      toDate: formatEndDate(moment().add(+1, 'day')),
      groupReportType: TIME_REPORT_FILTER.DAY.value,
      timeReportType: TIME_REPORT_FILTER.DAY.value,
      type: [],
    },
  });
  const intl = useIntl();
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate = formatEndDate(moment(toDateOrigin).add(-1, 'day'));
  const formData = form.getValues();
  const groupReportType = form.watch('groupReportType');

  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      fromDate,
      toDate,
    };
  }, [fromDate, searchParams, toDate]);
  const { data: reportData, isValidating } = useFetch(SALE_API.getExpenseList(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'TABLE'),
  });

  const refPrintComponent = useRef(null);
  const dateRangeList = useMemo(
    () =>
      groupReportType !== 'WEEK'
        ? getDateRangeList(
            moment(fromDate, BE_DATE_TIME_FORMAT),
            moment(toDate, BE_DATE_TIME_FORMAT),
            groupReportType === 'DAY' ? 'day' : 'month',
          ).map((date) => date.format(groupReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT))
        : getWeeklyDateGroups(moment(fromDate, BE_DATE_TIME_FORMAT), moment(toDate, BE_DATE_TIME_FORMAT)).map(
            (range) => `${range.start.format(FE_DATE_FORMAT)} - ${range.end.format(FE_DATE_FORMAT)}`,
          ),
    [fromDate, groupReportType, toDate],
  );

  const [finalTotalAmountData, setFinalTotalAmountData] = useState<number[]>([]);
  useEffect(() => {
    const timeFormat = groupReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT;
    if (!isValidating && reportData && isValidDate(fromDate, toDate, 'CHART')) {
      if (groupReportType !== 'WEEK') {
        const reportMap = groupBy(reportData, (item) => {
          const normalizedDate =
            groupReportType === 'DAY'
              ? moment(item.importDate, ['D/M/YYYY', 'DD/MM/YYYY']).format(timeFormat)
              : moment(item.importDate, ['M/YYYY', 'MM/YYYY']).format(timeFormat);
          return normalizedDate;
        });
        setFinalTotalAmountData(dateRangeList.map((date) => sum(reportMap[date]?.map((obj) => obj?.totalAmount)) || 0));
      } else {
        const weeklyGroups = getWeeklyDateGroups(
          moment(fromDate, BE_DATE_TIME_FORMAT),
          moment(toDate, BE_DATE_TIME_FORMAT),
        );
        setFinalTotalAmountData(calculateTotalAmounts(weeklyGroups, reportData || []));
      }
    }
  }, [dateRangeList, fromDate, isValidating, reportData, groupReportType, toDate]);

  useEffect(() => {
    if (groupReportType === 'DAY' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'month')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'month')
            .add(+1, 'day'),
        ),
      });
    }
    if (groupReportType === 'MONTH' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'year')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'year')
            .add(+1, 'day'),
        ),
      });
    }
    if (groupReportType === 'WEEK' && moment(fromDateOrigin) < moment(toDateOrigin).add(-3, 'months')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+3, 'months')
            .add(+1, 'day'),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateOrigin, groupReportType, toDateOrigin]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title title="report.import.expense.label" />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue, filterConfig) => {
          setSearchParams((prev) => ({
            ...prev,
            importBys: map(filterConfig.EMPLOYEE, 'employeeFullName'),
            medicationIds: filterValue.MEDICATION,
            medicationSuppliers: filterValue.MEDICAL_SUPPLIERS,
          }));
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
                required
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                maxDate={maxDate(groupReportType!, fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                format={FE_DATE_FORMAT}
                required
              />
              <Box marginTop={'-17px'}>
                <Report.DayMonthRadio
                  form={form}
                  name="groupReportType"
                  showWeek
                  onChange={(value) => {
                    if (value === 'DAY') {
                      form.reset({
                        fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment().add(+1, 'day')),
                        groupReportType: TIME_REPORT_FILTER.DAY.value,
                      });
                    }
                    if (value === 'MONTH') {
                      form.reset({
                        fromDate: moment().add(-1, 'year').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment()),
                        groupReportType: TIME_REPORT_FILTER.MONTH.value,
                      });
                    }
                    if (value === 'WEEK') {
                      form.reset({
                        fromDate: moment().add(-3, 'months').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment()),
                        groupReportType: TIME_REPORT_FILTER.WEEK.value,
                      });
                    }
                  }}
                />
              </Box>
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() =>
                    form.reset({
                      ...DEFAULT_VALUES,
                      toDate: formatEndDate(moment().add(+1, 'day')),
                    })
                  }
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
              <Filter.Button />
            </Stack>
            <Stack direction="column" gap={1}>
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" color="primary" startIcon={<Print />}>
                    <FormattedMessage id={'printReport'} />
                  </Button>
                )}
                content={() => refPrintComponent.current}
              />
            </Stack>
          </Stack>
        </Stack>
        <Report.ColumnChart
          refPrintComponent={refPrintComponent}
          yAxisTitle="report.chart.totalImportExpense.Title"
          xAxisTitle="inventory.importDate"
          categories={dateRangeList}
          formatYValue
          formatYAxis
          hiddenXTitle
          series={[
            {
              type: 'column',
              name: intl.formatMessage({ id: 'report.chart.allroducts.Title' }),
              data: finalTotalAmountData,
              color: '#3366FF',
            },
          ]}
        />
      </Filter>
    </Stack>
  );
};

export default ExpenseChart;
