import { z } from 'zod';

export const LaboratorySelectionSchema = z
  .object({
    id: z.number(),
    name: z.string(),
  })
  .partial();

export type LaboratorySelection = z.infer<typeof LaboratorySelectionSchema>;
