import { Add, Check, Delete } from '@mui/icons-material';
import { Box, Button, Paper } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import { axiosThunk } from 'modules/common/redux/axios';
import { MedicalExaminationForm } from 'modules/schema/MedicalExaminationForm';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import TableCustom from '../../../common/component/TableCustom';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { setLoading } from '../../../common/redux/commonReducer';
import FormEncounter from './FormEncounter';
import { useFetch } from 'modules/common/hook';

interface IFormListProps {}

const initialFormState: MedicalExaminationForm = {
  id: 0,
  name: '',
  symptom: '',
  illnessesHistory: '',
  provisionalDiagnosis: '',
  differentialDiagnosis: '',
  medicalHistory: '',
  note: '',
};

const FormList: React.FunctionComponent<IFormListProps> = () => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const [formData, setFormData] = React.useState<MedicalExaminationForm>(initialFormState);

  const { data, revalidate } = useFetch(API_SERVER.medicalExaminationForm.getList());

  const onDelete = React.useCallback(
    async (value: MedicalExaminationForm) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(false));
          const url = API_SERVER.medicalExaminationForm.delete(value.id || 0);

          await dispatch(axiosThunk({ url, method: 'delete' }));
          revalidate();
          value?.id === formData.id && setFormData(initialFormState);
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [close, dispatch, formData.id, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const onSubmit = React.useCallback(
    async (value: MedicalExaminationForm) => {
      const method = value.id ? 'put' : 'post';
      const url = value.id
        ? API_SERVER.medicalExaminationForm.update(value.id)
        : API_SERVER.medicalExaminationForm.create();
      const successMess = value.id ? 'updateSuccess' : 'createSuccess';
      const failMess = value.id ? 'updateFail' : 'createFail';
      try {
        dispatch(setLoading(false));
        await dispatch(axiosThunk({ url, method, data: value }));
        setFormData(value);
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: successMess }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: failMess }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <>
      <Paper style={{ padding: 16, flex: 1, height: '100%', overflow: 'auto', marginRight: 16 }}>
        <TableCustom
          dataSource={data}
          caption
          columns={[
            {
              title: 'report.name',
              dataIndex: 'name',
            },
            {
              title: 'action',
              align: 'right',
              minWidth: 120,
              render: (record: MedicalExaminationForm) => {
                return (
                  <Box display={'flex'} alignItems="center" gap={1}>
                    {formData?.id === record?.id && <Check color="success" />}
                    <IconButtonTitle
                      size="small"
                      title="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(record);
                      }}
                    >
                      <Delete />
                    </IconButtonTitle>
                  </Box>
                );
              },
            },
          ]}
          rowProps={(record) => ({
            onClick: async () => {
              setFormData(record);
            },
            style: { cursor: 'pointer' },
          })}
        />
        <Box position="sticky" zIndex={2} bgcolor={'background.paper'} bottom={0} top={'unset'}>
          <Button
            variant="text"
            color="primary"
            fullWidth
            startIcon={<Add />}
            style={{ marginTop: 8 }}
            onClick={() => setFormData(initialFormState)}
          >
            <FormattedMessage id="add" />
          </Button>
        </Box>
      </Paper>
      <FormEncounter formData={formData} onSubmit={onSubmit} listTemplate={data} />
    </>
  );
};

export default FormList;
