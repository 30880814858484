import { Box, Grid, Skeleton, Typography } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import { PATIENT_ADDRESS_TYPE } from 'modules/common/apiConstants';
import { Encounter, IndicationRequest } from 'modules/schema';

interface Props {
  data?: Encounter;
  indicationRequest?: IndicationRequest;
}

const PatientInfoBox: React.FunctionComponent<Props> = (props) => {
  const { data, indicationRequest } = props;
  return (
    <Grid container spacing={1}>
      <Grid item xl={1.5} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="encounterId" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? data.code : <Skeleton width={100} />}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="patientCode" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? data.patient?.formatCode : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>{' '}
      <Grid item xl={2} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="birthdayShort" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? moment(data.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT) : <Skeleton width={100} />}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="age_" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? (
              data.patient?.dob &&
              `${moment().diff(data.patient?.dob, 'years', false)} (${
                moment().diff(data.patient?.dob, 'months', false) % 12
              })`
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={2} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="gender" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? data.patient?.gender && <FormattedMessage id={data.patient.gender} /> : <Skeleton width={100} />}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="telephone" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? data.patient?.mobilePhone : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={2} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="address" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? (
              data.patient?.patientAddressList?.find((ele) => ele.type === PATIENT_ADDRESS_TYPE.HOME)?.address
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="category" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {data ? <FormattedMessage id={PAYMENT_PLAN[data?.paymentPlan!]?.label || ' '} /> : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={4} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="indication" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {indicationRequest ? indicationRequest.serviceName : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export default PatientInfoBox;
