import { useIntl } from 'react-intl';
import { FilterType } from './type';
import Employee from './type/Employee';
import FilterGroup from './FilterGroup';
import Icd10 from './type/Icd10';
import MedicalSupplyGroup from './type/MedicalSupplyGroup';
import CheckboxFilter from './CheckboxFilter';
import MedicationCategory from './type/MedicationCategory';
import Service from './type/Service';
import MedicationSuppliers from './type/MedicationSuppliers';
import Medication from './type/Medication';

const FilterPopupItem = ({ filter }: { filter: FilterType }) => {
  const intl = useIntl();
  switch (filter.type) {
    case 'EMPLOYEE': {
      return (
        <FilterGroup title={filter.title!}>
          <Employee filter={filter} />
        </FilterGroup>
      );
    }
    case 'SERVICE': {
      return (
        <FilterGroup title={filter.title!}>
          <Service filter={filter} />
        </FilterGroup>
      );
    }
    case 'ICD10': {
      return (
        <FilterGroup title={filter.title!}>
          <Icd10 filter={filter} />
        </FilterGroup>
      );
    }
    case 'MEDICAL_SUPPLY_GROUP': {
      return (
        <FilterGroup title={filter.title!}>
          <MedicalSupplyGroup filter={filter} />
        </FilterGroup>
      );
    }
    case 'MEDICATION_CATEGORY': {
      return (
        <FilterGroup title={filter.title!}>
          <MedicationCategory filter={filter} />
        </FilterGroup>
      );
    }
    case 'MEDICAL_SUPPLIERS': {
      return (
        <FilterGroup title={filter.title!}>
          <MedicationSuppliers filter={filter} />
        </FilterGroup>
      );
    }
    case 'MEDICATION': {
      return (
        <FilterGroup title={filter.title!}>
          <Medication filter={filter} />
        </FilterGroup>
      );
    }
    case 'INDICATION_REQUEST_STATUS':
    case 'ENCOUNTER_STATUS':
    case 'DEBT_STATUS':
    case 'PAYMENT_METHODS':
    case 'INDICATION_REQUEST_SCOPE':
    case 'APPOINTMENT_STATUS':
    case 'PATIENT_TYPE':
    case 'APPOINTMENT_PATIENT_TYPE':
    case 'PAYMENT_STATUS':
    case 'TRANSACTION_TYPE':
    case 'PAYMENT_PLAN': {
      return (
        <CheckboxFilter
          title={filter.title!}
          name={filter.type}
          getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
          xs={filter.xs}
        />
      );
    }
    default:
      return null;
  }
};

export default FilterPopupItem;
