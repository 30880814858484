import PrintDialogContent from './PrintDialogContent';
import DialogCustom from '../../../../../common/component/DialogCustom';
import { some } from '../../../../../common/constants';

interface Props {
  open: boolean;
  onClose: () => void;
  therapy: some;
  therapySession: some;
  indicationList: some[];
}

const IndicationRequestPrintForm = ({ open, onClose, therapy, therapySession, indicationList }: Props) => {
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="therapy.exam.therapyDetail.label.printForm.indicationRequest.title"
      PaperProps={{
        style: { width: '70vw' },
      }}
      maxWidth="xl"
    >
      <PrintDialogContent
        onClose={onClose}
        therapy={therapy}
        therapySession={therapySession}
        indicationList={indicationList}
      />
    </DialogCustom>
  );
};

export default IndicationRequestPrintForm;
