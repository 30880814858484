import VisibilityIcon from '@mui/icons-material/Visibility';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../../common/api';
import DialogCustom from '../../../common/component/DialogCustom';
import { RawLink } from '../../../common/component/elements';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import TableCustom from '../../../common/component/TableCustom';
import { FE_TIME_DATE_FORMAT, INDICATION_REQUEST_STATUS, INDICATION_REQUEST_TYPE } from '../../../common/constants';
import { ROUTES } from '../../../layout/router';
import { Encounter, IndicationRequest } from 'modules/schema';
import { useFetch } from 'modules/common/hook';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';

interface Props {
  encounter: Encounter;
  indicationRequest: IndicationRequest;
}

const IndicationHistoryEncounterBox: React.FunctionComponent<Props> = ({ encounter, indicationRequest }) => {
  const [dataDetail, setData] = React.useState<IndicationRequest | undefined>();

  const { data: indicationHistory } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(
      encounter?.id!,
      INDICATION_REQUEST_TYPE.ENCOUNTER,
      SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
    ),
    { enabled: encounter?.id },
  );

  const { data: dataImageDetail } = useFetch(API_SERVER.indicationImage.get(dataDetail?.id || 0), {
    enabled: dataDetail?.id,
  });

  return (
    <>
      <TableCustom
        loading={!indicationHistory}
        dataSource={indicationHistory?.filter((item) => item.status === INDICATION_REQUEST_STATUS.FINISHED.value)}
        columns={[
          {
            title: 'indicationDate',
            render: (record) => (record?.startTime ? record?.startTime.format(FE_TIME_DATE_FORMAT) : ''),
            width: '30%',
          },
          {
            title: 'encounterDetail.indicationName',
            dataIndex: 'serviceName',
            width: '60%',
          },
          {
            title: 'action',
            width: '10%',
            render: (record) => (
              <IconButtonTitle title="detail" size="small" onClick={() => setData(record)}>
                <VisibilityIcon />
              </IconButtonTitle>
            ),
          },
        ]}
      />
      <DialogCustom
        open={!!dataDetail}
        onClose={() => {
          setData(undefined);
        }}
        maxWidth="sm"
        title={
          <Typography variant="h6" color="primary">
            {dataDetail?.serviceName}
          </Typography>
        }
      >
        <Box padding={2}>
          <Typography variant="subtitle1">
            <FormattedMessage id="conclusion" />
          </Typography>
          <div className="mce-editor-customs" dangerouslySetInnerHTML={{ __html: dataImageDetail?.conclusion || '' }} />
        </Box>
        <Box
          position="sticky"
          zIndex={2}
          bgcolor={'background.paper'}
          bottom={0}
          top={'unset'}
          padding={2}
          display="flex"
          justifyContent="flex-end"
          style={{ background: 'white' }}
        >
          {dataDetail?.id && encounter?.patientId && encounter?.id && (
            <RawLink to={ROUTES.detailIndication.gen(dataDetail?.id, encounter?.patientId, encounter?.id)}>
              <Button variant="contained" color="primary" style={{ minWidth: 200 }}>
                <FormattedMessage id="seeIndication" />
              </Button>
            </RawLink>
          )}
        </Box>
      </DialogCustom>
    </>
  );
};

export default IndicationHistoryEncounterBox;
