import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../../common/component/DialogCustom';
import { some } from '../../../../common/constants';
import SchemaForm from '../../../../common/SchemaForm';

interface Props {
  open?: boolean;
  onClose?: () => void;
  formData?: some;
  onSubmit: (value: some) => void;
  readOnly?: boolean;
}

const NoteFormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData, readOnly, onSubmit } = props;

  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 682 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id="indication.addNewNote" />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaForm
          schema={{
            fields: ({ formProps }) => {
              const { intl } = formProps || {};
              return {
                note: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'note' }),
                  placeholder: intl.formatMessage({ id: 'noteEnter' }),
                  multiline: true,
                  rows: 8,
                  register: {
                    maxLength: {
                      value: 255,
                      message: intl.formatMessage({ id: 'common.maxLength.text' }, { length: '255' }),
                    },
                  },
                },
              };
            },
          }}
          readOnly={readOnly}
          onSubmit={onSubmit}
          formId="note-form"
          formData={formData}
        />
      </Box>
    </DialogCustom>
  );
};
export default NoteFormDialog;
