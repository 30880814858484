import { Box, Stack } from '@mui/material';
import DateInput from 'modules/common/component/form/DateInput';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Report from 'modules/common/component/report';
import { useDialog, useFetch } from 'modules/common/hook';
import Table, { TableOptions } from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { useIntl } from 'react-intl';
import { ReportFilterOption } from 'modules/common/component/report/type';
import { Pageable } from 'modules/common/type';
import { formatEndDate, formatStartDate, isValidDate, maxDateTable, NumericFormatText } from 'modules/common/utils';
import Filter from 'modules/common/component/filter';
import { SALE_API } from 'modules/common/service';
import { DEFAULT_VALUES, FILTER_CONFIGS, FILTER_KEY, FormSchema, SELECT_COLUMNS_KEY } from './const';
import { SaleImportReport } from 'modules/schema/SaleImportReport';
import { ExpenseDetailTableSub } from './ExpenseDetailTableSub';
import { map } from 'lodash';

interface Props {}

const COLUMNS: Column<SaleImportReport>[] = [
  {
    type: 'SUB_ROW',
    allowCollapse: true,
    width: '10px',
    collapseProps: {
      unmountOnExit: true,
    },
    render: (record) => <ExpenseDetailTableSub saleImport={record} />,
  },
  { type: 'INDEX', width: '30px', sticky: true },
  {
    title: 'inventory.importDate',
    fieldName: 'importDate',
    render: (record) => record.importDate?.format(FE_DATE_FORMAT),
    selectColumnConfig: {
      disabled: true,
      defaultChecked: true,
    },
  },
  {
    title: 'sale.report.expense.title.code.import',
    fieldName: 'importCode',
    render: (record) => record?.importCode,
    selectColumnConfig: {
      disabled: true,
      defaultChecked: true,
    },
  },
  {
    title: 'inventory.warehouser',
    fieldName: 'importBy',
    render: 'importBy',
    selectColumnConfig: {
      disabled: true,
      defaultChecked: true,
    },
    enableEllipsis: true,
  },
  {
    title: 'totalAmount',
    fieldName: 'totalAmount',
    render: (record) => NumericFormatText(record.totalAmount || 0),
    selectColumnConfig: {
      disabled: false,
      defaultChecked: true,
    },
  },
];

const ExpenseTable: React.FunctionComponent<Props> = () => {
  const [filterReportOptions, setFilterReportOptions] = React.useState<ReportFilterOption[]>([]);
  const [summaryData, setSummaryData] = useState<SaleImportReport>({});
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const { page, pageSize, paginationProps, resetPage } = useTablePagination();
  const intl = useIntl();
  const form = useForm<FormSchema>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate =
    moment(form.watch('toDate')) < moment()
      ? formatEndDate(moment(form.watch('toDate')).add(-1, 'day'))
      : formatEndDate(moment());
  const allOptions = intl.formatMessage({ id: 'all' });

  const [selectedColumns, setSelectedColumn] = React.useState<Column<SaleImportReport>[]>(
    COLUMNS?.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );
  const [searchParams, setSearchParams] = React.useState<
    {
      fromDate: string;
      toDate: string;
      keyword: string;
    } & Pageable
  >(DEFAULT_VALUES);

  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      fromDate,
      toDate,
      page: page,
      pageSize: pageSize,
    };
  }, [fromDate, page, pageSize, searchParams, toDate]);
  const { data: reportPage, isValidating } = useFetch(SALE_API.getExpensePaging(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'TABLE'),
  });

  const { data: printDataPage } = useFetch(SALE_API.getExpenseList({ ...formatSearchParams }), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: openPrintDialog && isValidDate(fromDate, toDate, 'TABLE'),
  });
  useEffect(() => {
    if (reportPage && reportPage.summary && Object.keys(reportPage.summary).length > 0 && !isValidating) {
      setSummaryData(reportPage?.summary);
    }
  }, [reportPage, isValidating]);

  useEffect(() => {
    setFilterReportOptions([
      { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      {
        title: 'inventory.warehouser',
        value: allOptions,
      },
      {
        title: 'supplierLabel',
        value: allOptions,
      },
      {
        title: 'productLabel',
        value: allOptions,
      },
    ]);
  }, [fromDate, toDate, allOptions]);

  const TABLE_OPTIONS = useMemo(() => {
    let tableOptions: TableOptions = {
      position: 'top',
      startIndex: COLUMNS.length === selectedColumns.length ? 4 : 0,
      useRender: ['debtAmount'],
      record: summaryData,
    };
    return tableOptions;
  }, [summaryData, selectedColumns.length]);
  const exportUrl = React.useMemo(() => SALE_API.getExpenseExcel(formatSearchParams), [formatSearchParams]);
  return (
    <Stack direction="column" gap={2}>
      <Report.Title
        title="report.import.expense.label"
        showSubTitle
        foundResultAmount={reportPage?.content.length || 0}
        totalElementAmount={reportPage?.pagination.totalElements}
        renderRight={
          <Stack direction="row" alignItems="center" justifyContent="center" gap="16px" paddingTop={2.5}>
            <Report.SearchInput
              form={form}
              name="keyword"
              onSearch={() => {
                setSearchParams((prev) => ({ ...prev, keyword: form.getValues('keyword') }));
                resetPage();
              }}
              placeholder="sale.report.search.input"
            />
          </Stack>
        }
      />
      <Filter
        filters={FILTER_CONFIGS}
        popupWidth="600px"
        onSave={(filterValue, filterConfig) => {
          if (fromDate && toDate) {
            setSearchParams((prev: any) => ({
              ...prev,
              importBys: map(filterConfig.EMPLOYEE, 'employeeFullName'),
              medicationIds: filterValue.MEDICATION,
              medicationSuppliers: filterValue.MEDICAL_SUPPLIERS,
            }));
            resetPage();
          }
          setFilterReportOptions([
            { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            {
              title: 'inventory.warehouser',
              value:
                filterConfig.EMPLOYEE?.length === 0
                  ? allOptions
                  : filterConfig.EMPLOYEE.map((ele) => ele.employeeFullName).join(', '),
            },
            {
              title: 'supplierLabel',
              value:
                filterConfig.MEDICAL_SUPPLIERS?.length === 0
                  ? allOptions
                  : filterConfig.MEDICAL_SUPPLIERS.map((ele) => ele.name).join(', '),
            },
            {
              title: 'productLabel',
              value:
                filterConfig.MEDICATION?.length === 0
                  ? allOptions
                  : filterConfig.MEDICATION.map((ele) => ele.name).join(', '),
            },
          ]);
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
                type="TABLE"
                onChange={resetPage}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                maxDate={maxDateTable(fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                type="TABLE"
                onChange={resetPage}
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() => {
                    form.reset();
                    setSearchParams(DEFAULT_VALUES);
                  }}
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <Report.SelectColumnButton
              columns={COLUMNS}
              onSave={setSelectedColumn}
              localStorageKey={SELECT_COLUMNS_KEY}
            />
            <Report.ExportDataButton exportUrl={exportUrl} />
            <Report.PrintDataButton
              title="report.import.expense.label"
              openDialog={openPrintDialog}
              onOpenDialog={onOpenPrintDialog}
              onCloseDialog={onClosePrintDialog}
              content={
                <Stack direction="column" gap={1}>
                  <Report.FilterPrintFormat filterOptions={filterReportOptions} />
                  <Table
                    data={printDataPage}
                    columns={selectedColumns}
                    dataKey="id"
                    rowProps={{ sx: { pageBreakAfter: 'auto' } }}
                  />
                </Stack>
              }
            />
          </Stack>
        </Stack>
      </Filter>
      <Table
        columns={selectedColumns}
        options={TABLE_OPTIONS}
        dataKey="id"
        data={reportPage?.content}
        paginationProps={paginationProps}
        totalElements={reportPage?.pagination.totalElements}
        stickyHeader
        maxHeigth="100%"
      />
    </Stack>
  );
};

export default ExpenseTable;
