import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Collapse } from '@mui/material';
import { Box } from '@mui/system';
import { push } from 'connected-react-router';
import { get } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { TableCustomCell, TableCustomRow } from '../../../common/component/TableCustom/element';
import { FE_DATE_FORMAT, INDICATION_REQUEST_STATUS, some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { ROUTES } from '../../../layout/router';
import DocumentDialog from './DocumentDialog';
import { API_SERVER } from 'modules/common/api';
import { Encounter, IndicationRequest } from 'modules/schema';
import { useFetch } from 'modules/common/hook';

interface Props {
  encounter: Encounter;
  columns: Columns<Encounter>[];
}
const EncounterRow: React.FunctionComponent<Props> = (props) => {
  const { encounter, columns } = props;
  const { dispatch } = useGeneralHook();
  const [open, setOpen] = React.useState(false);
  const [dataDetail, setDataDetail] = React.useState<some | undefined>(undefined);

  const { data: indicationRequests, isValidating } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(encounter?.id!, 'ENCOUNTER', 'RADIOLOGY_SERVICE'),
    { enabled: open },
  );

  const INDICATION_COLUMNS = React.useMemo(
    () =>
      [
        {
          title: 'labDoctor.testName',
          dataIndex: 'serviceName',
        },
        {
          title: 'indicationDate',
          render: (record) => record.createdTime && record?.createdTime?.format(FE_DATE_FORMAT),
        },
        {
          title: 'doctorOfIndication',
          dataIndex: 'picName',
        },
        {
          title: 'interpreterOfIndication',
          dataIndex: 'executeName',
        },
        {
          title: 'status',
          dataIndex: 'status',
          render: (record) => (
            <Box component="span" color={INDICATION_REQUEST_STATUS[record.status!].color}>
              <FormattedMessage id={`${INDICATION_REQUEST_STATUS[record.status!].label}`} />
            </Box>
          ),
        },
      ] as Columns<IndicationRequest>[],
    [],
  );

  return (
    <>
      <TableCustomRow>
        <TableCustomCell style={{ width: 50 }}>
          <IconButtonTitle aria-label="expand data" size="small" onClick={() => setOpen(!open)}>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButtonTitle>
        </TableCustomCell>
        {columns.map((column, index) => {
          return (
            <TableCustomCell key={index} align={column.align}>
              {column.render ? column.render(encounter, index) : get(encounter, column.dataIndex || '')}
            </TableCustomCell>
          );
        })}
        <TableCustomCell>{encounter?.indicationRequestQuantity || 0}</TableCustomCell>
      </TableCustomRow>
      <TableCustomRow style={{ padding: 0 }}>
        <TableCustomCell style={{ padding: 0, border: 'none' }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} bgcolor="white">
              <TableCustom
                loading={isValidating}
                dataSource={indicationRequests?.filter((item) => item.status !== INDICATION_REQUEST_STATUS.WAIT.value)}
                containerProps={{
                  sx: {
                    '& th,tr,td': {
                      padding: '4px 8px',
                      backgroundColor: 'unset',
                      '&:hover': {
                        backgroundColor: 'unset',
                      },
                    },
                    overflow: 'visible',
                    minWidth: 220,
                  },
                }}
                rowProps={(record) => ({
                  onClick: async (e) => {
                    dispatch(push(ROUTES.detailIndication.gen(record.id!, encounter?.patientId!, encounter?.id!)));
                  },
                  style: { cursor: 'pointer' },
                })}
                columns={INDICATION_COLUMNS}
              />
            </Box>
          </Collapse>
        </TableCustomCell>
      </TableCustomRow>
      {dataDetail && (
        <DocumentDialog
          data={dataDetail}
          open={true}
          onClose={() => {
            setDataDetail(undefined);
          }}
        />
      )}
    </>
  );
};
export default EncounterRow;
