import { CameraAlt, Close, Delete, Print } from '@mui/icons-material';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Webcam from 'react-webcam';
import { TwinCameraIcon } from 'svg';
import ImageGalleryDialog from 'modules/common/component/ImageGalleryDialog';
import MediCard from 'modules/common/component/MediCard';
import NoDataBox from 'modules/common/component/NoDataBox';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import FormControlAutoComplete from 'modules/common/SchemaForm/element/autocomplete/FormControlAutoComplete';
import SelectCustom from 'modules/common/SchemaForm/element/select/SelectCustom';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import ImagePreview from 'modules/common/component/ImagePreview';
import * as React from 'react';
import { useDiagnosisDialogSelector } from './state';
import { useFetchDentalExam, useFetchStorageResource } from '../../common/fetcher';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';

interface Image {
  imageSrc?: string;
  id?: string;
  description?: string;
  checked?: boolean;
  deleted?: boolean;
}

const ContentBox = () => {
  const { dentalSessionId } = useParams<{ patientId; dentalSessionId }>();
  const selectedDiagnosisImage = useDiagnosisDialogSelector.use.selectedDiagnosisImage();
  const onOpenPrintFormDialog = useDiagnosisDialogSelector.use.onOpenPrintFormDialog();
  const onCloseCameraDialog = useDiagnosisDialogSelector.use.onCloseDiagnosisImageDialog();
  const addDiagnosisImage = useDiagnosisDialogSelector.use.addDiagnosisImage();
  const updateDiagnosisImage = useDiagnosisDialogSelector.use.updateDiagnosisImage();
  const setSelectedDiagnosisImage = useDiagnosisDialogSelector.use.setSelectedDiagnosisImage();

  // fetch
  const { data: dentalExam } = useFetchDentalExam(dentalSessionId);
  const { data: images } = useFetchStorageResource(selectedDiagnosisImage?.id, 'DENTAL_DIAGNOSIS_IMAGE_ALBUM');

  useEffect(() => {
    setSelectedDiagnosisImage(
      images?.map((ele) => ({ id: ele.id! + '', checked: true, deleted: false, description: ele.description })) || [],
    );
  }, [images, setSelectedDiagnosisImage]);

  const [indexImage, setIndexImage] = useState<undefined | number>(undefined);
  const { intl, confirmDialog } = useGeneralHook();
  const evaluationFormMap = useSelector((state: AppState) => state.common.evaluationForms);
  const methods = useForm<{
    images: Image[];
    examDepartment?: string;
    conclusion?: string;
    id?: number;
  }>({
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const { control, setValue, getValues, watch, formState } = methods;
  const { append } = useFieldArray({
    control: control,
    name: 'images',
    keyName: 'id',
  });

  const fields = watch('images') || [];

  useEffect(() => {
    if (selectedDiagnosisImage && selectedDiagnosisImage?.images?.length > 0) {
      setValue(
        'images',
        selectedDiagnosisImage?.images?.map((image) => ({
          id: image?.id,
          description: image?.description,
          checked: true,
          deleted: false,
          imageSrc: image?.imageSrc,
        })) || [],
      );
    }
  }, [selectedDiagnosisImage, setValue]);

  useEffect(() => {
    if (selectedDiagnosisImage) {
      setValue('id', selectedDiagnosisImage.id!);
      setValue('conclusion', selectedDiagnosisImage.conclusion);
      setValue('examDepartment', selectedDiagnosisImage.examDepartment);
    }
  }, [selectedDiagnosisImage, setValue]);

  const webcamRef = useRef<any>(null);

  const [automaticDuration, setAutomaticDuration] = useState(1000);
  const [automation, setAutomation] = useState(false);
  const refInterval = useRef<any>(null);

  const captureScreen = useCallback(() => {
    if (fields?.length >= 100) {
      refInterval.current && clearInterval(refInterval.current);
      setAutomation(false);
      return;
    }
    const imageSrc = webcamRef.current.getScreenshot();
    append({ imageSrc, checked: true });
  }, [append, fields?.length]);

  useEffect(() => {
    if (automation) {
      refInterval.current = setInterval(() => {
        captureScreen();
      }, automaticDuration);
    } else {
      refInterval.current && clearInterval(refInterval.current);
    }
    return () => {
      clearInterval(refInterval.current);
    };
  }, [automaticDuration, automation, captureScreen]);

  const onSubmitImageForm = useCallback(async () => {
    const data = getValues();
    if (data?.id) {
      updateDiagnosisImage(data);
    } else {
      addDiagnosisImage({
        ...data,
        createdTime: moment(),
        updatedTime: moment(),
      });
    }
    onCloseCameraDialog();
  }, [addDiagnosisImage, getValues, onCloseCameraDialog, updateDiagnosisImage]);

  const closeDialog = async () => {
    setAutomation(false);
    refInterval.current && clearInterval(refInterval.current);
    if (Object.values(formState.dirtyFields)?.length > 0 || formState.isDirty) {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        content: intl.formatMessage({ id: 'youHaveUnsavedData' }),
        cancelId: 'cancel',
        okId: 'ok',
      });

      if (confirm) {
        onCloseCameraDialog();
      }
      confirmDialog.close();
    } else {
      onCloseCameraDialog();
    }
  };

  return (
    <>
      <Box
        bgcolor="primary.50"
        style={{
          position: 'sticky',
          top: 0,
          padding: '12px 16px',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box flex={1}>
          <Typography variant="h6" color="primary">
            <FormattedMessage id={'camera.title'} />
          </Typography>
        </Box>
        <Box justifySelf="flex-end">
          <IconButton onClick={closeDialog} size="small" tabIndex={-1}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {selectedDiagnosisImage?.id && (
        <Box display="flex" gap={2} padding={2} paddingBottom={0}>
          <Box display="flex">
            <Typography variant="subtitle1">
              <FormattedMessage id="name" />
            </Typography>
            :&nbsp; <Typography variant="body1">{dentalExam?.patient?.name}</Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1">
              <FormattedMessage id="birthdayShort" />
            </Typography>
            :&nbsp;{' '}
            <Typography variant="body1">
              {moment(dentalExam?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1">
              <FormattedMessage id="gender" />
            </Typography>
            :&nbsp;{' '}
            <Typography variant="body1">
              {dentalExam?.patient?.gender && <FormattedMessage id={dentalExam?.patient?.gender?.toLowerCase()} />}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1">
              <FormattedMessage id="address" />
            </Typography>
            :&nbsp;{' '}
            <Typography variant="body1">
              {dentalExam?.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address}
            </Typography>
          </Box>
        </Box>
      )}
      <FormProvider {...methods}>
        <SchemaElement
          fieldName="id"
          propsElement={{
            type: 'hidden',
          }}
        />
        <Box
          component="form"
          bgcolor="white"
          padding={2}
          flex={1}
          display="flex"
          flexDirection="column"
          height={'calc(100% - 120px)'}
        >
          <Box
            padding={2}
            paddingRight={0}
            gap={2}
            display="flex"
            flex={1}
            overflow="hidden"
            border={1}
            borderColor="divider"
            borderRadius={2}
            height={'100%'}
          >
            <Box overflow="auto" display="flex" flexDirection={'column'} gap={2} width={220}>
              <Typography variant="subtitle1">
                <FormattedMessage id="camera.listImage" />
              </Typography>
              {fields?.length > 0 ? (
                fields.map((item, index) => {
                  if (item?.deleted) {
                    return null;
                  }
                  return (
                    <Box
                      position="relative"
                      key={item.id}
                      display="flex"
                      padding={1}
                      gap={1}
                      bgcolor="background.default"
                      borderRadius={0.5}
                    >
                      <Box flex={2}>
                        <Box style={{ height: 120, width: '100%', cursor: 'pointer' }}>
                          {item.id ? (
                            <ImagePreview
                              alt=""
                              imageId={parseInt(item.id)}
                              style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <Avatar
                              src={item.imageSrc}
                              alt=""
                              variant="rounded"
                              style={{ height: 120, width: '100%', cursor: 'pointer', objectFit: 'contain' }}
                              onClick={() => setIndexImage(index)}
                            />
                          )}
                        </Box>
                        <Box display="flex" marginTop={1}>
                          <Controller
                            name={`images.${index}.checked`}
                            control={control}
                            render={({ field: { onChange, value }, formState }) => {
                              return (
                                <Checkbox
                                  checked={fields[index].checked}
                                  onChange={onChange}
                                  style={{ height: 32, width: 32 }}
                                />
                              );
                            }}
                          />

                          <Controller
                            name={`images.${index}.description`}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  fullWidth
                                  placeholder={intl.formatMessage({ id: 'camera.position' })}
                                  value={item.description}
                                  onChange={onChange}
                                  multiline
                                  rows={1}
                                  InputProps={{
                                    style: { minHeight: 'unset' },
                                  }}
                                  inputProps={{
                                    style: { minHeight: 'unset' },
                                  }}
                                />
                              );
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <NoDataBox imageStyle={{ width: '100%' }} />
              )}
            </Box>
            {!selectedDiagnosisImage?.id && (
              <Box flex={1} overflow="auto">
                <Box position="relative" overflow="hidden" width="100%">
                  <Webcam width={'100%'} height={'100%'} ref={webcamRef} screenshotFormat="image/jpeg" />
                  <ButtonBase
                    sx={{
                      '&:hover': {
                        opacity: 1,
                      },
                      opacity: 0,
                      transition: 'all 0.3s',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      right: '0',
                      bottom: '0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={captureScreen}
                  >
                    <AddAPhotoOutlinedIcon style={{ color: 'white', width: 80, height: 'auto' }} />
                  </ButtonBase>
                </Box>
                <Box display="flex" marginTop={2}>
                  <Box flex={1}>
                    <Typography variant="body2">
                      <FormattedMessage id={'name'} />
                      :&nbsp;{' '}
                      <Typography variant="subtitle2" component="span">
                        {dentalExam?.patient?.name}
                      </Typography>
                    </Typography>
                    <Typography variant="body2">
                      <FormattedMessage id={'gender'} />
                      :&nbsp;{' '}
                      <Typography variant="subtitle2" component="span">
                        {dentalExam?.patient?.gender && (
                          <FormattedMessage id={dentalExam?.patient?.gender?.toLowerCase()} />
                        )}
                      </Typography>
                    </Typography>
                    <Typography variant="body2">
                      <FormattedMessage id={'birthdayShort'} />
                      :&nbsp;{' '}
                      <Typography variant="subtitle2" component="span">
                        {moment(dentalExam?.patient?.dob, BE_DATE_FORMAT)?.format(FE_DATE_FORMAT)}
                      </Typography>
                    </Typography>
                    <Typography variant="body2">
                      <FormattedMessage id={'address'} />
                      :&nbsp;{' '}
                      <Typography variant="subtitle2" component="span">
                        {
                          dentalExam?.patient?.patientAddressList?.find(
                            (ele) => ele?.type === PATIENT_ADDRESS_TYPE.home,
                          )?.address
                        }
                      </Typography>
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <Button
                      sx={{ height: 40 }}
                      fullWidth
                      startIcon={<CameraAlt />}
                      onClick={captureScreen}
                      size="large"
                    >
                      <FormattedMessage id="camera.takeAShot" />
                    </Button>
                    <Typography variant="subtitle1" marginTop={3} color="textSecondary">
                      <FormattedMessage id={'camera.setting'} />
                    </Typography>
                    <SelectCustom
                      disableClearBtn={true}
                      options={[
                        { value: 1000, label: 'camera.duration.1' },
                        { value: 2000, label: 'camera.duration.2' },
                        { value: 3000, label: 'camera.duration.3' },
                        { value: 4000, label: 'camera.duration.4' },
                        { value: 5000, label: 'camera.duration.5' },
                      ]}
                      value={automaticDuration}
                      onChange={(event) => {
                        setAutomaticDuration(event);
                      }}
                    />{' '}
                    <Button
                      fullWidth
                      startIcon={<TwinCameraIcon />}
                      color={automation ? 'error' : 'primary'}
                      sx={{ marginTop: 1.5, height: 40 }}
                      onClick={() => {
                        setAutomation((old) => !old);
                      }}
                    >
                      <FormattedMessage id={automation ? 'camera.stop' : 'camera.takeMultipleShot'} />
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            <MediCard title="result" sx={{ flex: 1 }}>
              <Box padding={2} display="flex" flexDirection="column" gap={1.5} overflow="auto">
                <FormControlAutoComplete
                  label={intl.formatMessage({ id: 'encounterForm.template' })}
                  placeholder={intl.formatMessage({ id: 'encounterForm.templateEnter' })}
                  options={Object.values(evaluationFormMap)}
                  getOptionLabel={(opt) => opt.name}
                  inputStyle={{ width: '75%' }}
                  disableClearable
                  onChange={async (_, data) => {
                    setValue('examDepartment', data.content, { shouldDirty: true });
                    setValue('conclusion', data.conclusion, { shouldDirty: true });
                  }}
                />
                <SchemaElement
                  fieldName="id"
                  propsElement={{
                    hidden: true,
                  }}
                />
                <SchemaElement
                  fieldName="examDepartment"
                  propsElement={{
                    type: 'text-editor',
                    label: intl.formatMessage({ id: 'report.parts' }),
                    placeholder: intl.formatMessage({ id: 'enter' }),
                    noHelperText: true,
                    height: 200,
                  }}
                />
                <SchemaElement
                  fieldName="conclusion"
                  propsElement={{
                    type: 'text-editor',
                    label: intl.formatMessage({ id: 'conclusion' }),
                    placeholder: intl.formatMessage({ id: 'enter' }),
                    noHelperText: true,
                    height: 200,
                  }}
                />
              </Box>
            </MediCard>
          </Box>

          <Box display="flex" justifyContent="flex-end" alignItems={'center'} marginTop={2} gap={2}>
            {selectedDiagnosisImage?.id && (
              <Button startIcon={<Print />} onClick={() => onOpenPrintFormDialog(selectedDiagnosisImage)}>
                <FormattedMessage id="report.imagePrint" />
              </Button>
            )}
            <>
              <FormControlLabel
                control={<Checkbox color="primary" style={{ padding: 2 }} />}
                label={<FormattedMessage id="camera.selectAll" />}
                checked={fields?.every((v) => v.checked === true)}
                onChange={(event: any) => {
                  const checked = event.target.checked;
                  setValue(
                    'images',
                    fields?.map((v) => ({ ...v, checked: checked })),
                    { shouldDirty: true },
                  );
                }}
              />
              <Button
                startIcon={<Delete />}
                disabled={fields?.filter((v) => v.checked && !v.deleted)?.length === 0}
                onClick={() => {
                  setValue(
                    'images',
                    fields
                      .map((v) => {
                        if (v.checked && v.id) {
                          return { ...v, deleted: true };
                        }
                        return v;
                      })
                      .filter((v) => !v.checked || v.id),
                    { shouldDirty: true },
                  );
                }}
                size="large"
              >
                <FormattedMessage id="camera.deleteSelected" />
              </Button>
            </>
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
              size="large"
            >
              <FormattedMessage id="cancel" />
            </Button>
            <Button type="button" size="large" onClick={onSubmitImageForm}>
              <FormattedMessage id="save" />
            </Button>
          </Box>
        </Box>
      </FormProvider>
      <ImageGalleryDialog
        open={indexImage !== undefined}
        onClose={() => setIndexImage(undefined)}
        images={fields.map((item: some) => ({
          render: item?.id ? (
            <ImagePreview
              alt=""
              imageId={item.id}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Avatar
              src={item.imageSrc}
              alt=""
              variant="rounded"
              style={{ height: '100%', width: '100%' }}
              imgProps={{ style: { objectFit: 'contain' } }}
            />
          ),
        }))}
        index={indexImage}
      />
    </>
  );
};

const CameraImageGalleryDialog = () => {
  const cameraDialogOpen = useDiagnosisDialogSelector.use.diagnosisImageDialogOpen();
  return (
    <Dialog
      open={cameraDialogOpen}
      PaperProps={{
        sx: {
          position: 'relative',
          maxWidth: 'unset',
          width: 1380,
          height: 850,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <ContentBox />
    </Dialog>
  );
};

export default CameraImageGalleryDialog;
