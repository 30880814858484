import * as React from 'react';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { Box, Typography } from '@mui/material';
import printStyles from './PrintPrescriptionStyle.module.scss';
import { Prescription } from 'modules/schema';
import { renderLabelSearch } from '../../../const';

interface Props {
  data?: Prescription[];
}

const PrescriptionPrintDataTable: React.FunctionComponent<Props> = ({ data }) => {
  const columns: Columns[] = [
    {
      title: 'encounterPrescription.drugName',
      render: (record) => {
        return (
          <>
            <Box key={record.id} style={{ pageBreakInside: 'avoid' }}>
              <Typography variant="subtitle1" className={printStyles['LargeFontSize']}>
                {record.prescriptionDetail.type === 'MEDICATION_EXTERNAL' ? (
                  <Typography variant="subtitle1" paddingRight={1} gap={'4px'}>
                    {record.prescriptionDetail.medicationName}
                  </Typography>
                ) : (
                  <Typography variant="subtitle1" paddingRight={1} gap={'4px'}>
                    {renderLabelSearch(
                      record.prescriptionDetail.medication.name,
                      record?.prescriptionDetail?.medication?.ingredient,
                      record?.prescriptionDetail?.medication?.dose,
                    )}
                  </Typography>
                )}
              </Typography>
              <Box display="flex" flexWrap="wrap" paddingLeft={0} columnGap={2}>
                <Typography variant="body1" className={printStyles['LargeFontSize']}>
                  {record && record?.intendedRoute && record?.intendedRoute?.label}
                </Typography>
                <Typography variant="body1" className={printStyles['LargeFontSize']}>
                  &nbsp;{record?.usage}
                </Typography>
              </Box>
            </Box>
          </>
        );
      },
    },
    {
      align: 'center',
      title: 'quantity',
      render: (record) => {
        return (
          <Box>
            <Typography variant="body1" className={printStyles['LargeFontSize']}>
              {`${record?.prescriptionDetail?.quantity || 0} ${
                record?.prescriptionDetail?.medication?.numeratorUnit || record?.prescriptionDetail?.unit || ''
              }`}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <TableCustom
      containerProps={{
        sx: {
          '& th,tr,td': {
            padding: '9px 8px',
            backgroundColor: 'white',
            border: '0.5px solid',
            color: 'unset',
          },
          'tbody>tr td:nth-child(2)': {
            padding: '1px 5px',
          },
          width: '100%',
          overflow: 'visible',
          marginTop: '8px',
          'th>div': {
            display: 'block',
          },
        },
      }}
      dataSource={data}
      columns={columns}
    />
  );
};

export default PrescriptionPrintDataTable;
