import { Grid, Typography } from '@mui/material';
import { ReportFilterOption } from './type';
import { FormattedMessage } from 'react-intl';

interface Props {
  filterOptions: ReportFilterOption[];
}

export const FilterPrintFormat = ({ filterOptions }: Props) => {
  if (filterOptions.length === 0) {
    return null;
  }
  return (
    <Grid container>
      {filterOptions.map((option) => (
        <Grid item xs={4} display="flex" direction="row">
          <Typography
            sx={{
              color: '#546E7A',
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.1px',
              width: '160px',
            }}
          >
            <FormattedMessage id={option.title} />:
          </Typography>
          <Typography
            sx={{
              marginLeft: 1,
              color: '#263238',
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.1px',
            }}
          >
            {option.value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  );
};
