import {
  FieldsType,
  ILayoutFields,
  ISchemaFields,
  ISchemaForm,
  IUiFields,
  SchemaType,
} from '../../../../../common/SchemaForm/utils';
import { Box, Divider, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import PrintIcon from '@mui/icons-material/Print';
import MediCard from '../../../../../common/component/MediCard';
import React from 'react';
import ImageBox from './ImageBox';

const fieldSchema: ISchemaFields = ({ formProps, methods, valuesField }) => {
  const { intl, onPrint, readOnly, indication } = formProps || {};
  return {
    images: {
      type: 'array',
      rawComponent: ImageBox,
      indication: indication,
      formProps,
      readOnly,
      hideInitialElement: true,
      noHelperText: true,
      propsWrapper: { style: { paddingTop: 0, paddingBottom: 0 } },
    },
    content: {
      type: () => (
        <Box>
          <Typography variant={'subtitle1'}>
            <FormattedMessage id="subclinical.describe" />
          </Typography>
          <div className="mce-editor-customs" dangerouslySetInnerHTML={{ __html: valuesField.examDepartment }} />
          <Divider style={{ margin: '8px 0px' }} />
          <Typography variant={'subtitle1'}>
            <FormattedMessage id="conclusion" />
          </Typography>
          <div className="mce-editor-customs" dangerouslySetInnerHTML={{ __html: valuesField.conclusion }} />
        </Box>
      ),
    },
    print: onPrint && {
      type: 'button',
      label: intl.formatMessage({ id: 'imageDoctor.print' }),
      style: { minWidth: 200 },
      disabled: valuesField.status !== 'final',
      color: 'inherit',
      endIcon: <PrintIcon />,
      propsWrapper: { xs: undefined },
      onClick: onPrint,
    },
  } as SchemaType;
};

const ui: IUiFields = ({ formProps }) => {
  return [
    {
      id: 'images',
      fields: ['images'],
    },
    {
      id: 'content',
      fields: ['content'],
    },
    {
      id: 'print',
      fields: ['print'],
      propsGridContainer: { justifyContent: 'flex-end' },
    },
  ] as FieldsType[];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement } = props;

  return (
    <div>
      <Box marginY={2}>{listElement[0]}</Box>
      <MediCard title="result">
        <Box margin={2}> {listElement[1]}</Box>
      </MediCard>
      <Box marginTop={2}>{listElement[2]}</Box>
    </div>
  );
};

const imageResultSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
export default imageResultSchema;
