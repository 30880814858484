import { Box, Stack } from '@mui/material';
import { GROUP_VERSION, INDICATION_REQUEST_TYPE, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import Report from 'modules/common/component/report';
import { REPORT_VIEW_MODE } from 'modules/common/component/report/consts';
import IndicationExecuteChart from 'modules/common/page/report/work/indication/IndicationExecuteChart';
import IndicationServiceChart from 'modules/common/page/report/work/indication/IndicationServiceChart';
import IndicationReportTable from 'modules/common/page/report/work/indication/IndicationTable';
import IndicationTimeChart from 'modules/common/page/report/work/indication/IndicationTimeChart';
import { Option } from 'modules/common/type';
import { SPECIALIST } from 'modules/layout/constants';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';

interface Props {}
const IndicationReportTab: React.FunctionComponent<Props> = () => {
  const [viewMode, setViewMode] = useState<keyof typeof REPORT_VIEW_MODE>('TABLE');
  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const typeOption: Option[] = useMemo(() => {
    return isDentalClinic
      ? [INDICATION_REQUEST_TYPE.DENTAL_EXAM, INDICATION_REQUEST_TYPE.THERAPY_SESSION]
      : [INDICATION_REQUEST_TYPE.ENCOUNTER, INDICATION_REQUEST_TYPE.THERAPY_SESSION];
  }, [isDentalClinic]);

  const isBasic = useSelector((state: AppState) => state.authen.user?.group_version === GROUP_VERSION.BASIC);
  const scopesOption: Option[] = useMemo(() => {
    return isBasic
      ? [SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE, SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE]
      : [
          SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE,
          SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE,
          SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE,
          SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE,
        ];
  }, [isBasic]);

  return (
    <Report>
      <Stack direction="column" position="relative">
        <Stack direction="row" justifyContent="flex-end" position="absolute" top={-10} right={0}>
          <Report.SwitchLayout onChange={(viewMode) => setViewMode(viewMode)} />
        </Stack>
        <Box marginTop={1}>
          {viewMode === 'TABLE' ? (
            <IndicationReportTable typeOption={typeOption} scopesOption={scopesOption} />
          ) : (
            <Stack direction="column">
              <IndicationTimeChart typeOption={typeOption} scopesOption={scopesOption} />
              <IndicationServiceChart typeOption={typeOption} scopesOption={scopesOption} />
              <IndicationExecuteChart typeOption={typeOption} scopesOption={scopesOption} />
            </Stack>
          )}
        </Box>
      </Stack>
    </Report>
  );
};

export default IndicationReportTab;
