export const VITAL_SIGN_CODE = {
  temperature: {
    code: '8310-5',
    display: 'Body temperature',
    system: 'http://loinc.org',
    unit: '°C',
  },
  breath: {
    code: '9279-1',
    display: 'Respiratory rate',
    system: 'http://loinc.org',
    unit: 'nhịp/phút',
  },
  bloodPressure: {
    code: '35094-2',
    display: 'Blood pressure panel',
    system: 'http://loinc.org',
    unit: 'mmHg',
  },
  heartBeat: {
    code: '8867-4',
    display: 'Heart rate',
    system: 'http://loinc.org',
    unit: 'nhịp/phút',
  },
  weight: {
    code: '29463-7',
    display: 'Body Weight',
    system: 'http://loinc.org',
    unit: 'kg',
  },
  height: {
    code: '8302-2',
    display: 'Body height',
    system: 'http://loinc.org',
    unit: 'cm',
  },
  spo2: {
    code: '20564-1',
    display: 'Oxygen saturation in Blood',
    system: 'http://loinc.org',
    unit: '%',
  },
};

export const SOLUTIONS_VALUE = {
  orderMedicine: 'orderMedicine',
  recheckAppointment: 'recheckAppointment',
  checkAtHome: 'checkAtHome',
  hospitalTransfer: 'hospitalTransfer',
  other: 'other',
};

export const MAX_SECONDARY_ICD10_COUNT = 6;

export const THERAPY_STATUS = [
  { label: 'encounterInfo.therapyStatus.new', value: 'NEW' },
  { label: 'encounterInfo.therapyStatus.processing', value: 'PROCESSING' },
  { label: 'encounterInfo.therapyStatus.finished', value: 'FINISHED' },
  { label: 'encounterInfo.therapyStatus.canceled', value: 'CANCELLED' },
];
