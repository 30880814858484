import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import DialogCustom from 'modules/common/component/DialogCustom';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, FE_TIME_DATE_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { RadioImageSizeImage } from 'modules/common/component/RadioImageSizeImage';
import 'modules/common/print.scss';
import { Encounter, IndicationImage, IndicationRequest } from 'modules/schema';
import { useFetch, useSpecializedDepartmentPIC } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import ImagePreview from 'modules/common/component/ImagePreview';
import { IMAGE_DEPARTMENT_CODE } from 'modules/common/apiConstants';

const COMPACT_TYPE = 'IMAGE_DOCTOR_PRINT_COMPACT_TYPE';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
    },
  },
});

interface FormPrintContentProps {
  indicationImage: IndicationImage;
  indicationRequest: IndicationRequest;
  encounter?: Encounter;
  compact: boolean;
}

const FormPrintContent: React.FunctionComponent<FormPrintContentProps> = ({
  indicationImage,
  indicationRequest,
  encounter,
  compact,
}) => {
  const headDoctor = useSpecializedDepartmentPIC(IMAGE_DEPARTMENT_CODE);
  const { data: images } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.encounterIndicationDiagnosticImage,
      referenceId: indicationRequest?.id,
    }),
  );

  const { appState } = useGeneralHook();
  const { currentLocation } = appState.authen;
  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
            <FormattedMessage id="imageDoctor.printHeader" />
            {indicationRequest?.serviceName}
          </Typography>
        </HeaderPrintForm>
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ columnCount: 2 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="therapy.label.customer.code" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{encounter?.patient?.formatCode}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {encounter?.patient?.name}
              </Typography>
            </Box>
            {!compact && (
              <>
                <Box display="flex">
                  <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                    <FormattedMessage id="category" />
                  </Typography>
                  :&nbsp;
                  <Typography variant="body1">
                    <FormattedMessage id={PAYMENT_PLAN[indicationRequest?.paymentPlan!].label} />
                  </Typography>
                </Box>
              </>
            )}
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{encounter?.patient?.homeAddress?.address}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {moment(encounter?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {encounter?.patient?.gender && <FormattedMessage id={encounter?.patient?.gender?.toLowerCase()} />}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="telephone" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{encounter?.patient?.mobilePhone}</Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [
    compact,
    encounter?.patient?.dob,
    encounter?.patient?.formatCode,
    encounter?.patient?.gender,
    encounter?.patient?.homeAddress?.address,
    encounter?.patient?.mobilePhone,
    encounter?.patient?.name,
    indicationRequest?.paymentPlan,
    indicationRequest?.serviceName,
  ]);

  const encounterInfo = React.useMemo(() => {
    if (compact) {
      return (
        <Box>
          {' '}
          <Grid item xs={12} display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 160 }}>
              <FormattedMessage id="encounterDetail.clinicalSymptoms" />
            </Typography>
            :&nbsp;
            {encounter?.clinicalExamination && (
              <Typography variant="body1">{extractHTMLContent(encounter?.clinicalExamination)}</Typography>
            )}
          </Grid>
        </Box>
      );
    }
    return (
      <Box>
        <Grid item xs={12} display="flex">
          <Typography variant="subtitle1" style={{ minWidth: 160 }}>
            <FormattedMessage id="printForm.placeOfIndication" />
          </Typography>
          :&nbsp;
          <Typography variant="body1">{encounter?.physicsRoom?.name}</Typography>
        </Grid>
        <Grid item xs={12} display="flex">
          <Typography variant="subtitle1" style={{ minWidth: 160 }}>
            <FormattedMessage id="encounterDetail.clinicalSymptoms" />
          </Typography>
          :&nbsp;
          {encounter?.clinicalExamination && (
            <Typography variant="body1">{extractHTMLContent(encounter?.clinicalExamination)}</Typography>
          )}
        </Grid>
        <Box display="flex" gap={2}>
          <Box display="flex" flex={1}>
            <Typography variant="subtitle1" style={{ minWidth: 160 }}>
              <FormattedMessage id="doctorOfIndication" />
            </Typography>
            :&nbsp;
            <Typography variant="body1">{indicationRequest?.picName}</Typography>
          </Box>
        </Box>

        <Box display="flex">
          <Typography variant="subtitle1" style={{ minWidth: 160 }}>
            <FormattedMessage id="timeExecuted" />
          </Typography>
          :&nbsp;{' '}
          <Typography variant="body1">
            {indicationRequest?.createdTime && moment(indicationRequest?.createdTime).format(FE_TIME_DATE_FORMAT)} -{' '}
            {indicationRequest?.endTime && moment(indicationRequest?.endTime).format(FE_TIME_DATE_FORMAT)}
          </Typography>
        </Box>
      </Box>
    );
  }, [
    compact,
    encounter?.clinicalExamination,
    encounter?.physicsRoom?.name,
    indicationRequest?.createdTime,
    indicationRequest?.endTime,
    indicationRequest?.picName,
  ]);

  const footer = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 12 }}>
          <Grid container>
            {compact ? (
              <Grid item xs={6} />
            ) : (
              <Grid item xs={6} textAlign="center">
                &nbsp;
                <Typography variant="h6">
                  <FormattedMessage id="printForm.imageDoctor2" />
                </Typography>
                <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                  <FormattedMessage id="medicalPrint.helperText" />
                </Typography>{' '}
                <Typography variant="body1">{headDoctor && headDoctor?.employeeFullName}</Typography>
              </Grid>
            )}
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id={compact ? 'printForm.dateShort' : 'printForm.date'}
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="imageDoctor.doctor" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
              <Typography variant="body1">{appState.authen.user?.employee_name}</Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [appState.authen.user?.employee_name, compact, headDoctor]);

  const testInfo = React.useMemo(() => {
    return (
      <>
        <Box>
          <Grid container spacing={1} justifyContent={'space-between'}>
            <Grid item>
              <Typography variant="h6">{indicationRequest?.serviceName}</Typography>
            </Grid>
            <RadioImageSizeImage>
              {(imageSize) => (
                <Grid item width={'100%'}>
                  <Grid container spacing={2} display="flex">
                    {images?.map((item, index) => {
                      return (
                        <Grid
                          item
                          key={index}
                          xs={imageSize}
                          textAlign="center"
                          display="flex"
                          flexDirection={'column'}
                          position="relative"
                        >
                          <Box textAlign="center">
                            <ImagePreview
                              alt=""
                              imageId={item.id}
                              style={{
                                maxHeight: imageSize === 6 ? 340 : imageSize === 3 ? 165 : 610,
                                width: imageSize === 12 ? '84%' : '100%',
                                objectFit: 'cover',
                              }}
                            />
                            <Typography variant="body2" color="textSecondary">
                              {item.description}
                            </Typography>
                          </Box>
                        </Grid>
                      );
                    })}
                  </Grid>
                </Grid>
              )}
            </RadioImageSizeImage>
          </Grid>
        </Box>{' '}
        <Box style={{ pageBreakInside: 'avoid' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <FormattedMessage id="description" />
              </Typography>
            </Grid>
            {indicationImage.examDepartment && (
              <Grid item xs={12}>
                <div
                  className="mce-editor-customs"
                  dangerouslySetInnerHTML={{ __html: indicationImage.examDepartment }}
                ></div>
              </Grid>
            )}
          </Grid>
        </Box>{' '}
        <Box style={{ pageBreakInside: 'avoid' }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography variant="subtitle1">
                <FormattedMessage id="conclusion" />
              </Typography>
            </Grid>
            {indicationImage.conclusion && (
              <Grid item xs={12}>
                <div
                  className="mce-editor-customs"
                  dangerouslySetInnerHTML={{ __html: indicationImage.conclusion }}
                ></div>
              </Grid>
            )}
          </Grid>
        </Box>
      </>
    );
  }, [images, indicationImage.conclusion, indicationImage.examDepartment, indicationRequest?.serviceName]);

  if (!currentLocation) {
    return null;
  }
  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }}>
        {header}
        <Box paddingY={1} style={{ clear: 'both', pageBreakInside: 'avoid' }}>
          <Divider />
        </Box>
        {encounterInfo}
        <Box paddingY={1} style={{ clear: 'both', pageBreakInside: 'avoid' }}>
          <Divider />
        </Box>
        {testInfo}
        {footer}
      </Box>
    </>
  );
};

interface Props {
  open: boolean;
  onClose?: () => void;
  indicationRequest: IndicationRequest;
  encounter?: Encounter;
  indicationImage: IndicationImage;
}

const PrintFormDialog: React.FunctionComponent<Props> = ({
  onClose,
  open,
  indicationRequest,
  indicationImage,
  encounter,
}) => {
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [compact, setCompact] = React.useState(localStorage.getItem(COMPACT_TYPE) === 'compact' || false);

  return (
    <DialogCustom
      className="camera-print-form-dialog"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: 1200 },
      }}
      keepMounted={false}
      title={'medicalPrint.result'}
      footer={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={compact}
                onChange={(e) => {
                  setCompact(e.target.checked);
                  localStorage.setItem(COMPACT_TYPE, e.target.checked ? 'compact' : 'expansion');
                }}
              />
            }
            label={<FormattedMessage id="printCompact" />}
          />
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="imageDoctor.print" />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />{' '}
        </Box>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <table width={'100%'}>
          <tbody>
            <FormPrintContent
              indicationImage={indicationImage}
              indicationRequest={indicationRequest}
              compact={compact}
              encounter={encounter}
            />
          </tbody>
        </table>
      </Box>
    </DialogCustom>
  );
};

export default PrintFormDialog;
