import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import * as React from 'react';
import { ISchemaFields } from 'modules/common/SchemaForm/utils';
import { THERAPY_SESSION_STATUS } from 'modules/reception/constants';
import { FE_TIME_FORMAT, some } from 'modules/common/constants';
import moment from 'moment';
import { UserEmployeeRole } from 'modules/schema';
import { useUserRoles } from 'modules/common/hook/useUserRoles';
import { BASIC_ROLE_INFO, ROLE_INFO } from 'modules/layout/utils';
import { keyBy, sortBy } from 'lodash';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

const PARTICIPANTS = [
  ROLE_INFO.examDoctor,
  ROLE_INFO.labDoctor,
  ROLE_INFO.imageSpecialist,
  ROLE_INFO.laboratory,
  ROLE_INFO.imageTechnician,
  ROLE_INFO.reception,
  ROLE_INFO.nurse,
  ROLE_INFO.pharmacist,
  ROLE_INFO.cashier,
  ROLE_INFO.admin,
  BASIC_ROLE_INFO.frontOffice,
  BASIC_ROLE_INFO.general,
  BASIC_ROLE_INFO.pharmacist,
  BASIC_ROLE_INFO.admin,
  BASIC_ROLE_INFO.examDoctor,
];

interface Props {
  picOptions: some[];
  therapy: some;
  cloneDataSession: (cloneData: some) => void;
  dataTherapySession: some;
  dataUsers?: UserEmployeeRole[];
}
const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const {
    intl,
    picOptions,
    therapy,
    cloneDataSession,
    dataTherapySession,
    dataUsers,
    onChangeAttendPerson,
    selectAttendPerson,
  } = formProps || {};

  if (valuesField.clone) {
    cloneDataSession(valuesField.clone);
  }
  const { setValue } = methods;

  return {
    id: {
      type: 'hidden',
    },
    startTime: {
      type: 'timePicker',
      label: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.starTime' }),
      register: {
        required: !!valuesField?.date,
      },
      propsWrapper: { xs: 1 },
    },
    date: {
      type: 'datePicker',
      label: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.date' }),
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      disableFuture: valuesField?.status === 'FINISHED',
      tooltipError: true,
      register: {
        required: !!valuesField?.startTime,
      },
      propsWrapper: { xs: 2 },
    },
    picUserId: {
      type: 'select',
      label: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.pic.main' }),
      placeholder: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.pic.main' }),
      options: picOptions,
      rawOptions: true,
      propsWrapper: { xs: 2 },
      onChange: (value) => {
        setValue('generalInfo.picName', value ? picOptions?.find((ele) => ele.value === value)?.label : null);
      },
    },
    attendPersons: {
      type: 'auto-complete',
      multiple: true,
      limitTags: 1,
      label: intl.formatMessage({ id: 'participant' }),
      placeholder: intl.formatMessage({ id: 'participant.placeholder' }),
      options: dataUsers,
      groupBy: (option) => option.group,
      propsWrapper: { xs: 2 },
      onChange: (value) => {
        onChangeAttendPerson(value);
      },
      getOptionDisabled: (option) => {
        if (selectAttendPerson?.length >= 5) {
          return true;
        }
      },
      defaultValue: () => {
        return dataTherapySession?.attendPersonList?.map((data) => ({
          ...data,
          value: data?.userId,
          label: data?.employeeName,
        }));
      },
    },
    status: {
      type: 'select',
      options: THERAPY_SESSION_STATUS,
      label: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.status' }),
      placeholder: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.status' }),
      propsWrapper: { xs: 2 },
      disabled:
        therapy?.status === 'FINISHED' || therapy?.status === 'CANCELLED' || dataTherapySession?.status === 'FINISHED',
      onChange: (value) => {
        if ((value === 'PROCESSING' || value === 'FINISHED') && !valuesField?.date) {
          setValue('generalInfo.date', new Date());
          setValue('generalInfo.startTime', moment(new Date()).format(FE_TIME_FORMAT));
        }
      },
    },
    clone: {
      type: 'auto-complete',
      options: therapy?.therapySessionList?.filter((i: some) => i.id !== dataTherapySession?.id),
      label: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.clone' }),
      placeholder: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.clone' }),
      propsWrapper: { xs: 3 },
      getOptionLabel: (option) => option.name,
      disabled: dataTherapySession?.status === 'FINISHED',
    },
    description: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.description' }),
      placeholder: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.placeholder.description' }),
      propsWrapper: { xs: 12 },
      multiline: true,
      rows: 2,
      sx: { paddingLeft: 0 },
      noHelperText: false,
      register: {
        maxLength: {
          value: 255,
          message: intl.formatMessage({ id: 'common.text.length.invalid' }),
        },
      },
    },
  };
};

const EditableTherapySessionInfo = ({ picOptions, therapy, cloneDataSession, dataTherapySession }: Props) => {
  const users = useUserRoles();
  const { intl } = useGeneralHook();

  const participantOptions = React.useMemo(() => {
    if (users && users.length > 0) {
      const roleMap = keyBy(PARTICIPANTS, 'roleKey');

      return sortBy(
        users?.map((ele) => {
          return {
            ...ele,
            group: intl.formatMessage({ id: ele?.roleName && roleMap[ele?.roleName]?.label }),
            value: ele.id,
            label: ele.employeeFullName,
            employeeName: ele.employeeFullName,
            userId: ele.employeeId,
          };
        }),
        'group',
      );
    }
    return [];
  }, [intl, users]);

  const [selectAttendPerson, setSelectAttendPerson] = React.useState([]);

  const dataTherapySessionCustom = React.useMemo(() => {
    setSelectAttendPerson(dataTherapySession?.attendPersonList);
    return {
      ...dataTherapySession,
      attendPersons: dataTherapySession?.attendPersonList,
    };
  }, [dataTherapySession]);

  return (
    <SchemaElement
      fieldName="generalInfo"
      propsElement={{
        type: 'section',
        schema: {
          fields: fieldSchema,
        },
        picOptions: picOptions,
        therapy: therapy,
        cloneDataSession: cloneDataSession,
        dataTherapySession: dataTherapySessionCustom,
        propsWrapper: {
          sx: { '& .MuiPaper-root': { padding: 0, backgroundColor: 'transparent' } },
        },
        dataUsers: participantOptions,
        onChangeAttendPerson: (value) => setSelectAttendPerson(value),
        selectAttendPerson: selectAttendPerson,
      }}
    />
  );
};

export default EditableTherapySessionInfo;
