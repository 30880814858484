import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { PAYMENT_TYPE } from 'modules/common/apiConstants';

export const ReportDebtSchema = z
  .object({
    id: z.number(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    referenceCode: z.string(),
    patientName: z.string(),
    mobilePhone: z.string(),
    debtAmount: z.number(),
    amountPaid: z.number(),
    finalTotalAmount: z.number(),
    prepayment: z.number(),
    type: createObjectKeysEnumSchema(PAYMENT_TYPE),
  })
  .partial();

export type ReportDebt = z.infer<typeof ReportDebtSchema>;
