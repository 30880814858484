import { Box, BoxProps, Collapse, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../IconButtonTitle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

interface Props {
  titleKey: string;
  rightAction?: React.ReactNode;
  children: React.ReactNode;
  allowCollapse?: boolean;
  defaultCollapse?: boolean;
  containerSx?: BoxProps['sx'];
}

const PatientDetailSession = ({
  allowCollapse = false,
  defaultCollapse = true,
  titleKey,
  rightAction,
  children,
  containerSx,
}: Props) => {
  const [expand, setExpand] = useState(defaultCollapse);
  return (
    <Box sx={{ borderRadius: '4px', background: '#fff', border: '1px solid #CFD8DC', ...containerSx }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        padding="6px 10px"
        sx={{ backgroundColor: '#F4F8FF' }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          {allowCollapse && (
            <IconButtonTitle
              onClick={() => setExpand(!expand)}
              sx={{
                padding: 0,
                marginRight: '5px',
                '&:hover': {
                  backgroundColor: 'unset',
                },
              }}
            >
              <ArrowDropDownIcon
                sx={{
                  transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'all 200ms',
                  fontSize: 24,
                  color: '#0052E0',
                }}
              />
            </IconButtonTitle>
          )}
          <Typography
            sx={{
              color: '#003CA6',
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: 'Roboto',
              fontSize: '16px',
              fontStyle: 'normal',
              fontWeight: '500',
              lineHeight: '24px',
              letterSpacing: '0.15px',
            }}
          >
            <FormattedMessage id={titleKey} />
          </Typography>
        </Stack>

        {rightAction}
      </Stack>
      {allowCollapse ? (
        <Collapse in={expand}>{<Box sx={{ padding: '20px 16px', height: '100%' }}>{children}</Box>}</Collapse>
      ) : (
        <Box sx={{ padding: '20px 16px', height: '100%' }}>{children}</Box>
      )}
    </Box>
  );
};

export default PatientDetailSession;
