import { ExpandLess } from '@mui/icons-material';
import { Card, CardHeader, CardProps, Collapse, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { PRIMARY } from '../../../colors';

interface Props extends Omit<CardProps, 'title'> {
  title?: React.ReactNode;
  rawTitle?: boolean;
  hasCollapse?: boolean;
  speech?: React.ReactNode;
}
const MediCard: React.FunctionComponent<Props> = ({ title, rawTitle, children, hasCollapse, speech, ...cardProps }) => {
  const [expand, setExpand] = React.useState(false);

  return (
    <Card style={{ overflow: 'hidden' }} variant="outlined" {...cardProps}>
      {title && (
        <CardHeader
          onClick={() => setExpand((old) => !old)}
          title={
            typeof title === 'string' ? (
              <Typography variant="subtitle2" color="inherit">
                {rawTitle ? title : <FormattedMessage id={title} />}
              </Typography>
            ) : (
              title
            )
          }
          style={{
            padding: '8px 16px',
            background: PRIMARY.main,
            color: 'white',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
            cursor: hasCollapse ? 'pointer' : 'unset',
          }}
          action={
            <>
              {speech}
              {hasCollapse && (
                <ExpandLess
                  style={{
                    transition: '0.2s all',
                    transform: expand ? 'rotate(0deg)' : 'rotate(180deg)',
                    color: 'white',
                  }}
                />
              )}
            </>
          }
        />
      )}
      {hasCollapse ? <Collapse in={expand}>{children}</Collapse> : children}
    </Card>
  );
};

export default MediCard;
