import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import usePaginationHook from '../../common/hook/usePaginationHook';
import { useState } from 'react';
import { API_SERVER } from '../../common/api';
import { formatDateFilterField } from '../../common/utils';
import { Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import RegisterDentalExamDialog from 'modules/reception/component/dental/ReceiveWizardDialog';
import UpdateDentalExamDialog from 'modules/reception/component/dental/UpdateDentalExamDialog';
import FilterDentalBox from 'modules/reception/component/dental/FilterDentalBox';
import DentalList from '../component/dental/DentalList';
import { useFetch, useUpdateMutate } from 'modules/common/hook';

interface Props {}

const DentalListPage: React.FunctionComponent<Props> = () => {
  const [openRegisterForm, setOpenRegisterForm] = React.useState(false);
  const orderTherapy = useSelector((appState: AppState) => appState.common.orderTherapy);
  const hookPagination = usePaginationHook({
    defaultFilter: {
      orderBy: orderTherapy,
    },
  });
  const { params } = hookPagination;
  const [selectedSessionTherapy, setSelectedSessionTherapy] = useState(null);

  const { data, revalidate, isValidating } = useFetch(
    API_SERVER.dentalExam.getList(formatDateFilterField(params, 'fromDate', 'toDate')),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  const onUpdateTherapySessionMutate = useUpdateMutate({
    onSuccess: () => {
      setSelectedSessionTherapy(null);
      revalidate();
    },
  });
  const onUpdateTherapySession = async (data) => {
    await onUpdateTherapySessionMutate({
      url: API_SERVER.therapy.updateTherapySession(data.id),
      method: 'put',
      data: data,
    });
  };

  return (
    <>
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterDentalBox
          hookPagination={hookPagination}
          onRegister={() => setOpenRegisterForm(true)}
          pagin={
            <>
              <Typography variant="h6">
                <FormattedMessage id="navMenu.encounterList.dental" />
              </Typography>
              <Typography variant="caption">
                <FormattedMessage
                  id="foundResult"
                  values={{
                    num: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.content?.length || 0}
                      </Typography>
                    ),
                    total: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.pagination?.totalElements || 0}
                      </Typography>
                    ),
                  }}
                />
              </Typography>
            </>
          }
          onDataLoading={isValidating}
        />
        <DentalList
          refresh={() => {
            revalidate();
          }}
          data={data?.content}
          total={data?.pagination?.totalElements || 0}
          hookPagination={hookPagination}
          loading={isValidating}
        />
      </Paper>

      <RegisterDentalExamDialog
        open={openRegisterForm}
        mode={{
          type: 'create-new',
        }}
        close={() => {
          setOpenRegisterForm(false);
          localStorage.removeItem('create_onsecutively');
        }}
        refresh={revalidate}
        selectedObject={{}}
      />
      <UpdateDentalExamDialog
        open={!!selectedSessionTherapy}
        onClose={() => setSelectedSessionTherapy(null)}
        onSubmit={onUpdateTherapySession}
        data={selectedSessionTherapy}
      />
    </>
  );
};

export default DentalListPage;
