import { z } from 'zod';
import { PaymentHistorySchema } from './PaymentHistory';
import { PaymentDetailSchema } from './PaymentDetail';

export const PaymentHistoryDetailSchema = z
  .object({
    paymentHistories: z.array(PaymentHistorySchema),
    paymentDetails: z.array(PaymentDetailSchema),
  })
  .partial();

export type PaymentHistoryDetail = z.infer<typeof PaymentHistoryDetailSchema>;
