import { some } from '../../../../common/constants';
import DialogCustom from '../../../../common/component/DialogCustom';
import { Box } from '@mui/material';
import SchemaForm from '../../../../common/SchemaForm';
import { useEffect } from 'react';
import { PAYMENT_METHOD } from '../../../../admin/component/saleManagement/constant';
import * as React from 'react';

interface Props {
  open: boolean;
  paymentAdvanceDetail?: some;
  onClose?: () => void;
  onSubmit?: (value: some) => void;
}

const PrepaymentAddDialog = (props: Props) => {
  const { onClose, open, onSubmit, paymentAdvanceDetail } = props;
  const [paymentAdvance, setPaymentAdvance] = React.useState<some>();

  useEffect(() => {
    const paymentMethod = PAYMENT_METHOD.find(
      (m) => m.value.toString().toUpperCase() === paymentAdvanceDetail?.paymentMethod,
    )?.value.toString();
    if (paymentAdvanceDetail) {
      setPaymentAdvance({
        ...paymentAdvanceDetail,
        paymentMethod,
      });
    }
  }, [paymentAdvanceDetail]);

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title={paymentAdvance ? 'therapy.prepayment.header.paymentAdvanceEdit' : 'prepaidAdd'}
      maxWidth="sm"
      PaperProps={{
        style: { minWidth: 300 },
      }}
    >
      <Box padding={2}>
        <SchemaForm
          onCancel={onClose}
          onSubmit={onSubmit}
          formData={paymentAdvance}
          schema={{
            fields: ({ formProps: { intl, appState } }) => {
              return {
                amount: {
                  type: 'number-field',
                  inputType: 'number',
                  label: intl.formatMessage({ id: 'therapy.prepaid.label.prepaidAmount' }),
                  placeholder: intl.formatMessage({ id: 'therapy.prepaid.label.amountEnter' }),
                  register: {
                    maxLength: {
                      value: 16,
                      message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                    },
                    min: {
                      value: 1,
                      message: intl.formatMessage({ id: 'validation.invalid' }),
                    },
                    required: true,
                  },
                  propsWrapper: { xs: 12 },
                },
              };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default PrepaymentAddDialog;
