import * as React from 'react';
import usePaginationHook from '../../common/hook/usePaginationHook';
import { API_SERVER } from '../../common/api';
import { formatDateFilterField } from '../../common/utils';
import { Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import CashierDentalList from 'modules/cashier/component/dental/CashierDentalList';
import FilterBox from '../component/dental/FilterBox';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import { useFetch } from 'modules/common/hook';

interface Props {}

const CashierDentalListPage: React.FunctionComponent<Props> = () => {
  const orderTherapy = useSelector((appState: AppState) => appState.common.orderTherapy);
  const hookPagination = usePaginationHook({
    defaultFilter: {
      orderBy: orderTherapy,
    },
  });
  const { params } = hookPagination;
  const { data, isValidating } = useFetch(
    API_SERVER.dentalExam.getListWithPaymentDetail(formatDateFilterField(params, 'fromDate', 'toDate')),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  return (
    <>
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterBox
          hookPagination={hookPagination}
          pagin={
            <>
              <Typography variant="h6">
                <FormattedMessage id="navMenu.encounterList.dental" />
              </Typography>
              <Typography variant="caption">
                <FormattedMessage
                  id="foundResult"
                  values={{
                    num: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.content?.length || 0}
                      </Typography>
                    ),
                    total: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.pagination?.totalElements || 0}
                      </Typography>
                    ),
                  }}
                />
              </Typography>
            </>
          }
          onDataLoading={isValidating}
        />
        <CashierDentalList loading={isValidating} data={data} hookPagination={hookPagination} />
      </Paper>
    </>
  );
};

export default CashierDentalListPage;
