import { PAYMENT_HISTORY_TYPE, PAYMENT_METHOD } from 'modules/common/apiConstants';
import { FilterType } from 'modules/common/component/filter/type';
import { BE_DATE_TIME_FORMAT, ROLES } from 'modules/common/constants';
import { formatEndDate } from 'modules/common/utils';
import moment from 'moment';

export const DEFAULT_VALUES = {
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatEndDate(moment()),
  keyword: '',
  type: [],
  cashBookTypes: [],
};

export const FILTER_CONFIGS: FilterType[] = [
  {
    type: 'PAYMENT_METHODS',
    options: [PAYMENT_METHOD.BANK_TRANSFER, PAYMENT_METHOD.CARD, PAYMENT_METHOD.CASH, PAYMENT_METHOD.PREPAYMENT],
    xs: 4,
  },
  {
    type: 'EMPLOYEE',
    roles: [ROLES.CASHIER.value, ROLES.COORDINATOR.value, ROLES.GENERAL_DOCTOR.value],
    groupByRole: false,
    title: 'account.cashier',
    placeholder: 'filterAsCashier',
  },
  {
    type: 'TRANSACTION_TYPE',
    options: [
      PAYMENT_HISTORY_TYPE.PREPAYMENT,
      PAYMENT_HISTORY_TYPE.PAYMENT,
      PAYMENT_HISTORY_TYPE.REFUND,
      PAYMENT_HISTORY_TYPE.PAYMENT_ADVANCE,
      PAYMENT_HISTORY_TYPE.PAYMENT_ADVANCE_REFUND,
    ],
    xs: 6,
  },
];
