import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import * as React from 'react';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, FE_TIME_FORMAT } from 'modules/common/constants';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import { Button, Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import moment from 'moment';
import GradingIcon from '@mui/icons-material/Grading';
import { isEmpty } from 'lodash';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import { API_SERVER } from 'modules/common/api';
import { axiosThunk } from 'modules/common/redux/axios';
import DialogCustom from 'modules/common/component/DialogCustom';
import { Box } from '@mui/system';
import SchemaForm from 'modules/common/SchemaForm/index';
import { fetchThunk } from 'modules/common/redux/thunk';
import schema from './schema';
import { Patient, PatientSchema, RequestAppointment } from 'modules/schema';
import { useUserRoles } from 'modules/common/hook/useUserRoles';
import { z } from 'zod';
import ApproveDialog from './ApproveDialog';
import { GENDER } from 'modules/common/apiConstants';

interface Props {
  data?: RequestAppointment[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  revalidate: () => void;
  revalidateAppointment: () => void;
}

const PatientManagementTable = ({ data, loading, hookPagination, total, revalidate, revalidateAppointment }: Props) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const { dispatch, intl, openSnackbar, confirmDialog } = useGeneralHook();
  const [selectedAppointment, setSelectedAppointment] = React.useState<RequestAppointment[]>([]);
  const [open, setOpen] = React.useState(false);
  const [openConfirmApprove, setOpenConfirmApprove] = React.useState(false);
  const [selectedRequestAppointment, setSelectedRequestAppointment] = React.useState<RequestAppointment | undefined>();
  const [patientData, setPatientData] = React.useState<Patient[]>([]);
  const doctors = useUserRoles('DOCTOR', 'GENERAL_DOCTOR');
  const { promptConfirmation, close } = confirmDialog;

  const doctorOptions = React.useMemo(
    () => doctors?.map((doctor) => ({ value: doctor?.id, label: doctor?.employeeFullName })),
    [doctors],
  );

  const rejectAppointments = React.useCallback(
    async (requestAppointments: RequestAppointment[]) => {
      const confirm = await promptConfirmation({
        title: intl.formatMessage({ id: 'encounter.calendar.label.request.reject' }),
        content: intl.formatMessage({ id: 'encounter.calendar.content.reject' }),
        okId: intl.formatMessage({ id: 'confirm' }),
        cancelId: intl.formatMessage({ id: 'destroy' }),
      });
      if (confirm) {
        if (!requestAppointments || requestAppointments.length <= 0) {
          return;
        }
        try {
          dispatch(setLoading(true));
          await dispatch(
            axiosThunk({
              url: API_SERVER.requestAppointment.reject({
                requestAppointmentIds: requestAppointments?.map((ele) => ele?.id)?.join(','),
              }),
              method: 'put',
            }),
          );
          revalidate();
          openSnackbar({
            message: intl.formatMessage({
              id: 'encounter.calendar.message.rejectSuccess',
            }),
          });
        } catch (e: any) {
          if (e?.errors) {
            e?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  // appove,reject appointment
  const approveAppointments = React.useCallback(
    async (requestAppointment: RequestAppointment) => {
      const patientId = localStorage.getItem('patientId');
      if (!requestAppointment) {
        return;
      }
      try {
        dispatch(setLoading(true));
        await dispatch(
          axiosThunk({
            url: API_SERVER.requestAppointment.approve(),
            method: 'put',
            data: patientId
              ? {
                  requestAppointmentId: requestAppointment?.id,
                  patientId,
                }
              : {
                  requestAppointmentId: requestAppointment?.id,
                },
          }),
        );
        revalidate();
        revalidateAppointment();
        openSnackbar({
          message: intl.formatMessage({
            id: 'encounter.calendar.message.approveSuccess',
          }),
        });
      } catch (e: any) {
        if (e?.errors) {
          e?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
      }
      close();
      localStorage.removeItem('patientId');
    },
    [close, dispatch, intl, openSnackbar, revalidate, revalidateAppointment],
  );

  // confirm approve appointment
  const confirmApproveAppointments = React.useCallback(
    async (requestAppointment: RequestAppointment) => {
      try {
        const { data } = await dispatch(
          axiosThunk({
            url: API_SERVER.patient.searchByMobilePhoneAndName({
              absolute: true,
              mobilePhone: requestAppointment?.phoneNumber,
            }).url,
            method: 'get',
          }),
        );
        const patients = z.array(PatientSchema).parse(data);
        if (isEmpty(patients)) {
          const confirm = await promptConfirmation({
            title: intl.formatMessage({ id: 'encounter.calendar.label.request.approve' }),
            content: intl.formatMessage({ id: 'encounter.calendar.content.approve' }),
            okId: intl.formatMessage({ id: 'confirm' }),
            cancelId: intl.formatMessage({ id: 'destroy' }),
          });
          if (confirm) {
            approveAppointments(requestAppointment);
          }
        } else {
          setSelectedRequestAppointment(requestAppointment);
          setPatientData(patients);
          setOpenConfirmApprove(true);
        }
      } catch (e: any) {
        if (e?.errors) {
          e?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
      }
      close();
    },
    [approveAppointments, close, dispatch, intl, openSnackbar, promptConfirmation],
  );

  // update appointment
  const updateAppointment = async (data) => {
    try {
      const dateTimeFormat = `${FE_TIME_FORMAT} ${BE_DATE_FORMAT}`;
      const dateFormat = moment(data.bookingDate).format(BE_DATE_FORMAT);
      const startTime = moment(`${data.bookingTimeStart} ${dateFormat}`, dateTimeFormat);
      const endTime = moment(`${data.bookingTimeEnd} ${dateFormat}`, dateTimeFormat);
      dispatch(setLoading(true));
      await dispatch(
        fetchThunk(
          API_SERVER.requestAppointment.update(data?.id),
          'put',
          JSON.stringify({
            bookingStartTime: startTime.format(BE_DATE_TIME_FORMAT),
            bookingEndTime: endTime.format(BE_DATE_TIME_FORMAT),
            customerName: data.customerName,
            dob: data.dob,
            gender: data.gender,
            note: data.note,
            phoneNumber: data.phoneNumber,
            picUserId: data.picUserId,
            picName: doctors?.find((ele) => ele?.id === data?.picUserId)?.employeeFullName,
          }),
        ),
      );
      dispatch(setLoading(false));
      revalidate();
      setOpen(false);
      openSnackbar({
        message: intl.formatMessage({
          id: 'encounter.calendar.message.updateSuccess',
        }),
      });
    } catch (e: any) {
      dispatch(setLoading(false));
      if (e?.errors) {
        e?.errors.forEach((v) => {
          openSnackbar({ message: v.message, type: 'error' });
        });
      } else {
        openSnackbar({ message: intl.formatMessage({ id: 'login.unknownError' }), type: 'error' });
      }
    }
  };

  // set data form update appointment
  const formData = {
    ...selectedRequestAppointment,
    bookingDate: selectedRequestAppointment?.bookingStartTime?.format(BE_DATE_FORMAT),
    bookingTimeStart: selectedRequestAppointment?.bookingStartTime?.format(FE_TIME_FORMAT),
    bookingTimeEnd: selectedRequestAppointment?.bookingEndTime?.format(FE_TIME_FORMAT),
  };

  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounter.calendar.label.dateSent',
        render: (record) => {
          return (
            <Typography variant="body1">
              {record.createdTime && moment(record.createdTime, BE_DATE_TIME_FORMAT).utc().format(FE_DATE_FORMAT)}
            </Typography>
          );
        },
      },
      {
        title: 'sellingProduct.title.customerName',
        dataIndex: 'customerName',
      },
      {
        title: 'telephone',
        dataIndex: 'phoneNumber',
      },
      {
        title: 'birthdayShort',
        dataIndex: 'dob',
        render: (record) => {
          return (
            <Typography variant="body1">
              {record.dob && moment(record.dob, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
            </Typography>
          );
        },
      },
      {
        title: 'gender',
        dataIndex: 'gender',
        render: (record) => {
          return <Typography>{record?.gender && <FormattedMessage id={GENDER[record?.gender!]?.label} />}</Typography>;
        },
      },
      {
        title: 'note',
        dataIndex: 'note',
      },
      {
        title: 'encounterDate',
        render: (record) => {
          return (
            <Typography variant="body1">
              {record.bookingStartTime && moment(record.bookingStartTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
            </Typography>
          );
        },
      },
      {
        title: 'encounter.calendar.title.examinationTime',
        render: (record) => {
          return (
            <Typography variant="body1">
              {record.bookingStartTime && moment(record.bookingStartTime, BE_DATE_TIME_FORMAT).format(FE_TIME_FORMAT)}
            </Typography>
          );
        },
      },
      {
        title: 'action',
        align: 'right',
        render: (item) => {
          return (
            <>
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  setSelectedRequestAppointment(item);
                  setOpen(true);
                }}
                style={{ marginRight: 8 }}
              >
                <EditIcon />
              </IconButtonTitle>
              <IconButtonTitle
                title="encounter.calendar.label.request.reject"
                size="small"
                onClick={() => rejectAppointments([item])}
              >
                <DeleteIcon />
              </IconButtonTitle>
              <IconButtonTitle
                title="encounter.calendar.label.request.approve"
                size="small"
                onClick={() => confirmApproveAppointments(item)}
              >
                <GradingIcon />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns<RequestAppointment>[];
  }, [confirmApproveAppointments, rejectAppointments]);

  return (
    <>
      <Paper
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 2,
          alignItems: 'center',
          paddingBottom: '20px',
          paddingTop: '8px',
        }}
      >
        {/* <Button
          disabled={isEmpty(selection)}
          variant="contained"
          color="primary"
          startIcon={<GradingIcon />}
          onClick={() => approveAppointments('', 'approve')}
        >
          <FormattedMessage id="encounter.calendar.label.request.approve" />
        </Button> */}
        <Button
          disabled={isEmpty(selectedAppointment)}
          variant="outlined"
          color="primary"
          startIcon={<DeleteIcon />}
          onClick={() => rejectAppointments(selectedAppointment)}
        >
          <FormattedMessage id="encounter.calendar.label.request.reject" />
        </Button>
      </Paper>
      <TableCustom
        dataSource={data}
        loading={loading}
        columns={columns}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        rowSelection={{
          selectedRowKeys: selectedAppointment,
          onChange: ({ selectedRows }) => setSelectedAppointment(selectedRows),
        }}
      />
      <DialogCustom
        open={open}
        maxWidth="md"
        keepMounted={false}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { width: 560 },
        }}
        title={
          <Typography variant="h6" color="primary">
            <FormattedMessage id="encounter.calendar.title.update" />
          </Typography>
        }
      >
        <Box p={2}>
          <SchemaForm
            schema={schema}
            formData={formData}
            formId="patientForm"
            onCancel={() => setOpen(false)}
            onSubmit={updateAppointment}
            dataListDoctor={doctorOptions}
            setOpen={setOpen}
          />
        </Box>
      </DialogCustom>
      {selectedRequestAppointment && (
        <ApproveDialog
          onClose={() => setOpenConfirmApprove(false)}
          requestAppointment={selectedRequestAppointment}
          patients={patientData}
          open={openConfirmApprove}
          approveAppointment={approveAppointments}
        />
      )}
    </>
  );
};

export default PatientManagementTable;
