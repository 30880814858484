import { RouteProps } from 'react-router';
import { some } from '../common/constants';

export interface RoutesTabType extends RouteProps {
  title?: string; // string id of title breadcrumb sidebar helmet
  path?: string; // path link
  directPath?: string; // parent path containing this path in the side nav menu
  isModule?: boolean; // is a page at the top level in the side nav menu
  hidden?: boolean;
  hiddenMenu?: RoutesTabType[];
  subMenu?: RoutesTabType[];
  isRedirect?: boolean;
  icon?: React.ReactNode;
  dental?: boolean;
}

export const ROLE_INFO = {
  examDoctor: {
    system: 'http://terminology.hl7.org/CodeSystem/practitioner-role',
    code: 'doctor',
    display: 'Doctor',
    label: 'account.examDoctor',
    roleKey: 'DOCTOR',
  },
  labDoctor: {
    system: 'http://snomed.info/sct',
    code: '81464008',
    display: 'Clinical pathologist',
    label: 'account.labDoctor',
    roleKey: 'CLINICAL_PATHOLOGIST',
  },
  imageSpecialist: {
    system: 'http://snomed.info/sct',
    code: '66862007',
    display: 'Radiologist',
    label: 'account.imageSpecialist',
    roleKey: 'RADIOLOGIST',
  },
  laboratory: {
    system: 'http://snomed.info/sct',
    code: '386629007',
    display: 'Laboratory technologist',
    label: 'account.laboratory',
    roleKey: 'LABORATORY_TECHNOLOGIST',
  },
  imageTechnician: {
    system: 'http://snomed.info/sct',
    code: '159016003',
    display: 'Radiographer',
    label: 'account.imageTechnician',
    nonLogin: true,
    roleKey: 'RADIOGRAPHER',
  },
  reception: {
    system: 'http://snomed.info/sct',
    code: '768836001',
    display: 'Patient navigator',
    label: 'account.reception',
    roleKey: 'RECEPTIONIST',
  },
  nurse: {
    system: 'http://terminology.hl7.org/CodeSystem/practitioner-role',
    code: 'nurse',
    display: 'Nurse',
    label: 'account.nurse',
    nonLogin: true,
    roleKey: 'NURSE',
  },
  pharmacist: {
    system: 'http://terminology.hl7.org/CodeSystem/practitioner-role',
    code: 'pharmacist',
    display: 'Pharmacist',
    label: 'account.pharmacist',
    roleKey: 'PHARMACIST',
  },
  cashier: {
    system: 'http://snomed.info/sct',
    code: '159502000',
    display: 'Cash handling clerk',
    label: 'account.cashier',
    roleKey: 'CASHIER',
  },
  admin: {
    system: 'http://snomed.info/sct',
    code: '6868009',
    display: 'Hospital administrator',
    label: 'account.admin',
    roleKey: 'ADMIN',
  },
};

export const BASIC_ROLE_INFO = {
  examDoctor: ROLE_INFO.examDoctor,
  admin: ROLE_INFO.admin,
  frontOffice: {
    system: 'http://snomed.info/sct',
    code: '159561009',
    display: 'Receptionist',
    label: 'account.frontOffice',
    roleKey: 'COORDINATOR',
  },
  general: {
    system: 'http://hl7.org/fhir/medi-practitioner-role',
    code: '0',
    display: 'General doctor',
    label: 'account.general',
    roleKey: 'GENERAL_DOCTOR',
  },
  pharmacist: {
    system: 'http://terminology.hl7.org/CodeSystem/practitioner-role',
    code: 'pharmacist',
    display: 'Pharmacist',
    label: 'account.pharmacist',
    roleKey: 'PHARMACIST',
  },
};

export const ALL_ROLE_INFO = {
  ...ROLE_INFO,
  ...BASIC_ROLE_INFO,
};

export function generateRoleValue(roleInfo?: some) {
  return `${roleInfo?.code}__${roleInfo?.system}`;
}

export function flatRoutes(list: RoutesTabType[]): RoutesTabType[] {
  let listTerm: RoutesTabType[] = [];
  list.forEach((route) => {
    if (route.subMenu) {
      listTerm = listTerm.concat(flatRoutes(route.subMenu));
    }
    if (route.hiddenMenu) {
      listTerm = listTerm.concat(flatRoutes(route.hiddenMenu));
    }
    if (route.path) {
      listTerm = listTerm.concat(route);
    }
  });
  return listTerm;
}

export function getMachineId() {
  let machineId = localStorage.getItem('MachineId');

  if (!machineId) {
    machineId = String(window.crypto['randomUUID']());
    localStorage.setItem('MachineId', machineId);
  }

  return machineId;
}
