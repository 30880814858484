import {
  FormControl,
  FormControlLabel,
  FormControlTypeMap,
  FormLabel,
  InputLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import React, { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from '../../../constants';

export interface PropsMultipleRadioElement
  extends Omit<OverridableComponent<FormControlTypeMap<{}, 'div'>>, 'defaultValue' | 'ref'> {
  label?: React.ReactNode;
  onChange?: (value) => void;
  className?: string;
  options?: some[];
  error?: boolean;
  rawOptions?: boolean;
  required?: boolean;
  row?: boolean;
  value?: any;
  name?: string;
}

interface Props extends PropsMultipleRadioElement {}

const MultipleRadioElement = React.forwardRef((props: Props, ref: any) => {
  const { options, label, row = false, onChange, className, name, value, error, rawOptions, required, ...rest } = props;

  const handleChange = (event) => {
    let tmp = event.target.value;
    if (typeof value === 'number') {
      tmp = Number.parseInt(tmp, 10);
    }
    onChange && onChange(tmp);
  };

  return (
    <FormControl component="fieldset" className={className} error={!!error} ref={ref} {...rest}>
      {label && (
        <FormLabel component="legend" style={{ marginBottom: 0 }}>
          <InputLabel required={required}>{label}</InputLabel>
        </FormLabel>
      )}
      <RadioGroup aria-label={name} name={name} value={String(value)} row={row} onChange={handleChange}>
        {options?.map(({ label: labelOption = '', value: valueTmp, ...one }: some, index: number) => {
          return (
            <FormControlLabel
              key={index}
              style={{ marginLeft: 0 }}
              control={<Radio color="primary" style={{ padding: 4 }} />}
              label={
                typeof labelOption === 'string' ? (
                  rawOptions ? (
                    labelOption
                  ) : (
                    <Typography variant="body2">{labelOption ? <FormattedMessage id={labelOption} /> : ''}</Typography>
                  )
                ) : (
                  labelOption
                )
              }
              value={String(valueTmp)}
              {...one}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
});
export default memo(MultipleRadioElement);
