import { Box } from '@mui/system';
import DialogCustom from 'modules/common/component/DialogCustom';
import SchemaForm from 'modules/common/SchemaForm';
import { useIndicationNoteSelectors } from './state';
import { useUpdateMutate } from 'modules/common/hook/useMutate';
import { API_SERVER } from 'modules/common/api';
import { mutate } from 'swr';
import { useCallback } from 'react';
import { useParams } from 'react-router';

const IndicationNoteDialog = () => {
  const openDialog = useIndicationNoteSelectors.use.open();
  const onDialogClose = useIndicationNoteSelectors.use.onCloseDialog();
  const selectedIndication = useIndicationNoteSelectors.use.selectedIndication()!;
  const { dentalSessionId } = useParams<{ dentalSessionId }>();

  const revalidateIndications = useCallback(
    () => mutate(API_SERVER.indicationRequest.getIndicationRequestByReferenceId(dentalSessionId, 'DENTAL_EXAM').url),
    [dentalSessionId],
  );
  const onUpdateNoteIndicationMutate = useUpdateMutate({ onSuccess: revalidateIndications });

  return (
    <DialogCustom
      open={openDialog}
      onClose={onDialogClose}
      title="dental.dentalDetail.statusAndDiagnosis.dialog.title"
      PaperProps={{
        style: { width: '500px' },
      }}
      maxWidth="xl"
      sx={{ '& .MuiBox-root.dialog_header': { backgroundColor: '#094EFF', h6: { color: '#E0ECFF' } } }}
    >
      <Box padding={2}>
        <SchemaForm
          formData={{ note: selectedIndication?.note }}
          schema={{
            fields: ({ formProps }) => {
              const { intl } = formProps || {};
              return {
                note: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'note' }),
                  placeholder: intl.formatMessage({ id: 'noteEnter' }),
                  multiline: true,
                  rows: 5,

                  register: {
                    maxLength: {
                      value: 255,
                      message: intl.formatMessage({ id: 'common.maxLength.text' }, { length: '255' }),
                    },
                  },
                },
              };
            },
          }}
          onSubmit={(data) => {
            onUpdateNoteIndicationMutate({
              url: API_SERVER.indicationRequest.updateNote(selectedIndication.id!),
              method: 'put',
              data: { data: data.note },
            });
            onDialogClose();
          }}
          formId="note-form"
        />
      </Box>
    </DialogCustom>
  );
};

export default IndicationNoteDialog;
