import { Prescription } from 'modules/schema';
import PatientDetailSession from '../../PatientDetailSession';
import { PatientSessionProps } from '../../type';
import { Stack, Typography } from '@mui/material';
import { PRESCRIPTION_TYPE } from 'modules/common/apiConstants';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { SPECIALIST } from 'modules/layout/constants';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import Table from '../../../table';
import { Column } from '../../../table/type';
import { FE_DATE_FORMAT } from 'modules/common/constants';
import { PrescriptionDetailTable } from './PrescriptionDetailTable';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { ROUTES } from 'modules/layout/router';
import { Note } from 'svg';
import { FormattedMessage } from 'react-intl';

const COLUMNS: Column<Prescription>[] = [
  {
    type: 'SUB_ROW',
    allowCollapse: true,
    width: '10px',
    collapseProps: {
      unmountOnExit: true,
    },
    render: (record) => <PrescriptionDetailTable prescription={record} />,
  },
  {
    title: 'patient.patientDetail.prescriptionHistory.title.code',
    render: 'id',
  },
  {
    title: 'patient.patientDetail.prescriptionHistory.title.pic',
    render: 'picName',
  },
  {
    title: 'patient.patientDetail.prescriptionHistory.title.createdTime',
    render: (record) => record.createdTime?.format(FE_DATE_FORMAT),
  },
  {
    title: 'patient.patientDetail.prescriptionHistory.title.examCode',
    linkUrl: (record) =>
      record.type === PRESCRIPTION_TYPE.ENCOUNTER
        ? ROUTES.encounterDetail.gen(record.referenceId!, record?.patientId!)
        : ROUTES.therapyDetail.gen(record.referenceId!),
    render: (record) => <Typography>{record.referenceCode}</Typography>,
  },
];

const NoData = () => {
  return (
    <Stack height="300px" justifyContent="center" alignItems="center">
      <Stack sx={{ margin: '0 auto' }} justifyContent="center" alignItems="center" gap="20px">
        <Note />
        <FormattedMessage id="patientProfile..label.prescriptionHistory.noPrescription" />
      </Stack>
    </Stack>
  );
};
const PatientPrescriptionHistory = ({ patient }: PatientSessionProps) => {
  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const { page, pageSize, paginationProps } = useTablePagination({ defaultPageSize: 5 });
  const { data: prescriptionPage } = useFetch(
    API_SERVER.prescription.getPatientPrescriptions(patient.id!, {
      type: isDentalClinic ? PRESCRIPTION_TYPE.DENTAL_EXAM : PRESCRIPTION_TYPE.ENCOUNTER,
      page,
      pageSize,
    }),
  );

  return (
    <PatientDetailSession titleKey="patient.patientDetail.session.title.drugHistory">
      {prescriptionPage?.content?.length === 0 ? (
        <NoData />
      ) : (
        <Table
          columns={COLUMNS}
          data={prescriptionPage?.content}
          dataKey={'id'}
          paginationProps={{ ...paginationProps, rowsPerPageOptions: [5, 10, 20] }}
          totalElements={prescriptionPage?.pagination.totalElements}
        />
      )}
    </PatientDetailSession>
  );
};

export default PatientPrescriptionHistory;
