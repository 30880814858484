import { Delete } from '@mui/icons-material';
import { Box, InputLabel, Popper, Typography } from '@mui/material';
import moment from 'moment/moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from 'modules/common/component/DialogCustom';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { DECIMAL_NUMBER_REGEX, NUMBER_REGEX_NO_ZERO_HEADER } from 'modules/common/regex';
import SchemaForm from 'modules/common/SchemaForm';
import { ROUTES } from 'modules/layout/router';
import { INVENTORY_IMPORT_STATUS, MEDICATION_UNIT } from '../inventory/constant';
import { axiosThunk } from 'modules/common/redux/axios';
import Stack from '@mui/material/Stack';
import styled from '@emotion/styled';
import { NumericFormatText } from 'modules/common/utils';
import { Medication, MedicationSupplier } from 'modules/schema';

interface Props {
  onSubmit?: (formVal: some) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
  medicationImportData: Medication[];
  supplierData: MedicationSupplier[];
}

const StyledPopper = styled(Popper)({
  minWidth: '250px !important',
});

const FormDataDialog: React.FunctionComponent<Props> = (props) => {
  const { formData, onClose, onSubmit, open, medicationImportData, supplierData } = props;
  const { dispatch, API_SERVER } = useGeneralHook();

  const supplierOption = React.useMemo(() => {
    return supplierData?.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    }));
  }, [supplierData]);

  const status = INVENTORY_IMPORT_STATUS.find((v) => v.value === formData?.status)?.label;

  return (
    <DialogCustom
      open={!!open}
      maxWidth="lg"
      onClose={onClose}
      disableCloseOutSide
      disableEscapeKeyDown
      PaperProps={{
        style: { width: 1460 },
      }}
      title={formData?.readOnly ? 'inventory.view' : formData?.id ? 'inventory.update' : 'inventory.create'}
    >
      <Box padding={2}>
        <SchemaForm
          onSubmit={onSubmit}
          initialData={formData}
          readOnly={formData?.readOnly}
          noHelperText={formData?.readOnly}
          onCancel={onClose}
          cancelLabel={<FormattedMessage id={'destroy'} />}
          formProps={{
            style: {
              flex: 1,
            },
          }}
          schema={{
            fields: ({ formProps, valuesField: valuesFieldAll }) => {
              const { intl, readOnly } = formProps || {};

              return {
                code: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="inventory.code" />
                        </InputLabel>
                        <Typography variant="subtitle1">{formData?.code}</Typography>
                      </div>
                    );
                  },
                  hidden: !formData?.id,
                  propsWrapper: { xs: 2 },
                },
                importDate: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="inventory.importDate" />
                        </InputLabel>
                        <Typography variant="subtitle1">
                          {moment(formData?.importDate).format(FE_DATE_FORMAT)}
                        </Typography>
                      </div>
                    );
                  },
                  propsWrapper: { xs: 3 },
                  register: {
                    required: true,
                  },
                  readOnly: true,
                  hidden: formData?.status !== 'IMPORTED' && formData?.status !== 'CANCELLED',
                },
                importBy: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="inventory.warehouser" />
                        </InputLabel>
                        <Typography>{formData?.importBy}</Typography>
                      </div>
                    );
                  },
                  propsWrapper: { xs: 3 },
                  hidden: formData?.status !== 'IMPORTED' && formData?.status !== 'PROCESSING',
                },
                status: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="status" />
                        </InputLabel>
                        <Typography
                          noWrap
                          sx={{
                            color:
                              status === 'new'
                                ? 'text.secondary'
                                : status === 'processing'
                                ? 'warning.main'
                                : status === 'imported'
                                ? 'success.main'
                                : status === 'cancelled'
                                ? 'error.main'
                                : 'primary.main',
                            fontWeight: '500',
                          }}
                        >
                          <FormattedMessage id={`inventory.import.status.` + status} />
                        </Typography>
                      </div>
                    );
                  },
                  hidden: !formData?.id,
                  propsWrapper: { xs: 4 },
                },
                inventoryImportDetails: {
                  type: 'array',
                  label: intl.formatMessage({ id: 'inventory.drugList' }),
                  labelAdd: intl.formatMessage({ id: 'inventory.addNew' }),
                  hiddenLabelAdd: valuesFieldAll?.inventoryImportDetails?.length >= 100,
                  disableCloseBtn: true,
                  hideInitialElement: readOnly,
                  paperItemProps: {
                    sx: { padding: '12px 24px', bgcolor: 'grey.50' },
                    variant: 'elevation',
                    elevation: 0,
                  },
                  defaultData: {
                    medication: null,
                    drugInfo: null,
                    medicationSupplier: null,
                    lot: null,
                    expiredDate: null,
                    quantity: null,
                    unit: null,
                    unitPrice: null,
                  },
                  schema: {
                    fields: ({ formProps, valuesField, methods, fieldName }) => {
                      const { arrayData, methodsArray } = formProps;
                      const medicationImmutable =
                        formData?.readOnly || (formData?.status !== 'NEW' && formData?.id && valuesField?.id);
                      // const unitImmutable =
                      //     formData?.readOnly || (formData?.status != 'NEW' && formData?.id && valuesField?.id);
                      return {
                        medication: {
                          type: medicationImmutable ? 'label' : 'auto-complete',
                          value: valuesField.medication?.name,
                          variant: 'subtitle1',
                          label: intl.formatMessage({ id: 'inventory.drugName' }),
                          placeholder: intl.formatMessage({ id: 'enter' }),
                          propsWrapper: { xs: 4 },
                          options: medicationImportData,
                          register: {
                            required: true,
                          },
                          // readOnly:
                          //   formData?.readOnly || (formData?.status != 'NEW' && formData?.id && valuesField?.id),
                          getOptionLabel: (option) =>
                            option
                              ? `${option.id} - ${option.name} (${option.ingredient ? option.ingredient : ''})`
                              : ' ',
                        },
                        drugInfo: {
                          unregister: true,
                          type: () => (
                            <div style={{ display: 'flex', gap: 16 }}>
                              <div style={{ flex: 1 }}>
                                <Typography variant="body2" color="grey.600" fontWeight={500}>
                                  <FormattedMessage id="inventory.drugCode" />
                                </Typography>
                                <Typography variant="body2" mt={0.5}>
                                  {valuesField?.medication?.code}
                                </Typography>
                              </div>
                              <div style={{ flex: 1 }}>
                                <Typography variant="body2" color="grey.600" fontWeight={500}>
                                  <FormattedMessage id="inventory.manufacturer" />
                                </Typography>
                                <Typography variant="body2" mt={0.5}>
                                  {valuesField?.medication?.manufacturer?.name}
                                </Typography>
                              </div>
                            </div>
                          ),
                          propsWrapper: { xs: 4 },
                        },
                        medicationSupplier: {
                          type: 'auto-complete',
                          label: intl.formatMessage({ id: 'inventory.supplier' }),
                          placeholder: intl.formatMessage({ id: 'enter' }),
                          disableSearchByText: true,
                          options: supplierOption,
                          labelKey: 'name',
                          propsWrapper: { xs: 4 },
                          noHelperText: true,
                          onChange: (value) => {
                            if (value) {
                              methods.setValue(`${fieldName}.detailChanged`, true);
                            }
                          },
                        },
                        lot: {
                          type: 'auto-complete',
                          freeSolo: true,
                          label: intl.formatMessage({ id: 'inventory.lot' }),
                          placeholder: intl.formatMessage({ id: 'enter' }),
                          loadKey: valuesField?.medication?.id,
                          propsWrapper: { xs: 2 },
                          PopperComponent: StyledPopper,
                          register: {
                            required: true,
                          },
                          renderOption: (props, option) => {
                            return (
                              <Box component="li" {...props} key={option.lot}>
                                <Box>
                                  <Stack alignItems="baseline" direction="row">
                                    <Typography variant="subtitle1">
                                      <FormattedMessage id="inventory.lotShort" />
                                      :&nbsp;
                                    </Typography>
                                    <Typography variant="body1">{option.lot}</Typography>
                                  </Stack>
                                  <Stack alignItems="baseline" direction="row">
                                    <Typography variant="subtitle1">
                                      <FormattedMessage id="expiredDate" />
                                      :&nbsp;
                                    </Typography>
                                    <Typography variant="body1">
                                      {option.expiredDate && moment(option.expiredDate).format(FE_DATE_FORMAT)}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Box>
                            );
                          },
                          getOptionLabel: (option) => option.lot || option,
                          onChange: (value) => {
                            methods.setValue(
                              `${fieldName}.expiredDate`,
                              moment(value?.expiredDate, BE_DATE_TIME_FORMAT) || '',
                            );
                            if (value?.medicationUnitSettings?.length === 1) {
                              methods.setValue(`${fieldName}.unit`, value?.medicationUnitSettings?.[0].unit);
                            } else {
                              methods.setValue(`${fieldName}.unit`, null);
                            }

                            methods.setValue(`${fieldName}.detailChanged`, true);
                          },
                          loadOptions: async () => {
                            const json = valuesField?.medication?.id
                              ? await dispatch(
                                  axiosThunk({
                                    url: API_SERVER.inventory.getInventoryInfo(valuesField?.medication?.id).url,
                                  }),
                                )
                              : { data: [] };
                            // const listLot = json?.data.filter((ele) => moment(ele.expiredDate).isAfter(new Date()));
                            methods.setValue(`${fieldName}.listLot`, json?.data);
                            return json?.data;
                          },
                          onChangeInput: (event) => {
                            methods.setValue(`${fieldName}.lot`, event.target.value);
                            if (event.target.value) {
                              methods.setValue(`${fieldName}.detailChanged`, true);
                            }
                          },
                        },
                        listLot: {
                          type: 'hidden',
                          unregister: true,
                          shouldUnregister: true,
                        },
                        detailChanged: {
                          type: 'hidden',
                          unregister: true,
                          shouldUnregister: true,
                        },
                        expiredDate: {
                          type: 'datePicker',
                          label: intl.formatMessage({ id: 'expiredDate' }),
                          propsWrapper: { xs: 2 },
                          disablePast: true,
                          register: {
                            required: true,
                            validate: (value) => {
                              return (formData?.status === 'IMPORTED' &&
                                valuesField?.medication?.id &&
                                !valuesField?.detailChanged) ||
                                (moment(value, BE_DATE_FORMAT, true).isValid() &&
                                  moment(value, BE_DATE_FORMAT, true).isSameOrAfter(moment().startOf('day')))
                                ? true
                                : intl.formatMessage({ id: 'validation.invalidDate' });
                            },
                          },
                          onChange: (value) => {
                            if (value) {
                              methods.setValue(`${fieldName}.detailChanged`, true);
                            }
                          },
                        },
                        quantity: {
                          type: 'text-field',
                          inputType: 'number',
                          label: intl.formatMessage({ id: 'inventory.quantity' }),
                          placeholder: intl.formatMessage({ id: 'enter' }),
                          propsWrapper: { xs: 2 },
                          register: {
                            required: true,
                            pattern: {
                              value: NUMBER_REGEX_NO_ZERO_HEADER,
                              message: intl.formatMessage({ id: 'inventory.quantityValid' }),
                            },
                            max: {
                              value: 1000000000,
                              message: intl.formatMessage({ id: 'validation.invalid' }),
                            },
                            validate: (value) => {
                              return !valuesField?.unitPrice ||
                                !valuesField?.quantity ||
                                valuesField?.unitPrice * valuesField?.quantity < 9999999999999999
                                ? true
                                : intl.formatMessage({ id: 'inventory.import.totalAmount.max' });
                            },
                          },
                          tooltipError: true,
                          onChange: (value) => {
                            if (value) {
                              methods.setValue(`${fieldName}.detailChanged`, true);
                            }
                          },
                        },
                        unit: {
                          type: 'select',
                          options: MEDICATION_UNIT.filter((unit) => {
                            const lot = valuesField?.lot;
                            const existsLot = valuesField?.listLot?.find((l) => l?.lot === lot);
                            if (lot && typeof lot === 'object') {
                              //case import exists lot, get list unit from medication unit setting
                              return lot.medicationUnitSettings?.find((u) => u.unit === unit.value);
                              // )
                            } else if (lot && typeof lot === 'string' && existsLot) {
                              return existsLot.medicationUnitSettings?.find((u) => u.unit === unit.value);
                            } else {
                              // case import new lot, get list unit from medication
                              const medicationN = medicationImportData?.find(
                                (d) => d.id === valuesField?.medication?.id,
                              );
                              return (
                                unit.value === medicationN?.basicUnit ||
                                medicationN?.unitConverterList?.find((u) => u.fromUnit === unit.value)
                              );
                            }
                          }),
                          label: intl.formatMessage({ id: 'inventory.unit' }),
                          placeholder: intl.formatMessage({ id: 'select' }),
                          propsWrapper: { xs: 2 },
                          register: {
                            required: true,
                          },
                          onChange: (value) => {
                            if (value) {
                              methods.setValue(`${fieldName}.detailChanged`, true);
                            }
                          },
                          // readOnly:
                          //   formData?.readOnly || (formData?.status != 'NEW' && formData?.id && valuesField?.id),
                        },
                        unitPrice: {
                          type: 'number-field',
                          inputType: 'number',
                          label: intl.formatMessage({ id: 'price' }),
                          placeholder: intl.formatMessage({ id: 'enter' }),
                          propsWrapper: { xs: 2 },
                          register: {
                            required: true,
                            maxLength: {
                              value: 16,
                              message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                            },
                            pattern: DECIMAL_NUMBER_REGEX,
                            validate: (value) => {
                              return !valuesField?.unitPrice ||
                                !valuesField?.quantity ||
                                valuesField?.unitPrice * valuesField?.quantity < 9999999999999999
                                ? true
                                : intl.formatMessage({ id: 'inventory.import.totalAmount.max' });
                            },
                          },
                          tooltipError: true,
                          onChange: (value) => {
                            if (value) {
                              methods.setValue(`${fieldName}.detailChanged`, true);
                            }
                          },
                        },
                        totalPrice: {
                          unregister: true,
                          type: () => {
                            const price = (valuesField.unitPrice || 0) * (valuesField.quantity || 0);
                            return (
                              <div>
                                <Typography variant="body2" color="grey.600" fontWeight={500}>
                                  <FormattedMessage id="totalPrice" />
                                </Typography>
                                <Typography variant="subtitle1" fontWeight={500} mt={0.5}>
                                  {price > 0 && NumericFormatText(price)}{' '}
                                </Typography>
                              </div>
                            );
                          },
                          propsWrapper: { xs: 2 },
                        },
                        delete: {
                          unregister: true,
                          hidden: readOnly || valuesField.isLocked,
                          type: () => (
                            <IconButtonTitle
                              title="delete"
                              size="small"
                              style={{ height: 'fit-content' }}
                              onClick={() => methodsArray?.remove(arrayData?.index)}
                            >
                              <Delete />
                            </IconButtonTitle>
                          ),
                          propsWrapper: {
                            xs: undefined,
                            style: {
                              justifyContent: 'center',
                              alignItems: 'center',
                              display: 'flex',
                              height: '100%',
                            },
                          },
                        },
                        basicUnitWarning: {
                          unregister: true,
                          hidden:
                            valuesField?.medication === null ||
                            valuesField?.medication === undefined ||
                            medicationImportData?.find((d) => d.id === valuesField?.medication?.id)?.basicUnit ||
                            formData?.readOnly ||
                            (formData?.status !== 'NEW' && formData?.id && valuesField?.id),
                          type: () => {
                            return (
                              <Typography
                                color="primary"
                                style={{
                                  textDecoration: 'underline',
                                  cursor: 'pointer',
                                  color: 'red',
                                }}
                                onClick={() => {
                                  window.open(
                                    `${ROUTES.clinicDrugWarehouse}?medicationId=${valuesField?.medication?.id}`,
                                    '_blank',
                                  );
                                }}
                              >
                                {intl.formatMessage({ id: 'inventory.medication.baseUnit.warning' })}
                              </Typography>
                            );
                          },
                        },
                        medicationDuplicateWarning: {
                          unregister: true,
                          hidden:
                            !valuesField?.medication ||
                            !valuesField?.medication?.id ||
                            valuesFieldAll?.inventoryImportDetails?.filter(
                              (u) => u.medication?.id === valuesField?.medication?.id,
                            )?.length <= 1,
                          type: () => {
                            return (
                              <Typography color="primary" style={{ color: 'red' }}>
                                {intl.formatMessage({ id: 'inventory.medication.duplicate.warning' })}
                              </Typography>
                            );
                          },
                        },
                        productLockedWarning: {
                          unregister: true,
                          hidden: !valuesField?.isLocked,
                          type: () => {
                            return (
                              <Typography color="warning.main">
                                {intl.formatMessage({ id: 'inventory.import.product.locked' })}
                              </Typography>
                            );
                          },
                        },
                      };
                    },
                    ui: [
                      {
                        id: 'default',
                        propsWrapper: { xs: true },
                        fields: [
                          'medication',
                          'drugInfo',
                          'medicationSupplier',
                          'lot',
                          'expiredDate',
                          'quantity',
                          'unit',
                          'unitPrice',
                          'totalPrice',
                        ].filter(Boolean),
                      },
                      {
                        id: 'action',
                        propsWrapper: { xs: undefined },
                        propsGridContainer: { style: { height: '100%' } },
                        fields: ['delete'],
                      },
                      {
                        id: 'medicationDuplicateWarning',
                        fields: ['medicationDuplicateWarning'],
                        propsWrapper: {
                          style: { paddingTop: '0' },
                        },
                      },
                      {
                        id: 'basicUnitWarning',
                        fields: ['basicUnitWarning'],
                        propsWrapper: {
                          style: { paddingTop: '0' },
                        },
                      },
                      {
                        id: 'productLockedWarning',
                        fields: ['productLockedWarning'],
                        propsWrapper: {
                          style: { paddingTop: '0' },
                        },
                      },
                    ],
                  },
                },
                note: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'note' }),
                  placeholder: intl.formatMessage({ id: 'noteEnter' }),
                  multiline: true,
                  rows: 3,
                  disabled: readOnly,
                  propsWrapper: {
                    style: { paddingTop: '0' },
                  },
                  required: false,
                  register: {
                    maxLength: {
                      value: 10000,
                      message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                    },
                  },
                },
              };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default FormDataDialog;
