import * as React from 'react';
import { useParams } from 'react-router';
import { API_SERVER } from '../../common/api';
import { Paper } from '@mui/material';
import TablePriceBox from '../component/therapy/detail/TablePriceBox';
import BasicEOCInfo from 'modules/cashier/component/therapy/detail/BasicEocInfo';
import PaymentHistoryFormDialog from '../component/therapy/PaymentHistoryTableDialog';
import { some } from '../../common/constants';
import PrepaymentRefundPrintDialog from '../component/therapy/detail/PrintForm/PrepaymentRefundPrintDialog';
import PaymentHistoryPrintDialog from '../component/therapy/detail/PrintForm/PaymentHistoryPrintDialog';
import PaymentRefundHistoryPrintDialog from '../component/therapy/detail/PrintForm/PaymentRefundHistoryPrintDialog';
import { useFetch } from 'modules/common/hook';

interface Props {}

const CashierTherapyDetailPage: React.FunctionComponent<Props> = (props) => {
  const { therapyId, paymentId } = useParams<{ therapyId: string; paymentId: string }>();
  const [openPaymentHistoryDialog, setOpenPaymentHistoryDialog] = React.useState(false);
  const [prepaymentRefundPrint, setPrepaymentRefundPrint] = React.useState<some>();
  const [paymentHistoryPrint, setPaymentHistoryPrint] = React.useState<some>();
  const [paymentRefundHistoryPrint, setPaymentRefundHistoryPrint] = React.useState<some>();

  const {
    data: therapyDetail,
    revalidate: revalidateTherapy,
    isValidating: isValidatingTherapyDetail,
  } = useFetch(API_SERVER.therapy.detail(parseInt(therapyId)), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });

  const {
    data: paymentDetail,
    revalidate: revalidatePaymentDetail,
    isValidating: isValidatingPaymentDetail,
  } = useFetch(API_SERVER.payment.detail(parseInt(paymentId), { type: 'THERAPY' }), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });

  const {
    data: prepaymentDetail,
    revalidate: revalidatePrepaymentDetail,
    isValidating: isValidatingPrepaymentDetail,
  } = useFetch(API_SERVER.prepayment.getPrepayment({ paymentId: parseInt(paymentId) }), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });

  const swrPaymentHistory = useFetch(API_SERVER.paymentHistory.getList(parseInt(paymentId)), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    enabled: openPaymentHistoryDialog,
  });

  return (
    <Paper elevation={1} style={{ overflow: 'auto', padding: 16, flex: 1 }}>
      <BasicEOCInfo data={therapyDetail} setOpenPaymentHistoryFormDialog={setOpenPaymentHistoryDialog} />
      <TablePriceBox
        paymentDetail={paymentDetail}
        therapyDetail={therapyDetail}
        prepaymentDetail={prepaymentDetail}
        paymentAdvanceList={prepaymentDetail?.paymentAdvances}
        // onApplyDiscount={onApplyDiscount}
        revalidate={() => {
          revalidateTherapy();
          revalidatePaymentDetail();
          revalidatePrepaymentDetail();
        }}
        loading={isValidatingTherapyDetail || isValidatingPaymentDetail || isValidatingPrepaymentDetail}
      />
      <PaymentHistoryFormDialog
        open={openPaymentHistoryDialog}
        onClose={() => {
          setOpenPaymentHistoryDialog(false);
        }}
        data={swrPaymentHistory}
        onOpenPrepaymentRefundPrint={setPrepaymentRefundPrint}
        onOpenPaymentHistoryPrint={setPaymentHistoryPrint}
        onOpenPaymentRefundHistoryPrint={setPaymentRefundHistoryPrint}
      />
      <PrepaymentRefundPrintDialog
        open={!!prepaymentRefundPrint}
        prepaymentRefundPrint={prepaymentRefundPrint}
        therapyDetail={therapyDetail}
        onClose={() => {
          setPrepaymentRefundPrint(undefined);
        }}
      />
      {paymentHistoryPrint && (
        <PaymentHistoryPrintDialog
          open={!!paymentHistoryPrint}
          paymentHistoryPrint={paymentHistoryPrint}
          therapyDetail={therapyDetail}
          onClose={() => {
            setPaymentHistoryPrint(undefined);
          }}
        />
      )}
      {paymentRefundHistoryPrint && (
        <PaymentRefundHistoryPrintDialog
          open={!!paymentRefundHistoryPrint}
          paymentHistoryPrint={paymentRefundHistoryPrint}
          therapyDetail={therapyDetail}
          onClose={() => {
            setPaymentRefundHistoryPrint(undefined);
          }}
        />
      )}
    </Paper>
  );
};

export default CashierTherapyDetailPage;
