import { FieldArrayWithId } from 'react-hook-form';
import { CheckboxTypeFilter, FormFilter } from './type';
import FilterGroup from './FilterGroup';
import CheckboxList from './CheckboxList';
import { GridProps } from '@mui/material';

interface Props<T extends CheckboxTypeFilter = CheckboxTypeFilter> {
  title: string;
  name: CheckboxTypeFilter;
  getOptionLabel: (field: FieldArrayWithId<FormFilter, T, 'id'>) => string;
  xs?: GridProps['xs'];
}

const CheckboxFilter = ({ title, name, getOptionLabel, xs }: Props) => {
  return (
    <FilterGroup title={title}>
      <CheckboxList getOptionLabel={getOptionLabel} name={name} xs={xs || 3} />
    </FilterGroup>
  );
};

export default CheckboxFilter;
