import { Box, Grid } from '@mui/material';
import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { PRIMARY } from '../../../../colors';
import { API_SERVER } from '../../../common/api';
import { some } from '../../../common/constants';
import { axiosThunk } from '../../../common/redux/axios';
import { PropsArrayElement } from '../../../common/SchemaForm/element/array-element/ArrayElement';
import SchemaElement from '../../../common/SchemaForm/SchemaElement';
import ProductCard from './ProductCard';

export const getTotalPrice = (valuesField: some) => {
  const price = Number(valuesField.quantity || 0) * Number(valuesField.unitPrice || 0);
  const discount =
    valuesField.discountType === 'PERCENT'
      ? (Number(valuesField.discount || 0) * price) / 100
      : Number(valuesField.discount || 0);
  const vat = ((price - discount) * Number(valuesField.vat || 0)) / 100;
  const totalPrice = price - discount + vat;
  return totalPrice > 0 ? totalPrice : 0;
};

interface Props extends PropsArrayElement {}

const ProductList = (props: Props) => {
  const { formProps, methodsArray, name } = props;
  const { dispatch, intl, valuesField } = formProps;
  const { fields, append, remove, update } = methodsArray;

  return (
    <>
      <Box sx={{ backgroundColor: PRIMARY[50], px: 1, py: 2 }}>
        <Grid container spacing={2}>
          <SchemaElement
            fieldName={'groupProduct'}
            propsElement={{
              type: 'auto-complete',
              loadKey: valuesField?.groupProduct?.id,
              disableSearchByText: true,
              loadOptions: async (name) => {
                const json = await dispatch(
                  axiosThunk({ url: API_SERVER.medicationCategory.getCategoryByClinicId({ productName: name }).url }),
                );
                return json.data;
              },
              getOptionLabel: (option) => option.name,
              label: intl.formatMessage({ id: 'sale.groupProduct' }),
              placeholder: intl.formatMessage({ id: 'select' }),
              noHelperText: true,
              propsWrapper: { xs: 6 },
            }}
          />
          <SchemaElement
            fieldName={'product'}
            propsElement={{
              type: 'auto-complete',
              loadKey: valuesField?.groupProduct?.id,
              disableSearchByText: true,
              loadOptions: async () => {
                const json = await dispatch(
                  axiosThunk({
                    url: API_SERVER.medication.inventories({ categoryId: valuesField?.groupProduct?.id }).url,
                  }),
                );
                return json?.data;
              },
              getOptionLabel: (option) => `${option?.id} - ${option?.name}`,
              label: intl.formatMessage({ id: 'sale.product' }),
              placeholder: intl.formatMessage({ id: 'select' }),
              onChange: (data) => {
                if (data) {
                  append({ ...data, quantity: 0 });
                }
              },
              noHelperText: true,
              propsWrapper: { xs: 6 },
            }}
          />
        </Grid>
      </Box>
      {fields?.length > 0 ? (
        fields.map((item: some, index: number) => {
          return (
            <Box
              key={item.key}
              sx={{ backgroundColor: PRIMARY[50], display: 'flex', gap: 2, px: 1, pt: 2, pb: 1, mt: 1 }}
            >
              <ProductCard
                name={name}
                valuesFieldAll={valuesField}
                index={index}
                item={item}
                remove={remove}
                update={update}
                formProps={{
                  ...formProps,
                  arrayData: { value: item, index: index },
                  methodsArray,
                }}
              />
            </Box>
          );
        })
      ) : (
        <Box display={'flex'} alignItems={'center'} justifyContent={'center'} p={2}>
          <FormattedMessage id="noData" />
        </Box>
      )}
    </>
  );
};

export default memo(ProductList);
