import { some } from '../../../common/constants';
import { UseFormReturn } from 'react-hook-form';
import * as React from 'react';
import SchemaForm from '../../../common/SchemaForm';
import DialogCustom from '../../../common/component/DialogCustom';
import { Box, InputLabel, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { MEDICATION_UNIT } from './constant';
import { NUMBER_REGEX_NO_ZERO_HEADER } from '../../../common/regex';
import { useFetch } from 'modules/common/hook';
import { Inventory } from 'modules/schema';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: Inventory;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { open, onSubmit, formData, onClose } = props;
  const { intl, API_SERVER } = useGeneralHook();

  const listUnit = formData?.unitConverterList?.map((c) => c.fromUnit) || [];

  const { data: alertData, isValidating } = useFetch(API_SERVER.inventoryAlertConfig.index(formData?.medicationId), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    enabled: open,
  });

  if (isValidating) {
    return null;
  }

  return (
    <>
      <SchemaForm
        onSubmit={onSubmit}
        initialData={{ ...alertData, medicationId: formData?.medicationId }}
        onCancel={onClose}
        formId="inventory-alert-config"
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
        schema={{
          fields: ({ formProps, valuesField }) => {
            return {
              name: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="inventory.drugName" />
                      </InputLabel>
                      <Typography>{formData?.name}</Typography>
                    </div>
                  );
                },
                propsWrapper: { xs: 6 },
                unregister: true,
              },
              code: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="ID" />
                      </InputLabel>
                      <Typography>{formData?.medicationId}</Typography>
                    </div>
                  );
                },
                propsWrapper: { xs: 6 },
                unregister: true,
              },
              quantity: {
                type: 'text-field',
                inputType: 'number',
                label: intl.formatMessage({ id: 'inventory.alert.label.quantity' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
                propsWrapper: { xs: 4 },
                required: false,
                register: {
                  pattern: {
                    value: NUMBER_REGEX_NO_ZERO_HEADER,
                    message: intl.formatMessage({ id: 'inventory.quantityValid' }),
                  },
                  maxLength: {
                    value: 19,
                    message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                  },
                },
                tooltipError: true,
              },
              unit: {
                type: 'select',
                options: MEDICATION_UNIT.filter((unit) => listUnit?.indexOf(unit.value) > -1),
                label: intl.formatMessage({ id: 'inventory.unit' }),
                placeholder: intl.formatMessage({ id: 'select' }),
                propsWrapper: { xs: 4 },
                register: {
                  required: !!valuesField?.quantity,
                },
              },
              blank: {
                unregister: true,
                type: () => {
                  return (
                    <div
                      style={{
                        height: 67,
                        borderRight: '2px solid gray',
                      }}
                    />
                  );
                },
                propsWrapper: { xs: undefined },
              },
              remainingDays: {
                type: 'text-field',
                inputType: 'number',
                label: intl.formatMessage({ id: 'inventory.alert.label.remainingDays' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
                propsWrapper: { xs: 3 },
                required: false,
                register: {
                  pattern: {
                    value: NUMBER_REGEX_NO_ZERO_HEADER,
                    message: intl.formatMessage({ id: 'inventory.quantityValid' }),
                  },
                  maxLength: {
                    value: 10,
                    message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                  },
                },
                tooltipError: true,
              },
            };
          },
          ui: [
            {
              id: 'medicationInfo',
              fields: ['name', 'code'],
            },
            {
              id: 'alertInfo',
              title: intl.formatMessage({ id: 'inventory.alert.label.configLabel' }),
              fields: ['quantity', 'unit', 'blank', 'remainingDays'],
            },
          ],
        }}
      />
    </>
  );
};

const AlertConfigDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose } = props;
  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      disableCloseOutSide
      disableEscapeKeyDown
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 800 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={'inventory.alert.config'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default AlertConfigDialog;
