import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from '../../../../common/component/TableCustom';
import { FE_DATE_FORMAT } from '../../../../common/constants';
import { IndicationHistory } from 'modules/schema';
import { INDICATION_LABORATORY_EVALUATE } from 'modules/common/apiConstants';
import IndexGraph from './IndexGraph';

interface Props {
  indicationHistories: IndicationHistory[];
  unit: string;
}

const COLUMNS = [
  {
    title: 'encounterDate',
    render: (record) => record?.dateTimeFinish?.format(FE_DATE_FORMAT),
  },
  {
    title: 'referenceIndication.value',
    dataIndex: 'result',
    align: 'right',
  },
  {
    title: 'referenceIndication.review',
    render: (record) => <FormattedMessage id={INDICATION_LABORATORY_EVALUATE?.[record?.evaluate!]?.label || ' '} />,
  },
] as Columns<IndicationHistory>[];

const StatisticLabResult: React.FunctionComponent<Props> = ({ indicationHistories, unit }) => {
  return (
    <Box display="flex" padding={1}>
      <Box flex={1.5}>
        <Typography variant="subtitle1" color="textSecondary" style={{ marginTop: 16 }}>
          <FormattedMessage id="referenceIndication.result" />
        </Typography>
        <TableCustom
          dataSource={indicationHistories}
          containerProps={{ style: { maxHeight: 400 } }}
          columns={COLUMNS}
        />
      </Box>
      <Box flex={2} padding={1}>
        <IndexGraph indicationHistories={indicationHistories} unit={unit} />
      </Box>
    </Box>
  );
};

export default StatisticLabResult;
