import { Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FormattedMessage } from 'react-intl';
import { FE_DATE_FORMAT, FE_TIME_FORMAT, some } from '../../../common/constants';
import React, { useMemo } from 'react';
import { APPOINTMENT_TYPES } from './constants';
import { Appointment } from 'modules/schema';

type Props = {
  data: Appointment;
  onClose?: () => void;
  onUpdate?: (value: some) => void;
  onDelete?: (value: some) => void;
  sx?: any;
};

export const AppointmentInfoBox = (props: Props & { headerComponent?: React.ComponentType<Props> }) => {
  const { data, sx, headerComponent: Header, onUpdate, onDelete, onClose } = props;
  const isThearpyType = data?.type === APPOINTMENT_TYPES.THERAPY.value;

  const lightColor = useMemo(() => {
    return data.appointmentGroupId
      ? APPOINTMENT_TYPES.APPOINTMENT_GROUP.lightColor
      : data.patientId
      ? APPOINTMENT_TYPES.PATIENT.lightColor
      : data.type === 'THERAPY'
      ? APPOINTMENT_TYPES.THERAPY.lightColor
      : '';
  }, [data]);
  return (
    <>
      <Box
        sx={{
          p: 1,
          bgcolor: lightColor,
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          userSelect: 'text',
          ...sx,
        }}
      >
        {Header && <Header onDelete={onDelete} onClose={onClose} onUpdate={onUpdate} data={data} />}
        <Box>
          <Typography variant="caption" color="primary" style={{ textTransform: 'uppercase', fontWeight: 500 }}>
            <FormattedMessage id="calendar.timeAppointment" />
          </Typography>
          <Typography variant="body1">
            {data?.startTime?.format(FE_TIME_FORMAT)}&nbsp;-&nbsp;{data?.endTime?.format(FE_TIME_FORMAT)}
            &emsp;{data?.startTime?.format(FE_DATE_FORMAT)}
          </Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="primary" style={{ textTransform: 'uppercase', fontWeight: 500 }}>
            <FormattedMessage
              id={isThearpyType ? 'sale.customer' : data.appointmentGroupId ? 'calendar.presentName' : 'patient'}
            />
          </Typography>
          <Typography variant="body1">{data?.name}</Typography>
        </Box>
        <Box>
          <Typography variant="caption" color="primary" style={{ textTransform: 'uppercase', fontWeight: 500 }}>
            <FormattedMessage id="telephone" />
          </Typography>
          <Typography variant="body1"> {data?.mobilePhone}</Typography>
        </Box>
        {data?.address && (
          <Box>
            <Typography variant="caption" color="primary" style={{ textTransform: 'uppercase', fontWeight: 500 }}>
              <FormattedMessage id="address" />
            </Typography>
            <Typography variant="body1">{data?.address}</Typography>
          </Box>
        )}
        {data.appointmentGroupId && (
          <Box>
            <Typography variant="caption" color="primary" style={{ textTransform: 'uppercase', fontWeight: 500 }}>
              <FormattedMessage id="calendar.numOfCustomer" />
            </Typography>
            <Typography variant="body1">{data?.quantity}</Typography>
          </Box>
        )}
        <Box>
          <Typography variant="caption" color="primary" style={{ textTransform: 'uppercase', fontWeight: 500 }}>
            <FormattedMessage
              id={isThearpyType ? 'therapyList.header.label.therapySession.picName' : 'calendar.doctor'}
            />
          </Typography>
          <Typography variant="body1">{data?.picName}</Typography>
        </Box>
        {data.reason?.trim() && (
          <Box>
            <Typography variant="caption" color="primary" style={{ textTransform: 'uppercase', fontWeight: 500 }}>
              <FormattedMessage id={isThearpyType ? 'therapy.sessionContent' : 'reason'} />
            </Typography>
            <Typography variant="body1">{data?.reason}&nbsp;</Typography>
          </Box>
        )}
      </Box>
    </>
  );
};

export default AppointmentInfoBox;
