import { styled } from '@mui/system';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import {
  BE_DATE_TIME_FORMAT,
  FE_DATE_TIME_FORMAT,
  INDICATION_REQUEST_STATUS,
  some,
  SYSTEM_CATEGORY_SCOPE_LABEL,
} from '../../../../../../common/constants';
import * as React from 'react';
import TableCustom, { Columns } from '../../../../../../common/component/TableCustom';
import {
  THERAPY_SESSION_DETAIL_COLUMNS,
  THERAPY_SESSION_INDICATION_COLUMNS,
  THERAPY_SESSION_MEDICATION_COLUMNS,
  THERAPY_SESSION_SUPPLIES_COLUMNS,
} from './tableColumns';
import moment from 'moment';
import { THERAPY_SESSION_STATUS } from '../../../../../../reception/constants';
import useGeneralHook from '../../../../../../common/hook/useGeneralHook';
import { useMemo } from 'react';
import Stack from '@mui/material/Stack';
import DoctorSign from './DoctorSign';

interface Props {
  therapySession: some;
}

interface TableProps {
  data: some[];
  columns: Columns[];
}

const TitleStyled = styled(Typography)({
  color: '#546E7A',
  fontFamily: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  marginTop: '15px',
  marginBottom: '5px',
  backgroundColor: 'transparent',
});

const ConcludeStyled = styled(Typography)({
  color: '#263238',
  fontFamily: 'Roboto',
  fontSize: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.25px',
  wordBreak: 'break-all',
});

const Table = ({ data, columns }: TableProps) => {
  return (
    <TableCustom
      containerProps={{
        sx: {
          '& th,tr,td': {
            padding: '2px 8px',
            backgroundColor: 'white',
            border: '1px solid #000',
            color: 'unset',
          },
          width: '100%',
          overflow: 'visible',
          marginTop: '5px',
        },
      }}
      dataSource={data}
      columns={columns}
    />
  );
};

const TherapySessionDetailInfo = ({ therapySession }: Props) => {
  const { intl, appState } = useGeneralHook();
  const therapySessionDetailData = useMemo(
    () => [
      {
        ...therapySession,
        startTime: therapySession?.startTime
          ? moment(therapySession?.startTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT)
          : '',
        status: intl.formatMessage({
          id: THERAPY_SESSION_STATUS.find((ele) => ele.value === therapySession?.status)?.label,
        }),
      },
    ],
    [intl, therapySession],
  );
  const therapySessionIndicationData = useMemo(
    () =>
      therapySession?.indicationRequestList?.map((indication) => ({
        ...indication,
        scope: intl.formatMessage({
          id: SYSTEM_CATEGORY_SCOPE_LABEL?.find((ele) => ele.value === indication?.scope)?.label || ' ',
        }),
        status: intl.formatMessage({ id: INDICATION_REQUEST_STATUS?.[indication?.status]?.label || ' ' }),
        isFree: intl.formatMessage({
          id: indication?.isFree
            ? 'therapy.indication.request.printForm.isNotOutPackage'
            : 'therapy.indication.request.printForm.isOutPackage',
        }),
      })),
    [intl, therapySession?.indicationRequestList],
  );
  const therapySessionMedicationData = useMemo(
    () =>
      therapySession?.medicationList?.map((medication) => ({
        ...medication,
        indentedRoute: appState.common.intendedRoutes?.find((ele) => ele?.value === medication?.intendedRoute)?.label,
        isFree: intl.formatMessage({
          id: medication?.isFree
            ? 'therapy.indication.request.printForm.isNotOutPackage'
            : 'therapy.indication.request.printForm.isOutPackage',
        }),
      })),
    [appState.common.intendedRoutes, intl, therapySession?.medicationList],
  );
  const therapySessionSuppliesData = useMemo(
    () =>
      therapySession?.medicalSuppliesList?.map((supply) => ({
        ...supply,
        isFree: intl.formatMessage({
          id: supply?.isFree
            ? 'therapy.indication.request.printForm.isNotOutPackage'
            : 'therapy.indication.request.printForm.isOutPackage',
        }),
      })),
    [intl, therapySession?.medicalSuppliesList],
  );

  return (
    <Box>
      <TitleStyled>
        <FormattedMessage id="therapy.therapyDetail.printForm.title.therapySessionInfo" />
      </TitleStyled>
      <Table data={therapySessionDetailData} columns={THERAPY_SESSION_DETAIL_COLUMNS} />
      {therapySessionIndicationData && therapySessionIndicationData?.length > 0 && (
        <>
          <TitleStyled>
            <FormattedMessage id="therapy.therapyDetail.printForm.title.indication" />
          </TitleStyled>
          <Table data={therapySessionIndicationData} columns={THERAPY_SESSION_INDICATION_COLUMNS} />
        </>
      )}
      {therapySessionMedicationData && therapySessionMedicationData?.length > 0 && (
        <>
          <TitleStyled>
            <FormattedMessage id="therapy.therapyDetail.printForm.title.drugUsage" />
          </TitleStyled>
          <Table data={therapySessionMedicationData} columns={THERAPY_SESSION_MEDICATION_COLUMNS} />
        </>
      )}
      {therapySessionSuppliesData && therapySessionSuppliesData?.length > 0 && (
        <>
          <TitleStyled>
            <FormattedMessage id="therapy.therapyDetail.printForm.title.medicalSupplies" />
          </TitleStyled>
          <Table data={therapySessionSuppliesData} columns={THERAPY_SESSION_SUPPLIES_COLUMNS} />
        </>
      )}
      {therapySession?.conclude && (
        <>
          <TitleStyled>
            <FormattedMessage id="therapy.therapyDetail.printForm.title.therapyConclude" />
          </TitleStyled>
          <ConcludeStyled>{therapySession?.conclude}</ConcludeStyled>
        </>
      )}
      <Stack alignItems="flex-end" marginTop={3}>
        <DoctorSign />
      </Stack>
    </Box>
  );
};

export default TherapySessionDetailInfo;
