import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { MEDICATION_USAGE_TYPE } from 'modules/common/apiConstants';
import { IndicationRequestSchema } from './IndicationRequest';
import { MedicationSchema } from './Medication';

export const MedicationUsageSchema = z
  .object({
    id: z.number(),
    referenceId: z.number(),
    type: createObjectKeysEnumSchema(MEDICATION_USAGE_TYPE),
    medicationId: z.number(),
    medicationName: z.string(),
    medicationIngredient: z.string(),
    medicationUnit: z.string(),
    quantity: z.number().default(0),
    cancellationQuantity: z.number().default(0),
    cancellationReason: z.string(),
    isFree: z.boolean(),
    intendedRoute: z.string(),
    note: z.string(),
    indicationRequestId: z.number(),
    isPaid: z.boolean(),
    indicationRequest: IndicationRequestSchema,
    medication: MedicationSchema,
  })
  .partial();

export type MedicationUsage = z.infer<typeof MedicationUsageSchema>;
