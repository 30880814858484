import { z } from 'zod';
import { PrescriptionFormDetailTemplateSchema } from './PrescriptionFormDetailTemplate';

export const PrescriptionFormTemplateSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    advice: z.string(),
    status: z.string(),
    detailTemplateList: z.array(PrescriptionFormDetailTemplateSchema),
  })
  .partial();

export type PrescriptionFormTemplate = z.infer<typeof PrescriptionFormTemplateSchema>;
