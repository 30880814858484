import { Dictionary, get, keyBy } from 'lodash';
import { FilterType, FormFilter } from './type';
import { APPOINTMENT_STATUS, INDICATION_REQUEST_STATUS, PAYMENT_PLAN } from 'modules/common/constants';
import {
  APPOINTMENT_CHART_TYPE,
  APPOINTMENT_PATIENT_TYPE,
  DEBT_STATUS,
  ENCOUNTER_STATUS,
  PATIENT_TYPE,
  PAYMENT_HISTORY_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  SYSTEM_CATEGORY_SCOPE,
} from 'modules/common/apiConstants';

export const formatCheckboxOption = (filterMap: Dictionary<FilterType>, path: FilterType['type']) => {
  return get(filterMap[path], 'options')?.map((ele) => ({ ...ele, checked: false })) || [];
};

export const getFilterValue = (filters: FormFilter) => {
  return {
    PAYMENT_METHODS: filters['PAYMENT_METHODS'].filter((ele) => ele.checked),
    INDICATION_REQUEST_STATUS: filters['INDICATION_REQUEST_STATUS'].filter((ele) => ele.checked),
    INDICATION_REQUEST_SCOPE: filters['INDICATION_REQUEST_SCOPE'].filter((ele) => ele.checked),
    ENCOUNTER_STATUS: filters['ENCOUNTER_STATUS'].filter((ele) => ele.checked),
    DEBT_STATUS: filters['DEBT_STATUS'].filter((ele) => ele.checked),
    PAYMENT_PLAN: filters['PAYMENT_PLAN'].filter((ele) => ele.checked),
    APPOINTMENT_STATUS: filters['APPOINTMENT_STATUS'].filter((ele) => ele.checked),
    PATIENT_TYPE: filters['PATIENT_TYPE'].filter((ele) => ele.checked),
    APPOINTMENT_PATIENT_TYPE: filters['APPOINTMENT_PATIENT_TYPE'].filter((ele) => ele.checked),
    APPOINTMENT_CHART_TYPE: filters['APPOINTMENT_CHART_TYPE'].filter((ele) => ele.checked),
    EMPLOYEE: filters['EMPLOYEE'],
    SERVICE: filters['SERVICE'],
    ICD10: filters['ICD10'],
    MEDICAL_SUPPLY_GROUP: filters['MEDICAL_SUPPLY_GROUP'],
    MEDICATION_CATEGORY: filters['MEDICATION_CATEGORY'],
    PAYMENT_STATUS: filters['PAYMENT_STATUS'].filter((ele) => ele.checked),
    TRANSACTION_TYPE: filters['TRANSACTION_TYPE'].filter((ele) => ele.checked),
    MEDICAL_SUPPLIERS: filters['MEDICAL_SUPPLIERS'],
    MEDICATION: filters['MEDICATION'],
    isSaveFilter: filters.isSaveFilter,
  };
};

export const formatFilterValue = (filters: FormFilter) => {
  return {
    PAYMENT_METHODS: filters['PAYMENT_METHODS']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof PAYMENT_METHOD)[],
    INDICATION_REQUEST_STATUS: filters['INDICATION_REQUEST_STATUS']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof INDICATION_REQUEST_STATUS)[],
    INDICATION_REQUEST_SCOPE: filters['INDICATION_REQUEST_SCOPE']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof SYSTEM_CATEGORY_SCOPE)[],
    ENCOUNTER_STATUS: filters['ENCOUNTER_STATUS']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof ENCOUNTER_STATUS)[],
    DEBT_STATUS: filters['DEBT_STATUS']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof DEBT_STATUS)[],
    PAYMENT_PLAN: filters['PAYMENT_PLAN']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof PAYMENT_PLAN)[],
    APPOINTMENT_STATUS: filters['APPOINTMENT_STATUS']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof APPOINTMENT_STATUS)[],
    PATIENT_TYPE: filters['PATIENT_TYPE']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof PATIENT_TYPE)[],
    APPOINTMENT_PATIENT_TYPE: filters['APPOINTMENT_PATIENT_TYPE']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof APPOINTMENT_PATIENT_TYPE)[],
    APPOINTMENT_CHART_TYPE: filters['APPOINTMENT_CHART_TYPE']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof APPOINTMENT_CHART_TYPE)[],
    EMPLOYEE: filters['EMPLOYEE']?.map((ele) => ele.id!),
    EMPLOYEE_CASHIER: filters['EMPLOYEE']?.map((ele) => ele.employeeId!),
    SERVICE: filters['SERVICE']?.map((ele) => ele.id!),
    ICD10: filters['ICD10']?.map((ele) => ele.value!),
    MEDICAL_SUPPLY_GROUP: filters['MEDICAL_SUPPLY_GROUP']?.map((ele) => ele.id!),
    PAYMENT_STATUS: filters['PAYMENT_STATUS']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof PAYMENT_STATUS)[],
    TRANSACTION_TYPE: filters['TRANSACTION_TYPE']
      .filter((ele) => ele.checked)
      ?.map((ele) => ele.value) as (keyof typeof PAYMENT_HISTORY_TYPE)[],
    MEDICAL_SUPPLIERS: filters['MEDICAL_SUPPLIERS']?.map((ele) => ele.id!),
    MEDICATION: filters['MEDICATION']?.map((ele) => ele.id!),
  };
};

export const getDefaultValue = (filters: FilterType[]): FormFilter => {
  const filterMap = keyBy(filters, 'type');
  return {
    PAYMENT_METHODS: formatCheckboxOption(filterMap, 'PAYMENT_METHODS'),
    INDICATION_REQUEST_SCOPE: formatCheckboxOption(filterMap, 'INDICATION_REQUEST_SCOPE'),
    INDICATION_REQUEST_STATUS: formatCheckboxOption(filterMap, 'INDICATION_REQUEST_STATUS'),
    ENCOUNTER_STATUS: formatCheckboxOption(filterMap, 'ENCOUNTER_STATUS'),
    DEBT_STATUS: formatCheckboxOption(filterMap, 'DEBT_STATUS'),
    PAYMENT_PLAN: formatCheckboxOption(filterMap, 'PAYMENT_PLAN'),
    APPOINTMENT_STATUS: formatCheckboxOption(filterMap, 'APPOINTMENT_STATUS'),
    PATIENT_TYPE: formatCheckboxOption(filterMap, 'PATIENT_TYPE'),
    APPOINTMENT_PATIENT_TYPE: formatCheckboxOption(filterMap, 'APPOINTMENT_PATIENT_TYPE'),
    APPOINTMENT_CHART_TYPE: formatCheckboxOption(filterMap, 'APPOINTMENT_CHART_TYPE'),
    EMPLOYEE: [],
    SERVICE: [],
    ICD10: [],
    MEDICAL_SUPPLY_GROUP: [],
    MEDICATION_CATEGORY: [],
    PAYMENT_STATUS: formatCheckboxOption(filterMap, 'PAYMENT_STATUS'),
    TRANSACTION_TYPE: formatCheckboxOption(filterMap, 'TRANSACTION_TYPE'),
    MEDICAL_SUPPLIERS: [],
    MEDICATION: [],
    isSaveFilter: true,
  };
};
