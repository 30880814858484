import { Box, Button, Typography } from '@mui/material';
import { SchedulerLeft, SchedulerRight } from '../../../../../svg';
import Stack from '@mui/material/Stack';
import React from 'react';
import { styled } from '@mui/system';
import { APPOINTMENT_TYPES } from '../constants';
import { DateNavigator as DateNavigatorBase } from '@devexpress/dx-react-scheduler';
import { FE_DATE_FORMAT } from '../../../../common/constants';
import moment from 'moment';
import useGeneralHook from '../../../../common/hook/useGeneralHook';

interface Props extends DateNavigatorBase.RootProps {
  date: Date;
}

const StyledButton = styled(Button)({
  padding: '16px 20px 16px 20px',
  marginLeft: '25px',
  minWidth: 'unset',
  '&,&:hover': {
    border: '2px solid #2A4D82',
    borderRadius: '6px',
  },
  backgroundColor: 'white',
});

const SchedulerNavigationHeader = ({ onNavigate, date }: Props) => {
  const { intl } = useGeneralHook();
  return (
    <Stack direction="row" justifyContent="space-between" flexGrow={1} alignItems="center">
      <Box>
        <StyledButton variant="outlined" onClick={() => onNavigate('back', 0)}>
          <SchedulerLeft />
        </StyledButton>
        <StyledButton variant="outlined" onClick={() => onNavigate('forward', 0)}>
          <SchedulerRight />
        </StyledButton>
      </Box>
      <Typography sx={{ fontWeight: 400, fontSize: '24px', lineHeight: '24px', letterSpacing: '0.18px' }}>
        {moment(date).format(FE_DATE_FORMAT)}
      </Typography>
      <Stack direction="row" alignItems="center">
        {Object.keys(APPOINTMENT_TYPES).map((key) => (
          <Stack direction="row" alignItems="center" gap="12px" marginRight="15px" key={key}>
            <Box
              sx={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: APPOINTMENT_TYPES[key].color }}
            ></Box>
            <Typography>{intl.formatMessage({ id: APPOINTMENT_TYPES[key].label })}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};

export default SchedulerNavigationHeader;
