import { Box, Dialog } from '@mui/material';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import { ROLES, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import SchemaForm from 'modules/common/SchemaForm';
import { ReceivePatientForm } from 'modules/reception/model';
import { loadTherapyServiceList } from 'modules/reception/redux/receptionReducer';
import { createPatientRequestBody } from 'modules/reception/component/utils';
import NewTherapyWizardTitle from '../ReceiveWizardDialog/NewTherapyWizardTitle';
import { receivePatientFormSchema } from '../ReceiveWizardDialog/schema';
import { axiosThunk } from 'modules/common/redux/axios';
import '../ReceiveWizardDialog/style.scss';
import { useFetch, useUserRoles } from 'modules/common/hook';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';

interface Props {
  open: boolean;
  close(): void;
  refresh(): void;
  encounterPatientId?: some | null;
}

const RegisterTherapyForm: React.FunctionComponent<Props> = ({ close, refresh, encounterPatientId }) => {
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const userMap = useSelector((state: AppState) => state.common.users);
  const methodsForm = useForm<ReceivePatientForm>({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });

  const { data: collaboratorOptions } = useFetch(API_SERVER.patient.getAllCollaborator);

  const onSubmit = React.useCallback(
    async (data: some) => {
      dispatch(setLoading(true));
      try {
        let patientId;
        if (data.patientId) {
          //update patient
          await dispatch(
            axiosThunk({
              url: API_SERVER.patient.updatePatient(data.patientId),
              method: 'put',
              data: createPatientRequestBody(data),
            }),
          );
          patientId = data.patientId;
        } else {
          const createPatientRes = await dispatch(
            axiosThunk({
              url: API_SERVER.patient.create(true),
              method: 'post',
              data: createPatientRequestBody(data),
            }),
          );
          patientId = createPatientRes?.data?.id;
        }
        await dispatch(
          axiosThunk({
            url: API_SERVER.therapy.assignment(),
            method: 'post',
            data: {
              patient: {
                ...createPatientRequestBody(data),
                id: patientId,
              },
              paymentPlan: data.paymentCategory,
              picUserId: userMap[data?.doctor]?.id,
              picName: userMap[data?.doctor]?.employeeFullName,
              registerReason: data?.reason,
              therapyDefinitionId: data?.healthcareServiceItem?.id,
              collaboratorId: data?.collaborator?.referenceId,
              collaboratorName: data?.collaborator?.name,
              collaboratorType: data?.collaborator?.type,
            },
          }),
        );

        openSnackbar({
          message: intl.formatMessage({ id: 'therapy.register.message.success' }),
          type: 'success',
        });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'receivePatient.createEncounterFails' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
        close();
        refresh();
      }
    },
    [dispatch, userMap, openSnackbar, intl, close, refresh],
  );

  React.useEffect(() => {
    dispatch(loadTherapyServiceList());
  }, [dispatch]);

  const pics = useUserRoles(
    ROLES.DOCTOR.value,
    ROLES.GENERAL_DOCTOR.value,
    ROLES.NURSE.value,
    ROLES.RADIOLOGIST.value,
    ROLES.LABORATORY_TECHNOLOGIST.value,
    ROLES.RADIOGRAPHER.value,
    ROLES.CLINICAL_PATHOLOGIST.value,
  );

  const picOptions = React.useMemo(
    () => pics.map((pic) => ({ value: pic.id, label: pic.employeeFullName })) || [],
    [pics],
  );

  return (
    <>
      <NewTherapyWizardTitle close={close} mode={encounterPatientId?.mode} />
      <Box overflow="auto" className="receive-wizard-dialog">
        <SchemaForm
          schema={receivePatientFormSchema}
          onSubmit={onSubmit}
          onCancel={() => close()}
          cancelLabel={<FormattedMessage id={'cancel'} />}
          submitLabel={
            <FormattedMessage id={encounterPatientId?.mode === 'edit' ? 'save' : 'therapy.label.register'} />
          }
          hiddenSearch={!!encounterPatientId?.patientId}
          methods={methodsForm}
          mode={encounterPatientId?.mode}
          collaboratorOptions={collaboratorOptions}
          picOptions={picOptions}
        />
      </Box>
    </>
  );
};

const RegisterTherapyDialog: React.FunctionComponent<Props> = (props) => {
  const { open } = props;
  return (
    <Dialog
      open={open}
      maxWidth="md"
      keepMounted={false}
      PaperProps={{
        style: { minWidth: 680, position: 'relative', overflow: 'hidden' },
      }}
    >
      <RegisterTherapyForm {...props} />
    </Dialog>
  );
};

export default RegisterTherapyDialog;
