import { Box, Button, Stack } from '@mui/material';
import Report from 'modules/common/component/report';
import Filter from 'modules/common/component/filter';
import { useEffect, useMemo, useRef, useState } from 'react';
import DateInput from 'modules/common/component/form/DateInput';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';
import { TIME_REPORT_FILTER } from 'modules/common/component/report/consts';
import { formatEndDate, formatStartDate, getDateRangeList, isValidDate, maxDate } from 'modules/common/utils';
import { DEFAULT_VALUES, FILTER_CONFIGS } from './const';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectInput from 'modules/common/component/form/SelectInput';
import { SERVICE_TYPE_ALL } from 'modules/common/apiConstants';
import { ValueType } from 'modules/common/type';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, MONTH_YEAR_FORMAT } from 'modules/common/constants';
import { groupBy, map, sum } from 'lodash';
import { Print } from '@mui/icons-material';
import ReactToPrint from 'react-to-print';
import { BUSINESS_API } from 'modules/common/service';

export const FILTER_KEY = 'REVENUE_REPORT_CHART_FILTER';

export const SELECT_COLUMNS_KEY = 'REVENUE_REPORT_CHART_SELECT_COLUMNS';

interface FormSchema {
  type?: ValueType<typeof SERVICE_TYPE_ALL>[];
  groupReportType?: keyof typeof TIME_REPORT_FILTER;
  fromDate: string;
  toDate: string;
}

interface Props {
  typeOption: ValueType<typeof SERVICE_TYPE_ALL>[];
}

const RevenueChart: React.FunctionComponent<Props> = ({ typeOption }: Props) => {
  const [searchParams, setSearchParams] = useState<FormSchema>({
    fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
    toDate: formatEndDate(moment().add(+1, 'day')),
    groupReportType: TIME_REPORT_FILTER.DAY.value,
    type: [],
  });
  const form = useForm<FormSchema & { timeReportType: keyof typeof TIME_REPORT_FILTER }>({
    defaultValues: {
      fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
      toDate: formatEndDate(moment().add(+1, 'day')),
      groupReportType: TIME_REPORT_FILTER.DAY.value,
      timeReportType: TIME_REPORT_FILTER.DAY.value,
      type: [],
    },
  });
  const intl = useIntl();
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate = formatEndDate(moment(toDateOrigin).add(-1, 'day'));
  const formData = form.getValues();
  const groupReportType = form.watch('groupReportType');
  const type = form.watch('type');

  const { data: reportData, isValidating } = useFetch(
    BUSINESS_API.getRevenueChart({
      ...searchParams,
      type: type ? map(type, 'value') : [],
      fromDate,
      toDate,
      groupReportType: groupReportType,
    }),
    {
      globalLoading: true,
      revalidateOnFocus: false,
      enabled: isValidDate(fromDate, toDate, 'CHART'),
    },
  );

  const refPrintComponent = useRef(null);
  const dateRangeList = useMemo(
    () =>
      getDateRangeList(
        moment(fromDate, BE_DATE_TIME_FORMAT),
        moment(toDate, BE_DATE_TIME_FORMAT),
        groupReportType === 'DAY' ? 'day' : 'month',
      ).map((date) => date.format(groupReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT)),
    [fromDate, groupReportType, toDate],
  );

  const [finalTotalAmountData, setFinalTotalAmountData] = useState<number[]>([]);
  const [amountPaidData, setAmountPaidData] = useState<number[]>([]);
  useEffect(() => {
    const timeFormat = groupReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT;
    if (!isValidating && reportData && isValidDate(fromDate, toDate, 'CHART')) {
      const reportMap = groupBy(reportData, (item) => {
        const normalizedDate =
          groupReportType === 'DAY'
            ? moment(item.identifier, ['D/M/YYYY', 'DD/MM/YYYY']).format(timeFormat)
            : moment(item.identifier, ['M/YYYY', 'MM/YYYY']).format(timeFormat);
        return normalizedDate;
      });
      setFinalTotalAmountData(
        dateRangeList.map((date) => sum(reportMap[date]?.map((obj) => obj?.finalTotalAmount)) || 0),
      );
      setAmountPaidData(dateRangeList.map((date) => sum(reportMap[date]?.map((obj) => obj?.amountPaid)) || 0));
    }
  }, [dateRangeList, fromDate, isValidating, reportData, groupReportType, toDate]);

  useEffect(() => {
    if (groupReportType === 'DAY' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'month')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'month')
            .add(+1, 'day'),
        ),
      });
    }
    if (groupReportType === 'MONTH' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'year')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'year')
            .add(+1, 'day'),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateOrigin, groupReportType, toDateOrigin]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title title="therapy.therapyReport.summary.expectedRevenueReport.title" />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue) => {
          setSearchParams((prev) => ({
            ...prev,
            icd10Codes: filterValue.ICD10,
            paymentPlans: filterValue.PAYMENT_PLAN,
            picUserIds: filterValue.EMPLOYEE,
            serviceIds: filterValue.SERVICE,
            statuses: filterValue.ENCOUNTER_STATUS,
          }));
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                multiple
                showCheckBox
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
                required
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                maxDate={maxDate(groupReportType!, fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                format={FE_DATE_FORMAT}
                required
              />
              <Box marginTop={'-17px'}>
                <Report.DayMonthRadio
                  form={form}
                  name="groupReportType"
                  onChange={(value) => {
                    if (value === 'DAY') {
                      form.reset({
                        fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment().add(+1, 'day')),
                        groupReportType: TIME_REPORT_FILTER.DAY.value,
                      });
                    }
                    if (value === 'MONTH') {
                      form.reset({
                        fromDate: moment().add(-1, 'year').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment()),
                        groupReportType: TIME_REPORT_FILTER.MONTH.value,
                      });
                    }
                  }}
                />
              </Box>
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() =>
                    form.reset({
                      ...DEFAULT_VALUES,
                      toDate: formatEndDate(moment().add(+1, 'day')),
                    })
                  }
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Stack direction="column" gap={1}>
              <ReactToPrint
                trigger={() => (
                  <Button variant="contained" color="primary" startIcon={<Print />}>
                    <FormattedMessage id={'printReport'} />
                  </Button>
                )}
                content={() => refPrintComponent.current}
              />
            </Stack>
          </Stack>
        </Stack>
        <Report.ColumnChart
          refPrintComponent={refPrintComponent}
          xAxisTitle="common.time"
          yAxisTitle="report.revenue.chart.money"
          categories={dateRangeList}
          formatYValue
          formatYAxis
          series={[
            {
              type: 'column',
              name: intl.formatMessage({ id: 'revenue.report.actual' }),
              data: amountPaidData,
              color: '#003CA6',
            },
            {
              name: intl.formatMessage({ id: 'therapy.therapyDetail.printForm.title.expectedRevenue' }),
              type: 'line',
              data: finalTotalAmountData,
              color: '#43A047',
            },
          ]}
        />
      </Filter>
    </Stack>
  );
};

export default RevenueChart;
