import {
  Autocomplete,
  AutocompleteProps,
  AutocompleteValue,
  Checkbox,
  CircularProgress,
  Stack,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FormCommonProps, OverrideFormControlProps } from './type';
import { Controller, ControllerProps, FieldPath, FieldValues, PathValue, UnpackNestedValue } from 'react-hook-form';
import useId from '@mui/material/utils/useId';
import FormControl from './FormControl';
import { ReactNode, useEffect, useState } from 'react';
import { getError, getOptionValue, getPlaceholder } from './utils';
import { useIntl } from 'react-intl';
import { useDebouncedCallback } from 'use-debounce';
import { flatMap, groupBy } from 'lodash';

export type AutocompleteInputProps<
  TOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  readonly options: TOption[] | undefined;
  getValue: keyof TOption | ((option: TOption) => string | number);
  getLabel: keyof TOption | ((option: TOption) => string | number);
  renderLabel?: (option: TOption) => ReactNode;
  rawLabel?: boolean;
  rawError?: boolean;
  groupBy?: keyof TOption | ((option: TOption) => string | number | undefined);
  renderGroup?: keyof TOption | ((option: TOption) => string | number | undefined);
  rawGroup?: boolean;
  placeholder?: string;
  rawPlaceholder?: boolean;
  sortOption?: (o1: TOption, o2: TOption) => number;
  multiple?: Multiple;
  allOption?: boolean;
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'render'>;
  renderValue?: (selectedValue: UnpackNestedValue<PathValue<TFieldValues, TName>>) => ReactNode;
  clearButton?: DisableClearable;
  freeSolo?: FreeSolo;
  loadOption?: (value: string) => Promise<TOption[]>;
  delayLoadTime?: number;
  InputProps?: Omit<TextFieldProps, 'ref' | 'id' | 'placeholder'>;
  clearInputAfterSelect?: boolean;
  liStyle?: React.CSSProperties;
  showCheck?: boolean;
  showError?: boolean;
} & FormCommonProps<TFieldValues, TName> &
  OverrideFormControlProps &
  Omit<
    AutocompleteProps<TOption, Multiple, DisableClearable, FreeSolo>,
    | 'name'
    | 'ref'
    | 'placeholder'
    | 'error'
    | 'id'
    | 'value'
    | 'multiline'
    | 'renderValue'
    | 'disableClearable'
    | 'freeSolo'
    | 'renderInput'
    | 'renderOption'
    | 'defaultValue'
    | 'options'
    | 'groupBy'
    | 'renderGroup'
  >;

const AutocompleteInput = <
  TOption,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  options,
  getValue,
  getLabel,
  renderLabel,
  rawLabel = false,
  groupBy: groupByFn,
  renderGroup,
  rawGroup = false,
  form,
  sortOption,
  placeholder,
  rawPlaceholder = false,
  label,
  controllerProps,
  controlProps,
  helpTextProps,
  labelProps,
  hideError = false,
  required,
  fullWidth = true,
  multiple,
  allOption,
  renderValue,
  clearButton = false,
  freeSolo = false,
  loadOption,
  delayLoadTime = 200,
  disabled = false,
  rawError = false,
  loading: isLoading = false,
  InputProps,
  clearInputAfterSelect = false,
  liStyle,
  showCheck,
  showError = false,
  ...restProps
}: AutocompleteInputProps<TOption, Multiple, DisableClearable, FreeSolo, TFieldValues, TName>) => {
  const id = useId();
  const intl = useIntl();
  const [loading, setLoading] = useState(false);
  useEffect(() => setLoading(isLoading), [isLoading]);
  const [formatedOptions, setFormatedOptions] = useState<TOption[]>([]);
  useEffect(() => {
    if (groupByFn) {
      setFormatedOptions(flatMap(Object.values(groupBy(options, groupByFn))));
    } else {
      setFormatedOptions((sortOption ? options?.sort(sortOption) : options) || []);
    }
  }, [groupByFn, options, sortOption]);
  const debouncedLoadFunc = useDebouncedCallback(loadOption || (() => {}), delayLoadTime);

  return (
    <FormControl
      error={getError(form, name)}
      label={label}
      rawLabel={rawLabel}
      required={required}
      htmlFor={id}
      fullWidth={fullWidth}
      controlProps={controlProps}
      labelProps={labelProps}
      helpTextProps={helpTextProps}
      hideError={hideError}
      disabled={disabled}
      rawError={rawError}
    >
      <Controller
        name={name}
        control={form.control}
        render={({ field: { onChange, onBlur, ref, value } }) => {
          return (
            <Autocomplete
              {...restProps}
              disabled={disabled}
              disableClearable={!clearButton}
              options={formatedOptions}
              freeSolo={freeSolo}
              value={value}
              // autoComplete
              multiple={multiple}
              // ListboxComponent={ListboxComponent}
              noOptionsText={intl.formatMessage({ id: 'noOption' })}
              onChange={(event, data, reason) => {
                onChange(data);
                restProps.onChange &&
                  restProps.onChange(
                    event,
                    data as AutocompleteValue<TOption, Multiple, DisableClearable, FreeSolo>,
                    reason,
                  );
              }}
              loading={loading}
              onBlur={(...params) => {
                onBlur();
                restProps.onBlur && restProps.onBlur(...params);
              }}
              groupBy={groupByFn && ((option) => getOptionValue(option, groupByFn))}
              onInputChange={(_, value, reason) => {
                if (loadOption && value) {
                  setLoading(true);
                  debouncedLoadFunc(value)
                    ?.then((loadedOptions) => setFormatedOptions(loadedOptions))
                    .catch(() => setFormatedOptions([]))
                    .finally(() => setLoading(false));
                }
              }}
              isOptionEqualToValue={(o1, o2) => getOptionValue(o1, getValue) === getOptionValue(o2, getValue)}
              getOptionLabel={(option) => getOptionValue(option, getLabel)}
              renderInput={(params) => {
                return (
                  <TextField
                    {...InputProps}
                    {...params}
                    ref={ref}
                    id={id}
                    error={showError && !value}
                    placeholder={getPlaceholder(placeholder, rawPlaceholder, intl)}
                    InputProps={{
                      ...params.InputProps,
                      sx: { fontSize: '16px' },
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="inherit" size={20} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                );
              }}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={getOptionValue(option, getValue)} style={{ ...props.style, ...liStyle }}>
                    {showCheck && multiple === true ? (
                      <Stack direction={'row'} gap="10px" flex={1} alignItems={'center'}>
                        <Checkbox checked={Object.values(value)?.indexOf(option) !== -1} />
                        {renderLabel ? renderLabel(option) : getOptionValue(option, getLabel)}
                      </Stack>
                    ) : renderLabel ? (
                      renderLabel(option)
                    ) : (
                      getOptionValue(option, getLabel)
                    )}
                  </li>
                );
              }}
            />
          );
        }}
        {...controllerProps}
      />
    </FormControl>
  );
};

export default AutocompleteInput;
