import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from '../../../../common/component/TableCustom';
import { FE_DATE_FORMAT } from '../../../../common/constants';
import { IndicationHistory } from 'modules/schema';

interface Props {
  indicationHistories: IndicationHistory[];
}

const COLUMNS = [
  {
    title: 'encounterDate',
    width: 200,
    render: (record) => record?.dateTimeFinish?.format(FE_DATE_FORMAT),
  },
  {
    title: 'referenceIndication.result',
    dataIndex: 'result',
  },
] as Columns<IndicationHistory>[];

const DataInputLabDetail: React.FunctionComponent<Props> = ({ indicationHistories }) => {
  return (
    <Box padding={1}>
      <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: 8 }}>
        <FormattedMessage id={'indication.result'} />
      </Typography>
      <TableCustom dataSource={indicationHistories} columns={COLUMNS} />
    </Box>
  );
};

export default DataInputLabDetail;
