import { Box } from '@mui/material';
import * as React from 'react';
import LoadingIcon from '../../../common/component/LoadingIcon';
import NoDataBox from '../../../common/component/NoDataBox';
import { some } from '../../../common/constants';
import DrugTable from './DrugTable';
import SearchDrug from './SearchDrug';

interface Props {
  data?: some[];
  loading?: boolean;
  isInsurance?: boolean;
  setFormDataGroup: (value: some) => void;
  onDeleteGroup: (value: some) => void;
  searchParams: some;
  setSearchParams: (value: some) => void;
  revalidate: () => void;
  refreshMedicationCategoryId?: number | null;
  setRefreshMedicationCategoryId?(categoryId: number | null): void;
}

const DrugDataListTable: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    setFormDataGroup,
    onDeleteGroup,
    searchParams,
    setSearchParams,
    loading,
    refreshMedicationCategoryId,
    setRefreshMedicationCategoryId,
    revalidate,
  } = props;

  return (
    <>
      <Box padding={2} paddingBottom={1}>
        <SearchDrug
          onAddNew={() => setFormDataGroup({})}
          onSubmit={(value) => {
            setSearchParams(value);
          }}
          formData={searchParams}
        />
      </Box>
      {loading ? (
        <LoadingIcon />
      ) : (
        <Box padding={2} paddingTop={0} overflow="auto">
          {data && data?.length > 0 ? (
            data?.map((value: some) => {
              return (
                <DrugTable
                  key={value.id}
                  setFormDataGroup={setFormDataGroup}
                  onDeleteGroup={onDeleteGroup}
                  data={value}
                  revalidateCategory={revalidate}
                  refreshMedicationCategoryId={refreshMedicationCategoryId}
                  setRefreshMedicationCategoryId={setRefreshMedicationCategoryId}
                />
              );
            })
          ) : (
            <NoDataBox />
          )}
        </Box>
      )}
    </>
  );
};

export default DrugDataListTable;
