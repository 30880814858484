import { INDICATION_REQUEST_STATUS } from 'modules/common/constants';
import { useMemo, useRef, useState } from 'react';
import { Box, Divider } from '@mui/material';
import Header from './Header';
import Body from './Body';
import DialogAction from './DialogAction';
import { chain } from 'lodash';
import { styled } from '@mui/system';
import { INDICATION_REQUEST_PRINT_TYPE, LOCAL_STORAGE_INDICATION_PRINT_TYPE } from './constant';
import { useFetchIndicationRequest } from '../../common/fetcher';
import { useParams } from 'react-router';

const DividerStyled = styled(Divider)({
  marginTop: '30px',
  marginBottom: '15px',
  '@media print': {
    display: 'none',
  },
});

const PrintDialogContent = () => {
  const { dentalSessionId } = useParams<{ dentalSessionId }>();
  const { data: indicationRequests } = useFetchIndicationRequest(dentalSessionId);

  const printContentRef = useRef(null);
  const [printType, setPrintType] = useState<INDICATION_REQUEST_PRINT_TYPE>(
    (sessionStorage.getItem(LOCAL_STORAGE_INDICATION_PRINT_TYPE) as INDICATION_REQUEST_PRINT_TYPE) || 'GROUP',
  );
  const requestedIndicationMap = useMemo(() => {
    return chain(indicationRequests)
      .filter((indication) => INDICATION_REQUEST_STATUS.REQUESTED.value === indication?.status)
      .groupBy(printType === 'GROUP' ? 'scope' : 'systemCategoryId')
      .value();
  }, [indicationRequests, printType]);

  return (
    <Box paddingTop="̣9px">
      <Box ref={printContentRef}>
        {Object.keys(requestedIndicationMap).map((key) => (
          <Box sx={{ pageBreakBefore: 'always' }} key={key}>
            <Header />
            <Body indicationList={requestedIndicationMap[key]} printType={printType} />
            <DividerStyled />
          </Box>
        ))}
      </Box>
      <DialogAction printContentRef={printContentRef} printType={printType} onPrintTypeChange={setPrintType} />
    </Box>
  );
};

export default PrintDialogContent;
