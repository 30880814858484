/* eslint-disable react-hooks/exhaustive-deps */
import { Autocomplete, AutocompleteRenderInputParams, OutlinedInputProps } from '@mui/material';
import { debounce, isEqual } from 'lodash';
import React, { ReactNode, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from '../../../constants';
import TextFieldElement from '../text-field/TextFieldElement';
import { ListboxComponent } from './ListboxComponent';

export interface FormControlAutoCompletePropsBase {
  label?: React.ReactNode;
  formControlStyle?: React.CSSProperties;
  inputStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  error?: boolean;
  disableCloseOnSelect?: boolean;
  placeholder?: string;
  renderInput?: (params: AutocompleteRenderInputParams) => React.ReactNode;
  required?: boolean;
  loadOptions?: (input: string) => Promise<some[]>;
  loadKey?: any;
  disableSearchByText?: boolean;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  readOnly?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  multiple?: boolean;
  freeSolo?: boolean;
  disableClearable?: boolean;
  onChangeInput?: OutlinedInputProps['onChange'];
  options?: some[];
  windowScroll?: boolean;
  getOptionLabel?: (option: some) => string;
  isOptionEqualToValue?: (option: some, value: some) => boolean;
  getOptionDisabled?: (option: some) => boolean;
  groupBy?: (option: some) => string;
  initialSearch?: string;
  limitTags?: number;
  onBlur?: (value: any) => void;
  labelKey?: string;
}

export interface FormControlAutoCompleteProps extends FormControlAutoCompletePropsBase {
  innerRef?: React.Ref<any>;
}

export const FormControlAutoComplete = (props: FormControlAutoCompleteProps | some) => {
  const {
    label,
    placeholder,
    error,
    formControlStyle,
    required,
    renderInput,
    options,
    loadOptions,
    startAdornment,
    endAdornment,
    inputStyle,
    labelStyle,
    innerRef,
    readOnly,
    onChangeInput,
    autoFocus,
    windowScroll,
    initialSearch,
    loadKey,
    disableSearchByText,
    onBlur,
    labelKey,
    ...rest
  } = props;

  const [optionsTmp, setOption] = React.useState<typeof options>(options);

  const loadOptionsFnc = async (input) => {
    if (loadOptions) {
      const data = await loadOptions(input);
      if (data) {
        setOption(data);
      }
    }
  };
  const onLoadOptions = React.useCallback(
    debounce(
      (input: string) => {
        loadOptionsFnc(input);
      },
      500,
      {
        trailing: true,
        leading: false,
      },
    ),
    [],
  );

  useEffect(() => {
    if (!isEqual(options, optionsTmp)) {
      setOption(options);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  useEffect(() => {
    if (!!loadOptions) {
      loadOptionsFnc(initialSearch);
    }
  }, [loadKey]);

  return (
    <Autocomplete
      size="small"
      fullWidth
      options={optionsTmp || []}
      onInputChange={(event: object, value: string, reason: string) => {
        reason === 'input' && !disableSearchByText && loadOptions && onLoadOptions(value);
      }}
      onBlur={(e) => {
        loadOptions && initialSearch !== undefined && onLoadOptions(initialSearch);
        onBlur && onBlur(e);
      }}
      disabled={readOnly}
      noOptionsText={<FormattedMessage id="noOption" />}
      renderInput={
        renderInput ||
        (({ InputProps, ...params }) => {
          return (
            <TextFieldElement
              {...params}
              disabled={rest.disabled || false} // Cái này để readOnly autocomplete
              fullWidth
              error={error}
              label={label}
              inputRef={innerRef}
              placeholder={placeholder}
              inputProps={{
                ...params.inputProps,
                autoComplete: 'off',
              }}
              required={required}
              InputProps={{
                ...InputProps,
                disabled: rest.disabled,
                readOnly,
                autoFocus: autoFocus,
                style: {
                  paddingTop: 0,
                  paddingBottom: 0,
                  paddingLeft: 0,
                  // ...inputStyle,
                },
                startAdornment: (
                  <>
                    {startAdornment}
                    {InputProps.startAdornment}
                  </>
                ),
                endAdornment: (
                  <>
                    {InputProps.endAdornment}
                    {endAdornment}
                  </>
                ),
                error: error,
              }}
              onChange={onChangeInput}
              variant="outlined"
              size="small"
            />
          );
        })
      }
      getOptionLabel={(option: any) => (labelKey ? option[labelKey] : option?.label || ' ')}
      isOptionEqualToValue={(option: some, value: some) => isEqual(option, value)}
      autoComplete
      ListboxComponent={
        windowScroll ? (ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>) : undefined
      }
      onMouseDownCapture={(e) => !optionsTmp?.length && e.stopPropagation()}
      disableCloseOnSelect={!!rest.multiple}
      {...(!!loadOptions && !disableSearchByText ? { filterOptions: (option) => option } : {})}
      {...rest}
    />
  );
};

export default FormControlAutoComplete;
