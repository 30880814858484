import { Box, Stack } from '@mui/material';
import { SERVICE_TYPE_ALL } from 'modules/common/apiConstants';
import Report from 'modules/common/component/report';
import { REPORT_VIEW_MODE } from 'modules/common/component/report/consts';
import RevenueChart from 'modules/common/page/report/business/revenueReport/RevenueChart';
import RevenueChartCustomer from 'modules/common/page/report/business/revenueReport/RevenueChartCustomer';
import RevenueReportTable from 'modules/common/page/report/business/revenueReport/RevenueReportTable';
import { ValueType } from 'modules/common/type';
import { SPECIALIST } from 'modules/layout/constants';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';

const RevenueReportTab = () => {
  const [viewMode, setViewMode] = useState<keyof typeof REPORT_VIEW_MODE>('CHART');
  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const typeOption: ValueType<typeof SERVICE_TYPE_ALL>[] = useMemo(() => {
    return isDentalClinic
      ? [SERVICE_TYPE_ALL.DENTAL, SERVICE_TYPE_ALL.THERAPY]
      : [SERVICE_TYPE_ALL.ENCOUNTER, SERVICE_TYPE_ALL.THERAPY];
  }, [isDentalClinic]);
  return (
    <Report>
      <Stack direction="column" position="relative">
        <Stack direction="row" justifyContent="flex-end" position="absolute" top={-10} right={0}>
          <Report.SwitchLayout onChange={(viewMode) => setViewMode(viewMode)} defaultMode="CHART" />
        </Stack>
        <Box marginTop={1}>
          {viewMode === 'TABLE' ? (
            <RevenueReportTable typeOption={typeOption} />
          ) : (
            <Stack direction={'column'}>
              <RevenueChart typeOption={typeOption} />
              <RevenueChartCustomer typeOption={typeOption} />
            </Stack>
          )}
        </Box>
      </Stack>
    </Report>
  );
};

export default RevenueReportTab;
