import { Box } from '@mui/material';
import SchemaFormMain from 'modules/common/SchemaForm';
import { API_SERVER } from 'modules/common/api';
import { PAYMENT_METHOD_NOT_FULL, PAYMENT_STATUS, PAYMENT_TYPE } from 'modules/common/apiConstants';
import DialogCustom from 'modules/common/component/DialogCustom';
import { useFetch, useUpdateMutate } from 'modules/common/hook';
import { PaymentDetail } from 'modules/schema';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { mutate } from 'swr';

interface Props {
  open: boolean;
  onClose: () => void;
  paymentDetail: PaymentDetail;
  patientPay: number;
}

const DialogContent = ({ open, onClose, paymentDetail, patientPay }: Props) => {
  const methods = useForm({ mode: 'onChange' });
  const { data: paymentHistory } = useFetch(API_SERVER.paymentHistory.getRefundDetail(paymentDetail?.refundId!), {
    enabled: paymentDetail?.status === PAYMENT_STATUS.REFUNDED.value,
  });

  const refundMutate = useUpdateMutate({
    confirmMessage: 'therapy.cashier.payment.refund.message.confirm',
    onSuccess: () => {
      mutate(API_SERVER.paymentHistory.getList(paymentDetail?.paymentId!).url);
      mutate(API_SERVER.payment.detail(paymentDetail?.paymentId!, { type: PAYMENT_TYPE.ENCOUNTER }).url);
      onClose();
    },
  });
  useEffect(() => {
    if (paymentHistory) {
      methods.reset({
        refundAmount: paymentHistory?.amountPaid,
        paymentMethod: paymentHistory?.paymentMethod,
        note: paymentHistory?.note,
      });
    }
  }, [methods, paymentHistory]);
  return (
    <Box padding={2}>
      <SchemaFormMain
        methods={methods}
        onCancel={onClose}
        onSubmit={(formData) =>
          refundMutate({
            url: API_SERVER.payment.refund(paymentDetail?.paymentId!),
            method: 'POST',
            data: {
              id: paymentDetail?.id,
              refundAmount: formData?.refundAmount,
              paymentMethod: formData?.paymentMethod,
              note: formData?.note,
            },
          })
        }
        readOnly={paymentDetail?.status === PAYMENT_STATUS.REFUNDED.value}
        schema={{
          fields: ({ formProps: { intl } }) => {
            return {
              refundAmount: {
                type: 'number-field',
                inputType: 'number',
                label: intl.formatMessage({ id: 'therapy.cashier.dialog.label.refundAmount' }),
                placeholder: intl.formatMessage({ id: 'therapy.refund.label.amountEnter' }),
                register: {
                  required: true,
                  max: {
                    value: patientPay,
                    message: intl.formatMessage({ id: 'validation.invalid' }),
                  },
                },
                propsWrapper: { xs: 6 },
              },
              paymentMethod: {
                type: 'select',
                defaultValue: PAYMENT_METHOD_NOT_FULL.CASH.value,
                label: intl.formatMessage({ id: 'common.refund.paymentMethod' }),
                placeholder: intl.formatMessage({ id: 'select' }),
                options: Object.values(PAYMENT_METHOD_NOT_FULL),
                disableClearBtn: true,
                style: { width: 248 },
                register: { required: true },
                propsWrapper: { xs: 6 },
              },
              note: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'refundReason' }),
                placeholder: intl.formatMessage({ id: 'therapy.refund.label.reasonEnter' }),
                rows: 3,
                multiline: true,
                propsWrapper: { xs: 12 },
                register: {
                  required: true,
                  maxLength: {
                    value: 256,
                    message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                  },
                },
              },
            };
          },
        }}
      />
    </Box>
  );
};
const ConfirmRefundDialog = (props: Props) => {
  const { open, onClose } = props;
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title={'therapy.payment.refund.title.refundDialog'}
      maxWidth="sm"
      PaperProps={{
        style: { minWidth: 480 },
      }}
    >
      <DialogContent {...props} />
    </DialogCustom>
  );
};

export default ConfirmRefundDialog;
