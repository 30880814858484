import { some } from '../../../../common/constants';
import * as React from 'react';
import DialogCustom from '../../../../common/component/DialogCustom';
import { Box } from '@mui/material';
import SchemaForm from '../../../../common/SchemaForm';
import { PAYMENT_METHOD } from 'modules/common/apiConstants';

interface Props {
  open: boolean;
  paymentAdvanceDetail?: some;
  onClose?: () => void;
  onSubmit?: (value: some) => void;
}

const PrepaymentConfirmDialog = (props: Props) => {
  const { onClose, open, onSubmit, paymentAdvanceDetail } = props;

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title={'therapy.prepayment.header.paymentAdvanceConfirm.paymentMethod'}
      maxWidth="sm"
      PaperProps={{
        style: { minWidth: 300 },
      }}
    >
      <Box padding={2}>
        <SchemaForm
          onCancel={onClose}
          onSubmit={onSubmit}
          formData={paymentAdvanceDetail}
          schema={{
            fields: ({ formProps: { intl, appState } }) => {
              return {
                paymentMethod: {
                  type: 'select',
                  defaultValue: PAYMENT_METHOD.CASH.value,
                  label: intl.formatMessage({ id: 'cashier.paymentMethod' }),
                  placeholder: intl.formatMessage({ id: 'select' }),
                  options: Object.values(PAYMENT_METHOD).filter(
                    (item) => item.value !== PAYMENT_METHOD.PREPAYMENT.value,
                  ),
                  disableClearBtn: true,
                  register: { required: true },
                  propsWrapper: { xs: 12 },
                },
              };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default PrepaymentConfirmDialog;
