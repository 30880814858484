import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';
import { API_SERVER } from 'modules/common/api';
import DialogCustom from 'modules/common/component/DialogCustom';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaForm from 'modules/common/SchemaForm';
import { examPriceFormSchema } from './schema';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  isInsurance?: boolean;
  onClose?: () => void;
  formData?: some;
  list?: some[];
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose, isInsurance } = props;

  const { dispatch, fetchThunk } = useGeneralHook();

  const getKey = React.useCallback(() => {
    const url = API_SERVER.department.getDepartmentList;
    return url;
  }, []);

  const { data } = useSWR(getKey, async (url) => {
    return dispatch(fetchThunk(url, 'get'));
  });

  const departments = React.useMemo(() => {
    if (!data) {
      return [];
    }

    const returnValue: some[] = [];
    const entries: any = data || [];
    for (let entry of entries) {
      const name = entry.name;
      const code = entry.code;
      returnValue.push({ value: entry.id, label: `${name} - ${code}` });
    }
    return returnValue;
  }, [data]);

  return (
    <>
      <SchemaForm
        schema={examPriceFormSchema}
        onSubmit={onSubmit}
        departments={departments}
        formData={formData}
        onCancel={onClose}
        formId="exam-price"
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
        isInsurance={isInsurance}
      />
    </>
  );
};

const ExamFormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;
  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 460 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'exam.editService' : 'exam.addService'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default ExamFormDialog;
