import { Stack, Typography } from '@mui/material';
import { Patient } from 'modules/schema';
import { Home, RightArrow } from 'svg';

interface Props {
  patient: Patient;
  linkHome?: string;
}

const PathBreadcrumbs = ({ patient, linkHome }: Props) => {
  return (
    <Stack
      direction="row"
      sx={{
        padding: '8px 16px',
        backgroundColor: '#fff',
      }}
    >
      <a href={linkHome || '/'} style={{ marginRight: '15px' }}>
        <Home />
      </a>
      <RightArrow style={{ margin: '5px 15px 0 0' }} />
      <Typography variant="subtitle1" color={'#003CA6'}>
        {patient.name}
      </Typography>
    </Stack>
  );
};

export default PathBreadcrumbs;
