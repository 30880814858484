import { Box } from '@mui/material';
import * as React from 'react';
import DialogCustom from '../../../common/component/DialogCustom';
import { some } from '../../../common/constants';
import SchemaForm from '../../../common/SchemaForm';
import { Medication } from 'modules/schema';

interface Props {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (value: some) => void;
  medication?: Medication;
}

const AddDrugUsageDialog = (props: Props) => {
  const { onClose, open, medication, onSubmit } = props;

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 480 },
      }}
      title={'drugUsage.addInfo'}
    >
      <Box padding={2}>
        <SchemaForm
          onSubmit={onSubmit}
          onCancel={onClose}
          initialData={{
            status: 'complete',
          }}
          schema={{
            fields: ({ formProps: { intl } }) => {
              return {
                intendedRoute: {
                  type: 'auto-complete',
                  label: intl.formatMessage({ id: 'drugUsage.intendedRoute' }),
                  placeholder: intl.formatMessage({ id: 'drugUsage.intendedRouteEnter' }),
                  options: medication?.intendedRoute,
                  register: { required: true },
                },
              };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default AddDrugUsageDialog;
