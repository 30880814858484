import { axiosThunk } from 'modules/common/redux/axios';
import * as React from 'react';
import { API_SERVER } from 'modules/common/api';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useState } from 'react';
import { AxiosError } from 'axios/index';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { MEDICATION_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';

const useDrugClinicAdmin = () => {
  const { dispatch, openSnackbar, confirmDialog, locationId, intl } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();
  const { params, setParams } = usePaginationHook({ disablePagination: true });

  const { data, revalidate, isValidating } = useFetch(
    API_SERVER.medicationCategory.getCategoryByClinicId({
      productName: params.name,
      scope: MEDICATION_CATEGORY_SCOPE.CLINIC,
    }),
  );
  const [refreshMedicationCategoryId, setRefreshMedicationCategoryId] = useState<number | null>(null);

  const onSubmitForm = React.useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: value?.id ? API_SERVER.drugClinic.index(value.id) : API_SERVER.drugClinic.index(value?.drug.id),
          method: value?.id ? 'put' : 'post',
          data: {
            medicationCategoryId: value?.medicationCategoryId,
            basePrice: value.basePrice,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
          revalidate();
          setParams({ medicationId: '', medicationCategoryId: '' });
          setRefreshMedicationCategoryId(value?.medicationCategoryId);
        })
        .catch((e: any) => {
          if (e?.response?.data?.errors)
            e?.response?.data?.errors?.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          else {
            openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateFail' : 'createFail' }) });
          }
          setRefreshMedicationCategoryId(null);
        });
      dispatch(setLoading(false));
    },
    [dispatch, intl, openSnackbar, revalidate, setParams],
  );

  const onDeleteGroup = React.useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDeleteServicePrice' }, { name: value.name }),
      });
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.medicationCategory.update(value.id),
            method: 'delete',
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
            revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const onSubmitFormGroup = React.useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: value?.id
            ? API_SERVER.medicationCategory.update(value.id)
            : API_SERVER.medicationCategory.getCategoryByClinicId(locationId).url,
          method: value?.id ? 'put' : 'post',
          data: { ...value, scope: MEDICATION_CATEGORY_SCOPE.CLINIC },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
          revalidate();
          setFormDataGroup(undefined);
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
        });
      dispatch(setLoading(false));
    },
    [dispatch, intl, locationId, openSnackbar, revalidate],
  );
  return {
    data: data,
    revalidate,
    isValidating: isValidating && !data,
    state: { searchParams: params, setSearchParams: setParams, formDataGroup },
    fnc: {
      setFormDataGroup,
      onDeleteGroup,
      onSubmitFormGroup,
      onSubmitForm,
      refreshMedicationCategoryId,
      setRefreshMedicationCategoryId,
    },
  };
};

export default useDrugClinicAdmin;
