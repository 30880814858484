import {
  Box,
  Checkbox,
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  TableRowProps,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import { Column, RowKey, SubRowColumn } from './type';
import { calculateLeft, getKey, renderCell } from './utlils';
import { useCallback, useMemo, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styled from '@emotion/styled';

interface Props<T> {
  record: T;
  columns: Column<T>[];
  dataKey: RowKey<T>;
  index: number;
  rowProps?: TableRowProps | ((record: T) => TableRowProps);
  subTableStyle?: boolean;
}

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 14,
    boxShadow: '0px 4px 8px 0px #E0ECFF',
    borderRadius: '5px',
    padding: '20px',
    minWidth: 'auto',
    minHeight: 'auto',
  },
}));
function ExpandButton({ expand, setExpand }: { expand: boolean; setExpand: (expand: boolean) => void }) {
  return (
    <IconButton
      onClick={() => setExpand(!expand)}
      sx={{
        padding: '0px',
      }}
    >
      <KeyboardArrowDownIcon
        fontSize="large"
        style={{
          transform: expand ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'all 200ms',
        }}
      />
    </IconButton>
  );
}

export function TableBodyRow<T>({ record, columns, dataKey, index, rowProps, subTableStyle }: Props<T>) {
  const [expand, setExpand] = useState(false);
  const tableRowProps = useMemo(
    () => (typeof rowProps === 'function' ? rowProps(record) : rowProps),
    [record, rowProps],
  );

  const renderBodyCell = useCallback(
    (record: T, column: Column<T>) => {
      switch (column.type) {
        case 'CHECKBOX':
          const { onChange, selectedItems } = column;
          return (
            <Checkbox
              checked={selectedItems?.some((selectedItem) => getKey(selectedItem, dataKey) === getKey(record, dataKey))}
              onChange={(event) => onChange && onChange(record, event.target.checked)}
            />
          );
        case 'SUB_ROW':
          return column.allowCollapse ? <ExpandButton expand={expand} setExpand={setExpand} /> : null;
        case 'INDEX':
          return index + 1;
        default:
          const { linkUrl } = column;
          if (!!linkUrl) {
            return (
              <Box
                component="a"
                href={typeof linkUrl === 'string' ? linkUrl : linkUrl(record)}
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  fontSize: '14px',
                  lineHeight: '16px',
                  letter: '0.4px',
                  color: '#0052E0',
                  textTransform: 'capitalize',
                  textDecoration: 'none',
                  '&:hover': {
                    textDecoration: 'underline',
                  },
                }}
              >
                {renderCell(record, index, column)}
              </Box>
            );
          }
          return renderCell(record, index, column);
      }
    },
    [dataKey, expand, index],
  );

  const renderSubRow = useCallback(() => {
    const collapseColumn = columns.find((column) => column.type === 'SUB_ROW') as SubRowColumn<T>;

    if (!collapseColumn?.render) {
      return null;
    }
    if (collapseColumn.allowCollapse) {
      return (
        <TableRow>
          <TableCell colSpan={columns.length} sx={{ padding: 0, border: 'none' }}>
            <Collapse in={expand} {...collapseColumn.collapseProps}>
              {collapseColumn.render(record, index, expand)}
            </Collapse>
          </TableCell>
        </TableRow>
      );
    }

    return (
      <TableRow>
        <TableCell
          colSpan={columns.length}
          sx={{
            paddingBottom: 0,
            paddingTop: 0,
            border: 'none',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          <Box
            sx={{
              maxWidth: 200,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: 'block',
            }}
          >
            {collapseColumn.render(record, index, true) as React.ReactNode}
          </Box>
        </TableCell>
      </TableRow>
    );
  }, [columns, expand, index, record]);

  return (
    <>
      <TableRow {...tableRowProps} sx={{ border: 'none', ...tableRowProps?.sx }}>
        {columns.map((column, cellIndex) => {
          const { align, colSpan, sticky, width, minWidth, cellProps, enableEllipsis } = column;
          const tableCellProps = typeof cellProps === 'function' ? cellProps(record) : cellProps;

          return (
            <TableCell
              {...tableCellProps}
              align={align}
              key={`${index}-cell${cellIndex}`}
              colSpan={colSpan}
              sx={{
                fontFamily: 'Roboto',
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '16px',
                letter: '0.4px',
                border: 'none',
                padding: '10px',
                borderTop: subTableStyle ? '3px solid white' : '5px solid white',
                backgroundColor: '#F4F8FF',
                width: width,
                minWidth: minWidth,
                position: sticky ? 'sticky' : 'unset',
                left: sticky ? calculateLeft(columns, cellIndex) : 'unset',
                ...tableCellProps?.sx,
                cursor: 'default',
              }}
            >
              {enableEllipsis ? (
                <LightTooltip
                  title={(column.fieldName && record[column.fieldName as keyof T]) || ''}
                  placement="bottom-start"
                >
                  <Box
                    sx={{
                      maxWidth: column.maxWidth,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                      cursor: 'pointer',
                    }}
                  >
                    {record[column.fieldName as keyof T] || renderBodyCell(record, column)}
                  </Box>
                </LightTooltip>
              ) : (
                renderBodyCell(record, column)
              )}
            </TableCell>
          );
        })}
      </TableRow>
      {renderSubRow()}
    </>
  );
}
