import { some } from '../../../../common/constants';
import { useForm } from 'react-hook-form';
import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { Close } from '@mui/icons-material';
import SchemaForm from '../../../../common/SchemaForm';
import schema from './schema';
import * as React from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  formData?: some;
  mode: 'view' | 'edit';
  onSubmit: (formVal: some) => void;
}

const PatientFormContent = ({ open, onClose, formData, mode, onSubmit }: Props) => {
  const methodsForm = useForm<some>({
    defaultValues: formData || {},
    reValidateMode: 'onChange',
  });

  let formDataTitleKey = ' ';
  if (mode === 'edit') {
    formDataTitleKey =
      formData?.id || formData?.patientId
        ? 'patient.management.dialog.title.updatePatient'
        : 'patient.management.dialog.title.main';
  } else {
    formDataTitleKey = 'patientInfo';
  }

  return (
    <>
      <Box overflow="auto">
        <DialogTitle sx={{ bgcolor: 'primary.50' }} style={{ position: 'sticky', top: 0, padding: 16, zIndex: 2 }}>
          <Box>
            <Typography variant="h6" color="primary">
              <FormattedMessage id={formDataTitleKey} />
            </Typography>
          </Box>
          <Box position="absolute" top={6} right={0} marginX={2} marginY={1}>
            <IconButton onClick={onClose} size="small">
              <Close fontSize="small" />
            </IconButton>
          </Box>
        </DialogTitle>
        <SchemaForm
          methods={methodsForm}
          schema={schema}
          formData={formData}
          formId="patientForm"
          onCancel={onClose}
          mode={mode}
          onSubmit={onSubmit}
        />
      </Box>
    </>
  );
};

export default PatientFormContent;
