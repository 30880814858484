import * as React from 'react';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFilterEncounterForm } from './schema';

interface Props {
  pagin?: React.ReactNode;
  hookPagination: HookPaginationProps;
  dataLoading: boolean;
}

const FilterIndicationBox: React.FunctionComponent<Props> = ({ pagin, hookPagination, dataLoading }) => {
  const { params, setParams, clearParams } = hookPagination;
  return (
    <SchemaForm
      onSubmit={setParams}
      formData={params}
      clearParams={clearParams}
      schema={schemaFilterEncounterForm}
      hideSubmitButton
      pagin={pagin}
      dataLoading={dataLoading}
    />
  );
};

export default FilterIndicationBox;
