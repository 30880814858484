import { z } from 'zod';

export const IndicationMedicationSchema = z
  .object({
    id: z.number(),
    indicationRequestId: z.number(),
    medicationId: z.number(),
    medicationName: z.string(),
    medicationIngredient: z.string(),
    medicationDose: z.string(),
    medicationUnit: z.string(),
    quantity: z.number(),
    paymentDetailId: z.number(),
    isFree: z.boolean(),
    note: z.string(),
    groupId: z.number(),
    isDisable: z.boolean(),
    cancellationQuantity: z.number(),
    cancellationReason: z.string(),
  })
  .partial();

export type IndicationMedication = z.infer<typeof IndicationMedicationSchema>;
