import { Box, Grid } from '@mui/material';
import { useParams } from 'react-router';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import PatientInfo from './session/PatientInfo';
import PatientMedicalHistory from './session/PatientMedicalHistory';
import PatientAppointment from './session/appointmentUpcoming/PatientAppointment';
import PatientTherapyHistory from './session/PatientTherapyHistory';
import PatientExaminationHistory from './session/PatientExaminationHistory';
import PatientPrescriptionHistory from './session/prescriptionHistory';
import PatientAttachments from './session/PatientAttachments';
import PathBreadcrumbs from '../PathBreadcrumbs';

const PatientProfileLayout = () => {
  const { patientId } = useParams<{ patientId }>();
  const { data: patient } = useFetch(API_SERVER.patient.getPatient(patientId), { globalLoading: true });
  if (!patient) return null;
  return (
    <Box>
      <PathBreadcrumbs patient={patient} linkHome="/main/patientManagement" />
      <Box padding="16px">
        <Grid container spacing="10px">
          <Grid item xs={3} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <PatientInfo patient={patient} />
            <PatientMedicalHistory patient={patient} />
          </Grid>
          <Grid item xs={4.5} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <PatientExaminationHistory patient={patient} />
            <PatientTherapyHistory patient={patient} />
          </Grid>
          <Grid item xs={4.5} sx={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
            <PatientAppointment patient={patient} />
            <PatientPrescriptionHistory patient={patient} />
            <PatientAttachments patient={patient} />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
export default PatientProfileLayout;
