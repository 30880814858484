import { Box, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import { styled } from '@mui/system';
import { useDiagnosisDialogSelector } from './state';
import fileDownload from 'js-file-download';
import { useCallback } from 'react';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { axiosThunk } from 'modules/common/redux/axios';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { FileIcon, defaultStyles } from 'react-file-icon';
import { API_SERVER } from 'modules/common/api';
import { useFetchAttachments } from './fetcher';
import CameraImageGalleryDialog from './CameraImageGalleryDialog';
import CameraPrintFormDialog from './CameraPrintFormDialog';

const CreateTimeTitleStyled = styled(Typography)({
  color: '#000',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '16px',
  letterSpacing: '0.2px',
});
const IMAGE_EXTENSIONS = ['png', 'jpg', 'jpeg'];
const AttachmentTable = () => {
  const onDeleteAttachment = useDiagnosisDialogSelector.use.deleteAttachment();
  const attachments = useDiagnosisDialogSelector.use.attachments();
  const { dispatch, intl, openSnackbar } = useGeneralHook();
  const onOpenPreviewDialog = useDiagnosisDialogSelector.use.onOpenPreviewDialog();
  useFetchAttachments();

  const onDownloadButtonClick = useCallback(
    async (resourceId: number, fileName: string) => {
      try {
        const { data } = await dispatch(
          axiosThunk({
            url: API_SERVER.storageResource.getFile(resourceId),
            method: 'get',
            responseType: 'blob',
          }),
        );
        await fileDownload(data, fileName);
      } catch (error) {
        openSnackbar({
          message: intl.formatMessage({ id: 'report.downloadFail' }),
          type: 'error',
        });
      }
    },
    [dispatch, intl, openSnackbar],
  );

  return (
    <Box marginTop={2}>
      {attachments
        .filter((file) => file.state !== 'delete')
        .map((file, index) => {
          const fileExtension = file?.fileName?.split('.')[1];
          return (
            <Stack
              key={file.id}
              direction="row"
              justifyContent="space-between"
              padding="8px"
              alignItems="center"
              sx={{
                backgroundColor: '#F4F8FF',
                boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset',
                cursor: 'pointer',
              }}
              onClick={() => {
                if (IMAGE_EXTENSIONS.some((extension) => extension === fileExtension)) {
                  onOpenPreviewDialog(file.id!);
                }
              }}
            >
              <CreateTimeTitleStyled flex={2}>
                {moment(file.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
              </CreateTimeTitleStyled>
              <Stack direction="row" flex={5} gap={1}>
                <Box width="20px">
                  <FileIcon
                    extension={file.fileName?.substr(file.fileName.lastIndexOf('.') + 1)}
                    {...defaultStyles.pdf}
                  />
                </Box>
                <Typography>{file.fileName}</Typography>
              </Stack>
              <Stack direction="row" flex={1}>
                <IconButtonTitle
                  title="delete"
                  size="small"
                  onClick={(event) => {
                    event.stopPropagation();
                    onDeleteAttachment(file.id!);
                  }}
                  style={{ marginRight: 8 }}
                >
                  <DeleteIcon />
                </IconButtonTitle>
                {file.state !== 'add' && (
                  <IconButtonTitle
                    title="download"
                    size="small"
                    onClick={(event) => {
                      event.stopPropagation();
                      onDownloadButtonClick(file.id!, file.fileName || 'document');
                    }}
                    style={{ marginRight: 8 }}
                  >
                    <DownloadIcon />
                  </IconButtonTitle>
                )}
              </Stack>
            </Stack>
          );
        })}
      <CameraImageGalleryDialog />
      <CameraPrintFormDialog />
    </Box>
  );
};

export default AttachmentTable;
