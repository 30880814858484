import { Dictionary } from 'lodash';
import { DISCOUNT_TYPE, PAYMENT_METHOD, PAYMENT_STATUS } from 'modules/common/apiConstants';
import { PaymentDetail } from 'modules/schema';

export interface IFormData {
  discountPercent: number;
  paymentList: {
    paymentDetailId: number;
    checked: boolean;
    unitPrice: number;
    discount: number;
    discountType: keyof typeof DISCOUNT_TYPE;
    vat: number;
    status: keyof typeof PAYMENT_STATUS;
  }[];
}

export interface PayFormData {
  prepaymentChecked: boolean;
  prepayment: number;
  paymentMethodChecked: boolean;
  paymentMethod: keyof typeof PAYMENT_METHOD;
  note: string;
  amountPaid: number;
}

export const caculatePrice = (quantity: number, discountPercent: number, payment: IFormData['paymentList'][number]) => {
  const { unitPrice = 0, discount = 0, discountType = 'PERCENT', vat = 0 } = payment || {};
  //Thành tiền trước chiết khấu = Số lượng * Đơn giá
  const baseAmount = quantity * unitPrice;

  //Số tiền CK theo % tổng hóa đơn = Số lượng * Đơn giá * CK tổng hóa đơn
  const receiptDiscountAmount = (quantity * unitPrice * discountPercent) / 100;

  //Số tiền CK = Số tiền CK theo % tổng hóa đơn + CK đơn giá* Số lượng
  const discountAmount = discountType === 'PERCENT' ? (quantity * unitPrice * discount) / 100 : quantity * discount;
  const finalDiscountAmount = receiptDiscountAmount + discountAmount;

  //Thành tiền = Đơn giá*Số lượng - Số tiền CK
  const totalAmount = quantity * unitPrice - finalDiscountAmount;

  // Thuế VAT = Thành tiền * VAT%
  const vatAmount = (totalAmount * vat || 0) / 100;

  // Khách hàng phai trả = Thành tiền + Thuế VAT
  const patientPay = totalAmount + vatAmount;

  const isPaid = payment?.status === PAYMENT_STATUS.PAID.value;
  const isRefunded = payment?.status === PAYMENT_STATUS.REFUNDED.value;
  // Khách hàng đã chả
  const customerPaid = isPaid || isRefunded ? patientPay : 0;

  // Tổng CK trên DV đã thanh toán
  const discountOnPaid = isPaid ? discountAmount : 0;
  return {
    baseAmount,
    discountAmount,
    unitPrice,
    receiptDiscountAmount,
    finalDiscountAmount,
    totalAmount,
    vatAmount,
    patientPay,
    customerPaid,
    discountOnPaid,
  };
};

export const caculateSumPrice = (
  paymentDetails: IFormData['paymentList'],
  discountPercent: number,
  paymentDetailMap: Dictionary<PaymentDetail>,
) => {
  return paymentDetails
    ?.map((payment) =>
      caculatePrice(paymentDetailMap?.[payment?.paymentDetailId]?.quantity || 0, discountPercent, payment),
    )
    ?.reduce(
      (sumObj, payment) => {
        return {
          baseAmount: sumObj?.baseAmount + (payment?.baseAmount || 0),
          unitPrice: sumObj?.unitPrice + (payment?.unitPrice || 0),
          finalDiscountAmount: sumObj?.finalDiscountAmount + (payment?.finalDiscountAmount || 0),
          patientPay: sumObj?.patientPay + (payment?.patientPay || 0),
          receiptDiscountAmount: sumObj?.receiptDiscountAmount + (payment?.receiptDiscountAmount || 0),
          totalAmount: sumObj?.totalAmount + (payment?.totalAmount || 0),
          vatAmount: sumObj?.vatAmount + (payment?.vatAmount || 0),
          customerPaid: sumObj?.customerPaid + (payment?.customerPaid || 0),
          discountOnPaid: sumObj?.discountOnPaid + (payment?.discountOnPaid || 0),
          discountAmount: sumObj?.discountAmount + (payment?.discountAmount || 0),
        };
      },
      {
        discountAmount: 0,
        baseAmount: 0,
        unitPrice: 0,
        finalDiscountAmount: 0,
        patientPay: 0,
        receiptDiscountAmount: 0,
        totalAmount: 0,
        vatAmount: 0,
        customerPaid: 0,
        discountOnPaid: 0,
      },
    );
};
