import { History } from '@mui/icons-material';
import { Button, Typography } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import { PAYMENT_HISTORY_TYPE } from 'modules/common/apiConstants';
import DialogCustom from 'modules/common/component/DialogCustom';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { FE_DATE_FORMAT, FE_TIME_FORMAT } from 'modules/common/constants';
import { useFetch, useDialog } from 'modules/common/hook';
import { NumericFormatText } from 'modules/common/utils';
import { Encounter, PaymentHistory } from 'modules/schema';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import PaymentHistoryPrintButton from './PaymentHistoryPrintButton';
import { PAYMENT_METHOD_ENUM } from 'modules/admin/component/saleManagement/constant';

interface Props {
  encounter?: Encounter;
}

const PaymentHistoryButton = ({ encounter }: Props) => {
  const [openHistoryDialog, onOpenHistoryDialog, onCloseHistoryDialog] = useDialog();
  const { data: paymentHistories } = useFetch(API_SERVER.paymentHistory.getList(encounter?.paymentId!), {
    enabled: !!encounter,
  });

  const COLUMNS = useMemo(
    () =>
      [
        {
          dataIndex: 'transactionTime',
          title: 'therapy.cashier.dialog.label.paymentHistory.transactionTime',
          render: (record) => (
            <Typography>
              {record.transactionTime &&
                record.transactionTime.format(FE_DATE_FORMAT) + ' - ' + record.transactionTime?.format(FE_TIME_FORMAT)}
            </Typography>
          ),
        },
        {
          dataIndex: 'type',
          title: 'therapy.cashier.dialog.label.paymentHistory.paymentType',
          render: (record) => (
            <FormattedMessage id={PAYMENT_HISTORY_TYPE[record?.type!]?.label || ' '}></FormattedMessage>
          ),
        },
        {
          dataIndex: 'paymentMethod',
          title: 'therapy.cashier.dialog.label.paymentHistory.paymentMethod',
          render: (record) => (
            <FormattedMessage
              id={
                record?.type === PAYMENT_HISTORY_TYPE.PREPAYMENT.value
                  ? 'therapy.cashier.payment.prepayment.type'
                  : PAYMENT_METHOD_ENUM.find((v) => v.value === record?.paymentMethod)?.label || ' '
              }
            ></FormattedMessage>
          ),
        },
        {
          dataIndex: 'amountPaid',
          title: 'therapy.cashier.dialog.label.paymentHistory.amountPaid',
          render: (record) => {
            return <Typography variant="body1">{NumericFormatText(record.amountPaid)}</Typography>;
          },
        },
        {
          title: 'action',
          align: 'right',
          width: 120,
          render: (record) => {
            return (
              record?.type !== PAYMENT_HISTORY_TYPE.PAYMENT_ADVANCE.value && (
                <PaymentHistoryPrintButton encounter={encounter} paymentHistory={record} />
              )
            );
          },
        },
      ] as Columns<PaymentHistory>[],
    [encounter],
  );
  return (
    <>
      <Button startIcon={<History />} onClick={onOpenHistoryDialog} disabled={!encounter}>
        <FormattedMessage id="therapy.cashier.dialog.label.paymentHistory" />
      </Button>
      <DialogCustom
        open={openHistoryDialog}
        onClose={onCloseHistoryDialog}
        PaperProps={{
          style: { width: '80vw' },
        }}
        maxWidth="xl"
        title={'therapy.cashier.dialog.label.paymentHistory'}
      >
        <TableCustom dataSource={paymentHistories} columns={COLUMNS} />
      </DialogCustom>
    </>
  );
};

export default PaymentHistoryButton;
