// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Box, Button, ClickAwayListener, Collapse, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { matchPath } from 'react-router';
import { RawLink } from '../common/component/elements';
import { ASIDE_MIN_WIDTH } from './constants';
import { flatRoutes, RoutesTabType } from './utils';
// import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { ArrowDropDownRounded } from '@mui/icons-material';

interface Props {
  data: RoutesTabType;
  pathname: string;
  open: boolean;
}

const DefaultAsideItems: React.FC<Props> = (props: Props) => {
  const { data, open } = props;
  const [openList, setOpen] = React.useState(false);
  const pathname = window.location.pathname;

  const [isHover, setIsHover] = useState(false);

  const handleMouseEnter = () => {
    setIsHover(true);
  };
  const handleMouseLeave = () => {
    setIsHover(false);
  };

  let checkIsActive = !!flatRoutes([data]).find(
    (v) =>
      v.path &&
      !!matchPath(pathname, {
        path: v.path,
        exact: v.exact,
      }),
  );

  React.useEffect(() => {
    setOpen(!!checkIsActive);
  }, [checkIsActive, pathname]);

  if (data.hidden) {
    return null;
  }

  return (
    <ClickAwayListener onClickAway={() => setIsHover(false)}>
      {data.subMenu ? (
        <Box
          bgcolor={checkIsActive ? 'primary.dark' : undefined}
          sx={{
            width: open ? 'unset' : ASIDE_MIN_WIDTH - 18,
            position: 'relative',
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <Button
            variant="text"
            sx={{
              textAlign: 'start',
              color: checkIsActive ? '#003CA6' : 'common.white',
              background: checkIsActive ? '#E0ECFF' : 'undefined',
              borderRadius: 0,
              '&:hover': { borderRadius: 0, background: '#E0ECFF', color: '#003CA6' },
              padding: '10px 5px',
              '& .MuiButton-endIcon': {
                marginLeft: 0.5,
              },
            }}
            fullWidth
            disableElevation
            onClick={() => setOpen(!openList)}
            endIcon={
              <ArrowDropDownRounded
                sx={{
                  transform: isHover ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'all 200ms',
                }}
                fontSize="small"
              />
            }
          >
            <Typography
              className="menu-title"
              variant={checkIsActive ? 'subtitle2' : 'body2'}
              // color={checkIsActive ? '#fff' : '#003CA6'}
              noWrap
              fontSize="16px"
              fontWeight={500}
              lineHeight="24px"
            >
              {data.title && <FormattedMessage id={data.title} />}
            </Typography>
          </Button>
          <Collapse
            in={isHover}
            sx={{
              border: '1px solid rgb(224, 236, 255)',
              borderBottom: 0,
              position: 'absolute',
              background: '#F4F8FF',
              zIndex: 99,
              minWidth: '100%',
              boxShadow: '0px 4px 16px 0px #E0ECFF',
            }}
          >
            {data.subMenu?.map((item) => {
              if (!item.path) {
                return null;
              }
              const match = !!flatRoutes([item]).find(
                (v) =>
                  v.path &&
                  !!matchPath(pathname, {
                    path: v.path,
                    exact: v.exact,
                  }),
              );
              return (
                <RawLink to={{ pathname: item.path }} key={item.path}>
                  <Button
                    fullWidth
                    variant="text"
                    sx={{
                      backgroundColor: match ? '#E0ECFF' : 'white',
                      color: match ? '#0052E0' : '#333333',
                      borderRadius: 0,
                      '&:hover': { borderRadius: 0 },
                    }}
                    style={{
                      padding: '10px 5px',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      minWidth: '-webkit-fill-available',
                      borderBottom: '1px solid #e0ecff',
                    }}
                  >
                    <Typography
                      noWrap
                      variant={match ? 'subtitle2' : 'body2'}
                      color="#003CA6"
                      fontWeight="500"
                      fontSize={16}
                    >
                      {item.title && <FormattedMessage id={item.title} />}
                    </Typography>
                  </Button>
                </RawLink>
              );
            })}
          </Collapse>
        </Box>
      ) : (
        <RawLink
          to={{
            pathname: data.directPath || data.path,
          }}
        >
          <Button
            variant={checkIsActive ? 'contained' : 'text'}
            fullWidth
            disableElevation
            sx={{
              bgcolor: checkIsActive ? '#E0ECFF' : undefined,
              color: checkIsActive ? '#003CA6' : 'common.white',
              height: '100%',
              '&:hover': {
                bgcolor: '#E0ECFF',
                color: '#003CA6',
                borderRadius: 0,
              },
              minWidth: '-webkit-fill-available',
              borderRadius: 0,
              justifyContent: 'flex-start',
              padding: '10px 5px',
              // marginLeft: '10px',
            }}
          >
            <Typography
              className="menu-title"
              noWrap
              variant={checkIsActive ? 'subtitle2' : 'body2'}
              // color={checkIsActive ? '#fff' : '#003CA6'}
              fontSize={'16px'}
              fontWeight={500}
            >
              {data.title && <FormattedMessage id={data.title} />}
            </Typography>
          </Button>
        </RawLink>
      )}
    </ClickAwayListener>
  );
};

export default DefaultAsideItems;
