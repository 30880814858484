import * as React from 'react';
import SchemaForm from '../../../../common/SchemaForm';
import schema from './schema';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { intersection } from 'lodash';
import { ROLES } from 'modules/common/constants';

interface Props {
  pagin?: React.ReactNode;
  hookPagination: HookPaginationProps;
  onCreateHandle: () => void;
  onExportHandle: () => void;
  onImportHandle: () => void;
}

const FilterPatientManagementBox = (props: Props) => {
  const { hookPagination, pagin, onCreateHandle, onExportHandle, onImportHandle } = props;
  const { params, setParams, clearParams } = hookPagination;
  const roles = useSelector((state: AppState) => state.authen.user?.role);

  const hideExport = React.useMemo(() => {
    return intersection([ROLES.ADMIN.value], roles).length > 0;
  }, [roles]);
  return (
    <Box sx={{ marginBottom: 2 }}>
      <SchemaForm
        schema={schema}
        onSubmit={setParams}
        formData={params}
        clearParams={clearParams}
        pagin={pagin}
        onCreateHandle={onCreateHandle}
        onExportHandle={onExportHandle}
        onImportHandle={onImportHandle}
        hideExport={hideExport}
      />
    </Box>
  );
};

export default FilterPatientManagementBox;
