import { Typography } from '@mui/material';
import { Delete } from '@mui/icons-material';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import { LAB_TYPE_RESULT_CORE_SERVICE } from '../../../../common/constants';
import { FieldsType, ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';

const OBSERVATION_OPTIONS = [
  { value: LAB_TYPE_RESULT_CORE_SERVICE.quantity, label: 'referenceIndication.STATISTICS' },
  { value: LAB_TYPE_RESULT_CORE_SERVICE.list, label: 'referenceIndication.LIST' },
  { value: LAB_TYPE_RESULT_CORE_SERVICE.string, label: 'referenceIndication.DATA_INPUT' },
];

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl } = formProps || {};
  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'referenceIndication.code' }),
      placeholder: intl.formatMessage({ id: 'referenceIndication.codeEnter' }),
      propsWrapper: { xs: 6 },
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'referenceIndication.name' }),
      placeholder: intl.formatMessage({ id: 'referenceIndication.nameEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    resultType: {
      type: 'select',
      label: intl.formatMessage({ id: 'referenceIndication.typeResult' }),
      placeholder: intl.formatMessage({ id: 'referenceIndication.typeResultEnter' }),
      options: OBSERVATION_OPTIONS,
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    unit: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'referenceIndication.unit' }),
      placeholder: intl.formatMessage({ id: 'referenceIndication.unitEnter' }),
      propsWrapper: { xs: 6 },
    },
    //data Type
    laboratoryConditionList: {
      type: 'array',
      labelAdd: intl.formatMessage({ id: 'referenceIndication.addNewReference' }),
      disableCloseBtn: true,
      paperItemProps: { style: { padding: 0 }, variant: 'elevation', elevation: 0 },
      schema: {
        fields: ({ formProps, valuesField: valuesFieldSection }) => {
          const { arrayData, methodsArray } = formProps;

          return {
            name: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'referenceIndication.conditionIndex' }, { name: arrayData?.index + 1 }),
              placeholder: intl.formatMessage({ id: 'referenceIndication.conditionEnter' }),
              propsWrapper: { xs: 6 },
            },
            lowIndex: {
              type: 'text-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'referenceIndication.value' }),
              placeholder: intl.formatMessage({ id: 'referenceIndication.numberEnter' }),
              propsWrapper: { xs: true, style: { flexGrow: 4 } },
            },
            compareMark: {
              unregister: true,
              type: () => (
                <Typography variant="h5" style={{ marginTop: 16 }}>
                  ≤
                </Typography>
              ),
              style: { width: 80, marginTop: 20 },
              propsWrapper: { xs: true, style: { display: 'flex', alignItems: 'center', justifyContent: 'center' } },
            },
            highIndex: {
              type: 'text-field',
              inputType: 'number',
              placeholder: intl.formatMessage({ id: 'referenceIndication.numberEnter' }),
              propsWrapper: { xs: true, style: { alignSelf: 'flex-end', flexGrow: 4 } },
              register: {
                validate: (value) =>
                  valuesFieldSection.low !== undefined &&
                  value !== undefined &&
                  Number(value) < Number(valuesFieldSection.low)
                    ? ' '
                    : true,
              },
            },
            delete: {
              unregister: true,
              type: () => {
                return (
                  <IconButtonTitle title="delete" size="small" onClick={() => methodsArray?.remove(arrayData?.index)}>
                    <Delete />
                  </IconButtonTitle>
                );
              },
              propsWrapper: {
                xs: true,
                style: { alignSelf: 'flex-end', marginBottom: 12, justifyContent: 'center', display: 'flex' },
              },
            },
          };
        },
      },
    },
    //list
    laboratorySelectionList: {
      type: 'array',
      labelAdd: intl.formatMessage({ id: 'referenceIndication.addSelection' }),
      disableCloseBtn: true,
      paperItemProps: { style: { padding: 0 }, variant: 'elevation', elevation: 0 },
      schema: {
        fields: ({ formProps }) => {
          const { arrayData, methodsArray } = formProps;
          return {
            name: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'referenceIndication.select' }, { name: arrayData?.index + 1 }),
              placeholder: intl.formatMessage({ id: 'referenceIndication.selectEnter' }),
              propsWrapper: { xs: true },
              register: {
                required: true,
              },
            },
            delete: {
              unregister: true,
              type: () => (
                <IconButtonTitle title="delete" size="small" onClick={() => methodsArray?.remove(arrayData?.index)}>
                  <Delete />
                </IconButtonTitle>
              ),
              propsWrapper: {
                xs: undefined,
                style: { alignSelf: 'flex-end', marginBottom: 12, justifyContent: 'center', display: 'flex' },
              },
            },
          };
        },
      },
    },
  };
};

const ui: IUiFields = ({ formProps, valuesField }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'referenceIndication.indexInfo' }),
      fields: ['code', 'name', 'resultType', 'unit'],
    },
    valuesField?.resultType === LAB_TYPE_RESULT_CORE_SERVICE.list && {
      id: 'laboratorySelectionList',
      title: intl.formatMessage({ id: 'referenceIndication.selection' }),
      fields: ['laboratorySelectionList', 'laboratoryConditionList'],
    },
    valuesField?.resultType === LAB_TYPE_RESULT_CORE_SERVICE.quantity && {
      id: 'laboratoryConditionList',
      title: intl.formatMessage({ id: 'referenceIndication.reference' }),
      fields: ['laboratoryConditionList'],
    },
  ]?.filter(Boolean) as FieldsType[];
};

export const referenceIndicationFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
