import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { FE_DATE_TIME_FORMAT, some } from '../../common/constants';
import BasicEncounterInfo from '../../examDoctor/component/detail/BasicEncounterInfo';
import { useFetch, useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import VitalSignBox from 'modules/examDoctor/component/detail/VitalSignBox';
import { FormProvider, useForm } from 'react-hook-form';
import { mutate } from 'swr';

const TAB_MENUS = [{ id: 'vitalSign' }];

interface IPatientEncounterDetailPageProps {}

export const calculateBMI = (weightValue, heightValue) => {
  return weightValue && heightValue
    ? Number(Number(weightValue) / (((Number(heightValue) / 100) * Number(heightValue)) / 100)).toFixed(2)
    : '';
};

const NurseEncounterDetailPage: React.FunctionComponent<IPatientEncounterDetailPageProps> = (props) => {
  const { encounterId } = useParams<{ encounterId; patientId }>();
  const [tab, setTab] = React.useState('vitalSign');
  const { data: encounter } = useFetch(API_SERVER.encounter.get(encounterId), {
    globalLoading: true,
  });
  const { data: observation } = useFetch(API_SERVER.observation.getByEncounterId(encounterId), {
    shouldRetryOnError: false,
    enabled: encounterId,
  });
  const formMethods = useForm();
  const { watch } = formMethods;
  React.useEffect(() => {
    if (observation) {
      formMethods.reset(observation);
    }
  }, [encounter, formMethods, observation]);

  const readOnly = React.useMemo(() => {
    return (
      (encounter?.status === ENCOUNTER_STATUS.FINISHED.value &&
        encounter?.endTime?.isBefore(moment().subtract(1, 'day'))) ||
      encounter?.status === ENCOUNTER_STATUS.ERROR.value
    );
  }, [encounter?.endTime, encounter?.status]);

  const saveObservationMutate = useUpdateMutate({
    onSuccess: () => encounterId && mutate(API_SERVER.observation.getByEncounterId(encounterId).url),
    globalLoading: false,
  });

  const onSubmit = React.useCallback(
    (data) => {
      if (
        formMethods.getValues('bodyHeight') !== observation?.bodyHeight ||
        formMethods.getValues('bodyWeight') !== observation?.bodyWeight ||
        formMethods.getValues('bodyTemperature') !== observation?.bodyTemperature ||
        formMethods.getValues('diastolicBloodPressure') !== observation?.diastolicBloodPressure ||
        formMethods.getValues('heartRate') !== observation?.heartRate ||
        formMethods.getValues('respiratoryRate') !== observation?.respiratoryRate ||
        formMethods.getValues('spo2') !== observation?.spo2 ||
        formMethods.getValues('systolicBloodPressure') !== observation?.systolicBloodPressure
      ) {
        saveObservationMutate({
          url: API_SERVER.observation.save(),
          method: 'post',
          data: {
            encounterId,
            bodyTemperature: formMethods.getValues('bodyTemperature'),
            heartRate: formMethods.getValues('heartRate'),
            respiratoryRate: formMethods.getValues('respiratoryRate'),
            systolicBloodPressure: formMethods.getValues('systolicBloodPressure'),
            diastolicBloodPressure: formMethods.getValues('diastolicBloodPressure'),
            bodyHeight: formMethods.getValues('bodyHeight'),
            bodyWeight: formMethods.getValues('bodyWeight'),
            spo2: formMethods.getValues('spo2'),
          },
        });
      }
    },
    [
      encounterId,
      formMethods,
      observation?.bodyHeight,
      observation?.bodyTemperature,
      observation?.bodyWeight,
      observation?.diastolicBloodPressure,
      observation?.heartRate,
      observation?.respiratoryRate,
      observation?.spo2,
      observation?.systolicBloodPressure,
      saveObservationMutate,
    ],
  );

  const weightValue = watch('bodyWeight');
  const heightValue = watch('bodyHeight');

  const bmi = React.useMemo(() => {
    return calculateBMI(weightValue, heightValue);
  }, [weightValue, heightValue]);

  return (
    <Paper elevation={1} style={{ overflow: 'auto', position: 'relative', flex: 1 }}>
      <Box padding={2} paddingBottom={0}>
        <BasicEncounterInfo encounter={encounter} editable={false} />
      </Box>
      <Box bgcolor={'white'} borderBottom={`1px solid ${grey[400]}`}>
        <Box paddingX={2} paddingTop={1}>
          <Typography variant="h6" color="primary">
            {encounter?.patient?.name}
            &emsp;-&emsp;
            <Typography variant="subtitle1" color="textSecondary" component="span">
              <FormattedMessage id="encounterHistory.day" />
              &nbsp;
              {encounter?.createdTime?.format(FE_DATE_TIME_FORMAT)}
            </Typography>
          </Typography>
          <Typography variant="body2" marginTop={1}>
            <FormattedMessage id="reason" />
            :&nbsp;
            <Typography variant="body2" component="span">
              {encounter?.reason}
            </Typography>
          </Typography>
        </Box>
        <Box display="flex" alignItems="center">
          <Tabs
            style={{ flex: 1 }}
            value={tab}
            onChange={(e, newValue) => {
              setTab(newValue);
            }}
            variant="scrollable"
            indicatorColor="secondary"
            textColor="secondary"
          >
            {TAB_MENUS.map((tab: some, index: number) => {
              return (
                <Tab
                  key={index}
                  value={tab.id}
                  style={{ minWidth: 100 }}
                  label={
                    <Typography variant="subtitle2">
                      <FormattedMessage id={`encounterDetail.${tab.id}`} />
                    </Typography>
                  }
                />
              );
            })}
          </Tabs>
        </Box>
      </Box>
      <Box overflow="auto">
        <Box style={{ display: tab === 'vitalSign' ? 'block' : 'none' }} padding={2}>
          <FormProvider {...formMethods}>
            <VitalSignBox readOnly={readOnly} submit={onSubmit} expand={true} bmi={bmi} />
          </FormProvider>
        </Box>
      </Box>
    </Paper>
  );
};

export default NurseEncounterDetailPage;
