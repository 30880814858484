import { ClickAwayListener, Grow, List, ListItem, ListItemIcon, Paper, Theme, Typography } from '@mui/material';
import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  DENTAL_EXAM_STATUS,
  FE_DATE_FORMAT,
  some,
} from '../../../common/constants';
import EditIcon from '@mui/icons-material/Edit';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import * as React from 'react';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { API_SERVER } from '../../../common/api';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import ReceiveWizardDialog, { ModeProps } from '../dental/ReceiveWizardDialog';
import CancelReasonEncounterDialog from '../encounter/CancelReasonEncounterDialog';
import DialogCustom from '../../../common/component/DialogCustom';
import { Box } from '@mui/system';
import VitalSignForm from '../../../nurse/encounterDetail/VitalSignForm';
import PrintEncounterRegistration from '../encounter/PrintEncounterRegistration';
import moment from 'moment';
import { DentalExam } from 'modules/schema';
import { useImmer } from 'use-immer';
import { DoctorExchange, PrintRegistration } from 'svg';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import PrintForm from './PrintForm';
import makeStyles from '@mui/styles/makeStyles';
import { useEditPICSelectors } from '../encounter/EditPICDialog/state';
import EditPICDialog from '../encounter/EditPICDialog';
import { useUpdateMutate } from 'modules/common/hook';

const useStyles = makeStyles((theme: Theme) => ({
  paper: { padding: 0, overflow: 'hidden' },
  list: { padding: 0 },
  listItemActive: {
    // backgroundColor: LIGHT_GREY,
  },
  listIcon: {
    minWidth: 36,
    fill: 'gray',
  },
}));

interface IDentalExamListProps {
  data?: DentalExam[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  refresh: () => void;
}

const DentalList: React.FunctionComponent<IDentalExamListProps> = ({
  refresh,
  data,
  loading,
  hookPagination,
  total,
}) => {
  const { appState } = useGeneralHook();
  const classes = useStyles();
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const ref = React.useRef<HTMLTableRowElement | null>();
  const [rightClickPopup, setRightClickPopup] = useImmer<{
    x: number;
    y: number;
    selectedObject: DentalExam | null;
    dialogOpen: boolean;
  }>({ x: 0, y: 0, selectedObject: null, dialogOpen: false });
  const [objectMode, setObjectMode] = React.useState<ModeProps | null>(null);
  const [printRegistrationInfo, setPrintRegistrationInfo] = React.useState<some | null>(null);
  const [cancelFormDialog, setCancelFormDialog] = React.useState<some | undefined>(undefined);
  const [vitalSignForm, setVitalSignForm] = React.useState<some | undefined>(undefined);
  const [rightClickData, setRightClickData] = React.useState<
    | {
        dentalExamId: number | undefined;
        status: string | undefined;
        patientId: number | undefined;
        dentalExam: some | undefined;
      }
    | undefined
  >(undefined);

  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
  const onCloseRightClickDialog = React.useCallback(
    () =>
      setRightClickPopup((draft) => {
        draft.dialogOpen = false;
        draft.selectedObject = null;
      }),
    [setRightClickPopup],
  );
  const onOpenEditPICDialog = useEditPICSelectors.use.onOpenDialog();

  // huy ca kham rang
  const cancelEncounterMutate = useUpdateMutate({
    onSuccess: () => {
      refresh();
      onCloseRightClickDialog();
      setRightClickData(undefined);
      setCancelFormDialog(undefined);
    },
  });
  const cancelEncounter = React.useCallback(
    async (values: some) => {
      if (values?.dentalExamId) {
        await cancelEncounterMutate({
          url: API_SERVER.dental.cancelDentalExam(values.dentalExamId),
          method: 'PUT',
          data: values.cancelReason || undefined,
        });
      }
    },
    [cancelEncounterMutate],
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounter.table.label.sequenceNumber',
        dataIndex: 'sequenceNumber',
        align: 'left',
        width: 90,
      },
      {
        title: 'encounterId',
        dataIndex: 'code',
        align: 'right',
      },

      {
        title: 'patientCodeShort',
        render: (record) => {
          return <Typography>{record?.patient?.code?.split('-')?.[1] || record?.patient?.resourceId}</Typography>;
        },
        align: 'right',
      },
      {
        title: 'patientName',
        dataIndex: 'patient.name',
      },

      {
        title: 'birthdayShort',
        render: (record) => (
          <Typography>
            {record.patient?.dob && moment(record.patient.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
        width: 120,
      },

      {
        title: 'telephone',
        dataIndex: 'patient.mobilePhone',
      },

      {
        title: 'encounterDate',
        render: (record) => (
          <Typography>
            {record.createdTime && moment(record.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
      },
      {
        title: 'encounterDoctor',
        dataIndex: 'picName',
      },
      {
        title: 'checkUpType',
        dataIndex: 'serviceName',
      },
      {
        title: 'status',
        render: (record) => {
          const status = DENTAL_EXAM_STATUS[record?.status!];
          return (
            <Typography
              noWrap
              sx={{
                color: status?.sx || 'primary.main',
                // fontWeight: '500',
              }}
            >
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
      },

      {
        title: 'checkupRoom',
        dataIndex: 'physicsRoomName',
      },
    ] as Columns<DentalExam>[];
  }, []);

  const showEditPICButton =
    rightClickData &&
    (!rightClickData?.dentalExam?.endTime ||
      (rightClickData?.dentalExam?.endTime &&
        moment(rightClickData?.dentalExam?.endTime, BE_DATE_TIME_FORMAT).isAfter(moment().add(-1, 'day'))));

  const role = appState.authen.user?.role || [];

  return (
    <>
      <TableCustom
        dataSource={data}
        loading={loading}
        columns={columns}
        rowProps={
          role.indexOf('RECEPTIONIST') < 0 && role.indexOf('COORDINATOR') < 0 && role.indexOf('GENERAL_DOCTOR') < 0
            ? undefined
            : (record: DentalExam) => ({
                onContextMenu: (e) => {
                  e.preventDefault();
                  if (e.currentTarget) {
                    ref.current = e.currentTarget;
                  }
                  setRightClickPopup((draft) => {
                    draft.x = e.clientX;
                    draft.y = e.clientY;
                    draft.dialogOpen = true;
                    draft.selectedObject = record;
                  });
                  setRightClickData({
                    dentalExamId: record.id,
                    status: record.status,
                    patientId: record.patientId,
                    dentalExam: record,
                  });
                },
                onClick: async () => {
                  setRightClickData({
                    dentalExamId: record.id,
                    status: record.status,
                    patientId: record.patientId,
                    dentalExam: record,
                  });
                },
                style: { cursor: 'pointer' },
              })
        }
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
      <ReceiveWizardDialog
        mode={objectMode}
        open={!!objectMode}
        close={() => {
          setObjectMode(null);
          onCloseRightClickDialog();
          localStorage.removeItem('create_onsecutively');
        }}
        refresh={refresh}
        selectedObject={rightClickPopup.selectedObject}
      />
      <CancelReasonEncounterDialog
        open={!!cancelFormDialog}
        onClose={() => {
          setCancelFormDialog(undefined);
        }}
        formData={cancelFormDialog}
        onSubmit={cancelEncounter}
      />
      <DialogCustom
        open={!!vitalSignForm}
        onClose={() => {
          setVitalSignForm(undefined);
        }}
        maxWidth="xl"
        title={'encounterList.vitalSignEdit'}
      >
        <Box padding={2}>
          {vitalSignForm?.encounterId && (
            <VitalSignForm
              encounterId={vitalSignForm?.encounterId}
              readOnly={vitalSignForm.encounterStatus === 'finished'}
              onClose={() => {
                setVitalSignForm(undefined);
              }}
            />
          )}
        </Box>
      </DialogCustom>
      <PrintEncounterRegistration
        encounter={{}}
        open={!!printRegistrationInfo}
        onClose={() => {
          setPrintRegistrationInfo(null);
        }}
      />
      <ClickAwayListener onClickAway={onCloseRightClickDialog}>
        <Grow in={rightClickPopup.dialogOpen}>
          <Paper
            elevation={3}
            sx={{
              position: 'fixed',
              top: rightClickPopup.y,
              left: rightClickPopup.x,
              overflow: 'hidden',
              zIndex: 99,
            }}
          >
            <List>
              <ListItem button dense onClick={() => setOpenPrintDialog(true)}>
                <ListItemIcon className={classes.listIcon}>
                  <PrintRegistration fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="encounter.registration.button.printRegistration" />
              </ListItem>
              <ListItem
                button
                dense
                onClick={() => {
                  setObjectMode({
                    patientId: rightClickData?.patientId!,
                    type: 'register',
                  });
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  <AddCircleIcon fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="encounterList.addEncounter" />
              </ListItem>
              <ListItem
                button
                dense
                onClick={() => {
                  setObjectMode({
                    patientId: rightClickData?.patientId,
                    dentalExamId: rightClickData?.dentalExamId,
                    type: 'edit',
                  });
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="encounterList.editEncounter" />
              </ListItem>
              {/*<ListItem button dense onClick={() => {}}>*/}
              {/*  <ListItemIcon className={classes.listIcon}>*/}
              {/*    <MedicalInformationIcon fontSize="small" />*/}
              {/*  </ListItemIcon>*/}
              {/*  <FormattedMessage id="encounterList.vitalSignEdit" />*/}
              {/*</ListItem>*/}
              {showEditPICButton && (
                <ListItem
                  button
                  dense
                  disabled={
                    rightClickData?.status === DENTAL_EXAM_STATUS.CANCELLED.value ||
                    rightClickData?.status === DENTAL_EXAM_STATUS.FINISHED.value
                  }
                  onClick={() => {
                    if (rightClickData) {
                      onOpenEditPICDialog({
                        encounterId: String(rightClickData.dentalExamId),
                        doctorName: String(rightClickData.dentalExam?.doctorName || ''),
                        doctorId: String(rightClickData.dentalExam?.doctorId),
                        encounterType: 'DENTAL',
                      });
                    }
                  }}
                >
                  <ListItemIcon className={classes.listIcon}>
                    <DoctorExchange />
                  </ListItemIcon>
                  <FormattedMessage id="reception.encounterList.editPIC.button.title" />
                </ListItem>
              )}
              <ListItem
                button
                dense
                disabled={rightClickData?.status !== DENTAL_EXAM_STATUS.ARRIVED.value}
                onClick={async () => {
                  if (rightClickData?.dentalExamId) {
                    setCancelFormDialog(rightClickData);
                  }
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <FormattedMessage id="encounterList.cancel" />
              </ListItem>
            </List>
          </Paper>
        </Grow>
      </ClickAwayListener>
      {rightClickPopup?.selectedObject && (
        <PrintForm
          dentalExam={rightClickPopup.selectedObject}
          openDialog={openPrintDialog}
          onCloseDialog={() => setOpenPrintDialog(false)}
        />
      )}
      <EditPICDialog refreshData={refresh} />
    </>
  );
};

export default DentalList;
