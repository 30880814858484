import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';
import { MedicationSchema } from './Medication';
import { MedicationUnitSettingSchema } from './MedicationUnitSetting';

export const InventoryInfoSchema = z
  .object({
    id: z.number(),
    clinicId: z.number(),
    quantity: z.number(),
    expiredDate: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    lot: z.string(),
    averagePrice: z.number(),
    medication: MedicationSchema,
    medicationUnitSettings: z.array(MedicationUnitSettingSchema),
    medicationId: z.number(),
  })
  .partial();

export type InventoryInfo = z.infer<typeof InventoryInfoSchema>;
