import IconButton from 'modules/common/component/IconButton';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { useDeleteMutate, useDialog, useFetch } from 'modules/common/hook';
import { Button, Stack, Typography } from '@mui/material';
import DialogCustom from 'modules/common/component/DialogCustom';
import { API_SERVER } from 'modules/common/api';
import { STORAGE_RESOURCE_TYPE } from 'modules/common/apiConstants';
import { EncounterDetailProps } from '../../type';
import { FormattedMessage } from 'react-intl';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ImageList from 'modules/common/component/ImageList';
import useUpdateMultipleFiles from 'modules/common/hook/useUploadMultipleFiles';

const ImageButton = ({ encounter, readonly }: EncounterDetailProps) => {
  const [open, onOpen, onClose] = useDialog();
  const { data: images, revalidate } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.ENCOUNTER_DIAGNOSTIC_IMAGE,
      referenceId: encounter?.id!,
    }),
  );
  const onDeleteMutate = useDeleteMutate({ onSuccess: revalidate });
  const onUploadFile = useUpdateMultipleFiles({ onSuccess: revalidate });
  return (
    <>
      <IconButton
        icon={<AddPhotoAlternateIcon />}
        sx={{ color: '#0962FF' }}
        showSlotNumber={images && images?.length > 0}
        slotNumber={images?.length || 0}
        onClick={onOpen}
        tooltipTitle="therapy.detail.label.attackImage"
      />
      <DialogCustom title="image" open={open} onClose={onClose}>
        <Stack padding={2} gap={3}>
          <ImageList
            editable
            images={images}
            onDeleteImage={(image) =>
              onDeleteMutate({ url: API_SERVER.storageResource.delete(image.id!), method: 'DELETE' })
            }
            imageHeight="150px"
            imageWidth="150px"
            lastItem={
              readonly ? undefined : (
                <Button
                  component="label"
                  sx={{
                    width: '150px',
                    height: '150px',
                    backgroundColor: '#fff',
                    border: '1px dashed #B8C9DA',
                    boxSizing: 'border-box',
                    '&:hover': {
                      backgroundColor: '#E6F1FF',
                    },
                  }}
                >
                  <input
                    accept="image/*"
                    hidden
                    type="file"
                    multiple
                    onChange={(event) => {
                      if (event.target.files && event.target.files?.length > 0) {
                        onUploadFile(
                          Array.from(event.target.files).map((file) => ({
                            file,
                            type: STORAGE_RESOURCE_TYPE.ENCOUNTER_DIAGNOSTIC_IMAGE,
                            referenceId: encounter?.id!,
                          })),
                        );
                      }
                    }}
                  />
                  <Stack direction="column" justifyContent="center" alignItems="center">
                    <AddCircleIcon sx={{ fontSize: '30px !important', color: '#307BFF' }} />
                    <Typography
                      sx={{
                        color: '#307BFF',
                        fontFeatureSettings: "'clig' off, 'liga' off",
                        fontFamily: 'Roboto',
                        fontSize: '14px',
                        fontStyle: 'normal',
                        fontWeight: 500,
                        lineHeight: '24px',
                        letterSpacing: '0.1px',
                        borderRadius: '50%',
                        cursor: 'pointer',
                      }}
                    >
                      <FormattedMessage id="add" />
                    </Typography>
                  </Stack>
                </Button>
              )
            }
          />
        </Stack>
      </DialogCustom>
    </>
  );
};

export default ImageButton;
