import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from '../../../common/component/TableCustom/element';
import TablePaginationActionsCustom from '../../../common/component/TableCustom/TablePaginationActionsCustom';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook, { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { THERAPY_SESSION_STATUS, THERAPY_STATUS } from '../../../reception/constants';
import { ROUTES } from '../../../layout/router';
import { useHistory } from 'react-router';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import CancelIcon from '@mui/icons-material/Cancel';
import InfoIcon from '@mui/icons-material/Info';
import { Therapy } from 'modules/schema';
import { Page } from 'modules/schema/common';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { PATIENT_TYPE } from 'modules/common/apiConstants';

const Row: React.FunctionComponent<{
  row: some;
  index: number;
  onDelete: (value) => void;
  onShowReason: (value) => void;
}> = (props) => {
  const { row, index, onDelete, onShowReason } = props;
  const [open, setOpen] = React.useState(false);
  const status = THERAPY_STATUS.find((v) => v.value === row.status);
  const sessionList = row?.therapySessionList?.sort((a, b) => a.orderNumber - b.orderNumber);
  const history = useHistory();
  const { setParams } = usePaginationHook({ disablePagination: true });

  // redirect to therapy detail page
  const onSelectTherapy = React.useCallback(
    (record: some, therapySessionId?: number) => {
      history.push(ROUTES.therapyDetail.gen(record?.id));
      if (therapySessionId) {
        setParams({
          therapySessionId,
        });
      }
    },
    [history, setParams],
  );

  const dataDiagnosis = React.useMemo(() => {
    return row.diagnosis ? extractHTMLContent(row.diagnosis) : '';
  }, [row]);

  return (
    <>
      <TableCustomRow style={{ cursor: 'pointer' }}>
        <TableCustomCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={(e) => {
              e.stopPropagation();
              setOpen(!open);
            }}
          >
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Typography sx={{ fontWeight: '500' }}>{index}</Typography>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            <Typography sx={{ fontWeight: '500' }}>{row.code}</Typography>
          </Typography>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            {row?.referenceCode}
          </Typography>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            {row.patient?.name}
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography sx={{ fontWeight: '500' }}>
            {row?.patientType && <FormattedMessage id={PATIENT_TYPE[row.patientType]?.label} />}
          </Typography>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Typography sx={{ fontWeight: '500' }}>{row.patient?.mobilePhone}</Typography>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Tooltip title={dataDiagnosis || ''}>
            <Typography
              sx={{
                fontWeight: '500',
                maxWidth: '120px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {dataDiagnosis}
            </Typography>
          </Tooltip>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Typography sx={{ fontWeight: '500' }}>{row.name}</Typography>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Typography sx={{ fontWeight: '500' }}>
            {row.therapySessionList.filter((i: some) => i.status === 'FINISHED').length +
              '/' +
              row.therapySessionList.filter((i: some) => i.status !== 'CANCELLED').length}
          </Typography>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Typography>
            {row.createdTime && moment(row.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        </TableCustomCell>
        <TableCustomCell onClick={(e) => onSelectTherapy(row)}>
          <Typography
            noWrap
            sx={{
              color: status?.sx || 'primary.main',
              fontWeight: '500',
            }}
          >
            <FormattedMessage id={status?.label} />
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          {row.status !== 'FINISHED' && row.status !== 'CANCELLED' && (
            <IconButtonTitle
              title="encounterInfo.cancelTherapy"
              size="small"
              onClick={() => {
                onDelete(row);
              }}
            >
              <CancelIcon />
            </IconButtonTitle>
          )}
          {row.status === 'CANCELLED' && (
            <IconButtonTitle
              title="sellingProduct.label.viewDetail"
              size="small"
              onClick={() => {
                onShowReason(row);
              }}
            >
              <InfoIcon />
            </IconButtonTitle>
          )}
        </TableCustomCell>
      </TableCustomRow>
      <TableRow style={{ backgroundColor: 'white', padding: 0 }}>
        <TableCustomCell style={{ padding: '0px', border: 'none', backgroundColor: 'inherit' }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases" style={{ marginTop: 4 }}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell style={{ width: 54 }} />
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapyList.header.label.therapySession.name" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapyList.header.label.therapySession.description" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapyList.header.label.therapySession.startDate" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapyList.header.label.therapySession.picName" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="status" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapyList.header.label.therapySession.conclude" />
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sessionList
                  ?.filter((s) => s.status !== 'CANCELLED')
                  .map((record: some, index) => {
                    const sessionStatus = THERAPY_SESSION_STATUS.find((v) => v.value === record.status);
                    return (
                      <TableRow key={index} style={{ cursor: 'pointer' }}>
                        <TableCustomCell />
                        <TableCustomCell onClick={() => onSelectTherapy(row, record.id)}>{record.name}</TableCustomCell>
                        <TableCustomCell
                          onClick={() => onSelectTherapy(row, record.id)}
                          style={{ wordBreak: 'break-all' }}
                        >
                          {record.description}
                        </TableCustomCell>
                        <TableCustomCell onClick={() => onSelectTherapy(row, record.id)}>
                          {record.startTime && moment(record.startTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
                        </TableCustomCell>
                        <TableCustomCell onClick={() => onSelectTherapy(row, record.id)}>
                          {record.picName}
                        </TableCustomCell>
                        <TableCustomCell onClick={() => onSelectTherapy(row, record.id)}>
                          <Typography
                            noWrap
                            sx={{
                              color: sessionStatus?.sx || 'primary.main',
                            }}
                          >
                            <FormattedMessage id={sessionStatus?.label || ' '} />
                          </Typography>
                        </TableCustomCell>
                        <TableCustomCell
                          onClick={() => onSelectTherapy(row, record.id)}
                          style={{ wordBreak: 'break-all' }}
                        >
                          {record.conclude}
                        </TableCustomCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCustomCell>
      </TableRow>
    </>
  );
};

interface Props {
  data?: Page<Therapy>;
  onDelete: (value) => void;
  onShowReason: (value) => void;
  hookPagination: HookPaginationProps;
}

const ExamTherapyList: React.FunctionComponent<Props> = (props) => {
  const { data, hookPagination, onDelete, onShowReason } = props;
  const { intl } = useGeneralHook();
  const { onPageChange, onRowsPerPageChange, pagination } = hookPagination;

  return (
    <Box
      position="relative"
      overflow="inherit"
      display="flex"
      flexDirection="column"
      height="-webkit-fill-available"
      flex={1}
    >
      <TableContainer style={{ height: '100%', flex: 1 }}>
        <Table stickyHeader>
          <TableHead>
            <TableCustomRow>
              <TableHeaderCell style={{ width: 54 }} />
              <TableHeaderCell style={{ width: 54 }}>
                <FormattedMessage id="stt" />
              </TableHeaderCell>
              <TableHeaderCell style={{ width: 120 }}>
                <FormattedMessage id="therapyList.header.label.therapyCode" />
              </TableHeaderCell>
              <TableHeaderCell style={{ width: 120 }}>
                <FormattedMessage id="cashierPrint.encounterId" />
              </TableHeaderCell>
              <TableHeaderCell width="15%">
                <FormattedMessage id="therapy.label.customer.name" />
              </TableHeaderCell>
              <TableHeaderCell width="130">
                <FormattedMessage id="appointmentBook.isNewPatient" />
              </TableHeaderCell>
              <TableHeaderCell width="120">
                <FormattedMessage id="therapyList.header.label.mobilePhone" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="therapyList.header.label.diagnosis" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="therapyList.header.label.therapyName" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="therapy.quantity" />
              </TableHeaderCell>
              <TableHeaderCell width="110">
                <FormattedMessage id="therapyList.header.label.therapySession.createdTime" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="status" />
              </TableHeaderCell>
              <TableHeaderCell style={{ width: 120 }}>
                <FormattedMessage id="therapy.action" />
              </TableHeaderCell>
            </TableCustomRow>
          </TableHead>
          <TableBody>
            {data?.content && data?.content?.length > 0 ? (
              data?.content?.map((row, index) => (
                <Row key={index} row={row} index={index + 1} onDelete={onDelete} onShowReason={onShowReason} />
              ))
            ) : (
              <>
                <TableCustomRow>
                  <TableCustomCell colSpan={12}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100%',
                      }}
                    >
                      <Typography variant="inherit" color="textSecondary">
                        <FormattedMessage id="noData" />
                      </Typography>
                    </Box>
                  </TableCustomCell>
                </TableCustomRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        data-tour="step-5"
        style={{ padding: 4 }}
        labelRowsPerPage={intl.formatMessage({ id: 'labelRowPerPage' })}
        ActionsComponent={TablePaginationActionsCustom}
        count={data?.pagination?.totalElements || 0}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Box>
  );
};

export default ExamTherapyList;
