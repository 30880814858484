export const DENTAL_INDICATION_STATUS = [
  { value: 'NEW', label: 'therapy.management.status.new', color: 'primary.main' },
  { value: 'REQUESTED', label: 'therapyIndication.status.requested', color: 'primary.main' },
  { value: 'FINISHED', label: 'therapyIndication.status.finished', color: 'success.main' },
  { value: 'DELETED', label: 'therapyIndication.status.deleted', color: 'error.main' },
  { value: 'WAIT', label: 'therapyIndication.status.wait', color: 'warning.main' },
];

export const DENTAL_SESSION_TYPE = {
  THERAPY_SESSION: 'THERAPY_SESSION',
  THERAPY_DIAGNOSTIC_IMAGE: 'THERAPY_DIAGNOSTIC_IMAGE',
  THERAPY_DIAGNOSTIC_IMAGE_ALBUM: 'THERAPY_DIAGNOSTIC_IMAGE_ALBUM',
};

export const TOOTH_POSITION = [...Array(38).keys()]?.map((num, index) => ({
  value: num,
  index: index + 11,
  label: `Răng Số ${index + 11}`,
}));

export const DENTAL_INDICATION_OBJECT_LIST = [
  ...TOOTH_POSITION.map((el) => ({
    ...el,
    dentalGroup: 'TOOTH',
  })),
  {
    value: 'GUMS',
    dentalGroup: 'GUMS',
    label: 'Nướu',
  },
  {
    value: 'JAW',
    dentalGroup: 'JAW',
    label: 'Hàm',
  },
  {
    value: 'MOUTH',
    dentalGroup: 'MOUTH',
    label: 'Khoang miệng',
  },
  {
    value: 'TONGUE',
    dentalGroup: 'TONGUE',
    label: 'Lưỡi',
  },
  {
    value: 'PERIODONTAL',
    dentalGroup: 'PERIODONTAL',
    label: 'Nha chu',
  },
  {
    value: 'GENERAL',
    dentalGroup: 'GENERAL',
    label: 'Chung',
  },
];

export const DENTAL_OBJECT_LIST = [
  {
    value: 'TOOTH',
    dentalGroup: 'TOOTH',
    label: 'Răng',
  },
  {
    value: 'GUMS',
    dentalGroup: 'GUMS',
    label: 'Nướu',
  },
  {
    value: 'JAW',
    dentalGroup: 'JAW',
    label: 'Hàm',
  },
  {
    value: 'MOUTH',
    dentalGroup: 'MOUTH',
    label: 'Khoang miệng',
  },
  {
    value: 'TONGUE',
    dentalGroup: 'TONGUE',
    label: 'Lưỡi',
  },
  {
    value: 'PERIODONTAL',
    dentalGroup: 'PERIODONTAL',
    label: 'Nha chu',
  },
  {
    value: 'GENERAL',
    dentalGroup: 'GENERAL',
    label: 'Chung',
  },
];
