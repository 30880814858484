import { Paper } from '@mui/material';
import * as React from 'react';
import DrugDataListTable from '../component/drugClinic/DrugDataListTable';
import DrugDataFormDialog from '../component/drugClinic/DrugDataFormDialog';
import GroupDrugDataFormDialog from '../component/drugClinic/GroupDrugDataFormDialog';
import useDrugClinicAdmin from '../component/drugClinic/DrugClinicHook';

interface Props {}

const DrugClinicPage: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    isValidating,
    revalidate,
    state: { searchParams, setSearchParams, formDataGroup },
    fnc: {
      setFormDataGroup,
      onDeleteGroup,
      onSubmitFormGroup,
      onSubmitForm,
      refreshMedicationCategoryId,
      setRefreshMedicationCategoryId,
    },
  } = useDrugClinicAdmin();

  return (
    <Paper style={{ flex: 1, overflow: 'auto' }}>
      <DrugDataListTable
        loading={isValidating}
        setFormDataGroup={setFormDataGroup}
        onDeleteGroup={onDeleteGroup}
        data={data}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        revalidate={revalidate}
        refreshMedicationCategoryId={refreshMedicationCategoryId}
        setRefreshMedicationCategoryId={setRefreshMedicationCategoryId}
      />
      <GroupDrugDataFormDialog
        open={!!formDataGroup}
        formData={formDataGroup}
        onSubmit={onSubmitFormGroup}
        onClose={() => {
          setFormDataGroup(undefined);
        }}
      />

      <DrugDataFormDialog onSubmit={onSubmitForm} />
    </Paper>
  );
};

export default DrugClinicPage;
