import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import { useDialog, useUpdateMutate } from 'modules/common/hook';
import { Encounter, IndicationRequest } from 'modules/schema';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import DialogCustom from 'modules/common/component/DialogCustom';
import { FormattedMessage } from 'react-intl';
import { mutate } from 'swr';
import { API_SERVER } from 'modules/common/api';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import { useMemo } from 'react';
import { INDICATION_REQUEST_STATUS } from 'modules/common/constants';
import { isEmpty } from 'lodash';
import CheckIcon from '@mui/icons-material/Check';

interface CompleteExamBlockProps {
  encounter: Encounter;
  readonly: boolean;
  dataIndication: IndicationRequest[];
}

const CompleteExamBlock = ({ encounter, dataIndication, readonly }: CompleteExamBlockProps) => {
  const [openCompleteDialog, onOpenCompleteDialog, onCloseCompleteDialog] = useDialog();
  const { intl } = useGeneralHook();
  const completeMutate = useUpdateMutate({
    onSuccess: () => {
      mutate(API_SERVER.encounter.get(encounter?.id!).url);
      onCloseCompleteDialog();
    },
  });

  const isCompleteIndication = useMemo(() => {
    return (
      dataIndication?.filter((item) => item.status === INDICATION_REQUEST_STATUS.FINISHED.value).length ===
        dataIndication?.length || isEmpty(dataIndication)
    );
  }, [dataIndication]);

  return (
    <>
      {encounter?.status === ENCOUNTER_STATUS.INPROGRESS.value && encounter?.id && (
        <Button
          onClick={onOpenCompleteDialog}
          disabled={readonly}
          variant="contained"
          fullWidth
          sx={{ padding: '5px 10px', justifyContent: 'center' }}
        >
          <FormattedMessage id="encounterDetail.complete" />
        </Button>
      )}
      {encounter?.status === ENCOUNTER_STATUS.FINISHED.value && (
        <Button
          startIcon={<CheckIcon color="success" />}
          sx={{
            background: '#e8f5e9',
            padding: '5px 10px',
            justifyContent: 'center',
            '&:hover': {
              backgroundColor: '#e8f5e9',
              boxShadow: 'none',
              cursor: 'default',
            },
          }}
          disableRipple
          size="small"
        >
          <Typography color={'#2e7d32'} fontWeight={500} fontSize={14}>
            <FormattedMessage id="encounter.incomplete" />
          </Typography>
        </Button>
      )}
      {(encounter?.status === ENCOUNTER_STATUS.CANCELLED.value ||
        encounter?.status === ENCOUNTER_STATUS.ERROR.value) && (
        <Chip color="error" label={intl.formatMessage({ id: 'encounter.error' })} />
      )}
      <DialogCustom
        open={openCompleteDialog}
        onClose={onCloseCompleteDialog}
        PaperProps={{
          style: { minWidth: 480 },
        }}
        title={intl.formatMessage({ id: 'encounterDetail.complete.title' })}
      >
        <Box padding={2}>
          <Typography>
            {intl.formatMessage({ id: 'encounterDetail.complete.confirmMessage' }, { name: encounter?.patient?.name })}
          </Typography>
          {!isCompleteIndication && (
            <Typography variant="caption" color="error">
              {intl.formatMessage({ id: 'NotAllIndicationComplete' })}
            </Typography>
          )}

          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2} marginTop={'30px'}>
            <Button
              variant="outlined"
              color="primary"
              sx={{ padding: '5px 20px' }}
              type="button"
              onClick={onCloseCompleteDialog}
            >
              <Typography>
                <FormattedMessage id="encounter.encounterDetail.editPIC.editButton.title" />
              </Typography>
            </Button>
            <Button
              sx={{ padding: '5px 20px' }}
              type="button"
              disabled={!isCompleteIndication}
              onClick={() => {
                completeMutate({
                  url: API_SERVER.encounter.complete(encounter?.id!),
                  method: 'PUT',
                  data: { isComplete: true },
                });
              }}
            >
              <Typography>
                <FormattedMessage id="encounter.encounterDetail.editPIC.saveButton.title" />
              </Typography>
            </Button>
          </Stack>
        </Box>
      </DialogCustom>
    </>
  );
};

export default CompleteExamBlock;
