import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { FE_DATE_FORMAT } from '../../../../common/constants';
import { IndicationHistory } from 'modules/schema';
import { INDICATION_LABORATORY_EVALUATE } from 'modules/common/apiConstants';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';

interface Props {
  indicationHistories: IndicationHistory[];
}

const COLUMNS = [
  {
    title: 'encounterDate',
    width: 200,
    render: (record) => record?.dateTimeFinish?.format(FE_DATE_FORMAT),
  },
  {
    title: 'referenceIndication.result',
    dataIndex: 'result',
  },
  {
    title: 'referenceIndication.review',
    dataIndex: 'interpretation',
    render: (record) => {
      return <FormattedMessage id={INDICATION_LABORATORY_EVALUATE[record?.evaluate!]?.label || ' '} />;
    },
  },
] as Columns<IndicationHistory>[];
const ListLabDetail: React.FunctionComponent<Props> = ({ indicationHistories }) => {
  return (
    <Box padding={1}>
      <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: 8 }}>
        <FormattedMessage id={'indication.result'} />
      </Typography>
      <TableCustom dataSource={indicationHistories} columns={COLUMNS} />
    </Box>
  );
};

export default ListLabDetail;
