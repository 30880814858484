import * as React from 'react';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { Box, Typography } from '@mui/material';
import printStyles from './PrintPrescriptionStyle.module.scss';
import { Prescription } from 'modules/schema';

interface Props {
  data?: Prescription[];
}

const PrescriptionPrintDataTable: React.FunctionComponent<Props> = ({ data }) => {
  const columns: Columns[] = [
    {
      title: 'encounterPrescription.drugName',
      render: (record) => {
        return (
          <>
            <Box key={record.id} style={{ pageBreakInside: 'avoid' }}>
              <Typography variant="subtitle1" className={printStyles['LargeFontSize']}>
                {record?.prescriptionDetail?.medication?.name}&nbsp;
                {!record.compact && (
                  <Typography variant="body1" component="span" className={printStyles['LargeFontSize']}>
                    ({record?.prescriptionDetail?.medication?.ingredient},{' '}
                    {record?.prescriptionDetail?.medication?.dose})
                  </Typography>
                )}
              </Typography>
              <Box display="flex" flexWrap="wrap" paddingLeft={0} columnGap={2}>
                <Typography variant="body1" className={printStyles['LargeFontSize']}>
                  {record && record?.intendedRoute && record?.intendedRoute[0]?.label}
                </Typography>
                <Typography variant="body1" className={printStyles['LargeFontSize']}>
                  &nbsp;{record?.usage}
                </Typography>
              </Box>
            </Box>
          </>
        );
      },
    },
    {
      render: (record) => {
        return (
          <Box>
            <Typography variant="body1" className={printStyles['LargeFontSize']}>
              {`${record?.prescriptionDetail?.quantity || 0} ${record?.prescriptionDetail?.medication?.numeratorUnit}`}
            </Typography>
          </Box>
        );
      },
      title: 'quantity',
      align: 'center',
    },
  ];

  return (
    <TableCustom
      containerProps={{
        sx: {
          '& th,tr,td': {
            padding: '9px 8px',
            backgroundColor: 'white',
            border: '0.5px solid',
            color: 'unset',
          },
          'tbody>tr td:nth-child(2)': {
            padding: '1px 5px',
          },
          width: '100%',
          overflow: 'visible',
          marginTop: '8px',
        },
      }}
      dataSource={data}
      columns={columns}
    />
  );
};

export default PrescriptionPrintDataTable;
