import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import DrugInfoBox from './DrugInfoBox';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, departments, options } = formProps || {};
  return {
    drug: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'pharmacy.chooseDrug' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.searchDrug' }),
      options: options,
      groupBy: (option) => option.group,
      getOptionLabel: (option) => (option ? `${option.name} (${option.ingredient})` : ' '),
      isOptionEqualToValue: (option, selected) => option.id === selected.id && option.listId === selected.listId,
      renderOption: (props, option) => {
        return (
          <li {...props} key={`${option.id}-${option.listId}`}>
            <Typography variant="body2" component="div">
              {option ? `${option.name} (${option.ingredient})` : ' '}
            </Typography>
          </li>
        );
      },
      register: {
        required: true,
      },
    },
    noSelected: {
      unregister: true,
      type: () => (
        <Typography variant="subtitle1" color="textSecondary" style={{ marginBottom: 16 }}>
          <FormattedMessage id="pharmacy.noSelected" />
        </Typography>
      ),
    },
    info: {
      unregister: true,
      type: () => <DrugInfoBox info={valuesField.drug} departments={departments} />,
    },
    title: {
      unregister: true,
      type: () => (
        <Typography variant="h6" style={{ marginBottom: 16 }}>
          <FormattedMessage id="pharmacy.price" />
        </Typography>
      ),
    },
    basePrice: {
      type: 'number-field',
      label: intl.formatMessage({ id: 'pharmacy.salePrice' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.salePriceEnter' }),
      propsWrapper: { xs: 6 },
      inputType: 'number',
      register: { required: true },
    },
  };
};
const ui: IUiFields = ({ valuesField }) => {
  return [
    {
      id: 'search',
      fields: valuesField?.id ? [] : ['drug'],
    },
    {
      id: 'default',
      fields: valuesField?.drug ? ['info', 'title', 'basePrice'] : ['noSelected'],
    },
  ];
};
export const drugStoreFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
