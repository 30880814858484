import { Box, Collapse, Stack, Typography } from '@mui/material';
import { EncounterDetailProps, EncounterTab } from './type';
import { FormattedMessage } from 'react-intl';
import { useCallback, useEffect, useState } from 'react';
import IconButtonTitle from '../IconButtonTitle';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useHistory, useLocation } from 'react-router';
import { BLOCK_KEY, SESSION_KEY } from './const';

interface Props extends EncounterDetailProps {
  id: string;
  index: number;
  tabs: EncounterTab[];
}

const EncounterDetailSession = ({ id, index, tabs, encounter, readonly }: Props) => {
  const location = useLocation();
  const history = useHistory();
  const [{ key: selectedKey, render: SelectedTab }, setSelectedTab] = useState<EncounterTab>(tabs[0]);
  useEffect(() => {
    const urlSearchParams = new URLSearchParams(location.search);
    const session = urlSearchParams.get(SESSION_KEY);
    const block = urlSearchParams.get(BLOCK_KEY);
    if (id === session) {
      const tab = tabs.find((tab) => tab.key === block);
      if (tab) {
        setSelectedTab(tab);
      }
    }
  }, [id, location.search, tabs]);
  const [expand, setExpand] = useState(true);
  const onBlockSelect = useCallback(
    (tab: EncounterTab) => {
      const urlSearchParams = new URLSearchParams(location.search);
      urlSearchParams.delete(SESSION_KEY);
      urlSearchParams.delete(BLOCK_KEY);
      urlSearchParams.append(SESSION_KEY, id);
      urlSearchParams.append(BLOCK_KEY, tab.key);
      history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
    },
    [history, location.pathname, location.search, id],
  );
  return (
    <Box id={id}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" justifyContent="row" alignItems="center" gap="10px">
          <Typography
            sx={{
              color: '#307BFF',
              fontFeatureSettings: "'clig' off, 'liga' off",
              fontFamily: 'Roboto',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.15px',
              paddingLeft: '15px',
            }}
          >
            {index}.
          </Typography>
          <Stack direction="row" justifyContent="row" alignItems="center" gap="5px">
            {tabs.map((tab) => (
              <Box
                key={tab.key}
                sx={{
                  padding: '15px 30px',
                  borderRadius: '8px 8px 0px 0px',
                  background: selectedKey === tab.key ? '#fff' : '#f4f8ff',
                  cursor: 'pointer',
                  '&:hover': {
                    background: '#CDDFFF',
                  },
                }}
                onClick={() => onBlockSelect(tab)}
              >
                <Typography
                  sx={{
                    color: selectedKey === tab.key ? '#003CA6' : '#607da9',
                    fontFamily: 'Roboto',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '18px',
                    letterSpacing: '0.15px',
                  }}
                >
                  <FormattedMessage id={tab.title} />
                </Typography>
              </Box>
            ))}
          </Stack>
        </Stack>
        <IconButtonTitle
          onClick={() => setExpand((prev) => !prev)}
          sx={{ transform: expand ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all 200ms' }}
        >
          <ArrowDropDownIcon fontSize="large" sx={{ color: '#0052E0' }} />
        </IconButtonTitle>
      </Stack>
      <Collapse in={expand}>
        <Box sx={{ padding: '16px 16px 20px 16px', borderRadius: '5px', backgroundColor: '#fff' }}>
          <SelectedTab encounter={encounter} readonly={readonly} />
        </Box>
      </Collapse>
    </Box>
  );
};

export default EncounterDetailSession;
