import { Add } from '@mui/icons-material';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { Box, Button, Paper, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { API_SERVER } from '../../common/api';
import { some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { setLoading } from '../../common/redux/commonReducer';
import ReferenceIndicationFormDialog from '../component/referenceIndication/ReferenceIndicationFormDialog';
import ReferenceListTable from '../component/referenceIndication/ReferenceListTable';
import { axiosThunk } from '../../common/redux/axios';
import { useFetch } from 'modules/common/hook';
interface Props {}

const ReferenceIndicationAdminPage: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, intl, confirmDialog, history } = useGeneralHook();
  const [formData, setFormData] = React.useState<some | undefined>();
  const { id } = useParams<{ id: string; groupId: string }>();
  const { data, revalidate, isValidating } = useFetch(API_SERVER.laboratoryReference.getList(id));

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));

          await dispatch(axiosThunk({ url: API_SERVER.laboratoryReference.delete(value.id), method: 'delete' }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar],
  );

  const onSubmitForm = React.useCallback(
    async (value: some) => {
      if (!value.id) {
        // create new
        dispatch(setLoading(true));
        try {
          await dispatch(
            axiosThunk({
              url: API_SERVER.laboratoryReference.create(id),
              method: 'post',
              data: value,
            }),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'exam.addNewSucceeds' }) });
        } catch (e) {
          openSnackbar({
            message: intl.formatMessage({ id: 'createFail' }),
            type: 'error',
          });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      } else {
        // edit
        dispatch(setLoading(true));
        try {
          await dispatch(
            axiosThunk({
              url: API_SERVER.laboratoryReference.update(value.id),
              method: 'put',
              data: value,
            }),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({
            message: intl.formatMessage({ id: 'updateFail' }),
            type: 'error',
          });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
    },
    [dispatch, revalidate, openSnackbar, intl, id],
  );

  return (
    <>
      <Paper style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <Box marginBottom={2} display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" style={{ minWidth: 120 }}>
            {data?.name}
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="indication.group" /> &emsp;
            <Typography variant="subtitle2" component="span">
              {data?.systemCategory?.name}
            </Typography>
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id="indication.device" /> &emsp;
            <Typography variant="subtitle2" component="span">
              {data?.medicalEquipment?.name}
            </Typography>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Add />}
            onClick={() => {
              setFormData({});
            }}
          >
            <FormattedMessage id={'add'} />
          </Button>
        </Box>
        <ReferenceListTable
          loading={isValidating && !data}
          data={data?.laboratoryReferences}
          onDelete={onDelete}
          setFormData={setFormData}
        />
        <Button
          variant="outlined"
          color="primary"
          startIcon={<KeyboardBackspaceIcon />}
          style={{ width: 160, marginTop: 16 }}
          onClick={() => {
            history.goBack();
          }}
        >
          <FormattedMessage id="back" />
        </Button>
        <ReferenceIndicationFormDialog
          open={!!formData}
          onClose={() => setFormData(undefined)}
          formData={formData}
          onSubmit={onSubmitForm}
        />
      </Paper>
    </>
  );
};

export default ReferenceIndicationAdminPage;
