import { API_SERVER } from 'modules/common/api';
import IconButton from 'modules/common/component/IconButton';
import { useDialog, useFetch } from 'modules/common/hook';
import { MedicalIcon } from 'svg';
import { EncounterDetailProps } from '../../type';
import { DIAGNOSTIC_IMAGE_TYPE } from 'modules/common/constants';
import DiagnosticImageDialog from 'modules/common/component/DiagnosticImageDialog';
import { STORAGE_RESOURCE_TYPE } from 'modules/common/apiConstants';

const DiagnosticImagesButton = ({ encounter, readonly }: EncounterDetailProps) => {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const { data: diagnosisImages } = useFetch(
    API_SERVER.diagnosticImage.getDiagnosticImages({
      referenceId: encounter?.id!,
      imageType: DIAGNOSTIC_IMAGE_TYPE.ENCOUNTER.value,
    }),
  );
  return (
    <>
      <IconButton
        icon={<MedicalIcon />}
        sx={{ padding: '2px' }}
        showSlotNumber={diagnosisImages && diagnosisImages.length > 0}
        slotNumber={diagnosisImages?.length || 0}
        onClick={onOpenDialog}
        tooltipTitle="common.title.connectDiagnosticImage"
      />
      <DiagnosticImageDialog
        open={openDialog}
        onClose={onCloseDialog}
        referenceId={encounter.id!}
        type={DIAGNOSTIC_IMAGE_TYPE.ENCOUNTER.value}
        patient={encounter.patient}
        storeResourceType={STORAGE_RESOURCE_TYPE.ENCOUNTER_DIAGNOSTIC_IMAGE_ALBUM}
        extracInfo={{ diagnosis: encounter.differentialDiagnosis, picName: encounter.picName }}
        readonly={readonly}
        encounter={encounter}
      />
    </>
  );
};

export default DiagnosticImagesButton;
