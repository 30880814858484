import { Box } from '@mui/material';
import SchemaFormMain from 'modules/common/SchemaForm';
import DialogCustom from 'modules/common/component/DialogCustom';

interface Props {
  open: boolean;
  onClose: () => void;
  prepaymentAmount?: number;
  onSubmit: (formData: { prepaymentAmount: number }) => void;
}

const EditPaymentAdvanceDialog = ({ open, onClose, prepaymentAmount, onSubmit }: Props) => {
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="prepaidAdd"
      maxWidth="sm"
      PaperProps={{
        style: { minWidth: 300 },
      }}
    >
      <Box padding={2}>
        <SchemaFormMain
          onCancel={onClose}
          onSubmit={onSubmit}
          formData={{ prepaymentAmount: prepaymentAmount }}
          schema={{
            fields: ({ formProps: { intl } }) => {
              return {
                prepaymentAmount: {
                  type: 'number-field',
                  inputType: 'number',
                  label: intl.formatMessage({ id: 'therapy.prepaid.label.prepaidAmount' }),
                  placeholder: intl.formatMessage({ id: 'therapy.prepaid.label.amountEnter' }),
                  register: {
                    maxLength: {
                      value: 16,
                      message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                    },
                    min: {
                      value: 1,
                      message: intl.formatMessage({ id: 'validation.invalid' }),
                    },
                    required: true,
                  },
                  propsWrapper: { xs: 12 },
                },
              };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default EditPaymentAdvanceDialog;
