import { z } from 'zod';
import { TherapySessionSchema } from './TherapySession';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { PatientSchema } from './Patient';
import { PaymentSchema } from './Payment';

export const TherapySchema = z
  .object({
    id: z.number(),
    code: z.string(),
    patientId: z.number(),
    patientType: z.string(),
    picUserId: z.number(),
    pucName: z.string(),
    therapyDefinitionId: z.number(),
    name: z.string(),
    groupId: z.number(),
    packagePrice: z.number(),
    diagnosis: z.string(),
    totalPrice: z.number(),
    paymentPlan: z.string(),
    registerReason: z.string(),
    status: z.string(),
    referenceId: z.number(),
    referenceType: z.string(),
    paymentDetailId: z.number(),
    therapySessionList: z.array(TherapySessionSchema),
    patient: PatientSchema,
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    numberSession: z.number(),
    note: z.string(),
    description: z.string(),
    payment: PaymentSchema,
    referenceCode: z.string(),
  })
  .partial();

export type Therapy = z.infer<typeof TherapySchema>;
