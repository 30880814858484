import { Paper } from '@mui/material';
import * as React from 'react';
import { some } from '../../../common/constants';
import SchemaForm from '../../../common/SchemaForm';

interface Props {
  formData: some;
  onSubmit: (value: some) => void;
  listTemplate?: some[];
}

const FormReport: React.FunctionComponent<Props> = ({ formData, onSubmit, listTemplate }) => {
  return (
    <Paper style={{ padding: 16, flex: 2, height: '100%', overflow: 'auto' }}>
      <SchemaForm
        formData={formData}
        onSubmit={onSubmit}
        schema={{
          fields: ({ formProps }) => {
            const { intl } = formProps;
            return {
              name: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'report.name' }),
                placeholder: intl.formatMessage({ id: 'report.nameEnter' }),
                register: {
                  required: true,
                  validate: (value) => {
                    if (
                      listTemplate
                        ?.filter((v) => v.id !== formData?.id)
                        ?.findIndex((v) => v.name?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) !== -1
                    ) {
                      return intl.formatMessage({ id: 'uniqName' });
                    }
                    return true;
                  },
                },
              },
              examDepartment: {
                type: 'text-editor',
                label: intl.formatMessage({ id: 'report.parts' }),
                height: '35vh',
                fullOption: true,
                register: {
                  required: true,
                },
              },
              conclusion: {
                type: 'text-editor',
                label: intl.formatMessage({ id: 'report.conclusion' }),
                fullOption: true,
                height: '25vh',
                register: {
                  required: true,
                },
              },
            };
          },
          ui: ({ formProps, valuesField }) => {
            const { intl } = formProps || {};
            return [
              {
                id: 'default',
                title: intl.formatMessage({ id: valuesField?.id ? 'report.edit' : 'report.addNew' }),
                fields: ['name', 'examDepartment', 'conclusion'],
              },
            ];
          },
        }}
      />
    </Paper>
  );
};

export default FormReport;
