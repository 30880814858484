import { useMemo, useRef, useState } from 'react';
import { Box, Divider } from '@mui/material';
import Header from './Header';
import Body from './Body';
import DialogAction from './DialogAction';
import {
  INDICATION_REQUEST_LIST_PRINT_TYPE,
  LOCAL_STORAGE_INDICATION_LIST_COMPACT_MODE,
  LOCAL_STORAGE_INDICATION_LIST_PRINT_TYPE,
} from './constant';
import { IndicationRequest } from 'modules/schema';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import useSWR from 'swr';
import { axiosThunk } from 'modules/common/redux/axios';
import { API_SERVER } from 'modules/common/api';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import styled from '@emotion/styled';
import { INDICATION_REQUEST_STATUS } from 'modules/common/constants';
import { useFetch } from 'modules/common/hook';

interface Props {
  onClose: () => void;
  selectedIndicationRequests: IndicationRequest[];
  dentalExamId?: number;
}

const DividerStyled = styled(Divider)({
  marginTop: '30px',
  marginBottom: '15px',
  '@media print': {
    display: 'none',
  },
});

const PrintDialogContent = ({ onClose, selectedIndicationRequests, dentalExamId }: Props) => {
  const printContentRef = useRef(null);

  const [printType, setPrintType] = useState<INDICATION_REQUEST_LIST_PRINT_TYPE>(
    (sessionStorage.getItem(LOCAL_STORAGE_INDICATION_LIST_PRINT_TYPE) as INDICATION_REQUEST_LIST_PRINT_TYPE) || 'GROUP',
  );
  const [compactMode, setCompactMode] = useState(
    Boolean(sessionStorage.getItem(LOCAL_STORAGE_INDICATION_LIST_COMPACT_MODE)) || false,
  );
  const { dispatch } = useGeneralHook();

  const { data: indicationRequests, isValidating: loadingIndicationRequest } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(dentalExamId!, 'DENTAL_EXAM'),
    { enabled: (!selectedIndicationRequests || selectedIndicationRequests.length === 0) && dentalExamId },
  );

  const indicationRequestPrint = useMemo(() => {
    if (selectedIndicationRequests && selectedIndicationRequests.length > 0) {
      return selectedIndicationRequests;
    }
    return (
      indicationRequests?.filter((indication) => indication.status === INDICATION_REQUEST_STATUS.FINISHED.value) || []
    );
  }, [indicationRequests, selectedIndicationRequests]);

  const { data: dentalExam, isValidating: loadingTherapy } = useFetch(
    API_SERVER.dental.getDentalExam(indicationRequestPrint[0].referenceId + ''),
    { enabled: indicationRequestPrint && indicationRequestPrint.length > 0 },
  );

  const { data: indicationLabs, isValidating: loadingIndicationLabs } = useSWR(
    indicationRequestPrint.map((ele) => ele.id),
    async (...indicationIds) => {
      const data = await Promise.all(
        indicationIds.map((id) => dispatch(axiosThunk({ url: API_SERVER.indicationLaboratory.getList(id).url }))),
      );
      return data?.map((ele) => ele?.data);
    },
  );

  if (loadingTherapy || !dentalExam || loadingIndicationLabs || !indicationLabs || loadingIndicationRequest)
    return <LoadingIcon />;

  return (
    <Box paddingTop="̣9px">
      <Box ref={printContentRef}>
        {printType === 'GROUP' ? (
          <Box sx={{ pageBreakBefore: 'always' }}>
            <Header />
            <Body dentalExam={dentalExam} indicationLabs={indicationLabs} />
          </Box>
        ) : (
          indicationRequestPrint.map((indication, index) => (
            <Box sx={{ pageBreakBefore: 'always' }}>
              <Header />
              <Body dentalExam={dentalExam} indicationLabs={[indicationLabs[index]]} />
              <DividerStyled />
            </Box>
          ))
        )}
      </Box>
      <DialogAction
        onClose={onClose}
        printContentRef={printContentRef}
        printType={printType}
        onPrintTypeChange={setPrintType}
        compactMode={compactMode}
        onCompactModeChange={setCompactMode}
      />
    </Box>
  );
};

export default PrintDialogContent;
