import { Delete } from '@mui/icons-material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { alpha, Box, Button, ButtonBase, Grid, IconButton, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import axios from 'axios/index';
import Stack from '@mui/material/Stack';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { PropsArrayElement } from 'modules/common/SchemaForm/element/array-element/ArrayElement';
import { API_SERVER } from 'modules/common/api';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import { setLoading } from 'modules/common/redux/commonReducer';
import { SS_TOKEN } from 'modules/common/constants';
import MediCard from 'modules/common/component/MediCard';
import { MedicalIcon } from 'svg';
import ImagePreview from 'modules/common/component/ImagePreview';
import NoDataBox from 'modules/common/component/NoDataBox';
import CameraImageGalleryDialog from '../therapy/detailTabs/image/CameraImageGalleryDialog';
import { useFetch, useDeleteMutate, useUpdateMutate } from 'modules/common/hook';
import ImageGalleryDialog from './ImageGalleryDialog';

const useStyles = makeStyles(() => ({
  image: {
    '&:hover': {
      '& .action-area': {
        opacity: 1,
      },
    },
    '& .action-area': {
      opacity: 0,
      transition: '0.3s all',
    },
  },
}));
interface Props extends PropsArrayElement {}

const ImageBox = (props: Props) => {
  const classes = useStyles();
  const { formProps } = props;
  const { indicationRequestId } = useParams<{ indicationRequestId: string }>();
  const { dispatch, openSnackbar, intl, editable, encounter } = formProps;
  const [open, setOpen] = useState<undefined | number>(undefined);
  const [openCamera, setOpenCamera] = useState<boolean>(false);

  const { data: images, revalidate: imagesRevalidate } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.encounterIndicationDiagnosticImage,
      referenceId: parseInt(indicationRequestId),
    }),
  );

  const onSaveImage = async (images) => {
    try {
      dispatch(setLoading(true));
      const files = await Promise.all(
        images
          ?.filter((ele) => !!ele?.imageSrc)
          ?.map((ele, i) => {
            return fetch(ele?.imageSrc)
              .then((res) => res.blob())
              .then(
                (res) =>
                  new File([res], 'screen ' + i + '.jpg', {
                    lastModified: new Date().getTime(),
                    type: 'image/jpeg',
                  }),
              );
          }) || [],
      );
      if (files && files.length > 0) {
        let multipartFormData = new FormData();
        files.forEach((ele) => multipartFormData.append('files', ele));
        const types = files?.map((ele) => STORAGE_RESOURCE_TYPE.encounterIndicationDiagnosticImage) || [];
        const referenceIds = files?.map((ele) => indicationRequestId) || [];
        const descriptions = files?.map((ele, index) => images?.[index]?.comment || ' ') || [];
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        await axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );
      }
      await imagesRevalidate();
      setOpenCamera(false);
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editFails' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onDeleteImageMutate = useDeleteMutate({
    onSuccess: imagesRevalidate,
    confirmMessage: intl.formatMessage({ id: 'confirmDeleteFile' }),
  });
  const onDeleteImage = useCallback(
    async (id: number) => {
      await onDeleteImageMutate({
        url: API_SERVER.storageResource.delete(id),
        method: 'delete',
      });
    },
    [onDeleteImageMutate],
  );

  const onAttachImage = useCallback(
    async (files: FileList) => {
      dispatch(setLoading(true));
      const allowedFileTypes = ['image/jpeg', 'image/png'];
      let hasValidFile = false;

      try {
        let multipartFormData = new FormData();
        const types: string[] = [];
        const referenceIds: string[] = [];
        const descriptions: string[] = [];

        for (let i = 0; i < files.length; i++) {
          if (allowedFileTypes.includes(files[i].type)) {
            hasValidFile = true;
            multipartFormData.append('files', files[i]);
            types.push(STORAGE_RESOURCE_TYPE.encounterIndicationDiagnosticImage);
            referenceIds.push(indicationRequestId);
            descriptions.push(' ');
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'invalid.file.type.no.support' }), type: 'error' });
          }
        }

        if (!hasValidFile) {
          return;
        }

        const token = sessionStorage.getItem(SS_TOKEN) || '';
        await axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );

        await imagesRevalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, imagesRevalidate, indicationRequestId, intl, openSnackbar],
  );

  const updateDescriptionImage = useUpdateMutate({ onSuccess: imagesRevalidate });

  return (
    <>
      <MediCard title={'subclinical.image'}>
        <Box padding={2}>
          {editable && (
            <Stack direction="row" marginBottom={2} gap={2}>
              <Button
                color="inherit"
                startIcon={<MedicalIcon />}
                sx={{ width: 'fit-content' }}
                onClick={() => setOpenCamera(true)}
              >
                <FormattedMessage id="camera.connection" />
              </Button>
              <IconButton
                component="label"
                sx={{
                  borderRadius: '4px',
                  backgroundColor: '#CDDFFF',
                  color: '#0052E0',
                  fontSize: '14px',
                  fontWeight: 500,
                  fontFamily: 'Roboto',
                  letterSpacing: '0.02857em',
                  width: '181px',
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                  style={{ objectFit: 'cover', position: 'relative' }}
                  gap={1}
                >
                  <input
                    accept="image/*"
                    hidden
                    type="file"
                    multiple
                    onChange={(event) => {
                      if (event.target.files && event.target.files?.length > 0) {
                        onAttachImage(event.target.files);
                      }
                    }}
                  />
                  <AddPhotoAlternateIcon color="primary" />
                  <FormattedMessage id="therapy.detail.label.attackImage" />
                </Stack>
              </IconButton>
            </Stack>
          )}
          <Grid container spacing={2}>
            {images && images?.length > 0
              ? images?.map((item, index: number) => {
                  return (
                    <Grid item key={item.id} xs={2}>
                      <Box position="relative" className={classes.image}>
                        <ButtonBase
                          style={{ background: grey[300], height: 180, width: '100%' }}
                          onClick={() => setOpen(index)}
                        >
                          <ImagePreview
                            alt=""
                            imageId={item.id}
                            style={{
                              height: '100%',
                              width: '100%',
                              objectFit: 'cover',
                            }}
                          />
                        </ButtonBase>
                        <Box
                          className="action-area"
                          position="absolute"
                          bottom={0}
                          right={0}
                          left={0}
                          top={0}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <Box display="flex">
                            {editable && (
                              <IconButton
                                size="small"
                                style={{ background: alpha(grey[100], 0.8), marginRight: 16 }}
                                onClick={(e) => {
                                  if (item?.id) onDeleteImage(item?.id);
                                }}
                              >
                                <Delete color="inherit" fontSize="small" />
                              </IconButton>
                            )}
                            <IconButton
                              size="small"
                              style={{ background: alpha(grey[100], 0.8) }}
                              onClick={() => setOpen(index)}
                            >
                              <ZoomInIcon color="inherit" />
                            </IconButton>
                          </Box>
                        </Box>
                      </Box>
                      <TextField
                        id="standard-basic"
                        variant="standard"
                        fullWidth
                        InputProps={{
                          readOnly: !editable,
                        }}
                        style={{ textAlign: 'center', textAlignLast: 'center' }}
                        defaultValue={item?.description}
                        onBlur={(value) => {
                          editable &&
                            item?.description !== value.target.value &&
                            updateDescriptionImage({
                              url: API_SERVER.storageResource.updateDescription(),
                              method: 'put',
                              data: { data: [{ id: item.id, description: value.target.value }] },
                            });
                        }}
                      />
                    </Grid>
                  );
                })
              : images &&
                images?.length === 0 && (
                  <Box display={'flex'} justifyContent="center" width="100%">
                    <NoDataBox />
                  </Box>
                )}
          </Grid>
        </Box>
      </MediCard>
      <ImageGalleryDialog open={open !== undefined} onClose={() => setOpen(undefined)} images={images} index={open} />
      <CameraImageGalleryDialog
        patientData={encounter?.patient}
        open={openCamera}
        onClose={() => setOpenCamera(false)}
        onSave={onSaveImage}
      />
    </>
  );
};

export default memo(ImageBox);
