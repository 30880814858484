import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import PrintIcon from '@mui/icons-material/Print';
import { Collapse } from '@mui/material';
import { get } from 'lodash';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { TableCustomCell, TableCustomRow } from 'modules/common/component/TableCustom/element';
import { some } from 'modules/common/constants';
import * as React from 'react';
import IndicationTable from './IndicationTable';
import { DentalExam, IndicationRequest } from 'modules/schema';
import IndicationPrintForm from './IndicationPrintForm';

interface Props {
  dataRow: DentalExam;
  columns: some[];
}
const Row: React.FunctionComponent<Props> = (props) => {
  const { dataRow, columns } = props;
  const [openPrintForm, setOpenPrintForm] = React.useState(false);
  const [selectedIndications, setSelectedIndications] = React.useState<IndicationRequest[]>([]);
  const [openPrintFormData, setOpenPrintFormData] = React.useState(false);

  return (
    <>
      <TableCustomRow>
        <TableCustomCell style={{ width: 50 }}>
          <IconButtonTitle
            aria-label="expand data"
            size="small"
            onClick={() => {
              setOpenPrintForm(!openPrintForm);
            }}
          >
            <KeyboardArrowDownIcon
              style={{
                transform: openPrintForm ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButtonTitle>
        </TableCustomCell>{' '}
        {columns.map((column, index) => {
          return (
            <TableCustomCell key={index} align={column.align}>
              {column.render ? column.render(dataRow) : get(dataRow, column.dataIndex)}
            </TableCustomCell>
          );
        })}
        <TableCustomCell>{dataRow.indicationRequestQuantity || 0}</TableCustomCell>
        <TableCustomCell style={{ width: 100 }} align="center">
          <IconButtonTitle
            disabled={!dataRow.indicationRequestFinishedQuantity}
            aria-label="expand data"
            title="print"
            size="small"
            onClick={() => setOpenPrintFormData(true)}
          >
            <PrintIcon />
          </IconButtonTitle>
        </TableCustomCell>
      </TableCustomRow>
      <TableCustomRow style={{ padding: 0 }}>
        <TableCustomCell style={{ padding: 0, border: 'none' }} colSpan={13}>
          <Collapse in={openPrintForm} timeout="auto" unmountOnExit>
            <IndicationTable
              dentalExam={dataRow}
              setSelectedIndications={setSelectedIndications}
              selectedIndications={selectedIndications}
            />
          </Collapse>
        </TableCustomCell>
      </TableCustomRow>
      <IndicationPrintForm
        open={openPrintFormData}
        onClose={() => setOpenPrintFormData(false)}
        dentalExamId={dataRow.id!}
        selectedIndicationRequests={selectedIndications}
      />
    </>
  );
};
export default Row;
