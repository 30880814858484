import { AssignmentTurnedIn, Edit } from '@mui/icons-material';
import { Box, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { push } from 'connected-react-router';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { FE_DATE_FORMAT, INDICATION_REQUEST_STATUS, some } from 'modules/common/constants';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import { ROUTES } from 'modules/layout/router';
import moment from 'moment';
import { IndicationRequest } from 'modules/schema';

const useStyles = makeStyles({
  table: {
    '& td,th': {
      padding: 8,
    },
  },
  select: {
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'normal',
    },
  },
});

interface Props {
  data?: some[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  setDataDetail: (value: some) => void;
  selectionIndication: some[];
  setSelectionIndication: (value: some[]) => void;
  onComplete: (value: some) => void;
  onOpenEditIndicationDialog: () => void;
}

const IndicationList: React.FunctionComponent<Props> = ({
  data,
  loading,
  hookPagination,
  total,
  selectionIndication,
  setSelectionIndication,
  setDataDetail,
  onComplete,
  onOpenEditIndicationDialog,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const tableDataMapped = React.useMemo(() => {
    return data?.map((ele) => ({
      ...ele,
      createdTime: ele?.startTime?.format(FE_DATE_FORMAT),
      dob: moment(ele?.dob)?.format(FE_DATE_FORMAT),
    })) as IndicationRequest[];
  }, [data]);

  const columns = React.useMemo(() => {
    return [
      // {
      //   title: 'labDoctor.stn',
      //   dataIndex: 'stn',
      // },
      {
        title: 'encounterId',
        dataIndex: 'referenceCode',
        align: 'right',
      },
      {
        title: 'labDoctor.patientCode',
        dataIndex: 'formatPatientCode',
      },
      {
        title: 'birthdayShort',
        dataIndex: 'dob',
      },
      {
        title: 'labDoctor.patientName',
        dataIndex: 'patientName',
      },

      {
        title: 'labDoctor.testName',
        dataIndex: 'serviceName',
      },
      {
        title: 'indicationDate',
        dataIndex: 'createdTime',
      },
      {
        title: 'doctorOfIndication',
        dataIndex: 'picName',
      },
      {
        title: 'interpreterOfIndication',
        dataIndex: 'executeName',
      },
      {
        title: 'status',
        dataIndex: 'status',
        render: (record) => (
          <Box component="span" color={INDICATION_REQUEST_STATUS?.[record.status]?.color}>
            <FormattedMessage id={INDICATION_REQUEST_STATUS?.[record.status]?.label || ' '} />
          </Box>
        ),
      },
      {
        title: 'action',
        width: 120,
        render: (record) => (
          <>
            <IconButtonTitle
              title="update"
              onClick={(e) => {
                e.stopPropagation();
                setDataDetail(record);
                onOpenEditIndicationDialog();
              }}
            >
              <Edit />
            </IconButtonTitle>
            <IconButtonTitle
              title="complete"
              onClick={(e) => {
                e.stopPropagation();
                onComplete(record);
              }}
              disabled={record.status === INDICATION_REQUEST_STATUS.FINISHED.value}
            >
              <AssignmentTurnedIn />
            </IconButtonTitle>
          </>
        ),
      },
    ] as Columns[];
  }, [onComplete, onOpenEditIndicationDialog, setDataDetail]);

  return (
    <>
      <TableCustom
        containerProps={{
          className: classes.table,
        }}
        dataSource={tableDataMapped}
        loading={loading}
        columns={columns}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: (...val) => {
            onPageChange(...val);
            setSelectionIndication([]);
          },
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        rowProps={(record) => ({
          onClick: async (e) => {
            dispatch(push(ROUTES.detailIndication.gen(record.id!, record.patientId!, record.referenceId!)));
          },
          style: { cursor: 'pointer' },
        })}
        rowSelection={{
          selectedRowKeys: selectionIndication,
          hidden: (record: some) =>
            record.status !== INDICATION_REQUEST_STATUS.FINISHED.value ||
            (selectionIndication?.[0] ? selectionIndication?.[0]?.referenceId !== record.referenceId : false),
          onChange: ({ selectedRows }) => setSelectionIndication(selectedRows),
          disableSelectAll: selectionIndication.length === 0,
          render: ({ record, selected, onChange }) =>
            record.status === INDICATION_REQUEST_STATUS.FINISHED.value && (
              <Checkbox
                disabled={
                  selectionIndication?.[0] ? selectionIndication?.[0]?.referenceId !== record.referenceId : false
                }
                checked={selected}
                color="primary"
                onChange={(e) => onChange(e.target.checked)}
              />
            ),
        }}
      />
    </>
  );
};

export default IndicationList;
