import { Autocomplete, Box, Card, CardHeader, List, ListItem, Popper, Typography } from '@mui/material';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { PRIMARY } from '../../../../../colors';
import { API_SERVER } from 'modules/common/api';
import FreeTextField from 'modules/common/component/FreeTextField';
import { ListboxComponent } from 'modules/common/component/ListboxComponent';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import { fetchThunk } from 'modules/common/redux/thunk';
import { ExamDoctorEncounterInfoFormNew, primaryICD10 } from 'modules/examDoctor/model';
import { Encounter } from 'modules/schema';
import { ICD_TYPE } from 'modules/common/apiConstants';
import { mutate } from 'swr';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';

interface Props {
  title?: React.ReactNode;
  style?: React.CSSProperties;
  readOnly?: boolean;
  encounter: Encounter;
}

const SelectPrimaryICDBox: React.FunctionComponent<Props> = (props) => {
  const { title, style, readOnly, encounter } = props;
  const { dispatch, intl, openSnackbar } = useGeneralHook();
  const { control, setValue } = useFormContext<ExamDoctorEncounterInfoFormNew>();
  const anchorEl = React.useRef<HTMLDivElement>(null);
  const icd10s = useSelector((state: AppState) => state.common.icd10s);

  const icd10 = encounter?.icd10;
  const defaultValue = {
    value: icd10?.code! || '',
    label: icd10?.display! || '',
  };

  // create icd 10
  const saveToBackend = React.useCallback(
    async (val) => {
      try {
        dispatch(setLoading(true));
        await dispatch(
          fetchThunk(
            API_SERVER.icdDiagnosis.updateByEncounterId(),
            'put',
            {
              code: val?.value,
              display: val?.label,
              encounterId: parseInt(encounter?.id?.toString() || ''),
              type: ICD_TYPE.PRIMARY_ICD10,
            },
            'application/json-patch+json',
          ),
        );
        mutate(API_SERVER.encounter.get(encounter?.id!).url);
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, encounter?.id, intl, openSnackbar],
  );

  return (
    <>
      <Card variant="outlined" style={style}>
        <CardHeader title={title} style={{ padding: '8px 16px', background: PRIMARY.main }} />
        <Box padding={1}>
          <Controller
            control={control}
            name="primaryICD10"
            defaultValue={defaultValue}
            render={({ field: { name, value, onChange } }) => {
              return (
                <>
                  {!readOnly && (
                    <div style={{ display: 'flex' }} ref={anchorEl}>
                      <Autocomplete
                        openOnFocus
                        filterSelectedOptions
                        filterOptions={(opts, state) => {
                          return opts.filter(
                            (opt) => opt?.label?.toLowerCase().indexOf(state?.inputValue?.toLowerCase().trim()) > -1,
                          );
                        }}
                        options={icd10s || []}
                        fullWidth
                        value={defaultValue as primaryICD10}
                        style={{ marginRight: 8, flex: 2 }}
                        disableListWrap
                        ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                        isOptionEqualToValue={(selected, value) => selected.value === value?.value}
                        onMouseDownCapture={(e) => e.stopPropagation()}
                        noOptionsText={
                          <Typography>
                            <FormattedMessage id="noOption" />
                          </Typography>
                        }
                        renderOption={(_, option) => (
                          <li {..._}>
                            <Typography variant="subtitle2">
                              {option.label}&nbsp;({option.value})
                            </Typography>
                          </li>
                        )}
                        onChange={async (e, val, reason) => {
                          const newVal = { ...value, ...val };
                          await saveToBackend(newVal);
                          setValue(name, newVal, { shouldDirty: false });
                        }}
                        getOptionLabel={(option) => option?.label}
                        disableClearable
                        renderInput={(params) => (
                          <FreeTextField
                            {...params}
                            InputProps={{ ...params.InputProps, style: { paddingRight: 35 } }}
                            placeholder={intl.formatMessage({ id: 'encounterInfo.codeICD10Display' })}
                            label={intl.formatMessage({ id: 'encounterInfo.name' })}
                          />
                        )}
                        PopperComponent={(props) => (
                          <Popper
                            {...props}
                            anchorEl={anchorEl.current}
                            style={{ width: anchorEl.current?.getBoundingClientRect().width }}
                            placement="bottom-start"
                          />
                        )}
                      />
                      <Autocomplete
                        openOnFocus
                        filterSelectedOptions
                        options={icd10s || []}
                        fullWidth
                        value={defaultValue as primaryICD10}
                        style={{ flex: 1 }}
                        isOptionEqualToValue={(selected, value) => selected.value === value?.value}
                        onMouseDownCapture={(e) => e.stopPropagation()}
                        noOptionsText={
                          <Typography>
                            <FormattedMessage id="noOption" />
                          </Typography>
                        }
                        ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                        renderOption={(_, option) => (
                          <li {..._}>
                            <Typography variant="subtitle2">
                              {option.label}&nbsp;({option.value})
                            </Typography>
                          </li>
                        )}
                        onChange={async (e, val, reason) => {
                          const newVal = { ...value, ...val };

                          await saveToBackend(newVal);

                          setValue(name, newVal, { shouldDirty: false });
                        }}
                        disableClearable
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => (
                          <FreeTextField
                            {...params}
                            placeholder={intl.formatMessage({ id: 'encounterInfo.codeICD10' })}
                            label={intl.formatMessage({ id: 'encounterInfo.code' })}
                          />
                        )}
                        PopperComponent={(props) => (
                          <Popper
                            {...props}
                            anchorEl={anchorEl.current}
                            style={{ width: anchorEl.current?.getBoundingClientRect().width }}
                            placement="bottom-start"
                          />
                        )}
                      />
                    </div>
                  )}
                  <List>
                    {icd10 && (
                      <ListItem key={icd10.code} sx={{ bgcolor: 'primary.50' }}>
                        <Box flex={1}>
                          <Typography variant="body2">
                            {icd10.display} ({icd10.code})
                          </Typography>
                        </Box>
                      </ListItem>
                    )}
                  </List>
                </>
              );
            }}
          />
        </Box>
      </Card>
    </>
  );
};

export default SelectPrimaryICDBox;
