import { z } from 'zod';

export const MedicationSupplierSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    representationPerson: z.string(),
    address: z.string(),
    phoneNumber: z.string(),
    email: z.string(),
    taxNumber: z.string(),
    status: z.string(),
  })
  .partial();

export type MedicationSupplier = z.infer<typeof MedicationSupplierSchema>;
