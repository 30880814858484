import { Box, Button, Checkbox, Stack } from '@mui/material';
import DialogCustom from '../DialogCustom';
import { FormattedMessage } from 'react-intl';
import useFilterContext from './useFilterContext';
import FilterItem from './FilterPopupItem';
import { Controller } from 'react-hook-form';
import { FilterTitle } from './style';
import { CSSProperties } from 'react';

interface Props {
  open: boolean;
  onClose: () => void;
  popupWidth?: CSSProperties['width'];
}

const FilterPopup = ({ open, onClose, popupWidth }: Props) => {
  const { onFilterChange, filters, form } = useFilterContext();

  return (
    <DialogCustom
      disableCloseOutSide
      open={open}
      onClose={() => {
        onClose();
        form.reset();
      }}
      title="filter.popup.title.diaglog"
      PaperProps={{ sx: { width: popupWidth || '750px', maxWidth: 'unset' } }}
    >
      <Stack direction="column" padding="20px" gap={2}>
        <Stack direction="column" gap={2}>
          {filters?.map((filter, index) => (
            <FilterItem filter={filter} key={index} />
          ))}
        </Stack>
        <Stack direction="row" alignContent="center" justifyContent="space-between">
          <Box component="label" display="flex" flexDirection="row" gap="8px" alignItems="flex-start">
            <Controller
              name={'isSaveFilter'}
              control={form.control}
              render={({ field: { value, onChange } }) => (
                <Checkbox sx={{ padding: 0 }} checked={value} onChange={(event) => onChange(event.target.checked)} />
              )}
            />
            <FilterTitle>
              <FormattedMessage id="filter.popup.title.isSaveFilter" />
            </FilterTitle>
          </Box>
          <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
            <Button
              variant="outlined"
              onClick={() => {
                onClose();
                form.reset();
              }}
            >
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              onClick={() => {
                onFilterChange(form.getValues());
                onClose();
              }}
            >
              <FormattedMessage id="filter.popup.title.button.filter" />
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </DialogCustom>
  );
};

export default FilterPopup;
