import { Box } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import DialogCustom from '../../../../common/component/DialogCustom';
import { some } from '../../../../common/constants';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFormAdminAccount } from './schema';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <>
      <SchemaForm schema={schemaFormAdminAccount} onSubmit={onSubmit} initialData={formData} onCancel={onClose} />
    </>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose } = props;
  return (
    <DialogCustom
      open={!!open}
      keepMounted={false}
      maxWidth="xs"
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 340 },
      }}
      title={'changePassword'}
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default FormDialog;
