import { Box, Checkbox, Typography } from '@mui/material';
import { TableCustomCell, TableCustomRow } from 'modules/common/component/TableCustom/element';
import { PaymentDetail } from 'modules/schema';
import { useFormContext, useWatch } from 'react-hook-form';
import { IFormData, caculatePrice } from './utils';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import { NumericFormatText } from 'modules/common/utils';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { DISCOUNT_TYPE, PAYMENT_STATUS } from 'modules/common/apiConstants';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { FormattedMessage } from 'react-intl';
import { Info } from '@mui/icons-material';
import React, { useCallback } from 'react';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDialog } from 'modules/common/hook';
import ConfirmRefundDialog from './ConfirmRefundDialog';

interface Props {
  paymentDetail: PaymentDetail;
  index: number;
}

const PaymentDetailRefundButton = ({
  paymentDetail,
  patientPay,
  isRefunded,
}: {
  paymentDetail: PaymentDetail;
  patientPay: number;
  isRefunded: boolean;
}) => {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  return (
    <>
      <IconButtonTitle size="small" title="refund" onClick={onOpenDialog}>
        {isRefunded ? <Info /> : <ReplayIcon />}
      </IconButtonTitle>
      <ConfirmRefundDialog
        onClose={onCloseDialog}
        open={openDialog}
        patientPay={patientPay}
        paymentDetail={paymentDetail}
      />
    </>
  );
};

const TablePriceRow = ({ paymentDetail, index }: Props) => {
  const { intl, appState } = useGeneralHook();
  const { setValue } = useFormContext<IFormData>();
  const currency = appState.common.groupCurrency;
  const discountPercent = useWatch<IFormData, 'discountPercent'>({ name: 'discountPercent' });
  const field = useWatch<IFormData, `paymentList.${number}`>({ name: `paymentList.${index}` });
  const isCashDiscountType = field?.discountType === DISCOUNT_TYPE.CASH.value;
  const quantity = paymentDetail?.quantity || 0;
  const readonly = paymentDetail?.status !== PAYMENT_STATUS.UNPAID.value;
  const { receiptDiscountAmount, finalDiscountAmount, patientPay, totalAmount, vatAmount } = caculatePrice(
    quantity,
    discountPercent,
    field,
  );
  const formatDiscountType = React.useMemo(
    () => ({ ...DISCOUNT_TYPE, CASH: { ...DISCOUNT_TYPE.CASH, label: currency } }),
    [currency],
  );
  const onClickRow = useCallback(() => {
    if (field?.status !== PAYMENT_STATUS.PAID.value && field?.status !== PAYMENT_STATUS.REFUNDED.value) {
      setValue(`paymentList.${index}.checked`, !field?.checked);
    }
  }, [field?.checked, field?.status, index, setValue]);
  return (
    <TableCustomRow key={field?.paymentDetailId}>
      <TableCustomCell sx={{ '& span': { opacity: '0.85' }, padding: 0 }}>
        {readonly || (
          <Checkbox
            disableTouchRipple
            disableRipple
            disableFocusRipple
            color="primary"
            name={`paymentList.${index}.checked`}
            checked={field?.checked}
            onChange={(event) => setValue(`paymentList.${index}.checked`, event.target.checked)}
          />
        )}
      </TableCustomCell>
      <TableCustomCell sx={{ padding: 0 }} onClick={onClickRow}>
        <Typography variant="body1">{index + 1}</Typography>
      </TableCustomCell>
      <TableCustomCell sx={{ padding: 0 }} onClick={onClickRow}>
        <Typography variant="body1">{paymentDetail?.content}</Typography>
      </TableCustomCell>
      <TableCustomCell sx={{ padding: 0 }} onClick={onClickRow}>
        <Typography variant="body1">{paymentDetail?.quantity}</Typography>
      </TableCustomCell>
      <TableCustomCell sx={{ padding: 0 }} onClick={onClickRow}>
        <Typography variant="body1">
          {paymentDetail?.unit ? paymentDetail.unit : intl.formatMessage({ id: 'cashier.time' })}
        </Typography>
      </TableCustomCell>
      <TableCustomCell>
        <Typography variant="body1">{NumericFormatText(paymentDetail?.unitPrice || 0)}</Typography>
      </TableCustomCell>
      <TableCustomCell>
        <Box display={'block'} marginTop={1.5}>
          <SchemaElement
            key={`paymentList.${index}.discount.${field?.discountType}`}
            fieldName={`paymentList.${index}.discount`}
            propsElement={{
              type: 'number-field',
              placeholder: intl.formatMessage({
                id: 'cashier.enter',
              }),
              inputType: 'number',
              register: {
                max: {
                  value: isCashDiscountType ? field?.unitPrice : 100,
                  message: isCashDiscountType ? `<= ${field?.unitPrice} ${currency}` : `<= 100%`,
                },
                min: { value: 0, message: `>= 0 ${isCashDiscountType ? currency : '%'}` },
              },
              disabled: readonly,
              tooltipError: true,
            }}
          />
        </Box>
      </TableCustomCell>
      <TableCustomCell sx={{ width: '65px' }}>
        <Box textAlign="left" marginTop={1.5}>
          <SchemaElement
            fieldName={`paymentList.${index}.discountType`}
            propsElement={{
              type: 'select',
              placeholder: intl.formatMessage({
                id: 'cashier.select',
              }),
              options: Object.values(formatDiscountType),
              defaultValue: formatDiscountType.PERCENT.value,
              rawOptions: true,
              disableClearBtn: true,
              hideIconSelect: true,
              disabled: readonly,
              onChange: () => setValue(`paymentList.${index}.discount`, 0),
            }}
          />
        </Box>
      </TableCustomCell>
      <TableCustomCell>
        <Typography variant="body1">{NumericFormatText(receiptDiscountAmount)}</Typography>
      </TableCustomCell>
      <TableCustomCell>
        <Typography variant="body1">{NumericFormatText(finalDiscountAmount)}</Typography>
      </TableCustomCell>
      <TableCustomCell>
        <Typography variant="body1">{NumericFormatText(totalAmount)}</Typography>
      </TableCustomCell>
      <TableCustomCell>
        <Typography variant="body1">{NumericFormatText(paymentDetail?.vat)}</Typography>
      </TableCustomCell>
      <TableCustomCell>
        <Typography variant="body1">{NumericFormatText(vatAmount)}</Typography>
      </TableCustomCell>
      <TableCustomCell>
        <Typography variant="body1">{NumericFormatText(patientPay)}</Typography>
      </TableCustomCell>
      <TableCustomCell>
        <Typography
          noWrap
          sx={{
            color: PAYMENT_STATUS[paymentDetail?.status!]?.color,
            fontWeight: '500',
          }}
        >
          <FormattedMessage id={PAYMENT_STATUS[paymentDetail?.status!]?.label || ' '} />
        </Typography>
      </TableCustomCell>
      <TableCustomCell>
        {paymentDetail?.status === PAYMENT_STATUS?.PAID?.value && (
          <PaymentDetailRefundButton patientPay={patientPay} paymentDetail={paymentDetail} isRefunded={false} />
        )}
        {paymentDetail?.status === PAYMENT_STATUS?.REFUNDED?.value && (
          <PaymentDetailRefundButton patientPay={patientPay} paymentDetail={paymentDetail} isRefunded={true} />
        )}
      </TableCustomCell>
    </TableCustomRow>
  );
};

export default TablePriceRow;
