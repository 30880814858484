import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../../../common/api';
import DialogCustom from '../../../../common/component/DialogCustom';
import { PHYSICS_ROOM_TYPE, some } from '../../../../common/constants';
import SchemaForm from '../../../../common/SchemaForm';
import { procedureFormSchema } from './schema';
import { useFetch } from 'modules/common/hook';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
  list?: some[];
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;

  const { data: roomData } = useFetch(API_SERVER.physicsRoom.getListByCategoryCode(PHYSICS_ROOM_TYPE.procedureRooms));

  const { data: departmentData } = useFetch(API_SERVER.specializedDepartment.getAll());

  const departmentOption = React.useMemo(() => {
    return departmentData?.map((ele) => ({ value: ele.id, label: `${ele.name} - ${ele.code}` }));
  }, [departmentData]);

  const roomsDataOption = React.useMemo(() => {
    return roomData?.map((e) => ({
      value: e.id,
      label: e.name,
    }));
  }, [roomData]);

  return (
    <>
      <SchemaForm
        schema={procedureFormSchema}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        roomsData={roomsDataOption}
        departments={departmentOption}
        formId="exam-price"
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
      />
    </>
  );
};

const ProcedureFormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 680 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'procedure.edit' : 'procedure.addNew'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default ProcedureFormDialog;
