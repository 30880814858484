import { Box, Grid, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { ENCOUNTER_STATUS, ROLE_TYPE } from 'modules/common/apiConstants';
import Calendar from 'modules/common/component/Calendar';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { ROUTES } from 'modules/layout/router';
import { Encounter, IcdDiagnosis } from 'modules/schema';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  encounter: Encounter;
}

const getCalendarColor = (encounter: Encounter) => {
  if (encounter.status === ENCOUNTER_STATUS.INPROGRESS.value) {
    return '#57A3FF';
  }
  return '#90A4AE';
};

const PatientExaminationHistoryItem = ({ encounter }: Props) => {
  const { appState } = useGeneralHook();
  const roles = appState.authen.user?.role || [];
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderChip = (icd: IcdDiagnosis) => {
    if (!icd) return null;
    return (
      <Tooltip title={`${icd.code} - ${icd.display}`}>
        <Typography
          fontSize="14px"
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          maxWidth={'100%'}
          component={'span'}
          display={'block'}
        >
          {`${icd.code} - ${icd.display}`}
        </Typography>
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        border: '1px solid #ECEFF1',
        padding: '10px',
        borderRadius: '8px',
        marginBottom: '15px',
        width: '100%',
        boxShadow: '1px solid var(--Neutral-050, #ECEFF1)',
      }}
    >
      <Grid container>
        <Grid item xs={2}>
          {encounter?.startTime && <Calendar time={encounter?.startTime} color={getCalendarColor(encounter)} />}
        </Grid>
        <Grid item xs={10} paddingLeft={'20px'}>
          <Grid container marginBottom={'10px'}>
            <Grid item xs={8}>
              <Box display={'flex'}>
                <Typography color={'#607D8B'} fontWeight={500} fontSize={'14px'} minWidth={'98px'}>
                  <FormattedMessage id="encounterId" />
                </Typography>
                {roles.indexOf(ROLE_TYPE.RECEPTIONIST.value) > -1 ? (
                  <Typography fontWeight={500}>{encounter?.code}</Typography>
                ) : (
                  <Typography
                    fontWeight={500}
                    color={'#0052E0'}
                    sx={{ cursor: 'pointer' }}
                    onClick={() =>
                      window.open(ROUTES.encounterDetail.gen(encounter?.id!, encounter?.patientId!), '_blank')
                    }
                  >
                    {encounter?.code}
                  </Typography>
                )}
              </Box>
            </Grid>
            <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Stack
                textAlign="center"
                justifyContent="center"
                borderRadius="50px"
                bgcolor="#ECEFF1"
                whiteSpace="nowrap"
                width="fit-content"
              >
                <Typography
                  fontWeight={500}
                  fontSize="14px"
                  padding="5px 15px"
                  sx={{ wordSpacing: 'nowrap' }}
                  color={ENCOUNTER_STATUS[encounter?.status!]?.color}
                >
                  <FormattedMessage id={ENCOUNTER_STATUS[encounter?.status!]?.label} />
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2.5}>
              <Typography fontWeight={500} fontSize="14px" color="#607D8B" marginBottom="10px">
                <FormattedMessage id="dental.label.conditionDiagnosis.icd10" />
              </Typography>
            </Grid>
            <Grid item xs={9.5}>
              {renderChip(encounter?.icd10!)}
              {encounter?.otherIcd10s?.length! > 0 ? (
                <Typography
                  onClick={handleClick}
                  key="rest-of-icd"
                  sx={{
                    fontSize: '14px',
                    fontWeight: 500,
                    cursor: 'pointer',
                  }}
                >
                  (+{encounter?.otherIcd10s?.length})
                </Typography>
              ) : null}
              <Popover
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                  sx: {
                    boxShadow: '0px 4px 8px 0px #E0ECFF',
                    borderRadius: '8px',
                  },
                }}
              >
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-start"
                  flexWrap="wrap"
                  gap="10px"
                  padding="20px"
                  boxSizing="border-box"
                  width="350px"
                >
                  {encounter?.otherIcd10s?.map((icd) => (
                    <Box key={icd.id} width="100%">
                      {renderChip(icd)}
                    </Box>
                  ))}
                </Stack>
              </Popover>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={2.5}>
              <Typography fontWeight={500} fontSize="14px" color={'#607D8B'}>
                <FormattedMessage id="diagnosis" />
              </Typography>
            </Grid>
            <Grid item xs={9.5}>
              <Typography fontSize="14px">
                <Tooltip title={extractHTMLContent(encounter?.differentialDiagnosis) || ''}>
                  <Typography
                    component={'span'}
                    sx={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      fontSize: '14px',
                      display: 'block',
                    }}
                  >
                    {extractHTMLContent(encounter?.differentialDiagnosis)}
                  </Typography>
                </Tooltip>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientExaminationHistoryItem;
