import { useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { useForm } from 'react-hook-form';
import { useContext, useEffect, useState } from 'react';
import { Encounter } from 'modules/schema';
import TextInput from 'modules/common/component/form/TextInput';
import { Box, Typography } from '@mui/material';
import { useDebouncedCallback } from 'use-debounce';
import { FormattedMessage } from 'react-intl';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { EncounterContext } from '../const';

interface MedicalHistoryBlockProps {
  encounter: Encounter;
  readonly: boolean;
}

interface Form {
  illnessesHistory: string;
  medicalHistory: string;
}

const MedicalHistoryBlock = ({ encounter, readonly }: MedicalHistoryBlockProps) => {
  const [errMessageIllnesses, setErrMessageIllnesses] = useState<boolean>(false);
  const [errMessageMedical, setErrMessageMedical] = useState<boolean>(false);

  const formMethods = useForm<Form>({
    defaultValues: {
      illnessesHistory: '',
      medicalHistory: '',
    },
  });

  const encounterContext = useContext(EncounterContext);
  const saveObservationMutate = useUpdateMutate({
    globalLoading: false,
    onSuccess: () => {
      encounterContext?.setCounter(encounterContext.counter + 1);
    },
  });

  const submit = useDebouncedCallback(() => {
    if (Object.values(formMethods.formState.dirtyFields).length > 0) {
      if (
        formMethods.getValues().illnessesHistory.length <= 2000 &&
        formMethods.getValues().medicalHistory.length <= 2000
      ) {
        try {
          saveObservationMutate({
            url: API_SERVER.encounter.updateInfoBasic(encounter?.id!),
            method: 'PUT',
            data: {
              illnessesHistory: formMethods.getValues().illnessesHistory,
              medicalHistory: formMethods.getValues().medicalHistory,
            },
          });

          formMethods.reset(formMethods.getValues());
        } catch (error) {
          formMethods.reset();
        }
        setErrMessageIllnesses(false);
        setErrMessageMedical(false);
      } else {
        if (
          formMethods.getValues().illnessesHistory.length > 2000 &&
          formMethods.getValues().medicalHistory.length > 2000
        ) {
          setErrMessageIllnesses(true);
          setErrMessageMedical(true);
        }
        if (
          formMethods.getValues().illnessesHistory.length > 2000 &&
          formMethods.getValues().medicalHistory.length <= 2000
        ) {
          setErrMessageIllnesses(true);
          setErrMessageMedical(false);
        }
        if (
          formMethods.getValues().illnessesHistory.length <= 2000 &&
          formMethods.getValues().medicalHistory.length > 2000
        ) {
          setErrMessageIllnesses(false);
          setErrMessageMedical(true);
        }
      }
    }
  }, 3000);

  // get data
  useEffect(() => {
    formMethods.reset({
      ...encounter,
      illnessesHistory: extractHTMLContent(encounter?.illnessesHistory) || '',
      medicalHistory: extractHTMLContent(encounter?.medicalHistory) || '',
    });
  }, [encounter, formMethods]);

  return (
    <Box
      sx={{
        '>div>div>p': { display: 'none' },
      }}
    >
      <Box>
        <TextInput
          form={formMethods}
          name="illnessesHistory"
          placeholder="encounter.label.prehistoric.enter"
          label="encounter.label.prehistoric"
          disabled={readonly}
          onChange={submit}
          rows={4}
          sx={{ textarea: { fontSize: '16px' } }}
        />
        {errMessageIllnesses && (
          <Typography fontSize={12} fontWeight={500} color={'#E53935'}>
            <FormattedMessage id="common.text.length.invalid" />
          </Typography>
        )}
      </Box>
      <Box marginTop={'10px'}>
        <TextInput
          form={formMethods}
          name="medicalHistory"
          placeholder="encounter.label.medicalHistory.enter"
          label="encounterDetail.illnessHistory"
          disabled={readonly}
          onChange={submit}
          rows={4}
          sx={{ textarea: { fontSize: '16px' } }}
        />
        {errMessageMedical && (
          <Typography fontSize={12} fontWeight={500} color={'#E53935'}>
            <FormattedMessage id="common.text.length.invalid" />
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default MedicalHistoryBlock;
