import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import { Property } from 'csstype';

const LabelStyled = styled(Typography)({
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#546E7A',
  flexShrink: '0',
});
const ValueStyled = styled(Typography)({
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '24px',
  letterSpacing: '0.5px',
  color: '#263238',
});

interface Props {
  label: string;
  value: string;
  localizationValue?: boolean;
  labelWidth?: Property.Width;
}

const TextValue = ({ label, labelWidth, value, localizationValue = false }: Props) => {
  return (
    <Stack direction="row">
      <LabelStyled width={labelWidth}>
        <FormattedMessage id={label} />
      </LabelStyled>
      &nbsp;:&nbsp;
      {value && <ValueStyled>{localizationValue ? <FormattedMessage id={value} /> : value}</ValueStyled>}
    </Stack>
  );
};

export default TextValue;
