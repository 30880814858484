import { Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  title: string;
  rightAction?: ReactNode;
  children: ReactNode;
}

const EncounterDetailBlock = ({ title, rightAction, children }: Props) => {
  return (
    <Stack
      direction="column"
      sx={{
        borderRadius: '4px',
        border: '1px solid #CFD8DC',
        backgroundColor: '#fff',
        width: '100%',
        overflow: 'hidden',
        cursor: 'default',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ padding: '6px 10px', backgroundColor: '#F4F8FF', minHeight: '45px' }}
      >
        <Typography
          sx={{
            color: '#003CA6',
            fontFeatureSettings: "'clig' off, 'liga' off",
            fontFamily: 'Roboto',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.15px',
          }}
        >
          <FormattedMessage id={title} />
        </Typography>
        {rightAction}
      </Stack>
      <Stack direction="column" sx={{ padding: '16px', flex: 1 }}>
        {children}
      </Stack>
    </Stack>
  );
};

export default EncounterDetailBlock;
