/* eslint-disable prefer-destructuring */
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import vi from '../vi.json';
import en from '../en.json';

function getMessages(locale: string) {
  if (locale === 'vi') {
    return vi;
  }
  if (locale === 'en') {
    return en;
  }
  return {};
}

function mapStateToProps(state: AppState) {
  return { locale: state.intl.locale, messages: getMessages(state.intl.locale) };
}

export default connect(mapStateToProps)(IntlProvider);
