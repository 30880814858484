import { z } from 'zod';

export const EvaluationFormSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    conclusion: z.string(),
    examDepartment: z.string(),
  })
  .partial();

export type EvaluationForm = z.infer<typeof EvaluationFormSchema>;
