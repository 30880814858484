import { Box, Popover, Stack, Tooltip, Typography } from '@mui/material';
import { useParams } from 'react-router';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import moment from 'moment';
import { FE_DATE_FORMAT } from 'modules/common/constants';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { Encounter, IcdDiagnosis } from 'modules/schema';
import { useMemo, useState } from 'react';
import { ROUTES } from 'modules/layout/router';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';

const ExamHistoryBlock = () => {
  const { patientId } = useParams<{ patientId }>();
  const { data: encounterHistory } = useFetch(API_SERVER.encounter.historyEncounter({ patientId }), {
    globalLoading: true,
  });
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderChip = (icd: IcdDiagnosis) => {
    if (!icd) return null;
    return (
      <Tooltip title={`${icd.code} - ${icd.display}`}>
        <Typography
          fontSize="14px"
          textOverflow={'ellipsis'}
          overflow={'hidden'}
          maxWidth={'100%'}
          component={'span'}
          display={'block'}
        >
          {`${icd.code} - ${icd.display}`}
        </Typography>
      </Tooltip>
    );
  };

  const columns = useMemo(() => {
    return [
      {
        width: '450px',
        title: 'dental.dentalDetail.sections.examinationHistories.code.title',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => {
          return (
            <Typography
              variant="body1"
              color={'#0052E0'}
              sx={{ cursor: 'pointer', textDecoration: 'underline', fontWeight: '700' }}
              onClick={() => window.open(ROUTES.encounterDetail.gen(record?.id!, record?.patientId!), '_blank')}
              fontSize="16px"
            >
              {record?.code}
            </Typography>
          );
        },
      },
      {
        width: '400px',
        title: 'encounterHistory.day',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => {
          return (
            <Typography fontSize="16px">
              {record?.createdTime ? moment(record?.createdTime).format(FE_DATE_FORMAT) : ''}
            </Typography>
          );
        },
      },
      {
        width: '350px',
        title: 'diagnosis',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => {
          if (!record?.differentialDiagnosis) return '';
          const diagnosis = extractHTMLContent(record?.differentialDiagnosis) || '';
          return (
            <Tooltip title={diagnosis}>
              <Typography
                sx={{
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  display: 'block',
                  maxWidth: '350px',
                }}
                component={'span'}
                fontSize="16px"
              >
                {diagnosis}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        width: '350px',
        title: 'dental.label.conditionDiagnosis.icd10',
        headerCellProps: { sx: { fontSize: '16px' } },
        render: (record) => {
          if (record?.icdDiagnosisList) {
            const diagnosis = record?.icdDiagnosisList?.map((icd) => icd?.code)?.join(', ') || '';
            return (
              <Tooltip title={diagnosis}>
                <Typography noWrap sx={{ maxWidth: 120 }} fontSize="16px">
                  {diagnosis}
                </Typography>
              </Tooltip>
            );
          } else {
            renderChip(record?.icd10!);
            if (record?.otherIcd10s?.length! > 0) {
              <Typography
                onClick={handleClick}
                key="rest-of-icd"
                sx={{
                  fontSize: '14px',
                  fontWeight: 500,
                  cursor: 'pointer',
                }}
                fontSize="16px"
              >
                (+{record?.otherIcd10s?.length})
              </Typography>;
            }
            <Popover
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              PaperProps={{
                sx: {
                  boxShadow: '0px 4px 8px 0px #E0ECFF',
                  borderRadius: '8px',
                },
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-start"
                flexWrap="wrap"
                gap="10px"
                padding="20px"
                boxSizing="border-box"
                width="350px"
              >
                {record?.otherIcd10s?.map((icd) => (
                  <Box key={icd.id} width="100%" fontSize="16px">
                    {renderChip(icd)}
                  </Box>
                ))}
              </Stack>
            </Popover>;
          }
        },
      },
    ] as Column<Encounter>[];
  }, [anchorEl, open]);

  return (
    <Box width={'100%'}>
      <Table data={encounterHistory} columns={columns} dataKey={'id'} />
    </Box>
  );
};

export default ExamHistoryBlock;
