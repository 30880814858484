import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CSVReader } from 'react-papaparse';
import { IconCSV } from '../../../../svg';
import LoadingIcon from '../../../common/component/LoadingIcon';
import NoDataBox from '../../../common/component/NoDataBox';
import { some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { setLoading } from '../../../common/redux/commonReducer';
import { sendToBackend } from './CSVDialog/csv';
import CSVPreviewDialog from './CSVDialog/CSVPreviewDialog';
import IndicationTable from './IndicationTable';
import SearchIndication from './SearchIndication';
import { FilterParams } from './SearchIndication/schema';
import { Service, SystemCategory } from 'modules/schema';
import { useDownloadFile } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { filter } from 'lodash';

interface Props {
  data: some[];
  loading?: boolean;
  setFormData: (value: some) => void;
  setFormDataGroup: (value: some) => void;
  onDelete: (value: some) => void;
  onDeleteGroup: (value: some) => void;
  onDeleteServices: (services: Service[]) => void;
  searchParams: some;
  isInsurance: boolean;
  setSearchParams: (value: FilterParams) => void;
  category: SystemCategory;
  revalidate: () => void;
}

const IndicationListTable: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    setFormData,
    setFormDataGroup,
    onDelete,
    onDeleteGroup,
    onDeleteServices,
    searchParams,
    setSearchParams,
    isInsurance,
    loading,
    category,
    revalidate,
  } = props;
  const { dispatch, openSnackbar, intl, appState } = useGeneralHook();
  const buttonRef = React.useRef<any>(null);
  const [dataCSV, setDataCSV] = React.useState<some[] | undefined>(undefined);
  const handleOnDrop = (data) => {
    setDataCSV(data);
  };

  const handleOnRemoveFile = (data) => {
    setDataCSV(undefined);
  };

  const onDownloadCSV = useDownloadFile(
    API_SERVER.service.downloadLaboratoryPriceCSV({
      scope: SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
      parentId: category.id!,
    }),
  );

  const handleOpenDialog = (e) => {
    // Note that the ref is set async, so it might be null at some point
    if (buttonRef.current) {
      buttonRef.current?.open(e);
    }
  };
  if (!loading && data && data?.length === 0 && !searchParams?.name) {
    return (
      <Box padding={2}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="50vh"
          overflow="auto"
        >
          <Typography variant="h5" color="primary">
            <FormattedMessage id="indication.empty" />
          </Typography>

          <Box padding={2}>
            <Typography variant="body1">
              <FormattedMessage id="addNewList" />
            </Typography>
          </Box>
          <Box display="flex">
            <CSVReader
              ref={buttonRef}
              onRemoveFile={handleOnRemoveFile}
              onDrop={handleOnDrop}
              noClick
              noDrag
              noProgressBar
            >
              {() => (
                <Button
                  variant="outlined"
                  color="primary"
                  startIcon={<IconCSV />}
                  style={{ marginRight: 16 }}
                  onClick={handleOpenDialog}
                >
                  <FormattedMessage id="importCSV" />
                </Button>
              )}
            </CSVReader>
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setFormDataGroup({})}>
              <FormattedMessage id="add" />
            </Button>
          </Box>
        </Box>
        <CSVPreviewDialog
          open={!!dataCSV}
          dataCSV={dataCSV}
          onClose={() => setDataCSV(undefined)}
          isInsurance={isInsurance}
          onSubmit={async (submittedCSVData) => {
            if (submittedCSVData.length < 1) {
              return;
            }
            if (filter(submittedCSVData, (obj) => obj?.name.length > 255).length !== 0) {
              openSnackbar({ message: intl.formatMessage({ id: 'validator.length.name.import.csv' }), type: 'error' });
              return;
            }
            dispatch(setLoading(true));
            try {
              await sendToBackend(submittedCSVData, dispatch, appState, category.id || 0);
              setDataCSV(undefined);
              revalidate();
              openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
            } catch (e) {
              openSnackbar({ message: e || intl.formatMessage({ id: 'createFail' }), type: 'error' });
            } finally {
              dispatch(setLoading(false));
            }
          }}
        />
      </Box>
    );
  }
  const dataChecked = data?.filter((d) => !(searchParams?.name && (!d.items || d.items?.length <= 0)));
  return (
    <>
      <Box padding={2} paddingBottom={1} display="flex" flexDirection={'row'}>
        <Box flexGrow={1} marginRight={2}>
          <SearchIndication
            onAddNew={() => setFormDataGroup({})}
            onSubmit={(value) => {
              setSearchParams(value);
            }}
            formData={searchParams}
          />
        </Box>
        <Button color="inherit" onClick={onDownloadCSV}>
          <FormattedMessage id="exportCSV" />
        </Button>
      </Box>
      {loading ? (
        <LoadingIcon />
      ) : (
        <Box padding={2} paddingTop={0} overflow="auto">
          {dataChecked.length > 0 ? (
            dataChecked?.map((value: some) => {
              return (
                <IndicationTable
                  key={value.id}
                  onSubmitForm={setFormData}
                  setFormDataGroup={setFormDataGroup}
                  onDelete={onDelete}
                  onDeleteGroup={onDeleteGroup}
                  onDeleteServices={onDeleteServices}
                  data={value}
                />
              );
            })
          ) : (
            <NoDataBox />
          )}
        </Box>
      )}
    </>
  );
};

export default IndicationListTable;
