import { Box, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import SchemaForm from '../../common/SchemaForm';
import PhonePausedIcon from '@mui/icons-material/PhonePaused';
import EmailIcon from '@mui/icons-material/Email';
import PublicIcon from '@mui/icons-material/Public';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import { fetchThunk } from 'modules/common/redux/thunk';
import { API_SERVER, IDENTITY_API_SERVER } from 'modules/common/api';
import useSWR from 'swr';
import { axiosThunk } from 'modules/common/redux/axios';
import './style.scss';
import moment from 'moment';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_TIME_FORMAT } from 'modules/common/constants';
import { GENDER } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';

const AppointmentBooking: React.FC<{}> = () => {
  const [success, setSuccess] = useState<boolean>(false);
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const locationPathName = window.location.pathname;
  const groupId = parseInt(locationPathName.substring(27, locationPathName.lastIndexOf('/')));

  // get list doctor
  const { data: dataListDoctor } = useFetch(
    IDENTITY_API_SERVER.user.getPublicUserByRoles(groupId, {
      roles: ['DOCTOR', 'GENERAL_DOCTOR'],
      status: 'ACTIVE',
    }),
  );

  // get clinic info
  const { data: dataUserInfo } = useFetch(IDENTITY_API_SERVER.group.getPublicGroupInfo(groupId));

  // get logo blob data
  const { data: logoBlobData } = useSWR(
    dataUserInfo?.avatarId ? API_SERVER.s3Resource.downloadPublic(dataUserInfo?.avatarId || 0) : null,
    async (url) => {
      const blob = await dispatch(axiosThunk({ url, responseType: 'blob' }));
      return blob?.data;
    },
    { dedupingInterval: 2000, revalidateOnFocus: false },
  );

  // submit form
  const onSubmit = async (data) => {
    try {
      dispatch(setLoading(true));
      const dateTimeFormat = `${FE_TIME_FORMAT} ${BE_DATE_FORMAT}`;
      const dateFormat = moment(data.bookingDate).format(BE_DATE_FORMAT);
      const startTime = moment(`${data.bookingTime} ${dateFormat}`, dateTimeFormat);
      const endTime = startTime.clone().add('hour', 1);
      const res = await dispatch(
        fetchThunk(API_SERVER.requestAppointment.create(groupId), 'post', {
          bookingStartTime: startTime.format(BE_DATE_TIME_FORMAT),
          bookingEndTime: endTime.format(BE_DATE_TIME_FORMAT),
          customerName: data.customerName,
          note: data.note,
          phoneNumber: data.phoneNumber,
          picUserId: data.picUserId,
          picName: dataListDoctor?.find((user) => user.id === data.picUserId)?.employeeFullName,
          gender: data?.gender,
          dob: data?.dob,
        }),
      );
      if (res?.status === 'NEW') {
        dispatch(setLoading(false));
        setSuccess(true);
      }
    } catch (e: any) {
      dispatch(setLoading(false));
      if (e?.errors) {
        e?.errors.forEach((v) => {
          openSnackbar({ message: v.message, type: 'error' });
        });
      } else {
        openSnackbar({ message: intl.formatMessage({ id: 'login.unknownError' }), type: 'error' });
      }
    }
  };

  return (
    <Box className="register-medical-examination" fontFamily={'Roboto'} height={'100vh'}>
      <Box
        className="register-form"
        padding={'40px 0'}
        height={'80vh'}
        position={'relative'}
        sx={{
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          '@media screen and (max-width: 1024px)': {
            height: 'auto',
            backgroundSize: 'auto 100%',
            padding: '20px',
          },
        }}
      >
        <Box
          className="register-form-inner"
          width={'578px'}
          sx={{
            background: '#fff',
            padding: '40px',
            borderRadius: '10px',
            transform: 'translate(-50%, -50%)',
            '@media screen and (max-width: 1024px)': {
              width: '100%',
              padding: '30px 20px',
              position: 'static',
              transform: 'none',
            },
          }}
          position={'absolute'}
          top={'50%'}
          left={'50%'}
        >
          {!success && (
            <>
              <Typography
                variant="h4"
                textTransform={'uppercase'}
                textAlign={'center'}
                sx={{
                  '@media screen and (max-width: 1024px)': {
                    fontWeight: '500',
                    fontSize: '20px',
                  },
                }}
              >
                <FormattedMessage id="page.registerMedicalExam.title" />
              </Typography>
              <Box
                marginTop="42px"
                sx={{
                  '@media screen and (max-width: 1024px)': {
                    marginTop: '20px',
                  },
                }}
              >
                <SchemaForm
                  onSubmit={onSubmit}
                  hideSubmitButton
                  schema={{
                    fields: ({ formProps: { intl, state, setState } }) => ({
                      customerName: {
                        type: 'text-field',
                        label: <FormattedMessage id="sellingProduct.title.customerName" />,
                        placeholder: intl.formatMessage({ id: 'sale.customerEnter' }),
                        register: {
                          required: true,
                        },
                        propsWrapper: { xs: 8 },
                      },
                      gender: {
                        type: 'select',
                        placeholder: intl.formatMessage({ id: 'pickGender' }),
                        label: intl.formatMessage({ id: 'gender' }),
                        options: [GENDER.OTHER, GENDER.FEMALE, GENDER.MALE],
                        propsWrapper: { xs: 4 },
                      },
                      phoneNumber: {
                        type: 'text-field',
                        inputType: 'number',
                        label: <FormattedMessage id="sellingProduct.title.phoneNumber" />,
                        placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
                        register: {
                          required: true,
                        },
                        propsWrapper: { xs: 8 },
                      },
                      dob: {
                        type: 'datePicker',
                        label: intl.formatMessage({ id: 'birthdayShort' }),
                        propsWrapper: { xs: 4 },
                        autoOk: true,
                        disableFuture: true,
                      },
                      bookingDate: {
                        type: 'datePickerDesktop',
                        label: <FormattedMessage id="encounterDate" />,
                        placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
                        propsWrapper: { xs: 6 },
                        minDate: moment().toDate(),
                        register: {
                          required: true,
                          validate: (value) => {
                            return moment(value).isSameOrAfter(moment().startOf('day'))
                              ? true
                              : intl.formatMessage({ id: 'page.registerMedicalExam.message.datePast' });
                          },
                        },
                      },
                      bookingTime: {
                        type: 'timePickerDesktop',
                        label: <FormattedMessage id="encounterTime" />,
                        placeholder: intl.formatMessage({ id: 'encounterTime' }),
                        propsWrapper: { xs: 6 },
                        register: {
                          required: true,
                        },
                      },
                      picUserId: {
                        type: 'select',
                        label: intl.formatMessage({ id: 'returnResultIndication.doctor' }),
                        placeholder: intl.formatMessage({ id: 'page.registerMedicalExam.label.chooseDoctor' }),
                        options: dataListDoctor?.map((i) => {
                          return {
                            value: i.id,
                            label: i.employeeFullName,
                          };
                        }),
                        rawOptions: true,
                      },
                      note: {
                        type: 'text-field',
                        label: <FormattedMessage id="note" />,
                        placeholder: intl.formatMessage({ id: 'page.registerMedicalExam.label.enterNote' }),
                      },
                      submit: {
                        type: 'submitButton',
                        fullWidth: true,
                        label: <FormattedMessage id="page.registerMedicalExam.label.makeAppointment" />,
                      },
                    }),
                  }}
                />
              </Box>
            </>
          )}
          {success && (
            <Box textAlign={'center'}>
              <CheckCircleOutlineIcon htmlColor="#307BFF" sx={{ fontSize: '85px!important', marginBottom: '20px' }} />
              <Typography fontSize={'24px'} color={'#0052E0'} marginBottom={'20px'}>
                <FormattedMessage id="page.registerMedicalExam.alert.title" />
              </Typography>
              <Typography fontWeight={'500'}>
                <FormattedMessage id="page.registerMedicalExam.alert.content" />
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Grid
        container
        padding={'40px 130px'}
        height={'20vh'}
        sx={{
          '@media screen and (max-width: 1024px)': {
            height: 'auto',
            padding: '30px 20px',
          },
        }}
      >
        <Grid
          item
          md={5}
          xs={12}
          display={'flex'}
          sx={{
            '@media screen and (max-width: 1024px)': {
              display: 'block',
              textAlign: 'center',
            },
          }}
        >
          <Box>
            {logoBlobData && <img src={URL.createObjectURL(logoBlobData)} alt="medring-logo-footer" width="89px" />}
          </Box>
          <Typography
            fontWeight={'500'}
            margin={'25px 20px 0 20px'}
            sx={{
              '@media screen and (max-width: 1024px)': {
                fontSize: '14px',
                margin: '20px 0',
              },
            }}
          >
            {dataUserInfo?.organizationName}
            <br />
            {dataUserInfo?.name}
          </Typography>
        </Grid>
        <Grid item md={2} xs={12}>
          <Box display={'flex'} marginBottom={'15px'}>
            <Box
              sx={{ background: '#E0ECFF' }}
              width={'36px'}
              height={'36px'}
              borderRadius={'40px'}
              position={'relative'}
            >
              <PhonePausedIcon
                htmlColor="#0052E0"
                sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              />
            </Box>
            <Typography fontSize={'14px'} fontWeight={'500'} margin={'6px 0 0 10px'} sx={{ wordBreak: 'break-all' }}>
              {dataUserInfo?.phoneNumber}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            sx={{
              marginBottom: '15px',
            }}
          >
            <Box
              sx={{ background: '#E0ECFF' }}
              width={'36px'}
              height={'36px'}
              borderRadius={'40px'}
              position={'relative'}
            >
              <EmailIcon
                htmlColor="#0052E0"
                sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              />
            </Box>
            <Typography fontSize={'14px'} fontWeight={'500'} margin={'6px 0 0 10px'} sx={{ wordBreak: 'break-all' }}>
              {dataUserInfo?.email}
            </Typography>
          </Box>
        </Grid>
        <Grid item md={5} xs={12}>
          <Box display={'flex'} marginBottom={'15px'}>
            <Box
              sx={{ background: '#E0ECFF' }}
              width={'36px'}
              height={'36px'}
              borderRadius={'40px'}
              position={'relative'}
            >
              <PublicIcon
                htmlColor="#0052E0"
                sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              />
            </Box>
            <Typography fontSize={'14px'} fontWeight={'500'} margin={'6px 0 0 10px'} sx={{ wordBreak: 'break-all' }}>
              {dataUserInfo?.website}
            </Typography>
          </Box>
          <Box
            display={'flex'}
            sx={{
              marginBottom: '15px',
            }}
          >
            <Box
              sx={{ background: '#E0ECFF' }}
              width={'36px'}
              height={'36px'}
              borderRadius={'40px'}
              position={'relative'}
            >
              <LocationOnIcon
                htmlColor="#0052E0"
                sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
              />
            </Box>
            <Typography fontSize={'14px'} fontWeight={'500'} margin={'6px 0 0 10px'} sx={{ wordBreak: 'break-all' }}>
              {dataUserInfo?.address}
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AppointmentBooking;
