import { formatEndDate } from 'modules/common/utils';
import moment from 'moment';
import { FilterType } from 'modules/common/component/filter/type';
import { BE_DATE_TIME_FORMAT, ROLES } from 'modules/common/constants';
import { ValueType } from 'modules/common/type';
import { SERVICE_TYPE_ALL } from 'modules/common/apiConstants';
import { TIME_REPORT_FILTER } from 'modules/common/component/report/consts';

export const FILTER_KEY = 'SALE_EXPENSE_REPORT_FILTER';
export const FILTER_KEY_VALUE = 'SALE_EXPENSE_REPORT_FILTER_VALUE';
export const SELECT_COLUMNS_KEY = 'SALE_EXPENSE_REPORT_SELECT_COLUMNS';

export interface FormSchema {
  fromDate: string;
  toDate: string;
  keyword: string;
}
export interface FormSchemaChart {
  type?: ValueType<typeof SERVICE_TYPE_ALL>[];
  groupReportType?: keyof typeof TIME_REPORT_FILTER;
  fromDate: string;
  toDate: string;
}

export const DEFAULT_VALUES = {
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatEndDate(moment()),
  keyword: '',
};

export const FILTER_CONFIGS: FilterType[] = [
  {
    type: 'EMPLOYEE',
    roles: [ROLES.ADMIN.value, ROLES.COORDINATOR.value, ROLES.PHARMACIST.value],
    groupByRole: false,
    title: 'inventory.warehouser',
    placeholder: 'sale.report.expense.inventory.warehouser.placeholder',
  },
  {
    type: 'MEDICAL_SUPPLIERS',
  },
  {
    type: 'MEDICATION',
  },
];
