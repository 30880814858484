import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { APPOINTMENT_STATUS, BE_DATE_TIME_FORMAT, PATIENT_TYPE } from 'modules/common/constants';
import { APPOINTMENT_TYPES, GENDER } from 'modules/common/apiConstants';

export const AppointmentSchema = z
  .object({
    id: z.number(),
    referenceId: z.number(),
    code: z.string(),
    name: z.string(),
    mobilePhone: z.string(),
    address: z.string(),
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    endTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    picUserId: z.number(),
    picName: z.string(),
    reason: z.string(),
    quantity: z.number(),
    type: createObjectKeysEnumSchema(APPOINTMENT_TYPES),
    groupId: z.number(),
    resourceId: z.string(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    status: createObjectKeysEnumSchema(APPOINTMENT_STATUS),
    patientType: createObjectKeysEnumSchema(PATIENT_TYPE),
    dob: z.string(),
    gender: createObjectKeysEnumSchema(GENDER),
    appointmentGroupId: z.number(),
    patientId: z.number(),
  })
  .partial();

export type Appointment = z.infer<typeof AppointmentSchema>;
