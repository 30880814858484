import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';

export const IndicationProcedureSchema = z
  .object({
    id: z.number(),
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    endTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    anesthesiaMethod: z.string(),
    diagram: z.string(),
    diagnosis: z.string(),
    note: z.string(),
  })
  .partial();

export type IndicationProcedure = z.infer<typeof IndicationProcedureSchema>;
