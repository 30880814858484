import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, equipmentsData, roomsData, departments } = formProps || {};
  const list = valuesField?.listData?.filter((v) => v.id !== valuesField?.id) || [];

  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'imageIndication.code' }),
      placeholder: intl.formatMessage({ id: 'imageIndication.codeEnter' }),
      propsWrapper: { xs: 6 },
      autoFocus: true,
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'imageIndication.name' }),
      placeholder: intl.formatMessage({ id: 'imageIndication.nameEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
        validate: (value) =>
          list.find((v) => v.code === value) ? intl.formatMessage({ id: 'imageIndication.duplicateCode' }) : true,
      },
    },
    specializedDepartment: {
      type: 'select',
      label: intl.formatMessage({ id: 'departmentCode' }),
      placeholder: intl.formatMessage({ id: 'departmentCodeEnter' }),
      propsWrapper: { xs: 6 },
      options: departments,
      rawOptions: true,
      register: {
        required: true,
      },
      required: true,
    },
    physicsRoom: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'imageIndication.room' }),
      placeholder: intl.formatMessage({ id: 'imageIndication.pickRoom' }),
      options: roomsData,
      isOptionEqualToValue: (options, selected) => options.value === selected.value,
      propsWrapper: { xs: 6 },
    },
    medicalEquipment: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'imageIndication.device' }),
      placeholder: intl.formatMessage({ id: 'imageIndication.pickDevice' }),
      options: equipmentsData,
      isOptionEqualToValue: (options, selected) => options.value === selected.value,
      propsWrapper: { xs: 6 },
    },
    price: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'imageIndication.price' }),
      placeholder: intl.formatMessage({ id: 'imageIndication.priceEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'info' }),
      fields: ['code', 'name', 'specializedDepartment', 'physicsRoom', 'medicalEquipment', 'price'],
    },
  ];
};

export const indicationFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
