import { formatEndDate } from 'modules/common/utils';
import moment from 'moment';
import { FilterType } from 'modules/common/component/filter/type';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { TIME_REPORT_FILTER } from 'modules/common/component/report/consts';
import { RECEIPT_TYPE } from 'modules/common/apiConstants';
import { ValueType } from 'modules/common/type';

export const FILTER_KEY = 'SALE_PROFIT_REPORT_FILTER';
export const FILTER_KEY_VALUE = 'SALE_PROFIT_REPORT_FILTER_VALUE';
export const SELECT_COLUMNS_KEY = 'SALE_PROFIT_REPORT_SELECT_COLUMNS';

export interface FormSchema {
  fromDate: string;
  toDate: string;
  saleReceiptCode: string;
  receiptType?: ValueType<typeof RECEIPT_TYPE>[];
}
export interface FormSchemaChart {
  fromDate: string;
  toDate: string;
  groupReportType?: keyof typeof TIME_REPORT_FILTER;
  receiptType?: ValueType<typeof RECEIPT_TYPE>[];
}

export const DEFAULT_VALUES = {
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatEndDate(moment()),
  saleReceiptCode: '',
  receiptType: [],
};

export const FILTER_CONFIGS: FilterType[] = [
  {
    type: 'MEDICATION',
  },
];
