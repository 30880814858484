import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import ReplayIcon from '@mui/icons-material/Replay';
import { useDialog, useUpdateMutate } from 'modules/common/hook';
import { Box } from '@mui/material';
import SchemaFormMain from 'modules/common/SchemaForm';
import DialogCustom from 'modules/common/component/DialogCustom';
import { PrePayment } from 'modules/schema';
import { PAYMENT_METHOD_NOT_FULL, PAYMENT_TYPE } from 'modules/common/apiConstants';
import { API_SERVER } from 'modules/common/api';
import { mutate } from 'swr';

interface Props {
  prepayment: PrePayment;
  prepaymentRevalidate: () => void;
  paymentHistoryRevalidate: () => void;
}

const RefundPrepaymentButton = ({ prepayment, prepaymentRevalidate, paymentHistoryRevalidate }: Props) => {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const refundMutate = useUpdateMutate({
    onSuccess: () => {
      prepaymentRevalidate();
      paymentHistoryRevalidate();
      mutate(API_SERVER.payment.detail(prepayment?.paymentId!, { type: PAYMENT_TYPE.ENCOUNTER }).url);
      onCloseDialog();
    },
  });
  return (
    <>
      <IconButtonTitle size="small" title="refund" onClick={onOpenDialog}>
        <ReplayIcon />
      </IconButtonTitle>
      <DialogCustom
        open={openDialog}
        onClose={onCloseDialog}
        title={'therapy.prepayment.refund.title.refundDialog'}
        maxWidth="sm"
        PaperProps={{
          style: { minWidth: 480 },
        }}
      >
        <Box padding={2}>
          <SchemaFormMain
            onCancel={onCloseDialog}
            onSubmit={(formData) =>
              refundMutate({
                url: API_SERVER.prepayment.refundPrepayment(prepayment?.id!),
                method: 'POST',
                data: {
                  amount: formData?.amount,
                  note: formData?.note,
                  paymentMethod: formData?.paymentMethod,
                },
              })
            }
            schema={{
              fields: ({ formProps: { intl, appState } }) => {
                return {
                  amount: {
                    type: 'number-field',
                    inputType: 'number',
                    label: intl.formatMessage({ id: 'therapy.cashier.dialog.label.refundAmount' }),
                    placeholder: intl.formatMessage({ id: 'select' }),
                    register: {
                      required: true,
                      max: {
                        value: prepayment?.amount,
                        message: intl.formatMessage({ id: 'therapy.cashier.prepaymentRefund.message.invalidAmount' }),
                      },
                    },
                    tooltipError: true,
                    propsWrapper: { xs: 6 },
                  },
                  paymentMethod: {
                    type: 'select',
                    label: intl.formatMessage({ id: 'common.refund.paymentMethod' }),
                    placeholder: intl.formatMessage({ id: 'select' }),
                    defaultValue: PAYMENT_METHOD_NOT_FULL.CASH.value,
                    options: Object.values(PAYMENT_METHOD_NOT_FULL),
                    disableClearBtn: true,
                    style: { width: 248 },
                    register: { required: true },
                    propsWrapper: { xs: 6 },
                  },
                  note: {
                    type: 'text-field',
                    label: intl.formatMessage({ id: 'refundReason' }),
                    placeholder: intl.formatMessage({ id: 'enter' }),
                    rows: 3,
                    multiline: true,
                    propsWrapper: { xs: 12 },
                    register: {
                      required: true,
                      maxLength: {
                        value: 256,
                        message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                      },
                    },
                  },
                };
              },
            }}
          />
        </Box>
      </DialogCustom>
    </>
  );
};

export default RefundPrepaymentButton;
