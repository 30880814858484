import { Print } from '@mui/icons-material';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { GENDER } from 'modules/common/apiConstants';
import DialogCustom from 'modules/common/component/DialogCustom';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import { useDialog } from 'modules/common/hook';
import { Encounter, PaymentDetail } from 'modules/schema';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useWatch } from 'react-hook-form';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import ReactToPrint from 'react-to-print';
import { IFormData, caculatePrice, caculateSumPrice } from './utils';
import { Dictionary } from 'lodash';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { NumericFormatText } from 'modules/common/utils';

interface Props {
  encounter?: Encounter;
  paymentDetailMap: Dictionary<PaymentDetail>;
}

const BillingFormButton = ({ encounter, paymentDetailMap }: Props) => {
  const { appState, intl } = useGeneralHook();
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const refPrintComponent = React.useRef(null);
  const paymentList = useWatch<IFormData, `paymentList`>({ name: 'paymentList' });
  const checkedPayments = useMemo(() => paymentList?.filter((payment) => payment?.checked), [paymentList]);
  const discountPercent = useWatch<IFormData, 'discountPercent'>({ name: 'discountPercent' });
  const finalPrice = React.useMemo<ReturnType<typeof caculatePrice>>(
    () => caculateSumPrice(checkedPayments, discountPercent, paymentDetailMap),
    // Warning: Sử dụng json.stringify(paymentList)
    // vì khi input của field thay đổi thì paymentList không tạo array mới mà chỉ update field
    // nên sẽ useMemo không được gọi lại nếu chỉ paymentList
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [discountPercent, paymentDetailMap, JSON.stringify(paymentList)],
  );
  const tableData = useMemo(
    () =>
      checkedPayments?.map((payment) => {
        const paymentDetail = paymentDetailMap[payment?.paymentDetailId!];
        return {
          ...paymentDetail,
          ...caculatePrice(paymentDetail?.quantity || 0, discountPercent, payment),
          ...payment,
        };
      }),
    [checkedPayments, discountPercent, paymentDetailMap],
  );
  const COLUMNS = useMemo(
    () =>
      [
        {
          title: 'cashier.service',
          width: 190,
          render: (record) => record?.content,
        },
        {
          title: 'quantity',
          align: 'center',
          render: (record) => ((record?.quantity || 0) > 9 ? record?.quantity : `0${record?.quantity}`),
        },
        {
          title: 'cashier.unit',
          render: (record) => record?.unit || intl.formatMessage({ id: 'cashier.time' }),
          align: 'center',
          lastCell: {
            colSpan: 4,
          },
        },
        {
          title: 'cashier.price',
          render: (record) => NumericFormatText(record.unitPrice),
          align: 'right',
          lastCell: {
            render: () => (
              <Typography variant="subtitle2" color="textSecondary" textAlign={'end'} fontSize="19px">
                <FormattedMessage id="total" />
              </Typography>
            ),
          },
        },
        {
          title: 'cashier.preDiscountTotal',
          render: (record) => NumericFormatText(record.baseAmount),
          align: 'right',
          lastCell: {
            render: () => (
              <Typography variant="subtitle2" color="textSecondary" textAlign={'end'} fontSize="19px">
                {NumericFormatText(finalPrice?.baseAmount)}
              </Typography>
            ),
          },
        },
        {
          title: 'cashier.discountAmount',
          dataIndex: 'discountAmount',
          render: (record) => NumericFormatText(record.finalDiscountAmount),
          align: 'right',
          lastCell: {
            align: 'right',
            colSpan: 1,
            render: () => (
              <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                {NumericFormatText(finalPrice?.discountAmount)}
              </Typography>
            ),
          },
        },
        {
          title: 'totalPrice',
          align: 'right',
          render: (record) => NumericFormatText(record.totalAmount),
          lastCell: {
            align: 'right',
            colSpan: 1,
            render: () => (
              <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                {NumericFormatText(finalPrice?.totalAmount)}
              </Typography>
            ),
          },
        },
        {
          title: 'cashier.vatRate',
          align: 'right',
          lastCell: {
            colSpan: 1,
          },
          render: (record) =>
            record.vat !== undefined ? (
              <>
                <FormattedNumber value={record.vat} maximumFractionDigits={0} />%
              </>
            ) : (
              <FormattedMessage id="cashier.noVat" />
            ),
        },
        {
          title: 'cashier.vatValue',
          align: 'right',
          render: (record) =>
            record.vatAmount !== undefined ? (
              <FormattedNumber value={record.vatAmount} maximumFractionDigits={0} />
            ) : null,
          lastCell: {
            align: 'right',
            colSpan: 1,
            render: () => (
              <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                <FormattedNumber value={finalPrice?.vatAmount} />
              </Typography>
            ),
          },
        },
        {
          title: 'therapy.label.customer.pay',
          align: 'right',
          render: (record) => NumericFormatText(record?.patientPay || 0),
          lastCell: {
            align: 'right',
            colSpan: 1,
            render: () => (
              <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                {NumericFormatText(finalPrice?.patientPay || 0)}
              </Typography>
            ),
          },
        },
      ] as Columns<Partial<typeof tableData[number]>>[],
    [
      finalPrice?.baseAmount,
      finalPrice?.discountAmount,
      finalPrice?.patientPay,
      finalPrice?.totalAmount,
      finalPrice?.vatAmount,
      intl,
    ],
  );
  return (
    <>
      <Button
        variant="contained"
        color="inherit"
        style={{ flex: 1 }}
        disabled={paymentList?.every((paymentItem) => !paymentItem?.checked)}
        onClick={onOpenDialog}
        startIcon={<Print />}
      >
        <FormattedMessage id="printReceipt" />
      </Button>
      <DialogCustom
        open={openDialog}
        onClose={onCloseDialog}
        PaperProps={{
          style: { maxWidth: '80vw' },
        }}
        title={'printForm.billForCashier'}
        footer={
          <Stack direction="row" justifyContent="flex-end" width="100%">
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                  <FormattedMessage id="printReceipt" />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Stack>
        }
      >
        <Box ref={refPrintComponent}>
          <Box
            style={{ pageBreakAfter: 'always' }}
            sx={{ padding: 2, '& p': { fontSize: '18px', marginBottom: '10px' } }}
          >
            <Box>
              <HeaderPrintForm>
                <Typography variant="h4" style={{ textTransform: 'uppercase', marginBottom: '25px' }}>
                  <FormattedMessage id="printForm.billForCashier" />
                </Typography>
              </HeaderPrintForm>
              <Grid container spacing={1}>
                <Grid item xs={12} sx={{ columnCount: 2 }}>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="cashierPrint.encounterId" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1">{encounter?.code}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="therapy.label.customer.code" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1">{encounter?.patient?.formatCode}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="name" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                      {encounter?.patient?.name}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="category" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1">
                      <FormattedMessage id={PAYMENT_PLAN[encounter?.paymentPlan!]?.label || ' '} />
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="address" />
                    </Typography>
                    :&nbsp;
                    <Typography variant="body1">{encounter?.patient?.homeAddress?.address}</Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="birthdayShort" />
                    </Typography>
                    :&nbsp;{' '}
                    <Typography variant="body1">
                      {moment(encounter?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="gender" />
                    </Typography>
                    :&nbsp;{' '}
                    <Typography variant="body1">
                      {encounter?.patient?.gender && (
                        <FormattedMessage id={GENDER[encounter?.patient?.gender!]?.label} />
                      )}
                    </Typography>
                  </Box>
                  <Box display="flex">
                    <Typography variant="body1" style={{ minWidth: 160 }}>
                      <FormattedMessage id="telephone" />
                    </Typography>
                    :&nbsp; <Typography variant="body1">{encounter?.patient?.mobilePhone}</Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box marginY={2}>
              <Divider />
            </Box>
            <Box>
              <Typography variant="subtitle1" marginBottom={2} style={{ fontSize: '18px' }}>
                <FormattedMessage id="service" />
              </Typography>
              <TableCustom
                dataSource={tableData}
                containerProps={{
                  sx: {
                    '& th,tr,td': {
                      backgroundColor: 'white',
                      border: '1px solid',
                      color: 'unset',
                      fontSize: '17px',
                    },
                    '& th span': {
                      fontSize: '13px',
                      letterSpacing: '1',
                      paddingBottom: 1,
                    },
                    '& tr': {
                      pageBreakInside: 'avoid',
                    },
                    width: '100%',
                    overflow: 'visible',
                    '& .extendRow,.extendCell': {
                      border: 'none',
                    },
                  },
                }}
                columns={COLUMNS}
              />
            </Box>
            <Box style={{ pageBreakInside: 'avoid', marginTop: 15 }}>
              <Grid container spacing={1}>
                <Grid item xs={6} textAlign="center">
                  <Typography variant="body1">&nbsp;</Typography>
                  <Typography variant="h6">
                    <FormattedMessage id="therapy.indication.request.printForm.patientConfirm" />
                  </Typography>
                  <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                    <FormattedMessage id="medicalPrint.helperText" />
                  </Typography>
                  <Typography variant="body1">{encounter?.patient?.name}</Typography>
                </Grid>
                <Grid item xs={6} textAlign="center">
                  <Typography variant="body1" fontStyle="italic">
                    <FormattedMessage
                      id={'printForm.dateShort'}
                      values={{
                        hour: moment().hour(),
                        min: moment().minute(),
                        day: moment().date(),
                        month: moment().month() + 1,
                        year: moment().year(),
                      }}
                    />
                  </Typography>
                  <Typography variant="h6">
                    <FormattedMessage id="printForm.cashier" />
                  </Typography>
                  <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                    <FormattedMessage id="medicalPrint.helperText" />
                  </Typography>{' '}
                  <Typography variant="body1">{appState.authen.user?.employee_name}</Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Box>
      </DialogCustom>
    </>
  );
};

export default BillingFormButton;
