import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createSelectors } from '../../../../../common/utils';

type CommonInfo = {
  doctorId?: string;
  doctorResourceId?: string;
  doctorName: string;
  encounterId: string;
  encounterType: string;
};
interface EditPICDialogState {
  openDialog: boolean;
  commonInfo: CommonInfo | null;
  onCloseDialog: () => void;
  onOpenDialog: (requireInfo: CommonInfo) => void;
}

export const useEditPICState = create(
  immer<EditPICDialogState>((set) => ({
    openDialog: false,
    commonInfo: null,
    onCloseDialog: () =>
      set((state) => {
        state.openDialog = false;
      }),
    onOpenDialog: (requireInfo) =>
      set((state) => {
        state.openDialog = true;
        state.commonInfo = requireInfo;
      }),
  })),
);

export const useEditPICSelectors = createSelectors(useEditPICState);
