import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT, INDICATION_REQUEST_STATUS, PAYMENT_PLAN } from 'modules/common/constants';
import {
  ANESTHESISA_METHOD,
  GENDER,
  INDICATION_REQUEST_TYPE,
  SYSTEM_CATEGORY_SCOPE,
} from 'modules/common/apiConstants';

export const IndicationResultReportSchema = z
  .object({
    id: z.number(),
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    referenceCode: z.string(),
    patientCode: z.string(),
    patientName: z.string(),
    dob: z.string(),
    gender: createObjectKeysEnumSchema(GENDER),
    paymentPlan: createObjectKeysEnumSchema(PAYMENT_PLAN),
    icdDiagnosis: z.string(),
    physicRoomName: z.string(),
    physicRoomNameExecute: z.string(),
    executeName: z.string(),
    indicationRequestName: z.string(),
    result: z.string(),
    picName: z.string(),
    differentialDiagnosis: z.string(),
    provisionalDiagnosis: z.string(),
    status: createObjectKeysEnumSchema(INDICATION_REQUEST_STATUS),
    note: z.string(),
    systemCategoryName: z.string(),
    systemCategoryId: z.number(),
    serviceId: z.number(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    specializedDepartmentName: z.string(),
    endTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    laterDiagnosis: z.string(),
    preDiagnosis: z.string(),
    anesthesiaMethod: createObjectKeysEnumSchema(ANESTHESISA_METHOD),
    medicalSuppliesGroup: z.string(),
    medicalSuppliesName: z.string(),
    unit: z.string(),
    price: z.number(),
    quantity: z.number(),
    registrationNumber: z.string(),
    ingredient: z.string(),
    usageQuantity: z.number(),
    cancelQuantity: z.number(),
    medicationName: z.string(),
    cancelReason: z.string(),
    phone: z.string(),
    clinicalExamination: z.string(),
    scope: createObjectKeysEnumSchema(SYSTEM_CATEGORY_SCOPE),
    isFree: z.boolean(),
    medicationCategory: z.string(),
    type: createObjectKeysEnumSchema(INDICATION_REQUEST_TYPE),
    referenceId: z.number(),
    patientId: z.number(),
    therapyId: z.number(),
  })
  .partial()
  .transform((indicationResultReport) => {
    return {
      ...indicationResultReport,
      ...(indicationResultReport?.patientCode
        ? {
            formatCode: indicationResultReport?.patientCode?.split('-')?.[1],
          }
        : {}),
    };
  });

export type IndicationResultReport = z.infer<typeof IndicationResultReportSchema>;
