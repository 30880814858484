import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../../common/component/DialogCustom';
import { some } from '../../../../common/constants';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../../common/hook/usePaginationHook';
import SchemaForm from '../../../../common/SchemaForm';
import { drugStoreFormSchema } from './schema';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { Option } from 'modules/common/type';
import { useFetch } from 'modules/common/hook';

interface Props {
  onSubmit: (formVal: some, methods: UseFormReturn) => void;
}
interface PropsContent {
  onSubmit: (formVal: some, methods: UseFormReturn) => void;
  formData?: some;
  onClose: () => void;
}

const SchemaFormContent: React.FunctionComponent<PropsContent> = (props) => {
  const { formData, onSubmit, onClose } = props;
  const specializedDepartmentMap = useSelector((state: AppState) => state.common.specializedDepartments);
  const specializedDepartmentOptions: Option[] = React.useMemo(
    () =>
      Object.values(specializedDepartmentMap).map((ele) => ({ value: ele.id!, label: `${ele.code} - ${ele.name}` })),
    [specializedDepartmentMap],
  );
  return (
    <>
      <SchemaForm
        schema={drugStoreFormSchema}
        onSubmit={onSubmit}
        specializedDepartmentOptions={specializedDepartmentOptions}
        formData={formData && Object.keys(formData).length ? formData : undefined}
        onCancel={onClose}
        formId="supply-form"
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
      />
    </>
  );
};

const DrugDataFormDialog: React.FunctionComponent<Props> = (props) => {
  const { params, setParams } = usePaginationHook();
  const { API_SERVER } = useGeneralHook();

  const { data: formData, mutate } = useFetch(API_SERVER.medication.getDetail(params?.medicationId), {
    dedupingInterval: 0,
    enabled: params?.medicationId,
  });

  return (
    <DialogCustom
      open={!!formData || !!params.medicationCategoryId}
      maxWidth="xs"
      keepMounted={false}
      onClose={() => setParams({ ...params, medicationId: '', medicationCategoryId: '' })}
      PaperProps={{
        style: { minWidth: 1000 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'pharmacy.edit' : 'pharmacy.addNew'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent
          formData={
            params.medicationId
              ? { ...formData, medicationCategoryId: params.medicationCategoryId }
              : { medicationCategoryId: params.medicationCategoryId }
          }
          onSubmit={(formVal, methods) => {
            props.onSubmit(formVal, methods);
            mutate(formVal);
          }}
          onClose={() => setParams({ ...params, medicationId: '', create: '', medicationCategoryId: '' })}
        />
      </Box>
    </DialogCustom>
  );
};
export default DrugDataFormDialog;
