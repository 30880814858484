import { useSelector } from 'react-redux';
import { IMAGE_DEPARTMENT_CODE, LAB_DEPARTMENT_CODE } from '../apiConstants';
import { AppState } from 'redux/reducer';
import { useMemo } from 'react';

const CODES = [LAB_DEPARTMENT_CODE, IMAGE_DEPARTMENT_CODE] as const;

type CodeType = typeof CODES[number];

export const useSpecializedDepartmentPIC = (departmentCode: CodeType) => {
  const specializedDepartmentsMap = useSelector((state: AppState) => state.common.specializedDepartments);
  const userMap = useSelector((state: AppState) => state.common.users);
  return useMemo(
    () => userMap[specializedDepartmentsMap[departmentCode]?.pic!],
    [departmentCode, specializedDepartmentsMap, userMap],
  );
};
