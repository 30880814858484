import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import useSWR from 'swr';
import { API_SERVER } from '../../../../common/api';
import DialogCustom from '../../../../common/component/DialogCustom';
import { some } from '../../../../common/constants';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { fetchThunk } from '../../../../common/redux/thunk';
import SchemaForm from '../../../../common/SchemaForm';
import { drugStoreFormSchema } from './schema';
import usePaginationHook from '../../../../common/hook/usePaginationHook';
import { useFetch } from 'modules/common/hook';

interface Props {
  onSubmit: (formVal: some, methods: UseFormReturn) => void;
}

interface PropsContent {
  onSubmit: (formVal: some, methods: UseFormReturn) => void;
  formData?: some;
  onClose: () => void;
}

const SchemaFormContent: React.FunctionComponent<PropsContent> = (props) => {
  const { onSubmit, formData, onClose } = props;
  const { dispatch } = useGeneralHook();

  const { data: departmentData } = useFetch(API_SERVER.specializedDepartment.getAll());

  const departmentOption = React.useMemo(() => {
    return departmentData?.map((ele) => ({ value: ele.resourceId, label: `${ele.name} - ${ele.code}` }));
  }, [departmentData]);

  const { data: options } = useSWR(
    API_SERVER.medicationCategory.getAllFetchCategory().url,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json?.reduce((val: some[], cur: some) => {
        return [
          ...val,
          ...(cur?.medicationList || [])?.map((v) => ({
            ...v,
            group: cur.name,
            listId: cur.id,
          })),
        ];
      }, []);
    },
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  return (
    <>
      <SchemaForm
        schema={drugStoreFormSchema}
        onSubmit={onSubmit}
        departments={departmentOption}
        options={options}
        formData={formData && Object.keys(formData).length ? formData : undefined}
        onCancel={onClose}
        formId="supply-form"
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
      />
    </>
  );
};

const DrugDataFormDialog: React.FunctionComponent<Props> = (props) => {
  const { params, setParams } = usePaginationHook();
  const { API_SERVER } = useGeneralHook();

  const { data: formData, mutate } = useFetch(API_SERVER.medication.getDetailDrugClinic(params?.medicationId), {
    dedupingInterval: 0,
    enabled: params?.medicationId,
  });
  return (
    <DialogCustom
      open={!!formData || (!!params.medicationCategoryId && !params.medicationId)}
      maxWidth="xs"
      keepMounted={false}
      onClose={() => setParams({ ...params, medicationId: '', medicationCategoryId: '' })}
      PaperProps={{
        style: { minWidth: 860 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'pharmacy.edit' : 'pharmacy.addNew'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent
          formData={
            params.medicationId
              ? {
                  id: formData?.id,
                  medicationCategoryId: params.medicationCategoryId,
                  drug: formData,
                  basePrice: formData?.basePrice,
                }
              : { medicationCategoryId: params.medicationCategoryId }
          }
          onSubmit={(formVal, methods) => {
            props.onSubmit(formVal, methods);
            mutate(formVal);
          }}
          onClose={() => setParams({ ...params, medicationId: '', create: '', medicationCategoryId: '' })}
        />
      </Box>
    </DialogCustom>
  );
};
export default DrugDataFormDialog;
