import { Paper, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../common/api';
import usePaginationHook from '../../common/hook/usePaginationHook';
import { formatDateFilterField } from 'modules/common/utils';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import DentalList from '../component/dental/dentalList/DentalList';
import FilterDentalBox from '../component/dental/FilterDentalBox';
import { useFetch } from 'modules/common/hook';

interface Props {}

const LabDoctorDentalListPage: React.FunctionComponent<Props> = () => {
  const orderTherapy = useSelector((appState: AppState) => appState.common.orderTherapy);
  const hookPagination = usePaginationHook({
    defaultFilter: {
      orderBy: orderTherapy,
    },
  });
  const { params } = hookPagination;

  const { data, isValidating } = useFetch(
    API_SERVER.dentalExam.getList(
      formatDateFilterField(
        { ...params, scope: 'LABORATORY_SERVICE', loadIndicationRequestQuantity: 'true' },
        'fromDate',
        'toDate',
      ),
    ),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  return (
    <>
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterDentalBox
          hookPagination={hookPagination}
          pagin={
            <>
              <Typography variant="h6">
                <FormattedMessage id="navMenu.encounterList.dental" />
              </Typography>
              <Typography variant="caption">
                <FormattedMessage
                  id="foundResult"
                  values={{
                    num: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.content?.length || 0}
                      </Typography>
                    ),
                    total: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.pagination?.totalElements || 0}
                      </Typography>
                    ),
                  }}
                />
              </Typography>
            </>
          }
          onDataLoading={isValidating}
        />
        <DentalList
          data={data?.content}
          total={data?.pagination?.totalElements || 0}
          hookPagination={hookPagination}
          loading={isValidating}
        />
      </Paper>
    </>
  );
};

export default LabDoctorDentalListPage;
