import { Box, Stack, Typography } from '@mui/material';
import PatientDetailSession from '../PatientDetailSession';
import { PatientSessionProps } from '../type';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { FormattedMessage } from 'react-intl';
import { ClockHistory } from 'svg';
import Wifi1BarSharpIcon from '@mui/icons-material/Wifi1BarSharp';
const NoData = () => {
  return (
    <Stack height="380px" justifyContent="center" alignItems="center">
      <Stack sx={{ margin: '0 auto' }} justifyContent="center" alignItems="center" gap="20px">
        <ClockHistory height="80px" />
        <FormattedMessage id="nodata.disease.history" />
      </Stack>
    </Stack>
  );
};

const PatientMedicalHistory = ({ patient }: PatientSessionProps) => {
  return (
    <PatientDetailSession titleKey="patient.patientDetail.session.title.medicalHistory" allowCollapse>
      {patient?.medicalHistory || patient?.illnessesHistory ? (
        <Box>
          <Typography variant="body2">
            <Wifi1BarSharpIcon fontSize="small" />
            {patient?.medicalHistory && extractHTMLContent(patient?.medicalHistory)}
          </Typography>
          <Typography variant="body2">
            <Wifi1BarSharpIcon fontSize="small" />
            {patient?.illnessesHistory && extractHTMLContent(patient?.illnessesHistory)}
          </Typography>
        </Box>
      ) : (
        <NoData />
      )}
    </PatientDetailSession>
  );
};

export default PatientMedicalHistory;
