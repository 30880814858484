import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';
import { PAYMENT_ADVANCE_STATUS, PAYMENT_METHOD } from 'modules/common/apiConstants';

export const PaymentAdvanceSchema = z
  .object({
    id: z.number(),
    paymentMethod: createObjectKeysEnumSchema(PAYMENT_METHOD),
    paymentId: z.number(),
    amount: z.number(),
    prepaymentId: z.number(),
    groupId: z.number(),
    note: z.string(),
    status: createObjectKeysEnumSchema(PAYMENT_ADVANCE_STATUS),
    paymentHistoryId: z.number(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    confirmedTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
  })
  .partial();

export type PaymentAdvance = z.infer<typeof PaymentAdvanceSchema>;
