import { Stack } from '@mui/material';
import TherapyBlock from 'modules/common/component/Encounter/block/therapy/TherapyBlock';
import { EncounterDetailProps } from 'modules/common/component/Encounter/type';

const TherapySession = ({ encounter, readonly }: EncounterDetailProps) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" gap="10px">
      <TherapyBlock encounter={encounter} readonly={readonly} />
    </Stack>
  );
};

export default TherapySession;
