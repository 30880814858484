import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { PATIENT_ADDRESS_TYPE } from 'modules/common/apiConstants';

export const PatientAddressSchema = z
  .object({
    id: z.number(),
    type: createObjectKeysEnumSchema(PATIENT_ADDRESS_TYPE),
    address: z.string(),
    village: z.string(),
    line: z.string(),
    rt: z.string(),
    rw: z.string(),
    province: z.string(),
    district: z.string(),
    state: z.string(),
    country: z.string(),
    postalCode: z.string(),
    homePhone: z.string(),
    mobilePhone: z.string(),
  })
  .partial();

export type PatientAddress = z.infer<typeof PatientAddressSchema>;
