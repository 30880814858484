import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import DialogCustom from '../../../../common/component/DialogCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from '../../../../common/constants';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import HeaderPrintForm from '../../../../common/component/HeaderPrintForm';
import './style.scss';
import { Encounter } from 'modules/schema';
import { GENDER, PATIENT_ADDRESS_TYPE } from 'modules/common/apiConstants';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
      margin: '0mm',
    },
  },
});

const SERVICE_COLUMNS: Columns[] = [
  {
    dataIndex: 'serviceName',
    title: 'nameService',
  },
  {
    dataIndex: 'physicsRoomName',
    title: 'encounterList.room',
  },
  {
    dataIndex: 'picName',
    title: 'examDoctor',
  },
];

interface ReceiptContentProps {
  encounter: Encounter;
}

interface Props {
  open: boolean;
  onClose?: () => void;
  encounter: Encounter;
}

const FormPrintContent = (props: ReceiptContentProps) => {
  const { encounter } = props;
  const { intl } = useGeneralHook();

  const { data: patient } = useFetch(API_SERVER.patient.getPatient(encounter?.patient?.id!), {
    globalLoading: true,
    enabled: encounter?.patient?.id,
  });

  return (
    <>
      <Box style={{ width: '100%' }}>
        <Box style={{ width: '100%' }}>
          <Box marginBottom={2}>
            <HeaderPrintForm>
              <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
                <FormattedMessage id="encounter.registration.title.printRegistrationForm" />
              </Typography>
            </HeaderPrintForm>
          </Box>

          <Box
            flex={1}
            display="flex"
            alignItems="center"
            style={{ backgroundColor: 'gainsboro', paddingTop: '20px', paddingBottom: '20px' }}
          >
            <Box margin={'auto'}>
              <Box display="flex" flexWrap="wrap" alignItems={'center'}>
                <Typography
                  variant="subtitle1"
                  style={{ margin: 'auto', color: 'gray', fontSize: '45px', lineHeight: '60px' }}
                >
                  <FormattedMessage id={'encounter.registration.label.sequenceNumber'} />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" alignItems={'center'}>
                <Typography variant="subtitle1" style={{ margin: 'auto', fontSize: '110px', lineHeight: '130px' }}>
                  {encounter?.sequenceNumber?.split('-')?.[1]?.padStart(3, '0')}
                </Typography>
              </Box>
              <Box display="flex" alignItems={'center'}>
                <Typography variant="body1" style={{ margin: 'auto', fontSize: '23px' }}>
                  {encounter?.createdTime?.format(FE_DATE_FORMAT)}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box paddingTop={1} marginBottom={2} width={'100%'}>
            <Divider />
          </Box>
          <Grid container spacing={0}>
            <Box paddingY={1} marginBottom={6}></Box>
            <Box display="flex" flexWrap="wrap" alignItems={'center'}>
              <Typography variant="h5" style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                <FormattedMessage id="encounter.registration.title.patientInformation" />
              </Typography>
            </Box>
            <Grid item xs={12} style={{ columnCount: 1 }}>
              {[
                {
                  label: 'encounter.registration.label.patientName',
                  value: encounter?.patient?.name,
                  uppercase: true,
                },
                {
                  label: 'birthdayShort',
                  value:
                    encounter?.patient?.dob && moment(encounter?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT),
                },
                {
                  label: 'age',
                  value: moment().year() - moment(encounter?.patient?.dob, BE_DATE_FORMAT).year(),
                },
                {
                  label: 'gender',
                  value: intl.formatMessage({ id: GENDER[encounter?.patient?.gender!].label || ' ' }),
                },
                {
                  label: 'address',
                  value: patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.HOME)
                    ?.address,
                },
                {
                  label: 'telephone',
                  value: encounter?.patient?.mobilePhone,
                },
              ].map((item, index) => {
                return item?.label ? (
                  <Box key={index} display="flex" flexWrap="wrap" alignItems={'baseline'}>
                    <Typography variant="subtitle1" style={{ minWidth: 190 }} className="info-a5-size">
                      <FormattedMessage id={item?.label || ' '} />
                    </Typography>
                    :&nbsp;
                    <span
                      style={{
                        textTransform: item?.uppercase ? 'uppercase' : 'initial',
                        display: 'flex',
                        flexWrap: 'wrap',
                      }}
                      className="info-a5-size"
                    >
                      {item?.value}
                    </span>
                  </Box>
                ) : (
                  <Box key={index} display="flex" flexWrap="wrap" alignItems={'baseline'}></Box>
                );
              })}
            </Grid>
          </Grid>
          <Box paddingTop={1} marginBottom={2} width={'100%'}>
            <Divider />
          </Box>
          <Box paddingY={1} marginBottom={2}>
            <Box display="flex" flexWrap="wrap" alignItems={'center'}>
              <Typography variant="h5" style={{ textTransform: 'uppercase', fontWeight: 'bold' }}>
                <FormattedMessage id="encounter.registration.title.listService" />
              </Typography>
            </Box>
            <Box paddingY={1} marginBottom={1}></Box>
            <TableCustom
              containerProps={{
                sx: {
                  '& th,tr,td': {
                    padding: '2px 8px',
                    backgroundColor: 'white',
                    border: '0.5px solid',
                    color: 'unset',
                  },
                  width: '100%',
                  overflow: 'visible',
                },
              }}
              dataSource={[
                {
                  serviceName: encounter?.serviceName,
                  physicsRoomName: encounter?.physicsRoom?.name,
                  picName: encounter?.picName,
                },
              ]}
              columns={SERVICE_COLUMNS}
            />
            <Box paddingY={1} marginBottom={2}></Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const PrintEncounterRegistration = (props: Props) => {
  const { onClose, open, encounter } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: '60vw' },
      }}
      title={'encounter.registration.button.printRegistration.full'}
      footer={
        <ReactToPrint
          documentTitle=""
          trigger={() => (
            <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
              <FormattedMessage id="sellingProduct.label.print.button" />
            </Button>
          )}
          content={() => refPrintComponent.current}
        />
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <table style={{ width: '100%' }} className="tablePrint">
          <tbody>
            <FormPrintContent encounter={encounter} />
          </tbody>
        </table>
      </Box>
    </DialogCustom>
  );
};

export default PrintEncounterRegistration;
