import { ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return {
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'room.name' }),
      placeholder: intl.formatMessage({ id: 'room.nameEnter' }),
      propsWrapper: { xs: 6 },
      autoFocus: true,
      register: {
        required: true,
      },
    },
    description: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'room.note' }),
      placeholder: intl.formatMessage({ id: 'room.noteEnter' }),
      propsWrapper: { xs: 6 },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  // const { intl } = formProps || {};
  return [
    {
      id: 'info',
      fields: ['name', 'description'],
    },
  ];
};

export const roomFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
