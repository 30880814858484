import { Box, Button, Typography } from '@mui/material';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useLocation } from 'react-router-dom';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import MediCard from 'modules/common/component/MediCard';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { some } from 'modules/common/constants';
import { ROUTES } from 'modules/layout/router';
import { NumericFormatText } from 'modules/common/utils';
import { Service } from 'modules/schema';
import { isEmpty } from 'lodash';

interface Props {
  data?: some;
  onSubmitForm: (item: some) => void;
  setFormDataGroup: (item: some) => void;
  onDelete: (item: some) => void;
  onDeleteGroup: (item: some) => void;
  onDeleteServices: (services: Service[]) => void;
  loading?: boolean;
  onReference?: (item: some) => void;
}

const IndicationTable: React.FunctionComponent<Props> = (props) => {
  const { data, onSubmitForm, setFormDataGroup, onDelete, onDeleteGroup, onDeleteServices, onReference, loading } =
    props;
  const location = useLocation();
  const [selection, setSelection] = React.useState<Service[]>([]);

  const columns = React.useMemo(() => {
    return [
      {
        title: 'serviceCode',
        dataIndex: 'code',
      },
      {
        title: 'serviceName',
        dataIndex: 'name',
        render: (record) => <Typography color={record?.name.length > 255 ? 'red' : ''}>{record?.name}</Typography>,
      },
      {
        title: 'departmentCode',
        dataIndex: 'specializedDepartment.code',
      },
      {
        title: 'indication.room',
        dataIndex: 'physicsRoom.name',
      },

      {
        title: 'indication.device',
        dataIndex: 'medicalEquipment.name',
      },
      {
        title: 'price',
        dataIndex: 'price',
        render: (record) => record.price && NumericFormatText(record.price),
      },
      {
        title: 'action',
        width: 160,
        render: (record) => {
          const { specializedDepartment = {} } = record;
          return (
            <>
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  onSubmitForm({
                    ...record,
                    systemCategoryId: data?.id,
                    department: specializedDepartment.id,
                  });
                }}
              >
                <EditIcon color={record.validation === false ? 'error' : undefined} />
              </IconButtonTitle>
              <IconButtonTitle
                title="delete"
                size="small"
                onClick={() => {
                  onDelete({
                    ...record,
                    listId: data?.id,
                    listData: data?.services,
                    allItems: data?.allItems,
                  });
                }}
                style={{ margin: '0px 8px' }}
              >
                <DeleteIcon />
              </IconButtonTitle>
              {onReference ? (
                <IconButtonTitle
                  title="create"
                  size="small"
                  onClick={() => {
                    onReference(record);
                  }}
                >
                  <AssignmentReturnIcon
                    color={record.validationItems === false ? 'error' : undefined}
                    style={{ transform: 'rotateY(180deg)' }}
                  />
                </IconButtonTitle>
              ) : (
                <Link
                  to={{
                    pathname: ROUTES.referenceIndication.gen(data?.id, record?.id),
                    search: location.search,
                  }}
                >
                  <IconButtonTitle size="small">
                    <AssignmentReturnIcon style={{ transform: 'rotateY(180deg)' }} />
                  </IconButtonTitle>
                </Link>
              )}
            </>
          );
        },
      },
    ] as Columns[];
  }, [onReference, data?.id, data?.services, data?.allItems, location.search, onSubmitForm, onDelete]);

  if (!data) {
    return null;
  }

  const funcDeleteServices = () => {
    onDeleteServices(selection);
    setSelection([]);
  };

  return (
    <MediCard
      style={{ margin: '8px 0px', color: 'white' }}
      title={
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="subtitle1" color="inherit">
              {data.name}
            </Typography>
          </Box>
          <Box display="flex">
            <IconButtonTitle
              title="create"
              size="small"
              color="inherit"
              onClick={() =>
                onSubmitForm({
                  systemCategoryId: data?.id,
                })
              }
            >
              <NoteAddIcon />
            </IconButtonTitle>
            <IconButtonTitle
              title="update"
              size="small"
              color="inherit"
              onClick={() => setFormDataGroup(data)}
              style={{ marginRight: 8, marginLeft: 8 }}
            >
              <EditIcon />
            </IconButtonTitle>
            <IconButtonTitle
              title="delete"
              size="small"
              color="inherit"
              disabled={!!data.items?.length && !onReference && isEmpty(selection)}
              onClick={() => {
                !isEmpty(selection) ? funcDeleteServices() : onDeleteGroup(data);
              }}
            >
              <DeleteIcon />
            </IconButtonTitle>
          </Box>
        </Box>
      }
    >
      <Box padding={1}>
        <TableCustom
          dataSource={data?.items}
          loading={loading}
          columns={columns}
          rowSelection={{
            selectedRowKeys: selection,
            onChange: ({ selectedRows }) => setSelection(selectedRows),
          }}
          caption={
            <Button
              size="large"
              fullWidth
              variant="text"
              startIcon={<NoteAddIcon />}
              color="primary"
              onClick={() => onSubmitForm({ systemCategoryId: data?.id })}
            >
              <Typography variant="subtitle2">
                <FormattedMessage id="indication.addNewInList" />
              </Typography>
            </Button>
          }
        />
      </Box>
    </MediCard>
  );
};

export default IndicationTable;
