import { Box, Typography } from '@mui/material';
import SessionTitle from './SessionTitle';
import { API_SERVER } from 'modules/common/api';
import { useDentalDetailStateSelector } from '../state';
import {
  DENTAL_DIAGNOSIS_BASIC_TYPE,
  FE_DATE_FORMAT,
  INDICATION_REQUEST_STATUS,
  THERAPY_REFERENCE_TYPE,
  some,
} from 'modules/common/constants';
import { PRESCRIPTION_DETAIL_TYPE, PRESCRIPTION_TYPE } from 'modules/common/apiConstants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { CarePlan } from 'modules/schema';
import { useMemo } from 'react';
import styled from '@emotion/styled';
import { FormattedMessage } from 'react-intl';
import { useFetchDentalInfoBoard, useFetchIndicationRequest } from '../common/fetcher';
import PrintFormTable from 'modules/common/component/PrintForm/PrintFormTable';
import {
  INDICATION_REQUEST_COLUMNS,
  INDICATION_THERAPY_COLUMNS,
  PRESCRIPTION_COLUMNS,
  STATUS_AND_DIAGNOSIS_COLUMNS,
} from './constant';
import { DENTAL_GROUP, DENTAL_GROUP_DETAIL } from '../common/constants';
import { chain } from 'lodash';
import Odontogram from './Odontogram';
import TextValue from 'modules/common/component/PrintForm/TextValue';
import { MEDICATION_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { CARE_PLAN_TYPE } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';

interface Props {
  carePlan?: CarePlan;
}

const LabelStyled = styled(Typography)({
  fontSize: '16px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  color: '#546E7A',
  flexShrink: '0',
});

const BoxStyled = styled(Typography)({
  marginBottom: '30px',
  '@media print': {
    pageBreakInside: 'avoid',
  },
});

const DentalInfo = ({ carePlan }: Props) => {
  const dentalExam = useDentalDetailStateSelector.use.dentalExamInfo();
  const { intl, appState } = useGeneralHook();
  const intendedRoutes = appState.common.intendedRoutes;
  const { data: dentalHistories } = useFetch(
    API_SERVER.dentalDiagnosisBasic.getList(String(dentalExam?.id), { type: DENTAL_DIAGNOSIS_BASIC_TYPE.DENTAL.value }),
    { enabled: dentalExam?.id },
  );
  const { data: otherHistories } = useFetch(
    API_SERVER.dentalDiagnosisBasic.getList(String(dentalExam?.id), {
      type: DENTAL_DIAGNOSIS_BASIC_TYPE.MEDICAL_HISTORY.value,
    }),
    { enabled: dentalExam?.id },
  );
  const { data: therapyData } = useFetch(
    API_SERVER.therapy.getTherapyByReference(dentalExam?.id!, {
      type: THERAPY_REFERENCE_TYPE.DENTAL,
    }),
    { enabled: dentalExam?.id },
  );

  const { data: medicationCategoryList } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({ scope: MEDICATION_CATEGORY_SCOPE.INVENTORY }),
  );

  const { data: dataDentalPrescriptions } = useFetch(
    API_SERVER.dental.getDentalPrescriptions({
      referenceId: dentalExam?.id!,
      type: PRESCRIPTION_TYPE.DENTAL_EXAM,
    }),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  const { data: indicationRequests } = useFetchIndicationRequest(dentalExam?.id!);
  const { data: dentalInfos } = useFetchDentalInfoBoard(dentalExam?.id!, dentalExam?.patientId!);

  const dentalHistory = useMemo(() => dentalHistories?.map((ele) => ele.name)?.join(', ') || '', [dentalHistories]);
  const otherHistory = useMemo(() => otherHistories?.map((ele) => ele.name)?.join(', ') || '', [otherHistories]);
  const dentalDiagnosis = useMemo(() => {
    return dentalInfos?.reduce<some[]>((arr, cur) => {
      return [
        ...arr,
        ...(cur.dentalDiagnosisList?.map((ele) => ({
          position: intl.formatMessage({ id: DENTAL_GROUP[ele.objectType!]?.label }) + ' ' + (cur?.position || ''),
          dentalGroupDetail: ele.objectDetail
            ? intl.formatMessage({ id: DENTAL_GROUP_DETAIL[ele.objectDetail]?.label })
            : '',
          diagnosticStatus: ele?.display,
          createdDate: ele?.createdTime?.format(FE_DATE_FORMAT),
        })) || []),
      ];
    }, []);
  }, [dentalInfos, intl]);
  const medicationCategoryMap = useMemo(
    () => chain(medicationCategoryList).flatMap('medicationList').keyBy('id').value(),
    [medicationCategoryList],
  );
  const prescriptionMap = useMemo(
    () =>
      dataDentalPrescriptions?.prescriptionDetailList
        ?.filter(
          (ele) =>
            ele.type === PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL ||
            ele.type === PRESCRIPTION_DETAIL_TYPE.MEDICATION_EXTERNAL,
        )
        .map((ele) => {
          if (ele.type === PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL) {
            return {
              ...ele,
              medicationName: medicationCategoryMap[ele.medicationId!]?.name,
              quantity: ele.quantity
                ? `${ele.quantity} ${medicationCategoryMap[ele.medicationId!]?.numeratorUnit}`
                : '',
              medication: medicationCategoryMap[ele.medicationId!],
              intendedRoute: intendedRoutes?.find((intendedRoute) => intendedRoute.value === ele?.intendedRoute)?.label,
            };
          } else {
            return {
              ...ele,
              quantity: ele.quantity ? `${ele.quantity} ${ele.unit}` : '',
              medication: medicationCategoryMap[ele.medicationId!],
            };
          }
        }),
    [dataDentalPrescriptions?.prescriptionDetailList, intendedRoutes, medicationCategoryMap],
  );

  return (
    <Box>
      <SessionTitle titleKey="dental.dentalDetail.printForm.dentalInfoSession.title" />
      <BoxStyled>
        <TextValue label="dental.dentalDetail.printForm.dentalHistory.title" value={dentalHistory} labelWidth="220px" />
      </BoxStyled>
      <BoxStyled>
        <TextValue label="dental.dentalDetail.printForm.otherHistory.title" value={otherHistory} labelWidth="220px" />
      </BoxStyled>
      <BoxStyled>
        <LabelStyled marginBottom="20px">
          <FormattedMessage id="dental.dentalDetail.printForm.odontogram.title" />
        </LabelStyled>
        <Odontogram />
      </BoxStyled>
      <BoxStyled>
        <LabelStyled marginBottom="10px">
          <FormattedMessage id="dental.dentalDetail.printForm.statusAndDiagnosis.title" />
        </LabelStyled>
        <PrintFormTable data={(dentalDiagnosis as some[]) || []} columns={STATUS_AND_DIAGNOSIS_COLUMNS} />
      </BoxStyled>
      <BoxStyled>
        <LabelStyled marginBottom="10px">
          <FormattedMessage id="dental.dentalDetail.printForm.indicationRequest.title" />
        </LabelStyled>
        <PrintFormTable
          data={
            indicationRequests?.filter(
              (ele) =>
                ele.status === INDICATION_REQUEST_STATUS.REQUESTED.value ||
                ele.status === INDICATION_REQUEST_STATUS.FINISHED.value,
            ) || []
          }
          columns={INDICATION_REQUEST_COLUMNS}
        />
      </BoxStyled>
      <BoxStyled>
        <LabelStyled marginBottom="10px">
          <FormattedMessage id="dental.dentalDetail.printForm.indicationTherapy.title" />
        </LabelStyled>
        <PrintFormTable
          data={(therapyData?.filter((ele) => ele.status !== 'CANCELLED') as some[]) || []}
          columns={INDICATION_THERAPY_COLUMNS}
        />
      </BoxStyled>

      <BoxStyled>
        <LabelStyled marginBottom="10px">
          <FormattedMessage id="dental.dentalDetail.printForm.prescription.title" />
        </LabelStyled>
        <PrintFormTable data={prescriptionMap || []} columns={PRESCRIPTION_COLUMNS} />
        <Box marginTop={2}>
          <TextValue
            label="dental.dentalDetail.printForm.medicationNode.title"
            value={dataDentalPrescriptions?.advice || ''}
            labelWidth="100px"
          />
        </Box>
      </BoxStyled>
      <BoxStyled>
        <TextValue label="dental.dentalDetail.printForm.extraNote.title" value={''} labelWidth="100px" />
      </BoxStyled>
      {carePlan && (
        <BoxStyled>
          <TextValue
            label="dental.dentalDetil.hospitalTranfer.printForm.solution"
            value={
              intl.formatMessage({ id: CARE_PLAN_TYPE[carePlan?.type!]?.label || ' ' }) +
              (carePlan?.note ? ` - ${carePlan?.note}` : ``)
            }
            labelWidth="100px"
          />
        </BoxStyled>
      )}
    </Box>
  );
};

export default DentalInfo;
