import { Box, ButtonBase, Paper } from '@mui/material';
import * as React from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducer';
import { ASIDE_MIN_WIDTH, ASIDE_WIDTH } from './constants';
import DefaultAsideItems from './DefaultAsideItems';
import { RoutesTabType } from './utils';
import { LogoSVG } from '../../svg';
import Stack from '@mui/material/Stack';

const mapStateToProps = (state: AppState) => {
  return { router: state.router };
};

interface Props extends ReturnType<typeof mapStateToProps> {
  listRouter: RoutesTabType[];
  open: boolean;
  onClose(): void;
}

const DefaultAside: React.FunctionComponent<Props> = (props) => {
  const { router, open, listRouter } = props;
  const { pathname } = router.location;

  return (
    <>
      <div
        style={{
          minWidth: open ? ASIDE_WIDTH : ASIDE_MIN_WIDTH,
          transition: 'all 0.3s',
        }}
      />
      <Paper
        style={{
          transition: 'width 0.3s',
          left: 0,
          flexShrink: 0,
          zIndex: 1200,
          borderRadius: 0,
          display: '-webkit-box',
          background: 'none',
          boxShadow: 'none',
        }}
        elevation={1}
      >
        {open && (
          <Box
            style={{
              width: 82,
              position: 'absolute',
              top: 24,
              left: 16,
              transition: 'all 0.3s',
              zIndex: 1,
            }}
          />
        )}
        <ButtonBase
          sx={{
            justifyContent: open ? 'space-between' : 'center',
            padding: open ? '0px 20px' : 0,
            minWidth: open ? ASIDE_WIDTH : ASIDE_MIN_WIDTH,
          }}
        >
          {open && (
            <Box
              flex={1}
              sx={{
                '& svg path': {
                  fill: '#fff',
                },
              }}
            >
              <LogoSVG style={{ width: 100, height: 40 }} />
            </Box>
          )}
        </ButtonBase>
        <Stack direction="row">
          {listRouter.map((v: RoutesTabType, index: number) => (
            <DefaultAsideItems key={index} open={open} data={v} pathname={pathname} />
          ))}
        </Stack>
      </Paper>
    </>
  );
};

export default connect(mapStateToProps)(DefaultAside);
