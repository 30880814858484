import { Stack, Typography } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import { PAYMENT_ADVANCE_STATUS, PAYMENT_METHOD } from 'modules/common/apiConstants';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import MediCard from 'modules/common/component/MediCard';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { FE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { useFetch, useDeleteMutate } from 'modules/common/hook';
import { NumericFormatText } from 'modules/common/utils';
import { Encounter, PaymentAdvance } from 'modules/schema';
import React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import DeleteIcon from '@mui/icons-material/Delete';
import AddPrepaymentButton from './AddPaymentAdvanceButton';
import EditPrepaymentButton from './EditPaymentAdvanceButton';
import ConfirmPaymentAdvanceButton from './ConfirmPaymentAdvanceButton';
import PaymentAdvancePrintButton from './PaymentAdvancePrintButton';
import RefundPrepaymentButton from './RefundPrepaymentButton';
import { mutate } from 'swr';

interface Props {
  encounter?: Encounter;
}

const Prepayments = ({ encounter }: Props) => {
  const payment = encounter?.payment;
  const {
    data: prepayment,
    isValidating,
    revalidate: prepaymentRevalidate,
  } = useFetch(
    API_SERVER.prepayment.getPrepayment({
      paymentId: payment?.id!,
    }),
    { enabled: payment },
  );
  const deletePaymentAdvanceMutate = useDeleteMutate({
    confirmMessage: 'therapy.prepaid.message.confirmDelete',
    onSuccess: prepaymentRevalidate,
  });

  const COLUMNS = React.useMemo(() => {
    return [
      {
        title: 'therapy.prepaid.table.header.prepaidAmount',
        render: (record) => NumericFormatText(record.amount),
        lastCell: {
          colSpan: 2,
          style: { background: '#E0ECFF' },
          render: () => {
            return (
              <Stack direction="row" justifyContent="flex-end">
                <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="therapy.prepaid.label.totalAmount" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">{NumericFormatText(prepayment?.totalAmount)}</Typography>
              </Stack>
            );
          },
        },
      },
      {
        title: 'therapy.prepaid.table.header.createdTime',
        render: (record) => (
          <Typography variant="body1">
            {record?.confirmedTime && record?.confirmedTime?.format(FE_DATE_TIME_FORMAT)}
          </Typography>
        ),
        lastCell: {
          style: { background: '#E0ECFF' },
          render: () => {
            return (
              <Stack direction="row" justifyContent="center">
                <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="therapy.prepaid.label.totalUsed" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  <FormattedNumber value={prepayment?.amountPaid || 0} />
                </Typography>
              </Stack>
            );
          },
        },
      },

      {
        title: 'therapy.prepaid.table.header.prepaidPaymentMethod',
        render: (record) => {
          return (
            <Typography variant="body1">
              <FormattedMessage id={PAYMENT_METHOD[record?.paymentMethod!]?.label || ' '} />
            </Typography>
          );
        },
        lastCell: {
          style: { background: '#E0ECFF' },
          render: () => {
            return (
              <Stack direction="row" justifyContent="flex-end">
                <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="therapy.prepaid.label.totalRefunded" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  <FormattedNumber value={prepayment?.refundAmount || 0} />
                </Typography>
              </Stack>
            );
          },
        },
      },

      {
        title: 'status',
        render: (record) => {
          return (
            <Typography
              noWrap
              sx={{
                color: PAYMENT_ADVANCE_STATUS[record?.status!]?.color,
                fontWeight: '500',
              }}
            >
              <FormattedMessage id={PAYMENT_ADVANCE_STATUS[record?.status!]?.label || ' '} />
            </Typography>
          );
        },
        lastCell: {
          style: { background: '#E0ECFF' },
          render: () => {
            return (
              <Stack direction="row" justifyContent="flex-end" style={{ textTransform: 'uppercase' }}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="therapy.prepaid.label.balance" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  <FormattedNumber value={prepayment?.amount || 0} />
                </Typography>
              </Stack>
            );
          },
        },
      },
      {
        title: 'action',
        dataIndex: 'unitPrice',
        render: (record) => (
          <>
            {record?.status === PAYMENT_ADVANCE_STATUS.DRAFT.value ? (
              <>
                <EditPrepaymentButton
                  paymentAdvance={record}
                  paymentAdvanceRevalidate={prepaymentRevalidate}
                  prepayment={prepayment}
                />
                <ConfirmPaymentAdvanceButton
                  paymentAdvance={record}
                  prepaymentRevalidate={prepaymentRevalidate}
                  paymentAdvanceRevalidate={prepaymentRevalidate}
                />
                <IconButtonTitle
                  title="delete"
                  size="small"
                  onClick={() =>
                    deletePaymentAdvanceMutate({
                      url: API_SERVER.prepayment.deletePaymentAdvance(record?.id!),
                      method: 'DELETE',
                    })
                  }
                >
                  <DeleteIcon />
                </IconButtonTitle>
              </>
            ) : (
              <PaymentAdvancePrintButton encounter={encounter} paymentAdvance={record} />
            )}
          </>
        ),
        lastCell: {
          style: { background: '#E0ECFF' },
          render: () => {
            return prepayment?.amount && prepayment?.amount > 0 ? (
              <RefundPrepaymentButton
                prepayment={prepayment}
                prepaymentRevalidate={prepaymentRevalidate}
                paymentHistoryRevalidate={() =>
                  mutate(encounter ? API_SERVER.paymentHistory.getList(encounter.paymentId!).url : null)
                }
              />
            ) : null;
          },
        },
      },
    ] as Columns<PaymentAdvance>[];
  }, [deletePaymentAdvanceMutate, encounter, prepayment, prepaymentRevalidate]);
  return (
    <MediCard
      sx={{ marginBottom: '20px', marginTop: '10px', minHeight: '12vh' }}
      title={
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ lineHeight: '30px' }}>
            <FormattedMessage id="prepaid" />
          </Typography>
          <AddPrepaymentButton prepayment={prepayment} paymentAdvanceRevalidate={prepaymentRevalidate} />
        </Stack>
      }
    >
      <TableCustom
        boxProps={{ flex: 1 }}
        dataSource={prepayment?.paymentAdvances}
        columns={COLUMNS}
        loading={isValidating}
        keyName="id"
      />
    </MediCard>
  );
};

export default Prepayments;
