import PatientDetailSession from 'modules/common/component/Patient/PatientDetailSession';
import { PatientSessionProps } from 'modules/common/component/Patient/type';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { ClockHistory } from 'svg';
import { Box, Stack } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import PatientExaminationHistoryItem from './PatientExaminationHistoryItem';
import { Pagination } from 'modules/common/component/Pagination';

const NoData = () => {
  return (
    <Stack height="400px" justifyContent="center" alignItems="center">
      <Stack sx={{ margin: '0 auto' }} justifyContent="center" alignItems="center" gap="20px">
        <ClockHistory height="80px" />
        <FormattedMessage id="patient.patientDetail.examinationHistory.title.empty" />
      </Stack>
    </Stack>
  );
};

const PatientExaminationHistory = ({ patient }: PatientSessionProps) => {
  const { page, pageSize, paginationProps } = useTablePagination({ defaultPageSize: 5 });
  const { data: encounterPage } = useFetch(
    API_SERVER.encounter.getEncounterHistory({ patientId: patient.id!, page, pageSize }),
  );

  return (
    <PatientDetailSession titleKey="patient.patientDetail.session.title.examinationHistory">
      {encounterPage?.content?.length === 0 ? (
        <NoData />
      ) : (
        <Pagination
          totalElements={encounterPage?.pagination.totalElements || 0}
          paginationProps={{ ...paginationProps, rowsPerPageOptions: [5, 10] }}
        >
          <Box sx={{ minHeight: '200px' }}>
            {encounterPage?.content.map((ele) => (
              <PatientExaminationHistoryItem encounter={ele} key={ele.id} />
            ))}
          </Box>
        </Pagination>
      )}
    </PatientDetailSession>
  );
};

export default PatientExaminationHistory;
