import { Pagination } from '@mui/material';
import { TablePaginationActionsProps } from '@mui/material/TablePagination/TablePaginationActions';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';

function TablePaginationActionsCustom(props: TablePaginationActionsProps) {
  const { count, page, rowsPerPage, onPageChange } = props;
  const [pageTmp, setPage] = useState(page);
  const [countTmp, setCount] = useState(Math.ceil(count / rowsPerPage));

  const onChangeDebounce = debounce(
    (e, value) => {
      onPageChange(e, value);
    },
    500,
    {
      trailing: true,
      leading: false,
    },
  );

  useEffect(() => {
    if (count) {
      setCount(Math.ceil(count / rowsPerPage));
    }
  }, [count, rowsPerPage]);

  useEffect(() => {
    setPage(page);
  }, [page]);

  return (
    <>
      <Pagination
        page={pageTmp + 1}
        count={countTmp}
        onChange={(e, page) => {
          setPage(page - 1);
          onChangeDebounce(e as any, page - 1);
        }}
        variant="outlined"
        shape="rounded"
        siblingCount={0}
      />
    </>
  );
}
export default TablePaginationActionsCustom;
