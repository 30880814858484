import { Box, Divider, FormControl, InputLabel, Paper, Skeleton } from '@mui/material';
import SunEditor from 'suneditor-react';
import { SunEditorReactProps } from 'suneditor-react/dist/types/SunEditorReactProps';
import React, { forwardRef, memo, useRef } from 'react';
import './styles.scss';

export function extractHTMLContent(html) {
  if (!html) {
    return null;
  }
  return new DOMParser().parseFromString(html, 'text/html').documentElement.textContent;
}

export interface TextEditorElementProps extends Omit<SunEditorReactProps, 'defaultValue' | 'onChange'> {
  readOnly?: boolean;
  label?: React.ReactNode;
  required?: boolean;
  className?: string;
  value?: string;
  onChange?: (value: string) => void;
  error?: boolean;
  name?: string;
  placeholder?: string;
  height?: string;
  isSubmitting?: boolean;
  fullOption?: boolean;
}

interface Props extends TextEditorElementProps {}

const TextEditorElement = forwardRef((props: Props, ref: any) => {
  const {
    name,
    error,
    readOnly,
    required,
    label,
    className,
    disable,
    value,
    onChange,
    height = '243',
    isSubmitting,
    onFocus,
    onBlur,
    placeholder,
    fullOption,
    ...rest
  } = props;
  const [loading, setLoading] = React.useState<boolean>(true);
  const editor = useRef(null);
  const getEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
    setLoading(false);
  };

  return (
    <FormControl
      error={!!error}
      className={!!error ? `${className} MuiFormControl-error` : className}
      fullWidth
      style={{ fontSize: 14 }}
    >
      {label && (
        <InputLabel required={required} shrink>
          {label}
        </InputLabel>
      )}
      {loading && (
        <Paper
          variant="outlined"
          style={{
            width: '100%',
            borderRadius: 0,
            height: height || 360,
          }}
        >
          <Box padding={1} gap={1} display={'flex'}>
            {Array(8)
              .fill(0)
              .map((_, idx) => (
                <Skeleton
                  key={idx}
                  variant="rectangular"
                  style={{
                    height: 24,
                    flex: 1,
                  }}
                />
              ))}
          </Box>
          <Divider />
          <Box padding={1} height="100%">
            <Skeleton
              variant="rectangular"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
        </Paper>
      )}
      <Box display={loading ? 'none' : ''}>
        <SunEditor
          {...rest}
          name={name}
          placeholder={placeholder}
          readOnly={disable || readOnly}
          height={height}
          setContents={value}
          onChange={(text) => {
            onChange && onChange(text);
          }}
          onFocus={(...event) => {
            onFocus && onFocus(...event);
          }}
          onBlur={(...event) => {
            onBlur && onBlur(...event);
          }}
          getSunEditorInstance={getEditorInstance}
          setDefaultStyle={'font-family:Roboto,Helvetica,Arial,sans-serif; font-size:16px'}
          setOptions={{
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              ['paragraphStyle', 'blockquote'],
              ['bold', 'underline', 'italic', 'strike'],
              ['fontColor', 'hiliteColor', 'textStyle'],
              ['outdent', 'indent'],
              ['align', 'horizontalRule', 'list', 'lineHeight'],
              ['table', 'link', 'image', 'video', 'audio'],
              ['fullScreen', 'showBlocks'],
              ['preview', 'print', 'save'],
            ],
          }}
        />
      </Box>
    </FormControl>
  );
});

export default memo(TextEditorElement);
