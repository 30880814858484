import { AssignmentTurnedIn, Edit } from '@mui/icons-material';
import { Box, Checkbox, Typography } from '@mui/material';
import { push } from 'connected-react-router';
import { API_SERVER } from 'modules/common/api';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, INDICATION_REQUEST_STATUS } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useUpdateMutate } from 'modules/common/hook/useMutate';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import { formatDateFilterField } from 'modules/common/utils';
import { ROUTES } from 'modules/layout/router';
import { DentalExam, IndicationRequest } from 'modules/schema';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { mutate } from 'swr';
import EditIndicationLab from './EditIndicationLab';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';

interface Props {
  dentalExam: DentalExam;
  selectedIndications: IndicationRequest[];
  setSelectedIndications: (indications: IndicationRequest[]) => void;
}

const IndicationTable = ({ dentalExam, setSelectedIndications, selectedIndications }: Props) => {
  const { dispatch } = useGeneralHook();
  const { params } = usePaginationHook();
  const [editIndicationLabDialogOpen, setEditIndicationLabDialogOpen] = useState(false);
  const [selectedIndicationRequestId, setSelectedIndicationRequestId] = useState<number | null>(null);

  const {
    data: indicationRequests,
    revalidate,
    isValidating,
  } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(
      dentalExam?.id!,
      'DENTAL_EXAM',
      'LABORATORY_SERVICE',
    ),
  );

  useEffect(() => {
    if (indicationRequests) {
      setSelectedIndications(
        indicationRequests.filter((indication) => indication.status === INDICATION_REQUEST_STATUS.FINISHED.value),
      );
    }
  }, [indicationRequests, setSelectedIndications]);

  const onCompleteIndicationMutate = useUpdateMutate({
    confirmMessage: 'dental.dentalDetail.indicationTable.completeIndication.message',
    onSuccess: () => {
      revalidate();
      mutate(
        API_SERVER.dentalExam.getList(
          formatDateFilterField({ ...params, loadIndicationRequestQuantity: 'true' }, 'fromDate', 'toDate'),
        ).url,
      );
    },
  });

  const COLUMNS: Columns<IndicationRequest>[] = [
    {
      title: 'labDoctor.testName',
      dataIndex: 'serviceName',
    },
    {
      title: 'indicationDate',
      render: (record) => (
        <Typography>
          {record.createdTime && moment(record.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
        </Typography>
      ),
    },
    {
      title: 'doctorOfIndication',
      dataIndex: 'picName',
    },
    {
      title: 'interpreterOfIndication',
      dataIndex: 'executeName',
    },
    {
      title: 'status',
      dataIndex: 'status',
      render: (record) => (
        <Box component="span" color={INDICATION_REQUEST_STATUS[record.status!].color}>
          <FormattedMessage id={`imageIndication.status.${record.status}`} />
        </Box>
      ),
    },
    {
      title: 'action',
      width: 100,
      align: 'center',
      render: (record) => (
        <>
          <IconButtonTitle
            size="small"
            title="update"
            onClick={(e) => {
              e.stopPropagation();
              setEditIndicationLabDialogOpen(true);
              setSelectedIndicationRequestId(record?.id!);
            }}
          >
            <Edit />
          </IconButtonTitle>{' '}
          <IconButtonTitle
            title="complete"
            onClick={(e) => {
              e.stopPropagation();
              onCompleteIndicationMutate({
                url: API_SERVER.indicationRequest.finishIndicationRequest(record.id!),
                method: 'put',
              });
            }}
            disabled={record.status === INDICATION_REQUEST_STATUS.FINISHED.value}
          >
            <AssignmentTurnedIn />
          </IconButtonTitle>
        </>
      ),
    },
  ];

  return (
    <Box margin={1} bgcolor="white">
      <TableCustom
        loading={isValidating}
        dataSource={indicationRequests?.filter(
          (indication) => indication.status !== INDICATION_REQUEST_STATUS.WAIT.value,
        )}
        containerProps={{
          sx: {
            '& th,tr,td': {
              padding: '4px 8px',
              backgroundColor: 'unset',
              '&:hover': {
                backgroundColor: 'unset',
              },
            },
            overflow: 'visible',
            minWidth: 220,
          },
        }}
        rowProps={(record) => ({
          onClick: async (e) => {
            dispatch(push(ROUTES.detailIndicationDental.gen(record.id + '', record.referenceId + '')));
          },
          style: { cursor: 'pointer' },
        })}
        rowSelection={{
          selectedRowKeys: selectedIndications,
          hidden: (record: IndicationRequest) => record.status !== INDICATION_REQUEST_STATUS.FINISHED.value,
          onChange: ({ selectedRows }) => setSelectedIndications(selectedRows),
          render: ({ record, selected, onChange }) =>
            record.status === INDICATION_REQUEST_STATUS.FINISHED.value && (
              <Checkbox
                style={{ padding: 0 }}
                checked={selected}
                color="primary"
                onChange={(e) => onChange(e.target.checked)}
              />
            ),
        }}
        columns={COLUMNS}
      />
      {selectedIndicationRequestId && (
        <EditIndicationLab
          open={editIndicationLabDialogOpen}
          onClose={() => setEditIndicationLabDialogOpen(false)}
          dentalExam={dentalExam}
          indicationRequestId={selectedIndicationRequestId}
        />
      )}
    </Box>
  );
};

export default IndicationTable;
