import { some } from '../../../../../common/constants';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import TextValue from './TextValue';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';

interface Props {
  therapy: some;
}
const TherapyExtraInfo = ({ therapy }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box flex={1.5}>
        <TextValue
          label="therapyList.header.label.diagnosis"
          value={extractHTMLContent(therapy.diagnosis) || ''}
          labelWidth="190px"
        />
        <TextValue
          label="therapy.therapyDetail.indicationRequest.printForm.timeRequest.title"
          value=""
          labelWidth="190px"
        />
      </Box>
    </Stack>
  );
};
export default TherapyExtraInfo;
