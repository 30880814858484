import PrintIcon from '@mui/icons-material/Print';
import { Box, Button, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import SchemaForm from 'modules/common/SchemaForm';
import TableList from './TableList';
import { Encounter, IndicationRequest, Medication } from 'modules/schema';
import { useFetch, useCreateMutate, useDeleteMutate, useDialog, useUpdateMutate } from 'modules/common/hook';
import { MEDICATION_CATEGORY_SCOPE, MEDICATION_USAGE_TYPE } from 'modules/common/apiConstants';
import { API_SERVER } from 'modules/common/api';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { keyBy } from 'lodash';
import DrugUsagePrintDialog from './DrugUsagePrintDialog';
import NoDataBox from 'modules/common/component/NoDataBox';
import AddDrugUsageDialog from './AddDrugUsageDialog';
import { GroupOption, Option } from 'modules/common/type';
import { NumericFormatText } from 'modules/common/utils';
import { PAYMENT_PLAN } from 'modules/common/constants';

interface Props {
  encounter?: Encounter;
  editable?: boolean;
  indicationRequest: IndicationRequest;
}

const DrugUsageContent: React.FunctionComponent<Props> = ({ encounter, editable, indicationRequest }) => {
  const { intl, confirmDialog } = useGeneralHook();
  const [openPrintDialog, setOpenPrintDialog] = React.useState(false);
  const [openAddDrugDialog, onOpenAddDrugDialog, onCloseAddDrugDialog] = useDialog();
  const [selectedMedication, setSelectedMedication] = React.useState<Medication | undefined>(undefined);

  const { data: medicationCategories } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({ scope: MEDICATION_CATEGORY_SCOPE.CLINIC }),
  );
  const { data: indicationMedicationUsage, revalidate: indicationMedicationRevalidate } = useFetch(
    API_SERVER.medicationUsage.getList(encounter?.id || 0, {
      type: MEDICATION_USAGE_TYPE.ENCOUNTER,
      indicationRequestId: indicationRequest.id,
    }),
    { enabled: encounter?.id && indicationRequest.id },
  );

  const mappedMedicationCategory = React.useMemo(() => {
    return medicationCategories?.map((medicationGroup) => ({
      value: medicationGroup.id,
      label: medicationGroup.name,
      medications: medicationGroup?.medicationList?.map((medication) => ({
        value: medication?.id,
        label: `${medication?.name}(${medication?.ingredient} ${medication?.dose})`,
        group: medicationGroup.name,
      })),
    }));
  }, [medicationCategories]);

  const onUpdateIndicationMedicationMutate = useUpdateMutate({ onSuccess: indicationMedicationRevalidate });
  const onUpdateIndicationMedication = React.useCallback(
    async (value) => {
      const data = {
        ...value,
        medicalSuppliesId: value?.medicationId,
      };

      onUpdateIndicationMedicationMutate({
        url: API_SERVER.medicationUsage.update(value?.id),
        method: 'put',
        data: data,
      });
    },
    [onUpdateIndicationMedicationMutate],
  );

  const onDeleteIndicationMedicationMutate = useDeleteMutate({ onSuccess: indicationMedicationRevalidate });
  const onDeleteIndicationMedication = React.useCallback(
    async (indicationMedication) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: indicationMedication?.medicationName }),
      });
      if (confirm) {
        await onDeleteIndicationMedicationMutate({
          url: API_SERVER.medicationUsage.delete(indicationMedication?.id),
          method: 'delete',
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, onDeleteIndicationMedicationMutate],
  );

  const isSelectedMedication = React.useCallback(
    (option) => {
      return !!indicationMedicationUsage?.find((ele) => parseInt(option?.value) === ele?.medicationId);
    },
    [indicationMedicationUsage],
  );

  const onAddMedicationUsageMutate = useCreateMutate({
    onSuccess: () => {
      indicationMedicationRevalidate();
      onCloseAddDrugDialog();
    },
  });

  const medicationCategoriesOptions = React.useMemo<(Option & { medications: (GroupOption & Medication)[] })[]>(
    () =>
      medicationCategories?.map((category) => ({
        value: category.id!,
        label: category.name!,
        medications:
          category?.medicationList?.map((medication) => ({
            value: medication?.id!,
            label: medication?.name!,
            group: category?.name!,
            ...medication,
          })) || [],
      })) || [],
    [medicationCategories],
  );

  const allMedications = React.useMemo<(GroupOption & Medication & { categoryName: string })[]>(
    () =>
      medicationCategoriesOptions?.reduce<(GroupOption & Medication & { categoryName: string })[]>(
        (arr, category) => [
          ...arr,
          ...category?.medications?.map((medication) => ({ ...medication, categoryName: category?.label })),
        ],
        [],
      ) || [],
    [medicationCategoriesOptions],
  );
  const medicationMap = React.useMemo(() => keyBy(allMedications, 'id'), [allMedications]);

  const totalPrice = React.useMemo(() => {
    return indicationMedicationUsage?.reduce((sum, medicationUsage) => {
      // Nếu thuốc sử dụng là miễn phí hoặc đã được trả phí thì không tính
      if (medicationUsage?.isFree || medicationUsage?.isPaid) return sum;
      return (
        sum +
        (medicationUsage?.medication?.basePrice || 0) *
          ((medicationUsage.quantity || 0) - (medicationUsage.cancellationQuantity || 0))
      );
    }, 0);
  }, [indicationMedicationUsage]);

  const totalPatientPay = React.useMemo(() => {
    return indicationMedicationUsage?.reduce((sum, medicationUsage) => {
      // Nếu thuốc sử dụng là miễn phí hoặc đã được trả phí thì không tính
      if (medicationUsage?.isFree || medicationUsage?.isPaid) return sum;
      return (
        sum +
        (medicationUsage?.medication?.basePrice || 0) *
          ((medicationUsage.quantity || 0) - (medicationUsage.cancellationQuantity || 0))
      );
    }, 0);
  }, [indicationMedicationUsage]);

  return (
    <Box>
      {editable && (
        <Box marginBottom={2}>
          <SchemaForm
            hideSubmitButton
            schema={{
              fields: ({ formProps, valuesField }) => {
                const { intl } = formProps;
                const medicationOptions = valuesField?.medicationCategory
                  ? valuesField?.medicationCategory?.medications
                  : allMedications;
                return {
                  group: {
                    type: 'auto-complete',
                    label: intl.formatMessage({ id: 'drugUsage.groupDrug' }),
                    placeholder: intl.formatMessage({ id: 'drugUsage.groupDrugEnter' }),
                    noHelperText: true,
                    propsWrapper: { xs: 4 },
                    options: mappedMedicationCategory,
                  },
                  name: {
                    type: 'auto-complete',
                    label: intl.formatMessage({ id: 'encounterPrescription.drugName' }),
                    placeholder: intl.formatMessage({ id: 'encounterPrescription.drugNameEnter' }),
                    noHelperText: true,
                    propsWrapper: { xs: 8 },
                    options: medicationOptions,
                    groupBy: (option) => option.group,
                    onChange: (option) => {
                      if (option) {
                        onOpenAddDrugDialog();
                        setSelectedMedication(option);
                      }
                    },
                    getOptionDisabled: isSelectedMedication,
                    disableCloseOnSelect: true,
                  },
                };
              },
            }}
          />
        </Box>
      )}

      {indicationMedicationUsage && indicationMedicationUsage.length !== 0 ? (
        <TableList
          readOnly={editable}
          indicationMedications={indicationMedicationUsage}
          onUpdateIndicationMedication={onUpdateIndicationMedication}
          onDeleteIndicationMedication={onDeleteIndicationMedication}
          medicationMap={medicationMap}
          encounter={encounter}
        />
      ) : (
        <NoDataBox />
      )}
      {indicationMedicationUsage && indicationMedicationUsage.length > 0 && (
        <Box
          sx={{
            paddingBottom: 2,
            position: 'sticky',
            bottom: 0,
            bgcolor: 'background.paper',
            zIndex: 2,
            display: 'block',
          }}
        >
          <Grid container spacing={2} justifyContent={'flex-end'} style={{ marginTop: 0 }}>
            <Grid item xs={8} />
            <Grid item xs={4} justifyContent="space-between" display="flex">
              <Typography variant="body1">
                <FormattedMessage id="drugUsage.totalPrice" />
              </Typography>
              <Typography variant="subtitle1">{NumericFormatText(totalPrice)}</Typography>
            </Grid>
            <Grid item xs={8} />
            <Grid item xs={4} justifyContent="space-between" display="flex">
              <Typography variant="body1">
                <FormattedMessage id="drugUsage.patientPrice" />
              </Typography>
              <Typography variant="subtitle1">
                {NumericFormatText(encounter?.paymentPlan === PAYMENT_PLAN.FREE.value ? 0 : totalPatientPay)}
              </Typography>
            </Grid>
            <Grid item xs={2} justifyContent="flex-end" display="flex">
              <Button
                startIcon={<PrintIcon />}
                variant="contained"
                color="inherit"
                style={{ width: 180 }}
                onClick={() => {
                  setOpenPrintDialog(true);
                }}
              >
                <FormattedMessage id="drugUsage.print" />
              </Button>
            </Grid>
          </Grid>
        </Box>
      )}
      <DrugUsagePrintDialog
        open={openPrintDialog}
        encounter={encounter && encounter}
        onClose={() => {
          setOpenPrintDialog(false);
        }}
        indicationMedications={indicationMedicationUsage || []}
      />
      {selectedMedication && (
        <AddDrugUsageDialog
          open={openAddDrugDialog}
          medication={selectedMedication}
          onSubmit={(data) =>
            onAddMedicationUsageMutate({
              url: API_SERVER.medicationUsage.create(),
              method: 'POST',
              data: {
                medicationId: selectedMedication?.id,
                quantity: 1,
                isFree: true,
                referenceId: encounter?.id,
                type: MEDICATION_USAGE_TYPE.ENCOUNTER,
                indicationRequestId: indicationRequest.id,
                intendedRoute: data?.intendedRoute?.value,
                cancellationQuantity: 0,
              },
            })
          }
          onClose={onCloseAddDrugDialog}
        />
      )}
    </Box>
  );
};

export default DrugUsageContent;
