import { Box, Button } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import { useDeleteMutate, useFetch } from 'modules/common/hook';
import { STORAGE_RESOURCE_TYPE } from 'modules/common/apiConstants';
import { AttachedDocument, Encounter, StorageResource } from 'modules/schema';
import { isEmpty } from 'lodash';
import DocumentBox from 'modules/common/component/Encounter/block/Attachment/DocumentBox';
import { Add } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { MAX_FILE_SIZE } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useCallback, useState } from 'react';
import useUploadFile from 'modules/common/hook/useUploadFile';
import NoDataBox from 'modules/common/component/NoDataBox';

interface Props {
  encounter: Encounter;
  readOnly: boolean;
}

const AttachmentBlock = ({ encounter }: Props) => {
  const { openNotify, confirmDialog, appState, intl } = useGeneralHook();
  const [key, setKey] = useState(false);
  const userId = appState.authen.user?.user_id;

  // get files
  const { data: dataFiles, revalidate: revalidateFiles } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.ENCOUNTER_ATTACHED_DOCUMENT,
      referenceId: encounter?.id,
    }),
    { enabled: encounter?.id },
  );

  // upload file
  const uploadFileToDoc = useUploadFile(revalidateFiles);

  // delete file
  const deleteFileMutate = useDeleteMutate({ onSuccess: revalidateFiles });
  const deleteFile = useCallback(
    async (doc: AttachedDocument) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: doc.name }),
      });
      if (confirm && doc.id) {
        await deleteFileMutate({
          url: API_SERVER.storageResource.delete(doc.id),
          method: 'DELETE',
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, deleteFileMutate, intl],
  );

  return (
    <Box display="flex" flexDirection="column" gap={1}>
      <Box style={{ gap: 16, display: 'flex', flexDirection: 'column' }}>
        {!isEmpty(dataFiles) ? (
          dataFiles?.map((fileData: StorageResource) => (
            <DocumentBox
              key={fileData?.id}
              data={fileData}
              onDeleteFile={() => deleteFile(fileData)}
              enableDelete={userId === encounter?.picUserId}
            />
          ))
        ) : (
          <NoDataBox />
        )}
        {userId === encounter?.picUserId && (
          <Button startIcon={<Add />} component="label" variant="text">
            <FormattedMessage id="attachedDocument.addFile" />
            <input
              key={`${key}`}
              accept={'.doc,.docx,application/pdf,image/*,.svg'}
              hidden
              type="file"
              multiple
              onChange={(file) => {
                const files = file.target.files;
                if (files && Array.from(files).every((file) => file.size <= MAX_FILE_SIZE)) {
                  uploadFileToDoc({
                    docRefId: encounter?.id!,
                    type: STORAGE_RESOURCE_TYPE.ENCOUNTER_ATTACHED_DOCUMENT,
                    file: files[0],
                  });
                } else {
                  openNotify({ message: 'attachedDocument.fileValidSize' });
                }
                setKey((value) => !value);
              }}
            />
          </Button>
        )}
      </Box>
    </Box>
  );
};
export default AttachmentBlock;
