import { Box, Button, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import MediCard from 'modules/common/component/MediCard';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { some } from 'modules/common/constants';
import { NumericFormatText } from 'modules/common/utils';
import { Service } from 'modules/schema';
import { isEmpty } from 'lodash';
interface Props {
  data?: some;
  setFormData: (item: some) => void;
  setFormDataGroup: (item: some) => void;
  onDelete: (item: some) => void;
  onDeleteGroup: (item: some) => void;
  onDeleteServices: (services: Service[]) => void;
  loading?: boolean;
  enableDeleteGroup?: boolean;
}

const SupplyTable: React.FunctionComponent<Props> = (props) => {
  const { data, setFormData, setFormDataGroup, onDelete, onDeleteGroup, onDeleteServices, enableDeleteGroup, loading } =
    props;
  const [selection, setSelection] = React.useState<Service[]>([]);

  const columns = React.useMemo(() => {
    return [
      {
        title: 'supply.code',
        dataIndex: 'code',
        width: '8%',
      },
      {
        title: 'supply.name',
        dataIndex: 'name',
        width: '8%',
      },
      {
        title: 'supply.commercialName',
        dataIndex: 'commercialName',
        width: '8%',
      },
      {
        title: 'supply.unit',
        dataIndex: 'unit',
      },
      {
        title: 'supply.codeGroupShort',
        dataIndex: 'groupCode',
      },
      {
        title: 'departmentCode',
        dataIndex: 'specializedDepartment.code',
      },
      {
        title: 'supply.packagingFormatShort',
        dataIndex: 'packageMethod',
      },
      {
        title: 'supply.bid',
        dataIndex: 'winningBidDecision',
      },
      {
        title: 'supply.cost',
        dataIndex: 'purchasePrice',
        render: (record) => record.purchasePrice && NumericFormatText(record.purchasePrice),
      },
      {
        title: 'supply.priceSupply',
        dataIndex: 'supplyPrice',
        render: (record) => record.supplyPrice && NumericFormatText(record.supplyPrice),
      },
      {
        title: 'supply.limitPrice',
        dataIndex: 'maximumPrice',
        render: (record) => record.maximumPrice && NumericFormatText(record.maximumPrice),
      },
      {
        title: 'action',
        width: 120,
        render: (record) => {
          return (
            <>
              <IconButton
                title="update"
                size="small"
                onClick={() => {
                  setFormData({ ...record, listId: data?.id });
                }}
                style={{ margin: '0px 8px' }}
              >
                <EditIcon color={record.validation === false ? 'error' : undefined} />
              </IconButton>
              <IconButton
                title="delete"
                size="small"
                onClick={() => {
                  onDelete({ ...record, listId: data?.id });
                }}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
      },
    ] as Columns[];
  }, [setFormData, data?.id, onDelete]);

  if (!data) {
    return null;
  }

  const funcDeleteServices = () => {
    onDeleteServices(selection);
    setSelection([]);
  };

  return (
    <MediCard
      style={{ margin: '8px 0px', color: 'white' }}
      title={
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="subtitle1" color="inherit">
              {data.name}
            </Typography>
          </Box>
          <Box display="flex">
            <IconButton title="create" size="small" color="inherit" onClick={() => setFormData({ listId: data.id })}>
              <NoteAddIcon />
            </IconButton>
            <IconButton
              title="update"
              size="small"
              color="inherit"
              onClick={() => setFormDataGroup(data)}
              style={{ margin: '0px 8px' }}
            >
              <EditIcon />
            </IconButton>
            <IconButton
              title="delete"
              size="small"
              color="inherit"
              disabled={!!data.items?.length && !enableDeleteGroup && isEmpty(selection)}
              onClick={() => {
                !isEmpty(selection) ? funcDeleteServices() : onDeleteGroup(data);
              }}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Box>
      }
    >
      <Box padding={1}>
        <TableCustom
          dataSource={data?.items}
          loading={loading}
          columns={columns}
          rowSelection={{
            selectedRowKeys: selection,
            onChange: ({ selectedRows }) => setSelection(selectedRows),
          }}
          caption={
            <Button
              size="large"
              fullWidth
              variant="text"
              startIcon={<NoteAddIcon />}
              color="primary"
              onClick={() => setFormData({ listId: data.id })}
            >
              <Typography variant="subtitle2">
                <FormattedMessage id="supply.addNew" />
              </Typography>
            </Button>
          }
        />
      </Box>
    </MediCard>
  );
};

export default SupplyTable;
