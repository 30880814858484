import { useCallback, useState } from 'react';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { axiosThunk } from 'modules/common/redux/axios';
import fileDownload from 'js-file-download';
import { AxiosError } from 'axios';

export const useDownloadFile = (url: string, fileName?: string) => {
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  return useCallback(() => {
    openSnackbar({ message: intl.formatMessage({ id: 'common.message.processing' }) });
    return dispatch(
      axiosThunk({
        url,
        method: 'get',
        responseType: 'blob',
      }),
    )
      .then((response) => {
        let headerFileName = response.headers['content-disposition']?.split('filename=')[1]?.split(';')[0];
        const effectiveFileName = fileName || headerFileName;
        fileDownload(response.data, effectiveFileName);
      })
      .catch((e: AxiosError) => {
        openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
      });
  }, [dispatch, fileName, intl, openSnackbar, url]);
};

export const useDownloadFileLoading = (url: string, fileName?: string) => {
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const [loading, setLoading] = useState(false);

  const downloadFile = useCallback(() => {
    setLoading(true);
    openSnackbar({ message: intl.formatMessage({ id: 'common.message.processing' }) });
    return dispatch(
      axiosThunk({
        url,
        method: 'get',
        responseType: 'blob',
      }),
    )
      .then((response) => {
        let headerFileName = response.headers['content-disposition']?.split('filename=')[1]?.split(';')[0];
        const effectiveFileName = fileName || headerFileName;
        fileDownload(response.data, effectiveFileName);
        setLoading(false);
      })
      .catch((e: AxiosError) => {
        setLoading(false);
        openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
      });
  }, [dispatch, fileName, intl, openSnackbar, url]);

  return { downloadFile, loading };
};
