import styled from '@emotion/styled';
import { Print } from '@mui/icons-material';
import { Box, Button, Divider, Stack, Typography } from '@mui/material';
import { GENDER } from 'modules/common/apiConstants';
import DialogCustom from 'modules/common/component/DialogCustom';
import PrintFormHeader from 'modules/common/component/PrintForm/PrintFormHeader';
import PrintFormTitle from 'modules/common/component/PrintForm/PrintFormTitle';
import TextValue from 'modules/common/component/PrintForm/TextValue';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { useDialog } from 'modules/common/hook';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { capitalizeFirstLetter, numericFormat } from 'modules/common/utils';
import { Encounter, PaymentDetail } from 'modules/schema';
import moment from 'moment';
import { useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { AppState } from 'redux/reducer';
import writtenNumber from 'written-number';
import { caculatePrice } from './utils';
import { keyBy } from 'lodash';

const LineWrapperStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItem: 'center',
});

const TextStyled = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.1px',
});

const LabelStyled = styled(TextStyled)({
  color: '#455A64',
});

const ValueStyled = styled(TextStyled)({
  color: '#263238',
});

const DividerStyled = styled(Divider)({
  marginTop: '10px',
  marginBottom: '10px',
});

const TitleStyled = styled(Typography)({
  color: '#546E7A',
  fontFamily: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  marginTop: '15px',
  marginBottom: '5px',
  backgroundColor: 'transparent',
});

interface Props {
  encounter?: Encounter;
  paymentDetails?: PaymentDetail[];
}

const SummaryPaymentPrintButton = ({ encounter, paymentDetails }: Props) => {
  const printContentRef = useRef(null);
  const { intl, appState } = useGeneralHook();
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const { locale } = useSelector((state: AppState) => state.intl);
  const calculatePriceMap = keyBy(
    paymentDetails?.map((paymentDetail) => ({
      id: paymentDetail?.id,
      ...caculatePrice(paymentDetail?.quantity!, encounter?.payment?.discountPercent!, {
        paymentDetailId: paymentDetail?.id!,
        checked: true,
        discount: paymentDetail?.discount!,
        discountType: paymentDetail?.discountType!,
        unitPrice: paymentDetail?.unitPrice!,
        vat: paymentDetail?.vat!,
        status: paymentDetail?.status!,
      }),
    })),
    'id',
  );
  const COLUMNS = useMemo(
    () =>
      [
        {
          dataIndex: 'content',
          title: 'therapy.payment.summaryPrintForm.title.paymentContent',
          default: true,
        },
        {
          dataIndex: 'unit',
          title: 'therapy.payment.summaryPrintForm.title.paymentUnit',
          default: true,
          render: (record) => (record?.unit ? record.unit : intl.formatMessage({ id: 'cashier.time' })),
        },
        {
          dataIndex: 'quantity',
          title: 'therapy.payment.summaryPrintForm.title.paymentQuantity',
          default: true,
        },
        {
          dataIndex: 'totalAmount',
          title: 'therapy.payment.summaryPrintForm.title.paymentTotalPrice',
          render: (record) => numericFormat(record?.totalAmount || 0),
          default: true,
        },
        {
          dataIndex: 'discount',
          title: 'therapy.payment.summaryPrintForm.title.paymentDiscount',
          render: (record) => numericFormat(calculatePriceMap[record?.id!]?.finalDiscountAmount || 0),
          default: true,
        },
        {
          dataIndex: 'vat',
          title: 'therapy.payment.summaryPrintForm.title.paymentVAT',
          default: true,
        },
        {
          dataIndex: 'amountPaid',
          title: 'therapy.payment.summaryPrintForm.title.paymentCustomerPay',
          render: (record) => numericFormat(record?.amountPaid || 0),
          default: true,
        },
      ] as Columns<PaymentDetail>[],
    [calculatePriceMap, intl],
  );
  const totalPrice = paymentDetails?.reduce((sum, payment) => sum + (payment?.totalAmount || 0), 0) || 0;
  const summaryDiscount =
    Object.values(calculatePriceMap)?.reduce((sum, payment) => sum + (payment?.finalDiscountAmount || 0), 0) || 0;
  const summaryVAT = paymentDetails?.reduce((sum, payment) => sum + (payment?.vatAmount || 0), 0) || 0;
  const customerPay = totalPrice - summaryDiscount + summaryVAT;
  return (
    <>
      <Button variant="contained" color="inherit" onClick={onOpenDialog} startIcon={<Print />}>
        <FormattedMessage id="therapy.payment.printForm.label.printButton" />
      </Button>
      <DialogCustom
        open={openDialog}
        onClose={onCloseDialog}
        title="cashier.printTableBill.title"
        PaperProps={{
          style: { width: '1092px' },
        }}
        maxWidth="xl"
      >
        <Box padding={2}>
          <Box ref={printContentRef}>
            <PrintFormHeader />
            <Box sx={{ padding: '9px 24px 48px 24px' }}>
              <Stack marginBottom={3}>
                <PrintFormTitle titleKey="cashier.printTableBill.title" />
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Box flex={1.5}>
                  <TextValue
                    label="therapy.label.customer.code"
                    value={encounter?.patient?.formatCode}
                    labelWidth="120px"
                  />
                  <TextValue label="name" value={encounter?.patient?.name} labelWidth="120px" />
                </Box>
                <Box flex={1}>
                  <TextValue
                    label="birthdayShort"
                    value={moment(encounter?.patient?.dob, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
                    labelWidth="80px"
                  />
                  <TextValue
                    label="gender"
                    value={GENDER[encounter?.patient?.gender!]?.label || ' '}
                    localizationValue
                    labelWidth="80px"
                  />
                </Box>
                <Box flex={1} paddingLeft="auto">
                  <TextValue label="address" value={encounter?.patient?.homeAddress?.address} />
                  <TextValue label="telephone" value={encounter?.patient?.mobilePhone} />
                </Box>
              </Stack>
              <DividerStyled />
              <Stack direction="row" justifyContent="space-between">
                <Box flex={1.5}>
                  <TextValue label="cashierPrint.encounterId" value={encounter?.code} labelWidth="110px" />
                  <TextValue
                    label="dental.register.label.serviceName"
                    value={encounter?.serviceName}
                    labelWidth="110px"
                  />
                </Box>
              </Stack>
              <DividerStyled />
              <Box>
                <TitleStyled>
                  <FormattedMessage id="therapy.payment.summaryPrintForm.title.therapyPayment" />
                </TitleStyled>
                <TableCustom
                  containerProps={{
                    sx: {
                      '& th,tr,td': {
                        padding: '2px 8px',
                        backgroundColor: 'white',
                        border: '1px solid #000',
                        color: 'unset',
                      },
                      width: '100%',
                      overflow: 'visible',
                      marginTop: '5px',
                    },
                  }}
                  dataSource={paymentDetails}
                  columns={COLUMNS}
                />
              </Box>
              <Box maxWidth="50%" marginLeft="auto">
                <LineWrapperStyled paddingTop="16px">
                  <LabelStyled>
                    <FormattedMessage id="printForm.finalPrice" />
                  </LabelStyled>
                  <ValueStyled>{numericFormat(totalPrice)}</ValueStyled>
                </LineWrapperStyled>
                <LineWrapperStyled>
                  <LabelStyled>
                    <FormattedMessage id="therapy.payment.summaryPrintForm.title.summaryDiscount" />
                  </LabelStyled>
                  <ValueStyled>{numericFormat(summaryDiscount)}</ValueStyled>
                </LineWrapperStyled>
                <LineWrapperStyled>
                  <LabelStyled>
                    <FormattedMessage id="therapy.payment.summaryPrintForm.title.summaryPriceBeforeVAT" />
                  </LabelStyled>
                  <ValueStyled>{numericFormat(totalPrice - summaryDiscount)}</ValueStyled>
                </LineWrapperStyled>
                <LineWrapperStyled>
                  <LabelStyled>
                    <FormattedMessage id="therapy.payment.summaryPrintForm.title.summaryVAT" />
                  </LabelStyled>
                  <ValueStyled>{numericFormat(summaryVAT)}</ValueStyled>
                </LineWrapperStyled>
                <DividerStyled />
                <LineWrapperStyled>
                  <LabelStyled sx={{ color: '#0052E0' }}>
                    <FormattedMessage id="therapy.payment.summaryPrintForm.title.customerPay" />
                  </LabelStyled>
                  <ValueStyled>{numericFormat(customerPay)}</ValueStyled>
                </LineWrapperStyled>
                <LineWrapperStyled gap="10px">
                  <LabelStyled sx={{ whiteSpace: 'pre' }}>
                    <FormattedMessage id="therapy.payment.summaryPrintForm.title.summaryPriceToWord" />
                  </LabelStyled>
                  <ValueStyled>
                    {capitalizeFirstLetter(writtenNumber(customerPay, { lang: locale })) || ''}
                    &nbsp; &nbsp;
                    {appState.common.groupCurrency}
                  </ValueStyled>
                </LineWrapperStyled>
              </Box>
              <Stack marginTop={2}>
                <Stack direction="row" justifyContent="space-between" marginTop={3}>
                  <Box textAlign="center">
                    <Typography variant="body1" fontStyle="italic">
                      &nbsp;
                    </Typography>
                    <Typography variant="h6">
                      <FormattedMessage id="therapy.payment.summaryPrintForm.title.createdPerson" />
                    </Typography>
                    <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                      <FormattedMessage id="medicalPrint.helperText" />
                    </Typography>
                  </Box>
                  <Box textAlign="center">
                    <Typography variant="body1" fontStyle="italic">
                      &nbsp;
                    </Typography>
                    <Typography variant="h6">
                      <FormattedMessage id="therapy.payment.summaryPrintForm.title.confirmCustomer" />
                    </Typography>
                    <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                      <FormattedMessage id="medicalPrint.helperText" />
                    </Typography>
                  </Box>
                  <Box textAlign="center">
                    <Typography variant="body1" fontStyle="italic">
                      <FormattedMessage
                        id="printForm.date"
                        values={{
                          hour: new Date().getHours(),
                          min: new Date().getMinutes(),
                          day: new Date().getDate(),
                          month: new Date().getMonth() + 1,
                          year: new Date().getFullYear(),
                        }}
                      />
                    </Typography>
                    <Typography variant="h6">
                      <FormattedMessage id="therapy.payment.summaryPrintForm.title.accountant" />
                    </Typography>
                    <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                      <FormattedMessage id="medicalPrint.helperText" />
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Box>
          <Stack direction="row" justifyContent="flex-end" gap="20px" sx={{ padding: '10px 24px' }}>
            <Button onClick={onCloseDialog} sx={{ padding: '3px 25px' }} variant="contained" color="inherit">
              <FormattedMessage id="cancel" />
            </Button>
            <ReactToPrint
              trigger={() => (
                <Button sx={{ padding: '3px 15px' }} startIcon={<Print />}>
                  <FormattedMessage id="cashierPrint.print" />
                </Button>
              )}
              content={() => printContentRef.current}
            />
          </Stack>
        </Box>
      </DialogCustom>
    </>
  );
};

export default SummaryPaymentPrintButton;
