import * as React from 'react';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { Box, Grid } from '@mui/material';
import SchemaForm from '../../../../common/SchemaForm';
import { some } from '../../../../common/constants';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import Delete from '@mui/icons-material/Delete';
import { API_SERVER } from '../../../../common/api';
import MedicalSuppliesTableList from './MedicalSuppliesTableList';
import { fetchThunk } from '../../../../common/redux/thunk';
import { setLoading } from '../../../../common/redux/commonReducer';
import { useFetch } from 'modules/common/hook';

interface Props {
  medicalSuppliesList?: some[];
  onUpdateMedicalSupplies: (indication: some) => void;
  onDeleteMedicalSupplies: (indication: some) => void;
  onSelectedMedicalSupplies: (indication: some) => void;
  indicationList?: some[];
  therapySessionId: number;
  onDataLoading?: boolean;
  editable: boolean;
}

const TherapyMedicalSuppliesList: React.FunctionComponent<Props> = (props) => {
  const {
    medicalSuppliesList,
    onUpdateMedicalSupplies,
    onDeleteMedicalSupplies,
    onSelectedMedicalSupplies,
    therapySessionId,
    indicationList,
    editable,
  } = props;
  const { dispatch, intl, openSnackbar, confirmDialog } = useGeneralHook();

  const { data: medicalSuppliesCategoryList } = useFetch(API_SERVER.medicalSupplies.getList());

  const onSelectMedicalSupplies = React.useCallback(
    async (medicalSupplies: some) => {
      if (medicalSupplies) {
        const medicalSuppliesAdd = {
          medicalSuppliesId: medicalSupplies.id,
          isFree: true,
          quantity: 1,
          referenceId: therapySessionId,
          type: 'THERAPY_SESSION',
        };
        try {
          dispatch(setLoading(true));
          const res = await dispatch(
            fetchThunk(
              API_SERVER.therapyMedicalSupplies.create(),
              'post',
              medicalSuppliesAdd,
              'application/json-patch+json',
            ),
          );
          onSelectedMedicalSupplies(res);
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        } catch (e) {
          openSnackbar({
            message: intl.formatMessage({ id: 'createFail' }),
            type: 'error',
          });
        } finally {
          dispatch(setLoading(false));
        }
      }
    },
    [dispatch, intl, onSelectedMedicalSupplies, openSnackbar, therapySessionId],
  );

  const onChangeIsFree = React.useCallback(
    async (supplies: some) => {
      try {
        const res = await dispatch(
          fetchThunk(
            API_SERVER.therapyMedicalSupplies.index(supplies?.id),
            'put',
            supplies,
            'application/json-patch+json',
          ),
        );
        onUpdateMedicalSupplies(res);

        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({
          message: intl.formatMessage({ id: 'updateFail' }),
          type: 'error',
        });
      }
    },
    [dispatch, intl, onUpdateMedicalSupplies, openSnackbar],
  );

  const onDelete = React.useCallback(
    async (supplies: some) => {
      try {
        await dispatch(
          fetchThunk(API_SERVER.therapyMedicalSupplies.index(supplies?.id), 'delete', 'application/json-patch+json'),
        );
        onDeleteMedicalSupplies(supplies);

        openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
      } catch (e) {
        openSnackbar({
          message: intl.formatMessage({ id: 'deleteFail' }),
          type: 'error',
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, onDeleteMedicalSupplies, openSnackbar, intl],
  );

  const updateMedicalSupplies = React.useCallback(
    async (medicalSupplies: some) => {
      try {
        const res = await dispatch(
          fetchThunk(
            API_SERVER.therapyMedicalSupplies.index(medicalSupplies?.id),
            'put',
            medicalSupplies,
            'application/json-patch+json',
          ),
        );
        onUpdateMedicalSupplies(res);
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({
          message: intl.formatMessage({ id: 'updateFail' }),
          type: 'error',
        });
      }
    },
    [dispatch, intl, onUpdateMedicalSupplies, openSnackbar],
  );

  const readOnly = !editable;

  return (
    <Box bgcolor="white">
      <Box padding={1} className="medical-supplies">
        {!readOnly && (
          <Box marginBottom={2} bgcolor={'#F4F8FF'} padding={2}>
            <SchemaForm
              hideSubmitButton
              schema={{
                fields: ({ valuesField, methods }) => {
                  const { setValue } = methods;
                  const getMedicationItemOptions = () => {
                    const arr: any[] = [];
                    let categoryList: any[] = [];
                    categoryList = valuesField?.category
                      ? medicalSuppliesCategoryList?.filter((v) => v.id === valuesField?.category?.id) || []
                      : medicalSuppliesCategoryList || [];
                    categoryList?.forEach((i) => {
                      i?.items?.forEach((i1: some) => {
                        const obj = {
                          ...i1,
                          group: i.name,
                        };
                        arr.push(obj);
                      });
                    });
                    return arr;
                  };
                  const medicatinItemOptions = getMedicationItemOptions();
                  const categoryOptions = medicalSuppliesCategoryList;

                  return {
                    category: {
                      type: 'auto-complete',
                      options: categoryOptions,
                      getOptionLabel: (option: some) => option.name || '',
                      label: intl.formatMessage({ id: 'therapy.medicalSupplies.category' }),
                      placeholder: intl.formatMessage({ id: 'therapy.medicalSupplies.categoryEnter' }),
                      onChange: () => {
                        setValue('medicalSupplies', null);
                      },
                      propsWrapper: { xs: 6 },
                    },
                    medicalSupplies: {
                      type: 'auto-complete',
                      options: medicatinItemOptions,
                      getOptionLabel: (option) => option.name,
                      isOptionEqualToValue: (opt, value) => opt.id === value.id,
                      label: intl.formatMessage({ id: 'therapy.medicalSupplies.name' }),
                      placeholder: intl.formatMessage({ id: 'therapy.medicalSupplies.nameEnter' }),
                      groupBy: (option) => option.group,
                      onChange: (value) => {
                        if (value && value.id) {
                          onSelectMedicalSupplies(value);
                        }
                      },
                      propsWrapper: { xs: 6 },
                    },
                  };
                },
              }}
            />
          </Box>
        )}
        <Box paddingBottom={2}>
          <Grid container spacing={2} justifyContent={'flex-end'}>
            <Grid item xs={12}>
              <MedicalSuppliesTableList
                readOnly={readOnly}
                data={medicalSuppliesList?.filter((i) => i?.status !== 'DELETED').sort((e1, e2) => e2?.id - e1?.id)}
                onChangeIsFreeStatus={onChangeIsFree}
                indicationList={indicationList}
                updateMedicalSupplies={updateMedicalSupplies}
                medicalSuppliesCategoryList={medicalSuppliesCategoryList}
                actionNode={(record) => (
                  <>
                    <IconButtonTitle
                      key="delete-btn"
                      title="delete"
                      size="small"
                      disabled={readOnly || record.isPaid}
                      onClick={async () => {
                        onDelete(record);
                      }}
                    >
                      <Delete fontSize="small" />
                    </IconButtonTitle>
                  </>
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default TherapyMedicalSuppliesList;
