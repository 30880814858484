import { Box } from '@mui/material';
import SchemaFormMain from 'modules/common/SchemaForm';
import { API_SERVER } from 'modules/common/api';
import { PAYMENT_METHOD_NOT_FULL, PAYMENT_TYPE } from 'modules/common/apiConstants';
import DialogCustom from 'modules/common/component/DialogCustom';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { useDialog, useUpdateMutate } from 'modules/common/hook';
import { PaymentAdvance } from 'modules/schema';
import { Claim } from 'svg';
import { mutate } from 'swr';
interface Props {
  paymentAdvance?: PaymentAdvance;
  prepaymentRevalidate: () => void;
  paymentAdvanceRevalidate: () => void;
}

const ConfirmPaymentAdvanceButton = ({ paymentAdvance, prepaymentRevalidate, paymentAdvanceRevalidate }: Props) => {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const confirmPaymentAdvanceMutate = useUpdateMutate({
    onSuccess: () => {
      prepaymentRevalidate();
      paymentAdvanceRevalidate();
      // validate in TablePriceBox Component
      mutate(
        paymentAdvance
          ? API_SERVER.payment.detail(paymentAdvance?.paymentId!, { type: PAYMENT_TYPE.ENCOUNTER }).url
          : null,
      );
      onCloseDialog();
    },
  });

  return (
    <>
      <IconButtonTitle
        size="small"
        title="therapy.cashier.payment.prepayment.label.confirmPaymentAdvance"
        onClick={onOpenDialog}
      >
        <Claim />
      </IconButtonTitle>
      <DialogCustom
        open={openDialog}
        onClose={onCloseDialog}
        title={'therapy.prepayment.header.paymentAdvanceConfirm.paymentMethod'}
        maxWidth="sm"
        PaperProps={{
          style: { minWidth: 300 },
        }}
      >
        <Box padding={2}>
          <SchemaFormMain
            onCancel={onCloseDialog}
            onSubmit={({ paymentMethod }: { paymentMethod: keyof typeof PAYMENT_METHOD_NOT_FULL }) =>
              confirmPaymentAdvanceMutate({
                url: API_SERVER.prepayment.confirmPrepayment(paymentAdvance?.id!),
                method: 'POST',
                data: { paymentMethod },
              })
            }
            schema={{
              fields: ({ formProps: { intl } }) => {
                return {
                  paymentMethod: {
                    type: 'select',
                    defaultValue: PAYMENT_METHOD_NOT_FULL.CASH.value,
                    label: intl.formatMessage({ id: 'cashier.paymentMethod' }),
                    placeholder: intl.formatMessage({ id: 'select' }),
                    options: Object.values(PAYMENT_METHOD_NOT_FULL),
                    disableClearBtn: true,
                    register: { required: true },
                    propsWrapper: { xs: 12 },
                  },
                };
              },
            }}
          />
        </Box>
      </DialogCustom>
    </>
  );
};

export default ConfirmPaymentAdvanceButton;
