import { Add } from '@mui/icons-material';
import { Button, Paper, Typography } from '@mui/material';
import { Box } from '@mui/system';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../common/api';
import { some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { setLoading } from '../../common/redux/commonReducer';
import DepartmentTable from '../component/department/DepartmentTable';
import FormDialog from '../component/department/FormDialog';
import { axiosThunk } from '../../common/redux/axios';
import { LICENSE_TYPES } from '../constants';
import { useFetch } from 'modules/common/hook';
import { SpecializedDepartment } from 'modules/schema';

interface Props {}

const DepartmentAdminPage: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const [formData, setFormData] = React.useState<SpecializedDepartment | undefined>();

  const { data, isValidating, revalidate } = useFetch(API_SERVER.specializedDepartment.getAll());
  const onSubmit = React.useCallback(
    async (value: some) => {
      const isEdit = !!value?.id;
      try {
        await dispatch(
          axiosThunk({
            url: isEdit ? API_SERVER.specializedDepartment.update(value.id) : API_SERVER.specializedDepartment.create(),
            method: isEdit ? 'PUT' : 'POST',
            data: {
              pic: value?.pic?.value,
              departmentCode: value?.department?.value,
              licenseType: value?.licenseType,
              collaborateName: value?.collaborateName,
              collaborateContract: value?.collaborateContract,
              practitionerRoleId: value?.pic?.practitionerRoleId,
            },
          }),
        );
        openSnackbar({ message: intl.formatMessage({ id: isEdit ? 'updateSuccess' : 'createSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: isEdit ? 'updateFail' : 'createFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
        setFormData(undefined);
        revalidate();
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        try {
          await dispatch(
            axiosThunk({
              url: API_SERVER.specializedDepartment.delete(value.id),
              method: 'DELETE',
            }),
          );
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          revalidate();
        }
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <Box display="flex" paddingBottom={1}>
        <Box flex={1}>
          <Typography variant="h6">
            <FormattedMessage id="department.list" />
          </Typography>
        </Box>
        <Box>
          <Button
            startIcon={<Add />}
            onClick={() => {
              setFormData({ licenseType: LICENSE_TYPES.own });
            }}
          >
            <FormattedMessage id="add" />
          </Button>
        </Box>
      </Box>
      <FormDialog
        open={!!formData}
        formData={formData}
        onClose={() => {
          setFormData(undefined);
        }}
        onSubmit={onSubmit}
      />
      <DepartmentTable data={data} loading={isValidating} onEdit={setFormData} onDelete={onDelete} />
    </Paper>
  );
};

export default DepartmentAdminPage;
