import { Table, TableBody, TableContainer, TableHead, TablePagination, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import LoadingIcon from '../../../common/component/LoadingIcon';
import NoDataBox from '../../../common/component/NoDataBox';
import { TableCustomRow, TableHeaderCell } from '../../../common/component/TableCustom/element';
import TablePaginationActionsCustom from '../../../common/component/TableCustom/TablePaginationActionsCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import EncounterRow from './EncounterRow';
import { Encounter } from 'modules/schema';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import moment from 'moment';
import { Columns } from 'modules/common/component/TableCustom';

interface IEncounterListProps {
  encounters?: Encounter[];
  loading: boolean;
  total: number;
  hookPagination: HookPaginationProps;
}

const EncounterList: React.FunctionComponent<IEncounterListProps> = ({
  encounters,
  hookPagination,
  loading,
  total,
}) => {
  const intl = useIntl();
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;

  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounterId',
        render: (record) => {
          return <Typography>{record?.code}</Typography>;
        },
        align: 'left',
      },
      {
        title: 'patientCodeShort',
        render: (record) => {
          return <Typography>{record?.patient?.formatCode}</Typography>;
        },
        align: 'left',
      },
      {
        title: 'patientName',
        render: (record) => {
          return <Typography>{record?.patient?.name}</Typography>;
        },
      },
      {
        title: 'birthdayShort',
        render: (record) => {
          return <Typography>{moment(record?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>;
        },
        width: 120,
      },
      {
        title: 'telephone',
        render: (record) => {
          return <Typography>{record?.patient?.mobilePhone}</Typography>;
        },
      },
      {
        title: 'encounterDate',
        dataIndex: 'date',
        render: (record) => {
          return <Typography>{record?.createdTime?.format(FE_DATE_FORMAT)}</Typography>;
        },
      },
      {
        title: 'encounterDoctor',
        render: (record) => {
          return record?.picName;
        },
      },
      {
        title: 'checkUpType',
        dataIndex: 'serviceName',
      },
      {
        title: 'status',
        render: (record) => {
          const status = ENCOUNTER_STATUS[record.status!];
          return (
            <Typography noWrap sx={{ color: status?.color }}>
              <FormattedMessage id={status?.label} />
            </Typography>
          );
        },
      },
    ] as Columns<Encounter>[];
  }, []);

  return (
    <>
      <TableContainer sx={{ height: '80vh' }}>
        {loading ? (
          <LoadingIcon />
        ) : (
          <Table stickyHeader>
            <TableHead>
              <TableCustomRow>
                <TableHeaderCell style={{ width: 50 }}></TableHeaderCell>
                {columns.map((item, index) => (
                  <TableHeaderCell key={index}>
                    {item.title && typeof item.title === 'string' && <FormattedMessage id={item.title} />}
                  </TableHeaderCell>
                ))}
                <TableHeaderCell>
                  <FormattedMessage id="indication.number" />
                </TableHeaderCell>
              </TableCustomRow>
            </TableHead>
            <TableBody>
              {encounters && encounters?.length > 0 ? (
                encounters?.map((row, index) => <EncounterRow key={index} encounter={row} columns={columns} />)
              ) : (
                <tr>
                  <td colSpan={12}>
                    <NoDataBox />
                  </td>
                </tr>
              )}
            </TableBody>
          </Table>
        )}
      </TableContainer>
      <TablePagination
        component="div"
        data-tour="step-5"
        style={{ padding: 4 }}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        count={total || 0}
        labelRowsPerPage={intl.formatMessage({ id: 'labelRowPerPage' })}
        ActionsComponent={TablePaginationActionsCustom}
      />
    </>
  );
};

export default EncounterList;
