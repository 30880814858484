import { TimePickerProps, DesktopTimePicker } from '@mui/lab';
import { TextField } from '@mui/material';
import moment from 'moment';
import React, { memo } from 'react';
import { FE_DATE_FORMAT, FE_TIME_FORMAT } from '../../../constants';

export interface PropsTimePickerElement extends Omit<TimePickerProps, 'defaultValue' | 'onChange' | 'value'> {
  onChange?: (value?: string) => void;
  value?: string;
  required?: boolean;
  error?: boolean;
  isSubmitting?: boolean;
  name?: string;
  formatDate?: string;
}

interface Props extends PropsTimePickerElement {}

const TimePickerDesktopElement = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { name, error, isSubmitting, required, value = null, onChange, formatDate, ...rest } = props;
  return (
    <DesktopTimePicker
      ampm={false}
      value={
        moment(value, formatDate || FE_TIME_FORMAT, true).isValid()
          ? moment(value, formatDate || FE_TIME_FORMAT, true).toDate()
          : null
      }
      onChange={(date: any, value) => {
        let tmp;
        if (date) {
          const md = moment(date);
          tmp = md.isValid() ? md.format(formatDate || FE_TIME_FORMAT) : '';
        } else if (value) {
          const md = moment(value, FE_DATE_FORMAT, true);
          tmp = md.isValid() ? md.format(formatDate || FE_TIME_FORMAT) : '';
        } else if (value === null) {
          tmp = null;
        }
        onChange && onChange(tmp);
      }}
      inputRef={ref}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            required: required,
          }}
          variant="outlined"
          fullWidth
          error={!!error}
        />
      )}
      {...(rest as any)}
      InputAdornmentProps={{ style: { display: 'none' } }}
    />
  );
});
export default memo(TimePickerDesktopElement);
