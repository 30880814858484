import { useIntl } from 'react-intl';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { XAxisOptions, AxisLabelsFormatterContextObject } from 'highcharts';
import { CSSProperties, Ref } from 'react';
import { get } from 'lodash';
import { Box } from '@mui/material';

interface Props {
  xAxisTitle?: string;
  yAxisTitle: string;
  categories: XAxisOptions['categories'];
  series: Highcharts.Options['series'];
  staking?: boolean;
  formatYValue?: boolean;
  formatYAxis?: boolean;
  refPrintComponent: Ref<unknown> | undefined;
  yMinValue?: number;
  hiddenXTitle?: boolean;
}

export const ChartTextStyle: CSSProperties = {
  color: '#263238',
  fontFamily: 'Roboto',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.4px',
};

export const ChartTitleStyle: CSSProperties = {
  color: '#546E7A',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.4px',
};

const ReportColumnChart = ({
  xAxisTitle,
  yAxisTitle,
  categories,
  series,
  staking,
  formatYValue,
  formatYAxis,
  refPrintComponent,
  yMinValue = 0,
  hiddenXTitle,
}: Props) => {
  const intl = useIntl();

  return (
    <Box ref={refPrintComponent}>
      <Box sx={{ pageBreakAfter: 'always', '@page': { size: '1920px 1080px' } }}>
        <HighchartsReact
          highcharts={Highcharts}
          options={{
            accessibility: {
              enabled: false,
            },
            credits: {
              enabled: false,
            },
            exporting: {
              buttons: {
                contextButton: {
                  menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
                },
              },
            },
            chart: {
              type: 'column',
              zoomType: 'x',
            },
            title: {
              text: '',
            },
            subtitle: {},
            plotOptions: {
              column: {
                stacking: staking ? 'normal' : undefined,
                pointPadding: 0.1,
                borderWidth: 0,
              },
              series: {
                borderRadius: 5,
              },
            },
            xAxis: {
              categories,
              labels: {
                style: ChartTextStyle,
              },
              title: !hiddenXTitle && {
                text: intl.formatMessage({ id: xAxisTitle }),
                style: ChartTitleStyle,
                margin: 20,
              },
              crosshair: true,
            },
            yAxis: {
              min: yMinValue === -1 ? null : yMinValue,
              crosshair: true,
              title: {
                text: intl.formatMessage({ id: yAxisTitle }),
                style: ChartTitleStyle,
                margin: 20,
              },
              labels: {
                style: ChartTextStyle,
                formatter:
                  formatYAxis &&
                  function (this: AxisLabelsFormatterContextObject) {
                    const value = parseFloat(this.value as string);
                    if (value >= 1e12) {
                      return (value / 1e12).toFixed(1) + ' T';
                    }
                    if (value >= 1e9) {
                      return (value / 1e9).toFixed(1) + ' T';
                    }
                    if (value >= 1e6) {
                      return (value / 1e6).toFixed(1) + ' TR';
                    }
                    return this.value;
                  },
              },
            },
            tooltip: {
              useHTML: true,
              backgroundColor: '#E0ECFF',
              borderWidth: 0,
              padding: 12,
              borderRadius: 5,
              formatter: function () {
                const xValue = get(this, 'x');
                const yValue = get(this, 'y');
                const formattedYValue = new Intl.NumberFormat('vi-VN', {
                  style: 'currency',
                  currency: 'VND',
                  minimumFractionDigits: 0,
                }).format(yValue);
                return `<div>
                      <div>
                        <span><b>${xAxisTitle && intl.formatMessage({ id: xAxisTitle })}</b>: ${
                  xAxisTitle && xValue
                }</span>
                      </div>
                      <div>
                        <span><b>${intl.formatMessage({ id: yAxisTitle })}</b>: ${
                  formatYValue ? formattedYValue : yValue
                }</span>
                      </div>
                    </div>`;
              },
              shadow: {
                opacity: 0,
              },
            },
            series,
          }}
        />
      </Box>
    </Box>
  );
};

export default ReportColumnChart;
