import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import EditIcon from '@mui/icons-material/Edit';
import { useDialog, useUpdateMutate } from 'modules/common/hook';
import { useCallback } from 'react';
import { API_SERVER } from 'modules/common/api';
import { PaymentAdvance, PrePayment } from 'modules/schema';
import EditPrepaymentDialog from './EditPaymentAdvanceDialog';

interface Props {
  paymentAdvance: PaymentAdvance;
  prepayment?: PrePayment;
  paymentAdvanceRevalidate: () => void;
}

const EditPaymentAdvanceButton = ({ paymentAdvance, prepayment, paymentAdvanceRevalidate }: Props) => {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();
  const createPaymentAdvanceMutate = useUpdateMutate({
    onSuccess: () => {
      paymentAdvanceRevalidate();
      onCloseDialog();
    },
  });
  const onCreatePaymentAdvance = useCallback(
    ({ prepaymentAmount }: { prepaymentAmount: number }) => {
      if (prepayment) {
        createPaymentAdvanceMutate({
          url: API_SERVER.prepayment.updatePaymentAdvance(paymentAdvance?.id!),
          method: 'PUT',
          data: { amount: prepaymentAmount, paymentId: prepayment?.paymentId, prepaymentId: prepayment?.id },
        });
      }
    },
    [createPaymentAdvanceMutate, paymentAdvance?.id, prepayment],
  );

  return (
    <>
      <IconButtonTitle size="small" title="therapy.tooltip.label.edit" onClick={onOpenDialog}>
        <EditIcon />
      </IconButtonTitle>
      <EditPrepaymentDialog
        onClose={onCloseDialog}
        open={openDialog}
        onSubmit={onCreatePaymentAdvance}
        prepaymentAmount={paymentAdvance?.amount}
      />
    </>
  );
};

export default EditPaymentAdvanceButton;
