import { z } from 'zod';
import { PatientAddressSchema } from './PatientAddress';
import { PatientContactInfoSchema } from './PatientContactInfo';
import { createObjectKeysEnumSchema } from './common';
import { BASE_STATUS, BE_DATE_FORMAT, EMPLOYMENT_STATUS, MARITAL_STATUS, PATIENT_TYPE } from 'modules/common/constants';
import { GENDER, PATIENT_ADDRESS_TYPE } from 'modules/common/apiConstants';
import moment from 'moment';

export const PatientSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    name: z.string(),
    otherName: z.string(),
    identifierCode: z.string(),
    email: z.string(),
    homePhone: z.string(),
    mobilePhone: z.string(),
    gender: createObjectKeysEnumSchema(GENDER),
    dob: z.string(),
    pob: z.string(),
    job: z.string(),
    employmentStatus: createObjectKeysEnumSchema(EMPLOYMENT_STATUS),
    maritalStatus: createObjectKeysEnumSchema(MARITAL_STATUS),
    ethnic: z.string(),
    nationality: z.string(),
    language: z.string(),
    status: createObjectKeysEnumSchema(BASE_STATUS),
    religion: z.string(),
    groupId: z.number(),
    resourceId: z.string(),
    resourceType: z.string(),
    patientAddressList: z.array(PatientAddressSchema),
    patientContactInfoList: z.array(PatientContactInfoSchema),
    amountPaid: z.number(),
    type: createObjectKeysEnumSchema(PATIENT_TYPE),
    medicalHistory: z.string(),
    illnessesHistory: z.string(),
    note: z.string(),
  })
  .partial()
  .transform((patient) => {
    return {
      ...patient,
      ...(patient?.code
        ? {
            formatCode: patient?.code?.split('-')?.[1],
          }
        : {}),
      ...(patient.dob
        ? {
            age: patient.dob ? moment().diff(moment(patient.dob, BE_DATE_FORMAT), 'years') : undefined,
            month: patient.dob ? moment().diff(moment(patient.dob, BE_DATE_FORMAT), 'months') % 12 : undefined,
          }
        : {}),
      ...(patient?.patientAddressList
        ? {
            workAddress: patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.WORK),
            homeAddress: patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.HOME),
          }
        : {}),
    };
  });

export type Patient = z.infer<typeof PatientSchema>;
