import { Box } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { IDENTITY_API_SERVER } from '../../../../common/api';
import DialogCustom from '../../../../common/component/DialogCustom';
import { some } from '../../../../common/constants';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFormAdminAccount } from './schema';
import { useFetch } from 'modules/common/hook';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<{
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
  employees: some[];
}> = (props) => {
  const { onSubmit, formData, onClose, employees } = props;
  return (
    <SchemaForm
      schema={schemaFormAdminAccount}
      onSubmit={onSubmit}
      initialData={formData}
      onCancel={onClose}
      employees={employees}
    />
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;
  const { data: allEmployee } = useFetch(IDENTITY_API_SERVER.employee.getAllEmployee);

  return (
    <DialogCustom
      open={!!open}
      keepMounted={false}
      maxWidth="xs"
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 680 },
      }}
      title={formData?.id ? 'accountAdmin.update' : 'accountAdmin.addNew'}
    >
      <Box padding={2}>
        <SchemaFormContent {...props} employees={allEmployee || []} />
      </Box>
    </DialogCustom>
  );
};
export default FormDialog;
