import { z } from 'zod';

export const MedicalEquipmentSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    code: z.string(),
    status: z.string(),
    systemCategoryId: z.number(),
    groupId: z.number(),
  })
  .partial();

export type MedicalEquipment = z.infer<typeof MedicalEquipmentSchema>;
