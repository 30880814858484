export const REPORT_VIEW_MODE = {
  TABLE: 'TABLE',
  CHART: 'CHART',
} as const;

export const TIME_REPORT_FILTER = {
  DAY: {
    value: 'DAY',
    label: 'common.day',
  },
  MONTH: {
    value: 'MONTH',
    label: 'common.month',
  },
  WEEK: {
    value: 'WEEK',
    label: 'common.week',
  },
} as const;
