import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import { FormattedMessage } from 'react-intl';
import NoteFormDialog from './NoteFormDialog';
import { API_SERVER } from 'modules/common/api';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { FE_DATE_FORMAT, some } from 'modules/common/constants';
import React from 'react';
import { DENTAL_OBJECT_LIST } from './constants';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import moment from 'moment';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { useParams } from 'react-router';
import { DENTAL_GROUP_DETAIL } from '../common/constants';
import { useFetchDentalInfoBoard } from '../common/fetcher';
import { useDeleteMutate, useUpdateMutate } from 'modules/common/hook/useMutate';
import { useDentalDetailStateSelector } from '../state';

const Row: React.FunctionComponent<{
  row: some;
  index: number;
  patientId: number;
  revalidate: any;
  setNoteIndication: any;
}> = (props) => {
  const { row, index, patientId, revalidate, setNoteIndication } = props;
  const { intl } = useGeneralHook();
  const [open, setOpen] = React.useState(false);
  const readOnly = useDentalDetailStateSelector.use.readonly();

  // column condition diagnosis
  const conditionDiagnosis = (data: some) => {
    const dentalGroup = DENTAL_OBJECT_LIST.find((i) => i.dentalGroup === data?.objectType);
    return (
      <Typography>
        {!data.objectDetail && dentalGroup?.label}{' '}
        {`${data.objectDetail ? intl.formatMessage({ id: DENTAL_GROUP_DETAIL[data.objectDetail]?.label }) : ''} ${
          data?.position ? data?.position : ''
        }`}
      </Typography>
    );
  };

  // column dental group
  const dentalGroup = (data: some) => {
    const dentalGroup = DENTAL_OBJECT_LIST.find((i) => i.dentalGroup === data?.objectType);
    return <Typography>{dentalGroup?.label}</Typography>;
  };

  // delete dental diagnosis
  const onDeleteMutate = useDeleteMutate({
    confirmMessage: 'therapyIndication.request.confirmDelete',
    onSuccess: revalidate,
  });

  // complete dental diagnosis
  const onCompleteMutate = useUpdateMutate({
    confirmMessage: 'subclinical.acceptReport',
    onSuccess: revalidate,
  });

  return (
    <>
      <TableCustomRow>
        <TableCustomCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell>
          <Typography>{index}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography>{conditionDiagnosis(row)}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography>{dentalGroup(row)}</Typography>
        </TableCustomCell>
      </TableCustomRow>
      <TableRow style={{ backgroundColor: 'white', padding: 0 }}>
        <TableCustomCell style={{ padding: '0px', border: 'none', backgroundColor: 'inherit' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases" style={{ marginTop: 4 }}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="dental.label.conditionDiagnosis.detailLocation" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="dental.dentalDetail.statusAndDiagnosis.table.label" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="dental.label.conditionDiagnosis.icd10" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="dental.label.conditionDiagnosis.dateDiagnosis" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="action" />
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {row?.dentalDiagnosisList?.map((record: some) => {
                  return (
                    <TableRow key={record?.id}>
                      <TableCustomCell>{conditionDiagnosis(record)}</TableCustomCell>
                      <TableCustomCell>{record?.display}</TableCustomCell>
                      <TableCustomCell>
                        {record?.code && !record?.code.startsWith('CODE') && record?.code}
                      </TableCustomCell>
                      <TableCustomCell>
                        {record.createdTime && moment(record.createdTime).format(FE_DATE_FORMAT)}
                      </TableCustomCell>
                      <TableCustomCell>
                        <>
                          <IconButtonTitle
                            key="delete-btn"
                            title="delete"
                            size="small"
                            onClick={async () => {
                              onDeleteMutate({
                                url: API_SERVER.dental.deleteDentalDiagnosis(record?.id, { patientId: patientId }),
                                method: 'delete',
                              });
                            }}
                            disabled={record.status === 'FINISHED' || record.isPaid || readOnly}
                          >
                            <Delete fontSize="small" />
                          </IconButtonTitle>
                          <IconButtonTitle
                            title="note"
                            key="note-btn"
                            size="small"
                            onClick={() => setNoteIndication({ ...record })}
                            disabled={readOnly}
                          >
                            {record.note ? (
                              <DescriptionSharpIcon fontSize="small" />
                            ) : (
                              <InsertDriveFileSharpIcon fontSize="small" />
                            )}
                          </IconButtonTitle>
                          {record?.status === 'FINISHED' ? (
                            <IconButtonTitle disableRipple size="small">
                              <AssignmentTurnedInIcon color="success" />
                            </IconButtonTitle>
                          ) : (
                            <IconButtonTitle
                              key="completed"
                              disableRipple
                              title={'complete'}
                              size="small"
                              disabled={readOnly}
                              onClick={async () => {
                                onCompleteMutate({
                                  url: API_SERVER.dental.completeDentalDiagnosis(record?.id, { patientId: patientId }),
                                  method: 'put',
                                });
                              }}
                            >
                              <AssignmentTurnedInIcon />
                            </IconButtonTitle>
                          )}
                        </>
                      </TableCustomCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCustomCell>
      </TableRow>
    </>
  );
};

const ConditionDiagnosis = () => {
  const { patientId, dentalSessionId } = useParams<{ patientId; dentalSessionId }>();
  const [noteIndication, setNoteIndication] = React.useState<some>();
  const { data, revalidate } = useFetchDentalInfoBoard(dentalSessionId, patientId);
  const readOnly = useDentalDetailStateSelector.use.readonly();

  // note dental diagnosis
  const onUpdateMutate = useUpdateMutate({
    onSuccess: () => {
      revalidate();
      setNoteIndication(undefined);
    },
  });

  return (
    <Box
      position="relative"
      overflow="inherit"
      display="flex"
      flexDirection="column"
      height="-webkit-fill-available"
      flex={1}
    >
      <TableContainer style={{ height: '100%' }}>
        <Table stickyHeader className="therapy-revenue-report">
          <TableHead>
            <TableCustomRow>
              <TableHeaderCell style={{ width: 54 }} />
              <TableHeaderCell className="col col2">
                <FormattedMessage id="stt" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="category" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="calendar.group" />
              </TableHeaderCell>
            </TableCustomRow>
          </TableHead>
          <TableBody>
            {data && data?.length > 0 ? (
              data?.map((row, index) => (
                <Row
                  key={row?.position}
                  row={row}
                  index={index + 1}
                  patientId={patientId}
                  revalidate={revalidate}
                  setNoteIndication={setNoteIndication}
                />
              ))
            ) : (
              <>
                <TableCustomRow>
                  <TableCustomCell colSpan={6}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100%',
                      }}
                    >
                      <Typography variant="inherit" color="textSecondary">
                        <FormattedMessage id="noData" />
                      </Typography>
                    </Box>
                  </TableCustomCell>
                </TableCustomRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <NoteFormDialog
        open={!!noteIndication}
        onClose={() => {
          setNoteIndication(undefined);
        }}
        formData={noteIndication}
        onSubmit={(indication) =>
          onUpdateMutate({
            url: API_SERVER.dental.noteDentalDiagnosis(indication?.id, { patientId: patientId }),
            method: 'put',
            data: { data: indication.note },
          })
        }
        readOnly={noteIndication?.status === 'FINISHED' || readOnly}
      />
    </Box>
  );
};

export default ConditionDiagnosis;
