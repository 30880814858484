import { Stack } from '@mui/material';
import useFilterContext from './useFilterContext';
import FilterViewItem from './FilterViewItem';
import { useMemo } from 'react';

const FilterView = () => {
  const { filters, filterValue } = useFilterContext();
  const viewFilters = useMemo(
    () => filters.filter((filter) => filterValue?.[filter.type] && filterValue?.[filter.type].length > 0),
    [filterValue, filters],
  );
  if (viewFilters?.length === 0) return null;

  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" gap="10px" flexWrap="wrap">
      {viewFilters.map((filter) => (
        <FilterViewItem filter={filter} key={filter.type} />
      ))}
    </Stack>
  );
};

export default FilterView;
