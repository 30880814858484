import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';

export const ReportSchema = z
  .object({
    title: z.string(),
    dateTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    value: z.number(),
    type: z.string(),
  })
  .partial();

export type Report = z.infer<typeof ReportSchema>;
