import {
  BE_DATE_TIME_FORMAT,
  FE_DATE_FORMAT,
  FE_TIME_DATE_FORMAT,
  FE_TIME_DATE_FORMAT_DASH,
  ROLES,
  ROUTE_TYPE,
  some,
} from 'modules/common/constants';
import { Box, Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { AssessmentFinished, DoctorExchange, DoctorExchangeGreen, WarningAmber, AppointmentCalendar } from '../../svg';
import { FormattedMessage } from 'react-intl';
import React from 'react';
import { ROUTES } from 'modules/layout/router';
import { axiosThunk } from 'modules/common/redux/axios';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import moment from 'moment/moment';
import { MEDICATION_UNIT } from 'modules/admin/component/inventory/constant';
import { useHistory } from 'react-router';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DialogCustom from 'modules/common/component/DialogCustom';
import { API_SERVER, NOTIFICATION_API_SERVER } from 'modules/common/api';
import { isEmpty } from 'lodash';
import { NotificationProps } from 'modules/layout/Header';
import { setLoading } from 'modules/common/redux/commonReducer';

interface Props {
  data: some;
  handleClose: () => void;
  revalidate: () => void;
  popupNotification: NotificationProps;
  setPopupNotification: (popupNotification) => void;
}

interface JSONMessage {
  medicationName: string;
  lot: string;
  quantity: number;
  unit: string;
  expireDate: string;
  createdTime: string;
  patientName: string;
  mobilePhone: string;
  therapySessionName: string;
  startTime: number;
  serviceName: string;
  triggerTime: number;
  contentType: string;
  messageContentType: string;
  indicationRequestFrom: string;
  indicationRequestId: string;
  patientId: number;
  therapySessionId: string;
  therapyId: number;
  itemType: string;
  itemName: string;
  paymentId: number;
  itemReferenceId: number;
  routeType: string;
  subItemReferenceId: number;
  itemCode: string;
  picUserBefore: string;
  note: string;
  customerName: string;
  bookingStartTime: string;
  phoneNumber: string;
}

const NotificationItem = (props: Props) => {
  const { data, handleClose, popupNotification, setPopupNotification } = props;
  const newPopupNotification = { ...popupNotification };
  const notificationItems = newPopupNotification?.items;
  const index = !isEmpty(notificationItems) && notificationItems.findIndex((i) => i.id === data.id);
  const { intl, dispatch } = useGeneralHook();
  const { setParams } = usePaginationHook({ disablePagination: true });
  const history = useHistory();
  const [open, setOpen] = React.useState<boolean>(false);
  const { appState } = useGeneralHook();
  const { user } = appState.authen;
  const jsonMessage: JSONMessage = data?.message && JSON.parse(data.message);
  const contentType = jsonMessage?.contentType;
  const unit = MEDICATION_UNIT.find((v) => v.value === jsonMessage?.unit)?.label;

  // indication content type
  const indicationContentType = () => {
    switch (contentType) {
      case 'INDICATION_IMAGE_FINISH':
        return 'INDICATION_IMAGE_FINISH';
      case 'INDICATION_PROCEDURE_FINISH':
        return 'INDICATION_PROCEDURE_FINISH';
      default:
        return 'INDICATION_LAB_FINISH';
    }
  };

  // content type upcoming new
  const upcomingNew = () => {
    if (
      (contentType === 'UPCOMING_THERAPY_SESSION' && !ROLES.RECEPTIONIST.value) ||
      contentType === 'INDICATION_PROCEDURE_NEW'
    ) {
      return true;
    }
    return false;
  };

  // content type indication finish
  const indicationFinish = () => {
    if (
      contentType === 'INDICATION_LAB_FINISH' ||
      contentType === 'INDICATION_IMAGE_FINISH' ||
      contentType === 'INDICATION_PROCEDURE_FINISH'
    ) {
      return true;
    }
    return false;
  };

  // content type indication finish role
  const indicationFinishRole = () => {
    if (indicationFinish() && (ROLES.DOCTOR.value || ROLES.GENERAL_DOCTOR.value)) {
      return true;
    }
    return false;
  };

  // content type assign encounter
  const assignEncounter = () => {
    if (
      (contentType === 'ASSIGN_NEW_ENCOUNTER' || contentType === 'CHANGE_ASSIGN_ENCOUNTER') &&
      (ROLES.DOCTOR.value || ROLES.GENERAL_DOCTOR.value)
    ) {
      return true;
    }
    return false;
  };

  // content type indication all
  const indicationNewFinish = () => {
    if (
      contentType === 'INDICATION_LAB_NEW' ||
      contentType === 'INDICATION_IMAGE_NEW' ||
      ((contentType === 'INDICATION_LAB_FINISH' || contentType === 'INDICATION_IMAGE_FINISH') &&
        ROLES.CLINICAL_PATHOLOGIST.value) ||
      ((contentType === 'INDICATION_LAB_FINISH' || contentType === 'INDICATION_IMAGE_FINISH') &&
        ROLES.LABORATORY_TECHNOLOGIST.value) ||
      ((contentType === 'INDICATION_LAB_FINISH' || contentType === 'INDICATION_IMAGE_FINISH') &&
        ROLES.RADIOGRAPHER.value) ||
      ((contentType === 'INDICATION_LAB_FINISH' || contentType === 'INDICATION_IMAGE_FINISH') &&
        ROLES.RADIOLOGIST.value)
    ) {
      return true;
    }
    return false;
  };

  // content type charge item new
  const chargeItemNew = () => {
    if (contentType === 'CHARGE_ITEM_NEW') {
      return true;
    }
    return false;
  };

  // content type request appointment new
  const requestAppointmentNew = () => {
    if (contentType === 'REQUEST_APPOINTMENT_NEW') {
      return true;
    }
    return false;
  };

  // display hyper link title notification
  const hyperLinkTitleNotification = () => {
    if (
      upcomingNew() ||
      indicationFinishRole() ||
      assignEncounter() ||
      indicationNewFinish() ||
      chargeItemNew() ||
      requestAppointmentNew()
    ) {
      return true;
    }
    return false;
  };

  // click notification item indication
  const readNotification = (
    indicationRequestId: string,
    itemReferenceId: number,
    routeType: string,
    patientId: number,
  ) => {
    if (itemReferenceId) {
      if (routeType === ROUTE_TYPE.THERAPY.value) {
        history.push(ROUTES.detailIndicationTherapy.gen(indicationRequestId, itemReferenceId + ''));
      } else if (routeType === ROUTE_TYPE.DENTAL_EXAM.value) {
        history.push(ROUTES.detailIndicationDental.gen(indicationRequestId, itemReferenceId + ''));
      } else if (routeType === ROUTE_TYPE.ENCOUNTER.value) {
        history.push(ROUTES.detailIndication.gen(parseInt(indicationRequestId), patientId, itemReferenceId));
      }
      handleClose();
    }
  };

  // click notification item therapy session
  const readNotificationTherapySession = (therapyId: number, therapySessionId: string) => {
    if (therapyId && therapySessionId) {
      history.push(ROUTES.therapyDetail.gen(therapyId));
      if (therapySessionId) {
        setParams({
          therapySessionId,
        });
      }
      handleClose();
    }
  };

  // click notification item cashier
  const readNotificationCashier = (
    itemReferenceId: number,
    paymentId: number,
    routeType: string,
    patientId: number,
  ) => {
    if (itemReferenceId && paymentId) {
      if (routeType === ROUTE_TYPE.THERAPY.value) {
        history.push(ROUTES.therapyPaymentDetail.gen(itemReferenceId, paymentId));
      } else if (routeType === ROUTE_TYPE.DENTAL_EXAM.value) {
        history.push(ROUTES.dentalPaymentDetail.gen(itemReferenceId, paymentId));
      } else if (routeType === ROUTE_TYPE.ENCOUNTER.value) {
        history.push(ROUTES.episodeOfCareDetail.gen(itemReferenceId, patientId));
      }
      handleClose();
    }
  };

  // display notification item
  const displayNotificationItem = () => {
    if (contentType === 'UPCOMING_THERAPY_SESSION') {
      return (
        <>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="sellingProduct.title.customerName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.patientName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" component={'span'}>
              <FormattedMessage id="sellingProduct.title.phoneNumber" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.mobilePhone}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="therapy.sessionName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.therapySessionName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="therapy.indication.request.printForm.startTime" />
            </Typography>
            <Typography variant="subtitle1">
              {jsonMessage?.startTime
                ? moment(new Date(jsonMessage?.startTime), BE_DATE_TIME_FORMAT).format(FE_TIME_DATE_FORMAT)
                : ''}
            </Typography>
          </Box>
        </>
      );
    }
    if (
      contentType === 'INDICATION_LAB_NEW' ||
      contentType === 'INDICATION_IMAGE_NEW' ||
      contentType === 'INDICATION_PROCEDURE_NEW'
    ) {
      return (
        <>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="sellingProduct.title.customerName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.patientName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" component={'span'}>
              <FormattedMessage id="encounterDetail.indicationName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.serviceName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="indicationDate" />
            </Typography>
            <Typography variant="subtitle1">
              {jsonMessage?.createdTime
                ? moment(new Date(jsonMessage?.createdTime), BE_DATE_TIME_FORMAT).format(FE_TIME_DATE_FORMAT)
                : ''}
            </Typography>
          </Box>
        </>
      );
    }
    if (indicationFinish()) {
      return (
        <>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="sellingProduct.title.customerName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.patientName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" component={'span'}>
              <FormattedMessage id="encounterDetail.indicationName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.serviceName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" component={'span'}>
              <FormattedMessage id="notification.label.indicationRequestFrom" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.indicationRequestFrom}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="indicationDate" />
            </Typography>
            <Typography variant="subtitle1">
              {jsonMessage?.createdTime
                ? moment(new Date(jsonMessage?.createdTime), BE_DATE_TIME_FORMAT).format(FE_TIME_DATE_FORMAT)
                : ''}
            </Typography>
          </Box>
        </>
      );
    }
    if (chargeItemNew()) {
      return (
        <>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="sellingProduct.title.customerName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.patientName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" component={'span'}>
              <FormattedMessage id="therapy.cashier.table.header.type" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.itemType}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" style={{ minWidth: '100px' }}>
              <FormattedMessage id="serviceName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.itemName}</Typography>
          </Box>
        </>
      );
    } else if (contentType === 'ASSIGN_NEW_ENCOUNTER') {
      return (
        <>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="sellingProduct.title.customerName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.patientName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" component={'span'}>
              <FormattedMessage id="cashierPrint.encounterId" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.itemCode}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" style={{ minWidth: '100px' }}>
              <FormattedMessage id="notification.label.examinationService" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.itemName}</Typography>
          </Box>
        </>
      );
    } else if (contentType === 'CHANGE_ASSIGN_ENCOUNTER') {
      return (
        <>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="sellingProduct.title.customerName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.patientName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" component={'span'}>
              <FormattedMessage id="cashierPrint.encounterId" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.itemCode}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" style={{ minWidth: '100px' }}>
              <FormattedMessage id="notification.label.examinationService" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.itemName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="notification.label.doctorChargeFirst" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.picUserBefore}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" style={{ minWidth: '100px' }}>
              <FormattedMessage id="note" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.note}</Typography>
          </Box>
        </>
      );
    } else if (requestAppointmentNew()) {
      return (
        <>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="sellingProduct.title.customerName" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.customerName}</Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="time" />
            </Typography>
            <Typography variant="subtitle1">
              {jsonMessage?.bookingStartTime
                ? moment(new Date(jsonMessage?.bookingStartTime), BE_DATE_TIME_FORMAT).format(FE_TIME_DATE_FORMAT_DASH)
                : ''}
            </Typography>
          </Box>
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="telephone" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.phoneNumber}</Typography>
          </Box>
        </>
      );
    }
    return (
      <>
        <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
          <Typography variant="body1">
            <FormattedMessage id="sale.products" />
          </Typography>
          <Typography variant="subtitle1">{jsonMessage?.medicationName}</Typography>
        </Box>
        {jsonMessage?.lot && (
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1" component={'span'}>
              <FormattedMessage id="inventory.lotShort" />
            </Typography>
            <Typography variant="subtitle1">{jsonMessage?.lot}</Typography>
          </Box>
        )}
        {jsonMessage?.expireDate && (
          <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
            <Typography variant="body1">
              <FormattedMessage id="expiredDate" />
            </Typography>
            <Typography variant="subtitle1" color={'error.light'}>
              {jsonMessage?.expireDate
                ? moment(jsonMessage?.expireDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)
                : ''}
            </Typography>
          </Box>
        )}
        <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
          <Typography variant="body1">
            <FormattedMessage id="inventory.remain.quantity" />
          </Typography>
          <Typography variant="subtitle1" color={'error.light'}>
            {jsonMessage?.quantity && unit && `${jsonMessage?.quantity} ${intl.formatMessage({ id: unit })}`}
          </Typography>
        </Box>
      </>
    );
  };

  // display notification icon
  const displayNotificationIcon = () => {
    if (contentType === 'UPCOMING_THERAPY_SESSION') {
      return <PendingActionsIcon style={{ color: '#0052E0' }} />;
    }
    if (indicationFinish()) {
      return <AssessmentFinished style={{ width: '25px', height: '25px' }} />;
    }
    if (contentType === 'ASSIGN_NEW_ENCOUNTER') {
      return <DoctorExchangeGreen />;
    }
    if (contentType === 'CHANGE_ASSIGN_ENCOUNTER') {
      return <DoctorExchange />;
    }
    if (requestAppointmentNew()) {
      return <AppointmentCalendar />;
    }
    return <WarningAmber style={{ width: '25px', height: '25px' }} />;
  };

  // handle accept dental, encounter
  const acceptDentalEncounter = async () => {
    setOpen(false);
    if (jsonMessage?.routeType === ROUTE_TYPE.DENTAL_EXAM.value) {
      await dispatch(
        axiosThunk({
          url: API_SERVER.dentalExam.acceptDentalEncounter(jsonMessage?.itemReferenceId),
          method: 'post',
          data: { id: jsonMessage?.itemReferenceId },
        }),
      );
    } else if (jsonMessage?.routeType === ROUTE_TYPE.ENCOUNTER.value) {
      await dispatch(
        axiosThunk({
          url: API_SERVER.encounter.receiveEncounter(jsonMessage?.itemReferenceId),
          method: 'put',
          data: { id: jsonMessage?.itemReferenceId },
        }),
      );
    }
    if (assignEncounter()) {
      if (jsonMessage?.routeType === ROUTE_TYPE.DENTAL_EXAM.value) {
        history.push(ROUTES.dentalDetail.gen(jsonMessage?.itemReferenceId, jsonMessage?.patientId));
      } else if (jsonMessage?.routeType === ROUTE_TYPE.ENCOUNTER.value) {
        history.push(ROUTES.encounterDetail.gen(jsonMessage?.itemReferenceId, jsonMessage?.patientId));
      }
    }
  };

  return (
    <>
      <Box
        marginBottom={'5px'}
        border={'1px solid rgba(0, 0, 0, 0.12)'}
        borderRadius={'4px'}
        sx={{ backgroundColor: '#fff' }}
        overflow={'hidden'}
      >
        <Box
          sx={{ backgroundColor: data?.isRead ? '#F4F8FF' : '' }}
          padding={'16px'}
          onClick={async () => {
            try {
              if (!data?.isRead) {
                dispatch(setLoading(true));
                await dispatch(
                  axiosThunk({ url: NOTIFICATION_API_SERVER.notification.readDetail(data?.id), method: 'put' }),
                );
              }
            } catch (error) {}
            if (index >= 0)
              notificationItems[index] = {
                ...notificationItems[index],
                isRead: true,
              };
            setPopupNotification(newPopupNotification);
            dispatch(setLoading(false));
          }}
        >
          {/*<RawLink to={`${ROUTES.changePassword}/${data.id}`} style={{ width: '95%' }}>*/}
          <Grid container xs={12} spacing={1}>
            <Grid item xs={1.2}>
              {displayNotificationIcon()}
            </Grid>
            <Grid item xs={10.8}>
              <Box>
                <Typography variant="caption" component={'span'}>
                  {`MEDi • ${data ? moment(data.createdTime, BE_DATE_TIME_FORMAT).format(FE_TIME_DATE_FORMAT) : ''}`}
                </Typography>
              </Box>
              <Box>
                <Typography
                  sx={{
                    cursor: `${hyperLinkTitleNotification() ? 'pointer' : ''}`,
                    '&:hover': {
                      textDecoration: `${hyperLinkTitleNotification() ? 'underline' : ''}`,
                    },
                  }}
                  color={'primary.main'}
                  variant="subtitle1"
                  component={'span'}
                  onClick={async () => {
                    if (upcomingNew()) {
                      if (jsonMessage?.routeType === ROUTE_TYPE.THERAPY.value) {
                        const subItemReferenceId = jsonMessage?.subItemReferenceId;
                        history.push(ROUTES.therapyDetail.gen(jsonMessage?.itemReferenceId));
                        setParams({
                          subItemReferenceId,
                        });
                        handleClose();
                      } else if (jsonMessage?.routeType === ROUTE_TYPE.DENTAL_EXAM.value) {
                        history.push(ROUTES.dentalDetail.gen(jsonMessage?.itemReferenceId, jsonMessage?.patientId));
                        handleClose();
                      } else if (jsonMessage?.routeType === ROUTE_TYPE.ENCOUNTER.value) {
                        history.push(ROUTES.encounterDetail.gen(jsonMessage?.itemReferenceId, jsonMessage?.patientId));
                        return handleClose();
                      } else {
                        readNotificationTherapySession(jsonMessage?.therapyId, jsonMessage?.therapySessionId);
                      }
                    }
                    if (indicationFinishRole()) {
                      if (jsonMessage?.routeType === ROUTE_TYPE.THERAPY.value) {
                        const subItemReferenceId = jsonMessage?.subItemReferenceId;
                        history.push(ROUTES.therapyDetail.gen(jsonMessage?.itemReferenceId));
                        setParams({
                          subItemReferenceId,
                        });
                        handleClose();
                      } else if (jsonMessage?.routeType === ROUTE_TYPE.DENTAL_EXAM.value) {
                        history.push(ROUTES.dentalDetail.gen(jsonMessage?.itemReferenceId, jsonMessage?.patientId));
                        handleClose();
                      } else if (jsonMessage?.routeType === ROUTE_TYPE.ENCOUNTER.value) {
                        localStorage.setItem('indication-content-type', indicationContentType());
                        history.push(ROUTES.encounterDetail.gen(jsonMessage?.itemReferenceId, jsonMessage?.patientId));
                        return handleClose();
                      } else {
                        readNotificationTherapySession(jsonMessage?.therapyId, jsonMessage?.therapySessionId);
                      }
                    }
                    if (assignEncounter()) {
                      if (jsonMessage?.routeType === ROUTE_TYPE.THERAPY.value) {
                        history.push(ROUTES.therapyDetail.gen(jsonMessage?.itemReferenceId));
                        handleClose();
                      } else if (jsonMessage?.routeType === ROUTE_TYPE.DENTAL_EXAM.value) {
                        const res = await dispatch(
                          axiosThunk({
                            url: API_SERVER.dental.getDentalExam(jsonMessage?.itemReferenceId + '').url,
                            method: 'get',
                          }),
                        );
                        if (res?.data?.picUserId === user?.user_id) {
                          if (res?.data?.status === 'ARRIVED') {
                            setOpen(!open);
                          } else {
                            history.push(ROUTES.dentalDetail.gen(jsonMessage?.itemReferenceId, jsonMessage?.patientId));
                            handleClose();
                          }
                        }
                      } else if (jsonMessage?.routeType === ROUTE_TYPE.ENCOUNTER.value) {
                        const res = await dispatch(
                          axiosThunk({
                            url: API_SERVER.encounter.get(jsonMessage?.itemReferenceId).url,
                            method: 'get',
                          }),
                        );
                        if (res?.data?.picUserId === user?.user_id) {
                          if (res?.data?.status === 'ARRIVED') {
                            setOpen(!open);
                          } else {
                            history.push(
                              ROUTES.encounterDetail.gen(jsonMessage?.itemReferenceId, jsonMessage?.patientId),
                            );
                            handleClose();
                          }
                        }
                      }
                    }
                    if (indicationNewFinish()) {
                      if (jsonMessage?.routeType) {
                        readNotification(
                          jsonMessage?.indicationRequestId,
                          jsonMessage?.itemReferenceId,
                          jsonMessage?.routeType,
                          jsonMessage?.patientId,
                        );
                      } else {
                        if (jsonMessage?.indicationRequestId && jsonMessage?.therapyId) {
                          history.push(
                            ROUTES.detailIndicationTherapy.gen(
                              jsonMessage?.indicationRequestId,
                              jsonMessage?.therapyId + '',
                            ),
                          );
                        }
                      }
                    }
                    if (chargeItemNew()) {
                      if (jsonMessage?.routeType) {
                        readNotificationCashier(
                          jsonMessage?.itemReferenceId,
                          jsonMessage?.paymentId,
                          jsonMessage?.routeType,
                          jsonMessage?.patientId,
                        );
                      } else {
                        history.push(ROUTES.therapyPaymentDetail.gen(jsonMessage?.therapyId, jsonMessage?.paymentId));
                      }
                    }
                    if (requestAppointmentNew()) {
                      history.push(ROUTES.encounterCalendar.value, 'appointment _schedule_board');
                      handleClose();
                    }
                  }}
                >
                  {data?.subject}
                </Typography>
              </Box>
              {displayNotificationItem()}
            </Grid>
          </Grid>
          {/*</RawLink>*/}
        </Box>
      </Box>
      <DialogCustom
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        PaperProps={{
          style: {
            minWidth: 420,
          },
        }}
        title={'encounterDetail.confirmReceiveEncounterMessage'}
      >
        <DialogContent style={{ padding: 16 }}>
          <Typography variant="body1" component="div">
            <FormattedMessage id="encounterDetail.confirmReceiveEncounter" />
          </Typography>
        </DialogContent>
        <DialogActions style={{ padding: 16 }}>
          <Button variant="contained" color="primary" onClick={acceptDentalEncounter} style={{ minWidth: 100 }}>
            <FormattedMessage id={'ok'} />
          </Button>
        </DialogActions>
      </DialogCustom>
    </>
  );
};

export default NotificationItem;
