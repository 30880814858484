import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { push } from 'connected-react-router';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { FE_DATE_FORMAT, INDICATION_REQUEST_STATUS, some } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { ROUTES } from '../../../layout/router';
import { useMemo } from 'react';
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    '& td,th': {
      padding: 8,
    },
  },
  select: {
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'normal',
    },
  },
});

interface Props {
  data?: some[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  selectionIndication: some[];
  setSelectionIndication: (value: some[]) => void;
}

const IndicationTherapyList: React.FunctionComponent<Props> = ({
  data,
  loading,
  hookPagination,
  total,
  setSelectionIndication,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;

  const tableDataMapped = useMemo(() => {
    return data?.map((ele) => ({
      ...ele,
      createdTime: moment(ele?.createdTime).format(FE_DATE_FORMAT),
      dob: moment(ele?.dob).format(FE_DATE_FORMAT),
      patientCode: ele?.patientCode?.split('-')?.[1] || '',
    })) as some[];
  }, [data]);

  const columns = React.useMemo(() => {
    return [
      {
        title: 'therapy.code',
        dataIndex: 'referenceCode',
        align: 'right',
      },
      {
        title: 'therapy.label.customer.code.kh',
        dataIndex: 'patientCode',
      },
      {
        title: 'therapy.label.customer.name',
        dataIndex: 'patientName',
      },
      {
        title: 'birthdayShort',
        dataIndex: 'dob',
      },
      {
        title: 'labDoctor.testName',
        dataIndex: 'serviceName',
      },
      {
        title: 'indicationDate',
        dataIndex: 'createdTime',
      },
      {
        title: 'doctorOfIndication',
        dataIndex: 'picName',
      },
      {
        title: 'interpreterOfIndication',
        dataIndex: 'executeName',
      },
      {
        title: 'status',
        dataIndex: 'status',
        render: (record) => {
          return (
            <Box component="span" color={INDICATION_REQUEST_STATUS?.[record.status]?.color}>
              <FormattedMessage id={INDICATION_REQUEST_STATUS?.[record.status]?.label || ' '} />
            </Box>
          );
        },
      },
    ] as Columns[];
  }, []);

  return (
    <>
      <TableCustom
        containerProps={{
          className: classes.table,
        }}
        dataSource={tableDataMapped}
        loading={loading}
        columns={columns}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: (...val) => {
            onPageChange(...val);
            setSelectionIndication([]);
          },
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        rowProps={(record) => ({
          onClick: async (e) => {
            dispatch(push(ROUTES.detailIndicationTherapy.gen(record?.id, record?.therapyId)));
          },
          style: { cursor: 'pointer' },
        })}
      />
    </>
  );
};

export default IndicationTherapyList;
