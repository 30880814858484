import { Box, Stack, Tooltip, Typography } from '@mui/material';
import {
  PACKAGE_SCOPE,
  PATIENT_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  TYPE_REPORT_REVENUE,
} from 'modules/common/apiConstants';
import Filter from 'modules/common/component/filter';
import { DEFAULT_CONFIG } from 'modules/common/component/filter/const';
import DateInput from 'modules/common/component/form/DateInput';
import SelectInput from 'modules/common/component/form/SelectInput';
import Report from 'modules/common/component/report';
import { ReportFilterOption } from 'modules/common/component/report/type';
import Table, { TableOptions } from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import { useDialog, useFetch } from 'modules/common/hook';
import { Payment, PaymentDetail } from 'modules/schema';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormSchema, SearchParams } from './type';
import { DEFAULT_VALUES, FILTER_CONFIGS, FILTER_KEY, PAYMENT_DETAIL_TYPE, SELECT_COLUMNS_KEY } from './const';
import { formatEndDate, formatStartDate, isValidDate, maxDateTable, NumericFormatText } from 'modules/common/utils';
import { Option } from 'modules/common/type';
import RevenueReportDetailDialog from './RevenueReportDetailDialog';
import { map } from 'lodash';
import InfoIcon from '@mui/icons-material/Info';
import { BUSINESS_API } from 'modules/common/service';

interface Props {
  typeOption: Option[];
}

const RevenueReportTable: React.FunctionComponent<Props> = ({ typeOption }: Props) => {
  const form = useForm<FormSchema>({
    defaultValues: { ...DEFAULT_VALUES, typeReport: TYPE_REPORT_REVENUE.SUMMARY },
    mode: 'onChange',
  });
  const intl = useIntl();
  const [searchParams, setSearchParams] = React.useState<SearchParams>(DEFAULT_VALUES);
  const { page, pageSize, paginationProps, resetPage } = useTablePagination();
  const [openDetailDialog, onOpenDetailDialog, onCloseDetailDialog] = useDialog();
  const [payment, setPayment] = React.useState<Payment>();
  const [summaryData, setSummaryData] = useState({});
  const [summaryDetailData, setSummaryDetailData] = useState({});

  const COLUMNS: Column<Payment>[] = [
    { type: 'INDEX', width: '30px', sticky: true },
    {
      fieldName: 'createdTime',
      title: 'revenueReport.date',
      render: (record) => moment(record?.createdTime)?.format(FE_DATE_FORMAT),
      width: '120',
      sticky: true,
      renderTitle: (
        <Tooltip title={intl.formatMessage({ id: 'report.business.date.encounter.title.tooltip' })}>
          <InfoIcon
            sx={{ marginBottom: '-5px', paddingLeft: '5px', height: '20px', width: '20px', color: '#7eadff' }}
          />
        </Tooltip>
      ),
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      fieldName: 'referenceCode',
      title: 'encounterId',
      width: '100',
      sticky: true,
      render: (record) => {
        return (
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '24px',
              letterSpacing: '0.5px',
              textDecoration: 'underline',
              cursor: 'pointer',
            }}
            color={'primary'}
            onClick={() => {
              onOpenDetailDialog();
              setPayment(record);
            }}
          >
            {record.referenceCode}
          </Typography>
        );
      },
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      fieldName: 'patientName',
      title: 'therapy.debtReport.label.patientName',
      render: 'patientName',
      width: '130',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
      enableEllipsis: true,
      maxWidth: '100px',
    },
    {
      title: 'encounterGroup',
      render: (record) => {
        return (
          record.patientType && (
            <Typography fontSize={14}>
              <FormattedMessage id={PATIENT_TYPE[record?.patientType]?.label} />
            </Typography>
          )
        );
      },
      width: '130',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'revenue.report.payment.plan.title.column',
      render: (record) => {
        return (
          record.paymentPlan && (
            <Typography fontSize={14}>
              <FormattedMessage id={PAYMENT_PLAN[record?.paymentPlan]?.label} />
            </Typography>
          )
        );
      },
      width: '100',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      fieldName: 'finalTotalAmount',
      title: 'therapy.therapyDetail.printForm.title.expectedRevenue',
      render: (record) => NumericFormatText(record?.finalTotalAmount),
      width: '160',
      renderTitle: (
        <Tooltip title={intl.formatMessage({ id: 'report.revenue.expectedRevenue.tooltip' })}>
          <InfoIcon
            sx={{ marginBottom: '-5px', paddingLeft: '5px', height: '20px', width: '20px', color: '#7eadff' }}
          />
        </Tooltip>
      ),
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      fieldName: 'amountPaid',
      title: 'revenue.report.actual',
      render: (record) => NumericFormatText(record?.amountPaid),
      width: '100',
      renderTitle: (
        <Tooltip title={intl.formatMessage({ id: 'report.revenue.actual.tooltip' })}>
          <InfoIcon
            sx={{ marginBottom: '-5px', paddingLeft: '5px', height: '20px', width: '20px', color: '#7eadff' }}
          />
        </Tooltip>
      ),
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      fieldName: 'difference',
      title: 'revenue.report.difference',
      render: (record) => {
        return (
          <Typography fontSize="14px">
            {NumericFormatText((record?.finalTotalAmount || 0) - (record?.amountPaid || 0))}
          </Typography>
        );
      },
      width: '100',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      fieldName: 'status',
      title: 'cashier.paymentStatus',
      render: (record) => {
        const status = PAYMENT_STATUS[record.status!];
        return (
          <Typography fontSize={14} color={status.color}>
            <FormattedMessage id={status?.label} />
          </Typography>
        );
      },
      minWidth: '100',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'calendar.label.personInCharge',
      render: (record) => record?.picName,
      minWidth: '100',
      selectColumnConfig: {
        defaultChecked: true,
      },
      enableEllipsis: true,
      fieldName: 'picName',
      maxWidth: '100px',
    },
  ];
  const COLUMNS_DETAIL: Column<PaymentDetail>[] = [
    { type: 'INDEX', width: '2%' },
    {
      title: 'revenueReport.date',
      render: (record) => record?.createdTime?.format(FE_DATE_FORMAT),
      width: '8%',
      renderTitle: (
        <Tooltip title={intl.formatMessage({ id: 'report.business.date.encounter.title.tooltip' })}>
          <InfoIcon
            sx={{ marginBottom: '-5px', paddingLeft: '5px', height: '20px', width: '20px', color: '#7eadff' }}
          />
        </Tooltip>
      ),
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'encounterId',
      width: '10%',
      render: (record) => {
        return <Typography fontSize={14}>{record.referenceCode}</Typography>;
      },
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'therapy.debtReport.label.patientName',
      render: 'patientName',
      width: '10%',
      maxWidth: '100px',
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
      enableEllipsis: true,
      fieldName: 'patientName',
    },
    {
      title: 'lab.serviceName',
      render: (record) => record.content,
      width: '8%',
      maxWidth: '100px',
      selectColumnConfig: {
        defaultChecked: true,
      },
      enableEllipsis: true,
      fieldName: 'content',
    },
    {
      title: 'dental.dentalDetail.printForm.category.title',
      render: (record) => {
        return (
          record.type && (
            <Typography fontSize={14}>
              {record?.type === PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value ? (
                <FormattedMessage id={PACKAGE_SCOPE[record?.packageScope!]?.label} />
              ) : (
                <FormattedMessage id={PAYMENT_DETAIL_TYPE[record?.type]?.label} />
              )}
            </Typography>
          )
        );
      },
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'dental.dentalDetail.printForm.quantity.title',
      render: (record) => record.quantity,
      width: '8%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      fieldName: 'totalAmount',
      title: 'drugDispenseBook.totalPrice',
      render: (record) =>
        record.status !== PAYMENT_STATUS.CANCELLED.value && record.status !== PAYMENT_STATUS.REFUNDED.value
          ? NumericFormatText(record?.totalAmount)
          : 0,
      width: '8%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      fieldName: 'discountAmount',
      title: 'revenueReport.discountAmount',
      render: (record) =>
        record.status !== PAYMENT_STATUS.CANCELLED.value && record.status !== PAYMENT_STATUS.REFUNDED.value
          ? NumericFormatText(record?.discountAmount)
          : 0,
      width: '8%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'sellingProduct.title.VAT',
      render: (record) =>
        record.status !== PAYMENT_STATUS.CANCELLED.value && record.status !== PAYMENT_STATUS.REFUNDED.value
          ? record?.vat
          : 0,
      width: '8%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      fieldName: 'amountPaid',
      title: 'sale.totalPrice',
      render: (record) =>
        record.status !== PAYMENT_STATUS.CANCELLED.value ? NumericFormatText(record?.amountPaid) : 0,
      width: '8%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'cashier.paymentStatus',
      render: (record) => {
        const status = PAYMENT_STATUS[record.status!];
        return (
          status && (
            <Typography fontSize={14} color={status.color}>
              <FormattedMessage id={status?.label} />
            </Typography>
          )
        );
      },
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'sellingProduct.title.paymentMethod',
      render: (record) => {
        const method = PAYMENT_METHOD[record.paymentMethod!];
        return (
          method && (
            <Typography fontSize={14}>
              <FormattedMessage id={method?.label} />
            </Typography>
          )
        );
      },
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
  ];

  const [selectedColumn, setSelectedColumn] = React.useState<Column<Payment>[]>(
    COLUMNS?.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );

  const [selectedColumnDetail, setSelectedColumnDetail] = React.useState<Column<PaymentDetail>[]>(
    COLUMNS_DETAIL?.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate =
    moment(form.watch('toDate')) < moment()
      ? formatEndDate(moment(form.watch('toDate')).add(-1, 'day'))
      : formatEndDate(moment());
  const serviceType = form.watch('serviceType');
  const typeReport = form.watch('typeReport');

  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      fromDate: fromDate,
      toDate: toDate,
      page: page,
      pageSize: pageSize,
      paymentTypeList: serviceType ? map(serviceType, 'value') : [],
      paymentStatusList: searchParams.paymentStatusList || [],
    };
  }, [fromDate, page, pageSize, searchParams, serviceType, toDate]);

  const [filterReportOptions, setFilterReportOptions] = React.useState<ReportFilterOption[]>([]);
  const allOptions = intl.formatMessage({ id: 'all' });

  const { data } = useFetch(BUSINESS_API.getRevenuePaging(formatSearchParams), {
    enabled: isValidDate(fromDate, toDate, 'TABLE') && typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value,
  });
  const { data: dataPrint, isValidating } = useFetch(
    BUSINESS_API.getRevenuePaging({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    {
      globalLoading: true,
      enabled:
        openPrintDialog &&
        isValidDate(fromDate, toDate, 'TABLE') &&
        typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value,
    },
  );

  // report type detail
  const { data: dataDetail, isValidating: isValidatingDetail } = useFetch(
    BUSINESS_API.getRevenueDetailPaging(formatSearchParams),
    {
      enabled: isValidDate(fromDate, toDate, 'TABLE') && typeReport?.value === TYPE_REPORT_REVENUE.DETAIL.value,
    },
  );
  const { data: dataDetailPrint } = useFetch(
    BUSINESS_API.getRevenueDetailPaging({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    {
      globalLoading: true,
      enabled:
        openPrintDialog &&
        isValidDate(fromDate, toDate, 'TABLE') &&
        typeReport?.value === TYPE_REPORT_REVENUE.DETAIL.value,
    },
  );

  useEffect(() => {
    if (
      data &&
      data.summary &&
      Object.keys(data.summary).length > 0 &&
      !isValidating &&
      typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value
    ) {
      setSummaryData({
        ...data.summary,
        difference: (data.summary.finalTotalAmount || 0) - (data.summary.amountPaid || 0),
      });
    }
    if (
      dataDetail &&
      dataDetail.summary &&
      Object.keys(dataDetail.summary).length > 0 &&
      !isValidatingDetail &&
      typeReport?.value === TYPE_REPORT_REVENUE.DETAIL.value
    ) {
      setSummaryDetailData(dataDetail.summary);
    }
  }, [data, dataDetail, isValidating, isValidatingDetail, typeReport?.value]);

  const REVENUE_OPTIONS = useMemo(() => {
    let tableOptions: TableOptions = {
      position: 'top',
      startIndex: COLUMNS.length === selectedColumn.length ? 4 : 0,
      record: summaryData,
      messageKey: 'common.table.summary',
    };
    return tableOptions;
  }, [COLUMNS.length, selectedColumn.length, summaryData]);

  const REVENUE_DETAIL_OPTIONS = useMemo(() => {
    let tableOptions: TableOptions = {
      position: 'top',
      startIndex: COLUMNS_DETAIL.length === selectedColumnDetail.length ? 6 : 0,
      record: summaryDetailData,
      messageKey: 'common.table.summary',
    };
    return tableOptions;
  }, [COLUMNS_DETAIL.length, selectedColumnDetail.length, summaryDetailData]);

  const exportUrl = React.useMemo(
    () => BUSINESS_API.getRevenueExcel({ ...formatSearchParams, page: 0, pageSize: 10000 }),
    [formatSearchParams],
  );

  // report type summary
  React.useEffect(() => {
    setFilterReportOptions([
      { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      {
        title: DEFAULT_CONFIG.SERVICE.title,
        value:
          serviceType && serviceType.length !== 0
            ? serviceType?.map((ele) => intl.formatMessage({ id: ele.label })).join(', ')
            : allOptions,
      },
      { title: DEFAULT_CONFIG.ENCOUNTER_STATUS.title, value: allOptions },
    ]);
  }, [allOptions, fromDate, toDate, serviceType, intl]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title
        title="revenueReport.list"
        showSubTitle
        foundResultAmount={
          typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value ? data?.content.length : dataDetail?.content.length
        }
        totalElementAmount={
          typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value
            ? data?.pagination.totalElements
            : dataDetail?.pagination.totalElements
        }
        renderRight={
          <Stack direction="row" alignItems="center" justifyContent="center" gap="16px" paddingTop={2.5}>
            <Report.SearchInput
              form={form}
              name="keyword"
              onSearch={() => {
                setSearchParams((prev) => ({ ...prev, keyword: form.getValues('keyword') }));
                resetPage();
              }}
            />
          </Stack>
        }
      />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue, filterConfig) => {
          setSearchParams((prev) => ({
            ...prev,
            paymentStatusList: filterValue.PAYMENT_STATUS,
            patientTypeList: filterValue.PATIENT_TYPE,
            picUserIdList: filterValue.EMPLOYEE,
            paymentPlanList: filterValue.PAYMENT_PLAN,
          }));
          setFilterReportOptions([
            { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            {
              title: DEFAULT_CONFIG.SERVICE.title,
              value:
                serviceType && serviceType.length !== 0
                  ? serviceType?.map((ele) => intl.formatMessage({ id: ele.label })).join(', ')
                  : allOptions,
            },
            {
              title: DEFAULT_CONFIG.PAYMENT_STATUS.title,
              value:
                filterConfig.PAYMENT_STATUS?.length === 0
                  ? allOptions
                  : filterConfig.PAYMENT_STATUS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
            {
              title: DEFAULT_CONFIG.PATIENT_TYPE.title,
              value:
                filterConfig.PATIENT_TYPE?.length === 0
                  ? allOptions
                  : filterConfig.PATIENT_TYPE.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
          ]);
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" gap="13px">
              <SelectInput
                form={form}
                name="typeReport"
                options={Object.values(TYPE_REPORT_REVENUE)}
                getValue="value"
                renderLabel="label"
                label="report.type.label"
                placeholder="report.type.label"
                onChange={resetPage}
              />
              <SelectInput
                form={form}
                name="serviceType"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                showCheckBox
                multiple
                onChange={resetPage}
              />
              <DateInput
                label="fromDate"
                form={form}
                name="fromDate"
                mode="start"
                required
                maxDate={moment() && moment(toDate)}
                format={FE_DATE_FORMAT}
                type="TABLE"
                onChange={resetPage}
              />
              <DateInput
                required
                label="toDate"
                form={form}
                name="toDate"
                mode="end"
                maxDate={maxDateTable(fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                format={FE_DATE_FORMAT}
                type="TABLE"
                onChange={resetPage}
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() => {
                    form.reset();
                    setSearchParams(DEFAULT_VALUES);
                    resetPage();
                  }}
                />
              </Box>
            </Stack>
            {typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value && <Filter.View />}
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            {typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value && <Filter.Button />}
            {typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value ? (
              <Report.SelectColumnButton
                columns={COLUMNS}
                onSave={setSelectedColumn}
                localStorageKey={SELECT_COLUMNS_KEY}
              />
            ) : (
              <Report.SelectColumnButton
                columns={COLUMNS_DETAIL}
                onSave={setSelectedColumnDetail}
                localStorageKey={SELECT_COLUMNS_KEY}
              />
            )}

            <Report.ExportDataButton exportUrl={exportUrl} />
            <Report.PrintDataButton
              title="revenueReport.list"
              openDialog={openPrintDialog}
              onOpenDialog={onOpenPrintDialog}
              onCloseDialog={onClosePrintDialog}
              content={
                <Stack direction="column" gap={1}>
                  <Report.FilterPrintFormat filterOptions={filterReportOptions} />
                  {typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value ? (
                    <Table
                      data={dataPrint?.content}
                      columns={selectedColumn}
                      dataKey="id"
                      rowProps={{ sx: { pageBreakAfter: 'auto' } }}
                    />
                  ) : (
                    <Table
                      data={dataDetailPrint?.content}
                      columns={selectedColumnDetail}
                      dataKey="id"
                      rowProps={{ sx: { pageBreakAfter: 'auto' } }}
                    />
                  )}
                </Stack>
              }
            />
          </Stack>
        </Stack>
      </Filter>
      {typeReport?.value === TYPE_REPORT_REVENUE.SUMMARY.value ? (
        <Table
          columns={selectedColumn}
          dataKey="id"
          data={data?.content}
          paginationProps={paginationProps}
          totalElements={data?.pagination.totalElements}
          stickyHeader
          maxHeigth="100%"
          options={REVENUE_OPTIONS}
        />
      ) : (
        <Table
          columns={selectedColumnDetail}
          dataKey="id"
          data={dataDetail?.content}
          paginationProps={paginationProps}
          totalElements={dataDetail?.pagination.totalElements}
          stickyHeader
          maxHeigth="100%"
          options={REVENUE_DETAIL_OPTIONS}
        />
      )}

      {openDetailDialog && payment && (
        <RevenueReportDetailDialog open={openDetailDialog} onClose={onCloseDetailDialog} payment={payment} />
      )}
    </Stack>
  );
};

export default RevenueReportTable;
