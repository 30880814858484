import { Box, Grid, Skeleton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, FE_DATE_TIME_FORMAT, PAYMENT_PLAN } from '../../../common/constants';
import { Encounter } from 'modules/schema';
import { GENDER, PAYMENT_STATUS } from 'modules/common/apiConstants';
import PaymentHistoryButton from './PaymentHistoryButton';

interface Props {
  encounter?: Encounter;
}

const BasicEOCInfo: React.FunctionComponent<Props> = ({ encounter }) => {
  return (
    <Grid container spacing={1} minHeight={'14vh'}>
      <Grid item xl={3} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="encounterId" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? encounter.code : <Skeleton width={100} />}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="therapy.label.customer.code" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? encounter?.patient?.formatCode : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={3} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="birthdayShort" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? (
              moment(encounter?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="age_" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? `${encounter?.patient?.age} (${encounter?.patient?.month})` : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>{' '}
      <Grid item xl={3} md={4}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="gender" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? (
              encounter.patient?.gender && <FormattedMessage id={GENDER[encounter?.patient?.gender!]?.label} />
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="telephone" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? encounter?.patient?.mobilePhone : <Skeleton width={100} />}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={true}>
        <Box display="flex" alignItems="center">
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="address" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? encounter?.patient?.homeAddress?.address : <Skeleton width={100} />}
          </Typography>
        </Box>
        <Box display="flex" alignItems="center" marginTop={1}>
          <Typography variant="body2" color="textSecondary" noWrap width={125}>
            <FormattedMessage id="paymentCategory" />
          </Typography>
          <Typography variant="subtitle2" noWrap>
            {encounter ? (
              <FormattedMessage id={PAYMENT_PLAN[encounter?.paymentPlan!]?.label || ' '} />
            ) : (
              <Skeleton width={100} />
            )}
          </Typography>
        </Box>
      </Grid>
      <Grid item xl={12} md={12}>
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <Stack direction="row" justifyContent="start" alignItems="center" gap={2}>
            <Typography variant="subtitle1" color="primary" fontSize="21px" noWrap>
              {encounter?.patient?.name}
            </Typography>
            <Typography variant="subtitle1" color="primary" fontSize="21px" noWrap>
              {`-`}
            </Typography>
            <Box display="flex">
              <Typography variant="subtitle2" color="textSecondary" noWrap>
                <FormattedMessage id="encounterDate" />
                &nbsp;
              </Typography>
              <Typography variant="subtitle2" noWrap>
                {encounter?.createdTime && encounter?.createdTime?.format(FE_DATE_TIME_FORMAT)}
              </Typography>
            </Box>
            <Typography variant="subtitle1" color="primary" fontSize="21px" noWrap>
              {`-`}
            </Typography>
            <Typography noWrap sx={{ color: PAYMENT_STATUS[encounter?.payment?.status!]?.color, fontWeight: '500' }}>
              <FormattedMessage id={PAYMENT_STATUS[encounter?.payment?.status!]?.label || ' '} />
            </Typography>
          </Stack>
          <PaymentHistoryButton encounter={encounter} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default BasicEOCInfo;
