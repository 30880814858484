import { Box } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { push } from 'connected-react-router';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, INDICATION_REQUEST_STATUS } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { ROUTES } from '../../../layout/router';
import { IndicationRequest } from 'modules/schema';
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    '& td,th': {
      padding: 8,
    },
  },
  select: {
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'normal',
    },
  },
});

interface Props {
  data?: IndicationRequest[];
  loading: boolean;
  changeStatusTest: (value) => void;
  hookPagination: HookPaginationProps;
  total: number;
}

const IndicationList: React.FunctionComponent<Props> = ({ data, loading, hookPagination, total, changeStatusTest }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const tableDataMapped = React.useMemo(() => {
    return data?.map((ele) => ({
      ...ele,
      createdTime: ele?.startTime?.format(FE_DATE_FORMAT),
      dob: moment(ele?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT),
    })) as IndicationRequest[];
  }, [data]);

  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounterId',
        dataIndex: 'referenceCode',
        align: 'right',
      },
      {
        title: 'imageDoctor.patientCode',
        dataIndex: 'formatPatientCode',
        align: 'right',
      },
      {
        title: 'birthdayShort',
        dataIndex: 'dob',
      },
      {
        title: 'imageDoctor.patientName',
        dataIndex: 'patientName',
      },

      {
        title: 'imageDoctor.testName',
        dataIndex: 'serviceName',
      },
      {
        title: 'indicationDate',
        dataIndex: 'createdTime',
      },
      {
        title: 'doctorOfIndication',
        dataIndex: 'picName',
      },
      {
        title: 'interpreterOfIndication',
        dataIndex: 'executeName',
      },
      {
        title: 'status',
        dataIndex: 'status',
        render: (record) => {
          return (
            <Box component="span" color={INDICATION_REQUEST_STATUS?.[record.status]?.color}>
              <FormattedMessage id={INDICATION_REQUEST_STATUS?.[record.status]?.label || ' '} />
            </Box>
          );
        },
      },
    ] as Columns[];
  }, []);

  return (
    <>
      <TableCustom
        containerProps={{
          className: classes.table,
        }}
        dataSource={tableDataMapped}
        loading={loading}
        columns={columns}
        rowProps={(record) => ({
          onClick: async () => {
            if (record.id && record.patientId && record.referenceId)
              dispatch(push(ROUTES.detailIndication.gen(record?.id, record?.patientId, record.referenceId)));
          },
          style: { cursor: 'pointer' },
        })}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
    </>
  );
};

export default IndicationList;
