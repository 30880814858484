import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Prompt, useHistory } from 'react-router-dom';
import ConfirmDialog from './ConfirmDialog';

const initBeforeUnLoad = (showExitPrompt) => {
  window.onbeforeunload = (event) => {
    if (showExitPrompt) {
      const e = event || window.event;
      e.preventDefault();
      if (e) {
        e.returnValue = '';
      }
      return '';
    }
  };
};

// Hook
export function useExitPrompt(bool) {
  const [showExitPrompt, setShowExitPrompt] = useState(bool);

  useEffect(() => {
    initBeforeUnLoad(showExitPrompt);
    return () => {
      initBeforeUnLoad(false);
    };
  }, [showExitPrompt]);

  return [showExitPrompt, setShowExitPrompt];
}

interface Props {
  when?: boolean | undefined;
  navigate?: (path: string) => void;
  shouldBlockNavigation?: (location: Location) => boolean;
  message?: React.ReactNode;
}
const RouteLeavingGuard = ({ when, navigate, shouldBlockNavigation, message }: Props) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const history = useHistory();
  const closeModal = () => {
    setModalVisible(false);
  };
  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && ((shouldBlockNavigation && shouldBlockNavigation(nextLocation)) || when)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    return true;
  };
  const handleConfirmNavigationClick = () => {
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  const [, setShowExitPrompt] = useExitPrompt(when);
  useEffect(() => {
    setShowExitPrompt(when);
  }, [setShowExitPrompt, when]);

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      if (navigate) {
        navigate(lastLocation.pathname);
      } else {
        history.push(lastLocation);
      }
    }
  }, [confirmedNavigation, history, lastLocation, navigate]);

  return (
    <>
      <Prompt when={when} message={handleBlockedNavigation} />
      <ConfirmDialog
        title={<FormattedMessage id="notify" />}
        content={message}
        okId="continue"
        open={modalVisible}
        onCancel={closeModal}
        onOK={handleConfirmNavigationClick}
      />
    </>
  );
};
export default RouteLeavingGuard;
