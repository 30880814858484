import { Box, Divider, Paper, Tab, Tabs, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ClinicsAdminInfo from '../component/clinic/ClinicsAdminInfo';
import ClinicsInfo from '../component/clinic/ClinicsInfo';

interface Props {}

const ClinicsInfoPage: React.FunctionComponent<Props> = (props) => {
  const [tab, setTab] = React.useState('clinicsAdmin');

  return (
    <Paper elevation={1} style={{ flex: 1, overflow: 'auto' }}>
      <Box>
        <Typography variant="h5" style={{ marginTop: 16, marginLeft: 16 }}>
          <FormattedMessage id="clinics.generalInfo" />
        </Typography>
        <Tabs
          value={tab}
          onChange={(e, newValue) => {
            if (newValue) {
              setTab(newValue);
            }
          }}
          variant="scrollable"
          indicatorColor="secondary"
          textColor="secondary"
          style={{ padding: '0px 8px' }}
        >
          <Tab
            value={'clinicsAdmin'}
            style={{ minWidth: 100 }}
            label={
              <Typography variant="subtitle2">
                <FormattedMessage id="clinicsAdmin" />
              </Typography>
            }
          />
          <Tab
            value={'clinics'}
            style={{ minWidth: 100 }}
            label={
              <Typography variant="subtitle2">
                <FormattedMessage id="clinics" />
              </Typography>
            }
          />
        </Tabs>
      </Box>
      <Divider />
      <Box p={2}>
        <div style={{ display: tab === 'clinicsAdmin' ? 'unset' : 'none' }}>
          <ClinicsAdminInfo />
        </div>
        <div style={{ display: tab === 'clinics' ? 'unset' : 'none' }}>
          <ClinicsInfo />
        </div>
      </Box>
    </Paper>
  );
};

export default ClinicsInfoPage;
