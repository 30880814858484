import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import LoadingIcon from '../../../common/component/LoadingIcon';
import NoDataBox from '../../../common/component/NoDataBox';
import { some } from '../../../common/constants';
import TherapyTable from './TherapyTable';
import SearchTherapy from './SearchTherapy';
import { FilterParams } from './SearchTherapy/schema';
import { Service } from 'modules/schema';

interface Props {
  data: some;
  loading?: boolean;
  setFormData: (value: some) => void;
  setFormDataGroup: (value: some) => void;
  onChangeStatus: (value: some) => void;
  onDeleteGroup: (value: some) => void;
  onDeleteServices: (services: Service[]) => void;
  searchParams: some;
  setSearchParams: (value: FilterParams) => void;
  revalidate: () => void;
}

const TherapyListTable: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    setFormData,
    setFormDataGroup,
    onChangeStatus,
    onDeleteGroup,
    onDeleteServices,
    searchParams,
    setSearchParams,
    loading,
  } = props;

  data?.sort((a: some, b: some) => a.name.localeCompare(b.name));
  if (!loading && data && data?.length === 0 && !searchParams?.name) {
    return (
      <Box padding={2}>
        <Typography variant="h6">
          <FormattedMessage id="therapy.list" />
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          height="50vh"
          overflow="auto"
        >
          <Typography variant="h5" color="primary">
            <FormattedMessage id="therapy.empty" />
          </Typography>

          <Box padding={2}>
            <Typography variant="body1">
              <FormattedMessage id="addNewList" />
            </Typography>
          </Box>
          <Box display="flex">
            <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setFormDataGroup({})}>
              <FormattedMessage id="add" />
            </Button>
          </Box>
        </Box>
      </Box>
    );
  }
  const dataChecked = data?.filter((d) => !(searchParams?.name && (!d.items || d.items?.length <= 0)));
  return (
    <>
      <Box padding={2} paddingBottom={1}>
        <SearchTherapy
          onAddNew={() => setFormDataGroup({})}
          onSubmit={(value) => {
            setSearchParams(value);
          }}
          formData={searchParams}
        />
      </Box>
      {loading ? (
        <LoadingIcon />
      ) : (
        <Box padding={2} paddingTop={0} overflow="auto">
          {dataChecked.length > 0 ? (
            dataChecked?.map((value: some) => {
              return (
                <TherapyTable
                  key={value.id}
                  setFormData={setFormData}
                  setFormDataGroup={setFormDataGroup}
                  onChangeStatus={onChangeStatus}
                  onDeleteGroup={onDeleteGroup}
                  onDeleteServices={onDeleteServices}
                  data={value}
                />
              );
            })
          ) : (
            <NoDataBox />
          )}
        </Box>
      )}
    </>
  );
};

export default TherapyListTable;
