import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';
import {
  DISCOUNT_TYPE,
  PACKAGE_SCOPE,
  PAYMENT_DETAIL_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
} from 'modules/common/apiConstants';

export const PaymentDetailSchema = z
  .object({
    id: z.number(),
    groupId: z.number(),
    paymentId: z.number(),
    content: z.string(),
    quantity: z.number(),
    unitPrice: z.number(),
    unit: z.string(),
    packageScope: createObjectKeysEnumSchema(PACKAGE_SCOPE),
    discount: z.number(),
    discountType: createObjectKeysEnumSchema(DISCOUNT_TYPE),
    discountAmount: z.number(),
    billDiscountPercent: z.number(),
    billDiscountAmount: z.number(),
    totalAmount: z.number(),
    vat: z.number(),
    vatAmount: z.number(),
    amountPaid: z.number(),
    paymentMethod: createObjectKeysEnumSchema(PAYMENT_METHOD),
    referenceId: z.number(),
    refundId: z.number(),
    type: createObjectKeysEnumSchema(PAYMENT_DETAIL_TYPE),
    status: createObjectKeysEnumSchema(PAYMENT_STATUS),
    updatedTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    totalDiscountAmount: z.number(),
    note: z.string(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    referenceCode: z.string(),
    patientName: z.string(),
  })
  .partial()
  .transform((paymentDetail) => ({
    ...paymentDetail,
    status: paymentDetail?.refundId ? PAYMENT_STATUS.REFUNDED.value : paymentDetail.status,
  }));

export type PaymentDetail = z.infer<typeof PaymentDetailSchema>;
