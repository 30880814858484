import DialogCustom from '../../../../../common/component/DialogCustom';
import PrintFormContent from './PrintFormContent';
import { some } from '../../../../../common/constants';

interface Props {
  open: boolean;
  onClose: () => void;
  therapyDetail: some;
  paymentDetail: some;
}
const SummaryPaymentPrintForm = ({ open, onClose, therapyDetail, paymentDetail }: Props) => {
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="cashier.printTableBill.title"
      PaperProps={{
        style: { width: '1092px' },
      }}
      maxWidth="xl"
    >
      <PrintFormContent onClose={onClose} therapyDetail={therapyDetail} paymentDetail={paymentDetail} />
    </DialogCustom>
  );
};

export default SummaryPaymentPrintForm;
