import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { DISCOUNT_TYPE, PAYMENT_METHOD, RECEIPT_TYPE } from 'modules/common/apiConstants';

export const SaleRevenueReportSchema = z
  .object({
    id: z.number(),
    amountPaid: z.number(),
    code: z.string(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    customerName: z.string(),
    discount: z.number(),
    discountType: createObjectKeysEnumSchema(DISCOUNT_TYPE),
    paymentMethod: createObjectKeysEnumSchema(PAYMENT_METHOD),
    phoneNumber: z.string(),
    receiptType: createObjectKeysEnumSchema(RECEIPT_TYPE),
  })
  .partial();

export type SaleRevenueReport = z.infer<typeof SaleRevenueReportSchema>;
