import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, FormControlLabel, Grid, Stack, Typography } from '@mui/material';
import DialogCustom from './DialogCustom';
import { FormattedMessage } from 'react-intl';
import { Column } from './table/type';
import { get } from 'lodash';
import { useEffect } from 'react';
import { z } from 'zod';

interface Props<T> {
  open: boolean;
  onClose: () => void;
  readonly columns: Column<T>[];
  onSave: (selectedColumn: Column<T>[]) => void;
  localStorageKey?: string;
}

const SelectColumnSchema = z.object({
  columns: z.array(
    z.object({
      disabled: z.boolean(),
      checked: z.boolean(),
      title: z.string(),
    }),
  ),
});

function SelectColumnDialog<T>({ columns, onClose, open, onSave, localStorageKey }: Props<T>) {
  const { control, reset, setValue, getValues } = useForm({
    defaultValues: {
      columns:
        columns?.map((column) => {
          if (column.selectColumnConfig) {
            return {
              disabled: !!column.selectColumnConfig?.disabled,
              checked: !!column.selectColumnConfig?.defaultChecked,
              title: get(column, 'title', ' ') as string,
            };
          }
          return {
            disabled: true,
            checked: true,
            title: ' ',
          };
        }) || [],
    },
  });

  const { fields } = useFieldArray({
    control: control,
    name: 'columns',
  });

  const onCloseDialog = () => {
    onClose();
    reset();
  };

  useEffect(() => {
    if (localStorageKey) {
      const storageData = localStorage.getItem(localStorageKey);
      const parsedData = SelectColumnSchema.safeParse(storageData);
      if (parsedData.success) {
        reset(parsedData.data);
        onSave(parsedData.data?.columns?.filter((column) => column.checked)?.map((_, index) => columns[index]));
      }
    }
    // prevent onSave change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columns, localStorageKey, reset]);

  return (
    <DialogCustom
      open={open}
      keepMounted={false}
      maxWidth="xs"
      onClose={onCloseDialog}
      PaperProps={{
        style: { minWidth: 720 },
      }}
      title={
        <Box display={'flex'} alignItems="center">
          <Typography variant="h6" color="primary">
            <FormattedMessage id="encounterBook.column" />
          </Typography>
          <FormControlLabel
            style={{ marginLeft: 24 }}
            control={
              <Checkbox
                color="primary"
                checked={fields?.every((column) => column.checked)}
                onChange={(event) =>
                  setValue(
                    'columns',
                    fields?.map((column) => ({
                      ...column,
                      checked: column.disabled ? column.checked : event.target.checked,
                    })),
                  )
                }
              />
            }
            label={
              <Typography variant="body2">
                <FormattedMessage id="all" />
              </Typography>
            }
          />
        </Box>
      }
    >
      <Box sx={{ padding: '8px 24px' }}>
        <Grid container>
          {fields?.map((field, index) => {
            if (!columns[index].selectColumnConfig) {
              return null;
            }
            return (
              <Grid item xs={6} key={field.id}>
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name={`columns.${index}.checked`}
                      render={({ field: { onChange, value } }) => (
                        <Checkbox
                          color="primary"
                          disabled={field.disabled}
                          checked={value}
                          onChange={(event) => onChange(event.target.checked)}
                        />
                      )}
                    />
                  }
                  label={
                    <Typography variant="body2">
                      <FormattedMessage id={field?.title} />
                    </Typography>
                  }
                />
              </Grid>
            );
          })}
        </Grid>
        <Stack direction="row" justifyContent="flex-end" gap={2}>
          <Button onClick={onCloseDialog} variant="outlined">
            <FormattedMessage id="cancel" />
          </Button>
          <Button
            onClick={() => {
              const formData = getValues();
              reset(formData);
              onSave(
                formData?.columns?.reduce<Column<T>[]>((array, current, currentIndex) => {
                  if (current.checked) {
                    return [...array, columns[currentIndex]];
                  }
                  return array;
                }, []),
              );
              if (localStorageKey) {
                localStorage.setItem(localStorageKey, JSON.stringify(formData));
              }
              onClose();
            }}
          >
            <FormattedMessage id="confirm" />
          </Button>
        </Stack>
      </Box>
    </DialogCustom>
  );
}

export default SelectColumnDialog;
