import { Typography } from '@mui/material';
import * as React from 'react';
import DialogCustomFullScreen from '../../common/component/DialogCustomFullScreen';
import TestServiceTab from '../component/detail/testService/TestServiceTab';
import { Encounter, IndicationRequest } from 'modules/schema';

interface Props {
  open: boolean;
  onClose?: () => void;
  indicationRequest: IndicationRequest;
  encounter: Encounter;
  revalidateIndicationList: () => void;
}

const DialogLabDoctorIndication: React.FunctionComponent<Props> = ({
  open,
  onClose,
  indicationRequest,
  encounter,
  revalidateIndicationList,
}) => {
  return (
    <DialogCustomFullScreen
      open={open}
      onClose={onClose}
      title={<Typography variant="h6">{encounter?.patient?.name}</Typography>}
    >
      <TestServiceTab
        indicationRequest={indicationRequest}
        onClose={onClose}
        revalidateIndicationList={revalidateIndicationList}
      />
    </DialogCustomFullScreen>
  );
};

export default DialogLabDoctorIndication;
