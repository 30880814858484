import { GENDER, PATIENT_ADDRESS_TYPE, PATIENT_CONTACT_TYPE } from 'modules/common/apiConstants';
import { BE_DATE_FORMAT } from 'modules/common/constants';
import { Option } from 'modules/common/type';
import { formatOption } from 'modules/common/utils';
import { Encounter, Patient, UserEmployeeRole } from 'modules/schema';
import moment from 'moment';
import { AppState } from 'redux/reducer';

export const formatEncounterData = (data, doctors: UserEmployeeRole[]) => {
  return {
    serviceId: data?.service?.value,
    serviceName: data?.service?.label,
    physicsRoomId: data?.physicsRoom,
    picUserId: data?.doctor,
    picName: doctors?.find((doctor) => doctor?.id === data?.doctor)?.employeeFullName,
    paymentPlan: data?.paymentPlan,
    reason: data?.reason,
    patient: {
      id: data?.patientId,
      name: data?.name,
      identifierCode: data?.identifierCode,
      email: data?.email,
      mobilePhone: data?.mobilePhone,
      gender: data?.gender,
      dob: data?.dob
        ? moment(data?.dob).format(BE_DATE_FORMAT)
        : moment()
            .subtract(parseInt(data?.age || 0), 'year')
            .subtract(parseInt(data?.month), 'month')
            .set(data?.age && 'date', 1)
            .set(data?.age && !data?.month && 'month', 0)
            .format(BE_DATE_FORMAT),
      job: data?.job,
      ethnic: data?.ethnic?.label,
      nationality: data?.nationality?.label,
      patientAddressList: [
        {
          address: data?.jobAddress,
          type: PATIENT_ADDRESS_TYPE.WORK,
        },
        {
          address: data?.address,
          district: formatOption(data?.district),
          province: formatOption(data?.province),
          type: PATIENT_ADDRESS_TYPE.HOME,
        },
      ],
      patientContactInfoList: [
        {
          type: PATIENT_CONTACT_TYPE.PERSON_IN_CONTACT,
          address: data?.contactAddress,
          name: data?.contactName,
          mobilePhone: data?.contactTelephone,
        },
      ],
    },
  };
};

export function formatPatientDataToForm(patient: Patient, appState: AppState) {
  const birthday = patient?.dob && moment(patient?.dob, BE_DATE_FORMAT);
  const contactInfo = patient?.patientContactInfoList?.[0];
  const workAddress = patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.WORK);
  const homeAddress = patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.HOME);

  const ethnic = appState?.common?.ethnicList?.find((ele) => ele.label === patient?.ethnic);
  const country = appState?.common?.countryList?.find((ele) => ele.label === patient?.nationality);
  const province = appState?.common?.provinceData?.find(
    (ele) => formatOption(ele as { value: string; label: string }) === homeAddress?.province,
  );
  const district = province?.districts?.find((ele) => formatOption(ele) === homeAddress?.district);
  return {
    note: patient?.note,
    patientId: patient?.id,
    name: patient?.name,
    mobilePhone: patient?.mobilePhone,
    code: patient?.code?.split('-')?.[1],
    dob: birthday,
    gender: GENDER[patient?.gender!]?.value,
    ethnic: ethnic,
    email: patient?.email,
    jobAddress: workAddress?.address,
    address: homeAddress?.address,
    job: patient?.job,
    identifierCode: patient?.identifierCode,
    nationality: country,
    province: province,
    district: district,
    contactName: contactInfo?.name,
    contactTelephone: contactInfo?.mobilePhone,
    contactAddress: contactInfo?.address,
  };
}

export function formatEncounterDataToForm(
  encounter: Encounter,
  appState: AppState,
  systemCategoryOptions: Option[],
  serviceOptions: Option[],
) {
  return {
    systemCategory: systemCategoryOptions?.find(
      (systemCategory) => systemCategory?.value === encounter?.parentSystemCategoryId,
    ),
    service: serviceOptions?.find((service) => service?.value === encounter?.serviceId),
    physicsRoom: encounter?.physicsRoom?.id,
    doctor: encounter?.picUserId,
    paymentPlan: encounter?.paymentPlan,
    reason: encounter?.reason,
    ...formatPatientDataToForm(encounter?.patient!, appState),
  };
}
