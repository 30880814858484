import { connectRouter, RouterState } from 'connected-react-router';
import { History } from 'history';
import { combineReducers } from 'redux';
import intlReducer, { IntlState } from '../modules/intl/redux/intlReducer';
import commonReducer, { CommonState } from '../modules/common/redux/commonReducer';
import authenReducer, { AuthenState } from '../modules/authen/redux/authenReducer';
import receptionReducer, { ReceptionState } from '../modules/reception/redux/receptionReducer';

export interface AppState {
  router: RouterState;
  intl: IntlState;
  common: CommonState;
  authen: AuthenState;
  reception: ReceptionState;
}

export default function createRootReducer(history: History) {
  return combineReducers({
    router: connectRouter(history),
    intl: intlReducer,
    common: commonReducer,
    authen: authenReducer,
    reception: receptionReducer,
  });
}
