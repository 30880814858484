import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT, THERAPY_SESSION_STATUS } from '../common/constants';
import { AttendPersonSchema } from './AttendPerson';

export const TherapyWorkReportSchema = z
  .object({
    id: z.number(),
    patientId: z.number(),
    patientCode: z.string(),
    patientName: z.string(),
    diagnosis: z.string(),
    therapyId: z.number(),
    therapyCode: z.string(),
    therapyName: z.string(),
    therapySessionName: z.string(),
    therapySessionStatus: createObjectKeysEnumSchema(THERAPY_SESSION_STATUS),
    picUserId: z.number(),
    picUserName: z.string(),
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    endTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    conclude: z.string(),
    attendPersons: z.array(AttendPersonSchema),
  })
  .partial();

export type TherapyWorkReport = z.infer<typeof TherapyWorkReportSchema>;
