import { FilterType } from './type';
import { Box, Button, Chip, Popover, Stack, Typography } from '@mui/material';
import { FormattedMessage, useIntl } from 'react-intl';
import useFilterContext from './useFilterContext';
import CheckboxList from './CheckboxList';
import React, { useCallback } from 'react';
import { FilterTitle } from './style';
import Employee from './type/Employee';
import Icd10 from './type/Icd10';
import MedicalSupplyGroup from './type/MedicalSupplyGroup';
import MedicationCategory from './type/MedicationCategory';
import Service from './type/Service';
import MedicationSuppliers from './type/MedicationSuppliers';
import Medication from './type/Medication';

const FilterViewItem = ({ filter }: { filter: FilterType }) => {
  const intl = useIntl();
  const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);

  const handleClick: React.MouseEventHandler<HTMLDivElement> = (event) => {
    setAnchorEl(event.currentTarget);
    form.reset();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const { filterValue, onFilterChange, form, defaultValues } = useFilterContext();
  const renderValue = useCallback(
    (filter: FilterType) => {
      switch (filter.type) {
        case 'EMPLOYEE': {
          return <Employee filter={filter} />;
        }
        case 'SERVICE': {
          return <Service filter={filter} />;
        }
        case 'ICD10': {
          return <Icd10 filter={filter} />;
        }
        case 'MEDICAL_SUPPLY_GROUP': {
          return <MedicalSupplyGroup filter={filter} />;
        }
        case 'MEDICATION_CATEGORY': {
          return <MedicationCategory filter={filter} />;
        }
        case 'MEDICAL_SUPPLIERS': {
          return <MedicationSuppliers filter={filter} />;
        }
        case 'MEDICATION': {
          return <Medication filter={filter} />;
        }
        case 'INDICATION_REQUEST_STATUS':
        case 'ENCOUNTER_STATUS':
        case 'DEBT_STATUS':
        case 'PAYMENT_METHODS':
        case 'INDICATION_REQUEST_SCOPE':
        case 'APPOINTMENT_STATUS':
        case 'PATIENT_TYPE':
        case 'APPOINTMENT_PATIENT_TYPE':
        case 'PAYMENT_STATUS':
        case 'TRANSACTION_TYPE':
        case 'PAYMENT_PLAN': {
          return (
            <CheckboxList
              xs={12}
              name={filter.type}
              getOptionLabel={(option) => intl.formatMessage({ id: option.label })}
            />
          );
        }
        default:
          return null;
      }
    },
    [intl],
  );
  if (!filterValue) return null;
  return (
    <>
      <Chip
        key={filter.type}
        onClick={handleClick}
        sx={{
          backgroundColor: '#E0ECFF',
          border: open ? '1px solid #307BFF' : '1px solid transparent',
          '& .MuiChip-deleteIcon': { color: '#307BFF' },
        }}
        label={
          <Typography
            sx={{
              fontFamily: 'Roboto',
              color: '#263238',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              lineHeight: '24px',
              letterSpacing: '0.25px',
            }}
          >
            {intl.formatMessage({ id: filter.title })}: ({filterValue[filter.type].length})
          </Typography>
        }
        onDelete={() => {
          form.setValue(filter.type, defaultValues[filter.type]);
          onFilterChange(form.getValues());
        }}
      />
      <Popover
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        marginThreshold={3}
      >
        <Stack direction="column" gap="10px" padding={1} sx={{ maxWidth: '300px', minWidth: '250px' }}>
          <FilterTitle>
            <FormattedMessage id={filter.title} />
          </FilterTitle>
          <Box>{renderValue(filter)}</Box>
          <Button
            sx={{ alignSelf: 'flex-end' }}
            onClick={() => {
              onFilterChange(form.getValues());
              handleClose();
            }}
          >
            <FormattedMessage id="filter.popup.title.button.filter" />
          </Button>
        </Stack>
      </Popover>
    </>
  );
};

export default FilterViewItem;
