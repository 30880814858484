import { Paper, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../common/api';
import { INDICATION_REQUEST_STATUS, INDICATION_REQUEST_TYPE, SYSTEM_CATEGORY_SCOPE } from '../../common/constants';
import usePaginationHook from '../../common/hook/usePaginationHook';
import { formatDateFilterField } from '../../common/utils';
import { IndicationRequest } from 'modules/schema';
import FilterIndicationBox from '../component/indication/FilterEncounterBox';
import IndicationDentalList from '../component/dental/IndicationDentalList';
import IndicationPrintForm from '../component/dental/dentalList/IndicationPrintForm';
import { useFetch } from 'modules/common/hook';

interface Props {}

export const INDICATION_REQUEST_STATUS_OPTIONS = [
  INDICATION_REQUEST_STATUS.REQUESTED,
  INDICATION_REQUEST_STATUS.FINISHED,
];

const LabDoctorIndicationDentalList: React.FunctionComponent<Props> = () => {
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;

  const [selectionIndication, setSelectionIndication] = React.useState<IndicationRequest[]>([]);
  const [open, setOpen] = React.useState(false);

  const { data: pageData, isValidating } = useFetch(
    API_SERVER.indicationRequest.getList({
      ...formatDateFilterField(params, 'fromDate', 'toDate'),
      scope: SYSTEM_CATEGORY_SCOPE.laboratory,
      type: INDICATION_REQUEST_TYPE.DENTAL_EXAM,
      statusList: params?.status?.join(',') || null,
    }),
  );

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterIndicationBox
        hookPagination={hookPagination}
        onPrint={() => {
          setOpen(true);
        }}
        selectionIndication={selectionIndication}
        pagin={
          <>
            <Typography variant="h6">
              <FormattedMessage id="labDoctor.list" />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="foundResult"
                values={{
                  num: (
                    <Typography variant="inherit" component="span" color="primary">
                      {pageData?.content?.length || 0}
                    </Typography>
                  ),
                  total: (
                    <Typography variant="inherit" component="span" color="primary">
                      {pageData?.pagination?.totalElements || 0}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </>
        }
      />
      <IndicationDentalList
        data={pageData?.content}
        total={pageData?.pagination?.totalElements || 0}
        loading={isValidating}
        hookPagination={hookPagination}
        selectionIndication={selectionIndication}
        setSelectionIndication={setSelectionIndication}
      />
      <IndicationPrintForm
        open={open}
        onClose={() => setOpen(false)}
        selectedIndicationRequests={selectionIndication}
      />
    </Paper>
  );
};

export default LabDoctorIndicationDentalList;
