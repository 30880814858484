import { DECIMAL_NUMBER_REGEX } from '../../../../common/regex';
import { ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';
import { Box, Grid, InputLabel, Switch, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';
import React from 'react';
import { some, SYSTEM_CATEGORY_SCOPE } from '../../../../common/constants';
import { NumericFormatText } from '../../../../common/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const {
    intl,
    medicationCategoryList,
    indicationOptions,
    onSelectMedication,
    onDelete,
    onChangeFee,
    onChangeMedicationInfo,
    readOnly,
  } = formProps || {};
  const { setValue } = methods;
  const getMedicationItemOptions = () => {
    const arr: any[] = [];
    let categoryList: any[] = [];
    categoryList = valuesField?.categoryOption
      ? medicationCategoryList.filter((v) => v.id === valuesField?.categoryOption?.id) || []
      : medicationCategoryList;
    categoryList?.forEach((i) => {
      i?.medicationList?.forEach((i1: some) => {
        const obj = {
          ...i1,
          group: i.name,
        };
        arr.push(obj);
      });
    });
    return arr;
  };
  const medicationOptions = getMedicationItemOptions();

  const getIndentedRouteOptions = (optionId) => {
    const arr: any[] = [];
    const medicationId: any[] = [];
    medicationCategoryList?.forEach((i) => {
      const medication = i?.medicationList?.find((ele) => ele.id === optionId);
      if (medication) {
        medication?.intendedRoute?.forEach((ele) => {
          if (!medicationId.includes(medication?.id)) {
            arr.push(ele);
          }
        });
        medicationId.push(medication?.id);
      }
    });
    return arr;
  };

  const getIndicationOptions = () => {
    let indicationList: any[] = [];
    indicationList = indicationOptions.filter(
      (v) =>
        v.scope === SYSTEM_CATEGORY_SCOPE.radiology ||
        v.scope === SYSTEM_CATEGORY_SCOPE.surgical ||
        v.scope === SYSTEM_CATEGORY_SCOPE.laboratory,
    );
    return indicationList;
  };

  return {
    categoryOption: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'therapy.detail.label.drugGroup' }),
      placeholder: intl.formatMessage({ id: 'therapy.detail.label.drugGroupEnter' }),
      propsWrapper: { xs: 6 },
      options: medicationCategoryList,
      isOptionEqualToValue: (selected, value) => selected?.id === value?.id,
      getOptionLabel: (option) => option.name || ' ',
      onChange: () => {
        setValue('medicationOption', null);
      },
    },
    medicationOption: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'therapy.detail.label.drugName' }),
      placeholder: intl.formatMessage({ id: 'therapy.detail.label.drugNameEnter' }),
      options: medicationOptions,
      getOptionLabel: (option) => (option ? `${option.name} (${option.ingredient} ${option.dose})` : ' '),
      propsWrapper: { xs: 6 },
      groupBy: (option) => option.group,
      onChange: (value) => {
        if (value && value.id) {
          onSelectMedication(value);
        }
      },
      getOptionDisabled: (option) => {
        return !!valuesField?.medicationList?.find((ele) => ele.medicationId === option?.id);
      },
    },
    medicationList: {
      type: 'array',
      hid: true,
      disableCloseBtn: true,
      addable: false,
      paperItemProps: { style: { padding: 0 }, variant: 'elevation', elevation: 0 },
      schema: {
        fields: ({ formProps, valuesField }) => {
          const { arrayData, methodsArray } = formProps;
          const medicationPrice = medicationOptions?.find(
            (ele) => String(ele.id) === String(valuesField.medicationId),
          )?.basePrice;
          return {
            id: {
              type: 'hidden',
            },
            name: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid item xs={3.5}>
                        <Typography>
                          <FormattedMessage id="therapy.detail.label.drugNameLabel" />
                        </Typography>
                      </Grid>
                      <Grid item xs={8.5}>
                        <Typography variant="subtitle1">{valuesField?.medicationName}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 12 },
            },
            ingredient: {
              type: () => {
                return (
                  <div style={{}}>
                    <Grid container paddingTop={0}>
                      <Grid item xs={3.5}>
                        <Typography>
                          <FormattedMessage id="therapy.detail.label.ingredientsLabel" />
                        </Typography>
                      </Grid>
                      <Grid item xs={8.5}>
                        <Typography variant="subtitle1">{valuesField?.medicationIngredient}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 12, sx: { marginTop: '-20px' } },
            },
            unit: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid item xs={3.5}>
                        <Typography>
                          <FormattedMessage id="therapy.detail.label.unitLabel" />
                        </Typography>
                      </Grid>
                      <Grid item xs={8.5}>
                        <Typography variant="subtitle1">{valuesField?.medicationUnit}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 12, sx: { marginTop: '-20px' } },
            },
            price: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid item xs={3.5}>
                        <Typography>
                          <FormattedMessage id="drugUsage.price" />:
                        </Typography>
                      </Grid>
                      <Grid item xs={8.5}>
                        <Typography variant="subtitle1">{NumericFormatText(medicationPrice)}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 12, sx: { marginTop: '-20px' } },
            },
            indicationRequest: {
              type: 'auto-complete',
              label: intl.formatMessage({ id: 'therapy.detail.label.instruction' }),
              placeholder: intl.formatMessage({ id: 'therapy.detail.label.instructionEnter' }),
              options: getIndicationOptions,
              getOptionLabel: (option) => {
                return option.serviceName || ' ';
              },
              onChange: (value) => {
                if (value) {
                  onChangeMedicationInfo({ ...valuesField, indicationRequest: value });
                }
              },
              propsWrapper: { xs: 6 },
            },
            intendedRoute: {
              type: 'auto-complete',
              label: intl.formatMessage({ id: 'therapy.detail.label.intendedRoute' }),
              placeholder: intl.formatMessage({ id: 'therapy.detail.label.intendedRouteEnter' }),
              options: getIndentedRouteOptions(valuesField?.medicationId),
              propsWrapper: { xs: 6 },
              onChange: (value) => {
                if (value) {
                  onChangeMedicationInfo({ ...valuesField, intendedRoute: value });
                }
              },
            },
            quantity: {
              type: 'number-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'therapy.detail.label.drugQuantity' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 4, sx: { marginTop: '-10px' } },
              register: {
                required: true,
                maxLength: {
                  value: 16,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
                pattern: DECIMAL_NUMBER_REGEX,
              },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  onChangeMedicationInfo({ ...valuesField, quantity: e.target.value?.trim().replace(/[,]/g, '') });
                }
              },
            },
            note: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'note' }),
              placeholder: intl.formatMessage({ id: 'noteEnter' }),
              propsWrapper: { xs: 8, sx: { marginTop: '-10px' } },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  onChangeMedicationInfo({ ...valuesField, note: e.target.value?.trim() });
                }
              },
            },
            isFree: {
              type: () => {
                return (
                  <Switch
                    disabled={readOnly || arrayData.value?.isPaid}
                    checked={!valuesField.isFree}
                    onChange={(e) =>
                      onChangeFee({
                        ...valuesField,
                        isFree: e.target.checked ? false : true,
                      })
                    }
                  />
                );
              },
            },
            delete: {
              unregister: true,
              type: () => {
                return (
                  <IconButtonTitle
                    title="delete"
                    size="small"
                    disabled={readOnly || arrayData.value?.isPaid}
                    onClick={() => {
                      methodsArray?.remove(arrayData?.index);
                      onDelete(valuesField);
                    }}
                  >
                    <Delete />
                  </IconButtonTitle>
                );
              },
              propsWrapper: {
                xs: true,
              },
            },
          };
        },
        ui: [
          {
            id: 'info',
            propsWrapper: { xs: true, paddingTop: 2.5 },
            fields: ['name', 'ingredient', 'unit', 'price'],
          },
          {
            id: 'edit',
            propsWrapper: { xs: true },
            fields: ['indicationRequest', 'intendedRoute', 'quantity', 'note'],
          },
          {
            id: 'isFree',
            propsWrapper: { xs: true, sx: { display: 'flex', alignItems: 'center' } },
            fields: ['isFree'],
          },
          {
            id: 'delete',
            propsWrapper: { xs: true, sx: { display: 'flex', alignItems: 'center' } },
            fields: ['delete'],
          },
        ],
        layout: ({ listElement, valuesField, fields }) => {
          return (
            <>
              {valuesField?.medicationList.length > 0 && (
                <Box mt={0} bgcolor={'#F4F8FF'} style={{ borderRadius: 4 }}>
                  <Grid container padding={1}>
                    <Grid item xs={3}>
                      <Box>{listElement[0]}</Box>
                    </Grid>
                    <Grid item xs={7}>
                      <Box>{listElement[1]}</Box>
                    </Grid>
                    <Grid item xs={1.5} alignItems={'center'}>
                      <InputLabel>
                        <Typography
                          variant="subtitle2"
                          color="#607d8b"
                          noWrap
                          style={{ lineHeight: 1, textAlign: 'center' }}
                        >
                          <FormattedMessage id="therapy.detail.label.isFree" />
                        </Typography>
                      </InputLabel>
                      <Box marginLeft={7}>{listElement[2]}</Box>
                    </Grid>
                    <Grid item xs={0.5}>
                      <Box marginTop={5.5}>{listElement[3]}</Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          );
        },
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  return [
    {
      id: 'info',
      fields: ['categoryOption', 'medicationOption'],
      paperProps: {
        sx: {
          border: 'none',
          bgcolor: '#F4F8FF',
          px: 2,
          py: 1,
        },
      },
      paper: true,
    },
    {
      id: 'medication',
      fields: ['medicationList'],
    },
  ];
};

export const therapyMedicationFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: ({ listElement, valuesField, fields }) => {
    return (
      <>
        {listElement[0]}
        <Box mt={2}>{listElement[1]}</Box>
      </>
    );
  },
};
