import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';

export const ReportRevenueDetailSchema = z
  .object({
    saleReceiptCode: z.string(),
    medicationName: z.string(),
    createdDate: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    expiredDate: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    type: z.string(),
    lot: z.string(),
    quantity: z.number(),
    unit: z.string(),
    unitPrice: z.number(),
    discountAmount: z.number(),
    vatAmount: z.number(),
    totalAmount: z.number(),
    invoiceDiscountAmount: z.number(),
    amountPaid: z.number(),
    paymentType: z.string(),
  })
  .partial();

export type ReportRevenueDetail = z.infer<typeof ReportRevenueDetailSchema>;
