import { Add, Search } from '@mui/icons-material';
import { Box, InputAdornment, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ILayoutFields, ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';

export interface FilterParamsUser {
  name?: string;
  email?: string;
  status?: string;
}

export const fieldSchema: ISchemaFields = ({ formProps }) => {
  const { intl, onAddNew } = formProps || {};
  return {
    name: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'accountAdmin.name' }),
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Search color="inherit" />
          </InputAdornment>
        ),
      },
      showClearBtn: true,
      propsWrapper: { xs: true },
    },

    search: {
      type: 'submitButton',
      color: 'inherit',
      label: intl.formatMessage({ id: 'search' }),
      propsWrapper: { xs: undefined },
    },

    add: {
      type: 'button',
      startIcon: <Add />,
      onClick: onAddNew,
      color: 'primary',
      label: intl.formatMessage({ id: 'add' }),
      propsWrapper: { xs: undefined },
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'layout',
      propsGridContainer: { spacing: 1 },
      fields: ['name', 'search', 'add'],
    },
  ];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement, formProps } = props;
  return (
    <>
      <Box display="flex" marginBottom={2}>
        <Box flex={1.5}>
          <Typography variant="h6">
            <FormattedMessage id="accountAdmin.manage" />
          </Typography>
          {formProps?.pagin}
        </Box>
        <Box flex={1}>{listElement[0]}</Box>
      </Box>
    </>
  );
};

export const schemaUserForm: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
