import { Paper } from '@mui/material';
import * as React from 'react';
import BasicEOCInfo from '../component/detail/BasicEOCInfo';
import { useParams } from 'react-router';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import Prepayments from '../component/detail/Prepayments';
import TablePriceBox from '../component/detail/TablePriceBox';
interface Props {}

const CashierEOCDetailPage: React.FunctionComponent<Props> = (props) => {
  const { encounterId } = useParams<{ encounterId; patientId; tab: string }>();

  const { data: encounter } = useFetch(API_SERVER.encounter.get(encounterId), {
    globalLoading: true,
  });
  return (
    <Paper elevation={1} style={{ overflow: 'auto', padding: 16, flex: 1, height: '100vh' }}>
      <BasicEOCInfo encounter={encounter} />
      <Prepayments encounter={encounter} />
      <TablePriceBox encounter={encounter} />
    </Paper>
  );
};

export default CashierEOCDetailPage;
