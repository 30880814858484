import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../../common/component/DialogCustom';
import { some, SYSTEM_CATEGORY_SCOPE_LABEL } from 'modules/common/constants';
import SchemaForm from 'modules/common/SchemaForm';
import { indicationFormSchema } from './schema';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
  list?: some[];
  serviceCategoryList: some[];
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose, serviceCategoryList } = props;
  const { intl } = useGeneralHook();

  const serviceTypeList = React.useMemo(() => {
    if (!serviceCategoryList) {
      return [];
    }
    const mapServiceType = new Map<string, some[]>();
    serviceCategoryList.forEach((group) => {
      if (!group.parentId) {
        const listCategoryFiltered = serviceCategoryList.filter((g) => g.parentId === group.id);
        listCategoryFiltered.forEach((g) => {
          mapServiceType.set(group.scope, [
            ...(mapServiceType.get(group.scope) || []),
            {
              ...g,
              label: group.name,
            },
          ]);
        });
      }
    });
    const result: some[] = [];
    for (let key of mapServiceType.keys()) {
      const label = SYSTEM_CATEGORY_SCOPE_LABEL.find((v) => v.value === key)?.label;
      result.push({
        serviceType: key,
        name: intl.formatMessage({ id: label || ' ' }),
        groups: mapServiceType.get(key),
      });
    }

    return result;
  }, [serviceCategoryList, intl]);

  const { data } = useFetch(API_SERVER.therapyManagement.getDetailById(formData?.id), {
    enabled: formData?.id,
  });

  return (
    <SchemaForm
      schema={indicationFormSchema}
      onSubmit={onSubmit}
      formData={{
        ...formData,
        detailList: data?.detailList || [
          {
            name: null,
            description: null,
            price: null,
            indicationList: [],
          },
        ],
      }}
      onCancel={onClose}
      formId="exam-price"
      formProps={{
        autoComplete: 'nope',
        autoCorrect: 'off',
      }}
      serviceTypeList={serviceTypeList}
    />
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 1000 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'therapy.editPriceList' : 'therapy.addNewInList'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default FormDialog;
