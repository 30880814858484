import { UserEmployeeRole } from 'modules/schema';
import { useMemo } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import DialogCustom from 'modules/common/component/DialogCustom';
import TextInput from 'modules/common/component/form/TextInput';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import { FormProvider, useForm } from 'react-hook-form';

interface DialogContentProps {
  open: boolean;
  onClose(): void;
  dialogTitleKey: string;
  currentPICId: number;
  doctors: UserEmployeeRole[];
  onUpdatePIC(data): void;
  readonly: boolean;
}

interface DialogContentFormProps {
  newDoctor: string | null;
  note: string | null;
}

const DialogContent = ({
  currentPICId,
  doctors,
  onUpdatePIC,
  onClose,
  readonly,
}: Omit<DialogContentProps, 'open' | 'dialogTitleKey'>) => {
  const { intl } = useGeneralHook();
  const formMethods = useForm<DialogContentFormProps>({
    defaultValues: {
      newDoctor: null,
      note: null,
    },
  });
  const { handleSubmit } = formMethods;

  const doctorOptions = useMemo(
    () =>
      doctors
        ?.filter((doctor) => doctor.id !== currentPICId)
        ?.map((doctor) => ({ value: doctor?.id, label: doctor?.employeeFullName })),
    [currentPICId, doctors],
  );

  return (
    <Box padding={2}>
      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onUpdatePIC)}>
          <Typography variant="inherit" color={'rgb(96, 125, 139)'} fontWeight={500} marginBottom={'10px'}>
            <FormattedMessage id="encounter.encounterDetail.editPIC.currentPIC.label" />
          </Typography>
          <Typography marginBottom={'10px'}>
            {doctors?.find((doctor) => doctor.id === currentPICId)?.employeeFullName}
          </Typography>
          <AutocompleteInput
            form={formMethods}
            name="newDoctor"
            options={doctorOptions}
            label={intl.formatMessage({ id: 'encounter.encounterDetail.editPIC.newPIC.label' })}
            placeholder={intl.formatMessage({ id: 'encounter.encounterDetail.editPIC.newPIC.placeholder' })}
            required={true}
            getLabel="label"
            getValue="value"
            disabled={readonly}
          />
          <TextInput
            form={formMethods}
            name="note"
            label={intl.formatMessage({ id: 'note' })}
            placeholder={intl.formatMessage({ id: 'enter' })}
            rows={3}
            disabled={readonly}
          />
          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
            <Button variant="outlined" color="primary" sx={{ padding: '5px 20px' }} type="button" onClick={onClose}>
              <FormattedMessage id="encounter.encounterDetail.editPIC.editButton.title" />
            </Button>
            <Button sx={{ padding: '5px 20px' }} type="submit" disabled={readonly}>
              <FormattedMessage id="encounter.encounterDetail.editPIC.saveButton.title" />
            </Button>
          </Stack>
        </form>
      </FormProvider>
    </Box>
  );
};

const EditPICDialog = (props: DialogContentProps) => {
  const { open, dialogTitleKey, ...rest } = props;
  return (
    <DialogCustom
      open={open}
      onClose={props.onClose}
      title={dialogTitleKey}
      PaperProps={{
        style: { width: '500px' },
      }}
      maxWidth="xl"
    >
      <DialogContent {...rest} />
    </DialogCustom>
  );
};

export default EditPICDialog;
