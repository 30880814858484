import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_FORMAT } from '../common/constants';
import { MedicationSchema } from './Medication';

export const InventoryExportDetailSchema = z
  .object({
    id: z.number(),
    inventoryExportId: z.number(),
    medicationId: z.number(),
    lot: z.string(),
    expiredDate: createDateTimeSchema(BE_DATE_FORMAT),
    quantity: z.number(),
    unit: z.string(),
    medication: MedicationSchema,
  })
  .partial();

export type InventoryExportDetail = z.infer<typeof InventoryExportDetailSchema>;
