import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, departments } = formProps || {};
  return {
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'supply.name' }),
      placeholder: intl.formatMessage({ id: 'supply.nameEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    commercialName: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'supply.commercialName' }),
      placeholder: intl.formatMessage({ id: 'supply.commercialNameEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'supply.code' }),
      placeholder: intl.formatMessage({ id: 'supply.codeEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    packageMethod: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'supply.packagingFormat' }),
      placeholder: intl.formatMessage({ id: 'supply.packagingFormatEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    groupCode: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'supply.codeGroup' }),
      placeholder: intl.formatMessage({ id: 'supply.codeGroupEnter' }),
      register: {
        required: true,
      },
      defaultValue: '11',
      propsWrapper: { xs: 6 },
    },
    unit: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'supply.unit' }),
      placeholder: intl.formatMessage({ id: 'supply.unitEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    winningBidDecision: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'supply.bid' }),
      placeholder: intl.formatMessage({ id: 'supply.bidEnter' }),
      propsWrapper: { xs: 6 },
    },
    specializedDepartmentId: {
      type: 'select',
      label: intl.formatMessage({ id: 'supply.departmentCode' }),
      placeholder: intl.formatMessage({ id: 'supply.departmentCodeEnter' }),
      propsWrapper: { xs: 6 },
      options: departments,
      rawOptions: true,
      register: {
        required: true,
      },
    },
    paymentPercent: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'paymentPercent' }),
      placeholder: intl.formatMessage({ id: 'paymentPercentEnter' }),
      propsWrapper: { xs: 6 },
      required: false,
      register: {
        max: { value: 100, message: intl.formatMessage({ id: 'supply.valid' }, { num: 100 }) },
      },
    },
    purchasePrice: {
      type: 'number-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'supply.cost' }),
      placeholder: intl.formatMessage({ id: 'supply.priceEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    supplyPrice: {
      type: 'number-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'supply.priceSupply' }),
      placeholder: intl.formatMessage({ id: 'supply.costEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    maximumPrice: {
      type: 'number-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'supply.limitPrice' }),
      placeholder: intl.formatMessage({ id: 'supply.limitPriceEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        validate: (value) =>
          valuesField.price && value > valuesField.price
            ? intl.formatMessage({ id: 'supply.valid' }, { num: valuesField.price })
            : true,
      },
      required: false,
    },
    vat: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'vatPercent' }),
      placeholder: intl.formatMessage({ id: 'vatEnter' }),
      propsWrapper: { xs: 6 },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'info' }),
      fields: [
        'name',
        'commercialName',
        'unit',
        'packageMethod',
        'code',
        'groupCode',
        'specializedDepartmentId',
        'winningBidDecision',
      ],
    },
    {
      id: 'price',
      title: intl.formatMessage({ id: 'supply.price' }),
      fields: ['paymentPercent', 'purchasePrice', 'supplyPrice', 'maximumPrice', 'vat'],
    },
  ];
};

export const supplyFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
