import { Box } from '@mui/material';
import DialogCustom from '../../../common/component/DialogCustom';
import { some } from '../../../common/constants';

interface Props {
  open: boolean;
  onClose: () => void;
  data: some;
}

const DocumentDialog = (props: Props) => {
  const { open, onClose } = props;

  return (
    <DialogCustom open={open} onClose={onClose} maxWidth="lg" title={'labDoctor.document'}>
      <Box padding={2}>{/* <AttachedDocumentBox encounter={{}} editable={true} /> */}</Box>
    </DialogCustom>
  );
};
export default DocumentDialog;
