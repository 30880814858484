import { Box, Stack, Typography } from '@mui/material';
import { intersection, map } from 'lodash';
import { GENDER, INDICATION_REQUEST_TYPE, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import Filter from 'modules/common/component/filter';
import { DEFAULT_CONFIG } from 'modules/common/component/filter/const';
import DateInput from 'modules/common/component/form/DateInput';
import SelectInput from 'modules/common/component/form/SelectInput';
import Report from 'modules/common/component/report';
import { ReportFilterOption } from 'modules/common/component/report/type';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { FE_DATE_FORMAT, INDICATION_REQUEST_STATUS, BE_DATE_TIME_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import { useDialog, useFetch } from 'modules/common/hook';
import { NumericFormatText, formatEndDate, formatStartDate, isValidDate } from 'modules/common/utils';
import { IndicationResultReport } from 'modules/schema';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { DEFAULT_VALUES, FILTER_CONFIGS, FILTER_KEY, SELECT_COLUMNS_KEY } from './const';
import { FormSchema, SearchParams } from './type';
import { AppState } from 'redux/reducer';
import { useSelector } from 'react-redux';
import { SPECIALIST } from 'modules/layout/constants';
import { Option } from 'modules/common/type';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { WORK_API } from 'modules/common/service';
import { VIEW_ENCOUNTER } from '../../const';
import { duplicateTab } from 'modules/common/component/Encounter/utils';
import { ROUTES } from 'modules/layout/router';

interface Props {
  typeOption: Option[];
  scopesOption: Option[];
}

const IndicationReportTable: React.FunctionComponent<Props> = ({ typeOption, scopesOption }) => {
  const { intl } = useGeneralHook();
  const form = useForm<FormSchema>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });
  const roles = useSelector((state: AppState) => state.authen.user?.role);
  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const [searchParams, setSearchParams] = React.useState<SearchParams>(DEFAULT_VALUES);
  const { page, pageSize, paginationProps, resetPage } = useTablePagination({});
  const allOptions = intl.formatMessage({ id: 'all' });

  const fromDate = formatStartDate(form.watch('fromDate'));
  const toDate =
    moment(form.watch('toDate')) < moment()
      ? formatEndDate(moment(form.watch('toDate')).add(-1, 'day'))
      : formatEndDate(moment());
  const type = form.watch('type');
  const scopes = form.watch('scopes');

  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      page: page,
      pageSize: pageSize,
      fromDate: fromDate,
      toDate: toDate,
      type: type
        ? map(type, 'value')
        : isDentalClinic
        ? [INDICATION_REQUEST_TYPE.DENTAL_EXAM.value, INDICATION_REQUEST_TYPE.THERAPY_SESSION.value]
        : [INDICATION_REQUEST_TYPE.ENCOUNTER.value, INDICATION_REQUEST_TYPE.THERAPY_SESSION.value],
      scopes: scopes ? map(scopes, 'value') : [],
    };
  }, [searchParams, page, pageSize, fromDate, toDate, type, isDentalClinic, scopes]);
  const { data: reportPage } = useFetch(WORK_API.getIndicationPaging(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: fromDate && moment(fromDate).isValid() && toDate && moment(toDate).isValid(),
  });

  React.useEffect(() => {
    setFilterReportOptions([
      { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      {
        title: 'serviceTypes',
        value:
          type && type.length !== 0 ? type?.map((ele) => intl.formatMessage({ id: ele.label })).join(', ') : allOptions,
      },
      {
        title: 'indication.plan',
        value:
          scopes && scopes.length !== 0
            ? scopes.map((ele) => intl.formatMessage({ id: ele.label })).join(', ')
            : allOptions,
      },
      { title: DEFAULT_CONFIG.INDICATION_REQUEST_STATUS.title, value: allOptions },
      { title: DEFAULT_CONFIG.PAYMENT_PLAN.title, value: allOptions },
      { title: DEFAULT_CONFIG.EMPLOYEE.title, value: allOptions },
      { title: DEFAULT_CONFIG.SERVICE.title, value: allOptions },
    ]);
  }, [allOptions, fromDate, toDate, type, scopes, intl]);
  const exportUrl = React.useMemo(
    () =>
      WORK_API.getIndicationExcel({
        ...formatSearchParams,
        fromDate: moment(form.getValues('fromDate')).subtract(7, 'hour').format(BE_DATE_TIME_FORMAT),
        page: 0,
        pageSize: 10000,
      }),
    [form, formatSearchParams],
  );

  const [filterReportOptions, setFilterReportOptions] = React.useState<ReportFilterOption[]>([]);
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const { data: printDataPage } = useFetch(
    WORK_API.getIndicationPaging({
      ...formatSearchParams,
      fromDate: fromDate,
      toDate: toDate,
      page: 0,
      pageSize: 100000,
    }),
    {
      globalLoading: true,
      revalidateOnFocus: false,
      enabled: openPrintDialog && isValidDate(fromDate, toDate, 'TABLE'),
    },
  );

  const COLUMNS: Column<IndicationResultReport>[] = [
    { type: 'INDEX', width: '2%', sticky: true },
    {
      title: 'dental.label.conditionDiagnosis.updateDay',
      render: (record) => record.createdTime?.format(FE_DATE_FORMAT),
      width: '8%',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'indication.plan',
      render: (record) => <FormattedMessage id={SYSTEM_CATEGORY_SCOPE[record.scope!].label} />,
      width: '8%',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'encounterDetail.indicationName',
      render: 'indicationRequestName',
      width: '10%',
      maxWidth: '130px',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
      enableEllipsis: true,
      fieldName: 'indicationRequestName',
    },
    {
      title: 'price',
      render: (record) => {
        return <Typography fontSize={14}>{NumericFormatText(record.price)}</Typography>;
      },
      width: '8%',
      sticky: true,
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'therapyIndication.header.label.picName',
      render: 'executeName',
      width: '10%',
      maxWidth: '100px',
      sticky: true,
      selectColumnConfig: {
        defaultChecked: true,
      },
      enableEllipsis: true,
      fieldName: 'executeName',
    },
    {
      title: 'returnResultIndication.status',
      render: (record) => {
        const status = INDICATION_REQUEST_STATUS[record.status!];
        return (
          <Typography fontSize={14} color={status.color}>
            <FormattedMessage id={status?.label} />
          </Typography>
        );
      },
      align: 'center',
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'tollStatus',
      render: (record) => {
        return (
          <Typography fontSize={14}>
            <FormattedMessage id={record.isFree ? 'common.label.free' : 'encounterBook.billable'} />
          </Typography>
        );
      },
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'cashierPrint.encounterId',
      render: (record) => {
        if (intersection(VIEW_ENCOUNTER, roles).length > 0) {
          return (
            <Typography
              fontSize={'14px'}
              onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                event.preventDefault();
                if (record.type === 'THERAPY_SESSION') {
                  duplicateTab(ROUTES.therapyDetail.gen(record.therapyId!));
                } else if (record.type === 'DENTAL_EXAM') {
                  duplicateTab(ROUTES.dentalDetail.gen(record.referenceId!, record.patientId!));
                } else {
                  duplicateTab(ROUTES.encounterDetail.gen(record.referenceId!, record.patientId!));
                }
              }}
              color={'primary'}
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {record.referenceCode}
            </Typography>
          );
        } else {
          return <Typography fontSize={'14px'}> {record.referenceCode}</Typography>;
        }
      },
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'patientName',
      render: 'patientName',
      width: '10%',
      maxWidth: '150px',
      selectColumnConfig: {
        defaultChecked: true,
      },
      fieldName: 'patientName',
      enableEllipsis: true,
    },
    {
      title: 'birthdayShort',
      render: (record) => moment(record?.dob)?.format(FE_DATE_FORMAT),
      width: '10%',
      selectColumnConfig: {
        defaultChecked: false,
      },
    },
    {
      title: 'gender',
      render: (record) => {
        const gender = GENDER[record.gender!];
        return (
          <Typography fontSize={14}>
            <FormattedMessage id={gender?.label} />
          </Typography>
        );
      },
      width: '10%',
      selectColumnConfig: {
        defaultChecked: false,
      },
    },
    {
      title: 'telephone',
      render: 'phone',
      width: '10%',
      selectColumnConfig: {
        defaultChecked: false,
      },
    },
  ];
  const [selectedColumn, setSelectedColumn] = React.useState<Column<IndicationResultReport>[]>(
    COLUMNS?.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );

  return (
    <Stack direction="column" gap={2}>
      <Report.Title
        title="report.workReport.tab.title.indicationRequestReport"
        showSubTitle
        foundResultAmount={reportPage?.content.length || 0}
        totalElementAmount={reportPage?.pagination.totalElements}
        renderRight={
          <Stack direction="row" alignItems="center" justifyContent="center" gap="16px" paddingTop={2.5}>
            <Report.SearchInput
              form={form}
              name="keyword"
              onSearch={() => {
                setSearchParams((prev) => ({ ...prev, keyword: form.getValues('keyword') }));
                resetPage();
              }}
            />
          </Stack>
        }
      />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue, filterConfig) => {
          setSearchParams((prev) => ({
            ...prev,
            isFrees: filterValue.PAYMENT_PLAN.map((ele) => (ele === PAYMENT_PLAN.FREE.value ? true : false)),
            executeUserIds: filterValue.EMPLOYEE,
            serviceIds: filterValue.SERVICE,
            status: filterValue.INDICATION_REQUEST_STATUS,
          }));
          setFilterReportOptions([
            { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            {
              title: 'serviceTypes',
              value:
                type && type.length !== 0
                  ? type.map((ele) => intl.formatMessage({ id: ele.label })).join(', ')
                  : allOptions,
            },
            {
              title: 'indication.plan',
              value:
                scopes && scopes.length !== 0
                  ? scopes.map((ele) => intl.formatMessage({ id: ele.label })).join(', ')
                  : allOptions,
            },
            {
              title: DEFAULT_CONFIG.INDICATION_REQUEST_STATUS.title,
              value:
                filterConfig.INDICATION_REQUEST_STATUS?.length === 0
                  ? allOptions
                  : filterConfig.INDICATION_REQUEST_STATUS.map((ele) => intl.formatMessage({ id: ele.label })).join(
                      ', ',
                    ),
            },
            {
              title: DEFAULT_CONFIG.PAYMENT_PLAN.title,
              value:
                filterConfig.PAYMENT_PLAN?.length === 0
                  ? allOptions
                  : filterConfig.PAYMENT_PLAN.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
            {
              title: DEFAULT_CONFIG.EMPLOYEE.title,
              value:
                filterConfig.EMPLOYEE?.length === 0
                  ? allOptions
                  : filterConfig.EMPLOYEE.map((ele) => intl.formatMessage({ id: ele.employeeFullName })).join(', '),
            },
            {
              title: DEFAULT_CONFIG.SERVICE.title,
              value:
                filterConfig.SERVICE?.length === 0
                  ? allOptions
                  : filterConfig.SERVICE.map((ele) => intl.formatMessage({ id: ele.name })).join(', '),
            },
          ]);
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                key="type"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                multiple
                showCheckBox
                onChange={resetPage}
              />
              <SelectInput
                form={form}
                name="scopes"
                key="scopes"
                options={scopesOption}
                getValue="value"
                renderLabel="label"
                label="indication.plan"
                placeholder="indication.planEnter"
                multiple
                onChange={resetPage}
              />
              <DateInput
                label="fromDate"
                form={form}
                name="fromDate"
                key="fromDate"
                mode="start"
                format={FE_DATE_FORMAT}
                required
                maxDate={moment() && moment(toDate)}
                type="TABLE"
                onChange={resetPage}
              />
              <DateInput
                label="toDate"
                form={form}
                name="toDate"
                key="toDate"
                mode="end"
                maxDate={moment()}
                minDate={moment(fromDate).add(+1, 'day')}
                format={FE_DATE_FORMAT}
                required
                type="TABLE"
                onChange={resetPage}
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() => {
                    form.reset();
                    setSearchParams(DEFAULT_VALUES);
                  }}
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <Report.SelectColumnButton
              columns={COLUMNS}
              onSave={setSelectedColumn}
              localStorageKey={SELECT_COLUMNS_KEY}
            />
            <Report.ExportDataButton exportUrl={exportUrl} />
            <Report.PrintDataButton
              title="report.workReport.tab.title.indicationRequestReport"
              openDialog={openPrintDialog}
              onOpenDialog={onOpenPrintDialog}
              onCloseDialog={onClosePrintDialog}
              content={
                <Stack direction="column" gap={1}>
                  <Report.FilterPrintFormat filterOptions={filterReportOptions} />
                  <Table
                    data={printDataPage?.content}
                    columns={selectedColumn}
                    dataKey="id"
                    rowProps={{ sx: { pageBreakAfter: 'auto' } }}
                  />
                </Stack>
              }
            />
          </Stack>
        </Stack>
      </Filter>
      <Table
        columns={selectedColumn}
        dataKey="id"
        data={reportPage?.content}
        paginationProps={paginationProps}
        totalElements={reportPage?.pagination.totalElements}
        stickyHeader
        maxHeigth="100%"
      />
    </Stack>
  );
};

export default IndicationReportTable;
