import { Box } from '@mui/material';
import * as React from 'react';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { useParams } from 'react-router';
import { ENCOUNTER_PAYMENT_PLAN, some, THERAPY_REFERENCE_TYPE } from '../../../../../common/constants';
import { setLoading } from '../../../../../common/redux/commonReducer';
import { axiosThunk } from '../../../../../common/redux/axios';
import { API_SERVER } from '../../../../../common/api';
import { AxiosError } from 'axios';
import TherapyEncounterBox from './TherapyEncounterBox';
import TherapyTable from './TherapyTable';
import { useDentalDetailStateSelector } from '../state';
import { useCreateMutate, useFetch } from 'modules/common/hook';

const TherapyRequests = () => {
  const { dispatch, intl, openSnackbar, appState, confirmDialog } = useGeneralHook();
  const { dentalSessionId, patientId } = useParams<{ dentalSessionId; patientId }>();
  const readOnly = useDentalDetailStateSelector.use.readonly();
  const { data: therapyData = [], revalidate } = useFetch(
    API_SERVER.therapy.getTherapyByReference(dentalSessionId, { type: THERAPY_REFERENCE_TYPE.DENTAL }),
    { enabled: dentalSessionId },
  );

  const therapyMappedData = React.useMemo(() => {
    if (therapyData.length === 0) {
      return [];
    }

    const returnValue: some[] = [];
    for (let i = 0; i < therapyData.length; i++) {
      returnValue.push({
        id: therapyData[i].id,
        name: therapyData[i].name,
        numberSession: therapyData[i].numberSession,
        packagePrice: therapyData[i].packagePrice,
        totalPrice: therapyData[i].totalPrice,
        description: therapyData[i].description,
        note: therapyData[i].note,
        status: therapyData[i].status,
      });
    }
    returnValue.sort((a, b) => a.id - b.id);
    return { therapies: returnValue };
  }, [therapyData]);

  const onRequestTherapyMutate = useCreateMutate({ onSuccess: revalidate });
  const onRequestTherapy = React.useCallback(
    async (value: some) => {
      await onRequestTherapyMutate(
        {
          url: API_SERVER.therapy.assignmentReference(),
          method: 'post',
          data: {
            referenceId: parseInt(dentalSessionId),
            type: THERAPY_REFERENCE_TYPE.DENTAL,
            patientId,
            userId: appState.authen.user?.user_id,
            therapyDefinitionId: value?.therapy?.id,
            paymentPlan: ENCOUNTER_PAYMENT_PLAN['1'].value,
          },
        },
        { successMessage: 'encounterInfo.requestSuccess' },
      );
    },
    [appState.authen.user?.user_id, dentalSessionId, onRequestTherapyMutate, patientId],
  );

  const changeTherapyStatus = React.useCallback(
    async (value) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmCancelTitle' }),
        content: intl.formatMessage({ id: 'therapy.therapyDetail.deleteAction.confirmMessage.therapy' }),
        cancelId: 'no',
        okId: 'yes',
      });
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.therapy.cancel(value.id),
            method: 'put',
            data: {
              data: value?.note,
            },
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'encounterInfo.cancelSuccess' }) });
            //revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({
                  message: v.message,
                  type: 'error',
                });
              });
          })
          .finally(() => {
            dispatch(setLoading(false));
            revalidate();
            confirmDialog.close();
          });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <Box sx={{ backgroundColor: '#F4F8FF' }}>
      <Box padding={1} marginTop={1}>
        <TherapyEncounterBox readOnly={readOnly} encounterId={dentalSessionId} onRequestTherapy={onRequestTherapy} />
      </Box>
      <Box padding={1} marginTop={1}>
        <TherapyTable
          data={therapyMappedData}
          readOnly={readOnly}
          isAuthor={true}
          onChangeStatus={changeTherapyStatus}
          revalidate={revalidate}
        />
      </Box>
    </Box>
  );
};

export default TherapyRequests;
