import { Box, Divider, Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { GridOn } from '@mui/icons-material';
import * as React from 'react';
import { WORK_API } from 'modules/common/service';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import DetailedTable from 'modules/admin/component/therapyReport/TherapyWorkReport/detailed/DetailedTable';
import DetailedFilterBox from 'modules/admin/component/therapyReport/TherapyWorkReport/detailed/DetailedFilterBox';
import moment from 'moment';
import { BE_DATE_FORMAT, SERVICE_TYPE } from 'modules/common/constants';
import { formatEndDate, formatStartDate } from 'modules/common/utils';
import { GroupOption } from 'modules/common/type';
import { useFetch } from 'modules/common/hook/useFetch';
import Report from 'modules/common/component/report';
import { useDownloadFileLoading } from 'modules/common/hook';
import { LoadingButton } from '@mui/lab';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';

interface Props {
  serviceOptions: GroupOption[];
  type: keyof typeof SERVICE_TYPE;
}

const TherapyWorkReport: React.FunctionComponent<Props> = ({ serviceOptions, type }) => {
  const hookPagination = usePaginationHook({
    defaultFilter: {
      toDate: moment().format(BE_DATE_FORMAT),
      fromDate: moment().format(BE_DATE_FORMAT),
    },
  });
  const { params } = hookPagination;
  const { page, pageSize, paginationProps } = useTablePagination();

  const mappedFilterData = React.useMemo(() => {
    const end = params?.toDate || new Date();
    const start = params?.fromDate ? params.fromDate : moment(end).add(-4, 'year');
    return {
      ...params,
      code: params.code ? params.code : undefined,
      type,
      therapyId: params?.therapyId ? params?.therapyId?.map((t) => t.value).join(',') : undefined,
      picUserId: params?.picUserId ? params?.picUserId?.map((t) => t.value).join(',') : undefined,
      picUserName: params?.picUserId ? params?.picUserId?.map((t) => t.label).join(',') : undefined,
      fromDate: formatStartDate(start),
      toDate: formatEndDate(end),
      page: page,
      pageSize: pageSize,
    };
  }, [page, pageSize, params, type]);

  const { data } = useFetch(WORK_API.getTherapyWork(mappedFilterData), { globalLoading: false });

  const { downloadFile, loading } = useDownloadFileLoading(
    WORK_API.getTherapyWorkExcel({ ...mappedFilterData, page: 0, pageSize: 100000 }),
  );

  return (
    <Report>
      <Paper
        style={{
          padding: 16,
          overflow: 'hidden',
          height: '100%',
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Box display="flex" alignItems="center" gap={2}>
          <Box flex={1} marginBottom={3}>
            <Typography variant="h6">
              <FormattedMessage id="therapy.workReport.label.name" />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="foundResult2"
                values={{
                  total: (
                    <Typography variant="inherit" component="span" color="primary">
                      {data?.content?.length || 0}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </Box>
          <LoadingButton startIcon={<GridOn />} onClick={downloadFile} variant="contained" loading={loading}>
            <FormattedMessage id="appointmentBook.exportExcel" />
          </LoadingButton>
        </Box>
        <DetailedFilterBox hookPagination={hookPagination} serviceOptions={serviceOptions} />
        <Divider style={{ marginBottom: 16, marginTop: 16 }} />
        <DetailedTable data={data} paginationProps={paginationProps} />
      </Paper>
    </Report>
  );
};

export default TherapyWorkReport;
