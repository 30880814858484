const DEFAULT_INSTRUCTION_KEY = [
  'instruction.30mAfterEat',
  'instruction.30mBeforeEat',
  'instruction.rightAfterEat',
  'instruction.beforeSleep',
  'instruction.underTheTongue',
  'instruction.whenFever38',
];

export { DEFAULT_INSTRUCTION_KEY };
