import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT, ROLES } from 'modules/common/constants';
import { USER_STATUS } from 'modules/common/apiConstants';
import { EmployeeSchema } from './Employee';

export const UserSchema = z
  .object({
    id: z.number(),
    username: z.string(),
    email: z.string(),
    password: z.string(),
    lastPassword: z.string(),
    passwordChangedTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    failedAttempt: z.number(),
    lockedTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    roleName: createObjectKeysEnumSchema(ROLES),
    roleId: z.number(),
    status: createObjectKeysEnumSchema(USER_STATUS),
    employeeFullName: z.string(),
    practiceCertificate: z.string(),
    specialty: z.string(),
    resourceId: z.string(),
    employee: EmployeeSchema,
  })
  .partial();

export type User = z.infer<typeof UserSchema>;
