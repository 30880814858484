import { z } from 'zod';

export const MedicationUnitSettingSchema = z
  .object({
    id: z.number(),
    medicationId: z.number(),
    lot: z.string(),
    unit: z.string(),
    price: z.number(),
    clinicId: z.number(),
    inventoryId: z.number(),
    isBasicUnit: z.boolean(),
    factor: z.number(),
  })
  .partial();

export type MedicationUnitSetting = z.infer<typeof MedicationUnitSettingSchema>;
