import { Box, Stack } from '@mui/material';
import { Encounter } from 'modules/schema';
import AttachmentBlock from 'modules/common/component/Encounter/block/Attachment/AttachmentBlock';

interface Props {
  encounter: Encounter;
  readonly: boolean;
}

const AttachmentSession = ({ encounter, readonly }: Props) => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" gap="10px">
      <Box width={'100%'}>
        <AttachmentBlock encounter={encounter} readOnly={readonly} />
      </Box>
    </Stack>
  );
};

export default AttachmentSession;
