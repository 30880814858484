import { Box, Stack, Tooltip, Typography } from '@mui/material';
import DateInput from 'modules/common/component/form/DateInput';
import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Report from 'modules/common/component/report';
import { useDialog, useFetch } from 'modules/common/hook';
import Table, { TableOptions } from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, FE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { API_SERVER } from 'modules/common/api';
import { PAYMENT_ADVANCE_STATUS, PAYMENT_METHOD, PAYMENT_STATUS, SERVICE_TYPE_ALL } from 'modules/common/apiConstants';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { FormattedMessage, FormattedNumber, useIntl } from 'react-intl';
import { ReportFilterOption } from 'modules/common/component/report/type';
import SelectInput from 'modules/common/component/form/SelectInput';
import { FormSchema } from 'modules/common/page/report/business/debtReport/type';
import { DEFAULT_VALUES, FILTER_CONFIGS } from 'modules/common/page/report/business/debtReport/consts';
import { Payment, PaymentAdvance, PaymentDetail, PrePayment, ReportDebt } from 'modules/schema';
import { Option, Pageable, ValueType } from 'modules/common/type';
import { formatEndDate, formatStartDate, isValidDate, maxDateTable, NumericFormatText } from 'modules/common/utils';
import { isEmpty, map } from 'lodash';
import Filter from 'modules/common/component/filter';
import { DEFAULT_CONFIG } from 'modules/common/component/filter/const';
import DialogCustom from 'modules/common/component/DialogCustom';
import TextValue from 'modules/common/component/PrintForm/TextValue';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { axiosThunk } from 'modules/common/redux/axios';
import InfoIcon from '@mui/icons-material/Info';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { SPECIALIST } from 'modules/layout/constants';
import { BUSINESS_API } from 'modules/common/service';

export const FILTER_KEY = 'DEBT_REPORT_FILTER';
export const FILTER_KEY_VALUE = 'DEBT_REPORT_FILTER_VALUE';
export const SELECT_COLUMNS_KEY = 'DEBT_REPORT_SELECT_COLUMNS';

interface Props {}

const DebtReportTable: React.FunctionComponent<Props> = () => {
  const filterConfig = JSON.parse(localStorage.getItem(FILTER_KEY_VALUE)!);
  const [prepaymentDetailData, setPrepaymentDetailData] = useState<PrePayment>();
  const [paymentDetailData, setPaymentDetailData] = useState<Payment>();
  const [filterReportOptions, setFilterReportOptions] = React.useState<ReportFilterOption[]>([]);
  const [recordData, setRecordData] = useState<ReportDebt>();
  const [summaryData, setSummaryData] = useState<ReportDebt>({});
  const [openDetailDialog, onOpenDetailDialog, onCloseDetailDialog] = useDialog();
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const { page, pageSize, paginationProps, resetPage } = useTablePagination();
  const { dispatch } = useGeneralHook();
  const intl = useIntl();

  const form = useForm<FormSchema>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate =
    moment(form.watch('toDate')) < moment()
      ? formatEndDate(moment(form.watch('toDate')).add(-1, 'day'))
      : formatEndDate(moment());
  const type = form.watch('paymentTypeList');
  const allOptions = intl.formatMessage({ id: 'all' });

  // Get data payment detail
  const paymentDetail = async (record: ReportDebt) => {
    const { data: prepaymentDetailData } = await dispatch(
      axiosThunk({
        url: API_SERVER.prepayment.getPrepayment({
          paymentId: record?.id!,
          statuses: [PAYMENT_ADVANCE_STATUS.REFUNDED.value, PAYMENT_ADVANCE_STATUS.PREPAID.value],
        }).url,
      }),
    );
    const { data: paymentDetailData } = await dispatch(
      axiosThunk({
        url: API_SERVER.payment.detail(record?.id!, {
          type: record?.type!,
        }).url,
      }),
    );
    setPrepaymentDetailData(prepaymentDetailData);
    setPaymentDetailData(paymentDetailData);
    onOpenDetailDialog();
  };

  const COLUMNS: Column<ReportDebt>[] = [
    { type: 'INDEX', width: '30px', sticky: true },
    {
      title: 'report.debt.table.title.createdTime',
      fieldName: 'createdTime',
      render: (record) => record.createdTime?.format(FE_DATE_FORMAT),
      renderTitle: (
        <Tooltip title={intl.formatMessage({ id: 'report.business.date.encounter.title.tooltip' })}>
          <InfoIcon
            sx={{ marginBottom: '-5px', paddingLeft: '5px', height: '20px', width: '20px', color: '#7eadff' }}
          />
        </Tooltip>
      ),
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'report.debt.table.title.referenceCode',
      fieldName: 'referenceCode',
      render: (record) => (
        <Typography
          onClick={() => {
            paymentDetail(record);
            setRecordData(record);
          }}
          color={'#0052E0'}
          fontSize={'14px'}
          sx={{
            fontStyle: 'normal',
            fontWeight: 500,
            fontSize: '14px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {record?.referenceCode}
        </Typography>
      ),
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'report.debt.table.title.patientName',
      fieldName: 'patientName',
      render: 'patientName',
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
      enableEllipsis: true,
      maxWidth: '100px',
    },
    {
      title: 'report.debt.table.title.mobilePhone',
      fieldName: 'mobilePhone',
      render: 'mobilePhone',
      selectColumnConfig: {
        disabled: false,
        defaultChecked: true,
      },
    },
    {
      title: 'report.debt.table.title.finalTotalAmount',
      fieldName: 'finalTotalAmount',
      render: (record) => NumericFormatText(record.finalTotalAmount),
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'report.debt.table.title.prepayment',
      fieldName: 'prepayment',
      render: (record) => NumericFormatText(record.prepayment),
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'report.debt.table.title.amountPaid',
      fieldName: 'amountPaid',
      render: (record) => NumericFormatText(record.amountPaid),
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'report.debt.table.title.debtAmount',
      fieldName: 'debtAmount',
      renderTitle: (
        <Tooltip title={intl.formatMessage({ id: 'debtTooltip' })}>
          <InfoIcon
            sx={{ marginBottom: '-5px', paddingLeft: '5px', height: '20px', width: '20px', color: '#7eadff' }}
          />
        </Tooltip>
      ),
      render: (record) => {
        const debt = record.debtAmount || 0;
        const prepayment = record.prepayment || 0;
        return NumericFormatText(debt - prepayment);
      },
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
  ];

  const COLUMNS_PREPAYMENT_DETAIL = React.useMemo(() => {
    return [
      {
        title: 'therapy.prepaid.table.header.createdTime',
        render: (record) => (
          <Typography fontSize={'14px'}>
            {record?.confirmedTime && moment(record?.confirmedTime)?.format(FE_DATE_TIME_FORMAT)}
          </Typography>
        ),
        lastCell: {
          colSpan: 2,
          render: () => {
            return (
              <Stack direction="row">
                <Typography fontSize={'14px'} fontWeight={'500'}>
                  <FormattedMessage id="therapy.prepaid.label.totalUsed" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  <FormattedNumber value={prepaymentDetailData?.amountPaid || 0} />
                </Typography>
              </Stack>
            );
          },
        },
      },
      {
        title: 'therapy.prepaid.table.header.prepaidAmount',
        render: (record) => {
          return <Typography fontSize={'14px'}>{NumericFormatText(record.amount)}</Typography>;
        },
        lastCell: {
          render: () => {
            return (
              <Stack direction="row">
                <Typography fontSize={'14px'} fontWeight={'500'}>
                  <FormattedMessage id="therapy.prepaid.label.totalAmount" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">{NumericFormatText(prepaymentDetailData?.totalAmount)}</Typography>
              </Stack>
            );
          },
        },
      },
      {
        title: 'therapy.prepaid.table.header.prepaidPaymentMethod',
        render: (record) => {
          return (
            <Typography fontSize={'14px'}>
              <FormattedMessage id={PAYMENT_METHOD[record?.paymentMethod!]?.label || ' '} />
            </Typography>
          );
        },
        lastCell: {
          render: () => {
            return (
              <Stack direction="row">
                <Typography fontSize={'14px'} fontWeight={'500'}>
                  <FormattedMessage id="therapy.prepaid.label.totalRefunded" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  <FormattedNumber value={prepaymentDetailData?.refundAmount || 0} />
                </Typography>
              </Stack>
            );
          },
        },
      },
      {
        title: '',
        lastCell: {
          render: () => {
            return (
              <Stack direction="row">
                <Typography fontSize={'14px'} fontWeight={'500'}>
                  <FormattedMessage id="therapy.prepaid.label.balance" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  <FormattedNumber value={prepaymentDetailData?.amount || 0} />
                </Typography>
              </Stack>
            );
          },
        },
      },
    ] as Columns<PaymentAdvance>[];
  }, [prepaymentDetailData]);

  const COLUMNS_PREPAYMENT_DETAIL_NO_DATA = React.useMemo(() => {
    return [
      {
        title: 'therapy.prepaid.table.header.createdTime',
      },
      {
        title: 'therapy.prepaid.table.header.prepaidAmount',
      },
      {
        title: 'therapy.prepaid.table.header.prepaidPaymentMethod',
      },
    ] as Columns<PaymentAdvance>[];
  }, []);

  const COLUMNS_PAYMENT_DETAIL: Columns<PaymentDetail>[] = [
    {
      title: 'detail',
      dataIndex: 'content',
    },
    {
      title: 'quantity',
      dataIndex: 'quantity',
      align: 'center',
    },
    {
      title: 'indication.unit',
      render: (record) => record?.unit || <FormattedMessage id="cashier.time" />,
    },
    {
      title: 'totalPrice',
      render: (record) => NumericFormatText(record?.amountPaid),
    },
    {
      title: 'cashier.discountAmount',
      render: (record) => NumericFormatText(record?.discountAmount! + record?.billDiscountAmount!),
    },
    {
      title: 'vatPercent',
      dataIndex: 'vat',
    },
    {
      title: 'label.report.revenueExpenditure.total',
      render: (record) => NumericFormatText(record?.totalAmount),
    },
    {
      title: 'status',
      render: (record) => <FormattedMessage id={PAYMENT_STATUS[record.status!].label || ' '} />,
    },
  ];

  const [selectedColumns, setSelectedColumn] = React.useState<Column<ReportDebt>[]>(
    COLUMNS?.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );
  const [searchParams, setSearchParams] = React.useState<
    {
      fromDate: string;
      toDate: string;
      keyword: string;
      type?: ValueType<typeof SERVICE_TYPE_ALL>[];
    } & Pageable
  >(DEFAULT_VALUES);

  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      paymentTypeList: type ? map(type, 'value') : map([SERVICE_TYPE_ALL.ENCOUNTER, SERVICE_TYPE_ALL.THERAPY], 'value'),
      fromDate,
      toDate,
      page: page,
      pageSize: pageSize,
    };
  }, [fromDate, page, pageSize, searchParams, toDate, type]);

  const { data: reportPage, isValidating } = useFetch(BUSINESS_API.getDebtPaging(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'TABLE'),
  });

  const { data: printDataPage } = useFetch(
    BUSINESS_API.getDebtPaging({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    {
      globalLoading: true,
      revalidateOnFocus: false,
      enabled: openPrintDialog && isValidDate(fromDate, toDate, 'TABLE'),
    },
  );

  useEffect(() => {
    if (reportPage && reportPage.summary && Object.keys(reportPage.summary).length > 0 && !isValidating) {
      setSummaryData(reportPage.summary);
    }
  }, [reportPage, isValidating]);

  const TABLE_OPTIONS = useMemo(() => {
    let tableOptions: TableOptions = {
      position: 'top',
      startIndex: COLUMNS.length === selectedColumns.length ? 4 : 0,
      useRender: ['debtAmount'],
      record: summaryData,
    };
    return tableOptions;
  }, [summaryData, COLUMNS.length, selectedColumns.length]);

  const exportUrl = React.useMemo(
    () => BUSINESS_API.getDebtExcel({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    [formatSearchParams],
  );

  React.useEffect(() => {
    setFilterReportOptions([
      { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      {
        title: DEFAULT_CONFIG.DEBT_STATUS.title,
        value:
          filterConfig?.DEBT_STATUS?.length === 0
            ? allOptions
            : filterConfig?.DEBT_STATUS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions, fromDate, intl, toDate, type]);

  //
  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const typeOption: Option[] = useMemo(() => {
    return isDentalClinic
      ? [SERVICE_TYPE_ALL.DENTAL, SERVICE_TYPE_ALL.THERAPY]
      : [SERVICE_TYPE_ALL.ENCOUNTER, SERVICE_TYPE_ALL.THERAPY];
  }, [isDentalClinic]);

  //
  const paymentDetailDataFilter = () => {
    if (paymentDetailData?.type === SERVICE_TYPE_ALL.ENCOUNTER.value) {
      return paymentDetailData?.paymentDetails;
    }
    if (paymentDetailData?.isPackagePaid) {
      return paymentDetailData?.paymentDetails?.filter((i) => !i.packageScope);
    } else {
      return paymentDetailData?.paymentDetails?.filter((i) => i.packageScope);
    }
  };

  return (
    <Stack direction="column" gap={2}>
      <Report.Title
        title="report.businessReport.tab.title.debtReport"
        showSubTitle
        foundResultAmount={reportPage?.content.length || 0}
        totalElementAmount={reportPage?.pagination.totalElements}
        renderRight={
          <Stack direction="row" alignItems="center" justifyContent="center" gap="16px" paddingTop={2.5}>
            <Report.SearchInput
              form={form}
              name="keyword"
              onSearch={() => {
                setSearchParams((prev) => ({ ...prev, keyword: form.getValues('keyword') }));
                resetPage();
              }}
            />
          </Stack>
        }
      />
      <Filter
        filters={FILTER_CONFIGS}
        popupWidth="600px"
        onSave={(filterValue, filterConfig) => {
          if (fromDate && toDate) {
            setSearchParams((prev: any) => ({
              ...prev,
              debtAmountZero: filterValue.DEBT_STATUS,
            }));
            resetPage();
          }
          setFilterReportOptions([
            { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            {
              title: DEFAULT_CONFIG.DEBT_STATUS.title,
              value:
                filterConfig.DEBT_STATUS?.length === 0
                  ? allOptions
                  : filterConfig.DEBT_STATUS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
          ]);
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="paymentTypeList"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                multiple
                showCheckBox
                onChange={resetPage}
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
                type="TABLE"
                onChange={resetPage}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                maxDate={maxDateTable(fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                type="TABLE"
                onChange={resetPage}
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() => {
                    form.reset();
                    setSearchParams(DEFAULT_VALUES);
                  }}
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <Report.SelectColumnButton
              columns={COLUMNS}
              onSave={setSelectedColumn}
              localStorageKey={SELECT_COLUMNS_KEY}
            />
            <Report.ExportDataButton exportUrl={exportUrl} />
            <Report.PrintDataButton
              title="report.businessReport.tab.title.debtReport"
              openDialog={openPrintDialog}
              onOpenDialog={onOpenPrintDialog}
              onCloseDialog={onClosePrintDialog}
              content={
                <Stack direction="column" gap={1}>
                  <Report.FilterPrintFormat filterOptions={filterReportOptions} />
                  <Table
                    data={printDataPage?.content}
                    columns={selectedColumns}
                    dataKey="id"
                    rowProps={{ sx: { pageBreakAfter: 'auto' } }}
                  />
                </Stack>
              }
            />
            <DialogCustom
              open={openDetailDialog}
              onClose={onCloseDetailDialog}
              PaperProps={{
                style: { width: '80vw' },
              }}
              maxWidth="xl"
              title={'debtDetail'}
            >
              <Box padding={'9px 64px 48px 64px'}>
                <Typography fontSize={'28px'} textTransform={'uppercase'} textAlign={'center'} margin={'20px 0'}>
                  <FormattedMessage id="encounterList.detail" />
                </Typography>
                <Stack direction="row" justifyContent="space-between" marginBottom={'30px'}>
                  <Box flex={1.5}>
                    <TextValue
                      label="sellingProduct.title.customerName"
                      value={recordData?.patientName}
                      labelWidth="140px"
                    />
                    <TextValue label="telephone" value={recordData?.mobilePhone} labelWidth="140px" />
                    <TextValue
                      label="therapy.therapyReport.detail.filter.label.examCode"
                      value={recordData?.referenceCode}
                      labelWidth="140px"
                    />
                  </Box>
                </Stack>
                <Box marginBottom={'20px'}>
                  <Typography fontWeight={500} color={'#546E7A'}>
                    <FormattedMessage id="prepaid" />
                  </Typography>
                  <TableCustom
                    containerProps={{
                      sx: {
                        '& th,tr,td': {
                          padding: '9px 8px',
                          backgroundColor: 'white',
                          border: '0.5px solid',
                          color: 'unset',
                        },
                        'tbody>tr td:nth-child(2)': {
                          padding: '1px 5px',
                        },
                        width: '100%',
                        overflow: 'visible',
                        marginTop: '8px',
                      },
                    }}
                    dataSource={prepaymentDetailData?.paymentAdvances || []}
                    columns={
                      !isEmpty(prepaymentDetailData?.paymentAdvances)
                        ? COLUMNS_PREPAYMENT_DETAIL
                        : COLUMNS_PREPAYMENT_DETAIL_NO_DATA
                    }
                  />
                </Box>
                <Box>
                  <Typography fontWeight={500} color={'#546E7A'}>
                    <FormattedMessage id="pay" />
                  </Typography>
                  <TableCustom
                    containerProps={{
                      sx: {
                        '& th,tr,td': {
                          padding: '9px 8px',
                          backgroundColor: 'white',
                          border: '0.5px solid',
                          color: 'unset',
                        },
                        'tbody>tr td:nth-child(2)': {
                          padding: '1px 5px',
                        },
                        width: '100%',
                        overflow: 'visible',
                        marginTop: '8px',
                      },
                    }}
                    dataSource={paymentDetailDataFilter() || []}
                    columns={COLUMNS_PAYMENT_DETAIL}
                  />
                </Box>
              </Box>
            </DialogCustom>
          </Stack>
        </Stack>
      </Filter>
      <Table
        columns={selectedColumns}
        options={TABLE_OPTIONS}
        dataKey="id"
        data={reportPage?.content}
        paginationProps={paginationProps}
        totalElements={reportPage?.pagination.totalElements}
        stickyHeader
        maxHeigth="100%"
      />
    </Stack>
  );
};

export default DebtReportTable;
