import { Box, Divider, Paper, Tab, Tabs, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { API_SERVER } from '../../common/api';
import LoadingIcon from '../../common/component/LoadingIcon';
import EncounterInfoBox from '../../examDoctor/component/detail/info/EncounterInfoBox';
import PrescriptionContent from '../../examDoctor/component/detail/prescription/PrescriptionContent';
import ParticipantTab from '../component/detail/participant/ParticipantTab';
import PatientInfoBox from '../component/detail/PatientInfoBox';
import SubclinicalImageDoctor from '../component/detail/SubclinicalImageDoctor';
import DrugUsageContent from '../component/drugUsage/DrugUsageContent';
import IndicationHistoryEncounterBox from '../component/history/IndicationHistoryEncounterBox';
import { useFetch } from 'modules/common/hook';
import { FE_DATE_TIME_FORMAT, INDICATION_REQUEST_STATUS } from 'modules/common/constants';
import moment from 'moment';
import SupplyOrder from '../component/detail/supply/SupplyOrder';
import LabResult from 'modules/examDoctor/component/detail/labResult/LabResult';

const TABS = [
  { id: 'imageIndication' },
  { id: 'participant' },
  { id: 'navMenu.supply' },
  { id: 'encounterDetail.encounterInfo' },
  { id: 'encounterDetail.subclinicalResult' },
  { id: 'drugUsage' },
  { id: 'encounterDetail.prescription' },
  { id: 'imageIndication.history' },
];
interface Props {}

const ImageDoctorIndicationDetail: React.FunctionComponent<Props> = (props) => {
  const { indicationRequestId } = useParams<{ indicationRequestId }>();
  const [tabValue, setTabValue] = React.useState(0);
  const { data: indicationRequest } = useFetch(API_SERVER.indicationRequest.get(indicationRequestId));
  const { data: encounter } = useFetch(API_SERVER.encounter.get(indicationRequest?.referenceId || 0), {
    enabled: indicationRequest && indicationRequest?.referenceId,
  });

  const readOnly = React.useMemo(() => {
    return (
      (indicationRequest?.status === INDICATION_REQUEST_STATUS.FINISHED.value &&
        indicationRequest?.endTime?.isBefore(moment().subtract(1, 'day'))) ||
      indicationRequest?.status === INDICATION_REQUEST_STATUS.CANCELLED.value
    );
  }, [indicationRequest?.endTime, indicationRequest?.status]);

  if (!indicationRequest) {
    return (
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <LoadingIcon />
      </Paper>
    );
  }

  return (
    <Paper elevation={1} style={{ overflow: 'auto', position: 'relative', flex: 1 }}>
      <Box padding={2} paddingBottom={0}>
        <PatientInfoBox data={indicationRequest} encounter={encounter || {}} />
      </Box>
      <Box padding={2}>
        <Typography variant="h6" color="primary">
          {encounter?.patient?.name}
          &emsp;-&emsp;
          <Typography variant="subtitle1" color="textSecondary" component="span">
            <FormattedMessage id="indicationDate" />
            &nbsp;
            {indicationRequest && moment(indicationRequest?.createdTime).format(FE_DATE_TIME_FORMAT)}
          </Typography>
        </Typography>
        <Typography variant="body2" marginTop={1}>
          <FormattedMessage id="reason" />
          :&nbsp;
          <Typography variant="body2" component="span">
            {encounter?.reason}
          </Typography>
        </Typography>
      </Box>
      <Box borderBottom={`1px solid ${grey[400]}`}>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
          }}
          variant="scrollable"
          indicatorColor="secondary"
          textColor="secondary"
          style={{ padding: '0px 8px' }}
        >
          {TABS.map((tab, index: number) => {
            return (
              <Tab
                key={index}
                value={index}
                style={{ minWidth: 100 }}
                label={
                  <Typography variant="subtitle2">
                    <FormattedMessage id={tab.id || ''} />
                  </Typography>
                }
              />
            );
          })}
        </Tabs>
        <Divider />
      </Box>
      <Box padding={2} overflow="auto">
        <div style={{ display: tabValue === 0 ? 'unset' : 'none' }}>
          <SubclinicalImageDoctor indicationRequest={indicationRequest} encounter={encounter} editable={!readOnly} />
        </div>
        <div style={{ display: tabValue === 1 ? 'unset' : 'none' }}>
          <ParticipantTab data={indicationRequest} editable={!readOnly} />
        </div>
        <div style={{ display: tabValue === 2 ? 'unset' : 'none' }}>
          <SupplyOrder indicationRequest={indicationRequest} editable={!readOnly} encounter={encounter} />
        </div>
        <div style={{ display: tabValue === 3 ? 'unset' : 'none' }}>
          <EncounterInfoBox encounter={encounter!} editable />
        </div>
        <div>{tabValue === 4 && encounter && <LabResult encounter={encounter} editable={!readOnly} />}</div>
        <div style={{ display: tabValue === 5 ? 'unset' : 'none' }}>
          <DrugUsageContent encounter={encounter} indicationRequest={indicationRequest} editable={!readOnly} />
        </div>
        <div style={{ display: tabValue === 6 ? 'unset' : 'none' }}>
          {encounter && <PrescriptionContent encounter={encounter} editable={false} />}
        </div>
        <div style={{ display: tabValue === 7 ? 'unset' : 'none' }}>
          {encounter && <IndicationHistoryEncounterBox encounter={encounter} indicationRequest={indicationRequest} />}
        </div>
      </Box>
    </Paper>
  );
};

export default ImageDoctorIndicationDetail;
