import { Box } from '@mui/material';
import React from 'react';
import { API_SERVER } from 'modules/common/api';
import { AttachedDocument, Encounter, StorageResource } from 'modules/schema';
import { useFetch } from 'modules/common/hook';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import DocumentBox from './DocumentBox';

interface Props {
  data: AttachedDocument;
  encounter?: Encounter;
}
const DocumentCard: React.FunctionComponent<Props> = ({ data, encounter }) => {
  // get data detail attached document
  const { data: dataFiles } = useFetch(
    API_SERVER.storageResource.getList({ type: STORAGE_RESOURCE_TYPE.attachedDocument, referenceId: data.id }),
  );

  return (
    <>
      <Box style={{ gap: 16, display: 'flex', flexDirection: 'column' }}>
        <Box>
          {dataFiles?.map((v: StorageResource, index: number) => (
            <DocumentBox key={index} data={v} encounter={encounter || {}} />
          ))}
        </Box>
      </Box>
    </>
  );
};
export default DocumentCard;
