import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import TextValue from './TextValue';
import { some } from '../../../../../../common/constants';
import useGeneralHook from '../../../../../../common/hook/useGeneralHook';

interface Props {
  therapy: some;
}

const TherapyInfo = ({ therapy }: Props) => {
  const { appState } = useGeneralHook();
  const { currentLocation } = appState.authen;
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box flex={1.5}>
        <TextValue
          label="therapy.indication.request.printForm.placeOfTreatment"
          value={currentLocation?.name}
          labelWidth="110px"
        />
        <TextValue
          label="therapy.indication.request.printForm.picOfTreatment"
          value={therapy?.picName}
          labelWidth="110px"
        />
      </Box>
      <Box flex={2}>
        <TextValue label="therapy.detail.label.therapyNumber" value={therapy?.code} labelWidth="110px" />
        <TextValue
          label="therapy.detail.label.therapyName"
          value={therapy?.name}
          localizationValue
          labelWidth="110px"
        />
      </Box>
    </Stack>
  );
};

export default TherapyInfo;
