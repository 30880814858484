import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionType, createCustomAction, getType } from 'typesafe-actions';
import { AppState } from '../../../redux/reducer';
import { API_SERVER } from '../../common/api';
import { some, SYSTEM_CATEGORY_SCOPE, SYSTEM_CATEGORY_SCOPE_LABEL } from '../../common/constants';
import { fetchThunk } from '../../common/redux/thunk';
import { axiosThunk } from '../../common/redux/axios';

export interface ReceptionState {
  healthcareServiceCategories: some[];
  therapyServiceList: some[];
  allServiceCategories: some[];
}

export const setHealthcareServiceCategory = createCustomAction(
  'reception/setHealthcareServiceCategory',
  (entries: some[]) => ({ entries }),
);

export const setTherapyServiceList = createCustomAction('reception/setTherapyServiceList', (entries: some[]) => ({
  entries,
}));

export const setAllServiceCategory = createCustomAction('reception/setAllServiceCategory', (entries: some[]) => ({
  entries,
}));

export function loadTherapyServiceList(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch, getState) => {
    const entries = await dispatch(fetchThunk(API_SERVER.therapyManagement.getList().url));

    const decoratedCategories = entries.map((category) => {
      return {
        id: category.id,
        title: category.name,
        items: (category.items || [])
          .filter((t) => t.status === 'ACTIVE')
          .map((entry) => {
            return {
              ...entry,
              id: entry.id,
              healthcareServiceId: entry.id,
              title: category.name,
              name: entry.name,
              price: entry.packagePrice,
            };
          }),
      };
    });

    dispatch(setTherapyServiceList(decoratedCategories));
  };
}

export function loadAllServiceLists(intl?): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch, getState) => {
    const serviceCategoryList = await dispatch(
      axiosThunk({
        url: API_SERVER.service.getAllSystemCategoryList({
          scope: [
            SYSTEM_CATEGORY_SCOPE.healthCheck,
            SYSTEM_CATEGORY_SCOPE.surgical,
            SYSTEM_CATEGORY_SCOPE.laboratory,
            SYSTEM_CATEGORY_SCOPE.other,
            SYSTEM_CATEGORY_SCOPE.radiology,
          ],
        }).url,
      }),
    );

    const mapServiceType = new Map<string, some[]>();
    serviceCategoryList?.data?.forEach((priceList) => {
      if (priceList.type === 'PRICE_LIST') {
        const listGroupFiltered = serviceCategoryList?.data?.filter((g) => g.parentId === priceList.id);
        listGroupFiltered.forEach((group) => {
          const serviceTypeLabel = SYSTEM_CATEGORY_SCOPE_LABEL.find((v) => v.value === group.scope)?.label;
          mapServiceType.set(group.scope, [
            ...(mapServiceType.get(group.scope) || []),
            {
              ...group,
              priceListName: priceList.name,
              priceListId: priceList.id,
              serviceType: group.scope,
              serviceTypeLabel: serviceTypeLabel,
              serviceTypeName: intl ? intl.formatMessage({ id: serviceTypeLabel }) : '',
            },
          ]);
        });
      }
    });

    const listServiceByType: some[] = [];
    for (let scope of mapServiceType.keys()) {
      const label = SYSTEM_CATEGORY_SCOPE_LABEL.find((v) => v.value === scope)?.label;
      listServiceByType.push({
        serviceType: scope,
        label: label,
        name: intl ? intl.formatMessage({ id: label }) : '',
        categoryList: mapServiceType.get(scope),
      });
    }

    dispatch(setAllServiceCategory(listServiceByType));
  };
}

const actions = {
  setHealthcareServiceCategory,
  setTherapyServiceList,
  setAllServiceCategory,
};

type Action = ActionType<typeof actions>;

export default function receptionReducer(
  state: ReceptionState = {
    healthcareServiceCategories: [],
    therapyServiceList: [],
    allServiceCategories: [],
  },
  action: Action,
): ReceptionState {
  switch (action.type) {
    case getType(setHealthcareServiceCategory):
      return { ...state, healthcareServiceCategories: action.entries };
    case getType(setTherapyServiceList):
      return { ...state, therapyServiceList: action.entries };
    case getType(setAllServiceCategory):
      return { ...state, allServiceCategories: action.entries };
    default:
      return state;
  }
}
