import { Box, Grid, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import LogoImage from 'modules/common/component/LogoImage';
import TextValue from 'modules/examDoctor/component/therapy/TherapySessionDetail/PrintTherapySessionDialog/PrintDialogContent/TextValue';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

const Header = () => {
  const { appState } = useGeneralHook();
  const { currentLocation } = appState.authen;
  return (
    <Stack direction="row" sx={{ padding: '9px 64px 0 64px' }}>
      <LogoImage
        src={currentLocation?.avatarId}
        className="logo"
        sx={{
          width: '135px',
          height: 'auto',
          objectFit: 'contain',
          marginRight: '86px',
        }}
      />
      <Box display={'flex'} flexDirection="column" gap={1}>
        <Typography variant="h6" style={{ textTransform: 'uppercase' }}>
          {currentLocation?.name}
        </Typography>
        <Grid container>
          <Grid xs={6}>
            <TextValue label={'medicalPrint.hotline'} value={currentLocation?.phone} labelWidth="60px" />
          </Grid>
          <Grid item xs={6}>
            <TextValue label="clinics.website" value={currentLocation?.website} labelWidth="60px" />
          </Grid>
          <Grid item xs={6}>
            <TextValue label="email" value={currentLocation?.email} labelWidth="60px" />
          </Grid>
          <Grid item xs={6}>
            <TextValue label="clinics.address" value={currentLocation?.address} labelWidth="60px" />
          </Grid>
        </Grid>
      </Box>
    </Stack>
  );
};

export default Header;
