import { Box } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from 'modules/common/api';
import { PAYMENT_PLAN } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaForm from 'modules/common/SchemaForm';
import TableListSupply from './TableListSupply';
import { useFetch, useCreateMutate, useDeleteMutate, useUpdateMutate } from 'modules/common/hook';
import { Encounter, IndicationRequest, MedicalSupplies } from 'modules/schema';

interface Props {
  indicationRequest?: IndicationRequest;
  readOnly?: boolean;
  encounter?: Encounter;
}

const SupplyOrder: React.FunctionComponent<Props> = ({ indicationRequest, readOnly, encounter }) => {
  const { confirmDialog, intl } = useGeneralHook();
  const { close } = confirmDialog;

  // get list medical supplies
  const { data: medicalSupplies } = useFetch(API_SERVER.medicalSupplies.getList());

  // get list indication medical supplies
  const { data: dataIndicationMedicalSupplies, revalidate: revalidateMedicalByEncounter } = useFetch(
    API_SERVER.indicationMedicalSupplies.get({ indicationRequestId: indicationRequest?.id }),
    { enabled: indicationRequest?.id },
  );

  // create medical supplies
  const medicalSuppliesAddMutate = useCreateMutate({ onSuccess: revalidateMedicalByEncounter });
  const onSelectMedicalSupplies = React.useCallback(
    async (medicalSupplies) => {
      if (medicalSupplies) {
        const medicalSuppliesAdd = {
          medicalSuppliesId: medicalSupplies.id,
          isFree: true,
          quantity: 1,
          indicationRequestId: indicationRequest?.id,
        };
        await medicalSuppliesAddMutate({
          url: API_SERVER.indicationMedicalSupplies.create(),
          method: 'POST',
          data: medicalSuppliesAdd,
        });
      }
    },
    [indicationRequest?.id, medicalSuppliesAddMutate],
  );

  // map list medical supplies
  const medicalItems = medicalSupplies?.flatMap((medication) => medication?.items);
  const medicalTable = dataIndicationMedicalSupplies?.map((medical) => {
    const foundMedical = medicalItems?.find((item) => item?.id === medical?.medicalSuppliesId) || {};
    return {
      ...foundMedical,
      ...medical,
    };
  });

  // delete medical supplies
  const deleteMedicalSuppliesMudate = useDeleteMutate({ onSuccess: revalidateMedicalByEncounter });
  const deleteSupplyItem = React.useCallback(
    async (value) => {
      await deleteMedicalSuppliesMudate(
        {
          url: API_SERVER.indicationMedicalSupplies.delete(value.id),
          method: 'DELETE',
        },
        { confirmMessage: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }) },
      );
      close();
    },
    [close, deleteMedicalSuppliesMudate, intl],
  );

  // update medical supplies
  const updateSupplyItemMutate = useUpdateMutate({ onSuccess: revalidateMedicalByEncounter });
  const updateSupplyItem = React.useCallback(
    async (data) => {
      await updateSupplyItemMutate({
        url: API_SERVER.indicationMedicalSupplies.update(data?.id),
        method: 'PUT',
        data: data,
      });
    },
    [updateSupplyItemMutate],
  );

  return (
    <Box>
      {!readOnly && (
        <SchemaForm
          formId="supply-header"
          schema={{
            fields: ({ formProps: { intl }, valuesField, methods }) => {
              const { setValue } = methods;
              const getMedicationItemOptions = () => {
                let arr: any[] = [];
                let categoryList =
                  valuesField?.category && Array.isArray(medicalSupplies)
                    ? medicalSupplies.filter((v) => v.id === valuesField.category?.id)
                    : medicalSupplies || [];
                categoryList.forEach((i) => {
                  i?.items?.forEach((i1) => {
                    const obj = {
                      ...i1,
                      group: i.name,
                    };
                    arr.push(obj);
                  });
                });
                return arr;
              };
              const medicatinItemOptions = getMedicationItemOptions();
              return {
                category: {
                  type: 'auto-complete',
                  options: medicalSupplies,
                  getOptionLabel: (option: MedicalSupplies) => option?.name || '',
                  label: intl.formatMessage({ id: 'therapy.medicalSupplies.category' }),
                  placeholder: intl.formatMessage({ id: 'therapy.medicalSupplies.categoryEnter' }),
                  onChange: () => {
                    setValue('medicalSupplies', null);
                  },
                  propsWrapper: { xs: 6 },
                },
                medicalSupplies: {
                  type: 'auto-complete',
                  options: medicatinItemOptions,
                  getOptionLabel: (option) => option?.name,
                  isOptionEqualToValue: (opt, value) => opt.id === value.id,
                  label: intl.formatMessage({ id: 'therapy.medicalSupplies.name' }),
                  placeholder: intl.formatMessage({ id: 'therapy.medicalSupplies.nameEnter' }),
                  groupBy: (option) => option.group,
                  onChange: (value) => {
                    if (value && value.id) {
                      onSelectMedicalSupplies(value);
                    }
                  },
                  propsWrapper: { xs: 6 },
                },
              };
            },
          }}
          hideSubmitButton
        />
      )}
      <Box marginTop={1}>
        <TableListSupply
          data={medicalTable}
          onDeleteSupply={deleteSupplyItem}
          updateSupplyItem={updateSupplyItem}
          readOnly={readOnly}
          exempted={encounter?.paymentPlan === PAYMENT_PLAN.FREE.value}
        />
      </Box>
    </Box>
  );
};

export default SupplyOrder;
