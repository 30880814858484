import { Box } from '@mui/material';
import * as React from 'react';
import FormList from '../component/reportForm/FormList';

interface Props {}

const ManageEncounterFormPage: React.FunctionComponent<Props> = (props) => {
  return (
    <Box display="flex" flex={1} overflow="hidden">
      <FormList />
    </Box>
  );
};

export default ManageEncounterFormPage;
