import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import {
  BE_DATE_TIME_FORMAT,
  INDICATION_REQUEST_STATUS,
  INDICATION_REQUEST_TYPE,
  PAYMENT_PLAN,
} from '../common/constants';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';

export const IndicationRequestSchema = z
  .object({
    id: z.number(),
    referenceCode: z.string(),
    referenceId: z.number(),
    serviceId: z.number(),
    serviceName: z.string(),
    physicsRoomName: z.string(),
    systemCategoryId: z.number(),
    systemCategoryName: z.string(),
    scope: createObjectKeysEnumSchema(SYSTEM_CATEGORY_SCOPE),
    picUserId: z.number(),
    picName: z.string(),
    type: createObjectKeysEnumSchema(INDICATION_REQUEST_TYPE),
    therapyId: z.number(),
    encounterId: z.number(),
    patientId: z.number(),
    paymentDetailId: z.number(),
    note: z.string(),
    status: createObjectKeysEnumSchema(INDICATION_REQUEST_STATUS),
    isFree: z.boolean(),
    groupId: z.number(),
    executeEmployeeId: z.number(),
    executeName: z.string(),
    patientCode: z.string(),
    patientName: z.string(),
    dob: z.string(),
    homePhone: z.string(),
    paymentPlan: createObjectKeysEnumSchema(PAYMENT_PLAN),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    isPaid: z.boolean(),
    price: z.number(),
    vat: z.number(),
    quantity: z.number(),
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    endTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
  })
  .partial()
  .transform((indication) => {
    return {
      ...indication,
      ...(indication?.patientCode
        ? {
            formatPatientCode: indication?.patientCode?.split('-')?.[1],
          }
        : {}),
    };
  });

export type IndicationRequest = z.infer<typeof IndicationRequestSchema>;
