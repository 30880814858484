import { z } from 'zod';

export const ManagementAgencySchema = z
  .object({
    id: z.number(),
    groupId: z.number(),
    specializedManagement: z.string(),
    directManagement: z.string(),
    address: z.string(),
  })
  .partial();

export type ManagementAgency = z.infer<typeof ManagementAgencySchema>;
