import { UserEmployeeRole } from 'modules/schema';
import DialogCustom from './DialogCustom';
import { useMemo } from 'react';
import useGeneralHook from '../hook/useGeneralHook';
import { Box, Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SchemaFormMain from '../SchemaForm';

interface PICForm {
  picUserId: number;
  picName: string;
  note?: string;
}

interface Props {
  open: boolean;
  onClose(): void;
  dialogTitleKey: string;
  currentPICId: number;
  doctors: UserEmployeeRole[];
  onUpdatePIC(data: PICForm): void;
}

const DialogContent = ({ currentPICId, doctors, onUpdatePIC, onClose }: Omit<Props, 'open' | 'dialogTitleKey'>) => {
  const { intl } = useGeneralHook();

  const doctorOptions = useMemo(
    () =>
      doctors
        ?.filter((doctor) => doctor.id !== currentPICId)
        ?.map((doctor) => ({ value: doctor?.id, label: doctor?.employeeFullName })),
    [currentPICId, doctors],
  );
  return (
    <Box padding={2}>
      <SchemaFormMain
        hideSubmitButton
        onSubmit={onUpdatePIC}
        schema={{
          changeDataBeforeSubmit(formData): PICForm {
            return {
              picName: doctors?.find((doctor) => doctor?.id === formData?.newDoctor)?.employeeFullName!,
              picUserId: formData?.newDoctor,
              note: formData?.note,
            };
          },
          fields: () => {
            return {
              currentPIC: {
                type: 'label',
                label: intl.formatMessage({ id: 'encounter.encounterDetail.editPIC.currentPIC.label' }),
                value: doctors?.find((doctor) => doctor.id === currentPICId)?.employeeFullName,
              },
              newDoctor: {
                type: 'select',
                options: doctorOptions,
                label: intl.formatMessage({ id: 'encounter.encounterDetail.editPIC.newPIC.label' }),
                placeholder: intl.formatMessage({ id: 'encounter.encounterDetail.editPIC.newPIC.placeholder' }),
                propsWrapper: { xs: 12 },
                register: {
                  required: true,
                },
                rawOptions: true,
              },
              note: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'note' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
                rows: 3,
                multiline: true,
              },
              action: {
                type: () => (
                  <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2}>
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ padding: '5px 20px' }}
                      type="button"
                      onClick={onClose}
                    >
                      <Typography>
                        <FormattedMessage id="encounter.encounterDetail.editPIC.editButton.title" />
                      </Typography>
                    </Button>
                    <Button sx={{ padding: '5px 20px' }} type="submit">
                      <Typography>
                        <FormattedMessage id="encounter.encounterDetail.editPIC.saveButton.title" />
                      </Typography>
                    </Button>
                  </Stack>
                ),
              },
            };
          },
        }}
      />
    </Box>
  );
};

const EditPICDialog = (props: Props) => {
  const { open, dialogTitleKey, ...rest } = props;
  return (
    <DialogCustom
      open={open}
      onClose={props.onClose}
      title={dialogTitleKey}
      PaperProps={{
        style: { width: '500px' },
      }}
      maxWidth="xl"
    >
      <DialogContent {...rest} />
    </DialogCustom>
  );
};

export default EditPICDialog;
