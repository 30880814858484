export const GENDER_OPTIONS = [
  { value: 'MALE', label: 'male' },
  { value: 'FEMALE', label: 'female' },
];
export const CATEGORY_INSURANCE = {
  insured: 'insured',
  uninsured: 'uninsured',
  noCard: 'insured-no-card',
};

export const ENTER_REASON_VALUE = {
  correctRoute: 'correct-route',
  incorrectRoute: 'incorrect-route',
  emergency: 'emergency',
  referral: 'referral',
  uninsured: 'uninsured',
};

export const ENTER_REASON_OPTIONS = [
  { id: ENTER_REASON_VALUE.correctRoute, labelId: 'enterReason.correctRoute' },
  { id: ENTER_REASON_VALUE.incorrectRoute, labelId: 'enterReason.incorrectRoute' },
  { id: ENTER_REASON_VALUE.emergency, labelId: 'enterReason.emergency' },
  { id: ENTER_REASON_VALUE.referral, labelId: 'enterReason.referral' },
  { id: ENTER_REASON_VALUE.uninsured, labelId: 'enterReason.uninsured' },
];

export const THERAPY_STATUS = [
  { value: 'NEW', label: 'therapy.management.status.new', sx: 'primary.main' },
  { value: 'PROCESSING', label: 'therapy.management.status.processing', sx: 'warning.main' },
  { value: 'FINISHED', label: 'therapy.management.status.finished', sx: 'success.main' },
  { value: 'CANCELLED', label: 'therapy.management.status.cancelled', sx: 'error.main' },
];

export const THERAPY_SESSION_STATUS = [
  { value: 'NEW', label: 'therapySession.management.status.new', sx: 'primary.main' },
  { value: 'PROCESSING', label: 'therapySession.management.status.processing', sx: 'warning.main' },
  { value: 'FINISHED', label: 'therapySession.management.status.finished', sx: 'success.main' },
];

export const PAYMENT_STATUS = [
  { value: 'PAID', label: 'therapy.payment.status.label.paid', sx: 'success.main' },
  { value: 'UNPAID', label: 'therapy.payment.status.label.unpaid', sx: 'warning.main' },
  { value: 'PACKAGE_PAID', label: 'therapy.payment.status.label.packagePaid', sx: 'warning.main' },
  // { value: 'PREPAID', label: 'therapy.payment.status.label.prepaid', sx: 'primary.main' },
  { value: 'PREPAID', label: 'therapy.payment.status.label.unpaid', sx: 'warning.main' },
  { value: 'REFUNDED', label: 'therapy.payment.status.label.refund', sx: 'primary.main' },
  { value: 'CANCELLED', label: 'therapy.payment.status.label.cancelled', sx: 'error.main' },
];

export const THERAPY_PAYMENT_STATUS_FILTER_OPTIONS = [
  { value: 'PAID', label: 'therapy.payment.status.label.paid', sx: 'success.main' },
  { value: 'UNPAID', label: 'therapy.payment.status.label.unpaid', sx: 'warning.main' },
];

export const STORAGE_RESOURCE_TYPE = {
  therapySession: 'THERAPY_SESSION',
  therapyDiagnosticImage: 'THERAPY_DIAGNOSTIC_IMAGE',
  therapyDiagnosticImageAlbum: 'THERAPY_DIAGNOSTIC_IMAGE_ALBUM',
  therapyIndicationDiagnosticImage: 'THERAPY_INDICATION_DIAGNOSTIC_IMAGE',
  dentalAdult: 'DENTAL_ADULT',
  dentalChild: 'DENTAL_CHILD',
  dentalExamImage: 'DENTAL_EXAM_IMAGE',
  dentalExamImageAlbum: 'DENTAL_EXAM_IMAGE_ALBUM',
  dentalDiagnosisImage: 'DENTAL_DIAGNOSIS_IMAGE',
  dentalDiagnosisImageAlbum: 'DENTAL_DIAGNOSIS_IMAGE_ALBUM',
  encounterIndicationDiagnosticImage: 'ENCOUNTER_INDICATION_DIAGNOSTIC_IMAGE',
  encounterDiagnosticImage: 'ENCOUNTER_DIAGNOSTIC_IMAGE',
  encounterDiagNosticImageAlbum: 'ENCOUNTER_DIAGNOSTIC_IMAGE_ALBUM',
  encounterAttachedDocument: 'ENCOUNTER_ATTACHED_DOCUMENT',
  attachedDocument: 'ATTACHED_DOCUMENT',
} as const;

export const DENTAL_STATUS = [
  { value: 'ARRIVED', label: 'dental.management.status.arrived', sx: 'primary.main' },
  { value: 'PROCESSING', label: 'dental.management.status.processing', sx: 'warning.main' },
  { value: 'FINISHED', label: 'dental.management.status.finished', sx: 'success.main' },
  { value: 'CANCELLED', label: 'dental.management.status.canceled', sx: 'error.main' },
];
