import { EncounterDetailProps } from 'modules/common/component/Encounter/type';
import EncounterDetailBlock from 'modules/common/component/Encounter/EncounterDetailBlock';
import { useForm } from 'react-hook-form';
import { useFetch, useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { useContext, useEffect, useMemo, useState } from 'react';
import { APPOINTMENT_TYPES, CARE_PLAN_SCOPE, CARE_PLAN_TYPE } from 'modules/common/apiConstants';
import { ValueType } from 'modules/common/type';
import SelectInput from 'modules/common/component/form/SelectInput';
import TextInput from 'modules/common/component/form/TextInput';
import { Alert, Button, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { useDebouncedCallback } from 'use-debounce';
import { EncounterContext } from 'modules/common/component/Encounter/const';
import CheckIcon from '@mui/icons-material/Check';
import SunEditorInput from 'modules/common/component/form/SunEditorInput';
import moment from 'moment';
import { FE_TIME_DATE_FORMAT } from 'modules/common/constants';
import AppointmentBookingDialog from '../session/exam/AppointmentBookingDialog';

interface Form {
  type: ValueType<typeof CARE_PLAN_TYPE> | null;
  note: string;
  hospitalCode: string;
  hospitalName: string;
}

const Solution = ({ encounter, readonly }: EncounterDetailProps) => {
  const [popperElement, setPopperElement] = useState(null);
  const onOpenCreateDialog = (event) => {
    setPopperElement(popperElement ? null : event.currentTarget);
  };
  const open = Boolean(popperElement);

  const { data: carePlan } = useFetch(
    API_SERVER.carePlan.get({ referenceId: encounter.id!, scope: CARE_PLAN_SCOPE.ENCOUNTER }),
  );

  const { data: appointmentData, revalidate: revalidateAppointment } = useFetch(
    API_SERVER.appointment.searchAll({ referenceId: encounter.id!, type: APPOINTMENT_TYPES.ENCOUNTER }),
    {
      enabled: encounter.id,
    },
  );

  const message = useMemo(() => {
    if (appointmentData && appointmentData?.length !== 0) {
      const startTime = moment(appointmentData[0].startTime).format(FE_TIME_DATE_FORMAT);
      return startTime;
    }
  }, [appointmentData]);

  const form = useForm<Form>({
    defaultValues: {
      hospitalCode: '',
      hospitalName: '',
      note: carePlan?.note,
      type: CARE_PLAN_TYPE.RE_EXAMINATION || CARE_PLAN_TYPE[carePlan?.type!],
    },
  });

  const note = form.getValues('note');
  const type = form.getValues('type');
  const disableBookButton = useMemo(
    () =>
      type &&
      type?.value !== CARE_PLAN_TYPE.HOME_EXAMINATION.value &&
      type?.value !== CARE_PLAN_TYPE.RE_EXAMINATION.value &&
      type?.value !== CARE_PLAN_TYPE.PRESCRIPTION.value &&
      type?.value !== CARE_PLAN_TYPE.OTHER.value,
    [type],
  );

  useEffect(() => {
    if (carePlan) {
      form.reset({
        hospitalCode: carePlan.hospitalCode,
        hospitalName: carePlan.hospitalName,
        note: carePlan.note,
        type: CARE_PLAN_TYPE[carePlan.type!],
      });
    }
  }, [carePlan, form]);
  const encounterContext = useContext(EncounterContext);
  const onUpdateCarePlanMutate = useUpdateMutate({
    globalLoading: false,
    onSuccess: () => encounterContext?.setCounter(encounterContext.counter + 1),
  });

  const onUpdateCarePlan = useDebouncedCallback((value) => {
    const formData = form.getValues();
    if (typeof value === 'string' && value !== note) {
      try {
        onUpdateCarePlanMutate({
          url: API_SERVER.carePlan.create(),
          method: 'PUT',
          data: {
            referenceId: encounter?.id,
            type: formData.type?.value,
            scope: CARE_PLAN_SCOPE.ENCOUNTER,
            hospitalCode: formData?.hospitalCode,
            hospitalName: formData?.hospitalName,
            note: value,
          },
        });
        form.reset({
          ...form.getValues(),
          note: value,
        });
      } catch (error) {
        form.reset();
      }
    }
    if (Object.keys(form.formState.dirtyFields).length > 0) {
      try {
        onUpdateCarePlanMutate({
          url: API_SERVER.carePlan.create(),
          method: 'PUT',
          data: {
            referenceId: encounter?.id,
            type: formData.type?.value,
            scope: CARE_PLAN_SCOPE.ENCOUNTER,
            hospitalCode: formData?.hospitalCode,
            hospitalName: formData?.hospitalName,
            note: formData?.note,
          },
        });
        form.reset(form.getValues());
      } catch (error) {
        form.reset();
      }
    }
  }, 2000);

  const sunEditorHeight = () => {
    if (type?.value === CARE_PLAN_TYPE.HOSPITAL_TRANSFER.value) {
      return '160px';
    }
    return '237px';
  };

  return (
    <EncounterDetailBlock title="encounter.encounterDetail.block.title.solution">
      <Stack
        direction="row"
        alignContent="center"
        justifyContent="space-between"
        gap="10px"
        marginBottom="8px"
        sx={{
          '@media screen and (max-width: 1366px)': {
            display: 'block',
          },
        }}
      >
        <Stack direction="row" width={'100%'}>
          <SelectInput
            form={form}
            name="type"
            options={Object.values(CARE_PLAN_TYPE)}
            placeholder="encounter.encounterDetail.input.placeholder.solution"
            getValue="value"
            renderLabel="label"
            disabled={readonly}
            hideError
            onChange={onUpdateCarePlan}
            sx={{ fontSize: '16px' }}
          />
          {!readonly && (
            <Button
              sx={{ whiteSpace: 'nowrap', marginLeft: '10px' }}
              disabled={disableBookButton || false}
              onClick={onOpenCreateDialog}
            >
              <FormattedMessage id="appointmentBook.book" />
            </Button>
          )}
        </Stack>
        {message && (
          <Alert
            icon={<CheckIcon fontSize="inherit" />}
            severity="success"
            sx={{
              background: 'none',
              flexDirection: 'row',
              minWidth: '217px',
              padding: '5px 0 0',
              justifyContent: 'end',
              '>div': { padding: 0 },
              '>div:first-child': { marginRight: '5px' },
              '@media screen and (max-width: 1366px)': {
                justifyContent: 'left',
              },
            }}
          >
            <Typography fontSize={'14px'}>
              <FormattedMessage id="booked" /> {message}
            </Typography>
          </Alert>
        )}
      </Stack>

      {type?.value === CARE_PLAN_TYPE.HOSPITAL_TRANSFER.value ? (
        <Stack direction="row" alignItems="center" justifyContent="center" gap="10px">
          <TextInput
            form={form}
            name="hospitalName"
            placeholder="encounterInfo.hospitalNameEnter"
            label="encounterInfo.hospitalName"
            disabled={readonly}
            onChange={onUpdateCarePlan}
          />
          <TextInput
            form={form}
            name="hospitalCode"
            placeholder="encounterInfo.hospitalNameEnter"
            label="encounterInfo.hospitalCode"
            disabled={readonly}
            onChange={onUpdateCarePlan}
          />
        </Stack>
      ) : null}
      <SunEditorInput
        name="note"
        readOnly={readonly}
        height={sunEditorHeight()}
        onChange={(value) => onUpdateCarePlan(value)}
        setContents={note || ''}
      />
      <AppointmentBookingDialog
        popperElement={popperElement}
        open={open}
        setPopperElement={setPopperElement}
        encounter={encounter}
        revalidateAppointment={revalidateAppointment}
      />
    </EncounterDetailBlock>
  );
};

export default Solution;
