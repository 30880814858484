import { z } from 'zod';

export const MedicalExaminationFormSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    symptom: z.string(),
    illnessesHistory: z.string(),
    provisionalDiagnosis: z.string(),
    differentialDiagnosis: z.string(),
    medicalHistory: z.string(),
    note: z.string(),
  })
  .partial();

export type MedicalExaminationForm = z.infer<typeof MedicalExaminationFormSchema>;
