import { Delete } from '@mui/icons-material';
import { Box, Stack, Switch, Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { MedicalSuppliesUsage } from 'modules/schema';
import QuantityBox from 'modules/common/component/QuantityBox';
import { NumericFormatText } from 'modules/common/utils';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';

interface Props {
  data?: MedicalSuppliesUsage[];
  loading?: boolean;
  readOnly?: boolean;
  onDeleteSupply: (value: MedicalSuppliesUsage) => void;
  updateSupplyItem: (value) => void;
  exempted: boolean;
}

type ColumnType = MedicalSuppliesUsage & {
  patientPayPrice?: number;
  purchasePrice?: number;
  vat?: number;
  quantity?: number;
  commercialName?: string;
};

const TableListSupply: React.FunctionComponent<Props> = ({
  data,
  loading,
  readOnly,
  onDeleteSupply,
  updateSupplyItem,
  exempted,
}) => {
  const columns = React.useMemo(() => {
    let totalVat = 0;
    let totalAmount = 0;
    let totalAmountPaid = 0;

    if (data && data?.length !== 0) {
      data
        .filter((i) => !i.isFree)
        .forEach((item: ColumnType) => {
          if (item?.quantity && item?.price) {
            // Cacualate amount
            let amount = item.price * item.quantity;
            totalAmount += amount;
            // Caculate Vat amount
            let vat = item.vat ? item.vat : 0;
            let vatAmount = (amount * vat) / 100;
            // Cacualate amount paid
            totalVat += vat;
            totalAmountPaid += amount + vatAmount;
          }
        });
    }

    return [
      {
        title: 'supply.group',
        dataIndex: 'medicalSuppliesGroup',
      },
      {
        title: 'supply.name',
        dataIndex: 'medicalSuppliesName',
      },
      {
        title: 'quantity',
        width: 160,
        render: (record) => {
          return (
            <QuantityBox
              min={1}
              readOnly={readOnly || exempted || record?.isDisable}
              value={record.quantity}
              onChange={async (value) => {
                const data = {
                  ...record,
                  quantity: value,
                  type: 'ENCOUNTER',
                };
                await updateSupplyItem(data);
              }}
            />
          );
        },
      },
      {
        title: 'unitPrice',
        render: (record) => {
          return record.price && NumericFormatText(record.price);
        },
      },
      {
        title: 'totalPrice',
        render: (record) => {
          return record.price && record.quantity && NumericFormatText(record.price * record.quantity);
        },
      },
      {
        title: 'cashier.vatRate',
        render: (record) => {
          return record.vat !== undefined && record.vat !== 0 ? record.vat : <FormattedMessage id="cashier.noVat" />;
        },
        lastCell: {
          colSpan: 6,
        },
      },
      {
        title: 'supply.provisional',
        render: (record) => {
          let totalAmountPaid = 0;
          if (record?.quantity && record?.price) {
            let vat = record.vat ? record.vat : 0;
            let amount = record.quantity * record.price;
            let vatAmount = (amount * vat) / 100;
            totalAmountPaid = amount + vatAmount;
          }
          return NumericFormatText(totalAmountPaid);
        },
        lastCell: {
          colSpan: 6,
          render: () => {
            return (
              <Stack>
                <Box display="flex" justifyContent="space-between" marginY={1}>
                  <Typography variant="subtitle1" color="primary">
                    <FormattedMessage id="totalPrice" />
                  </Typography>
                  <Typography variant="subtitle1">{NumericFormatText(totalAmount)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" marginY={1}>
                  <Typography variant="subtitle1" color="primary">
                    <FormattedMessage id="vat" />
                  </Typography>
                  <Typography variant="subtitle1">{NumericFormatText(totalVat)}</Typography>
                </Box>
                <Box display="flex" justifyContent="space-between" marginY={1}>
                  <Typography variant="h6" color="textPrimary">
                    <FormattedMessage id="patientPay" />
                  </Typography>
                  <Typography variant="h6">{NumericFormatText(totalAmountPaid)}</Typography>
                </Box>
              </Stack>
            );
          },
        },
      },
      {
        title: 'action',
        width: 100,
        hidden: readOnly,
        render: (record) => {
          return (
            <>
              <Tooltip title={<FormattedMessage id="payMoreSwitch" />} placement="top" arrow>
                <Switch
                  size="small"
                  color="primary"
                  defaultChecked={!record?.isFree}
                  disabled={readOnly || exempted || record?.isDisable}
                  onChange={async (value) => {
                    const data = {
                      ...record,
                      isFree: !value.target.checked,
                      type: 'ENCOUNTER',
                    };

                    await updateSupplyItem(data);
                  }}
                />
              </Tooltip>
              <IconButtonTitle
                disabled={readOnly || exempted || record?.isDisable}
                title="delete"
                size="small"
                onClick={() => onDeleteSupply(record)}
              >
                <Delete />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns<ColumnType>[];
  }, [data, exempted, onDeleteSupply, readOnly, updateSupplyItem]);

  return <TableCustom dataSource={data} loading={loading} columns={columns} />;
};
export default TableListSupply;
