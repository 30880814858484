import { Delete, Edit } from '@mui/icons-material';
import * as React from 'react';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { some } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';

interface IEncounterListProps {
  data?: some;
  loading: boolean;
  setFormData: (value: some) => void;
  onDelete: (value: some) => void;
  hookPagination: HookPaginationProps;
}

const TableBox: React.FunctionComponent<IEncounterListProps> = ({
  data,
  loading,
  hookPagination,
  setFormData,
  onDelete,
}) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const columns: Columns[] = [
    {
      dataIndex: 'name',
      title: 'supplier.name',
    },
    {
      dataIndex: 'representationPerson',
      title: 'supplier.presentative',
    },
    {
      dataIndex: 'phoneNumber',
      title: 'telephone',
    },
    {
      dataIndex: 'address',
      title: 'address',
    },
    {
      dataIndex: 'email',
      title: 'email',
    },
    {
      dataIndex: 'taxNumber',
      title: 'supplier.taxCode',
    },
    {
      title: 'action',
      align: 'right',
      width: 160,
      render: (record) => {
        return (
          <>
            <IconButtonTitle
              title="update"
              size="small"
              onClick={() => {
                setFormData(record);
              }}
            >
              <Edit />
            </IconButtonTitle>
            <IconButtonTitle
              title="delete"
              size="small"
              onClick={() => {
                onDelete(record);
              }}
            >
              <Delete />
            </IconButtonTitle>
          </>
        );
      },
    },
  ];

  return (
    <>
      <TableCustom
        dataSource={data?.content}
        loading={loading}
        columns={columns}
        paginationProps={{
          count: data?.pagination?.totalElements || 0,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
    </>
  );
};

export default TableBox;
