import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { PAYMENT_HISTORY_TYPE, PAYMENT_METHOD } from 'modules/common/apiConstants';
import { PAYMENT_PLAN } from 'modules/common/constants';

export const RevenueExpenditureSchema = z
  .object({
    code: z.string(),
    examName: z.string(),
    patientName: z.string(),
    amountPaid: z.number(),
    refundAmount: z.number(),
    type: createObjectKeysEnumSchema(PAYMENT_HISTORY_TYPE),
    paymentMethod: createObjectKeysEnumSchema(PAYMENT_METHOD),
    employeeName: z.string(),
    transactionTime: z.string(),
    note: z.string(),

    paymentPlan: createObjectKeysEnumSchema(PAYMENT_PLAN),
  })
  .partial();

export type RevenueExpenditure = z.infer<typeof RevenueExpenditureSchema>;
