import React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { some } from '../../../common/constants';
import SchemaForm from '../../../common/SchemaForm';
import { schemaChangePassword } from './schema';
interface Props {
  label?: string;
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
}

const ChangePasswordBox = (props: Props) => {
  const { onSubmit } = props;
  return <SchemaForm schema={schemaChangePassword} onSubmit={onSubmit} hideSubmitButton />;
};
export default ChangePasswordBox;
