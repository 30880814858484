import { z } from 'zod';
import { LaboratoryReferenceSchema } from './LaboratoryReference';
import { createObjectKeysEnumSchema } from './common';
import { INDICATION_LABORATORY_EVALUATE } from 'modules/common/apiConstants';

export const IndicationLaboratorySchema = z
  .object({
    id: z.number(),
    indicationRequestId: z.number(),
    laboratoryReferenceId: z.number(),
    laboratoryReferenceName: z.string(),
    isChoose: z.boolean(),
    result: z.string(),
    evaluate: createObjectKeysEnumSchema(INDICATION_LABORATORY_EVALUATE),
    interpretation: z.string(),
    laboratoryReferenceDetailObject: LaboratoryReferenceSchema,
  })
  .partial();

export type IndicationLaboratory = z.infer<typeof IndicationLaboratorySchema>;
