import { AxiosError } from 'axios';
import * as React from 'react';
import { some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import usePaginationHook from '../../../common/hook/usePaginationHook';
import { axiosThunk } from '../../../common/redux/axios';
import { setLoading } from '../../../common/redux/commonReducer';
import { useState } from 'react';
import { useFetch } from 'modules/common/hook';

const useDrugDataAdmin = () => {
  const { dispatch, openSnackbar, confirmDialog, locationId, intl, API_SERVER, appState } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const { params, setParams } = usePaginationHook({ disablePagination: true });
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();
  const { data, revalidate, isValidating } = useFetch(
    API_SERVER.medicationCategory.getCategoryByClinicId({ productName: params.name }),
  );
  const [refreshMedicationCategoryId, setRefreshMedicationCategoryId] = useState<number | null>(null);

  const onSubmitForm = React.useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: value?.id ? API_SERVER.medication.update(value.id) : API_SERVER.medication.index(locationId).url,
          method: value?.id ? 'put' : 'post',
          data: { ...value, currency: appState.common.groupCurrency },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
          revalidate();
          setParams({ medicationId: '', medicationCategoryId: '' });
          setRefreshMedicationCategoryId(value?.medicationCategoryId);
        })
        .catch((e: any) => {
          if (e?.response?.data?.errors)
            e?.response?.data?.errors?.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          else {
            openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateFail' : 'createFail' }) });
          }
          setRefreshMedicationCategoryId(null);
        });
      dispatch(setLoading(false));
    },
    [
      API_SERVER.medication,
      appState.common.groupCurrency,
      dispatch,
      intl,
      locationId,
      openSnackbar,
      revalidate,
      setParams,
    ],
  );

  const onDeleteGroup = React.useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.medicationCategory.update(value.id),
            method: 'delete',
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
            revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });
      }
      close();
    },
    [API_SERVER.medicationCategory, close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const onSubmitFormGroup = React.useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: value?.id
            ? API_SERVER.medicationCategory.update(value.id)
            : API_SERVER.medicationCategory?.getCategoryByClinicId(locationId).url,
          method: value?.id ? 'put' : 'post',
          data: value,
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
          revalidate();
          setFormDataGroup(undefined);
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
        });
      dispatch(setLoading(false));
    },
    [API_SERVER.medicationCategory, dispatch, intl, locationId, openSnackbar, revalidate],
  );

  return {
    data,
    revalidate,
    isValidating: isValidating && !data,
    state: { searchParams: params, setSearchParams: setParams, formDataGroup },
    fnc: {
      setFormDataGroup,
      onDeleteGroup,
      onSubmitFormGroup,
      onSubmitForm,
      refreshMedicationCategoryId,
      setRefreshMedicationCategoryId,
    },
  };
};

export default useDrugDataAdmin;
