import { ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl } = formProps || {};

  return {
    title: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'imageIndication.nameTable' }),
      placeholder: intl.formatMessage({ id: 'imageIndication.nameTableEnter' }),
      propsWrapper: { xs: 12 },
      style: { minWidth: 220 },
      register: {
        required: true,
      },
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'info',
      fields: ['title'],
    },
  ];
};

export const listServiceFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
