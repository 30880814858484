import { Box, Divider, Typography } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import MediCard from '../../../../../common/component/MediCard';
import {
  FieldsType,
  ILayoutFields,
  ISchemaFields,
  ISchemaForm,
  IUiFields,
  SchemaType,
} from '../../../../../common/SchemaForm/utils';
import ImageBox from './ImageBox';
import { INDICATION_REQUEST_STATUS } from '../../../../../common/constants';

const fieldSchema: ISchemaFields = ({ formProps, methods, valuesField }) => {
  const { intl, encounterId, onPrint, evaluationFormMap, onSave, patientData, indicationRequest } = formProps || {};
  const { setValue, trigger } = methods;

  return {
    indicationRequestId: {
      type: 'hidden',
    },
    images: {
      type: 'array',
      rawComponent: ImageBox,
      patientData: patientData,
      patientId: valuesField?.patientId,
      encounterId: encounterId,
      formProps,
      hideInitialElement: true,
      noHelperText: true,
      propsWrapper: { style: { paddingTop: 0, paddingBottom: 0 } },
    },
    parts: {
      type: 'auto-complete',
      options: Object.values(evaluationFormMap),
      label: intl.formatMessage({ id: 'subclinical.parts' }),
      placeholder: intl.formatMessage({ id: 'subclinical.pickForm' }),
      getOptionLabel: (option) => option.name || ' ',
      isOptionEqualToValue: (opt, value) => opt.id === value.id,
      propsWrapper: { xs: 4 },
      onChange: (value) => {
        if (value) {
          setValue('examDepartment', value?.examDepartment);
          setValue('conclusion', value?.conclusion);
        }
      },
      disableClearable: true,
      blurOnSelect: true,
      noHelperText: true,
    },
    examDepartment: {
      type: 'text-editor',
      label: intl.formatMessage({ id: 'subclinical.describe' }),
      height: 350,
      noHelperText: true,
    },
    conclusion: {
      type: 'text-editor',
      label: intl.formatMessage({ id: 'conclusion' }),
      height: 350,
      noHelperText: true,
    },
    content: {
      type: () => (
        <Box>
          <Typography variant={'subtitle1'}>
            <FormattedMessage id="subclinical.describe" />
          </Typography>
          <div className="mce-editor-customs" dangerouslySetInnerHTML={{ __html: valuesField.describe }} />
          <Divider style={{ margin: '8px 0px' }} />
          <Typography variant={'subtitle1'}>
            <FormattedMessage id="conclusion" />
          </Typography>
          <div className="mce-editor-customs" dangerouslySetInnerHTML={{ __html: valuesField.conclusion }} />
        </Box>
      ),
    },

    submit: {
      type: 'submitButton',
      label: intl.formatMessage({ id: 'complete' }),
      style: { minWidth: 200 },
      propsWrapper: { xs: undefined },
    },
    print: onPrint && {
      type: 'button',
      label: intl.formatMessage({ id: 'imageDoctor.print' }),
      style: { minWidth: 200 },
      disabled: indicationRequest?.status !== INDICATION_REQUEST_STATUS.FINISHED.value,
      color: 'inherit',
      endIcon: <PrintIcon />,
      propsWrapper: { xs: undefined },
      onClick: onPrint,
    },
    save: {
      type: 'button',
      label: intl.formatMessage({ id: 'save' }),
      style: { minWidth: 200 },
      onClick: async () => {
        const validate = await trigger();
        if (validate) {
          onSave(valuesField);
        }
      },
      propsWrapper: { xs: undefined },
    },
  } as SchemaType;
};

const ui: IUiFields = ({ formProps }) => {
  const { readOnly } = formProps;
  return [
    {
      id: 'images',
      fields: ['images'],
    },
    {
      id: 'content',
      fields: readOnly ? ['content'] : ['parts', 'examDepartment', 'conclusion'],
    },
    {
      id: 'submit',
      fields: readOnly ? ['print'] : ['print', 'save', 'submit'],
      propsGridContainer: { justifyContent: 'flex-end' },
    },
  ] as FieldsType[];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement } = props;

  return (
    <div>
      <Box marginY={2}>{listElement[0]}</Box>
      <MediCard title="result">
        <Box margin={2}> {listElement[1]}</Box>
      </MediCard>
      <Box marginTop={2}>{listElement[2]}</Box>
    </div>
  );
};

const schemaClinical: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
export default schemaClinical;
