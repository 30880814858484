import { Box } from '@mui/system';
import TextValue from '../../../../examDoctor/component/therapy/TherapySessionDetail/PrintTherapySessionDialog/PrintDialogContent/TextValue';
import Stack from '@mui/material/Stack';
import { Encounter } from 'modules/schema';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';

interface Props {
  encounter: Encounter;
  compact: boolean;
}
const ExtraInfo = ({ encounter, compact }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      {!compact && (
        <Box flex={1.5}>
          <TextValue label="doctorOfIndication" value={encounter?.picName || ''} labelWidth="175px" />
          <TextValue
            label="printForm.placeOfIndication"
            value={encounter?.physicsRoom?.name || ''}
            labelWidth="175px"
          />
          <TextValue label="printForm.samplingTime" value="" labelWidth="175px" />
          <TextValue label="printForm.approveTime" value="" labelWidth="175px" />
        </Box>
      )}
      <Box flex={1}>
        <TextValue
          labelWidth="130px"
          label="encounterDetail.clinicalSymptoms"
          value={encounter?.clinicalExamination ? extractHTMLContent(encounter.clinicalExamination) || '' : ''}
        />
        {!compact && <TextValue label="referenceIndication.specimenNumber" value="" labelWidth="130px" />}
      </Box>
    </Stack>
  );
};

export default ExtraInfo;
