import { IconButton as IconButtonMUI, IconButtonProps, Tooltip, TooltipProps, Typography } from '@mui/material';
import { ReactNode, useCallback } from 'react';
import { useIntl } from 'react-intl';

interface Props extends IconButtonProps {
  showSlotNumber?: boolean;
  slotNumber?: number;
  icon: ReactNode;
  tooltipTitle?: string;
  tooltipProps?: TooltipProps;
}

const IconButton = ({ icon, showSlotNumber = false, slotNumber, tooltipTitle, tooltipProps, ...otherProps }: Props) => {
  const intl = useIntl();
  const renderButton = useCallback(
    () => (
      <IconButtonMUI
        {...otherProps}
        sx={{
          padding: '4px 8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: '#CDDFFF',
          borderRadius: '5px',
          width: '32px',
          height: '32px',
          cursor: otherProps.disabled ? 'not-allowed' : 'pointer',
          boxSizing: 'border-box',
          position: 'relative',
          '&.Mui-disabled': {
            backgroundColor: '#dde3e9 !important',
          },
          ...otherProps.sx,
        }}
      >
        {icon}
        {showSlotNumber && (
          <Typography
            component="span"
            sx={{
              position: 'absolute',
              backgroundColor: 'white',
              filter: 'drop-shadow(0px 4px 16px #E0ECFF)',
              width: '15px',
              height: '15px',
              lineHeight: '15px',
              color: '#307BFF',
              borderRadius: '50%',
              top: '-7px',
              right: '-7px',
              fontFamily: 'Roboto',
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 700,
              letterSpacing: '0.15px',
            }}
          >
            {slotNumber}
          </Typography>
        )}
      </IconButtonMUI>
    ),
    [icon, otherProps, showSlotNumber, slotNumber],
  );
  return tooltipTitle ? (
    <Tooltip {...tooltipProps} title={intl.formatMessage({ id: tooltipTitle })}>
      {renderButton()}
    </Tooltip>
  ) : (
    renderButton()
  );
};

export default IconButton;
