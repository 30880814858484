import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import * as React from 'react';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { PRIMARY } from '../../../../colors';
import { AppState } from '../../../../redux/reducer';
import { BE_DATE_FORMAT, some } from '../../../common/constants';
import '../../css/slider.css';
import AppointmentInfoBox from './AppointmentInfoBox';
import { GroupIcon, PersonIcon } from '../../../../svg';
import { Close, Delete, Edit } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import { APPOINTMENT_TYPES } from './constants';
import { Appointment } from 'modules/schema';

interface Props {
  appointments?: Appointment[];
  onChange: (current, next) => void;
  onUpdate: (value: Appointment) => void;
  onDelete: (value: Appointment) => void;
  params: some;
}

const EncounterCalendarBox: React.FunctionComponent<Props> = (props) => {
  const { appointments, onChange, onDelete, onUpdate, params } = props;
  const { locale } = useSelector((state: AppState) => ({ locale: state.intl.locale }));
  const sliderRef = React.useRef<any>();

  const formatedAppointments = React.useMemo(() => {
    const numberDays = moment(params.dateRange.end).diff(moment(params.dateRange.start), 'day') + 1;
    return Array(numberDays)
      .fill(0)
      .map((_, index) => {
        const date = moment(params.dateRange.start).add(index, 'days');
        return {
          date: date,
          appointments: appointments
            ?.filter((appointment) => date.format(BE_DATE_FORMAT) === appointment.startTime?.format(BE_DATE_FORMAT))
            ?.sort((firstItem, secondItem) => firstItem?.id! - secondItem?.id!),
        };
      });
  }, [appointments, params.dateRange.end, params.dateRange.start]);

  return (
    <Box
      position="relative"
      style={{ overflow: 'hidden', paddingRight: 3 }}
      onContextMenu={(e) => {
        e.preventDefault();
      }}
    >
      <Slider
        ref={(instance) => (sliderRef.current = instance)}
        slidesToShow={5}
        initialSlide={0}
        infinite={false}
        arrows={false}
        className="slider-padding"
        speed={500}
        beforeChange={(current, next) => {
          onChange(current, next);
        }}
        responsive={[
          {
            breakpoint: 1124,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 1568,
            settings: {
              slidesToShow: 4,
            },
          },
        ]}
      >
        {formatedAppointments.map((day, dayIndex) => (
          <Box key={dayIndex}>
            <Box
              textAlign="center"
              paddingY={1}
              style={{ textTransform: 'capitalize' }}
              color={day.date.dayOfYear() === moment().dayOfYear() ? PRIMARY : 'inherit'}
            >
              <Typography variant="h6">{day.date.locale(locale).format('dddd DD')}</Typography>
            </Box>
            <Box
              border={`1px solid ${grey[400]}`}
              borderLeft={dayIndex === 0 ? `1px solid ${grey[400]}` : 'unset'}
              height="calc(100vh - 320px)"
              overflow="auto"
              display={'flex'}
              flexDirection="column"
              gap={1}
              p={1}
            >
              {day.appointments ? (
                day.appointments.map((appointment) => {
                  const color = appointment.appointmentGroupId
                    ? APPOINTMENT_TYPES.APPOINTMENT_GROUP.color
                    : appointment.patientId
                    ? APPOINTMENT_TYPES.PATIENT.color
                    : appointment.type === 'THERAPY'
                    ? APPOINTMENT_TYPES.THERAPY.color
                    : APPOINTMENT_TYPES.PATIENT.color;
                  const isGroup = appointment?.appointmentGroupId;
                  return (
                    <AppointmentInfoBox
                      sx={{ width: '100%' }}
                      data={appointment}
                      onUpdate={onUpdate}
                      onDelete={onDelete}
                      headerComponent={({ onClose, onDelete, onUpdate }) => {
                        return (
                          <Box display={'flex'} gap={2} alignItems="center">
                            <Button
                              variant="contained"
                              sx={{ backgroundColor: color }}
                              disableRipple
                              disableElevation
                              size="small"
                              style={{ cursor: 'unset', padding: 0 }}
                            >
                              <Box paddingX={0.5}>{isGroup ? <GroupIcon /> : <PersonIcon />}</Box>
                              <Typography variant="body2" style={{ padding: '4px 8px 4px 0px' }}>
                                <FormattedMessage id={isGroup ? 'calendar.group' : 'calendar.person'} />
                              </Typography>
                            </Button>
                            {appointment?.type === APPOINTMENT_TYPES.THERAPY.value || (
                              <Box display={'flex'} gap={1} justifyContent="flex-end" flex={1}>
                                <IconButton onClick={() => onUpdate && onUpdate(appointment)}>
                                  <Edit />
                                </IconButton>
                                <IconButton onClick={() => onDelete && onDelete(appointment)}>
                                  <Delete />
                                </IconButton>
                                {onClose && (
                                  <IconButton onClick={onClose}>
                                    <Close />
                                  </IconButton>
                                )}
                              </Box>
                            )}
                          </Box>
                        );
                      }}
                    />
                  );
                })
              ) : (
                <Box>Loading</Box>
              )}
            </Box>
          </Box>
        ))}
      </Slider>
      <Box position="absolute" top={4} left={0}>
        <IconButton
          size="small"
          style={{ background: 'white' }}
          onClick={() => {
            sliderRef.current?.slickPrev();
          }}
        >
          <ChevronLeftIcon />
        </IconButton>
      </Box>
      <Box position="absolute" top={4} right={0}>
        <IconButton
          size="small"
          style={{ background: 'white' }}
          onClick={() => {
            sliderRef.current?.slickNext();
          }}
        >
          <ChevronRightIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default EncounterCalendarBox;
