import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FE_DATE_FORMAT, THERAPY_SESSION_STATUS } from 'modules/common/constants';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { TherapyWorkReport } from 'modules/schema';
import _ from 'lodash';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';

interface Props {
  data?: any;
  paginationProps: any;
}

const DetailedTable: React.FunctionComponent<Props> = ({ data, paginationProps }) => {
  const tableDataMapped = React.useMemo(() => {
    return data?.content?.map((ele) => ({
      ...ele,
      startTime: moment(ele?.startTime)?.format(FE_DATE_FORMAT),
      endTime: ele?.endTime && moment(ele?.endTime)?.format(FE_DATE_FORMAT),
      patientCode: ele?.patientCode?.split('-')?.[1],
    }));
  }, [data]);

  const LIST_COLUMNS: Column<TherapyWorkReport>[] = [
    { type: 'INDEX', width: '2%', sticky: true, align: 'center' },
    {
      title: 'therapy.label.customer.code.kh',
      render: 'patientCode',
    },
    {
      render: 'patientName',
      title: 'therapy.label.customerName',
    },
    {
      title: 'therapyList.header.label.diagnosis',
      render: (record) => {
        return (
          <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
            {record.diagnosis && extractHTMLContent(record.diagnosis)}
          </Typography>
        );
      },
    },
    {
      render: 'therapyCode',
      title: 'therapy.code',
      minWidth: 80,
    },
    {
      render: 'therapyName',
      title: 'therapy',
      minWidth: 80,
    },
    {
      render: 'therapySessionName',
      title: 'therapy.session.name',
    },
    {
      title: 'status',
      render: (record) => {
        return (
          <Box component="span" color={THERAPY_SESSION_STATUS?.[record.therapySessionStatus!]?.color}>
            <FormattedMessage id={THERAPY_SESSION_STATUS?.[record.therapySessionStatus!]?.label || ' '} />
          </Box>
        );
      },
    },
    {
      render: 'picUserName',
      title: 'therapyList.header.label.therapySession.picName',
    },
    {
      title: 'participant',
      render: (record) => {
        const attendPersonName = record?.attendPersons && _.map(record?.attendPersons, 'employeeName').join(', ');
        return <Typography variant="body1">{attendPersonName}</Typography>;
      },
    },
    {
      render: 'startTime',
      title: 'therapyList.header.label.therapySession.startDate',
    },
    {
      render: 'endTime',
      title: 'therapyList.header.label.therapySession.endDate',
    },
    {
      title: 'therapyList.header.label.therapySession.conclude',
      render: (record) => {
        return (
          <Typography variant="body1" sx={{ wordWrap: 'break-word' }}>
            {record.conclude}
          </Typography>
        );
      },
    },
  ];

  return (
    <Table
      columns={LIST_COLUMNS}
      dataKey="id"
      data={tableDataMapped}
      paginationProps={paginationProps}
      totalElements={data?.pagination?.totalElements}
    />
  );
};

export default DetailedTable;
