import { Box, Paper, Tab, Tabs, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import { FE_DATE_TIME_FORMAT, some } from '../../common/constants';
import ParticipantTab from '../component/therapy/detailTabs/attendPersons/ParticipantTab';
import { API_SERVER } from '../../common/api';
import PatientInfoBox from '../component/therapy/PatientInfoBox';
import TestServiceTab from '../component/therapy/testService/TestServiceTab';
import TherapyMedicalSuppliesList from '../component/therapy/TherapyMedicalSupplies/TherapyMedicalSuppliesList';
import { useFetch } from 'modules/common/hook';

const TAB_MENUS = [{ id: 'experiment' }, { id: 'participant' }, { id: 'supply.title' }];

const LabDoctorIndicationDentalDetail = () => {
  const { indicationRequestId, dentalExamId } = useParams<{ indicationRequestId: string; dentalExamId: string }>();
  const [tabValue, setTabValue] = React.useState(0);
  const { data: indicationRequest } = useFetch(API_SERVER.indicationRequest.get(parseInt(indicationRequestId)));

  const { data: dentalExam } = useFetch(API_SERVER.dentalExam.getDentalExam(dentalExamId));

  return (
    <Paper elevation={1} style={{ overflow: 'auto', position: 'relative', flex: 1 }}>
      <Box bgcolor={'white'} borderBottom={`1px solid ${grey[400]}`}>
        <Box padding={2} paddingBottom={1}>
          <PatientInfoBox exam={dentalExam} indicationRequest={indicationRequest} />
        </Box>
        <Box paddingX={2}>
          <Typography variant="h6" color="primary">
            {dentalExam?.patient?.name}
            &emsp;-&emsp;
            <Typography variant="subtitle1" color="textSecondary" component="span">
              <FormattedMessage id="indicationDate" />
              &nbsp;
              {indicationRequest && moment(indicationRequest?.createdTime).format(FE_DATE_TIME_FORMAT)}
            </Typography>
          </Typography>
          <Typography variant="body2" marginTop={1}>
            <FormattedMessage id="reason" />
            :&nbsp;
            <Typography variant="body2" component="span">
              {dentalExam?.note}
            </Typography>
          </Typography>
        </Box>
        <Tabs
          value={tabValue}
          onChange={(e, newValue) => {
            setTabValue(newValue);
          }}
          variant="scrollable"
          indicatorColor="secondary"
          textColor="secondary"
          style={{ padding: '0px 8px' }}
        >
          {TAB_MENUS.map((tab: some, index: number) => {
            return (
              <Tab
                key={index}
                value={index}
                style={{ minWidth: 100 }}
                label={
                  <Typography variant="subtitle2">
                    <FormattedMessage id={tab.id} />
                  </Typography>
                }
              />
            );
          })}
        </Tabs>
      </Box>
      <Box padding={2} overflow="auto">
        <div style={{ display: tabValue === 0 ? 'unset' : 'none' }}>
          <TestServiceTab indicationRequestId={indicationRequestId} />
        </div>
        <div style={{ display: tabValue === 1 ? 'unset' : 'none' }}>
          <ParticipantTab id={parseInt(indicationRequestId)} />
        </div>
        <div style={{ display: tabValue === 2 ? 'unset' : 'none' }}>
          <TherapyMedicalSuppliesList indicationRequestId={indicationRequestId} />
        </div>
      </Box>
    </Paper>
  );
};

export default LabDoctorIndicationDentalDetail;
