import { Print } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import DialogCustom from 'modules/common/component/DialogCustom';
import { DentalExam } from 'modules/schema';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import PrintFormContent from './PrintFormContent';

interface Props {
  dentalExam: DentalExam;
  openDialog: boolean;
  onCloseDialog: () => void;
}

const PrintForm = ({ dentalExam, openDialog, onCloseDialog }: Props) => {
  const refPrintComponent = React.useRef(null);
  return (
    <DialogCustom
      open={openDialog}
      onClose={onCloseDialog}
      PaperProps={{
        style: { minWidth: '60vw' },
      }}
      title={'encounter.registration.button.printRegistration.full'}
      footer={
        <ReactToPrint
          documentTitle=""
          trigger={() => (
            <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
              <FormattedMessage id="sellingProduct.label.print.button" />
            </Button>
          )}
          content={() => refPrintComponent.current}
        />
      }
    >
      <Box ref={refPrintComponent}>
        <PrintFormContent dentalExam={dentalExam} />
      </Box>
    </DialogCustom>
  );
};

export default PrintForm;
