import { Box, Grid, InputLabel, Switch, TextField, Tooltip, Typography } from '@mui/material';
import React, { memo } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { PRIMARY } from 'colors';
import QuantityBox from 'modules/common/component/QuantityBox';
import { Delete } from '@mui/icons-material';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { Encounter, IndicationMedication, Medication, MedicationUsage } from 'modules/schema';
import { Dictionary } from 'lodash';
import FormControlAutoComplete from 'modules/common/SchemaForm/element/autocomplete/FormControlAutoComplete';
import { PAYMENT_PLAN } from 'modules/common/constants';

interface Props {
  indicationMedications?: MedicationUsage[];
  onUpdateIndicationMedication: (value) => void;
  onDeleteIndicationMedication: (value) => void;
  readOnly?: boolean;
  medicationMap: Dictionary<Medication & { categoryName: string }>;
  encounter?: Encounter;
}

type CustomType = IndicationMedication &
  MedicationUsage & {
    medication?: Medication & { group?: string };
  };

const TableList: React.FunctionComponent<Props> = ({
  indicationMedications,
  onUpdateIndicationMedication,
  onDeleteIndicationMedication,
  readOnly,
  medicationMap,
  encounter,
}) => {
  const { intl } = useGeneralHook();

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      {indicationMedications?.map((item: CustomType, index: number) => {
        const medication = medicationMap[item?.medicationId!];
        const billed = item.isPaid;
        const exempted = encounter?.paymentPlan === PAYMENT_PLAN.FREE.value;
        return (
          <Box key={item.id} display={'flex'} bgcolor={PRIMARY[50]} alignItems={'center'}>
            <Box display="flex" padding={2} flex={1} gap={3}>
              <Box flex={1}>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.registerNumber" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medication?.registrationNumber}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.groupDrug" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {Object.values(medicationMap)?.filter((e) => e.code === item.medication?.code)[0]?.categoryName}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.drugName" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medicationName}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.ingredients" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medication?.ingredient}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.unit" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medicationUnit}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.ingredients" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medicationIngredient}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="sale.dose" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    {item?.medication?.dose}
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.price" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    <FormattedNumber value={item?.medication?.basePrice || 0} />
                  </Typography>
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage id="drugUsage.patientPrice" />
                  :&nbsp;
                  <Typography variant="subtitle1" component="span">
                    <FormattedNumber
                      value={
                        !item.isFree && item?.quantity
                          ? (item?.quantity - (item?.cancellationQuantity || 0)) * (item?.medication?.basePrice || 0)
                          : 0
                      }
                    />
                  </Typography>
                </Typography>
              </Box>
              <Box>
                {billed || exempted ? (
                  <Grid container spacing={2} width={820}>
                    <Grid item xs={3}>
                      <InputLabel>
                        <FormattedMessage id="drugUsage.intendedRoute" />
                      </InputLabel>
                      {medication?.intendedRoute?.find((route) => route?.value === item?.intendedRoute)?.label}
                    </Grid>
                    <Grid item xs={3}>
                      <InputLabel>
                        <FormattedMessage id="drugUsage.quantity" />
                      </InputLabel>
                      {item.quantity}
                    </Grid>
                    <Grid item xs={3}>
                      <InputLabel>
                        <FormattedMessage id="drugUsage.cancelledQuantity" />
                      </InputLabel>
                      {item.cancellationQuantity}
                    </Grid>{' '}
                    <Grid item xs={23}>
                      <InputLabel>
                        <FormattedMessage id="drugUsage.reasonCancel" />
                      </InputLabel>
                      {item.cancellationReason}
                    </Grid>
                  </Grid>
                ) : (
                  <>
                    <Grid container spacing={2} width={820}>
                      <Grid item xs={3} textAlign="center">
                        <FormControlAutoComplete
                          key={medication?.intendedRoute?.find((ele) => ele.value === item.intendedRoute)?.value}
                          label={intl.formatMessage({ id: 'drugUsage.intendedRoute' })}
                          placeholder={intl.formatMessage({ id: 'drugUsage.intendedRouteEnter' })}
                          disableClearable
                          options={medication?.intendedRoute}
                          value={medication?.intendedRoute?.find((ele) => ele.value === item.intendedRoute)}
                        />
                      </Grid>
                      <Grid item xs={3} textAlign="center">
                        <InputLabel required>
                          <FormattedMessage id="drugUsage.quantity" />
                        </InputLabel>
                        <QuantityBox
                          decimal
                          value={item?.quantity}
                          onChange={(quantity) => {
                            if (quantity !== item?.quantity)
                              onUpdateIndicationMedication({ ...item, quantity: quantity });
                          }}
                        />
                      </Grid>
                      <Grid item xs={3} textAlign="center">
                        <InputLabel>
                          <FormattedMessage id="drugUsage.cancelledQuantity" />
                        </InputLabel>
                        <QuantityBox
                          decimal
                          value={item?.cancellationQuantity}
                          onChange={(cancellationQuantity) => {
                            if (cancellationQuantity !== item?.cancellationQuantity)
                              onUpdateIndicationMedication({ ...item, cancellationQuantity: cancellationQuantity });
                          }}
                        />
                      </Grid>
                      <Grid item xs={6} textAlign="left">
                        <TextField
                          disabled={item?.cancellationQuantity === 0 || !item.cancellationQuantity}
                          fullWidth
                          label={intl.formatMessage({ id: 'cancelReason' })}
                          placeholder={intl.formatMessage({ id: 'cancelReasonEnter' })}
                          defaultValue={item?.cancellationReason}
                          onBlur={(e) => {
                            if (e.target.value !== item.cancellationReason) {
                              onUpdateIndicationMedication({ ...item, cancellationReason: e.target.value });
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} textAlign="left">
                        <TextField
                          fullWidth
                          label={intl.formatMessage({ id: 'drugUsage.note' })}
                          placeholder={intl.formatMessage({ id: 'drugUsage.noteEnter' })}
                          defaultValue={item?.note}
                          onBlur={(e) => {
                            if (e.target.value !== item.note) {
                              onUpdateIndicationMedication({ ...item, note: e.target.value });
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Box>
            {item?.isDisable || !readOnly || (
              <Box display="flex" flexDirection="column" gap={1}>
                <Box>
                  <IconButtonTitle
                    size="small"
                    title="delete"
                    disabled={billed}
                    onClick={() => {
                      onDeleteIndicationMedication(item);
                    }}
                  >
                    <Delete />
                  </IconButtonTitle>
                </Box>
                <Tooltip title={<FormattedMessage id="freeSwitch" />} arrow>
                  <Switch
                    size="small"
                    color="primary"
                    checked={!item?.isFree}
                    disabled={billed || encounter?.paymentPlan === PAYMENT_PLAN.FREE.value}
                    onChange={(e, checked) => {
                      onUpdateIndicationMedication({ ...item, isFree: !checked });
                    }}
                  />
                </Tooltip>
              </Box>
            )}
          </Box>
        );
      })}
    </Box>
  );
};

export default memo(TableList);
