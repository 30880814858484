import * as React from 'react';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import { Box, Paper, Typography } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import { formatDateFilterField } from 'modules/common/utils';
import { axiosThunk } from 'modules/common/redux/axios';
import { useCallback } from 'react';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { FormattedMessage } from 'react-intl';
import TherapyList from 'modules/admin/component/therapy/AdminTherapyList';
import FilterTherapyBox from 'modules/admin/component/therapy/FilterTherapyBox';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import DialogCustom from 'modules/common/component/DialogCustom';
import SchemaForm from 'modules/common/SchemaForm';
import { AxiosError } from 'axios';
import { useFetch } from 'modules/common/hook';

interface Props {}

const AdminTherapyListPage: React.FunctionComponent<Props> = () => {
  const orderTherapy = useSelector((appState: AppState) => appState.common.orderTherapy);
  const hookPagination = usePaginationHook({
    defaultFilter: {
      orderBy: orderTherapy,
    },
  });
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const { params } = hookPagination;
  const [open, setOpen] = React.useState(false);
  const [openReason, setOpenReason] = React.useState(false);
  const [rowValue, setRowValue] = React.useState<some>();

  const { data, revalidate, isValidating } = useFetch(
    API_SERVER.therapy.getList(formatDateFilterField(params, 'fromDate', 'toDate')),
    { globalLoading: true },
  );

  const onDelete = useCallback(async (value: some) => {
    setRowValue(value);
    setOpen(true);
  }, []);

  const onShowReason = useCallback(async (value: some) => {
    setRowValue(value);
    setOpenReason(true);
  }, []);

  // handle submit reason cancel therapy
  const onSubmit = React.useCallback(
    async (value: some) => {
      await dispatch(
        axiosThunk({
          url: API_SERVER.therapy.cancel(rowValue?.id),
          method: 'put',
          data: {
            data: value.reason || undefined,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: 'encounterInfo.cancelSuccess' }) });
          revalidate();
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
        });
      setOpen(false);
      revalidate();
    },
    [dispatch, intl, openSnackbar, revalidate, rowValue?.id],
  );

  return (
    <>
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterTherapyBox
          hookPagination={hookPagination}
          pagin={
            <>
              <Typography variant="h6">
                <FormattedMessage id="navMenu.therapyList" />
              </Typography>
              <Typography variant="caption">
                <FormattedMessage
                  id="foundResult"
                  values={{
                    num: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.content?.length || 0}
                      </Typography>
                    ),
                    total: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.pagination?.totalElements || 0}
                      </Typography>
                    ),
                  }}
                />
              </Typography>
            </>
          }
          onDataLoading={isValidating}
        />
        <TherapyList data={data} onDelete={onDelete} hookPagination={hookPagination} onShowReason={onShowReason} />
      </Paper>
      <DialogCustom
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { minWidth: 480 },
        }}
        title={'encounterInfo.cancelTherapy'}
      >
        <Box padding={2}>
          <SchemaForm
            onSubmit={onSubmit}
            hideSubmitButton
            initialData={{
              status: 'complete',
            }}
            schema={{
              fields: ({ formProps: { intl }, valuesField }) => {
                return {
                  reason: {
                    type: 'text-field',
                    label: intl.formatMessage({ id: 'encounterDetail.inCompleteReason' }),
                    multiline: true,
                    rows: 3,
                    noHelperText: true,
                    register: {
                      required: true,
                    },
                  },
                  cancel: {
                    type: 'button',
                    label: intl.formatMessage({ id: 'cancel' }),
                    variant: 'outlined',
                    style: { width: 100 },
                    propsWrapper: { xs: undefined },
                    onClick: () => {
                      setOpen(false);
                    },
                  },
                  save: {
                    type: 'submitButton',
                    label: intl.formatMessage({ id: 'save' }),
                    style: { width: 100 },
                    propsWrapper: { xs: undefined },
                  },
                };
              },
              ui: () => {
                return [
                  {
                    id: 'default',
                    fields: ['reason'],
                  },
                  {
                    id: 'footer',
                    propsGridContainer: { justifyContent: 'flex-end' },
                    fields: ['cancel', 'save'],
                  },
                ];
              },
            }}
          />
        </Box>
      </DialogCustom>
      <DialogCustom
        open={openReason}
        onClose={() => setOpenReason(false)}
        PaperProps={{
          style: { minWidth: 480 },
        }}
        title={'cancelReason'}
      >
        <Box padding={2}>{rowValue?.note}</Box>
      </DialogCustom>
    </>
  );
};

export default AdminTherapyListPage;
