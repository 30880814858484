import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import SchemaForm from 'modules/common/SchemaForm';
import IndexListTable from './IndexListTable';
import { axiosThunk } from 'modules/common/redux/axios';
import { isEmpty } from 'lodash';
import { useFetch } from 'modules/common/hook';

interface Props {
  onSubmitSuccess?: () => void;
  indicationRequestId: string;
}

const TestServiceTab: React.FunctionComponent<Props> = ({ onSubmitSuccess, indicationRequestId }) => {
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();

  // get list indication laboratories
  const { data: indicationLaboratories, revalidate } = useFetch(
    API_SERVER.indicationRequest.getIndicationLaboratories({ indicationRequestId: parseInt(indicationRequestId) }),
  );

  // handle finish, save infomation form
  const onSubmit = React.useCallback(
    async (value: some) => {
      if (indicationLaboratories?.indicationRequest?.status !== 'FINISHED') {
        const confirm =
          value.status === 'final'
            ? true
            : await confirmDialog.promptConfirmation({
                title: intl.formatMessage({ id: 'confirmCompleteTitle' }),
                content: intl.formatMessage(
                  {
                    id: 'confirmComplete',
                  },
                  { name: value?.indicationName },
                ),
              });
        if (!confirm) {
          confirmDialog.close();
          return;
        }
      }
      try {
        dispatch(setLoading(true));
        await dispatch(
          axiosThunk({
            url:
              indicationLaboratories?.indicationRequest?.status === 'FINISHED'
                ? API_SERVER.indicationRequest.updateIndicationLaboratories()
                : API_SERVER.indicationRequest.finishIndicationLaboratories(),
            method: 'put',
            data: {
              data: value.items?.map((i: some) => {
                return {
                  evaluate: i.evaluate,
                  id: i.id,
                  indicationRequestId: i.indicationRequestId,
                  isChoose: i.isChoose,
                  result: i.listSelect
                    ? i.laboratoryReferenceDetailObject.laboratorySelectionList.find(
                        (itemResult: some) => itemResult.id === i.listSelect,
                      ).name
                    : i.result,
                };
              }),
            },
          }),
        );
        dispatch(setLoading(false));
        revalidate();
        onSubmitSuccess && onSubmitSuccess();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
        confirmDialog.close();
      }
    },
    [confirmDialog, dispatch, indicationLaboratories, intl, onSubmitSuccess, openSnackbar, revalidate],
  );

  if (!indicationLaboratories) {
    return <LoadingIcon />;
  }

  return (
    <>
      <Box display="flex">
        <Box minWidth={350}>
          <Typography variant="subtitle1" color="textSecondary">
            <FormattedMessage id="serviceName" />
          </Typography>
          <Typography variant="body1">{indicationLaboratories?.indicationRequest?.serviceName}</Typography>
        </Box>
        <Box>
          <Typography variant="subtitle1" color="textSecondary">
            <FormattedMessage id="note" />
          </Typography>
          <Typography variant="subtitle1">{indicationLaboratories?.indicationRequest?.note}</Typography>
        </Box>
      </Box>
      <SchemaForm
        formData={{ items: indicationLaboratories.indicationLaboratoryList }}
        formProps={{ style: { marginTop: 16 } }}
        onSubmit={onSubmit}
        hideSubmitButton
        schema={{
          fields: ({ formProps: { intl }, valuesField, methods }) => {
            return {
              items: {
                unregister: true,
                type: () => <IndexListTable />,
              },
              submit: {
                hidden: isEmpty(indicationLaboratories.indicationLaboratoryList),
                type: 'submitButton',
                label: intl.formatMessage({
                  id: indicationLaboratories.indicationRequest?.status === 'FINISHED' ? 'save' : 'status.finished',
                }),
                propsWrapper: { justifyContent: 'flex-end', display: 'flex' },
              },
            };
          },
        }}
      />
    </>
  );
};

export default TestServiceTab;
