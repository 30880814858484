import { BE_DATE_FORMAT, FE_TIME_FORMAT, some } from 'modules/common/constants';
import { UseFormReturn } from 'react-hook-form';
import * as React from 'react';
import { API_SERVER } from 'modules/common/api';
import SchemaForm from 'modules/common/SchemaForm';
import DialogCustom from 'modules/common/component/DialogCustom';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { procedureFormSchema } from './schema';
import moment from 'moment/moment';
import { useFetch } from 'modules/common/hook';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
  readOnly?: boolean;
  indication?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose, readOnly } = props;
  const { data: medAdmData, isValidating } = useFetch(API_SERVER.indicationProcedures.get(formData?.id), {
    revalidateOnFocus: false,
    dedupingInterval: 500,
    enabled: formData?.id,
  });

  const formDataTmo = React.useMemo(() => {
    return {
      ...medAdmData,
      indicationRequestId: formData?.id,
      date: medAdmData?.startTime ? moment(medAdmData?.startTime).format(BE_DATE_FORMAT) : null,
      startTime: medAdmData?.startTime ? moment(medAdmData?.startTime).format(FE_TIME_FORMAT) : null,
      endTime: medAdmData?.endTime ? moment(medAdmData?.endTime).format(FE_TIME_FORMAT) : null,
    };
  }, [formData, medAdmData]);

  return (
    <>
      <SchemaForm
        schema={procedureFormSchema}
        loading={isValidating && !medAdmData}
        onSubmit={onSubmit}
        readOnly={readOnly}
        formData={formDataTmo}
        onCancel={onClose}
        formId="exam-price"
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
      />
    </>
  );
};

const ProcedureDetailDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustom
      open={!!open}
      maxWidth="lg"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { width: 900 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'procedure.updateForm' : 'procedure.createForm'} />
          &nbsp;-&nbsp; {formData?.serviceName}
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default ProcedureDetailDialog;
