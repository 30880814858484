import { Delete } from '@mui/icons-material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { alpha, Box, ButtonBase, Grid, IconButton, TextField } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { memo, useState } from 'react';
import { some } from '../../../../common/constants';
import ImagePreview from '../../../../common/component/ImagePreview';
import ImageZoomIn from '../../../../common/component/ImageZoomIn';
import { useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';

const useStyles = makeStyles(() => ({
  image: {
    '&:hover': {
      '& .action-area': {
        opacity: 1,
      },
    },
    '& .action-area': {
      opacity: 0,
      transition: '0.3s all',
    },
  },
}));
interface Props {
  images: some[];
  onDeleteTherapyImage: (therapyImageId: number, index: number) => void;
  editable: boolean;
  revalidate: () => void;
}

const ImageBox = ({ images, onDeleteTherapyImage, editable, revalidate }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState<undefined | number>(undefined);
  const updateDescriptionImage = useUpdateMutate({ onSuccess: revalidate });
  return (
    <>
      <Box>
        <Grid container spacing={2} justifyContent={'flex-start'}>
          {images?.map((item: some, index: number) => {
            return (
              <Grid item key={item.mediaId || item?.id} xs={2}>
                <Box position="relative" className={classes.image} sx={{ height: 130, width: '100%' }}>
                  <ButtonBase
                    style={{ background: grey[300], height: '100%', width: '100%' }}
                    onClick={() => setOpen(index)}
                  >
                    <ImagePreview
                      alt=""
                      imageId={item.id}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </ButtonBase>
                  <Box
                    className="action-area"
                    position="absolute"
                    bottom={0}
                    right={0}
                    left={0}
                    top={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box display="flex">
                      {editable && (
                        <IconButton
                          size="small"
                          style={{ background: alpha(grey[100], 0.8), marginRight: 16 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteTherapyImage(item?.id, index);
                          }}
                        >
                          <Delete color="inherit" fontSize="small" />
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        style={{ background: alpha(grey[100], 0.8) }}
                        onClick={() => setOpen(index)}
                      >
                        <ZoomInIcon color="inherit" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
                <TextField
                  id="standard-basic"
                  variant="standard"
                  fullWidth
                  InputProps={{
                    readOnly: !editable,
                  }}
                  style={{ textAlign: 'center', textAlignLast: 'center' }}
                  defaultValue={item?.description}
                  onBlur={(value) => {
                    editable &&
                      item?.description !== value.target.value &&
                      updateDescriptionImage({
                        url: API_SERVER.storageResource.updateDescription(),
                        method: 'put',
                        data: { data: [{ id: item.id, description: value.target.value }] },
                      });
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <ImageZoomIn
        open={open !== undefined}
        onClose={() => setOpen(undefined)}
        images={images.map((item: some) => ({
          imageId: item.id,
        }))}
        index={open}
      />
    </>
  );
};

export default memo(ImageBox);
