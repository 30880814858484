import { Add, Check, Delete } from '@mui/icons-material';
import { Box, Button, Paper } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import TableCustom from 'modules/common/component/TableCustom';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import { fetchThunk } from 'modules/common/redux/thunk';
import FormReport from './FormReport';
import { useFetch } from 'modules/common/hook';

interface IFormListProps {
  onSelect: (formId: string) => void;
}

const FormList: React.FunctionComponent<IFormListProps> = ({ onSelect }) => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const [formData, setFormData] = React.useState<some>({ name: '', content: '' });
  const { data, revalidate } = useFetch(API_SERVER.evaluationForms.getAll());

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(false));
          await dispatch(fetchThunk(API_SERVER.evaluationForms.delete(value.id), 'delete'));
          revalidate();
          value?.id === formData.id && setFormData({ name: '', content: '' });
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [close, dispatch, formData.id, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const onSubmit = React.useCallback(
    async (value: some) => {
      if (value.id) {
        try {
          dispatch(setLoading(false));
          await dispatch(
            fetchThunk(API_SERVER.evaluationForms.update(value.id), 'put', {
              name: value.name,
              examDepartment: value.examDepartment,
              conclusion: value.conclusion,
            }),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      } else {
        try {
          dispatch(setLoading(false));
          const createRes = await dispatch(
            fetchThunk(API_SERVER.evaluationForms.create(), 'post', {
              name: value.name,
              examDepartment: value.examDepartment,
              conclusion: value.conclusion,
            }),
          );
          revalidate();
          setFormData({ ...value, id: createRes.id });
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <>
      <Paper style={{ padding: 16, flex: 1, height: '100%', overflow: 'auto', marginRight: 16 }}>
        <TableCustom
          dataSource={data}
          caption
          columns={[
            {
              title: 'report.name',
              dataIndex: 'name',
            },
            {
              title: 'action',
              align: 'right',
              minWidth: 120,
              render: (record: some) => {
                return (
                  <Box display={'flex'} alignItems="center" gap={1} justifyContent="flex-end">
                    {formData?.id === record?.id && <Check color="success" />}
                    <IconButtonTitle
                      size="small"
                      title="delete"
                      onClick={(e) => {
                        e.stopPropagation();
                        onDelete(record);
                      }}
                    >
                      <Delete />
                    </IconButtonTitle>
                  </Box>
                );
              },
            },
          ]}
          rowProps={(record, index) => ({
            onClick: async () => {
              setFormData(record);
            },
            style: { cursor: 'pointer' },
          })}
        />
        <Box position="sticky" zIndex={2} bgcolor={'background.paper'} bottom={0} top={'unset'}>
          <Button
            variant="text"
            color="primary"
            fullWidth
            startIcon={<Add />}
            style={{ marginTop: 8 }}
            onClick={() => setFormData({ name: '', content: '' })}
          >
            <FormattedMessage id="add" />
          </Button>
        </Box>
      </Paper>
      <FormReport formData={formData} onSubmit={onSubmit} listTemplate={data} />
    </>
  );
};

export default FormList;
