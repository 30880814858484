import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../common/api';
import { some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { setLoading } from '../../common/redux/commonReducer';
import { fetchThunk } from '../../common/redux/thunk';
import EquipmentListTable from '../component/equipment/EquipmentListTable';
import EquipmentFormDialog from '../component/equipment/RoomFormDialog';
import { useFetch } from 'modules/common/hook';
export interface SearchParamsFilterEquipment {
  name: string;
}
interface IEquipmentAdminPageProps {}

const EquipmentAdminPage: React.FunctionComponent<IEquipmentAdminPageProps> = (props) => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const [searchParams, setSearchParams] = React.useState<SearchParamsFilterEquipment>({ name: '' });
  const [formData, setFormData] = React.useState<some | undefined>();

  const { data, isValidating, revalidate } = useFetch(API_SERVER.medicalEquipment.search({ name: searchParams.name }));

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog?.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'equipment.deleteTitle' }),
        content: intl.formatMessage({ id: 'equipment.deleteContent' }, { name: value.name }),
      });
      if (confirm) {
        try {
          await dispatch(fetchThunk(API_SERVER.medicalEquipment.delete(value.id), 'delete'));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog?.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar],
  );

  const onSubmitForm = React.useCallback(
    async (value: some) => {
      if (!value.id) {
        // create new
        dispatch(setLoading(true));
        try {
          await dispatch(
            fetchThunk(
              API_SERVER.medicalEquipment.create(value.gid),
              'post',
              JSON.stringify({
                code: value.code,
                name: value.name,
              }),
            ),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      } else {
        // edit
        dispatch(setLoading(true));
        try {
          await dispatch(
            fetchThunk(
              API_SERVER.medicalEquipment.update(value.id),
              'put',
              JSON.stringify({
                code: value.code,
                name: value.name,
              }),
            ),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <Paper variant="outlined" style={{ flex: 1, overflow: 'hidden' }}>
      <EquipmentListTable
        data={data}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        setFormData={setFormData}
        onDelete={onDelete}
        loading={isValidating && !data}
      />
      <EquipmentFormDialog
        open={!!formData}
        formData={formData}
        onSubmit={onSubmitForm}
        onClose={() => {
          setFormData(undefined);
        }}
      />
    </Paper>
  );
};

export default EquipmentAdminPage;
