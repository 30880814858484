import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { shallowEqual, useSelector } from 'react-redux';
import ReactToPrint from 'react-to-print';
import { AppState } from '../../../../../redux/reducer';
import DialogCustom from 'modules/common/component/DialogCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE } from 'modules/common/constants';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { CarePlan, Encounter } from 'modules/schema';

const Dot = withStyles({
  root: {
    border: 'none',
    borderBottom: '1px dotted black',
    flex: 1,
    margin: 0,
  },
})(Divider);

const useStyles = makeStyles({
  print: {
    position: 'relative',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
    },
  },
  noBorderTable: {
    '& table,th,td': {
      border: 'none',
      paddingLeft: 0,
    },
  },
});

interface TestReceiptContentProps {
  data?: CarePlan;
  patientData: Encounter;
}
interface Props {
  open: boolean;
  onClose?: () => void;
  data?: CarePlan;
  patientData?: Encounter;
}

const FormPrintContent = (props: TestReceiptContentProps) => {
  const { data, patientData } = props;
  const { currentLocation } = useSelector((state: AppState) => state.authen, shallowEqual);
  const classes = useStyles();

  const headerInfo = React.useMemo(() => {
    return (
      <TableRow>
        <TableCell colSpan={6}>
          <Box display="flex">
            <Box>
              <Box display="flex">
                <Typography variant="body2" style={{ width: 80 }}>
                  <FormattedMessage id={'encounterPrintForm.healthDepartment'} />
                </Typography>
                <Typography variant="body2"></Typography>
              </Box>
              <Box display="flex">
                <Typography variant="body2" style={{ width: 80 }}>
                  <FormattedMessage id={'encounterPrintForm.bv'} />
                </Typography>
                <Typography variant="body2">:&nbsp; {currentLocation?.name}</Typography>
              </Box>
            </Box>
            <Box flex={1} textAlign="center">
              <Typography variant="body2">
                <FormattedMessage id="encounterPrintForm.title" values={{ br: <br /> }} />
              </Typography>
            </Box>
            <Box>
              <Box display="flex">
                <Typography variant="body2" style={{ width: 80 }}>
                  <FormattedMessage id={'encounterPrintForm.ms'} />
                </Typography>
                <Typography variant="body2">: 02/BV-01</Typography>
              </Box>
              <Box display="flex">
                <Typography variant="body2" style={{ width: 80 }}>
                  <FormattedMessage id={'encounterPrintForm.numberStorage'} />
                </Typography>
                <Typography variant="body2">:&nbsp;{currentLocation?.name}</Typography>
              </Box>
            </Box>
          </Box>
        </TableCell>
      </TableRow>
    );
  }, [currentLocation?.name]);

  const testInfo = React.useMemo(() => {
    return (
      <>
        <TableRow>
          <TableCell colSpan={2}>
            <Typography variant="subtitle2">
              <FormattedMessage id={'encounterPrintForm.dear'} />
              <Typography variant="body2" component="span">
                :&nbsp; {data?.hospitalName}
              </Typography>
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={6}>
            <FormattedMessage id={'encounterPrintForm.dearHospital'} />:
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id={'patientName'} />
          </TableCell>
          <TableCell>:&nbsp;{patientData?.patient?.name}</TableCell>
          <TableCell>
            <FormattedMessage id={'age'} />
          </TableCell>
          <TableCell>:&nbsp;{patientData && moment().diff(patientData?.patient?.dob, 'years', false)}</TableCell>
          <TableCell>
            <FormattedMessage id={'encounterPrintForm.gender'} />
          </TableCell>
          <TableCell>
            :&nbsp;{patientData?.patient?.gender && <FormattedMessage id={patientData?.patient?.gender} />}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id={'encounterPrintForm.nation'} />
          </TableCell>
          <TableCell>
            <Box display="flex" alignItems="baseline">
              :&nbsp;
              <Dot />
            </Box>
          </TableCell>
          <TableCell>
            <FormattedMessage id={'encounterPrintForm.foreigner'} />
          </TableCell>
          <TableCell>
            <Box display="flex" alignItems="baseline">
              :&nbsp;
              <Dot />
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id={'encounterPrintForm.job'} />
          </TableCell>
          <TableCell>
            <Box display="flex" alignItems="baseline">
              :&nbsp;
              <Dot />
            </Box>
          </TableCell>
          <TableCell>
            <FormattedMessage id={'encounterPrintForm.jobAddress'} />
          </TableCell>
          <TableCell>
            <Box display="flex" alignItems="baseline">
              :&nbsp;
              <Dot />
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body2">
              <FormattedMessage id={'address'} />
            </Typography>
          </TableCell>
          <TableCell colSpan={5}>
            :&nbsp;
            {patientData?.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body2">
              <FormattedMessage id={'encounterPrintForm.addressTreatment'} />
            </Typography>
          </TableCell>
          <TableCell colSpan={5}>
            :&nbsp;
            {currentLocation?.name}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <Typography variant="body2">
              <FormattedMessage id={'encounterPrintForm.from'} />
            </Typography>
          </TableCell>
          <TableCell>
            :&nbsp;
            {patientData?.startTime ? moment(patientData?.startTime, BE_DATE_FORMAT).format(FE_DATE_FORMAT) : ''}
          </TableCell>
          <TableCell>
            <Typography variant="body2">
              <FormattedMessage id={'encounterPrintForm.to'} />
            </Typography>
          </TableCell>
          <TableCell>
            :&nbsp;
            {moment().format(FE_DATE_FORMAT)}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={6}>
            <Divider style={{ marginTop: 24, marginBottom: 24 }} />
          </TableCell>
        </TableRow>
      </>
    );
  }, [currentLocation?.name, data?.hospitalName, patientData]);

  const content = React.useMemo(() => {
    return (
      <>
        <TableRow>
          <TableCell colSpan={6}>
            <Typography variant="h6">
              <FormattedMessage id="encounterPrintForm.summary" />
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={6}>
            <Typography variant="subtitle1" style={{ marginTop: 16 }}>
              <FormattedMessage id="encounterPrintForm.clinical" />
            </Typography>
            {patientData.clinicalExamination && extractHTMLContent(patientData.clinicalExamination)}
            <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 80 }}>
              <FormattedMessage id="encounterPrintForm.test" />
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: 16 }}>
              <FormattedMessage id="encounterPrintForm.diagnosis" />
            </Typography>
            {patientData.differentialDiagnosis && extractHTMLContent(patientData.differentialDiagnosis)}
            <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 80 }}>
              <FormattedMessage id="encounterPrintForm.medicine" />
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 80 }}>
              <FormattedMessage id="encounterPrintForm.patientStatus" />
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: 16, marginBottom: 80 }}>
              <FormattedMessage id="encounterPrintForm.reason" />
            </Typography>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={6}>
            <Divider style={{ marginTop: 24, marginBottom: 24 }} />
          </TableCell>
        </TableRow>
      </>
    );
  }, [patientData.clinicalExamination, patientData.differentialDiagnosis]);

  const footer = React.useMemo(() => {
    return (
      <>
        <TableRow>
          <TableCell>
            <FormattedMessage id="encounterPrintForm.reason" />
          </TableCell>
          <TableCell>
            <Box display="flex" alignItems="baseline">
              :&nbsp;
              <Dot />
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id="encounterPrintForm.vehicle" />
          </TableCell>
          <TableCell>
            <Box display="flex" alignItems="baseline">
              :&nbsp;
              <Dot />
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>
            <FormattedMessage id="encounterPrintForm.transporter" />
          </TableCell>
          <TableCell>
            <Box display="flex" alignItems="baseline">
              :&nbsp;
              <Dot />
            </Box>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={3}>
            <Box textAlign="center">
              <Typography variant="body1">&nbsp;</Typography>
              <Typography variant="subtitle1">
                <FormattedMessage id="printForm.doctor" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic">
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
            </Box>
          </TableCell>
          <TableCell colSpan={3}>
            <Box textAlign="center">
              <Typography variant="body1">
                <FormattedMessage
                  id="printForm.date"
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="subtitle1">
                <FormattedMessage id={'encounterPrintForm.director'} />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic">
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
            </Box>
          </TableCell>
        </TableRow>
      </>
    );
  }, []);

  if (!currentLocation) {
    return null;
  }
  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }}>
        <Table size="small" className={classes.noBorderTable}>
          <TableBody>
            {headerInfo}
            <TableRow>
              <TableCell colSpan={6}>
                <Typography
                  textAlign={'center'}
                  variant="h4"
                  style={{ margin: '16px auto', textTransform: 'uppercase' }}
                >
                  <FormattedMessage id={'encounterPrintForm.transferForm'} />
                </Typography>
              </TableCell>
            </TableRow>
            {testInfo}
            {content}
            {footer}
          </TableBody>
        </Table>
      </Box>
    </>
  );
};

const PrintFormDialogExamCarePlan = (props: Props) => {
  const { onClose, open, data, patientData } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        style: { width: 1126 },
      }}
      title={'encounterPrintForm.transferForm'}
      footer={
        <ReactToPrint
          trigger={() => (
            <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
              <FormattedMessage id="printForm.printTransferForm" />
            </Button>
          )}
          content={() => refPrintComponent.current}
        />
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <FormPrintContent data={data} patientData={patientData || {}} />
      </Box>
    </DialogCustom>
  );
};

export default PrintFormDialogExamCarePlan;
