import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from 'modules/common/api';
import { SERVICE_CATEGORY_LIST, SYSTEM_CATEGORY_SCOPE, SYSTEM_CATEGORY_TYPE, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import ExamFormDialog from './ExamFormDialog';
import ExamPriceList from './ExamPriceList';
import GroupExamFormDialog from './GroupExamFormDialog';
import { axiosThunk } from 'modules/common/redux/axios';
import { useFetch } from 'modules/common/hook';
import usePriceManage from 'modules/common/hook/usePriceManage';

interface Props {
  category: some;
}

const HealthcareServiceCategoryContent: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const { category } = props;
  const { id: categoryId, type } = category;
  const isInsurance = type === SERVICE_CATEGORY_LIST.insured;
  const [formData, setFormData] = React.useState<some | undefined>();
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();
  const [searchParams, setSearchParams] = React.useState<some>({});

  const {
    data = [],
    isValidating,
    revalidate,
  } = useFetch(
    API_SERVER.service.getSystemCategoryList({
      ...searchParams,
      parentId: categoryId,
      scope: SYSTEM_CATEGORY_SCOPE.healthCheck,
    }),
  );

  const { onDeleteServices } = usePriceManage({ revalidate, setFormData });

  const onSubmitForm = React.useCallback(
    async (value: some) => {
      const mappedData = {
        code: value.code,
        internationalCode: value.internationalCode,
        name: value.name,
        price: value.basePrice,
        specializedDepartmentId: value.department,
        systemCategoryId: value?.systemCategoryId,
        physicsRoomId: value.room?.value,
      };
      if (!value.id) {
        // create new
        dispatch(setLoading(true));
        try {
          await dispatch(axiosThunk({ url: API_SERVER.service.create(), method: 'post', data: mappedData }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'exam.addNewSucceeds' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      } else {
        // edit
        dispatch(setLoading(true));
        try {
          await dispatch(axiosThunk({ url: API_SERVER.service.update(value.id), method: 'put', data: mappedData }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );
  const onSubmitFormGroup = React.useCallback(
    async (value: some) => {
      const data = {
        ...value,
        type: SYSTEM_CATEGORY_TYPE.group,
        scope: SYSTEM_CATEGORY_SCOPE.healthCheck,
        parentId: categoryId,
      };
      try {
        dispatch(setLoading(true));

        if (value.id) {
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.update(value.id), method: 'put', data }));
        } else {
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.create(), method: 'post', data }));
        }
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e: any) {
        const errors = e?.response?.data?.errors;
        if (errors) {
          errors?.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
        setFormDataGroup(undefined);
      }
    },
    [categoryId, dispatch, intl, openSnackbar, revalidate],
  );

  const onDeleteFormGroup = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));

          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.delete(value.id), method: 'delete' }));

          revalidate();

          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar],
  );

  const onDeleteForm = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));

          await dispatch(axiosThunk({ url: API_SERVER.service.delete(value.id), method: 'delete' }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar],
  );

  return (
    <Paper style={{ flex: 1, overflow: 'auto' }}>
      <ExamPriceList
        loading={isValidating && !data}
        setFormData={setFormData}
        setFormDataGroup={setFormDataGroup}
        onDelete={onDeleteForm}
        onDeleteGroup={onDeleteFormGroup}
        onDeleteServices={onDeleteServices}
        data={data}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        isInsurance={isInsurance}
        categoryId={categoryId}
        revalidate={revalidate}
      />
      <ExamFormDialog
        open={!!formData}
        formData={formData}
        onSubmit={onSubmitForm}
        onClose={() => {
          setFormData(undefined);
        }}
        isInsurance={isInsurance}
      />
      <GroupExamFormDialog
        open={!!formDataGroup}
        formData={formDataGroup}
        onSubmit={onSubmitFormGroup}
        onClose={() => {
          setFormDataGroup(undefined);
        }}
      />
    </Paper>
  );
};

export default HealthcareServiceCategoryContent;
