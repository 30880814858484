import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { useForm, UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../../common/api';
import RouteLeavingGuard from '../../../common/component/RouteLeavingGuard';
import { INDICATION_REQUEST_STATUS } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { setLoading } from '../../../common/redux/commonReducer';
import SchemaForm from '../../../common/SchemaForm';
import { ReportClinicalFormImageDoctor } from '../../../examDoctor/component/detail/imageResult/utils';
import { Encounter, IndicationRequest } from 'modules/schema';
import { useFetch, useUpdateMutate } from 'modules/common/hook';
import PrintFormDialog from './PrintFormDialog';
import schemaClinical from './schema';
import { mutate } from 'swr';
import { AppState } from 'redux/reducer';
import { useSelector } from 'react-redux';

interface Props {
  encounter?: Encounter;
  indicationRequest: IndicationRequest;
  editable?: boolean;
}

const SubclinicalImageDoctor: React.FunctionComponent<Props> = ({ indicationRequest, encounter, editable }) => {
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const evaluationFormMap = useSelector((state: AppState) => state.common.evaluationForms);
  const refSchemaForm = React.useRef<UseFormReturn<any>>();
  const [open, setOpen] = React.useState(false);
  const formMethods = useForm<ReportClinicalFormImageDoctor>({
    reValidateMode: 'onChange',
    mode: 'onChange',
  });
  const { data: indicationImage, revalidate: indicationImageRevalidate } = useFetch(
    API_SERVER.indicationImage.get(indicationRequest?.id!),
    { enabled: indicationRequest?.id },
  );
  const { reset } = formMethods;
  React.useEffect(() => {
    formMethods.reset({ ...indicationImage });
  }, [formMethods, indicationImage, indicationRequest?.id]);

  const onCompleteMutate = useUpdateMutate({
    onSuccess: () => mutate(API_SERVER.indicationRequest.get(indicationRequest?.id!).url),
  });
  const onComplete = React.useCallback(
    async (value) => {
      try {
        if (indicationRequest.status !== INDICATION_REQUEST_STATUS.FINISHED.value) {
          const confirm = await confirmDialog.promptConfirmation({
            title: intl.formatMessage({ id: 'confirmCompleteTitle' }),
            content: intl.formatMessage(
              {
                id: 'confirmComplete',
              },
              { name: value?.indicationName },
            ),
          });

          if (confirm) {
            const dataUpdate = {
              ...value,
              examDepartment: value?.examDepartment || '',
              conclusion: value?.conclusion || '',
              indicationRequestId: indicationRequest?.id,
            };
            await onCompleteMutate({
              url: API_SERVER.indicationImage.finishIndication(),
              method: 'put',
              data: dataUpdate,
            });
          }
        }
      } catch (e) {
        reset(value);
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.fail' }), type: 'error' });
      } finally {
        confirmDialog.close();
        dispatch(setLoading(false));
      }
    },
    [
      indicationRequest.status,
      indicationRequest?.id,
      confirmDialog,
      intl,
      onCompleteMutate,
      reset,
      openSnackbar,
      dispatch,
    ],
  );

  const onSaveMuate = useUpdateMutate({
    onSuccess: () => {
      indicationImageRevalidate();
      mutate(API_SERVER.indicationRequest.get(indicationRequest?.id!).url);
    },
  });
  const onSave = React.useCallback(
    async (value) => {
      const dataUpdate = {
        ...value,
        examDepartment: value?.examDepartment || '',
        conclusion: value?.conclusion || '',
        indicationRequestId: indicationRequest?.id,
      };

      await onSaveMuate({
        url: API_SERVER.indicationImage.update(),
        method: 'put',
        data: dataUpdate,
      });
    },
    [indicationRequest?.id, onSaveMuate],
  );

  const enabledPrint = React.useMemo(() => {
    return (
      indicationRequest?.status === INDICATION_REQUEST_STATUS.FINISHED.value &&
      indicationImage?.examDepartment !== null &&
      indicationImage?.conclusion !== null
    );
  }, [indicationImage, indicationRequest?.status]);

  const evaluationFormOptions = React.useMemo(
    () => Object.values(evaluationFormMap)?.map((e) => ({ ...e, value: e?.id, label: e?.name })),
    [evaluationFormMap],
  );

  return (
    <Box>
      <RouteLeavingGuard
        when={Object.values(formMethods?.formState.dirtyFields)?.length > 0 ? formMethods?.formState.isDirty : false}
        message={intl.formatMessage({ id: 'subclinical.youHaveUnsavedData' }, { name: indicationRequest?.serviceName })}
      />
      <Box marginBottom={2}>
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="serviceName" />
            </Typography>
            <Typography variant="body2">{indicationRequest?.serviceName}</Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="doctorOfIndication" />
            </Typography>
            <Typography variant="body2">{indicationRequest?.picName}</Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="note" />
            </Typography>
            <Typography variant="body2">{indicationRequest?.note}</Typography>
          </Grid>
        </Grid>
      </Box>
      <SchemaForm
        ref={refSchemaForm}
        schema={schemaClinical}
        evaluationFormMap={evaluationFormOptions}
        formId="schemaClinical"
        methods={formMethods}
        editable={editable}
        enabledPrint={enabledPrint}
        patientData={encounter}
        indicationImage={indicationImage}
        onSubmit={onComplete}
        onPrint={() => {
          setOpen(true);
        }}
        onSave={onSave}
      />
      <PrintFormDialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        indicationRequest={indicationRequest}
        encounter={encounter}
        indicationImage={indicationImage || {}}
      />
    </Box>
  );
};

export default SubclinicalImageDoctor;
