import { Control, FieldValues, useFieldArray } from 'react-hook-form';
import { ALL_ROLE_INFO, ROLE_INFO } from '../../../../../../../layout/utils';
import * as React from 'react';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { FormattedMessage } from 'react-intl';
import { API_SERVER, IDENTITY_API_SERVER } from 'modules/common/api';
import { some, THERAPY_PROCEDURES_ATTEND_PERSON_OPTIONS } from 'modules/common/constants';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import { Box, Typography } from '@mui/material';
import SchemaForm from 'modules/common/SchemaForm';
import TableCustom from 'modules/common/component/TableCustom';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import Delete from '@mui/icons-material/Delete';
import { axiosThunk } from 'modules/common/redux/axios';
import { useMemo } from 'react';
import { keyBy, sortBy } from 'lodash';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useFetch } from 'modules/common/hook';

interface Props {
  name: string;
  control: Control<FieldValues>;
  loading: boolean;
  readOnly?: boolean;
  isCreate?: boolean;
  indicationRequestId: number;
}

const PARTICIPANTS = [ROLE_INFO.examDoctor, ROLE_INFO.nurse];
const BASIC_PARTICIPANTS = [ROLE_INFO.examDoctor, ALL_ROLE_INFO.general];

const ParticipantBox: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, confirmDialog, intl, isBasic } = useGeneralHook();
  const { control, name, loading, indicationRequestId, readOnly } = props;
  const methodsArray = useFieldArray({
    control: control,
    name: name,
    keyName: 'keyName',
  });
  const { fields } = methodsArray;
  const roles = isBasic ? BASIC_PARTICIPANTS : PARTICIPANTS;
  const { data: users } = useFetch(
    IDENTITY_API_SERVER.user.getListUserByRoles({
      roles: roles.map((ele) => ele.roleKey).join(','),
      status: 'ACTIVE',
    }),
  );

  const participantOptions = useMemo(() => {
    if (users && users.length > 0) {
      const roleMap = keyBy(roles, 'roleKey');
      return sortBy(
        users?.map((ele) => {
          return {
            id: ele?.id,
            name: ele?.employeeFullName,
            roleName: ele?.roleName,
            group: intl.formatMessage({ id: roleMap[ele?.roleName!]?.label }),
          };
        }),
        'group',
      );
    }
    return [];
  }, [intl, roles, users]);

  const { data: participants, revalidate: participantsRevalidate } = useFetch(
    API_SERVER.indicationAttendPerson.get(indicationRequestId),
    { enabled: indicationRequestId },
  );

  const participantsMapped = useMemo(() => {
    return participants?.map((person) => ({
      ...person,
      roleLabel: intl.formatMessage({ id: roles.find((ele) => ele?.roleKey === person?.roleName)?.label }),
    }));
  }, [intl, participants, roles]);

  const addParticipant = React.useCallback(
    async (value: some) => {
      try {
        dispatch(setLoading(true));
        await dispatch(
          axiosThunk({
            url: API_SERVER.indicationAttendPerson.create(),
            method: 'post',
            data: {
              indicationRequestId: indicationRequestId,
              roleName: value?.employee?.roleName,
              userId: value?.employee?.id,
              employeeName: value?.employee?.name,
              mission: value?.mission,
            },
          }),
        );
        await participantsRevalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, indicationRequestId, intl, openSnackbar, participantsRevalidate],
  );

  const deletePerformer = React.useCallback(
    async (value) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.indicationAttendPerson.delete(value?.id), method: 'delete' }));
          await participantsRevalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, participantsRevalidate],
  );

  if (!participants) {
    return <LoadingIcon />;
  }

  return (
    <>
      <Typography variant="h6">
        <FormattedMessage id="participant" />
      </Typography>
      <Box paddingY={2}>
        {!readOnly && (
          <SchemaForm
            formId="choose-box"
            hideSubmitButton
            submitLabel={<FormattedMessage id="status.finished" />}
            arrayFields={fields}
            schema={{
              fields: ({ formProps: { intl, arrayFields, appState }, valuesField, methods: { setValue } }) => {
                const nameOptions = valuesField?.role
                  ? participantOptions?.filter((ele) => ele?.roleName === valuesField?.role?.roleKey)
                  : participantOptions;

                return {
                  role: {
                    type: 'auto-complete',
                    label: intl.formatMessage({ id: 'role' }),
                    placeholder: intl.formatMessage({ id: 'roleEnter' }),
                    readOnly: readOnly,
                    options: roles,
                    getOptionLabel: (role) => {
                      return intl.formatMessage({ id: role.label });
                    },
                    propsWrapper: { xs: 4 },
                  },
                  employee: {
                    type: 'auto-complete',
                    label: intl.formatMessage({ id: 'employeeName' }),
                    placeholder: intl.formatMessage({ id: 'employeeEnter' }),
                    readOnly: readOnly,
                    propsWrapper: { xs: 4 },
                    options: nameOptions,
                    groupBy: (option) => option.group,
                    getOptionLabel: (opt) => {
                      return opt.name;
                    },
                    onChange: () => {
                      setValue('procedureRole', null);
                    },
                  },
                  procedureRole: {
                    type: 'auto-complete',
                    label: intl.formatMessage({ id: 'mission' }),
                    placeholder: intl.formatMessage({ id: 'missionEnter' }),
                    propsWrapper: { xs: 4 },
                    options: THERAPY_PROCEDURES_ATTEND_PERSON_OPTIONS.filter((item) => {
                      if (valuesField.employee) {
                        const listPer = participantsMapped?.filter(
                          (v) =>
                            v.employeeName === valuesField.employee.name &&
                            v.roleName === valuesField.employee.roleName,
                        );
                        return listPer?.findIndex((v) => v.mission === item.value) === -1;
                      }

                      return true;
                    }),
                    getOptionLabel: (record) => {
                      const label = THERAPY_PROCEDURES_ATTEND_PERSON_OPTIONS.find(
                        (v) => v.value === record?.value,
                      )?.label;
                      return intl.formatMessage({ id: `${label}` });
                    },
                    disabled: !valuesField.employee,
                    onChange: (v) => {
                      if (!!v) {
                        //prepend({ ...valuesField.employee, procedureRole: v });
                        addParticipant({ ...valuesField, mission: v?.value });
                      }
                    },
                  },
                };
              },
            }}
          />
        )}
        <Box overflow="auto" display="flex">
          <TableCustom
            keyName="keyName"
            dataSource={participantsMapped}
            loading={loading}
            columns={[
              {
                title: 'role',
                dataIndex: 'roleLabel',
                width: '30%',
              },
              {
                title: 'employeeName',
                dataIndex: 'employeeName',
              },
              {
                title: 'mission',
                dataIndex: 'mission',
                render: (record) => {
                  const label = THERAPY_PROCEDURES_ATTEND_PERSON_OPTIONS.find(
                    (v) => v.value === record?.mission,
                  )?.label;
                  return <FormattedMessage id={label}></FormattedMessage>;
                },
              },
              {
                title: 'action',
                align: 'right',
                hidden: readOnly,
                render: (record, index) => (
                  <>
                    <IconButtonTitle title="delete" size="small" onClick={() => deletePerformer(record)}>
                      <Delete />
                    </IconButtonTitle>
                  </>
                ),
              },
            ]}
          />
        </Box>
      </Box>
    </>
  );
};

export default ParticipantBox;
