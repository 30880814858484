import React, { useEffect } from 'react';
import { BE_DATE_TIME_FORMAT, FE_TIME_DATE_FORMAT } from 'modules/common/constants';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { CancelReceiptIcon } from 'modules/../svg';
import InfoIcon from '@mui/icons-material/Info';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { DISCOUNT_TYPE, RECEIPT_TYPE, SELLING_STATUS } from './constant';
import moment from 'moment/moment';
import { Typography } from '@mui/material';
import { ROUTES } from 'modules/layout/router';
import { RECEIPT_ID } from 'modules/admin/constants';
import { NumericFormatText } from 'modules/common/utils';
import { PAYMENT_METHOD } from 'modules/common/apiConstants';
import { Page } from 'modules/schema/common';
import { SaleReceipt } from 'modules/schema';

interface Props {
  data?: Page<SaleReceipt>;
  onViewDetail: (value) => void;
  onCancel: (value) => void;
  hookPagination: HookPaginationProps;
  loading?: boolean;
}

const ReceiptListTable: React.FunctionComponent<Props> = (props) => {
  const { data, hookPagination, onViewDetail, onCancel, loading } = props;
  const { onPageChange, onRowsPerPageChange, pagination } = hookPagination;

  const columns = React.useMemo(() => {
    return [
      {
        title: 'sellingProduct.title.receiptCode',
        render: (record) => {
          return (
            <Typography
              color="primary"
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() => {
                onViewDetail({ ...record });
              }}
            >
              <Typography sx={{ fontWeight: '500' }}>{record.code}</Typography>
            </Typography>
          );
        },
      },
      {
        title: 'sellingProduct.title.customerName',
        dataIndex: 'customerName',
      },
      {
        title: 'sellingProduct.title.phoneNumber',
        dataIndex: 'phoneNumber',
      },
      {
        title: 'sellingProduct.title.receiptType',
        render: (record) => <FormattedMessage id={RECEIPT_TYPE.find((v) => v.value === record.receiptType)?.label} />,
      },
      {
        title: 'sellingProduct.title.createdDate',
        render: (record) => {
          return record.createdTime && moment(record.createdTime, BE_DATE_TIME_FORMAT).format(FE_TIME_DATE_FORMAT);
        },
      },
      {
        title: 'sellingProduct.title.discount',
        render: (record) => {
          if (record.discountType === 'PERCENT') {
            return `${record.discount || 0}${DISCOUNT_TYPE.find((v) => v.value === record.discountType)?.name}`;
          } else {
            return <FormattedNumber value={record.discount || 0} />;
          }
        },
      },
      {
        title: 'sellingProduct.title.totalAmount',
        align: 'right',
        render: (record) => NumericFormatText(record.amountPaid || 0),
      },
      {
        title: 'sellingProduct.title.status',
        render: (record) => {
          const status = SELLING_STATUS.find((v) => v.value === record.receiptStatus)?.label;
          return (
            <Typography
              sx={{
                color: status === 'paid' ? 'success.main' : 'error.main',
                fontWeight: '500',
              }}
            >
              <FormattedMessage id={`sellingProduct.receipt.status.${status}`} />
            </Typography>
          );
        },
      },
      {
        title: 'sellingProduct.title.paymentMethod',
        render: (record) => <FormattedMessage id={PAYMENT_METHOD[record.paymentMethod!]?.label} />,
      },
      {
        title: 'action',
        align: 'right',
        render: (record) => {
          const status = SELLING_STATUS.find((v) => v.value === record.receiptStatus)?.label;
          return (
            <>
              <IconButtonTitle
                title="sellingProduct.label.duplicate"
                size="small"
                onClick={() => {
                  localStorage.setItem(RECEIPT_ID, record.id + '');
                  window.open(ROUTES.createOrder, '_blank');
                }}
              >
                <ContentCopyIcon />
              </IconButtonTitle>
              {status === 'paid' && (
                <IconButtonTitle
                  title="sellingProduct.label.cancel"
                  size="small"
                  onClick={() => {
                    onCancel(record);
                  }}
                >
                  <CancelReceiptIcon />
                </IconButtonTitle>
              )}

              {status === 'cancelled' && (
                <IconButtonTitle
                  title="sellingProduct.label.viewDetail"
                  size="small"
                  onClick={() => {
                    onViewDetail(record);
                  }}
                >
                  <InfoIcon />
                </IconButtonTitle>
              )}
            </>
          );
        },
      },
    ] as Columns<SaleReceipt>[];
  }, [onCancel, onViewDetail]);

  useEffect(() => {
    localStorage.removeItem(RECEIPT_ID);
  }, []);

  return (
    <TableCustom
      dataSource={data?.content}
      loading={loading}
      columns={columns}
      hideColumnIndex
      paginationProps={{
        count: data?.pagination?.totalElements || 0,
        page: pagination.page,
        rowsPerPage: pagination.pageSize,
        onPageChange: onPageChange,
        onRowsPerPageChange: onRowsPerPageChange,
      }}
    />
  );
};

export default ReceiptListTable;
