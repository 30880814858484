import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../common/api';
import usePaginationHook from '../../common/hook/usePaginationHook';
import EncounterList from '../encounterList/EncounterList';
import FilterEncounterBox from '../encounterList/FilterEncounterBox';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import { formatDateFilterField } from 'modules/common/utils';
import { useFetch } from 'modules/common/hook/useFetch';
import PageHeader from 'modules/common/component/PageHeader';

const NurseEncounterListPage: React.FunctionComponent = () => {
  const hookPagination = usePaginationHook({
    defaultFilter: {
      status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
    },
  });
  const { params } = hookPagination;
  const { data: encounterPage, isValidating } = useFetch(
    API_SERVER.encounter.search(formatDateFilterField(params, 'fromDate', 'toDate')),
  );

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterEncounterBox
        hookPagination={hookPagination}
        pagin={
          <PageHeader
            titleKey="encounterList"
            pagination={{
              size: encounterPage?.content.length || 0,
              totalElements: encounterPage?.pagination.totalElements || 0,
            }}
          />
        }
      />
      <EncounterList
        encounters={encounterPage?.content}
        total={encounterPage?.pagination?.totalElements || 0}
        hookPagination={hookPagination}
        loading={isValidating}
      />
    </Paper>
  );
};

export default NurseEncounterListPage;
