import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { ROLE_TYPE } from 'modules/common/apiConstants';

export const UserEmployeeRoleSchema = z
  .object({
    id: z.number(),
    userName: z.string(),
    employeeId: z.number(),
    employeeFullName: z.string(),
    roleName: createObjectKeysEnumSchema(ROLE_TYPE),
    resourceId: z.string(),
    practiceCertificate: z.string(),
    email: z.string(),
  })
  .partial();

export type UserEmployeeRole = z.infer<typeof UserEmployeeRoleSchema>;
