import { API_SERVER } from 'modules/common/api';
import { CODE_SYSTEM, SYSTEM_CATEGORY_SCOPE } from 'modules/common/constants';
import { useFetch } from 'modules/common/hook';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';

export const useFetchDentalInfoBoard = (dentalSessionId: number, patientId: number) => {
  return useFetch(API_SERVER.dental.getDentalDiagnosis({ dentalExamId: dentalSessionId, patientId }), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });
};

export const useFetchSystemCategories = () => {
  return useFetch(
    API_SERVER.service.getAllSystemCategoryList({
      scope: [
        SYSTEM_CATEGORY_SCOPE.surgical,
        SYSTEM_CATEGORY_SCOPE.laboratory,
        SYSTEM_CATEGORY_SCOPE.other,
        SYSTEM_CATEGORY_SCOPE.radiology,
      ],
    }),
  );
};

export const useFetchIndicationRequest = (dentalSessionId: number) => {
  return useFetch(API_SERVER.indicationRequest.getIndicationRequestByReferenceId(dentalSessionId, 'DENTAL_EXAM'));
};

export const useFetchServices = () => {
  return useFetch(
    API_SERVER.service.getAllSystemCategoryList({
      scope: [
        SYSTEM_CATEGORY_SCOPE.surgical,
        SYSTEM_CATEGORY_SCOPE.laboratory,
        SYSTEM_CATEGORY_SCOPE.other,
        SYSTEM_CATEGORY_SCOPE.radiology,
      ],
    }),
  );
};

export const useFetchDiagnosisCodeSystem = () => {
  return useFetch(API_SERVER.code_system.getList({ type: CODE_SYSTEM.dentalDiagnosis }));
};

export const useFetchDentalExam = (dentalSessionId?: number) => {
  return useFetch(API_SERVER.dentalExam.getDentalExam(dentalSessionId), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    enabled: dentalSessionId,
  });
};

export const useFetchDentalDiagnosisImage = (diagnosisImageId?: number) => {
  return useFetch(API_SERVER.diagnosticImage.getDiagnosticImage(diagnosisImageId || 0), {
    enabled: diagnosisImageId,
  });
};

export const useFetchStorageResource = (
  referenceId?: number,
  type?: typeof STORAGE_RESOURCE_TYPE[keyof typeof STORAGE_RESOURCE_TYPE],
) => {
  return useFetch(API_SERVER.storageResource.getList({ referenceId, type }), {
    enabled: referenceId && type,
  });
};
