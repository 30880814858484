import { ArrowDropDownRounded, Lock, Logout, Notifications } from '@mui/icons-material';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Card,
  CardActionArea,
  CardContent,
  ClickAwayListener,
  Divider,
  Fade,
  Grid,
  IconButton,
  Paper,
  Popper,
  Typography,
} from '@mui/material';
import Badge, { BadgeProps } from '@mui/material/Badge';
import { styled } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { replace } from 'connected-react-router';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { AppState } from '../../redux/reducer';
import { logoutThunk } from 'modules/authen/redux/authenReducer';
import { NOTIFICATION_API_SERVER } from 'modules/common/api';
import { PopoverState, RawLink } from 'modules/common/component/elements';
import { ROUTES } from './router';
import NotificationItem from 'modules/notification/NotificationItem';
import CloseIcon from '@mui/icons-material/Close';
import { axiosThunk } from 'modules/common/redux/axios';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import Snackbar from '@mui/material/Snackbar';
import { AppointmentCalendar, AssessmentFinished, DoctorExchange, DoctorExchangeGreen, WarningAmber } from '../../svg';
import { ROLES, ROUTE_TYPE, some } from 'modules/common/constants';
import { onMessageListener } from 'modules/notification/firebase';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import DefaultAside from './DefaultAside';
import Stack from '@mui/material/Stack';
import InfiniteScroll from 'react-infinite-scroll-component';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useFetch } from 'modules/common/hook';
import { isEmpty } from 'lodash';
import { setLoading } from 'modules/common/redux/commonReducer';
import { AxiosError } from 'axios';
import { MessageFull } from 'modules/schema/MessageFull';

const useStyles = makeStyles(() => ({
  button: {
    width: '100%',
    padding: '8px 24px',
    justifyContent: 'flex=start',
    textAlign: 'start',
  },
  collapse: { position: 'absolute', width: 220, color: 'black', zIndex: 110, top: 52, right: 40 },
  paper: { overflow: 'hidden', width: 240, padding: 0 },
}));

interface IUserAppBarBoxProps extends ReturnType<typeof mapStateToProps> {
  listRouter: any;
  openAside: boolean;
  setOpenAside: any;
}

export interface NotificationProps {
  items: any;
  hasMore: boolean;
  page: number;
}

const Header: React.FunctionComponent<IUserAppBarBoxProps> = ({ user, listRouter, openAside, setOpenAside }) => {
  const classes = useStyles();
  const { dispatch, appState, openSnackbar } = useGeneralHook();

  const [openNotificationSnackbar, setOpenNotificationSnackbar] = React.useState(false);
  const [notiData, setNotiData] = React.useState<some | undefined>();

  const [scrollDirection, setScrollDirection] = React.useState<'down' | 'up' | null>();
  const [open, setOpen] = React.useState<boolean>(false);
  const [popupNotification, setPopupNotification] = React.useState<NotificationProps>({
    items: [],
    hasMore: true,
    page: 0,
  });
  const notificationItems = popupNotification.items;
  const isAppointmentBooking = window.location.pathname;
  const roles = useMemo(() => appState.authen?.user?.role || [], [appState.authen?.user?.role]);

  useEffect(() => {
    let lastScrollY = window.pageYOffset;

    const updateScrollDirection = () => {
      const scrollY = window.pageYOffset;
      const direction = scrollY > lastScrollY ? 'down' : 'up';
      if (direction !== scrollDirection && (scrollY - lastScrollY > 30 || scrollY - lastScrollY < -20)) {
        setScrollDirection(direction);
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };
    window.addEventListener('scroll', updateScrollDirection); // add event listener
    return () => {
      window.removeEventListener('scroll', updateScrollDirection); // clean up
    };
  }, [scrollDirection]);

  const handleClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenNotificationSnackbar(false);
  };

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    '& .MuiBadge-badge': {
      right: 5,
      top: 2,
    },
  }));

  const { data, revalidate } = useFetch(
    NOTIFICATION_API_SERVER.notification.getAllList({
      page: 0,
      pageSize: 10,
    }),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    },
  );

  // role user
  const roleUser = useCallback(
    (role: keyof typeof ROLES) => {
      if (roles.indexOf(role) > -1) {
        return true;
      }
      return false;
    },
    [roles],
  );

  // display warning title
  const displayWarningTitle = () => {
    if (notiData?.contentType === 'UPCOMING_THERAPY_SESSION') {
      return <FormattedMessage id="therapy.sessionName" />;
    }
    if (
      notiData?.contentType === 'INDICATION_LAB_NEW' ||
      notiData?.contentType === 'INDICATION_IMAGE_NEW' ||
      notiData?.contentType === 'INDICATION_PROCEDURE_NEW' ||
      notiData?.contentType === 'INDICATION_LAB_FINISH' ||
      notiData?.contentType === 'INDICATION_IMAGE_FINISH' ||
      notiData?.contentType === 'INDICATION_PROCEDURE_FINISH'
    ) {
      return <FormattedMessage id="encounterDetail.indicationName" />;
    }
    if (notiData?.contentType === 'CHARGE_ITEM_NEW') {
      return notiData?.itemName;
    }
    if (notiData?.contentType === 'ASSIGN_NEW_ENCOUNTER' || notiData?.contentType === 'CHANGE_ASSIGN_ENCOUNTER') {
      if (notiData?.routeType === ROUTE_TYPE.THERAPY.value) {
        return <FormattedMessage id="therapy" />;
      } else if (
        notiData?.routeType === ROUTE_TYPE.DENTAL_EXAM.value ||
        notiData?.routeType === ROUTE_TYPE.ENCOUNTER.value
      ) {
        return <FormattedMessage id="printForm.encounter" />;
      }
    }
    if (notiData?.contentType === 'REQUEST_APPOINTMENT_NEW') {
      return `${notiData?.customerName} - ${notiData?.phoneNumber}`;
    }
    return <FormattedMessage id="sale.products" />;
  };

  // display warning name
  const displayWarningName = () => {
    if (notiData?.contentType === 'UPCOMING_THERAPY_SESSION') {
      return notiData?.therapySessionName;
    }
    if (
      notiData?.contentType === 'INDICATION_LAB_NEW' ||
      notiData?.contentType === 'INDICATION_IMAGE_NEW' ||
      notiData?.contentType === 'INDICATION_PROCEDURE_NEW' ||
      notiData?.contentType === 'INDICATION_LAB_FINISH' ||
      notiData?.contentType === 'INDICATION_IMAGE_FINISH' ||
      notiData?.contentType === 'INDICATION_PROCEDURE_FINISH'
    ) {
      return notiData?.serviceName;
    }
    if (notiData?.contentType === 'ASSIGN_NEW_ENCOUNTER' || notiData?.contentType === 'CHANGE_ASSIGN_ENCOUNTER') {
      return `${notiData?.itemCode} - ${notiData?.patientName}`;
    }
    return notiData?.medicationName;
  };

  useEffect(() => {
    onMessageListener()
      .then((payload: any) => {
        const jsonMessage: MessageFull = JSON.parse(payload?.data?.content);
        if (
          roles.indexOf('PHARMACIST') > -1 ||
          roles.indexOf('ADMIN') > -1 ||
          roleUser('RECEPTIONIST') ||
          roleUser('DOCTOR') ||
          roleUser('CLINICAL_PATHOLOGIST') ||
          roleUser('RADIOLOGIST') ||
          roleUser('CASHIER') ||
          roleUser('GENERAL_DOCTOR') ||
          roleUser('COORDINATOR')
        ) {
          setNotiData(JSON.parse(jsonMessage?.message));
          setOpenNotificationSnackbar(true);
          if (Array.isArray(notificationItems) && jsonMessage) {
            let newPopupNotification = { ...popupNotification };
            newPopupNotification.items.unshift(jsonMessage);
            setPopupNotification(newPopupNotification);
          }
        }
      })
      .catch((err) => console.log('failed: ', err));
  });

  // read all notification (isRead = false)
  const readAllNotification = async () => {
    if (notificationItems?.filter((d) => !d?.isRead)?.length !== 0) {
      try {
        dispatch(setLoading(true));
        await dispatch(axiosThunk({ url: NOTIFICATION_API_SERVER.notification.readAll, method: 'put' }));
        revalidate();
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response) {
            error.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          }
        }
      }
      dispatch(setLoading(false));
    }
  };

  // display notification icon
  const displayNotificationIcon = () => {
    if (notiData?.contentType === 'UPCOMING_THERAPY_SESSION') {
      return <PendingActionsIcon style={{ color: '#0052E0' }} />;
    }
    if (
      notiData?.contentType === 'INDICATION_LAB_FINISH' ||
      notiData?.contentType === 'INDICATION_IMAGE_FINISH' ||
      notiData?.contentType === 'INDICATION_PROCEDURE_FINISH'
    ) {
      return <AssessmentFinished style={{ width: '25px', height: '25px' }} />;
    }
    if (notiData?.contentType === 'ASSIGN_NEW_ENCOUNTER') {
      return <DoctorExchangeGreen />;
    }
    if (notiData?.contentType === 'CHANGE_ASSIGN_ENCOUNTER') {
      return <DoctorExchange />;
    }
    if (notiData?.contentType === 'REQUEST_APPOINTMENT_NEW') {
      return <AppointmentCalendar />;
    }
    return <WarningAmber style={{ width: '25px', height: '25px' }} />;
  };

  // fetch more data notification
  const fetchMoreData = async () => {
    if (open) {
      if (notificationItems.length >= 500) {
        setPopupNotification({
          items: data?.content || [],
          hasMore: false,
          page: 0,
        });
        return;
      }
      // a fake async api call like which sends
      const url = NOTIFICATION_API_SERVER.notification.getAllList({
        page: popupNotification.page + 1,
        pageSize: 10,
      });
      const json = await dispatch(axiosThunk(url));
      const res = json.data?.content;
      // 20 more records in .5 secs
      setTimeout(() => {
        setPopupNotification({
          items: notificationItems?.concat(res) || [],
          hasMore: true,
          page: popupNotification.page + 1,
        });
      }, 500);
    }
  };

  useEffect(() => {
    const defaultNotification = {
      items: data?.content || [],
      hasMore: true,
      page: 0,
    };
    if (!open) {
      setPopupNotification(defaultNotification);
    }
    setPopupNotification(defaultNotification);
  }, [open, data]);

  const isUnreadNotification =
    Array.isArray(notificationItems) && notificationItems.findIndex((i) => !i?.isRead) !== -1;
  const isVisible = !isUnreadNotification;

  return (
    <Box
      display={isAppointmentBooking.includes('/main/appointment-register') ? 'none' : 'flex'}
      alignItems="center"
      // marginBottom={2}
      boxSizing="border-box"
      sx={{
        background: 'rgb(0, 60, 166)',
        position: 'sticky',
        left: 0,
        zIndex: 100,
        top: scrollDirection === 'up' ? '0' : scrollDirection === 'down' ? '-100px' : '0px',
        transitionProperty: 'all',
        transitionDuration: '500ms',
        '& .ps': {
          overflow: 'unset !important',

          transitionProperty: 'all',
          transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
          transitionDuration: '500ms',
        },
      }}
    >
      <Box flex={1} maxWidth={'78%'}>
        <DefaultAside
          listRouter={listRouter}
          open={openAside}
          onClose={() => {
            setOpenAside(!openAside);
          }}
        />
      </Box>
      <Box display="flex" alignItems="center" justifyContent="flex-end" minWidth={'22%'}>
        {(roles.indexOf('PHARMACIST') > -1 ||
          roles.indexOf('ADMIN') > -1 ||
          roleUser('RECEPTIONIST') ||
          roleUser('DOCTOR') ||
          roleUser('CLINICAL_PATHOLOGIST') ||
          roleUser('LABORATORY_TECHNOLOGIST') ||
          roleUser('RADIOGRAPHER') ||
          roleUser('RADIOLOGIST') ||
          roleUser('NURSE') ||
          roleUser('CASHIER') ||
          roleUser('GENERAL_DOCTOR') ||
          roleUser('COORDINATOR')) && (
          <PopoverState>
            {({ anchorEl, handleClick, handleClose, open }) => (
              <>
                <IconButton
                  style={{
                    color: '#fff',
                  }}
                  onClick={(eventCustom: React.MouseEvent<HTMLButtonElement>) => {
                    handleClick(eventCustom);
                    setOpen(!open);
                  }}
                >
                  <StyledBadge color="error" variant={'dot'} invisible={isVisible}>
                    <Notifications />
                  </StyledBadge>
                </IconButton>
                <Popper open={open} anchorEl={anchorEl} transition style={{ zIndex: 1000, width: '400px' }}>
                  {({ TransitionProps }) => (
                    <ClickAwayListener
                      onClickAway={() => {
                        handleClose();
                        setOpen(!open);
                      }}
                    >
                      <Fade {...TransitionProps} timeout={350} style={{ zIndex: 1000, width: '420px' }}>
                        <Paper
                          className={classes.paper}
                          style={{
                            backgroundColor: '#E0ECFF',
                            border: '1px #bbbaba solid',
                          }}
                        >
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            sx={{ margin: '9px 9px 0px 9px' }}
                          >
                            <Typography
                              variant="subtitle1"
                              sx={{
                                textTransform: 'uppercase',
                                color: '#2d2d2d',
                              }}
                            >
                              <FormattedMessage id="notify" />
                            </Typography>
                            <Box display={'flex'}>
                              <DoneAllIcon sx={{ color: '#43A047', margin: '7px' }} />
                              <Typography
                                variant="subtitle1"
                                color={'#0052E0'}
                                sx={{ cursor: 'pointer' }}
                                component="span"
                                padding={'8px'}
                                onClick={readAllNotification}
                              >
                                <FormattedMessage id="readAll" />
                              </Typography>
                              <IconButton
                                onClick={() => {
                                  handleClose();
                                  setOpen(!open);
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          </Stack>
                          <Box
                            padding="5px"
                            sx={{
                              width: '100%',
                              position: 'relative',
                              '& ul': { padding: '0px' },
                            }}
                          >
                            <InfiniteScroll
                              dataLength={notificationItems.length}
                              next={fetchMoreData}
                              hasMore={popupNotification.hasMore}
                              loader=""
                              endMessage={
                                <p style={{ textAlign: 'center' }}>
                                  <b>
                                    <FormattedMessage id="notification.label.full" />
                                  </b>
                                </p>
                              }
                              height={'80vh'}
                            >
                              {!isEmpty(notificationItems) ? (
                                notificationItems?.map((v) => (
                                  <NotificationItem
                                    data={v}
                                    handleClose={handleClose}
                                    revalidate={revalidate}
                                    popupNotification={popupNotification}
                                    setPopupNotification={setPopupNotification}
                                  />
                                ))
                              ) : (
                                <Box display={'flex'} alignItems={'center'} justifyContent={'center'} p={2}>
                                  <FormattedMessage id="noData" />
                                </Box>
                              )}
                            </InfiniteScroll>
                          </Box>
                        </Paper>
                      </Fade>
                    </ClickAwayListener>
                  )}
                </Popper>
              </>
            )}
          </PopoverState>
        )}
        <PopoverState>
          {({ anchorEl, handleClick, handleClose, open }) => (
            <>
              <Button variant="text" onClick={handleClick} sx={{ paddingLeft: 0 }}>
                <Box
                  marginX={2}
                  sx={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    color: '#fff',
                    marginLeft: 0,
                  }}
                >
                  {user?.employee_name}
                </Box>
                <Avatar alt="User" style={{ height: 32, width: 32, textTransform: 'uppercase' }}>
                  {user?.employee_name?.[0]}
                </Avatar>
                <ArrowDropDownRounded
                  color="action"
                  style={{
                    transition: 'all 300ms',
                    transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                    cursor: 'pointer',
                    color: '#fff',
                  }}
                />
              </Button>
              <Popper open={open} anchorEl={anchorEl} transition style={{ zIndex: 100 }}>
                {({ TransitionProps }) => (
                  <ClickAwayListener onClickAway={handleClose}>
                    <Fade {...TransitionProps} timeout={350}>
                      <Paper className={classes.paper}>
                        <Box padding={2}>
                          <Typography variant="h6">{user?.employee_name}</Typography>
                          <Typography variant="subtitle2" style={{ marginTop: 8 }} color="primary">
                            {user?.role?.[0] && <FormattedMessage id={ROLES[user?.role?.[0]].label} />}
                          </Typography>
                          <Typography variant="body2" style={{ marginTop: 16 }}>
                            {user?.email}
                          </Typography>
                        </Box>
                        <Divider />
                        <RawLink to={ROUTES.changePassword}>
                          <ButtonBase className={classes.button}>
                            <Box flex={1}>
                              <Typography variant="body2">
                                <FormattedMessage id="changePassword" />
                              </Typography>
                            </Box>
                            <Lock color="action" />
                          </ButtonBase>
                        </RawLink>
                        <ButtonBase
                          className={classes.button}
                          onClick={() => {
                            dispatch(replace({ search: '' })); // Trung Ha: don't like this. For Trello 495
                            dispatch(logoutThunk());
                          }}
                        >
                          <Box flex={1}>
                            <Typography variant="body2">
                              <FormattedMessage id="logout" />
                            </Typography>
                          </Box>
                          <Logout color="action" />
                        </ButtonBase>
                      </Paper>
                    </Fade>
                  </ClickAwayListener>
                )}
              </Popper>
            </>
          )}
        </PopoverState>
      </Box>
      <Snackbar
        open={openNotificationSnackbar}
        autoHideDuration={6000}
        onClose={handleClose}
        message="Note archived"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Card variant="outlined" style={{ marginBottom: '5px', minWidth: '400px', boxShadow: '8px' }}>
          <CardActionArea>
            <CardContent style={{ padding: '8px', paddingTop: '2px', border: '1px orange solid' }}>
              <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
                <Typography variant="subtitle2" style={{ textTransform: 'uppercase' }} color="error.main">
                  <FormattedMessage id="notify" />
                </Typography>
                <IconButton onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
              </Box>
              <Grid container spacing={1}>
                <Grid item xs={1.2}>
                  {displayNotificationIcon()}
                </Grid>
                <Grid item xs={10.8}>
                  <Box>
                    <Box>
                      <Typography color={'primary.main'} variant="subtitle1" component={'span'}>
                        {notiData?.subject}
                      </Typography>
                    </Box>
                    <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
                      <Typography variant="body1">{displayWarningTitle()}</Typography>
                      <Typography variant="subtitle1">{displayWarningName()}</Typography>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </CardActionArea>
        </Card>
      </Snackbar>
    </Box>
  );
};

function mapStateToProps(state: AppState) {
  return {
    user: state.authen.user,
    locations: state.authen.locations,
    currentLocation: state.authen.currentLocation,
  };
}

export default connect(mapStateToProps)(Header);
