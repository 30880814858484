import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  children: (imageSize: number) => React.ReactNode;
}

export const RadioImageSize = ({ children }: Props) => {
  const [imageSize, setImageSize] = useState<number>(3);

  return (
    <Grid container justifyContent={'space-between'}>
      <Grid item>
        <Typography variant="h6" marginBottom={1}>
          <FormattedMessage id="image" />
        </Typography>
      </Grid>
      <Grid
        item
        sx={{
          '@media print': {
            display: 'none',
          },
        }}
      >
        <FormControl fullWidth>
          <Grid container>
            <Grid item>
              <FormLabel id="demo-radio-buttons-group-label">
                <FormattedMessage id="encounter.detail.label.imageSizePerRow" />
              </FormLabel>
            </Grid>
            <Grid item margin={'-8px 0 0 10px'}>
              <RadioGroup
                row
                aria-labelledby="demo-radio-buttons-group-label"
                name="radio-buttons-group"
                onChange={(e) => setImageSize(parseInt(e.target.value))}
                defaultValue={'3'}
              >
                <FormControlLabel value="3" control={<Radio />} label="4" />
                <FormControlLabel value="6" control={<Radio />} label="2" />
                <FormControlLabel value="12" control={<Radio />} label="1" />
              </RadioGroup>
            </Grid>
          </Grid>
        </FormControl>
      </Grid>
      {children(imageSize)}
    </Grid>
  );
};
