import { Box, Typography } from '@mui/material';
import { styled } from '@mui/styles';

export const TypographyCustom = styled(Typography)({
  padding: '5px 0',
  lineHeight: '24px',
});
export const BoxCustom = styled(Box)({
  display: 'flex',
  cursor: 'pointer',
  justifyContent: 'space-between',
});
