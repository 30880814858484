import styled from '@emotion/styled';
import { Delete } from '@mui/icons-material';
import { Box, InputLabel, Popper, Typography } from '@mui/material';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { FE_DATE_FORMAT, some } from 'modules/common/constants';
import { DECIMAL_NUMBER_REGEX, NUMBER_REGEX_NO_ZERO_HEADER } from 'modules/common/regex';
import SchemaView from 'modules/common/SchemaForm/SchemaView';
import { mergeFieldName } from 'modules/common/SchemaForm/utils';
import { DISCOUNT_TYPE } from './OrderForm';
import { getTotalPrice } from './ProductList';
import { NumericFormatText } from 'modules/common/utils';
import { useFetch } from 'modules/common/hook';

const StyledPopper = styled(Popper)({
  minWidth: '320px !important',
});

interface Props {
  name: string;
  index: number;
  item: some;
  remove: (index: number) => void;
  update: (index: number, obj: some) => void;
  formProps: any;
  valuesFieldAll: any;
}

const ProductCard = (props: Props) => {
  const { name, index, item, remove, update, formProps, valuesFieldAll } = props;
  const { intl } = formProps;

  const { data } = useFetch(API_SERVER.inventory.getLot(item.id), {
    onSuccess: (data) => {
      if (!item?.lot) {
        if (data?.medicationPackageList && data?.medicationPackageList?.length > 0) {
          for (let medicationPackage of data?.medicationPackageList || []) {
            if (medicationPackage.expiredStatus !== 'OUT_OF_DATE') {
              const lotDefault = medicationPackage;
              const basicUnit = lotDefault?.unitConverterList?.find((u) => u?.basicUnit === u?.fromUnit);
              const unitDefault = {
                value: basicUnit?.fromUnit,
                label: basicUnit?.fromUnit && intl.formatMessage({ id: basicUnit.fromUnit }),
                factor: basicUnit?.factor,
                unitPrice: basicUnit?.price,
              };
              update(index, {
                ...item,
                lot: lotDefault,
                unit: unitDefault,
                unitPrice: unitDefault?.unitPrice,
                discountType: 'PERCENT',
              });
              break;
            }
          }
        }
      } else if (typeof item.lot === 'string') {
        const lotDefault = data?.medicationPackageList?.find((mp) => mp?.lot === item?.lot);
        update(index, {
          ...item,
          lot: moment(lotDefault?.expiredDate) > moment() && lotDefault,
        });
      }
    },
  });

  return (
    <>
      <SchemaView
        fieldName={mergeFieldName({ name, index })}
        schema={{
          fields: ({ valuesField }) => {
            return {
              stt: {
                type: () => {
                  return (
                    <div style={{ height: 53 }}>
                      <InputLabel>
                        <FormattedMessage id="stt" />
                      </InputLabel>
                      <Typography mt={1.5} align="center">
                        {index + 1}
                      </Typography>
                    </div>
                  );
                },
                unregister: true,
                propsWrapper: {
                  xs: undefined,
                },
              },
              name: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="sale.product" />
                      </InputLabel>
                      <Typography mt={1.5}>{item.name}</Typography>
                    </div>
                  );
                },
                unregister: true,
                propsWrapper: {
                  xs: 1,
                  md: 1.5,
                  xl: 2,
                },
              },
              lot: {
                type: 'auto-complete',
                label: intl.formatMessage({ id: 'inventory.lot' }),
                placeholder: intl.formatMessage({ id: 'inventory.lotEnter' }),
                propsWrapper: { xs: 2 },
                register: {
                  required: true,
                },
                options: data?.medicationPackageList,
                getOptionDisabled: (option) => option?.expiredStatus && option.expiredStatus === 'OUT_OF_DATE',
                getOptionLabel: (option) => {
                  return option.lot ? `${option.lot} - ${moment(option?.expiredDate).format(FE_DATE_FORMAT)}` : '';
                },
                PopperComponent: StyledPopper,
                onChange: (option) => {
                  const basicUnit = option?.unitConverterList?.find((u) => u?.basicUnit === u?.fromUnit);
                  const unitDefault = {
                    value: basicUnit?.fromUnit,
                    label: basicUnit?.fromUnit && intl.formatMessage({ id: basicUnit.fromUnit }),
                    factor: basicUnit?.factor,
                    unitPrice: basicUnit?.price,
                  };

                  update(index, {
                    ...valuesField,
                    lot: option,
                    unit: unitDefault,
                    unitPrice: unitDefault.unitPrice,
                  });
                },
                renderOption: (props, option) => (
                  <li {...props} key={option.lot}>
                    <Box minWidth={320}>
                      <Box display={'flex'} alignItems="baseline">
                        <Typography variant="subtitle1">
                          <FormattedMessage id="inventory.lotShort" />
                          :&nbsp;
                        </Typography>
                        <Typography variant="body1">{option.lot}</Typography>
                        &nbsp;{' - '}&nbsp;
                        <Box component="span">
                          {option.expiredDate && moment(option.expiredDate).format(FE_DATE_FORMAT)}
                        </Box>
                      </Box>
                      <Box display={'flex'} alignItems="baseline">
                        <Typography variant="subtitle1">
                          <FormattedMessage id="inventory" />
                          :&nbsp;
                        </Typography>
                        <Typography variant="body2">
                          {option.unitQuantityList
                            .map((v) => {
                              return `${v.quantity} ${v.unit && intl.formatMessage({ id: v.unit })}`;
                            })
                            ?.join(', ')}
                        </Typography>
                      </Box>
                    </Box>
                  </li>
                ),
              },
              quantity: {
                type: 'text-field',
                inputType: 'number',
                label: intl.formatMessage({ id: 'quantity' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
                propsWrapper: { xs: 1 },
                tooltipError: true,
                register: {
                  required: true,
                  max: {
                    value: 1000000000,
                    message: intl.formatMessage({ id: 'validation.invalid' }),
                  },
                  pattern: {
                    value: NUMBER_REGEX_NO_ZERO_HEADER,
                    message: intl.formatMessage({ id: 'inventory.quantityValid' }),
                  },
                  validate: (value) => {
                    const sameLot = valuesFieldAll.productList?.filter(
                      (p) => p.id === valuesField.id && p.lot?.lot === valuesField.lot?.lot,
                    );
                    const totalQuantity = sameLot.reduce((v, c) => {
                      return v + Number(c.quantity) * (c.unit?.factor || 1);
                    }, 0);
                    return totalQuantity > (valuesField.lot?.quantityBasic || 0)
                      ? intl.formatMessage(
                          { id: sameLot?.length > 1 ? 'inventory.maxTotal' : 'inventory.max' },
                          {
                            num: Math.floor((valuesField.lot?.quantityBasic || 0) / (valuesField.unit?.factor || 1)),
                            unit: valuesField.unit?.label,
                          },
                        )
                      : true;
                  },
                },
                helperProps: { noWrap: false, whiteSpace: 'nowrap' },
              },
              unit: {
                type: 'auto-complete',
                label: intl.formatMessage({ id: 'inventory.unit' }),
                placeholder: intl.formatMessage({ id: 'select' }),
                propsWrapper: { xs: 1 },
                options: valuesField.lot?.unitConverterList?.map((item) => ({
                  value: item.fromUnit,
                  label: item.fromUnit && intl.formatMessage({ id: item.fromUnit }),
                  factor: item.factor,
                  unitPrice: item.price,
                })),
                register: {
                  required: true,
                },
                onChange: (data) => {
                  update(index, {
                    ...valuesField,
                    unit: data,
                    unitPrice: data?.unitPrice,
                  });
                },
              },
              unitPrice: {
                type: 'number-field',
                inputType: 'number',
                label: intl.formatMessage({ id: 'price' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
                propsWrapper: { xs: true },
                register: {
                  required: true,
                  maxLength: {
                    value: 16,
                    message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                  },
                  pattern: DECIMAL_NUMBER_REGEX,
                },
              },
              discount: {
                type: 'text-field',
                inputType: 'number',
                label: intl.formatMessage({ id: 'sale.discount' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
                propsWrapper: { xs: true },
                tooltipError: true,
                required: false,
                register: {
                  max: {
                    value:
                      valuesField?.discountType === 'PERCENT'
                        ? 100
                        : Number(valuesField.quantity || 0) * Number(valuesField.unitPrice || 0),
                    message: intl.formatMessage({ id: 'validation.invalid' }),
                  },
                },
              },
              discountType: {
                type: 'select',
                label: <>&nbsp;</>,
                defaultValue: 'PERCENT',
                options: DISCOUNT_TYPE,
                // rawOptions: true,
                disableClearBtn: true,
                propsWrapper: { xs: 1 },
              },
              vat: {
                type: 'text-field',
                inputType: 'number',
                label: intl.formatMessage({ id: 'vatPercent' }),
                placeholder: intl.formatMessage({ id: 'vatEnter' }),
                propsWrapper: { xs: 1 },
                required: false,
                register: {
                  max: { value: 100, message: '<=100' },
                },
              },
              totalPrice: {
                type: () => {
                  return (
                    <div style={{ height: 53 }}>
                      <InputLabel>
                        <FormattedMessage id="totalPrice" />
                      </InputLabel>
                      <Typography mt={1.5}>{NumericFormatText(getTotalPrice(valuesField))}</Typography>
                    </div>
                  );
                },
                unregister: true,
                propsWrapper: {
                  xs: undefined,
                },
              },
              delete: {
                unregister: true,
                type: () => {
                  return (
                    <IconButtonTitle title="delete" size="small" onClick={() => remove(index)}>
                      <Delete />
                    </IconButtonTitle>
                  );
                },
                propsWrapper: {
                  xs: undefined,
                  style: { alignSelf: 'flex-end', marginBottom: 12, justifyContent: 'center', display: 'flex' },
                },
              },
            };
          },
        }}
        formProps={formProps}
      />
    </>
  );
};

export default ProductCard;
