import makeStyles from '@mui/styles/makeStyles';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PAYMENT_PLAN, some } from '../../../../../common/constants';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import * as React from 'react';
import HeaderPrintForm from '../../../../../common/component/HeaderPrintForm';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import moment from 'moment';
import TableCustom from '../../../../../common/component/TableCustom';
import { numericFormat, NumericFormatText } from '../../../../../common/utils';
import DialogCustom from '../../../../../common/component/DialogCustom';
import ReactToPrint from 'react-to-print';
import Print from '@mui/icons-material/Print';
import { API_SERVER } from '../../../../../common/api';
import { PAYMENT_HISTORY_TYPE } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
      '& th,tr,td': {
        backgroundColor: 'unset',
      },
    },
  },
});

interface TestReceiptContentProps {
  therapyDetail?: some;
  paymentHistoryPrint?: some;
}
interface Props {
  open: boolean;
  onClose?: () => void;
  therapyDetail?: some;
  paymentHistoryPrint?: some;
}

const PaymentHistoryPrintContent: React.FunctionComponent<TestReceiptContentProps> = (props) => {
  const { therapyDetail, paymentHistoryPrint } = props;
  const { appState } = useGeneralHook();
  const { currentLocation } = appState.authen;
  const { data: paymentDetails } = useFetch(
    API_SERVER.paymentHistory.getDetail(paymentHistoryPrint?.parentId || paymentHistoryPrint?.id),
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase', marginBottom: '25px' }}>
            <FormattedMessage id="therapy.cashier.paymentHistory.printDialog.title.receipt" />
          </Typography>
        </HeaderPrintForm>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ columnCount: 2 }}>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="therapy.code" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{therapyDetail?.code}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="therapy.label.customer.code" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{therapyDetail?.patient?.code?.split('-')?.[1]}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {therapyDetail?.patient?.name}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="category" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                <FormattedMessage id={PAYMENT_PLAN?.[therapyDetail?.paymentPlan]?.label || ' '} />
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                {therapyDetail?.patient?.patientAddressList?.find((a) => a.type === 'HOME')?.address}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {moment(therapyDetail?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {therapyDetail?.patient?.gender && (
                  <FormattedMessage id={therapyDetail?.patient?.gender?.toLowerCase()} />
                )}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="telephone" />
              </Typography>
              :&nbsp; <Typography variant="body1">{therapyDetail?.patient?.mobilePhone}</Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [therapyDetail]);

  const testInfo = React.useMemo(() => {
    const listPayment = paymentDetails?.paymentDetails;
    const paymentHistory = paymentDetails?.paymentHistories?.find((p) => p.type === PAYMENT_HISTORY_TYPE.PAYMENT.value);
    const prepaymentHistory = paymentDetails?.paymentHistories?.find((p) => p.type === 'PREPAYMENT');

    return (
      <>
        <Box>
          {listPayment && listPayment.length > 0 && (
            <>
              <Typography variant="subtitle1" marginBottom={2} style={{ fontSize: '18px' }}>
                <FormattedMessage id="service" />
              </Typography>
              <TableCustom
                dataSource={listPayment}
                containerProps={{
                  sx: {
                    '& th,tr,td': {
                      padding: '2px 8px',
                      backgroundColor: 'white',
                      border: '0.5px solid',
                      color: 'unset',
                      fontSize: '19px',
                    },
                    '& th': {
                      padding: '8px 1px !important',
                      textAlign: 'center',
                    },
                    '& th span': {
                      fontSize: '14px',
                      letterSpacing: '1',
                    },
                    '& tr': {
                      pageBreakInside: 'avoid',
                    },
                    width: '100%',
                    overflow: 'visible',
                    '& .extendRow,.extendCell': {
                      border: 'none',
                    },
                  },
                }}
                columns={[
                  {
                    title: 'cashier.service',
                    width: 190,
                    dataIndex: 'content',
                  },
                  {
                    title: 'printForm.SL',
                    dataIndex: 'quantity',
                    align: 'center',
                    render: (record: some) => (record.quantity > 9 ? record.quantity : `0${record.quantity}`),
                  },

                  {
                    title: 'therapy.cashier.paymentHistory.printDialog.header.unit',
                    dataIndex: 'unit',
                    align: 'center',
                    lastCell: {
                      colSpan: 4,
                    },
                    render: (record) => (record?.unit ? record?.unit : <FormattedMessage id="cashier.time" />),
                  },

                  {
                    title: 'cashier.price',
                    dataIndex: 'unitPrice',
                    render: (record: some) => NumericFormatText(record.unitPrice),
                    align: 'right',
                    lastCell: {
                      style: { paddingTop: '10px' },
                      render: () => {
                        return (
                          <>
                            <Typography variant="subtitle2" color="textSecondary" textAlign={'end'} fontSize="19px">
                              <FormattedMessage id="total" />
                            </Typography>
                          </>
                        );
                      },
                    },
                  },

                  // {
                  //   title: 'cashier.preDiscountTotal',
                  //   render: (record: some) => NumericFormatText(record.unitPrice * record.quantity),
                  //   align: 'right',
                  //   lastCell: {
                  //     render: () => {
                  //       return (
                  //         <Typography variant="subtitle2" textAlign={'end'}>
                  //           {NumericFormatText(DataService?.reduce((a, b) => a + b.quantity * b.unitPrice, 0) || 0)}
                  //         </Typography>
                  //       );
                  //     },
                  //   },
                  // },
                  {
                    title: 'cashier.discountAmount',
                    dataIndex: 'discountAmount',
                    render: (record: some) => NumericFormatText(record.totalDiscountAmount),
                    align: 'right',
                    lastCell: {
                      style: { paddingTop: '10px' },
                      align: 'right',
                      colSpan: 1,
                      render: () => {
                        return (
                          <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                            {NumericFormatText(listPayment?.reduce((a, b) => a + (b.totalDiscountAmount || 0), 0) || 0)}
                          </Typography>
                        );
                      },
                    },
                  },
                  {
                    title: 'totalPrice',
                    align: 'right',
                    render: (record: some) => NumericFormatText(record.totalAmount - record.totalDiscountAmount),
                    lastCell: {
                      style: { paddingTop: '10px' },
                      align: 'right',
                      colSpan: 1,
                      render: () => {
                        return (
                          <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                            {NumericFormatText(
                              listPayment?.reduce(
                                (a, b) => a + ((b.totalAmount || 0) - (b.totalDiscountAmount || 0)),
                                0,
                              ) || 0,
                            )}
                          </Typography>
                        );
                      },
                    },
                  },
                  {
                    title: 'cashier.vatRate',
                    align: 'right',
                    lastCell: {
                      style: { paddingTop: '10px' },
                      colSpan: 1,
                    },
                    render: (record: some) =>
                      record.vat !== undefined ? (
                        <>
                          <FormattedNumber value={record.vat} maximumFractionDigits={0} />%
                        </>
                      ) : (
                        <FormattedMessage id="cashier.noVat" />
                      ),
                  },
                  {
                    title: 'cashier.vatValue',
                    align: 'right',
                    render: (record: some) =>
                      record.vatAmount !== undefined ? (
                        <FormattedNumber value={record.vatAmount} maximumFractionDigits={0} />
                      ) : (
                        <></>
                      ),
                    lastCell: {
                      style: { paddingTop: '10px' },
                      align: 'right',
                      colSpan: 1,
                      render: () => {
                        return (
                          <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                            <FormattedNumber value={listPayment?.reduce((a, b) => a + (b.vatAmount || 0), 0) || 0} />
                          </Typography>
                        );
                      },
                    },
                  },
                  {
                    title: 'therapy.label.customer.pay',
                    align: 'right',
                    render: (record: some) => NumericFormatText(record.amountPaid),
                    lastCell: {
                      style: { paddingTop: '10px' },
                      align: 'right',
                      colSpan: 1,
                      render: () => {
                        return (
                          <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                            {NumericFormatText(listPayment?.reduce((a, b) => a + (b.amountPaid || 0), 0) || 0)}
                          </Typography>
                        );
                      },
                    },
                  },
                ]}
              />
            </>
          )}
        </Box>
        {paymentDetails?.paymentHistories?.length === 2 && (
          <Grid container spacing={1} style={{ paddingRight: '6px' }}>
            <Grid item xs={12} sx={{ columnCount: 1 }}>
              <Box display="flex" justifyContent="flex-end">
                <Typography fontSize="19px" variant="subtitle1" style={{ minWidth: 200 }}>
                  <FormattedMessage id="therapy.payment.paymentConfirm.label.sofPrepayment" />
                </Typography>
                :&nbsp;
                <Typography fontSize="19px" variant="subtitle1" style={{ minWidth: 130 }} align="right">
                  {numericFormat(prepaymentHistory?.amountPaid || 0)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="flex-end">
                <Typography fontSize="19px" variant="subtitle1" style={{ minWidth: 200 }}>
                  <FormattedMessage id="therapy.payment.paymentConfirm.label.directPayment" />
                </Typography>
                :&nbsp;
                <Typography fontSize="19px" variant="subtitle1" style={{ minWidth: 130 }} align="right">
                  {numericFormat(paymentHistory?.amountPaid || 0)}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </>
    );
  }, [paymentDetails]);

  const footer = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 25 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1">&nbsp;</Typography>
              <Typography variant="h6">
                <FormattedMessage id="therapy.indication.request.printForm.patientConfirm" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
              <Typography variant="body1">{therapyDetail?.patient?.name}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id={'printForm.dateShort'}
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="printForm.cashier" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>{' '}
              <Typography variant="body1">{appState.authen.user?.employee_name}</Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [appState.authen.user?.employee_name, therapyDetail?.patient?.name]);

  if (!currentLocation) {
    return null;
  }
  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }} sx={{ '& p': { fontSize: '18px', marginBottom: '10px' } }}>
        {header}
        <Box marginY={2}>
          <Divider />
        </Box>
        {testInfo}
        {footer}
      </Box>
    </>
  );
};

const PaymentHistoryPrintDialog = (props: Props) => {
  const { onClose, open, therapyDetail, paymentHistoryPrint } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [dataTmp, setTmp] = React.useState(paymentHistoryPrint);

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEnter: () => setTmp(paymentHistoryPrint),
      }}
      PaperProps={{
        style: { minWidth: '60vw', maxWidth: '80vw' },
      }}
      title={'therapy.cashier.paymentHistory.printDialog.title.receipt'}
      footer={
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="printReceipt" />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />
        </Stack>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <table style={{ width: '100%' }}>
          <tbody>
            <PaymentHistoryPrintContent paymentHistoryPrint={dataTmp} therapyDetail={therapyDetail} />
          </tbody>
        </table>
      </Box>
    </DialogCustom>
  );
};

export default PaymentHistoryPrintDialog;
