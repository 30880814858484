import { TextField, TextFieldProps } from '@mui/material';
import { FormCommonProps, OverrideFormControlProps } from './type';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { getError, getPlaceholder } from './utils';
import useId from '@mui/material/utils/useId';
import FormControl from './FormControl';

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  rawError?: boolean;
  placeholder?: string;
  rawPlaceholder?: boolean;
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'render'>;
} & FormCommonProps<TFieldValues, TName> &
  OverrideFormControlProps &
  Omit<TextFieldProps, 'name' | 'ref' | 'placeholder' | 'error' | 'id' | 'multiline' | 'value'>;

const TextArea = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  form,
  placeholder,
  rawPlaceholder = false,
  label,
  rawLabel = false,
  controllerProps,
  controlProps,
  helpTextProps,
  labelProps,
  hideError = false,
  required,
  fullWidth = true,
  disabled = false,
  rawError = false,
  ...restProps
}: Props<TFieldValues, TName>) => {
  const intl = useIntl();
  const id = useId();
  const error = getError(form, name);
  return (
    <FormControl
      error={error}
      label={label}
      rawLabel={rawLabel}
      required={required}
      htmlFor={id}
      fullWidth={fullWidth}
      controlProps={controlProps}
      labelProps={labelProps}
      helpTextProps={helpTextProps}
      hideError={hideError}
      disabled={disabled}
      rawError={rawError}
    >
      <Controller
        name={name}
        control={form.control}
        render={({ field: { value, ref, onChange, onBlur } }) => (
          <TextField
            {...restProps}
            disabled={disabled}
            value={value}
            multiline
            rows={restProps.rows || 3}
            ref={ref}
            name={name}
            onChange={(event) => {
              onChange(event);
              restProps?.onChange && restProps.onChange(event);
            }}
            onBlur={(event) => {
              onBlur();
              restProps?.onBlur && restProps.onBlur(event);
            }}
            id={id}
            error={!!error}
            placeholder={getPlaceholder(placeholder, rawPlaceholder, intl)}
          />
        )}
        {...controllerProps}
      />
    </FormControl>
  );
};

export default TextArea;
