import styled from '@emotion/styled';
import { Box, Stack, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { Medication } from 'modules/schema';
import { FormattedMessage } from 'react-intl';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { NumericFormatText } from 'modules/common/utils';
import { renderLabelSearch } from '../../const';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: 11,
    boxShadow: '0px 4px 8px 0px #E0ECFF',
    borderRadius: '5px',
    padding: '20px',
    width: '300px',
  },
}));

const TitleStyle = styled(Typography)({
  color: '#263238',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.1px',
  width: '130px',
});

const ValueStyle = styled(Typography)({
  color: '#263238',
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.25px',
});

interface Props {
  medication?: Medication;
  medicationGroupName?: string;
  medicationGroup?: Medication;
  medicationName: string;
  type: string;
}

const MedicationInfo = ({ medication, medicationGroupName, medicationGroup, medicationName, type }: Props) => {
  const { appState } = useGeneralHook();
  const countryList = appState.common.countryList;

  return (
    <Stack
      direction="row"
      justifyContent="flex-start"
      alignItems="center"
      sx={{
        cursor: 'default',
      }}
    >
      <LightTooltip
        placement="bottom"
        title={
          <Stack
            direction="column"
            gap="8px"
            sx={{
              whiteSpace: 'normal',
              wordWrap: 'break-word',
              cursor: 'default',
            }}
          >
            <Typography
              sx={{
                color: '#263238',
                fontFamily: 'Roboto',
                fontSize: '16px',
                fontStyle: 'normal',
                fontWeight: 500,
                lineHeight: '24px',
                letterSpacing: '0.15px',
              }}
            >
              {type === 'MEDICATION_EXTERNAL' ? (
                <Typography variant="subtitle1" paddingRight={1} gap={'4px'}>
                  {medicationName}
                  &nbsp;&#40;
                  <FormattedMessage id="prescription.detail.type.medication.external" />
                  &nbsp;&#41;
                </Typography>
              ) : (
                medicationName
              )}
            </Typography>
            {type === 'MEDICATION_INTERNAL' ? (
              <Box minWidth={400}>
                <Stack direction="row">
                  <TitleStyle>
                    <FormattedMessage id="therapy.detail.label.drugGroup" />
                  </TitleStyle>
                  <ValueStyle>{medicationGroupName}</ValueStyle>
                </Stack>
                <Stack direction="row">
                  <TitleStyle>
                    <FormattedMessage id="pharmacy.produceCountry" />
                  </TitleStyle>
                  <ValueStyle>
                    {countryList?.find((item) => item.value === medicationGroup?.country || '')?.label}
                  </ValueStyle>
                </Stack>
                <Stack direction="row">
                  <TitleStyle>
                    <FormattedMessage id="inventory" />
                  </TitleStyle>
                  <ValueStyle>
                    {medicationGroup?.quantity} <FormattedMessage id={medicationGroup?.basicUnit || ' '} />
                  </ValueStyle>
                </Stack>
                <Stack direction="row">
                  <TitleStyle>
                    <FormattedMessage id="rawPrice" />
                  </TitleStyle>
                  <ValueStyle>{NumericFormatText(medication?.publicPrice)}</ValueStyle>
                </Stack>
              </Box>
            ) : (
              <FormattedMessage id="prescription.medication.external" />
            )}
          </Stack>
        }
      >
        <Stack direction={'row'} flex={1} alignItems={'center'}>
          {type === 'MEDICATION_EXTERNAL' ? (
            <Typography variant="subtitle1" paddingRight={1} gap={'4px'}>
              {medicationName}
              &nbsp;&#40;
              <FormattedMessage id="prescription.detail.type.medication.external" />
              &nbsp;&#41;
            </Typography>
          ) : (
            <Typography variant="subtitle1" paddingRight={1} gap={'4px'}>
              {renderLabelSearch(medication?.name, medication?.ingredient, medication?.dose)}
            </Typography>
          )}
        </Stack>
      </LightTooltip>
    </Stack>
  );
};

export default MedicationInfo;
