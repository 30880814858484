import { Close, Delete } from '@mui/icons-material';
import { Box, IconButton, InputLabel, Popper, Typography } from '@mui/material';
import moment from 'moment/moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { DECIMAL_NUMBER_REGEX, NUMBER_REGEX_NO_ZERO_HEADER } from 'modules/common/regex';
import SchemaForm from 'modules/common/SchemaForm';
import { ROUTES } from 'modules/layout/router';
import { INVENTORY_IMPORT_STATUS, MEDICATION_UNIT } from '../inventory/constant';
import Stack from '@mui/material/Stack';
import styled from '@emotion/styled';
import { NumericFormatText } from 'modules/common/utils';
import LazyLoad from 'react-lazyload';
import { InventoryInfo, Medication, MedicationSupplier } from 'modules/schema';
import { useFetch } from 'modules/common/hook';

interface Props {
  onSubmit?: (formVal: some) => void;
  onClose?: () => void;
  formData?: some;
  medicationImportData: Medication[];
  supplierData: MedicationSupplier[];
}

const StyledPopper = styled(Popper)({
  minWidth: '250px !important',
});

const FormDataDialogEdit: React.FunctionComponent<Props> = (props) => {
  const { formData, onClose, onSubmit, medicationImportData, supplierData } = props;
  const { API_SERVER } = useGeneralHook();
  const status = INVENTORY_IMPORT_STATUS.find((v) => v.value === formData?.status)?.label;
  const [newFormData, setNewFormData] = React.useState<some | undefined>();

  const supplierOption = React.useMemo(() => {
    return supplierData?.map((item) => ({
      ...item,
      value: item.id,
      label: item.name,
    }));
  }, [supplierData]);

  // Get all data inventory info
  const medicationIds = formData?.inventoryImportDetails?.map((i) => i?.medication?.id);
  const { data: inventoryInfoData } = useFetch(
    API_SERVER.inventory.getListInventoryInfo({ medicationIds: medicationIds }),
    {
      globalLoading: true,
      enabled: medicationIds,
    },
  );

  React.useEffect(() => {
    setNewFormData({
      ...formData,
      inventoryInfoData,
    });
  }, [formData, inventoryInfoData]);

  return (
    <Box padding={2}>
      <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} marginBottom={'20px'}>
        <Typography variant="h6" color={'rgb(0, 82, 224)'}>
          <FormattedMessage id={formData?.readOnly ? 'inventory.view' : formData?.id && 'inventory.update'} />
        </Typography>
        <Box textAlign={'right'}>
          <IconButton onClick={onClose} size="small" tabIndex={-1}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      <SchemaForm
        onSubmit={onSubmit}
        formData={newFormData}
        readOnly={formData?.readOnly}
        noHelperText={formData?.readOnly}
        onCancel={onClose}
        cancelLabel={<FormattedMessage id={'destroy'} />}
        formProps={{
          style: {
            flex: 1,
          },
        }}
        schema={{
          fields: ({ formProps, valuesField: valuesFieldAll }) => {
            const { intl, readOnly } = formProps || {};
            return {
              code: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="inventory.code" />
                      </InputLabel>
                      <Typography variant="subtitle1">{formData?.code}</Typography>
                    </div>
                  );
                },
                hidden: !formData?.id,
                propsWrapper: { xs: 2 },
              },
              importDate: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="inventory.importDate" />
                      </InputLabel>
                      <Typography variant="subtitle1">{moment(formData?.importDate).format(FE_DATE_FORMAT)}</Typography>
                    </div>
                  );
                },
                propsWrapper: { xs: 3 },
                register: {
                  required: true,
                },
                readOnly: true,
                hidden: formData?.status !== 'IMPORTED' && formData?.status !== 'CANCELLED',
              },
              importBy: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="inventory.warehouser" />
                      </InputLabel>
                      <Typography>{formData?.importBy}</Typography>
                    </div>
                  );
                },
                propsWrapper: { xs: 3 },
                hidden: formData?.status !== 'IMPORTED' && formData?.status !== 'PROCESSING',
              },
              status: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="status" />
                      </InputLabel>
                      <Typography
                        noWrap
                        sx={{
                          color:
                            status === 'new'
                              ? 'text.secondary'
                              : status === 'processing'
                              ? 'warning.main'
                              : status === 'imported'
                              ? 'success.main'
                              : status === 'cancelled'
                              ? 'error.main'
                              : 'primary.main',
                          fontWeight: '500',
                        }}
                      >
                        <FormattedMessage id={`inventory.import.status.` + status} />
                      </Typography>
                    </div>
                  );
                },
                hidden: !formData?.id,
                propsWrapper: { xs: 4 },
              },
              inventoryImportDetails: {
                type: 'array',
                label: intl.formatMessage({ id: 'inventory.drugList' }),
                labelAdd: intl.formatMessage({ id: 'inventory.addNew' }),
                disableCloseBtn: true,
                hideInitialElement: readOnly,
                paperItemProps: {
                  sx: { padding: '12px 24px', bgcolor: 'grey.50' },
                  variant: 'elevation',
                  elevation: 0,
                },
                defaultData: {
                  medication: null,
                  drugInfo: null,
                  medicationSupplier: null,
                  lot: null,
                  expiredDate: null,
                  quantity: null,
                  unit: null,
                  unitPrice: null,
                },
                schema: {
                  fields: (props) => {
                    const { formProps, valuesField, methods, fieldName } = props;
                    const { arrayData, methodsArray } = formProps;
                    return {
                      lazyLoad: {
                        type: 'raw',
                        component: () => {
                          return (
                            <LazyLoad height={200} offset={100}>
                              <SchemaForm
                                hideSubmitButton
                                formData={valuesField}
                                schema={{
                                  fields: (propsChild) => {
                                    const { valuesField: valuesFieldChild, methods: methodChild } = propsChild;
                                    const medicationImmutable =
                                      formData?.readOnly ||
                                      (formData?.status !== 'NEW' && formData?.id && valuesFieldChild?.id);
                                    return {
                                      medication: {
                                        type: medicationImmutable ? 'label' : 'auto-complete',
                                        labelValue: valuesFieldChild.medication?.name,
                                        variant: 'subtitle1',
                                        label: intl.formatMessage({ id: 'inventory.drugName' }),
                                        placeholder: intl.formatMessage({ id: 'enter' }),
                                        propsWrapper: { xs: 4 },
                                        options: medicationImportData,
                                        isOptionEqualToValue: (option, value) => option.id === value.id,
                                        register: {
                                          required: true,
                                        },
                                        getOptionLabel: (option) =>
                                          option
                                            ? `${option.id} - ${option.name} (${
                                                option.ingredient ? option.ingredient : ''
                                              })`
                                            : ' ',
                                        onChange: (value) => {
                                          if (value) {
                                            methodChild.setValue('detailChanged', true);
                                            methods.setValue(`${fieldName}.detailChanged`, true);
                                            methods.setValue(`${fieldName}.medication`, value);
                                          }
                                        },
                                      },
                                      drugInfo: {
                                        unregister: true,
                                        type: () => (
                                          <div style={{ display: 'flex', gap: 16 }}>
                                            <div style={{ flex: 1 }}>
                                              <Typography variant="body2" color="grey.600" fontWeight={500}>
                                                <FormattedMessage id="inventory.drugCode" />
                                              </Typography>
                                              <Typography variant="body2" mt={0.5}>
                                                {valuesFieldChild?.medication?.code}
                                              </Typography>
                                            </div>
                                            <div style={{ flex: 1 }}>
                                              <Typography variant="body2" color="grey.600" fontWeight={500}>
                                                <FormattedMessage id="inventory.manufacturer" />
                                              </Typography>
                                              <Typography variant="body2" mt={0.5}>
                                                {valuesFieldChild?.medication?.manufacturer?.name}
                                              </Typography>
                                            </div>
                                          </div>
                                        ),
                                        propsWrapper: { xs: 4 },
                                      },
                                      medicationSupplier: {
                                        readOnly,
                                        type: 'auto-complete',
                                        label: intl.formatMessage({ id: 'inventory.supplier' }),
                                        placeholder: intl.formatMessage({ id: 'enter' }),
                                        disableSearchByText: true,
                                        options: supplierOption,
                                        labelKey: 'name',
                                        propsWrapper: { xs: 4 },
                                        noHelperText: true,
                                        isOptionEqualToValue: (option, value) => option.id === value.id,
                                        onChange: (value) => {
                                          if (value) {
                                            methodChild.setValue('detailChanged', true);
                                            methods.setValue(`${fieldName}.detailChanged`, true);
                                            methods.setValue(`${fieldName}.medicationSupplier`, value);
                                          }
                                        },
                                      },
                                      lot: {
                                        readOnly,
                                        type: 'auto-complete',
                                        freeSolo: true,
                                        label: intl.formatMessage({ id: 'inventory.lot' }),
                                        placeholder: intl.formatMessage({ id: 'enter' }),
                                        loadKey: valuesFieldChild?.medication?.id,
                                        propsWrapper: { xs: 2 },
                                        PopperComponent: StyledPopper,
                                        isOptionEqualToValue: (option, value) => option.id === value.id,
                                        register: {
                                          required: true,
                                        },
                                        renderOption: (props, option) => {
                                          return (
                                            <Box component="li" {...props} key={option.lot}>
                                              <Box>
                                                <Stack alignItems="baseline" direction="row">
                                                  <Typography variant="subtitle1">
                                                    <FormattedMessage id="inventory.lotShort" />
                                                    :&nbsp;
                                                  </Typography>
                                                  <Typography variant="body1">{option.lot}</Typography>
                                                </Stack>
                                                <Stack alignItems="baseline" direction="row">
                                                  <Typography variant="subtitle1">
                                                    <FormattedMessage id="expiredDate" />
                                                    :&nbsp;
                                                  </Typography>
                                                  <Typography variant="body1">
                                                    {option.expiredDate &&
                                                      moment(option.expiredDate).format(FE_DATE_FORMAT)}
                                                  </Typography>
                                                </Stack>
                                              </Box>
                                            </Box>
                                          );
                                        },
                                        getOptionLabel: (option) => option.lot || option,
                                        onChange: (value) => {
                                          methodChild.setValue(
                                            'expiredDate',
                                            moment(value?.expiredDate, BE_DATE_TIME_FORMAT) || '',
                                          );
                                          methods.setValue(
                                            `${fieldName}.expiredDate`,
                                            moment(value?.expiredDate, BE_DATE_TIME_FORMAT) || '',
                                          );
                                          if (value?.medicationUnitSettings?.length === 1) {
                                            methodChild.setValue('unit', value?.medicationUnitSettings?.[0].unit);
                                            methods.setValue(
                                              `${fieldName}.unit`,
                                              value?.medicationUnitSettings?.[0].unit,
                                            );
                                          } else {
                                            methodChild.setValue('unit', null);
                                            methods.setValue(`${fieldName}.unit`, null);
                                          }
                                          methodChild.setValue('detailChanged', true);
                                          methods.setValue(`${fieldName}.detailChanged`, true);
                                          methods.setValue(`${fieldName}.lot`, value?.lot || '');
                                        },
                                        loadOptions: async () => {
                                          const data = valuesFieldAll?.inventoryInfoData?.filter(
                                            (i: InventoryInfo) => i.medicationId === valuesFieldChild?.medication?.id,
                                          );
                                          methodChild.setValue('listLot', data);
                                          methods.setValue(`${fieldName}.listLot`, data);
                                          return data;
                                        },
                                        onChangeInput: (event) => {
                                          methodChild.setValue('lot', event.target.value);
                                          methods.setValue(`${fieldName}.lot`, event.target.value);
                                          if (event.target.value) {
                                            methodChild.setValue('detailChanged', true);
                                            methods.setValue(`${fieldName}.detailChanged`, true);
                                          }
                                        },
                                      },
                                      listLot: {
                                        type: 'hidden',
                                        unregister: true,
                                        shouldUnregister: true,
                                      },
                                      detailChanged: {
                                        type: 'hidden',
                                        unregister: true,
                                        shouldUnregister: true,
                                      },
                                      expiredDate: {
                                        readOnly,
                                        type: 'datePicker',
                                        label: intl.formatMessage({ id: 'expiredDate' }),
                                        propsWrapper: { xs: 2 },
                                        disablePast: true,
                                        register: {
                                          required: true,
                                          validate: (value) => {
                                            return (formData?.status === 'IMPORTED' &&
                                              valuesFieldChild?.medication?.id &&
                                              !valuesFieldChild?.detailChanged) ||
                                              (moment(value, BE_DATE_FORMAT, true).isValid() &&
                                                moment(value, BE_DATE_FORMAT, true).isSameOrAfter(
                                                  moment().startOf('day'),
                                                ))
                                              ? true
                                              : intl.formatMessage({ id: 'validation.invalidDate' });
                                          },
                                        },
                                        onChange: (value) => {
                                          if (value) {
                                            methodChild.setValue('detailChanged', true);
                                            methods.setValue(`${fieldName}.detailChanged`, true);
                                            methods.setValue(`${fieldName}.expiredDate`, moment(value));
                                          }
                                        },
                                      },
                                      quantity: {
                                        readOnly,
                                        type: 'text-field',
                                        inputType: 'number',
                                        label: intl.formatMessage({ id: 'inventory.quantity' }),
                                        placeholder: intl.formatMessage({ id: 'enter' }),
                                        propsWrapper: { xs: 2 },
                                        register: {
                                          required: true,
                                          pattern: {
                                            value: NUMBER_REGEX_NO_ZERO_HEADER,
                                            message: intl.formatMessage({ id: 'inventory.quantityValid' }),
                                          },
                                          max: {
                                            value: 1000000000,
                                            message: intl.formatMessage({ id: 'validation.invalid' }),
                                          },
                                          validate: () => {
                                            return !valuesFieldChild?.unitPrice ||
                                              !valuesFieldChild?.quantity ||
                                              valuesFieldChild?.unitPrice * valuesFieldChild?.quantity <
                                                9999999999999999
                                              ? true
                                              : intl.formatMessage({ id: 'inventory.import.totalAmount.max' });
                                          },
                                        },
                                        tooltipError: true,
                                        onChange: (e) => {
                                          if (e) {
                                            methodChild.setValue('detailChanged', true);
                                            methods.setValue(`${fieldName}.detailChanged`, true);
                                            methods.setValue(`${fieldName}.quantity`, e.target.value);
                                          }
                                        },
                                      },
                                      unit: {
                                        readOnly,
                                        type: 'select',
                                        options: MEDICATION_UNIT.filter((unit) => {
                                          const lot = valuesFieldChild?.lot;
                                          const existsLot =
                                            valuesFieldChild?.listLot &&
                                            valuesFieldChild?.listLot?.find((l) => l?.lot === lot);
                                          if (lot && typeof lot === 'object') {
                                            //case import exists lot, get list unit from medication unit setting
                                            return lot.medicationUnitSettings?.find((u) => u.unit === unit.value);
                                            // )
                                          } else if (lot && typeof lot === 'string' && existsLot) {
                                            return existsLot.medicationUnitSettings?.find((u) => u.unit === unit.value);
                                          } else {
                                            // case import new lot, get list unit from medication
                                            const medicationN = medicationImportData?.find(
                                              (d) => d.id === valuesFieldChild?.medication?.id,
                                            );
                                            return (
                                              unit.value === medicationN?.basicUnit ||
                                              medicationN?.unitConverterList?.find((u) => u.fromUnit === unit.value)
                                            );
                                          }
                                        }),
                                        label: intl.formatMessage({ id: 'inventory.unit' }),
                                        placeholder: intl.formatMessage({ id: 'select' }),
                                        propsWrapper: { xs: 2 },
                                        register: {
                                          required: true,
                                        },
                                        onChange: (value) => {
                                          if (value) {
                                            methodChild.setValue('detailChanged', true);
                                            methods.setValue(`${fieldName}.detailChanged`, true);
                                            methods.setValue(`${fieldName}.unit`, value);
                                          }
                                        },
                                      },
                                      unitPrice: {
                                        readOnly,
                                        type: 'number-field',
                                        inputType: 'number',
                                        label: intl.formatMessage({ id: 'price' }),
                                        placeholder: intl.formatMessage({ id: 'enter' }),
                                        propsWrapper: { xs: 2 },
                                        register: {
                                          required: true,
                                          maxLength: {
                                            value: 16,
                                            message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                                          },
                                          pattern: DECIMAL_NUMBER_REGEX,
                                          validate: () => {
                                            return !valuesFieldChild?.unitPrice ||
                                              !valuesFieldChild?.quantity ||
                                              valuesFieldChild?.unitPrice * valuesFieldChild?.quantity <
                                                9999999999999999
                                              ? true
                                              : intl.formatMessage({ id: 'inventory.import.totalAmount.max' });
                                          },
                                        },
                                        tooltipError: true,
                                        onChange: (e) => {
                                          if (e) {
                                            methodChild.setValue('detailChanged', true);
                                            methods.setValue(`${fieldName}.detailChanged`, true);
                                            methods.setValue(`${fieldName}.unitPrice`, e.target.value);
                                          }
                                        },
                                      },
                                      totalPrice: {
                                        unregister: true,
                                        type: () => {
                                          const price =
                                            (valuesFieldChild.unitPrice || 0) * (valuesFieldChild.quantity || 0);
                                          return (
                                            <div>
                                              <Typography variant="body2" color="grey.600" fontWeight={500}>
                                                <FormattedMessage id="totalPrice" />
                                              </Typography>
                                              <Typography variant="subtitle1" fontWeight={500} mt={0.5}>
                                                {price > 0 && NumericFormatText(price)}{' '}
                                              </Typography>
                                            </div>
                                          );
                                        },
                                        propsWrapper: { xs: 2 },
                                      },
                                      basicUnitWarning: {
                                        unregister: true,
                                        hidden:
                                          valuesFieldChild?.medication === null ||
                                          valuesFieldChild?.medication === undefined ||
                                          medicationImportData?.find((d) => d.id === valuesFieldChild?.medication?.id)
                                            ?.basicUnit ||
                                          formData?.readOnly ||
                                          (formData?.status !== 'NEW' && formData?.id && valuesFieldChild?.id),
                                        type: () => {
                                          return (
                                            <Typography
                                              color="primary"
                                              style={{
                                                textDecoration: 'underline',
                                                cursor: 'pointer',
                                                color: 'red',
                                              }}
                                              onClick={() => {
                                                window.open(
                                                  `${ROUTES.clinicDrugWarehouse}?medicationId=${valuesFieldChild?.medication?.id}`,
                                                  '_blank',
                                                );
                                              }}
                                            >
                                              {intl.formatMessage({ id: 'inventory.medication.baseUnit.warning' })}
                                            </Typography>
                                          );
                                        },
                                      },
                                      medicationDuplicateWarning: {
                                        unregister: true,
                                        hidden:
                                          !valuesFieldChild?.medication ||
                                          !valuesFieldChild?.medication?.id ||
                                          valuesFieldAll?.inventoryImportDetails?.filter(
                                            (u) => u.medication?.id === valuesFieldChild?.medication?.id,
                                          )?.length <= 1,
                                        type: () => {
                                          return (
                                            <Typography color="primary" style={{ color: 'red' }}>
                                              {intl.formatMessage({ id: 'inventory.medication.duplicate.warning' })}
                                            </Typography>
                                          );
                                        },
                                      },
                                      productLockedWarning: {
                                        unregister: true,
                                        hidden: !valuesFieldChild?.isLocked,
                                        type: () => {
                                          return (
                                            <Typography color="warning.main">
                                              {intl.formatMessage({ id: 'inventory.import.product.locked' })}
                                            </Typography>
                                          );
                                        },
                                      },
                                    };
                                  },
                                }}
                              />
                            </LazyLoad>
                          );
                        },
                      },
                      delete: {
                        unregister: true,
                        hidden: readOnly || valuesField.isLocked,
                        type: () => (
                          <IconButtonTitle
                            title="delete"
                            size="small"
                            style={{ height: 'fit-content' }}
                            onClick={() => methodsArray?.remove(arrayData?.index)}
                          >
                            <Delete />
                          </IconButtonTitle>
                        ),
                        propsWrapper: {
                          xs: undefined,
                          style: {
                            justifyContent: 'center',
                            alignItems: 'center',
                            display: 'flex',
                            height: '100%',
                          },
                        },
                      },
                    };
                  },
                  ui: [
                    {
                      id: 'default',
                      propsWrapper: { xs: true },
                      fields: ['lazyLoad'].filter(Boolean),
                    },
                    {
                      id: 'action',
                      propsWrapper: { xs: undefined },
                      propsGridContainer: { style: { height: '100%' } },
                      fields: ['delete'],
                    },
                    {
                      id: 'medicationDuplicateWarning',
                      fields: ['medicationDuplicateWarning'],
                      propsWrapper: {
                        style: { paddingTop: '0' },
                      },
                    },
                    {
                      id: 'basicUnitWarning',
                      fields: ['basicUnitWarning'],
                      propsWrapper: {
                        style: { paddingTop: '0' },
                      },
                    },
                    {
                      id: 'productLockedWarning',
                      fields: ['productLockedWarning'],
                      propsWrapper: {
                        style: { paddingTop: '0' },
                      },
                    },
                  ],
                },
              },
              note: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'note' }),
                placeholder: intl.formatMessage({ id: 'noteEnter' }),
                multiline: true,
                rows: 3,
                disabled: readOnly,
                propsWrapper: {
                  style: { paddingTop: '0' },
                },
                required: false,
                register: {
                  maxLength: {
                    value: 10000,
                    message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                  },
                },
              },
            };
          },
        }}
      />
    </Box>
  );
};

export default FormDataDialogEdit;
