import { InputAdornment, Typography } from '@mui/material';
import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, equipmentsData, roomsData, departments, isInsurance } = formProps || {};
  const list = valuesField?.listData?.filter((v) => v.id !== valuesField?.id) || [];

  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'indication.code' }),
      placeholder: intl.formatMessage({ id: 'indication.codeEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: isInsurance,
      },
      required: isInsurance,
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'indication.name' }),
      placeholder: intl.formatMessage({ id: 'indication.nameEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
        validate: (value) =>
          list.find((v) => v.code === value) ? intl.formatMessage({ id: 'indication.duplicateCode' }) : true,
      },
    },
    department: {
      type: 'select',
      label: intl.formatMessage({ id: 'departmentCode' }),
      placeholder: intl.formatMessage({ id: 'departmentCodeEnter' }),
      propsWrapper: { xs: 6 },
      rawOptions: true,
      options: departments,
      register: {
        required: true,
      },
      required: true,
    },
    physicsRoom: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'indication.room' }),
      placeholder: intl.formatMessage({ id: 'indication.pickRoom' }),
      options: roomsData,
      isOptionEqualToValue: (options, selected) => options.value === selected.value,
      propsWrapper: { xs: 6 },
    },
    medicalEquipment: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'indication.device' }),
      placeholder: intl.formatMessage({ id: 'indication.pickDevice' }),
      options: equipmentsData,
      isOptionEqualToValue: (options, selected) => options.value === selected.value,
      propsWrapper: { xs: 6 },
    },
    paymentPercent: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'paymentPercent' }),
      placeholder: intl.formatMessage({ id: 'paymentPercentEnter' }),
      propsWrapper: { xs: 6 },
      readOnly: !isInsurance,
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <Typography variant="h6" style={{ marginRight: 10 }}>
              %
            </Typography>
          </InputAdornment>
        ),
      },
      register: {
        required: isInsurance,
        max: { value: 100, message: intl.formatMessage({ id: 'supply.valid' }, { num: 100 }) },
      },
      required: isInsurance,
    },
    price: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'indication.price' }),
      placeholder: intl.formatMessage({ id: 'indication.priceEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'info' }),
      fields: ['code', 'name', 'department', 'physicsRoom', 'medicalEquipment'],
    },
    {
      id: 'price',
      title: intl.formatMessage({ id: 'rawPrice' }),
      fields: ['price'],
    },
  ];
};

export const indicationFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
