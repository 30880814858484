import { some } from '../../../../common/constants';
import DialogCustom from '../../../../common/component/DialogCustom';
import { Box } from '@mui/material';
import SchemaForm from '../../../../common/SchemaForm';
import * as React from 'react';
import { API_SERVER } from '../../../../common/api';
import { useEffect } from 'react';
import { PAYMENT_METHOD } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';

interface Props {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (value: some) => void;
  paymentDetail?: some;
}

const PaymentMethodDialog: React.FunctionComponent<Props> = (props) => {
  const { onClose, open, onSubmit, paymentDetail } = props;
  const [formDataRefund, setFormDataRefund] = React.useState<some>();
  const { data } = useFetch(API_SERVER.paymentHistory.getRefundDetail(paymentDetail?.refundId), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (data) {
      setFormDataRefund({
        ...data,
        refundAmount: data?.amountPaid,
      });
    }
  }, [data]);

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title={'therapy.payment.refund.title.refundDialog'}
      maxWidth="sm"
      PaperProps={{
        style: { minWidth: 480 },
      }}
    >
      <Box padding={2}>
        <SchemaForm
          onCancel={onClose}
          onSubmit={onSubmit}
          formData={formDataRefund}
          readOnly={paymentDetail?.refundId}
          schema={{
            fields: ({ formProps: { intl, appState } }) => {
              return {
                refundAmount: {
                  type: 'number-field',
                  inputType: 'number',
                  // defaultValue: '0',
                  label: intl.formatMessage({ id: 'therapy.cashier.dialog.label.refundAmount' }),
                  placeholder: intl.formatMessage({ id: 'therapy.refund.label.amountEnter' }),
                  register: {
                    required: true,
                    max: {
                      value: paymentDetail?.patientPay,
                      message: intl.formatMessage({ id: 'validation.invalid' }),
                    },
                  },
                  propsWrapper: { xs: 6 },
                },
                paymentMethod: {
                  type: 'select',
                  defaultValue: PAYMENT_METHOD.CASH.value,
                  label: intl.formatMessage({ id: 'common.refund.paymentMethod' }),
                  placeholder: intl.formatMessage({ id: 'select' }),
                  options: Object.values(PAYMENT_METHOD).filter(
                    (item) => item.value !== PAYMENT_METHOD.PREPAYMENT.value,
                  ),
                  disableClearBtn: true,
                  style: { width: 248 },
                  register: { required: true },
                  propsWrapper: { xs: 6 },
                },
                note: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'refundReason' }),
                  placeholder: intl.formatMessage({ id: 'therapy.refund.label.reasonEnter' }),
                  rows: 3,
                  multiline: true,
                  propsWrapper: { xs: 12 },
                  register: {
                    required: true,
                    maxLength: {
                      value: 256,
                      message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                    },
                  },
                },
              };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default PaymentMethodDialog;
