import { replace } from 'connected-react-router';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router';
import { some } from '../constants';

export const stringifyUrl = function (obj) {
  var str: string[] = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      const value = obj[p];
      if (value !== '' && value !== null && value !== undefined) {
        str.push(
          encodeURIComponent(p) +
            '=' +
            encodeURIComponent(
              typeof value === 'object'
                ? JSON.stringify(value)
                : typeof value === 'string' || typeof value === 'number'
                ? value
                : '',
            ),
        );
      }
    }
  return str.join('&');
};

export interface HookPaginationProps extends ReturnType<typeof usePaginationHook> {}
interface Pagination {
  page: number;
  pageSize: number;
}
interface Props {
  defaultFilter?: some;
  disableLink?: boolean;
  disablePagination?: boolean;
}
const usePaginationHook = (props?: Props) => {
  const { defaultFilter = {}, disableLink, disablePagination } = props || {};
  const defaultPagination = useMemo(
    () => ({
      page: 0,
      pageSize: 25,
    }),
    [],
  );

  const dispatch = useDispatch();
  const location = useLocation();
  const paramsUrl = useMemo(() => {
    let tmp: some = {};
    const search = new URLSearchParams(location.search);
    search.forEach(function (value, key) {
      if (value.trim() !== '') {
        try {
          tmp[key] = JSON.parse(value.trim());
        } catch (e) {
          tmp[key] = value.trim();
        }
      } else {
        tmp[key] = undefined;
      }
    });
    return tmp;
  }, [location.search]);

  const [pagination, setPagination] = useState<Pagination>({
    page: paramsUrl.page || defaultPagination.page,
    pageSize: paramsUrl.pageSize || defaultPagination.pageSize,
  });
  const [filter, setFilter] = useState<some>({
    ...defaultFilter,
    ...paramsUrl,
  });

  const clearParams = useCallback(
    (value?: some) => {
      if (disableLink) {
        setPagination(defaultPagination);
        setFilter(value || defaultFilter);
      } else {
        dispatch(
          replace({
            search: stringifyUrl({
              ...defaultPagination,
              ...defaultFilter,
              tab: filter.tab,
              ...value,
            }),
          }),
        );
        setPagination(defaultPagination);
        setFilter({ ...defaultPagination, ...defaultFilter, tab: filter.tab, ...value } || defaultFilter);
      }
    },
    [defaultFilter, defaultPagination, disableLink, dispatch, filter.tab],
  );

  const setParams = useCallback(
    (form: some) => {
      const { page, pageSize, ...rest } = form;
      if (disableLink) {
        if (!disablePagination) {
          setPagination((old) => ({
            page: page || old.page,
            pageSize: pageSize || old.pageSize,
          }));
        }
        setFilter({ ...filter, ...rest });
      } else {
        if (disablePagination) {
          dispatch(
            replace({
              search: stringifyUrl({
                ...filter,
                ...rest,
              }),
            }),
          );
        } else {
          dispatch(
            replace({
              search: stringifyUrl({
                ...pagination,
                ...form,
                page: 0,
              }),
            }),
          );
        }
      }
    },
    [disableLink, disablePagination, dispatch, filter, pagination],
  );

  const onPageChange = useCallback(
    (event: unknown, newPage: number) => {
      if (disableLink || disablePagination) {
        setPagination((old) => ({ ...old, page: newPage }));
      } else {
        dispatch(
          replace({
            search: stringifyUrl({ ...filter, ...pagination, page: newPage }),
          }),
        );
      }
    },
    [disableLink, disablePagination, dispatch, filter, pagination],
  );

  const onRowsPerPageChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      if (disableLink || disablePagination) {
        setPagination((old) => ({
          ...old,
          pageSize: parseInt(event.target.value, 10),
          page: 0,
        }));
      } else {
        dispatch(
          replace({
            search: stringifyUrl({
              ...pagination,
              ...filter,
              pageSize: parseInt(event.target.value, 10),
              page: 0,
            }),
          }),
        );
      }
    },
    [disableLink, disablePagination, dispatch, filter, pagination],
  );

  useEffect(() => {
    if (!disableLink) {
      const { page = 0, pageSize, ...rest } = paramsUrl;
      setPagination((old) => ({
        page: page,
        pageSize: pageSize || old.pageSize,
      }));
      setFilter({ ...defaultFilter, ...rest });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [disableLink, paramsUrl]);

  return {
    pagination,
    filter,
    params: { ...filter, ...pagination } as some,
    tab: filter.tab,
    setParams,
    clearParams,
    onPageChange,
    onRowsPerPageChange,
  };
};

export default usePaginationHook;
