import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../common/api';
import LoadingIcon from '../../common/component/LoadingIcon';
import { some, SYSTEM_CATEGORY_SCOPE, SYSTEM_CATEGORY_TYPE } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { setLoading } from '../../common/redux/commonReducer';
import PriceListFormDialog from '../component/service/PriceListFormDialog';
import ServiceMainBox from '../component/service/ServiceMainBox';
import TabCategory from '../component/service/TabCategory';
import { axiosThunk } from '../../common/redux/axios';
import { useFetch } from 'modules/common/hook';

interface Props {}

const ServiceAdminPage: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const [categoryListFormData, setCategoryListFormData] = React.useState<some | undefined>();
  const [categoryId, setCategoryId] = React.useState<number | undefined>();
  const { promptConfirmation, close } = confirmDialog;

  const {
    data: categoryList,
    revalidate,
    isValidating,
  } = useFetch(
    API_SERVER.systemCategory.getList({
      scope: SYSTEM_CATEGORY_SCOPE.other,
      type: SYSTEM_CATEGORY_TYPE.priceList,
    }),
  );

  const onSubmitCategoryPlanDefinitionForm = React.useCallback(
    async (value: some) => {
      try {
        const data = {
          ...value,
          type: SYSTEM_CATEGORY_TYPE.priceList,
          scope: SYSTEM_CATEGORY_SCOPE.other,
        };
        dispatch(setLoading(true));
        if (value.id) {
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.update(value.id), method: 'put', data }));
        } else {
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.create(), method: 'post', data }));
        }
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e: any) {
        const errors = e?.response?.data?.errors;
        if (errors) {
          errors?.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
        setCategoryListFormData(undefined);
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onDeleteCategory = React.useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDeleteServicePrice' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.delete(value.id), method: 'delete' }));
          revalidate();
          if (value.id === categoryId) {
            setCategoryId(categoryList?.[0]?.id);
          }
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e: any) {
          let err = e?.response?.data?.errors;
          if (err) {
            err.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
          setCategoryListFormData(undefined);
        }
      }
      close();
    },
    [categoryId, categoryList, close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  React.useEffect(() => {
    if (!categoryId && categoryList?.[0]?.id) {
      setCategoryId(categoryList?.[0]?.id || undefined);
    }
    if (!categoryList?.[0]?.id) {
      setCategoryId(undefined);
    }
  }, [categoryId, categoryList]);

  return (
    <Paper style={{ flex: 1, overflow: 'auto' }}>
      <TabCategory
        data={categoryList}
        onCreateOrUpdateCategory={setCategoryListFormData}
        onDeleteCategory={onDeleteCategory}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
      />
      {!categoryList && isValidating && <LoadingIcon />}
      {categoryId && <ServiceMainBox categoryId={categoryId} />}
      <PriceListFormDialog
        open={!!categoryListFormData}
        formData={categoryListFormData}
        onSubmit={onSubmitCategoryPlanDefinitionForm}
        onClose={() => {
          setCategoryListFormData(undefined);
        }}
        categoryList={categoryList}
      />
    </Paper>
  );
};

export default ServiceAdminPage;
