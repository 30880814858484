import { Box, Checkbox, Typography, createFilterOptions } from '@mui/material';
import { FieldPath, FieldValues } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { API_SERVER } from 'modules/common/api';
import { isEqual, keyBy, trim } from 'lodash';
import { useFetch } from 'modules/common/hook';
import AutocompleteInput, { AutocompleteInputProps } from 'modules/common/component/form/AutocompleteInput';
import { Medication } from 'modules/schema';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { MEDICATION_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { Option } from 'modules/common/type';
import { renderLabelSearch } from '../../const';

type MedicationType = Medication & { categoryName: string; value: string | undefined };
const filter = createFilterOptions<MedicationType>();

type Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  onAddPrescriptionDetail: (option: MedicationType[]) => void;
  medicationCategory?: Option | null;
  loadingPrescription: boolean;
} & Omit<
  AutocompleteInputProps<MedicationType, true, undefined, undefined, TFieldValues, TName>,
  'options' | 'getValue' | 'getLabel' | 'renderLabel' | 'filterOptions'
>;

const PatientPrescriptionInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  props: Props<TFieldValues, TName>,
) => {
  const { intl } = useGeneralHook();
  const { data: medicationCategories } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({
      scope: MEDICATION_CATEGORY_SCOPE.INVENTORY,
      isShowQuantityInventory: true,
    }),
  );
  const [medicationData, setMedicationData] = useState<MedicationType[]>([]);
  const medicationMap = useMemo(() => {
    return keyBy(
      medicationCategories?.flatMap(
        (category) =>
          category.medicationList?.map((medication) => ({
            ...medication,
            categoryName: category.name!,
            quantity: medication.quantity,
            value: medication.name,
          })) || [],
      ) || [],
      'id',
    );
  }, [medicationCategories]);

  useEffect(() => {
    if (!props.loadingPrescription) {
      setMedicationData(
        props.medicationCategory
          ? Object.values(medicationMap).filter((item) => item.categoryName === props?.medicationCategory?.value)
          : Object.values(medicationMap),
      );
    }
  }, [medicationMap, props.loadingPrescription, props.medicationCategory]);

  return (
    <AutocompleteInput
      {...props}
      key="medication"
      form={props.form}
      options={medicationData}
      getLabel={(option) => renderLabelSearch(option.name, option.ingredient, option.dose)}
      getValue="id"
      placeholder="pharmacy.chooseDrug"
      label="encounterPrescription.addSingleMedicationRequest"
      renderLabel={(option) => {
        return (
          <Box sx={{ width: '100%', padding: '4px' }}>
            <Typography>
              {medicationData.some((item) => isEqual(item, option)) && (
                <Checkbox checked={props.form.getValues(props.name)?.indexOf(option) !== -1} />
              )}
              {option.ingredient ? renderLabelSearch(option.name, option.ingredient, option.dose) : option?.name}
            </Typography>
          </Box>
        );
      }}
      autoComplete
      handleHomeEndKeys
      autoHighlight
      filterOptions={(options, params) => {
        let filtered = filter(options, params);
        const inputValue = trim(params.inputValue);
        if (inputValue !== '') {
          const isExisting = filtered.some((option) => option.name === inputValue);
          if (!isExisting && !options.some((option) => option.id === -1)) {
            filtered.push({
              id: -1,
              name: `${intl.formatMessage({ id: 'dental.prescription.label.addExtraMedicine' })} "${inputValue}"`,
              categoryName: '',
              value: params.inputValue,
            });
          }
        }
        return filtered;
      }}
      onBlur={async (event) => {
        const option = props.form.getValues(props.name);
        if (Array.isArray(option) && option.length > 0) {
          await props.onAddPrescriptionDetail(option);
        }
        if (props.onBlur) {
          props.onBlur(event);
        }
      }}
      // showCheck
      multiple
      disableCloseOnSelect
      renderTags={() => null}
    />
  );
};

export default PatientPrescriptionInput;
