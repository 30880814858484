import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import TablePaginationActionsCustom from 'modules/common/component/TableCustom/TablePaginationActionsCustom';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, some } from 'modules/common/constants';
import { PAYMENT_STATUS } from 'modules/reception/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import { THERAPY_SESSION_STATUS, THERAPY_STATUS } from 'modules/reception/constants';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import { useMemo } from 'react';
import './style.scss';
import { Therapy, TherapySession, TherapySessionSchema } from 'modules/schema';
import { Page } from 'modules/schema/common';
import { axiosThunk } from 'modules/common/redux/axios';
import { API_SERVER } from 'modules/common/api';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from 'redux/reducer';
import { AnyAction } from 'redux';
import { z } from 'zod';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';

const Row: React.FunctionComponent<{
  row: some;
  onUpdate: (value) => void;
  onDelete: (value) => void;
  onShowReason: (value) => void;
  onDeleteSession: (value) => void;
  index: number;
  dispatch: ThunkDispatch<AppState, null, AnyAction>;
}> = (props) => {
  const { row, onUpdate, onDelete, onShowReason, onDeleteSession, dispatch } = props;
  const [paymentDetailData, setPaymentDetailData] = useState<TherapySession[]>([]);
  const [open, setOpen] = React.useState(false);
  const status = THERAPY_STATUS.find((v) => v.value === row.status);
  const paymentStatus = PAYMENT_STATUS.find((v) => v.value === row?.payment?.status);

  const nextSession = useMemo(() => {
    const nextSessionLst = paymentDetailData
      ?.filter((t) => t.status !== 'FINISHED' && t.status !== 'CANCELLED')
      .map((t) => moment(t.startTime, BE_DATE_TIME_FORMAT).valueOf())
      ?.filter((v) => v && v >= moment().startOf('day').valueOf());
    if (nextSessionLst && nextSessionLst.length > 0) {
      return Math.min.apply(Math, nextSessionLst);
    }
    return null;
  }, [paymentDetailData]);

  // Get data therapy session payment detail
  const getPaymentDetail = async () => {
    const { data } = await dispatch(
      axiosThunk({
        url: API_SERVER.therapySession.paymentDetail(row?.id).url,
      }),
    );
    const parseData = z.array(TherapySessionSchema).parse(data);
    setPaymentDetailData(parseData);
    setOpen(!open);
  };

  const dataDiagnosis = React.useMemo(() => {
    return row.diagnosis ? extractHTMLContent(row.diagnosis) : '';
  }, [row]);

  return (
    <>
      <TableCustomRow>
        <TableCustomCell>
          <IconButton aria-label="expand row" size="small" onClick={getPaymentDetail}>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            <Typography sx={{ fontWeight: '500' }}>{row.patient?.code?.split('-')?.[1]}</Typography>
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            {row.patient?.name}
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography sx={{ fontWeight: '500' }}>{row.patient?.mobilePhone}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Tooltip title={dataDiagnosis || ''}>
            <Typography
              sx={{
                fontWeight: '500',
                maxWidth: '120px',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              }}
            >
              {dataDiagnosis}
            </Typography>
          </Tooltip>
        </TableCustomCell>
        <TableCustomCell>
          <Typography sx={{ fontWeight: '500' }}>{row.name}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography
            noWrap
            sx={{
              color: status?.sx || 'primary.main',
              fontWeight: '500',
            }}
          >
            {status?.label ? <FormattedMessage id={status.label || ' '} /> : ''}
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography sx={{ fontWeight: '500' }}>
            {row.payment.isPackagePaid !== false ? (
              <FormattedMessage id="report.therapy.revenue.isPackagePaid.all" />
            ) : (
              <FormattedMessage id="report.therapy.revenue.isPackagePaid.session" />
            )}
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography
            noWrap
            sx={{
              color: paymentStatus?.sx || 'primary.main',
              fontWeight: '500',
            }}
          >
            <FormattedMessage id={paymentStatus?.label || ' '} />
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography>{nextSession && moment(nextSession).format(FE_DATE_FORMAT)}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          {row.status !== 'FINISHED' && row.status !== 'CANCELLED' && (
            <IconButtonTitle
              title="encounterInfo.cancelTherapy"
              size="small"
              onClick={() => {
                onDelete(row);
              }}
            >
              <CancelIcon />
            </IconButtonTitle>
          )}
          {row.status === 'CANCELLED' && (
            <IconButtonTitle
              title="sellingProduct.label.viewDetail"
              size="small"
              onClick={() => {
                onShowReason(row);
              }}
            >
              <InfoIcon />
            </IconButtonTitle>
          )}
        </TableCustomCell>
      </TableCustomRow>
      <TableRow style={{ backgroundColor: 'white', padding: 0 }}>
        <TableCustomCell style={{ padding: '0px', border: 'none', backgroundColor: 'inherit' }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases" style={{ marginTop: 4 }}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell style={{ width: 54 }} />
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapy.label.name" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapyList.header.label.therapySession.description" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapyList.header.label.therapySession.startDate" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapyList.header.label.therapySession.picName" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="status" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="therapyList.header.label.therapySession.conclude" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="cashier.paymentStatus" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey', minWidth: 110 }}>
                    <FormattedMessage id="action" />
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {paymentDetailData
                  ?.filter((s) => s.status !== 'CANCELLED')
                  .map((record: some, index) => {
                    const sessionStatus = THERAPY_SESSION_STATUS.find((v) => v.value === record.status);
                    const sessionPaymentStatus = PAYMENT_STATUS.find((v) => v.value === record?.paymentDetail?.status);
                    return (
                      <TableRow key={index}>
                        <TableCustomCell />
                        <TableCustomCell>{record.name}</TableCustomCell>
                        <TableCustomCell style={{ wordBreak: 'break-all' }}>{record.description}</TableCustomCell>
                        <TableCustomCell>
                          {record?.startTime && moment(record?.startTime).format(FE_DATE_FORMAT)}
                        </TableCustomCell>
                        <TableCustomCell>{record.picName}</TableCustomCell>
                        <TableCustomCell>
                          <Typography
                            noWrap
                            sx={{
                              color: sessionStatus?.sx || 'primary.main',
                            }}
                          >
                            {sessionStatus?.label ? <FormattedMessage id={sessionStatus.label} /> : ' '}
                          </Typography>
                        </TableCustomCell>
                        <TableCustomCell className="conclude">{record.conclude}</TableCustomCell>
                        <TableCustomCell>
                          <Typography
                            noWrap
                            sx={{
                              color: sessionPaymentStatus?.sx || 'primary.main',
                            }}
                          >
                            <FormattedMessage id={sessionPaymentStatus?.label || ' '} />
                          </Typography>
                        </TableCustomCell>
                        <TableCustomCell>
                          {row.status !== 'FINISHED' &&
                            row.status !== 'CANCELLED' &&
                            record.status !== 'FINISHED' &&
                            record.status !== 'CANCELLED' && (
                              <>
                                <IconButtonTitle
                                  title="update"
                                  size="small"
                                  onClick={() =>
                                    onUpdate({
                                      ...record,
                                      patient: row?.patient,
                                    })
                                  }
                                  style={{ marginRight: 8 }}
                                >
                                  <EditIcon />
                                </IconButtonTitle>
                                <IconButtonTitle
                                  title="delete"
                                  size="small"
                                  onClick={() => {
                                    onDeleteSession(record);
                                  }}
                                  disabled={
                                    (row.payment.isPackagePaid !== false && row.payment.packagePaymentStatus) ||
                                    record?.paymentDetail?.status === 'PAID'
                                  }
                                >
                                  <DeleteIcon />
                                </IconButtonTitle>
                              </>
                            )}
                        </TableCustomCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCustomCell>
      </TableRow>
    </>
  );
};

interface Props {
  data?: Page<Therapy>;
  onUpdate: (value) => void;
  onDelete: (value) => void;
  onShowReason: (value) => void;
  onDeleteSession: (value) => void;
  hookPagination: HookPaginationProps;
}

const TherapyList: React.FunctionComponent<Props> = (props) => {
  const { data, hookPagination, onUpdate, onDelete, onShowReason, onDeleteSession } = props;
  const { intl, dispatch } = useGeneralHook();
  const { onPageChange, onRowsPerPageChange, pagination } = hookPagination;

  return (
    <Box
      position="relative"
      overflow="inherit"
      display="flex"
      flexDirection="column"
      height="-webkit-fill-available"
      flex={1}
    >
      <TableContainer style={{ height: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableCustomRow>
              <TableHeaderCell style={{ width: 54 }} />
              <TableHeaderCell style={{ width: 160 }}>
                <FormattedMessage id="therapy.label.customer.code.kh" />
              </TableHeaderCell>
              <TableHeaderCell width="20%">
                <FormattedMessage id="therapy.label.customer.name" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="therapyList.header.label.mobilePhone" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="therapyList.header.label.diagnosis" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="therapyList.header.label.therapyName" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="status" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="therapy.receptionist.list.label.paymentType" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="therapy.receptionist.list.label.paymentStatus" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="therapyList.header.label.nextScheduler" />
              </TableHeaderCell>
              <TableHeaderCell style={{ width: 120 }}>
                <FormattedMessage id="therapy.action" />
              </TableHeaderCell>
            </TableCustomRow>
          </TableHead>
          <TableBody>
            {data?.content && data?.content?.length > 0 ? (
              data?.content?.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  onShowReason={onShowReason}
                  onDeleteSession={onDeleteSession}
                  index={index + 1}
                  dispatch={dispatch}
                />
              ))
            ) : (
              <>
                <TableCustomRow>
                  <TableCustomCell colSpan={12}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100%',
                      }}
                    >
                      <Typography variant="inherit" color="textSecondary">
                        <FormattedMessage id="noData" />
                      </Typography>
                    </Box>
                  </TableCustomCell>
                </TableCustomRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        data-tour="step-5"
        style={{ padding: 4 }}
        labelRowsPerPage={intl.formatMessage({ id: 'labelRowPerPage' })}
        ActionsComponent={TablePaginationActionsCustom}
        count={data?.pagination?.totalElements || 0}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Box>
  );
};

export default TherapyList;
