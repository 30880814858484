import * as React from 'react';
import { API_SERVER } from '../../../../../common/api';
import { useParams } from 'react-router';
import MedicalSuppliesUsageList from './MedicalSuppliesUsageList';
import { useFetchIndicationRequest } from '../common/fetcher';
import { useDentalDetailStateSelector } from '../state';
import { useFetch } from 'modules/common/hook';

const MedicalSupply = () => {
  const { dentalSessionId } = useParams<{ dentalSessionId; patientId }>();
  const readOnly = useDentalDetailStateSelector.use.readonly();

  // get data dental medical supplies list
  const { data: medicalSuppliesUsageList, revalidate } = useFetch(
    API_SERVER.medicalSuppliesUsage.getList({ referenceId: dentalSessionId, referenceType: 'DENTAL_EXAM' }),
    { enabled: dentalSessionId },
  );

  const { data: indicationRequests } = useFetchIndicationRequest(dentalSessionId);

  return (
    <MedicalSuppliesUsageList
      onUpdateMedicalSupplies={revalidate}
      onDeleteMedicalSupplies={revalidate}
      medicalSuppliesList={medicalSuppliesUsageList}
      // indicationList={[]}
      indicationList={indicationRequests?.filter((i) => i?.status !== 'DELETED')}
      dentalSessionId={dentalSessionId}
      onSelectedMedicalSupplies={revalidate}
      readOnly={readOnly}
    />
  );
};

export default MedicalSupply;
