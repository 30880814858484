import { z } from 'zod';
import { UnitSchema } from './Unit';

export const WardSchema = z
  .object({
    code: z.string(),
    codeName: z.string(),
    name: z.string(),
    nameEn: z.string(),
    fullName: z.string(),
    fullNameEn: z.string(),
    districtCode: z.string(),
    unitId: z.number(),
    unit: UnitSchema,
  })
  .partial();

export type Ward = z.infer<typeof WardSchema>;
