import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';

export const SaleImportReportSchema = z
  .object({
    id: z.number(),
    importCode: z.string(),
    medicationName: z.string(),
    medicationCode: z.string(),
    importDate: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    importBy: z.string(),
    supplierName: z.string(),
    lot: z.string(),
    expiredDate: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    quantity: z.number(),
    unit: z.string(),
    unitPrice: z.number(),
    totalAmount: z.number(),
  })
  .partial();

export type SaleImportReport = z.infer<typeof SaleImportReportSchema>;
