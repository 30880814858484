import { z } from 'zod';

export const IndicationImageSchema = z
  .object({
    id: z.number(),
    indicationRequestId: z.number(),
    examDepartment: z.string(),
    conclusion: z.string(),
  })
  .partial();

export type IndicationImage = z.infer<typeof IndicationImageSchema>;
