import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../common/component/DialogCustom';
import SchemaForm from '../../../common/SchemaForm';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (value: { cancelReason: string }) => void;
  formData?: { cancelReason?: string; patientName?: string };
}

const CancelReasonEncounterDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData, onSubmit } = props;
  const { intl } = useGeneralHook();
  return (
    <DialogCustom
      open={open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 682 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id="cancelEncounter" />
        </Typography>
      }
    >
      <Box padding={2}>
        <Typography paddingBottom={1}>
          {intl.formatMessage({ id: 'encounter.confirmation.popup.cancel' }, { name: formData?.patientName })}
        </Typography>
        <SchemaForm
          schema={{
            fields: ({ formProps }) => {
              const { intl } = formProps || {};
              return {
                cancelReason: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'cancelReason' }),
                  placeholder: intl.formatMessage({ id: 'cancelReasonEnter' }),
                  multiline: true,
                  register: { required: true },
                  rows: 8,
                },
              };
            },
          }}
          onSubmit={onSubmit}
          formId="note-form"
          formData={formData}
          onCancel={onClose}
          submitLabel={<FormattedMessage id="accept" />}
        />
      </Box>
    </DialogCustom>
  );
};
export default CancelReasonEncounterDialog;
