import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT, DENTAL_EXAM_STATUS, DENTAL_TYPE, PAYMENT_PLAN } from '../common/constants';
import { PatientSchema } from './Patient';
import { PaymentSchema } from './Payment';

export const DentalExamSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    patientId: z.number(),
    patientType: z.string(),
    type: createObjectKeysEnumSchema(DENTAL_TYPE),
    serviceId: z.number(),
    serviceName: z.string(),
    picUserId: z.number(),
    picName: z.string(),
    price: z.number(),
    paymentPlan: createObjectKeysEnumSchema(PAYMENT_PLAN),
    dentalExamStatus: z.string(),
    registerReason: z.string(),
    physicsRoomId: z.string(),
    physicsRoomName: z.string(),
    note: z.string(),
    groupId: z.number(),
    patient: PatientSchema,
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    endTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    status: createObjectKeysEnumSchema(DENTAL_EXAM_STATUS),
    payment: PaymentSchema,
    indicationRequestQuantity: z.number(),
    indicationRequestFinishedQuantity: z.number(),
    sequenceNumber: z.string(),
  })
  .partial();

export type DentalExam = z.infer<typeof DentalExamSchema>;
