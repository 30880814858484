import LocalizationProvider from '@mui/lab/LocalizationProvider';
import { Backdrop, CircularProgress, ThemeProvider } from '@mui/material';
import enLocale from 'date-fns/locale/en-US';
import idLocale from 'date-fns/locale/id';
import viLocale from 'date-fns/locale/vi';
import 'moment/locale/es';
import 'moment/locale/vi';
import React, { useEffect, useRef } from 'react';
import { Redirect, Switch, useLocation } from 'react-router';
import LoginPage from './modules/authen/page/LoginPage';
import ConfirmDialogProvider from './modules/common/component/ConfirmDialogProvider';
import CustomAdapter from './modules/common/component/CustomDateAdapter';
import FetchErrorDialog from './modules/common/component/FetchErrorDialog';
import LoadingIcon from './modules/common/component/LoadingIcon';
import NotifyDialog from './modules/common/component/NotifyDialog';
import ProtectedRoute from './modules/common/component/ProtectedRoute';
import RedirectRoute from './modules/common/component/RedirectRoute';
import ScrollToTop from './modules/common/component/ScrollToTop';
import SnackbarCustom from './modules/common/component/SnackbarCustom';
import useGeneralHook from './modules/common/hook/useGeneralHook';
import HomePage from './modules/common/page/HomePage';
import { setLoading, setNetworkError } from './modules/common/redux/commonReducer';
import DefaultLayout from './modules/layout/DefaultLayout';
import { ROUTES } from './modules/layout/router';
import './scss/svg.scss';
import { MUITheme } from './setupTheme';
import ResetPasswordPage from './modules/authen/page/ResetPasswordPage';
import CacheBuster from './CacheBuster';
import { SWRConfig } from 'swr';
import ReactGA from 'react-ga';
import AppointmentBooking from 'modules/authen/page/AppointmentBooking';

ReactGA.initialize('G-8LJJPFBYZL');

export const localeMap = {
  'vi-VN': viLocale,
  'en-US': enLocale,
  'id-ID': idLocale,
};

interface Props {}

const App: React.FC<Props> = () => {
  const { dispatch, appState } = useGeneralHook();
  const authen = appState.authen.authen;
  const loading = appState.common.loading;
  const locale = appState.intl.locale;
  const location = useLocation();
  const prevLoading = useRef(loading);

  useEffect(() => {
    if (appState.authen.user?.group_id) {
      ReactGA.set({ userId: appState.authen.user?.group_id });
    }
  }, [appState.authen.user?.group_id]);

  React.useEffect(() => {
    dispatch(setNetworkError('', false));
    if (prevLoading.current) {
      dispatch(setLoading(false));
    }
  }, [dispatch, location.pathname]);

  React.useEffect(() => {
    prevLoading.current = loading;
  }, [loading]);

  return (
    <CacheBuster>
      <ThemeProvider theme={MUITheme}>
        <SWRConfig
          value={{
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            onError: (error, key) => {
              console.error(`Fetch error with key: ` + key);
              console.error(`Error: ` + error);
            },
          }}
        >
          <ConfirmDialogProvider>
            <LocalizationProvider dateAdapter={CustomAdapter as any} locale={localeMap[locale]}>
              <ScrollToTop>
                <React.Suspense fallback={<LoadingIcon />}>
                  <Switch>
                    <RedirectRoute auth={authen} path={ROUTES.login} component={LoginPage} />
                    <RedirectRoute auth={authen} path={ROUTES.appointmentBooking} component={AppointmentBooking} />
                    <RedirectRoute auth={authen} path={ROUTES.resetPassword} component={ResetPasswordPage} />
                    <ProtectedRoute auth={authen} path={ROUTES.main} component={DefaultLayout} />
                    <ProtectedRoute auth={authen} path="/" component={HomePage} />
                    <Redirect to={ROUTES.login} />
                  </Switch>
                </React.Suspense>
              </ScrollToTop>
              <FetchErrorDialog />
              <Backdrop unmountOnExit style={{ zIndex: 9000 }} open={loading}>
                <div style={{ color: 'white' }}>
                  <CircularProgress variant="indeterminate" color="inherit" />
                </div>
              </Backdrop>
              <SnackbarCustom />
              <NotifyDialog />
            </LocalizationProvider>
          </ConfirmDialogProvider>
        </SWRConfig>
      </ThemeProvider>
    </CacheBuster>
  );
};

export default App;
