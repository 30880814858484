import { useMemo, useRef, useState } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import Header from './Header';
import Body from './Body';
import DialogAction from './DialogAction';
import {
  INDICATION_REQUEST_LIST_PRINT_TYPE,
  LOCAL_STORAGE_INDICATION_LIST_COMPACT_MODE,
  LOCAL_STORAGE_INDICATION_LIST_PRINT_TYPE,
} from './constant';
import useSWR from 'swr';
import { API_SERVER } from '../../../../common/api';
import { axiosThunk } from '../../../../common/redux/axios';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import LoadingIcon from '../../../../common/component/LoadingIcon';
import { styled } from '@mui/system';
import { Encounter, IndicationLaboratoryListSchema, IndicationRequest } from 'modules/schema';
import { useFetch } from 'modules/common/hook';
import { INDICATION_REQUEST_STATUS, INDICATION_REQUEST_TYPE } from 'modules/common/constants';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';

interface Props {
  onClose: () => void;
  encounter: Encounter;
  selectedIndications: IndicationRequest[] | undefined;
}

const DividerStyled = styled(Divider)({
  marginTop: '30px',
  marginBottom: '15px',
  '@media print': {
    display: 'none',
  },
});

const PrintDialogContent = ({ onClose, encounter, selectedIndications }: Props) => {
  const printContentRef = useRef(null);
  const [printType, setPrintType] = useState<INDICATION_REQUEST_LIST_PRINT_TYPE>(
    (sessionStorage.getItem(LOCAL_STORAGE_INDICATION_LIST_PRINT_TYPE) as INDICATION_REQUEST_LIST_PRINT_TYPE) || 'GROUP',
  );
  const [compactMode, setCompactMode] = useState(
    Boolean(sessionStorage.getItem(LOCAL_STORAGE_INDICATION_LIST_COMPACT_MODE)) || false,
  );
  const { dispatch } = useGeneralHook();
  const { data: indicationRequests, isValidating: isIndicationValidating } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(
      encounter?.id!,
      INDICATION_REQUEST_TYPE.ENCOUNTER,
      SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
    ),
  );
  const printIndications = useMemo(() => {
    if (selectedIndications) {
      return indicationRequests?.filter((ele) => selectedIndications.some((selectedEle) => selectedEle.id === ele.id));
    }
    return indicationRequests?.filter((ele) => ele.status === INDICATION_REQUEST_STATUS.FINISHED.value);
  }, [indicationRequests, selectedIndications]);

  const { data: indicationLabs, isValidating: loadingIndicationLabs } = useSWR(
    printIndications && printIndications.length > 0 ? printIndications?.map((ele) => ele.id) : null,
    async (...indicationIds) => {
      const data = await Promise.all(
        indicationIds.map((id) => dispatch(axiosThunk({ url: API_SERVER.indicationLaboratory.getList(id).url }))),
      );
      return data?.map((ele) => IndicationLaboratoryListSchema.parse(ele?.data));
    },
  );

  if (!encounter || loadingIndicationLabs || !indicationLabs || isIndicationValidating)
    return (
      <Stack sx={{ height: '800px' }} justifyContent="center" alignItems="center">
        <LoadingIcon />
      </Stack>
    );

  return (
    <Box paddingTop="̣9px">
      <Box ref={printContentRef}>
        {printType === 'GROUP' ? (
          <Box sx={{ pageBreakBefore: 'always' }}>
            <Header />
            <Body encounter={encounter} indicationLabs={indicationLabs} compact={compactMode} />
          </Box>
        ) : (
          printIndications?.map((_, index) => (
            <Box sx={{ pageBreakBefore: 'always' }}>
              <Header />
              <Body encounter={encounter} indicationLabs={[indicationLabs[index]]} compact={compactMode} />
              <DividerStyled />
            </Box>
          ))
        )}
      </Box>
      <DialogAction
        onClose={onClose}
        printContentRef={printContentRef}
        printType={printType}
        onPrintTypeChange={setPrintType}
        compactMode={compactMode}
        onCompactModeChange={setCompactMode}
      />
    </Box>
  );
};

export default PrintDialogContent;
