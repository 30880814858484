import { Box, Checkbox } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { push } from 'connected-react-router';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { FE_DATE_FORMAT, INDICATION_REQUEST_STATUS, some } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { ROUTES } from '../../../layout/router';
import { useMemo } from 'react';
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    '& td,th': {
      padding: 8,
    },
  },
  select: {
    '& .MuiSelect-selectMenu': {
      whiteSpace: 'normal',
    },
  },
});

interface Props {
  data?: some[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
  selectionIndication: some[];
  setSelectionIndication: (value: some[]) => void;
}

const COLUMNS: Columns[] = [
  {
    title: 'therapy.code',
    dataIndex: 'referenceCode',
    align: 'right',
  },
  {
    title: 'therapy.label.customer.code.kh',
    dataIndex: 'patientCode',
  },
  {
    title: 'therapy.label.customer.name',
    dataIndex: 'patientName',
  },
  {
    title: 'birthdayShort',
    dataIndex: 'dob',
  },
  {
    title: 'labDoctor.testName',
    dataIndex: 'serviceName',
  },
  {
    title: 'indicationDate',
    dataIndex: 'createdTime',
  },
  {
    title: 'doctorOfIndication',
    dataIndex: 'picName',
  },
  {
    title: 'interpreterOfIndication',
    dataIndex: 'executeName',
  },
  {
    title: 'status',
    dataIndex: 'status',
    render: (record) => {
      return (
        <Box component="span" color={INDICATION_REQUEST_STATUS?.[record.status]?.color}>
          {INDICATION_REQUEST_STATUS?.[record.status]?.label && (
            <FormattedMessage id={INDICATION_REQUEST_STATUS?.[record.status]?.label} />
          )}
        </Box>
      );
    },
  },
];

const IndicationTherapyList: React.FunctionComponent<Props> = ({
  data,
  loading,
  hookPagination,
  total,
  selectionIndication,
  setSelectionIndication,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;

  const tableDataMapped = useMemo(() => {
    return data?.map((ele) => ({
      ...ele,
      createdTime: moment(ele?.createdTime).format(FE_DATE_FORMAT),
      dob: moment(ele?.dob).format(FE_DATE_FORMAT),
      patientCode: ele?.patientCode?.split('-')?.[1] || '',
    })) as some[];
  }, [data]);

  return (
    <>
      <TableCustom
        containerProps={{
          className: classes.table,
        }}
        dataSource={tableDataMapped}
        loading={loading}
        columns={COLUMNS}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: (...val) => {
            onPageChange(...val);
            setSelectionIndication([]);
          },
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        rowProps={(record) => ({
          onClick: async (e) => {
            dispatch(push(ROUTES.detailIndicationTherapy.gen(record?.id, record?.therapyId)));
          },
          style: { cursor: 'pointer' },
        })}
        rowSelection={{
          selectedRowKeys: selectionIndication,
          hidden: (record: some) =>
            record.status !== INDICATION_REQUEST_STATUS.FINISHED.value ||
            (selectionIndication?.[0] ? selectionIndication?.[0]?.therapyId !== record.refernce : false),
          onChange: ({ selectedRows }) => setSelectionIndication(selectedRows),
          disableSelectAll: selectionIndication.length === 0,
          render: ({ record, selected, onChange }) =>
            record.status === INDICATION_REQUEST_STATUS.FINISHED.value && (
              <Checkbox
                disabled={selectionIndication?.[0] ? selectionIndication?.[0]?.therapyId !== record.therapyId : false}
                checked={selected}
                color="primary"
                onChange={(e) => onChange(e.target.checked)}
              />
            ),
        }}
      />
    </>
  );
};

export default IndicationTherapyList;
