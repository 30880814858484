import { Box, Typography } from '@mui/material';
import { Moment } from 'moment';
import React, { CSSProperties } from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  time: Moment;
  color?: CSSProperties['color'];
}
const Calendar: React.FunctionComponent<Props> = ({ time, color = '#5794FF' }) => {
  return (
    <Box
      border={`1px solid`}
      borderColor={color}
      textAlign={'center'}
      borderRadius="3px"
      width="85px"
      boxSizing="border-box"
    >
      <Typography color={'#fff'} sx={{ backgroundColor: color }}>
        {time?.year()}
      </Typography>
      <Box padding={'5px'}>
        <Typography fontSize={'24px'}>{time?.date()}</Typography>
        <Typography fontSize={'12px'} fontWeight={500}>
          <FormattedMessage id="filterLabelMonth" /> {time?.format('M')}
        </Typography>
      </Box>
    </Box>
  );
};

export default Calendar;
