import { Box } from '@mui/material';
import { DateRangePicker, StaticDateRangePickerProps } from '@mui/lab';
import moment from 'moment';
import React, { memo, useEffect, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import TextFieldElement from '../text-field/TextFieldElement';

export interface PropsDateRangePickerElement extends Omit<StaticDateRangePickerProps, 'defaultValue' | 'renderInput'> {
  required?: boolean;
  error?: boolean;
  isSubmitting?: boolean;
  name?: string;
  spacing?: number;
}

interface Props extends PropsDateRangePickerElement {}

const DateRangePickerElement = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { name, error, isSubmitting, required, value = [null, null], spacing, ...rest } = props;

  const id = useMemo(() => {
    return `${name}_${moment().valueOf()}_${Math.random()}`;
  }, [name]);

  useEffect(() => {
    const ele = document.getElementById(id);
    if (ele && !!error && isSubmitting) {
      ele?.focus();
    }
  }, [id, error, isSubmitting]);

  return (
    <DateRangePicker
      mask="__/__/____"
      inputFormat="dd/MM/yyyy"
      startText={<FormattedMessage id="startDate" />}
      endText={<FormattedMessage id="endDate" />}
      value={value || [null, null]}
      renderInput={(startProps, endProps) => (
        <React.Fragment>
          <TextFieldElement {...startProps} required={required} inputRef={ref} />
          <Box sx={{ marginRight: spacing || 2 }} />
          <TextFieldElement {...endProps} required={required} />
        </React.Fragment>
      )}
      {...rest}
    />
  );
});
export default memo(DateRangePickerElement);
