import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { chain } from 'lodash';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import DialogCustom from 'modules/common/component/DialogCustom';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, FE_TIME_DATE_FORMAT, PAYMENT_PLAN, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { Encounter, IndicationRequest } from 'modules/schema';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { EncounterContext } from '../../../const';
import { NumericFormatText } from 'modules/common/utils';

const COMPACT_TYPE = 'INDICATION_PRINT_COMPACT_TYPE';
const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      '& .newPage': { pageBreakBefore: 'always' },
      '& .diver-page': { display: 'none' },
      padding: 0,
      '& th,tr,td': {
        backgroundColor: 'unset',
      },
      '& .titleForm': {
        fontSize: '24px',
        lineHeight: '20px',
      },
    },
  },
});

const titlePrint = {
  LABORATORY_SERVICE: 'printForm.subClinicalRequestForm',
  RADIOLOGY_SERVICE: 'printForm.subClinicalRequestForm',
  SURGICAL_SERVICE: 'printForm.requestFormProduce',
  OTHER_SERVICE: 'printForm.requestOtherService',
};

export interface PrintIndicationEncounterProps {
  encounterData: Encounter;
  serviceData: some;
  compact: boolean;
  onlyContent?: boolean;
  isImageTest: boolean;
}

interface PrintIndicationProps {
  open: boolean;
  onClose?: () => void;
  groupedServices: IndicationRequest[];
}

export const PrintIndicationEncounter = React.forwardRef((props: PrintIndicationEncounterProps, ref) => {
  const { encounterData, serviceData, compact, onlyContent, isImageTest } = props;
  const { appState } = useGeneralHook();
  const { currentLocation } = appState.authen;
  const { intl } = useGeneralHook();

  React.useEffect(() => {
    if (!onlyContent) {
      const css = `@page { size: ${1000 * 0.75}px  ${1290 * 0.75}px; margin: 0m 0.6cm; }`;
      const head = document.head || document.getElementsByTagName('head')[0];
      const style = document.createElement('style');
      style.media = 'print';
      style.appendChild(document.createTextNode(css));
      head.appendChild(style);
      return () => {
        head.removeChild(style);
      };
    }
  }, [onlyContent]);

  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
            <FormattedMessage id={intl.formatMessage({ id: titlePrint[serviceData?.scope || ''] })} />
          </Typography>
        </HeaderPrintForm>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="patientCode" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{encounterData?.patient?.formatCode}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {encounterData?.patient?.name}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="telephone" />
              </Typography>
              :&nbsp; <Typography variant="body1">{encounterData?.patient?.mobilePhone}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="category" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                <FormattedMessage
                  id={intl.formatMessage({ id: PAYMENT_PLAN[encounterData?.paymentPlan || '']?.label })}
                />
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{encounterData?.patient?.homeAddress?.address}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {moment(encounterData?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 160 }}>
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {encounterData?.patient?.gender && <FormattedMessage id={encounterData?.patient?.gender} />}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [encounterData, intl, serviceData?.scope]);

  const encounterInfo = React.useMemo(() => {
    return (
      <Box>
        <Box display="flex">
          <Typography variant="subtitle1" style={{ minWidth: 160, flexShrink: 0 }}>
            <FormattedMessage id="printForm.placeOfIndication" />
          </Typography>
          :&nbsp;
          <Typography variant="body1">{encounterData.physicsRoom?.name}</Typography>
        </Box>
        <Box display="flex">
          <Typography variant="subtitle1" style={{ minWidth: 160, flexShrink: 0 }}>
            <FormattedMessage id="encounter.encounterDetail.block.title.clinicalSymptoms" />
          </Typography>
          :&nbsp;
          <Typography variant="body1" component="span">
            {encounterData?.clinicalExamination && extractHTMLContent(encounterData?.clinicalExamination)}
          </Typography>
        </Box>
        {!isImageTest && (
          <Box display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 160, flexShrink: 0 }}>
              <FormattedMessage id="referenceIndication.specimenNumber" />
            </Typography>
            :&nbsp;
          </Box>
        )}
        <Box display="flex">
          <Typography variant="subtitle1" style={{ minWidth: 160, flexShrink: 0 }}>
            <FormattedMessage id="timeReceive" />
          </Typography>
          :&nbsp;{' '}
          <Typography variant="body1">
            {encounterData.startTime && encounterData.startTime.format(FE_TIME_DATE_FORMAT)}
          </Typography>
        </Box>
      </Box>
    );
  }, [encounterData, isImageTest]);

  const testInfo = React.useMemo(() => {
    const columns: Columns[] = [
      {
        title: (
          <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
            <FormattedMessage id="stt" />
          </Typography>
        ),
        align: 'center',
        dataIndex: 'index',
        width: '1%',
        render: (_, index) => <Typography sx={{ fontSize: '14px' }}>{+index}</Typography>,
      },
      {
        title: (
          <Typography variant="subtitle1" sx={{ fontSize: '14px' }}>
            <FormattedMessage id="printForm.contentOfIndication" />
          </Typography>
        ),
        dataIndex: 'serviceName',
        width: '17%',
        render: (record) => (
          <Typography
            sx={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word', fontSize: '14px' }}
          >
            {record?.serviceName}
          </Typography>
        ),
      },

      {
        title: (
          <Typography sx={{ fontSize: '14px' }} variant="subtitle1">
            <FormattedMessage id="printForm.executionPlace" />
          </Typography>
        ),
        dataIndex: 'physicsRoomName',
        width: '15%',
        render: (record) => (
          <Typography
            sx={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word', fontSize: '14px' }}
          >
            {record?.physicsRoomName}
          </Typography>
        ),
      },
      {
        title: (
          <Typography sx={{ fontSize: '14px' }} variant="subtitle1">
            <FormattedMessage id="doctorOfIndication" />
          </Typography>
        ),
        dataIndex: 'picName',
        width: '15%',
        render: (record) => (
          <Typography
            sx={{ whiteSpace: 'normal', wordBreak: 'break-word', overflowWrap: 'break-word', fontSize: '14px' }}
          >
            {record?.picName}
          </Typography>
        ),
      },
      {
        hidden: isImageTest,
        title: (
          <Typography sx={{ fontSize: '14px' }} variant="subtitle1">
            <FormattedMessage id="printForm.samplingTime" />
          </Typography>
        ),
        width: '10%',
      },
      {
        hidden: isImageTest,
        title: (
          <Typography sx={{ fontSize: '14px' }} variant="subtitle1">
            <FormattedMessage id="printForm.sampler" />
          </Typography>
        ),
        width: '10%',
      },
      {
        title: (
          <Typography sx={{ fontSize: '14px' }} variant="subtitle1">
            <FormattedMessage id="printForm.note" />
          </Typography>
        ),
        dataIndex: 'note',
        width: '17%',
        render: (record) => <Typography sx={{ fontSize: '14px' }}>{record?.note}</Typography>,
      },
      {
        title: (
          <Typography sx={{ fontSize: '14px' }} variant="subtitle1">
            <FormattedMessage id="exam.price" />
          </Typography>
        ),
        render: (record) => (
          <Typography sx={{ fontSize: '14px', wordBreak: 'keep-all' }}>{NumericFormatText(record?.price)}</Typography>
        ),
        width: '20%',
      },
    ];
    return (
      <>
        <Typography variant="subtitle1" marginBottom={1}>
          <FormattedMessage id="printForm.test" />
          :&nbsp;
          {compact && serviceData.systemCategoryName !== 'undefined' ? (
            <FormattedMessage id={SYSTEM_CATEGORY_SCOPE[serviceData.systemCategoryName].label} />
          ) : (
            serviceData.systemCategoryName || ''
          )}
        </Typography>
        {serviceData?.items?.length > 0 ? (
          <TableCustom
            containerProps={{
              sx: {
                '& th,tr,td': {
                  padding: '2px 8px',
                  backgroundColor: 'white',
                  border: '0.5px solid',
                  color: 'unset',
                },
                overflow: 'visible',
              },
            }}
            dataSource={serviceData?.items}
            hideColumnIndex
            columns={columns}
          />
        ) : (
          <Typography variant="body1">
            <FormattedMessage id="printForm.noIndication" />
          </Typography>
        )}
      </>
    );
  }, [compact, isImageTest, serviceData?.items, serviceData.systemCategoryName]);

  if (!currentLocation) {
    return null;
  }
  if (onlyContent) {
    return (
      <>
        <Typography variant="h4" style={{ textTransform: 'uppercase', textAlign: 'center' }}>
          <FormattedMessage id="printForm.subClinicalRequestForm" />
        </Typography>{' '}
        {testInfo}
      </>
    );
  }
  return (
    <>
      <Box>
        {header}
        <Box paddingY={1} style={{ clear: 'both' }}>
          <Divider />
        </Box>
        {encounterInfo}
        {testInfo}
      </Box>
    </>
  );
});

const PrintIndicationDialog = (props: PrintIndicationProps) => {
  const { onClose, open, groupedServices } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();

  const [compact, setCompact] = React.useState(localStorage.getItem(COMPACT_TYPE) === 'compact' || false);

  const encounterContext = React.useContext(EncounterContext);
  // When the dialog is open check counter > 0 then call the API get encounter data and reset the counter.
  React.useEffect(() => {
    if (encounterContext?.counter! > 0 && open) {
      encounterContext?.revalidateEncounter();
      encounterContext?.setCounter(0);
    }
  }, [encounterContext, open]);

  const groupedServicesMapping = React.useMemo(() => {
    return chain(groupedServices)
      .groupBy(compact ? 'scope' : 'systemCategoryId')
      .map((value, key) => {
        return {
          systemCategoryName: compact ? value[0].scope : value[0].systemCategoryName,
          items: value,
          isImageTest: value[0].scope !== SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
          scope: value[0].scope,
        };
      })
      .value();
  }, [compact, groupedServices]);

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: '80vw', minWidth: '60vw' },
      }}
      title={'printForm.requestForm'}
      footer={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={compact}
                onChange={(e) => {
                  setCompact(e.target.checked);
                  localStorage.setItem(COMPACT_TYPE, e.target.checked ? 'compact' : 'expansion');
                }}
              />
            }
            label={<FormattedMessage id="printCompact" />}
          />
          <Box display="flex" alignItems="center">
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                  <FormattedMessage id="printForm.printRequired" />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Box>
        </Box>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        {groupedServicesMapping?.map((item: some, index) => {
          return (
            <Box>
              <Box
                className={index ? 'newPage' : ''}
                key={index}
                sx={{
                  position: 'relative',
                  display: 'block',
                  minHeight: '100%',
                  '& table': { pageBreakInside: 'auto', pageBreakAfter: 'auto' },
                  '& tr': { pageBreakInside: 'avoid', pageBreakAfter: 'auto' },
                  '& td': { pageBreakInside: 'avoid', pageBreakAfter: 'auto' },
                }}
              >
                <table key={index} style={{ width: '100%' }}>
                  <tbody key={index}>
                    <PrintIndicationEncounter
                      key={index}
                      serviceData={item}
                      compact={compact}
                      {...props}
                      encounterData={encounterContext?.encounter!}
                      isImageTest={item.isImageTest}
                    />
                  </tbody>
                </table>
                <Box style={{ marginTop: 12, breakInside: 'avoid' }}>
                  <Grid container>
                    <Grid item xs={6} />
                    <Grid item xs={6} textAlign="center">
                      <Typography variant="body1" fontStyle="italic">
                        <FormattedMessage
                          id={'printForm.date'}
                          values={{
                            hour: moment().hour(),
                            min: moment().minute(),
                            day: moment().date(),
                            month: moment().month() + 1,
                            year: moment().year(),
                          }}
                        />
                      </Typography>
                      <Typography variant="h6">
                        <FormattedMessage id="examDoctor" />
                      </Typography>
                      <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                        <FormattedMessage id="medicalPrint.helperText" />
                      </Typography>
                      <Typography variant="body1">{encounterContext?.encounter?.picName}</Typography>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              {index !== groupedServicesMapping.length - 1 && <Divider className="diver-page" sx={{ marginY: 4 }} />}
            </Box>
          );
        })}
      </Box>
    </DialogCustom>
  );
};

export default PrintIndicationDialog;
