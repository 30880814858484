import { Add } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import DocumentBox from './DocumentBox';
import { isEmpty } from 'lodash';

interface Props {
  uploadFileToDoc: (files: FileList) => void;
  deleteFile: (docRef: some, index: number) => void;
  dataStorageResources: any;
  therapy: some;
}
const DocumentCard = (props: Props) => {
  const { uploadFileToDoc, deleteFile, therapy } = props;
  const { dataStorageResources } = props;
  const [tick, setTick] = useState(false);

  const { openNotify } = useGeneralHook();

  return (
    <>
      <Box style={{ gap: 16, display: 'flex', flexDirection: 'column' }}>
        <Box>
          {!isEmpty(dataStorageResources) &&
            dataStorageResources.map((v: any, index: number) => (
              <DocumentBox key={index} data={v} onDeleteFile={() => deleteFile(v, index)} />
            ))}
        </Box>
        {therapy?.status !== 'CANCELLED' && (
          <Button startIcon={<Add />} component="label" variant="text">
            <FormattedMessage id="attachedDocument.addFile" />
            <input
              key={`${tick}`}
              accept={'.doc,.docx,application/pdf,image/*'}
              hidden
              type="file"
              multiple
              onChange={(event) => {
                const files = event.target.files;
                if (files && files?.length > 0) {
                  if (Array.from(files).every((file) => file.size <= 5000000)) {
                    uploadFileToDoc(files);
                  } else {
                    openNotify({ message: 'attachedDocument.fileValidSize' });
                  }
                }
                setTick((old) => !old);
              }}
            />
          </Button>
        )}
      </Box>
    </>
  );
};
export default DocumentCard;
