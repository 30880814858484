import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE, PAYMENT_PLAN } from 'modules/common/constants';
import TextValue from './TextValue';
import { useFetchDentalExam } from '../../common/fetcher';
import { useParams } from 'react-router';

const PatientInfo = () => {
  const { dentalSessionId } = useParams<{ dentalSessionId }>();
  const { data: dentalExam } = useFetchDentalExam(dentalSessionId);
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box flex={1.5}>
        <TextValue
          label="therapy.label.customer.code"
          value={dentalExam?.patient?.code?.split('-')[1] || ''}
          labelWidth="150px"
        />
        <TextValue label="name" value={dentalExam?.patient?.name || ''} labelWidth="150px" />
        <TextValue label="telephone" value={dentalExam?.patient?.mobilePhone || ''} labelWidth="150px" />
        <TextValue
          label="category"
          value={PAYMENT_PLAN[dentalExam?.paymentPlan!].label}
          localizationValue
          labelWidth="150px"
        />
      </Box>
      <Box flex={1}>
        <TextValue
          labelWidth="100px"
          label="address"
          value={
            dentalExam?.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address ||
            ''
          }
        />
        <TextValue
          label="birthdayShort"
          value={moment(dentalExam?.patient?.dob, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          labelWidth="100px"
        />
        <TextValue
          label="gender"
          value={dentalExam?.patient?.gender?.toLowerCase() || ''}
          localizationValue
          labelWidth="100px"
        />
      </Box>
    </Stack>
  );
};

export default PatientInfo;
