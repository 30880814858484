import { Tooltip, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from '../../common/component/TableCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { HookPaginationProps } from '../../common/hook/usePaginationHook';
import { Encounter } from 'modules/schema';
import { ENCOUNTER_STATUS, PATIENT_TYPE } from 'modules/common/apiConstants';
import moment from 'moment';
import { useHistory } from 'react-router';
import { ROUTES } from 'modules/layout/router';

interface IEncounterListProps {
  encounters?: Encounter[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
}

const EncounterList: React.FunctionComponent<IEncounterListProps> = ({
  encounters,
  loading,
  hookPagination,
  total,
}) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const { confirmDialog } = useGeneralHook();
  const history = useHistory();
  const columns = React.useMemo(() => {
    return [
      {
        title: 'patientCodeShort',
        render: (record) => {
          return <Typography>{record?.patient?.formatCode}</Typography>;
        },
        align: 'left',
      },
      {
        title: 'patientName',
        render: (record) => {
          return <Typography>{record?.patient?.name}</Typography>;
        },
      },
      {
        title: 'birthdayShort',
        render: (record) => {
          return <Typography>{moment(record?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>;
        },
        width: 120,
      },
      {
        title: 'telephone',
        render: (record) => {
          return <Typography>{record?.patient?.mobilePhone}</Typography>;
        },
      },
      {
        title: 'encounterDate',
        dataIndex: 'date',
        render: (record) => {
          return <Typography>{record?.createdTime?.format(FE_DATE_FORMAT)}</Typography>;
        },
      },
      {
        title: 'encounterGroup',
        render: (record) => {
          return <FormattedMessage id={PATIENT_TYPE[record?.patientType!]?.label || ' '} />;
        },
      },
      {
        title: 'encounterDoctor',
        render: (record) => {
          return record?.picName;
        },
      },
      {
        title: 'checkUpType',
        dataIndex: 'serviceName',
      },
      {
        title: 'diagnosis',
        width: 100,
        render: (record) => {
          const diagnosis = record?.icdDiagnosisList?.map((icd) => icd?.code)?.join(', ') || '';
          return (
            <Tooltip title={diagnosis}>
              <Typography noWrap sx={{ maxWidth: 120 }}>
                {diagnosis}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        title: 'status',
        render: (record) => {
          const status = ENCOUNTER_STATUS[record.status!];
          return (
            <Typography noWrap sx={{ color: status?.color }}>
              <FormattedMessage id={status?.label} />
            </Typography>
          );
        },
      },
    ] as Columns<Encounter>[];
  }, []);

  return (
    <>
      <TableCustom
        dataSource={encounters}
        loading={loading}
        columns={columns}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        rowProps={(record) => ({
          selected: confirmDialog.open,
          onClick: () => {
            history.push(ROUTES.encounterDetail.gen(record?.id!, record?.patientId!));
          },
          style: { cursor: 'pointer' },
        })}
      />
    </>
  );
};

export default EncounterList;
