import { z } from 'zod';
import { PrescriptionDetailSchema } from './PrescriptionDetail';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { PRESCRIPTION_TYPE } from 'modules/common/apiConstants';
import moment from 'moment';

export const PrescriptionSchema = z
  .object({
    id: z.number(),
    referenceId: z.number(),
    type: createObjectKeysEnumSchema(PRESCRIPTION_TYPE),
    advice: z.string(),
    patientId: z.number(),
    picUserId: z.number(),
    picName: z.string(),
    prescriptionDetailList: z.array(PrescriptionDetailSchema),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    differentialDiagnosis: z.string(),
    referenceCode: z.string(),
  })
  .partial()
  .transform((prescription) => {
    return {
      ...prescription,
      ...(prescription?.createdTime
        ? {
            createdTimeFormat: moment(prescription.createdTime).format(FE_DATE_FORMAT),
          }
        : {}),
    };
  });

export type Prescription = z.infer<typeof PrescriptionSchema>;
