import { some } from '../../../common/constants';
import { extractHTMLContent } from '../../../common/SchemaForm/element/text-editor/TextEditorElement';

const FORM_FIELDS = [
  'clinicalExamination',
  'provisionalDiagnosis',
  'differentialDiagnosis',
  'illnessesHistory',
  'medicalHistory',
  'carePlanOtherValue',
];

export function empty(values: some) {
  for (let field of FORM_FIELDS) {
    const value = values[field];
    const extractedValue = extractHTMLContent(value)?.trim();
    if (!!extractedValue) {
      return false;
    }
  }
  return true;
}
