import PatientDetailSession from '../../PatientDetailSession';
import { PatientSessionProps } from '../../type';
import PatientAppointmentItem from './PatientAppointmentItem';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { Box, Typography } from '@mui/material';
import { NoDataAppointmentUpcoming } from 'svg';
import { FormattedMessage } from 'react-intl';
import { formatStartDate } from 'modules/common/utils';

const NoData = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        padding: 4,
      }}
    >
      <NoDataAppointmentUpcoming height="80px" />
      <Typography variant="body1" marginTop={2}>
        <FormattedMessage id={'appointment.upcoming.no.data'} />
      </Typography>
    </Box>
  );
};

const PatientAppointment = ({ patient }: PatientSessionProps) => {
  const { data: upcomingAppointments } = useFetch(
    API_SERVER.appointment.getUpcomingAppoimentOfPatient(patient.id!, { fromDate: formatStartDate(new Date()) }),
  );
  return (
    <PatientDetailSession titleKey="patient.patientDetail.session.title.appointment">
      {upcomingAppointments?.length === 0 ? (
        <NoData />
      ) : (
        <Box>
          {upcomingAppointments?.map((appointment) => (
            <PatientAppointmentItem appoimentUpcoming={appointment} key={appointment.id} />
          ))}
        </Box>
      )}
    </PatientDetailSession>
  );
};

export default PatientAppointment;
