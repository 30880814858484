import { get, groupBy } from 'lodash';
import { IntlShape } from 'react-intl';
import { GroupRender, IntenalOption, OptionRender } from './type';
import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';

export const getPlaceholder = (placeholder: string | undefined, rawPlaceholder: boolean, intl: IntlShape) => {
  if (!placeholder) {
    return undefined;
  }
  return rawPlaceholder ? placeholder : intl.formatMessage({ id: placeholder });
};

export function getOptionValue<TOption>(
  option: TOption,
  getValue: keyof TOption | ((option: TOption) => string | number | undefined),
) {
  if (typeof getValue === 'function') {
    return getValue(option);
  }
  return get(option, getValue);
}

export function renderOptionLabel<TOption>(option: TOption, renderLabel: OptionRender<TOption>) {
  if (typeof renderLabel === 'function') {
    return renderLabel(option);
  }
  return get(option, renderLabel);
}

export function formatOptions<TOption>(
  options: TOption[],
  getValue: keyof TOption | ((option: TOption) => string | number | undefined),
  renderLabel: OptionRender<TOption>,
  intl: IntlShape,
  getOptionDisabled?: (option: TOption) => boolean,
  rawLabel?: boolean,
): IntenalOption<TOption>[] {
  return options.map((option) => {
    const label = renderOptionLabel(option, renderLabel);
    const needFormatLabel = !rawLabel && typeof label === 'string';
    return {
      isGroup: false,
      label: needFormatLabel ? intl.formatMessage({ id: label }) : label,
      items: [],
      value: getOptionValue(option, getValue),
      disable: getOptionDisabled ? getOptionDisabled(option) : false,
    };
  });
}

export function formatGroupOptions<TOption>(
  options: TOption[],
  getGroupBy: keyof TOption | ((option: TOption) => string | number | undefined),
  getValue: keyof TOption | ((option: TOption) => string | number | undefined),
  renderLabel: OptionRender<TOption>,
  intl: IntlShape,
  renderGroup?: GroupRender<TOption>,
  getOptionDisabled?: (option: TOption) => boolean,
  rawLabel?: boolean,
  rawGroup?: boolean,
): IntenalOption<TOption>[] {
  const optionMap = groupBy(options, getGroupBy);
  return Object.keys(optionMap).reduce<IntenalOption<TOption>[]>((arr, groupKey) => {
    const options = (optionMap[groupKey] as TOption[]) || [];
    const groupLabel = renderGroup ? renderGroup(groupKey, options) : groupKey;
    const needFormatGroupOptionLabel = !rawGroup && typeof groupLabel === 'string';
    return [
      ...arr,
      {
        isGroup: true,
        label: needFormatGroupOptionLabel ? intl.formatMessage({ id: groupLabel }) : groupLabel,
        items: options,
        value: `group-${groupKey}`,
        disable: false,
      },
      ...formatOptions(options, getValue, renderLabel, intl, getOptionDisabled, rawLabel),
    ];
  }, []);
}

export function getError<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(form: UseFormReturn<TFieldValues>, name: TName) {
  const errors = form.formState.errors;

  return get(errors, name);
}
