import { Box, Grid, InputAdornment, Typography } from '@mui/material';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { VITAL_SIGN_CODE } from 'modules/examDoctor/constants';
import VitalSignItem from './VitalSignItem';
import { useFetch, useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { Encounter } from 'modules/schema';
import { useEffect } from 'react';
import NumberInput from 'modules/common/component/form/NumberInput';
import { calculateBMI } from 'modules/common/utils';
import TextInput from 'modules/common/component/form/TextInput';
import { useDebouncedCallback } from 'use-debounce';
import './style.scss';

interface VitalSignBlockProps {
  encounter: Encounter;
  readonly: boolean;
}

interface Form {
  bodyTemperature: number | null;
  systolicBloodPressure: number | null;
  diastolicBloodPressure: number | null;
  respiratoryRate: number | null;
  heartRate: number | null;
  bodyHeight: number | null;
  bodyWeight: number | null;
  spo2: number | null;
  bmi: string;
}

const VitalSignBlock = ({ encounter, readonly }: VitalSignBlockProps) => {
  const formMethods = useForm<Form>({
    defaultValues: {
      bodyTemperature: null,
      systolicBloodPressure: null,
      diastolicBloodPressure: null,
      respiratoryRate: null,
      heartRate: null,
      bodyHeight: null,
      bodyWeight: null,
      spo2: null,
      bmi: '',
    },
  });
  const { formState, reset, watch } = formMethods;
  const weightValue = watch('bodyWeight');
  const heightValue = watch('bodyHeight');
  useEffect(() => {
    const bmi = calculateBMI(weightValue || 0, heightValue || 0);
    const bmiValue = isNaN(bmi) ? '' : bmi.toFixed(2);
    formMethods.setValue('bmi', bmiValue, { shouldDirty: false });
  }, [formMethods, heightValue, weightValue]);

  const { data: observation } = useFetch(API_SERVER.observation.getByEncounterId(encounter.id!), {
    shouldRetryOnError: false,
    enabled: encounter.id,
    globalLoading: false,
  });

  const saveObservationMutate = useUpdateMutate({
    globalLoading: false,
  });
  const submitObservation = useDebouncedCallback(async () => {
    if (Object.values(formMethods.formState.dirtyFields).length > 0) {
      const formData = formMethods.getValues();
      try {
        await saveObservationMutate({
          url: API_SERVER.observation.save(),
          method: 'post',
          data: {
            encounterId: encounter?.id,
            bodyTemperature: formData.bodyTemperature,
            heartRate: formData.heartRate,
            respiratoryRate: formData.respiratoryRate,
            systolicBloodPressure: formData.systolicBloodPressure,
            diastolicBloodPressure: formData.diastolicBloodPressure,
            bodyHeight: formData.bodyHeight,
            bodyWeight: formData.bodyWeight,
            spo2: formData.spo2,
          },
        });
        formMethods.reset(formMethods.getValues());
      } catch (error) {}
    }
  }, 3000);

  useEffect(() => {
    if (observation) {
      const bmi = calculateBMI(observation.bodyWeight || 0, observation.bodyHeight || 0);
      reset({
        bodyTemperature: observation?.bodyTemperature,
        systolicBloodPressure: observation?.systolicBloodPressure,
        diastolicBloodPressure: observation?.diastolicBloodPressure,
        respiratoryRate: observation?.respiratoryRate,
        heartRate: observation?.heartRate,
        bodyHeight: observation?.bodyHeight,
        bodyWeight: observation?.bodyWeight,
        spo2: observation?.spo2,
        bmi: isNaN(bmi) ? '' : bmi.toFixed(2),
      });
    }
  }, [encounter, observation, reset]);

  return (
    <Box
      marginTop={2}
      sx={{
        '@media screen and (max-width: 1366px)': {
          marginLeft: '-16px',
          marginRight: '-16px',
        },
      }}
    >
      <Grid
        container
        spacing={2}
        sx={{
          '@media screen and (max-width: 1366px)': {
            width: '100%',
            marginLeft: 0,
            justifyContent: 'space-evenly',
          },
        }}
      >
        <Grid item xs={6} className="vital-sign-item">
          <VitalSignItem
            label={
              <Typography variant="subtitle1" color={!!formState.errors.bodyTemperature ? 'error' : 'textSecondary'}>
                <FormattedMessage id="encounterDetail.temperature" />
              </Typography>
            }
            control={
              <NumberInput
                form={formMethods}
                name="bodyTemperature"
                variant="standard"
                hideError
                onChange={submitObservation}
                numericFormatProps={{
                  isAllowed: (values) => {
                    const number = values.floatValue || 0;
                    return number >= 0 && number <= 100;
                  },
                }}
                disabled={readonly}
                InputProps={{
                  style: { background: 'inherit' },
                  readOnly: readonly,
                  endAdornment: (
                    <InputAdornment position="end" className="vital-sign-item-adornment">
                      <Typography variant="caption">{VITAL_SIGN_CODE.temperature.unit}</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={6} className="vital-sign-item">
          <VitalSignItem
            label={
              <Typography
                variant="subtitle1"
                color={
                  !!formState.errors.systolicBloodPressure || !!formState.errors.diastolicBloodPressure
                    ? 'error'
                    : 'textSecondary'
                }
              >
                <FormattedMessage id="encounterDetail.bloodPressure" />
              </Typography>
            }
            control={
              <Box display="flex" alignItems="center">
                <Box sx={{ width: '46px' }}>
                  <NumberInput
                    sx={{ 'input[name="systolicBloodPressure"]': { padding: '4px!important' } }}
                    form={formMethods}
                    name="systolicBloodPressure"
                    variant="standard"
                    hideError
                    onChange={submitObservation}
                    disabled={readonly}
                    numericFormatProps={{
                      isAllowed: (values) => {
                        const number = values.floatValue || 0;
                        return number >= 0 && number <= 1000;
                      },
                    }}
                    InputProps={{
                      className: 'vital-sign-item-input',
                      style: { background: 'inherit' },
                      readOnly: readonly,
                    }}
                  />
                </Box>
                /
                <NumberInput
                  sx={{ 'input[name="diastolicBloodPressure"]': { padding: '4px!important' } }}
                  form={formMethods}
                  name="diastolicBloodPressure"
                  variant="standard"
                  onChange={submitObservation}
                  hideError
                  disabled={readonly}
                  numericFormatProps={{
                    isAllowed: (values) => {
                      const number = values.floatValue || 0;
                      return number >= 0 && number <= 1000;
                    },
                  }}
                  InputProps={{
                    className: 'vital-sign-item-input',
                    style: { background: 'inherit' },
                    readOnly: readonly,
                    endAdornment: (
                      <InputAdornment position="end" className="vital-sign-item-adornment">
                        <Typography variant="caption" marginRight={0}>
                          {VITAL_SIGN_CODE.bloodPressure.unit}
                        </Typography>
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>
            }
          />
        </Grid>
        <Grid item xs={6} className="vital-sign-item">
          <VitalSignItem
            label={
              <Typography variant="subtitle1" color={!!formState.errors.respiratoryRate ? 'error' : 'textSecondary'}>
                <FormattedMessage id="encounterDetail.breathBeat" />
              </Typography>
            }
            control={
              <NumberInput
                form={formMethods}
                name="respiratoryRate"
                variant="standard"
                hideError
                disabled={readonly}
                onChange={submitObservation}
                numericFormatProps={{
                  isAllowed: (values) => {
                    const number = values.floatValue || 0;
                    return number >= 0 && number <= 1000;
                  },
                }}
                InputProps={{
                  className: 'vital-sign-item-input',
                  style: { background: 'inherit' },
                  readOnly: readonly,
                  endAdornment: (
                    <InputAdornment position="end" className="vital-sign-item-adornment">
                      <Typography variant="caption">{VITAL_SIGN_CODE.breath.unit}</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={6} className="vital-sign-item">
          <VitalSignItem
            label={
              <Typography variant="subtitle1" color={!!formState.errors.heartRate ? 'error' : 'textSecondary'}>
                <FormattedMessage id="encounterDetail.heartBeat" />
              </Typography>
            }
            control={
              <NumberInput
                form={formMethods}
                name="heartRate"
                variant="standard"
                hideError
                onChange={submitObservation}
                disabled={readonly}
                numericFormatProps={{
                  isAllowed: (values) => {
                    const number = values.floatValue || 0;
                    return number >= 0 && number <= 1000;
                  },
                }}
                InputProps={{
                  className: 'vital-sign-item-input',
                  style: { background: 'inherit' },
                  readOnly: readonly,
                  endAdornment: (
                    <InputAdornment position="end" className="vital-sign-item-adornment">
                      <Typography variant="caption">{VITAL_SIGN_CODE.heartBeat.unit}</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={6} className="vital-sign-item">
          <VitalSignItem
            label={
              <Typography variant="subtitle1" color={!!formState.errors.bodyHeight ? 'error' : 'textSecondary'}>
                <FormattedMessage id="encounterDetail.height" />
              </Typography>
            }
            control={
              <NumberInput
                form={formMethods}
                name="bodyHeight"
                variant="standard"
                hideError
                onChange={submitObservation}
                numericFormatProps={{
                  isAllowed: (values) => {
                    const number = values.floatValue || 0;
                    return number >= 0 && number <= 1000;
                  },
                }}
                disabled={readonly}
                InputProps={{
                  className: 'vital-sign-item-input',
                  style: { background: 'inherit' },
                  readOnly: readonly,
                  endAdornment: (
                    <InputAdornment position="end" className="vital-sign-item-adornment">
                      <Typography variant="caption">{VITAL_SIGN_CODE.height.unit}</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={6} className="vital-sign-item">
          <VitalSignItem
            label={
              <Typography variant="subtitle1" color={!!formState.errors.bodyWeight ? 'error' : 'textSecondary'}>
                <FormattedMessage id="encounterDetail.weight" />
              </Typography>
            }
            control={
              <NumberInput
                form={formMethods}
                name="bodyWeight"
                variant="standard"
                hideError
                onChange={submitObservation}
                numericFormatProps={{
                  isAllowed: (values) => {
                    const number = values.floatValue || 0;
                    return number >= 0 && number <= 1000;
                  },
                }}
                disabled={readonly}
                InputProps={{
                  className: 'vital-sign-item-input',
                  style: { background: 'inherit' },
                  readOnly: readonly,
                  endAdornment: (
                    <InputAdornment position="end" className="vital-sign-item-adornment">
                      <Typography variant="caption">{VITAL_SIGN_CODE.weight.unit}</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={6} className="vital-sign-item">
          <VitalSignItem
            label={
              <Typography variant="subtitle1" color={'textSecondary'}>
                <FormattedMessage id="encounterDetail.BMI" />
              </Typography>
            }
            control={
              <TextInput
                form={formMethods}
                name="bmi"
                variant="standard"
                hideError
                onChange={submitObservation}
                disabled={readonly}
                InputProps={{
                  className: 'vital-sign-item-input',
                  style: { background: 'inherit' },
                  readOnly: true,
                }}
              />
            }
          />
        </Grid>
        <Grid item xs={6} className="vital-sign-item">
          <VitalSignItem
            label={
              <Typography variant="subtitle1" color={!!formState.errors.spo2 ? 'error' : 'textSecondary'}>
                <FormattedMessage id="encounterDetail.SpO2" />
              </Typography>
            }
            control={
              <NumberInput
                form={formMethods}
                name="spo2"
                variant="standard"
                hideError
                onChange={submitObservation}
                disabled={readonly}
                numericFormatProps={{
                  isAllowed: (values) => {
                    const number = values.floatValue || 0;
                    return number >= 0 && number <= 1000;
                  },
                }}
                InputProps={{
                  className: 'vital-sign-item-input',
                  style: { background: 'inherit' },
                  readOnly: readonly,
                  endAdornment: (
                    <InputAdornment position="end" className="vital-sign-item-adornment">
                      <Typography variant="caption">{VITAL_SIGN_CODE.spo2.unit}</Typography>
                    </InputAdornment>
                  ),
                }}
              />
            }
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default VitalSignBlock;
