import { Button, Stack } from '@mui/material';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import { FormattedMessage } from 'react-intl';

interface Props {
  printContentRef: React.MutableRefObject<null>;
}

const DialogAction = ({ printContentRef }: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      width="100%"
      position="absolute"
      bottom={0}
      sx={{ backgroundColor: '#F4F8FF', padding: '15px 24px' }}
    >
      <ReactToPrint
        trigger={() => (
          <Button sx={{ padding: '5px 10px' }} startIcon={<PrintIcon />}>
            <FormattedMessage id="therapy.therapyDetail.indicationRequest.printForm.printButton.title" />
          </Button>
        )}
        content={() => printContentRef.current}
      />
    </Stack>
  );
};

export default DialogAction;
