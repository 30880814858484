import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { PRIMARY } from 'colors';
import DialogCustom from 'modules/common/component/DialogCustom';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { PRESCRIPTION_DETAIL_TYPE } from 'modules/common/apiConstants';
import { NumericFormatText } from 'modules/common/utils';
import moment from 'moment';
import { MedicationCategory, Prescription } from 'modules/schema';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';

export const TableCellPadding = withStyles({
  root: {
    padding: 8,
  },
})(TableCell);

function Row(props: {
  row: Prescription;
  select?: Prescription;
  onSelect: (item: Prescription) => void;
  medicationOptionAll: MedicationCategory[];
}) {
  const { row, select, onSelect, medicationOptionAll } = props;
  const [open, setOpen] = React.useState(false);
  const { appState } = useGeneralHook();
  const allIntendedRoutes = appState.common.intendedRoutes;

  const editableRadio = row?.prescriptionDetailList?.some((item) => {
    const medicationId = item?.medicationId;
    return (
      medicationId &&
      medicationOptionAll
        ?.flatMap((medication) => medication?.medicationList)
        ?.some((medicationItem) => medicationItem?.id === medicationId)
    );
  });

  return (
    <React.Fragment>
      <TableCustomRow>
        <TableCustomCell style={{ width: 70 }}>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell>
          {row.createdTime && moment(row.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
        </TableCustomCell>
        <TableCustomCell>{extractHTMLContent(row.differentialDiagnosis)}</TableCustomCell>
        <TableCustomCell align="right">
          <Radio
            disabled={!editableRadio}
            checked={select?.id === row?.id}
            onChange={() => {
              setOpen(true);
              onSelect({ ...row });
            }}
          />
        </TableCustomCell>
      </TableCustomRow>
      <TableCustomRow>
        <TableCustomCell style={{ paddingBottom: 0, paddingTop: 0, border: 'none' }} colSpan={5}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1} bgcolor="white">
              {row?.prescriptionDetailList && row?.prescriptionDetailList?.length > 0 ? (
                row?.prescriptionDetailList?.map((item, index: number) => {
                  const medicationItem = medicationOptionAll
                    ?.flatMap((medication) =>
                      medication?.medicationList?.map((item) => ({
                        ...item,
                        group: medication?.name,
                      })),
                    )
                    ?.find((i) => i?.id === item.medicationId);
                  return (
                    <Box
                      key={index}
                      display={'flex'}
                      bgcolor={PRIMARY[50]}
                      alignItems={'center'}
                      borderTop={`1px solid grey`}
                    >
                      <Box padding={2} flex={1}>
                        {item?.type === PRESCRIPTION_DETAIL_TYPE.MEDICATION_INTERNAL ? (
                          <>
                            <Box display="flex" columnGap={2} flexWrap="wrap">
                              <Typography variant="body1">
                                <FormattedMessage id="encounterPrescription.groupDrug" />
                                :&nbsp;
                                <Typography variant="subtitle1" component="span">
                                  {medicationItem?.group}
                                </Typography>
                              </Typography>
                              <Typography variant="body1">
                                <FormattedMessage id="encounterPrescription.drugName" />
                                :&nbsp;
                                <Typography variant="subtitle1" component="span">
                                  {item?.medication?.name}
                                </Typography>
                              </Typography>
                              <Typography variant="body1">
                                <FormattedMessage id="encounterPrescription.ingredients" />
                                :&nbsp;
                                <Typography variant="subtitle1" component="span">
                                  {item?.medication?.ingredient}
                                </Typography>
                              </Typography>
                              <Typography variant="body1">
                                <FormattedMessage id="encounterPrescription.unit" />
                                :&nbsp;
                                <Typography variant="subtitle1" component="span">
                                  {item?.medication?.numeratorUnit}
                                </Typography>
                              </Typography>
                              <Typography variant="body1">
                                <FormattedMessage id="encounterPrescription.price" />
                                :&nbsp;
                                <Typography variant="subtitle1" component="span">
                                  {NumericFormatText(item?.medication?.publicPrice)}
                                </Typography>
                              </Typography>
                            </Box>
                            <Box display="flex" gap={2}>
                              <Box>
                                <Typography>
                                  <FormattedMessage id="quantity" />
                                </Typography>
                                {item.quantity}
                              </Box>
                              <Box>
                                <Typography>
                                  <FormattedMessage id="encounterPrescription.intendedRoute" />
                                </Typography>
                                {allIntendedRoutes?.find((v) => v.value === item?.intendedRoute)?.label}
                              </Box>
                              <Box>
                                <Typography>
                                  <FormattedMessage id="encounterPrescription.instruction" />
                                </Typography>
                                {item.usage}
                              </Box>
                            </Box>
                          </>
                        ) : (
                          <>
                            <Box display="flex" columnGap={2} flexWrap="wrap">
                              <Typography variant="body1">
                                <FormattedMessage id="encounterPrescription.drugName" />
                                :&nbsp;
                                <Typography variant="subtitle1" component="span">
                                  {item?.medicationName}
                                </Typography>
                              </Typography>
                              <Typography variant="body1">
                                <FormattedMessage id="indication.unit" />
                                :&nbsp;
                                <Typography variant="subtitle1" component="span">
                                  {item?.unit}
                                </Typography>
                              </Typography>
                            </Box>
                            <Box display="flex" gap={2}>
                              <Box>
                                <Typography>
                                  <FormattedMessage id="quantity" />
                                </Typography>
                                {item.quantity}
                              </Box>
                              <Box>
                                <Typography>
                                  <FormattedMessage id="encounterPrescription.intendedRoute" />
                                </Typography>
                                {item?.intendedRoute}
                              </Box>
                              <Box>
                                <Typography>
                                  <FormattedMessage id="encounterPrescription.instruction" />
                                </Typography>
                                {item.usage}
                              </Box>
                            </Box>
                          </>
                        )}
                        {medicationItem === null || medicationItem === undefined ? (
                          <Typography
                            variant="body1"
                            sx={{ color: '#ff9100', display: 'flex', justifyItems: 'center' }}
                          >
                            <WarningAmberIcon sx={{ borderRadius: '10px', paddingRight: '5px' }} />{' '}
                            <FormattedMessage id="warning.prescription.delated" />
                          </Typography>
                        ) : null}
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <Typography textAlign={'center'}>
                  <FormattedMessage id="encounterPrescription.noPrescription" />
                </Typography>
              )}
            </Box>
          </Collapse>
        </TableCustomCell>
      </TableCustomRow>
    </React.Fragment>
  );
}

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (value) => void;
  dataDentalPrescriptionsPatient: any;
  medicationOptionAll: MedicationCategory[];
}

const HistoryPrescriptionDialog = (props: Props) => {
  const { open, onClose, onSubmit, dataDentalPrescriptionsPatient, medicationOptionAll } = props;
  const [select, setSelect] = React.useState<Prescription | undefined>(undefined);

  return (
    <>
      <DialogCustom
        open={open}
        onClose={onClose}
        title="encounterPrescription.listOldPrescription"
        TransitionProps={{ onEnter: () => setSelect(undefined) }}
        PaperProps={{ style: { width: '70vw', maxWidth: 'unset' } }}
      >
        <Box padding={2}>
          <TableContainer>
            <Table stickyHeader>
              <TableHead>
                <TableCustomRow>
                  <TableHeaderCell></TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="encounterBook.encounterDate" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="printForm.diagnosis" />
                  </TableHeaderCell>
                  <TableHeaderCell></TableHeaderCell>
                </TableCustomRow>
              </TableHead>
              <TableBody>
                {dataDentalPrescriptionsPatient?.map((row, index) => (
                  <Row
                    key={row.id}
                    row={row}
                    select={select}
                    onSelect={(value) => {
                      setSelect(value);
                    }}
                    medicationOptionAll={medicationOptionAll}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box paddingTop={2} display="flex" justifyContent={'flex-end'} gap={2}>
            <Button variant="outlined" onClick={onClose} style={{ width: 120 }}>
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              style={{ width: 120 }}
              disabled={!select?.prescriptionDetailList?.length}
              onClick={() => onSubmit(select)}
            >
              <FormattedMessage id="apply" />
            </Button>
          </Box>
        </Box>
      </DialogCustom>
    </>
  );
};

export default HistoryPrescriptionDialog;
