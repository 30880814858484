import { Box, BoxProps, Skeleton } from '@mui/material';
import * as React from 'react';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../redux/reducer';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';
import useSWR from 'swr';
import { API_SERVER } from '../api';
import { fetchThunk } from '../redux/thunk';

interface Props extends BoxProps {
  imageId?: number;
  alt?: string;
}

const ImagePreview: React.FunctionComponent<Props> = (props) => {
  const { imageId } = props;
  const dispatch: ThunkDispatch<AppState, null, AnyAction> = useDispatch();

  const { data } = useSWR(
    imageId ? API_SERVER.storageResource.doawloadFile(imageId) : null,
    (url: string) => dispatch(fetchThunk(url, 'get', undefined, 'image/jpeg')),
    { dedupingInterval: 2000, revalidateOnFocus: false },
  );

  const urlObj = React.useMemo(() => {
    if (data) {
      return URL.createObjectURL(data as any);
    } else {
      return null;
    }
  }, [data]);

  React.useEffect(() => {
    return () => {
      if (urlObj) {
        URL.revokeObjectURL(urlObj);
      }
    };
  }, [urlObj]);

  return !urlObj ? (
    <Skeleton
      variant="rectangular"
      style={{
        ...props.style,
      }}
    />
  ) : (
    <Box component={'img'} {...props} alt={props.alt || 'img'} src={urlObj} />
  );
};

export default ImagePreview;
