import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import TextValue from '../../../../../examDoctor/component/therapy/TherapySessionDetail/PrintTherapySessionDialog/PrintDialogContent/TextValue';
import { some } from '../../../../../common/constants';

interface Props {
  therapy: some;
}

const TherapyInfo = ({ therapy }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box flex={1.5}>
        <TextValue
          label="therapy.payment.summaryPrintForm.title.therapyCode"
          value={therapy?.code}
          labelWidth="110px"
        />
        <TextValue
          label="therapy.payment.summaryPrintForm.title.therapyName"
          value={therapy?.name}
          labelWidth="110px"
        />
      </Box>
    </Stack>
  );
};

export default TherapyInfo;
