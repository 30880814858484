import { Typography } from '@mui/material';
import DialogCustomFullScreen from 'modules/common/component/DialogCustomFullScreen';
import TestServiceTab from 'modules/labDoctor/component/therapy/testService/TestServiceTab';
import { DentalExam } from 'modules/schema';
interface Props {
  open: boolean;
  onClose: () => void;
  dentalExam: DentalExam;
  indicationRequestId: number;
}

const EditIndicationLab = ({ open, onClose, dentalExam, indicationRequestId }: Props) => {
  return (
    <DialogCustomFullScreen
      open={open}
      onClose={onClose}
      title={<Typography variant="h6">{dentalExam?.patient?.name}</Typography>}
    >
      <TestServiceTab indicationRequestId={indicationRequestId + ''} />
    </DialogCustomFullScreen>
  );
};

export default EditIndicationLab;
