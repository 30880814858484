import { Box, Checkbox, Typography } from '@mui/material';
import { chain, groupBy, keyBy, map, flatMap } from 'lodash';
import { API_SERVER } from 'modules/common/api';
import {
  INDICATION_REQUEST_STATUS,
  INDICATION_REQUEST_TYPE,
  SYSTEM_CATEGORY_SCOPE,
  SYSTEM_CATEGORY_TYPE,
} from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaFormMain from 'modules/common/SchemaForm';
import { Option } from 'modules/common/type';
import { useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { mutate } from 'swr';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import { useFetchIndicationRequest, useFetchSystemCategories } from '../../common/fetcher';
import IndicationRequestPrintForm from '../IndicationRequestPrintForm';
import { useIndicationPrintFormDialogSelectors } from '../IndicationRequestPrintForm/state';
import { useUpdateMutate } from 'modules/common/hook/useMutate';
import { useDentalDetailStateSelector } from '../../state';

const STANDARD_SERVICE_TYPES: Option[] = [
  { value: SYSTEM_CATEGORY_SCOPE.radiology, label: 'systemCategory.radiology' },
  { value: SYSTEM_CATEGORY_SCOPE.surgical, label: 'systemCategory.surgical' },
  { value: SYSTEM_CATEGORY_SCOPE.laboratory, label: 'systemCategory.laboratory' },
  { value: SYSTEM_CATEGORY_SCOPE.other, label: 'systemCategory.other' },
];

const BASIC_SERVICE_TYPES: Option[] = [
  { value: SYSTEM_CATEGORY_SCOPE.surgical, label: 'systemCategory.surgical' },
  { value: SYSTEM_CATEGORY_SCOPE.other, label: 'systemCategory.other' },
];
const IndicationsFilterBox = () => {
  const { intl, isBasic } = useGeneralHook();
  const { dentalSessionId } = useParams<{ dentalSessionId }>();
  const { data: serviceCategories } = useFetchSystemCategories();
  const { data: indicationRequests } = useFetchIndicationRequest(dentalSessionId);
  const onOpenIndicationPrintFormDialog = useIndicationPrintFormDialogSelectors.use.onOpenDialog();
  const indicationRequestData = useMemo(
    () => indicationRequests?.filter((ele) => ele.status !== INDICATION_REQUEST_STATUS.DELETED.value) || [],
    [indicationRequests],
  );
  const readOnly = useDentalDetailStateSelector.use.readonly();

  const mappedData = useMemo(() => {
    if (serviceCategories) {
      return chain<typeof serviceCategories>(serviceCategories)
        .groupBy('scope')
        .mapValues((value: any[]) => {
          const parents = chain(value)
            .filter((ele) => ele.type === SYSTEM_CATEGORY_TYPE.priceList)
            .keyBy('id')
            .value();
          const temp = groupBy(value, 'parentId');
          return keyBy(
            map(parents, (ele) => ({
              ...ele,
              children:
                temp[ele.id!]?.map((category) => ({
                  value: category.id,
                  label: category.name,
                  group: ele.name,
                  categoryScope: category.scope,
                  items:
                    category?.items?.map((service) => ({
                      value: service.id!,
                      label: service.name!,
                      group: category.name,
                      groupId: category.id,
                    })) || [],
                })) || [],
            })),
            'id',
          );
        })
        .value();
    }
    return {};
  }, [serviceCategories]);

  const validateIndications = useCallback(
    () => mutate(API_SERVER.indicationRequest.getIndicationRequestByReferenceId(dentalSessionId, 'DENTAL_EXAM').url),
    [dentalSessionId],
  );
  const addIndication = useUpdateMutate({ onSuccess: validateIndications });

  return (
    <Box sx={{ padding: '10px 8px', backgroundColor: '#F4F8FF' }}>
      <SchemaFormMain
        hideSubmitButton
        readOnly={readOnly}
        schema={{
          fields: ({ valuesField, methods }) => {
            const { setValue } = methods;
            const temp = valuesField.serviceType
              ? { [valuesField.serviceType?.value]: mappedData[valuesField.serviceType?.value] }
              : mappedData;
            const groupOptions = flatMap(temp, (value) => flatMap(value, (ele) => ele.children));
            const servicesOptions = valuesField.group
              ? valuesField.group.items
              : flatMap(groupOptions, (ele) => ele.items);

            return {
              serviceType: {
                type: 'auto-complete',
                getOptionLabel: (option: any) => intl.formatMessage({ id: option.label }),
                options: isBasic ? BASIC_SERVICE_TYPES : STANDARD_SERVICE_TYPES,
                label: intl.formatMessage({ id: 'therapyIndication.header.label.serviceType' }),
                placeholder: intl.formatMessage({ id: 'therapyIndication.header.label.serviceTypeSelect' }),
                onChange: () => {
                  setValue('group', null);
                  setValue('service', null);
                },
                propsWrapper: { xs: 3 },
                noHelperText: true,
              },
              group: {
                type: 'auto-complete',
                options: groupOptions,
                getOptionLabel: (option) => option.label,
                label: intl.formatMessage({ id: 'procedure.priceType' }),
                placeholder: intl.formatMessage({ id: 'encounterList.groupPriceTypeEnter' }),
                groupBy: (option) => option.group,
                onChange: () => {
                  setValue('service', null);
                },
                propsWrapper: { xs: 3 },
                noHelperText: true,
              },
              service: {
                type: 'auto-complete',
                propsWrapper: { xs: 3 },
                options: servicesOptions,
                disableCloseOnSelect: true,
                groupBy: (option) => option.group,
                label: intl.formatMessage({ id: 'serviceName' }),
                placeholder: intl.formatMessage({ id: 'serviceNameSelect' }),
                onChange: (option) =>
                  addIndication({
                    url: API_SERVER.indicationRequest.create(),
                    method: 'post',
                    data: {
                      referenceId: parseInt(dentalSessionId),
                      serviceId: option.value,
                      note: '',
                      type: INDICATION_REQUEST_TYPE.DENTAL_EXAM,
                    },
                  }),
                renderOption: (props, option) => {
                  return (
                    <li {...props} key={option?.id}>
                      <Box alignItems="center" display="flex">
                        <Checkbox
                          size="small"
                          color="primary"
                          style={{ marginRight: 8, padding: 0 }}
                          checked={
                            indicationRequestData &&
                            indicationRequestData.some((indication) => indication.serviceId === option.value)
                          }
                        />
                        <Typography variant="body2" component="div">
                          {option.label}
                        </Typography>
                      </Box>
                    </li>
                  );
                },
                noHelperText: true,
              },
              printIndication: {
                type: 'button',
                onClick: onOpenIndicationPrintFormDialog,
                propsWrapper: { xs: 3 },
                color: 'inherit',
                label: intl.formatMessage({
                  id: 'dental.dentalDetail.indicationTable.button.printIndicationRequest.label',
                }),
                style: { marginTop: 22 },
                startIcon: <LocalPrintshopIcon />,
                disabled: !indicationRequests?.some(
                  (indication) => indication.status === INDICATION_REQUEST_STATUS.REQUESTED.value,
                ),
              },
            };
          },
        }}
      />
      <IndicationRequestPrintForm />
    </Box>
  );
};

export default IndicationsFilterBox;
