export const ODONTOGRAM_TYPES = {
  outsideSurface: {
    value: 'outsideSurface',
    label: 'dental.dentalDetail.odontogram.label.outsideSurface',
  },
  occlusalSurface: {
    value: 'occlusalSurface',
    label: 'dental.dentalDetail.odontogram.label.occlusalSurface',
  },
  insideSurface: {
    value: 'insideSurface',
    label: 'dental.dentalDetail.odontogram.label.insideSurface',
  },
} as const;
