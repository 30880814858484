import { DateTimePicker, DateTimePickerProps } from '@mui/lab';
import { TextField } from '@mui/material';
import moment from 'moment';
import React, { memo } from 'react';
import { BE_DATE_TIME_FORMAT } from '../../../constants';

export interface PropsDateTimePickerElement
  extends Omit<DateTimePickerProps, 'defaultValue' | 'onChange' | 'minDate' | 'maxDate'> {
  onChange?: (value: Date | null) => void;
  required?: boolean;
  error?: boolean;
  isSubmitting?: boolean;
  name?: string;
  minDate?: string;
  maxDate?: string;
  formatDate?: string;
}

interface Props extends PropsDateTimePickerElement {}

const DateTimePickerElement = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { name, error, isSubmitting, required, value, onChange, formatDate, ...rest } = props;

  return (
    <DateTimePicker
      inputFormat="P HH:mm"
      mask="__/__/____ __:__"
      // openTo="year"
      // views={['year', 'month', 'day', 'hours', 'minutes']}
      fullWidth
      inputRef={ref}
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            required: required,
          }}
          variant="outlined"
          fullWidth
          error={!!error}
        />
      )}
      allowSameDateSelection
      value={value}
      onChange={(date: any, value) => {
        let tmp;
        if (date) {
          const md = moment(date);
          tmp = md.isValid() ? md.format(formatDate || BE_DATE_TIME_FORMAT) : '';
        } else if (value) {
          const md = moment(value, BE_DATE_TIME_FORMAT, true);
          tmp = md.isValid() ? md.format(formatDate || BE_DATE_TIME_FORMAT) : '';
        } else if (value === null) {
          tmp = null;
        }
        onChange && onChange(tmp || null);
      }}
      {...(rest as any)}
    />
  );
});
export default memo(DateTimePickerElement);
