import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import { Box, Button, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import { NumericFormatText } from 'modules/common/utils';
import FormControlAutoComplete from 'modules/common/SchemaForm/element/autocomplete/FormControlAutoComplete';
import { styled } from '@mui/system';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const { intl, medicationCategoryList, listTemplate, addTemplate, key, setOpenHistory, readOnly } = formProps || {};

  const getMedicationItemOptions = () => {
    const arr: any[] = [];
    let categoryList: any[] = [];
    categoryList = valuesField?.categoryOption
      ? medicationCategoryList.filter((v) => v.id === valuesField?.categoryOption?.id) || []
      : medicationCategoryList;

    categoryList?.forEach((i) => {
      i?.medicationList?.forEach((i1: some) => {
        const obj = {
          ...i1,
          group: i.name,
        };
        arr.push(obj);
      });
    });
    return arr;
  };

  const medicationOptions = getMedicationItemOptions();

  const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 500,
    },
  });

  return {
    categoryOption: {
      propsWrapper: { xs: 6 },
      type: () => {
        return (
          <FormControlAutoComplete
            readOnly={readOnly}
            key={`${key}`}
            label={intl.formatMessage({ id: 'navMenu.managePrescriptionTemplate' })}
            placeholder={intl.formatMessage({ id: 'select' })}
            options={listTemplate}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, selected) => option.id === selected.id}
            onChange={(_, val) => {
              if (val) {
                addTemplate(val);
              }
            }}
            clearOnBlur={true}
            renderOption={(_, option) => {
              return (
                <CustomWidthTooltip
                  arrow
                  placement="right-start"
                  title={
                    <Box>
                      <Typography variant="h6" textAlign={'center'}>
                        <FormattedMessage id="encounterDetail.prescription" />
                        &nbsp;{option.name}
                      </Typography>
                      {option?.detailTemplateList?.map((item, index) => {
                        const medicationItem = medicationOptions.find(
                          (i) => String(i.id) === String(item.medicationId),
                        );
                        return (
                          <>
                            <Box display="flex" flex={1} columnGap={2} flexWrap="wrap">
                              <Typography variant="body2">
                                {index + 1}.&nbsp; <FormattedMessage id="encounterPrescription.groupDrug" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {medicationItem?.group}
                                </Typography>
                              </Typography>
                              <Typography variant="body2">
                                <FormattedMessage id="encounterPrescription.drugName" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {medicationItem?.name}
                                </Typography>
                              </Typography>
                              <Typography variant="body2">
                                <FormattedMessage id="inventory" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {medicationItem?.inventory}
                                </Typography>
                              </Typography>
                              <Typography variant="body2">
                                <FormattedMessage id="encounterPrescription.ingredients" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {medicationItem?.ingredient}
                                </Typography>
                              </Typography>
                              <Typography variant="body2">
                                <FormattedMessage id="sale.dose" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {medicationItem?.dose}
                                </Typography>
                              </Typography>
                              <Typography variant="body2">
                                <FormattedMessage id="encounterPrescription.unit" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {medicationItem?.numeratorUnit}
                                </Typography>
                              </Typography>
                              <Typography variant="body2">
                                <FormattedMessage id="encounterPrescription.price" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {NumericFormatText(medicationItem?.basicPrice)}
                                </Typography>
                              </Typography>
                              <Typography variant="body2">
                                <FormattedMessage id="quantity" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {item?.quantity}
                                </Typography>
                              </Typography>
                              <Typography variant="body2">
                                <FormattedMessage id="therapy.detail.label.intendedRoute" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {medicationItem?.intendedRoute[0]?.label}
                                </Typography>
                              </Typography>
                              <Typography variant="body2">
                                <FormattedMessage id="encounterPrescription.instruction" />
                                :&nbsp;
                                <Typography variant="subtitle2" component="span">
                                  {item?.usage}
                                </Typography>
                              </Typography>
                            </Box>
                          </>
                        );
                      })}
                      <Typography variant="body2">
                        <FormattedMessage id="encounterPrescription.note" />
                        :&nbsp;
                        <Typography variant="subtitle2" component="span">
                          {option.advice}
                        </Typography>
                      </Typography>
                    </Box>
                  }
                >
                  <li {..._}>
                    <Typography variant="subtitle2">{option.name}</Typography>
                  </li>
                </CustomWidthTooltip>
              );
            }}
          />
        );
      },
    },
    medicationOption: {
      type: () => {
        // const readOnly = useDentalDetailStateSelector.use.readonly();
        return (
          <div>
            <Typography color={'#607d8b'} fontWeight={'500'} fontSize={'14px'} lineHeight={1} marginBottom={'7px'}>
              <FormattedMessage id="dental.prescription.label.oldPrescription" />
            </Typography>
            <Button variant="outlined" onClick={() => setOpenHistory(true)} disabled={readOnly}>
              <FormattedMessage id="dental.prescription.label.prescriptionPrevious" />
            </Button>
          </div>
        );
      },
      propsWrapper: { xs: 6 },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  return [
    {
      id: 'info',
      fields: ['categoryOption', 'medicationOption'],
      paperProps: {
        sx: {
          border: 'none',
          bgcolor: '#F4F8FF',
          px: 2,
          py: 1,
        },
      },
      paper: true,
    },
    {
      id: 'medication',
      fields: ['medicationList'],
    },
  ];
};

export const quicklyCreatePrescriptions: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: ({ listElement }) => {
    return <>{listElement[0]}</>;
  },
};
