import React, { memo } from 'react';
import SelectCustom, { SelectCustomProps } from './SelectCustom';

export interface PropsSelectElement extends Omit<SelectCustomProps, 'onChange'> {
  onChange?: (value: any) => void;
}

interface Props extends PropsSelectElement {}

const SelectElement = React.forwardRef<any, Props>((props: Props, ref) => {
  const { value, multiple, onChange } = props;

  return (
    <SelectCustom
      ref={ref}
      {...props}
      value={value !== undefined && value !== null ? value : multiple ? [] : ' '}
      onChange={onChange}
    />
  );
});

export default memo(SelectElement);
