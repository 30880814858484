import DeleteRoundedIcon from '@mui/icons-material/DeleteRounded';
import { Autocomplete, Box, Card, CardHeader, IconButton, List, ListItem, Popper, Typography } from '@mui/material';
import { cloneDeep } from 'lodash';
import * as React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { PRIMARY } from '../../../../../colors';
import { API_SERVER } from 'modules/common/api';
import FreeTextField from 'modules/common/component/FreeTextField';
import { ListboxComponent } from 'modules/common/component/ListboxComponent';
import { CODE_SYSTEM } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import { MAX_SECONDARY_ICD10_COUNT } from 'modules/examDoctor/constants';
import { useFetch, useUpdateMutate } from 'modules/common/hook';
import { Encounter } from 'modules/schema';
import { ICD_TYPE } from 'modules/common/apiConstants';
import { mutate } from 'swr';

interface Props {
  title?: React.ReactNode;
  style?: React.CSSProperties;
  readOnly?: boolean;
  encounter: Encounter;
}

const SelectSecondaryICDBox: React.FunctionComponent<Props> = (props) => {
  const { title, style, readOnly, encounter } = props;
  const { dispatch, intl } = useGeneralHook();
  const { control, setValue } = useFormContext();
  const [tick, setTick] = React.useState(false);
  const anchorEl = React.useRef<HTMLDivElement>(null);

  // Get code system by type
  const { data: dataCodeSystem } = useFetch(
    API_SERVER.code_system.getList({
      type: CODE_SYSTEM.ICD10,
    }),
  );

  const otherIcd10s = encounter?.otherIcd10s;
  const otherIcd10sMap = otherIcd10s?.map((i) => i.code);
  const dataCodeSystemFilter = dataCodeSystem?.filter((item) => !otherIcd10sMap?.includes(item.value));

  // create icd 10 khac
  const saveToBackendMutate = useUpdateMutate({
    onSuccess: () => {
      mutate(API_SERVER.encounter.get(encounter?.id!).url);
    },
  });
  const saveToBackend = React.useCallback(
    async (val) => {
      const newVal = cloneDeep(val);
      for (let one of newVal) {
        if (one.id === undefined) {
          dispatch(setLoading(true));
          await saveToBackendMutate({
            url: API_SERVER.icdDiagnosis.updateByEncounterId(),
            method: 'put',
            data: {
              code: one?.value,
              display: one?.label,
              encounterId: parseInt(encounter?.id?.toString() || ''),
              type: ICD_TYPE.SECOND_ICD10,
            },
          });
        }
      }
      return newVal;
    },
    [dispatch, encounter?.id, saveToBackendMutate],
  );

  // delete icd 10 khac
  const deleteIcdOther = React.useCallback(
    async (val, value, name) => {
      dispatch(setLoading(true));
      await saveToBackendMutate({
        url: API_SERVER.icdDiagnosis.deleteIcdDiagnosis(val.id!),
        method: 'delete',
      });
      setValue(
        name,
        value.filter((v) => v.value !== val.code),
        { shouldDirty: false },
      );
      setTick((one) => !one);
    },
    [dispatch, saveToBackendMutate, setValue],
  );

  return (
    <>
      <Card variant="outlined" style={style}>
        <CardHeader title={title} style={{ padding: '8px 16px', background: PRIMARY.main }} />
        <Box padding={1}>
          <Controller
            control={control}
            name={'secondaryICD10'}
            defaultValue={[]}
            render={({ field: { name, value, onChange } }) => {
              return (
                <>
                  {!readOnly && (
                    <div key={`${tick}`} style={{ display: 'flex' }} ref={anchorEl}>
                      <Autocomplete
                        multiple
                        openOnFocus
                        filterSelectedOptions
                        filterOptions={(opts, state) => {
                          return opts.filter(
                            (opt) => opt?.label?.toLowerCase().indexOf(state?.inputValue?.toLowerCase().trim()) > -1,
                          );
                        }}
                        disableCloseOnSelect
                        options={dataCodeSystemFilter || []}
                        fullWidth
                        value={value}
                        disabled={otherIcd10s?.length! >= MAX_SECONDARY_ICD10_COUNT}
                        style={{ marginRight: 8, flex: 2 }}
                        ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                        isOptionEqualToValue={(selected, value) => selected.code === value.value}
                        onMouseDownCapture={(e) => e.stopPropagation()}
                        noOptionsText={
                          <Typography>
                            <FormattedMessage id="noOption" />
                          </Typography>
                        }
                        renderOption={(_, option) => (
                          <li {..._}>
                            <Typography variant="subtitle2">
                              {option.label}&nbsp;({option.value})
                            </Typography>
                          </li>
                        )}
                        onChange={async (e, val, reason) => {
                          if (val.length > MAX_SECONDARY_ICD10_COUNT) {
                            return;
                          }

                          const newVal = await saveToBackend(val);
                          setValue(name, newVal, { shouldDirty: false });
                        }}
                        getOptionLabel={(option) => option.label}
                        disableClearable
                        renderInput={(params) => (
                          <FreeTextField
                            {...params}
                            label={intl.formatMessage({ id: 'encounterInfo.name' })}
                            placeholder={intl.formatMessage({ id: 'encounterInfo.codeICD10Display' })}
                          />
                        )}
                        PopperComponent={(props) => (
                          <Popper
                            {...props}
                            anchorEl={anchorEl.current}
                            style={{ width: anchorEl.current?.getBoundingClientRect().width }}
                            placement="bottom-start"
                          />
                        )}
                        renderTags={() => null}
                      />
                      <Autocomplete
                        multiple
                        openOnFocus
                        filterSelectedOptions
                        disableCloseOnSelect
                        options={dataCodeSystemFilter || []}
                        fullWidth
                        value={value}
                        style={{ flex: 1 }}
                        disabled={otherIcd10s?.length! >= MAX_SECONDARY_ICD10_COUNT}
                        ListboxComponent={ListboxComponent as React.ComponentType<React.HTMLAttributes<HTMLElement>>}
                        isOptionEqualToValue={(selected, value) => selected.code === value.value}
                        onMouseDownCapture={(e) => e.stopPropagation()}
                        noOptionsText={
                          <Typography>
                            <FormattedMessage id="noOption" />
                          </Typography>
                        }
                        renderOption={(_, option) => (
                          <li {..._}>
                            <Typography variant="subtitle2">
                              {option.label}&nbsp;({option.value})
                            </Typography>
                          </li>
                        )}
                        onChange={async (e, val, reason) => {
                          if (val.length > MAX_SECONDARY_ICD10_COUNT) {
                            return;
                          }

                          const newVal = await saveToBackend(val);
                          setValue(name, newVal, { shouldDirty: false });
                          setTick((one) => !one);
                        }}
                        disableClearable
                        getOptionLabel={(option) => option.value}
                        renderInput={(params) => (
                          <FreeTextField
                            {...params}
                            placeholder={intl.formatMessage({ id: 'encounterInfo.codeICD10' })}
                            label={intl.formatMessage({ id: 'encounterInfo.code' })}
                          />
                        )}
                        PopperComponent={(props) => (
                          <Popper
                            {...props}
                            anchorEl={anchorEl.current}
                            style={{ width: anchorEl.current?.getBoundingClientRect().width }}
                            placement="bottom-start"
                          />
                        )}
                        renderTags={() => null}
                      />
                    </div>
                  )}
                  <List>
                    {otherIcd10s?.map((val, index: number) => {
                      return (
                        <ListItem key={val.code} sx={{ bgcolor: index % 2 ? 'unset' : 'primary.50' }}>
                          <Box flex={1}>
                            <Typography variant="body2">
                              {val.display} ({val.code})
                            </Typography>
                          </Box>
                          {!readOnly && (
                            <IconButton style={{ padding: 0 }} onClick={() => deleteIcdOther(val, value, name)}>
                              <DeleteRoundedIcon />
                            </IconButton>
                          )}
                        </ListItem>
                      );
                    })}
                  </List>
                </>
              );
            }}
          />
        </Box>
      </Card>
    </>
  );
};

export default SelectSecondaryICDBox;
