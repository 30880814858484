import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box } from '@mui/system';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import useGeneralHook from '../hook/useGeneralHook';
import { setCloseNotify } from '../redux/commonReducer';

const TransitionBox = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const NotifyDialog = () => {
  const { dispatch, appState } = useGeneralHook();
  const { isOpen, title, message } = appState.common?.notifyProps;

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        dispatch(setCloseNotify());
      }}
      keepMounted={false}
      TransitionComponent={TransitionBox}
      PaperProps={{ style: { minWidth: 320 } }}
    >
      <Box style={{ padding: 16, display: 'flex', alignItems: 'flex-end' }}>
        <Box flex={1}>
          {typeof title === 'string' ? (
            <Typography variant="h6" color="primary">
              <FormattedMessage id={title} />
            </Typography>
          ) : (
            title
          )}
        </Box>
      </Box>
      <DialogContent style={{ padding: 16 }}>
        <Typography variant="body1" component="div">
          {typeof message === 'string' ? <FormattedMessage id={message} /> : message}
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button
          color={'primary'}
          variant="contained"
          onClick={() => {
            dispatch(setCloseNotify());
          }}
        >
          <FormattedMessage id={'ok'} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NotifyDialog;
