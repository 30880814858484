import {
  // Box,
  Paper,
  Typography,
} from '@mui/material';
import { AxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import OrderForm from '../component/createOrder/OrderForm';
import { FormattedMessage } from 'react-intl';
import PrintReceiptDetailDialog from '../component/saleManagement/printReceiptDetail';
import { RECEIPT_ID } from '../constants';
import { PAYMENT_METHOD } from 'modules/common/apiConstants';
// import useSWR from 'swr';
// import { axiosThunk } from 'modules/common/redux/axios';

interface LocationObject {
  state?: {
    // type: string
    id: number;
  };
}

interface Props {
  location: LocationObject;
}

const CreateOrderPage: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, intl, axiosThunk, confirmDialog, API_SERVER } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const [receiptToPrint, setReceiptToPrint] = useState<some | undefined>();
  const [receiptDetail, setReceiptDetail] = useState<any>();
  // const locationStateId = props.location.state?.id;
  const locationStateId = localStorage.getItem(RECEIPT_ID);

  const onSubmit = React.useCallback(
    async (value: some, methods) => {
      const confirm = await promptConfirmation({
        warning: false,
        title: intl.formatMessage({ id: 'common.title.confirm' }),
        content: intl.formatMessage({ id: 'sellingProduct.title.pay.confirm' }),
        cancelId: 'no',
        okId: 'yes',
      });
      if (confirm) {
        let isSuccess = false;
        let receiptId;
        dispatch(setLoading(true));
        await dispatch(
          axiosThunk(
            {
              url: API_SERVER.sale.create(),
              method: 'post',
              data: value,
            },
            true,
          ),
        )
          .then((res) => {
            openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
            isSuccess = true;
            receiptId = res?.data?.id;
          })
          .catch((e: AxiosError<some>) => {
            if (e?.response)
              e?.response?.data?.errors?.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });
        dispatch(setLoading(false));

        if (isSuccess && receiptId) {
          const confirm2 = await promptConfirmation({
            warning: false,
            title: intl.formatMessage({ id: 'common.title.confirm' }),
            content: intl.formatMessage({ id: 'sellingProduct.title.pay.success.print.confirm' }),
            cancelId: 'cancel',
            okId: 'printInvoice',
          });
          if (confirm2) {
            const detailRes = await dispatch(
              axiosThunk({ url: API_SERVER.receiptManagement.getDetail(receiptId).url }),
            );
            setReceiptToPrint(detailRes?.data);
          }
          methods.reset({
            paymentMethod: PAYMENT_METHOD.CASH.value,
            product: null,
            groupProduct: null,
            customerName: intl.formatMessage({ id: 'calendar.person' }),
            phoneNumber: null,
            medicalFacility: null,
            doctor: null,
            encounterId: null,
            note: null,
            discount: null,
          });
        }
      }
      close();
    },
    [
      promptConfirmation,
      intl,
      close,
      dispatch,
      axiosThunk,
      API_SERVER.sale,
      API_SERVER.receiptManagement,
      openSnackbar,
    ],
  );

  // call api receipt detail
  useEffect(() => {
    if (locationStateId) {
      const async = async () => {
        let receiptId;
        receiptId = locationStateId;
        const detailRes = await dispatch(axiosThunk({ url: API_SERVER.receiptManagement.getDetail(receiptId).url }));
        setReceiptDetail(detailRes?.data);
      };
      async();
    }
  }, [API_SERVER.receiptManagement, axiosThunk, dispatch, locationStateId]);

  return (
    <Paper sx={{ flex: 1, overflow: 'auto', p: 2 }}>
      <Typography variant="h6" style={{ marginBottom: '25px' }}>
        <FormattedMessage id="navMenu.createOrder" />
      </Typography>
      <OrderForm onSubmit={onSubmit} receiptDetail={receiptDetail} />
      <PrintReceiptDetailDialog
        receiptToPrint={receiptToPrint}
        open={!!receiptToPrint}
        onClose={() => {
          setReceiptToPrint(undefined);
        }}
      />
    </Paper>
  );
};

export default CreateOrderPage;
