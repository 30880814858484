import React, { useCallback, useEffect, useRef } from 'react';
import useSWR from 'swr';
import { axiosThunk } from './modules/common/redux/axios';
import useGeneralHook from './modules/common/hook/useGeneralHook';

const VERSION_KEY = 'version';
const semverGreaterThan = (versionA, versionB) => {
  if (!versionA || !versionB) {
    return false;
  }
  const versionsA = versionA.split(/\./g);

  const versionsB = versionB.split(/\./g);
  while (versionsA.length || versionsB.length) {
    const a = Number(versionsA.shift());

    const b = Number(versionsB.shift());
    // eslint-disable-next-line no-continue
    if (a === b) continue;
    // eslint-disable-next-line no-restricted-globals
    return a > b || isNaN(b);
  }
  return false;
};

const CacheBuster: React.FC<{}> = (props) => {
  const { dispatch, intl } = useGeneralHook();

  const random = useRef(Date.now());
  const { data: metaRes } = useSWR(
    ['/meta.json', random],
    async (url) => {
      const json = await dispatch(axiosThunk({ url }));
      return json.data;
    },
    { refreshInterval: 60000 },
  );

  const refreshCacheAndReload = useCallback(() => {
    console.log('Clearing cache and hard reloading...');
    if (caches) {
      // Service worker cache should be cleared with caches.delete()
      caches.keys().then(function (names) {
        for (let name of names) caches.delete(name);
      });
    }
    alert(intl.formatMessage({ id: 'application.notification.hasNewVersion' }));
    localStorage.setItem(VERSION_KEY, metaRes?.version);
    window.location.href = '/';
  }, [intl, metaRes?.version]);

  useEffect(() => {
    const latestVersion = metaRes?.version;
    const currentVersion = localStorage.getItem(VERSION_KEY) || '0.0.0';
    const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
    if (latestVersion && shouldForceRefresh && !window.location.pathname.includes('/main/appointment-register')) {
      console.log(`We have a new version - ${latestVersion}. Should force refresh`);
      refreshCacheAndReload();
    }
  }, [metaRes, refreshCacheAndReload]);

  return <>{props.children}</>;
};

export default CacheBuster;
