import { Box, Dialog, DialogProps, alpha, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { grey } from '@mui/material/colors';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Close from '@mui/icons-material/Close';
import { DetailedHTMLProps, ImgHTMLAttributes, ReactChild } from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css'; // requires a loader
import { some } from '../constants';

const useStyles = makeStyles((theme) => ({
  closeBtn: {
    position: 'absolute',
    top: 8,
    right: 8,
    background: alpha('#000000', 0.7),
  },
  frame: {
    cursor: 'pointer',
    minHeight: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialog: {
    alignItems: 'center',
    justifyContent: 'center',
    background: 'unset',
  },
  imageDialog: {
    height: '100%',
    width: '100%',
    objectFit: 'contain',
  },
  imagegallery: {
    '& .carousel.carousel-slider': {
      overflow: 'unset',
    },
    '& .carousel .thumbs-wrapper': {
      margin: 0,
    },
  },
  nextButton: {
    background: 'white',
    position: 'absolute',
    top: '50%',
    right: '-48px',
    transform: 'translateY(-50%)',
  },
  prevButton: {
    background: 'white',
    position: 'absolute',
    top: '50%',
    left: '-48px',
    transform: 'translateY(-50%)',
  },
}));

interface Props extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  dialogProps?: DialogProps;
  open?: boolean;
  onClose?: () => void;
  images?: some[];
  index?: number;
}

const ImageGalleryDialog = (props: Props) => {
  const { dialogProps, open, onClose, images, index } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={!!open}
      fullScreen
      PaperProps={{
        className: classes.dialog,
      }}
      {...dialogProps}
      onClose={onClose}
    >
      <IconButton onClick={onClose} className={classes.closeBtn}>
        <Close style={{ color: 'white' }} />
      </IconButton>
      <Box
        position="relative"
        marginX="auto"
        maxWidth={'70vw'}
        onContextMenu={(e) => {
          e.preventDefault();
        }}
      >
        <Carousel
          className={classes.imagegallery}
          showArrows={true}
          infiniteLoop={true}
          swipeable={true}
          selectedItem={index}
          thumbWidth={120}
          renderThumbs={(children) => {
            let array: ReactChild[] = [];
            images?.forEach((item: some, index: number) => {
              array.push(
                <Box
                  key={index}
                  style={{
                    display: 'flex',
                    background: grey[300],
                    height: 70,
                    width: '100%',
                    alignItems: 'center',
                    userSelect: 'none',
                  }}
                >
                  {item?.render}
                </Box>,
              );
            });
            return array;
          }}
          renderArrowNext={(clickHandler) => {
            return (
              <IconButton size="small" className={classes.nextButton} onClick={clickHandler}>
                <ChevronRightIcon />
              </IconButton>
            );
          }}
          renderArrowPrev={(clickHandler) => {
            return (
              <IconButton size="small" className={classes.prevButton} onClick={clickHandler}>
                <ChevronLeftIcon />
              </IconButton>
            );
          }}
        >
          {images?.map((item: some, index: number) => {
            return (
              <Box
                key={index}
                style={{
                  background: grey[300],
                  height: '75vh',
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  userSelect: 'none',
                }}
              >
                {item?.render}
              </Box>
            );
          })}
        </Carousel>
      </Box>
    </Dialog>
  );
};

export default ImageGalleryDialog;
