import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';

export const DiagnosticImageSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    examDepartment: z.string(),
    conclusion: z.string(),
    referenceId: z.number(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    updatedTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
  })
  .partial();

export type DiagnosticImage = z.infer<typeof DiagnosticImageSchema>;
