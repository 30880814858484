import { Box, Button, Switch, Tooltip, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import MediCard from 'modules/common/component/MediCard';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { some } from 'modules/common/constants';
import { NumericFormatText } from 'modules/common/utils';
import { Service } from 'modules/schema';
interface Props {
  data?: some;
  setFormData: (item: some) => void;
  setFormDataGroup: (item: some) => void;
  onChangeStatus: (item: some) => void;
  onDeleteGroup: (item: some) => void;
  onDeleteServices: (services: Service[]) => void;
  loading?: boolean;
  enableDeleteGroup?: boolean;
}

const TherapyTable: React.FunctionComponent<Props> = (props) => {
  const { data, setFormData, setFormDataGroup, onChangeStatus, onDeleteGroup, enableDeleteGroup, loading } = props;

  const columns = React.useMemo(() => {
    return [
      {
        title: 'therapy.code',
        dataIndex: 'code',
        width: '20%',
      },
      {
        title: 'therapy.name',
        dataIndex: 'name',
      },
      {
        title: 'therapy.totalPrice',
        dataIndex: 'totalPrice',
        render: (record) => record.totalPrice && NumericFormatText(record.totalPrice),
      },
      {
        title: 'therapy.allInPrice',
        dataIndex: 'packagePrice',
        render: (record) => record.packagePrice && NumericFormatText(record.packagePrice),
      },
      {
        title: 'therapy.status',
        dataIndex: 'status',
        render: (record) => {
          return (
            <Tooltip title={<FormattedMessage id="status" />}>
              <Switch
                checked={record.status === 'ACTIVE'}
                onChange={(e) =>
                  onChangeStatus({
                    ...record,
                    status: e.target.checked ? 'ACTIVE' : 'INACTIVE',
                  })
                }
              />
            </Tooltip>
          );
        },
      },
      {
        title: 'action',
        width: 120,
        render: (record) => {
          return (
            <>
              {record.status === 'ACTIVE' && (
                <IconButtonTitle
                  title="update"
                  size="small"
                  onClick={() => {
                    setFormData({ ...record, listId: data?.id, listData: data?.items });
                  }}
                  style={{ margin: '0px 8px' }}
                  hidden={true}
                >
                  <EditIcon color={record.validation === false ? 'error' : undefined} />
                </IconButtonTitle>
              )}
            </>
          );
        },
      },
    ] as Columns[];
  }, [setFormData, data?.id, data?.items, onChangeStatus]);

  if (!data) {
    return null;
  }

  data?.items?.sort((a: some, b: some) => a.name.localeCompare(b.name));

  return (
    <MediCard
      style={{ margin: '8px 0px', color: 'white' }}
      title={
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="subtitle1" color="inherit">
              {data.name}
            </Typography>
          </Box>
          <Box display="flex">
            <IconButtonTitle
              title="create"
              size="small"
              color="inherit"
              onClick={() => setFormData({ listId: data.id, listData: data.items })}
            >
              <NoteAddIcon />
            </IconButtonTitle>
            <IconButtonTitle
              title="update"
              size="small"
              color="inherit"
              onClick={() => setFormDataGroup(data)}
              style={{ marginRight: 8, marginLeft: 8 }}
            >
              <EditIcon />
            </IconButtonTitle>
            <IconButtonTitle
              title="delete"
              size="small"
              color="inherit"
              disabled={!!data.items?.length && !enableDeleteGroup}
              onClick={() => {
                onDeleteGroup(data);
              }}
            >
              <DeleteIcon />
            </IconButtonTitle>
          </Box>
        </Box>
      }
    >
      <Box padding={1}>
        <TableCustom
          dataSource={data?.items}
          loading={loading}
          columns={columns}
          caption={
            <Button
              size="large"
              fullWidth
              variant="text"
              startIcon={<NoteAddIcon />}
              color="primary"
              onClick={() => setFormData({ listId: data.id, listData: data.items })}
            >
              <Typography variant="subtitle2">
                <FormattedMessage id="therapy.addNewInList" />
              </Typography>
            </Button>
          }
        />
      </Box>
    </MediCard>
  );
};

export default TherapyTable;
