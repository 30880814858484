import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';

interface UseTablePaginationParams {
  defaultPage?: number;
  defaultPageSize?: number;
  toUrl?: boolean;
}

const PAGE_KEY = 'page';
const PAGE_SIZE_KEY = 'pageSize';

export function useTablePagination(paginationParams?: UseTablePaginationParams) {
  const { defaultPageSize = 20, defaultPage = 0, toUrl = false } = paginationParams || {};
  const history = useHistory();
  const location = useLocation();
  const urlSearchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
  const [page, setPage] = useState(defaultPage);
  const [pageSize, setPageSize] = useState(defaultPageSize);

  useEffect(() => {
    if (toUrl) {
      const pageUrl = parseInt(urlSearchParams.get(PAGE_KEY) || String(defaultPage));
      const pageSizeUrl = parseInt(urlSearchParams.get(PAGE_SIZE_KEY) || String(defaultPageSize));
      if (page !== pageUrl) {
        setPage(pageUrl);
      }
      if (pageSize !== pageSizeUrl) {
        setPageSize(pageSizeUrl);
      }
    }
  }, [defaultPage, defaultPageSize, page, pageSize, toUrl, urlSearchParams]);

  const onPageChange = useCallback(
    (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
      if (toUrl) {
        const urlSearchParams = new URLSearchParams(location.search);
        if (urlSearchParams.has(PAGE_KEY)) {
          urlSearchParams.delete(PAGE_KEY);
        }
        urlSearchParams.append(PAGE_KEY, String(page));
        history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
      }
    },
    [history, location.pathname, location.search, page, toUrl],
  );

  const onPageSizeChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setPageSize(parseInt(event.target.value));
      setPage(0);

      if (toUrl) {
        const urlSearchParams = new URLSearchParams(location.search);
        if (urlSearchParams.has(PAGE_KEY)) {
          urlSearchParams.delete(PAGE_KEY);
        }
        if (urlSearchParams.has(PAGE_SIZE_KEY)) {
          urlSearchParams.delete(PAGE_SIZE_KEY);
        }
        urlSearchParams.append(PAGE_KEY, '0');
        urlSearchParams.append(PAGE_SIZE_KEY, event.target.value);
        history.push({ pathname: location.pathname, search: urlSearchParams.toString() });
      }
    },
    [history, location.pathname, location.search, toUrl],
  );

  const onResetPageAndPageSize = useCallback(() => {
    setPageSize(defaultPageSize);
    setPage(defaultPage);
  }, [defaultPage, defaultPageSize]);

  return {
    page,
    pageSize,
    paginationProps: {
      page,
      rowsPerPage: pageSize,
      onPageChange,
      onRowsPerPageChange: onPageSizeChange,
    },
    resetPage: onResetPageAndPageSize,
  };
}
