import * as React from 'react';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { some } from 'modules/common/constants';
import { FormattedNumber } from 'react-intl';
import { DISCOUNT_TYPE } from 'modules/admin/component/saleManagement/constant';
import { MEDICATION_UNIT } from 'modules/admin/component/inventory/constant';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { NumericFormatText } from 'modules/common/utils';

interface Props {
  data?: some[];
}

const PrintReceiptDataTable: React.FunctionComponent<Props> = (props) => {
  const { data } = props;
  const { intl } = useGeneralHook();

  const columns: Columns[] = [
    {
      dataIndex: 'medicationName',
      title: 'sellingProduct.title.productName',
    },
    {
      render: (record) => {
        const unit = MEDICATION_UNIT.find((v) => v.value === record.unit)?.label;
        return unit ? `${record.quantity || 0} ${intl.formatMessage({ id: unit })}` : record.quantity;
      },
      title: 'printForm.SL',
      align: 'center',
    },
    {
      render: (record) => NumericFormatText(record?.unitPrice || 0),
      title: 'sellingProduct.title.unitPrice',
      align: 'center',
    },
    {
      render: (record) =>
        record?.discountType === 'PERCENT' ? (
          `${record?.discount || 0}${DISCOUNT_TYPE.find((v) => v.value === record?.discountType)?.name}`
        ) : (
          <FormattedNumber value={record?.discount || 0} />
        ),
      title: 'sellingProduct.title.discount',
      align: 'center',
    },
    {
      // dataIndex: 'vat',
      render: (record) => record.vat || 0,
      title: 'sellingProduct.title.VAT',
      align: 'center',
    },
    {
      render: (record) => NumericFormatText(record ? Math.abs(record.totalAmount) : 0),
      title: 'sellingProduct.title.totalAmount',
      align: 'right',
    },
  ];

  return (
    <TableCustom
      containerProps={{
        sx: {
          '& th,tr,td': {
            padding: '2px 8px',
            backgroundColor: 'white',
            border: '0.5px solid',
            color: 'unset',
          },
          width: '100%',
          overflow: 'visible',
        },
      }}
      dataSource={data}
      columns={columns}
    />
  );
};

export default PrintReceiptDataTable;
