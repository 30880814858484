import { Box, Stack, Typography } from '@mui/material';
import DateInput from 'modules/common/component/form/DateInput';
import { NumericFormatText, formatEndDate, formatStartDate, isValidDate } from 'modules/common/utils';
import moment from 'moment';
import React, { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import Filter from 'modules/common/component/filter';
import Report from 'modules/common/component/report';
import { useDialog, useFetch } from 'modules/common/hook';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, PAYMENT_PLAN, ROLES } from 'modules/common/constants';
import { GROUP_VERSION, INDICATION_REQUEST_TYPE, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { FilterType } from 'modules/common/component/filter/type';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReportFilterOption } from 'modules/common/component/report/type';
import { DEFAULT_CONFIG } from 'modules/common/component/filter/const';
import { IndicationResultReport } from 'modules/schema';
import { ValueType } from 'modules/common/type';
import SelectInput from 'modules/common/component/form/SelectInput';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { SPECIALIST } from 'modules/layout/constants';
import { intersection, map } from 'lodash';
import { WORK_API } from 'modules/common/service';
import { VIEW_ENCOUNTER } from '../../const';
import { duplicateTab } from 'modules/common/component/Encounter/utils';
import { ROUTES } from 'modules/layout/router';

interface FormSchema {
  type?: ValueType<typeof INDICATION_REQUEST_TYPE>[];
  fromDate: string;
  toDate: string;
  keyword: string;
}

const FILTER_KEY = 'MEDICAL_REPORT_FILTER';
const FILTER_KEY_VALUE = 'MEDICAL_REPORT_FILTER_VALUE';
const SELECT_COLUMNS_KEY = 'MEDICAL_REPORT_SELECT_COLUMNS';

interface SearchParams extends FormSchema {
  scope?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
  isFrees?: boolean[];
  picUserIds?: number[];
  indicationRequestIds?: number[];
  medicationCategories?: string[];
}

const DEFAULT_VALUES = {
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatEndDate(moment()),
  keyword: '',
  type: [],
};

const MedicalReportTab: React.FunctionComponent = () => {
  const isBasic = useSelector((state: AppState) => state.authen.user?.group_version === GROUP_VERSION.BASIC);
  const filterConfig = JSON.parse(localStorage.getItem(FILTER_KEY_VALUE)!);
  const intl = useIntl();
  const allOptions = intl.formatMessage({ id: 'all' });
  const { page, pageSize, paginationProps, resetPage } = useTablePagination();
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const [searchParams, setSearchParams] = useState<SearchParams>(DEFAULT_VALUES);
  const [filterReportOptions, setFilterReportOptions] = useState<ReportFilterOption[]>([]);

  const FILTER_CONFIGS_MEDICAL: FilterType[] = [
    {
      type: 'INDICATION_REQUEST_SCOPE',
      options: isBasic
        ? [SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE, SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE]
        : [
            SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE,
            SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE,
            SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE,
            SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE,
          ],
    },
    {
      type: 'PAYMENT_PLAN',
      options: [PAYMENT_PLAN.FEE, PAYMENT_PLAN.FREE],
      title: 'tollStatus',
    },
    {
      type: 'SERVICE',
      scopes: [
        SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE.value,
        SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
        SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
        SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value,
      ],
      title: 'encounterDetail.indicationName',
      placeholder: 'filterAsIndication',
    },
    {
      type: 'MEDICATION_CATEGORY',
    },
  ];

  const form = useForm<FormSchema>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });
  const fromDate = formatStartDate(form.watch('fromDate'));
  const toDate =
    moment(form.watch('toDate')) < moment()
      ? formatEndDate(moment(form.watch('toDate')).add(-1, 'day'))
      : formatEndDate(moment());
  const type = form.watch('type');
  const roles = useSelector((state: AppState) => state.authen.user?.role);
  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const serviceTypeOptions: ValueType<typeof INDICATION_REQUEST_TYPE>[] = useMemo(() => {
    return isDentalClinic
      ? [INDICATION_REQUEST_TYPE.DENTAL_EXAM, INDICATION_REQUEST_TYPE.THERAPY_SESSION]
      : [INDICATION_REQUEST_TYPE.ENCOUNTER, INDICATION_REQUEST_TYPE.THERAPY_SESSION];
  }, [isDentalClinic]);
  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      page: page,
      pageSize: pageSize,
      fromDate: fromDate,
      toDate: toDate,
      type: type ? map(type, 'value') : map(serviceTypeOptions, 'value'),
    };
  }, [fromDate, page, pageSize, searchParams, serviceTypeOptions, toDate, type]);

  const { data: reportSupply } = useFetch(WORK_API.getMedicationUsagePaging(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'TABLE'),
  });
  const { data: printDataSupply } = useFetch(
    WORK_API.getMedicationUsagePaging({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    {
      globalLoading: true,
      revalidateOnFocus: false,
      enabled: openPrintDialog && isValidDate(fromDate, toDate, 'TABLE'),
    },
  );

  const checkView = useMemo(() => {
    return intersection(VIEW_ENCOUNTER, roles).length;
  }, [roles]);

  const exportUrl = React.useMemo(
    () => WORK_API.getMedicationUsageExcel({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    [formatSearchParams],
  );

  const checkViewImageAndLab = useMemo(() => {
    return intersection(
      [
        ROLES.RADIOLOGIST.value,
        ROLES.CLINICAL_PATHOLOGIST.value,
        ROLES.RADIOGRAPHER.value,
        ROLES.LABORATORY_TECHNOLOGIST.value,
      ],
      roles,
    ).length;
  }, [roles]);

  React.useEffect(() => {
    setFilterReportOptions([
      { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      {
        title: DEFAULT_CONFIG.SERVICE.title,
        value:
          type && type.length !== 0 ? type.map((ele) => intl.formatMessage({ id: ele.label })).join(', ') : allOptions,
      },
      {
        title: DEFAULT_CONFIG.INDICATION_REQUEST_SCOPE.title,
        value:
          filterConfig?.INDICATION_REQUEST_SCOPE?.length === 0
            ? allOptions
            : filterConfig?.INDICATION_REQUEST_SCOPE.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
      },
      {
        title: DEFAULT_CONFIG.PAYMENT_PLAN.title,
        value:
          filterConfig?.PAYMENT_PLAN?.length === 0
            ? allOptions
            : filterConfig?.PAYMENT_PLAN.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
      },
      {
        title: DEFAULT_CONFIG.EMPLOYEE.title,
        value:
          filterConfig?.EMPLOYEE?.length === 0
            ? allOptions
            : filterConfig?.EMPLOYEE.map((ele) => ele.employeeFullName).join(', '),
      },
      {
        title: 'encounterDetail.indicationName',
        value:
          filterConfig?.SERVICE?.length === 0
            ? allOptions
            : filterConfig?.SERVICE.map((ele) => intl.formatMessage({ id: ele.name })).join(', '),
      },
      {
        title: DEFAULT_CONFIG.MEDICATION_CATEGORY.title,
        value:
          filterConfig?.MEDICATION_CATEGORY?.length === 0
            ? allOptions
            : filterConfig?.MEDICATION_CATEGORY.map((ele) => intl.formatMessage({ id: ele.name })).join(', '),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions, fromDate, intl, toDate, type]);
  const COLUMNS: Column<IndicationResultReport>[] = [
    { type: 'INDEX', width: '2%', sticky: true, align: 'center' },
    {
      render: (record) => record?.createdTime?.format(FE_DATE_FORMAT),
      title: 'drugDispenseBook.usingDate',
      selectColumnConfig: {
        defaultChecked: true,
        disabled: true,
      },
      width: '8%',
    },
    {
      render: 'medicationCategory',
      title: 'encounterPrescription.groupDrug',
      selectColumnConfig: {
        defaultChecked: true,
        disabled: true,
      },
      fieldName: 'medicationCategory',
      enableEllipsis: true,
      width: '8%',
      maxWidth: '100px',
    },
    {
      render: 'medicationName',
      title: 'drugUsage.drugName',
      selectColumnConfig: {
        defaultChecked: true,
        disabled: true,
      },
      fieldName: 'medicationName',
      enableEllipsis: true,
      width: '10%',
      maxWidth: '120px',
    },
    {
      render: 'ingredient',
      title: 'drugDispenseBook.ingredients',
      selectColumnConfig: {
        defaultChecked: true,
        disabled: true,
      },
      fieldName: 'ingredient',
      enableEllipsis: true,
      width: '10%',
      maxWidth: '120px',
    },
    {
      align: 'center',
      render: 'quantity',
      title: 'quantity',
      selectColumnConfig: {
        defaultChecked: true,
        disabled: true,
      },
      width: '8%',
    },
    {
      render: 'unit',
      title: 'encounterPrescription.unit',
      selectColumnConfig: {
        defaultChecked: true,
      },
      width: '8%',
    },
    {
      render: (record) => NumericFormatText(record?.price),
      title: 'price',
      selectColumnConfig: {
        defaultChecked: true,
      },
      width: '8%',
    },
    {
      render: (record) => NumericFormatText((record?.quantity || 0) * (record?.price || 0)),
      title: 'drugDispenseBook.totalPrice',
      selectColumnConfig: {
        defaultChecked: true,
      },
      width: '8%',
    },
    {
      render: 'indicationRequestName',
      title: 'encounterDetail.indicationName',
      selectColumnConfig: {
        defaultChecked: true,
      },
      fieldName: 'indicationRequestName',
      enableEllipsis: true,
      width: '10%',
      maxWidth: '120px',
    },
    {
      render: (record) => {
        if (checkViewImageAndLab > 0 && record.type === 'THERAPY_SESSION') {
          return (
            <Typography
              fontSize={'14px'}
              onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                event.preventDefault();
                duplicateTab(ROUTES.therapyDetail.gen(record.therapyId!));
              }}
              color={'primary'}
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {record.referenceCode}
            </Typography>
          );
        } else if (checkView > 0) {
          return (
            <Typography
              fontSize={'14px'}
              onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                event.preventDefault();
                if (record.type === 'THERAPY_SESSION') {
                  duplicateTab(ROUTES.therapyDetail.gen(record.therapyId!));
                } else if (record.type === 'DENTAL_EXAM') {
                  duplicateTab(ROUTES.dentalDetail.gen(record.referenceId!, record.patientId!));
                } else {
                  duplicateTab(ROUTES.encounterDetail.gen(record.referenceId!, record.patientId!));
                }
              }}
              color={'primary'}
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {record.referenceCode}
            </Typography>
          );
        } else {
          return <Typography fontSize={'14px'}> {record.referenceCode}</Typography>;
        }
      },
      title: 'dental.dentalDetail.sections.examinationHistories.code.title',
      selectColumnConfig: {
        defaultChecked: true,
      },
      width: '10%',
      maxWidth: '120px',
    },
    {
      render: (record) => record?.patientName,
      title: 'patientName',
      selectColumnConfig: {
        defaultChecked: false,
      },
      fieldName: 'patientName',
      enableEllipsis: true,
      width: '10%',
      maxWidth: '120px',
    },
    {
      render: (record) => {
        return (
          <Typography fontSize={14}>
            <FormattedMessage id={record.isFree ? 'common.label.free' : 'encounterBook.billable'} />
          </Typography>
        );
      },
      title: 'tollStatus',
      selectColumnConfig: {
        defaultChecked: true,
      },
      width: '10%',
    },
  ];
  const [selectedColumns, setSelectedColumn] = useState<Column<IndicationResultReport>[]>(
    COLUMNS?.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );

  return (
    <Report>
      <Stack direction="column" gap={2}>
        <Report.Title
          title="report.workReport.tab.title.medicalReport"
          showSubTitle
          foundResultAmount={reportSupply?.content.length || 0}
          totalElementAmount={reportSupply?.pagination.totalElements}
          renderRight={
            <Stack direction="row" alignItems="center" justifyContent="center" gap="16px">
              <Report.SearchInput
                form={form}
                name="keyword"
                onSearch={() => {
                  setSearchParams((prev) => ({ ...prev, keyword: form.getValues('keyword') }));
                  resetPage();
                }}
              />
            </Stack>
          }
        />
        <Filter
          filters={FILTER_CONFIGS_MEDICAL}
          onSave={(filterValue, filterConfig) => {
            setSearchParams((prev) => ({
              ...prev,
              scopes: filterValue.INDICATION_REQUEST_SCOPE,
              isFrees: filterValue.PAYMENT_PLAN.map((ele) => (ele === PAYMENT_PLAN.FREE.value ? true : false)),
              indicationRequestIds: filterValue.SERVICE,
              medicationCategories: filterConfig.MEDICATION_CATEGORY.map((ele) => ele.name!),
            }));
            setFilterReportOptions([
              { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
              { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
              {
                title: DEFAULT_CONFIG.SERVICE.title,
                value:
                  type && type.length !== 0
                    ? type.map((ele) => intl.formatMessage({ id: ele.label })).join(', ')
                    : allOptions,
              },
              {
                title: DEFAULT_CONFIG.INDICATION_REQUEST_SCOPE.title,
                value:
                  filterConfig.INDICATION_REQUEST_SCOPE?.length === 0
                    ? allOptions
                    : filterConfig.INDICATION_REQUEST_SCOPE.map((ele) => intl.formatMessage({ id: ele.label })).join(
                        ', ',
                      ),
              },
              {
                title: DEFAULT_CONFIG.PAYMENT_PLAN.title,
                value:
                  filterConfig.PAYMENT_PLAN?.length === 0
                    ? allOptions
                    : filterConfig.PAYMENT_PLAN.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
              },
              {
                title: DEFAULT_CONFIG.EMPLOYEE.title,
                value:
                  filterConfig.EMPLOYEE?.length === 0
                    ? allOptions
                    : filterConfig.EMPLOYEE.map((ele) => ele.employeeFullName).join(', '),
              },
              {
                title: 'encounterDetail.indicationName',
                value:
                  filterConfig.SERVICE?.length === 0
                    ? allOptions
                    : filterConfig.SERVICE.map((ele) => intl.formatMessage({ id: ele.name })).join(', '),
              },
              {
                title: DEFAULT_CONFIG.MEDICATION_CATEGORY.title,
                value:
                  filterConfig.MEDICATION_CATEGORY?.length === 0
                    ? allOptions
                    : filterConfig.MEDICATION_CATEGORY.map((ele) => intl.formatMessage({ id: ele.name })).join(', '),
              },
            ]);
          }}
          localStorageKey={FILTER_KEY}
        >
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack direction="column" gap="10px">
              <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
                <SelectInput
                  form={form}
                  name="type"
                  options={serviceTypeOptions}
                  getValue="value"
                  renderLabel="label"
                  label="serviceTypes"
                  placeholder="serviceTypes"
                  multiple
                  showCheckBox
                  onChange={resetPage}
                />
                <DateInput
                  form={form}
                  name="fromDate"
                  label="fromDate"
                  mode="start"
                  required
                  format={FE_DATE_FORMAT}
                  maxDate={moment() && moment(toDate)}
                  type="TABLE"
                  onChange={resetPage}
                />
                <DateInput
                  form={form}
                  name="toDate"
                  label="toDate"
                  mode="end"
                  maxDate={moment()}
                  minDate={moment(fromDate).add(+1, 'day')}
                  required
                  format={FE_DATE_FORMAT}
                  type="TABLE"
                  onChange={resetPage}
                />
                <Box paddingTop={'11px'}>
                  <Report.RefreshButton
                    onClick={() => {
                      form.reset();
                      setSearchParams(DEFAULT_VALUES);
                    }}
                  />
                </Box>
              </Stack>
              <Filter.View />
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
              <Filter.Button />
              <Report.SelectColumnButton
                columns={COLUMNS}
                onSave={setSelectedColumn}
                localStorageKey={SELECT_COLUMNS_KEY}
              />
              <Report.ExportDataButton
                exportUrl={exportUrl}
                fileName={`Medi_So-phat-thuoc_${moment(fromDate).format(BE_DATE_FORMAT)}-${moment(toDate).format(
                  BE_DATE_FORMAT,
                )}.xlsx`}
              />
              <Report.PrintDataButton
                title="report.workReport.tab.title.medicalReport"
                openDialog={openPrintDialog}
                onOpenDialog={onOpenPrintDialog}
                onCloseDialog={onClosePrintDialog}
                content={
                  <Stack direction="column" gap={1}>
                    <Report.FilterPrintFormat filterOptions={filterReportOptions} />
                    <Table
                      data={printDataSupply?.content}
                      columns={selectedColumns}
                      dataKey="id"
                      rowProps={{ sx: { pageBreakAfter: 'auto' } }}
                    />
                  </Stack>
                }
              />
            </Stack>
          </Stack>
        </Filter>
        <Table
          columns={selectedColumns}
          dataKey="id"
          data={reportSupply?.content}
          paginationProps={paginationProps}
          totalElements={reportSupply?.pagination.totalElements}
          stickyHeader
          maxHeigth="100%"
        />
      </Stack>
    </Report>
  );
};

export default MedicalReportTab;
