import * as React from 'react';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import SchemaForm from 'modules/common/SchemaForm';
import { Box, InputLabel, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { DECIMAL_NUMBER_REGEX } from 'modules/common/regex';
import DialogCustom from 'modules/common/component/DialogCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, some } from 'modules/common/constants';
import { UseFormReturn } from 'react-hook-form';
import { IUiFields } from 'modules/common/SchemaForm/utils';
import moment from 'moment/moment';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open: boolean;
  onClose?: () => void;
  medication: some | null;
  lot: some | null;
}

const SchemaFormContent: React.FunctionComponent<some> = (props) => {
  const { onSubmit, onClose, lot } = props;
  const { intl } = useGeneralHook();

  return (
    <>
      <SchemaForm
        onSubmit={onSubmit}
        initialData={{ ...lot }}
        onCancel={onClose}
        formId="medication-unit-setting"
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
        schema={{
          fields: ({ formProps, valuesField }) => {
            return {
              name: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="inventory.unitSetting.dialog.productName" />
                      </InputLabel>
                      <Typography>{lot?.name}</Typography>
                    </div>
                  );
                },
                propsWrapper: { xs: 3 },
                unregister: true,
              },
              code: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="ID" />
                      </InputLabel>
                      <Typography>{lot?.medicationId}</Typography>
                    </div>
                  );
                },
                propsWrapper: { xs: 3 },
                unregister: true,
              },
              lot: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="inventory.unitSetting.dialog.lotNumber" />
                      </InputLabel>
                      <Typography>{lot?.lot}</Typography>
                    </div>
                  );
                },
                propsWrapper: { xs: 3 },
                unregister: true,
              },
              expiredDate: {
                type: () => {
                  return (
                    <div>
                      <InputLabel>
                        <FormattedMessage id="expiredDate" />
                      </InputLabel>
                      <Typography>{moment(lot?.expiredDate, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>
                    </div>
                  );
                },
                propsWrapper: { xs: 3 },
                unregister: true,
              },
              basicUnit: {
                type: () => {
                  return (
                    <div>
                      <InputLabel sx={{ marginBottom: '11px', textAlign: 'center' }}>
                        <FormattedMessage id="inventory.alert.label.unit" />
                      </InputLabel>
                      <Typography sx={{ textAlign: 'center' }}>
                        {intl.formatMessage({ id: lot?.basicSetting?.unit })}
                      </Typography>
                    </div>
                  );
                },
                propsWrapper: { xs: 3 },
                unregister: true,
                noHelperText: true,
              },
              basicPrice: {
                type: 'number-field',
                inputType: 'number',
                label: intl.formatMessage({ id: 'pharmacy.sellingPrice' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
                propsWrapper: { xs: 5 },
                register: {
                  required: true,
                },
                noHelperText: false,
              },
              unitConverterList: {
                type: 'array',
                disableCloseBtn: true,
                hiddenNodata: false,
                paperItemProps: { style: { padding: 0 }, variant: 'elevation', elevation: 0 },
                addable: false,
                schema: {
                  fields: ({ formProps, valuesField: unitConverterForm }) => {
                    const {
                      arrayData: { value },
                    } = formProps;
                    return {
                      quantity: {
                        unregister: true,
                        type: () => {
                          return (
                            <div style={{ height: 53 }}>
                              <InputLabel>
                                <FormattedMessage id="quantity" />
                              </InputLabel>
                              <Typography mt={1.5} textAlign="center">
                                {1}
                              </Typography>
                            </div>
                          );
                        },
                        propsWrapper: {
                          xs: 3,
                          style: { justifyContent: 'center', display: 'flex' },
                        },
                        noHelperText: true,
                      },
                      unit: {
                        unregister: true,
                        type: () => {
                          return (
                            <div style={{ height: 53 }}>
                              <InputLabel sx={{ textAlign: 'center', marginBottom: '11px' }}>
                                <FormattedMessage id="inventory.alert.label.unit" />
                              </InputLabel>
                              <Typography mt={1.5} textAlign="center">
                                {intl.formatMessage({ id: value?.unit })}
                              </Typography>
                            </div>
                          );
                        },
                        label: intl.formatMessage({ id: 'inventory.unit' }),
                        propsWrapper: { xs: 4 },
                        noHelperText: true,
                      },
                      price: {
                        type: 'number-field',
                        inputType: 'number',
                        label: intl.formatMessage({ id: 'pharmacy.sellingPrice' }),
                        placeholder: intl.formatMessage({ id: 'enter' }),
                        propsWrapper: { xs: 5 },
                        register: {
                          required: true,
                          maxLength: {
                            value: 16,
                            message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                          },
                          pattern: DECIMAL_NUMBER_REGEX,
                        },
                        noHelperText: false,
                      },
                      label_1: {
                        unregister: true,
                        type: () => {
                          return (
                            <div style={{ height: 53 }}>
                              <InputLabel>
                                <FormattedMessage id="pharmacy.equivalent" />
                              </InputLabel>
                              <Typography mt={1.5} textAlign="center">
                                {'='}
                              </Typography>
                            </div>
                          );
                        },
                        propsWrapper: {
                          xs: true,
                        },
                      },
                      factor: {
                        unregister: true,
                        type: () => {
                          return (
                            <div style={{ height: 53 }}>
                              <InputLabel sx={{ textAlign: 'center', marginBottom: '11px' }}>
                                <FormattedMessage id="quantity" />
                              </InputLabel>
                              <Typography mt={1.5} textAlign="center">
                                {value?.factor}
                              </Typography>
                            </div>
                          );
                        },
                        propsWrapper: {
                          xs: 5,
                        },
                      },
                      basicUnit: {
                        unregister: true,
                        type: () => {
                          return (
                            <div style={{ height: 53 }}>
                              <InputLabel sx={{ textAlign: 'center', marginBottom: '11px' }}>
                                <FormattedMessage id="inventory.alert.label.unit" />
                              </InputLabel>
                              <Typography mt={1.5} textAlign="center">
                                {intl.formatMessage({ id: lot?.basicSetting?.unit })}
                              </Typography>
                            </div>
                          );
                        },
                        propsWrapper: {
                          xs: 7,
                        },
                      },
                    };
                  },
                  ui: [
                    {
                      id: 'quantity',
                      propsWrapper: { xs: true },
                      paperProps: {
                        sx: {
                          border: 'none',
                          bgcolor: 'grey.200',
                          p: 2,
                          pb: 1,
                        },
                      },
                      paper: true,
                      fields: ['quantity', 'unit', 'price'],
                    },
                    {
                      id: 'label_1',
                      propsWrapper: { xs: undefined },
                      paperProps: {
                        sx: {
                          border: 'none',
                          bgcolor: 'grey.200',
                          p: 2,
                          pb: 2.5,
                        },
                      },
                      paper: true,
                      fields: ['label_1'],
                    },
                    {
                      id: 'factor',
                      propsWrapper: { xs: 4 },
                      paperProps: {
                        sx: {
                          border: 'none',
                          bgcolor: 'grey.200',
                          p: 2,
                          pb: 2.5,
                        },
                      },
                      paper: true,
                      fields: ['factor', 'basicUnit'],
                    },
                  ],
                },
              },
            };
          },
          ui: ui,
        }}
      />
    </>
  );
};

const ui: IUiFields = ({ formProps }) => {
  const {
    intl,
    initialData: { unitConverterList = [] },
  } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'inventory.unitSetting.dialog.productInformation' }),
      fields: ['name', 'code', 'lot', 'expiredDate'],
    },
    {
      id: 'unitConversionLabel',
      title: (
        <Box>
          <Typography variant="h6" style={{ flex: 1 }}>
            <FormattedMessage id="inventory.alert.label.unitExchange" />
          </Typography>
          <InputLabel style={{ marginTop: 16, marginBottom: '-5px' }}>
            <FormattedMessage id="inventory.alert.label.basicUnit" />
          </InputLabel>
        </Box>
      ),
    },
    {
      id: 'unitConversion',
      fields: ['basicUnit', 'basicPrice'],
      paperProps: {
        sx: {
          border: 'none',
          bgcolor: 'grey.200',
          p: 2,
          width: '48%',
        },
      },
      paper: true,
    },
    {
      id: 'unitConversion',
      hidden: !unitConverterList || unitConverterList?.length === 0,
      title: (
        <Box display={'flex'}>
          <InputLabel style={{ flex: 1 }}>
            <FormattedMessage id="pharmacy.formUnit" />
          </InputLabel>
          <InputLabel style={{ flex: 0.47 }}>
            <FormattedMessage id="pharmacy.toUnit" />
          </InputLabel>
        </Box>
      ),
      fields: ['unitConverterList'],
    },
  ];
};
const MedicationUnitSettingDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, lot, onSubmit } = props;
  return (
    <DialogCustom
      open={open}
      maxWidth="xs"
      keepMounted={false}
      disableCloseOutSide
      disableEscapeKeyDown
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 800 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={'inventory.unitSetting.dialog.medicationSetting'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent onSubmit={onSubmit} onClose={onClose} lot={lot} />
      </Box>
    </DialogCustom>
  );
};
export default MedicationUnitSettingDialog;
