import moment from 'moment';
import { BE_DATE_TIME_FORMAT } from '../../../../constants';
import { formatEndDate } from '../../../../utils';
import { FilterType } from '../../../../component/filter/type';
import { PAYMENT_METHOD, RECEIPT_TYPE } from '../../../../apiConstants';
import { ValueType } from '../../../../type';
import { TIME_REPORT_FILTER } from '../../../../component/report/consts';

export const DEFAULT_VALUES = {
  keyword: '',
  receiptType: [],
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatEndDate(moment()),
};

export interface FormSchema {
  fromDate: string;
  toDate: string;
  receiptType?: ValueType<typeof RECEIPT_TYPE>[];
  keyword?: string;
}

export interface FormSchemaChart {
  fromDate: string;
  toDate: string;
  receiptType?: ValueType<typeof RECEIPT_TYPE>[];
  keyword?: string;
  groupReportType?: keyof typeof TIME_REPORT_FILTER;
}

export const FILTER_KEY = 'SALE_REVENUE_REPORT_FILTER';
export const FILTER_KEY_VALUE = 'SALE_REVENUE_REPORT_FILTER_VALUE';
export const SELECT_COLUMNS_KEY = 'SALE_REVENUE_REPORT_SELECT_COLUMNS';

export const FILTER_CONFIGS: FilterType[] = [
  {
    type: 'PAYMENT_METHODS',
    options: [PAYMENT_METHOD.BANK_TRANSFER, PAYMENT_METHOD.CARD, PAYMENT_METHOD.CASH],
    xs: 4,
  },
  {
    type: 'MEDICATION',
  },
];
