import { some } from 'modules/common/constants';
import { Box, Typography } from '@mui/material';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import * as React from 'react';
import { useMemo } from 'react';

interface Props {
  indicationLabs: some[];
}

export const INDICATION_LAB_LIST_PRINT_FORM_COLUMNS: Columns[] = [
  {
    dataIndex: 'serviceIndex',
    title: 'stt',
    default: true,
    width: 40,
  },
  {
    dataIndex: 'laboratoryReferenceName',
    title: 'indication.indicationList.lab.printForm.indicationName.title',
    default: true,
    props: (record) => ({
      colSpan: record.serviceIndex ? 4 : 1,
      align: record.serviceIndex ? 'center' : 'left',
    }),
  },
  {
    dataIndex: 'result',
    title: 'indication.indicationList.lab.printForm.result.title',
    default: true,
    props: (record) => ({
      colSpan: record?.serviceIndex ? 0 : 1,
    }),
  },
  {
    dataIndex: 'laboratoryReferenceDetailObject.unit',
    title: 'indication.indicationList.lab.printForm.unit.title',
    default: true,
    props: (record) => ({
      colSpan: record?.serviceIndex ? 0 : 1,
    }),
  },
  {
    title: 'indication.indicationList.lab.printForm.referenceValue.title',
    default: true,
    props: (record) => ({
      colSpan: record?.serviceIndex ? 0 : 1,
    }),
    render: (record) => {
      return (
        <Typography>
          {record?.laboratoryReferenceDetailObject?.laboratoryConditionList
            ?.map((condition) =>
              condition?.name ? `${condition?.name} : ${condition?.lowIndex || ''} - ${condition.highIndex || ''}` : '',
            )
            .join(';')}
        </Typography>
      );
    },
  },
];
const IndicationTables = ({ indicationLabs }: Props) => {
  const data = useMemo(() => {
    return (
      indicationLabs?.map((indicationLab) => [
        { serviceIndex: 1, laboratoryReferenceName: indicationLab?.indicationRequest?.serviceName },
        ...indicationLab?.indicationLaboratoryList,
      ]) || []
    );
  }, [indicationLabs]);

  return (
    <Box>
      {indicationLabs.map((indicationLab, index) => {
        return (
          <Box>
            <Typography fontWeight={500}>{indicationLab?.indicationRequest?.systemCategoryName}</Typography>
            <TableCustom
              containerProps={{
                sx: {
                  '& th,tr,td': {
                    padding: '2px 8px',
                    backgroundColor: 'white',
                    border: '1px solid #000',
                    color: 'unset',
                  },
                  width: '100%',
                  overflow: 'visible',
                  marginTop: '5px',
                },
              }}
              dataSource={data[index] || []}
              columns={INDICATION_LAB_LIST_PRINT_FORM_COLUMNS}
              hideColumnIndex
            />
          </Box>
        );
      })}
    </Box>
  );
};

export default IndicationTables;
