import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../../common/api';
import { some, SYSTEM_CATEGORY_SCOPE, SYSTEM_CATEGORY_TYPE } from 'modules/common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { setLoading } from '../../../common/redux/commonReducer';
import GroupFormDialog from './GroupFormDialog';
import FormDialog from './FormDialog';
import TherapyListTable from './TherapyListTable';
import { FilterParams } from './SearchTherapy/schema';
import { axiosThunk } from '../../../common/redux/axios';
import { AxiosError } from 'axios';
import { useFetch } from 'modules/common/hook';
import usePriceManage from 'modules/common/hook/usePriceManage';
interface Props {}

const TherapyBox: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const [formData, setFormData] = React.useState<some | undefined>();
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();
  const [searchParams, setSearchParams] = React.useState<FilterParams>({});

  const {
    data: therapyCategory = [],
    isValidating,
    revalidate,
  } = useFetch(API_SERVER.therapyManagement.getList({ ...searchParams }));

  const { onDeleteServices } = usePriceManage({ revalidate, setFormData });

  const { data: serviceCategoryList = [] } = useFetch(
    API_SERVER.service.getAllSystemCategoryList({
      scope: [
        SYSTEM_CATEGORY_SCOPE.surgical,
        SYSTEM_CATEGORY_SCOPE.laboratory,
        SYSTEM_CATEGORY_SCOPE.other,
        SYSTEM_CATEGORY_SCOPE.radiology,
      ],
    }),
  );

  const onSubmitFormData = React.useCallback(
    async (value: some) => {
      const totalPrice = value.detailList?.reduce((v, c) => v + Number(c.price || 0), 0) || 0;
      if (value?.detailList.length === 0) {
        openSnackbar({
          message: intl.formatMessage({ id: 'validate.therapy.session.not.null' }),
          type: 'error',
        });
        return;
      }
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: value?.id ? API_SERVER.therapyManagement.update(value?.id) : API_SERVER.therapyManagement.create(),
          method: value?.id ? 'put' : 'post',
          data: {
            ...value,
            categoryId: value?.listId,
            totalPrice: totalPrice,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
          // revalidate();
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors?.forEach((v) => {
              openSnackbar({
                message: v.message,
                type: 'error',
              });
            });
        })
        .finally(() => {
          dispatch(setLoading(false));
          revalidate();
          setFormData(undefined);
        });
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onSubmitFormDataGroup = React.useCallback(
    async (value: some) => {
      await dispatch(
        axiosThunk({
          url: value?.id ? API_SERVER.systemCategory.update(value?.id) : API_SERVER.systemCategory.create(),
          method: value?.id ? 'put' : 'post',
          data: {
            ...value,
            scope: SYSTEM_CATEGORY_SCOPE.therapy,
            type: SYSTEM_CATEGORY_TYPE.group,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
          // revalidate();
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({
                message: v.message,
                type: 'error',
              });
            });
        })
        .finally(() => {
          dispatch(setLoading(false));
          revalidate();
          setFormDataGroup(undefined);
        });
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onDeleteGroup = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog?.promptConfirmation({
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDeleteServicePrice' }, { name: value.name }),
        warning: true,
      });
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.systemCategory.delete(value?.id),
            method: 'delete',
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
            // revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({
                  message: v.message,
                  type: 'error',
                });
              });
          })
          .finally(() => {
            dispatch(setLoading(false));
            revalidate();
          });
      }
      confirmDialog?.close();
    },
    [confirmDialog, intl, dispatch, openSnackbar, revalidate],
  );

  const onChangeStatus = React.useCallback(
    async (value: some) => {
      if (value.status === 'INACTIVE') {
        const confirm = await confirmDialog?.promptConfirmation({
          warning: false,
          title: intl.formatMessage({ id: 'therapy.confirmInActiveTitle' }),
          content: intl.formatMessage({ id: 'therapy.confirmInActiveMessage' }, { name: value.name }),
          cancelId: 'no',
          okId: 'yes',
        });
        if (confirm) {
          await dispatch(
            axiosThunk({
              url: API_SERVER.therapyManagement.changeStatus(value.id, {
                status: value.status,
              }),
              method: 'put',
            }),
          )
            .then((e) => {
              openSnackbar({ message: intl.formatMessage({ id: 'therapy.changeStatusSuccess' }) });
              revalidate();
            })
            .catch((e: AxiosError<some>) => {
              if (e.response)
                e.response?.data?.errors.forEach((v) => {
                  openSnackbar({
                    message: v.message,
                    type: 'error',
                  });
                });
            });
        }
      } else {
        await dispatch(
          axiosThunk({
            url: API_SERVER.therapyManagement.changeStatus(value.id, {
              status: value.status,
            }),
            method: 'put',
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'therapy.changeStatusSuccess' }) });
            revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({
                  message: v.message,
                  type: 'error',
                });
              });
          });
      }
      confirmDialog?.close();
    },
    [confirmDialog, intl, dispatch, openSnackbar, revalidate],
  );

  const onCancel = React.useCallback(async () => {
    const confirm = await confirmDialog?.promptConfirmation({
      warning: false,
      title: intl.formatMessage({ id: 'common.title.confirm' }),
      content: intl.formatMessage({ id: 'therapy.cancel.confirm.content' }),
      cancelId: 'no',
      okId: 'yes',
    });
    if (confirm) {
      setFormData(undefined);
    }
    confirmDialog.close();
  }, [confirmDialog, intl]);

  return (
    <Paper style={{ flex: 1, overflow: 'auto' }}>
      <TherapyListTable
        loading={isValidating}
        setFormData={setFormData}
        setFormDataGroup={setFormDataGroup}
        onChangeStatus={onChangeStatus}
        onDeleteGroup={onDeleteGroup}
        onDeleteServices={onDeleteServices}
        data={therapyCategory}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        revalidate={revalidate}
      />
      <FormDialog
        open={!!formData}
        formData={formData}
        onSubmit={onSubmitFormData}
        onClose={() => {
          onCancel();
        }}
        serviceCategoryList={serviceCategoryList}
      />
      <GroupFormDialog
        open={!!formDataGroup}
        formData={formDataGroup}
        onSubmit={onSubmitFormDataGroup}
        onClose={() => {
          setFormDataGroup(undefined);
        }}
      />
    </Paper>
  );
};

export default TherapyBox;
