import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import { CARE_PLAN_TYPE, GENDER } from 'modules/common/apiConstants';

export const ExamReportSchema = z
  .object({
    id: z.number(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    examCode: z.string(),
    patientCode: z.string(),
    patientName: z.string(),
    patientType: z.string(),
    dob: z.string(),
    gender: createObjectKeysEnumSchema(GENDER),
    paymentPlan: createObjectKeysEnumSchema(PAYMENT_PLAN),
    icdDiagnosis: z.string(),
    job: z.string(),
    mobilePhone: z.string(),
    email: z.string(),
    picName: z.string(),
    differentialDiagnosis: z.string(),
    carePlanType: createObjectKeysEnumSchema(CARE_PLAN_TYPE),
    carePlanNote: z.string(),
    status: z.string(),
    serviceName: z.string(),
    serviceId: z.number(),
    clinicalExamination: z.string(),
    patientId: z.number(),
  })
  .partial()
  .transform((patient) => {
    return {
      ...patient,
      ...(patient?.patientCode
        ? {
            formatCode: patient?.patientCode?.split('-')?.[1],
          }
        : {}),
    };
  });

export type ExamReport = z.infer<typeof ExamReportSchema>;
