import { Box } from '@mui/system';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import { DentalExam } from 'modules/schema';
import TextValue from 'modules/examDoctor/component/therapy/TherapyIndication/IndicationRequestPrintForm/TextValue';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE, PAYMENT_PLAN } from 'modules/common/constants';

interface Props {
  dentalExam: DentalExam;
}

const PatientInfo = ({ dentalExam }: Props) => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box flex={1.5}>
        <TextValue label="patientId" value={dentalExam?.patient?.code?.split('-')[1] || ''} labelWidth="110px" />
        <TextValue label="name" value={dentalExam?.patient?.name || ''} labelWidth="110px" />
        <TextValue label="telephone" value={dentalExam?.patient?.mobilePhone || ''} labelWidth="110px" />
        <TextValue
          label="category"
          value={PAYMENT_PLAN[dentalExam?.paymentPlan!].label}
          localizationValue
          labelWidth="110px"
        />
      </Box>
      <Box flex={1}>
        <TextValue
          labelWidth="90px"
          label="address"
          value={
            dentalExam?.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address ||
            ''
          }
        />
        <TextValue
          label="birthdayShort"
          value={moment(dentalExam?.patient?.dob, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          labelWidth="90px"
        />
        <TextValue
          label="gender"
          value={dentalExam?.patient?.gender?.toLowerCase() || ''}
          localizationValue
          labelWidth="90px"
        />
      </Box>
    </Stack>
  );
};

export default PatientInfo;
