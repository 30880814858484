import { z } from 'zod';

export const PrescriptionFormDetailTemplateSchema = z
  .object({
    id: z.number(),
    prescriptionFormId: z.number(),
    medicationId: z.number(),
    quantity: z.number(),
    intendedRoute: z.string(),
    usage: z.string(),
  })
  .partial();

export type PrescriptionFormDetailTemplate = z.infer<typeof PrescriptionFormDetailTemplateSchema>;
