import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';

export const AppointmentUpcomingShema = z
  .object({
    id: z.string(),
    startTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    note: z.string(),
    type: z.string(),
  })
  .partial();

export type AppointmentUpcoming = z.infer<typeof AppointmentUpcomingShema>;
