import { Box, Button, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { chain } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../../common/component/DialogCustom';
import { some } from '../../../../common/constants';
import ReferenceIndicationFormDialog from '../../referenceIndication/ReferenceIndicationFormDialog';
import ReferenceListTable from '../../referenceIndication/ReferenceListTable';
import GroupIndicationFormDialog from '../GroupIndicationFormDialog';
import IndicationFormDialog from '../IndicationFormDialog';
import { CORE_TYPE_OF_RESULTS } from '../constants';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import IndicationCSVTable from './IndicationCSVTable';
import { LightTooltip } from 'modules/common/component/table/TableBodyRow';

interface Props {
  onSubmit?: (formVal: some[]) => void;
  open?: boolean;
  isInsurance: boolean;
  onClose?: () => void;
  dataCSV?: some[];
}

const CSVPreviewDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, dataCSV, isInsurance, onSubmit } = props;
  const [data, setData] = React.useState<some[]>([]);
  const [formData, setFormData] = React.useState<some | undefined>();
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();
  const [formDataReference, setFormDataReference] = React.useState<some | undefined>();
  const [referenceData, setReferenceData] = React.useState<some | undefined>();
  const { intl } = useGeneralHook();
  const onDelete = React.useCallback((value: some) => {
    setData((old) => old.filter((val) => val.id !== value.id));
  }, []);

  const onDeleteGroup = React.useCallback((value: some) => {
    setData((old) => old.filter((val) => val.group !== value.name));
  }, []);
  const onDeleteReference = React.useCallback((value: some) => {
    setReferenceData((old) => {
      return {
        ...old,
        items: old?.items?.filter((val) => val.id !== value.id),
      };
    });
  }, []);

  const onSubmitForm = React.useCallback(({ listData, ...value }: some) => {
    if (value.id) {
      setData((old) =>
        old.map((val) => {
          if (val.id === value.id) {
            return value;
          }
          return val;
        }),
      );
    } else {
      setData((old) => [...old, { ...value, group: listData?.[0]?.group }]);
    }
    setFormData(undefined);
  }, []);

  const onSubmitFormGroup = React.useCallback((value: some) => {
    setData((old) =>
      old.map((val) => {
        if (val.group === value.group) {
          return { ...val, group: value.name };
        }
        return val;
      }),
    );
    setFormDataGroup(undefined);
  }, []);

  const onSubmitFormReference = React.useCallback((value: some) => {
    if (value.id) {
      setReferenceData((old) => {
        return {
          ...old,
          items: old?.items?.map((val) => {
            if (val.id === value.id) {
              return value;
            }
            return val;
          }),
        };
      });
    } else {
      setReferenceData((old) => {
        return {
          ...old,
          items: [...old?.items, value],
        };
      });
    }
    setFormDataReference(undefined);
  }, []);

  const onSubmitReference = React.useCallback(() => {
    if (referenceData) {
      setData((old) =>
        old.map((val) => {
          if (val.id === referenceData.id) {
            return referenceData;
          }
          return val;
        }),
      );
    }
    setReferenceData(undefined);
  }, [referenceData]);

  const getValidateReference = React.useCallback((value: some) => {
    if (!value.name || !value.resultType) {
      return false;
    }
    return true;
  }, []);

  const getValidate = React.useCallback(
    (value: some) => {
      if (isInsurance) {
        if (
          !value.code ||
          !value.groupCode ||
          !value.departmentCode ||
          !value.room ||
          !value.device ||
          value.paymentPercent !== undefined
        ) {
          return false;
        }
      }
      if (!value.name || !value.price) {
        return false;
      }

      return true;
    },
    [isInsurance],
  );

  const getValidateItems = React.useCallback(
    (value: some) => {
      if (value.items?.find((item) => !getValidateReference(item))) {
        return false;
      }

      return true;
    },
    [getValidateReference],
  );

  const dataMapped = React.useMemo(() => {
    return chain(
      data.map((val) => ({
        ...val,
        validation: getValidate(val),
        validationItems: getValidateItems(val),
      })),
    )
      .groupBy('group')
      .map((value, key) => ({
        group: key,
        name: key,
        items: value,
      }))
      .value()
      .sort((a: some, b: some) => a.group.localeCompare(b.group));
  }, [data, getValidate, getValidateItems]);

  const referenceDataMapped = React.useMemo(() => {
    return (
      referenceData && {
        ...referenceData,
        items: referenceData?.laboratoryReferences?.map((v) => ({ ...v, validation: getValidateReference(v) })),
      }
    );
  }, [getValidateReference, referenceData]);

  const setInitialData = React.useCallback(() => {
    setData(
      dataCSV
        ? dataCSV
            .slice(1)
            .map((value, index) => {
              const lengthArray = Math.ceil((value.data.length - 9) / 6);
              return {
                id: index + 1,
                group: value.data[0],
                code: value.data[1],
                name: value.data[2],
                specializedDepartmentId: value.data[3] || null,
                physicsRoomId: value.data[4],
                medicalEquipmentId: value.data[5],
                price: value.data[7],
                laboratoryReferences: Array(lengthArray > 0 ? lengthArray : 0)
                  .fill(0)
                  .map((val, index) => {
                    let typeResult = value.data[10 + index * 6];
                    const typeOfResult = CORE_TYPE_OF_RESULTS.find(
                      (val) => intl.formatMessage({ id: val.label }) === typeResult,
                    );

                    return (
                      value.data.slice(8 + index * 6).filter(Boolean).length > 0 && {
                        id: index + 1,
                        code: value.data[8 + index * 6],
                        name: value.data[9 + index * 6],
                        resultType: typeOfResult?.value || 'DATA_INPUT',
                        unit: value.data[11 + index * 6],
                        laboratoryConditionList: value.data[12 + index * 6]?.split(';').map((v) => ({
                          name: v.split(',')[0],
                          lowIndex: v.split(',')[1],
                          highIndex: v.split(',')[2],
                        })),
                        laboratorySelectionList: value.data[13 + index * 6]?.split(',').map((v) => ({
                          name: v,
                        })),
                      }
                    );
                  })
                  .filter(Boolean),
              };
            })
            .filter((v) => v.group)
        : [],
    );
  }, [dataCSV, intl]);

  return (
    <>
      <DialogCustom
        open={!!open}
        maxWidth="xs"
        keepMounted={false}
        onClose={onClose}
        TransitionProps={{
          onEnter: setInitialData,
          onExited: () => setReferenceData(undefined),
        }}
        PaperProps={{
          style: { minWidth: '70vw', overflow: 'hidden' },
        }}
        disableCloseOutSide
        title={
          <Typography variant="h6" color="primary">
            <FormattedMessage id={'CSVPreview'} />
          </Typography>
        }
      >
        <Box padding={2} overflow="auto">
          {referenceData ? (
            <>
              <Box marginBottom={2} display="flex" justifyContent="space-between" alignItems="center">
                <LightTooltip title={referenceData?.name} placement="bottom-start">
                  <Typography
                    variant="h6"
                    style={{
                      minWidth: 120,
                      maxWidth: 300,
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      display: 'block',
                      cursor: 'pointer',
                    }}
                  >
                    {referenceData?.name}
                  </Typography>
                </LightTooltip>
                <Typography variant="body2">
                  <FormattedMessage id="indication.group" /> &emsp;
                  <Typography variant="subtitle2" component="span">
                    {referenceData?.group}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="indication.device" /> &emsp;
                  <Typography variant="subtitle2" component="span">
                    {referenceData?.device?.label}
                  </Typography>
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  startIcon={<Add />}
                  onClick={() => {
                    setFormDataReference({});
                  }}
                >
                  <FormattedMessage id={'add'} />
                </Button>
              </Box>
              <ReferenceListTable
                data={referenceDataMapped?.items}
                onDelete={onDeleteReference}
                setFormData={setFormDataReference}
              />
            </>
          ) : (
            <>
              {dataMapped?.length ? (
                dataMapped?.map((value: some) => {
                  return (
                    <IndicationCSVTable
                      key={value.id}
                      onSubmitForm={setFormData}
                      setFormDataGroup={setFormDataGroup}
                      onDelete={onDelete}
                      onDeleteGroup={onDeleteGroup}
                      data={value}
                      isInsurance={isInsurance}
                      onReference={setReferenceData}
                    />
                  );
                })
              ) : (
                <FormattedMessage id="noData" />
              )}
            </>
          )}
        </Box>
        <Box padding={2} display="flex" justifyContent="flex-end" flex={1}>
          {referenceData ? (
            <>
              <Button
                variant="outlined"
                color="primary"
                style={{ minWidth: 100, marginRight: 16 }}
                onClick={() => {
                  setReferenceData(undefined);
                }}
              >
                <FormattedMessage id={'cancel'} />
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: 100 }}
                disabled={!!referenceDataMapped?.items.find((val) => !val.validation)}
                onClick={onSubmitReference}
              >
                <FormattedMessage id={'save'} />
              </Button>
            </>
          ) : (
            <>
              <Button variant="outlined" color="primary" style={{ minWidth: 100, marginRight: 16 }} onClick={onClose}>
                <FormattedMessage id={'cancel'} />
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ minWidth: 100 }}
                disabled={dataMapped.length === 0 || !!data.find((val) => !getValidate(val))}
                onClick={() => {
                  onSubmit && onSubmit(data);
                }}
              >
                <FormattedMessage id={'save'} />
              </Button>
            </>
          )}
        </Box>
      </DialogCustom>
      <IndicationFormDialog
        open={!!formData}
        formData={formData}
        isInsurance={isInsurance}
        onSubmit={onSubmitForm}
        onClose={() => {
          setFormData(undefined);
        }}
      />
      <GroupIndicationFormDialog
        open={!!formDataGroup}
        formData={formDataGroup}
        onSubmit={onSubmitFormGroup}
        onClose={() => {
          setFormDataGroup(undefined);
        }}
      />

      <ReferenceIndicationFormDialog
        open={!!formDataReference}
        onClose={() => setFormDataReference(undefined)}
        formData={formDataReference}
        onSubmit={onSubmitFormReference}
      />
    </>
  );
};
export default CSVPreviewDialog;
