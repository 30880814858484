import { Stack } from '@mui/material';
import EncounterActions from './EncounterActions';
import PathBreadcrumbs from '../../PathBreadcrumbs';
import { EncounterDetailProps } from '../type';
import EncounterNav from './EncounterNav';

const EncounterDetailHeader = ({ encounter, readonly }: EncounterDetailProps) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ position: 'sticky', left: 0, top: 0, padding: '8px 16px', backgroundColor: '#fff', zIndex: 20 }}
    >
      <PathBreadcrumbs patient={encounter?.patient!} linkHome="/main/encounter-list" />
      <EncounterNav />
      <EncounterActions encounter={encounter} readonly={readonly} />
    </Stack>
  );
};

export default EncounterDetailHeader;
