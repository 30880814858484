import { DECIMAL_NUMBER_REGEX } from 'modules/common/regex';
import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import { Autocomplete, Box, Grid, TextField, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';
import { NumericFormatText } from 'modules/common/utils';
import { DEFAULT_INSTRUCTION_KEY } from './constants';
import { Dictionary, sortBy } from 'lodash';
import { Medication, MedicationCategory } from 'modules/schema';
import { GroupOption, Option } from 'modules/common/type';

type MedicationWithCategoryId = Medication & { categoryId: number | undefined };

interface SchemaProps {
  medicationMap: Dictionary<MedicationWithCategoryId>;
  medicationCategoryMap: Dictionary<MedicationCategory & { medicationList: MedicationWithCategoryId[] }>;
  onDelete: (medication: any) => void;
  readOnly: boolean;
  onSelectMedication: (medication: any) => void;
  onChangeMedicationInfo: (medication: any) => void;
}

export const fieldSchema: ISchemaFields<SchemaProps> = ({ valuesField, formProps, methods }) => {
  const {
    intl,
    onSelectMedication,
    onDelete,
    onChangeMedicationInfo,
    readOnly,
    openSnackbar,
    medicationMap,
    medicationCategoryMap,
    confirmDialog,
  } = formProps || {};
  const { setValue } = methods;

  const medicationCategoryOptions: Option[] = Object.values(medicationCategoryMap)?.map((category) => ({
    value: category?.id!,
    label: category?.name!,
  }));
  const filteredMedications = valuesField?.categoryOption
    ? medicationCategoryMap[valuesField?.categoryOption?.value]?.medicationList
    : Object.values(medicationMap);
  const medicationOptions: GroupOption[] = sortBy(
    filteredMedications?.map((medication) => ({
      ...medication,
      value: medication?.id!,
      label: medication?.name!,
      group: medicationCategoryMap?.[medication?.categoryId!]?.name || '',
      id: medication?.id,
    })),
    'group',
  );

  return {
    categoryOption: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'therapy.detail.label.drugGroup' }),
      placeholder: intl.formatMessage({ id: 'therapy.detail.label.drugGroupEnter' }),
      propsWrapper: { xs: 6 },
      options: medicationCategoryOptions,
      onChange: () => {
        setValue('medicationOption', null);
      },
    },
    medicationOption: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'therapy.detail.label.drugName' }),
      placeholder: intl.formatMessage({ id: 'therapy.detail.label.drugNameEnter' }),
      options: medicationOptions,
      getOptionLabel: (option) => {
        const medication = medicationMap[option?.value];
        return medication
          ? `${medication.name} (${medication.ingredient ? medication.ingredient : ''} ${
              medication.dose ? medication.dose : ''
            })`
          : ' ';
      },
      propsWrapper: { xs: 6 },
      groupBy: (option) => option.group,
      onChange: (value) => {
        if (value && value.id) {
          onSelectMedication(value);
        }
      },
      getOptionDisabled: (option) => {
        return !!valuesField?.medicationList?.find((ele) => ele.medicationId === option?.id);
      },
    },
    medicationList: {
      type: 'array',
      hid: true,
      disableCloseBtn: true,
      addable: false,
      paperItemProps: { style: { padding: 0 }, variant: 'elevation', elevation: 0 },
      gridItemProps: { sx: { paddingTop: '8px !important' } },

      schema: {
        fields: ({ formProps, valuesField }) => {
          const medication = medicationMap?.[valuesField.medicationId];
          const medicationCatogory = medicationCategoryMap[medication?.categoryId!];
          const { arrayData, methodsArray } = formProps;

          return {
            id: {
              type: 'hidden',
            },
            group: {
              type: () => {
                return (
                  <Grid container>
                    <Grid xs={4}>
                      <Typography>
                        <FormattedMessage id="therapy.detail.label.drugGroup" />
                      </Typography>
                    </Grid>
                    <Grid xs={8}>
                      <Typography variant="subtitle1">{medicationCatogory?.name}</Typography>
                    </Grid>
                  </Grid>
                );
              },
              propsWrapper: { xs: 4 },
            },
            name: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid xs={2.4}>
                        <Typography>
                          <FormattedMessage id="therapy.detail.label.drugNameLabel" />
                        </Typography>
                      </Grid>
                      <Grid xs={9.6}>
                        <Typography variant="subtitle1">{valuesField?.medication?.name}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 5 },
            },
            inventory: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid xs={4}>
                        <Typography color={'#003CA6'}>
                          <FormattedMessage id="inventory" />
                        </Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography component="span" fontWeight={500} color={'#E53935'}>
                          0
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 3 },
            },
            ingredient: {
              type: () => {
                return (
                  <div style={{}}>
                    <Grid container paddingTop={0}>
                      <Grid xs={4}>
                        <Typography>
                          <FormattedMessage id="therapy.detail.label.ingredientsLabel" />
                        </Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography variant="subtitle1">{valuesField?.medication?.ingredient}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 4, sx: { marginBottom: '20px' } },
            },
            dose: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid xs={5}>
                        <Typography>
                          <FormattedMessage id="sale.dose" />
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant="subtitle1">{valuesField?.medication?.dose}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 2.5, sx: { marginBottom: '20px' } },
            },
            unit: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid xs={5}>
                        <Typography>
                          <FormattedMessage id="therapy.detail.label.unitLabel" />
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant="subtitle1">{valuesField?.medication?.numeratorUnit}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 2.5, sx: { marginBottom: '20px' } },
            },
            price: {
              type: () => {
                return (
                  <div>
                    <Grid container>
                      <Grid xs={4}>
                        <Typography>
                          <FormattedMessage id="encounterPrescription.price" />:
                        </Typography>
                      </Grid>
                      <Grid xs={8}>
                        <Typography variant="subtitle1">
                          {NumericFormatText(valuesField?.medication?.publicPrice)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                );
              },
              propsWrapper: { xs: 3, sx: { marginBottom: '20px' } },
            },
            dayNumber: {
              type: 'text-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'dental.prescription.label.dayNumber' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 1.5 },
              register: {
                maxLength: {
                  value: 4,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
                pattern: DECIMAL_NUMBER_REGEX,
              },
              onBlur: (e) => {
                if (e.target.value?.trim()) {
                  const value = e.target.value?.trim().replace(/[,]/g, '');
                  if (value.length <= 4 && parseInt(value) !== arrayData?.value?.dayNumber) {
                    onChangeMedicationInfo({
                      ...valuesField,
                      dayNumber: parseInt(value),
                      quantity: valuesField?.unitPerDay && valuesField?.unitPerDay * parseInt(value),
                    });
                  } else if (value.length >= 4) {
                    openSnackbar({
                      message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                      type: 'error',
                    });
                  }
                }
              },
            },
            multiply: {
              type: () => {
                return 'x';
              },
              propsWrapper: { xs: 0.5, sx: { marginTop: '25px', textAlign: 'center' } },
            },
            equalSign: {
              type: () => {
                return '=';
              },
              propsWrapper: { xs: 0.5, sx: { marginTop: '25px', textAlign: 'center' } },
            },
            unitPerDay: {
              type: 'text-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'dental.prescription.label.unitPerDay' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 1.5 },
              register: {
                maxLength: {
                  value: 16,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
                pattern: DECIMAL_NUMBER_REGEX,
              },
              onBlur: (e) => {
                const value = e.target.value?.trim().replace(/[,]/g, '');
                if (value && parseInt(value) !== arrayData?.value?.unitPerDay) {
                  onChangeMedicationInfo({
                    ...valuesField,
                    unitPerDay: value,
                    quantity: valuesField?.dayNumber && valuesField?.dayNumber * parseInt(value),
                  });
                }
              },
            },
            quantity: {
              type: 'number-field',
              inputType: 'number',
              label: intl.formatMessage({ id: 'quantity' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: 1.5 },
              register: {
                required: true,
                maxLength: {
                  value: 16,
                  message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                },
                pattern: DECIMAL_NUMBER_REGEX,
              },
              onBlur: (e) => {
                const value = e.target.value?.trim().replace(/[,]/g, '');
                if (value && parseInt(value) !== arrayData?.value?.quantity) {
                  onChangeMedicationInfo({
                    ...valuesField,
                    quantity: value,
                    dayNumber: '',
                    unitPerDay: '',
                  });
                }
              },
            },
            intendedRoute: {
              type: 'auto-complete',
              label: intl.formatMessage({ id: 'therapy.detail.label.intendedRoute' }),
              placeholder: intl.formatMessage({ id: 'therapy.detail.label.intendedRouteEnter' }),
              options: medication?.intendedRoute,
              propsWrapper: { xs: 2.5 },
              onChange: (value) => {
                if (value && value?.value !== arrayData?.value?.intendedRoute?.value) {
                  onChangeMedicationInfo({ ...valuesField, intendedRoute: value });
                }
              },
              register: {
                required: true,
              },
            },
            usage: {
              propsWrapper: { xs: 4 },
              type: () => {
                return (
                  <div>
                    <Autocomplete
                      freeSolo
                      value={valuesField?.usage}
                      readOnly={readOnly}
                      options={DEFAULT_INSTRUCTION_KEY?.map((option) => intl.formatMessage({ id: option }))}
                      renderInput={(params) => (
                        <>
                          <TextField
                            {...params}
                            required={true}
                            label={intl.formatMessage({ id: 'encounterPrescription.instruction' })}
                            placeholder={intl.formatMessage({ id: 'noteEnter' })}
                            InputProps={{
                              ...params.InputProps,
                              type: 'search',
                            }}
                            onBlur={(e) => {
                              if (e.target?.value?.trim() && e.target?.value?.trim() !== valuesField?.usage)
                                onChangeMedicationInfo({ ...valuesField, usage: e.target.value?.trim() });
                            }}
                          />
                          {readOnly && (
                            <Typography variant="caption" color="textSecondary" sx={{ fontSize: '12px' }}>
                              <FormattedMessage id="readOnly" />
                            </Typography>
                          )}
                        </>
                      )}
                      onChange={(e, value) => {
                        if (value && value !== arrayData?.value?.usage)
                          onChangeMedicationInfo({ ...valuesField, usage: value?.trim() });
                      }}
                    />
                  </div>
                );
              },
            },
            delete: {
              unregister: true,
              type: () => {
                if (!readOnly)
                  return (
                    <IconButtonTitle
                      title="delete"
                      size="small"
                      disabled={readOnly || arrayData.value?.isPaid}
                      onClick={async () => {
                        try {
                          const confirm = await confirmDialog.promptConfirmation({
                            title: intl.formatMessage({ id: 'confirm' }),
                            content: intl.formatMessage(
                              { id: 'confirmDelete' },
                              { name: valuesField?.medication?.name },
                            ),
                          });
                          if (!confirm) {
                            confirmDialog.close();
                            return;
                          }
                          methodsArray?.remove(arrayData?.index);
                          onDelete(valuesField);
                        } catch (e) {
                          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
                        }
                        confirmDialog.close();
                      }}
                    >
                      <Delete />
                    </IconButtonTitle>
                  );
              },
              propsWrapper: {
                xs: true,
              },
            },
          };
        },
        ui: [
          {
            id: 'info',
            propsWrapper: { xs: true, paddingTop: 1 },
            fields: ['group', 'name', 'inventory', 'ingredient', 'dose', 'unit', 'price'],
          },
          {
            id: 'edit',
            propsWrapper: { xs: true },
            fields: ['dayNumber', 'multiply', 'unitPerDay', 'equalSign', 'quantity', 'intendedRoute', 'usage'],
          },
          {
            id: 'delete',
            propsWrapper: { xs: true, sx: { display: 'flex', alignItems: 'center' } },
            fields: ['delete'],
          },
        ],
        layout: ({ listElement, valuesField, fields }) => {
          return (
            <>
              {valuesField?.medicationList.length > 0 && (
                <Box mt={0} bgcolor={'#F4F8FF'} style={{ borderRadius: 4 }}>
                  <Grid container padding={1}>
                    <Grid item xs={11}>
                      <Box>{listElement[0]}</Box>
                    </Grid>
                    <Grid item xs={1} alignItems={'center'}>
                      <Box marginLeft={7}>{listElement[2]}</Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box>{listElement[1]}</Box>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </>
          );
        },
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  return [
    {
      id: 'info',
      fields: ['categoryOption', 'medicationOption'],
      paperProps: {
        sx: {
          border: 'none',
          bgcolor: '#F4F8FF',
          px: 2,
          py: 1,
        },
      },
      paper: true,
    },
    {
      id: 'medication',
      fields: ['medicationList'],
    },
  ];
};

export const prescriptionBox: ISchemaForm<SchemaProps> = {
  fields: fieldSchema,
  ui: ui,
  layout: ({ listElement, formProps, valuesField }) => {
    return (
      <>
        {!formProps?.readOnly && listElement[0]}
        <Typography
          className="title"
          fontWeight={500}
          fontSize={'20px'}
          color={'#3B5999'}
          textTransform={'uppercase'}
          paddingTop={'15px'}
        >
          <FormattedMessage id="encounterDetail.prescription" />
        </Typography>
        {valuesField?.medicationList?.length !== 0 ? (
          <Box mt={2}>{listElement[1]}</Box>
        ) : (
          <Box sx={{ textAlign: 'center' }}>
            <FormattedMessage id="prescription.box.no.data" />
          </Box>
        )}
      </>
    );
  },
};
