import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@mui/material';
import { withStyles } from '@mui/styles';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import MediCard from 'modules/common/component/MediCard';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import { INDICATION_REQUEST_TYPE, VALUE_EVALUATE } from 'modules/common/constants';
import { displayRangeCompare } from 'modules/common/utils';
import { IndicationLaboratory, IndicationLaboratoryList } from 'modules/schema';
import IndicationLabResult from './IndicationLabResult';

export const TableCellPadding = withStyles({
  root: {
    padding: '8px 16px',
  },
})(TableCell);

const Row: React.FunctionComponent<{ row: IndicationLaboratory; index: number; patientId: number }> = (props) => {
  const { row, index, patientId } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <>
      <TableCustomRow>
        <TableCustomCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell style={{ textAlign: 'center' }}>{index}</TableCustomCell>
        <TableCustomCell>{row?.laboratoryReferenceName}</TableCustomCell>
        <TableCustomCell align="right">
          <Typography
            variant="body1"
            fontWeight={!!row?.evaluate && row?.evaluate !== VALUE_EVALUATE.normal ? 700 : undefined}
          >
            {row?.result}
          </Typography>
        </TableCustomCell>
        <TableCustomCell>{row?.laboratoryReferenceDetailObject?.unit}</TableCustomCell>
        <TableCustomCell>
          {row?.laboratoryReferenceDetailObject?.laboratoryConditionList
            ?.map((v) =>
              v.name
                ? `${v.name} : ${displayRangeCompare(v.highIndex, v.lowIndex)}`
                : `${displayRangeCompare(v.highIndex, v.lowIndex)}`,
            )
            .join('; ')}
        </TableCustomCell>
      </TableCustomRow>
      <TableCustomRow>
        <TableCustomCell
          style={{ paddingBottom: 0, paddingTop: 0, border: 'none', backgroundColor: 'inherit' }}
          colSpan={6}
        >
          <Collapse in={open} timeout="auto" unmountOnExit>
            <IndicationLabResult
              indicationLaboratory={row}
              patientId={patientId}
              type={INDICATION_REQUEST_TYPE.ENCOUNTER}
            />
          </Collapse>
        </TableCustomCell>
      </TableCustomRow>
    </>
  );
};

interface Props {
  data: IndicationLaboratoryList;
  patientId: number;
}

const LabTable: React.FunctionComponent<Props> = ({ data, patientId }) => {
  return (
    <MediCard title={data?.indicationRequest?.serviceName} rawTitle style={{ marginBottom: 16 }}>
      <Box display="flex" padding={1}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableCustomRow>
                <TableHeaderCell style={{ width: 54 }} />
                <TableHeaderCell style={{ width: 54, textAlign: 'center' }}>
                  <FormattedMessage id="stt" />
                </TableHeaderCell>
                <TableHeaderCell width="20%">
                  <FormattedMessage id="indication.nameTest" />
                </TableHeaderCell>
                <TableHeaderCell align="right" width="15%">
                  <FormattedMessage id="indication.result" />
                </TableHeaderCell>
                <TableHeaderCell width="8%">
                  <FormattedMessage id="indication.unit" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="indication.referenceValue" />
                </TableHeaderCell>
              </TableCustomRow>
            </TableHead>
            <TableBody>
              {data?.indicationLaboratoryList?.map((row, index) => (
                <Row key={index} row={row} index={index + 1} patientId={patientId} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MediCard>
  );
};

export default LabTable;
