import { Box, InputLabel, Typography } from '@mui/material';
import moment from 'moment/moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../common/component/DialogCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, some } from '../../../common/constants';
import SchemaForm from '../../../common/SchemaForm';
import { INVENTORY_EXPORT_STATUS, INVENTORY_EXPORT_TYPE, MEDICATION_UNIT } from '../inventory/constant';

interface Props {
  open?: boolean;
  onClose?: () => void;
  formData?: some;
}

const FormDataDialog: React.FunctionComponent<Props> = (props) => {
  const { formData, onClose, open } = props;

  const exportStatus = INVENTORY_EXPORT_STATUS.find((v) => v.value === formData?.status);
  const exportType = INVENTORY_EXPORT_TYPE.find((v) => v.value === formData?.type);

  return (
    <DialogCustom
      open={!!open}
      maxWidth="lg"
      onClose={onClose}
      PaperProps={{
        style: { width: 1460 },
      }}
      title={'inventory.export.viewDetail'}
    >
      <Box padding={2}>
        <SchemaForm
          initialData={formData}
          readOnly={true}
          noHelperText={true}
          onCancel={onClose}
          cancelLabel={<FormattedMessage id={'cancel'} />}
          formProps={{
            style: {
              flex: 1,
            },
          }}
          schema={{
            fields: ({ formProps, valuesField: valuesFieldAll }) => {
              const { intl } = formProps || {};

              return {
                code: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="inventory.code" />
                        </InputLabel>
                        <Typography variant="subtitle1">{formData?.code}</Typography>
                      </div>
                    );
                  },
                  propsWrapper: { xs: 2 },
                },
                exportDate: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="inventory.exportDate" />
                        </InputLabel>
                        <Typography variant="body1">
                          {formData?.exportDate && moment(formData?.exportDate, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                        </Typography>
                      </div>
                    );
                  },
                  propsWrapper: { xs: 2 },
                },
                exportBy: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="inventory.exportBy" />
                        </InputLabel>
                        <Typography variant="body1">{formData?.exportBy}</Typography>
                      </div>
                    );
                  },
                  propsWrapper: { xs: 2 },
                },
                type: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="inventory.exportType" />
                        </InputLabel>
                        <Typography variant="body1">
                          {exportType && <FormattedMessage id={exportType.label} />}
                        </Typography>
                      </div>
                    );
                  },
                  propsWrapper: { xs: 2 },
                },
                referReceipt: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="inventory.export.refer.saleReceipt" />
                        </InputLabel>
                        <Typography variant="subtitle1">{formData?.saleReceipt?.code}</Typography>
                      </div>
                    );
                  },
                  propsWrapper: { xs: 2 },
                },
                status: {
                  type: () => {
                    return (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="status" />
                        </InputLabel>
                        <Typography
                          noWrap
                          sx={{
                            color:
                              exportStatus?.value === 'EXPORTED'
                                ? 'success.main'
                                : exportStatus?.value === 'CANCELLED'
                                ? 'error.main'
                                : 'primary.main',
                            fontWeight: '500',
                          }}
                        >
                          {exportStatus && <FormattedMessage id={exportStatus?.label} />}
                        </Typography>
                      </div>
                    );
                  },
                  propsWrapper: { xs: 2 },
                },
                inventoryExportDetails: {
                  type: 'array',
                  label: intl.formatMessage({ id: 'inventory.drugList' }),
                  disableCloseBtn: true,
                  hideInitialElement: true,
                  paperItemProps: {
                    sx: { padding: '12px 24px', bgcolor: 'grey.50' },
                    variant: 'elevation',
                    elevation: 0,
                  },
                  schema: {
                    fields: ({ formProps, valuesField }) => {
                      const unit = MEDICATION_UNIT.find((v) => v.value === valuesField.unit)?.label;
                      return {
                        medicationName: {
                          type: () => {
                            return (
                              <div>
                                <InputLabel>
                                  <FormattedMessage id="inventory.drugName" />
                                </InputLabel>
                                <Typography variant="subtitle1">{valuesField?.medication.name}</Typography>
                              </div>
                            );
                          },
                          propsWrapper: { xs: 2 },
                        },
                        medicationCode: {
                          type: () => {
                            return (
                              <div>
                                <InputLabel>
                                  <FormattedMessage id="inventory.drugCode" />
                                </InputLabel>
                                <Typography variant="subtitle1">{valuesField?.medication.code}</Typography>
                              </div>
                            );
                          },
                          propsWrapper: { xs: 2 },
                        },
                        lot: {
                          type: () => {
                            return (
                              <div>
                                <InputLabel>
                                  <FormattedMessage id="inventory.lot" />
                                </InputLabel>
                                <Typography variant="subtitle1">{valuesField?.lot}</Typography>
                              </div>
                            );
                          },
                          propsWrapper: { xs: 2 },
                        },
                        expiredDate: {
                          type: () => {
                            return (
                              <div>
                                <InputLabel>
                                  <FormattedMessage id="expiredDate" />
                                </InputLabel>
                                <Typography variant="body1">
                                  {valuesField?.expiredDate &&
                                    moment(valuesField?.expiredDate, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                                </Typography>
                              </div>
                            );
                          },
                          propsWrapper: { xs: 2 },
                        },
                        quantity: {
                          type: () => {
                            return (
                              <div>
                                <InputLabel>
                                  <FormattedMessage id="inventory.quantity" />
                                </InputLabel>
                                <Typography variant="subtitle1">{valuesField?.quantity}</Typography>
                              </div>
                            );
                          },
                          propsWrapper: { xs: 2 },
                        },
                        unit: {
                          type: () => {
                            return (
                              <div>
                                <InputLabel>
                                  <FormattedMessage id="inventory.unit" />
                                </InputLabel>
                                <Typography variant="body1">{unit && <FormattedMessage id={unit} />}</Typography>
                              </div>
                            );
                          },
                          propsWrapper: { xs: 2 },
                        },
                      };
                    },
                    ui: [
                      {
                        id: 'default',
                        propsWrapper: { xs: true },
                        fields: ['medicationName', 'medicationCode', 'lot', 'expiredDate', 'quantity', 'unit'],
                      },
                    ],
                  },
                },
                note: {
                  type: () => {
                    return formData?.note ? (
                      <div>
                        <InputLabel>
                          <FormattedMessage id="note" />
                        </InputLabel>
                        <Typography variant="body1">{formData?.note}</Typography>
                      </div>
                    ) : null;
                  },
                  propsWrapper: { xs: 2 },
                },
              };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default FormDataDialog;
