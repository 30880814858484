import { Box, Chip, Popover, Stack, Tooltip } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import { ICD_TYPE } from 'modules/common/apiConstants';
import { useDeleteMutate } from 'modules/common/hook';
import { IcdDiagnosis } from 'modules/schema';
import { useCallback, useState } from 'react';
import { mutate } from 'swr';

interface Props {
  encounterId: number;
  primaryIcd10: IcdDiagnosis | undefined;
  secondaryIcd10s: IcdDiagnosis[] | undefined;
  readonly: boolean;
}

const IcdList = ({ primaryIcd10, secondaryIcd10s, readonly, encounterId }: Props) => {
  const deleteIcd10Mutate = useDeleteMutate({
    onSuccess: () => {
      mutate(API_SERVER.icdDiagnosis.getByEncounterId({ encounterId, type: ICD_TYPE.PRIMARY_ICD10 }).url);
      mutate(API_SERVER.icdDiagnosis.getByEncounterId({ encounterId, type: ICD_TYPE.SECOND_ICD10 }).url);
    },
  });
  const onDeleteIcd = useCallback(
    (icd: IcdDiagnosis) => {
      deleteIcd10Mutate({ url: API_SERVER.icdDiagnosis.deleteIcdDiagnosis(icd.id!), method: 'DELETE' });
    },
    [deleteIcd10Mutate],
  );
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const renderChip = useCallback(
    (icd?: IcdDiagnosis) => {
      if (!icd) return null;
      return (
        <Tooltip title={`${icd.code} - ${icd.display}`}>
          <Chip
            label={`${icd.code} - ${icd.display}`}
            key={icd.id}
            onDelete={readonly ? undefined : () => onDeleteIcd(icd)}
            sx={{
              backgroundColor: icd.type === ICD_TYPE.PRIMARY_ICD10 ? '#CDDFFF' : '#D7DCE5',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              maxWidth: '100%',
            }}
          />
        </Tooltip>
      );
    },
    [onDeleteIcd, readonly],
  );
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [firstIcd, ...otherIcds] = secondaryIcd10s || [];
  return (
    <Box>
      {primaryIcd10 && <Box marginBottom="10px">{renderChip(primaryIcd10)}</Box>}
      <Stack direction="row" alignItems="center" justifyContent="flex-start" flexWrap="nowrap" gap="10px">
        {renderChip(firstIcd)}
        {otherIcds?.length > 0 ? (
          <Box
            key="rest-of-icd"
            sx={{
              backgroundColor: '#D7DCE5',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 500,
              lineHeight: '24px',
              letterSpacing: '0.1px',
              cursor: 'pointer',
              borderRadius: '20px',
              padding: '4px 12px',
            }}
            onClick={handleClick}
          >
            +{otherIcds?.length}
          </Box>
        ) : null}
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          PaperProps={{
            sx: {
              boxShadow: '0px 4px 8px 0px #E0ECFF',
              borderRadius: '8px',
            },
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            flexWrap="wrap"
            gap="10px"
            padding="20px"
            boxSizing="border-box"
            width="350px"
          >
            {otherIcds.map((icd) => (
              <Box key={icd.id} width="100%">
                {renderChip(icd)}
              </Box>
            ))}
          </Stack>
        </Popover>
      </Stack>
    </Box>
  );
};

export default IcdList;
