import { DentalExamSchema } from '../../../../../schema';
import TableCustom, { Columns } from '../../../../../common/component/TableCustom';
import * as React from 'react';
import { FE_DATE_FORMAT } from '../../../../../common/constants';
import { API_SERVER } from '../../../../../common/api';
import { useParams } from 'react-router';
import { z } from 'zod';
import { styled } from '@mui/system';
import { Typography } from '@mui/material';
import { ROUTES } from '../../../../../layout/router';
import { useFetch } from 'modules/common/hook';

const DentalExamHistorySchema = DentalExamSchema.pick({
  id: true,
  patientId: true,
  createdTime: true,
  code: true,
  registerReason: true,
});

const CellStyled = styled(Typography)({
  fontSize: '15px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '16px',
  letterSpacing: '0.2px',
  color: '#263238',
});

type DentalExam = z.infer<typeof DentalExamHistorySchema>;

const COLUMNS: Columns<DentalExam>[] = [
  {
    title: 'dental.dentalDetail.sections.examinationHistories.code.title',
    width: '15%',
    align: 'center',
    render: (record) => (
      <Typography
        variant="subtitle2"
        component={'span'}
        sx={{ textDecoration: 'underline', cursor: 'pointer', color: '#0052E0', fontWeight: 500 }}
        onClick={() => {
          window.open(ROUTES.dentalDetail.gen(record.id!, record.patientId!), '_blank');
        }}
      >
        {record.code}
      </Typography>
    ),
  },
  {
    title: 'dental.dentalDetail.sections.examinationHistories.examinationDay.title',
    width: '25%',
    render: (record) => <CellStyled>{record?.createdTime?.format(FE_DATE_FORMAT)}</CellStyled>,
  },
  {
    title: 'dental.dentalDetail.sections.examinationHistories.reason.title',
    render: (record) => <CellStyled>{record?.registerReason}</CellStyled>,
  },
];
const ExaminationHistoryTable = () => {
  const { patientId } = useParams<{ patientId: string }>();
  const { data } = useFetch(API_SERVER.dentalExam.getExaminationList(parseInt(patientId)));
  return (
    <TableCustom
      containerProps={{
        sx: {
          '& th': {
            color: '#607D8B',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '24px',
            letterSpacing: '0.25px',
          },
          '& thead th': {
            textTransform: 'unset !important',
          },
          '& td': {
            padding: '8px',
            backgroundColor: 'transparent',
          },
          '& tr': {
            backgroundColor: '#F4F8FF',
          },
          width: '100%',
          overflow: 'visible',
        },
      }}
      dataSource={data}
      columns={COLUMNS}
      hideColumnIndex
    />
  );
};

export default ExaminationHistoryTable;
