import { Paper, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import ReceiptListTable from 'modules/admin/component/saleManagement/ReceiptListTable';
import FilterBox from 'modules/admin/component/saleManagement/FilterBox';
import { axiosThunk } from 'modules/common/redux/axios';
import CancelConfirmationDialog from 'modules/admin/component/saleManagement/CancelConfirmDialog';
import { mappedBody } from 'modules/common/api';
import * as React from 'react';
import PrintReceiptDetailDialog from 'modules/admin/component/saleManagement/printReceiptDetail';
import { useFetch } from 'modules/common/hook';
import { SaleReceipt } from 'modules/schema';
import { setLoading } from 'modules/common/redux/commonReducer';

interface Props {}

const ReceiptListPage: React.FunctionComponent<Props> = (props: Props) => {
  const { dispatch, openSnackbar, intl, API_SERVER } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const [receiptToCancel, setReceiptToCancel] = useState<some | undefined>();
  const [receiptToPrint, setReceiptToPrint] = useState<SaleReceipt | undefined>();
  const { params } = hookPagination;

  const { data, revalidate } = useFetch(API_SERVER.receiptManagement.getList(params));

  const onCancel = useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: API_SERVER.receiptManagement?.cancel(value?.id),
          method: 'put',
          data: mappedBody({ reason: value.reason }),
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: 'common.message.success' }) });
          revalidate();
          setReceiptToCancel(undefined);
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
        });
      dispatch(setLoading(false));
    },
    [API_SERVER.receiptManagement, dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <>
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterBox
          hookPagination={hookPagination}
          title={
            <>
              <Typography variant="h6">
                <FormattedMessage id="navMenu.receiptList" />
              </Typography>
            </>
          }
        />
        <ReceiptListTable
          data={data}
          onCancel={(record) => {
            setReceiptToCancel(record);
          }}
          onViewDetail={(record) => {
            setReceiptToPrint(record);
          }}
          hookPagination={hookPagination}
        />
      </Paper>
      <CancelConfirmationDialog
        receiptToCancel={receiptToCancel}
        open={!!receiptToCancel}
        onClose={() => {
          setReceiptToCancel(undefined);
        }}
        onSubmit={onCancel}
      ></CancelConfirmationDialog>
      <PrintReceiptDetailDialog
        receiptToPrint={receiptToPrint}
        open={!!receiptToPrint}
        onClose={() => {
          setReceiptToPrint(undefined);
        }}
      />
    </>
  );
};
export default ReceiptListPage;
