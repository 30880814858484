import { Autorenew } from '@mui/icons-material';
import { Box, Button, Checkbox, Stack, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import MediCard from 'modules/common/component/MediCard';
import { Encounter } from 'modules/schema';
import { FormProvider, useFieldArray, useForm, useFormContext, useWatch } from 'react-hook-form';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { PAYMENT_STATUS, PAYMENT_TYPE } from 'modules/common/apiConstants';
import { TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import { IFormData } from './utils';
import TablePriceRow from './TablePriceRow';
import FinalPriceInfo from './FinalPriceInfo';
import SummaryPaymentPrintButton from './SummaryPaymentPrintButton';

interface Props {
  encounter?: Encounter;
}

const AllCheckBox = () => {
  const { setValue } = useFormContext<IFormData>();
  const paymentList = useWatch<IFormData, `paymentList`>({ name: 'paymentList' });
  return (
    <Checkbox
      color="primary"
      disabled={!paymentList?.some((payment) => payment?.status === PAYMENT_STATUS.UNPAID.value)}
      onChange={(event) =>
        setValue(
          'paymentList',
          paymentList?.map((payment) => ({
            ...payment,
            checked: payment?.status === PAYMENT_STATUS.UNPAID.value ? event.target.checked : false,
          })),
        )
      }
    />
  );
};

const TablePriceBox: React.FunctionComponent<Props> = ({ encounter }) => {
  const methods = useForm<IFormData>({ reValidateMode: 'onChange', mode: 'onChange' });
  const { control, reset } = methods;
  const { fields } = useFieldArray({
    control,
    name: 'paymentList',
  });
  const { data: payment, revalidate } = useFetch(
    API_SERVER.payment.detail(encounter?.payment?.id!, { type: PAYMENT_TYPE.ENCOUNTER }),
    { globalLoading: true, enabled: encounter },
  );
  React.useEffect(() => {
    if (payment) {
      reset({
        discountPercent: payment?.discountPercent,
        paymentList: payment?.paymentDetails?.map((paymentDetail) => ({
          paymentDetailId: paymentDetail?.id,
          checked: false,
          discount: paymentDetail?.discount,
          discountType: paymentDetail?.discountType,
          unitPrice: paymentDetail?.unitPrice,
          vat: paymentDetail?.vat,
          status: paymentDetail?.status,
        })),
      });
    }
  }, [payment, reset]);

  return (
    <MediCard
      sx={{ minHeight: 'calc(100vh - 26vh)' }}
      title={
        <Stack direction="row" justifyContent="space-between">
          <Typography sx={{ lineHeight: '30px' }}>
            <FormattedMessage id="pay" />
          </Typography>
          <Box display="flex" gap={2}>
            <SummaryPaymentPrintButton
              encounter={encounter}
              paymentDetails={payment?.paymentDetails?.filter(
                (detail) =>
                  detail?.status === PAYMENT_STATUS.PAID.value || detail?.status === PAYMENT_STATUS.UNPAID.value,
              )}
            />
            <Button onClick={revalidate} startIcon={<Autorenew />} color="inherit">
              <FormattedMessage id="refresh" />
            </Button>
          </Box>
        </Stack>
      }
    >
      <FormProvider {...methods}>
        <form>
          <TableContainer style={{ height: '100%' }}>
            <Table stickyHeader>
              <TableHead>
                <TableCustomRow>
                  <TableHeaderCell sx={{ '& span': { opacity: '0.85' } }}>
                    <AllCheckBox />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="stt" />
                  </TableHeaderCell>
                  <TableHeaderCell sx={{ width: 250 }}>
                    <FormattedMessage id="cashier.service" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="quantity" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="indication.unit" />
                  </TableHeaderCell>
                  <TableHeaderCell sx={{ width: 150 }}>
                    <FormattedMessage id="therapy.price" />
                  </TableHeaderCell>
                  <TableHeaderCell sx={{ width: 150 }}>
                    <FormattedMessage id="therapy.cashier.table.header.discount" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="cashier.discountType" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="therapy.cashier.table.header.discountAmountPercent" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="therapy.cashier.table.header.discountAmount" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="totalPrice" />
                  </TableHeaderCell>
                  <TableHeaderCell sx={{ width: 15 }}>
                    <FormattedMessage id="vatPercent" />
                  </TableHeaderCell>
                  <TableHeaderCell sx={{ width: 15 }}>
                    <FormattedMessage id="cashier.vatValue" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="patientPay" />
                  </TableHeaderCell>
                  <TableHeaderCell sx={{ maxWidth: '30px', minWidth: '30px' }}>
                    <FormattedMessage id="therapy.cashier.table.header.paymentStatus" />
                  </TableHeaderCell>
                  <TableHeaderCell>
                    <FormattedMessage id="action" />
                  </TableHeaderCell>
                </TableCustomRow>
              </TableHead>
              <TableBody>
                {fields?.map((field, index) => {
                  return (
                    <TablePriceRow
                      key={field?.paymentDetailId}
                      index={index}
                      paymentDetail={payment?.paymentDetails?.[index]!}
                    />
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <FinalPriceInfo encounter={encounter} payment={payment} />
        </form>
      </FormProvider>
    </MediCard>
  );
};

export default TablePriceBox;
