import { INDICATION_CATEGORY_LIST } from '../../../../common/constants';
import { ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl } = formProps || {};

  return {
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'indication.nameService' }),
      placeholder: intl.formatMessage({ id: 'indication.nameServiceEnter' }),
      style: { minWidth: 220 },
      propsWrapper: { xs: 12 },
      readOnly: valuesField.type === INDICATION_CATEGORY_LIST.insured,
      register:
        valuesField.type === INDICATION_CATEGORY_LIST.uninsured
          ? {
              required: true,
            }
          : undefined,
    },
  };
};

const ui: IUiFields = ({ valuesField }) => {
  return [
    {
      id: 'info',
      fields: ['name'],
    },
  ];
};

export const listServiceFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
