import { Box } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { IDENTITY_API_SERVER } from '../../../../common/api';
import DialogCustom from '../../../../common/component/DialogCustom';
import { ROLES, some } from '../../../../common/constants';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFormAdminDepartment } from './schema';
import { useFetch } from 'modules/common/hook';
import { SpecializedDepartment } from 'modules/schema';
import { Option } from 'modules/common/type';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: SpecializedDepartment;
}

interface SFCProps extends Props {
  doctorOptions: Option[];
  formData: some;
}

const SchemaFormContent: React.FunctionComponent<SFCProps> = (props) => {
  const { onSubmit, formData, onClose, doctorOptions } = props;

  return (
    <SchemaForm
      schema={schemaFormAdminDepartment}
      onSubmit={onSubmit}
      formData={formData}
      listDoctor={doctorOptions}
      onCancel={onClose}
      formProps={{
        autoComplete: 'nope',
        autoCorrect: 'off',
      }}
    />
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;
  const { intl, appState } = useGeneralHook();

  const { data: doctors } = useFetch(IDENTITY_API_SERVER.user.getListDoctorUser());
  const doctorOptions: Option[] = React.useMemo(
    () =>
      doctors
        ?.filter((ele) => ele.status === 'ACTIVE')
        ?.map((doctor) => ({
          label: `${doctor?.employeeFullName} - ${doctor?.email} - ${intl.formatMessage({
            id: ROLES[doctor?.roleName!]?.label,
          })}`,
          value: doctor?.id!,
          practitionerRoleId: doctor?.resourceId!,
        })) || [],
    [doctors, intl],
  );

  const data = {
    ...formData,
    department: appState.common.departments?.find((department) => department.value === formData?.code),
    pic: doctorOptions?.find((doctor) => doctor.value === formData?.pic),
  };

  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 680 },
      }}
      title={formData?.id ? 'department.update' : 'department.add'}
    >
      <Box padding={2}>
        <SchemaFormContent {...props} doctorOptions={doctorOptions} formData={data} />
      </Box>
    </DialogCustom>
  );
};
export default FormDialog;
