import GridOnIcon from '@mui/icons-material/GridOn';
import { LoadingButton } from '@mui/lab';
import { useDownloadFileLoading } from 'modules/common/hook';
import { FormattedMessage } from 'react-intl';
interface Props {
  exportUrl: string;
  fileName?: string;
}
export const ExportDataButton = ({ exportUrl, fileName }: Props) => {
  const { downloadFile, loading } = useDownloadFileLoading(exportUrl, fileName);
  return (
    <LoadingButton loading={loading} variant="contained" startIcon={<GridOnIcon />} onClick={downloadFile}>
      <FormattedMessage id="refundReport.exportExcel" />
    </LoadingButton>
  );
};
