import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import Stack from '@mui/material/Stack';

const DoctorSign = () => {
  return (
    <Stack direction="row" justifyContent="space-between" marginTop={2}>
      <Grid item xs={6} textAlign="center">
        <Typography variant="body1" fontStyle="italic">
          <FormattedMessage
            id="printForm.date"
            values={{
              hour: new Date().getHours(),
              min: new Date().getMinutes(),
              day: new Date().getDate(),
              month: new Date().getMonth() + 1,
              year: new Date().getFullYear(),
            }}
          />
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="printForm.labDoctor" />
        </Typography>
        <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
          <FormattedMessage id="medicalPrint.helperText" />
        </Typography>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <Typography variant="body1" fontStyle="italic">
          <FormattedMessage
            id="printForm.date"
            values={{
              hour: new Date().getHours(),
              min: new Date().getMinutes(),
              day: new Date().getDate(),
              month: new Date().getMonth() + 1,
              year: new Date().getFullYear(),
            }}
          />
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="therapy.indication.request.printForm.picDoctor" />
        </Typography>
        <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
          <FormattedMessage id="medicalPrint.helperText" />
        </Typography>
      </Grid>
    </Stack>
  );
};

export default DoctorSign;
