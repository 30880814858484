import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  titleKey: string;
}

const SessionTitle = ({ titleKey }: Props) => {
  return (
    <Typography
      sx={{
        color: '#263238',
        fontFeatureSettings: "'clig' off, 'liga' off",
        fontFamily: 'Roboto',
        fontSize: '20px',
        fontStyle: 'normal',
        fontWeight: '500',
        lineHeight: '24px',
        letterSpacing: '0.15px',
        marginTop: '33px',
        marginBottom: '20px',
      }}
    >
      <FormattedMessage id={titleKey} />
    </Typography>
  );
};

export default SessionTitle;
