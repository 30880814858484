import CheckBoxOutlineBlankOutlinedIcon from '@mui/icons-material/CheckBoxOutlineBlankOutlined';
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';
import { Box, Divider, Grid, Table, Typography } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Encounter } from 'modules/schema';
import { CARE_PLAN_SCOPE, CARE_PLAN_TYPE, GENDER, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { API_SERVER } from 'modules/common/api';
import { useFetch, useUserRoles } from 'modules/common/hook';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { flatMap, keyBy } from 'lodash';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import ImagePreview from 'modules/common/component/ImagePreview';
import { RadioImageSizeImage } from 'modules/common/component/RadioImageSizeImage';
import { VITAL_SIGN_CODE } from 'modules/examDoctor/constants';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, FE_DATE_TIME_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';

const CheckBox = ({ checked }: { checked?: Boolean }) => {
  if (checked) {
    return <CheckBoxOutlinedIcon />;
  }
  return <CheckBoxOutlineBlankOutlinedIcon />;
};

interface Props {
  encounter?: Encounter;
  compact: boolean;
  children?: React.ReactNode;
}

const PrintContent = ({ encounter, compact, children }: Props) => {
  const { intl } = useGeneralHook();

  const { data: observation } = useFetch(API_SERVER.observation.getByEncounterId(encounter?.id!), {
    shouldRetryOnError: false,
    enabled: encounter?.id,
  });

  const { data: carePlan } = useFetch(
    API_SERVER.carePlan.get({ referenceId: encounter?.id!, scope: CARE_PLAN_SCOPE.ENCOUNTER }),
    {
      shouldRetryOnError: false,
    },
  );
  const { data: diagnosisImages } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.encounterDiagnosticImage,
      referenceId: encounter?.id!,
    }),
  );

  const { data: systemCategories } = useFetch(
    API_SERVER.service.getAllSystemCategoryList({ scope: [SYSTEM_CATEGORY_SCOPE.HEALTH_CHECK_SERVICE.value] }),
  );
  const serviceMap = React.useMemo(
    () =>
      keyBy(
        flatMap(systemCategories, (systemCategory) => systemCategory.items),
        'id',
      ),
    [systemCategories],
  );
  const doctors = useUserRoles('DOCTOR', 'GENERAL_DOCTOR', 'RADIOLOGIST', 'CLINICAL_PATHOLOGIST');

  const headDoctor = doctors?.find(
    (doctor) => doctor?.id === serviceMap[encounter?.serviceId!]?.specializedDepartment?.pic,
  );

  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
            <FormattedMessage id="medicalPrint" />
          </Typography>
        </HeaderPrintForm>
        <Grid container>
          <Grid item xs={4} md={3}>
            <Typography variant="body1">
              <FormattedMessage id="medicalPrint.room" />
              :&nbsp;{encounter?.physicsRoom?.name}
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} display="flex" alignItems="center">
            <Typography variant="body1" display="flex" alignItems="center">
              <FormattedMessage id="medicalPrint.normal" />
              :&nbsp; <CheckBox checked />
            </Typography>
            &emsp;
            <Typography variant="body1" display="flex" alignItems="center">
              <FormattedMessage id="medicalPrint.emergency" />
              :&nbsp; <CheckBox />
            </Typography>
          </Grid>
          <Grid item xs={4} md={3} display="flex" alignItems="center">
            <Typography variant="subtitle1">
              <FormattedMessage id="medicalPrint.number" />
              :&nbsp;{encounter?.code}
            </Typography>
          </Grid>
          <Grid item xs={8} md={9} display="flex" alignItems="center">
            <Typography variant="subtitle1">
              <FormattedMessage id="medicalPrint.patientId" />
              :&nbsp;{encounter?.patient?.formatCode}
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }, [encounter]);

  const administrative = React.useMemo(() => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Typography variant="h6">
            <FormattedMessage id="medicalPrint.section1" />
          </Typography>
        </Grid>
        <Grid item xs={12} style={{ columnCount: 2 }}>
          {[
            {
              label: 'medicalPrint.fullName',
              value: encounter?.patient?.name,
              uppercase: true,
            },
            {
              label: 'medicalPrint.birthday',
              value: (
                <>
                  {moment(encounter?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                  &emsp;-&emsp;
                  <FormattedMessage id="medicalPrint.age" />
                  :&nbsp;{encounter?.patient?.age}
                </>
              ),
            },
            {
              label: 'medicalPrint.gender',
              value: encounter?.patient?.gender && (
                <FormattedMessage id={GENDER?.[encounter?.patient?.gender]?.label || ' '} />
              ),
            },
            { label: 'telephone', value: encounter?.patient?.mobilePhone },
            {
              hidden: compact,
              label: 'medicalPrint.job',
              value: encounter?.patient?.job,
            },
            { hidden: compact, label: 'medicalPrint.ethnic', value: encounter?.patient?.ethnic },
            { label: 'medicalPrint.nation', value: encounter?.patient?.nationality },
            { label: 'medicalPrint.address', value: encounter?.patient?.homeAddress?.address },
            { hidden: compact, label: 'medicalPrint.jobAddress', value: encounter?.patient?.workAddress?.address },
            { label: 'medicalPrint.identity', value: encounter?.patient?.identifierCode },
            {
              label: 'category',
              value: Object.values([PAYMENT_PLAN.FEE, PAYMENT_PLAN.FREE, PAYMENT_PLAN.OTHER]).map(
                (paymentPlan, index) => {
                  return (
                    <Box key={index} display="flex" alignItems="center">
                      <Typography
                        variant="body1"
                        style={{ marginLeft: 16, marginRight: 8, fontStyle: 'italic' }}
                        noWrap
                      >
                        {`${index + 1}. ${intl.formatMessage({ id: paymentPlan.label })}`} &nbsp;
                      </Typography>
                      {paymentPlan.value === encounter?.paymentPlan ? (
                        <CheckBox checked />
                      ) : (
                        <CheckBox checked={false} />
                      )}
                    </Box>
                  );
                },
              ),
            },
            {
              hidden: compact,
              label: 'medicalPrint.timeArrived',
              value: encounter?.createdTime && encounter?.createdTime?.format(FE_DATE_TIME_FORMAT),
            },
            {
              hidden: compact,
              label: 'medicalPrint.timeStart',
              value: encounter?.startTime && encounter?.startTime?.format(FE_DATE_TIME_FORMAT),
            },
            { hidden: compact, label: 'medicalPrint.diagnosisIntroduce', value: '' },
          ]
            .filter((v) => !v.hidden)
            .map((item, index) => {
              return (
                <Box key={index} display="flex" flexWrap="wrap">
                  <Typography variant="body1">
                    {index + 1}.&nbsp;
                    <FormattedMessage id={item.label} />
                  </Typography>
                  :&nbsp;
                  <span
                    style={{
                      textTransform: item?.uppercase ? 'uppercase' : 'initial',
                      display: 'flex',
                      flexWrap: 'wrap',
                    }}
                  >
                    {item.value}
                  </span>
                </Box>
              );
            })}
        </Grid>
      </Grid>
    );
  }, [compact, encounter, intl]);

  const encounterInfo = React.useMemo(() => {
    const dataTmp = [
      [
        {
          title: 'encounterDetail.temperature',
          value: observation?.bodyTemperature,
          unit: VITAL_SIGN_CODE.temperature.unit,
        },
        {
          title: 'encounterDetail.bloodPressure',
          value: `${observation?.systolicBloodPressure || '-'}/${observation?.diastolicBloodPressure || '-'}`,
          unit: VITAL_SIGN_CODE.bloodPressure.unit,
        },
        {
          title: 'encounterDetail.breathBeat',
          value: observation?.respiratoryRate,
          unit: VITAL_SIGN_CODE.breath.unit,
        },
        {
          title: 'encounterDetail.heartBeat',
          value: observation?.heartRate,
          unit: VITAL_SIGN_CODE.heartBeat.unit,
        },
      ],
      [
        {
          title: 'encounterDetail.height',
          value: observation?.bodyHeight,
          unit: VITAL_SIGN_CODE.height.unit,
        },
        {
          title: 'encounterDetail.weight',
          value: observation?.bodyWeight,
          unit: VITAL_SIGN_CODE.weight.unit,
        },
        {
          title: 'encounterDetail.BMI',
          value: observation?.bodyBMI,
        },
        {
          title: 'encounterDetail.SpO2',
          value: observation?.spo2,
          unit: VITAL_SIGN_CODE.spo2.unit,
        },
      ],
    ];

    const RenderData = ({ data }) => {
      return (
        <>
          {data?.map((item, index) => (
            <Box key={index}>
              &nbsp;-&nbsp;{item.code}&nbsp;-&nbsp;{item.display}
            </Box>
          ))}
          <Box>
            &nbsp;{encounter?.differentialDiagnosis && '- ' + extractHTMLContent(encounter?.differentialDiagnosis)}
          </Box>
        </>
      );
    };

    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', position: 'relative', width: '100%' }} marginBottom={1}>
          <Typography variant="h6">
            <FormattedMessage id="medicalPrint.section2" />
          </Typography>
          <Box paddingY={1}>
            <Table
              sx={{
                width: '100%',
                '& th,tr,td': {
                  padding: '2px 8px',
                  backgroundColor: 'white',
                  border: '0.5px solid',
                  color: 'unset',
                },
                overflow: 'visible',
              }}
            >
              <tbody>
                {dataTmp.map((value, index) => {
                  return (
                    <tr key={index}>
                      {value.map((item, idx) => {
                        return (
                          <td key={idx}>
                            <FormattedMessage id={item.title} />
                            :&nbsp;{`${item.value || '-'} ${item.unit || ''}`}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Box>
        </Box>
        <Box>
          <Box style={{ display: 'flex' }}>
            <Typography variant="subtitle1" style={{ textTransform: 'uppercase', minWidth: 220 }} noWrap>
              <FormattedMessage id="medicalPrint.reason" />
            </Typography>{' '}
            :&nbsp;
            <Typography variant="body1" component="span">
              {encounter?.reason}
            </Typography>
          </Box>
          <Box style={{ display: 'flex' }}>
            <Typography variant="subtitle1" style={{ textTransform: 'uppercase', minWidth: 220 }} noWrap>
              <FormattedMessage id="medicalPrint.illnessHistory" />
            </Typography>
            :&nbsp;{' '}
            <Typography variant="body1" component="div">
              <div
                className="mce-editor-customs"
                dangerouslySetInnerHTML={{ __html: encounter?.illnessesHistory || '' }}
              />
            </Typography>
          </Box>
          <Box style={{ display: 'flex' }}>
            <Typography variant="subtitle1" style={{ textTransform: 'uppercase', minWidth: 220 }} noWrap>
              <FormattedMessage id="medicalPrint.medicalHistory" />
            </Typography>
            :&nbsp;
            <Typography variant="body1" component="div">
              <div
                className="mce-editor-customs"
                dangerouslySetInnerHTML={{ __html: encounter?.medicalHistory || '' }}
              />
            </Typography>
          </Box>
          <Box style={{ display: 'flex' }}>
            <Typography
              variant="subtitle1"
              component="span"
              style={{ textTransform: 'uppercase', minWidth: 220 }}
              noWrap
            >
              <FormattedMessage id="medicalPrint.clinical" />
            </Typography>
            :&nbsp;
            <Typography variant="body1" component="span">
              <div
                className="mce-editor-customs"
                dangerouslySetInnerHTML={{ __html: encounter?.clinicalExamination || '' }}
              />
            </Typography>
          </Box>
          <Box style={{ marginLeft: 230 }}>
            {diagnosisImages && diagnosisImages.length !== 0 && (
              <RadioImageSizeImage>
                {(imageSize) => (
                  <Grid item width={'100%'}>
                    <Grid container spacing={2} display="flex">
                      {diagnosisImages?.map((item, index) => {
                        return (
                          <Grid
                            item
                            key={index}
                            xs={imageSize}
                            textAlign="center"
                            display="flex"
                            flexDirection={'column'}
                            position="relative"
                          >
                            <Box textAlign="center">
                              <ImagePreview
                                alt=""
                                imageId={item.id}
                                style={{
                                  width: imageSize === 12 ? '84%' : '100%',
                                  objectFit: 'cover',
                                }}
                              />
                              <Typography variant="body2" color="textSecondary">
                                {item.description}
                              </Typography>
                            </Box>
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                )}
              </RadioImageSizeImage>
            )}
          </Box>
          <Box style={{ display: 'flex' }}>
            <Typography
              variant="subtitle1"
              component="div"
              style={{ textTransform: 'uppercase', minWidth: 220 }}
              noWrap
            >
              <FormattedMessage id="medicalPrint.diagnosis" />
            </Typography>
            :&nbsp;
            <Typography variant="body1" component="span">
              <RenderData data={encounter?.icdDiagnosisList} />
            </Typography>
          </Box>
        </Box>
      </>
    );
  }, [diagnosisImages, encounter, observation]);

  const solutionSection = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid' }}>
          <Grid container spacing={0}>
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'baseline' }}>
              <Typography variant="subtitle1" component="span">
                <FormattedMessage id="medicalPrint.solution" />
                :&nbsp;
              </Typography>
              <Typography variant="body1" component="span">
                {carePlan?.type ? <FormattedMessage id={CARE_PLAN_TYPE[carePlan?.type!]?.label} /> : ''}
              </Typography>
            </Grid>
            <Grid item xs={12} style={{ display: 'flex' }}>
              <Typography variant="subtitle1" component="span" whiteSpace="nowrap">
                <FormattedMessage id="note" />
                :&nbsp;
              </Typography>
              <Typography variant="subtitle1" component="span">
                {extractHTMLContent(carePlan?.note || '')}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [carePlan?.note, carePlan?.type]);

  const footerSection = React.useMemo(() => {
    const date = encounter?.startTime ? encounter?.startTime : moment();
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 12 }}>
          <Grid container spacing={0}>
            {compact ? (
              <Grid item xs={6} textAlign="center" />
            ) : (
              <Grid item xs={6} textAlign="center">
                <Typography variant="body1">&nbsp;</Typography>
                <Typography variant="h6">
                  <FormattedMessage id="medicalPrint.headDoctor" />
                </Typography>
                <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                  <FormattedMessage id="medicalPrint.helperText" />
                </Typography>
                <Typography variant="body1">{headDoctor?.employeeFullName}</Typography>
              </Grid>
            )}
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id="printForm.dateShort"
                  values={{
                    day: date.date(),
                    month: date.month() + 1,
                    year: date.year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="examDoctor" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
              <Typography variant="body1">{encounter?.picName}</Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [compact, encounter?.picName, encounter?.startTime, headDoctor?.employeeFullName]);

  return (
    <>
      <Box style={{ marginBottom: 15 }}>
        {header}
        {administrative}
        <Box paddingY={1} style={{ clear: 'both', pageBreakInside: 'avoid' }}>
          <Divider />
        </Box>
        {encounterInfo}
        <Box paddingY={1} style={{ clear: 'both', pageBreakInside: 'avoid' }}>
          <Divider />
        </Box>
        {solutionSection}
        {children ? children : null}
        {footerSection}
      </Box>
    </>
  );
};

export default PrintContent;
