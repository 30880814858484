import firebase from 'firebase/app';
import 'firebase/messaging';
import { firebaseConfig } from './constants';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}

let messaging: firebase.messaging.Messaging;

if (typeof window !== 'undefined') {
  if (firebase.messaging.isSupported()) {
    messaging = firebase.messaging();
  }
}

export const getMessagingToken = () => {
  if (!messaging) return;
  return messaging
    .getToken({
      vapidKey: firebaseConfig.vapidKey,
    })
    .catch((e) => console.log(e));
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging?.onMessage((payload) => {
      resolve(payload);
    });
  });
