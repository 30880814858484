import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useCallback } from 'react';
import { BE_DATE_TIME_FORMAT, FE_DATE_TIME_FORMAT, some, SS_TOKEN } from 'modules/common/constants';
import { mutate } from 'swr';
import moment from 'moment/moment';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { Delete, Edit, Print } from '@mui/icons-material';
import { FormattedMessage } from 'react-intl';
import CameraImageGalleryDialog from './CameraImageGalleryDialog';
import CameraPrintFormDialog from './CameraPrintFormDialog';
import ImageBox from './ImageBox';
import axios from 'axios';
import Stack from '@mui/material/Stack';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import { API_SERVER } from 'modules/common/api';
import { axiosThunk } from 'modules/common/redux/axios';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useParams } from 'react-router';
import { MedicalIcon } from 'svg';
import { useDiagnosisImageSelectors } from './state';
import { useDentalDetailStateSelector } from '../../state';
import { useFetch } from 'modules/common/hook';

const DentalDiagnosticImage = () => {
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const onOpenCameraDialog = useDiagnosisImageSelectors.use.onOpenCameraDialog();
  const onOpenPrintFormDialog = useDiagnosisImageSelectors.use.onOpenPrintFormDialog();
  const selectedDiagnosisImage = useDiagnosisImageSelectors.use.selectedDiagnosisImage();
  const { patientId } = useParams<{ patientId; dentalSessionId }>();
  const { data: diagnosisImages, revalidate: diagnosisImagesRevalidate } = useFetch(
    API_SERVER.diagnosticImage.getDiagnosticImages({ imageType: 'PATIENT_DENTAL', referenceId: patientId || 0 }),
    {
      enabled: patientId,
    },
  );

  const onSaveSingleImage = useCallback(
    async (files: FileList) => {
      dispatch(setLoading(true));
      try {
        let multipartFormData = new FormData();
        const types: string[] = [];
        const referenceIds: string[] = [];
        const descriptions: string[] = [];
        for (let i = 0; i < files.length; i++) {
          multipartFormData.append('files', files[i]);
          types.push(STORAGE_RESOURCE_TYPE.dentalExamImage);
          referenceIds.push(patientId);
          descriptions.push(' ');
        }
        const token = sessionStorage.getItem(SS_TOKEN) || '';
        await axios.post(
          API_SERVER.storageResource.uploadMultiFile({
            types: types.join(','),
            referenceIds: referenceIds.join(','),
            descriptions: descriptions.join(','),
          }),
          multipartFormData,
          { headers: { 'Content-type': 'multipart/form-data', Authorization: `Bearer ${token}` } },
        );

        mutate(
          API_SERVER.storageResource.getList({
            type: STORAGE_RESOURCE_TYPE.dentalExamImage,
            referenceId: patientId,
          }).url,
        );
        openSnackbar({ message: intl.formatMessage({ id: 'exam.addNewSucceeds' }) });
      } catch (error) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, openSnackbar, patientId],
  );

  const onDeleteTherapyImage = useCallback(
    async (value: some, index) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: `CĐHA-${index + 1}` }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.diagnosticImage.delete(value?.id), method: 'delete' }));
          await diagnosisImagesRevalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, diagnosisImagesRevalidate],
  );

  const readOnly = useDentalDetailStateSelector.use.readonly();

  return (
    <Box display="flex" flexDirection="column" gap={1} marginTop={2}>
      {diagnosisImages
        ?.sort((a, b) => {
          return (a.createdTime?.millisecond() || 0) - (b.createdTime?.millisecond() || 0);
        })
        ?.map((item, index) => {
          return (
            <Box
              key={item.id}
              color="inherit"
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                textAlign: 'start',
                minHeight: '32px',
                padding: 1,
                bgcolor: 'secondary.light',
                alignItems: 'center',
                borderRadius: 1,
              }}
            >
              <Typography variant="subtitle2" color="textPrimary" flex={1}>
                {moment(item.updatedTime || item.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT)}
                &emsp;CĐHA-
                {index + 1}
              </Typography>
              <IconButton
                style={{ padding: 4, marginRight: 8 }}
                onClick={() => onOpenPrintFormDialog(item)}
                disabled={readOnly}
              >
                <Print />
              </IconButton>
              <IconButton
                style={{ padding: 4, marginRight: 8 }}
                onClick={() => onOpenCameraDialog(item)}
                disabled={readOnly}
              >
                <Edit />
              </IconButton>
              <IconButton
                style={{ padding: 4 }}
                onClick={() => {
                  onDeleteTherapyImage(item, index);
                }}
                disabled={readOnly}
              >
                <Delete />
              </IconButton>
            </Box>
          );
        })}
      <Stack direction="row" gap={2}>
        <Button
          color="inherit"
          startIcon={<MedicalIcon />}
          sx={{ width: 'fit-content' }}
          onClick={() => onOpenCameraDialog()}
          disabled={readOnly}
        >
          <FormattedMessage id="camera.connection" />
        </Button>
        <IconButton
          disabled={readOnly}
          component="label"
          sx={{
            borderRadius: '4px',
            backgroundColor: readOnly ? 'rgba(0, 0, 0, 0.12)!important' : '#CDDFFF',
            color: '#0052E0',
            fontSize: '14px',
            fontWeight: 500,
            fontFamily: 'Roboto',
            letterSpacing: '0.02857em',
            width: '181px',
          }}
        >
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            style={{ objectFit: 'cover', position: 'relative' }}
            gap={1}
          >
            <input
              accept="image/*"
              hidden
              type="file"
              multiple
              onChange={(event) => {
                if (event.target.files && event.target.files?.length > 0) {
                  onSaveSingleImage(event.target.files);
                }
              }}
            />
            <AddPhotoAlternateIcon color="primary" />
            <FormattedMessage id="therapy.detail.label.attackImage" />
          </Stack>
        </IconButton>
      </Stack>
      <CameraImageGalleryDialog />
      {selectedDiagnosisImage && <CameraPrintFormDialog />}
      <ImageBox />
    </Box>
  );
};

export default DentalDiagnosticImage;
