import { Stack, Typography } from '@mui/material';
import useFilterContext from '../useFilterContext';
import SelectInput from '../../form/SelectInput';
import { ROLES } from 'modules/common/constants';
import { FormattedMessage } from 'react-intl';
import { FilterProps, MedicalSupplyGroupFilter } from '../type';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import ChipList from '../CustomeChip';

const MedicalSupplyGroup = ({ filter: { placeholder, type } }: FilterProps<MedicalSupplyGroupFilter>) => {
  const { form } = useFilterContext();
  const { data: systemCategories } = useFetch(API_SERVER.medicalSupplies.getList(), {
    revalidateOnFocus: false,
  });
  const selectedMedicalSypplyGroups = form.watch(type);
  return (
    <Stack direction="column" gap="10px" flex={1}>
      <SelectInput
        form={form}
        name={type}
        options={systemCategories}
        getValue="id"
        renderLabel="name"
        rawOptionLabel
        placeholder={placeholder}
        multiple
        hideError
        renderValue={() => (
          <Typography
            sx={{
              color: '#AEB3B5',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              letterSpacing: '0.15px',
              lineHeight: 'unset',
            }}
          >
            <FormattedMessage id={placeholder} />
          </Typography>
        )}
        renderGroup={(optionKey) => ROLES[optionKey].label}
      />
      {selectedMedicalSypplyGroups && selectedMedicalSypplyGroups.length > 0 && (
        <ChipList
          items={selectedMedicalSypplyGroups}
          labelKey="name"
          valueKey="id"
          onDelete={(data) =>
            form.setValue(
              type,
              selectedMedicalSypplyGroups.filter((ele) => ele.id !== data),
            )
          }
        />
      )}
    </Stack>
  );
};

export default MedicalSupplyGroup;
