import { z } from 'zod';
import { PaymentDetailSchema } from './PaymentDetail';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { GENDER, PATIENT_TYPE, PAYMENT_STATUS, PAYMENT_TYPE } from 'modules/common/apiConstants';
import { BE_DATE_TIME_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';

export const PaymentSchema = z
  .object({
    id: z.number(),
    referenceId: z.number(),
    groupId: z.number(),
    discountPercent: z.number(),
    vatAmount: z.number(),
    discountAmount: z.number(),
    itemDiscountAmount: z.number(),
    totalAmount: z.number(),
    finalTotalAmount: z.number(),
    amountPaid: z.number(),
    discountAmountPaid: z.number(),
    itemDiscountAmountPaid: z.number(),
    vatAmountPaid: z.number(),
    totalAmountPaid: z.number(),
    debtAmount: z.number(),
    refundAmount: z.number(),
    prepayment: z.number(),
    type: createObjectKeysEnumSchema(PAYMENT_TYPE),
    status: createObjectKeysEnumSchema(PAYMENT_STATUS),
    paymentDetails: z.array(PaymentDetailSchema),
    isPackagePaid: z.boolean(),
    packagePaymentStatus: z.boolean(),
    totalDiscountAmount: z.number(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    referenceCode: z.string(),
    patientCode: z.string(),
    patientName: z.string(),
    dob: z.string(),
    gender: createObjectKeysEnumSchema(GENDER),
    paymentPlan: createObjectKeysEnumSchema(PAYMENT_PLAN),
    icdDiagnosis: z.string(),
    mobilePhone: z.string(),
    picName: z.string(),
    serviceName: z.string(),
    serviceId: z.number(),
    patientType: createObjectKeysEnumSchema(PATIENT_TYPE),
  })
  .partial();

export type Payment = z.infer<typeof PaymentSchema>;
