import { z } from 'zod';

export const EmployeeSchema = z
  .object({
    id: z.number(),
    fullName: z.string(),
    gender: z.string(),
    dob: z.string(),
    address: z.string(),
    status: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
    avatar: z.string(),
    position: z.string(),
    branch: z.string(),
    contractInfo: z.string(),
    code: z.string(),
    groupId: z.number(),
    resourceId: z.string(),
  })
  .partial();

export type Employee = z.infer<typeof EmployeeSchema>;
