import { Box } from '@mui/material';
import * as React from 'react';
import FormList from '../component/reportForm/FormList';

interface IManageReportFormPageProps {}

const ManageReportFormPage: React.FunctionComponent<IManageReportFormPageProps> = (props) => {
  return (
    <Box display="flex" flex={1} overflow="hidden">
      <FormList onSelect={(id) => console.log(id)} />
    </Box>
  );
};

export default ManageReportFormPage;
