import { ILayoutFields, ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';
import moment from 'moment';
import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  FE_DATE_FORMAT,
  FE_TIME_FORMAT,
  PATIENT_ADDRESS_TYPE,
} from '../../../../common/constants';
import React from 'react';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import Stack from '@mui/material/Stack';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const { intl, picOptions } = formProps || {};
  return {
    id: {
      type: 'hidden',
    },
    date: {
      type: 'datePicker',
      value: moment(valuesField?.dob, BE_DATE_FORMAT)?.isValid()
        ? moment(valuesField?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)
        : '',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'therapyList.dialog.form.date.title' }),
      disableFuture: false,
      register: {
        required: !!valuesField?.startTime || !!valuesField?.endTime,
      },
      tooltipError: true,
      propsWrapper: { xs: 12 },
    },
    startTime: {
      type: 'timePicker',
      label: intl.formatMessage({ id: 'therapyList.dialog.form.startTime.title' }),
      register: {
        required: !!valuesField?.date || !!valuesField?.endTime,
        validate: (value) => {
          if (!valuesField.endTime) return true;
          return value && moment(valuesField.endTime, FE_TIME_FORMAT).isAfter(moment(value, FE_TIME_FORMAT))
            ? true
            : intl.formatMessage({ id: 'calendar.timeInvalid' });
        },
      },
      propsWrapper: { xs: 6 },
    },
    endTime: {
      type: 'timePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'therapyList.dialog.form.endTime.title' }),
      propsWrapper: { xs: 6 },
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'therapyList.dialog.form.name.title' }),
      placeholder: intl.formatMessage({ id: 'therapyList.dialog.form.name.placeholder' }),
      propsWrapper: { xs: 12 },
      register: {
        required: true,
      },
    },
    content: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'therapy.sessionContent' }),
      placeholder: intl.formatMessage({ id: 'therapyList.dialog.form.name.placeholder' }),
      propsWrapper: { xs: 12 },
      multiline: true,
      rows: 3,
    },
    pic: {
      type: 'select',
      options: picOptions,
      label: intl.formatMessage({ id: 'therapyList.dialog.form.pic.title' }),
      placeholder: intl.formatMessage({ id: 'therapyList.dialog.form.pic.title' }),
      propsWrapper: { xs: 12 },
      rawOptions: true,
      disableClearBtn: true,
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  return [
    {
      id: 'form',
      fields: ['id', 'date', 'startTime', 'endTime', 'name', 'content', 'pic'],
    },
  ];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement, valuesField, fields } = props;
  return (
    <Stack direction="column">
      <Box flex={1} marginBottom={3}>
        <Typography variant="h6" marginBottom={2}>
          <FormattedMessage id="therapyList.dialog.form.name.patientInfo" />
        </Typography>
        <Box sx={{ display: 'grid', gridTemplateColumns: 'auto 1fr', columnGap: 2 }}>
          <Typography sx={{ fontWeight: 400, fontSize: 16, color: '#455A64' }}>
            <FormattedMessage id="therapyList.dialog.form.patientName.title" />
          </Typography>
          <Typography>{valuesField?.patient?.name}</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: 16, color: '#455A64' }}>
            <FormattedMessage id="therapyList.dialog.form.phoneNumber.title" />
          </Typography>
          <Typography>{valuesField?.patient?.mobilePhone}</Typography>
          <Typography sx={{ fontWeight: 400, fontSize: 16, color: '#455A64' }}>
            <FormattedMessage id="therapyList.dialog.form.address.title" />
          </Typography>
          <Typography>
            {valuesField?.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address}
          </Typography>
        </Box>
      </Box>
      <Box flex={1}>
        <Typography variant="h6" marginBottom={2}>
          <FormattedMessage id="therapyList.dialog.form.name.therapyInfo" />
        </Typography>
        {listElement[0]}
      </Box>
      <Box padding={1} paddingTop={2} display="flex" gap={2} justifyContent={'flex-end'}>
        {fields.footer.cancel}
        {fields.footer.default}
      </Box>
    </Stack>
  );
};

const schema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
  changeDataBeforeSubmit: (data, { picOptions }) => {
    const dateTimeFormat = `${FE_TIME_FORMAT} ${BE_DATE_FORMAT}`;
    const dateFormat = moment(data?.date).format(BE_DATE_FORMAT);
    const startDate = data?.date ? moment(`${data?.startTime} ${dateFormat}`, dateTimeFormat) : null;
    const endDate =
      startDate &&
      (data?.endTime ? moment(`${data.endTime} ${dateFormat}`, dateTimeFormat) : startDate.clone().add(2, 'hour'));
    return {
      id: data?.id,
      name: data?.name,
      content: data?.content,
      startTime: startDate?.format(BE_DATE_TIME_FORMAT),
      endTime: endDate?.format(BE_DATE_TIME_FORMAT),
      picUserId: data?.pic,
      picName: picOptions?.find((ele) => ele.value === data?.pic)?.label,
    };
  },
};

export default schema;
