import { GROUP_VERSION } from 'modules/common/apiConstants';
import { ISchemaFields, ISchemaForm, IUiFields, SchemaType } from '../../../../common/SchemaForm/utils';
import { USER_ROLE_LIST } from '../constant';

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl, employees, appState } = formProps || {};

  const basicMedi = appState.authen.user?.group_version === GROUP_VERSION.BASIC;

  return {
    username: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'accountAdmin.accountId' }),
      placeholder: intl.formatMessage({ id: 'accountAdmin.accountIdEnter' }),
      propsWrapper: { xs: 6 },
      autoFocus: true,
      readOnly: !!valuesField?.id,
      register: {
        required: true,
      },
    },
    role: {
      type: 'select',
      label: intl.formatMessage({ id: 'accountAdmin.accountType' }),
      placeholder: intl.formatMessage({ id: 'account.pickRole' }),
      propsWrapper: { xs: 6 },
      options: basicMedi ? USER_ROLE_LIST.filter((r) => r.isBasic) : USER_ROLE_LIST.filter((r) => r.isStandard),
      register: {
        required: true,
      },
    },
    employee: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'accountAdmin.employee' }),
      placeholder: intl.formatMessage({ id: 'accountAdmin.employeeEnter' }),
      options: employees,
      getOptionLabel: (option: any) => option.fullName,
      isOptionEqualToValue: (option, selected) => option.id === selected.id,
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    practiceCertificate: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'accountAdmin.licenseNumber' }),
      placeholder: intl.formatMessage({ id: 'accountAdmin.licenseNumberEnter' }),
      propsWrapper: { xs: 6 },
    },
    specialty: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'accountAdmin.specialty' }),
      placeholder: intl.formatMessage({ id: 'accountAdmin.specialtyEnter' }),
      multiline: true,
      rows: 3,
    },
  } as SchemaType;
};

const ui: IUiFields = ({ valuesField }) => {
  return [
    {
      id: 'layout',
      fields: ['username', 'role', 'employee', 'practiceCertificate', 'specialty'],
    },
  ];
};

export const schemaFormAdminAccount: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
