import makeStyles from '@mui/styles/makeStyles';
import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  FE_DATE_FORMAT,
  FE_DATE_TIME_FORMAT,
  PAYMENT_PLAN,
  some,
} from '../../../../../common/constants';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import * as React from 'react';
import HeaderPrintForm from '../../../../../common/component/HeaderPrintForm';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import TableCustom from '../../../../../common/component/TableCustom';
import { NumericFormatText } from '../../../../../common/utils';
import DialogCustom from '../../../../../common/component/DialogCustom';
import ReactToPrint from 'react-to-print';
import Print from '@mui/icons-material/Print';
import { PAYMENT_METHOD_ENUM } from '../../../../../admin/component/saleManagement/constant';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
      '& th,tr,td': {
        backgroundColor: 'unset',
      },
    },
  },
});

interface ContentProps {
  therapyDetail?: some;
  paymentAdvancePrint?: some;
}

interface Props {
  open: boolean;
  onClose?: () => void;
  therapyDetail?: some;
  paymentAdvancePrint?: some;
}

const PaymentPrintFormContent = (props: ContentProps) => {
  const { therapyDetail, paymentAdvancePrint } = props;
  const { appState, intl } = useGeneralHook();
  const { currentLocation } = appState.authen;

  const header = React.useMemo(() => {
    return (
      <Box>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase', marginBottom: '25px' }}>
            <FormattedMessage id="therapy.prepayment.paymentAdvancePrint.header.prepayment" />
          </Typography>
        </HeaderPrintForm>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ columnCount: 2 }}>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="therapy.code" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{therapyDetail?.code}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="therapy.label.customer.code" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{therapyDetail?.patient?.code?.split('-')?.[1]}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {therapyDetail?.patient?.name}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="category" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                <FormattedMessage id={PAYMENT_PLAN?.[therapyDetail?.paymentPlan]?.label || ' '} />
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                {therapyDetail?.patient?.patientAddressList?.find((a) => a.type === 'HOME')?.address}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {moment(therapyDetail?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {therapyDetail?.patient?.gender && (
                  <FormattedMessage id={therapyDetail?.patient?.gender?.toLowerCase()} />
                )}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="telephone" />
              </Typography>
              :&nbsp; <Typography variant="body1">{therapyDetail?.patient?.mobilePhone}</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    );
  }, [therapyDetail]);

  const prepaymentInfo = React.useMemo(() => {
    return (
      <>
        <Box>
          {paymentAdvancePrint && (
            <TableCustom
              dataSource={[paymentAdvancePrint]}
              containerProps={{
                sx: {
                  '& th,tr,td': {
                    padding: '2px 8px',
                    backgroundColor: 'white',
                    border: '1px solid #000',
                    color: 'unset',
                  },
                  width: '100%',
                  overflow: 'visible',
                  marginTop: '5px',
                  '& .MuiTypography-root': {
                    fontSize: '18px',
                    lineHeight: '26px',
                    marginTop: '10px',
                  },
                },
              }}
              columns={[
                {
                  title: 'therapy.prepaid.table.header.createdTime',
                  width: '30%',
                  render: (record: some) => (
                    <Typography variant="body1">
                      {record?.confirmedTime &&
                        moment(record?.confirmedTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT)}
                    </Typography>
                  ),
                },
                {
                  title: 'therapy.prepaid.table.header.prepaidAmount',
                  width: '30%',
                  render: (record: some) => <Typography variant="body1">{NumericFormatText(record.amount)}</Typography>,
                },
                {
                  title: 'cashier.paymentMethod',
                  width: '40%',
                  render: (record: some) => {
                    return (
                      <Typography variant="body1">
                        {intl.formatMessage({
                          id: record?.paymentMethod
                            ? `therapy.paymentMethod.${
                                PAYMENT_METHOD_ENUM.find((v) => v.value === record?.paymentMethod)?.label
                              }`
                            : ' ',
                        })}
                      </Typography>
                    );
                  },
                },
              ]}
            />
          )}
        </Box>
      </>
    );
  }, [intl, paymentAdvancePrint]);

  const footer = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 15 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} textAlign="center">
              {/*<Typography variant="body1">&nbsp;</Typography>*/}
              {/*<Typography variant="h6">*/}
              {/*  <FormattedMessage id="therapy.indication.request.printForm.patientConfirm" />*/}
              {/*</Typography>*/}
              {/*<Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>*/}
              {/*  <FormattedMessage id="medicalPrint.helperText" />*/}
              {/*</Typography>*/}
              {/*<Typography variant="body1">{therapyDetail?.patient?.name}</Typography>*/}
            </Grid>
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id={'printForm.dateShort'}
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="printForm.cashier" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>{' '}
              <Typography variant="body1">{appState.authen.user?.employee_name}</Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [appState.authen.user?.employee_name]);

  if (!currentLocation) {
    return null;
  }
  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }} sx={{ '& p': { fontSize: '18px', marginBottom: '10px' } }}>
        {header}
        <Box marginY={2}>
          <Divider />
        </Box>
        {prepaymentInfo}
        {footer}
      </Box>
    </>
  );
};

const PrepaymentPrintDialog = (props: Props) => {
  const { onClose, open, paymentAdvancePrint, therapyDetail } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: '60vw', maxWidth: '80vw' },
      }}
      title={'therapy.prepayment.paymentAdvancePrint.header.prepayment'}
      footer={
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="printReceipt" />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />
        </Stack>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <table style={{ width: '100%' }}>
          <tbody>
            <PaymentPrintFormContent paymentAdvancePrint={paymentAdvancePrint} therapyDetail={therapyDetail} />
          </tbody>
        </table>
      </Box>
    </DialogCustom>
  );
};

export default PrepaymentPrintDialog;
