import { z } from 'zod';

export const MedicationInstructionSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    groupId: z.number(),
  })
  .partial();

export type MedicationInstruction = z.infer<typeof MedicationInstructionSchema>;
