import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from '../../../common/component/TableCustom/element';
import TablePaginationActionsCustom from '../../../common/component/TableCustom/TablePaginationActionsCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { INVENTORY_EXPORT_STATUS, INVENTORY_EXPORT_TYPE, MEDICATION_UNIT } from '../inventory/constant';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';

const Row: React.FunctionComponent<{
  row: some;
  onViewDetail: (value) => void;
  index: number;
}> = (props) => {
  const { row, onViewDetail } = props;
  const [open, setOpen] = React.useState(false);
  const status = INVENTORY_EXPORT_STATUS.find((v) => v.value === row.status);
  const type = INVENTORY_EXPORT_TYPE.find((v) => v.value === row.type);

  // Get inventory export by id
  const { data: inventoryExportDetail } = useFetch(API_SERVER.exportInventory.getById(row?.id), {
    globalLoading: true,
    enabled: row?.id && open,
  });

  return (
    <>
      <TableCustomRow>
        <TableCustomCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell
          onClick={() => {
            onViewDetail({ ...row, readOnly: true });
          }}
        >
          <Typography color="primary" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            <Typography sx={{ fontWeight: '500' }}>{row.code}</Typography>
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            {row.exportDate && moment(row.exportDate, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography sx={{ fontWeight: '500' }}>{row.exportBy}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography sx={{ fontWeight: '500' }}>{type && <FormattedMessage id={type.label} />}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography
            noWrap
            sx={{
              color:
                status?.value === 'EXPORTED'
                  ? 'success.main'
                  : status?.value === 'CANCELLED'
                  ? 'error.main'
                  : 'primary.main',
              fontWeight: '500',
            }}
          >
            {status && <FormattedMessage id={status.label} />}
          </Typography>
        </TableCustomCell>
      </TableCustomRow>
      <TableRow style={{ backgroundColor: 'white', padding: 0 }}>
        <TableCustomCell style={{ padding: '0px', border: 'none', backgroundColor: 'inherit' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases" style={{ marginTop: 4 }}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell style={{ width: 54 }} />
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.drugCode" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.drugName" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.lot" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="expiredDate" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="quantity" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.unit" />
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryExportDetail?.inventoryExportDetails?.map((record: some, index) => {
                  const unit = MEDICATION_UNIT.find((v) => v.value === record.unit)?.label;
                  return (
                    <TableRow key={index}>
                      <TableCustomCell />
                      <TableCustomCell width="10%">{record.medication?.code}</TableCustomCell>
                      <TableCustomCell>{record.medication?.name}</TableCustomCell>
                      <TableCustomCell width="10%">{record.lot}</TableCustomCell>
                      <TableCustomCell width="10%">
                        {record.expiredDate && moment(record.expiredDate, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                      </TableCustomCell>
                      <TableCustomCell width="10%">
                        <FormattedNumber value={record.quantity} />
                      </TableCustomCell>
                      <TableCustomCell width="10%">{unit && <FormattedMessage id={unit} />}</TableCustomCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCustomCell>
      </TableRow>
    </>
  );
};

interface Props {
  data: some;
  onViewDetail: (value) => void;
  hookPagination: HookPaginationProps;
}

const ExportInventoryTable: React.FunctionComponent<Props> = (props) => {
  const { data, hookPagination, onViewDetail } = props;
  const { intl } = useGeneralHook();
  const { onPageChange, onRowsPerPageChange, pagination } = hookPagination;

  return (
    <Box
      position="relative"
      overflow="inherit"
      display="flex"
      flexDirection="column"
      height="-webkit-fill-available"
      flex={1}
    >
      <TableContainer style={{ height: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableCustomRow>
              <TableHeaderCell style={{ width: 54 }} />
              <TableHeaderCell style={{ width: 160 }}>
                <FormattedMessage id="inventory.code" />
              </TableHeaderCell>
              <TableHeaderCell width="20%">
                <FormattedMessage id="inventory.exportDate" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="inventory.exportBy" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="inventory.exportType" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="status" />
              </TableHeaderCell>
            </TableCustomRow>
          </TableHead>
          <TableBody>
            {data?.content?.length > 0 ? (
              data?.content?.map((row, index) => (
                <Row key={index} onViewDetail={onViewDetail} row={row} index={index + 1} />
              ))
            ) : (
              <>
                <TableCustomRow>
                  <TableCustomCell colSpan={6}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100%',
                      }}
                    >
                      <Typography variant="inherit" color="textSecondary">
                        <FormattedMessage id="noData" />
                      </Typography>
                    </Box>
                  </TableCustomCell>
                </TableCustomRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        data-tour="step-5"
        style={{ padding: 4 }}
        labelRowsPerPage={intl.formatMessage({ id: 'labelRowPerPage' })}
        ActionsComponent={TablePaginationActionsCustom}
        count={data?.pagination?.totalElements || 0}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Box>
  );
};

export default ExportInventoryTable;
