import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../common/api';
import usePaginationHook from '../../common/hook/usePaginationHook';
import EpisodeOfCareListCashier from '../component/list/EpisodeOfCareListCashier';
import FilterEncounterForCashier from '../component/list/FilterEncounterForCashier';
import { ENCOUNTER_STATUS, PAYMENT_STATUS } from 'modules/common/apiConstants';
import { formatDateFilterField } from 'modules/common/utils';
import { useFetch } from 'modules/common/hook/useFetch';
import PageHeader from 'modules/common/component/PageHeader';

interface IEpisodeOfCareListPageProps {}

const CashierEpisodeOfCareListPage: React.FunctionComponent<IEpisodeOfCareListPageProps> = (props) => {
  const hookPagination = usePaginationHook({
    defaultFilter: {
      status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
      paymentStatus: [PAYMENT_STATUS.PAID.value, PAYMENT_STATUS.UNPAID.value],
    },
  });
  const { params } = hookPagination;

  const formatParams = React.useMemo(() => {
    return {
      ...params,
      paymentStatus:
        params?.paymentStatus?.includes(PAYMENT_STATUS.UNPAID.value) && params?.paymentStatus?.length === 2
          ? [PAYMENT_STATUS.PAID.value, PAYMENT_STATUS.UNPAID.value, PAYMENT_STATUS.PREPAID.value]
          : params?.paymentStatus?.includes(PAYMENT_STATUS.UNPAID.value) && params?.paymentStatus?.length === 1
          ? [PAYMENT_STATUS.UNPAID.value, PAYMENT_STATUS.PREPAID.value]
          : params?.paymentStatus,
    };
  }, [params]);

  const { data: encounterPage, isValidating } = useFetch(
    API_SERVER.encounter.payment(formatDateFilterField(formatParams, 'fromDate', 'toDate')),
  );

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterEncounterForCashier
        hookPagination={hookPagination}
        pagin={
          <PageHeader
            titleKey="encounterList"
            pagination={{
              size: encounterPage?.content.length || 0,
              totalElements: encounterPage?.pagination.totalElements || 0,
            }}
          />
        }
      />
      <EpisodeOfCareListCashier
        encounters={encounterPage?.content}
        hookPagination={hookPagination}
        loading={isValidating}
        total={encounterPage?.pagination?.totalElements || 0}
      />
    </Paper>
  );
};

export default CashierEpisodeOfCareListPage;
