import { Box } from '@mui/material';
import * as React from 'react';
import { some } from 'modules/common/constants';
import AttachedDocumentBox from './attachedDocument/AttachedDocumentBox';

interface Props {
  dataTherapySession: some;
  editable: boolean;
  therapy: some;
}

const TherapySessionDocument: React.FunctionComponent<Props> = (props) => {
  const { dataTherapySession, editable, therapy } = props;
  return (
    <Box className="file">
      <AttachedDocumentBox role="exam" dataTherapySession={dataTherapySession} editable={editable} therapy={therapy} />
    </Box>
  );
};

export default TherapySessionDocument;
