import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_FORMAT } from '../common/constants';
import { UnitQuantitySchema } from './UnitQuantity';
import { UnitConverterSchema } from './UnitConverter';

export const MedicationPackageSchema = z
  .object({
    lot: z.string(),
    expiredStatus: z.string(),
    expiredDate: createDateTimeSchema(BE_DATE_FORMAT),
    unitQuantityList: z.array(UnitQuantitySchema),
    unitConverterList: z.array(UnitConverterSchema),
    quantityBasic: z.number(),
    averagePrice: z.number(),
  })
  .partial();

export type MedicationPackage = z.infer<typeof MedicationPackageSchema>;
