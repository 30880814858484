import { AlertColor } from '@mui/material';
import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionType, createCustomAction, getType } from 'typesafe-actions';
import { AppState } from '../../../redux/reducer';
import { API_SERVER, IDENTITY_API_SERVER } from '../api';
import { CODE_SYSTEM, ORDER_BE, ROLES, some } from '../constants';
import { fetchThunk } from './thunk';
import {
  CodeSystem,
  CodeSystemSchema,
  District,
  MedicationInstruction,
  PhysicsRoom,
  PrescriptionFormDetailTemplate,
  PrescriptionFormTemplate,
  Province,
  Service,
  SpecializedDepartment,
  UserEmployeeRole,
  UserEmployeeRoleSchema,
} from 'modules/schema';
import { Dictionary, flatMap, groupBy, keyBy, mapValues } from 'lodash';
import { axiosThunk } from './axios';
import { z } from 'zod';
import { ORDER_TYPE, PHYSICS_ROOM_CODE } from '../apiConstants';
import { EvaluationForm, EvaluationFormSchema } from 'modules/schema/EvaluationForms';
import { MedicalExaminationForm } from 'modules/schema/MedicalExaminationForm';

export const VN_CODE = '0';

export interface SnackbarProps {
  isOpen: boolean;
  type?: AlertColor | number;
  message?: React.ReactNode;
}

export interface NotifyProps {
  isOpen: boolean;
  title?: React.ReactNode | string;
  message?: React.ReactNode | string;
}

type ProvinceState = CodeSystem & { districts: CodeSystem[] };

export interface CommonState {
  readonly networkErrorMsg?: string;
  loading: boolean;
  icd10s: CodeSystem[];
  evaluationForms: Dictionary<EvaluationForm>;
  openErrorDialog: boolean;
  ethnicList: CodeSystem[];
  countryList: CodeSystem[];
  provinceData: ProvinceState[];
  provinces: Dictionary<Province>;
  districts: Dictionary<District>;
  intendedRoutes: CodeSystem[];
  departments: CodeSystem[];
  snackbarProps: SnackbarProps;
  notifyProps: NotifyProps;
  orderEncounter: keyof typeof ORDER_TYPE;
  orderTherapy: string;
  currencyOptions: CodeSystem[];
  groupCurrency: string;
  dentalIllnessOptions: some[];
  medicalIllnessOptions: some[];
  users: Dictionary<UserEmployeeRole>;
  roles: { [key in keyof typeof ROLES]: UserEmployeeRole[] };
  specializedDepartments: Dictionary<SpecializedDepartment>;
  physicRooms: { [key in keyof typeof PHYSICS_ROOM_CODE]: PhysicsRoom[] };
  healthCheckServices: Dictionary<Service>;
  medicalExaminationForms: Dictionary<MedicalExaminationForm>;
  prescrptionFormTemplate: Dictionary<PrescriptionFormTemplate>;
  medicationInstructions: MedicationInstruction[];
}

export const setCloseSnackbar = createCustomAction('common/setCloseSnackbar');
export const setOrderEncounter = createCustomAction('common/setOrderEncounter', (val: keyof typeof ORDER_TYPE) => ({
  val,
}));
export const toggleOrderEncounter = createCustomAction('common/toggleOrderEncounter');
export const setOrderTherapy = createCustomAction('common/setOrderTherapy', (val: string) => ({ val }));
export const setOpenSnackbar = createCustomAction('common/setOpenSnackbar', (val: Omit<SnackbarProps, 'isOpen'>) => ({
  val,
}));
export const setCloseNotify = createCustomAction('common/setCloseNotify');
export const setOpenNotify = createCustomAction('common/setOpenNotify', (val: Omit<NotifyProps, 'isOpen'>) => ({
  val,
}));

export const setNetworkError = createCustomAction(
  'common/setNetworkError',
  (errorMsg: string, openErrorDialog: boolean) => ({
    errorMsg,
    openErrorDialog,
  }),
);
export const setNational = createCustomAction(
  'common/setNational',
  ({ districts, provinces }: { districts: Dictionary<District>; provinces: Dictionary<Province> }) => ({
    districts,
    provinces,
  }),
);
export const setLoading = createCustomAction('common/setLoading', (val: boolean) => ({ val }));
export const setRolesAndUsers = createCustomAction(
  'common/roles',
  (roles: { [key in keyof typeof ROLES]: UserEmployeeRole[] }, users: Dictionary<UserEmployeeRole>) => ({
    roles,
    users,
  }),
);
export const setHealthCheckServices = createCustomAction('common/setHealthCheckServices', (val: Service[]) => ({
  val,
}));
export const setMedicalExaminationForm = createCustomAction(
  'common/setMedicalExaminationForm',
  (val: Dictionary<MedicalExaminationForm>) => ({
    val,
  }),
);
export const setPhysicRoom = createCustomAction(
  'common/physicRooms',
  (val: { [key in keyof typeof PHYSICS_ROOM_CODE]: PhysicsRoom[] }) => ({
    val,
  }),
);

export const setPrescriptionFormTemplate = createCustomAction(
  'common/presriptionFormTemplate',
  (val: Dictionary<PrescriptionFormDetailTemplate>) => ({
    val,
  }),
);
export const setSpecializedDepartments = createCustomAction(
  'common/specializedDepartments',
  (val: Dictionary<SpecializedDepartment>) => ({ val }),
);
export const setICD10s = createCustomAction('common/setICD10s', (val: CodeSystem[]) => ({ val }));
export const setNationalInsuranceCopayPercent = createCustomAction(
  'common/setNationalInsuranceCopayPercent',
  (val: some) => ({ val }),
);
export const setMedicationInstructions = createCustomAction(
  'common/setMedicationInstructions',
  (val: MedicationInstruction[]) => ({
    val,
  }),
);
export const setOpenErrorDialog = createCustomAction('common/setOpenErrorDialog', (val: boolean) => ({ val }));
export const setEvaluationForm = createCustomAction('common/setEvaluationForm', (val: Dictionary<EvaluationForm>) => ({
  val,
}));
export const setCountryList = createCustomAction('common/setCountryList', (val: CodeSystem[]) => ({ val }));
export const setProvinceData = createCustomAction('common/setProvinceData', (val: ProvinceState[]) => ({ val }));
export const setIntendedRoutes = createCustomAction('common/setIntendedRoutes', (val: CodeSystem[]) => ({ val }));
export const setDepartmentCodes = createCustomAction('common/setDepartments', (val: CodeSystem[]) => ({ val }));
export const setCurrencyOptions = createCustomAction('common/setCurrencyOptions', (val: CodeSystem[]) => ({ val }));
export const setGroupCurrency = createCustomAction('common/setGroupCurrency', (val: string) => ({ val }));
export const setDentalIllnessOption = createCustomAction('common/setDentalIllnessOption', (val: some[]) => ({ val }));
export const setMedicalIllnessOption = createCustomAction('common/setMedicalIllnessOption', (val: some[]) => ({ val }));

export function loadICD10Data(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch, getState) => {
    const { data } = await dispatch(
      axiosThunk({
        url: API_SERVER.code_system.getList({
          type: CODE_SYSTEM.ICD10,
        }).url,
      }),
    );
    dispatch(setICD10s(z.array(CodeSystemSchema).parse(data) || []));
  };
}
export function loadMedicationInstructions(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    const controller = API_SERVER.medicationInstruction.getMedicationInstructions();
    const { data } = await dispatch(
      axiosThunk({
        url: controller.url,
      }),
    );
    dispatch(setMedicationInstructions(controller.schema.parse(data) || []));
  };
}
export function loadMedicalExaminationForm(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch, getState) => {
    const controller = API_SERVER.medicalExaminationForm.getList();
    const { data } = await dispatch(
      axiosThunk({
        url: controller.url,
      }),
    );
    const parsedData = controller.schema.safeParse(data);
    if (parsedData.success) {
      dispatch(setMedicalExaminationForm(keyBy(parsedData.data, 'id') || {}));
    }
  };
}
export function loadNational(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    const nationalController = API_SERVER.nationalData.getAllProvincesWithDistricts();
    const [{ data: provinces }, { data: countries }] = await Promise.all([
      dispatch(axiosThunk({ url: nationalController.url })),
      dispatch(axiosThunk({ url: API_SERVER.code_system.getList({ type: CODE_SYSTEM.country }).url })),
    ]);
    const parseData = nationalController.schema.parse(provinces);
    const districts = flatMap(parseData, (province) => province.districts || []);
    dispatch(
      setNational({
        districts: keyBy(districts, 'code'),
        provinces: keyBy(parseData, 'code'),
      }),
    );
    dispatch(
      setProvinceData(
        parseData
          ?.map((provice) => {
            return {
              value: provice.code!,
              label: provice.fullName!,
              districts:
                provice?.districts?.map((district) => ({
                  value: district.code!,
                  label: district.fullName!,
                })) || [],
            };
          })
          .sort((a) => (a.value === '01' || a.value === '79' || a.value === '48' ? -1 : 1)), // HCM Hà Nội Đã Nẵng  lên đầu || [],
      ),
    );
    dispatch(setCountryList(z.array(CodeSystemSchema).parse(countries)));
  };
}

export function loadRoles(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    const { data } = await dispatch(
      axiosThunk({ url: IDENTITY_API_SERVER.user.getListUserByRoles({ status: 'ACTIVE' }).url }),
    );
    const parseData = z.array(UserEmployeeRoleSchema).parse(data);
    const userEmployeeRoleMap = groupBy(parseData, 'roleName');
    dispatch(
      setRolesAndUsers(
        mapValues(ROLES, (role) => userEmployeeRoleMap[role.value] || []),
        keyBy(parseData, 'id'),
      ),
    );
  };
}

export function loadPhysicRooms(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    const { data } = await dispatch(axiosThunk({ url: API_SERVER.physicsRoom.getList().url }));
    dispatch(
      setPhysicRoom(
        mapValues(PHYSICS_ROOM_CODE, (roomCode) => {
          let systemCategory = data.find((s) => s.code === roomCode.code);
          return systemCategory?.items || [];
        }),
      ),
    );
  };
}

export function loadPrescriptionFormTemplate(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    const controller = API_SERVER.dental.getDentalPrescriptionTemplate();
    const { data } = await dispatch(axiosThunk({ url: controller.url }));
    const parsedData = controller.schema.safeParse(data);
    if (parsedData.success) {
      dispatch(setPrescriptionFormTemplate(keyBy(parsedData.data, 'id')));
    }
  };
}

export function loadSpecializedDepartments(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    const { data } = await dispatch(axiosThunk({ url: API_SERVER.specializedDepartment.getAll().url }));
    dispatch(setSpecializedDepartments(keyBy(data, 'code')));
  };
}

export function loadEvaluationForm(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    const { data } = await dispatch(axiosThunk({ url: API_SERVER.evaluationForms.getAll().url }));
    const parsedData = z.array(EvaluationFormSchema).parse(data);
    dispatch(setEvaluationForm(keyBy(parsedData, 'id')));
  };
}

export function loadIntentedRoutes(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch) => {
    const { data } = await dispatch(
      axiosThunk({ url: API_SERVER.code_system.getList({ type: CODE_SYSTEM.MEDICATION_INTENDED_ROUTE }).url }),
    );
    if (data) {
      const intendedRoutes = z.array(CodeSystemSchema).safeParse(data);
      intendedRoutes?.success && dispatch(setIntendedRoutes(intendedRoutes.data));
    }
  };
}

export function loadDepartmentCodes(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch, getState) => {
    const [departmentCodes] = await Promise.all([
      dispatch(fetchThunk(API_SERVER.code_system.getList({ type: CODE_SYSTEM.department }).url, 'get')),
    ]);
    dispatch(setDepartmentCodes(z.array(CodeSystemSchema).parse(departmentCodes)));
  };
}

export function loadCurrencyOptions(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch, getState) => {
    const currencies = await dispatch(
      fetchThunk(API_SERVER.code_system.getList({ type: CODE_SYSTEM.currency }).url, 'get'),
    );
    const currenciesOptions = currencies.map((entry) => {
      return {
        value: entry.value,
        label: entry.value + ' - ' + entry.label,
      };
    });
    dispatch(setCurrencyOptions(currenciesOptions));
  };
}

export function loadDentalIllnessOption(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
  return async (dispatch, getState) => {
    const dentalIllness = await dispatch(
      fetchThunk(API_SERVER.code_system.getList({ type: CODE_SYSTEM.dentalIllness }).url, 'get'),
    );
    const medicalIllness = await dispatch(
      fetchThunk(API_SERVER.code_system.getList({ type: CODE_SYSTEM.medicalIllness }).url, 'get'),
    );
    const dentalIllnessOptions = dentalIllness.map((entry) => {
      return {
        value: entry.label,
        label: entry.label,
      };
    });
    const medicalIllnessOptions = medicalIllness.map((entry) => {
      return {
        value: entry.label,
        label: entry.label,
      };
    });
    dispatch(setDentalIllnessOption(dentalIllnessOptions));
    dispatch(setMedicalIllnessOption(medicalIllnessOptions));
  };
}

const actions = {
  setNetworkError,
  setLoading,
  setICD10s,
  setOpenErrorDialog,
  setNationalInsuranceCopayPercent,
  setEvaluationForm,
  setOpenSnackbar,
  setCloseSnackbar,
  setCountryList,
  setProvinceData,
  setIntendedRoutes,
  setDepartmentCodes,
  setOpenNotify,
  setCloseNotify,
  setOrderEncounter,
  setCurrencyOptions,
  setGroupCurrency,
  setOrderTherapy,
  setDentalIllnessOption,
  setMedicalIllnessOption,
  setRolesAndUsers,
  toggleOrderEncounter,
  setSpecializedDepartments,
  setPhysicRoom,
  setNational,
  setMedicalExaminationForm,
  setPrescriptionFormTemplate,
  setMedicationInstructions,
};

type Action = ActionType<typeof actions>;

export default function reducer(
  state: CommonState = {
    loading: false,
    icd10s: [],
    openErrorDialog: false,
    evaluationForms: {},
    ethnicList: [
      { value: '0', label: 'DT thiểu số' },
      { value: '1', label: 'Kinh' },
      { value: '55', label: 'Người nước ngoài' },
      { value: '56', label: 'Không rõ' },
    ],
    countryList: [],
    provinceData: [],
    intendedRoutes: [],
    departments: [],
    provinces: {},
    districts: {},
    snackbarProps: { isOpen: false },
    notifyProps: { isOpen: false },
    orderEncounter: ORDER_TYPE.DESC,
    orderTherapy: ORDER_BE.desc,
    currencyOptions: [],
    groupCurrency: '',
    dentalIllnessOptions: [],
    medicalIllnessOptions: [],
    specializedDepartments: {},
    users: {},
    roles: mapValues(ROLES, () => []),
    physicRooms: mapValues(PHYSICS_ROOM_CODE, () => []),
    healthCheckServices: {},
    medicalExaminationForms: {},
    prescrptionFormTemplate: {},
    medicationInstructions: [],
  },
  action: Action,
): CommonState {
  switch (action.type) {
    case getType(setNetworkError):
      return { ...state, networkErrorMsg: action.errorMsg, openErrorDialog: action.openErrorDialog };
    case getType(setLoading):
      return { ...state, loading: action.val };
    case getType(setRolesAndUsers):
      return { ...state, roles: action.roles, users: action.users };
    case getType(setICD10s):
      return { ...state, icd10s: action.val };
    case getType(setOpenErrorDialog):
      return { ...state, openErrorDialog: action.val };
    case getType(setEvaluationForm):
      return { ...state, evaluationForms: action.val };
    case getType(setOpenSnackbar):
      return { ...state, snackbarProps: { isOpen: true, ...action.val } };
    case getType(setCloseSnackbar):
      return {
        ...state,
        snackbarProps: { ...state.snackbarProps, isOpen: false },
      };
    case getType(setOpenNotify):
      return { ...state, notifyProps: { isOpen: true, ...action.val } };
    case getType(setCloseNotify):
      return {
        ...state,
        notifyProps: { ...state.notifyProps, isOpen: false },
      };
    case getType(setCountryList):
      return {
        ...state,
        countryList: action.val,
      };
    case getType(setProvinceData):
      return {
        ...state,
        provinceData: action.val,
      };
    case getType(setIntendedRoutes):
      return {
        ...state,
        intendedRoutes: action.val,
      };
    case getType(setDepartmentCodes):
      return {
        ...state,
        departments: action.val,
      };
    case getType(setOrderEncounter):
      return { ...state, orderEncounter: action.val };
    case getType(toggleOrderEncounter):
      return { ...state, orderEncounter: state.orderEncounter === 'ASC' ? 'DESC' : 'ASC' };
    case getType(setCurrencyOptions):
      return {
        ...state,
        currencyOptions: action.val,
      };
    case getType(setGroupCurrency):
      return {
        ...state,
        groupCurrency: action.val,
      };
    case getType(setDentalIllnessOption):
      return {
        ...state,
        dentalIllnessOptions: action.val,
      };
    case getType(setMedicalIllnessOption):
      return {
        ...state,
        medicalIllnessOptions: action.val,
      };
    case getType(setOrderTherapy):
      return { ...state, orderTherapy: action.val };
    case getType(setSpecializedDepartments):
      return { ...state, specializedDepartments: action.val };
    case getType(setPhysicRoom):
      return { ...state, physicRooms: action.val };
    case getType(setNational):
      return { ...state, districts: action.districts, provinces: action.provinces };
    case getType(setMedicalExaminationForm):
      return { ...state, medicalExaminationForms: action.val };
    case getType(setPrescriptionFormTemplate):
      return { ...state, prescrptionFormTemplate: action.val };
    case getType(setMedicationInstructions):
      return { ...state, medicationInstructions: action.val };
    default:
      return state;
  }
}
