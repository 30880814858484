import { z } from 'zod';
import { TherapyDefinitionIndicationSchema } from './TherapyDefinitionIndication';

export const TherapyDefinitionDetailSchema = z
  .object({
    id: z.number(),
    description: z.string(),
    name: z.string(),
    price: z.number(),
    indicationList: z.array(TherapyDefinitionIndicationSchema),
  })
  .partial();

export type TherapyDefinitionDetail = z.infer<typeof TherapyDefinitionDetailSchema>;
