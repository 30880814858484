import ExamCarePlan from 'modules/common/component/ExamCarePlan';
import { useParams } from 'react-router';
import { useDentalDetailStateSelector } from '../state';
import { Box } from '@mui/material';
import DentalPrintForm from '../DentalPrintForm';
import { useDialog } from 'modules/common/hook/useDialog';
import { Encounter } from 'modules/schema';
import PrintFormDialogExamCarePlan from 'modules/examDoctor/component/detail/info/PrintFormDialogExamCarePlan';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { CARE_PLAN_SCOPE } from 'modules/common/apiConstants';

interface Props {
  readOnly: boolean;
  encounter: Encounter;
}

const CarePlanSession: React.FunctionComponent<Props> = (props) => {
  const { readOnly, encounter } = props;
  const { dentalSessionId } = useParams<{ dentalSessionId; patientId }>();
  const { encounterId } = useParams<{ encounterId }>();
  const readOnlyDental = useDentalDetailStateSelector.use.readonly() || readOnly;
  const dental = useDentalDetailStateSelector.use.dentalExamInfo();
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const { data: carePlan } = useFetch(
    API_SERVER.carePlan.get({ referenceId: encounterId!, scope: CARE_PLAN_SCOPE.ENCOUNTER }),
    { enabled: encounterId },
  );

  return (
    <Box sx={{ backgroundColor: 'white', padding: '12px 24px' }}>
      <ExamCarePlan
        editable={!readOnlyDental}
        referenceId={dentalSessionId || encounterId}
        scope={dentalSessionId ? 'DENTAL' : 'ENCOUNTER'}
        patient={dental?.patient || encounter?.patient}
        onPrint={onOpenPrintDialog}
      />
      {dentalSessionId ? (
        <DentalPrintForm isHospitalTranfer open={openPrintDialog} onClose={onClosePrintDialog} />
      ) : (
        <PrintFormDialogExamCarePlan
          data={carePlan}
          patientData={encounter}
          open={openPrintDialog}
          onClose={onClosePrintDialog}
        />
      )}
    </Box>
  );
};

export default CarePlanSession;
