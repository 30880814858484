import { CameraAlt, Close, Delete, Print } from '@mui/icons-material';
import AddAPhotoOutlinedIcon from '@mui/icons-material/AddAPhotoOutlined';
import {
  Avatar,
  Box,
  Button,
  ButtonBase,
  Checkbox,
  Dialog,
  FormControlLabel,
  IconButton,
  TextField,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import Webcam from 'react-webcam';
import { TwinCameraIcon } from '../../../../../svg';
import ImageGalleryDialog from 'modules/common/component/ImageGalleryDialog';
import MediCard from 'modules/common/component/MediCard';
import NoDataBox from 'modules/common/component/NoDataBox';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import FormControlAutoComplete from 'modules/common/SchemaForm/element/autocomplete/FormControlAutoComplete';
import SelectCustom from 'modules/common/SchemaForm/element/select/SelectCustom';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import { API_SERVER } from 'modules/common/api';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import ImagePreview from 'modules/common/component/ImagePreview';
import * as React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { useFetch } from 'modules/common/hook';

interface Props {
  therapy: some;
  formData: some | null;
  open: boolean;
  onClose: () => void;
  onSave: (value: some, methods) => void;
  onPrintFormClickHandler: () => void;
}
const ContentBox = (props: Props) => {
  const {
    onPrintFormClickHandler,
    formData = { images: [], examDepartment: '', conclusion: '' },
    therapy,
    onClose,
    onSave,
  } = props;

  const { encounterId } = useParams<{ encounterId }>();
  const { data: images } = useFetch(
    API_SERVER.storageResource.getList({
      type: encounterId
        ? STORAGE_RESOURCE_TYPE.encounterDiagNosticImageAlbum
        : STORAGE_RESOURCE_TYPE.therapyDiagnosticImageAlbum,
      referenceId: formData?.id || 0,
    }),
    {
      enabled: formData?.id,
    },
  );
  const evaluationFormMap = useSelector((appState: AppState) => appState.common.evaluationForms);
  const refFormData = useRef<any>(formData);
  const [indexImage, setIndexImage] = useState<undefined | number>(undefined);
  const { intl, confirmDialog } = useGeneralHook();
  const methods = useForm<{
    images: { imageSrc?: string; id?: string | number; description?: string; checked?: boolean; deleted?: boolean }[];
    examDepartment: string;
    conclusion: string;
    id: string;
  }>({
    defaultValues: formData || {},
    reValidateMode: 'onChange',
    mode: 'onSubmit',
  });

  const { control, setValue, watch, handleSubmit, reset, formState } = methods;
  const { fields, append } = useFieldArray({
    control: control,
    name: 'images',
    keyName: 'key',
  });

  useEffect(() => {
    setValue('images', images?.map((ele) => ({ ...ele, checked: true, deleted: false })) || []);
  }, [images, setValue]);

  const imagesField = watch('images') || [];

  const webcamRef = useRef<any>(null);

  const [automaticDuration, setAutomaticDuration] = useState(1000);
  const [automation, setAutomation] = useState(false);
  const refInterval = useRef<any>(null);

  const captureScreen = useCallback(() => {
    if (imagesField?.length >= 100) {
      refInterval.current && clearInterval(refInterval.current);
      setAutomation(false);
      return;
    }
    const imageSrc = webcamRef.current.getScreenshot();
    append({ imageSrc, checked: true });
  }, [append, imagesField?.length]);

  useEffect(() => {
    if (automation) {
      refInterval.current = setInterval(() => {
        captureScreen();
      }, automaticDuration);
    } else {
      refInterval.current && clearInterval(refInterval.current);
    }
    return () => {
      clearInterval(refInterval.current);
    };
  }, [automaticDuration, automation, captureScreen]);

  const closeDialog = async () => {
    setAutomation(false);
    refInterval.current && clearInterval(refInterval.current);
    if (Object.values(formState.dirtyFields)?.length > 0 || formState.isDirty) {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        content: intl.formatMessage({ id: 'youHaveUnsavedData' }),
        cancelId: 'cancel',
        okId: 'ok',
      });
      if (confirm) {
        onClose();
        reset({ images: [], conclusion: '', examDepartment: '' });
      }
      confirmDialog.close();
    } else {
      onClose();
    }
  };

  useEffect(() => {
    if (JSON.stringify(refFormData.current) !== JSON.stringify(formData)) {
      reset(formData || {});
    }
    refFormData.current = formData;
  }, [formData, reset]);

  return (
    <>
      <Box
        bgcolor="primary.50"
        style={{
          position: 'sticky',
          top: 0,
          padding: '12px 16px',
          zIndex: 2,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Box flex={1}>
          <Typography variant="h6" color="primary">
            <FormattedMessage id={'camera.title'} />
          </Typography>
        </Box>
        <Box justifySelf="flex-end">
          <IconButton onClick={closeDialog} size="small" tabIndex={-1}>
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </Box>
      {formData?.id && (
        <Box display="flex" gap={2} padding={2} paddingBottom={0}>
          <Box display="flex">
            <Typography variant="subtitle1">
              <FormattedMessage id="name" />
            </Typography>
            :&nbsp; <Typography variant="body1">{therapy?.patient?.name}</Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1">
              <FormattedMessage id="birthdayShort" />
            </Typography>
            :&nbsp;{' '}
            <Typography variant="body1">
              {moment(therapy?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1">
              <FormattedMessage id="gender" />
            </Typography>
            :&nbsp;{' '}
            <Typography variant="body1">
              {therapy?.patient?.gender && <FormattedMessage id={therapy?.patient?.gender?.toLowerCase()} />}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography variant="subtitle1">
              <FormattedMessage id="address" />
            </Typography>
            :&nbsp;{' '}
            <Typography variant="body1">
              {therapy.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address}
            </Typography>
          </Box>
        </Box>
      )}
      <FormProvider {...methods}>
        <SchemaElement
          fieldName="id"
          propsElement={{
            type: 'hidden',
          }}
        />
        <Box
          component="form"
          onSubmit={handleSubmit(onSave)}
          bgcolor="white"
          padding={2}
          flex={1}
          display="flex"
          flexDirection="column"
          height={'calc(100% - 120px)'}
        >
          <Box
            padding={2}
            paddingRight={0}
            gap={2}
            display="flex"
            flex={1}
            overflow="hidden"
            border={1}
            borderColor="divider"
            borderRadius={2}
            height={'100%'}
          >
            <Box overflow="auto" display="flex" flexDirection={'column'} gap={2} width={220}>
              <Typography variant="subtitle1">
                <FormattedMessage id="camera.listImage" />
              </Typography>
              {fields?.length > 0 ? (
                fields.map((item: any, index) => {
                  if (imagesField[index]?.deleted) {
                    return null;
                  }
                  return (
                    <Box
                      position="relative"
                      key={item.key}
                      display="flex"
                      padding={1}
                      gap={1}
                      bgcolor="background.default"
                      borderRadius={0.5}
                    >
                      <Box flex={2}>
                        <Box style={{ height: 120, width: '100%', cursor: 'pointer' }}>
                          {item.id ? (
                            <ImagePreview
                              alt=""
                              imageId={item.id}
                              style={{
                                height: '100%',
                                width: '100%',
                                objectFit: 'cover',
                              }}
                            />
                          ) : (
                            <Avatar
                              src={item.imageSrc}
                              alt=""
                              variant="rounded"
                              style={{ height: 120, width: '100%', cursor: 'pointer', objectFit: 'contain' }}
                              onClick={() => setIndexImage(index)}
                            />
                          )}
                        </Box>
                        <Box display="flex" marginTop={1}>
                          <Controller
                            name={`images.${index}.checked`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <Checkbox checked={value} onChange={onChange} style={{ height: 32, width: 32 }} />
                            )}
                          />

                          <Controller
                            name={`images.${index}.description`}
                            control={control}
                            render={({ field: { onChange, value } }) => (
                              <TextField
                                fullWidth
                                placeholder={intl.formatMessage({ id: 'camera.position' })}
                                value={value}
                                onChange={onChange}
                                multiline
                                rows={1}
                                InputProps={{
                                  style: { minHeight: 'unset' },
                                }}
                                inputProps={{
                                  style: { minHeight: 'unset' },
                                }}
                              />
                            )}
                          />
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              ) : (
                <NoDataBox imageStyle={{ width: '100%' }} />
              )}
            </Box>
            {!formData?.id && (
              <Box flex={1} overflow="auto">
                <Box position="relative" overflow="hidden" width="100%">
                  <Webcam width={'100%'} height={'100%'} ref={webcamRef} screenshotFormat="image/jpeg" />
                  <ButtonBase
                    sx={{
                      '&:hover': {
                        opacity: 1,
                      },
                      opacity: 0,
                      transition: 'all 0.3s',
                      position: 'absolute',
                      top: '0',
                      left: '0',
                      right: '0',
                      bottom: '0',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                    onClick={captureScreen}
                  >
                    <AddAPhotoOutlinedIcon style={{ color: 'white', width: 80, height: 'auto' }} />
                  </ButtonBase>
                </Box>
                <Box display="flex" marginTop={2}>
                  <Box flex={1}>
                    <Typography variant="body2">
                      <FormattedMessage id={'name'} />
                      :&nbsp;{' '}
                      <Typography variant="subtitle2" component="span">
                        {therapy?.patient?.name}
                      </Typography>
                    </Typography>
                    <Typography variant="body2">
                      <FormattedMessage id={'gender'} />
                      :&nbsp;{' '}
                      <Typography variant="subtitle2" component="span">
                        {therapy?.patient?.gender && <FormattedMessage id={therapy?.patient?.gender?.toLowerCase()} />}
                      </Typography>
                    </Typography>
                    <Typography variant="body2">
                      <FormattedMessage id={'birthdayShort'} />
                      :&nbsp;{' '}
                      <Typography variant="subtitle2" component="span">
                        {moment(therapy?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                      </Typography>
                    </Typography>
                    <Typography variant="body2">
                      <FormattedMessage id={'address'} />
                      :&nbsp;{' '}
                      <Typography variant="subtitle2" component="span">
                        {
                          therapy.patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)
                            ?.address
                        }
                      </Typography>
                    </Typography>
                  </Box>
                  <Box flex={1}>
                    <Button
                      sx={{ height: 40 }}
                      fullWidth
                      startIcon={<CameraAlt />}
                      onClick={captureScreen}
                      size="large"
                    >
                      <FormattedMessage id="camera.takeAShot" />
                    </Button>
                    <Typography variant="subtitle1" marginTop={3} color="textSecondary">
                      <FormattedMessage id={'camera.setting'} />
                    </Typography>
                    <SelectCustom
                      disableClearBtn={true}
                      options={[
                        { value: 1000, label: 'camera.duration.1' },
                        { value: 2000, label: 'camera.duration.2' },
                        { value: 3000, label: 'camera.duration.3' },
                        { value: 4000, label: 'camera.duration.4' },
                        { value: 5000, label: 'camera.duration.5' },
                      ]}
                      value={automaticDuration}
                      onChange={(event) => {
                        setAutomaticDuration(event);
                      }}
                    />{' '}
                    <Button
                      fullWidth
                      startIcon={<TwinCameraIcon />}
                      color={automation ? 'error' : 'primary'}
                      sx={{ marginTop: 1.5, height: 40 }}
                      onClick={() => {
                        setAutomation((old) => !old);
                      }}
                    >
                      <FormattedMessage id={automation ? 'camera.stop' : 'camera.takeMultipleShot'} />
                    </Button>
                  </Box>
                </Box>
              </Box>
            )}
            <MediCard title="result" sx={{ flex: 1 }}>
              <Box padding={2} display="flex" flexDirection="column" gap={1.5} overflow="auto">
                <FormControlAutoComplete
                  label={intl.formatMessage({ id: 'encounterForm.template' })}
                  placeholder={intl.formatMessage({ id: 'encounterForm.templateEnter' })}
                  options={Object.values(evaluationFormMap)}
                  getOptionLabel={(opt) => opt.name}
                  inputStyle={{ width: '75%' }}
                  disableClearable
                  onChange={async (_, data) => {
                    setValue('examDepartment', data.examDepartment, { shouldDirty: true });
                    setValue('conclusion', data.conclusion, { shouldDirty: true });
                  }}
                />
                <SchemaElement
                  fieldName="id"
                  propsElement={{
                    hidden: true,
                  }}
                />
                <SchemaElement
                  fieldName="examDepartment"
                  propsElement={{
                    type: 'text-editor',
                    label: intl.formatMessage({ id: 'report.parts' }),
                    placeholder: intl.formatMessage({ id: 'enter' }),
                    noHelperText: true,
                    height: 200,
                  }}
                />
                <SchemaElement
                  fieldName="conclusion"
                  propsElement={{
                    type: 'text-editor',
                    label: intl.formatMessage({ id: 'conclusion' }),
                    placeholder: intl.formatMessage({ id: 'enter' }),
                    noHelperText: true,
                    height: 200,
                  }}
                />
              </Box>
            </MediCard>
          </Box>

          <Box display="flex" justifyContent="flex-end" alignItems={'center'} marginTop={2} gap={2}>
            {formData?.id && (
              <Button startIcon={<Print />} onClick={onPrintFormClickHandler}>
                <FormattedMessage id="report.imagePrint" />
              </Button>
            )}
            <>
              <FormControlLabel
                control={<Checkbox color="primary" style={{ padding: 2 }} />}
                label={<FormattedMessage id="camera.selectAll" />}
                checked={imagesField.every((v) => v.checked === true)}
                onChange={(event: any) => {
                  const checked = event.target.checked;
                  setValue(
                    'images',
                    imagesField.map((v) => ({ ...v, checked: checked })),
                    { shouldDirty: true },
                  );
                }}
              />
              <Button
                startIcon={<Delete />}
                disabled={imagesField.filter((v) => v.checked && !v.deleted)?.length === 0}
                onClick={() => {
                  setValue(
                    'images',
                    imagesField
                      .map((v) => {
                        if (v.checked && v.id) {
                          return { ...v, deleted: true };
                        }
                        return v;
                      })
                      .filter((v) => !v.checked || v.id),
                    { shouldDirty: true },
                  );
                }}
                size="large"
              >
                <FormattedMessage id="camera.deleteSelected" />
              </Button>
            </>
            <Button
              variant="outlined"
              onClick={() => {
                closeDialog();
              }}
              size="large"
            >
              <FormattedMessage id="cancel" />
            </Button>
            <Button type="submit" size="large">
              <FormattedMessage id="save" />
            </Button>
          </Box>
        </Box>
      </FormProvider>
      <ImageGalleryDialog
        open={indexImage !== undefined}
        onClose={() => setIndexImage(undefined)}
        images={imagesField.map((item: some) => ({
          render: item?.id ? (
            <ImagePreview
              alt=""
              imageId={item.id}
              style={{
                height: '100%',
                width: '100%',
                objectFit: 'cover',
              }}
            />
          ) : (
            <Avatar
              src={item.imageSrc}
              alt=""
              variant="rounded"
              style={{ height: '100%', width: '100%' }}
              imgProps={{ style: { objectFit: 'contain' } }}
            />
          ),
        }))}
        index={indexImage}
      />
    </>
  );
};

const CameraImageGalleryDialog = (props: Props) => {
  const { open } = props;

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          position: 'relative',
          maxWidth: 'unset',
          width: 1380,
          height: 850,
          display: 'flex',
          flexDirection: 'column',
        },
      }}
    >
      <ContentBox {...props} />
    </Dialog>
  );
};

export default CameraImageGalleryDialog;
