export const TYPE_OF_RESULTS = [
  { value: 'Quantity', label: 'lab.quantity' },
  { value: 'CodeableConcept', label: 'lab.list' },
  { value: 'string', label: 'lab.input' },
];

export const CORE_TYPE_OF_RESULTS = [
  { value: 'STATISTICS', label: 'lab.quantity' },
  { value: 'LIST', label: 'lab.list' },
  { value: 'DATA_INPUT', label: 'lab.input' },
];
