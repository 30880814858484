import { Button, Typography } from '@mui/material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import { useDiagnosisDialogSelector } from './state';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { MedicalIcon } from 'svg';

const AttachmentsDocument = () => {
  const onOpenDiagnosisImageDialog = useDiagnosisDialogSelector.use.onOpenDiagnosisImageDialog();
  const addAttachments = useDiagnosisDialogSelector.use.addAttachments();
  const { intl, openSnackbar } = useGeneralHook();
  return (
    <Stack direction="row" alignItems="center" justifyContent="flex-start" gap={1} marginTop={2}>
      <Button
        color="inherit"
        startIcon={<MedicalIcon />}
        sx={{ width: 'fit-content', padding: '8px 12px' }}
        onClick={() => onOpenDiagnosisImageDialog()}
      >
        <FormattedMessage id="camera.connection" />
      </Button>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="flex-start"
        gap={1}
        sx={{ backgroundColor: '#CDDFFF', borderRadius: '4px', padding: '8px 12px', width: '150px', cursor: 'pointer' }}
        component="label"
      >
        <input
          hidden
          type="file"
          multiple
          onChange={(event) => {
            const files = event.target.files;
            if (files && files.length > 0) {
              let errorKey = '';
              for (var i = 0; i < files.length; i++) {
                // 5MB
                if (files[i].size >= 5 * 1024 * 1024) {
                  errorKey = 'dental.dentalDetail.statusAndDiagnosis.attachment.sizeMoreThan5MB.error';
                  break;
                }
                if (files[i].name?.substr(files[i].name.lastIndexOf('.') + 1) === 'exe') {
                  errorKey = 'dental.dentalDetail.statusAndDiagnosis.attachment.exe.error';
                  break;
                }
              }
              if (errorKey) {
                openSnackbar({ message: intl.formatMessage({ id: errorKey }), type: 'error' });
              } else {
                addAttachments(files);
              }
            }
          }}
        />
        <AddPhotoAlternateIcon color="primary" />
        <Typography
          sx={{
            color: '#0052E0',
            fontFamily: 'Roboto',
            fontSize: '14px',
            fontStyle: 'normal',
            fontWeight: 500,
            lineHeight: '24px',
            letterSpacing: '0.1px',
          }}
        >
          <FormattedMessage id="dental.dentalDetail.statusAndDiagnosis.button.addAttachments.label" />
        </Typography>
      </Stack>
    </Stack>
  );
};

export default AttachmentsDocument;
