import { Box, Button, Collapse, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import MediCard from '../../../common/component/MediCard';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { some } from '../../../common/constants';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
interface Props {
  data?: some;
  setFormData: (item: some) => void;
  setFormDataGroup: (item: some) => void;
  onDelete: (item: some) => void;
  onDeleteGroup: (item: some) => void;
  onDeleteMultiple: (item: some, callback?: () => void) => void;
  loading?: boolean;
  isPreview?: boolean;
  isInsurance?: boolean;
}

const DrugTable: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    setFormData,
    setFormDataGroup,
    onDelete,
    onDeleteMultiple,
    onDeleteGroup,
    isInsurance,
    isPreview,
    loading,
  } = props;
  const [open, setOpen] = React.useState(false);
  const [selection, setSelection] = React.useState<some[]>([]);
  const columns = React.useMemo(() => {
    return [
      {
        title: 'pharmacy.code',
        dataIndex: 'code',
      },
      {
        title: 'pharmacy.drugName',
        dataIndex: 'drugName',
      },
      {
        title: 'pharmacy.registerNumber',
        dataIndex: 'registerNumber',
      },
      {
        title: 'pharmacy.unitShort',
        dataIndex: 'unit',
      },
      {
        title: 'pharmacy.intendedRoute',
        dataIndex: 'intendedRoute.label',
        render: (record) => (
          <Box component="ul" margin={0} paddingLeft={2}>
            {record.intendedRoute &&
              record.intendedRoute
                //.filter((one) => one.value)
                .map((route) => (
                  <Typography component={'li'}>{` ${route?.label || ''} - ${route?.value || ''}`}</Typography>
                ))}
          </Box>
        ),
      },
      {
        title: 'pharmacy.groupCode',
        dataIndex: 'groupCode',
      },
      {
        title: 'pharmacy.departmentCode',
        dataIndex: 'departmentCode',
      },
      {
        title: 'pharmacy.ingredients',
        dataIndex: 'ingredients',
      },
      {
        title: 'pharmacy.declarePrice',
        dataIndex: 'price',
        align: 'right',
        render: (record) => record.declarePrice && <FormattedNumber value={record.declarePrice} />,
      },
      {
        title: 'pharmacy.registerCompany',
        dataIndex: 'registerCompany',
      },
      {
        title: 'action',
        align: 'right',
        width: 100,
        render: (record) => {
          return (
            <>
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  setFormData({ ...record, listId: data?.id, listData: data?.items });
                }}
                style={{ margin: '0px 8px' }}
              >
                <EditIcon color={record.isValid === false ? 'error' : undefined} />
              </IconButtonTitle>
              <IconButtonTitle
                title="delete"
                size="small"
                onClick={() => {
                  onDelete({ ...record, listId: data?.id, listData: data?.items, allItems: data?.allItems });
                  setSelection([]);
                }}
              >
                <DeleteIcon />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns[];
  }, [setFormData, data?.id, data?.items, data?.allItems, onDelete]);

  if (!data) {
    return null;
  }

  return (
    <MediCard
      style={{ margin: '8px 0px', color: 'white' }}
      title={
        <Box
          display="flex"
          alignItems="center"
          onClick={() => {
            setOpen((old) => !old);
          }}
          style={{ cursor: 'pointer' }}
        >
          <Box flex={1} display="flex" alignItems="center">
            <ExpandLessIcon
              style={{
                transition: '0.2s all',
                transform: open ? 'rotate(0deg)' : 'rotate(180deg)',
                marginRight: 16,
              }}
            />
            <Typography variant="subtitle1" color="inherit">
              {data.name}&nbsp;({data?.items?.length || 0})
            </Typography>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <IconButtonTitle
              title="create"
              size="small"
              color="inherit"
              onClick={(e) => {
                setFormData({
                  listId: data.id,
                  listData: data.items,
                  allItems: data.allItems,
                  paymentPercent: !isInsurance ? 0 : '',
                });
              }}
            >
              <NoteAddIcon />
            </IconButtonTitle>
            <IconButtonTitle
              title="update"
              size="small"
              color="inherit"
              onClick={(e) => {
                setFormDataGroup(data);
              }}
              style={{ margin: '0px 8px' }}
            >
              <EditIcon />
            </IconButtonTitle>
            <IconButtonTitle
              title="delete"
              size="small"
              color="inherit"
              disabled={data?.items?.length > 0 ? !selection.length : !!data.items?.length && !isPreview}
              onClick={(e) => {
                e.stopPropagation();
                if (data?.items?.length > 0) {
                  onDeleteMultiple(
                    {
                      listId: data?.id,
                      listData: data?.items,
                      allItems: data?.allItems,
                      groupName: data.name,
                      list: selection,
                    },
                    () => {
                      setSelection([]);
                    },
                  );
                } else {
                  onDeleteGroup(data);
                }
              }}
            >
              <DeleteIcon />
            </IconButtonTitle>
          </Box>
        </Box>
      }
    >
      <Collapse in={open} unmountOnExit>
        <Box padding={1}>
          <TableCustom
            dataSource={data?.items}
            loading={loading}
            columns={columns}
            rowSelection={{
              selectedRowKeys: selection,
              onChange: ({ selectedRows }) => setSelection(selectedRows),
            }}
            caption={
              <Button
                size="large"
                fullWidth
                variant="text"
                startIcon={<NoteAddIcon />}
                color="primary"
                onClick={() =>
                  setFormData({
                    listId: data.id,
                    listData: data.items,
                    allItems: data.allItems,
                    paymentPercent: !isInsurance ? 0 : '',
                  })
                }
              >
                <Typography variant="subtitle2">
                  <FormattedMessage id="pharmacy.addNew" />
                </Typography>
              </Button>
            }
          />
        </Box>
      </Collapse>
    </MediCard>
  );
};

export default DrugTable;
