import { some } from '../../../common/constants';
import { NUMBER_PATIENT_COLUMN_ID } from './constants';
import { AppointmentModel } from '@devexpress/dx-react-scheduler';
import moment from 'moment';
import { waitForElementToExist } from '../../../common/utils';
import { Appointment } from 'modules/schema';

export interface IAppointmentForm {
  patientResourceId?: string;
  orgResourceId?: string;
  patientName: string;
  telephone: string;
  address: string;
  date: string;
  startTime?: string;
  endTime?: string;
  doctorId?: string;
  reason: string;
  totalPatient?: number;
  id?: string;
}

export function handleStartAndEndOfScheduler(appointments: some[]) {
  if (!appointments || appointments.length === 0) return { startHour: 0, endHour: 24 };
  const start = appointments.reduce((min, { startDate }) => {
    if (startDate.getTime() < min) return startDate.getTime();
    return min;
  }, Infinity);
  const end = appointments.reduce((max, { endDate }) => {
    if (endDate.getTime() > max) return endDate.getTime();
    return max;
  }, 0);
  return { startHour: new Date(start).getHours(), endHour: new Date(end).getHours() + 1 };
}

// xử lý côt thứ 2 (Số BN) trong day calendar - đếm số ca khám trong từng khung giờ
export function handleNumberOfPatientInTimeRange(
  appointments: Appointment[] = [],
  { startHour, endHour }: ReturnType<typeof handleStartAndEndOfScheduler>,
  date: Date,
  duration: number,
): AppointmentModel[] {
  const startDate = new Date(date.setHours(startHour, 0, 0, 0));
  const endDate = new Date(date.setHours(endHour - 1, 59, 59, 59));

  const timeRange: AppointmentModel[] = [];
  while (startDate < endDate) {
    const start = new Date(startDate);
    let end = new Date(startDate.setMinutes(startDate.getMinutes() + duration));
    if (start.getDay() !== end.getDay()) {
      end = new Date(date.setHours(endHour - 1, 58));
    }

    const numberOfPatient = appointments
      .filter(
        (ele) =>
          moment(start).isBetween(ele.startTime, ele.endTime) ||
          moment(end).isBetween(ele.startTime, ele.endTime) ||
          moment(ele.startTime).isBetween(start, end) ||
          moment(ele.endTime).isBetween(start, end),
      )
      .reduce((prev, current) => {
        return prev + (current?.quantity || 1);
      }, 0);

    timeRange.push({
      members: [NUMBER_PATIENT_COLUMN_ID],
      startDate: start,
      endDate: end,
      id: startDate.getTime(),
      title: numberOfPatient + '',
    });
  }
  return timeRange;
}

export async function scrollToAppointment(appointment: AppointmentModel) {
  if (!appointment || !appointment.id) return null;
  const element = await waitForElementToExist(`[id='${String(appointment.id)}']`);
  element.scrollIntoView({ behavior: 'auto', block: 'center', inline: 'center' });
  return appointment;
}
