import { Box, Button, Stack } from '@mui/material';
import Report from 'modules/common/component/report';
import Filter from 'modules/common/component/filter';
import { DEFAULT_VALUES, FILTER_CONFIGS } from 'modules/common/page/report/work/medicalExam/consts';
import { FormSchema, SearchParams } from 'modules/common/page/report/work/medicalExam/type';
import { useEffect, useMemo, useRef, useState } from 'react';
import DateInput from 'modules/common/component/form/DateInput';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';
import { groupBy } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import SelectInput from 'modules/common/component/form/SelectInput';
import { SPECIALIZED_REPORT_TYPE } from 'modules/common/apiConstants';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { formatEndDate, formatStartDate, isValidDate, maxDate } from 'modules/common/utils';
import { ValueType } from 'modules/common/type';
import ReactToPrint from 'react-to-print';
import { Print } from '@mui/icons-material';
import { WORK_API } from 'modules/common/service';

export const FILTER_KEY = 'ENCOUNTER_SERVICE_CHART_FILTER';
export const SELECT_COLUMNS_KEY = 'ENCOUNTER_SERVICE_CHART_SELECT_COLUMNS';

interface Props {
  typeOption: ValueType<typeof SPECIALIZED_REPORT_TYPE>[];
}

const EncounterServiceChart: React.FunctionComponent<Props> = ({ typeOption }) => {
  const refPrintComponent = useRef(null);
  const intl = useIntl();
  const [searchParams, setSearchParams] = useState<SearchParams>(DEFAULT_VALUES);

  const form = useForm<FormSchema>({
    defaultValues: {
      fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
      toDate: formatEndDate(moment().add(+1, 'day')),
      keyword: '',
      type: typeOption[0],
    },
    mode: 'onChange',
  });

  const type = form.watch('type');
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate = formatEndDate(moment(toDateOrigin).add(-1, 'day'));
  const formData = form.getValues();

  const { data: reportData } = useFetch(
    WORK_API.getMedicalExamList({
      ...searchParams,
      page: 0,
      pageSize: 100000,
      type: type?.value ? type?.value : typeOption[0].value,
      fromDate,
      toDate,
    }),
    {
      globalLoading: true,
      revalidateOnFocus: false,
      enabled: isValidDate(fromDate, toDate, 'CHART'),
    },
  );

  const reportMap = useMemo(() => groupBy(reportData || [], 'serviceId'), [reportData]);

  useEffect(() => {
    if (moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'month')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'month')
            .add(+1, 'day'),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateOrigin, toDateOrigin]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title title="report.workReport.chart.title.encounterServiceChart" />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue) => {
          setSearchParams((prev) => ({
            ...prev,
            icd10Codes: filterValue.ICD10,
            paymentPlans: filterValue.PAYMENT_PLAN,
            picUserIds: filterValue.EMPLOYEE,
            serviceIds: filterValue.SERVICE,
            statuses: filterValue.ENCOUNTER_STATUS,
            types: filterValue.APPOINTMENT_CHART_TYPE,
            patientTypes: filterValue.PATIENT_TYPE,
          }));
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                maxDate={maxDate('DAY', fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() =>
                    form.reset({
                      ...DEFAULT_VALUES,
                      toDate: formatEndDate(moment().add(+1, 'day')),
                      type: typeOption[0],
                    })
                  }
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />}>
                  <FormattedMessage id={'printReport'} />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Stack>
        </Stack>
        <Report.ColumnChart
          refPrintComponent={refPrintComponent}
          xAxisTitle="serviceName"
          yAxisTitle="report.chart.title.numberOfCustomerRegistration"
          categories={Object.values(reportMap).map((encounters) => encounters[0].serviceName || '')}
          series={[
            {
              type: 'column',
              name: intl.formatMessage({ id: 'serviceName' }),
              data: Object.values(reportMap).map((encounters) => encounters.length),
              color: '#307BFF',
              showInLegend: false,
            },
          ]}
        />
      </Filter>
    </Stack>
  );
};

export default EncounterServiceChart;
