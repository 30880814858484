import { DatePickerProps } from '@mui/lab';
import { TextField } from '@mui/material';
import moment, { MomentInput } from 'moment';
import React, { memo } from 'react';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from '../../../constants';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, enUS, PickersLocaleText, DatePicker } from '@mui/x-date-pickers';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

export interface PropsDatePickerElement extends Omit<DatePickerProps, 'defaultValue' | 'onChange' | 'value'> {
  onChange?: (value?: string) => void;
  value?: string;
  required?: boolean;
  error?: boolean;
  isSubmitting?: boolean;
  name?: string;
  formatDate?: string;
}

interface Props extends PropsDatePickerElement {}

const DatePickerDesktopElement = React.forwardRef<HTMLInputElement, Props>((props: Props, ref) => {
  const { name, error, isSubmitting, required, value = null, onChange, formatDate, ...rest } = props;
  const { intl } = useGeneralHook();
  const customEnUSLocaleText: Partial<PickersLocaleText<any>> = {
    ...enUS.components.MuiLocalizationProvider.defaultProps.localeText,
    okButtonLabel: intl.formatMessage({ id: 'patient.management.dialog.button.save' }),
    cancelButtonLabel: intl.formatMessage({ id: 'patient.management.dialog.button.close' }),
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} localeText={customEnUSLocaleText}>
      <DatePicker
        mask="__/__/____"
        inputFormat="dd/MM/yyyy"
        value={
          moment(value, formatDate || BE_DATE_FORMAT, true).isValid()
            ? moment(value, formatDate || BE_DATE_FORMAT, true).toDate()
            : null
        }
        views={['year', 'month', 'day']}
        onChange={(date: any, value) => {
          let tmp;
          if (date) {
            const md = moment(date);
            tmp = md.isValid() ? md.format(formatDate || BE_DATE_FORMAT) : '';
          } else if (value) {
            const md = moment(value as MomentInput, FE_DATE_FORMAT, true);
            tmp = md.isValid() ? md.format(formatDate || BE_DATE_FORMAT) : '';
          } else if (value === null) {
            tmp = null;
          }
          onChange && onChange(tmp || null);
        }}
        allowSameDateSelection
        inputRef={ref}
        renderInput={(params) => (
          <TextField
            {...params}
            InputLabelProps={{
              required: required,
            }}
            variant="outlined"
            fullWidth
            error={!!error}
            placeholder={intl.formatMessage({ id: 'dateFormat' })}
          />
        )}
        {...(rest as any)}
      />
    </LocalizationProvider>
  );
});
export default memo(DatePickerDesktopElement);
