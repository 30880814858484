import { Paper } from '@mui/material';
import * as React from 'react';
import TherapyBox from '../component/therapy/TherapyBox';
interface Props {}

const TherapyAdminPage: React.FunctionComponent<Props> = (props) => {
  return (
    <Paper style={{ flex: 1, overflow: 'auto' }}>
      <TherapyBox />
    </Paper>
  );
};

export default TherapyAdminPage;
