import { Box, Divider, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import { some } from '../../../../../common/constants';
import { capitalizeFirstLetter, numericFormat } from '../../../../../common/utils';
import writtenNumber from 'written-number';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../../../../../../redux/reducer';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';

const LineWrapperStyled = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItem: 'center',
});

const TextStyled = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.1px',
});

const LabelStyled = styled(TextStyled)({
  color: '#455A64',
});

const ValueStyled = styled(TextStyled)({
  color: '#263238',
});

const DividerStyled = styled(Divider)({
  marginTop: '10px',
  marginBottom: '10px',
});

interface Props {
  paymentDetail: some;
}
const Summary = ({ paymentDetail }: Props) => {
  const { appState } = useGeneralHook();
  const { locale } = useSelector((state: AppState) => state.intl, shallowEqual);
  const summaryVAT = paymentDetail?.paymentDetails?.reduce((sum, cur) => sum + cur?.vatAmount, 0);
  const summaryDiscount = paymentDetail?.discountAmount + paymentDetail?.itemDiscountAmount;
  return (
    <Box maxWidth="50%" marginLeft="auto">
      <LineWrapperStyled paddingTop="16px">
        <LabelStyled>
          <FormattedMessage id="therapy.payment.summaryPrintForm.title.summaryPay" />
        </LabelStyled>
        <ValueStyled>{numericFormat(paymentDetail?.totalAmount)}</ValueStyled>
      </LineWrapperStyled>
      <LineWrapperStyled>
        <LabelStyled>
          <FormattedMessage id="therapy.payment.summaryPrintForm.title.summaryDiscount" />
        </LabelStyled>
        <ValueStyled>{numericFormat(summaryDiscount)}</ValueStyled>
      </LineWrapperStyled>
      <LineWrapperStyled>
        <LabelStyled>
          <FormattedMessage id="therapy.payment.summaryPrintForm.title.summaryPriceBeforeVAT" />
        </LabelStyled>
        <ValueStyled>{numericFormat(paymentDetail?.totalAmount - summaryDiscount)}</ValueStyled>
      </LineWrapperStyled>
      <LineWrapperStyled>
        <LabelStyled>
          <FormattedMessage id="therapy.payment.summaryPrintForm.title.summaryVAT" />
        </LabelStyled>
        <ValueStyled>{numericFormat(summaryVAT)}</ValueStyled>
      </LineWrapperStyled>
      <DividerStyled />
      <LineWrapperStyled>
        <LabelStyled sx={{ color: '#0052E0' }}>
          <FormattedMessage id="therapy.payment.summaryPrintForm.title.customerPay" />
        </LabelStyled>
        <ValueStyled>{numericFormat(paymentDetail?.finalTotalAmount)}</ValueStyled>
      </LineWrapperStyled>
      <LineWrapperStyled gap="10px">
        <LabelStyled sx={{ whiteSpace: 'pre' }}>
          <FormattedMessage id="therapy.payment.summaryPrintForm.title.summaryPriceToWord" />
        </LabelStyled>
        <ValueStyled>
          {capitalizeFirstLetter(writtenNumber(paymentDetail?.finalTotalAmount, { lang: locale })) || ''}&nbsp;
          {appState.common.groupCurrency}
        </ValueStyled>
      </LineWrapperStyled>
    </Box>
  );
};

export default Summary;
