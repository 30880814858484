import { Box, Typography } from '@mui/material';
import NoDataBox from 'modules/common/component/NoDataBox';
import { some } from 'modules/common/constants';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import SchemaElement from '../../../../common/SchemaForm/SchemaElement';
import useGeneralHook from '../../../../common/hook/useGeneralHook';

interface Props {
  dataTherapySession: some;
  editable: boolean;
}

const TherapySessionResult: React.FunctionComponent<Props> = (props) => {
  const { dataTherapySession, editable } = props;
  const { intl } = useGeneralHook();
  return (
    <Box className="mb20">
      <Typography className="title">
        <FormattedMessage id="therapyList.header.label.therapySession.conclude" />
      </Typography>
      {editable ? (
        <SchemaElement
          fieldName="generalInfo.conclude"
          propsElement={{
            type: 'text-field',
            multiline: true,
            rows: 2,
            register: {
              maxLength: {
                value: 5000,
                message: intl.formatMessage({ id: 'common.text.length.invalid' }),
              },
            },
            placeholder: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.placeholder.conclude' }),
            style: { marginTop: '7px' },
          }}
        />
      ) : (
        <Box className="therapy-sesion-result">{dataTherapySession?.conclude || <NoDataBox />}</Box>
      )}
    </Box>
  );
};

export default TherapySessionResult;
