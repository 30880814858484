import SchemaFormMain from '../SchemaForm';
import { CARE_PLAN_SCOPE, CARE_PLAN_TYPE, PATIENT_ADDRESS_TYPE, ROLE_TYPE } from '../apiConstants';
import useGeneralHook from '../hook/useGeneralHook';
import PrintIcon from '@mui/icons-material/Print';
import { useUpdateMutate } from '../hook/useMutate';
import { useCallback, useMemo } from 'react';
import { API_SERVER } from '../api';
import { Patient } from 'modules/schema';
import { useFetch } from '../hook/useFetch';
import { useDialog } from '../hook/useDialog';
import FormAppointmentDialog from 'modules/reception/component/calendar/FormAppointmentDialog';
import { useUserRoles } from '../hook/useUserRoles';
import { ROLE_INFO } from 'modules/layout/utils';

interface Props {
  referenceId: number;
  scope: keyof typeof CARE_PLAN_SCOPE;
  editable: boolean;
  patient?: Patient;
  onPrint?: () => void;
}

const ExamCarePlan = ({ referenceId, scope, editable, patient, onPrint }: Props) => {
  const { intl } = useGeneralHook();
  const [openCreateDialog, onOpenCreateDialog, onCloseCreateDialog] = useDialog();
  const doctorsAndNurse = useUserRoles('DOCTOR', 'GENERAL_DOCTOR', 'NURSE');
  const onUpdateCarePlanMutate = useUpdateMutate();
  const { data: carePlan } = useFetch(API_SERVER.carePlan.get({ referenceId, scope }), {
    errorRetryCount: 1,
  });

  const onSubmit = useCallback(
    (data) => {
      if (data?.note !== carePlan?.note) {
        onUpdateCarePlanMutate({
          url: API_SERVER.carePlan.create(),
          method: 'PUT',
          data: {
            referenceId: referenceId,
            type: data?.type,
            scope,
            hospitalCode: data?.hospitalCode,
            hospitalName: data?.hospitalName,
            note: data?.note,
          },
        });
      }
    },
    [carePlan?.note, onUpdateCarePlanMutate, referenceId, scope],
  );

  const examDoctorsNursesOption = useMemo(
    () =>
      doctorsAndNurse
        ?.sort((a, b) => {
          if (a?.roleName === b?.roleName) {
            return a?.userName?.localeCompare(b?.userName!) || 0;
          }
          if (a?.roleName === ROLE_INFO.nurse.roleKey) {
            return 1;
          }
          return -1;
        })
        .map((user) => {
          return {
            ...user,
            value: user?.id,
            label: `${user?.employeeFullName} - ${intl.formatMessage({
              id: ROLE_TYPE[user.roleName!]?.label || ' ',
            })}`,
          };
        }) || [],
    [doctorsAndNurse, intl],
  );
  return (
    <>
      <SchemaFormMain
        hideSubmitButton
        formData={carePlan}
        schema={{
          fields: ({ valuesField }) => {
            const diableBookButton =
              !editable ||
              valuesField?.type === CARE_PLAN_TYPE.PRESCRIPTION.value ||
              valuesField?.type === CARE_PLAN_TYPE.HOSPITAL_TRANSFER.value ||
              valuesField?.type === CARE_PLAN_TYPE.OTHER.value;
            return {
              type: {
                type: 'select',
                options: Object.values(CARE_PLAN_TYPE),
                label: intl.formatMessage({ id: 'encounterInfo.consulationMethod' }),
                placeholder: intl.formatMessage({ id: 'encounterInfo.consulationMethodEnter' }),
                noHelperText: true,
                readOnly: !editable,
                defaultValue: CARE_PLAN_TYPE.OTHER.value,
                disableClearBtn: true,
                propsWrapper: { xs: true },
                onChange: (type) => onSubmit({ ...valuesField, type }),
              },
              book: {
                type: 'button',
                label: intl.formatMessage({ id: 'encounterInfo.book' }),
                disabled: diableBookButton,
                color: 'primary',
                variant: 'contained',
                onClick: onOpenCreateDialog,
                style: { minWidth: 120, marginTop: 20 },
                propsWrapper: { xs: undefined },
              },
              note: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'note' }),
                placeholder: intl.formatMessage({ id: 'noteEnter' }),
                multiline: true,
                rows: 5,
                noHelperText: true,
                readOnly: !editable,
                onBlur: () => editable && onSubmit(valuesField),
                register: {
                  maxLength: {
                    value: 1000,
                    message: intl.formatMessage({ id: 'common.text.length.invalid' }),
                  },
                },
              },
              hospitalCode: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'encounterInfo.hospitalCode' }),
                placeholder: intl.formatMessage({ id: 'encounterInfo.hospitalCodeEnter' }),
                noHelperText: true,
                onBlur: () => onSubmit(valuesField),
                readOnly: !editable,
              },
              hospitalName: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'encounterInfo.hospitalName' }),
                placeholder: intl.formatMessage({ id: 'encounterInfo.hospitalNameEnter' }),
                noHelperText: true,
                onBlur: () => onSubmit(valuesField),
                readOnly: !editable,
              },
              print: {
                type: 'button',
                hidden: !onPrint,
                label: intl.formatMessage({ id: 'printFile' }),
                style: { minWidth: 192 },
                color: 'inherit',
                endIcon: <PrintIcon />,
                propsWrapper: { xs: undefined, style: { display: 'flex', justifyContent: 'flex-end', flex: 1 } },
                onClick: onPrint,
              },
            };
          },
          ui: ({ valuesField }) => [
            { id: 'select', fields: ['type', 'book'] },
            {
              id: 'content',
              fields:
                valuesField?.type === CARE_PLAN_TYPE.HOSPITAL_TRANSFER.value
                  ? ['hospitalCode', 'hospitalName', 'note', 'print']
                  : ['note'],
            },
          ],
        }}
      />
      <FormAppointmentDialog
        open={openCreateDialog}
        formData={{
          name: patient?.name,
          mobilePhone: patient?.mobilePhone,
          address: patient?.patientAddressList?.find((address) => address.type === PATIENT_ADDRESS_TYPE.HOME)?.address,
          referenceId: patient?.id,
          gender: patient?.gender,
          dob: patient?.dob,
        }}
        close={onCloseCreateDialog}
        revalidateAll={() => {}}
        examDoctorsNurses={examDoctorsNursesOption}
        disableSearch={true}
        type=""
      />
    </>
  );
};

export default ExamCarePlan;
