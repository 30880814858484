import {
  Box,
  Checkbox,
  Table as MUITable,
  TableBody,
  TableCell,
  TableContainer,
  TableContainerProps,
  TableHead,
  TablePagination,
  TableProps,
  TableRow,
  TableRowProps,
  Typography,
} from '@mui/material';
import { Column, RowKey, StickyHeaderConfig, TablePaginationConfig } from './type';
import { calculateLeft } from './utlils';
import { FormattedMessage, useIntl } from 'react-intl';
import * as React from 'react';
import { useCallback } from 'react';
import { TableBodyRow } from './TableBodyRow';
import { TableSummaryRow } from './TableSummaryRow';
import DOMPurify from 'dompurify';

type Props<T> = {
  data?: T[];
  columns: Column<T>[];
  dataKey: RowKey<T>;
  tableContainerProps?: Omit<TableContainerProps, 'maxHeigth'>;
  tableProps?: Omit<TableProps, 'stickyHeader'>;
  subTableStyle?: boolean;
  options?: TableOptions;
  rowProps?: TableRowProps | ((record: T) => TableRowProps);
  warningTitle?: string;
} & StickyHeaderConfig &
  TablePaginationConfig;

export type TableOptions = {
  position: 'top' | 'bottom';
  startIndex?: number;
  record: any;
  messageKey?: string;
  useRender?: string[];
};

function Table<T>({
  data = [],
  columns,
  dataKey,
  stickyHeader = false,
  maxHeigth,
  tableContainerProps,
  tableProps,
  subTableStyle,
  rowProps,
  paginationProps,
  totalElements,
  options,
  warningTitle,
}: Props<T>) {
  const intl = useIntl();
  const renderHeaderCell = useCallback(
    (column: Column<T>, index: number) => {
      switch (column.type) {
        case 'CHECKBOX':
          const { onAllChange, selectedItems } = column;
          return onAllChange ? (
            <Checkbox
              checked={selectedItems.length === data.length}
              onChange={(event) => onAllChange(data, index, event.target.checked)}
            />
          ) : null;
        case 'SUB_ROW':
          return '';
        case 'INDEX':
          return <FormattedMessage id="stt" />;
        default:
          const { rawTitle, title, renderTitle } = column;
          return rawTitle ? (
            title
          ) : (
            <>
              <FormattedMessage id={title} />
              {renderTitle}
            </>
          );
      }
    },
    [data],
  );

  const isShowSummary = data.length !== 0 && options;

  return (
    <TableContainer
      {...tableContainerProps}
      sx={{ maxHeight: maxHeigth, position: 'relative', overflow: 'unset', ...tableContainerProps?.sx }}
    >
      <MUITable {...tableProps} stickyHeader={stickyHeader}>
        <TableHead>
          <TableRow>
            {columns.map((column, index) => {
              const { align, colSpan, sticky, width, minWidth, headerCellProps, required } = column;
              return (
                <TableCell
                  {...headerCellProps}
                  key={`headerCell${index}`}
                  align={align}
                  colSpan={colSpan}
                  sx={{
                    lineHeight: subTableStyle ? '10px' : '16px',
                    fontWeight: subTableStyle ? '700' : '500',
                    color: subTableStyle ? '#78909C' : '#0052E0',
                    fontSize: '14px',
                    fontFamily: 'Roboto',
                    letter: '0.4px',
                    padding: '10px',
                    textTransform: 'capitalize',
                    border: 'none',
                    backgroundColor: '#F4F8FF',
                    width: width,
                    minWidth: minWidth,
                    position: sticky || stickyHeader ? 'sticky' : 'unset',
                    left: sticky ? calculateLeft(columns, index) : 'unset',
                    cursor: 'default',
                    ...headerCellProps?.sx,
                  }}
                >
                  {renderHeaderCell(column, index)}
                  {required && (
                    <Typography variant="caption" color="error" sx={{ fontSize: '14px' }}>
                      *
                    </Typography>
                  )}
                </TableCell>
              );
            })}
          </TableRow>
          {isShowSummary && options?.position === 'top' && (
            <TableSummaryRow
              columns={columns}
              startIndex={options.startIndex}
              record={options.record}
              messageKey={options.messageKey}
              useRender={options.useRender}
            />
          )}
        </TableHead>
        <TableBody>
          {data.length !== 0 ? (
            data.map((record, rowIndex) => (
              <TableBodyRow
                key={`row${rowIndex}`}
                columns={columns}
                index={rowIndex}
                record={record}
                dataKey={dataKey}
                rowProps={rowProps}
                subTableStyle={subTableStyle}
              />
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                sx={{
                  fontFamily: 'Roboto',
                  fontWeight: '400',
                  fontSize: '16px',
                  letter: '0.4px',
                  padding: '10px',
                  border: 'none',
                  backgroundColor: '#F4F8FF',
                  textAlign: 'center',
                  borderTop: subTableStyle ? '3px solid white' : '5px solid white',
                }}
              >
                <FormattedMessage id="noData" />
              </TableCell>
            </TableRow>
          )}
          {isShowSummary && options?.position === 'bottom' && (
            <TableSummaryRow
              columns={columns}
              startIndex={options.startIndex}
              record={options.record}
              messageKey={options.messageKey}
              useRender={options.useRender}
            />
          )}
        </TableBody>
      </MUITable>
      {warningTitle && (
        <Box
          sx={{
            background: '#FFFDE7',
            borderRadius: '6px',
            margin: '20px',
            padding: '7px',
            border: '1px gray solid',
            fontSize: '14px',
            fontStyle: 'italic',
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(warningTitle) }} />
        </Box>
      )}
      {paginationProps ? (
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 100]}
          component="div"
          labelRowsPerPage={intl.formatMessage({ id: 'component.table.title.show' })}
          labelDisplayedRows={({ from, to, count }) => `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}
          SelectProps={{
            sx: {
              '& .MuiTablePagination-select, & .MuiTablePagination-select:focus': {
                borderRadius: '5px',
              },
            },
          }}
          {...paginationProps}
          count={totalElements || 0}
        />
      ) : null}
    </TableContainer>
  );
}

export default Table;
