import styled from '@emotion/styled';
import { Box, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { Dictionary } from 'lodash';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { NumericFormatText } from 'modules/common/utils';
import { Medication, Prescription } from 'modules/schema';
import { FormattedMessage } from 'react-intl';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

interface Props {
  prescription: Prescription;
  medicationMap: Dictionary<Medication & { categoryName?: string }>;
}

const PrescriptionOldInfo = ({ prescription, medicationMap }: Props) => {
  const { appState } = useGeneralHook();
  const allIntendedRoutes = appState.common.intendedRoutes;

  return (
    <CustomTooltip
      placement="right"
      title={
        <Box>
          <Typography variant="h6" textAlign={'center'}>
            <FormattedMessage id="encounterDetail.prescription" />
            &nbsp;{prescription.createdTimeFormat}
            &nbsp;{extractHTMLContent(prescription?.differentialDiagnosis)}
          </Typography>
          {prescription?.prescriptionDetailList?.map((item, index) => {
            const medication = medicationMap[item.medicationId!];
            return (
              <Box display="flex" flex={1} columnGap={2} flexWrap="wrap">
                <Typography variant="body2">
                  {index + 1}.&nbsp; <FormattedMessage id="encounterPrescription.groupDrug" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {medication?.categoryName}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.drugName" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {item?.medicationName}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.ingredients" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {medication?.ingredient}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="sale.dose" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {medication?.dose}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.unit" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {item?.unit}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.price" />
                  <Typography variant="subtitle2" component="span">
                    {NumericFormatText(medication?.basicPrice)}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="quantity" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {item?.quantity}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="therapy.detail.label.intendedRoute" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {allIntendedRoutes.find((i) => i.value === item.intendedRoute)?.label}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.instruction" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {item?.usage}
                  </Typography>
                </Typography>
              </Box>
            );
          })}
          <Typography variant="body2">
            <FormattedMessage id="encounterPrescription.note" />
            :&nbsp;
            <Typography variant="subtitle2" component="span">
              {prescription?.advice && extractHTMLContent(prescription.advice)}
            </Typography>
          </Typography>
        </Box>
      }
    >
      <Typography variant="subtitle2" width="100%">
        {prescription.createdTimeFormat}
        &nbsp;{extractHTMLContent(prescription?.differentialDiagnosis)}
      </Typography>
    </CustomTooltip>
  );
};

export default PrescriptionOldInfo;
