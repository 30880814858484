import styled from '@emotion/styled';
import { Box, Divider, Grid, Stack, Typography } from '@mui/material';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { DentalExam } from 'modules/schema';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

interface Props {
  dentalExam: DentalExam;
}

const TitleSessionStyled = styled(Typography)({
  textTransform: 'uppercase',
  fontWeight: 'bold',
  fontSize: '24px',
});

const COLUMNS: Columns<DentalExam>[] = [
  {
    dataIndex: 'serviceName',
    title: 'nameService',
  },
  {
    dataIndex: 'physicsRoomName',
    title: 'encounterList.room',
  },
  {
    dataIndex: 'picName',
    title: 'examDoctor',
  },
];

const PrintFormContent = ({ dentalExam }: Props) => {
  const { intl } = useGeneralHook();
  return (
    <Box padding={3} onClick={(event) => event.stopPropagation()}>
      <HeaderPrintForm>
        <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
          <FormattedMessage id="encounter.registration.title.printRegistrationForm" />
        </Typography>
      </HeaderPrintForm>
      <Stack sx={{ backgroundColor: 'gainsboro', paddingTop: '20px', paddingBottom: '20px', justifyContent: 'center' }}>
        <Typography sx={{ margin: 'auto', color: 'gray', fontSize: '45px', lineHeight: '60px' }}>
          <FormattedMessage id="encounter.registration.label.sequenceNumber" />
        </Typography>
        <Typography sx={{ margin: 'auto', fontSize: '110px', lineHeight: '130px', fontWeight: 500 }}>
          {dentalExam?.sequenceNumber?.split('-')?.[1]?.padStart(3, '0')}
        </Typography>

        <Typography sx={{ margin: 'auto', fontSize: '23px' }}>
          {dentalExam.createdTime?.format(FE_DATE_FORMAT)}
        </Typography>
      </Stack>
      <Divider sx={{ marginY: 1 }} />
      <Box marginTop={4}>
        <TitleSessionStyled>
          <FormattedMessage id="encounter.registration.title.patientInformation" />
        </TitleSessionStyled>
        <Grid item xs={12} style={{ columnCount: 1, marginTop: '16px' }}>
          {[
            {
              label: 'encounter.registration.label.patientName',
              value: dentalExam?.patient?.name,
              uppercase: true,
            },
            {
              label: 'birthdayShort',
              value: dentalExam?.patient?.dob
                ? moment(dentalExam?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)
                : '',
            },
            {
              label: 'age',
              value: moment().year() - moment(dentalExam?.patient?.dob, BE_DATE_FORMAT).year(),
            },
            {
              label: 'gender',
              value: intl.formatMessage({ id: dentalExam?.patient?.gender?.toLocaleLowerCase() || ' ' }),
            },
            {
              label: 'address',
              value: dentalExam.patient?.patientAddressList?.find(
                (address) => address?.type === PATIENT_ADDRESS_TYPE.home,
              )?.address,
            },
            {
              label: 'telephone',
              value: dentalExam?.patient?.mobilePhone,
            },
          ].map((item, index) => {
            return item?.label ? (
              <Box key={index} display="flex" flexWrap="wrap" alignItems={'baseline'}>
                <Typography variant="subtitle1" style={{ minWidth: 190 }} className="info-a5-size">
                  {/*{index + 1}.&nbsp;*/}
                  <FormattedMessage id={item?.label || ' '} />
                </Typography>
                :&nbsp;
                <span
                  style={{
                    textTransform: item?.uppercase ? 'uppercase' : 'initial',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                  className="info-a5-size"
                >
                  {item?.value}
                </span>
              </Box>
            ) : (
              <Box key={index} display="flex" flexWrap="wrap" alignItems={'baseline'}></Box>
            );
          })}
        </Grid>
      </Box>
      <Divider sx={{ marginY: 1 }} />
      <Box marginTop={4}>
        <TitleSessionStyled>
          <FormattedMessage id="encounter.registration.title.listService" />
        </TitleSessionStyled>
        <TableCustom
          containerProps={{
            sx: {
              marginTop: 3,
              '& th,tr,td': {
                padding: '2px 8px',
                backgroundColor: 'white',
                border: '0.5px solid',
                color: 'unset',
              },
              width: '100%',
              overflow: 'visible',
              '& td': {
                fontSize: '20px',
                padding: '10px 8px !important',
                '& p': {
                  fontSize: '20px',
                },
              },
              '& tr': {
                fontSize: '20px',
                '& th': {
                  padding: '10px 8px !important',
                  '& span': {
                    fontSize: '16px',
                  },
                },
              },
            },
          }}
          dataSource={[dentalExam]}
          columns={COLUMNS}
        />
      </Box>
    </Box>
  );
};

export default PrintFormContent;
