import { get, isEmpty } from 'lodash';
import { Column, NormalColumn, RowKey } from './type';
import { CSSProperties } from 'react';

export function getKey<T>(record: T, key: RowKey<T>) {
  if (typeof key === 'function') {
    return key(record);
  }
  return get(record, key);
}

export function renderCell<T>(record: T, index: number, column: NormalColumn<T>) {
  if (typeof column.render === 'function') {
    return column.render(record, index);
  }
  return get(record, column.render);
}

export function calculateLeft<T>(columns: Column<T>[], currentColumnIndex: number): CSSProperties['left'] {
  const firstStickyColumnIndex = columns.findIndex((column) => column.sticky);
  if (firstStickyColumnIndex === currentColumnIndex) {
    return '0px';
  }
  const frontStickyColumns = columns
    .filter((column) => column.sticky)
    .slice(firstStickyColumnIndex, currentColumnIndex);
  return frontStickyColumns.reduce<CSSProperties['left']>((sum, cur) => {
    if (isEmpty(sum)) {
      return cur.width;
    }
    return `calc(${sum} + ${cur.width})`;
  }, '');
}
