import { Box, Grid, Typography } from '@mui/material';
import PrintFormHeader from 'modules/common/component/PrintForm/PrintFormHeader';
import PrintFormTitle from 'modules/common/component/PrintForm/PrintFormTitle';
import TextValue from 'modules/examDoctor/component/therapy/TherapyIndication/IndicationRequestPrintForm/TextValue';
import { useDentalDetailStateSelector } from '../state';
import PatientInfo from './PatientInfo';
import DentalInfo from './DentalInfo';
import { useRef } from 'react';
import DialogAction from './DialogAction';
import { FormattedMessage } from 'react-intl';
import { FE_DATE_FORMAT } from 'modules/common/constants';
import { useFetch } from 'modules/common/hook/useFetch';
import { API_SERVER } from 'modules/common/api';
import { CARE_PLAN_SCOPE } from 'modules/common/apiConstants';

interface Props {
  isHospitalTranfer: boolean;
}

const DialogContent = ({ isHospitalTranfer }: Props) => {
  const dentalExam = useDentalDetailStateSelector.use.dentalExamInfo();
  const printContentRef = useRef(null);
  const { data: carePlan } = useFetch(
    API_SERVER.carePlan.get({ referenceId: dentalExam?.id!, scope: CARE_PLAN_SCOPE.DENTAL }),
    { enabled: dentalExam?.id },
  );

  return (
    <>
      <Box padding="56px" ref={printContentRef}>
        <PrintFormHeader />
        <PrintFormTitle
          titleKey={isHospitalTranfer ? 'printForm.transferForm' : 'dental.dentalDetail.printForm.title'}
        />
        <Grid container paddingTop={4}>
          {isHospitalTranfer ? (
            <Grid item xs={7}>
              <Typography
                sx={{
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '24px',
                  letterSpacing: '0.15px',
                  color: '#546E7A',
                  flexShrink: '0',
                }}
              >
                <FormattedMessage
                  id="dental.dentalDetil.hospitalTranfer.printForm.examDate"
                  values={{
                    fromDate: dentalExam?.startTime?.format(FE_DATE_FORMAT) || '...',
                    toDate: dentalExam?.endTime?.format(FE_DATE_FORMAT) || '...',
                  }}
                />
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={7}>
              <TextValue
                value={dentalExam?.physicsRoomName || ''}
                label="dental.dentalDetail.printForm.physicalRoom.title"
                labelWidth="180px"
              />
            </Grid>
          )}
          <Grid item xs={5}>
            <TextValue
              value={dentalExam?.patient?.code?.split('-')[1] || ''}
              label="dental.dentalDetail.printForm.patientId.title"
              labelWidth="150px"
            />
          </Grid>
          {isHospitalTranfer && (
            <Grid item xs={7}>
              <TextValue
                value={dentalExam?.physicsRoomName || ''}
                label="dental.dentalDetail.printForm.physicalRoom.title"
                labelWidth="180px"
              />
            </Grid>
          )}
          {isHospitalTranfer && (
            <Grid item xs={5}>
              <TextValue value={carePlan?.hospitalCode || ''} label="encounterInfo.hospitalCode" labelWidth="150px" />
            </Grid>
          )}
          <Grid item xs={7}>
            <TextValue
              value={dentalExam?.code || ''}
              label="dental.dentalDetail.printForm.id.title"
              labelWidth="180px"
            />
          </Grid>
          {isHospitalTranfer && (
            <Grid item xs={5}>
              <TextValue value={carePlan?.hospitalName || ''} label="encounterInfo.hospitalName" labelWidth="150px" />
            </Grid>
          )}
        </Grid>
        <PatientInfo />
        <DentalInfo carePlan={carePlan} />
      </Box>
      <DialogAction printContentRef={printContentRef} />
    </>
  );
};

export default DialogContent;
