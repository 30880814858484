import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_FORMAT } from '../common/constants';

export const SaleReceiptDetailSchema = z
  .object({
    id: z.number(),
    receiptId: z.number(),
    medicationId: z.number(),
    resourceId: z.string(),
    medicationName: z.string(),
    lot: z.string(),
    expiredDate: createDateTimeSchema(BE_DATE_FORMAT),
    quantity: z.number(),
    unit: z.string(),
    unitPrice: z.number(),
    factor: z.number(),
    discountType: z.string(),
    discount: z.number(),
    discountAmount: z.number(),
    vat: z.number(),
    vatAmount: z.number(),
    invoiceDiscountAmount: z.number(),
    totalAmount: z.number(),
    amountPaid: z.number(),
    numeratorUnit: z.string(),
    ingredient: z.string(),
    dose: z.string(),
  })
  .partial();

export type SaleReceiptDetail = z.infer<typeof SaleReceiptDetailSchema>;
