import moment from 'moment';
import * as React from 'react';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import SchemaForm from 'modules/common/SchemaForm';
import { Typography } from '@mui/material';
import { validateOneYearDate } from '../../utils';
import { GroupOption } from 'modules/common/type';
import { useUserRoles } from 'modules/common/hook/useUserRoles';
import { keyBy, sortBy } from 'lodash';
import { BASIC_ROLE_INFO, ROLE_INFO } from 'modules/layout/utils';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

interface Props {
  hookPagination: HookPaginationProps;
  serviceOptions: GroupOption[];
}
const PARTICIPANTS = [
  ROLE_INFO.examDoctor,
  ROLE_INFO.labDoctor,
  ROLE_INFO.imageSpecialist,
  ROLE_INFO.laboratory,
  ROLE_INFO.imageTechnician,
  ROLE_INFO.nurse,
  ROLE_INFO.pharmacist,
  BASIC_ROLE_INFO.general,
  BASIC_ROLE_INFO.pharmacist,
  BASIC_ROLE_INFO.examDoctor,
  BASIC_ROLE_INFO.frontOffice,
];

const DetailedFilterBox: React.FunctionComponent<Props> = ({ hookPagination, serviceOptions }) => {
  const { params, setParams, clearParams } = hookPagination;
  const users = useUserRoles(
    'DOCTOR',
    'NURSE',
    'RADIOLOGIST',
    'LABORATORY_TECHNOLOGIST',
    'RADIOGRAPHER',
    'CLINICAL_PATHOLOGIST',
    'GENERAL_DOCTOR',
  );
  const { intl } = useGeneralHook();

  const participantOptions = React.useMemo(() => {
    if (users && users.length > 0) {
      const roleMap = keyBy(PARTICIPANTS, 'roleKey');

      return sortBy(
        users?.map((ele) => {
          return {
            ...ele,
            group: intl?.formatMessage({ id: ele?.roleName && roleMap[ele?.roleName]?.label }),
            value: ele.id!,
            label: ele.employeeFullName!,
          };
        }),
        'group',
      );
    }
    return [];
  }, [intl, users]);

  return (
    <SchemaForm
      schema={{
        fields: ({ valuesField, formProps }) => {
          const { intl } = formProps || {};

          return {
            fromDate: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'fromDate' }),
              propsWrapper: { xs: 1.5, paddingBottom: 1 },
              maxDate: valuesField.toDate ? moment(valuesField.toDate).toDate() : null,
              minDate: valuesField.toDate ? moment(valuesField.toDate).subtract(364, 'days').toDate() : null,
              register: {
                validate: (value) => {
                  return value && moment(value).isAfter(valuesField?.toDate)
                    ? intl.formatMessage({ id: 'validation.invalidDate' })
                    : true;
                },
              },
              tooltipError: true,
            },
            toDate: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'toDate' }),
              propsWrapper: { xs: 1.5 },
              minDate: valuesField.fromDate ? moment(valuesField.fromDate).toDate() : null,
              maxDate: valuesField.fromDate ? moment(valuesField.fromDate).add(364, 'days').toDate() : null,
              register: {
                validate: (value) => {
                  return value && moment(value).isBefore(valuesField?.fromDate)
                    ? intl.formatMessage({ id: 'validation.invalidDate' })
                    : true;
                },
              },
              tooltipError: true,
            },
            therapyId: {
              type: 'auto-complete',
              label: intl.formatMessage({ id: 'therapy.name' }),
              placeholder: intl.formatMessage({
                id: 'dental.dentalDetail.statusAndDiagnosis.input.therapyDefinition.placeholder',
              }),
              options: serviceOptions,
              propsWrapper: { xs: 2 },
              groupBy: (option) => option.group,
              hasAllOptions: true,
              multiple: true,
            },
            picUserId: {
              type: 'auto-complete',
              label: intl.formatMessage({ id: 'therapyList.filter.label.picName' }),
              placeholder: intl.formatMessage({
                id: 'therapy.therapyReport.summary.title.filter.placeholder.personInCharge',
              }),
              options: participantOptions,
              propsWrapper: { xs: 1.5 },
              groupBy: (option) => option.group,
              hasAllOptions: true,
              multiple: true,
            },
            search: {
              type: 'submitButton',
              color: 'inherit',
              label: intl.formatMessage({ id: 'apply' }),
              propsWrapper: { xs: 1 },
              style: { minWidth: 120, marginTop: 20 },
            },
            reset: {
              type: 'button',
              onClick: () => clearParams && clearParams(),
              color: 'inherit',
              label: intl.formatMessage({ id: 'reset' }),
              propsWrapper: { xs: 1 },
              style: { minWidth: 120, marginTop: 20 },
            },
            dateOutRange: {
              unregister: true,
              hidden:
                !valuesField.fromDate ||
                !valuesField.toDate ||
                validateOneYearDate(moment(valuesField.fromDate), moment(valuesField.toDate)),
              type: () => {
                return (
                  <Typography color="primary" style={{ color: 'red', marginTop: '-15px', marginBottom: '10px' }}>
                    {intl.formatMessage({ id: 'validation.outOfQuarter2' })}
                  </Typography>
                );
              },
            },
          };
        },
      }}
      onSubmit={setParams}
      formData={params}
      hideSubmitButton
    />
  );
};

export default DetailedFilterBox;
