import useSWR, { SWRConfiguration, SWRResponse } from 'swr';
import { ZodSchema, z } from 'zod';
import useGeneralHook from './useGeneralHook';
import { axiosThunk } from '../redux/axios';
import { setLoading } from '../redux/commonReducer';
import { useEffect } from 'react';

export type ControllerURL<T extends (params: Parameters<T>) => string = () => string> = string | T;

export type ControllerAPI<Z extends ZodSchema> = {
  url: string;
  schema: Z;
};

type Config<T extends ZodSchema> = SWRConfiguration<z.infer<T>, any> & {
  globalLoading?: boolean;
  enabled?: any;
};

export function useFetch<T extends ZodSchema>(
  controller: ControllerAPI<T>,
  config: Config<T> = { enabled: true },
): SWRResponse<z.infer<T>, any> {
  const { schema, url } = controller;
  const { dispatch } = useGeneralHook();
  const { globalLoading, enabled, ...swrConfig } = config || {};
  const result = useSWR(
    !config.hasOwnProperty('enabled') || enabled ? url : null,
    async () => {
      const { data } = await dispatch(axiosThunk({ url }));
      return schema?.parse(data);
    },
    { ...swrConfig },
  );
  useEffect(() => {
    if (globalLoading) {
      dispatch(setLoading(result.isValidating));
    }
  }, [dispatch, globalLoading, result.isValidating]);
  return result;
}
