import { Button } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import Stack from '@mui/material/Stack';
import * as React from 'react';

interface Props {
  onClose: () => void;
  printContentRef: React.MutableRefObject<null>;
}

const DialogAction = ({ onClose, printContentRef }: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      gap="20px"
      sx={{ backgroundColor: '#F4F8FF', padding: '10px 24px' }}
    >
      <Button onClick={onClose} sx={{ padding: '3px 25px' }} variant="contained" color="inherit">
        <FormattedMessage id="cancel" />
      </Button>
      <ReactToPrint
        trigger={() => (
          <Button sx={{ padding: '3px 15px' }} startIcon={<PrintIcon />}>
            <FormattedMessage id="therapyDetail.print.dialog.label.buttonPrint" />
          </Button>
        )}
        content={() => printContentRef.current}
      />
    </Stack>
  );
};

export default DialogAction;
