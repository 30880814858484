import { Alert, Box, Button, IconButton, Popper, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { Close } from '@mui/icons-material';
import moment from 'moment';
import { TimeField } from '@mui/x-date-pickers';
import dayjs, { Dayjs } from 'dayjs';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_TIME_FORMAT, FE_TIME_ZONE_FORMAT } from 'modules/common/constants';
import { useCreateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { isEmpty } from 'lodash';
import { axiosThunk } from 'modules/common/redux/axios';
import { Appointment, Encounter } from 'modules/schema';
import { setLoading } from 'modules/common/redux/commonReducer';
import { AxiosError } from 'axios';

interface Props {
  popperElement: null | HTMLElement;
  open: boolean;
  setPopperElement: (popperElement) => void;
  encounter: Encounter;
  revalidateAppointment: () => void;
}

const AppointmentBookingDialog: React.FunctionComponent<Props> = (props) => {
  const { popperElement, open, setPopperElement, encounter, revalidateAppointment } = props;
  const { intl, appState, dispatch, openSnackbar } = useGeneralHook();
  const authenUser = appState.authen.user;
  const [appointmentDate, setAppointmentDate] = useState<Dayjs | null>();
  const [startTime, setStartTime] = useState<Dayjs | null>();
  const [endTime, setEndTime] = useState<Dayjs | null>();
  const [isErr, setIsErr] = useState<boolean>(false);
  const [overLapData, setOverLapData] = useState<Appointment[]>([]);

  // format start time request
  const startTimeFormat = useCallback(
    (startTime) => {
      if (startTime) {
        if (appointmentDate) {
          return `${appointmentDate.format(BE_DATE_FORMAT)}T${startTime.format(FE_TIME_ZONE_FORMAT)}`;
        } else {
          return `${moment().format(BE_DATE_FORMAT)}T${startTime.format(FE_TIME_ZONE_FORMAT)}`;
        }
      }
      return '';
    },
    [appointmentDate],
  );

  // format end time request
  const endTimeFormat = useCallback(
    (endTime) => {
      if (endTime) {
        if (appointmentDate) {
          return `${appointmentDate.format(BE_DATE_FORMAT)}T${endTime.format(FE_TIME_ZONE_FORMAT)}`;
        } else {
          return `${moment().format(BE_DATE_FORMAT)}T${endTime.format(FE_TIME_ZONE_FORMAT)}`;
        }
      }
      return '';
    },
    [appointmentDate],
  );

  // submit create booking appointment
  const createAppointmentBooking = useCreateMutate({
    onSuccess: () => {
      revalidateAppointment();
      setPopperElement(null);
    },
  });
  const onSubmit = async () => {
    let startTimeValue = startTimeFormat(startTime);
    let endTimeValue = endTimeFormat(endTime);
    if (startTimeValue && endTimeValue) {
      await createAppointmentBooking({
        url: API_SERVER.appointment.create(),
        method: 'POST',
        data: {
          picName: authenUser?.employee_name,
          picUserId: authenUser?.user_id,
          startTime: startTimeValue,
          endTime: endTimeValue,
          type: 'ENCOUNTER',
          name: encounter?.patient?.name,
          mobilePhone: encounter?.patient?.mobilePhone,
          referenceId: encounter.id,
          patientId: encounter.patientId,
          gender: encounter.patient?.gender,
          dob: encounter.patient?.dob,
          address: encounter.patient?.homeAddress?.address,
        },
      });
    } else {
      setIsErr(true);
    }
  };

  // appointment overlap
  const endTimeShow = useCallback(async () => {
    if (startTime) {
      let newEndTime: Dayjs | null = null;
      if (startTime && !endTime) {
        newEndTime = dayjs(
          moment(startTime.format())
            .add(+30, 'minute')
            .format(BE_DATE_TIME_FORMAT),
        );
        setEndTime(newEndTime);
      }

      if (!startTime.isValid() || !endTime?.isValid()) {
        return;
      }
      dispatch(setLoading(true));
      try {
        const res = await dispatch(
          axiosThunk(
            API_SERVER.appointment.overlap({
              fromDate: startTimeFormat(startTime),
              toDate: newEndTime ? endTimeFormat(newEndTime) : endTimeFormat(endTime),
              picUserIds: authenUser?.user_id ? [authenUser.user_id] : [],
            }),
          ),
        );
        setOverLapData(res?.data);
      } catch (error) {
        if (error instanceof AxiosError) {
          if (error.response) {
            error.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          }
        }
      }
      dispatch(setLoading(false));
    }
  }, [authenUser, dispatch, endTime, endTimeFormat, openSnackbar, startTime, startTimeFormat]);

  useEffect(() => {
    if (!popperElement) {
      setAppointmentDate(null);
      setStartTime(null);
      setEndTime(null);
      setIsErr(false);
      setOverLapData([]);
    }
  }, [popperElement]);

  return (
    <Popper id="simple-popper" open={open} anchorEl={popperElement} style={{ zIndex: 9, maxWidth: '450px' }}>
      <Box sx={{ border: '1px solid #CFD8DC', bgcolor: 'background.paper' }}>
        <Typography
          variant="h6"
          color="primary"
          display={'flex'}
          justifyContent={'space-between'}
          padding={'12px 16px'}
          sx={{ backgroundColor: 'rgb(244, 248, 255)' }}
        >
          <FormattedMessage id={'calendar.add'} />
          <IconButton onClick={() => setPopperElement(null)} size="small" tabIndex={-1} sx={{ padding: 0 }}>
            <Close fontSize="small" />
          </IconButton>
        </Typography>
        <Box sx={{ p: '10px 30px 30px' }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateCalendar
              sx={{
                border: '1px solid #ECEFF1',
                marginBottom: '15px',
                '.MuiPickersCalendarHeader-root': {
                  backgroundColor: ' #ECEFF1',
                  marginTop: 0,
                  padding: '25px 10px',
                  '.MuiPickersCalendarHeader-labelContainer': {
                    color: '#37474F',
                    fontSize: '20px',
                  },
                  '.MuiSvgIcon-root': {
                    fontSize: '30px!important',
                  },
                },
              }}
              disablePast={true}
              onChange={(value) => setAppointmentDate(value)}
            />
            <TimeField
              label={intl.formatMessage({ id: 'calendar.checkinTime' })}
              format={FE_TIME_FORMAT}
              sx={{ marginRight: '20px', maxWidth: '150px' }}
              value={startTime}
              onChange={(value) => setStartTime(value)}
              required
              helperText={
                isErr &&
                !startTime && (
                  <Typography color={'red'} fontSize={'12px'} lineHeight={'12px'}>
                    <FormattedMessage id="required" />
                  </Typography>
                )
              }
              onBlur={() => endTimeShow()}
            />
            <TimeField
              label={intl.formatMessage({ id: 'calendar.checkoutTime' })}
              format={FE_TIME_FORMAT}
              sx={{ maxWidth: '150px' }}
              value={endTime}
              onChange={(value) => setEndTime(value)}
              required
              onBlur={() => endTimeShow()}
              helperText={
                isErr &&
                !endTime && (
                  <Typography color={'red'} fontSize={'12px'} lineHeight={'12px'}>
                    <FormattedMessage id="required" />
                  </Typography>
                )
              }
            />
          </LocalizationProvider>
          {!isEmpty(overLapData) && (
            <Alert
              severity="warning"
              sx={{
                background: 'none',
                flexDirection: 'row',
                '>div': { color: '#FF9800!important' },
                justifyContent: 'center',
                padding: 0,
              }}
            >
              {intl.formatMessage({ id: 'encounter.detail.appointment.label.exist' }, { number: overLapData?.length })}
            </Alert>
          )}
          <Box display={'flex'} marginTop={'9px'}>
            <Button variant="outlined" fullWidth sx={{ marginRight: '20px' }} onClick={() => setPopperElement(null)}>
              <FormattedMessage id="cancel" />
            </Button>
            <Button variant="contained" fullWidth onClick={onSubmit} disabled={!startTime || !endTime}>
              <FormattedMessage id="booked" />
            </Button>
          </Box>
        </Box>
      </Box>
    </Popper>
  );
};

export default React.memo(AppointmentBookingDialog);
