import { z } from 'zod';

export const RevenueReportSchema = z
  .object({
    identifier: z.string(),
    finalTotalAmount: z.number(),
    amountPaid: z.number(),
    status: z.string(),
  })
  .partial();

export type RevenueReport = z.infer<typeof RevenueReportSchema>;
