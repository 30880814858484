import styled from '@emotion/styled';
import { alpha, InputBase } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';

export const CustomInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 16,
    width: 'auto',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}))(InputBase);

export const RawLink = styled(Link)`
  text-decoration: none;
  color: unset;
`;

export const PopoverState = (props: {
  children(params: {
    anchorEl: HTMLButtonElement | null;
    handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    handleClose: () => void;
    open: boolean;
  }): ReactElement;
}) => {
  const { children } = props;
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return <>{children({ anchorEl, handleClick, handleClose, open })}</>;
};
