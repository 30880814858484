import { Box, Grid, Typography } from '@mui/material';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import { some } from '../../../../common/constants';
import { DRUG_OPTIONS } from '../../../constants';
import * as React from 'react';

interface Props {
  info?: some;
  departments: some[];
}
const DrugInfoBox = (props: Props) => {
  const { info, departments } = props;

  if (!info) {
    return null;
  }
  return (
    <>
      <Typography variant="h6" style={{ marginBottom: 16 }}>
        <FormattedMessage id="pharmacy.drugInfo" />
      </Typography>
      <Grid container spacing={1.5}>
        {[
          {
            label: 'pharmacy.code',
            dataIndex: 'code',
          },
          {
            label: 'pharmacy.registerNumber',
            dataIndex: 'registrationNumber',
          },
          {
            label: 'pharmacy.drugName',
            dataIndex: 'name',
          },
          {
            label: 'pharmacy.unit',
            dataIndex: 'numeratorUnit',
          },

          {
            label: 'pharmacy.departmentCode',
            render: (record) => departments.find((v) => v.value === record.organizationId)?.label,
          },
          {
            label: 'pharmacy.groupCode',
            render: (record) => record.codeGroup,
          },
          {
            label: 'pharmacy.packagingFormat',
            dataIndex: 'medicationKnowledge.packaging',
          },
          {
            label: 'pharmacy.dosageForm',
            dataIndex: 'form',
          },
          {
            label: 'expiredDate',
            dataIndex: 'medicationKnowledge.expiration',
          },

          {
            label: 'pharmacy.produceCountry',
            dataIndex: 'country',
          },

          {
            label: 'pharmacy.intendedRoute',
            dataIndex: 'intendedRoute.label',
            render: (record) => (
              <Box component="ul" margin={0} paddingLeft={2}>
                {record.intendedRoute &&
                  record.intendedRoute
                    //.filter((one) => one.value)
                    .map((route) => (
                      <Typography component={'li'}>{` ${route.label || ''} - ${route.value || ''}`}</Typography>
                    ))}
              </Box>
            ),
          },
          {
            label: 'pharmacy.formType',
            render: (record) => {
              const label = DRUG_OPTIONS.find((v) => v.value === record.medicationKnowledge?.productType)?.label;
              return label && <FormattedMessage id={label} />;
            },
          },
          {
            label: 'pharmacy.ingredients',
            dataIndex: 'ingredient',
          },
          {
            label: 'pharmacy.amount',
            dataIndex: 'dose',
          },
        ].map((item, index) => {
          return (
            <Grid item key={index} xs={6}>
              <Typography variant="subtitle1" color="textSecondary">
                <FormattedMessage id={item.label} />
              </Typography>
              <Typography variant="body2">{item.render ? item.render(info) : get(info, item.dataIndex)}</Typography>
            </Grid>
          );
        })}
      </Grid>
      <Typography variant="h6" style={{ marginBottom: 16, marginTop: 16, display: 'none' }}>
        <FormattedMessage id="pharmacy.organizationInfo" />
      </Typography>
      <Grid container spacing={1.5} style={{ display: 'none' }}>
        {[
          {
            label: 'pharmacy.producer',
            dataIndex: 'manufacturer.name',
          },
          {
            label: 'address',
            dataIndex: 'manufacturer.address',
          },
          {
            label: 'pharmacy.registerCompany',
            dataIndex: 'registration.name',
          },
          {
            label: 'address',
            dataIndex: 'registration.address',
          },
          {
            label: 'pharmacy.distributor',
            dataIndex: 'distributor.name',
          },
          {
            label: 'address',
            dataIndex: 'distributor.address',
          },
        ].map((item, index) => {
          return (
            <Grid item key={index} xs={6}>
              <Typography variant="subtitle1" color="textSecondary">
                <FormattedMessage id={item.label} />
              </Typography>
              <Typography variant="body2">{get(info, item.dataIndex)}</Typography>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
export default DrugInfoBox;
