import { Box, Button, Typography } from '@mui/material';
import { chain } from 'lodash';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../common/component/DialogCustom';
import { some } from '../../../common/constants';
import GroupSupplyFormDialog from './GroupSupplyFormDialog';
import SupplyFormDialog from './SupplyFormDialog';
import SupplyTable from './SupplyTable';

interface Props {
  onSubmit: (formVal: some[]) => void;
  open: boolean;
  onClose: () => void;
  dataCSV?: some[];
}

const CSVPreviewDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, dataCSV, onSubmit } = props;
  const [data, setData] = React.useState<some[]>([]);
  const [formData, setFormData] = React.useState<some | undefined>();
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();

  const onDelete = React.useCallback((value: some) => {
    setData((old) => old.filter((val) => val.id !== value.id));
  }, []);

  const onDeleteGroup = React.useCallback((value: some) => {
    setData((old) => old.filter((val) => val.group !== value.name));
  }, []);

  const onSubmitForm = React.useCallback(({ listData, ...value }: some) => {
    if (value.id) {
      setData((old) =>
        old.map((val) => {
          if (val.id === value.id) {
            return {
              ...value,
              specializedDepartment: {
                code: value.specializedDepartmentId,
              },
            };
          }
          return val;
        }),
      );
    } else {
      setData((old) => [
        ...old,
        {
          ...value,
          group: listData?.[0]?.group,
          specializedDepartment: {
            code: value.specializedDepartmentId,
          },
        },
      ]);
    }
    setFormData(undefined);
  }, []);

  const onSubmitFormGroup = React.useCallback((value: some) => {
    setData((old) =>
      old.map((val) => {
        if (val.group === value.group) {
          return { ...val, group: value.name };
        }
        return val;
      }),
    );
    setFormDataGroup(undefined);
  }, []);

  const getValidate = React.useCallback((value: some) => {
    if (
      !value.group ||
      !value.name ||
      !value.commercialName ||
      !value.unit ||
      !value.packageMethod ||
      !value.code ||
      !value.groupCode ||
      !value.specializedDepartmentId ||
      !value.purchasePrice ||
      !value.supplyPrice
    ) {
      return false;
    }
    return true;
  }, []);

  const dataMapped = React.useMemo(() => {
    return chain(data.map((val) => ({ ...val, validation: getValidate(val) })))
      .groupBy('group')
      .map((value, key) => ({
        group: key,
        name: key,
        items: value,
      }))
      .value()
      .sort((a: some, b: some) => a.group.localeCompare(b.group));
  }, [data, getValidate]);

  return (
    <>
      <DialogCustom
        open={!!open}
        maxWidth="xs"
        keepMounted={false}
        onClose={onClose}
        TransitionProps={{
          onEnter: () =>
            setData(
              dataCSV
                ? dataCSV.slice(1).map((v, index) => ({
                    id: index + 1,
                    categoryName: v.data[0],
                    group: v.data[0],
                    name: v.data[1],
                    commercialName: v.data[2],
                    unit: v.data[3],
                    packageMethod: v.data[4],
                    code: v.data[5],
                    groupCode: v.data[6],
                    department: v.data[7],
                    departmentCode: `{${v.data[7]}}`,
                    winningBidDecision: v.data[8],
                    paymentPercent: v.data[9],
                    purchasePrice: v.data[10],
                    supplyPrice: v.data[11],
                    maximumPrice: v.data[12],
                    specializedDepartmentId: parseInt(v.data[7]),
                    specializedDepartment: {
                      code: `{${v.data[7]}}`,
                    },
                  }))
                : [],
            ),
        }}
        PaperProps={{
          style: { minWidth: '70vw', overflow: 'hidden' },
        }}
        disableCloseOutSide
        title={
          <Typography variant="h6" color="primary">
            <FormattedMessage id={'CSVPreview'} />
          </Typography>
        }
      >
        <Box padding={2} overflow="auto">
          {dataMapped?.length ? (
            dataMapped?.map((value: some) => {
              return (
                <SupplyTable
                  key={value.id}
                  setFormData={setFormData}
                  setFormDataGroup={setFormDataGroup}
                  onDelete={onDelete}
                  onDeleteGroup={onDeleteGroup}
                  onDeleteServices={() => {}}
                  data={value}
                  enableDeleteGroup
                />
              );
            })
          ) : (
            <FormattedMessage id="noData" />
          )}
        </Box>
        <Box padding={2} display="flex" justifyContent="flex-end">
          <Button variant="outlined" color="primary" style={{ minWidth: 100, marginRight: 16 }} onClick={onClose}>
            <FormattedMessage id={'cancel'} />
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ minWidth: 100 }}
            disabled={dataMapped.length === 0 || !!data.find((val) => !getValidate(val))}
            onClick={() => {
              onSubmit && onSubmit(data);
            }}
          >
            <FormattedMessage id={'save'} />
          </Button>
        </Box>
      </DialogCustom>
      <SupplyFormDialog
        open={!!formData}
        formData={formData}
        onSubmit={onSubmitForm}
        onClose={() => {
          setFormData(undefined);
        }}
      />
      <GroupSupplyFormDialog
        open={!!formDataGroup}
        formData={formDataGroup}
        onSubmit={onSubmitFormGroup}
        onClose={() => {
          setFormDataGroup(undefined);
        }}
      />
    </>
  );
};
export default CSVPreviewDialog;
