import { Box, IconButton, Typography } from '@mui/material';
import { Delete, Edit, Print } from '@mui/icons-material';
import moment from 'moment';
import { BE_DATE_TIME_FORMAT, FE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { useDiagnosisDialogSelector } from './state';
import { API_SERVER } from 'modules/common/api';
import { useFetch } from 'modules/common/hook';

const CameraImageList = () => {
  const dentalDetail = useDiagnosisDialogSelector.use.dentalDetail();
  const diagnosisImages = useDiagnosisDialogSelector.use.diagnosisImages();
  const deleteDiagnosisImage = useDiagnosisDialogSelector.use.deleteDiagnosisImage();
  const addDiagnosisImages = useDiagnosisDialogSelector.use.addDiagnosisImages();
  const onOpenDiagnosisImageDialog = useDiagnosisDialogSelector.use.onOpenDiagnosisImageDialog();
  const onOpenPrintFormDialog = useDiagnosisDialogSelector.use.onOpenPrintFormDialog();

  useFetch(
    API_SERVER.diagnosticImage.getDiagnosticImages({
      imageType: 'DENTAL_DIAGNOSIS',
      referenceId: dentalDetail?.id || 0,
    }),
    {
      onSuccess: addDiagnosisImages,
      revalidateOnFocus: false,
      enabled: dentalDetail?.id,
    },
  );

  const diagnosisImageList = diagnosisImages.filter((ele) => ele.state !== 'delete');

  if (!diagnosisImageList || diagnosisImageList.length === 0) return null;

  return (
    <Box marginTop={2}>
      {diagnosisImageList
        .sort((a, b) => {
          return (a.createdTime?.millisecond() || 0) - (b.createdTime?.millisecond() || 0);
        })
        .map((item, index) => {
          return (
            <Box
              key={item.id}
              color="inherit"
              sx={{
                display: 'flex',
                justifyContent: 'flex-start',
                textAlign: 'start',
                minHeight: '32px',
                padding: 1,
                bgcolor: 'secondary.light',
                alignItems: 'center',
              }}
            >
              <Typography variant="subtitle2" color="textPrimary" flex={1}>
                {moment(item.updatedTime || item.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT)}
                &emsp;CĐHA-
                {index + 1}
              </Typography>
              {item?.state !== 'add' && (
                <IconButton style={{ padding: 4 }} onClick={() => onOpenPrintFormDialog(item)}>
                  <Print />
                </IconButton>
              )}
              <IconButton style={{ padding: 4 }} onClick={() => onOpenDiagnosisImageDialog(item)}>
                <Edit />
              </IconButton>
              <IconButton style={{ padding: 4, marginRight: 18 }} onClick={() => deleteDiagnosisImage(item?.id!)}>
                <Delete />
              </IconButton>
            </Box>
          );
        })}
    </Box>
  );
};

export default CameraImageList;
