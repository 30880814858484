import { Box, Divider, Grid, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import DialogCustom from 'modules/common/component/DialogCustom';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { Payment, PaymentDetail } from 'modules/schema';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import TableCustom, { Columns } from '../../../../component/TableCustom';
import { NumericFormatText } from 'modules/common/utils';
import {
  PACKAGE_SCOPE,
  PAYMENT_DETAIL_TYPE,
  PAYMENT_METHOD,
  PAYMENT_STATUS,
  PAYMENT_TYPE,
} from 'modules/common/apiConstants';

export interface RevenueReportDetailContentProps {
  payment: Payment;
}

interface RevenueReportDetailProps {
  open: boolean;
  onClose?: () => void;
  payment: Payment;
}

export const RevenueReportDetailContent = (props: RevenueReportDetailContentProps) => {
  const { payment } = props;
  const { data: dataPaymentDetail } = useFetch(
    API_SERVER.payment.detail(payment.id!, {
      type: payment.type!,
      isShowAll: true,
    }),
  );
  const { appState, intl } = useGeneralHook();
  const { currentLocation } = appState.authen;
  const dataSourcePaymentDetail = React.useMemo(() => {
    if (payment.type === PAYMENT_TYPE.THERAPY) {
      if (dataPaymentDetail?.isPackagePaid || dataPaymentDetail?.isPackagePaid === undefined) {
        return dataPaymentDetail?.paymentDetails?.filter(
          (item) =>
            item.type !== PAYMENT_DETAIL_TYPE.THERAPY_SESSION.value ||
            item.packageScope === PACKAGE_SCOPE.OUT_PACKAGE.value,
        );
      } else {
        return dataPaymentDetail?.paymentDetails?.filter(
          (item) =>
            item.type !== PAYMENT_DETAIL_TYPE.THERAPY.value || item.packageScope === PACKAGE_SCOPE.OUT_PACKAGE.value,
        );
      }
    }
    return dataPaymentDetail?.paymentDetails;
  }, [dataPaymentDetail, payment.type]);

  const header = React.useMemo(() => {
    return (
      <>
        <Box display={'flex'} justifyContent={'center'} paddingBottom={3} paddingTop={3}>
          <Typography variant="h5" style={{ textTransform: 'uppercase', paddingTop: '0px', paddingBottom: '8px' }}>
            <FormattedMessage id={'therapy.report.revenue.table.header.revenueDetailAll'} />
          </Typography>
        </Box>
        <Grid container spacing={2} paddingLeft={3}>
          <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 130 }}>
                <FormattedMessage id="report.debt.table.title.patientName" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{payment.patientName}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 130 }}>
                <FormattedMessage id="report.debt.table.title.mobilePhone" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {payment.mobilePhone}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 130 }}>
                <FormattedMessage id="cashierPrint.encounterId" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {payment.referenceCode}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [payment]);

  const bodyTable = React.useMemo(() => {
    const columns: Columns<PaymentDetail>[] = [
      {
        title: 'cashier.service',
        render: (record) => <Typography variant="body1">{record?.content}</Typography>,
      },
      {
        render: (record) => <Typography variant="body1">{record?.quantity}</Typography>,
        title: 'printForm.SL',
        align: 'center',
      },
      {
        render: (record) => (
          <Typography variant="body1">{record?.unit || intl.formatMessage({ id: 'cashier.time' })}</Typography>
        ),
        title: 'indication.unit',
        align: 'center',
      },
      {
        render: (record) => NumericFormatText(record?.totalAmount),
        title: 'drugDispenseBook.totalPrice',
        align: 'center',
      },
      {
        render: (record) => NumericFormatText((record?.discountAmount || 0) + (record.billDiscountAmount || 0)),
        title: 'revenueReport.discountAmount',
        align: 'center',
      },
      {
        render: (record) => <Typography variant="body1">{record?.vat}</Typography>,
        title: 'sellingProduct.title.VAT',
        align: 'center',
      },
      {
        render: (record) => NumericFormatText(record?.amountPaid),
        title: 'label.report.revenueExpenditure.total',
        align: 'center',
      },
      {
        render: (record) => {
          let status = record?.refundId ? PAYMENT_STATUS.PAID : PAYMENT_STATUS[record.status!];
          return (
            <Typography>
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
        title: 'cashier.paymentStatus',
        align: 'center',
      },
      {
        render: (record) => {
          const status = PAYMENT_METHOD[record.paymentMethod!];
          return (
            <Typography>
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
        title: 'cashier.paymentMethod',
        align: 'center',
      },
    ];

    return (
      <Box padding={3}>
        <TableCustom
          containerProps={{
            sx: {
              '& th,tr,td': {
                padding: '9px 8px',
                backgroundColor: 'white',
                border: '0.5px solid',
                color: 'unset',
              },
              'tbody>tr td:nth-child(2)': {
                padding: '1px 5px',
              },
              width: '100%',
              overflow: 'visible',
              marginTop: '8px',
            },
          }}
          dataSource={dataSourcePaymentDetail}
          columns={columns}
        />
      </Box>
    );
  }, [dataSourcePaymentDetail, intl]);

  React.useEffect(() => {
    const css = `@page { size: ${1000 * 0.75}px  ${1290 * 0.75}px; margin: 0cm 0.6cm; }`;
    const head = document.head || document.getElementsByTagName('head')[0];
    const style = document.createElement('style');
    style.media = 'print';
    style.appendChild(document.createTextNode(css));
    head.appendChild(style);
    return () => {
      head.removeChild(style);
    };
  }, []);

  if (!currentLocation) {
    return null;
  }

  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }}>
        {header}
        <Box marginY={1}>
          <Divider />
        </Box>
        {bodyTable}
      </Box>
    </>
  );
};
const RevenueReportDetailDialog = (props: RevenueReportDetailProps) => {
  const { onClose, open, payment } = props;

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: '100vw', minWidth: '80vw' },
      }}
      title={'therapy.report.revenue.table.header.revenueDetailAll'}
    >
      <Box>
        <RevenueReportDetailContent payment={payment} />
      </Box>
    </DialogCustom>
  );
};

export default RevenueReportDetailDialog;
