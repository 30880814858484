import React, { ReactNode } from 'react';
import DialogCustom from '../DialogCustom';
import ReactToPrint from 'react-to-print';
import { Box, Button, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import HeaderPrintForm from '../HeaderPrintForm';
import { Print } from '@mui/icons-material';

interface Props {
  openDialog: boolean;
  onCloseDialog: () => void;
  onOpenDialog: () => void;
  title: string;
  content: ReactNode;
}

export function PrintDataButton({ title, content, openDialog, onCloseDialog, onOpenDialog }: Props) {
  const refPrintComponent = React.useRef(null);
  return (
    <>
      <Button startIcon={<Print />} onClick={onOpenDialog}>
        <FormattedMessage id="printReport" />
      </Button>
      <DialogCustom
        open={openDialog}
        onClose={onCloseDialog}
        PaperProps={{
          style: { width: '80vw' },
        }}
        maxWidth="xl"
        title={'printReport'}
        footer={
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id={'printReport'} />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />
        }
      >
        <Box ref={refPrintComponent}>
          <Box sx={{ pageBreakAfter: 'always', '@page': { size: '1800px 1000px' } }} padding={2}>
            <HeaderPrintForm>
              <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
                <FormattedMessage id={title} />
              </Typography>
            </HeaderPrintForm>
            {content}
          </Box>
        </Box>
      </DialogCustom>
    </>
  );
}
