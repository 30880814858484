import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from 'modules/common/api';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import DetailedTable from './DetailedTable';
import DetailedFilterBox from './DetailedFilterBox';
import { formatDateFilterField } from 'modules/common/utils';
import { useFetch } from 'modules/common/hook';

interface Props {
  revalidateAppointment: () => void;
}

const TherapyDebtReport: React.FunctionComponent<Props> = ({ revalidateAppointment }) => {
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;

  const { data, revalidate, isValidating } = useFetch(
    API_SERVER.requestAppointment.getList({
      ...params,
      ...(params?.createdTime
        ? formatDateFilterField(
            { createdStartTime: params.createdTime, createdEndTime: params.createdTime },
            'createdStartTime',
            'createdEndTime',
          )
        : {}),
      ...(params?.bookingDate
        ? formatDateFilterField(
            { bookingStartTime: params.bookingDate, bookingEndTime: params.bookingDate },
            'bookingStartTime',
            'bookingEndTime',
          )
        : {}),
    }),
  );

  return (
    <Paper
      style={{
        overflow: 'hidden',
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <DetailedFilterBox hookPagination={hookPagination} />
      <DetailedTable
        data={data?.content || []}
        total={data?.pagination?.totalElements || 0}
        hookPagination={hookPagination}
        loading={isValidating}
        revalidate={revalidate}
        revalidateAppointment={revalidateAppointment}
      />
    </Paper>
  );
};

export default TherapyDebtReport;
