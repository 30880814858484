import { Box, InputLabel, Typography } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import DialogCustom from '../../../common/component/DialogCustom';
import { some } from '../../../common/constants';
import SchemaForm from '../../../common/SchemaForm';
import { ISchemaFields, IUiFields } from '../../../common/SchemaForm/utils';
import { DRUG_OPTIONS } from '../../constants';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  formData?: some;
  list?: some[];
}

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, departments, appState } = formProps || {};
  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.code' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.codeEnter' }),
      propsWrapper: { xs: 6 },
    },
    registerNumber: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.registerNumber' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.registerNumberEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    drugName: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.drugName' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.drugNameEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    unit: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.numeratorUnit' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.unitEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    groupCode: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.groupCode' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.groupCodeEnter' }),
      propsWrapper: { xs: 6 },
    },
    department: {
      type: 'select',
      label: intl.formatMessage({ id: 'pharmacy.departmentCodepharmacy.departmentCode' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.departmentCodeEnter' }),
      rawOptions: true,
      options: departments,
      propsWrapper: { xs: 6 },
    },
    packagingFormat: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.packagingFormat' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.packagingFormatEnter' }),
      propsWrapper: { xs: 6 },
    },
    dosageForm: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.dosageForm' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.dosageFormEnter' }),
      propsWrapper: { xs: 6 },
    },
    expiredDate: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'expiredDate' }),
      placeholder: intl.formatMessage({ id: 'expiredDateEnter' }),
      propsWrapper: { xs: 6 },
    },
    produceCountry: {
      type: 'select',
      label: intl.formatMessage({ id: 'pharmacy.produceCountry' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.produceCountryEnter' }),
      options: appState.common.countryList,
      rawOptions: true,
      propsWrapper: { xs: 6 },
    },
    intendedRoute: {
      type: 'auto-complete',
      multiple: true,
      limitTags: 2,
      label: intl.formatMessage({ id: 'pharmacy.intendedRoute' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.intendedRouteEnter' }),
      options: appState.common.intendedRoutes,
      register: {
        required: true,
      },
      propsWrapper: { xs: 6 },
    },
    formType: {
      type: 'select',
      label: intl.formatMessage({ id: 'pharmacy.formType' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.formTypeEnter' }),
      register: {
        required: true,
      },
      options: DRUG_OPTIONS,
      propsWrapper: { xs: 6 },
    },
    ingredients: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.ingredients' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.ingredientsEnter' }),
      register: { required: true },
      propsWrapper: { xs: 6 },
    },
    amount: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.amount' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.amountEnter' }),
      propsWrapper: { xs: 6 },
    },
    producer: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.producer' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.producerEnter' }),
      propsWrapper: { xs: 6 },
    },
    producerAddress: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'address' }),
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      propsWrapper: { xs: 6 },
    },
    registerCompany: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.registerCompany' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.registerCompanyEnter' }),
      propsWrapper: { xs: 6 },
    },
    registerCompanyAddress: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'address' }),
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      propsWrapper: { xs: 6 },
    },
    distributor: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'pharmacy.distributor' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.distributorEnter' }),
      propsWrapper: { xs: 6 },
    },
    distributorAddress: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'address' }),
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      propsWrapper: { xs: 6 },
    },
    label_amount: {
      unregister: true,
      type: () => {
        return (
          <div style={{ height: 53 }}>
            <InputLabel>
              <FormattedMessage id="quantity" />
            </InputLabel>
            <Typography mt={1.5} textAlign="center">
              {1}
            </Typography>
          </div>
        );
      },
      propsWrapper: {
        xs: 2,
        style: { justifyContent: 'center', display: 'flex' },
      },
      noHelperText: true,
    },
    declarePrice: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'pharmacy.declarePrice' }),
      placeholder: intl.formatMessage({ id: 'pharmacy.declarePriceEnter' }),
      propsWrapper: { xs: 6 },
    },
    applicableDate: {
      type: 'datePicker',
      label: intl.formatMessage({ id: 'pharmacy.applicableDate' }),
      propsWrapper: { xs: 6 },
      noHelperText: true,
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'info' }),
      fields: [
        'code',
        'registerNumber',
        'drugName',
        'unit',
        'groupCode',
        'department',
        'packagingFormat',
        'dosageForm',
        'expiredDate',
        'produceCountry',
        'intendedRoute',
        'formType',
        'ingredients',
        'amount',
      ],
    },
    {
      id: 'price',
      title: intl.formatMessage({ id: 'pharmacy.organizationInfo' }),
      fields: [
        'producer',
        'producerAddress',
        'registerCompany',
        'registerCompanyAddress',
        'distributor',
        'distributorAddress',
      ],
    },
    {
      id: 'price',
      title: intl.formatMessage({ id: 'pharmacy.price' }),
      fields: ['declarePrice', 'applicableDate'],
    },
  ];
};

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  const departments = useSelector((state: AppState) => state.common.departments);
  return (
    <>
      <SchemaForm
        schema={{
          fields: fieldSchema,
          ui: ui,
          changeDataBeforeSubmit: (values) => {
            return { ...values, intendedRoute: values.intendedRoute?.map((v) => v.value) };
          },
        }}
        onSubmit={onSubmit}
        departments={departments}
        formData={formData && Object.keys(formData).length ? formData : undefined}
        onCancel={onClose}
        formId="supply-form"
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
      />
    </>
  );
};

const DrugDataFormDialogCSV: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;
  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 860 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'pharmacy.edit' : 'pharmacy.addNew'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default DrugDataFormDialogCSV;
