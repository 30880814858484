import { z } from 'zod';

export const AttachedDocumentSchema = z
  .object({
    id: z.number(),
    name: z.string(),
  })
  .partial();

export type AttachedDocument = z.infer<typeof AttachedDocumentSchema>;
