import { z } from 'zod';
import { PatientSchema } from './Patient';

export const DistributionCustomerByRevenueSchema = z
  .object({
    patientList: z.array(PatientSchema),
    fromAmountPaid: z.string(),
    toAmountPaid: z.string(),
    percent: z.number(),
  })
  .partial();

export type DistributionCustomerByRevenue = z.infer<typeof DistributionCustomerByRevenueSchema>;
