import { Box, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import MediCard from '../../../common/component/MediCard';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { some } from '../../../common/constants';

interface Props {
  data?: some;
  setFormData: (item: some) => void;
  onDelete: (item: some) => void;
  loading?: boolean;
}

const RoomTable: React.FunctionComponent<Props> = (props) => {
  const { data, setFormData, onDelete, loading } = props;

  const columns = React.useMemo(() => {
    return [
      {
        title: 'ID',
        dataIndex: 'id',
        width: '100px',
      },
      {
        title: 'room.name',
        dataIndex: 'name',
        width: '30%',
      },
      {
        title: 'room.note',
        dataIndex: 'description',
      },
      {
        title: 'action',
        align: 'right',
        width: 120,
        render: (record) => {
          return (
            <>
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  setFormData(record);
                }}
                style={{ margin: '0px 8px' }}
              >
                <EditIcon />
              </IconButtonTitle>
              <IconButtonTitle
                title="delete"
                size="small"
                onClick={() => {
                  onDelete(record);
                }}
              >
                <DeleteIcon />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns[];
  }, [setFormData, onDelete]);

  if (!data) {
    return null;
  }

  return (
    <MediCard
      style={{ margin: '8px 0px', color: 'white' }}
      title={
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="subtitle1" color="inherit">
              {data.name}
            </Typography>
          </Box>
          <Box display="flex">
            <IconButtonTitle
              title="create"
              size="small"
              color="inherit"
              onClick={() => setFormData({ categoryId: data.id })}
            >
              <NoteAddIcon />
            </IconButtonTitle>
          </Box>
        </Box>
      }
    >
      <Box padding={1}>
        <TableCustom
          dataSource={data?.items}
          loading={loading}
          columns={columns}
          caption={
            <Button
              size="large"
              fullWidth
              variant="text"
              startIcon={<NoteAddIcon />}
              color="primary"
              onClick={() => setFormData({ categoryId: data.id })}
            >
              <Typography variant="subtitle2">
                <FormattedMessage id="room.addNew" />
              </Typography>
            </Button>
          }
        />
      </Box>
    </MediCard>
  );
};

export default RoomTable;
