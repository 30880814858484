import { Delete } from '@mui/icons-material';
import { Button, createFilterOptions, Grid, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { uniq } from 'lodash';
import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import { mergeFieldName } from 'modules/common/SchemaForm/utils';
import { PRIMARY } from 'colors';
import { some } from 'modules/common/constants';
import { NumericFormatText } from 'modules/common/utils';
import { DEFAULT_INSTRUCTION_KEY } from '../detail/prescription/constants';

const filter = createFilterOptions<some>();

interface Props {
  readOnly?: boolean;
  arrayMethods: any;
}

function TableList(props: Props) {
  const { readOnly, arrayMethods } = props;
  const intl = useIntl();

  const { fields, remove } = arrayMethods;

  return (
    <>
      <Typography variant="h6" marginY={2}>
        <FormattedMessage id="encounterDetail.prescription" />
      </Typography>
      <Box display="flex" flexDirection="column" gap={1} marginTop={1}>
        {fields?.length > 0 ? (
          fields?.map((item: some, index: number) => {
            return (
              <Box key={item.key} display={'flex'} bgcolor={PRIMARY[50]} alignItems={'center'}>
                <Box padding={2} flex={1}>
                  <Box display="flex" flex={1} gap={2} marginBottom={1} flexWrap="wrap">
                    <Typography variant="body1">
                      <FormattedMessage id="encounterPrescription.groupDrug" />
                      :&nbsp;
                      <Typography variant="subtitle1" component="span">
                        {item.groupName}
                      </Typography>
                    </Typography>
                    <Typography variant="body1">
                      <FormattedMessage id="encounterPrescription.drugName" />
                      :&nbsp;
                      <Typography variant="subtitle1" component="span">
                        {item.name}
                      </Typography>
                    </Typography>
                    <Typography variant="body1">
                      <FormattedMessage id="encounterPrescription.ingredients" />
                      :&nbsp;
                      <Typography variant="subtitle1" component="span">
                        {item.ingredient || item.ingredients}
                      </Typography>
                    </Typography>
                    <Typography variant="body1">
                      <FormattedMessage id="sale.dose" />
                      :&nbsp;
                      <Typography variant="subtitle1" component="span">
                        {item.dose}
                      </Typography>
                    </Typography>
                    <Typography variant="body1">
                      <FormattedMessage id="encounterPrescription.unit" />
                      :&nbsp;
                      <Typography variant="subtitle1" component="span">
                        {item.numeratorUnit}
                      </Typography>
                    </Typography>
                    <Typography variant="body1">
                      <FormattedMessage id="encounterPrescription.price" />
                      :&nbsp;
                      <Typography variant="subtitle1" component="span">
                        {item.publicPrice !== undefined ? NumericFormatText(item.publicPrice) : ''}
                      </Typography>
                    </Typography>
                  </Box>
                  <Grid container spacing={1}>
                    <SchemaElement
                      fieldName={mergeFieldName({ name: 'quantity', parent: 'list', index })}
                      propsElement={{
                        type: 'text-field',
                        inputType: 'number',
                        label: intl.formatMessage({ id: 'quantity' }),
                        placeholder: intl.formatMessage({ id: 'enter' }),
                        propsWrapper: { xs: 2 },
                        readOnly: readOnly,
                        required: true,
                      }}
                    />

                    <SchemaElement
                      fieldName={mergeFieldName({ name: 'intendedRoute', parent: 'list', index })}
                      propsElement={{
                        type: 'auto-complete',
                        label: intl.formatMessage({ id: 'encounterPrescription.intendedRoute' }),
                        placeholder: intl.formatMessage({ id: 'encounterPrescription.intendedRouteEnter' }),
                        options: item.intendedRouteOption || [],
                        propsWrapper: { xs: 4 },
                        readOnly: readOnly,
                        register: {
                          required: true,
                        },
                      }}
                    />
                    <SchemaElement
                      fieldName={mergeFieldName({ name: 'instruction', parent: 'list', index })}
                      propsElement={{
                        type: 'auto-complete',
                        label: intl.formatMessage({ id: 'encounterPrescription.instruction' }),
                        placeholder: intl.formatMessage({ id: 'encounterPrescription.instructionEnter' }),
                        propsWrapper: { xs: 6 },
                        options: uniq(
                          [
                            ...DEFAULT_INSTRUCTION_KEY.map((key) => intl.formatMessage({ id: key })),
                            ...(localStorage.getItem('instruction')?.split(',') || []),
                            item.instruction,
                          ].filter(Boolean),
                        ),
                        freeSolo: true,
                        filterOptions: (options, params) => {
                          const filtered = filter(options, params);
                          const { inputValue } = params;
                          const isExisting = options.some((option) => inputValue === option);
                          if (inputValue !== '' && !isExisting) {
                            filtered.push(inputValue);
                          }
                          return filtered;
                        },
                        readOnly: readOnly,
                        register: {
                          required: true,
                        },
                        getOptionLabel: (option) => option,
                        renderOption: (props, option, state) => (
                          <li {...props}>
                            <Box
                              display={'flex'}
                              alignItems="center"
                              width={'100%'}
                              sx={{
                                minHeight: 32,
                                '&:hover': {
                                  '& .clear-btn': {
                                    display: 'flex',
                                  },
                                },
                              }}
                            >
                              <Typography flex={1}>{option}</Typography>
                              &nbsp;
                            </Box>
                          </li>
                        ),
                      }}
                    />
                  </Grid>
                </Box>
                {!readOnly && (
                  <Box alignSelf={'stretch'} height="auto" borderLeft={4} borderColor="background.paper">
                    <Button
                      title="delete"
                      onClick={() => {
                        remove(index);
                      }}
                      variant="text"
                      style={{ height: '100%' }}
                    >
                      <Delete />
                    </Button>
                  </Box>
                )}
              </Box>
            );
          })
        ) : (
          <Box display="flex" justifyContent={'center'} fontStyle="italic">
            <FormattedMessage id="encounterPrescription.noPrescription" />
          </Box>
        )}
      </Box>
    </>
  );
}

export default memo(TableList);
