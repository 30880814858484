import { Paper, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../common/api';
import {
  INDICATION_REQUEST_STATUS,
  INDICATION_REQUEST_TYPE,
  some,
  SYSTEM_CATEGORY_SCOPE,
} from '../../common/constants';
import usePaginationHook from '../../common/hook/usePaginationHook';
import FilterIndicationBox from '../component/therapy/FilterEncounterBox/index';
import IndicationTherapyList from '../component/therapy/IndicationTherapyList';
import { formatDateFilterField } from '../../common/utils';
import IndicationPrintForm from '../component/indication/IndicationPrintForm';
import { useFetch } from 'modules/common/hook';

interface Props {}

export const INDICATION_REQUEST_STATUS_OPTIONS = [
  INDICATION_REQUEST_STATUS.REQUESTED,
  INDICATION_REQUEST_STATUS.FINISHED,
];

const LabDoctorIndicationTherapyList: React.FunctionComponent<Props> = () => {
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;

  const [selectionIndication, setSelectionIndication] = React.useState<some[]>([]);
  const [open, setOpen] = React.useState(false);

  const { data: pageData, isValidating } = useFetch(
    API_SERVER.indicationRequest.getList({
      ...formatDateFilterField(params, 'fromDate', 'toDate'),
      scope: SYSTEM_CATEGORY_SCOPE.laboratory,
      type: INDICATION_REQUEST_TYPE.THERAPY_SESSION,
      statusList: params?.status?.join(',') || null,
    }),
  );

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterIndicationBox
        hookPagination={hookPagination}
        onPrint={() => {
          setOpen(true);
        }}
        selectionIndication={selectionIndication}
        pagin={
          <>
            <Typography variant="h6">
              <FormattedMessage id="labDoctor.list" />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage
                id="foundResult"
                values={{
                  num: (
                    <Typography variant="inherit" component="span" color="primary">
                      {pageData?.content?.length || 0}
                    </Typography>
                  ),
                  total: (
                    <Typography variant="inherit" component="span" color="primary">
                      {pageData?.pagination?.totalElements || 0}
                    </Typography>
                  ),
                }}
              />
            </Typography>
          </>
        }
      />
      <IndicationTherapyList
        data={pageData?.content}
        total={pageData?.pagination?.totalElements || 0}
        loading={isValidating}
        hookPagination={hookPagination}
        selectionIndication={selectionIndication}
        setSelectionIndication={setSelectionIndication}
      />
      <IndicationPrintForm open={open} onClose={() => setOpen(false)} indicationList={selectionIndication} />
    </Paper>
  );
};

export default LabDoctorIndicationTherapyList;
