import { Box, Collapse, Radio, RadioGroup, Stack, Typography, styled } from '@mui/material';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import SwitchElement from 'modules/common/SchemaForm/element/switch/SwitchElement';
import { Controller, useFormContext } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { SCHEDULE_RANGE_UNIT, ScheduleType } from './constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

const TextStyled = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  whiteSpace: 'nowrap',
});

const TitleStyled = styled(Typography)({
  fontFamily: 'Roboto',
  fontSize: '20px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  whiteSpace: 'nowrap',
});

const ScheduleSetting = () => {
  const { control, watch } = useFormContext<{
    autoSchedule: boolean;
    byRange: boolean;
    byRangeNumber: number;
    byRangeUnit: keyof typeof SCHEDULE_RANGE_UNIT;
    byDayOfMonth: boolean;
    byDayOfMonthDay: number;
    amountOfAppointment: number;
    scheduleType: ScheduleType;
  }>();
  const autoSchedule = watch('autoSchedule');
  const { intl } = useGeneralHook();

  return (
    <Box marginLeft={2} marginTop={1}>
      <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
        <TitleStyled>
          <FormattedMessage id="appointment.reception.label.autoSchedule" />
        </TitleStyled>
        <Controller
          control={control}
          name="autoSchedule"
          render={({ field: { onChange, value } }) => <SwitchElement onChange={onChange} value={value || false} />}
        />
      </Stack>

      <Collapse in={autoSchedule}>
        <Controller
          control={control}
          name="scheduleType"
          render={({ field: { onChange, value: type } }) => {
            return (
              <RadioGroup
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={type}
                onChange={onChange}
              >
                <Stack direction="row" justifyContent="start" alignItems="center" gap={1}>
                  <Radio value="byRange" />
                  <TextStyled>
                    <FormattedMessage id="appointment.reception.label.afterEach" />
                  </TextStyled>
                  <SchemaElement
                    fieldName="byRangeNumber"
                    propsElement={{
                      type: 'number-field',
                      disabled: type === 'byDayOfMonth',
                      xs: 1.5,
                      register: {
                        required: type === 'byRange' && autoSchedule ? true : false,
                        validate: (value) =>
                          parseInt(value) <= 0
                            ? intl.formatMessage({ id: 'appointment.receptopm.byRangeNumber.error.largeThanZero' })
                            : true,
                      },
                      propsWrapper: {
                        sx: {
                          '& .MuiFormHelperText-root .MuiTypography-root ': {
                            textOverflow: 'unset',
                            overflow: 'unset',
                          },
                        },
                      },
                      sx: { '& .MuiOutlinedInput-input': { textAlign: 'center' } },
                    }}
                  />
                  <SchemaElement
                    fieldName="byRangeUnit"
                    propsElement={{
                      type: 'select',
                      disabled: type === 'byDayOfMonth',
                      xs: 2,
                      options: Object.values(SCHEDULE_RANGE_UNIT),
                      register: {
                        required: type === 'byRange' && autoSchedule ? true : false,
                      },
                      defaultValue: 'month',
                      disableClearBtn: true,
                    }}
                  />
                </Stack>
                <Stack direction="row" justifyContent="start" alignItems="center" gap={1} mt={1}>
                  <Radio value="byDayOfMonth" />
                  <TextStyled>
                    <FormattedMessage id="appointment.reception.label.onDay" />
                  </TextStyled>
                  <SchemaElement
                    fieldName="byDayOfMonthDay"
                    propsElement={{
                      type: 'number-field',
                      disabled: type === 'byRange',
                      xs: 1.5,
                      register: {
                        required: type === 'byDayOfMonth' && autoSchedule ? true : false,
                        validate: (value) =>
                          parseInt(value) >= 1 && parseInt(value) <= 31
                            ? true
                            : intl.formatMessage({ id: 'appointment.receptopm.byRangeNumber.error.dayOfMonth' }),
                      },
                      propsWrapper: {
                        sx: {
                          '& .MuiFormHelperText-root .MuiTypography-root ': {
                            textOverflow: 'unset',
                            overflow: 'unset',
                          },
                        },
                      },
                      sx: {
                        '& .MuiOutlinedInput-input': { textAlign: 'center' },
                      },
                    }}
                  />
                  <TextStyled>
                    <FormattedMessage id="appointment.reception.label.everyMonth" />
                  </TextStyled>
                </Stack>
              </RadioGroup>
            );
          }}
        />
        <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={1.5} mt={1}>
          <TextStyled>
            <FormattedMessage id="appointment.reception.label.sumOfCreatedAppointment" />
          </TextStyled>
          <SchemaElement
            fieldName="amountOfAppointment"
            propsElement={{
              type: 'number-field',
              xs: 3,
              register: {
                required: autoSchedule ? true : false,
              },
              sx: { '& .MuiOutlinedInput-input': { textAlign: 'center' } },
            }}
          />
        </Stack>
      </Collapse>
    </Box>
  );
};

export default ScheduleSetting;
