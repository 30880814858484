import { TablePagination, TablePaginationBaseProps, TablePaginationTypeMap } from '@mui/material';
import React from 'react';
import { useIntl } from 'react-intl';

interface Props {
  children: JSX.Element;
  totalElements: number;
  paginationProps?: Omit<
    TablePaginationTypeMap<{}, React.JSXElementConstructor<TablePaginationBaseProps>>['props'],
    'component' | 'count'
  >;
}

export const Pagination = ({ children, paginationProps, totalElements }: Props) => {
  const intl = useIntl();
  if (!paginationProps) return children;
  return (
    <>
      {children}
      <TablePagination
        rowsPerPageOptions={[10, 20, 50, 100]}
        component="div"
        labelRowsPerPage={intl.formatMessage({ id: 'component.table.title.show' })}
        labelDisplayedRows={({ from, to, count }) => `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`}
        SelectProps={{
          sx: {
            '& .MuiTablePagination-select, & .MuiTablePagination-select:focus': {
              borderRadius: '5px',
            },
          },
        }}
        {...paginationProps}
        count={totalElements}
      />
    </>
  );
};
