import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Box, Collapse, Divider, Grid, Tab, Tabs, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import { PAYMENT_PLAN } from '../../../../common/constants';
import { VN_CODE } from '../../../../common/redux/commonReducer';
import {
  FieldsType,
  ILayoutFields,
  ISchemaFields,
  ISchemaForm,
  IUiFields,
  SchemaType,
} from '../../../../common/SchemaForm/utils';
import { CATEGORY_INSURANCE } from '../../../../reception/constants';
import { NumericFormatText } from 'modules/common/utils';
import { Encounter } from 'modules/schema';
import { ENCOUNTER_STATUS, GENDER, PAYMENT_STATUS } from 'modules/common/apiConstants';

interface SchemaProps {
  encounter: Encounter;
}

export const fieldSchema: ISchemaFields<SchemaProps> = ({ valuesField, methods, formProps }) => {
  const { intl, appState } = formProps || {};
  return {
    name: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'enterPatientName' }),
      label: intl.formatMessage({ id: 'patientName' }),
      propsWrapper: { xs: 4 },
      required: true,
    },
    dob: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'birthday' }),
      propsWrapper: { xs: 2.5 },
      disableFuture: true,
      openTo: 'year',
      tooltipError: true,
      required: true,
    },
    gender: {
      type: 'select',
      placeholder: intl.formatMessage({ id: 'pickGender' }),
      label: intl.formatMessage({ id: 'gender' }),
      propsWrapper: { xs: 4 },
      options: [GENDER.FEMALE, GENDER.MALE],
      required: true,
    },
    job: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'job' }),
      placeholder: intl.formatMessage({ id: 'jobEnter' }),
      propsWrapper: { xs: 4 },
    },
    identifierCode: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'enterIdentification' }),
      label: intl.formatMessage({ id: 'identification' }),
      propsWrapper: { xs: 6 },
      defaultValue: '',
      inputType: 'number',
    },
    mobilePhone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      label: intl.formatMessage({ id: 'telephone' }),
      propsWrapper: { xs: 4 },
      inputType: 'number',
    },
    email: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'emailAddress' }),
      placeholder: intl.formatMessage({ id: 'enterEmail' }),
      propsWrapper: { xs: 6 },
    },
    jobAddress: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'jobAddressEnter' }),
      label: intl.formatMessage({ id: 'jobAddress' }),
      propsWrapper: { xs: 4 },
    },
    ethnic: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'ethnic' }),
      placeholder: intl.formatMessage({ id: 'ethnicEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.ethnicList,
    },
    nationality: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'nation' }),
      placeholder: intl.formatMessage({ id: 'nationEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.countryList.filter((country) => {
        return valuesField.ethnic?.value === '55' ? country.value !== VN_CODE : true;
      }),
    },
    province: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'province' }),
      placeholder: intl.formatMessage({ id: 'provinceEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.provinceData,
    },
    district: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'district' }),
      placeholder: intl.formatMessage({ id: 'districtEnter' }),
      propsWrapper: { xs: 4 },
      options: valuesField.province?.districts,
    },
    address: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      label: intl.formatMessage({ id: 'address' }),
      propsWrapper: { xs: 6.5 },
      required: true,
    },
    // Contact info
    contactName: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'contactNameEnter' }),
      label: intl.formatMessage({ id: 'contactName' }),
      propsWrapper: { xs: 4 },
    },
    contactTelephone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'contactTelephoneEnter' }),
      label: intl.formatMessage({ id: 'contactTelephone' }),
      propsWrapper: { xs: 4 },
    },
    contactAddress: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'contactAddressEnter' }),
      label: intl.formatMessage({ id: 'contactAddress' }),
      propsWrapper: { xs: 4 },
    },
    // Step 2
    priceType: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'encounterList.groupPriceType' }),
      placeholder: intl.formatMessage({ id: 'encounterList.groupPriceTypeEnter' }),
      propsWrapper: { xs: 6 },
      options: [],
      isOptionEqualToValue: (selected, value) => selected && value && selected.id === value.id,
      getOptionLabel: (option) => option.title || ' ',
      required: true,
    },
    systemCategory: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'exam.packageName' }),
      placeholder: intl.formatMessage({ id: 'encounterList.groupPriceTypeEnter' }),
      propsWrapper: { xs: 6 },
      required: true,
    },
    service: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'encounterList.serviceName' }),
      placeholder: intl.formatMessage({ id: 'encounterList.serviceNameEnter' }),
      propsWrapper: { xs: 6 },
      required: true,
    },
    physicsRoom: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'encounterList.room' }),
      propsWrapper: { xs: 6 },
      required: true,
    },
    doctor: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'examDoctor' }),
      placeholder: intl.formatMessage({ id: 'examDoctorPick' }),
      propsWrapper: { xs: 6 },
      required: true,
    },
    paymentPlan: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'paymentCategory' }),
      placeholder: intl.formatMessage({ id: 'paymentCategoryPick' }),
      propsWrapper: { xs: 6 },
      required: true,
    },
    reason: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'encounterList.reason' }),
      placeholder: intl.formatMessage({ id: 'encounterList.reasonEnter' }),
      propsWrapper: { xs: 12 },
      multiline: true,
      rows: 3,
    },
  } as SchemaType;
};

const ui: IUiFields<SchemaProps> = ({ formProps, valuesField }) => {
  const { intl } = formProps || {};

  return [
    {
      id: 'patientInfo',
      title: valuesField?.patientCode && (
        <Box marginBottom={2} display="flex" alignItems="baseline">
          <Typography variant="subtitle2" color="textSecondary">
            <FormattedMessage id="patientCodeShort" />
            :&nbsp;{valuesField?.patientCode}
          </Typography>
        </Box>
      ),
      fields: ['patientCode', 'name', 'mobilePhone', 'gender', 'dob', 'address', 'email', 'identifierCode'],
    },
    {
      id: 'moreInfo',
      fields: ['job', 'jobAddress', 'ethnic', 'nationality', 'province', 'district'],
    },
    {
      id: 'contactInfo',
      fields: ['contactName', 'contactTelephone', 'contactAddress'],
    },
    {
      id: 'insuranceNumber',
      title: intl.formatMessage({ id: 'insuranceInfo' }),
      fields: ['insuranceNumber'],
    },
    {
      id: 'step-2',
      fields: [
        'enterReason',
        'priceType',
        'systemCategory',
        'service',
        'physicsRoom',
        'insurePercent',
        'doctor',
        'paymentPlan',
        'reason',
      ],
    },
  ] as FieldsType[];
};

const LayoutSchema: ILayoutFields<SchemaProps> = (props) => {
  const { listElement, valuesField, formProps } = props;
  const { state, setState, encounter } = formProps;

  const step2 = (
    <>
      <Box padding={2}>
        {listElement[4]}
        <Box display="flex" justifyContent="flex-end" marginTop={2}>
          <Grid container spacing={1} style={{ width: '50%', alignSelf: 'flex-end' }}>
            <Grid item xs={6}>
              <Typography variant="subtitle1" color="primary">
                <FormattedMessage id="totalPrice" />
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'end' }}>
              <Typography variant="subtitle1">
                {encounter?.payment?.status === PAYMENT_STATUS.PAID.value &&
                encounter?.status === ENCOUNTER_STATUS.FINISHED.value
                  ? 0
                  : NumericFormatText(valuesField?.price || 0)}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6" color="primary">
                <FormattedMessage id="patientPay" />
              </Typography>
            </Grid>
            <Grid item xs={6} style={{ textAlign: 'end' }}>
              <Typography variant="subtitle1">
                {encounter?.payment?.status === PAYMENT_STATUS.PAID.value &&
                encounter?.status === ENCOUNTER_STATUS.FINISHED.value
                  ? 0
                  : NumericFormatText(valuesField.paymentPlanValue === PAYMENT_PLAN.FREE ? 0 : valuesField?.price || 0)}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );

  const step1 = (
    <>
      <Box padding={2}>
        {listElement[0]}
        <Box marginTop={2}>
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography variant="h6">
              <FormattedMessage id="moreInfo" />
            </Typography>
            <Divider style={{ flex: 1, marginLeft: 16 }} />
            <IconButtonTitle
              onClick={() => {
                setState((old) => ({ ...old, moreInfoCollapse: !old.moreInfoCollapse }));
              }}
            >
              <KeyboardArrowDownIcon
                style={{
                  transform: state.moreInfoCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'all 200ms',
                  fontSize: 24,
                }}
              />
            </IconButtonTitle>
          </Box>
          <Collapse in={state.moreInfoCollapse}>{listElement[1]}</Collapse>
        </Box>
        <Box marginTop={2}>
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography variant="h6">
              <FormattedMessage id="contactInfoPerson" />
            </Typography>
            <Divider style={{ flex: 1, marginLeft: 16 }} />
            <IconButtonTitle
              onClick={() => {
                setState((old) => ({ ...old, contactInfoPersonCollapse: !old.contactInfoPersonCollapse }));
              }}
            >
              <KeyboardArrowDownIcon
                style={{
                  transform: state.contactInfoPersonCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'all 200ms',
                  fontSize: 24,
                }}
              />
            </IconButtonTitle>
          </Box>
          <Collapse in={state.contactInfoPersonCollapse}>{listElement[2]}</Collapse>
        </Box>
      </Box>
      {(valuesField?.categoryInsurance === CATEGORY_INSURANCE.insured ||
        valuesField?.categoryInsurance === CATEGORY_INSURANCE.noCard) && (
        <Box padding={2} paddingTop={0} display="flex" justifyContent={'flex-end'}>
          {listElement[3]}
        </Box>
      )}
    </>
  );
  return (
    <>
      <Tabs
        style={{ flex: 1 }}
        value={state.tab || 0}
        onChange={(e, newValue) => {
          setState({ tab: newValue });
        }}
        variant="scrollable"
        indicatorColor="secondary"
        textColor="secondary"
      >
        {['patientInfo', 'encounterInfo'].map((tab: string, index: number) => {
          return (
            <Tab
              key={index}
              value={index}
              style={{ minWidth: 100 }}
              label={
                <Typography variant="subtitle2">
                  <FormattedMessage id={tab} />
                </Typography>
              }
            />
          );
        })}
      </Tabs>
      <Divider />
      {state.tab === 1 ? step2 : step1}
    </>
  );
};

export const receivePatientFormSchema: ISchemaForm<SchemaProps> = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
