import { Box, styled } from '@mui/system';
import { Divider, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import PatientInfo from './PatientInfo';
import TherapyExtraInfo from './TherapyExtraInfo';
import DoctorSign from './DoctorSign';
import IndicationRequestTable from './IndicationRequestTable';
import { INDICATION_REQUEST_PRINT_TYPE } from './constant';
import { SYSTEM_CATEGORY_SCOPE_LABEL } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { IndicationRequest } from 'modules/schema';

interface Props {
  indicationList: IndicationRequest[];
  printType: INDICATION_REQUEST_PRINT_TYPE;
}

const PrintFormTitle = styled(Typography)({
  color: '#263238',
  textAlign: 'center',
  fontSize: '28px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '36px',
  textTransform: 'uppercase',
});

const DividerStyled = styled(Divider)({
  marginTop: '20px',
  marginBottom: '10px',
});

const Body = ({ indicationList, printType }: Props) => {
  const { intl } = useGeneralHook();
  const systemCategoryScopeLabel = intl.formatMessage({
    id: SYSTEM_CATEGORY_SCOPE_LABEL.find((scope) => scope.value === indicationList[0]?.scope)?.label || ' ',
  });
  return (
    <Box sx={{ padding: '9px 24px 24px 24px' }}>
      <Stack marginBottom={3}>
        <PrintFormTitle>
          <FormattedMessage
            id="therapy.therapyDetail.indicationRequest.printForm.title"
            values={{
              serviceName: systemCategoryScopeLabel,
            }}
          />
        </PrintFormTitle>
      </Stack>
      <PatientInfo />
      <DividerStyled />
      <TherapyExtraInfo />
      <IndicationRequestTable printType={printType} indicationRequests={indicationList} />
      <DoctorSign />
    </Box>
  );
};

export default Body;
