import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import TextValue from './TextValue';

const TherapyExtraInfo = () => {
  return (
    <Stack direction="row" justifyContent="space-between">
      <Box flex={1.5}>
        <TextValue label="therapyList.header.label.diagnosis" value={' '} labelWidth="190px" />
        <TextValue
          label="therapy.therapyDetail.indicationRequest.printForm.timeRequest.title"
          value=""
          labelWidth="190px"
        />
      </Box>
    </Stack>
  );
};
export default TherapyExtraInfo;
