import Stack from '@mui/material/Stack';
import { useMemo } from 'react';
import { Box, Chip } from '@mui/material';
import { useFetchServices, useFetchTherapyDefinitions } from './fetcher';
import SchemaElement from 'modules/common/SchemaForm/SchemaElement';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useDiagnosisDialogSelector } from './state';
import { SYSTEM_CATEGORY_SCOPE_LABEL } from 'modules/common/constants';
import { useFormContext } from 'react-hook-form';

type Option = { value?: number; label?: string; scope?: string };

const QuickServices = () => {
  const { intl } = useGeneralHook();
  const services = useDiagnosisDialogSelector.use.services();
  const addService = useDiagnosisDialogSelector.use.addService();
  const deleteService = useDiagnosisDialogSelector.use.deleteService();
  const addTherapyDefinition = useDiagnosisDialogSelector.use.addTherapyDefinition();
  const { setValue, setFocus } = useFormContext();
  const therapyDefinitionsAdded = useDiagnosisDialogSelector.use.therapyDefinitions();
  const deleteTherapyDefinition = useDiagnosisDialogSelector.use.deleteTherapyDefinition();

  //fetch
  const { data: therapyDefinitions } = useFetchTherapyDefinitions();
  const { data: indicationServices } = useFetchServices();

  const indicationOptions = useMemo(() => {
    return indicationServices?.reduce<Option[]>((array, cur) => {
      return [
        ...array,
        ...(cur?.items?.map((service) => ({
          value: service?.id,
          label: service?.name,
          scope: intl.formatMessage({ id: SYSTEM_CATEGORY_SCOPE_LABEL.find((ele) => ele.value === cur?.scope)?.label }),
        })) || []),
      ];
    }, []);
  }, [indicationServices, intl]);
  const therapyDefinitionOptions = useMemo(() => {
    return therapyDefinitions?.reduce<Option[]>((array, cur) => {
      return [
        ...array,
        ...(cur?.items?.map((therapyDefinition) => ({
          value: therapyDefinition?.id,
          label: therapyDefinition?.name,
          scope: cur?.name,
        })) || []),
      ];
    }, []);
  }, [therapyDefinitions]);

  return (
    <Stack direction="row" justifyContent="center" alignItems="flex-start" gap={3}>
      <Box flex={1}>
        <SchemaElement
          fieldName="serviceId"
          propsElement={{
            type: 'auto-complete',
            label: intl.formatMessage({ id: 'dental.dentalDetail.statusAndDiagnosis.input.service.label' }),
            placeholder: intl.formatMessage({ id: 'dental.dentalDetail.statusAndDiagnosis.input.service.placeholder' }),
            options: indicationOptions,
            groupBy: (option) => option.scope,
            onChange: (option) => {
              if (option) {
                addService({ id: option.value, name: option.label });
              }
            },
          }}
        />
        <Stack direction="row" gap={1} flexWrap="wrap">
          {services.map((service) => (
            <Chip label={service.name} onDelete={() => deleteService(service.id)} />
          ))}
        </Stack>
      </Box>
      <Box flex={1}>
        <SchemaElement
          fieldName="therapyDefinition"
          propsElement={{
            type: 'auto-complete',
            label: intl.formatMessage({ id: 'dental.dentalDetail.statusAndDiagnosis.input.therapyDefinition.label' }),
            placeholder: intl.formatMessage({
              id: 'dental.dentalDetail.statusAndDiagnosis.input.therapyDefinition.placeholder',
            }),
            options: therapyDefinitionOptions,
            onChange: (option) => {
              addTherapyDefinition({ id: option.value, name: option.label });
              setFocus('serviceId');
              setValue('therapyDefinition', null);
            },
            groupBy: (option) => option.scope,
          }}
        />
        <Stack direction="row" gap={1} flexWrap="wrap">
          {therapyDefinitionsAdded.map((therapyDefinition) => (
            <Chip label={therapyDefinition.name} onDelete={() => deleteTherapyDefinition(therapyDefinition.id)} />
          ))}
        </Stack>
      </Box>
    </Stack>
  );
};

export default QuickServices;
