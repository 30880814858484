import * as React from 'react';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFilterEncounterForm } from './schema';
import { API_SERVER } from 'modules/common/api';
import { useFetch } from 'modules/common/hook/useFetch';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
interface Props {
  pagin?: React.ReactNode;
  hookPagination: HookPaginationProps;
}

const FilterEncounterBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, pagin } = props;
  const { params, setParams, clearParams } = hookPagination;
  //TODO: hard code name params ==> need to change to code
  const { data: physicsRooms } = useFetch(
    API_SERVER.systemCategory.getList({ type: 'GROUP', scope: 'PHYSICS_ROOM', name: 'Khám bệnh', isShowList: true }),
  );
  const physicsRoomOptions = React.useMemo(
    () => physicsRooms?.[0]?.items?.map((physicsRoom) => ({ value: physicsRoom?.id, label: physicsRoom?.name })),
    [physicsRooms],
  );

  return (
    <SchemaForm
      schema={schemaFilterEncounterForm}
      onSubmit={setParams}
      formData={params}
      clearParams={() =>
        clearParams({
          status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
        })
      }
      hideSubmitButton
      pagin={pagin}
      physicsRoomOptions={physicsRoomOptions}
    />
  );
};

export default FilterEncounterBox;
