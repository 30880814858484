import { Box, Table, TableBody, TableContainer, TableHead, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import MediCard from 'modules/common/component/MediCard';
import { displayRange } from 'modules/common/utils';

interface Props {
  data: some;
}

const LabTable: React.FunctionComponent<Props> = (props) => {
  const { data } = props;
  return (
    <MediCard title={data?.indicationRequest?.serviceName} rawTitle style={{ marginBottom: 16 }}>
      <Box display="flex" padding={1}>
        <TableContainer>
          <Table stickyHeader>
            <TableHead>
              <TableCustomRow>
                <TableHeaderCell style={{ width: 54, textAlign: 'center' }}>
                  <FormattedMessage id="stt" />
                </TableHeaderCell>
                <TableHeaderCell width="20%">
                  <FormattedMessage id="indication.nameTest" />
                </TableHeaderCell>
                <TableHeaderCell align="right" width="15%">
                  <FormattedMessage id="indication.result" />
                </TableHeaderCell>
                <TableHeaderCell width="8%">
                  <FormattedMessage id="indication.unit" />
                </TableHeaderCell>
                <TableHeaderCell>
                  <FormattedMessage id="indication.referenceValue" />
                </TableHeaderCell>
              </TableCustomRow>
            </TableHead>
            <TableBody>
              {data?.indicationLaboratoryList?.map((row, index) => (
                <TableCustomRow>
                  <TableCustomCell style={{ textAlign: 'center' }}>{index + 1}</TableCustomCell>
                  <TableCustomCell>{row?.laboratoryReferenceName}</TableCustomCell>
                  <TableCustomCell align="right">
                    <Typography variant="body1">{row?.result}</Typography>
                  </TableCustomCell>
                  <TableCustomCell>{row.laboratoryReferenceDetailObject?.unit}</TableCustomCell>
                  <TableCustomCell>
                    {row?.laboratoryReferenceDetailObject?.laboratoryConditionList
                      ?.map((v) =>
                        v.name
                          ? `${v.name} : ${displayRange(v.highIndex, v.lowIndex)}`
                          : `${displayRange(v.highIndex, v.lowIndex)}`,
                      )
                      .join('; ')}
                  </TableCustomCell>
                </TableCustomRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </MediCard>
  );
};

export default LabTable;
