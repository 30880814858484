import { ExpandLess, GridOn, Search } from '@mui/icons-material';
import { Box, Collapse, InputAdornment } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import SchemaForm from '../../../common/SchemaForm';
import { INVENTORY_EXPORT_STATUS, INVENTORY_EXPORT_TYPE } from '../inventory/constant';
import { axiosThunk } from '../../../common/redux/axios';
import fileDownload from 'js-file-download';
import { useFetch } from 'modules/common/hook';

interface Props {
  title?: React.ReactNode;
  hookPagination: HookPaginationProps;
  onCreate: () => void;
}

const FilterBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, title } = props;
  const { params, setParams, clearParams } = hookPagination;
  const { dispatch, locationId, API_SERVER, openSnackbar, intl } = useGeneralHook();

  const { data: medications = [] } = useFetch(API_SERVER.medication.inventories(locationId), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
    dedupingInterval: 60000,
  });

  const exportExcelReport = async (valueFields) => {
    openSnackbar({ message: intl.formatMessage({ id: 'common.message.processing' }) });
    try {
      const response = await dispatch(
        axiosThunk({
          url: API_SERVER.report.exportReport(valueFields),
          method: 'get',
          responseType: 'blob',
        }),
      );
      let fileName = response.headers['content-disposition']?.split('filename=')[1]?.split(';')[0];
      const effectiveFileName = fileName || 'inventory_export_report.xlsx';
      fileDownload(response.data, effectiveFileName);
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
    }
  };

  return (
    <SchemaForm
      schema={{
        fields: ({ formProps: { intl, clearParams, appState, state, setState }, valuesField }) => {
          return {
            code: {
              type: 'text-field',
              placeholder: intl.formatMessage({ id: 'inventory.searchByExportCode' }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="inherit" />
                  </InputAdornment>
                ),
              },
              showClearBtn: true,
              propsWrapper: { xs: true },
            },
            search: {
              type: 'submitButton',
              color: 'inherit',
              label: intl.formatMessage({ id: 'search' }),
              propsWrapper: { xs: undefined },
            },
            export: {
              type: 'button',
              label: intl.formatMessage({ id: 'inventory.export.excel.button' }),
              propsWrapper: { xs: undefined },
              startIcon: <GridOn />,
              onClick: () => {
                setParams(valuesField);
                exportExcelReport(valuesField);
              },
            },
            expand: {
              type: 'button',
              endIcon: (
                <ExpandLess
                  style={{
                    transition: '0.2s all',
                    transform: state?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              ),
              color: 'primary',
              variant: 'text',
              onClick: () => setState({ expand: !state.expand }),
              label: intl.formatMessage({ id: 'advance' }),
              propsWrapper: { xs: undefined },
            },
            medicationId: {
              type: 'auto-complete',
              label: intl.formatMessage({ id: 'inventory.drugName' }),
              placeholder: intl.formatMessage({ id: 'select' }),
              options: medications?.map((v) => ({
                id: v.id,
                name: v.name,
                code: v.code,
                manufacturer: v.manufacturer,
                ingredient: v.ingredient,
              })),

              getOptionLabel: (option) => {
                return `${option.id} - ${option.name} (${option.ingredient})`;
              },
              propsWrapper: { xs: true },
            },
            type: {
              type: 'select',
              label: intl.formatMessage({ id: 'inventory.exportType' }),
              hasAllOptions: true,
              options: INVENTORY_EXPORT_TYPE,
              propsWrapper: { xs: true },
            },
            status: {
              type: 'select',
              label: intl.formatMessage({ id: 'status' }),
              hasAllOptions: true,
              options: INVENTORY_EXPORT_STATUS,
              propsWrapper: { xs: true },
            },
            lot: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'inventory.lot' }),
              placeholder: intl.formatMessage({ id: 'inventory.lotEnter' }),
              propsWrapper: { xs: true },
            },
            exportDateFrom: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'sellingProduct.label.createdDateFrom' }),
              propsWrapper: { xs: true, paddingBottom: 1 },
              maxDate: valuesField.exportDateTo && moment(valuesField.exportDateTo).toDate(),
              register: {
                validate: (value) => {
                  return value && moment(value).isAfter(valuesField?.exportDateTo)
                    ? intl.formatMessage({ id: 'validation.invalidDate' })
                    : true;
                },
              },
            },
            exportDateTo: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'sellingProduct.label.createdDateTo' }),
              propsWrapper: { xs: true, paddingBottom: 1 },
              minDate: valuesField.exportDateFrom && moment(valuesField.exportDateFrom).toDate(),
              register: {
                validate: (value) => {
                  return value && moment(value).isBefore(valuesField?.exportDateFrom)
                    ? intl.formatMessage({ id: 'validation.invalidDate' })
                    : true;
                },
              },
            },
          };
        },
        ui: () => {
          return [
            {
              id: 'layout',
              propsGridContainer: { spacing: 1, justifyContent: 'flex-end' },
              fields: ['code', 'search', 'expand', 'export'],
            },
            {
              id: 'expand',
              fields: [
                'medicationId',
                // 'type',
                'status',
                'lot',
                'exportDateFrom',
                'exportDateTo',
              ],
            },
          ];
        },
        layout: ({ formProps: { state }, listElement }) => {
          return (
            <>
              <Box display="flex" justifyContent={'space-between'}>
                <Box>{title}</Box>
                <Box width={750}>{listElement[0]}</Box>
              </Box>
              <Collapse in={state?.expand} style={{ marginTop: 16 }}>
                {listElement[1]}
              </Collapse>
            </>
          );
        },
      }}
      onSubmit={setParams}
      formData={params}
      clearParams={clearParams}
      hideSubmitButton
    />
  );
};

export default FilterBox;
