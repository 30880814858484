import { createSelectors } from 'modules/common/utils';
import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { DiagnosticImage } from 'modules/schema/DiagnosticImage';

interface IDiagnosisImageState {
  cameraDialogOpen: boolean;
  selectedDiagnosisImage: DiagnosticImage | null;
  printFormDialogOpen: boolean;
  onOpenCameraDialog: (selectedDiagnosisImage?: DiagnosticImage) => void;
  onCloseCameraDialog: () => void;
  onClosePrintFormDialog: () => void;
  onOpenPrintFormDialog: (selectedDiagnosisImage?: DiagnosticImage) => void;
}

export const useDiagnosisImageState = create(
  immer<IDiagnosisImageState>((set) => ({
    cameraDialogOpen: false,
    selectedDiagnosisImage: null,
    printFormDialogOpen: false,
    onOpenCameraDialog: (selectedDiagnosisImage) =>
      set((state) => {
        if (selectedDiagnosisImage) {
          state.selectedDiagnosisImage = selectedDiagnosisImage;
        } else {
          state.selectedDiagnosisImage = null;
        }
        state.cameraDialogOpen = true;
      }),
    onCloseCameraDialog: () => {
      set((state) => {
        state.cameraDialogOpen = false;
      });
    },
    onClosePrintFormDialog: () =>
      set((state) => {
        state.printFormDialogOpen = false;
      }),
    onOpenPrintFormDialog: (selectedDiagnosisImage) =>
      set((state) => {
        if (selectedDiagnosisImage) {
          state.selectedDiagnosisImage = selectedDiagnosisImage;
        }
        state.printFormDialogOpen = true;
      }),
  })),
);

export const useDiagnosisImageSelectors = createSelectors(useDiagnosisImageState);
