import { Paper, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { IDENTITY_API_SERVER } from '../../common/api';
import { some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import usePaginationHook from '../../common/hook/usePaginationHook';
import { setLoading } from '../../common/redux/commonReducer';
import { fetchThunk } from '../../common/redux/thunk';
import AccountTable from '../component/account/AccountTable';
import ChangePasswordDialog from '../component/account/ChangePasswordDialog';
import FormDialog from '../component/account/FormDialog';
import SearchAccountAdmin from '../component/account/SearchAccountAdmin';
import { axiosThunk } from '../../common/redux/axios';
import { AxiosError } from 'axios/index';
import { USER_ROLE_LIST } from '../component/account/constant';
import { useFetch } from 'modules/common/hook';

interface Props {}

const AccountAdminPage: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();

  const [dataUser, setDataUser] = React.useState<some | undefined>();
  const [dataUserPassword, setDataUserPassword] = React.useState<some | undefined>();
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;

  const { data, isValidating, revalidate } = useFetch(IDENTITY_API_SERVER.user.getListUser(params));

  const onSubmit = React.useCallback(
    async (value: some) => {
      const isEdit = !!value?.id;
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: isEdit ? IDENTITY_API_SERVER.user.updateUser(value?.id) : IDENTITY_API_SERVER.user.createUser,
          method: value?.id ? 'put' : 'post',
          data: {
            ...value,
            roleId: USER_ROLE_LIST.find((r) => r.value === value?.role)?.id,
            employeeId: value?.employee?.id,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
        })
        .finally(() => {
          dispatch(setLoading(false));
          setDataUser(undefined);
          revalidate();
        });
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onChangePassword = React.useCallback(
    async (value: some) => {
      try {
        dispatch(setLoading(true));
        await dispatch(
          fetchThunk(IDENTITY_API_SERVER.user.adminChangePassword(value.id), 'put', {
            password: value.password,
            confirmPassword: value.rePassword,
          }),
        );
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e: any) {
        if (e?.errors) {
          e?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
        setDataUserPassword(undefined);
        revalidate();
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.accountId }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(fetchThunk(IDENTITY_API_SERVER.user.deleteUser(value.id), 'delete'));
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e: any) {
          if (e?.errors) {
            e?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
          setDataUser(undefined);
          revalidate();
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <SearchAccountAdmin
        onAddNew={() => {
          setDataUser({});
        }}
        hookPagination={hookPagination}
        pagin={
          <Typography variant="caption">
            <FormattedMessage
              id="foundResult"
              values={{
                num: (
                  <Typography variant="inherit" component="span" color="primary">
                    {params.page * params.pageSize + (data?.content?.length || 0)}
                  </Typography>
                ),
                total: (
                  <Typography variant="inherit" component="span" color="primary">
                    {data ? data?.pagination?.totalElements : 0}
                  </Typography>
                ),
              }}
            />
          </Typography>
        }
      />
      <FormDialog
        open={!!dataUser}
        formData={dataUser}
        onClose={() => {
          setDataUser(undefined);
        }}
        onSubmit={onSubmit}
      />
      <ChangePasswordDialog
        open={!!dataUserPassword}
        formData={dataUserPassword}
        onClose={() => {
          setDataUserPassword(undefined);
        }}
        onSubmit={onChangePassword}
      />
      <AccountTable
        data={data}
        loading={isValidating}
        hookPagination={hookPagination}
        onEdit={(value) => {
          setDataUser({
            ...value,
            role: value?.roleName,
          });
        }}
        onDelete={onDelete}
        onChangePassword={setDataUserPassword}
      />
    </Paper>
  );
};

export default AccountAdminPage;
