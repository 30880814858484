import { ExpandLess, Search } from '@mui/icons-material';
import { Box, Collapse, InputAdornment } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import SchemaForm from '../../../common/SchemaForm';
import { RECEIPT_TYPE } from './constant';
import AddIcon from '@mui/icons-material/Add';
import { ROUTES } from '../../../layout/router';
import SchemaElement from '../../../common/SchemaForm/SchemaElement';
import { RawLink } from '../../../common/component/elements';

interface Props {
  title?: React.ReactNode;
  hookPagination: HookPaginationProps;
}

const FilterBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, title } = props;
  const { params, setParams, clearParams } = hookPagination;

  return (
    <SchemaForm
      schema={{
        fields: ({ formProps: { intl, clearParams, appState, state, setState }, valuesField }) => {
          return {
            code: {
              type: 'text-field',
              placeholder: intl.formatMessage({ id: 'sellingProduct.label.searchByReceiptCode' }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="inherit" />
                  </InputAdornment>
                ),
              },
              showClearBtn: true,
              propsWrapper: { xs: true },
            },
            search: {
              type: 'submitButton',
              color: 'inherit',
              label: intl.formatMessage({ id: 'search' }),
              propsWrapper: { xs: undefined },
            },
            expand: {
              type: 'button',
              endIcon: (
                <ExpandLess
                  style={{
                    transition: '0.2s all',
                    transform: state?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              ),
              color: 'primary',
              variant: 'text',
              onClick: () => setState({ expand: !state.expand }),
              label: intl.formatMessage({ id: 'advance' }),
              propsWrapper: { xs: undefined },
            },
            create: {
              type: () => {
                return (
                  <RawLink to={{ pathname: ROUTES.createOrder }}>
                    <SchemaElement
                      fieldName="btnCreateOrder"
                      propsElement={{
                        type: 'button',
                        startIcon: <AddIcon />,
                        color: 'primary',
                        label: intl.formatMessage({ id: 'sellingProduct.label.create.receipt' }),
                      }}
                    />
                  </RawLink>
                );
              },
              propsWrapper: { xs: undefined },
            },
            receiptType: {
              type: 'select',
              label: intl.formatMessage({ id: 'sellingProduct.title.receiptType' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              options: RECEIPT_TYPE,
              propsWrapper: { xs: true },
            },
            customerName: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'sellingProduct.title.customerName' }),
              placeholder: intl.formatMessage({ id: 'enter' }),
              propsWrapper: { xs: true },
            },
            createdDateFrom: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'sellingProduct.label.createdDateFrom' }),
              propsWrapper: { xs: true, paddingBottom: 1 },
              maxDate: valuesField.createdDateTo && moment(valuesField.createdDateTo).toDate(),
              register: {
                validate: (value) => {
                  return value && moment(value).isAfter(valuesField?.createdDateTo)
                    ? intl.formatMessage({ id: 'validation.invalidDate' })
                    : true;
                },
              },
            },
            createdDateTo: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'sellingProduct.label.createdDateTo' }),
              propsWrapper: { xs: true, paddingBottom: 1 },
              minDate: valuesField.createdDateFrom && moment(valuesField.createdDateFrom).toDate(),
              register: {
                validate: (value) => {
                  return value && moment(value).isBefore(valuesField?.createdDateFrom)
                    ? intl.formatMessage({ id: 'validation.invalidDate' })
                    : true;
                },
              },
            },
          };
        },
        ui: () => {
          return [
            {
              id: 'layout',
              propsGridContainer: { spacing: 1, justifyContent: 'flex-end' },
              fields: ['code', 'search', 'expand', 'create'],
            },
            {
              id: 'expand',
              fields: ['receiptType', 'customerName', 'saleName', 'createdDateFrom', 'createdDateTo'],
            },
          ];
        },
        layout: ({ formProps: { state }, listElement }) => {
          return (
            <>
              <Box display="flex" justifyContent={'space-between'}>
                <Box>{title}</Box>
                <Box width={750}>{listElement[0]}</Box>
              </Box>
              <Collapse in={state?.expand} style={{ marginTop: 16 }}>
                {listElement[1]}
              </Collapse>
            </>
          );
        },
      }}
      onSubmit={setParams}
      formData={params}
      clearParams={clearParams}
      hideSubmitButton
    />
  );
};

export default FilterBox;
