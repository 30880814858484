import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { PRESCRIPTION_TYPE } from 'modules/common/apiConstants';

export const PrescriptionDetailCloneSchema = z
  .object({
    id: z.number(),
    referenceId: z.number(),
    patientId: z.number(),
    prescriptionType: createObjectKeysEnumSchema(PRESCRIPTION_TYPE),
    medicationId: z.number(),
    medicationName: z.string(),
    quantity: z.number(),
    unit: z.string(),
    dayNumber: z.number(),
    unitPerDay: z.number(),
    intendedRoute: z.string(),
    usage: z.string(),
    type: z.string(),
  })
  .partial();

export type PrescriptionDetailClone = z.infer<typeof PrescriptionDetailCloneSchema>;
