import { useDialog, useUpdateMutate, useUserRoles } from 'modules/common/hook';
import { mutate } from 'swr';
import { API_SERVER } from 'modules/common/api';
import { Encounter } from 'modules/schema';
import IconButton from 'modules/common/component/IconButton';
import { DoctorExchange } from 'svg';
import EditPICDialog from './EditPICDialog';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { useMemo } from 'react';

interface DoctorExchangeBlockProps {
  encounter: Encounter;
  readonly: boolean;
}

const DoctorExchangeBlock = ({ encounter }: DoctorExchangeBlockProps) => {
  const [openChangePICDialog, onOpenChangePICDialog, onCloseChangePICDialog] = useDialog();
  const doctors = useUserRoles('DOCTOR', 'GENERAL_DOCTOR');
  const userId = useSelector((state: AppState) => state.authen.user?.user_id);

  const onChangePICMutate = useUpdateMutate({
    onSuccess: () => {
      onCloseChangePICDialog();
      mutate(API_SERVER.encounter.get(encounter.id!).url);
    },
  });

  const disabled = useMemo(() => {
    return encounter.status === ENCOUNTER_STATUS.FINISHED.value || userId !== encounter?.picUserId;
  }, [encounter?.picUserId, encounter.status, userId]);

  return (
    <>
      <IconButton
        tooltipTitle="encounterList.examDoctor.action.picButton"
        icon={<DoctorExchange />}
        disabled={disabled}
        onClick={() => {
          onOpenChangePICDialog();
        }}
        sx={{
          '& path': {
            fill: !disabled ? '#307BFF' : '',
          },
        }}
      />
      <EditPICDialog
        currentPICId={encounter.picUserId!}
        dialogTitleKey="encounter.encounterDetail.editPIC.dialog.title"
        doctors={doctors}
        onClose={onCloseChangePICDialog}
        onUpdatePIC={(data) =>
          onChangePICMutate({
            url: API_SERVER.encounter.changePIC(encounter?.id!),
            method: 'PUT',
            data: {
              picName: doctors?.find((doctor) => doctor?.id === data?.newDoctor?.value)?.employeeFullName!,
              picUserId: data?.newDoctor?.value,
              note: data?.note,
            },
          })
        }
        readonly={encounter.status === ENCOUNTER_STATUS.FINISHED.value || userId !== encounter?.picUserId}
        open={openChangePICDialog}
      />
    </>
  );
};

export default DoctorExchangeBlock;
