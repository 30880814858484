import { Paper } from '@mui/material';
import usePaginationHook from '../../common/hook/usePaginationHook';
import EncounterList from '../component/encounter/EncounterList';
import FilterEncounterBox from '../component/encounter/FilterEncounterBox';
import { ENCOUNTER_STATUS, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook/useFetch';
import { formatDateFilterField } from 'modules/common/utils';
import PageHeader from 'modules/common/component/PageHeader';
import { API_SERVER } from 'modules/common/api';
import React from 'react';

interface Props {}

const LabDoctorEncounterListPage: React.FunctionComponent<Props> = () => {
  const hookPagination = usePaginationHook({
    defaultFilter: {
      status: [ENCOUNTER_STATUS.INPROGRESS.value, ENCOUNTER_STATUS.ARRIVED.value, ENCOUNTER_STATUS.FINISHED.value],
    },
  });

  const { params } = hookPagination;
  const { data: encounterPage, isValidating } = useFetch(
    API_SERVER.encounter.searchEncounterIndicationRequest(
      formatDateFilterField(
        { ...params, loadIndicationRequestQuantity: true, scope: SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value },
        'fromDate',
        'toDate',
      ),
    ),
  );

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <FilterEncounterBox
        hookPagination={hookPagination}
        pagin={
          <PageHeader
            titleKey="encounterList"
            pagination={{
              size: encounterPage?.content.length || 0,
              totalElements: encounterPage?.pagination.totalElements || 0,
            }}
          />
        }
      />
      <EncounterList
        encounters={encounterPage?.content}
        total={encounterPage?.pagination?.totalElements || 0}
        hookPagination={hookPagination}
        loading={isValidating}
      />
    </Paper>
  );
};

export default LabDoctorEncounterListPage;
