import { FieldPath, FieldValues, UseFormReturn } from 'react-hook-form';
import { TIME_REPORT_FILTER } from './consts';
import RadioInput from '../form/RadioInput';

interface Props<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> {
  form: UseFormReturn<TFieldValues>;
  name: TName;
  onChange: (value: keyof typeof TIME_REPORT_FILTER) => void;
  showWeek?: boolean;
}

const ReportDayMonthRadio = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  form,
  name,
  onChange,
  showWeek,
}: Props<TFieldValues, TName>) => {
  return (
    <RadioInput
      form={form}
      label="report.workReport.filter.title.timeFilterType"
      name={name}
      options={
        showWeek
          ? Object.values(TIME_REPORT_FILTER)
          : Object.values(TIME_REPORT_FILTER).filter((item) => item.value !== TIME_REPORT_FILTER.WEEK.value)
      }
      getValue="value"
      renderLabel="label"
      hideError
      row
      sx={{ gap: 2, flexWrap: 'nowrap' }}
      controlProps={{ sx: { minWidth: 'unset' } }}
      fullWidth={false}
      onChange={(_, value) => onChange(value as keyof typeof TIME_REPORT_FILTER)}
    />
  );
};

export default ReportDayMonthRadio;
