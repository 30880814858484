import { CORE, GATEWAY_HOST, stringifyUrl } from 'modules/common/api';
import { ENCOUNTER_STATUS, SPECIALIZED_REPORT_TYPE, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import {
  APPOINTMENT_STATUS,
  INDICATION_REQUEST_STATUS,
  INDICATION_REQUEST_TYPE,
  PAYMENT_PLAN,
  some,
} from 'modules/common/constants';
import { Pageable } from 'modules/common/type';
import {
  AppointmentSchema,
  ExamReportSchema,
  IndicationResultReportSchema,
  TherapyWorkReportSchema,
} from 'modules/schema';
import { createPageSchema } from 'modules/schema/common';
import { z } from 'zod';

export const WORK_API = {
  getMedicationUsagePaging: (params: {
    fromDate?: string;
    toDate?: string;
    scope?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
    isFrees?: boolean[];
    indicationRequestIds?: number[];
    medicalSuppliesGroups?: string[];
    page?: number;
    pageSize?: number;
    sortBy?: string;
    orderBy?: string;
  }) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/medication-usage?${stringifyUrl(params)}`,
    schema: createPageSchema(IndicationResultReportSchema),
  }),
  getMedicationUsageList: (params: {
    fromDate?: string;
    toDate?: string;
    scope?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
    isFrees?: boolean[];
    indicationRequestIds?: number[];
    medicalSuppliesGroups?: string[];
    page?: number;
    pageSize?: number;
    sortBy?: string;
    orderBy?: string;
  }) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/medication-usage?${stringifyUrl(params)}`,
    schema: z.array(IndicationResultReportSchema),
  }),
  getMedicationUsageExcel: (params: {
    fromDate?: string;
    toDate?: string;
    scope?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
    isFrees?: boolean[];
    indicationRequestIds?: number[];
    medicalSuppliesGroups?: string[];
    page?: number;
    pageSize?: number;
    sortBy?: string;
    orderBy?: string;
  }) => `${GATEWAY_HOST}/${CORE}/v1.0/report/work/medication-usage/excel?${stringifyUrl(params)}`,
  getAppointmentPaging: (params: {
    fromDate?: string;
    toDate?: string;
    picUserIds?: number[];
    mobilePhone?: string;
    type?: keyof typeof SPECIALIZED_REPORT_TYPE;
    status?: (keyof typeof APPOINTMENT_STATUS)[];
    page?: number;
    pageSize?: number;
    sortBy?: string;
    orderBy?: string;
  }) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/appointment?${stringifyUrl(params)}`,
    schema: createPageSchema(AppointmentSchema),
  }),
  getAppointmentList: (params: {
    fromDate?: string;
    toDate?: string;
    type?: keyof typeof SPECIALIZED_REPORT_TYPE;
  }) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/appointment/list?${stringifyUrl(params)}`,
    schema: z.array(AppointmentSchema),
  }),
  getAppointmentExcel: (
    params: {
      fromDate?: string;
      toDate?: string;
      picUserIds?: number[];
      mobilePhone?: string;
      type?: keyof typeof SPECIALIZED_REPORT_TYPE;
      status?: (keyof typeof APPOINTMENT_STATUS)[];
    } & Pageable,
  ) => `${GATEWAY_HOST}/${CORE}/v1.0/report/work/appointment/excel?${stringifyUrl(params)}`,
  getMedicalExamPaging: (
    params: {
      fromDate: string;
      toDate: string;
      keyword: string;
      paymentPlans?: (keyof typeof PAYMENT_PLAN)[];
      statuses?: (keyof typeof ENCOUNTER_STATUS)[];
      picUserIds?: number[];
      serviceIds?: number[];
      icd10Codes?: string[];
      type: keyof typeof SPECIALIZED_REPORT_TYPE;
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/medical-exam?${stringifyUrl(params)}`,
    schema: createPageSchema(ExamReportSchema),
  }),
  getMedicalExamExcel: (
    params: {
      fromDate: string;
      toDate: string;
      keyword: string;
      paymentPlans?: (keyof typeof PAYMENT_PLAN)[];
      statuses?: (keyof typeof ENCOUNTER_STATUS)[];
      picUserIds?: number[];
      serviceIds?: number[];
      icd10Codes?: string[];
      type: keyof typeof SPECIALIZED_REPORT_TYPE;
    } & Pageable,
  ) => `${GATEWAY_HOST}/${CORE}/v1.0/report/work/medical-exam/excel?${stringifyUrl(params)}`,
  getMedicalExamList: (
    params: {
      fromDate: string;
      toDate: string;
      keyword: string;
      paymentPlans?: (keyof typeof PAYMENT_PLAN)[];
      statuses?: (keyof typeof ENCOUNTER_STATUS)[];
      picUserIds?: number[];
      serviceIds?: number[];
      icd10Codes?: string[];
      type: keyof typeof SPECIALIZED_REPORT_TYPE;
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/medical-exam/list?${stringifyUrl(params)}`,
    schema: z.array(ExamReportSchema),
  }),
  getIndicationPaging: (
    params: {
      scopes?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
      keyword?: string;
      isFrees?: boolean[];
      fromDate: string;
      toDate: string;
      status?: (keyof typeof INDICATION_REQUEST_STATUS)[];
      systemCategoryIds?: number[];
      executeUserIds?: number[];
      serviceIds?: number[];
      type?: (keyof typeof INDICATION_REQUEST_TYPE)[];
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/indication?${stringifyUrl(params)}`,
    schema: createPageSchema(IndicationResultReportSchema),
  }),
  getIndicationExcel: (
    params: {
      scopes?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
      keyword?: string;
      paymentPlans?: (keyof typeof PAYMENT_PLAN)[];
      fromDate: string;
      toDate: string;
      status?: (keyof typeof INDICATION_REQUEST_STATUS)[];
      systemCategoryIds?: number[];
      executeUserIds?: number[];
      serviceIds?: number[];
    } & Pageable,
  ) => `${GATEWAY_HOST}/${CORE}/v1.0/report/work/indication/excel?${stringifyUrl(params)}`,
  getIndicationList: (
    params: {
      scopes?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
      keyword?: string;
      isFrees?: boolean[];
      fromDate: string;
      toDate: string;
      status?: (keyof typeof INDICATION_REQUEST_STATUS)[];
      systemCategoryIds?: number[];
      executeUserIds?: number[];
      serviceIds?: number[];
      type?: (keyof typeof INDICATION_REQUEST_TYPE)[];
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/indication/list?${stringifyUrl(params)}`,
    schema: z.array(IndicationResultReportSchema),
  }),
  getMedicalSuppliesPaging: (params: {
    fromDate?: string;
    toDate?: string;
    scope?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
    isFrees?: boolean[];
    indicationRequestIds?: number[];
    medicalSuppliesGroups?: string[];
    page?: number;
    pageSize?: number;
    sortBy?: string;
    orderBy?: string;
  }) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/medical-supplies?${stringifyUrl(params)}`,
    schema: createPageSchema(IndicationResultReportSchema),
  }),
  getMedicalSuppliesExcel: (params: {
    fromDate?: string;
    toDate?: string;
    scope?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
    isFrees?: boolean[];
    indicationRequestIds?: number[];
    medicalSuppliesGroups?: string[];
    page?: number;
    pageSize?: number;
    sortBy?: string;
    orderBy?: string;
  }) => `${GATEWAY_HOST}/${CORE}/v1.0/report/work/medical-supplies/excel?${stringifyUrl(params)}`,
  getMedicalSuppliesList: (params: {
    fromDate?: string;
    toDate?: string;
    scope?: (keyof typeof SYSTEM_CATEGORY_SCOPE)[];
    isFrees?: boolean[];
    indicationRequestIds?: number[];
    medicalSuppliesGroups?: string[];
    page?: number;
    pageSize?: number;
    sortBy?: string;
    orderBy?: string;
  }) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/medical-supplies?${stringifyUrl(params)}`,
    schema: z.array(IndicationResultReportSchema),
  }),
  getTherapyWork: (params: some) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/work/therapy?${stringifyUrl(params)}`,
    schema: createPageSchema(TherapyWorkReportSchema),
  }),
  getTherapyWorkExcel: (params: some) =>
    `${GATEWAY_HOST}/${CORE}/v1.0/report/work/therapy/excel?${stringifyUrl(params)}`,
};
