import { some } from 'modules/common/constants';
import * as React from 'react';
import DialogCustom from 'modules/common/component/DialogCustom';
import { Box, InputLabel, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SchemaForm from 'modules/common/SchemaForm';
import NoDataBox from 'modules/common/component/NoDataBox';
import imageResultSchema from './imageResultSchema';
import { API_SERVER } from 'modules/common/api';
import { STORAGE_RESOURCE_TYPE } from '../../../reception/constants';
import { useFetch } from 'modules/common/hook';

interface Props {
  indication?: some;
  open: boolean;
  onClose?: () => void;
}

const ImageResultDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, indication } = props;
  const { data: dataImage } = useFetch(API_SERVER.indicationImage.get(indication?.id));
  const { data: images } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.therapyIndicationDiagnosticImage,
      referenceId: indication?.id,
    }),
  );

  const indicationImageResult = React.useMemo(() => {
    const imagesMap = images?.map((i) => {
      return { ...i, fileId: i.id };
    });
    return {
      ...dataImage,
      images: imagesMap,
    };
  }, [images, dataImage]);

  return (
    <DialogCustom
      open={open}
      maxWidth="lg"
      // keepMounted={false}
      onClose={onClose}
      disableCloseOutSide
      disableEscapeKeyDown
      PaperProps={{
        style: { width: 1460 },
      }}
      title={'therapyIndication.radiology.result.dialog.header'}
    >
      <Box padding={2}>
        <Box flex={5} display="flex">
          {indication && (
            <>
              <Box marginBottom={3}>
                <InputLabel>
                  <Typography variant="subtitle1" color="textSecondary" noWrap style={{ lineHeight: 1 }}>
                    <FormattedMessage id="therapyIndication.radiology.result.label.service" />
                  </Typography>
                </InputLabel>
                <Typography variant="body1">{indication?.serviceName}</Typography>
              </Box>
              <Box marginLeft={10}>
                <InputLabel>
                  <Typography variant="subtitle1" color="textSecondary" noWrap style={{ lineHeight: 1 }}>
                    <FormattedMessage id="doctor" />
                  </Typography>
                </InputLabel>
                <Typography variant="body1">{indication?.picName}</Typography>
              </Box>
              <Box marginX={10}>
                <InputLabel>
                  <Typography variant="subtitle1" color="textSecondary" noWrap style={{ lineHeight: 1 }}>
                    <FormattedMessage id="note" />
                  </Typography>
                </InputLabel>
                <Typography variant="subtitle1">{indication?.note}</Typography>
              </Box>
            </>
          )}
        </Box>
        {indicationImageResult ? (
          <>
            <SchemaForm
              schema={imageResultSchema}
              hideSubmitButton={true}
              formData={indicationImageResult}
              // patientData={patientData}
              readOnly={true}
              // encounterId={encounterId}
            />
          </>
        ) : (
          <Box display={'flex'} justifyContent="center">
            <NoDataBox />
          </Box>
        )}
      </Box>
    </DialogCustom>
  );
};

export default ImageResultDialog;
