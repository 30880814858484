import Stack from '@mui/material/Stack';
import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

const DoctorSign = () => {
  return (
    <Stack direction="row" justifyContent="space-between" marginTop={3}>
      <Box textAlign="center">
        <Typography variant="body1" fontStyle="italic">
          &nbsp;
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="therapy.payment.summaryPrintForm.title.createdPerson" />
        </Typography>
        <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
          <FormattedMessage id="medicalPrint.helperText" />
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="body1" fontStyle="italic">
          &nbsp;
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="therapy.payment.summaryPrintForm.title.confirmCustomer" />
        </Typography>
        <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
          <FormattedMessage id="medicalPrint.helperText" />
        </Typography>
      </Box>
      <Box textAlign="center">
        <Typography variant="body1" fontStyle="italic">
          <FormattedMessage
            id="printForm.date"
            values={{
              hour: new Date().getHours(),
              min: new Date().getMinutes(),
              day: new Date().getDate(),
              month: new Date().getMonth() + 1,
              year: new Date().getFullYear(),
            }}
          />
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="therapy.payment.summaryPrintForm.title.accountant" />
        </Typography>
        <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
          <FormattedMessage id="medicalPrint.helperText" />
        </Typography>
      </Box>
    </Stack>
  );
};

export default DoctorSign;
