import { Box } from '@mui/system';
import { EncounterDetailProps } from '../../type';
import { useCreateMutate, useDeleteMutate, useFetch, useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { MEDICATION_USAGE_TYPE, SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { INDICATION_REQUEST_TYPE } from 'modules/common/constants';
import { useFieldArray, useForm } from 'react-hook-form';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { Stack, Switch, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { Column } from 'modules/common/component/table/type';
import Table, { TableOptions } from 'modules/common/component/table';
import SelectInput from 'modules/common/component/form/SelectInput';
import { IndicationRequest, MedicalSupplies, MedicalSuppliesUsage, Medication } from 'modules/schema';
import NumberInput from 'modules/common/component/form/NumberInput';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { Delete } from '@mui/icons-material';
import AutocompleteInput from 'modules/common/component/form/AutocompleteInput';
import { useDebouncedCallback } from 'use-debounce';
import { NumericFormatText } from 'modules/common/utils';
import { FormattedMessage } from 'react-intl';
import { sumBy } from 'lodash';

type IndicationRequestType = Omit<IndicationRequest, 'createdTime' | 'startTime' | 'endTime'>;
type MedicationSupplyUsageType = Omit<MedicalSuppliesUsage, 'indicationRequest'> & {
  indicationRequest?: IndicationRequestType;
};

interface SchemaFormMedicationSupply {
  group: MedicalSupplies | null;
  medication: (Pick<Medication, 'id' | 'name'> & { group?: string; medicalSuppliesId?: number })[] | [];
  medicalSuppliesUsages: MedicationSupplyUsageType[];
}

const MedicationSupplySession = ({ encounter, readonly }: EncounterDetailProps) => {
  const form = useForm<SchemaFormMedicationSupply>({
    defaultValues: { group: null, medication: [], medicalSuppliesUsages: [] },
  });
  const { fields } = useFieldArray({ control: form.control, name: 'medicalSuppliesUsages', keyName: 'key' });
  const { intl } = useGeneralHook();
  const { data: medicalSupplies } = useFetch(API_SERVER.medicalSupplies.getList());
  const { data: medicationSupplyUsage, revalidate: revalidateSupplyUsage } = useFetch(
    API_SERVER.medicalSuppliesUsage.getList({
      referenceId: encounter.id,
      referenceType: MEDICATION_USAGE_TYPE.ENCOUNTER,
    }),
  );

  useEffect(() => {
    if (medicationSupplyUsage) {
      form.setValue('medicalSuppliesUsages', medicationSupplyUsage);
    }
  }, [medicationSupplyUsage, form]);

  const medicalItems = medicalSupplies?.flatMap((medication) =>
    medication?.items?.map((item) => ({
      ...item,
      group: medication.name,
    })),
  );

  const { data: indicationRequest } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(encounter?.id!, INDICATION_REQUEST_TYPE.ENCOUNTER, [
      SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE.value,
      SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
      SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value,
      SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value,
    ]),
  );

  const deleteMedicalSuppliesMutate = useDeleteMutate({ onSuccess: revalidateSupplyUsage });
  const updateSupplyItemMultipleMutate = useUpdateMutate({
    onSuccess: () => {
      revalidateSupplyUsage();
    },
    globalLoading: false,
  });
  const updateSupplyItemMutate = useUpdateMutate({
    onSuccess: revalidateSupplyUsage,
  });
  const updateSupplyItemMultipleDetail = useDebouncedCallback(() => {
    updateSupplyItemMultipleMutate({
      url: API_SERVER.medicalSuppliesUsage.updateMultipleMedicalSuppliesUsages(),
      method: 'PUT',
      data: {
        data: form
          .getValues('medicalSuppliesUsages')
          .filter((value, index) => !value.isPaid)
          .map((medical) => ({
            id: medical.id,
            referenceId: medical?.referenceId,
            medicalSuppliesId: medical.medicalSuppliesId,
            isFree: medical.isFree,
            quantity: medical.quantity ? medical.quantity : 1,
            indicationRequestId: medical.indicationRequest?.id,
            type: 'ENCOUNTER',
          })),
      },
    });
  }, 2000);

  const columns: Column<typeof fields[number]>[] = [
    {
      type: 'INDEX',
      width: '2%',
      align: 'center',
      headerCellProps: { sx: { fontSize: '16px' } },
    },
    {
      title: 'supply.name',
      render: (record) => (
        <Typography variant="subtitle1" sx={{ cursor: 'default' }}>
          {record.medicalSuppliesName}
        </Typography>
      ),
      width: '26%',
      align: 'left',
      headerCellProps: { sx: { fontSize: '16px' } },
    },
    {
      title: 'quantity',
      align: 'center',
      required: true,
      width: '10%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record, index) => (
        <NumberInput
          form={form}
          name={`medicalSuppliesUsages.${index}.quantity`}
          hideError
          variant="standard"
          sx={{ '& .MuiInput-root': { backgroundColor: 'transparent' }, fontSize: '16px' }}
          disabled={record.isPaid || readonly}
          onChange={updateSupplyItemMultipleDetail}
          inputProps={{ sx: { textAlign: 'center' } }}
          max={999}
        />
      ),
    },
    {
      title: 'indication',
      width: '20%',
      align: 'left',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record, index) => {
        return (
          <SelectInput
            form={form}
            name={`medicalSuppliesUsages.${index}.indicationRequest`}
            options={indicationRequest}
            getValue="serviceName"
            renderLabel="serviceName"
            variant="standard"
            hideError
            rawOptionLabel
            rawGroup
            sx={{ backgroundColor: 'transparent', fontSize: '16px' }}
            groupBy="systemCategoryName"
            disabled={record.isPaid || readonly}
            onChange={updateSupplyItemMultipleDetail}
          />
        );
      },
    },
    {
      title: 'unitPrice',
      align: 'left',
      width: '8%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (data) => {
        return (
          <Typography
            sx={{
              fontSize: '16px',
              cursor: 'default',
            }}
          >
            {NumericFormatText(data.price)}
          </Typography>
        );
      },
    },
    {
      title: 'totalPrice',
      fieldName: 'totalAmount',
      align: 'left',
      width: '10%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (data) => {
        return (
          <Typography
            sx={{
              fontSize: '16px',
              cursor: 'default',
            }}
          >
            {NumericFormatText(data.totalAmount)}
          </Typography>
        );
      },
    },
    {
      title: 'common.billable',
      align: 'left',
      width: '10%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record) => (
        <Tooltip
          title={<FormattedMessage id={record.isPaid ? 'report.debt.table.title.amountPaid' : 'freeSwitch'} />}
          disableInteractive
        >
          <span>
            <Switch
              title={intl.formatMessage({ id: 'therapy.therapyDetail.generalInfo.switch.payMoreSwitch.title' })}
              size="small"
              color="primary"
              checked={!record.isFree}
              disabled={record.isPaid || readonly}
              onChange={async (_, checked) => {
                await updateSupplyItemMutate({
                  url: API_SERVER.medicalSuppliesUsage.update(record.id!),
                  method: 'PUT',
                  data: {
                    id: record.id,
                    referenceId: record?.referenceId,
                    medicalSuppliesId: record.medicalSuppliesId,
                    isFree: !checked,
                    quantity: record.quantity ? record.quantity : 1,
                    indicationRequestId: record.indicationRequest?.id,
                    type: 'ENCOUNTER',
                  },
                });
              }}
            />
          </span>
        </Tooltip>
      ),
    },
    {
      title: 'action',
      align: 'right',
      width: '10%',
      headerCellProps: { sx: { fontSize: '16px' } },
      render: (record) => (
        <IconButtonTitle
          onClick={async () => {
            deleteMedicalSuppliesMutate(
              {
                url: API_SERVER.medicalSuppliesUsage.delete(record?.id!),
                method: 'DELETE',
              },
              { confirmMessage: intl.formatMessage({ id: 'confirmDelete' }, { name: record.medicalSuppliesName }) },
            );
          }}
          title="delete"
          size="small"
          disabled={record.isPaid || readonly}
        >
          <Delete sx={{ color: record.isPaid || readonly ? '#E0E0E0' : '#78909C' }} />
        </IconButtonTitle>
      ),
    },
  ];

  const medicalSuppliesAddMutate = useCreateMutate({
    onSuccess: () => {
      revalidateSupplyUsage();
      form.reset();
    },
  });
  const onSelectMedicalSupplies = async () => {
    const selectMedicationSupply = form.watch('medication');
    if (selectMedicationSupply) {
      const dataMedical = selectMedicationSupply.map((item) => ({
        medicalSuppliesId: item.id,
        isFree: false,
        quantity: 1,
        referenceId: encounter?.id,
        type: 'ENCOUNTER',
      }));
      if (dataMedical.length !== 0) {
        await medicalSuppliesAddMutate({
          url: API_SERVER.medicalSuppliesUsage.createMultiple(),
          method: 'POST',
          data: dataMedical,
        });
      }
    }
  };

  const TABLE_OPTIONS = useMemo(() => {
    let tableOptions: TableOptions = {
      position: 'bottom',
      record: { totalAmount: sumBy(medicationSupplyUsage, 'totalAmount') },
      messageKey: 'supply.provisional.uppercase',
      startIndex: 4,
    };
    return tableOptions;
  }, [medicationSupplyUsage]);

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" gap={2}>
          <Box width="300px">
            <SelectInput
              key="group"
              form={form}
              name="group"
              getValue={(option) => option?.id!}
              options={medicalSupplies}
              rawLabel
              rawOptionLabel
              label={intl.formatMessage({ id: 'therapy.medicalSupplies.category' })}
              placeholder="therapy.medicalSupplies.categoryEnter"
              renderLabel="name"
              disabled={readonly}
              clearButton
              sx={{ fontSize: '16px' }}
            />
          </Box>
          <Box width="300px">
            <AutocompleteInput
              key={'medication'}
              form={form}
              name="medication"
              getValue={(option) => option?.id!}
              options={
                form.watch('group')
                  ? medicalItems?.filter((item) => item?.systemCategoryId === form.watch('group')?.id)
                  : medicalItems
              }
              rawLabel
              label={intl.formatMessage({ id: 'therapy.medicalSupplies.name' })}
              placeholder="therapy.medicalSupplies.nameEnter"
              getLabel={(option) => option?.name!}
              onBlur={onSelectMedicalSupplies}
              multiple
              showCheck
              disableCloseOnSelect
              renderTags={() => null}
              disabled={readonly}
            />
          </Box>
        </Stack>
      </Stack>
      <Table columns={columns} dataKey={'id'} data={fields} options={TABLE_OPTIONS} />
    </Box>
  );
};

export default MedicationSupplySession;
