import { Box } from '@mui/material';
import { GENDER, PATIENT_ADDRESS_TYPE } from 'modules/common/apiConstants';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { Patient } from 'modules/schema';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

interface Props {
  patient: Patient;
  otherProps: some;
}

const AutocompleteOptionField: React.FC<{ name: string; value: string }> = ({ name, value }) => {
  return (
    <>
      <Box component="span" color="text.secondary" fontWeight="normal">
        {name}:&nbsp;
      </Box>
      <Box component="span" fontWeight={500}>
        {value}
      </Box>
    </>
  );
};

const PatientOption = ({ patient, otherProps }: Props) => {
  const { intl } = useGeneralHook();
  const homeAddress = patient?.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.HOME);
  const birthday = moment(patient?.dob, BE_DATE_FORMAT);
  return (
    <li key={patient.id} {...otherProps}>
      <Box fontSize="body2.fontSize" fontWeight={500}>
        <Box>
          <Box component="span">{patient.name}</Box>
          {' - '}
          <Box component="span">
            <FormattedMessage id={GENDER[patient?.gender || GENDER.UNKNOWN.value]?.label} />
          </Box>
          {' - '}
          <Box component="span">{birthday.isValid() ? birthday.format(FE_DATE_FORMAT) : ''}</Box>
          {' - '}
          <AutocompleteOptionField name={intl.formatMessage({ id: 'idShort' })} value={patient.identifierCode || ''} />
          {' - '}
          <AutocompleteOptionField
            name={intl.formatMessage({ id: 'telephoneShort' })}
            value={patient.mobilePhone || ''}
          />
        </Box>
        <Box>
          <AutocompleteOptionField name={intl.formatMessage({ id: 'address' })} value={homeAddress?.address || ''} />
        </Box>
      </Box>
    </li>
  );
};

export default PatientOption;
