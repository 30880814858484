import * as React from 'react';
import { BE_DATE_TIME_FORMAT, FE_DATE_TIME_FORMAT, some } from '../../../../common/constants';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import { setLoading } from '../../../../common/redux/commonReducer';
import { fetchThunk } from '../../../../common/redux/thunk';
import { API_SERVER } from '../../../../common/api';
import { Box, Stack, Typography } from '@mui/material';
import { UseFormReturn } from 'react-hook-form/dist/types';
import TableCustom, { Columns } from '../../../../common/component/TableCustom';
import { NumericFormatText } from '../../../../common/utils';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import ReplayIcon from '@mui/icons-material/Replay';
import moment from 'moment/moment';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import { PAYMENT_METHOD_ENUM } from '../../../../admin/component/saleManagement/constant';
import PrepaymentAddDialog from './PrepaymentAddDialog';
import { Claim } from '../../../../../svg';
import PrepaymentConfirmDialog from './PrepaymentConfirmDialog';
import PrepaymentRefundDialog from './PrepaymentRefundDialog';
import DeleteIcon from '@mui/icons-material/Delete';
import { axiosThunk } from '../../../../common/redux/axios';
import PrepaymentPrintDialog from './PrintForm/PrepaymentPrintDialog';
import { PAYMENT_ADVANCE_STATUS, PAYMENT_METHOD } from 'modules/common/apiConstants';

interface Props {
  formData?: some;
  paymentAdvanceList?: some[];
  prepaymentDetail?: some;
  // reCalculate: (therapyPaymentDetail, event) => void;
  revalidate: () => void;
  methods: UseFormReturn<any>;
  loading?: boolean;
  setSelectedPrepaymentList: (selectedRows) => void;
  selectedPrepaymentList: some[];
  therapyDetail?: some;
}

const TablePrepayment: React.FunctionComponent<Props> = (props) => {
  const { paymentAdvanceList, prepaymentDetail, revalidate, therapyDetail } = props;

  const { dispatch, confirmDialog, openSnackbar, intl } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;

  const [paymentAdvance, setPaymentAdvance] = React.useState<some>();

  const [paymentAdvanceConfirm, setPaymentAdvanceConfirm] = React.useState<some>();
  const [openPrepaymentRefundDialog, setOpenPrepaymentRefundDialog] = React.useState(false);
  const [paymentAdvancePrint, setPaymentAdvancePrint] = React.useState<some>();

  const onDeletePrepayment = React.useCallback(
    async (paymentAdvance) => {
      const confirm = await promptConfirmation({
        title: <FormattedMessage id="therapy.prepaid.title.confirmDelete" />,
        content: <FormattedMessage id="therapy.prepaid.message.confirmDelete" />,
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(
            axiosThunk({
              url: API_SERVER.prepayment.deletePaymentAdvance(paymentAdvance?.id),
              method: 'delete',
            }),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e: any) {
          if (e?.errors) {
            e?.errors?.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: 'therapy.prepaid.table.header.prepaidAmount',
        width: '18%',
        render: (record: some) => NumericFormatText(record.amount),
        align: 'right',
        lastCell: {
          align: 'right',
          colSpan: 2,
          style: { background: '#E0ECFF' },
          render: (record) => {
            return (
              <Stack direction="row" justifyContent="flex-end">
                <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="therapy.prepaid.label.totalAmount" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  {/*<FormattedNumber value={prepaymentDetail?.totalAmount} />*/}
                  {NumericFormatText(prepaymentDetail?.totalAmount)}
                </Typography>
              </Stack>
            );
          },
        },
      },
      {
        title: 'therapy.prepaid.table.header.createdTime',
        width: '27%',
        align: 'center',
        render: (record: some) => (
          <Typography variant="body1">
            {record?.confirmedTime && moment(record?.confirmedTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT)}
          </Typography>
        ),
        lastCell: {
          align: 'left',
          style: { background: '#E0ECFF' },
          render: (record) => {
            return (
              <Stack direction="row" justifyContent="center">
                <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="therapy.prepaid.label.totalUsed" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  <FormattedNumber value={prepaymentDetail?.amountPaid} />
                </Typography>
              </Stack>
            );
          },
        },
      },
      {
        title: 'therapy.prepaid.table.header.prepaidPaymentMethod',
        width: '15%',
        align: 'left',
        render: (record: some) => {
          return (
            <Typography variant="body1">
              <FormattedMessage
                id={
                  record?.paymentMethod
                    ? `therapy.paymentMethod.${
                        PAYMENT_METHOD_ENUM.find((v) => v.value === record?.paymentMethod)?.label
                      }`
                    : ' '
                }
              />
            </Typography>
          );
        },
        lastCell: {
          align: 'right',
          style: { background: '#E0ECFF' },
          render: (record) => {
            return (
              <Stack direction="row" justifyContent="flex-end">
                <Typography variant="subtitle1" style={{ textTransform: 'uppercase' }}>
                  <FormattedMessage id="therapy.prepaid.label.totalRefunded" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  <FormattedNumber value={prepaymentDetail?.refundAmount} />
                </Typography>
              </Stack>
            );
          },
        },
      },
      {
        title: 'status',
        width: '20%',
        render: (record: some) => {
          const status = PAYMENT_ADVANCE_STATUS[record?.status!];
          return (
            <Typography
              noWrap
              sx={{
                color: status?.color || 'primary.main',
                fontWeight: '500',
              }}
            >
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
        align: 'center',
        lastCell: {
          align: 'center',
          style: { background: '#E0ECFF' },
          render: (record) => {
            return (
              <Stack direction="row" justifyContent="flex-end" style={{ textTransform: 'uppercase' }}>
                <Typography variant="subtitle1">
                  <FormattedMessage id="therapy.prepaid.label.balance" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">
                  <FormattedNumber value={prepaymentDetail?.amount} />
                </Typography>
              </Stack>
            );
          },
        },
      },
      {
        title: 'action',
        width: '20%',
        dataIndex: 'unitPrice',
        render: (record: some) => (
          <>
            {record?.status === 'DRAFT' && (
              <IconButtonTitle
                size="small"
                title="therapy.tooltip.label.edit"
                onClick={() => {
                  setPaymentAdvance(record);
                }}
              >
                <EditIcon />
              </IconButtonTitle>
            )}
            {record?.status !== 'DRAFT' && (
              <IconButtonTitle
                size="small"
                title="print"
                onClick={() => {
                  setPaymentAdvancePrint(record);
                }}
              >
                <PrintIcon />
              </IconButtonTitle>
            )}
            {record?.status === 'DRAFT' && (
              <IconButtonTitle
                size="small"
                title="therapy.cashier.payment.prepayment.label.confirmPaymentAdvance"
                onClick={() => {
                  setPaymentAdvanceConfirm(record);
                }}
              >
                <Claim />
              </IconButtonTitle>
            )}
            {record?.status === 'DRAFT' && (
              <IconButtonTitle
                title="delete"
                size="small"
                onClick={() => {
                  onDeletePrepayment(record);
                }}
              >
                <DeleteIcon />
              </IconButtonTitle>
            )}
          </>
        ),
        align: 'center',
        lastCell: {
          align: 'center',
          style: { background: '#E0ECFF' },
          render: (record) => {
            return (
              <IconButtonTitle
                size="small"
                title="refund"
                onClick={() => {
                  setOpenPrepaymentRefundDialog(true);
                }}
              >
                <ReplayIcon />
              </IconButtonTitle>
            );
          },
        },
      },
    ] as Columns[];
  }, [
    onDeletePrepayment,
    prepaymentDetail?.amount,
    prepaymentDetail?.amountPaid,
    prepaymentDetail?.refundAmount,
    prepaymentDetail?.totalAmount,
  ]);

  const onUpdatePaymentAdvance = React.useCallback(
    async (paymentAdvanceDetail: some) => {
      try {
        dispatch(setLoading(true));
        await dispatch(
          fetchThunk(
            API_SERVER.prepayment.updatePaymentAdvance(paymentAdvanceDetail?.id),
            'put',
            paymentAdvanceDetail,
            'application/json-patch+json',
          ),
        );
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e: any) {
        if (e?.errors) {
          e?.errors?.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
      }
      setPaymentAdvance(undefined);
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onConfirmPaymentAdvance = React.useCallback(
    async (paymentAdvanceDetail) => {
      const confirm = await promptConfirmation({
        title: <FormattedMessage id="confirm" />,
        content: <FormattedMessage id="therapy.payment.prepayment.message.confirm" />,
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          const paymentMethodEnum = PAYMENT_METHOD[paymentAdvanceDetail?.paymentMethod]?.value;
          await dispatch(
            fetchThunk(
              API_SERVER.prepayment.confirmPrepayment(paymentAdvanceDetail?.id),
              'post',
              {
                ...paymentAdvanceDetail,
                paymentMethod: paymentMethodEnum,
              },
              'application/json-patch+json',
            ),
          );
          revalidate();

          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e: any) {
          if (e?.errors) {
            e?.errors?.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const onRefundPrepayment = React.useCallback(
    async (refundDetail) => {
      const confirm = await promptConfirmation({
        title: <FormattedMessage id="confirm" />,
        content: <FormattedMessage id="therapy.cashier.prepaymentRefund.confirm.refund" />,
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          const paymentMethodEnum = PAYMENT_METHOD[refundDetail?.paymentMethod]?.value;
          await dispatch(
            fetchThunk(
              API_SERVER.prepayment.refundPrepayment(prepaymentDetail?.id),
              'post',
              {
                ...refundDetail,
                paymentMethod: paymentMethodEnum,
              },
              'application/json-patch+json',
            ),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e: any) {
          if (e?.errors) {
            e?.errors?.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
        }
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, prepaymentDetail?.id, promptConfirmation, revalidate],
  );

  const content = React.useMemo(() => {
    return (
      <Box
        position="relative"
        overflow="inherit"
        display="flex"
        flexDirection="column"
        height="-webkit-fill-available"
        flex={1}
      >
        <TableCustom
          boxProps={{ flex: 1 }}
          dataSource={paymentAdvanceList
            ?.filter((p) => p.status !== 'REFUNDED')
            .sort((a) => (a.status === 'DRAFT' ? -1 : 1))}
          columns={columns}
          // rowProps={(record) => {
          //   if (record.status === 'DRAFT') {
          //     return {
          //       style: { '& p': { opacity: '0.4' }, '& span': { opacity: '0.4' } },
          //     };
          //   } else {
          //     return {
          //       style: {},
          //     };
          //   }
          // }}
          rowProps={(record) => {
            if (record.status === 'DRAFT') {
              return {
                sx: {
                  '& p': { opacity: '0.7' },
                  '& span': { opacity: '0.7' },
                },
              };
            } else {
              return {};
            }
          }}
          keyName="rowId"
        />
        <PrepaymentAddDialog
          open={!!paymentAdvance}
          paymentAdvanceDetail={paymentAdvance}
          onClose={() => {
            setPaymentAdvance(undefined);
          }}
          onSubmit={onUpdatePaymentAdvance}
        />
        <PrepaymentConfirmDialog
          open={!!paymentAdvanceConfirm}
          paymentAdvanceDetail={paymentAdvanceConfirm}
          onClose={() => {
            setPaymentAdvanceConfirm(undefined);
          }}
          onSubmit={onConfirmPaymentAdvance}
        />
        <PrepaymentRefundDialog
          open={openPrepaymentRefundDialog}
          onClose={() => {
            setOpenPrepaymentRefundDialog(false);
          }}
          prepaymentDetail={prepaymentDetail}
          onSubmit={onRefundPrepayment}
        />
        <PrepaymentPrintDialog
          open={!!paymentAdvancePrint}
          paymentAdvancePrint={paymentAdvancePrint}
          therapyDetail={therapyDetail}
          onClose={() => {
            setPaymentAdvancePrint(undefined);
          }}
        />
      </Box>
    );
  }, [
    paymentAdvanceList,
    columns,
    paymentAdvance,
    onUpdatePaymentAdvance,
    paymentAdvanceConfirm,
    onConfirmPaymentAdvance,
    openPrepaymentRefundDialog,
    prepaymentDetail,
    onRefundPrepayment,
    paymentAdvancePrint,
    therapyDetail,
  ]);

  return <>{content}</>;
};

export default TablePrepayment;
