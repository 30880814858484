import { Box, Paper, Typography } from '@mui/material';
import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { mappedBody } from 'modules/common/api';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import { axiosThunk } from 'modules/common/redux/axios';
import FilterSupplierBox from 'modules/admin/component/warehouse-supplier/FilterSupplierBox';
import FormDataDialog from 'modules/admin/component/warehouse-supplier/FormDataDialog';
import TableBox from 'modules/admin/component/warehouse-supplier/TableBox';
import { useFetch } from 'modules/common/hook';
import { setLoading } from 'modules/common/redux/commonReducer';

interface Props {}
const SupplierPage: React.FunctionComponent<Props> = (props: Props) => {
  const { API_SERVER, dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const [formData, setFormData] = useState<some | undefined>();
  const { params } = hookPagination;

  const { data, isValidating, revalidate } = useFetch(API_SERVER.supplier.get(params), {
    revalidateOnMount: true,
    revalidateOnFocus: false,
  });

  const createUpdateForm = async (value) => {
    dispatch(setLoading(true));
    await dispatch(
      axiosThunk(
        value.id
          ? { url: API_SERVER.supplier.update(value.id), method: 'put', data: mappedBody(value) }
          : { url: API_SERVER.supplier.create, method: 'post', data: mappedBody(value) },
      ),
    )
      .then((e) => {
        openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        revalidate();
        setFormData(undefined);
      })
      .catch((e: AxiosError<some>) => {
        if (e.response)
          e.response?.data?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
      });
    dispatch(setLoading(false));
  };

  const onDelete = useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        await dispatch(axiosThunk({ url: API_SERVER.supplier.update(value.id), method: 'delete' }))
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
            revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });
      }
      confirmDialog.close();
    },
    [API_SERVER.supplier, confirmDialog, dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <>
      <Paper sx={{ flex: 1, overflow: 'hidden', padding: 2 }}>
        <Box sx={{ display: 'flex', gap: 1, marginBottom: 2 }}>
          <Typography variant="h6" flex={1}>
            <FormattedMessage id="supplier.title" />
          </Typography>
          <FilterSupplierBox hookPagination={hookPagination} onAddNew={() => setFormData({})} />
        </Box>
        <TableBox
          data={data}
          loading={isValidating}
          setFormData={setFormData}
          onDelete={onDelete}
          hookPagination={hookPagination}
        />
      </Paper>
      <FormDataDialog
        formData={formData}
        open={!!formData}
        onClose={() => setFormData(undefined)}
        onSubmit={createUpdateForm}
      />
    </>
  );
};
export default SupplierPage;
