import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { createSelectors } from 'modules/common/utils';
import { DENTAL_DIAGNOSIS_STATUS, DENTAL_GROUP, DENTAL_GROUP_DETAIL } from '../../common/constants';
import { DentalDiagnosis, DentalInfo, DiagnosticImage, StorageResource } from 'modules/schema';

export type State = { state?: 'add' | 'delete' };

export interface DiagnosisSetting {
  position?: number;
  dentalGroup?: keyof typeof DENTAL_GROUP;
  dentalGroupDetail?: keyof typeof DENTAL_GROUP_DETAIL;
}

interface Service {
  id: number;
  name: string;
}

interface TherapyDefinition {
  id: number;
  name: string;
}
interface Image {
  imageSrc?: string;
  id?: string;
  description?: string;
  checked?: boolean;
  deleted?: boolean;
}

type DiagnosisImageForm = DiagnosticImage & { state?: 'add' | 'delete' | 'update' } & { images: Image[] };

interface DialogState {
  open: boolean;
  diagnosisNoteDialogOpen: boolean;
  therapyDefinitions: TherapyDefinition[];
  diagnosisImageDialogOpen: boolean;
  previewDialogOpen: boolean;
  printFormDialogOpen: boolean;
  diagnosisImages: DiagnosisImageForm[];
  selectedImageId: number | null;
  selectedDentalDiagnosisId: number | null;
  selectedDiagnosisImage: DiagnosisImageForm | null;
  diagnosisSetting?: DiagnosisSetting;
  dentalDetail: Omit<DentalInfo, 'dentalDiagnosisList'> & { dentalDiagnosisList?: (DentalDiagnosis & State)[] };
  services: Service[];
  notes: { [key: string | number]: string };
  attachments: (StorageResource & State & { file?: File })[];
  onCloseDiagnosisImageDialog: () => void;
  onOpenDiagnosisImageDialog: (selectedDiagnosisImage?: DiagnosisImageForm) => void;
  onClosePrintFormDialog: () => void;
  onOpenPrintFormDialog: (selectedDiagnosisImage?: DiagnosisImageForm) => void;
  onCloseDialog: () => void;
  onOpenDialog: (diagnosisSetting?: DiagnosisSetting) => void;
  setDentalDetail: (dentalDetail: DentalInfo) => void;
  setAttachment: (resources: StorageResource[]) => void;
  addDiagnosis: (diagnosis: DiagnosisSetting & { display: string; code: string }) => void;
  deleteDiagnosis: (index: number) => void;
  addAttachments: (documents: FileList) => void;
  deleteAttachment: (fileId: number) => void;
  completeDentalDiagnosis: (diagnosisId: number) => void;
  addService: (serviceId: Service) => void;
  deleteService: (serviceId: number) => void;
  setDentalDiagnosisNote: (note: string | undefined) => void;
  setDiagnosisSetting: (diagnosisSetting: DiagnosisSetting) => void;
  onDiagnosisNoteDialogClose: () => void;
  onDiagnosisNoteDialogOpen: (selectedDentalDiagnosisId: number) => void;
  onClosePreviewDialog: () => void;
  onOpenPreviewDialog: (selectedImageId: number) => void;
  addTherapyDefinition: (therapyDefinitionId: TherapyDefinition) => void;
  deleteTherapyDefinition: (therapyDefinitionId: number) => void;
  addDiagnosisImage: (diagnosisImage: DiagnosisImageForm) => void;
  deleteDiagnosisImage: (diagnosisImageId: number) => void;
  addDiagnosisImages: (diagnosisImages: DiagnosticImage[]) => void;
  updateDiagnosisImage: (
    diagnosisImage: DiagnosticImage & { state?: 'add' | 'delete' | 'update' } & { images: Image[] },
  ) => void;
  setSelectedDiagnosisImage: (images: Image[]) => void;
}

export const useDiagnosisDialogState = create(
  immer<DialogState>((set) => ({
    open: false,
    diagnosisNoteDialogOpen: false,
    previewDialogOpen: false,
    printFormDialogOpen: false,
    diagnosisImages: [],
    diagnosisImageDialogOpen: false,
    selectedDentalDiagnosisId: null,
    selectedImageId: null,
    notes: {},
    selectedDiagnosisImage: null,
    dentalDetail: { dentalDiagnosisList: [] },
    attachments: [],
    services: [],

    therapyDefinitions: [],
    onCloseDialog: () =>
      set((state) => {
        state.open = false;
        state.attachments = [];
        state.services = [];
        state.therapyDefinitions = [];
        state.dentalDetail = { dentalDiagnosisList: [] };
        state.notes = {};
        state.diagnosisImages = [];
      }),
    onOpenDialog: (diagnosisSetting?: DiagnosisSetting) =>
      set((state) => {
        state.open = true;
        if (diagnosisSetting) {
          state.diagnosisSetting = diagnosisSetting;
        }
      }),
    setDentalDetail: (dentalDetail) =>
      set((state) => {
        state.dentalDetail = dentalDetail;

        //set notes
        const notes = dentalDetail.dentalDiagnosisList?.reduce<{ [key: string | number]: string }>(
          (result, cur) => ({ ...result, [cur.id!]: cur.note || '' }),
          {},
        );
        if (notes) {
          state.notes = notes;
        }
      }),
    addDiagnosis: (diagnosisFormData) =>
      set((state) => {
        if (diagnosisFormData.code) {
          state.dentalDetail.dentalDiagnosisList?.push({
            position: diagnosisFormData.position,
            objectType: diagnosisFormData.dentalGroup,
            objectDetail: diagnosisFormData.dentalGroupDetail,
            createdTime: moment(),
            code: diagnosisFormData.code,
            display: diagnosisFormData.display,
            status: DENTAL_DIAGNOSIS_STATUS.PROCESSING.value,
            id: uuidv4(),
            state: 'add',
          });
        } else if (diagnosisFormData.display) {
          state.dentalDetail.dentalDiagnosisList?.push({
            position: diagnosisFormData.position,
            objectType: diagnosisFormData.dentalGroup,
            objectDetail: diagnosisFormData.dentalGroupDetail,
            createdTime: moment(),
            display: diagnosisFormData.display,
            status: DENTAL_DIAGNOSIS_STATUS.PROCESSING.value,
            id: uuidv4(),
            state: 'add',
          });
        }
      }),
    setAttachment: (resources) =>
      set((state) => {
        state.attachments = resources;
      }),
    deleteDiagnosis: (id: number) =>
      set((state) => {
        const index = state.dentalDetail.dentalDiagnosisList?.findIndex((diagnosis) => diagnosis.id === id) ?? -1;
        const current = state.dentalDetail.dentalDiagnosisList?.[index];
        if (current) {
          if (current.state === 'add') {
            state.dentalDetail.dentalDiagnosisList?.splice(index, 1);
          } else {
            current.status = DENTAL_DIAGNOSIS_STATUS.DELETED.value;
          }
        }
      }),

    completeDentalDiagnosis: (diagnosisId: number) =>
      set((state) => {
        const index =
          state.dentalDetail.dentalDiagnosisList?.findIndex((diagnosis) => diagnosis.id === diagnosisId) ?? -1;
        const current = state.dentalDetail.dentalDiagnosisList?.[index];
        if (current) {
          current.status = DENTAL_DIAGNOSIS_STATUS.FINISHED.value;
        }
      }),
    addAttachments: (documents: FileList) =>
      set((state) => {
        state.attachments.push(
          ...Array.from(documents).map((file) => {
            return {
              fileName: file.name,
              state: 'add' as const,
              description: '',
              createdTime: moment(new Date()),
              id: uuidv4(),
              file,
            };
          }),
        );
      }),
    deleteAttachment: (resourceId: number) =>
      set((state) => {
        const index = state.attachments.findIndex((diagnosis) => diagnosis.id === resourceId);
        const current = state.attachments[index];
        if (current.state === 'add') {
          state.attachments.splice(index, 1);
        } else {
          current.state = 'delete';
        }
      }),
    addService: (service) =>
      set((state) => {
        state.services.push(service);
      }),
    deleteService: (deleteServiceId) =>
      set((state) => {
        const index = state.services.findIndex((service) => service.id === deleteServiceId);
        state.services.splice(index, 1);
      }),
    setDentalDiagnosisNote: (note) =>
      set((state) => {
        if (state.selectedDentalDiagnosisId) {
          state.notes[state.selectedDentalDiagnosisId] = note || '';
        }
      }),
    setDiagnosisSetting: (diagnosisSetting) =>
      set((state) => {
        state.diagnosisSetting = diagnosisSetting;
      }),
    onDiagnosisNoteDialogClose: () => {
      set((state) => {
        state.diagnosisNoteDialogOpen = false;
        state.selectedDentalDiagnosisId = null;
      });
    },
    onDiagnosisNoteDialogOpen: (selectedDentalDiagnosisId) =>
      set((state) => {
        state.diagnosisNoteDialogOpen = true;
        state.selectedDentalDiagnosisId = selectedDentalDiagnosisId;
      }),
    onClosePreviewDialog: () =>
      set((state) => {
        state.previewDialogOpen = false;
        state.selectedImageId = null;
      }),
    onOpenPreviewDialog: (selectedImageId) =>
      set((state) => {
        state.previewDialogOpen = true;
        state.selectedImageId = selectedImageId;
      }),
    addTherapyDefinition: (therapyDefinition) =>
      set((state) => {
        state.therapyDefinitions.push(therapyDefinition);
      }),
    deleteTherapyDefinition: (deleteTherapyDefinitionId) =>
      set((state) => {
        const index = state.therapyDefinitions.findIndex(
          (therapyDefinition) => therapyDefinition.id === deleteTherapyDefinitionId,
        );
        state.therapyDefinitions.splice(index, 1);
      }),
    onCloseDiagnosisImageDialog: () =>
      set((state) => {
        state.diagnosisImageDialogOpen = false;
        state.selectedDiagnosisImage = null;
      }),
    onOpenDiagnosisImageDialog(selectedDiagnosisImage) {
      set((state) => {
        if (selectedDiagnosisImage) {
          state.selectedDiagnosisImage = selectedDiagnosisImage;
        } else {
          state.selectedDiagnosisImage = null;
        }
        state.diagnosisImageDialogOpen = true;
      });
    },
    onClosePrintFormDialog() {
      set((state) => {
        state.printFormDialogOpen = false;
        state.selectedDiagnosisImage = null;
      });
    },
    onOpenPrintFormDialog(selectedDiagnosisImage) {
      set((state) => {
        if (selectedDiagnosisImage) {
          state.selectedDiagnosisImage = selectedDiagnosisImage;
        }
        state.printFormDialogOpen = true;
      });
    },
    addDiagnosisImage(diagnosisImage) {
      set((state) => {
        state.diagnosisImages.push({ ...diagnosisImage, id: uuidv4(), state: 'add' });
      });
    },
    deleteDiagnosisImage(diagnosisImageId) {
      set((state) => {
        const index =
          state.diagnosisImages.findIndex((diagnosisImage) => diagnosisImageId === diagnosisImage?.id) ?? -1;
        const diagnosisImage = state.diagnosisImages[index];
        if (diagnosisImage) {
          if (diagnosisImage.state === 'add') {
            state.diagnosisImages.splice(index, 1);
          } else {
            diagnosisImage.state = 'delete';
          }
        }
      });
    },
    addDiagnosisImages(diagnosisImages) {
      set((state) => {
        state.diagnosisImages = diagnosisImages.map((diagnosisImages) => ({ ...diagnosisImages, images: [] }));
      });
    },
    updateDiagnosisImage(diagnosisImage) {
      set((state) => {
        const index = state.diagnosisImages.findIndex((ele) => ele.id === diagnosisImage.id) ?? -1;
        const currentDiagnosisImage = state.diagnosisImages[index];
        if (currentDiagnosisImage) {
          currentDiagnosisImage.id = diagnosisImage.id;
          currentDiagnosisImage.conclusion = diagnosisImage.conclusion;
          currentDiagnosisImage.examDepartment = diagnosisImage.examDepartment;
          currentDiagnosisImage.images = diagnosisImage.images;
          if (currentDiagnosisImage.state !== 'add') {
            currentDiagnosisImage.state = 'update';
          }
        }
      });
    },
    setSelectedDiagnosisImage(images) {
      set((state) => {
        if (state.selectedDiagnosisImage) {
          state.selectedDiagnosisImage.images = images;
        }
      });
    },
  })),
);

export const useDiagnosisDialogSelector = createSelectors(useDiagnosisDialogState);
