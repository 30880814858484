import { Box, Button, Dialog, DialogActions, DialogContent, DialogProps, Slide, Typography } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import makeStyles from '@mui/styles/makeStyles';
import React, { forwardRef, useCallback, useImperativeHandle, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const TransitionBox = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const useStyles = makeStyles(() => ({
  paper: { minWidth: 460 },
  button: { minWidth: 110 },
}));

interface Params {
  title?: React.ReactNode | string;
  content?: React.ReactNode | string;
  dialogProps?: Omit<DialogProps, 'open'>;
  okId?: React.ReactNode | string;
  cancelId?: React.ReactNode | string;
  warning?: boolean;
  open?: boolean;
  close?: () => void;
  onOK?: () => void;
  onCancel?: () => void;
}
export interface ConfirmDialogParams {
  promptConfirmation: (setting?: Params) => Promise<unknown>;
  close: () => void;
  open: boolean;
  setOptions: React.Dispatch<React.SetStateAction<Props>>;
  resetSetting: () => void;
}

interface Props extends Params {}

const ConfirmDialog = forwardRef((props: Props, ref) => {
  const { open, close: onCloseProps, onOK, onCancel } = props;
  const classes = useStyles();
  const [settings, setOptions] = useState<Props>(props);
  const [confirmDialogResolveRef, setConfirmDialogResolveRef] = React.useState<
    | {
        resolve?: (confirm: boolean) => void; //Đố biết sao phải để trong Object
      }
    | undefined
  >();
  const { warning, title, content, dialogProps, okId, cancelId } = settings;

  const promptConfirmation = useCallback(async (setting?: Omit<Props, 'children'>) => {
    setting && setOptions((old) => ({ ...old, ...setting }));
    const confirmTmp = await new Promise((resolve) => {
      setConfirmDialogResolveRef({ resolve });
    });
    return confirmTmp;
  }, []);

  const close = useCallback(() => {
    setConfirmDialogResolveRef(undefined);
  }, []);

  const params: ConfirmDialogParams = useMemo(
    () => ({
      promptConfirmation,
      close,
      open: confirmDialogResolveRef?.resolve !== undefined,
      setOptions: (val) => setOptions((old) => ({ ...old, ...val })),
      resetSetting: () => setOptions(props),
    }),
    [promptConfirmation, close, confirmDialogResolveRef?.resolve, props],
  );

  const onClose = useCallback(() => {
    confirmDialogResolveRef?.resolve && confirmDialogResolveRef?.resolve(false);
    onCloseProps && onCloseProps();
    onCancel && onCancel();
  }, [confirmDialogResolveRef, onCancel, onCloseProps]);

  const onAccept = useCallback(() => {
    confirmDialogResolveRef?.resolve && confirmDialogResolveRef?.resolve(true);
    onCloseProps && onCloseProps();
    onOK && onOK();
  }, [confirmDialogResolveRef, onCloseProps, onOK]);

  const openDialog = confirmDialogResolveRef?.resolve !== undefined || !!open;

  useImperativeHandle(ref, () => params);

  return (
    <Dialog
      {...dialogProps}
      open={openDialog}
      classes={{ paper: classes.paper, ...dialogProps?.classes }}
      onClose={onClose}
      TransitionProps={{
        ...dialogProps?.TransitionProps,
        onExited: () => setOptions(props),
      }}
      keepMounted={false}
      TransitionComponent={TransitionBox}
    >
      <Box style={{ padding: 16, display: 'flex', alignItems: 'flex-end' }}>
        <Box flex={1}>
          <Typography variant="h6" color="primary">
            {title}
          </Typography>
        </Box>
      </Box>
      <DialogContent style={{ padding: 16 }}>
        <Typography variant="body1" component="div">
          {content}
        </Typography>
      </DialogContent>
      <DialogActions style={{ padding: 16 }}>
        <Button variant="outlined" color="primary" classes={{ root: classes.button }} onClick={onClose}>
          {typeof cancelId !== 'string' && typeof cancelId !== 'undefined' ? (
            cancelId
          ) : (
            <FormattedMessage id={cancelId || 'cancel'} />
          )}
        </Button>
        <Box marginLeft={2}>
          <Button
            color={warning ? 'error' : 'primary'}
            variant="contained"
            classes={{ root: classes.button }}
            onClick={onAccept}
          >
            {typeof okId !== 'string' && typeof okId !== 'undefined' ? (
              okId
            ) : (
              <FormattedMessage id={okId || (warning ? 'delete' : 'ok')} />
            )}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
});

export default ConfirmDialog;
