import { Box, Button, Stack } from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import { useIntl } from 'react-intl';
import CompleteExamBlock from 'modules/common/component/Encounter/block/CompleteExam/CompleteExamBlock';
import { Encounter } from 'modules/schema';
import DoctorExchangeBlock from 'modules/common/component/Encounter/block/DoctorExchange/DoctorExchangeBlock';
import { useDialog, useFetch } from 'modules/common/hook';
import PrintMedicalFormDialog from './PrintDialog/PrintMedicalFormDialog';
import MedicalRecordPrintFormDialog from './PrintDialog/MedicalRecordPrintFormDialog';
import { API_SERVER } from 'modules/common/api';
import { INDICATION_REQUEST_TYPE } from 'modules/common/constants';
import GroupCollapseButton from '../../GroupCollapseButton';
import { EncounterContext } from '../const';
import React from 'react';

interface EncounterActionsProps {
  encounter: Encounter;
  readonly: boolean;
}

const EncounterActions = ({ encounter, readonly }: EncounterActionsProps) => {
  const intl = useIntl();
  const [openMedicalFormDialog, onOpenMedicalFormDialog, onCloseMedicalFormDialog] = useDialog();
  const [openMedicalRecordDialog, onOpenMedicalRecordDialog, onCloseMedicalRecordDialog] = useDialog();
  const { data: dataIndication } = useFetch(
    API_SERVER.indicationRequest.getIndicationRequestByReferenceId(encounter?.id!, INDICATION_REQUEST_TYPE.ENCOUNTER),
    { enabled: encounter?.id },
  );

  const encounterContext = React.useContext(EncounterContext);
  // When the dialog is open check counter > 0 then call the API get encounter data and reset the counter.
  React.useEffect(() => {
    if (encounterContext?.counter! > 0 && (openMedicalFormDialog || openMedicalRecordDialog)) {
      encounterContext?.revalidateEncounter();
      encounterContext?.setCounter(0);
    }
  }, [encounterContext, openMedicalFormDialog, openMedicalRecordDialog]);

  return (
    <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
      <DoctorExchangeBlock encounter={encounter} readonly={readonly} />
      <GroupCollapseButton titleIcon={<PrintIcon sx={{ color: '#307BFF' }} />} variant="secondary">
        <Box width="200px">
          <Button
            variant="text"
            onClick={onOpenMedicalFormDialog}
            fullWidth
            sx={{ padding: '10px 20px', justifyContent: 'flex-start' }}
          >
            {intl.formatMessage({ id: 'encounterDetail.printMedicalForm' })}
          </Button>
          <Button
            variant="text"
            onClick={onOpenMedicalRecordDialog}
            fullWidth
            sx={{ padding: '10px 20px', justifyContent: 'flex-start' }}
          >
            {intl.formatMessage({ id: 'encounterDetail.medicalRecordPrint' })}
          </Button>
        </Box>
      </GroupCollapseButton>
      <Box width="220px">
        <CompleteExamBlock encounter={encounter} readonly={readonly} dataIndication={dataIndication || []} />
      </Box>
      <PrintMedicalFormDialog
        open={openMedicalFormDialog}
        onClose={onCloseMedicalFormDialog}
        encounter={encounterContext?.encounter}
      />
      <MedicalRecordPrintFormDialog
        open={openMedicalRecordDialog}
        onClose={onCloseMedicalRecordDialog}
        encounter={encounterContext?.encounter}
      />
    </Stack>
  );
};

export default EncounterActions;
