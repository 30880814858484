import { LABORATORY_REFERENCE_RESULT_TYPE } from 'modules/common/apiConstants';
import { IndicationLaboratory } from 'modules/schema';
import DataInputLabDetail from './DataInputLabDetail';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { INDICATION_REQUEST_TYPE } from 'modules/common/constants';
import ListLabDetail from './ListLabDetail';
import StatisticLabResult from './StatisticLabResult';

interface Props {
  indicationLaboratory: IndicationLaboratory;
  patientId: number;
  type: keyof typeof INDICATION_REQUEST_TYPE;
}

const IndicationLabResult = ({ indicationLaboratory, patientId, type }: Props) => {
  const { data: indicationHistories } = useFetch(
    API_SERVER.indicationLaboratory.getAllIndicationLaboratoryHistory({
      type,
      laboratoryReferenceId: indicationLaboratory.laboratoryReferenceDetailObject?.id!,
      patientId,
    }),
  );
  if (
    indicationLaboratory?.laboratoryReferenceDetailObject?.resultType === LABORATORY_REFERENCE_RESULT_TYPE.DATA_INPUT
  ) {
    return <DataInputLabDetail indicationHistories={indicationHistories || []} />;
  }
  if (indicationLaboratory?.laboratoryReferenceDetailObject?.resultType === LABORATORY_REFERENCE_RESULT_TYPE.LIST) {
    return <ListLabDetail indicationHistories={indicationHistories || []} />;
  }
  return (
    <StatisticLabResult
      indicationHistories={indicationHistories || []}
      unit={indicationLaboratory?.laboratoryReferenceDetailObject?.unit || ''}
    />
  );
};

export default IndicationLabResult;
