import { Delete } from '@mui/icons-material';
import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, Grid, IconButton, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import DialogCustom from 'modules/common/component/DialogCustom';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE } from 'modules/common/constants';
import ImagePreview from 'modules/common/component/ImagePreview';
import { useEffect } from 'react';
import { RadioImageSize } from 'modules/common/component/RadioImageSize';
import 'modules/common/print.scss';
import { DiagnosticImage, Encounter, Patient, StorageResource } from 'modules/schema';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    '& .hiddenPage': { display: 'none' },
    [`@media print`]: {
      '& .newPage': { pageBreakBefore: 'always' },
      '& .hiddenPage': { display: 'block' },
      '& .delete-btn': { display: 'none' },
      padding: 0,
      '& th,tr,td': {
        backgroundColor: 'unset',
      },
    },
  },
});

interface PrintContentProps {
  diagnosticImage: DiagnosticImage;
  patient: Patient;
  images: StorageResource[];
  extracInfo?: {
    diagnosis?: string;
    picName?: string;
  };
  encounter: Encounter;
}
interface Props {
  open: boolean;
  onClose: () => void;
  diagnosticImage: DiagnosticImage;
  patient: Patient;
  images: StorageResource[];
  extracInfo?: {
    diagnosis?: string;
    picName?: string;
  };
  encounter: Encounter;
}

export const PrintContent = (props: PrintContentProps) => {
  const { diagnosticImage, patient, images, extracInfo, encounter } = props;
  const [imagesList, setImageList] = React.useState(images);

  useEffect(() => {
    setImageList(images);
  }, [images]);
  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
            {diagnosticImage?.name}
          </Typography>
        </HeaderPrintForm>
        <Grid container>
          <Grid item xs={12} flexWrap={'wrap'} display="flex" columnGap={4}>
            <Box display="flex">
              <Typography variant="subtitle1">
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp; <Typography variant="body1">{patient?.name}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1">
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{moment(patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1">
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                {patient?.gender && <FormattedMessage id={patient?.gender?.toLowerCase()} />}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="subtitle1">
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                {patient?.patientAddressList?.find((ele) => ele?.type === PATIENT_ADDRESS_TYPE.home)?.address}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} flexWrap={'wrap'} display="flex" columnGap={4}>
            <Box display="flex">
              <Typography variant="subtitle1">
                <FormattedMessage id="common.title.clinicalExamination" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">{extractHTMLContent(encounter?.clinicalExamination || '')}</Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [
    diagnosticImage?.name,
    encounter?.clinicalExamination,
    patient?.dob,
    patient?.gender,
    patient?.name,
    patient?.patientAddressList,
  ]);

  const content = React.useMemo(() => {
    return (
      <Box sx={{ paddingRight: 1 }}>
        <Divider sx={{ marginY: 1 }} />
        <RadioImageSize>
          {(imageSize) => (
            <Grid container spacing={1.5}>
              {imagesList?.map((item, index) => {
                return (
                  <Grid
                    key={index}
                    item
                    xs={imageSize}
                    textAlign="center"
                    display="flex"
                    flexDirection={'column'}
                    position="relative"
                  >
                    <Box>
                      <ImagePreview
                        alt=""
                        imageId={item.id}
                        style={{
                          width: imageSize === 12 ? '84%' : '100%',
                          objectFit: 'cover',
                        }}
                      />
                      <Typography variant="body1" marginTop={1}>
                        {item?.description}&nbsp;
                      </Typography>

                      <IconButton
                        sx={{ position: 'absolute', top: 20, right: 8, background: 'white' }}
                        className="delete-btn"
                        onClick={() => {
                          setImageList(imagesList.filter((ele) => ele?.id !== item?.id));
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Box>
                  </Grid>
                );
              })}
            </Grid>
          )}
        </RadioImageSize>
        <Divider sx={{ marginY: 1 }} />
        <Typography variant="h6" marginBottom={1}>
          <FormattedMessage id="description" />
        </Typography>
        <Box dangerouslySetInnerHTML={{ __html: diagnosticImage?.examDepartment || '' }} />
        <Divider sx={{ marginY: 1 }} />
        <Typography variant="h6" marginBottom={1}>
          <FormattedMessage id="conclusion" />
        </Typography>
        <Box dangerouslySetInnerHTML={{ __html: diagnosticImage?.conclusion || '' }} />
      </Box>
    );
  }, [diagnosticImage?.examDepartment, diagnosticImage?.conclusion, imagesList]);

  const footerSection = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 12 }}>
          <Grid container spacing={0}>
            <Grid item xs={6} textAlign="center"></Grid>

            <Grid item xs={6} textAlign="center">
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id="printForm.date"
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="examDoctor" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
              <Typography variant="body1">{extracInfo?.picName}</Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [extracInfo?.picName]);

  return (
    <>
      <Box style={{ marginBottom: 15 }}>
        {header}
        {content}
        {footerSection}
      </Box>
    </>
  );
};

const PrintFormDialog = (props: Props) => {
  const { onClose, open, diagnosticImage, images, patient, extracInfo, encounter } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();

  return (
    <DialogCustom
      className="camera-print-form-dialog"
      open={open}
      onClose={onClose}
      maxWidth="lg"
      PaperProps={{
        style: { width: 1126 },
      }}
      title={'medicalPrint.result'}
      footer={
        <ReactToPrint
          trigger={() => (
            <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
              <FormattedMessage id="report.imagePrint" />
            </Button>
          )}
          content={() => refPrintComponent.current}
        />
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <PrintContent
          diagnosticImage={diagnosticImage}
          patient={patient}
          images={images}
          extracInfo={extracInfo}
          encounter={encounter}
        />
      </Box>
    </DialogCustom>
  );
};

export default PrintFormDialog;
