import { ISchemaFields, ISchemaForm, SchemaType } from '../../../../common/SchemaForm/utils';
import { LICENSE_TYPES } from '../../../constants';

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl, appState, listDoctor = [] } = formProps || {};
  const { setValue, clearErrors } = methods;
  const disableLicenseType = valuesField.department?.value !== 'K39' && valuesField.department?.value !== 'K47';
  const required = valuesField.licenseType !== LICENSE_TYPES.collaborate;
  return {
    department: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'department.code' }),
      placeholder: intl.formatMessage({ id: 'department.codeEnter' }),
      options: appState.common.departments,
      register: { required: true },
      propsWrapper: { xs: 6 },
      onChange: () => {
        if (!disableLicenseType) {
          setValue('licenseType', null);
        }
      },
      getOptionLabel: (option) => `${option.label} - ${option.value}`,
    },
    licenseType: {
      type: 'multiple-radio',
      label: intl.formatMessage({ id: 'department.licenseType' }),
      options: [
        { value: LICENSE_TYPES.own, label: 'department.ownLicense' },
        { value: LICENSE_TYPES.collaborate, label: 'department.collaborateLicense', disabled: disableLicenseType },
      ],
      row: true,
      register: { required: true },
      propsWrapper: { xs: 12 },
      onChange: () => {
        setValue('doctor', null);
        setValue('collaborateName', null);
        setValue('collaborateContract', null);
        clearErrors(['doctor', 'collaborateName', 'collaborateContract']);
      },
    },
    pic: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'department.doctor' }),
      placeholder: intl.formatMessage({ id: 'department.doctorEnter' }),
      options: listDoctor,
      register: { required: required },
      required: required,
      propsWrapper: { xs: 12 },
      disabled: !required,
    },
    collaborateName: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'department.collaborateName' }),
      placeholder: intl.formatMessage({ id: 'department.collaborateNameEnter' }),
      register: { required: !required },
      required: !required,
      propsWrapper: { xs: 6 },
      disabled: required,
    },
    collaborateContract: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'department.collaborateContract' }),
      placeholder: intl.formatMessage({ id: 'department.collaborateContractEnter' }),
      register: { required: !required },
      required: !required,
      propsWrapper: { xs: 6 },
      disabled: required,
    },
  } as SchemaType;
};
export const schemaFormAdminDepartment: ISchemaForm = {
  fields: fieldSchema,
};
