import { Box, Typography } from '@mui/material';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { some } from 'modules/common/constants';
import CancelIcon from '@mui/icons-material/Cancel';
import { API_SERVER } from 'modules/common/api';
import { ROUTES } from 'modules/layout/router';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import { THERAPY_STATUS } from 'modules/examDoctor/constants';
import NoteFormDialog from 'modules/examDoctor/component/detail/info/therapy/NoteFormDialog';
import { Therapy } from 'modules/schema';
import { useUpdateMutate } from 'modules/common/hook';
import { THERAPY_STATUS_COLOR } from 'modules/common/apiConstants';
import { NumericFormatText } from 'modules/common/utils';

interface Props {
  data?: Therapy[];
  cancelTherapy: (item: Therapy) => void;
  readOnly?: boolean;
  revalidate: () => Promise<boolean>;
  isAuthor?: boolean;
}

const TherapyTable = ({ data, cancelTherapy, readOnly, revalidate, isAuthor }: Props) => {
  const [noteData, setNoteData] = useState<some | undefined>();

  // update ghi chu
  const updateNoteMutate = useUpdateMutate({
    onSuccess: revalidate,
  });
  const updateNote = useCallback(
    async (value) => {
      updateNoteMutate({
        url: API_SERVER.therapy.note(value?.id),
        method: 'put',
        data: {
          data: value?.note,
        },
      });
      setNoteData(undefined);
    },
    [updateNoteMutate],
  );

  const columns = useMemo(() => {
    return [
      {
        title: 'therapy.name',
        dataIndex: 'name',
        render: (record) => {
          return (
            <Typography
              sx={{
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              fontStyle={'normal'}
              fontWeight={500}
              fontSize={'16px'}
              lineHeight={'24px'}
              letterSpacing={'0.5px'}
              color="primary"
              onClick={() => {
                window.open(`${ROUTES.therapyDetail.gen(record?.id!)}`, '_blank');
              }}
            >
              {record.name}
            </Typography>
          );
        },
      },
      {
        title: 'therapy.quantity',
        align: 'left',
        dataIndex: 'numberSession',
        render: (record) => record.numberSession && <FormattedNumber value={record.numberSession} />,
      },
      {
        title: 'therapy.packagePrice',
        align: 'left',
        dataIndex: 'packagePrice',
        render: (record) => {
          return <Typography variant="body1">{NumericFormatText(record.packagePrice)}</Typography>;
        },
      },
      {
        title: 'therapy.totalPriceAllSessions',
        align: 'left',
        dataIndex: 'totalPrice',
        render: (record) => {
          return <Typography variant="body1">{NumericFormatText(record.totalPrice)}</Typography>;
        },
      },
      {
        title: 'therapy.description',
        align: 'left',
        dataIndex: 'description',
        render: (record) => <Typography variant="body1">{record.description}</Typography>,
      },
      {
        title: 'therapy.status',
        align: 'left',
        dataIndex: 'status',
        render: (record) => {
          const label = THERAPY_STATUS.find((v) => v.value === record.status)?.label;
          return (
            <Typography
              noWrap
              sx={{
                color:
                  record.status === THERAPY_STATUS_COLOR.PROCESSING.value
                    ? 'warning.main'
                    : record.status === THERAPY_STATUS_COLOR.FINISHED.value
                    ? 'success.main'
                    : record.status === THERAPY_STATUS_COLOR.CANCELLED.value
                    ? 'error.main'
                    : 'primary.main',
              }}
            >
              <FormattedMessage id={label} />
            </Typography>
          );
        },
      },
      {
        title: 'action',
        width: 120,
        hidden: readOnly,
        render: (record) => {
          return (
            <>
              {record.status !== THERAPY_STATUS_COLOR.CANCELLED.value &&
                record.status !== THERAPY_STATUS_COLOR.FINISHED.value && (
                  <IconButtonTitle
                    disabled={record.status === THERAPY_STATUS_COLOR.CANCELLED.value}
                    title="encounterInfo.cancelTherapy"
                    size="small"
                    onClick={() => {
                      cancelTherapy({
                        ...record,
                        status: THERAPY_STATUS_COLOR.CANCELLED.value,
                      });
                    }}
                  >
                    <CancelIcon sx={{ color: '#78909C', width: '100%' }} />
                  </IconButtonTitle>
                )}
              <IconButtonTitle
                title="encounterInfo.takeNote"
                size="small"
                onClick={() => {
                  setNoteData({
                    ...record,
                    readOnly: record.status === THERAPY_STATUS_COLOR.CANCELLED.value,
                  });
                }}
              >
                {record.note ? (
                  <DescriptionSharpIcon fontSize="small" sx={{ color: '#78909C', width: '100%' }} />
                ) : (
                  <InsertDriveFileSharpIcon fontSize="small" sx={{ color: '#78909C', width: '100%' }} />
                )}
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns<Therapy>[];
  }, [readOnly, cancelTherapy]);

  if (!data) {
    return null;
  }

  return (
    <Box padding={1}>
      <TableCustom dataSource={data} columns={columns} />
      <NoteFormDialog
        open={!!noteData}
        onClose={() => {
          setNoteData(undefined);
        }}
        readOnly={readOnly || noteData?.readOnly}
        formData={noteData}
        onSubmit={updateNote}
      />
    </Box>
  );
};

export default TherapyTable;
