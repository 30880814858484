import { Box, Paper, PaperProps, Typography } from '@mui/material';
import React, { memo } from 'react';
import { some } from '../../../constants';
import useGeneralHook from '../../../hook/useGeneralHook';
import SchemaView from '../../SchemaView';
import { ISchemaForm } from '../../utils';
import makeStyles from '@mui/styles/makeStyles';

const useStyles = makeStyles(() => ({
  paper: {
    padding: '16px 12px',
  },
}));
export interface PropsSectionElement {
  schema?: ISchemaForm;
  title?: React.ReactNode;
  paperProps?: PaperProps;
}
interface Props extends PropsSectionElement {
  name: string;
}

const SectionElement = (props: Props | some) => {
  const { name, schema, title, paperProps, ...rest } = props;
  const mainProps = useGeneralHook();
  const classes = useStyles();
  return (
    <Paper className={classes.paper} {...paperProps}>
      {title && (
        <Box marginBottom={1.5}>
          <Typography variant="h6">{title}</Typography>
        </Box>
      )}
      <SchemaView fieldName={name} schema={schema} formProps={{ ...rest, ...mainProps }} />
    </Paper>
  );
};

export default memo(SectionElement);
