import * as React from 'react';
import { some } from '../../../../../common/constants';
import DialogCustom from '../../../../../common/component/DialogCustom';
import { Box, InputLabel, Typography } from '@mui/material';
import LabTable from './LabTable';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from 'modules/common/api';
import { useFetch } from 'modules/common/hook';

interface Props {
  indication?: some;
  onClose?: () => void;
  open: boolean;
}
const LabResultDialog: React.FunctionComponent<Props> = (props) => {
  const { onClose, indication, open } = props;
  const { data: dataLab } = useFetch(API_SERVER.indicationLaboratory.getList(indication?.id), {
    enabled: !!indication,
  });
  return (
    <DialogCustom
      open={open}
      maxWidth="lg"
      onClose={onClose}
      disableCloseOutSide
      disableEscapeKeyDown
      PaperProps={{
        style: { width: 1460 },
      }}
      title={'therapyIndication.laboratory.result.dialog.header'}
    >
      <Box padding={2}>
        <Box flex={5} display="flex">
          {dataLab && (
            <>
              <Box marginBottom={3}>
                <InputLabel>
                  <Typography variant="subtitle1" color="textSecondary" noWrap style={{ lineHeight: 1 }}>
                    <FormattedMessage id="therapyIndication.laboratory.result.label.service" />
                  </Typography>
                </InputLabel>
                <Typography variant="body1">{indication?.serviceName}</Typography>
              </Box>
              <Box marginLeft={10}>
                <InputLabel>
                  <Typography variant="subtitle1" color="textSecondary" noWrap style={{ lineHeight: 1 }}>
                    <FormattedMessage id="doctor" />
                  </Typography>
                </InputLabel>
                <Typography variant="body1">{indication?.picName}</Typography>
              </Box>
              <Box marginX={10}>
                <InputLabel>
                  <Typography variant="subtitle1" color="textSecondary" noWrap style={{ lineHeight: 1 }}>
                    <FormattedMessage id="note" />
                  </Typography>
                </InputLabel>
                <Typography variant="subtitle1">{indication?.note}</Typography>
              </Box>
            </>
          )}
        </Box>
        <LabTable key={indication?.id} data={dataLab || {}} />
      </Box>
    </DialogCustom>
  );
};

export default LabResultDialog;
