import { Box, InputAdornment, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import * as React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { some } from '../../../common/constants';
import SchemaForm from '../../../common/SchemaForm';
import { SearchParamsFilterRoom } from '../../page/RoomAdminPage';
import RoomTable from './RoomTable';

interface Props {
  data: some;
  loading?: boolean;
  searchParams: some;
  setSearchParams?: (value: SearchParamsFilterRoom) => void;
  setFormData: (value: some) => void;
  onDelete: (value: some) => void;
}

const RoomListTable: React.FunctionComponent<Props> = (props) => {
  const { data, loading, searchParams, setSearchParams, setFormData, onDelete } = props;
  const intl = useIntl();

  const dataChecked = data?.filter((d) => !(searchParams?.name && (!d.items || d.items?.length <= 0)));

  return (
    <>
      <Box padding={2} paddingBottom={1} display="flex" alignItems="center">
        <Box flex={1} display="flex">
          <Typography variant="h6">
            <FormattedMessage id="room.list" />
          </Typography>
        </Box>
        <SchemaForm
          onSubmit={setSearchParams}
          propsWrapper={{ spacing: 1 }}
          schema={{
            fields: {
              name: {
                type: 'text-field',
                placeholder: intl.formatMessage({ id: 'room.search' }),
                InputProps: {
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search color="inherit" />
                    </InputAdornment>
                  ),
                },
                noHelperText: true,
                propsWrapper: { xs: true },
                fullWidth: false,
                showClearBtn: true,
              },
              search: {
                type: 'submitButton',
                color: 'inherit',
                label: intl.formatMessage({ id: 'search' }),
                propsWrapper: { xs: undefined },
              },
            },
            ui: [
              {
                id: 'default',
                propsGridContainer: { spacing: 1 },
                fields: ['name', 'search'],
              },
            ],
          }}
          hideSubmitButton
        />
      </Box>
      <Box padding={2} paddingTop={0} overflow="auto">
        {dataChecked?.map((value: some, index) => {
          return <RoomTable key={index} loading={loading} setFormData={setFormData} onDelete={onDelete} data={value} />;
        })}
      </Box>
    </>
  );
};

export default RoomListTable;
