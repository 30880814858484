import { z } from 'zod';
import { LaboratoryConditionSchema } from './LaboratoryCondition';
import { LaboratorySelectionSchema } from './LaboratorySelection';
import { createObjectKeysEnumSchema } from './common';
import { LABORATORY_REFERENCE_RESULT_TYPE } from 'modules/common/apiConstants';

export const LaboratoryReferenceSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    name: z.string(),
    resultType: createObjectKeysEnumSchema(LABORATORY_REFERENCE_RESULT_TYPE),
    unit: z.string(),
    laboratoryConditionList: z.array(LaboratoryConditionSchema),
    laboratorySelectionList: z.array(LaboratorySelectionSchema),
  })
  .partial();

export type LaboratoryReference = z.infer<typeof LaboratoryReferenceSchema>;
