import { some } from '../../../../common/constants';
import * as React from 'react';
import { Switch, Typography } from '@mui/material';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import QuantityBox from 'modules/common/component/QuantityBox';
import FormControlAutoComplete from 'modules/common/SchemaForm/element/autocomplete/FormControlAutoComplete';
import { flatMap, keyBy } from 'lodash';
import { NumericFormatText } from '../../../../common/utils';

interface Props {
  data?: some[];
  loading?: boolean;
  readOnly?: boolean;
  actionNode?: (item: some, index: number) => React.ReactNode;
  updateQuantity?: (item: some) => void;
  updateMedicalSupplies?: (item: some) => void;
  selection?: some[];
  setSelection?: (item: some[]) => void;
  onChangeIsFreeStatus: (item: some) => void;
  indicationList?: some[];
  medicalSuppliesCategoryList?: some[];
}

const MedicalSuppliesTableList: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    selection,
    loading,
    setSelection,
    actionNode,
    readOnly,
    onChangeIsFreeStatus,
    updateMedicalSupplies,
    indicationList,
    medicalSuppliesCategoryList,
  } = props;
  const { intl } = useGeneralHook();
  const medicalSuppliesCategoryMap = keyBy(
    flatMap(medicalSuppliesCategoryList, (ele) => ele?.items || []),
    'id',
  );
  const columns = React.useMemo(() => {
    return [
      {
        title: 'therapy.medicalSupplies.category',
        render: (record) => {
          return <Typography>{record.medicalSuppliesGroup}</Typography>;
        },
      },
      {
        title: 'therapy.medicalSupplies.name',
        dataIndex: 'medicalSuppliesName',
      },
      {
        title: 'drugUsage.price',
        render: (record) => {
          return (
            <Typography>
              {NumericFormatText(medicalSuppliesCategoryMap[record.medicalSuppliesId]?.supplyPrice)}
            </Typography>
          );
        },
      },
      {
        title: 'therapy.medicalSupplies.quantity',
        dataIndex: 'quantity',
        width: 130,
        render: (record) => {
          return (
            <QuantityBox
              min={1}
              readOnly={readOnly}
              reduceProps={() => ({ disabled: readOnly })}
              addProps={() => ({ disabled: readOnly })}
              value={record.quantity}
              onChange={(value) => {
                updateMedicalSupplies && updateMedicalSupplies({ ...record, quantity: value });
              }}
            />
          );
        },
      },
      {
        title: 'therapy.medicalSupplies.indication',
        dataIndex: 'serviceId',
        width: 350,
        render: (record) => {
          return (
            <FormControlAutoComplete
              options={indicationList}
              value={record.indicationRequest || null}
              onChange={(_, value) => {
                if (value) {
                  updateMedicalSupplies && updateMedicalSupplies({ ...record, indicationRequestId: value.id });
                }
              }}
              placeholder={intl.formatMessage({ id: 'encounterDetail.indicationNameEnter' })}
              getOptionLabel={(option) => option.serviceName}
              readOnly={readOnly}
            />
          );
        },
      },
      {
        title: 'therapyIndication.header.label.isFree',
        dataIndex: 'isFree',
        render: (record) => {
          return (
            <Switch
              checked={!record.isFree}
              disabled={readOnly || record.isPaid}
              onChange={(e) =>
                onChangeIsFreeStatus({
                  ...record,
                  isFree: e.target.checked ? false : true,
                })
              }
            />
          );
        },
      },
      !!actionNode && {
        title: 'action',
        render: (record, index) => {
          return actionNode && actionNode(record, index);
        },
      },
    ] as Columns[];
  }, [
    actionNode,
    medicalSuppliesCategoryMap,
    readOnly,
    updateMedicalSupplies,
    indicationList,
    intl,
    onChangeIsFreeStatus,
  ]);

  return (
    <>
      {!!setSelection ? (
        <TableCustom
          dataSource={data}
          loading={loading}
          columns={columns}
          rowSelection={{
            selectedRowKeys: selection,
            onChange: ({ selectedRows }) => setSelection(selectedRows),
          }}
        />
      ) : (
        <TableCustom dataSource={data} loading={loading} columns={columns} />
      )}
    </>
  );
};

export default MedicalSuppliesTableList;
