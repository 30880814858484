import { Delete } from '@mui/icons-material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import { alpha, Box, ButtonBase, Grid, IconButton } from '@mui/material';
import { grey } from '@mui/material/colors';
import makeStyles from '@mui/styles/makeStyles';
import { memo, useState } from 'react';
import { some } from 'modules/common/constants';
import ImagePreview from 'modules/common/component/ImagePreview';
import ImageZoomIn from 'modules/common/component/ImageZoomIn';
import { API_SERVER } from 'modules/common/api';
import { STORAGE_RESOURCE_TYPE } from 'modules/reception/constants';
import { useParams } from 'react-router';
import { useDeleteMutate } from 'modules/common/hook/useMutate';
import { useFetch } from 'modules/common/hook';

const useStyles = makeStyles(() => ({
  image: {
    '&:hover': {
      '& .action-area': {
        opacity: 1,
      },
    },
    '& .action-area': {
      opacity: 0,
      transition: '0.3s all',
    },
  },
}));

const ImageBox = () => {
  const classes = useStyles();
  const editable = true;
  const [open, setOpen] = useState<undefined | number>(undefined);
  const { patientId } = useParams<{ patientId }>();

  const { data: images, revalidate: imagesRevalidate } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.dentalExamImage,
      referenceId: patientId,
    }),
  );

  const onDeleteImageMutate = useDeleteMutate({
    confirmMessage: 'dental.dentalDetail.dentalImage.message.deleteImage',
    onSuccess: imagesRevalidate,
  });

  return (
    <>
      <Box>
        <Grid container spacing={2} justifyContent={'flex-start'}>
          {images?.map((item, index: number) => {
            return (
              <Grid item key={item?.id} xs={2}>
                <Box position="relative" className={classes.image} sx={{ height: 130, width: '100%' }}>
                  <ButtonBase
                    style={{ background: grey[300], height: '100%', width: '100%' }}
                    onClick={() => setOpen(index)}
                  >
                    <ImagePreview
                      alt=""
                      imageId={item.id}
                      style={{
                        height: '100%',
                        width: '100%',
                        objectFit: 'cover',
                      }}
                    />
                  </ButtonBase>
                  <Box
                    className="action-area"
                    position="absolute"
                    bottom={0}
                    right={0}
                    left={0}
                    top={0}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box display="flex">
                      {editable && (
                        <IconButton
                          size="small"
                          style={{ background: alpha(grey[100], 0.8), marginRight: 16 }}
                          onClick={(e) => {
                            e.stopPropagation();
                            onDeleteImageMutate({
                              url: API_SERVER.storageResource.delete(item?.id!),
                              method: 'delete',
                            });
                          }}
                        >
                          <Delete color="inherit" fontSize="small" />
                        </IconButton>
                      )}
                      <IconButton
                        size="small"
                        style={{ background: alpha(grey[100], 0.8) }}
                        onClick={() => setOpen(index)}
                      >
                        <ZoomInIcon color="inherit" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <ImageZoomIn
        open={open !== undefined}
        onClose={() => setOpen(undefined)}
        images={images?.map((item: some) => ({
          imageId: item.id,
        }))}
        index={open}
      />
    </>
  );
};

export default memo(ImageBox);
