import { z } from 'zod';

export const SpecializedDepartmentSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    code: z.string(),
    licenseType: z.string(),
    pic: z.number(),
    collaborateName: z.string(),
    collaborateContract: z.string(),
    status: z.string(),
    groupId: z.number(),
    resourceId: z.string(),
  })
  .partial();

export type SpecializedDepartment = z.infer<typeof SpecializedDepartmentSchema>;
