import { Box, Paper } from '@mui/material';
import { PRIMARY } from 'colors';
import * as React from 'react';

interface VitalSignItemProps {
  control: React.ReactNode;
  label?: React.ReactNode;
}

const VitalSignItem: React.FunctionComponent<VitalSignItemProps> = ({ control, label }) => {
  return (
    <Paper
      elevation={0}
      style={{ padding: '8px', background: PRIMARY[100] }}
      sx={{
        '@media screen and (max-width: 1366px)': {
          padding: '5px!important',
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        gap={1}
        sx={{
          '@media screen and (max-width: 1366px)': {
            gap: 0,
          },
        }}
      >
        <Box alignSelf="center" minWidth={65}>
          {label}
        </Box>
        <Box flex={1}>{control}</Box>
      </Box>
    </Paper>
  );
};

export default VitalSignItem;
