import { Box, styled } from '@mui/system';
import { Divider, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { FormattedMessage } from 'react-intl';
import DoctorSign from './DoctorSign';
import PatientInfo from './PatientInfo';
import ExtraInfo from './ExtraInfo';
import IndicationTables from './IndicationTables';
import { DentalExam, IndicationLaboratoryList } from 'modules/schema';

interface Props {
  dentalExam: DentalExam;
  indicationLabs: IndicationLaboratoryList[];
}

const PrintFormTitle = styled(Typography)({
  color: '#263238',
  textAlign: 'center',
  fontSize: '28px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '36px',
  textTransform: 'uppercase',
});

const DividerStyled = styled(Divider)({
  marginTop: '20px',
  marginBottom: '10px',
});

const Body = ({ dentalExam, indicationLabs }: Props) => {
  return (
    <Box sx={{ padding: '9px 64px 48px 64px' }}>
      <Stack marginBottom={3}>
        <PrintFormTitle>
          <FormattedMessage id="indication.indicationList.lab.printForm.title" />
        </PrintFormTitle>
      </Stack>
      <PatientInfo dentalExam={dentalExam} />
      <DividerStyled />
      <ExtraInfo dentalExam={dentalExam} />
      <IndicationTables indicationLabs={indicationLabs} />
      <DoctorSign />
    </Box>
  );
};

export default Body;
