import { Delete, Edit } from '@mui/icons-material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { TableCustomCell, TableCustomRow, TableHeaderCell } from 'modules/common/component/TableCustom/element';
import TablePaginationActionsCustom from 'modules/common/component/TableCustom/TablePaginationActionsCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { HookPaginationProps } from 'modules/common/hook/usePaginationHook';
import { INVENTORY_IMPORT_STATUS, MEDICATION_UNIT } from '../inventory/constant';
import { CancelImportIcon, PackageIcon } from 'svg';
import { NumericFormat } from 'react-number-format';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';

// Numeric Format Text
const NumericFomratText = (value: string) => {
  return <NumericFormat thousandSeparator valueIsNumericString displayType="text" value={value} />;
};

const Row: React.FunctionComponent<{
  row: some;
  onUpdate: (value) => void;
  onDelete: (value) => void;
  onProcessImport: (value) => void;
  index: number;
}> = (props) => {
  const { row, onUpdate, onDelete, onProcessImport } = props;
  const [open, setOpen] = React.useState(false);
  const status = INVENTORY_IMPORT_STATUS.find((v) => v.value === row.status)?.label;

  // Get inventory import by id
  const { data: inventoryImportDetail } = useFetch(API_SERVER.importInventory.getById(row?.id), {
    globalLoading: true,
    enabled: row?.id && open,
  });

  return (
    <>
      <TableCustomRow>
        <TableCustomCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            <KeyboardArrowDownIcon
              style={{
                transform: open ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
        </TableCustomCell>
        <TableCustomCell
          onClick={() => {
            onUpdate({ ...row, readOnly: true });
          }}
        >
          <Typography color="primary" style={{ textDecoration: 'underline', cursor: 'pointer' }}>
            <Typography sx={{ fontWeight: '500' }}>{row.code}</Typography>
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography variant="body1" sx={{ fontWeight: '500' }}>
            {(status === 'imported' || status === 'cancelled') &&
              row.importDate &&
              moment(row.importDate, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography sx={{ fontWeight: '500' }}>{row.importBy}</Typography>
        </TableCustomCell>
        <TableCustomCell>
          <Typography
            noWrap
            sx={{
              color:
                status === 'new'
                  ? 'text.secondary'
                  : status === 'processing'
                  ? 'warning.main'
                  : status === 'imported'
                  ? 'success.main'
                  : status === 'cancelled'
                  ? 'error.main'
                  : 'primary.main',
              fontWeight: '500',
            }}
          >
            <FormattedMessage id={`inventory.import.status.` + status} />
          </Typography>
        </TableCustomCell>
        <TableCustomCell style={{ textAlign: 'end' }}>
          {row?.status !== 'CANCELLED' && (
            <>
              {row?.status === 'NEW' && (
                <IconButtonTitle
                  title="inventory.button.process.import"
                  size="small"
                  onClick={() => {
                    onProcessImport(row);
                  }}
                >
                  <PackageIcon />
                </IconButtonTitle>
              )}
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  onUpdate(row);
                }}
              >
                <Edit />
              </IconButtonTitle>
              <IconButtonTitle
                title={row.status === 'IMPORTED' ? 'inventory.label.cancel.import' : 'delete'}
                size="small"
                onClick={() => {
                  onDelete(row);
                }}
              >
                {row.status === 'IMPORTED' && <CancelImportIcon />}
                {row.status === 'NEW' && <Delete />}
              </IconButtonTitle>
            </>
          )}
        </TableCustomCell>
      </TableCustomRow>
      <TableRow style={{ backgroundColor: 'white', padding: 0 }}>
        <TableCustomCell style={{ padding: '0px', border: 'none', backgroundColor: 'inherit' }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Table size="small" aria-label="purchases" style={{ marginTop: 4 }}>
              <TableHead>
                <TableRow>
                  <TableHeaderCell style={{ width: 54 }} />
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.drugCode" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.drugName" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.supplier" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.lot" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="expiredDate" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="quantity" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="inventory.unit" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="price" />
                  </TableHeaderCell>
                  <TableHeaderCell style={{ color: 'grey' }}>
                    <FormattedMessage id="totalPrice" />
                  </TableHeaderCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {inventoryImportDetail?.inventoryImportDetails?.map((record: some, index) => {
                  const unit = MEDICATION_UNIT.find((v) => v.value === record.unit)?.label;
                  return (
                    <TableRow key={index}>
                      <TableCustomCell />
                      <TableCustomCell width="10%">{record.medication?.code}</TableCustomCell>
                      <TableCustomCell>{record.medication?.name}</TableCustomCell>
                      <TableCustomCell width="10%">{record.medicationSupplier?.name}</TableCustomCell>
                      <TableCustomCell width="10%">{record.lot}</TableCustomCell>
                      <TableCustomCell width="10%">
                        {record.expiredDate && moment(record.expiredDate, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                      </TableCustomCell>
                      <TableCustomCell width="10%">
                        <FormattedNumber value={record.quantity} />
                      </TableCustomCell>
                      <TableCustomCell width="10%">{unit && <FormattedMessage id={unit} />}</TableCustomCell>
                      <TableCustomCell width="10%">{NumericFomratText(record.unitPrice)}</TableCustomCell>
                      <TableCustomCell width="10%">{NumericFomratText(record.totalAmount)}</TableCustomCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </Collapse>
        </TableCustomCell>
      </TableRow>
    </>
  );
};

interface Props {
  data: some;
  onUpdate: (value) => void;
  onDelete: (value) => void;
  onProcessImport: (value) => void;
  hookPagination: HookPaginationProps;
}

const InventoryTable: React.FunctionComponent<Props> = (props) => {
  const { data, hookPagination, onUpdate, onDelete, onProcessImport } = props;
  const { intl } = useGeneralHook();
  const { onPageChange, onRowsPerPageChange, pagination } = hookPagination;

  return (
    <Box
      position="relative"
      overflow="inherit"
      display="flex"
      flexDirection="column"
      height="-webkit-fill-available"
      flex={1}
    >
      <TableContainer style={{ height: '100%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableCustomRow>
              <TableHeaderCell style={{ width: 54 }} />
              <TableHeaderCell style={{ width: 160 }}>
                <FormattedMessage id="inventory.code" />
              </TableHeaderCell>
              <TableHeaderCell width="20%">
                <FormattedMessage id="inventory.importDate" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="inventory.warehouser" />
              </TableHeaderCell>
              <TableHeaderCell>
                <FormattedMessage id="status" />
              </TableHeaderCell>
              <TableHeaderCell style={{ width: 120 }} />
            </TableCustomRow>
          </TableHead>
          <TableBody>
            {data?.content?.length > 0 ? (
              data?.content?.map((row, index) => (
                <Row
                  key={index}
                  row={row}
                  onUpdate={onUpdate}
                  onDelete={onDelete}
                  onProcessImport={onProcessImport}
                  index={index + 1}
                />
              ))
            ) : (
              <>
                <TableCustomRow>
                  <TableCustomCell colSpan={6}>
                    <Box
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        minHeight: '100%',
                      }}
                    >
                      <Typography variant="inherit" color="textSecondary">
                        <FormattedMessage id="noData" />
                      </Typography>
                    </Box>
                  </TableCustomCell>
                </TableCustomRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        data-tour="step-5"
        style={{ padding: 4 }}
        labelRowsPerPage={intl.formatMessage({ id: 'labelRowPerPage' })}
        ActionsComponent={TablePaginationActionsCustom}
        count={data?.pagination?.totalElements || 0}
        page={pagination.page}
        rowsPerPage={pagination.pageSize}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Box>
  );
};

export default InventoryTable;
