import { Box, IconButton, InputAdornment, Typography } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOffRounded';
import Visibility from '@mui/icons-material/VisibilityRounded';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { ISchemaFields, ISchemaForm, IUiFields, SchemaType } from '../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl } = formProps || {};
  const { setValue } = methods;
  return {
    oldPassword: {
      type: 'text-field',
      inputType: valuesField?.showOldPassword ? 'text' : 'password',
      label: intl.formatMessage({ id: 'oldPassword' }),
      placeholder: intl.formatMessage({ id: 'oldPassword.enter' }),
      register: {
        validate: {
          required: (value) => (!value ? intl.formatMessage({ id: 'required' }) : true),
          lessThan: (v) => (v.length < 8 || v.length > 24 ? intl.formatMessage({ id: 'minLength' }) : true),
        },
      },
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setValue('showOldPassword', !valuesField?.showOldPassword)}
            >
              {valuesField?.showOldPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ),
        style: { borderRadius: 4, overflow: 'hidden' },
        disbleUnderline: true,
      },
    },

    password: {
      type: 'text-field',
      inputType: valuesField?.showNewPassword ? 'text' : 'password',
      label: intl.formatMessage({ id: 'newPassword' }),
      placeholder: intl.formatMessage({ id: 'newPasswordEnter' }),
      register: {
        validate: {
          required: (value) => (!value ? intl.formatMessage({ id: 'required' }) : true),
          lessThan: (v) => (v.length < 8 || v.length > 24 ? intl.formatMessage({ id: 'minLength' }) : true),
          difference: (value) =>
            valuesField?.oldPassword === value && valuesField?.newPassword
              ? intl.formatMessage({ id: 'matchingOldPassword' })
              : true,
        },
      },
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setValue('showNewPassword', !valuesField?.showNewPassword)}
            >
              {valuesField?.showNewPassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ),
        style: { borderRadius: 4, overflow: 'hidden' },
        disbleUnderline: true,
      },
    },
    confirmPassword: {
      type: 'text-field',
      inputType: valuesField?.showRePassword ? 'text' : 'password',
      label: intl.formatMessage({ id: 'rePassword' }),
      placeholder: intl.formatMessage({ id: 'rePassword.enter' }),
      register: {
        validate: {
          required: (value) => (!value ? intl.formatMessage({ id: 'required' }) : true),
          lessThan: (v) => (v.length < 8 || v.length > 24 ? intl.formatMessage({ id: 'minLength' }) : true),
          positive: (value) =>
            valuesField?.newPassword !== value && valuesField?.newPassword
              ? intl.formatMessage({ id: 'notMatchingPassword' })
              : true,
        },
      },
      InputProps: {
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              tabIndex={-1}
              aria-label="toggle password visibility"
              onClick={() => setValue('showRePassword', !valuesField?.showRePassword)}
            >
              {valuesField?.showRePassword ? <Visibility fontSize="small" /> : <VisibilityOff fontSize="small" />}
            </IconButton>
          </InputAdornment>
        ),
        style: { borderRadius: 4, overflow: 'hidden' },
        disbleUnderline: true,
      },
    },

    save: {
      type: 'submitButton',
      label: intl.formatMessage({ id: 'save' }),
      propsWrapper: { style: { display: 'flex' } },
      fullWidth: true,
    },
  } as SchemaType;
};

const ui: IUiFields = ({ formProps, valuesField }) => {
  return [
    {
      id: 'info',
      title: (
        <Box marginBottom={1.5}>
          <Typography variant="subtitle1">
            <FormattedMessage id="changePassword" />
          </Typography>
        </Box>
      ),
      fields: ['oldPassword', 'password', 'confirmPassword', 'save'],
    },
  ];
};

export const schemaChangePassword: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
