import { Box, Stack } from '@mui/material';
import Report from 'modules/common/component/report';
import { REPORT_VIEW_MODE } from 'modules/common/component/report/consts';
import { useState } from 'react';
import ProfitTable from './ProfitTable';
import ProfitChart from './ProfitChart';

const ProfitReportTab = () => {
  const [viewMode, setViewMode] = useState<keyof typeof REPORT_VIEW_MODE>('CHART');
  return (
    <Report>
      <Stack direction="column" position="relative">
        <Stack direction="row" justifyContent="flex-end" position="absolute" top={-10} right={0}>
          <Report.SwitchLayout onChange={(viewMode) => setViewMode(viewMode)} defaultMode="CHART" />
        </Stack>
        <Box marginTop={1}>{viewMode === 'TABLE' ? <ProfitTable /> : <ProfitChart />}</Box>
      </Stack>
    </Report>
  );
};

export default ProfitReportTab;
