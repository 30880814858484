import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { COLLABORATOR_USER_TYPE } from 'modules/common/constants';

export const CollaboratorSchema = z
  .object({
    referenceId: z.number(),
    name: z.string(),
    type: createObjectKeysEnumSchema(COLLABORATOR_USER_TYPE),
  })
  .partial();

export type Collaborator = z.infer<typeof CollaboratorSchema>;
