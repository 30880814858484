import Book from '@mui/icons-material/Book';
import DynamicFormIcon from '@mui/icons-material/DynamicForm';
import EventIcon from '@mui/icons-material/Event';
import LocalPharmacyIcon from '@mui/icons-material/LocalPharmacy';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import PermContactCalendarRoundedIcon from '@mui/icons-material/PermContactCalendarRounded';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import Settings from '@mui/icons-material/Settings';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import AccountAdminPage from '../admin/page/AccountAdminPage';
import AdminEncounterListPage from '../admin/page/AdminEncounterListPage';
import ClinicsInfoPage from '../admin/page/ClinicsInfoPage';
import DepartmentAdminPage from '../admin/page/DepartmentAdminPage';
import DrugClinicPage from '../admin/page/DrugClinicPage';
import DrugDatabaseAdminPage from '../admin/page/DrugDatabasePage';
import EmployeeAdminPage from '../admin/page/EmployeeAdminPage';
import EquipmentAdminPage from '../admin/page/EquipmentAdminPage';
import HealthcareServiceAdminPage from '../admin/page/HealthcareServiceAdminPage';
import ImagingIndicationAdminPage from '../admin/page/ImagingIndicationAdminPage';
import IndicationAdminPage from '../admin/page/IndicationAdminPage';
import ProcedureAdminPage from '../admin/page/ProcedureAdminPage';
import ReferenceIndicationAdminPage from '../admin/page/ReferenceIndicationAdminPage';
import RoomAdminPage from '../admin/page/RoomAdminPage';
import ServiceAdminPage from '../admin/page/ServiceAdminPage';
import SupplyAdminPage from '../admin/page/SupplyAdminPage';
import InventoryPage from '../admin/page/InventoryPage';
import SupplierPage from '../admin/page/SupplierPage';
import AdminWorkReportPage from '../admin/page/AdminWorkReportPage';
import ChangePasswordPage from '../authen/page/ChangePasswordPage';
import BasicFrontEncounterListPage from '../basicFront/page/BasicFrontEncounterListPage';
import CashierEOCDetailPage from '../cashier/page/CashierEOCDetailPage';
import CashierEpisodeOfCareListPage from '../cashier/page/CashierEpisodeOfCareListPage';
import ExamDoctorEncounterListPage from '../examDoctor/page/ExamDoctorEncounterListPage';
import ManageEncounterFormPage from '../examDoctor/page/ManageEncounterFormPage';
import ManagePrescriptionTemplate from '../examDoctor/page/ManagePrescriptionTemplate';
import PatientEncounterDetailPage from '../examDoctor/page/PatientEncounterDetailPage';
import ImageDoctorEncounterListPage from '../imageDoctor/page/ImageDoctorEncounterListPage';
import ImageDoctorIndicationDetail from '../imageDoctor/page/ImageDoctorIndicationDetail';
import ImageDoctorIndicationList from '../imageDoctor/page/ImageDoctorIndicationList';
import ManageReportFormPage from '../imageDoctor/page/ManageReportFormPage';
import LabDoctorEncounterListPage from '../labDoctor/page/LabDoctorEncounterListPage';
import LabDoctorIndicationDetail from '../labDoctor/page/LabDoctorIndicationDetail';
import LabDoctorIndicationList from '../labDoctor/page/LabDoctorIndicationList';
import NurseEncounterDetailPage from '../nurse/page/NurseEncounterDetailPage';
import NurseEncounterListPage from '../nurse/page/NurseEncounterListPage';
import AppointmentCalendar from '../reception/page/AppointmentCalendar';
import EncounterListPage from '../reception/page/EncounterListPage';
import { RoutesTabType } from './utils';
import ImportInventoryPage from '../admin/page/ImportInventoryPage';
import { PatientManagement, PointofSaleFilled } from '../../svg';
import ReceiptListPage from '../admin/page/ReceiptListPage';
import CreateOrderPage from '../admin/page/CreateOrderPage';
import ExportInventoryPage from '../admin/page/ExportInventoryPage';
import PatientManagementPage from '../admin/page/PatientManagementPage';
import TherapyAdminPage from '../admin/page/TherapyAdminPage';
import TherapyListPage from '../reception/page/TherapyListPage';
import ExamTherapyListPage from '../examDoctor/page/ExamTherapyListPage';
import ExamTherapyDetailPage from '../examDoctor/page/ExamTherapyDetailPage';
import CashierTherapyListPage from '../cashier/page/CashierTherapyListPage';
import CashierTherapyDetailPage from '../cashier/page/CashierTherapyDetailPage';
import AdminTherapyListPage from 'modules/admin/page/AdminTherapyListPage';
import LabDoctorIndicationTherapyList from '../labDoctor/page/LabDoctorIndicationTherapyList';
import LabDoctorIndicationTherapyDetail from '../labDoctor/page/LabDoctorIndicationTherapyDetail';
import ImageDoctorIndicationTherapyList from '../imageDoctor/page/ImageDoctorIndicationTherapyList';
import ImageDoctorIndicationTherapyDetail from '../imageDoctor/page/ImageDoctorIndicationTherapyDetail';
import DentalListPage from '../reception/page/DentalListPage';
import DentalDetailPage from '../reception/page/DentalDetailPage';
import CashierDentalListPage from 'modules/cashier/page/CashierDentalListPage';
import CashierDentalDetailPage from 'modules/cashier/page/CashierDentalDetailPage';
import DentalExamListPage from '../examDoctor/page/DentalExamListPage';
import LabDoctorIndicationDentalList from 'modules/labDoctor/page/LabDoctorIndicationDentalList';
import LabDoctorIndicationDentalDetail from 'modules/labDoctor/page/LabDoctorIndicationDentalDetail';
import LabDoctorDentalListPage from 'modules/labDoctor/page/LabDoctorDentalListPage';
import ImageDoctorDentalListPage from 'modules/imageDoctor/page/ImageDoctorDentalListPage';
import ImageDoctorIndicationDentalList from 'modules/imageDoctor/page/ImageDoctorIndicationDentalList';
import ImageDoctorIndicationDentalDetail from 'modules/imageDoctor/page/ImageDoctorIndicationDentalDetail';
import NurseDentalListPage from 'modules/nurse/page/NurseDentallListPage';
import AppointmentBooking from 'modules/authen/page/AppointmentBooking';
import PatientProfileLayout from 'modules/common/component/Patient';
import AdminBusinessReportPage from '../admin/page/AdminBusinessReportPage';
import SaleReportPage from 'modules/common/page/report/sales';

export const ROUTES = {
  login: '/login',
  appointmentBooking: '/main/appointment-register/:clinicId/:clinicName',
  main: '/main',
  changePassword: '/main/changePassword',
  resetPassword: '/reset-password',
  dashboard: {
    value: '/main/dashboard',
  },
  encounterList: {
    value: `/main/encounter-list`,
    frontOffice: `/main/encounter-list-front`,
  },
  therapyList: {
    value: `/main/therapy-list`,
  },
  therapyPaymentList: {
    value: `/main/therapy-payment-list`,
  },
  dentalPaymentList: {
    value: `/main/dental-payment-list`,
  },
  therapyListFront: {
    value: `/main/therapy-list-front`,
  },
  dentalListPayment: {
    value: `/main/dental-list-payment`,
  },
  therapyDetail: {
    value: '/main/therapy-detail/:therapyId',
    gen: (therapyId: number) => `/main/therapy-detail/${therapyId}`,
  },
  therapyPaymentDetail: {
    value: '/main/therapy-detail/:therapyId/:paymentId',
    gen: (therapyId: number, paymentId: number) => `/main/therapy-detail/${therapyId}/${paymentId}`,
  },
  dentalPaymentDetail: {
    value: '/main/dental-detail-payment/:dentalId/:paymentId',
    gen: (dentalId: number, paymentId: number) => `/main/dental-detail-payment/${dentalId}/${paymentId}`,
  },
  EOCList: {
    value: `/main/EOC-list`,
  },
  encounterCalendar: {
    value: '/main/encounter-calendar',
  },
  encounterDetail: {
    value: '/main/encounter-detail/:encounterId/:patientId',
    gen: (encounterId: number, patientId: number, tab?: number) =>
      `/main/encounter-detail/${encounterId}/${patientId}${tab ? `/${tab}` : ''}`,
  },
  dentalList: {
    value: `/main/dental-list`,
    frontOffice: `/main/dental-list-front`,
    generalDoctor: `/main/dental-list-general`,
  },
  dentalDetail: {
    value: '/main/dental-detail/:dentalSessionId/:patientId',
    gen: (dentalSessionId: number, patientId: number) => `/main/dental-detail/${dentalSessionId}/${patientId}`,
  },
  episodeOfCareDetail: {
    value: '/main/eoc-detail/:encounterId/:patientId',
    gen: (encounterId: number, patientId: number, tab?: number) =>
      `/main/eoc-detail/${encounterId}/${patientId}${tab ? `/${tab}` : ''}`,
  },
  employeeAdmin: '/main/admin/employeeAdmin',
  accountAdmin: '/main/admin/accountAdmin',
  insuranceAdmin: '/main/admin/insurance',
  examTypesAdmin: '/main/admin/exam-type',
  therapyAdmin: '/main/admin/therapy',
  equipmentAdmin: '/main/admin/equipment',
  roomAdmin: '/main/admin/room',
  departmentAdmin: '/main/admin/department',
  labIndication: '/main/admin/lab',
  referenceIndication: {
    value: '/main/admin/lab/reference/:groupId/:id',
    gen: (groupId: string, id: string) => `/main/admin/lab/reference/${groupId}/${id}`,
  },
  imagingIndication: '/main/admin/imaging',
  managerAgentReport: '/main/admin/managerAgent',
  workReport: '/main/admin/workReport',
  otherServiceBook: '/main/admin/otherServiceBook',
  clinicsInfo: '/main/admin/clinicsInfo',
  supply: '/main/admin/supply',
  drugClinic: '/main/admin/drugClinic',
  procedure: '/main/admin/procedure',
  service: '/main/admin/service',
  detailIndication: {
    value: '/main/detailIndication/:indicationRequestId/:patientId/:encounterId',
    gen: (id: number, patientId: number, encounterId: number) =>
      `/main/detailIndication/${id}/${patientId}/${encounterId}`,
  },
  listIndication: '/main/listIndication',
  detailIndicationTherapy: {
    value: '/main/listIndicationTherapy/:indicationRequestId/:therapyId',
    gen: (indicationRequestId: string, therapyId: string) =>
      `/main/listIndicationTherapy/${indicationRequestId}/${therapyId}`,
  },
  listIndicationTherapy: '/main/listIndicationTherapy',
  listIndicationDental: '/main/listIndicationDental',
  detailIndicationDental: {
    value: '/main/listIndicationDental/:indicationRequestId/:dentalExamId',
    gen: (indicationRequestId: string, dentalExamId: string) =>
      `/main/listIndicationDental/${indicationRequestId}/${dentalExamId}`,
  },

  manageReportForm: '/main/manageReportForm',
  managePrescriptionTemplate: '/main/managePrescriptionTemplate',
  manageEncounterForm: '/main/manageEncounterForm',
  pharmacyStore: '/main/pharmacyStore',
  pharmacyWarehouse: '/main/pharmacyStore/pharmacy',
  clinicDrugWarehouse: '/main/pharmacyStore/clinic',
  appointmentBook: '/main/appointmentBook',
  appointmentCalendar: '/main/appointmentCalendar',
  encounterBook: '/main/encounterBook',
  drugDispenseBook: '/main/drugDispenseBook',
  importInventory: '/main/inventory/import',
  inventory: '/main/inventory/list',
  warehouseSupplier: '/main/inventory/supplier',
  supplyBook: '/main/supplyBook',
  businessReport: '/main/businessReport',
  receiptList: '/main/receipt/list',
  createOrder: '/main/createOrder',
  exportInventory: '/main/inventory/export',
  drugRevenueReport: '/main/drugRevenueReport',
  patientManagement: '/main/patientManagement',
  patientProfile: {
    value: '/main/patientManagement/:patientId',
    gen: (patientId: number) => `/main/patientManagement/${patientId}`,
  },
  therapyReport: '/main/therapyReport',
};

export const COMMON_ROUTES: RoutesTabType[] = [
  {
    path: ROUTES.changePassword,
    component: ChangePasswordPage,
    exact: true,
    hidden: true,
  },
];

export const RECEPTION_ROUTES: RoutesTabType[] = [
  // {
  //   title: 'navMenu.encounterList',
  //   path: ROUTES.encounterList.value,
  //   component: EncounterListPage,
  //   icon: <PermContactCalendarRoundedIcon />,
  //   exact: true,
  //   isRedirect: true,
  // },
  {
    title: 'navMenu.encounterListMenu',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.value,
        component: EncounterListPage,
        exact: true,
        isRedirect: true,
        dental: false,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.value,
        component: DentalListPage,
        exact: true,
        isRedirect: true,
        dental: true,
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyList.value,
        component: TherapyListPage,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.patientManagement',
    path: ROUTES.patientManagement,
    component: PatientManagementPage,
    icon: <PatientManagement />,
    exact: true,
    isRedirect: true,
    hiddenMenu: [
      {
        path: ROUTES.patientProfile.value,
        component: PatientProfileLayout,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.encounterCalendar',
    path: ROUTES.encounterCalendar.value,
    component: AppointmentCalendar,
    icon: <EventIcon />,
    exact: true,
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.workReport',
        path: ROUTES.workReport,
        component: AdminWorkReportPage,
        exact: true,
        dental: false,
      },
    ],
  },
];

export const EXAM_DOCTOR_ROUTES: RoutesTabType[] = [
  {
    title: 'navMenu.encounterListMenu',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.value,
        component: ExamDoctorEncounterListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.encounterDetail.value,
            component: PatientEncounterDetailPage,
            exact: true,
          },
        ],
        dental: false,
        isRedirect: true,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.value,
        component: DentalExamListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.dentalDetail.value,
            component: DentalDetailPage,
            exact: true,
          },
        ],
        isRedirect: true,
        dental: true,
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyList.value,
        component: ExamTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyDetail.value,
            component: ExamTherapyDetailPage,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.patientManagement',
    path: ROUTES.patientManagement,
    component: PatientManagementPage,
    icon: <PatientManagement />,
    exact: true,
    isRedirect: true,
    hiddenMenu: [
      {
        path: ROUTES.patientProfile.value,
        component: PatientProfileLayout,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.workReport',
        path: ROUTES.workReport,
        component: AdminWorkReportPage,
        exact: true,
        dental: false,
      },
    ],
  },
  {
    title: 'navMenu.form',
    icon: <DynamicFormIcon />,
    subMenu: [
      {
        title: 'navMenu.manageEncounterForm',
        path: ROUTES.manageEncounterForm,
        component: ManageEncounterFormPage,
        exact: true,
      },
      {
        title: 'navMenu.manageReportForm',
        path: ROUTES.manageReportForm,
        component: ManageReportFormPage,
        exact: true,
      },
      {
        title: 'navMenu.managePrescriptionTemplate',
        path: ROUTES.managePrescriptionTemplate,
        component: ManagePrescriptionTemplate,
        exact: true,
      },
    ],
  },
];

export const ADMIN_ROUTES: RoutesTabType[] = [
  {
    title: 'navMenu.encounterListMenu',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.value,
        component: AdminEncounterListPage,
        exact: true,
        dental: false,
        isRedirect: true,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.value,
        component: DentalListPage,
        exact: true,
        isRedirect: true,
        dental: true,
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyList.value,
        component: AdminTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyDetail.value,
            component: ExamTherapyDetailPage,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.patientManagement',
    path: ROUTES.patientManagement,
    component: PatientManagementPage,
    icon: <PatientManagement />,
    exact: true,
    isRedirect: true,
    hiddenMenu: [
      {
        path: ROUTES.patientProfile.value,
        component: PatientProfileLayout,
        exact: true,
      },
    ],
  },
  {
    title: 'page.registerMedicalExam.title',
    path: ROUTES.appointmentBooking,
    component: AppointmentBooking,
    hidden: true,
    exact: true,
  },
  {
    title: 'navMenu.price',
    icon: <RequestQuoteIcon />,
    subMenu: [
      {
        title: 'navMenu.examTypesAdmin',
        path: ROUTES.examTypesAdmin,
        component: HealthcareServiceAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.therapy',
        path: ROUTES.therapyAdmin,
        component: TherapyAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.labIndicationAdmin',
        path: ROUTES.labIndication,
        component: IndicationAdminPage,
        exact: true,
        hiddenMenu: [
          {
            title: 'navMenu.referenceIndicationAdmin',
            path: ROUTES.referenceIndication.value,
            component: ReferenceIndicationAdminPage,
            exact: true,
          },
        ],
      },
      {
        title: 'navMenu.imagingAdmin',
        path: ROUTES.imagingIndication,
        component: ImagingIndicationAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.procedure',
        path: ROUTES.procedure,
        component: ProcedureAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.service',
        path: ROUTES.service,
        component: ServiceAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.supply',
        path: ROUTES.supply,
        component: SupplyAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.drugClinic',
        path: ROUTES.drugClinic,
        component: DrugClinicPage,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.pharmacyStore',
    icon: <LocalPharmacyIcon />,
    subMenu: [
      {
        title: 'navMenu.drugDatabase',
        path: ROUTES.clinicDrugWarehouse,
        exact: true,
        component: DrugDatabaseAdminPage,
      },
    ],
  },
  {
    title: 'navMenu.warehouse',
    icon: <WarehouseIcon />,
    subMenu: [
      {
        title: 'navMenu.importInventory',
        path: ROUTES.importInventory,
        exact: true,
        component: ImportInventoryPage,
      },
      {
        title: 'navMenu.inventory',
        path: ROUTES.inventory,
        exact: true,
        component: InventoryPage,
      },
      {
        title: 'navMenu.exportInventory',
        path: ROUTES.exportInventory,
        exact: true,
        component: ExportInventoryPage,
      },
      {
        title: 'navMenu.warehouseSupplier',
        path: ROUTES.warehouseSupplier,
        exact: true,
        component: SupplierPage,
      },
    ],
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.workReport',
        path: ROUTES.workReport,
        component: AdminWorkReportPage,
        exact: true,
      },
      {
        title: 'navMenu.businessReport',
        path: ROUTES.businessReport,
        component: AdminBusinessReportPage,
        exact: true,
      },
      {
        title: 'navMenu.drugRevenueReport',
        path: ROUTES.drugRevenueReport,
        component: SaleReportPage,
        exact: true,
      },
      // {
      //   title: 'navMenu.therapyReport',
      //   path: ROUTES.therapyReport,
      //   component: TherapyReportPage,
      //   exact: true
      // },
    ],
  },
  {
    title: 'navMenu.admin',
    icon: <Settings />,
    subMenu: [
      {
        title: 'navMenu.employeeAdmin',
        path: ROUTES.employeeAdmin,
        component: EmployeeAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.accountAdmin',
        path: ROUTES.accountAdmin,
        component: AccountAdminPage,
        exact: true,
      },
      // {
      //   title: 'navMenu.insuranceAdmin',
      //   path: ROUTES.insuranceAdmin,
      //   component: InsuranceAdminPage,
      //   exact: true,
      // },
      {
        title: 'navMenu.equipmentAdmin',
        path: ROUTES.equipmentAdmin,
        component: EquipmentAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.roomAdmin',
        path: ROUTES.roomAdmin,
        component: RoomAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.department',
        path: ROUTES.departmentAdmin,
        component: DepartmentAdminPage,
        exact: true,
      },

      {
        title: 'navMenu.clinicsInfo',
        path: ROUTES.clinicsInfo,
        component: ClinicsInfoPage,
        exact: true,
      },
    ],
  },
];

export const BASIC_ADMIN_ROUTES: RoutesTabType[] = [
  {
    title: 'navMenu.encounterListMenu',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.value,
        component: AdminEncounterListPage,
        exact: true,
        dental: false,
        isRedirect: true,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.value,
        component: DentalListPage,
        exact: true,
        isRedirect: true,
        dental: true,
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyList.value,
        component: AdminTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyDetail.value,
            component: ExamTherapyDetailPage,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.patientManagement',
    path: ROUTES.patientManagement,
    component: PatientManagementPage,
    icon: <PatientManagement />,
    exact: true,
    isRedirect: true,
    hiddenMenu: [
      {
        path: ROUTES.patientProfile.value,
        component: PatientProfileLayout,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.price',
    icon: <RequestQuoteIcon />,
    subMenu: [
      {
        title: 'navMenu.examTypesAdmin',
        path: ROUTES.examTypesAdmin,
        component: HealthcareServiceAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.therapy',
        path: ROUTES.therapyAdmin,
        component: TherapyAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.procedure',
        path: ROUTES.procedure,
        component: ProcedureAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.service',
        path: ROUTES.service,
        component: ServiceAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.supply',
        path: ROUTES.supply,
        component: SupplyAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.drugClinic',
        path: ROUTES.drugClinic,
        component: DrugClinicPage,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.pharmacyStore',
    icon: <LocalPharmacyIcon />,
    subMenu: [
      {
        title: 'navMenu.drugDatabase',
        path: ROUTES.clinicDrugWarehouse,
        component: DrugDatabaseAdminPage,
      },
    ],
  },
  {
    title: 'navMenu.warehouse',
    icon: <WarehouseIcon />,
    subMenu: [
      {
        title: 'navMenu.importInventory',
        path: ROUTES.importInventory,
        exact: true,
        component: ImportInventoryPage,
      },
      {
        title: 'navMenu.inventory',
        path: ROUTES.inventory,
        exact: true,
        component: InventoryPage,
      },
      {
        title: 'navMenu.exportInventory',
        path: ROUTES.exportInventory,
        exact: true,
        component: ExportInventoryPage,
      },
      {
        title: 'navMenu.warehouseSupplier',
        path: ROUTES.warehouseSupplier,
        exact: true,
        component: SupplierPage,
      },
    ],
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.workReport',
        path: ROUTES.workReport,
        component: AdminWorkReportPage,
        exact: true,
      },
      {
        title: 'navMenu.businessReport',
        path: ROUTES.businessReport,
        component: AdminBusinessReportPage,
        exact: true,
      },
      {
        title: 'navMenu.drugRevenueReport',
        path: ROUTES.drugRevenueReport,
        component: SaleReportPage,
        exact: true,
      },
      // {
      //   title: 'navMenu.therapyReport',
      //   path: ROUTES.therapyReport,
      //   component: TherapyReportPage,
      //   exact: true,
      // },
    ],
  },
  {
    title: 'navMenu.admin',
    icon: <Settings />,
    subMenu: [
      {
        title: 'navMenu.employeeAdmin',
        path: ROUTES.employeeAdmin,
        component: EmployeeAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.accountAdmin',
        path: ROUTES.accountAdmin,
        component: AccountAdminPage,
        exact: true,
      },
      // {
      //   title: 'navMenu.insuranceAdmin',
      //   path: ROUTES.insuranceAdmin,
      //   component: InsuranceAdminPage,
      //   exact: true,
      // },
      // {
      //   title: 'navMenu.equipmentAdmin',
      //   path: ROUTES.equipmentAdmin,
      //   component: EquipmentAdminPage,
      //   exact: true,
      // },
      {
        title: 'navMenu.roomAdmin',
        path: ROUTES.roomAdmin,
        component: RoomAdminPage,
        exact: true,
      },
      {
        title: 'navMenu.department',
        path: ROUTES.departmentAdmin,
        component: DepartmentAdminPage,
        exact: true,
      },

      {
        title: 'navMenu.clinicsInfo',
        path: ROUTES.clinicsInfo,
        component: ClinicsInfoPage,
        exact: true,
      },
    ],
  },
];

export const LAB_ROUTES: RoutesTabType[] = [
  {
    title: 'navMenu.encounterListMenu',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.value,
        component: LabDoctorEncounterListPage,
        icon: <PermContactCalendarRoundedIcon />,
        exact: true,
        isRedirect: true,
        dental: false,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.value,
        component: LabDoctorDentalListPage,
        icon: <PermContactCalendarRoundedIcon />,
        exact: true,
        isRedirect: true,
        dental: true,
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyList.value,
        component: ExamTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyDetail.value,
            component: ExamTherapyDetailPage,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.listIndication',
    icon: <LibraryBooksIcon />,
    subMenu: [
      {
        title: 'navMenu.lab.encounter',
        path: ROUTES.listIndication,
        component: LabDoctorIndicationList,
        exact: true,
        isRedirect: true,
        dental: false,
        hiddenMenu: [
          {
            path: ROUTES.detailIndication.value,
            component: LabDoctorIndicationDetail,
            exact: true,
          },
        ],
      },
      {
        title: 'navMenu.lab.encounter',
        path: ROUTES.listIndicationDental,
        component: LabDoctorIndicationDentalList,
        exact: true,
        isRedirect: true,
        dental: true,
        hiddenMenu: [
          {
            path: ROUTES.detailIndicationDental.value,
            component: LabDoctorIndicationDentalDetail,
            exact: true,
          },
        ],
      },
      {
        title: 'navMenu.lab.therapy',
        path: ROUTES.listIndicationTherapy,
        component: LabDoctorIndicationTherapyList,
        exact: true,
        isRedirect: true,
        hiddenMenu: [
          {
            path: ROUTES.detailIndicationTherapy.value,
            component: LabDoctorIndicationTherapyDetail,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.workReport',
        path: ROUTES.workReport,
        component: AdminWorkReportPage,
        exact: true,
        dental: false,
      },
    ],
  },
];

export const IMAGE_DOCTOR_ROUTES: RoutesTabType[] = [
  {
    title: 'navMenu.encounterListMenu',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.value,
        component: ImageDoctorEncounterListPage,
        icon: <PermContactCalendarRoundedIcon />,
        exact: true,
        isRedirect: true,
        dental: false,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.value,
        component: ImageDoctorDentalListPage,
        icon: <PermContactCalendarRoundedIcon />,
        exact: true,
        isRedirect: true,
        dental: true,
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyList.value,
        component: ExamTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyDetail.value,
            component: ExamTherapyDetailPage,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.listIndication',
    icon: <LibraryBooksIcon />,
    subMenu: [
      {
        title: 'navMenu.lab.encounter',
        path: ROUTES.listIndication,
        component: ImageDoctorIndicationList,
        exact: true,
        isRedirect: true,
        dental: false,
        hiddenMenu: [
          {
            path: ROUTES.detailIndication.value,
            component: ImageDoctorIndicationDetail,
            exact: true,
          },
        ],
      },
      {
        title: 'navMenu.lab.dental',
        path: ROUTES.listIndicationDental,
        component: ImageDoctorIndicationDentalList,
        exact: true,
        isRedirect: true,
        dental: true,
        hiddenMenu: [
          {
            path: ROUTES.detailIndicationDental.value,
            component: ImageDoctorIndicationDentalDetail,
            exact: true,
          },
        ],
      },
      {
        title: 'navMenu.lab.therapy',
        path: ROUTES.listIndicationTherapy,
        component: ImageDoctorIndicationTherapyList,
        exact: true,
        isRedirect: true,
        hiddenMenu: [
          {
            path: ROUTES.detailIndicationTherapy.value,
            component: ImageDoctorIndicationTherapyDetail,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.manageReportForm',
    path: ROUTES.manageReportForm,
    component: ManageReportFormPage,
    exact: true,
    isRedirect: false,
    icon: <DynamicFormIcon />,
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.workReport',
        path: ROUTES.workReport,
        component: AdminWorkReportPage,
        exact: true,
        dental: false,
      },
    ],
  },
];

export const CASHIER_ROUTES: RoutesTabType[] = [
  {
    title: 'navMenu.encounterListMenu',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        path: ROUTES.EOCList.value,
        component: CashierEpisodeOfCareListPage,
        title: 'navMenu.episodeOfCareList',
        icon: <PermContactCalendarRoundedIcon />,
        exact: true,
        isRedirect: true,
        dental: false,
        hiddenMenu: [
          {
            path: ROUTES.episodeOfCareDetail.value,
            component: CashierEOCDetailPage,
            exact: true,
          },
        ],
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.value,
        component: CashierDentalListPage,
        exact: true,
        isRedirect: true,
        dental: true,
        hiddenMenu: [
          {
            path: ROUTES.dentalPaymentDetail.value,
            component: CashierDentalDetailPage,
            exact: true,
          },
        ],
      },
      {
        title: 'therapy.general.nav.therapyPayment',
        path: ROUTES.therapyPaymentList.value,
        component: CashierTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyPaymentDetail.value,
            component: CashierTherapyDetailPage,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.businessReport',
        path: ROUTES.businessReport,
        component: AdminBusinessReportPage,
        exact: true,
      },
      // {
      //   title: 'navMenu.therapyReport',
      //   path: ROUTES.therapyReport,
      //   component: TherapyReportPage,
      //   exact: true,
      // },
    ],
  },
];

export const PHARMACIST_ROUTES: RoutesTabType[] = [
  // {
  //   path: ROUTES.encounterList.value,
  //   component: DrugStoreEncounterListPage,
  //   title: 'navMenu.encounterList',
  //   icon: <PermContactCalendarRoundedIcon />,
  //   exact: true,
  //   isRedirect: true,
  //   hiddenMenu: [
  //     {
  //       path: ROUTES.encounterDetail.value,
  //       component: PharmacyPatientEncounterDetailPage,
  //       exact: true,
  //     },
  //   ],
  // },
  {
    title: 'navMenu.sale',
    icon: <PointofSaleFilled />,
    subMenu: [
      {
        title: 'navMenu.createOrder',
        path: ROUTES.createOrder,
        component: CreateOrderPage,
        exact: true,
        isRedirect: true,
      },
      {
        title: 'navMenu.receiptList',
        path: ROUTES.receiptList,
        component: ReceiptListPage,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.pharmacyStore',
    icon: <LocalPharmacyIcon />,
    subMenu: [
      // {
      //   title: 'navMenu.pharmacyWarehouseInsurance',
      //   path: ROUTES.pharmacyWarehouse,
      //   component: DrugStoreClinicPage,
      // },
      {
        title: 'navMenu.drugDatabase',
        path: ROUTES.clinicDrugWarehouse,
        component: DrugDatabaseAdminPage,
        exact: true,
        isRedirect: true,
      },
    ],
  },
  {
    title: 'navMenu.warehouse',
    icon: <WarehouseIcon />,
    subMenu: [
      {
        title: 'navMenu.importInventory',
        path: ROUTES.importInventory,
        exact: true,
        component: ImportInventoryPage,
      },
      {
        title: 'navMenu.inventory',
        path: ROUTES.inventory,
        exact: true,
        component: InventoryPage,
      },
      {
        title: 'navMenu.exportInventory',
        path: ROUTES.exportInventory,
        exact: true,
        component: ExportInventoryPage,
      },
      {
        title: 'navMenu.warehouseSupplier',
        path: ROUTES.warehouseSupplier,
        exact: true,
        component: SupplierPage,
      },
    ],
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.drugRevenueReport',
        path: ROUTES.drugRevenueReport,
        component: SaleReportPage,
        exact: true,
      },
    ],
  },
];

export const NURSE_ROUTES: RoutesTabType[] = [
  {
    title: 'navMenu.encounterListMenu',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.value,
        component: NurseEncounterListPage,
        exact: true,
        isRedirect: true,
        hiddenMenu: [
          {
            path: ROUTES.encounterDetail.value,
            component: NurseEncounterDetailPage,
            exact: true,
          },
        ],
        dental: false,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.value,
        component: NurseDentalListPage,
        exact: true,
        isRedirect: true,
        dental: true,
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyList.value,
        component: ExamTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyDetail.value,
            component: ExamTherapyDetailPage,
            exact: true,
          },
        ],
      },
    ],
  },
];

export const FRONT_OFFICE_ROUTES: RoutesTabType[] = [
  {
    title: 'navMenu.encounterListMenu',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.value,
        component: BasicFrontEncounterListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.episodeOfCareDetail.value,
            component: CashierEOCDetailPage,
            exact: true,
          },
        ],
        dental: false,
        isRedirect: true,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.frontOffice,
        component: DentalListPage,
        exact: true,
        dental: true,
        isRedirect: true,
      },
      {
        title: 'general.nav.dentalPayment',
        path: ROUTES.dentalListPayment.value,
        component: CashierDentalListPage,
        exact: true,
        dental: true,
        hiddenMenu: [
          {
            path: ROUTES.dentalPaymentDetail.value,
            component: CashierDentalDetailPage,
            exact: true,
          },
        ],
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyListFront.value,
        component: TherapyListPage,
        exact: true,
      },
      {
        title: 'therapy.general.nav.therapyPayment',
        path: ROUTES.therapyPaymentList.value,
        component: CashierTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyPaymentDetail.value,
            component: CashierTherapyDetailPage,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.patientManagement',
    path: ROUTES.patientManagement,
    component: PatientManagementPage,
    icon: <PatientManagement />,
    exact: true,
    isRedirect: true,
    hiddenMenu: [
      {
        path: ROUTES.patientProfile.value,
        component: PatientProfileLayout,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.encounterCalendar',
    path: ROUTES.encounterCalendar.value,
    component: AppointmentCalendar,
    icon: <EventIcon />,
    exact: true,
  },
  {
    title: 'navMenu.sale',
    icon: <PointofSaleFilled />,
    subMenu: [
      {
        title: 'navMenu.createOrder',
        path: ROUTES.createOrder,
        component: CreateOrderPage,
        exact: true,
        isRedirect: true,
      },
      {
        title: 'navMenu.receiptList',
        path: ROUTES.receiptList,
        component: ReceiptListPage,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.pharmacyStore',
    icon: <LocalPharmacyIcon />,
    subMenu: [
      {
        title: 'navMenu.drugDatabase',
        path: ROUTES.clinicDrugWarehouse,
        component: DrugDatabaseAdminPage,
        exact: true,
        isRedirect: true,
      },
    ],
  },
  {
    title: 'navMenu.warehouse',
    icon: <WarehouseIcon />,
    subMenu: [
      {
        title: 'navMenu.importInventory',
        path: ROUTES.importInventory,
        exact: true,
        component: ImportInventoryPage,
      },
      {
        title: 'navMenu.inventory',
        path: ROUTES.inventory,
        exact: true,
        component: InventoryPage,
      },
      {
        title: 'navMenu.exportInventory',
        path: ROUTES.exportInventory,
        exact: true,
        component: ExportInventoryPage,
      },
      {
        title: 'navMenu.warehouseSupplier',
        path: ROUTES.warehouseSupplier,
        exact: true,
        component: SupplierPage,
      },
    ],
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.workReport',
        path: ROUTES.workReport,
        component: AdminWorkReportPage,
        exact: true,
        dental: false,
      },
      {
        title: 'navMenu.businessReport',
        path: ROUTES.businessReport,
        component: AdminBusinessReportPage,
        exact: true,
      },
      {
        title: 'navMenu.drugRevenueReport',
        path: ROUTES.drugRevenueReport,
        component: SaleReportPage,
        exact: true,
      },
      // {
      //   title: 'navMenu.therapyReport',
      //   path: ROUTES.therapyReport,
      //   component: TherapyReportPage,
      //   exact: true,
      // },
    ],
  },
];

export const GENERAL_DOCTOR_ROUTES: RoutesTabType[] = [
  {
    title: 'navMenu.frontOffice',
    icon: <EventIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterCalendar',
        path: ROUTES.encounterCalendar.value,
        component: AppointmentCalendar,
        exact: true,
      },
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.frontOffice,
        component: BasicFrontEncounterListPage,
        exact: true,
        isRedirect: true,
        hiddenMenu: [
          {
            path: ROUTES.episodeOfCareDetail.value,
            component: CashierEOCDetailPage,
            exact: true,
          },
        ],
        dental: false,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.frontOffice,
        component: DentalListPage,
        exact: true,
        dental: true,
        isRedirect: true,
      },
      {
        title: 'general.nav.dentalPayment',
        path: ROUTES.dentalListPayment.value,
        component: CashierDentalListPage,
        exact: true,
        dental: true,
        hiddenMenu: [
          {
            path: ROUTES.dentalPaymentDetail.value,
            component: CashierDentalDetailPage,
            exact: true,
          },
        ],
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyListFront.value,
        component: TherapyListPage,
        exact: true,
      },
      {
        title: 'therapy.general.nav.therapyPayment',
        path: ROUTES.therapyPaymentList.value,
        component: CashierTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyPaymentDetail.value,
            component: CashierTherapyDetailPage,
            exact: true,
          },
        ],
      },
    ],
  },
  {
    title: 'navMenu.patientManagement',
    path: ROUTES.patientManagement,
    component: PatientManagementPage,
    icon: <PatientManagement />,
    exact: true,
    isRedirect: true,
    hiddenMenu: [
      {
        path: ROUTES.patientProfile.value,
        component: PatientProfileLayout,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.generalDoctor',
    icon: <PermContactCalendarRoundedIcon />,
    subMenu: [
      {
        title: 'navMenu.encounterList',
        path: ROUTES.encounterList.value,
        component: ExamDoctorEncounterListPage,
        icon: <PermContactCalendarRoundedIcon />,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.encounterDetail.value,
            component: PatientEncounterDetailPage,
            exact: true,
          },
        ],
        dental: false,
        isRedirect: true,
      },
      {
        title: 'navMenu.encounterList.dental',
        path: ROUTES.dentalList.generalDoctor,
        component: DentalExamListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.dentalDetail.value,
            component: DentalDetailPage,
            exact: true,
          },
        ],
        isRedirect: true,
        dental: true,
      },
      {
        title: 'navMenu.therapyList',
        path: ROUTES.therapyList.value,
        component: ExamTherapyListPage,
        exact: true,
        hiddenMenu: [
          {
            path: ROUTES.therapyDetail.value,
            component: ExamTherapyDetailPage,
            exact: true,
          },
        ],
      },
      {
        title: 'navMenu.manageEncounterForm',
        path: ROUTES.manageEncounterForm,
        component: ManageEncounterFormPage,
        exact: true,
        isRedirect: false,
      },
      {
        title: 'navMenu.manageReportForm',
        path: ROUTES.manageReportForm,
        component: ManageReportFormPage,
        exact: true,
        isRedirect: false,
      },
      {
        title: 'navMenu.managePrescriptionTemplate',
        path: ROUTES.managePrescriptionTemplate,
        component: ManagePrescriptionTemplate,
        exact: true,
      },
    ],
  },
  {
    title: 'navMenu.adminReport',
    icon: <Book />,
    subMenu: [
      {
        title: 'navMenu.workReport',
        path: ROUTES.workReport,
        component: AdminWorkReportPage,
        exact: true,
        dental: false,
      },
      {
        title: 'navMenu.businessReport',
        path: ROUTES.businessReport,
        component: AdminBusinessReportPage,
        exact: true,
      },
      // {
      //   title: 'navMenu.therapyReport',
      //   path: ROUTES.therapyReport,
      //   component: TherapyReportPage,
      //   exact: true,
      // },
    ],
  },
];
