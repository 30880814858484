import {
  Box,
  Button,
  FormControlLabel,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { BE_DATE_TIME_FORMAT, some } from 'modules/common/constants';
import _, { isEmpty } from 'lodash';
import EditableTherapySessionInfo from './EditableTherapySessionInfo';
import { UseFormReturn } from 'react-hook-form/dist/types';
import { TherapySessionDetailForm } from './TherapySessionDetail';
import TherapyDiagnosticImage from '../TherapyDiagnosticImage';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import PrintIcon from '@mui/icons-material/Print';
import { useState } from 'react';
import PrintTherapySessionDialog from './PrintTherapySessionDialog';
import { PACKAGE_SCOPE, PAYMENT_STATUS } from 'modules/common/apiConstants';

interface Props {
  dataTherapySession: some;
  formMethods: UseFormReturn<TherapySessionDetailForm>;
  editable: boolean;
  therapy: some;
  picOptions: some[];
  cloneDataSession: (cloneData: some) => void;
  handleFee: (checked: boolean) => void;
  onSaveButtonClick: () => void;
}

const TherapySessionInfo: React.FunctionComponent<Props> = ({
  dataTherapySession,
  formMethods,
  editable = true,
  therapy,
  picOptions,
  cloneDataSession,
  handleFee,
  onSaveButtonClick,
}) => {
  const [openPrintDialog, setOpenPrintDialog] = useState(false);

  // show thu phi
  const showFree = (isFree: boolean) => {
    if (!isFree) {
      return <FormattedMessage id="yes" />;
    }
    return <FormattedMessage id="no" />;
  };

  // show trang thai
  const showStatus = (status: string) => {
    if (status === 'NEW') {
      return <FormattedMessage id="therapySession.management.status.new" />;
    }
    if (status === 'PROCESSING') {
      return <FormattedMessage id="therapySession.management.status.processing" />;
    }
    if (status === 'FINISHED') {
      return <FormattedMessage id="therapySession.management.status.finished" />;
    }
    return <FormattedMessage id="therapySession.management.status.cancelled" />;
  };

  const attendPersonName =
    dataTherapySession?.attendPersonList && _.map(dataTherapySession?.attendPersonList, 'employeeName').join(', ');

  return (
    <Box className="therapy-session-info">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography className="title">
          <FormattedMessage id="therapy.detail.label.therapyInfo" />
        </Typography>
        <Box>
          <Button
            variant="contained"
            color="inherit"
            startIcon={<PrintIcon />}
            style={{ height: 32, marginRight: 16, minWidth: 180 }}
            onClick={() => setOpenPrintDialog(true)}
          >
            <FormattedMessage id="therapy.detail.label.print" />
          </Button>
          {dataTherapySession?.packageScope === PACKAGE_SCOPE.OUT_PACKAGE.value && (
            <FormControlLabel
              control={
                <Tooltip
                  title={<FormattedMessage id="therapy.therapyDetail.generalInfo.switch.payMoreSwitch.title" />}
                  placement="top"
                  arrow
                >
                  <Switch
                    size="medium"
                    color="primary"
                    checked={!formMethods.getValues('generalInfo.isFree')}
                    onChange={(e) => handleFee(e.target.checked)}
                    disabled={dataTherapySession?.paymentDetail?.status === PAYMENT_STATUS.PAID.value}
                  />
                </Tooltip>
              }
              label={<FormattedMessage id="encounterBook.billable" />}
            />
          )}
          {editable && (
            <Button style={{ height: 32, minWidth: 100 }} onClick={onSaveButtonClick}>
              <FormattedMessage id="save" />
            </Button>
          )}
        </Box>
      </Stack>
      <Box sx={{ backgroundColor: '#F4F8FF', padding: '20px 10px', marginBottom: 2, marginTop: '5px' }}>
        {editable ? (
          <EditableTherapySessionInfo
            picOptions={picOptions}
            therapy={therapy}
            cloneDataSession={cloneDataSession}
            dataTherapySession={dataTherapySession}
          />
        ) : (
          <TableContainer component={Paper} sx={{ marginBottom: 2 }}>
            <Table aria-label="disease-report-table-top10">
              <TableHead>
                <TableRow>
                  <TableCell>
                    <FormattedMessage id="insuranceReport.time" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="calendar.label.personInCharge" />
                  </TableCell>
                  {attendPersonName && dataTherapySession?.attendPersonList && (
                    <TableCell>
                      <FormattedMessage id="participant" />
                    </TableCell>
                  )}
                  <TableCell>
                    <FormattedMessage id="therapy.sessionContent" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="encounterBook.billable" />
                  </TableCell>
                  <TableCell>
                    <FormattedMessage id="status" />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isEmpty(dataTherapySession) ? (
                  <TableRow key={dataTherapySession.id}>
                    <TableCell>
                      {dataTherapySession?.startTime &&
                        moment(dataTherapySession?.startTime, BE_DATE_TIME_FORMAT).format('hh:mm DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{dataTherapySession.picName}</TableCell>
                    {attendPersonName && dataTherapySession?.attendPersonList && (
                      <TableCell>{attendPersonName}</TableCell>
                    )}
                    <TableCell>{dataTherapySession.description}</TableCell>
                    <TableCell>{showFree(dataTherapySession.isFree)}</TableCell>
                    <TableCell>{showStatus(dataTherapySession.status)}</TableCell>
                  </TableRow>
                ) : (
                  <tr>
                    <td colSpan={5}>
                      <FormattedMessage id="noData" />
                    </td>
                  </tr>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TherapyDiagnosticImage therapy={therapy} therapySession={dataTherapySession} editable={editable} />
        <PrintTherapySessionDialog
          open={openPrintDialog}
          onClose={() => setOpenPrintDialog(false)}
          therapy={therapy}
          therapySession={dataTherapySession}
        />
      </Box>
    </Box>
  );
};

export default TherapySessionInfo;
