import DialogCustom from 'modules/common/component/DialogCustom';
import { useEditPICSelectors } from './state';
import DialogContent from './DialogContent';

interface Props {
  refreshData?: () => void;
}

const EditPICDialog = ({ refreshData }: Props) => {
  const openDialog = useEditPICSelectors.use.openDialog();
  const onCloseDialog = useEditPICSelectors.use.onCloseDialog();
  return (
    <DialogCustom
      open={openDialog}
      onClose={onCloseDialog}
      title="encounter.encounterDetail.editPIC.dialog.title"
      PaperProps={{
        style: { width: '500px' },
      }}
      maxWidth="xl"
    >
      <DialogContent refreshData={refreshData} />
    </DialogCustom>
  );
};

export default EditPICDialog;
