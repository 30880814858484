import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { UseFormReturn } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { API_SERVER } from '../../../../common/api';
import DialogCustom from '../../../../common/component/DialogCustom';
import { some } from '../../../../common/constants';
import SchemaForm from '../../../../common/SchemaForm';
import { indicationFormSchema } from './schema';
import { useFetch } from 'modules/common/hook';

interface Props {
  onSubmit?: (formVal: some, methods: UseFormReturn) => void;
  open?: boolean;
  onClose?: () => void;
  isInsurance: boolean;
  formData?: some;
  list?: some[];
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose, isInsurance } = props;

  const mappedData = React.useMemo(() => {
    let returnValue = {};

    if (!formData) {
      return returnValue;
    }
    return {
      id: formData.id,
      code: formData.code,
      name: formData.name,
      department: formData.specializedDepartment?.id,
      medicalEquipment: { value: formData.medicalEquipment?.id, label: formData.medicalEquipment?.name },
      physicsRoom: { value: formData.physicsRoom?.id, label: formData.physicsRoom?.name },
      price: formData.price,
      systemCategoryId: formData.systemCategoryId,
    };
  }, [formData]);

  const { data: dataDevice } = useFetch(API_SERVER.medicalEquipment.getListByCategory('705215000'));

  const { data: dataRoom } = useFetch(API_SERVER.physicsRoom.getListByCategory('MBL'));

  const { data: departmentData } = useFetch(API_SERVER.specializedDepartment.getAll());

  const departmentOption = React.useMemo(() => {
    return departmentData?.map((ele) => ({ value: ele.id, label: `${ele.name} - ${ele.code}` }));
  }, [departmentData]);

  const equipmentsData = React.useMemo(() => {
    const returnValue: some[] = [];
    if (dataDevice?.length === 0) {
      return returnValue;
    }
    dataDevice?.forEach((entry) => {
      returnValue.push({
        value: entry.id,
        label: entry.name,
        code: entry.code,
      });
    });
    return returnValue;
  }, [dataDevice]);

  const roomsData = React.useMemo(() => {
    const returnValue: some[] = [];
    if (dataRoom?.length === 0) {
      return returnValue;
    }
    dataRoom?.forEach((entry) => {
      returnValue.push({
        value: entry.id,
        label: entry.name,
      });
    });
    return returnValue;
  }, [dataRoom]);

  return (
    <>
      <SchemaForm
        schema={indicationFormSchema}
        onSubmit={onSubmit}
        initialData={mappedData}
        onCancel={onClose}
        equipmentsData={equipmentsData}
        roomsData={roomsData}
        departments={departmentOption}
        formId="exam-price"
        isInsurance={isInsurance}
        formProps={{
          autoComplete: 'nope',
          autoCorrect: 'off',
        }}
      />
    </>
  );
};

const IndicationFormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustom
      open={!!open}
      maxWidth="xs"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: 680 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id={formData?.id ? 'indication.edit' : 'indication.addNew'} />
        </Typography>
      }
    >
      <Box padding={2}>
        <SchemaFormContent {...props} />
      </Box>
    </DialogCustom>
  );
};
export default IndicationFormDialog;
