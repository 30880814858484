import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { GROUP_STATUS, GROUP_TYPE, GROUP_VERSION, SPECIALIZATION_TYPE } from 'modules/common/apiConstants';
import { DATE_TIME_FORMAT } from 'modules/common/constants';

export const GroupSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    email: z.string(),
    phoneNumber: z.string(),
    address: z.string(),
    pic: z.number(),
    insuranceCode: z.string(),
    licenseNumber: z.string(),
    avatarId: z.number(),
    type: createObjectKeysEnumSchema(GROUP_TYPE),
    fanpage: z.string(),
    website: z.string(),
    governingBody: z.string(),
    specializationType: createObjectKeysEnumSchema(SPECIALIZATION_TYPE),
    version: createObjectKeysEnumSchema(GROUP_VERSION),
    status: createObjectKeysEnumSchema(GROUP_STATUS),
    description: z.string(),
    organizationId: z.number(),
    organizationName: z.string(),
    groupPayment: z.string(),
    remainDays: z.number(),
    totalActiveDays: z.number(),
    emailContact: z.string(),
    phoneContact: z.string(),
    totalActiveTime: z.number(),
    currency: z.string(),
    createDate: createDateTimeSchema(DATE_TIME_FORMAT),
    resourceId: z.string(),
    token: z.string(),
  })
  .partial();

export type Group = z.infer<typeof GroupSchema>;
