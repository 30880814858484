import { Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import EncounterDialog from './EncounterDialog';
import { Encounter } from 'modules/schema';
import moment from 'moment';
import { ENCOUNTER_STATUS } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook/useFetch';
import { API_SERVER } from 'modules/common/api';
import { GroupOption } from 'modules/common/type';

interface IEncounterListProps {
  encounters?: Encounter[];
  loading: boolean;
  hookPagination: HookPaginationProps;
  total: number;
}

const EncounterList: React.FunctionComponent<IEncounterListProps> = ({
  encounters,
  loading,
  hookPagination,
  total,
}) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const [selectedEncounter, setSelectedEncounter] = React.useState<Encounter | undefined>(undefined);
  const [openDetailEncounterDialog, setOpenDetailEncounterDialog] = React.useState(false);

  const { data: systemCategories } = useFetch(
    API_SERVER.systemCategory.getList({ type: 'PRICE_LIST', scope: 'HEALTH_CHECK_SERVICE' }),
  );
  const { data: services } = useFetch(
    API_SERVER.systemCategory.getList({ type: 'GROUP', scope: 'HEALTH_CHECK_SERVICE', isShowList: true }),
  );
  const systemCategoryOptions = React.useMemo(
    () =>
      systemCategories?.map((systemCategory) => ({ value: systemCategory?.id!, label: systemCategory?.name! })) || [],
    [systemCategories],
  );
  const serviceOptions = React.useMemo(
    () =>
      services?.reduce<(GroupOption & { price: number; systemCategoryId: number; parentId: number })[]>(
        (pre, cur) => [
          ...pre,
          ...(cur?.items?.map((item) => ({
            value: item?.id!,
            label: item?.name!,
            group: cur?.name!,
            price: item?.price || 0,
            systemCategoryId: cur?.id!,
            parentId: cur?.parentId!,
          })) || []),
        ],
        [],
      ) || [],
    [services],
  );

  const columns = React.useMemo(() => {
    return [
      {
        title: 'encounterId',
        render: (record) => {
          return <Typography>{record?.code}</Typography>;
        },
        align: 'right',
      },
      {
        title: 'patientCodeShort',
        render: (record) => {
          return <Typography>{record?.patient?.code?.split('-')[1]}</Typography>;
        },
        align: 'right',
      },
      {
        title: 'patientName',
        render: (record) => {
          return <Typography>{record?.patient?.name}</Typography>;
        },
      },
      {
        title: 'birthdayShort',
        render: (record) => {
          return <Typography>{moment(record?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}</Typography>;
        },
        width: 120,
      },
      {
        title: 'telephone',
        render: (record) => {
          return <Typography>{record?.patient?.mobilePhone}</Typography>;
        },
      },
      {
        title: 'encounterDate',
        dataIndex: 'date',
        render: (record) => {
          return <Typography>{record?.createdTime?.format(FE_DATE_FORMAT)}</Typography>;
        },
      },
      {
        title: 'encounterDoctor',
        render: (record) => {
          return record?.picName;
        },
      },
      {
        title: 'checkUpType',
        dataIndex: 'serviceName',
      },
      {
        title: 'status',
        render: (record) => {
          const status = ENCOUNTER_STATUS[record.status!];
          return (
            <Typography noWrap sx={{ color: status?.color }}>
              <FormattedMessage id={status?.label} />
            </Typography>
          );
        },
      },
      {
        title: 'checkupRoom',
        render: (record) => {
          return <Typography>{record?.physicsRoom?.name}</Typography>;
        },
      },
    ] as Columns<Encounter>[];
  }, []);

  return (
    <>
      <TableCustom
        dataSource={encounters}
        loading={loading}
        columns={columns}
        rowProps={(record) => {
          return {
            onClick: () => {
              setSelectedEncounter(record);
              setOpenDetailEncounterDialog(true);
            },
            style: { cursor: 'pointer' },
          };
        }}
        paginationProps={{
          count: total,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
      />
      {selectedEncounter && (
        <EncounterDialog
          encounter={selectedEncounter}
          open={openDetailEncounterDialog}
          close={() => setOpenDetailEncounterDialog(false)}
          systemCategoryOptions={systemCategoryOptions}
          serviceOptions={serviceOptions}
        />
      )}
    </>
  );
};

export default EncounterList;
