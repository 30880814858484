import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import ReactToPrint from 'react-to-print';
import DialogCustom from 'modules/common/component/DialogCustom';
import { BE_DATE_TIME_FORMAT, FE_DATE_TIME_FORMAT } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import PrintReceiptDataTable from './PrintReceiptDataTable';
import { LogoSVG } from 'modules/../svg';
import { DISCOUNT_TYPE } from '../constant';
import './style.scss';
import { NumericFormatText } from 'modules/common/utils';
import { SaleReceipt } from 'modules/schema';
import { PAYMENT_METHOD } from 'modules/common/apiConstants';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
      margin: '0mm',
    },
  },
});

interface ReceiptContentProps {
  receiptToPrint?: SaleReceipt;
}

interface Props {
  open: boolean;
  onClose?: () => void;
  receiptToPrint?: SaleReceipt;
}

const FormPrintContent = (props: ReceiptContentProps) => {
  const { receiptToPrint } = props;
  const { intl } = useGeneralHook();

  const headerInformation = React.useMemo(() => {
    return (
      <Box display={'flex'} justifyContent="center" padding={1}>
        <Typography variant="h4" style={{ textTransform: 'uppercase', fontSize: '40px' }}>
          <FormattedMessage id="sellingProduct.title.print.sale-receipt" />
        </Typography>
      </Box>
    );
  }, []);

  const mainInformation = React.useMemo(() => {
    return (
      <Grid container spacing={0}>
        <Grid item xs={12} style={{ columnCount: 2 }}>
          {[
            {
              label: 'sellingProduct.title.customerName',
              value: receiptToPrint?.customerName,
              uppercase: true,
            },
            {
              label: 'sellingProduct.title.createdDate',
              value: moment(receiptToPrint?.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT),
            },
            {
              label: 'sellingProduct.title.encounter.doctor-name',
              value: receiptToPrint?.doctor,
            },
            {
              label: 'sellingProduct.title.receiptCode',
              value: receiptToPrint?.code,
            },
            {
              label: 'sellingProduct.label.saleName',
              value: receiptToPrint?.salesBy,
            },
            {
              label: 'sellingProduct.title.clinic.location',
              value: receiptToPrint?.medicalFacility,
            },
          ].map((item, index) => {
            return (
              <Box key={index} display="flex" flexWrap="wrap" alignItems={'baseline'}>
                <Typography variant="subtitle1" style={{ minWidth: 190 }} className="info-a5-size">
                  {/*{index + 1}.&nbsp;*/}
                  <FormattedMessage id={item.label} />
                </Typography>
                :&nbsp;
                <span
                  style={{
                    textTransform: item?.uppercase ? 'uppercase' : 'initial',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}
                  className="info-a5-size"
                >
                  {item.value}
                </span>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    );
  }, [receiptToPrint]);

  const paymentDetail = React.useMemo(() => {
    return (
      <Grid container spacing={0} style={{ columnCount: 2 }}>
        <Grid item xs={6.5}>
          {receiptToPrint?.note && (
            <>
              <Box display="flex" flexWrap="wrap">
                <Typography variant="subtitle1" className="info-a5-size">
                  <FormattedMessage id="note" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" paddingTop="12px" paddingLeft="23px">
                <Typography
                  variant="body1"
                  sx={{ wordBreak: 'break-word', maxWidth: '80%', display: 'list-item' }}
                  className="info-a5-size"
                >
                  {receiptToPrint?.note}
                </Typography>
              </Box>
            </>
          )}
          {receiptToPrint?.cancelReason && (
            <>
              <Box display="flex" flexWrap="wrap" marginTop="15px">
                <Typography variant="subtitle1" className="info-a5-size">
                  <FormattedMessage id="cancelReason" />
                </Typography>
              </Box>
              <Box display="flex" flexWrap="wrap" paddingTop="12px" paddingLeft="23px">
                <Typography
                  variant="body1"
                  sx={{ wordBreak: 'break-word', maxWidth: '80%', display: 'list-item' }}
                  className="info-a5-size"
                >
                  {receiptToPrint?.cancelReason}
                </Typography>
              </Box>
            </>
          )}
        </Grid>
        <Grid item xs={5.5}>
          {[
            {
              label: 'sellingProduct.title.print.totalAmount',
              value: NumericFormatText(receiptToPrint?.totalAmount),
              params: { count: receiptToPrint?.productList?.length || 0 },
              strongText: true,
            },
            {
              label: 'sellingProduct.title.print.paymentMethod',
              value: intl.formatMessage({
                id: PAYMENT_METHOD[receiptToPrint?.paymentMethod!]?.label || ' ',
              }),
            },
            {
              label: 'sellingProduct.title.print.total.discount',
              value:
                receiptToPrint?.discountType === 'PERCENT' ? (
                  `${receiptToPrint?.discount || 0}${
                    DISCOUNT_TYPE.find((v) => v.value === receiptToPrint?.discountType)?.name
                  }`
                ) : (
                  <FormattedNumber value={receiptToPrint?.discount || 0} />
                ),
            },
            {
              label: 'sellingProduct.title.print.paid.amount',
              value: NumericFormatText(receiptToPrint?.amountPaid || 0),
              strongText: true,
            },
          ].map((item, index) => {
            return (
              <Box key={index} display="flex" flexWrap="wrap" paddingTop="20px" alignItems={'baseline'}>
                <Typography variant={item?.strongText ? 'subtitle1' : 'body1'} className="info-a5-size">
                  {intl.formatMessage({ id: item?.label }, item?.params)}
                </Typography>
                :&nbsp;
                <Typography
                  variant={item?.strongText ? 'subtitle1' : 'body1'}
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'absolute',
                    right: '20px',
                  }}
                  className="info-a5-size"
                >
                  {item.value}
                </Typography>
              </Box>
            );
          })}
        </Grid>
      </Grid>
    );
  }, [
    receiptToPrint?.note,
    receiptToPrint?.cancelReason,
    receiptToPrint?.totalAmount,
    receiptToPrint?.productList?.length,
    receiptToPrint?.discountType,
    receiptToPrint?.discount,
    receiptToPrint?.amountPaid,
    receiptToPrint?.paymentMethod,
    intl,
  ]);
  const receiptDetails = React.useMemo(() => {
    return (
      <>
        {mainInformation}
        <Box paddingY={1} marginBottom={6}></Box>
        <PrintReceiptDataTable data={receiptToPrint?.productList} />
        <Box paddingY={1} marginBottom={2}></Box>
        {paymentDetail}
      </>
    );
  }, [receiptToPrint?.productList, mainInformation, paymentDetail]);

  const footerSection = React.useMemo(() => {
    return (
      <>
        <Box
          style={{
            position: 'fixed',
            paddingTop: '850px',
            left: '50%',
            transform: 'translate(-50%)',
          }}
        >
          <Grid container spacing={0}>
            <Grid item xs={12} textAlign="center">
              {intl.formatMessage({ id: 'sellingProduct.title.print.footer' })}
              <span>
                <LogoSVG style={{ height: 12, width: 'auto', display: 'inline-block' }} />
              </span>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [intl]);

  return (
    <>
      {headerInformation}
      {receiptDetails}
      <Box paddingTop={1} marginBottom={2}>
        <Divider />
      </Box>
      {footerSection}
    </>
  );
};

const PrintReceiptDetailDialog = (props: Props) => {
  const { onClose, open, receiptToPrint } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { minWidth: '60vw' },
      }}
      title={'sellingProduct.print.title'}
      footer={
        <ReactToPrint
          documentTitle=""
          trigger={() => (
            <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
              <FormattedMessage id="sellingProduct.label.print.button" />
            </Button>
          )}
          content={() => refPrintComponent.current}
        />
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <table style={{ width: '100%' }} className="tablePrint">
          <tbody>
            <FormPrintContent receiptToPrint={receiptToPrint} />
          </tbody>
        </table>
      </Box>
    </DialogCustom>
  );
};

export default PrintReceiptDetailDialog;
