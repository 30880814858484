import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material';
import { FormCommonProps, OverrideFormControlProps } from './type';
import { Controller, ControllerProps, FieldPath, FieldValues } from 'react-hook-form';
import { formatOptions, getError } from './utils';
import useId from '@mui/material/utils/useId';
import FormControl from './FormControl';
import { ReactElement, useMemo } from 'react';
import { useIntl } from 'react-intl';

type Props<
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
> = {
  rawError?: boolean;
  options: TOption[];
  getValue: keyof TOption | ((option: TOption) => string | number);
  renderLabel: keyof TOption | ((option: TOption) => string | number) | ((option: TOption) => ReactElement);
  rawOptionLabel?: boolean;
  getOptionDisabled?: (option: TOption) => boolean;
  disable?: boolean;
  controllerProps?: Omit<ControllerProps<TFieldValues, TName>, 'name' | 'control' | 'render'>;
} & FormCommonProps<TFieldValues, TName> &
  OverrideFormControlProps &
  Omit<RadioGroupProps, 'name' | 'value' | 'ref'>;

const RadioInput = <
  TOption,
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  options,
  getValue,
  renderLabel,
  rawOptionLabel,
  form,
  label,
  rawLabel = false,
  controllerProps,
  controlProps,
  helpTextProps,
  labelProps,
  hideError = false,
  required,
  fullWidth = true,
  rawError = false,
  disable = false,
  getOptionDisabled,
  ...restProps
}: Props<TOption, TFieldValues, TName>) => {
  const id = useId();
  const intl = useIntl();
  const error = getError(form, name);
  const formatedOptions = useMemo(
    () => formatOptions(options || [], getValue, renderLabel, intl, getOptionDisabled, rawOptionLabel),
    [getOptionDisabled, getValue, intl, options, rawOptionLabel, renderLabel],
  );
  return (
    <FormControl
      error={error}
      label={label}
      rawLabel={rawLabel}
      required={required}
      htmlFor={id}
      fullWidth={fullWidth}
      controlProps={controlProps}
      labelProps={labelProps}
      helpTextProps={helpTextProps}
      hideError={hideError}
      disabled={disable}
      rawError={rawError}
    >
      <Controller
        control={form.control}
        name={name}
        render={({ field: { value, onChange, ref, onBlur, name } }) => (
          <RadioGroup
            {...restProps}
            id={id}
            aria-labelledby={id}
            name={name}
            value={value}
            onChange={(...params) => {
              onChange(...params);
              restProps.onChange && restProps.onChange(...params);
            }}
            ref={ref}
            onBlur={(...params) => {
              onBlur();
              restProps.onBlur && restProps.onBlur(...params);
            }}
          >
            {formatedOptions.map((option, index) => (
              <FormControlLabel
                key={index}
                sx={{
                  margin: 0,
                  color: '#455A64',
                  fontFamily: 'Roboto',
                  fontSize: '14px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '24px',
                  letterSpacing: '0.25px',
                  '>span:last-child': { paddingLeft: '5px' },
                }}
                value={option.value}
                control={<Radio sx={{ padding: 0 }} disabled={option.disable} />}
                label={option.label as string | number | ReactElement}
              />
            ))}
          </RadioGroup>
        )}
        {...controllerProps}
      />
    </FormControl>
  );
};

export default RadioInput;
