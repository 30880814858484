import { z } from 'zod';

export const LaboratoryConditionSchema = z
  .object({
    id: z.number(),
    name: z.string(),
    lowIndex: z.number(),
    highIndex: z.number(),
  })
  .partial();

export type LaboratoryCondition = z.infer<typeof LaboratoryConditionSchema>;
