import Stack from '@mui/material/Stack';
import { Box, ClickAwayListener, Collapse, IconButton, Tooltip, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import EditIcon from '@mui/icons-material/Edit';
import TherapySessionDetail from './TherapySessionDetail';
import * as React from 'react';
import { some } from 'modules/common/constants';
import { THERAPY_SESSION_STATUS } from 'modules/reception/constants';
import { useEffect, useState } from 'react';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import { styled } from '@mui/system';
import { axiosThunk } from 'modules/common/redux/axios';
import { API_SERVER } from 'modules/common/api';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import useSWR from 'swr';
import { fetchThunk } from 'modules/common/redux/thunk';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import DeleteIcon from '@mui/icons-material/Delete';
import { isEmpty } from 'lodash';
import { PAYMENT_STATUS } from 'modules/common/apiConstants';

const StyledInputElement = styled('input')(
  ({ theme }) => `
  font-size: 1.1rem;
  font-weight: 500;
  line-height: 24px;
  padding: 0px 4px;
  border-radius: 7px;
  color: #1A2027;
  background: #fff;
  border: 1px solid #007FFF;
  box-shadow: 0px 2px 2px #F3F6F9;

  &:focus {
    border-color: #007FFF;
    box-shadow: 0 0 0 2px #0072E5;
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
`,
);

interface Props {
  therapy: some;
  handlerSaveTherapySession: (therapy) => void;
  picOptions: some[];
  therapySession: some;
  handleDeleteTherapySession: (therapySession: some) => void;
  roleAdmin: boolean;
}

const TherapySessionWrapper = ({
  therapy,
  handlerSaveTherapySession,
  picOptions,
  therapySession,
  handleDeleteTherapySession,
  roleAdmin,
}: Props) => {
  const showEditButton = therapySession?.status === 'FINISHED';
  const showDeleteButton =
    therapySession?.status !== 'FINISHED' && therapy?.status !== 'FINISHED' && therapy?.status !== 'CANCELLED';
  const [editableName, setEditableName] = useState(false);
  const [expandTherapySession, setExpandTherapySession] = useState(false);
  const { params } = usePaginationHook({ disablePagination: true });
  const [therapyName, setTherapyName] = useState(therapySession?.name || null);
  const { dispatch, openSnackbar, intl, appState } = useGeneralHook();
  const [editable, setEditable] = useState(false);
  // get data therapy session detail
  const {
    data: dataTherapySession,
    isValidating,
    mutate: dataTherapySessionMutate,
    revalidate: revalidateTherapySession,
  } = useSWR(
    therapySession?.id && expandTherapySession && API_SERVER.therapySession.detail(therapySession.id).url,
    async (url) => {
      return dispatch(fetchThunk(url, 'get'));
    },
    { revalidateOnMount: true, revalidateOnFocus: false },
  );
  const sessionStatus = THERAPY_SESSION_STATUS.find(
    (v) => v.value === (dataTherapySession?.status || therapySession?.status),
  );

  const picOptionMapped = React.useMemo(() => {
    if (dataTherapySession?.picUserId && !picOptions.find((option) => option?.value === dataTherapySession.picUserId)) {
      return [...picOptions, { value: dataTherapySession?.picUserId, label: dataTherapySession?.picName }];
    }
    return picOptions;
  }, [dataTherapySession?.picName, dataTherapySession?.picUserId, picOptions]);

  // expand collapse by status
  const expandCollapseStatus = React.useCallback(() => {
    if (!params?.therapySessionId) {
      // PROCESSING
      if (
        isEmpty(therapy?.therapySessionList.filter((i: some) => i.status === 'FINISHED')) &&
        therapySession?.status === 'PROCESSING'
      ) {
        const startTimeArr = therapy?.therapySessionList
          .filter((i: some) => i.status === 'PROCESSING')
          ?.map((i: some) => new Date(i.startTime).getTime());
        const maxNumber = Math.max(...startTimeArr);
        if (maxNumber === new Date(therapySession?.startTime).getTime()) {
          return true;
        }
      }
      // FINISHED
      if (
        isEmpty(therapy?.therapySessionList.filter((i: some) => i.status === 'PROCESSING')) &&
        therapySession?.status === 'FINISHED'
      ) {
        const startTimeArr = therapy?.therapySessionList
          .filter((i: some) => i.status === 'FINISHED')
          ?.map((i: some) => new Date(i.startTime).getTime());
        const maxNumber = Math.max(...startTimeArr);
        if (maxNumber === new Date(therapySession?.startTime).getTime()) {
          return true;
        }
      }
      // FINISHED && PROCESSING
      if (
        !isEmpty(therapy?.therapySessionList.filter((i: some) => i.status === 'FINISHED')) &&
        !isEmpty(therapy?.therapySessionList.filter((i: some) => i.status === 'PROCESSING'))
      ) {
        const startTimeArr = therapy?.therapySessionList
          .filter((i: some) => i.status === 'PROCESSING')
          ?.map((i: some) => new Date(i.startTime).getTime());
        const maxNumber = Math.max(...startTimeArr);
        if (maxNumber === new Date(therapySession?.startTime).getTime()) {
          return true;
        }
      }
    }
    return false;
  }, [params?.therapySessionId, therapy?.therapySessionList, therapySession?.startTime, therapySession?.status]);

  useEffect(() => {
    if (params?.therapySessionId === therapySession?.id || expandCollapseStatus()) {
      setExpandTherapySession(true);
    }
  }, [expandCollapseStatus, params?.therapySessionId, therapySession?.id]);

  useEffect(() => {
    if (therapy?.status === 'CANCELLED' || therapySession?.status === 'FINISHED' || roleAdmin) {
      setEditable(false);
    } else {
      setEditable(true);
    }
  }, [appState.authen.user?.role, roleAdmin, therapy?.status, therapySession]);

  const handleClickAway = async () => {
    setEditableName(false);
    if (therapyName === therapySession?.name || !therapyName) {
      setTherapyName(therapySession?.name);
      return;
    }
    try {
      // save TherapySession
      await dispatch(
        axiosThunk({
          url: API_SERVER.therapySession.update(therapySession?.id),
          method: 'put',
          data: {
            ...therapySession,
            name: therapyName,
          },
        }),
      );
      openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.name.success' }) });
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.name.failed' }), type: 'error' });
    }
  };

  return (
    <Box className="box-child">
      {/* box trị liệu */}
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ backgroundColor: '#F4F8FF', padding: 1, cursor: 'pointer' }}
        className="header"
        onClick={() => setExpandTherapySession((old) => !old)}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <IconButton aria-label="expand row" size="small" className="icon-expand">
            <KeyboardArrowDownIcon
              style={{
                transform: expandTherapySession ? 'rotate(180deg)' : 'rotate(0deg)',
                transition: 'all 200ms',
                fontSize: 24,
              }}
            />
          </IconButton>
          <Box onClick={(event) => event.stopPropagation()}>
            {editableName ? (
              <ClickAwayListener onClickAway={handleClickAway}>
                <StyledInputElement
                  value={therapyName}
                  onChange={(e) => {
                    setTherapyName(e.target.value);
                  }}
                />
              </ClickAwayListener>
            ) : (
              <Typography variant="subtitle1" color="primary" noWrap onDoubleClick={() => setEditableName(true)}>
                {therapyName}
              </Typography>
            )}
          </Box>
        </Stack>
        <Box className="flex-space-between info">
          <Typography variant="body1" color="textSecondary" noWrap marginLeft={3}>
            <FormattedMessage id="time" />
            :&nbsp;
            <Typography variant="subtitle1" color="textPrimary" component={'span'}>
              {therapySession?.startTime && moment(therapySession?.startTime).format('hh:mm DD/MM/YYYY')}
            </Typography>
          </Typography>{' '}
          <Typography variant="body1" color="textSecondary" noWrap marginLeft={3}>
            <FormattedMessage id="calendar.label.personInCharge" />
            :&nbsp;
            <Typography variant="subtitle1" color="textPrimary" component={'span'}>
              {therapySession?.picName}
            </Typography>
          </Typography>
          <Typography variant="body1" color="textSecondary" noWrap marginLeft={3}>
            <FormattedMessage id="status" />
            :&nbsp;
            <Typography variant="subtitle1" color={sessionStatus?.sx} component={'span'} className="text-done">
              <FormattedMessage id={sessionStatus?.label || ' '} />
            </Typography>
          </Typography>
          {therapy?.status !== 'CANCELLED' && showEditButton && (
            <Tooltip title={<FormattedMessage id="therapy.tooltip.label.edit" />} placement="top" arrow>
              <Box
                marginLeft={3}
                onClick={(e) => {
                  e.stopPropagation();
                  setEditable(true);
                  setExpandTherapySession(true);
                }}
              >
                <IconButton sx={{ p: 0 }} className="icon-edit">
                  <EditIcon />
                </IconButton>
              </Box>
            </Tooltip>
          )}
          {showDeleteButton && (
            <Tooltip title={<FormattedMessage id="therapy.tooltip.label.delete" />} placement="top" arrow>
              <Box marginLeft={3}>
                <IconButton
                  sx={{ p: 0 }}
                  className="icon-edit"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteTherapySession(therapySession);
                    setExpandTherapySession(true);
                  }}
                  disabled={
                    therapySession?.paymentDetail?.status === PAYMENT_STATUS.PAID.value ||
                    therapySession?.paymentDetail?.status === PAYMENT_STATUS.PACKAGE_PAID.value
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Tooltip>
          )}
        </Box>
      </Stack>
      <Collapse in={expandTherapySession} timeout="auto" unmountOnExit>
        {isValidating || !dataTherapySession ? (
          <LoadingIcon />
        ) : (
          <TherapySessionDetail
            therapy={therapy}
            onSaveTherapySession={handlerSaveTherapySession}
            picOptions={picOptionMapped}
            editable={editable}
            setEditable={setEditable}
            dataTherapySession={dataTherapySession}
            dataTherapySessionMutate={dataTherapySessionMutate}
            revalidateTherapySession={revalidateTherapySession}
          />
        )}
      </Collapse>
    </Box>
  );
};

export default TherapySessionWrapper;
