import { useContext } from 'react';
import { FilterContext } from './const';

const useFilterContext = () => {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error('Must be wrap by Filter component');
  }
  return context;
};

export default useFilterContext;
