import { Search } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import PrintIcon from '@mui/icons-material/Print';
import { Box, Collapse, Divider, InputAdornment } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { DIAGNOSTIC_REPORT_STATUS_OPTIONS_LAB } from 'modules/common/constants';
import { ILayoutFields, ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
export interface SearchPrams {
  patientCode: string;
  statusList: string;
  patientName: string;
  interpreter: string;
  fromDate: string;
  toDate: string;
}

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, selectionIndication, onPrint, clearParams, state, setState } = formProps || {};

  return {
    patientCode: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'enterPatientCode' }),
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Search color="inherit" />
          </InputAdornment>
        ),
      },
      showClearBtn: true,
      propsWrapper: { xs: true, sx: { '&': { maxWidth: '400px' } } },
    },
    statusList: {
      type: 'select',
      hasAllOptions: true,
      label: intl.formatMessage({ id: 'status' }),
      multiple: true,
      options: DIAGNOSTIC_REPORT_STATUS_OPTIONS_LAB,
      propsWrapper: { xs: 4, xl: true },
    },
    patientName: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'patientName' }),
      placeholder: intl.formatMessage({ id: 'enterPatientName' }),
      propsWrapper: { xs: 4, xl: true },
    },
    telephone: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'telephone' }),
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      propsWrapper: { xs: 4, xl: true },
    },
    interpreter: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'interpreter' }),
      placeholder: intl.formatMessage({ id: 'interpreterEnter' }),
      propsWrapper: { xs: 4, xl: true },
    },
    fromDate: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'indicationDateFrom' }),
      propsWrapper: { xs: 4, xl: true },
      autoOk: true,
      maxDate: valuesField.toDate ? moment(valuesField.toDate).toDate() : null,
      register: {
        validate: (value) => {
          return value && moment(value).isAfter(valuesField?.toDate)
            ? intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
      },
    },
    toDate: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'indicationDateTo' }),
      propsWrapper: { xs: 4, xl: true },
      autoOk: true,
      minDate: valuesField.fromDate ? moment(valuesField.fromDate).toDate() : null,
      register: {
        validate: (value) => {
          return value && moment(value).isBefore(valuesField?.fromDate)
            ? intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
      },
      required: false,
    },
    search: {
      type: 'submitButton',
      color: 'inherit',
      label: intl.formatMessage({ id: 'search' }),
      propsWrapper: { xs: undefined },
    },
    expand: {
      type: 'button',
      endIcon: (
        <ExpandLessIcon
          style={{
            transition: '0.2s all',
            transform: valuesField?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      ),
      color: 'primary',
      variant: 'text',
      onClick: () => setState({ expand: !state.expand }),
      label: intl.formatMessage({ id: 'advance' }),
      propsWrapper: { xs: undefined },
    },
    print: {
      type: 'button',
      endIcon: <PrintIcon />,
      color: 'primary',
      onClick: onPrint,
      disabled: selectionIndication.length === 0,
      label: intl.formatMessage({ id: 'labDoctor.formPrint' }),
      propsWrapper: { xs: undefined },
    },

    reset: {
      type: 'button',
      onClick: () => clearParams && clearParams(),
      color: 'inherit',
      label: intl.formatMessage({ id: 'reset' }),
      propsWrapper: { xs: undefined },
      style: { minWidth: 120, marginTop: 20 },
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'layout',
      propsGridContainer: { spacing: 1, justifyContent: 'flex-end' },
      fields: ['patientCode', 'search', 'expand', 'print'],
    },
    {
      id: 'expand',
      fields: ['statusList', 'patientName', 'telephone', 'interpreter', 'fromDate', 'toDate', 'reset'],
    },
    { id: 'submit', fields: ['save'] },
  ];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement, formProps } = props;
  const { state } = formProps;
  return (
    <>
      <Box display="flex">
        <Box flex={1}>{formProps?.pagin}</Box>
        <Box flex={1}>{listElement[0]}</Box>
      </Box>
      <Collapse in={state?.expand} style={{ marginTop: 16 }}>
        {listElement[1]}
        <Divider style={{ marginBottom: 16 }} />
      </Collapse>
    </>
  );
};

export const schemaFilterEncounterForm: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
