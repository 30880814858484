import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { ICD_TYPE } from 'modules/common/apiConstants';

export const IcdDiagnosisSchema = z
  .object({
    id: z.number(),
    encounterId: z.number(),
    code: z.string(),
    display: z.string(),
    groupId: z.number(),
    type: createObjectKeysEnumSchema(ICD_TYPE),
  })
  .partial();

export type IcdDiagnosis = z.infer<typeof IcdDiagnosisSchema>;
