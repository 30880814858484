import styled from '@emotion/styled';
import { Box, Tooltip, TooltipProps, Typography, tooltipClasses } from '@mui/material';
import { Dictionary } from 'lodash';
import { NumericFormatText } from 'modules/common/utils';
import { Medication, PrescriptionFormTemplate } from 'modules/schema';
import { FormattedMessage } from 'react-intl';

const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
  },
});

interface Props {
  prescriptionTemplate: PrescriptionFormTemplate;
  medicationMap: Dictionary<Medication & { categoryName?: string }>;
}

const PrescriptionInfo = ({ prescriptionTemplate, medicationMap }: Props) => {
  return (
    <CustomTooltip
      placement="right"
      title={
        <Box>
          <Typography variant="h6" textAlign={'center'}>
            <FormattedMessage id="encounterDetail.prescription" />
            &nbsp;{prescriptionTemplate.name}
          </Typography>
          {prescriptionTemplate?.detailTemplateList?.map((item, index) => {
            const medication = medicationMap[item.medicationId!];
            return (
              <Box display="flex" flex={1} columnGap={2} flexWrap="wrap">
                <Typography variant="body2">
                  {index + 1}.&nbsp; <FormattedMessage id="encounterPrescription.groupDrug" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {medication?.categoryName}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.drugName" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {medication?.name}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.ingredients" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {medication?.ingredient}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="sale.dose" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {medication?.dose}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.unit" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {medication?.numeratorUnit}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.price" />
                  <Typography variant="subtitle2" component="span">
                    {NumericFormatText(medication?.basicPrice)}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="quantity" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {item?.quantity}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="therapy.detail.label.intendedRoute" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {medication?.intendedRoute?.[0]?.label}
                  </Typography>
                </Typography>
                <Typography variant="body2">
                  <FormattedMessage id="encounterPrescription.instruction" />
                  :&nbsp;
                  <Typography variant="subtitle2" component="span">
                    {item?.usage}
                  </Typography>
                </Typography>
              </Box>
            );
          })}
          <Typography variant="body2">
            <FormattedMessage id="encounterPrescription.note" />
            <Typography variant="subtitle2" component="span">
              {prescriptionTemplate.advice}
            </Typography>
          </Typography>
        </Box>
      }
    >
      <Typography variant="subtitle2" width="100%">
        {prescriptionTemplate.name}
      </Typography>
    </CustomTooltip>
  );
};

export default PrescriptionInfo;
