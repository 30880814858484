import { createFilterOptions, Stack } from '@mui/material';
import { EncounterDetailProps } from '../../type';
import IconButton from '../../../IconButton';
import KeyboardVoiceIcon from '@mui/icons-material/KeyboardVoice';
import { AppState } from 'redux/reducer';
import { useSelector } from 'react-redux';
import EncounterDetailBlock from '../../EncounterDetailBlock';
import { useForm } from 'react-hook-form';
import { CodeSystem } from 'modules/schema';
import AutocompleteInput from '../../../form/AutocompleteInput';
import { useFetch, useUpdateMutate } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { ICD_TYPE } from 'modules/common/apiConstants';
import IcdList from './IcdList';
import { useDebouncedCallback } from 'use-debounce';
import { useContext, useEffect } from 'react';
import { EncounterContext } from '../../const';
import SunEditorInput from 'modules/common/component/form/SunEditorInput';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { isEmpty } from 'lodash';

const ButtonGroup = () => {
  return (
    <Stack direction="row" alignItems="center" justifyContent="center" gap="10px">
      <IconButton icon={<KeyboardVoiceIcon />} sx={{ color: '#0962FF' }} />
    </Stack>
  );
};

interface Form {
  selectPrimaryIcd10: CodeSystem | null;
  selectSecondaryIcd10: CodeSystem | null;
  differentialDiagnosis: string;
}

const filterOptions = createFilterOptions<CodeSystem>({
  limit: 100,
  stringify: (option) => option.value + ' - ' + option.label,
});

const Diagnosis = ({ encounter, readonly }: EncounterDetailProps) => {
  const { intl } = useGeneralHook();

  const form = useForm<Form>({
    defaultValues: {
      selectPrimaryIcd10: null,
      selectSecondaryIcd10: null,
      differentialDiagnosis: encounter.differentialDiagnosis,
    },
  });

  const differentialDiagnosis = form.getValues('differentialDiagnosis');

  useEffect(() => {
    form.reset({ differentialDiagnosis: encounter.differentialDiagnosis });
  }, [encounter.differentialDiagnosis, form]);
  const icd10Options = useSelector((state: AppState) => state.common.icd10s);
  const encounterContext = useContext(EncounterContext);
  const updateClinicalExaminationMutate = useUpdateMutate({
    globalLoading: false,
    onSuccess: () => encounterContext?.setCounter(encounterContext.counter + 1),
  });
  const { data: primaryIcd10s, revalidate: primaryIcd10Revalidate } = useFetch(
    API_SERVER.icdDiagnosis.getByEncounterId({ encounterId: encounter.id!, type: ICD_TYPE.PRIMARY_ICD10 }),
  );
  const { data: secondaryIcd10s, revalidate: secondaryIcd10Revalidate } = useFetch(
    API_SERVER.icdDiagnosis.getByEncounterId({ encounterId: encounter.id!, type: ICD_TYPE.SECOND_ICD10 }),
  );
  const onUpdateDifferentialDiagnosis = useDebouncedCallback(async (value) => {
    if (differentialDiagnosis !== value) {
      try {
        updateClinicalExaminationMutate({
          url: API_SERVER.encounter.updateInfoBasic(encounter.id!),
          method: 'PUT',
          data: {
            differentialDiagnosis: value,
          },
        });
        form.reset({
          ...form.getValues(),
          differentialDiagnosis: value,
        });
      } catch (error) {
        form.reset();
      }
    }
  }, 2000);

  const addPrimaryIcd10Mutate = useUpdateMutate({
    onSuccess: () => {
      primaryIcd10Revalidate();
      encounterContext?.setCounter(encounterContext.counter + 1);
    },
  });
  const onAddPrimaryIcd = () => {
    const selectedIcd = form.getValues('selectPrimaryIcd10');
    if (selectedIcd) {
      addPrimaryIcd10Mutate({
        url: API_SERVER.icdDiagnosis.updateByEncounterId(),
        method: 'PUT',
        data: {
          code: selectedIcd?.value,
          display: selectedIcd?.label,
          encounterId: encounter?.id!,
          type: ICD_TYPE.PRIMARY_ICD10,
        },
      });
      form.setValue('selectPrimaryIcd10', null);
    }
  };
  const addSecondaryIcd10Mutate = useUpdateMutate({
    onSuccess: () => {
      secondaryIcd10Revalidate();
      encounterContext?.setCounter(encounterContext.counter + 1);
    },
  });
  const onAddSecondaryIcd = () => {
    const selectedIcd = form.getValues('selectSecondaryIcd10');
    if (selectedIcd) {
      addSecondaryIcd10Mutate({
        url: API_SERVER.icdDiagnosis.updateByEncounterId(),
        method: 'PUT',
        data: {
          code: selectedIcd?.value,
          display: selectedIcd?.label,
          encounterId: encounter?.id!,
          type: ICD_TYPE.SECOND_ICD10,
        },
      });
      form.setValue('selectSecondaryIcd10', null);
    }
  };

  const sunEditorHeight = () => {
    if (!isEmpty(primaryIcd10s) && !isEmpty(secondaryIcd10s)) {
      return '169px';
    }
    if (!isEmpty(primaryIcd10s) || !isEmpty(secondaryIcd10s)) {
      return '201px';
    }
    return '240px';
  };

  return (
    <EncounterDetailBlock
      title="encounter.encounterDetail.block.title.diagnosis"
      rightAction={readonly ? undefined : <ButtonGroup />}
    >
      <Stack direction="column" gap="4px" flex={1}>
        <Stack direction="row" gap={1}>
          <AutocompleteInput
            form={form}
            name="selectPrimaryIcd10"
            options={icd10Options}
            filterOptions={filterOptions}
            getValue="value"
            getLabel={(option) => option.value + ' - ' + option.label}
            placeholder="encounterInfo.primaryICD10"
            disabled={readonly}
            hideError
            onChange={onAddPrimaryIcd}
            blurOnSelect
            getOptionDisabled={(option) =>
              [...(primaryIcd10s || []), ...(secondaryIcd10s || [])]?.some(
                (selectedOption) => option.value === selectedOption.code,
              ) || false
            }
          />
          <AutocompleteInput
            form={form}
            name="selectSecondaryIcd10"
            options={icd10Options}
            filterOptions={filterOptions}
            getValue="value"
            getLabel={(option) => option.value + ' - ' + option.label}
            placeholder="encounterInfo.secondaryICD10"
            disabled={readonly}
            hideError
            onChange={onAddSecondaryIcd}
            blurOnSelect
            getOptionDisabled={(option) =>
              [...(primaryIcd10s || []), ...(secondaryIcd10s || [])]?.some(
                (selectedOption) => option.value === selectedOption.code,
              ) || false
            }
          />
        </Stack>
        <IcdList
          encounterId={encounter?.id!}
          readonly={readonly}
          primaryIcd10={primaryIcd10s?.[0]}
          secondaryIcd10s={secondaryIcd10s}
        />
        <SunEditorInput
          name="differentialDiagnosis"
          placeholder={intl.formatMessage({ id: 'enter' })}
          readOnly={readonly}
          height={sunEditorHeight()}
          onChange={(value) => onUpdateDifferentialDiagnosis(value)}
          setContents={differentialDiagnosis || ''}
        />
      </Stack>
    </EncounterDetailBlock>
  );
};

export default Diagnosis;
