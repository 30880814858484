import { z } from 'zod';
import { createDateTimeSchema } from './common';
import { BE_DATE_FORMAT } from '../common/constants';
import { CodeSystemSchema } from './CodeSystem';
import { MedicationKnowledgeSchema } from './MedicationKnowledge';
import { MedicationOrganizationSchema } from './MedicationOrganization';
import { UnitConverterSchema } from './UnitConverter';

export const MedicationSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    registrationNumber: z.string(),
    name: z.string(),
    numeratorUnit: z.string(),
    codeGroup: z.string(),
    organizationId: z.string(),
    form: z.string(),
    ingredient: z.string(),
    dose: z.string(),
    country: z.string(),
    basePrice: z.number(),
    publicPrice: z.number(),
    approvalDate: createDateTimeSchema(BE_DATE_FORMAT),
    currency: z.string(),
    basicUnit: z.string(),
    basicPrice: z.number(),
    isLockBasicUnit: z.boolean(),
    intendedRoute: z.array(CodeSystemSchema),
    resourceId: z.string(),
    medicationKnowledge: MedicationKnowledgeSchema,
    registration: MedicationOrganizationSchema,
    distributor: MedicationOrganizationSchema,
    manufacturer: MedicationOrganizationSchema,
    unitConverterList: z.array(UnitConverterSchema),
    specializedDepartmentId: z.number(),
    importedInventoryStatus: z.boolean(),
    quantity: z.number(),
  })
  .partial();

export type Medication = z.infer<typeof MedicationSchema>;
