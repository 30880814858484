import { CORE, GATEWAY_HOST, stringifyUrl } from 'modules/common/api';
import { RECEIPT_TYPE } from 'modules/common/apiConstants';
import { Pageable } from 'modules/common/type';
import { SaleImportReportSchema, SaleProfitReportSchema, SaleRevenueReportSchema } from 'modules/schema';
import { createPageSchema } from 'modules/schema/common';
import { ReportProfitDetailSchema } from 'modules/schema/ReportProfitDetail';
import { z } from 'zod';

export const SALE_API = {
  getProfitPaging: (
    params: {
      keyword?: string;
      fromDate: string;
      toDate: string;
      receiptTypeList?: (keyof typeof RECEIPT_TYPE)[];
      medicationIds?: number[];
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/profit?${stringifyUrl(params)}`,
    schema: createPageSchema(SaleProfitReportSchema),
  }),
  getProfitList: (params: {
    keyword?: string;
    fromDate: string;
    toDate: string;
    receiptTypeList?: (keyof typeof RECEIPT_TYPE)[];
    medicationIds?: number[];
    groupReportType?: string;
  }) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/profit/list?${stringifyUrl(params)}`,
    schema: z.array(SaleProfitReportSchema),
  }),
  getProfitExcel: (params: {
    keyword?: string;
    fromDate: string;
    toDate: string;
    receiptType?: keyof typeof RECEIPT_TYPE;
    medicationIds?: number[];
  }) => `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/profit/excel?${stringifyUrl(params)}`,
  getSaleProfitDetail: (inventoryExportId?: number) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/profit/detail/${inventoryExportId}`,
    schema: z.array(ReportProfitDetailSchema),
  }),
  getRevenuePaging: (
    params: {
      keyword?: string;
      fromDate: string;
      toDate: string;
      receiptTypes?: string[];
      paymentMethods?: string[];
      medicationIds?: number[];
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/revenue?${stringifyUrl(params)}`,
    schema: createPageSchema(SaleRevenueReportSchema),
  }),
  getRevenueList: (params: {
    keyword?: string;
    fromDate: string;
    toDate: string;
    receiptTypes?: string[];
    paymentMethods?: string[];
    medicationIds?: number[];
  }) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/revenue/list?${stringifyUrl(params)}`,
    schema: z.array(SaleRevenueReportSchema),
  }),

  getRevenueExcel: (params: {
    keyword?: string;
    fromDate: string;
    toDate: string;
    receiptTypes?: string[];
    paymentMethods?: string[];
    medicationIds?: number[];
  }) => `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/revenue/excel?${stringifyUrl(params)}`,

  getExpensePaging: (
    params: {
      keyword?: string;
      fromDate: string;
      toDate: string;
      importBys?: string[];
      medicationIds?: number[];
      medicationSuppliers?: number[];
    } & Pageable,
  ) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/expense?${stringifyUrl(params)}`,
    schema: createPageSchema(SaleImportReportSchema),
  }),
  getExpenseList: (params: {
    keyword?: string;
    fromDate: string;
    toDate: string;
    importBys?: string[];
    medicationIds?: number[];
    medicationSuppliers?: number[];
  }) => ({
    url: `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/expense/list?${stringifyUrl(params)}`,
    schema: z.array(SaleImportReportSchema),
  }),
  getExpenseExcel: (params: {
    keyword?: string;
    fromDate: string;
    toDate: string;
    importBys?: string[];
    medicationIds?: number[];
    medicationSuppliers?: number[];
  }) => `${GATEWAY_HOST}/${CORE}/v1.0/report/sales/expense/excel?${stringifyUrl(params)}`,
};
