import { Encounter } from 'modules/schema';
import { formatPatientDataToForm } from 'modules/reception/component/encounter/ReceiveWizardDialog/utils';
import { GroupOption, Option } from 'modules/common/type';
import { AppState } from 'redux/reducer';
import { PAYMENT_PLAN } from 'modules/common/constants';
import { IntlShape } from 'react-intl';

export function formatEncounterData(
  encounter: Encounter,
  appState: AppState,
  systemCategoryOptions: Option[],
  serviceOptions: (GroupOption & { price: number; systemCategoryId: number; parentId: number })[],
  intl: IntlShape,
) {
  const parentId = serviceOptions?.find((ele) => ele.value === encounter?.serviceId)?.parentId;

  return {
    ...formatPatientDataToForm(encounter?.patient!, appState),
    priceType: systemCategoryOptions?.find((e) => e.value === parentId)?.label,
    service: encounter?.serviceName,
    physicsRoom: encounter?.physicsRoom?.name,
    doctor: encounter?.picName,
    paymentPlan: intl.formatMessage({ id: PAYMENT_PLAN[encounter?.paymentPlan!]?.label }),
    paymentPlanValue: intl.formatMessage({ id: PAYMENT_PLAN[encounter?.paymentPlan!]?.value }),
    reason: encounter?.reason,
    price: serviceOptions?.find((service) => service?.value === encounter?.serviceId)?.price,
    systemCategory: serviceOptions?.find((option) => option?.parentId === parentId)?.group,
  };
}
