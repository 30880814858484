import { Add, Search } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Collapse, Divider, InputAdornment } from '@mui/material';
import moment from 'moment';
import { STATUS_ENCOUNTER_BILL_OPTIONS } from '../../../../cashier/component/list/FilterEncounterForCashier/schema';
import { NUMBER_REGEX } from '../../../../common/regex';
import { ILayoutFields, ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';
import { Sort } from '../../../../../svg';
import { ENCOUNTER_STATUS, ORDER_TYPE } from 'modules/common/apiConstants';
import { Option } from 'modules/common/type';
import { toggleOrderEncounter } from 'modules/common/redux/commonReducer';

const STATUS_OPTIONS = [ENCOUNTER_STATUS.INPROGRESS, ENCOUNTER_STATUS.ARRIVED, ENCOUNTER_STATUS.FINISHED];

interface SchemaProps {
  clearParams(): void;
  physicsRoomOptions: Option[];
  dataLoading: boolean;
  onRegisterButtonClick(): void;
  pagin: React.ReactNode;
}

export const fieldSchema: ISchemaFields<SchemaProps> = ({ valuesField, formProps, methods }) => {
  const { intl, onRegisterButtonClick, appState, dispatch, clearParams, state, setState, dataLoading } =
    formProps || {};
  const orderBy = appState.common.orderEncounter;

  return {
    mobilePhone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'searchByTel' }),
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Search color="inherit" />
          </InputAdornment>
        ),
      },
      showClearBtn: true,
      register: { pattern: { value: NUMBER_REGEX, message: intl.formatMessage({ id: 'telephoneInvalid' }) } },
      propsWrapper: { xs: true },
    },
    paymentStatus: {
      type: 'select',
      hasAllOptions: true,
      label: intl.formatMessage({ id: 'cashier.paymentStatus' }),
      multiple: true,
      options: STATUS_ENCOUNTER_BILL_OPTIONS,
      noHelperText: true,
      propsWrapper: { xs: 2.4 },
    },
    status: {
      type: 'select',
      hasAllOptions: true,
      label: intl.formatMessage({ id: 'status' }),
      multiple: true,
      options: STATUS_OPTIONS,
      noHelperText: true,
      propsWrapper: { xs: 2.4 },
    },
    patientName: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'patientName' }),
      placeholder: intl.formatMessage({ id: 'enterPatientName' }),
      noHelperText: true,
      propsWrapper: { xs: 2.4 },
    },
    yearOfDOB: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'enterBirthYear' }),
      label: intl.formatMessage({ id: 'birthYear' }),
      noHelperText: true,
      propsWrapper: { xs: 2.4 },
      disableFuture: true,
      views: ['year'],
      inputFormat: 'yyyy',
      formatDate: 'yyyy',
      mask: '____',
    },
    picName: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'encounterPractitioner' }),
      placeholder: intl.formatMessage({ id: 'enterDoctorName' }),
      noHelperText: true,
      propsWrapper: { xs: 2.4 },
    },
    fromDate: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'encounterDateFrom' }),
      noHelperText: true,
      propsWrapper: { xs: 2.4, paddingBottom: 1 },
      autoOk: true,
      maxDate: valuesField.toDate ? moment(valuesField.toDate).toDate() : null,
      register: {
        validate: (value) => {
          return value && moment(value).isAfter(valuesField?.toDate)
            ? intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
      },
      required: false,
    },
    toDate: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'encounterDateTo' }),
      noHelperText: true,
      propsWrapper: { xs: 2.4 },
      autoOk: true,
      minDate: valuesField.fromDate ? moment(valuesField.fromDate).toDate() : null,
      register: {
        validate: (value) => {
          return value && moment(value).isBefore(valuesField?.fromDate)
            ? intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
      },
      required: false,
    },
    search: {
      type: 'submitButton',
      color: 'inherit',
      label: intl.formatMessage({ id: 'search' }),
      propsWrapper: { xs: undefined },
    },
    expand: {
      type: 'button',
      endIcon: (
        <ExpandLessIcon
          style={{
            transition: '0.2s all',
            transform: valuesField?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      ),
      color: 'primary',
      variant: 'text',
      onClick: () => setState({ expand: !state.expand }),
      label: intl.formatMessage({ id: 'advance' }),
      propsWrapper: { xs: undefined },
    },
    add: {
      type: 'button',
      color: 'secondary',
      startIcon: <Add />,
      label: intl.formatMessage({ id: 'encounterList.receive' }),
      propsWrapper: { xs: undefined },
      onClick: onRegisterButtonClick,
    },
    reset: {
      type: 'button',
      onClick: () => clearParams && clearParams(),
      color: 'inherit',
      label: intl.formatMessage({ id: 'reset' }),
      propsWrapper: { xs: undefined },
      style: { minWidth: 120, marginTop: 20 },
    },
    orderBy: {
      type: 'submitButton',
      onClick: () => dispatch(toggleOrderEncounter()),
      disabled: dataLoading,
      label: intl.formatMessage({ id: 'encounterList.label.input.sort' }),
      style: { minWidth: 120 },
      propsWrapper: { xs: undefined },
      color: 'inherit',
      startIcon: (
        <Box
          sx={{
            transition: 'all 0.3s ease',
            transform: orderBy === ORDER_TYPE.ASC ? 'rotate(180deg)' : 'rotate(0deg)',
            '& path': {
              fill: dataLoading ? '#A8A8AA' : '#0962FF',
            },
          }}
        >
          <Sort />
        </Box>
      ),
    },
  };
};

const ui: IUiFields<SchemaProps> = () => {
  return [
    {
      id: 'layout',
      propsGridContainer: { spacing: 1, justifyContent: 'flex-end' },
      fields: ['mobilePhone', 'search', 'orderBy', 'expand', 'add'],
    },
    {
      id: 'expand',
      fields: [
        'paymentStatus',
        'status',
        'patientName',
        'yearOfDOB',
        'picName',
        'physicsRoomId',
        'fromDate',
        'toDate',
        'reset',
      ],
    },

    { id: 'submit', fields: ['save'] },
  ];
};

const LayoutSchema: ILayoutFields<SchemaProps> = (props) => {
  const { listElement, formProps } = props;
  const { state } = formProps;
  return (
    <>
      <Box display="flex">
        <Box flex={1}>{formProps?.pagin}</Box>
        <Box flex={1}>{listElement[0]}</Box>
      </Box>
      <Collapse in={state?.expand} style={{ marginTop: 16 }}>
        {listElement[1]}
        <Divider style={{ marginBottom: 16, marginTop: 16 }} />
      </Collapse>
    </>
  );
};

export const schemaFilterEncounterForm: ISchemaForm<SchemaProps> = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
