import { Button, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import PrintIcon from '@mui/icons-material/Print';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import { INDICATION_REQUEST_PRINT_TYPE, LOCAL_STORAGE_INDICATION_PRINT_TYPE } from './constant';

interface Props {
  printContentRef: React.MutableRefObject<null>;
  printType: INDICATION_REQUEST_PRINT_TYPE;
  onPrintTypeChange: (type: INDICATION_REQUEST_PRINT_TYPE) => void;
}

const DialogAction = ({ printContentRef, printType, onPrintTypeChange }: Props) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      gap="20px"
      width="100%"
      position="absolute"
      zIndex={50}
      bottom={0}
      sx={{ backgroundColor: '#F4F8FF', padding: '15px 24px' }}
    >
      <RadioGroup
        row
        value={printType}
        onChange={(event) => {
          const val = (event.target as HTMLInputElement).value as INDICATION_REQUEST_PRINT_TYPE;
          onPrintTypeChange(val);
          sessionStorage.setItem(LOCAL_STORAGE_INDICATION_PRINT_TYPE, val);
        }}
      >
        <FormControlLabel value="GROUP" control={<Radio />} label={<FormattedMessage id="printForm.groupingTest" />} />
        <FormControlLabel
          value="SEPARATE"
          control={<Radio />}
          label={<FormattedMessage id="printForm.separatingTest" />}
        />
      </RadioGroup>
      <ReactToPrint
        trigger={() => (
          <Button sx={{ padding: '0 10px' }} startIcon={<PrintIcon />}>
            <FormattedMessage id="therapy.therapyDetail.indicationRequest.printForm.printButton.title" />
          </Button>
        )}
        content={() => printContentRef.current}
      />
    </Stack>
  );
};

export default DialogAction;
