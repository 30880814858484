import makeStyles from '@mui/styles/makeStyles';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PAYMENT_PLAN, some } from '../../../../../common/constants';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import * as React from 'react';
import HeaderPrintForm from '../../../../../common/component/HeaderPrintForm';
import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material';
import { FormattedMessage, FormattedNumber } from 'react-intl';
import moment from 'moment/moment';
import TableCustom from '../../../../../common/component/TableCustom';
import { NumericFormatText } from '../../../../../common/utils';
import DialogCustom from '../../../../../common/component/DialogCustom';
import ReactToPrint from 'react-to-print';
import Print from '@mui/icons-material/Print';

const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    [`@media print`]: {
      padding: 0,
      '& th,tr,td': {
        backgroundColor: 'unset',
      },
    },
  },
});

interface TestReceiptContentProps {
  therapyDetail?: some;
  dataSource: some;
}
interface Props {
  open: boolean;
  onClose?: () => void;
  therapyDetail?: some;
  dataSource: some;
}

const BillFormPrintContent = (props: TestReceiptContentProps) => {
  const { therapyDetail, dataSource } = props;
  const { appState } = useGeneralHook();
  const { currentLocation } = appState.authen;

  const header = React.useMemo(() => {
    return (
      <>
        <HeaderPrintForm>
          <Typography variant="h4" style={{ textTransform: 'uppercase', marginBottom: '25px' }}>
            <FormattedMessage id="printForm.billForCashier" />
          </Typography>
        </HeaderPrintForm>
        <Grid container spacing={1}>
          <Grid item xs={12} sx={{ columnCount: 2 }}>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="cashierPrint.encounterId" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{therapyDetail?.code}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="therapy.label.customer.code" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{therapyDetail?.patient?.code?.split('-')?.[1]}</Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="name" />
              </Typography>
              :&nbsp;
              <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                {therapyDetail?.patient?.name}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="category" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                <FormattedMessage id={PAYMENT_PLAN?.[therapyDetail?.paymentPlan]?.label || ' '} />
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="address" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">
                {therapyDetail?.patient?.patientAddressList?.find((a) => a.type === 'HOME')?.address}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="birthdayShort" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {moment(therapyDetail?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="gender" />
              </Typography>
              :&nbsp;{' '}
              <Typography variant="body1">
                {therapyDetail?.patient?.gender && (
                  <FormattedMessage id={therapyDetail?.patient?.gender?.toLowerCase()} />
                )}
              </Typography>
            </Box>
            <Box display="flex">
              <Typography variant="body1" style={{ minWidth: 160 }}>
                <FormattedMessage id="telephone" />
              </Typography>
              :&nbsp; <Typography variant="body1">{therapyDetail?.patient?.mobilePhone}</Typography>
            </Box>
          </Grid>
        </Grid>
      </>
    );
  }, [therapyDetail]);

  const testInfo = React.useMemo(() => {
    const listCheckedPayment = dataSource?.paymentList?.filter((item) => item.checked);
    return (
      <>
        <Box>
          {listCheckedPayment && listCheckedPayment.length > 0 && (
            <>
              <Typography variant="subtitle1" marginBottom={2} style={{ fontSize: '18px' }}>
                <FormattedMessage id="service" />
              </Typography>
              <TableCustom
                dataSource={listCheckedPayment}
                containerProps={{
                  sx: {
                    '& th,tr,td': {
                      padding: '1px 4px',
                      backgroundColor: 'white',
                      border: '0.5px solid',
                      color: 'unset',
                      fontSize: '19px',
                    },
                    '& th span': {
                      fontSize: '14px',
                      letterSpacing: '1',
                    },
                    '& tr': {
                      pageBreakInside: 'avoid',
                    },
                    width: '100%',
                    overflow: 'visible',
                    '& .extendRow,.extendCell': {
                      border: 'none',
                    },
                  },
                }}
                columns={[
                  {
                    title: 'cashier.service',
                    width: 190,
                    dataIndex: 'content',
                  },
                  {
                    title: 'quantity',
                    dataIndex: 'quantity',
                    align: 'center',
                    render: (record: some) => (record.quantity > 9 ? record.quantity : `0${record.quantity}`),
                  },

                  {
                    title: 'cashier.unit',
                    dataIndex: 'unit',
                    align: 'center',
                    lastCell: {
                      colSpan: 4,
                    },
                  },

                  {
                    title: 'cashier.price',
                    dataIndex: 'unitPrice',
                    render: (record: some) => NumericFormatText(record.unitPrice),
                    align: 'right',
                    lastCell: {
                      render: () => {
                        return (
                          <>
                            <Typography variant="subtitle2" color="textSecondary" textAlign={'end'} fontSize="19px">
                              <FormattedMessage id="total" />
                            </Typography>
                          </>
                        );
                      },
                    },
                  },

                  // {
                  //   title: 'cashier.preDiscountTotal',
                  //   render: (record: some) => NumericFormatText(record.unitPrice * record.quantity),
                  //   align: 'right',
                  //   lastCell: {
                  //     render: () => {
                  //       return (
                  //         <Typography variant="subtitle2" textAlign={'end'}>
                  //           {NumericFormatText(DataService?.reduce((a, b) => a + b.quantity * b.unitPrice, 0) || 0)}
                  //         </Typography>
                  //       );
                  //     },
                  //   },
                  // },
                  {
                    title: 'cashier.discountAmount',
                    dataIndex: 'discountAmount',
                    render: (record: some) => NumericFormatText(record.discountAmount),
                    align: 'right',
                    lastCell: {
                      align: 'right',
                      colSpan: 1,
                      render: () => {
                        return (
                          <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                            {NumericFormatText(listCheckedPayment?.reduce((a, b) => a + b.discountAmount, 0) || 0)}
                          </Typography>
                        );
                      },
                    },
                  },
                  {
                    title: 'totalPrice',
                    align: 'right',
                    render: (record: some) => NumericFormatText(record.totalAmount),
                    lastCell: {
                      align: 'right',
                      colSpan: 1,
                      render: () => {
                        return (
                          <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                            {NumericFormatText(listCheckedPayment?.reduce((a, b) => a + b.totalAmount, 0) || 0)}
                          </Typography>
                        );
                      },
                    },
                  },
                  {
                    title: 'cashier.vatRate',
                    align: 'right',
                    lastCell: {
                      colSpan: 1,
                    },
                    render: (record: some) =>
                      record.vat !== undefined ? (
                        <>
                          <FormattedNumber value={record.vat} maximumFractionDigits={0} />%
                        </>
                      ) : (
                        <FormattedMessage id="cashier.noVat" />
                      ),
                  },
                  {
                    title: 'cashier.vatValue',
                    align: 'right',
                    render: (record: some) =>
                      record.vatAmount !== undefined ? (
                        <FormattedNumber value={record.vatAmount} maximumFractionDigits={0} />
                      ) : (
                        <></>
                      ),
                    lastCell: {
                      align: 'right',
                      colSpan: 1,
                      render: () => {
                        return (
                          <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                            <FormattedNumber value={listCheckedPayment?.reduce((a, b) => a + b.vatAmount, 0) || 0} />
                          </Typography>
                        );
                      },
                    },
                  },
                  {
                    title: 'therapy.label.customer.pay',
                    align: 'right',
                    render: (record: some) => NumericFormatText(record.patientPay),
                    lastCell: {
                      align: 'right',
                      colSpan: 1,
                      render: () => {
                        return (
                          <Typography variant="subtitle2" textAlign={'end'} fontSize="19px">
                            {NumericFormatText(listCheckedPayment?.reduce((a, b) => a + b.patientPay, 0) || 0)}
                          </Typography>
                        );
                      },
                    },
                  },
                ]}
              />
            </>
          )}
        </Box>
      </>
    );
  }, [dataSource]);

  const footer = React.useMemo(() => {
    return (
      <>
        <Box style={{ pageBreakInside: 'avoid', marginTop: 15 }}>
          <Grid container spacing={1}>
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1">&nbsp;</Typography>
              <Typography variant="h6">
                <FormattedMessage id="therapy.indication.request.printForm.patientConfirm" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>
              <Typography variant="body1">{therapyDetail?.patient?.name}</Typography>
            </Grid>
            <Grid item xs={6} textAlign="center">
              <Typography variant="body1" fontStyle="italic">
                <FormattedMessage
                  id={'printForm.dateShort'}
                  values={{
                    hour: moment().hour(),
                    min: moment().minute(),
                    day: moment().date(),
                    month: moment().month() + 1,
                    year: moment().year(),
                  }}
                />
              </Typography>
              <Typography variant="h6">
                <FormattedMessage id="printForm.cashier" />
              </Typography>
              <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                <FormattedMessage id="medicalPrint.helperText" />
              </Typography>{' '}
              <Typography variant="body1">{appState.authen.user?.employee_name}</Typography>
            </Grid>
          </Grid>
        </Box>
      </>
    );
  }, [appState.authen.user?.employee_name, therapyDetail?.patient?.name]);

  if (!currentLocation) {
    return null;
  }
  return (
    <>
      <Box style={{ pageBreakAfter: 'always' }} sx={{ '& p': { fontSize: '18px', marginBottom: '10px' } }}>
        {header}
        <Box marginY={2}>
          <Divider />
        </Box>
        {testInfo}
        {footer}
      </Box>
    </>
  );
};

const BillFormPrintDialog = (props: Props) => {
  const { onClose, open, therapyDetail, dataSource } = props;
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [dataTmp, setTmp] = React.useState(dataSource);

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      TransitionProps={{
        onEnter: () => setTmp(dataSource),
      }}
      PaperProps={{
        style: { maxWidth: '80vw' },
      }}
      title={'printForm.billForCashier'}
      footer={
        <Stack direction="row" justifyContent="flex-end" width="100%">
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="printReceipt" />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />
        </Stack>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <table>
          <tbody>
            <BillFormPrintContent dataSource={dataTmp} therapyDetail={therapyDetail} />
          </tbody>
        </table>
      </Box>
    </DialogCustom>
  );
};

export default BillFormPrintDialog;
