import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../../common/api';
import { some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { setLoading } from '../../../common/redux/commonReducer';
import { fetchThunk } from '../../../common/redux/thunk';
import GroupImageIndicationFormDialog from './GroupImageIndicationFormDialog';
import ImageIndicationFormDialog from './ImageIndicationFormDialog';
import ImageIndicationListTable from './ImageIndicationListTable';
import { FilterParams } from './SearchImageIndication/schema';
import { SYSTEM_CATEGORY_SCOPE, SYSTEM_CATEGORY_TYPE } from 'modules/common/constants';
import { useFetch } from 'modules/common/hook';
import usePriceManage from 'modules/common/hook/usePriceManage';

interface Props {
  categoryId: number;
}

const IndicationBox: React.FunctionComponent<Props> = (props) => {
  const { categoryId } = props;
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const [formData, setFormData] = React.useState<some | undefined>();
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();
  const [searchParams, setSearchParams] = React.useState<FilterParams>({});
  const {
    data = [],
    isValidating,
    revalidate,
  } = useFetch(
    API_SERVER.service.getSystemCategoryList({
      ...searchParams,
      parentId: categoryId,
      scope: SYSTEM_CATEGORY_SCOPE.radiology,
    }),
  );

  const { onDeleteServices } = usePriceManage({ revalidate, setFormData });

  const onSubmitFormData = React.useCallback(
    async (value: some) => {
      if (!value.id) {
        // create new
        dispatch(setLoading(true));
        try {
          const createChargeItemDefRes = await dispatch(
            fetchThunk(API_SERVER.service.create(), 'post', {
              code: value.code,
              name: value.name,
              specializedDepartmentId: value.specializedDepartment,
              physicsRoomId: value.physicsRoom?.value,
              medicalEquipmentId: value.medicalEquipment?.value,
              systemCategoryId: value.listId,
              price: value.price,
            }),
          );

          const newEntryList = value.allItems || [];
          newEntryList.push(createChargeItemDefRes);
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      } else {
        // edit
        dispatch(setLoading(true));
        try {
          await dispatch(
            fetchThunk(API_SERVER.service.update(value.id), 'put', {
              code: value.code,
              name: value.name,
              specializedDepartmentId: value.specializedDepartment,
              physicsRoomId: value.physicsRoom?.value,
              medicalEquipmentId: value.medicalEquipment?.value,
              systemCategoryId: value.listId,
              price: value.price,
            }),
          );

          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onSubmitFormDataGroup = React.useCallback(
    async (value: some) => {
      try {
        dispatch(setLoading(true));

        if (value.id) {
          await dispatch(
            fetchThunk(API_SERVER.systemCategory.update(value.id), 'put', {
              name: value.name,
              parentId: categoryId,
              type: SYSTEM_CATEGORY_TYPE.group,
              scope: SYSTEM_CATEGORY_SCOPE.radiology,
            }),
          );
        } else {
          await dispatch(
            fetchThunk(API_SERVER.systemCategory.create(), 'post', {
              name: value.name,
              parentId: categoryId,
              type: SYSTEM_CATEGORY_TYPE.group,
              scope: SYSTEM_CATEGORY_SCOPE.radiology,
            }),
          );
        }
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        if (e) {
          openSnackbar({ message: e, type: 'error' });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
        setFormDataGroup(undefined);
      }
    },
    [categoryId, dispatch, intl, openSnackbar, revalidate],
  );

  const onDeleteGroup = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog?.promptConfirmation({
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
        warning: true,
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(fetchThunk(API_SERVER.systemCategory.delete(value.id), 'delete'));

          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog?.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar],
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog?.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));

          await dispatch(fetchThunk(API_SERVER.service.delete(value.id), 'delete'));

          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog?.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar],
  );

  return (
    <Paper style={{ flex: 1, overflow: 'auto' }}>
      <ImageIndicationListTable
        loading={isValidating && !data}
        setFormData={setFormData}
        setFormDataGroup={setFormDataGroup}
        onDelete={onDelete}
        onDeleteGroup={onDeleteGroup}
        onDeleteServices={onDeleteServices}
        data={data}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        categoryId={categoryId}
        revalidate={revalidate}
      />
      <ImageIndicationFormDialog
        open={!!formData}
        formData={formData}
        onSubmit={onSubmitFormData}
        onClose={() => {
          setFormData(undefined);
        }}
      />
      <GroupImageIndicationFormDialog
        open={!!formDataGroup}
        formData={formDataGroup}
        onSubmit={onSubmitFormDataGroup}
        onClose={() => {
          setFormDataGroup(undefined);
        }}
      />
    </Paper>
  );
};

export default IndicationBox;
