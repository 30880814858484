import { Add } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { CSVReader } from 'react-papaparse';
import { ImportDrug } from '../../../../svg';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import NoDataBox from 'modules/common/component/NoDataBox';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import CSVPreviewDialog from './CSVPreviewDialog';
import DrugTable from './DrugTable';
import SearchDrug from './SearchDrug';
import ImportUnitExchangeFormDialog from './ImportUnitExchangeFormDialog';
import { axiosThunk } from 'modules/common/redux/axios';
import { API_SERVER } from 'modules/common/api';
import fileDownload from 'js-file-download';
import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { mappedMedicationsFormDataToBEData } from './utils';
import ImportDrugFormDialog from './ImportDrugFormDialog';

interface Props {
  data?: some[];
  loading?: boolean;
  isInsurance?: boolean;
  setFormDataGroup: (value: some) => void;
  onDeleteGroup: (value: some) => void;
  searchParams: some;
  setSearchParams: (value: some) => void;
  revalidate: () => void;
  refreshMedicationCategoryId?: number | null;
  setRefreshMedicationCategoryId?: (categoryId: number | null) => void;
}

const DrugDataListTable: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    setFormDataGroup,
    onDeleteGroup,
    searchParams,
    setSearchParams,
    revalidate,
    loading,
    refreshMedicationCategoryId,
    setRefreshMedicationCategoryId,
  } = props;

  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const buttonRef = React.useRef<any>(null);
  const [dataCSV, setDataCSV] = React.useState<some[] | undefined>(undefined);
  const [openPopup, setOpenPopup] = React.useState<boolean>(false);
  const [openPopupImportDrug, setOpenPopupImportDrug] = React.useState<boolean>(false);
  const [showErrorMessage, setShowErrorMessage] = React.useState<boolean>(false);
  const [showSuccessMessage, setShowSuccessMessage] = React.useState<boolean>(false);
  const [showErrorFileMax, setShowErrorFileMax] = React.useState<boolean>(false);
  const [showErrorFileMaxContent, setShowErrorFileMaxContent] = React.useState<string>('');

  const handleOnDrop = (data) => {
    setDataCSV(data);
  };

  const handleOnRemoveFile = (data) => {
    setDataCSV(undefined);
  };

  const openPopupImport = () => {
    setOpenPopup(true);
  };

  const openPopupImportDrugFunc = () => {
    setOpenPopupImportDrug(true);
  };

  const closePopupImport = () => {
    setOpenPopup(false);
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setShowErrorFileMax(false);
  };

  const closePopupImportDrugFunc = () => {
    setOpenPopupImportDrug(false);
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setShowErrorFileMax(false);
  };

  const resetMessage = () => {
    setShowSuccessMessage(false);
    setShowErrorMessage(false);
    setShowErrorFileMax(false);
  };

  const onUploadFile = useCallback(
    async (file: File | null) => {
      resetMessage();
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: API_SERVER.medicationCategory.uploadExchangeUnitFile(),
          method: 'post',
          responseType: 'blob',
          headers: {
            'Content-type': 'multipart/form-data',
          },
          data: { data: file },
        }),
      )
        .then((response) => {
          let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
          const effectiveFileName = fileName || 'import-exchange-unit.xlsx';
          dispatch(setLoading(false));
          if (response.data.size === 0) {
            setShowSuccessMessage(true);
            return;
          }
          setShowErrorMessage(true);
          fileDownload(response.data, effectiveFileName);
        })
        .catch((e: AxiosError<some>) => {
          dispatch(setLoading(false));
          openSnackbar({ message: intl.formatMessage({ id: 'report.uploadFail' }), type: 'error' });
        });
    },
    [dispatch, intl, openSnackbar],
  );

  // upload file drug data
  const onUploadFileDrug = useCallback(
    async (file: File | null) => {
      resetMessage();
      dispatch(setLoading(true));
      try {
        const response = await dispatch(
          axiosThunk({
            url: API_SERVER.medicationCategory.uploadDrugFile(),
            method: 'post',
            responseType: 'blob',
            headers: {
              'Content-type': 'multipart/form-data',
            },
            data: { data: file },
            timeout: 900000, // 15m
          }),
        );
        let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const effectiveFileName = fileName || 'import-exchange-unit.xlsx';
        dispatch(setLoading(false));
        if (response.data.size === 0) {
          setShowSuccessMessage(true);
          return;
        }
        setShowErrorMessage(true);
        fileDownload(response.data, effectiveFileName);
      } catch (e: any) {
        dispatch(setLoading(false));
        if (e.response?.status === 400) {
          const fr = new FileReader();
          fr.onload = function (e) {
            if (e.target?.result) {
              setShowErrorFileMaxContent(JSON.parse(e.target?.result.toString())?.errors[0]?.message);
            }
          };
          fr.readAsText(e?.response.data);
          return setShowErrorFileMax(true);
        }
        openSnackbar({ message: intl.formatMessage({ id: 'report.uploadFail' }), type: 'error' });
      }
    },
    [dispatch, intl, openSnackbar],
  );

  const onDownLoadFile = async () => {
    resetMessage();
    dispatch(setLoading(true));
    await dispatch(
      axiosThunk({
        url: API_SERVER.medicationCategory.downloadSimpleExchangeUnitFile,
        method: 'get',
        responseType: 'blob',
      }),
    )
      .then((response) => {
        let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const effectiveFileName = fileName || 'import-exchange-unit.xlsx';
        fileDownload(response.data, effectiveFileName);
        dispatch(setLoading(false));
      })
      .catch((e: AxiosError<some>) => {
        dispatch(setLoading(false));
        openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
      });
  };

  // download file drug data
  const onDownLoadFileDrug = async () => {
    resetMessage();
    dispatch(setLoading(true));
    await dispatch(
      axiosThunk({
        url: API_SERVER.medicationCategory.downloadSimpleDrugFile,
        method: 'get',
        responseType: 'blob',
      }),
    )
      .then((response) => {
        let fileName = response.headers['content-disposition'].split('filename=')[1].split(';')[0];
        const effectiveFileName = fileName || 'import-exchange-unit.xlsx';
        fileDownload(response.data, effectiveFileName);
        dispatch(setLoading(false));
      })
      .catch((e: AxiosError<some>) => {
        dispatch(setLoading(false));
        openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
      });
  };

  const onShowErrorFileMax = async () => {
    setShowErrorFileMax(true);
  };

  const renderImportCSVButton = useCallback(() => {
    return (
      <CSVReader ref={buttonRef} onRemoveFile={handleOnRemoveFile} onDrop={handleOnDrop} noClick noDrag noProgressBar>
        {() => (
          <Button
            variant="outlined"
            color="primary"
            startIcon={<ImportDrug />}
            style={{ marginRight: 16 }}
            onClick={() => setOpenPopupImportDrug(true)}
          >
            <FormattedMessage id="lab.input" />
          </Button>
        )}
      </CSVReader>
    );
  }, []);

  if (!loading && data && data?.length === 0 && !searchParams?.name) {
    return (
      <>
        <Box padding={2}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="50vh"
            overflow="auto"
          >
            <Typography variant="h5" color="primary">
              <FormattedMessage id="indication.empty" />
            </Typography>

            <Box padding={2}>
              <Typography variant="body1">
                <FormattedMessage id="addNewList" />
              </Typography>

              <Box display="flex" pt={3}>
                {renderImportCSVButton()}
                <Button variant="contained" color="primary" startIcon={<Add />} onClick={() => setFormDataGroup({})}>
                  <FormattedMessage id="add" />
                </Button>
              </Box>
            </Box>
          </Box>
          <CSVPreviewDialog
            open={!!dataCSV}
            dataCSV={dataCSV}
            onClose={() => setDataCSV(undefined)}
            onSubmit={async (submittedCSVData) => {
              if (submittedCSVData.length < 1) {
                return;
              }

              dispatch(setLoading(true));
              try {
                await dispatch(
                  axiosThunk({
                    url: API_SERVER.medication.uploadMultiMedication(),
                    method: 'post',
                    data: { data: mappedMedicationsFormDataToBEData(submittedCSVData) },
                    timeout: 900000, // 15m
                  }),
                );
                revalidate();
                openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
                setDataCSV(undefined);
              } catch (e) {
                openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
              } finally {
                dispatch(setLoading(false));
              }
            }}
          />
        </Box>
        <ImportDrugFormDialog
          open={openPopupImportDrug}
          onClose={closePopupImportDrugFunc}
          onUploadFile={onUploadFileDrug}
          onDownLoadFile={onDownLoadFileDrug}
          showErrorMessage={showErrorMessage}
          showSuccessMessage={showSuccessMessage}
          showErrorFileMax={showErrorFileMax}
          onShowErrorFileMax={onShowErrorFileMax}
          showErrorFileMaxContent={showErrorFileMaxContent}
        />
      </>
    );
  }

  return (
    <>
      <Box padding={2} paddingBottom={1}>
        <SearchDrug
          onAddNew={() => setFormDataGroup({})}
          onSubmit={setSearchParams}
          formData={searchParams}
          onImportExchange={openPopupImport}
          onImportDrug={openPopupImportDrugFunc}
          renderImportCSVButton={renderImportCSVButton}
        />
      </Box>
      {loading ? (
        <LoadingIcon />
      ) : (
        <Box padding={2} paddingTop={0} overflow="auto">
          {data && data?.length > 0 ? (
            data?.map((value: some) => {
              return (
                <DrugTable
                  key={value.id}
                  setFormDataGroup={setFormDataGroup}
                  onDeleteGroup={onDeleteGroup}
                  data={value}
                  revalidateCategory={revalidate}
                  refreshMedicationCategoryId={refreshMedicationCategoryId}
                  setRefreshMedicationCategoryId={setRefreshMedicationCategoryId}
                />
              );
            })
          ) : (
            <NoDataBox />
          )}
        </Box>
      )}
      <ImportUnitExchangeFormDialog
        open={openPopup}
        onClose={closePopupImport}
        onUploadFile={onUploadFile}
        onDownLoadFile={onDownLoadFile}
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
        showErrorFileMax={showErrorFileMax}
        onShowErrorFileMax={onShowErrorFileMax}
      />
      <ImportDrugFormDialog
        open={openPopupImportDrug}
        onClose={closePopupImportDrugFunc}
        onUploadFile={onUploadFileDrug}
        onDownLoadFile={onDownLoadFileDrug}
        showErrorMessage={showErrorMessage}
        showSuccessMessage={showSuccessMessage}
        showErrorFileMax={showErrorFileMax}
        onShowErrorFileMax={onShowErrorFileMax}
        showErrorFileMaxContent={showErrorFileMaxContent}
      />
      <CSVPreviewDialog
        open={!!dataCSV}
        dataCSV={dataCSV}
        onClose={() => setDataCSV(undefined)}
        onSubmit={async (submittedCSVData) => {
          if (submittedCSVData.length < 1) {
            return;
          }

          dispatch(setLoading(true));
          try {
            await dispatch(
              axiosThunk({
                url: API_SERVER.medication.uploadMultiMedication(),
                method: 'post',
                data: { data: mappedMedicationsFormDataToBEData(submittedCSVData) },
              }),
            );
            revalidate();
            openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
            setDataCSV(undefined);
          } catch (e) {
            openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
          } finally {
            dispatch(setLoading(false));
          }
        }}
      />
    </>
  );
};

export default DrugDataListTable;
