import { Box, Stack, Typography, styled } from '@mui/material';
import { GENDER } from 'modules/common/apiConstants';
import { BE_DATE_FORMAT } from 'modules/common/constants';
import { Patient } from 'modules/schema';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';

const PatientInfoTitle = styled(Typography)({
  color: '#263238',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  letterSpacing: '0.5px',
});

const PatientInfoValue = styled(Typography)({
  color: '#263238',
  fontFamily: 'Roboto',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  letterSpacing: '0.5px',
  marginLeft: '10px',
  maxWidth: '150px',
  wordBreak: 'break-word',
});

interface Props {
  patient: Patient;
}

const PatientInfo = ({ patient }: Props) => {
  return (
    <Box>
      <Stack direction="row">
        <PatientInfoTitle>
          <FormattedMessage id="fullName" /> :
        </PatientInfoTitle>
        <PatientInfoValue>{patient.name}</PatientInfoValue>
      </Stack>
      <Stack direction="row">
        <PatientInfoTitle>
          <FormattedMessage id="gender" /> :
        </PatientInfoTitle>
        <PatientInfoValue>
          <FormattedMessage id={GENDER[patient.gender!].label || ' '} />
        </PatientInfoValue>
      </Stack>
      <Stack direction="row">
        <PatientInfoTitle>
          <FormattedMessage id="birthYear" /> :
        </PatientInfoTitle>
        <PatientInfoValue>{moment(patient.dob, BE_DATE_FORMAT).year()}</PatientInfoValue>
      </Stack>
      <Stack direction="row">
        <PatientInfoTitle>
          <FormattedMessage id="patient.management.table.title.address" /> :
        </PatientInfoTitle>
        <PatientInfoValue>{patient.homeAddress?.address}</PatientInfoValue>
      </Stack>
    </Box>
  );
};

export default PatientInfo;
