import { AppointmentTooltip as AppointmentTooltipBase } from '@devexpress/dx-react-scheduler';
import { Box, Button, IconButton, Typography } from '@mui/material';
import { GroupIcon, PersonIcon } from '../../../../../svg';
import { FormattedMessage } from 'react-intl';
import { ENCOUNTER_STATUS, some } from '../../../../common/constants';
import { Close, Delete, Edit } from '@mui/icons-material';
import { APPOINTMENT_TYPES } from '../constants';

interface Props extends AppointmentTooltipBase.HeaderProps {
  onUpdate: (value: some) => void;
  onDelete: (value: some) => void;
}

const AppointmentHeaderTooltip = ({ appointmentData: data, onHide, onUpdate, onDelete }: Props) => {
  if (!data) return null;
  const color = data.appointmentGroupId
    ? APPOINTMENT_TYPES.APPOINTMENT_GROUP.color
    : data.patientId
    ? APPOINTMENT_TYPES.PATIENT.color
    : data.type === 'THERAPY'
    ? APPOINTMENT_TYPES.THERAPY.color
    : '';

  const lightColor = data.appointmentGroupId
    ? APPOINTMENT_TYPES.APPOINTMENT_GROUP.lightColor
    : data.patientId
    ? APPOINTMENT_TYPES.PATIENT.lightColor
    : data.type === 'THERAPY'
    ? APPOINTMENT_TYPES.THERAPY.lightColor
    : '';

  const label = data.appointmentGroupId
    ? APPOINTMENT_TYPES.APPOINTMENT_GROUP.label
    : data.patientId
    ? APPOINTMENT_TYPES.PATIENT.label
    : APPOINTMENT_TYPES.THERAPY.label;

  return (
    <Box
      display={'flex'}
      gap={2}
      alignItems="center"
      sx={{
        p: 1,
        bgcolor: lightColor,
        userSelect: 'text',
      }}
    >
      <Button
        variant="contained"
        sx={{
          bgcolor: color,
        }}
        disableRipple
        disableElevation
        size="small"
        style={{ cursor: 'unset', padding: 0 }}
      >
        <Box paddingX={0.5}>{data.appointmentGroupId ? <GroupIcon /> : <PersonIcon />}</Box>
        <Typography variant="body2" style={{ padding: '4px 8px 4px 0px' }}>
          <FormattedMessage id={label} />
        </Typography>
      </Button>
      <Box display={'flex'} gap={1} justifyContent="flex-end" flex={1}>
        {data?.type === APPOINTMENT_TYPES.THERAPY.value || (
          <>
            <IconButton
              onClick={() => {
                onUpdate(data);
                onHide && onHide();
              }}
              disabled={data?.status === ENCOUNTER_STATUS.arrived}
            >
              <Edit />
            </IconButton>
            <IconButton
              onClick={() => {
                onDelete(data);
                onHide && onHide();
              }}
              disabled={data?.status === ENCOUNTER_STATUS.arrived}
            >
              <Delete />
            </IconButton>
          </>
        )}
        <IconButton onClick={onHide}>
          <Close />
        </IconButton>
      </Box>
    </Box>
  );
};

export default AppointmentHeaderTooltip;
