import { BE_DATE_TIME_FORMAT, FE_TIME_FORMAT, some } from '../../../../common/constants';
import { Box, Dialog, DialogTitle, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { Close } from '@mui/icons-material';
import SchemaForm from '../../../../common/SchemaForm';
import schema from './schema';
import { IDENTITY_API_SERVER } from '../../../../common/api';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { useMemo } from 'react';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: some) => void;
  data: some | null;
}

const UpdateTherapyDialog = ({ open, onClose, onSubmit, data }: Props) => {
  const { isBasic } = useGeneralHook();
  const { data: nurseAndDoctors = [] } = useFetch(
    IDENTITY_API_SERVER.user.getListUserByRoles({
      roles: isBasic
        ? ['DOCTOR', 'GENERAL_DOCTOR', 'RADIOLOGIST', 'CLINICAL_PATHOLOGIST'].join(',')
        : [
            'DOCTOR',
            'NURSE',
            'RADIOLOGIST',
            'LABORATORY_TECHNOLOGIST',
            'RADIOGRAPHER',
            'CLINICAL_PATHOLOGIST',
            'GENERAL_DOCTOR',
          ].join(','),
    }),
  );

  const picOptions = useMemo(() => {
    const options = nurseAndDoctors.map((ele) => ({ value: ele?.id, label: ele?.employeeFullName }));
    if (data?.picUserId && !options.find((option) => option?.value === data.picUserId)) {
      return [...options, { value: data?.picUserId, label: data?.picName }];
    }
    return options;
  }, [data?.picName, data?.picUserId, nurseAndDoctors]);

  const formData = useMemo(() => {
    const startTime = moment(data?.startTime, BE_DATE_TIME_FORMAT);
    const endTime = moment(data?.endTime, BE_DATE_TIME_FORMAT);
    const date = startTime?.isValid() ? startTime.toDate() : endTime?.isValid() ? endTime.toDate() : null;
    return {
      id: data?.id,
      name: data?.name,
      date: date || '',
      startTime: startTime.isValid() ? startTime.format(FE_TIME_FORMAT) : '',
      endTime: endTime.isValid() ? endTime.format(FE_TIME_FORMAT) : '',
      pic: picOptions.find((ele) => ele?.value === data?.picUserId)?.value,
      content: data?.description,
      patient: data?.patient,
    };
  }, [data, picOptions]);

  return (
    <Dialog
      open={open}
      maxWidth="md"
      keepMounted={false}
      PaperProps={{
        style: { width: 500, position: 'relative', overflow: 'hidden' },
      }}
    >
      <DialogTitle sx={{ bgcolor: 'primary.50' }} style={{ position: 'sticky', top: 0, padding: 16, zIndex: 2 }}>
        <Box>
          <Typography variant="h6" color="primary">
            <FormattedMessage id="therapyList.dialog.update.main.title" />
          </Typography>
        </Box>
        <Box position="absolute" top={6} right={0} marginX={2} marginY={1}>
          <IconButton onClick={onClose} size="small">
            <Close fontSize="small" />
          </IconButton>
        </Box>
      </DialogTitle>
      <Box padding={2}>
        <SchemaForm
          schema={schema}
          onSubmit={onSubmit}
          formId="therapy-session"
          formData={formData}
          onCancel={onClose}
          picOptions={picOptions}
        />
      </Box>
    </Dialog>
  );
};

export default UpdateTherapyDialog;
