import Delete from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import * as React from 'react';
import IconButtonTitle from '../../../common/component/IconButtonTitle';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { some } from '../../../common/constants';
import PasswordIcon from '@mui/icons-material/Password';
import { FormattedMessage } from 'react-intl';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import { USER_ROLE_LIST } from './constant';
import { User } from 'modules/schema';
import { Page } from 'modules/schema/common';

interface Props {
  data?: Page<User>;
  hookPagination: HookPaginationProps;
  onEdit: (item: some) => void;
  onDelete: (item: some) => void;
  onChangePassword: (item: some) => void;
  loading?: boolean;
}

const AccountTable: React.FunctionComponent<Props> = (props) => {
  const { data, hookPagination, onEdit, onDelete, onChangePassword, loading } = props;
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const columns = React.useMemo(() => {
    return [
      {
        title: 'accountAdmin.accountId',
        dataIndex: 'username',
      },
      {
        title: 'accountAdmin.role',
        render: (record) => (
          <FormattedMessage
            id={record?.roleName ? USER_ROLE_LIST.find((r) => r.value === record.roleName)?.label : ''}
          />
        ),
      },
      {
        title: 'accountAdmin.name',
        render: (record) => record?.employeeFullName,
      },
      {
        title: 'accountAdmin.licenseNumber',
        dataIndex: 'practiceCertificate',
      },
      {
        title: 'action',
        align: 'right',
        render: (record) => {
          return (
            <>
              <IconButtonTitle
                title="changePassword"
                size="small"
                onClick={() => {
                  onChangePassword(record);
                }}
              >
                <PasswordIcon />
              </IconButtonTitle>
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  onEdit(record);
                }}
                sx={{ marginX: 1 }}
              >
                <EditIcon />
              </IconButtonTitle>
              <IconButtonTitle
                title="delete"
                size="small"
                onClick={() => {
                  onDelete(record);
                }}
              >
                <Delete />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns<User>[];
  }, [onChangePassword, onDelete, onEdit]);

  return (
    <TableCustom
      dataSource={data?.content}
      loading={loading}
      columns={columns}
      paginationProps={{
        count: data?.pagination?.totalElements || 0,
        page: pagination.page,
        rowsPerPage: pagination.pageSize,
        onPageChange: onPageChange,
        onRowsPerPageChange: onRowsPerPageChange,
      }}
    />
  );
};

export default AccountTable;
