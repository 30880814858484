import { Paper } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../../common/api';
import LoadingIcon from '../../common/component/LoadingIcon';
import { some, SYSTEM_CATEGORY_TYPE } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { setLoading } from '../../common/redux/commonReducer';
import IndicationBox from '../component/indication/IndicationBox';
import PriceListFormDialog from '../component/indication/PriceListFormDialog';
import TabCategory from '../component/indication/TabCategory';
import { axiosThunk } from '../../common/redux/axios';
import { useFetch } from 'modules/common/hook';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';

interface Props {}

const IndicationAdminPage: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const [categoryListFormData, setCategoryListFormData] = React.useState<some | undefined>();
  const [categoryId, setCategoryId] = React.useState<number | undefined>();
  const {
    data: categoryList,
    revalidate,
    isValidating,
  } = useFetch(
    API_SERVER.systemCategory.getList({
      scope: SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
      type: SYSTEM_CATEGORY_TYPE.priceList,
    }),
  );

  const category = categoryList?.find((v) => v.id === categoryId);
  const onSubmitCategoryPlanDefinitionForm = React.useCallback(
    async (value: some) => {
      const data = {
        ...value,
        type: SYSTEM_CATEGORY_TYPE.priceList,
        scope: SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value,
      };
      try {
        dispatch(setLoading(true));
        if (value.id) {
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.update(value.id), method: 'put', data }));
        } else {
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.create(), method: 'post', data }));
        }
        revalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e: any) {
        const errors = e?.response?.data?.errors;
        if (errors) {
          errors?.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
        setCategoryListFormData(undefined);
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onDeleteCategory = React.useCallback(
    async (value: some) => {
      try {
        const confirm = await confirmDialog?.promptConfirmation({
          warning: true,
          content: intl.formatMessage({ id: 'confirmDeleteServicePrice' }, { name: value.name }),
          title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        });
        if (confirm) {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.systemCategory.delete(value.id), method: 'delete' }));
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        }
      } catch (e: any) {
        e?.response?.data?.errors?.forEach((v) => {
          openSnackbar({ message: v.message, type: 'error' });
        });
      } finally {
        dispatch(setLoading(false));
        setCategoryListFormData(undefined);
      }
      confirmDialog?.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, revalidate],
  );

  React.useEffect(() => {
    if (!categoryId && categoryList && categoryList?.length > 0) {
      setCategoryId(categoryList?.[0]?.id);
    }
  }, [categoryId, categoryList]);

  return (
    <Paper style={{ flex: 1 }}>
      <TabCategory
        data={categoryList}
        onCreateOrUpdateCategory={setCategoryListFormData}
        onDeleteCategory={onDeleteCategory}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
      />
      {categoryList! && isValidating && <LoadingIcon />}
      {category && <IndicationBox category={category} />}
      <PriceListFormDialog
        open={!!categoryListFormData}
        formData={categoryListFormData}
        onSubmit={onSubmitCategoryPlanDefinitionForm}
        onClose={() => {
          setCategoryListFormData(undefined);
        }}
        categoryList={categoryList}
      />
    </Paper>
  );
};

export default IndicationAdminPage;
