import Delete from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER, IDENTITY_API_SERVER } from '../../../../common/api';
import IconButtonTitle from '../../../../common/component/IconButtonTitle';
import LoadingIcon from '../../../../common/component/LoadingIcon';
import TableCustom from '../../../../common/component/TableCustom';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import SchemaForm from '../../../../common/SchemaForm';
import { ROLE_INFO } from '../../../../layout/utils';
import { useFetch, useCreateMutate, useDeleteMutate } from 'modules/common/hook';
import { keyBy, sortBy } from 'lodash';
import { IndicationRequest } from 'modules/schema';

interface Props {
  data: IndicationRequest;
  editable: boolean;
}

const PARTICIPANTS = [ROLE_INFO.examDoctor, ROLE_INFO.imageSpecialist, ROLE_INFO.imageTechnician, ROLE_INFO.nurse];

const ParticipantTab: React.FunctionComponent<Props> = ({ data, editable }) => {
  const { intl, confirmDialog } = useGeneralHook();

  const { data: users } = useFetch(
    IDENTITY_API_SERVER.user.getListUserByRoles({
      roles: PARTICIPANTS.map((ele) => ele.roleKey).join(','),
      status: 'ACTIVE',
    }),
  );

  const { data: participants, revalidate: participantsRevalidate } = useFetch(
    API_SERVER.indicationAttendPerson.get(data?.id || 0),
    { enabled: data?.id },
  );

  const participantOptions = React.useMemo(() => {
    if (users && users.length > 0) {
      const roleMap = keyBy(PARTICIPANTS, 'roleKey');
      return sortBy(
        users
          ?.filter((ele) => ele?.practiceCertificate)
          ?.map((ele) => {
            return {
              id: ele?.id,
              name: ele?.employeeFullName,
              roleName: ele?.roleName,
              group: intl.formatMessage({ id: ele?.roleName && roleMap[ele?.roleName]?.label }),
            };
          }),
        'group',
      );
    }
    return [];
  }, [intl, users]);

  const participantsMapped = React.useMemo(() => {
    return participants?.map((person) => ({
      ...person,
      roleLabel: intl.formatMessage({
        id: PARTICIPANTS.find((ele) => ele?.roleKey === person?.roleName)?.label || ' ',
      }),
    }));
  }, [intl, participants]);

  const addParticipantMutate = useCreateMutate({ onSuccess: participantsRevalidate });
  const addParticipant = React.useCallback(
    async (value) => {
      const dataAdd = {
        indicationRequestId: data?.id,
        roleName: value?.roleName,
        userId: value?.id,
        employeeName: value?.name,
      };

      await addParticipantMutate({
        url: API_SERVER.indicationAttendPerson.create(),
        method: 'post',
        data: dataAdd,
      });
    },
    [addParticipantMutate, data?.id],
  );

  const deletePerformerMutate = useDeleteMutate({ onSuccess: participantsRevalidate });
  const deletePerformer = React.useCallback(
    async (value) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        await deletePerformerMutate({
          url: API_SERVER.indicationAttendPerson.delete(value?.id),
          method: 'delete',
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, deletePerformerMutate, intl],
  );

  if (!data) {
    return <LoadingIcon />;
  }

  return (
    <>
      {editable && (
        <SchemaForm
          hideSubmitButton
          submitLabel={<FormattedMessage id="status.finished" />}
          schema={{
            fields: ({ formProps: { intl }, valuesField }) => {
              const nameOptions = valuesField?.role
                ? participantOptions?.filter((ele) => ele?.roleName === valuesField?.role?.roleKey)
                : participantOptions;
              return {
                role: {
                  type: 'auto-complete',
                  label: intl.formatMessage({ id: 'role' }),
                  placeholder: intl.formatMessage({ id: 'roleEnter' }),
                  options: PARTICIPANTS,
                  getOptionLabel: (role) => {
                    return intl.formatMessage({ id: role.label });
                  },
                  propsWrapper: { xs: 5 },
                },
                name: {
                  type: 'auto-complete',
                  label: intl.formatMessage({ id: 'employeeName' }),
                  placeholder: intl.formatMessage({ id: 'employeeEnter' }),
                  propsWrapper: { xs: 7 },
                  options: nameOptions,
                  groupBy: (option) => option.group,
                  getOptionLabel: (opt) => {
                    return opt.name;
                  },
                  getOptionDisabled: (opt) => {
                    return !!participantsMapped?.find((ele) => ele?.userId === opt?.id);
                  },
                  onChange: (v) => {
                    if (!!v) {
                      addParticipant(v);
                    }
                  },
                },
              };
            },
          }}
        />
      )}
      <Box marginTop={1}>
        <TableCustom
          dataSource={participantsMapped}
          columns={[
            {
              title: 'role',
              dataIndex: 'roleLabel',
              width: '30%',
            },
            {
              title: 'employeeName',
              dataIndex: 'employeeName',
              width: '60%',
            },
            {
              title: 'action',
              width: '10%',
              hidden: !editable,
              render: (record) => (
                <>
                  <IconButtonTitle title="delete" size="small" onClick={() => deletePerformer(record)}>
                    <Delete />
                  </IconButtonTitle>
                </>
              ),
            },
          ]}
        />
      </Box>
    </>
  );
};

export default ParticipantTab;
