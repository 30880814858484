import { z } from 'zod';
import { UnitSchema } from './Unit';
import { RegionSchema } from './Region';
import { DistrictSchema } from './District';

export const ProvinceSchema = z
  .object({
    code: z.string(),
    codeName: z.string(),
    name: z.string(),
    nameEn: z.string(),
    fullName: z.string(),
    fullNameEn: z.string(),
    unitId: z.number(),
    regionId: z.number(),
    unit: UnitSchema,
    region: RegionSchema,
    districts: z.array(DistrictSchema),
  })
  .partial();

export type Province = z.infer<typeof ProvinceSchema>;
