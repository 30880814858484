import { InputLabel, Typography } from '@mui/material';
import { SunEditorReactProps } from 'suneditor-react/dist/types/SunEditorReactProps';
import React, { forwardRef, memo } from 'react';

export function extractHTMLContent(html) {
  if (!html) {
    return null;
  }
  return new DOMParser().parseFromString(html, 'text/html').documentElement.textContent;
}

export interface LabelElementProps extends Omit<SunEditorReactProps, 'defaultValue' | 'onChange'> {
  label?: React.ReactNode | string;
  labelValue?: string;
}

interface Props extends LabelElementProps {}

const LabelElement = forwardRef((props: Props, ref: any) => {
  const { label, labelValue } = props;
  return (
    <div>
      {label && <InputLabel shrink>{label}</InputLabel>}
      <Typography mt={1.5}>{labelValue}</Typography>
    </div>
  );
});

export default memo(LabelElement);
