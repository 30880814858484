import { useTheme } from '@mui/material';
import { useCallback, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../redux/reducer';
import { API_SERVER, NOTIFICATION_API_SERVER } from '../api';
import { useConfirmDialog } from '../component/ConfirmDialogProvider';
import { some } from '../constants';
import { axiosThunk } from '../redux/axios';
import { NotifyProps, setLoading, setOpenNotify, setOpenSnackbar, SnackbarProps } from '../redux/commonReducer';
import { fetchThunk } from '../redux/thunk';
import { GROUP_VERSION } from '../apiConstants';

const useGeneralHook = () => {
  const dispatch: ThunkDispatch<AppState, null, AnyAction> = useDispatch();
  const appState = useSelector((state: AppState) => state);
  const theme = useTheme();
  const confirmDialog = useConfirmDialog();
  const location = useLocation();
  const history = useHistory();
  const intl = useIntl();
  const [state, setState] = useState<some>({});

  const setLoadingFnc = useCallback(
    (open: boolean) => {
      dispatch(setLoading(open));
    },
    [dispatch],
  );

  return {
    intl,
    dispatch,
    appState,
    openSnackbar: (val: Omit<SnackbarProps, 'isOpen'>) => dispatch(setOpenSnackbar(val)),
    openNotify: (val: Omit<NotifyProps, 'isOpen'>) => dispatch(setOpenNotify(val)),
    theme,
    confirmDialog,
    location,
    history,
    fetchThunk: fetchThunk,
    axiosThunk: axiosThunk,
    setLoading: setLoadingFnc,
    locationId: appState.authen.currentLocation?.id,
    state,
    setState,
    API_SERVER,
    NOTIFICATION_API_SERVER,
    isBasic: appState.authen.user?.group_version === GROUP_VERSION.BASIC,
  };
};

export default useGeneralHook;
