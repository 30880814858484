import { z } from 'zod';
import { UnitQuantitySchema } from './UnitQuantity';
import { MedicationPackageSchema } from './MedicationPackage';
import { UnitConverterSchema } from './UnitConverter';

export const InventorySchema = z
  .object({
    medicationId: z.number(),
    code: z.string(),
    name: z.string(),
    unitQuantityList: z.array(UnitQuantitySchema),
    quantityStatus: z.string(),
    medicationPackageList: z.array(MedicationPackageSchema),
    unitConverterList: z.array(UnitConverterSchema),
    resourceId: z.string(),
    medicationCategory: z.string(),
  })
  .partial();

export type Inventory = z.infer<typeof InventorySchema>;
