import * as React from 'react';
import { useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import usePaginationHook from '../../common/hook/usePaginationHook';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { useState } from 'react';
import { API_SERVER } from '../../common/api';
import { formatDateFilterField } from '../../common/utils';
import { axiosThunk } from '../../common/redux/axios';
import { setLoading } from '../../common/redux/commonReducer';
import { Paper, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import RegisterDentalExamDialog from 'modules/reception/component/dental/ReceiveWizardDialog';
import UpdateDentalExamDialog from 'modules/reception/component/dental/UpdateDentalExamDialog';
import FilterDentalBox from 'modules/reception/component/dental/FilterDentalBox';
import DentalList from '../component/dental/DentalList';
import { useFetch } from 'modules/common/hook';

interface Props {}

const DentalExamListPage: React.FunctionComponent<Props> = () => {
  const [openRegisterForm, setOpenRegisterForm] = React.useState(false);
  const orderTherapy = useSelector((appState: AppState) => appState.common.orderTherapy);
  const hookPagination = usePaginationHook({
    defaultFilter: {
      orderBy: orderTherapy,
    },
  });
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const { params } = hookPagination;
  const [selectedSessionTherapy, setSelectedSessionTherapy] = useState(null);

  const { data, revalidate, isValidating } = useFetch(
    API_SERVER.dentalExam.getList(formatDateFilterField(params, 'fromDate', 'toDate')),
    {
      revalidateOnMount: true,
      revalidateOnFocus: false,
    },
  );
  const onUpdateTherapySession = async (data) => {
    dispatch(setLoading(true));
    try {
      await dispatch(axiosThunk({ url: API_SERVER.therapy.updateTherapySession(data.id), method: 'put', data }));
      openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.success' }) });
      setSelectedSessionTherapy(null);
      revalidate();
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.failed' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterDentalBox
          hookPagination={hookPagination}
          onRegister={() => setOpenRegisterForm(true)}
          pagin={
            <>
              <Typography variant="h6">
                <FormattedMessage id="navMenu.encounterList.dental" />
              </Typography>
              <Typography variant="caption">
                <FormattedMessage
                  id="foundResult"
                  values={{
                    num: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.content?.length || 0}
                      </Typography>
                    ),
                    total: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.pagination?.totalElements || 0}
                      </Typography>
                    ),
                  }}
                />
              </Typography>
            </>
          }
          onDataLoading={isValidating}
        />
        <DentalList
          refresh={() => {
            revalidate();
          }}
          data={data?.content}
          total={data?.pagination?.totalElements || 0}
          hookPagination={hookPagination}
          loading={isValidating}
        />
      </Paper>

      <RegisterDentalExamDialog
        open={openRegisterForm}
        mode={{
          type: 'create-new',
        }}
        close={() => {
          setOpenRegisterForm(false);
        }}
        refresh={revalidate}
        selectedObject={{}}
      />
      <UpdateDentalExamDialog
        open={!!selectedSessionTherapy}
        onClose={() => setSelectedSessionTherapy(null)}
        onSubmit={onUpdateTherapySession}
        data={selectedSessionTherapy}
      />
    </>
  );
};

export default DentalExamListPage;
