import CheckBoxIcon from '@mui/icons-material/CheckBox';
import Print from '@mui/icons-material/Print';
import { Box, Button, Divider, FormControlLabel, Grid, Switch, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import ReactToPrint from 'react-to-print';
import DialogCustom from 'modules/common/component/DialogCustom';
import HeaderPrintForm from 'modules/common/component/HeaderPrintForm';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { Encounter, MedicationUsage } from 'modules/schema';
import { GENDER } from 'modules/common/apiConstants';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import _ from 'lodash';
import { EncounterContext, renderLabelSearch } from '../../../const';

const COMPACT_TYPE = 'DRUG_USAGE_EXAM_DOCTOR_PRINT_COMPACT_TYPE';
const useStyles = makeStyles({
  print: {
    position: 'relative',
    height: '100%',
    display: 'block',
    padding: '24px 16px',
    '& .hiddenPage': { display: 'none' },
    [`@media print`]: {
      '& .newPage': { pageBreakBefore: 'always' },
      '& .hiddenPage': { display: 'block' },
      padding: 0,
    },
  },
});

interface PrescriptionPrintExamDoctorContentProps {
  encounter: Encounter;
  medicationUsages: MedicationUsage[];
  compact: boolean;
}
interface Props {
  open: boolean;
  onClose?: () => void;
  medicationUsages: MedicationUsage[];
}

export const PrescriptionPrintExamDoctorContent = React.forwardRef(
  (props: PrescriptionPrintExamDoctorContentProps, ref) => {
    const { encounter, medicationUsages, compact } = props;
    const intendedRoutes = useSelector((state: AppState) => state.common.intendedRoutes);

    const cancelMedicationUsage = React.useMemo(
      () =>
        medicationUsages?.filter(
          (medicationUsage) => medicationUsage?.cancellationQuantity && medicationUsage?.cancellationQuantity > 0,
        ) || [],
      [medicationUsages],
    );

    const footer = React.useMemo(
      () => (
        <Box>
          <Box style={{ pageBreakInside: 'avoid', marginTop: 12 }}>
            <Grid container>
              {compact ? (
                <Grid item xs={6} />
              ) : (
                <>
                  <Grid item xs={3} textAlign="center">
                    <Typography variant="body1">&nbsp;</Typography>
                    <Typography variant="h6">
                      <FormattedMessage id="printForm.patientConfirm" />
                    </Typography>
                    <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                      <FormattedMessage id="medicalPrint.helperText" />
                    </Typography>
                    <Typography variant="body1">{encounter?.patient?.name}</Typography>
                  </Grid>
                  <Grid item xs={3} textAlign="center">
                    <Typography variant="body2">&nbsp;</Typography>
                    <Typography variant="h6">
                      <FormattedMessage id="drugUsage.employee" />
                    </Typography>
                    <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                      <FormattedMessage id="medicalPrint.helperText" />
                    </Typography>
                  </Grid>
                </>
              )}
              <Grid item xs={6} textAlign="center">
                <Typography variant="body1" fontStyle="italic">
                  <FormattedMessage
                    id={compact ? 'printForm.dateShort' : 'printForm.date'}
                    values={{
                      hour: moment().hour(),
                      min: moment().minute(),
                      day: moment().date(),
                      month: moment().month() + 1,
                      year: moment().year(),
                    }}
                  />
                </Typography>
                <Typography variant="h6">
                  <FormattedMessage id="printForm.doctor" />
                </Typography>
                <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
                  <FormattedMessage id="medicalPrint.helperText" />
                </Typography>
                <Typography variant="body1">{encounter?.picName}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      ),
      [compact, encounter],
    );

    const RenderData = ({ data }) => {
      return (
        <>
          {data?.map((item, index) => (
            <Box key={index}>
              &nbsp;-&nbsp;{item.code}&nbsp;-&nbsp;{item.display}
            </Box>
          ))}
          <Box>
            &nbsp;{encounter?.differentialDiagnosis && '- ' + extractHTMLContent(encounter?.differentialDiagnosis)}
          </Box>
        </>
      );
    };

    return (
      <Box style={{ pageBreakAfter: 'always' }}>
        <Box>
          <HeaderPrintForm>
            <Typography variant="h4" style={{ textTransform: 'uppercase' }}>
              <FormattedMessage id="drugUsage.printLabel" />
            </Typography>
          </HeaderPrintForm>
          <Grid container>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
              <Box display="flex">
                <Typography variant="subtitle1" style={{ minWidth: 150 }}>
                  <FormattedMessage id="patientCode" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">{encounter?.patient?.code?.split('-')[1]}</Typography>
              </Box>
              <Box display="flex">
                <Typography variant="subtitle1" style={{ minWidth: 150 }}>
                  <FormattedMessage id="name" />
                </Typography>
                :&nbsp;
                <Typography variant="body1" style={{ textTransform: 'uppercase' }}>
                  {encounter?.patient?.name}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="subtitle1" style={{ minWidth: 150 }}>
                  <FormattedMessage id="telephone" />
                </Typography>
                :&nbsp; <Typography variant="body1">{encounter?.patient?.mobilePhone}</Typography>
              </Box>
            </Grid>
            <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'column', gap: 0 }}>
              <Box display="flex">
                <Typography variant="subtitle1" style={{ minWidth: 150 }}>
                  <FormattedMessage id="address" />
                </Typography>
                :&nbsp;
                <Typography variant="body1">{encounter?.patient?.homeAddress?.address}</Typography>
              </Box>
              <Box display="flex">
                <Typography variant="subtitle1" style={{ minWidth: 150 }}>
                  <FormattedMessage id="birthdayShort" />
                </Typography>
                :&nbsp;{' '}
                <Typography variant="body1">
                  {moment(encounter?.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
                </Typography>
              </Box>
              <Box display="flex">
                <Typography variant="subtitle1" style={{ minWidth: 150 }}>
                  <FormattedMessage id="gender" />
                </Typography>
                :&nbsp;{' '}
                <Typography variant="body1">
                  {encounter?.patient?.gender && (
                    <FormattedMessage id={GENDER[encounter?.patient?.gender!]?.label || ' '} />
                  )}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box marginY={1}>
          <Divider />
        </Box>
        <Grid container>
          {!compact && (
            <Grid item xs={12} display="flex">
              <Typography variant="subtitle1" style={{ minWidth: 150 }}>
                <FormattedMessage id="printForm.placeOfIndication" />
              </Typography>
              :&nbsp;
              <Typography variant="body1">{encounter?.physicsRoom?.name}</Typography>
            </Grid>
          )}
          <Grid item xs={12} display="flex">
            <Typography variant="subtitle1" style={{ minWidth: 150 }}>
              <FormattedMessage id="encounter.encounterDetail.block.title.diagnosis" />
            </Typography>
            :&nbsp;
            <Box>
              <Typography variant="body1" component="span">
                <RenderData data={encounter?.icdDiagnosisList} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Box>
          <Box style={{ pageBreakInside: 'avoid', marginTop: 16 }}>
            <Typography variant="subtitle1" color="textSecondary">
              <FormattedMessage id="drugUsage.drugIndication" />
            </Typography>
          </Box>
          {medicationUsages?.length ? (
            medicationUsages.map((medicationUsage, index: number) => {
              const medication = medicationUsage?.medication;
              return (
                <Box key={medicationUsage?.id} style={{ marginTop: 4, marginBottom: 12 }}>
                  <Typography variant="subtitle1">
                    {index + 1}.&nbsp;{medication?.name}{' '}
                    <Typography variant="body1" component="span">
                      (
                      {_.join(
                        [medication?.ingredient, medication?.dose].filter((e) => e),
                        ',',
                      )}
                      )
                    </Typography>
                  </Typography>
                  <Box display="flex" flexWrap={'wrap'} gap={4}>
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.quantity" />
                      :&nbsp;{medicationUsage.quantity}
                    </Typography>
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.unit" />
                      :&nbsp;{medication?.numeratorUnit}
                    </Typography>
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.intendedRoute" />
                      :&nbsp;
                      {intendedRoutes.find((route) => route?.value === medicationUsage.intendedRoute)?.label}
                    </Typography>{' '}
                    <Typography variant="body1" component="span">
                      <FormattedMessage id="drugUsage.indication" />
                      :&nbsp;{medicationUsage.indicationRequest?.serviceName}
                    </Typography>
                  </Box>
                  <Typography variant="body1" component="span">
                    <FormattedMessage id="drugUsage.note" />
                    :&nbsp;{medicationUsage.note}
                  </Typography>
                </Box>
              );
            })
          ) : (
            <Typography variant="body1" color="textSecondary">
              <FormattedMessage id="drugUsage.noUsed" />
            </Typography>
          )}
        </Box>
        {footer}
        {cancelMedicationUsage.length > 0 && (
          <Box>
            <Box marginY={1}>
              <Divider />
            </Box>{' '}
            <Box style={{ pageBreakInside: 'avoid', marginTop: 16 }}>
              <Typography variant="subtitle1" color="textSecondary">
                <FormattedMessage id="drugUsage.noteCancelDrug" />
              </Typography>
            </Box>
            {cancelMedicationUsage.map((item, index: number) => {
              const medication = item?.medication;

              return (
                <Box key={item.id} style={{ marginTop: 4, marginBottom: 12 }}>
                  <Typography variant="subtitle1">
                    {index + 1}.&nbsp;{renderLabelSearch(medication?.name, medication?.ingredient, medication?.dose)}
                  </Typography>
                  <Box display="flex">
                    <Typography variant="body1" component="span" style={{ minWidth: 100 }}>
                      <FormattedMessage id="drugUsage.cancelledQuantity" />
                      :&nbsp;{item.cancellationQuantity}
                    </Typography>
                    <Typography variant="body1" component="span" sx={{ marginX: 4 }}>
                      <FormattedMessage id="drugUsage.reasonCancel" />
                      :&nbsp;{item.cancellationReason}
                    </Typography>
                  </Box>
                  {!item.isFree && (
                    <Box display="flex" alignItems={'center '} marginTop={1}>
                      <CheckBoxIcon sx={{ marginRight: 1, color: 'text.secondary' }} />
                      <Typography variant="subtitle1" color="textSecondary">
                        <FormattedMessage id="drugUsage.patientPay" />
                      </Typography>
                    </Box>
                  )}
                </Box>
              );
            })}
            {footer}
          </Box>
        )}
      </Box>
    );
  },
);

const DrugUsagePrintDialog = ({ onClose, open, medicationUsages }: Props) => {
  const refPrintComponent = React.useRef(null);
  const classes = useStyles();
  const [compact, setCompact] = React.useState(localStorage.getItem(COMPACT_TYPE) === 'compact' || false);
  const encounterContext = React.useContext(EncounterContext);

  // When the dialog is open check counter > 0 then call the API get encounter data and reset the counter.
  React.useEffect(() => {
    if (encounterContext?.counter! > 0 && open) {
      encounterContext?.revalidateEncounter();
      encounterContext?.setCounter(0);
    }
  }, [encounterContext, open]);
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      PaperProps={{
        style: { maxWidth: '80vw' },
      }}
      keepMounted={false}
      title={'drugUsage.printLabel'}
      footer={
        <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
          <FormControlLabel
            control={
              <Switch
                checked={compact}
                onChange={(e) => {
                  setCompact(e.target.checked);
                  localStorage.setItem(COMPACT_TYPE, e.target.checked ? 'compact' : 'expansion');
                }}
              />
            }
            label={<FormattedMessage id="printCompact" />}
          />
          <ReactToPrint
            trigger={() => (
              <Button variant="contained" color="primary" startIcon={<Print />} style={{ minWidth: 182 }}>
                <FormattedMessage id="drugUsage.print" />
              </Button>
            )}
            content={() => refPrintComponent.current}
          />{' '}
        </Box>
      }
    >
      <Box ref={refPrintComponent} className={classes.print}>
        <Box sx={{ position: 'relative', display: 'block', minHeight: '100%' }}>
          <table width="100%">
            <tbody>
              <PrescriptionPrintExamDoctorContent
                encounter={encounterContext?.encounter!}
                medicationUsages={medicationUsages}
                compact={compact}
              />
            </tbody>
          </table>
        </Box>
      </Box>
    </DialogCustom>
  );
};

export default DrugUsagePrintDialog;
