import { some } from '../../../../common/constants';
import DialogCustom from '../../../../common/component/DialogCustom';
import { Box, Typography } from '@mui/material';
import SchemaForm from '../../../../common/SchemaForm';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import { NumericFormatText } from '../../../../common/utils';
import { PAYMENT_METHOD } from 'modules/common/apiConstants';

interface Props {
  open: boolean;
  onClose?: () => void;
  onSubmit?: (value: some) => void;
  paymentDetail: some;
}

const PaymentMethodDialog = (props: Props) => {
  const { onClose, open, onSubmit, paymentDetail } = props;

  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title={'therapy.cashier.dialog.title.paymentConform'}
      maxWidth="sm"
      PaperProps={{
        style: { minWidth: 480 },
      }}
    >
      <Box padding={2}>
        <SchemaForm
          onCancel={onClose}
          onSubmit={onSubmit}
          formData={{
            ...paymentDetail,
            paymentMethodChecked: paymentDetail?.paymentMethodChecked ? paymentDetail?.paymentMethodChecked : true,
          }}
          schema={{
            fields: ({ formProps: { intl, appState }, valuesField, methods }) => {
              const { setValue } = methods;
              const patientPay = valuesField?.paymentList
                ?.filter((v) => v.checked)
                .reduce((a, b) => a + b.patientPay, 0);
              const directPay = valuesField?.paymentMethodChecked
                ? patientPay - (valuesField?.prepaymentChecked ? valuesField?.prepayment : 0)
                : 0;
              return {
                labelAmountPaid: {
                  type: () => {
                    return (
                      <>
                        <Typography variant="h6" color="success.main">
                          <FormattedMessage id="therapy.payment.paymentConfirm.label.totalAmount" />
                        </Typography>
                        <Typography variant="h6">{NumericFormatText(patientPay)}</Typography>
                      </>
                    );
                  },
                  unregister: true,
                  propsWrapper: { xs: 12 },
                },
                labelSelectSof: {
                  type: () => {
                    return (
                      <>
                        <Typography variant="body1">
                          <FormattedMessage id="therapy.payment.paymentConfirm.label.sofSelect" />
                        </Typography>
                      </>
                    );
                  },
                  unregister: true,
                  propsWrapper: { xs: 12 },
                },
                requiredSelectSofWarning: {
                  unregister: true,
                  hidden: valuesField?.prepaymentChecked || valuesField?.paymentMethodChecked,
                  type: () => {
                    return (
                      <Typography
                        sx={{
                          color: 'error.main',
                          marginTop: '-10px',
                        }}
                      >
                        {intl.formatMessage({
                          id: 'therapy.cashier.paymentConfirmDialog.message.validateSelectSof.required',
                        })}
                      </Typography>
                    );
                  },
                  propsWrapper: { xs: 12 },
                },
                prepaymentChecked: {
                  type: 'checkbox',
                  label: intl.formatMessage({ id: 'therapy.payment.paymentConfirm.label.sofPrepayment' }),
                  labelPlacement: 'end',
                  disabled: paymentDetail?.prepaymentDetail?.amount <= 0,
                  required: !valuesField?.paymentMethodChecked,
                  register: {
                    validate: (value) => {
                      return value || valuesField?.paymentMethodChecked
                        ? true
                        : intl.formatMessage({
                            id: 'therapy.cashier.paymentConfirmDialog.message.validateSelectSof.required',
                          });
                    },
                  },
                  propsWrapper: {
                    xs: 12,
                    sx: {
                      paddingTop: '5px !important',
                      marginTop: '0',
                      '& label': { marginTop: '0' },
                      '& .MuiFormHelperText-root': { display: 'none' },
                    },
                  },
                  onChange: (event) => {
                    const isChecked = event.target.value;
                    if (isChecked) {
                      setValue('prepayment', Math.min(patientPay, paymentDetail?.prepaymentDetail?.amount));
                    }
                  },
                },
                prepayment: {
                  type: 'number-field',
                  inputType: 'number',
                  placeholder: intl.formatMessage({ id: 'enter' }),
                  required: valuesField?.prepaymentChecked,
                  defaultValue: Math.min(patientPay, paymentDetail?.prepaymentDetail?.amount),
                  disabled: !valuesField?.prepaymentChecked,
                  register: {
                    required: valuesField?.prepaymentChecked,
                    max: {
                      value: Math.min(patientPay, paymentDetail?.prepaymentDetail?.amount),
                      message: intl.formatMessage({ id: 'validation.invalid' }),
                    },
                    validate: (value) => {
                      return !valuesField?.prepaymentChecked || patientPay - (value || 0) - directPay === 0
                        ? true
                        : intl.formatMessage({ id: 'validation.invalid' });
                    },
                  },
                  propsWrapper: { xs: 6, sx: { paddingTop: '5px !important' } },
                },
                empty: {
                  type: () => {
                    return <></>;
                  },
                  unregister: true,
                  propsWrapper: { xs: 6 },
                },
                paymentMethodChecked: {
                  type: 'checkbox',
                  label: intl.formatMessage({ id: 'therapy.payment.paymentConfirm.label.directPayment' }),
                  labelPlacement: 'end',
                  required: !valuesField?.prepaymentChecked,
                  disabled: paymentDetail?.prepaymentDetail?.amount <= 0,
                  register: {
                    validate: (value) => {
                      return value || valuesField?.prepaymentChecked
                        ? true
                        : intl.formatMessage({
                            id: 'therapy.cashier.paymentConfirmDialog.message.validateSelectSof.required',
                          });
                    },
                  },
                  propsWrapper: {
                    xs: 6,
                    sx: {
                      paddingTop: '5px !important',
                      marginTop: '0',
                      '& label': { marginTop: '0' },
                      '& .MuiFormHelperText-root': { display: 'none' },
                    },
                  },
                },
                labelAmountRemain: {
                  type: () => {
                    return (
                      <>
                        <Typography
                          variant="subtitle1"
                          style={{
                            minWidth: 160,
                            color: '#607D8B',
                            fontWeight: 500,
                            fontSize: '14px',
                          }}
                        >
                          <FormattedMessage id="therapy.payment.paymentConfirm.label.directPaymentAmount" />
                        </Typography>
                      </>
                    );
                  },
                  unregister: true,
                  propsWrapper: {
                    xs: 6,
                    sx: {
                      paddingTop: '10px !important',
                      marginTop: '0',
                    },
                  },
                },
                paymentMethod: {
                  type: 'select',
                  defaultValue: PAYMENT_METHOD.CASH.value,
                  placeholder: intl.formatMessage({ id: 'select' }),
                  options: Object.values(PAYMENT_METHOD).filter(
                    (item) => item.value !== PAYMENT_METHOD.PREPAYMENT.value,
                  ),
                  disableClearBtn: true,
                  required: valuesField?.paymentMethodChecked,
                  register: { required: valuesField?.paymentMethodChecked },
                  noHelperText: true,
                  propsWrapper: { xs: 6, sx: { paddingTop: '5px !important' } },
                  disabled: !valuesField?.paymentMethodChecked,
                },
                valueAmountRemain: {
                  type: () => {
                    return (
                      <>
                        <Typography variant="body1" style={{ minWidth: 160 }}>
                          {directPay <= 0 ? 0 : NumericFormatText(directPay)}
                        </Typography>
                      </>
                    );
                  },
                  unregister: true,
                  propsWrapper: {
                    xs: 6,
                    sx: {
                      paddingTop: '5px !important',
                      marginTop: '0',
                    },
                  },
                },
                note: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'note' }),
                  placeholder: intl.formatMessage({ id: 'noteEnter' }),
                  rows: 3,
                  multiline: true,
                },
              };
            },
            changeDataBeforeSubmit: (values) => {
              return { ...values, prepayment: values.prepaymentChecked ? values.prepayment : 0 };
            },
          }}
        />
      </Box>
    </DialogCustom>
  );
};

export default PaymentMethodDialog;
