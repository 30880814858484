import PrintIcon from '@mui/icons-material/Print';
import { Box, Button, Grid } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import SchemaForm from 'modules/common/SchemaForm';
import DrugUsagePrintDialog from './DrugUsagePrintDialog';
import TableList from './TableList';
import { API_SERVER } from '../../../../../common/api';
import { axiosThunk } from '../../../../../common/redux/axios';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { useCallback, useMemo, useState } from 'react';
import { flatMap } from 'lodash';
import { useParams } from 'react-router';
import { setLoading } from '../../../../../common/redux/commonReducer';
import { MEDICATION_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';
import { IndicationRequest } from 'modules/schema';

interface Props {
  patient: some;
  readonly: boolean;
  indicationRequest: IndicationRequest;
}

const IndicationMedicationTab: React.FunctionComponent<Props> = ({ readonly, patient, indicationRequest }) => {
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const { indicationRequestId } = useParams<{ indicationRequestId: string }>();
  const [openPrintDialog, setOpenPrintDialog] = useState(false);
  const { data: medicationCategoryList } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({ scope: MEDICATION_CATEGORY_SCOPE.CLINIC }),
  );

  const { data: indicationMedications, revalidate: indicationMedicationRevalidate } = useFetch(
    API_SERVER.indicationMedication.get(parseInt(indicationRequestId)),
  );

  const mappedMedicationCategory = useMemo(() => {
    return medicationCategoryList?.map((medicationGroup) => ({
      value: medicationGroup.id,
      label: medicationGroup.name,
      medications: medicationGroup?.medicationList?.map((medication) => ({
        value: medication?.id,
        label: `${medication?.name}(${medication.ingredient ? medication.ingredient : ''} ${
          medication.dose ? medication.dose : ''
        })`,
        group: medicationGroup.name,
      })),
    }));
  }, [medicationCategoryList]);

  const onCreateIndicationMedication = useCallback(
    async (medication: some) => {
      dispatch(setLoading(true));
      try {
        await dispatch(
          axiosThunk({
            url: API_SERVER.indicationMedication.create(),
            method: 'post',
            data: {
              indicationRequestId: parseInt(indicationRequestId),
              medicationId: parseInt(medication?.value),
              quantity: 1,
              isFree: false,
            },
          }),
        );
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editSucceeds' }) });
        await indicationMedicationRevalidate();
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editFails' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, indicationMedicationRevalidate, indicationRequestId, intl, openSnackbar],
  );

  const onUpdateIndicationMedication = useCallback(
    async (value) => {
      dispatch(setLoading(true));
      try {
        await dispatch(
          axiosThunk({
            url: API_SERVER.indicationMedication.update(value?.id),
            method: 'put',
            data: value,
          }),
        );
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editSucceeds' }) });
        await indicationMedicationRevalidate();
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'subclinicalResponse.editFails' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, indicationMedicationRevalidate, intl, openSnackbar],
  );

  const onDeleteIndicationMedication = useCallback(
    async (indicationMedication: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: indicationMedication?.medicationName }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(
            axiosThunk({ url: API_SERVER.indicationMedication.delete(indicationMedication?.id), method: 'delete' }),
          );
          await indicationMedicationRevalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, indicationMedicationRevalidate, intl, openSnackbar],
  );

  const isSelectedMedication = useCallback(
    (option) => {
      return !!indicationMedications?.find((ele) => parseInt(option?.value) === ele?.medicationId);
    },
    [indicationMedications],
  );

  return (
    <Box>
      <Box marginBottom={2}>
        <SchemaForm
          hideSubmitButton
          schema={{
            fields: ({ formProps, valuesField }) => {
              const { intl } = formProps;
              const medicationOptions = valuesField?.group
                ? mappedMedicationCategory?.find((ele) => ele?.value === valuesField?.group?.value)?.medications
                : flatMap(mappedMedicationCategory, (ele) => ele.medications);
              return {
                group: {
                  type: 'auto-complete',
                  label: intl.formatMessage({ id: 'drugUsage.groupDrug' }),
                  placeholder: intl.formatMessage({ id: 'drugUsage.groupDrugEnter' }),
                  noHelperText: true,
                  propsWrapper: { xs: 4 },
                  options: mappedMedicationCategory,
                },
                name: {
                  type: 'auto-complete',
                  label: intl.formatMessage({ id: 'encounterPrescription.drugName' }),
                  placeholder: intl.formatMessage({ id: 'encounterPrescription.drugNameEnter' }),
                  noHelperText: true,
                  propsWrapper: { xs: 8 },
                  options: medicationOptions,
                  groupBy: (option) => option.group,
                  onChange: (value) => {
                    if (value) {
                      onCreateIndicationMedication(value);
                    }
                  },
                  getOptionDisabled: isSelectedMedication,
                  disableCloseOnSelect: true,
                },
              };
            },
          }}
        />
      </Box>

      <TableList
        indicationMedications={indicationMedications}
        onUpdateIndicationMedication={onUpdateIndicationMedication}
        onDeleteIndicationMedication={onDeleteIndicationMedication}
      />
      {indicationMedications && indicationMedications.length > 0 && (
        <Box
          sx={{
            paddingBottom: 2,
            paddingTop: 2,
            position: 'sticky',
            bottom: 0,
            bgcolor: 'background.paper',
            zIndex: 2,
            display: 'block',
          }}
        >
          <Grid container spacing={2} justifyContent={'flex-end'} style={{ marginTop: 0 }}>
            <Button
              startIcon={<PrintIcon />}
              variant="contained"
              color="inherit"
              style={{ width: 180 }}
              onClick={() => {
                setOpenPrintDialog(true);
              }}
            >
              <FormattedMessage id="drugUsage.print" />
            </Button>
          </Grid>
        </Box>
      )}
      <DrugUsagePrintDialog
        open={openPrintDialog}
        patient={patient}
        indicationRequest={indicationRequest}
        onClose={() => {
          setOpenPrintDialog(false);
        }}
        indicationMedications={indicationMedications}
      />
    </Box>
  );
};

export default IndicationMedicationTab;
