import { Box, Typography } from '@mui/material';
import TableCustom, { Columns } from '../../../../../common/component/TableCustom';
import { FormattedMessage } from 'react-intl';
import { styled } from '@mui/system';
import { some } from '../../../../../common/constants';
import { useMemo } from 'react';
import { numericFormat } from '../../../../../common/utils';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { PAYMENT_DETAIL_TYPE } from 'modules/common/apiConstants';

interface Props {
  paymentDetail: some;
}

export const THERAPY_PAYMENT_DETAIL_COLUMNS: Columns[] = [
  {
    dataIndex: 'content',
    title: 'therapy.payment.summaryPrintForm.title.paymentContent',
    default: true,
  },
  {
    dataIndex: 'unit',
    title: 'therapy.payment.summaryPrintForm.title.paymentUnit',
    default: true,
  },
  {
    dataIndex: 'quantity',
    title: 'therapy.payment.summaryPrintForm.title.paymentQuantity',
    default: true,
  },
  {
    dataIndex: 'totalAmount',
    title: 'therapy.payment.summaryPrintForm.title.paymentTotalPrice',
    default: true,
  },
  {
    dataIndex: 'discount',
    title: 'therapy.payment.summaryPrintForm.title.paymentDiscount',
    default: true,
  },
  {
    dataIndex: 'vat',
    title: 'therapy.payment.summaryPrintForm.title.paymentVAT',
    default: true,
  },
  {
    dataIndex: 'amountPaid',
    title: 'therapy.payment.summaryPrintForm.title.paymentCustomerPay',
    default: true,
  },
];

const TitleStyled = styled(Typography)({
  color: '#546E7A',
  fontFamily: '16px',
  fontStyle: 'normal',
  fontWeight: '500',
  lineHeight: '24px',
  letterSpacing: '0.15px',
  marginTop: '15px',
  marginBottom: '5px',
  backgroundColor: 'transparent',
});

const TherapyPayment = ({ paymentDetail }: Props) => {
  const { intl } = useGeneralHook();
  const therapyPaymentDetails = useMemo(
    () =>
      paymentDetail?.paymentDetails?.map((payment) => {
        const totalAmount = payment?.unitPrice * payment?.quantity;
        const discount = payment?.discountAmount;
        return {
          ...payment,
          amountPaid: numericFormat(totalAmount - discount + payment.vatAmount),
          totalAmount: numericFormat(totalAmount),
          discount: numericFormat(discount),
          type: intl.formatMessage({ id: PAYMENT_DETAIL_TYPE[payment?.type].label }),
        };
      }),
    [intl, paymentDetail],
  );

  return (
    <Box>
      <TitleStyled>
        <FormattedMessage id="therapy.payment.summaryPrintForm.title.therapyPayment" />
      </TitleStyled>
      <TableCustom
        containerProps={{
          sx: {
            '& th,tr,td': {
              padding: '2px 8px',
              backgroundColor: 'white',
              border: '1px solid #000',
              color: 'unset',
            },
            width: '100%',
            overflow: 'visible',
            marginTop: '5px',
          },
        }}
        dataSource={therapyPaymentDetails}
        columns={THERAPY_PAYMENT_DETAIL_COLUMNS}
      />
    </Box>
  );
};

export default TherapyPayment;
