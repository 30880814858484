import { z } from 'zod';

export const PaginationSchema = z.object({
  totalPages: z.number(),
  totalElements: z.number(),
  pageSize: z.number(),
  page: z.number(),
});

export type Pagination = z.infer<typeof PaginationSchema>;
