import { z } from 'zod';
import { SpecializedDepartmentSchema } from './SpecializedDepartment';
import { PhysicsRoomSchema } from './PhysicsRoom';
import { MedicalEquipmentSchema } from './MedicalEquipment';
import { LaboratoryReferenceSchema } from './LaboratoryReference';
import { SystemCategorySchema } from './SystemCategory';

export const ServiceSchema = z
  .object({
    id: z.number(),
    code: z.string(),
    name: z.string(),
    internationalCode: z.string(),
    price: z.number(),
    vat: z.number(),
    groupCode: z.string(),
    specializedDepartment: SpecializedDepartmentSchema,
    physicsRoom: PhysicsRoomSchema,
    medicalEquipment: MedicalEquipmentSchema,
    laboratoryReferences: z.array(LaboratoryReferenceSchema),
    systemCategory: SystemCategorySchema,
    status: z.string(),
    systemCategoryId: z.number(),
  })
  .partial();

export type Service = z.infer<typeof ServiceSchema>;
