import * as React from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { some } from '../../../../../common/constants';
import SchemaForm from '../../../../../common/SchemaForm';
import { ExamDoctorEncounterInfoForm } from '../../../../model';
import { API_SERVER } from '../../../../../common/api';
import { useFetch } from 'modules/common/hook';

interface Props {
  patientData?: some;
  readOnly?: boolean;
  encounterId: number;
  onRequestTherapy: (item: some) => void;
}

const TherapyEncounterBox: React.FunctionComponent<Props> = ({ onRequestTherapy }) => {
  const intl = useIntl();
  const formMethods = useFormContext<ExamDoctorEncounterInfoForm>();
  const { data: therapyCategoryData = [] } = useFetch(API_SERVER.therapyManagement.getList({ status: 'ACTIVE' }));

  const therapyCategories = React.useMemo(() => {
    if (therapyCategoryData.length === 0) {
      return [];
    }

    const returnValue: some[] = [];
    for (let i = 0; i < therapyCategoryData.length; i++) {
      const name = therapyCategoryData[i].name;
      returnValue.push({
        value: therapyCategoryData[i].id,
        label: `${name}`,
        items: therapyCategoryData[i].items,
      });
    }
    return returnValue;
  }, [therapyCategoryData]);

  return (
    <>
      <SchemaForm
        methods={formMethods}
        notForm
        hideSubmitButton
        schema={{
          fields: ({ valuesField, methods }) => {
            const { setValue } = methods;
            const getTherapyItemOptions = () => {
              const arr: any[] = [];
              let categoryList: any[] = [];
              categoryList = valuesField?.therapyCategory
                ? therapyCategories.filter((v) => v.value === valuesField?.therapyCategory?.value) || []
                : therapyCategories;
              categoryList.forEach((i) => {
                i?.items?.forEach((i1: some) => {
                  const obj = {
                    ...i1,
                    group: i.label,
                  };
                  arr.push(obj);
                });
              });
              return arr;
            };
            return {
              therapyCategory: {
                type: 'auto-complete',
                label: intl.formatMessage({ id: 'encounterInfo.therapyCategory' }),
                placeholder: intl.formatMessage({ id: 'encounterInfo.therapyCategoryEnter' }),
                propsWrapper: { xs: 4.5 },
                options: therapyCategories,
                isOptionEqualToValue: (selected, value) => selected?.value === value?.value,
                getOptionLabel: (option) => option.label || ' ',
                onChange: () => {
                  setValue('therapy', null);
                },
              },
              therapy: {
                type: 'auto-complete',
                label: intl.formatMessage({ id: 'encounterInfo.therapySelect' }),
                placeholder: intl.formatMessage({ id: 'encounterInfo.therapyEnter' }),
                options: getTherapyItemOptions,
                getOptionLabel: (option) => option.name || ' ',
                propsWrapper: { xs: 4.5 },
                groupBy: (option) => option.group,
              },
              register: {
                type: 'button',
                label: intl.formatMessage({ id: 'encounterInfo.therapyRequest' }),
                color: 'primary',
                variant: 'contained',
                style: { minWidth: 120, marginTop: 20 },
                propsWrapper: { xs: 2 },
                onClick: () => {
                  methods.reset();
                  onRequestTherapy(valuesField);
                },
                disabled: !valuesField?.therapy,
              },
            };
          },
          ui: ({ valuesField }) => [{ id: 'select', fields: ['therapyCategory', 'therapy', 'register'] }],
        }}
      />
    </>
  );
};

export default TherapyEncounterBox;
