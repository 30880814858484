import { useFetch } from 'modules/common/hook';
import useFilterContext from 'modules/common/component/filter/useFilterContext';
import { API_SERVER } from 'modules/common/api';
import { FilterProps, MedicationSuppliersFilter } from 'modules/common/component/filter/type';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SelectInput from 'modules/common/component/form/SelectInput';
import ChipList from '../CustomeChip';

const MedicationSuppliers = ({ filter: { placeholder, type } }: FilterProps<MedicationSuppliersFilter>) => {
  const { form } = useFilterContext();
  const { data } = useFetch(API_SERVER.supplier.get(), {
    revalidateOnFocus: false,
  });
  const selectedMedicationSupplies = form.watch(type);
  return (
    <Stack direction="column" gap="10px" flex={1}>
      <SelectInput
        form={form}
        name={type}
        options={data?.content}
        getValue="id"
        renderLabel="name"
        rawOptionLabel
        placeholder={placeholder}
        multiple
        hideError
        renderValue={() => (
          <Typography
            sx={{
              color: '#AEB3B5',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              letterSpacing: '0.15px',
              lineHeight: 'unset',
            }}
          >
            <FormattedMessage id={placeholder} />
          </Typography>
        )}
      />
      {selectedMedicationSupplies && selectedMedicationSupplies.length > 0 && (
        <ChipList
          items={selectedMedicationSupplies}
          labelKey="name"
          valueKey="id"
          onDelete={(data) =>
            form.setValue(
              type,
              selectedMedicationSupplies.filter((ele) => ele.id !== data),
            )
          }
        />
      )}
    </Stack>
  );
};

export default MedicationSuppliers;
