import { ROLES } from 'modules/common/constants';

export const VIEW_PATIENT = [
  ROLES.ADMIN.value,
  ROLES.DOCTOR.value,
  ROLES.GENERAL_DOCTOR.value,
  ROLES.RECEPTIONIST.value,
  ROLES.COORDINATOR.value,
];
export const VIEW_ENCOUNTER = [ROLES.DOCTOR.value, ROLES.GENERAL_DOCTOR.value];
