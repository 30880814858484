import { Box, Grid } from '@mui/material';
import SessionTitle from './SessionTitle';
import TextValue from 'modules/examDoctor/component/therapy/TherapyIndication/IndicationRequestPrintForm/TextValue';
import { useDentalDetailStateSelector } from '../state';
import moment from 'moment';
import { BE_DATE_FORMAT, FE_DATE_FORMAT, PATIENT_ADDRESS_TYPE } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

const PatientInfo = () => {
  const { intl } = useGeneralHook();
  const dentalExam = useDentalDetailStateSelector.use.dentalExamInfo();
  const { patient } = dentalExam || {};
  const dob = patient
    ? `${moment(patient.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)} - ${intl.formatMessage({ id: 'age' })} ${
        moment().year() - moment(patient.dob, BE_DATE_FORMAT).year()
      }`
    : '';
  const address = patient
    ? patient.patientAddressList?.find((address) => address?.type === PATIENT_ADDRESS_TYPE.home)?.address
    : '';

  return (
    <Box>
      <SessionTitle titleKey="dental.dentalDetail.printForm.patientInfoSession.title" />
      <Grid container>
        <Grid item xs={7}>
          <TextValue
            label="dental.dentalDetail.printForm.patientName.title"
            value={patient?.name || ''}
            labelWidth="200px"
            valueProps={{ sx: { textTransform: 'uppercase' } }}
          />
          <TextValue label="dental.dentalDetail.printForm.birthday.title" value={dob} labelWidth="200px" />
          <TextValue
            label="dental.dentalDetail.printForm.gender.title"
            value={patient?.gender || ''}
            localizationValue
            labelWidth="200px"
          />
          <TextValue
            label="dental.dentalDetail.printForm.phoneNumber.title"
            value={patient?.mobilePhone || ''}
            labelWidth="200px"
          />
        </Grid>
        <Grid item xs={5}>
          <TextValue
            label="dental.dentalDetail.printForm.nation.title"
            value={patient?.nationality || ''}
            labelWidth="150px"
          />
          <TextValue label="dental.dentalDetail.printForm.address.title" value={address || ''} labelWidth="150px" />
          <TextValue
            label="dental.dentalDetail.printForm.cmt.title"
            value={patient?.identifierCode || ''}
            localizationValue
            labelWidth="150px"
          />
          <TextValue
            label="dental.dentalDetail.printForm.target.title"
            value={`common.label.${dentalExam?.paymentPlan?.toLocaleLowerCase()}` || ''}
            localizationValue
            labelWidth="150px"
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default PatientInfo;
