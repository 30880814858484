import { Box, Button, Stack } from '@mui/material';
import { DEFAULT_VALUES, FILTER_CONFIGS, FILTER_KEY } from './const';
import { FormSchema, SearchParams } from './type';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { useFetch } from 'modules/common/hook';
import { groupBy, map } from 'lodash';
import { FormattedMessage, useIntl } from 'react-intl';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, MONTH_YEAR_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import { formatEndDate, formatStartDate, getDateRangeList, isValidDate, maxDate } from 'modules/common/utils';
import { TIME_REPORT_FILTER } from 'modules/common/component/report/consts';
import Report from 'modules/common/component/report';
import Filter from 'modules/common/component/filter';
import DateInput from 'modules/common/component/form/DateInput';
import { INDICATION_REQUEST_TYPE } from 'modules/common/apiConstants';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { SPECIALIST } from 'modules/layout/constants';
import SelectInput from '../../../../component/form/SelectInput';
import { Option } from 'modules/common/type';
import ReactToPrint from 'react-to-print';
import { Print } from '@mui/icons-material';
import { WORK_API } from 'modules/common/service';

interface Props {
  typeOption: Option[];
  scopesOption: Option[];
}

const IndicationTimeChart: React.FunctionComponent<Props> = ({ typeOption, scopesOption }) => {
  const refPrintComponent = useRef(null);
  const [searchParams, setSearchParams] = useState<SearchParams>(DEFAULT_VALUES);
  const form = useForm<FormSchema & { timeReportType: keyof typeof TIME_REPORT_FILTER }>({
    defaultValues: {
      fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
      toDate: formatEndDate(moment().add(+1, 'day')),
      timeReportType: TIME_REPORT_FILTER.DAY.value,
      type: [],
      scopes: [],
    },
    mode: 'onChange',
  });
  const [seriesData, setSeriesData] = useState<number[]>([]);
  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate = formatEndDate(moment(toDateOrigin).add(-1, 'day'));
  const formData = form.getValues();
  const timeReportType = form.watch('timeReportType');
  const scopes = form.watch('scopes');
  const type = form.watch('type');
  const isDentalClinic = useSelector(
    (state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL,
  );
  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      fromDate: fromDate,
      toDate: toDate,
      type: type
        ? map(type, 'value')
        : isDentalClinic
        ? [INDICATION_REQUEST_TYPE.DENTAL_EXAM.value, INDICATION_REQUEST_TYPE.THERAPY_SESSION.value]
        : [INDICATION_REQUEST_TYPE.ENCOUNTER.value, INDICATION_REQUEST_TYPE.THERAPY_SESSION.value],
      scopes: scopes ? map(scopes, 'value') : [],
      page: 0,
      pageSize: 100000,
    };
  }, [searchParams, fromDate, toDate, type, isDentalClinic, scopes]);

  const intl = useIntl();

  const { data: reportData, isValidating } = useFetch(WORK_API.getIndicationList(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'CHART'),
  });

  const dateRangeList = useMemo(
    () =>
      getDateRangeList(
        moment(form.watch('fromDate'), BE_DATE_TIME_FORMAT),
        moment(form.watch('toDate'), BE_DATE_TIME_FORMAT),
        timeReportType === 'DAY' ? 'day' : 'month',
      ).map((date) => date.format(timeReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT)),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fromDate, toDate, timeReportType],
  );

  useEffect(() => {
    const timeFormat = timeReportType === 'DAY' ? FE_DATE_FORMAT : MONTH_YEAR_FORMAT;
    if (!isValidating && reportData) {
      const reportMap = groupBy(reportData, (item) => item.createdTime?.format(timeFormat));
      setSeriesData(dateRangeList.map((date) => reportMap[date]?.length || 0));
    }
  }, [dateRangeList, isValidating, reportData, timeReportType]);

  useEffect(() => {
    if (timeReportType === 'DAY' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'month')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'month')
            .add(+1, 'day'),
        ),
      });
    }
    if (timeReportType === 'MONTH' && moment(fromDateOrigin) < moment(toDateOrigin).add(-1, 'year')) {
      form.reset({
        ...formData,
        toDate: formatStartDate(
          moment(fromDateOrigin)
            .add(+1, 'year')
            .add(+1, 'day'),
        ),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromDateOrigin, timeReportType, toDateOrigin]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title title="report.workReport.chart.title.indication.timeChart" />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue, filterConfig) => {
          setSearchParams((prev) => ({
            ...prev,
            isFrees: filterConfig.PAYMENT_PLAN.map((ele) => (ele.value === PAYMENT_PLAN.FREE.value ? true : false)),
            executeUserIds: filterValue.EMPLOYEE,
            serviceIds: filterValue.SERVICE,
            status: filterValue.INDICATION_REQUEST_STATUS,
          }));
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                key="type"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                multiple
                showCheckBox
              />
              <SelectInput
                form={form}
                name="scopes"
                key="scopes"
                options={scopesOption}
                getValue="value"
                renderLabel="label"
                label="indication.plan"
                placeholder="indication.planEnter"
                multiple
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                format={FE_DATE_FORMAT}
                required
                maxDate={moment() && moment(toDate)}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                maxDate={maxDate(timeReportType, fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                format={FE_DATE_FORMAT}
                required
              />
              <Box marginTop={'-17px'}>
                <Report.DayMonthRadio
                  form={form}
                  name="timeReportType"
                  onChange={(value) => {
                    if (value === 'DAY') {
                      form.reset({
                        fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment().add(+1, 'day')),
                        timeReportType: TIME_REPORT_FILTER.DAY.value,
                      });
                    }
                    if (value === 'MONTH') {
                      form.reset({
                        fromDate: moment().add(-1, 'year').format(BE_DATE_TIME_FORMAT),
                        toDate: formatEndDate(moment()),
                        timeReportType: TIME_REPORT_FILTER.MONTH.value,
                      });
                    }
                  }}
                />
              </Box>
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() =>
                    form.reset({
                      ...DEFAULT_VALUES,
                      toDate: formatEndDate(moment().add(+1, 'day')),
                    })
                  }
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <ReactToPrint
              trigger={() => (
                <Button variant="contained" color="primary" startIcon={<Print />}>
                  <FormattedMessage id={'printReport'} />
                </Button>
              )}
              content={() => refPrintComponent.current}
            />
          </Stack>
        </Stack>
        <Report.ColumnChart
          refPrintComponent={refPrintComponent}
          xAxisTitle="common.time"
          yAxisTitle="report.chart.title.numberOfIndication"
          categories={dateRangeList}
          series={[
            {
              type: 'column',
              name: intl.formatMessage({ id: 'serviceName' }),
              data: seriesData,
              color: '#003CA6',
              borderRadius: 5,
              showInLegend: false,
            },
          ]}
        />
      </Filter>
    </Stack>
  );
};

export default IndicationTimeChart;
