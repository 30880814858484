import { Box, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from 'modules/common/constants';
import { API_SERVER } from '../../../../common/api';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import SchemaForm from '../../../../common/SchemaForm';
import { therapyMedicationFormSchema } from './TherapyMedicationBox';
import { fetchThunk } from '../../../../common/redux/thunk';
import { setLoading } from '../../../../common/redux/commonReducer';
import { MEDICATION_CATEGORY_SCOPE, MEDICATION_USAGE_TYPE } from 'modules/common/apiConstants';
import { useFetch } from 'modules/common/hook';

interface Props {
  therapySessionId: number;
  formData?: some;
  indicationOptions?: some[];
  onSelectedMedication: (medication: some) => void;
  onUpdateMedication: (medication: some) => void;
  onDeleteMedication: (medication: some) => void;
  editable: boolean;
}

const TherapyMedication: React.FunctionComponent<Props> = (props) => {
  const { dispatch, intl, openSnackbar, appState } = useGeneralHook();
  const {
    therapySessionId,
    formData,
    indicationOptions,
    onSelectedMedication,
    onUpdateMedication,
    onDeleteMedication,
    editable,
  } = props;

  const { data: medicationCategoryList } = useFetch(
    API_SERVER.medicationCategory.getAllFetchCategory({ scope: MEDICATION_CATEGORY_SCOPE.CLINIC }),
  );

  const mappedDataMedicationList = React.useMemo(() => {
    let temp: any[] = [];
    formData?.medicationList?.forEach((i) => {
      const obj = {
        ...i,
        intendedRoute: {
          value: i.intendedRoute,
          label: appState.common.intendedRoutes.find((v) => v.value === i.intendedRoute)?.label,
        },
      };
      temp.push(obj);
    });
    temp.sort((a, b) => a.id - b.id);
    return { medicationList: temp };
  }, [appState.common.intendedRoutes, formData?.medicationList]);

  const onSelectMedication = React.useCallback(
    async (medication: some) => {
      if (medication) {
        const medicationAdd = {
          medicationId: medication.id,
          status: 'REQUESTED',
          isFree: true,
          quantity: 1,
          referenceId: therapySessionId,
          type: MEDICATION_USAGE_TYPE.THERAPY_SESSION,
        };
        try {
          dispatch(setLoading(true));
          const res = await dispatch(
            fetchThunk(API_SERVER.medicationUsage.create(), 'post', medicationAdd, 'application/json-patch+json'),
          );
          onSelectedMedication(res);
          openSnackbar({ message: intl.formatMessage({ id: 'createSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'createFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
    },
    [dispatch, intl, onSelectedMedication, openSnackbar, therapySessionId],
  );

  const onDelete = React.useCallback(
    async (medication: some) => {
      try {
        dispatch(setLoading(true));
        await dispatch(fetchThunk(API_SERVER.medicationUsage.index(medication?.id), 'delete'));
        onDeleteMedication(medication);
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, intl, onDeleteMedication, openSnackbar],
  );

  const onChangeMedicationInfo = React.useCallback(
    async (medication: some) => {
      try {
        const medicationData: some = {
          ...medication,
          intendedRoute: medication?.intendedRoute?.value,
          indicationRequestId: medication?.indicationRequest?.id,
        };

        const res = await dispatch(
          fetchThunk(
            API_SERVER.medicationUsage.index(medicationData?.id),
            'put',
            medicationData,
            'application/json-patch+json',
          ),
        );
        const indentedRoute = {
          value: res.intendedRoute,
          label: appState.common.intendedRoutes.find((v) => v.value === res.intendedRoute)?.label,
        };
        onUpdateMedication({ ...res, indentedRoute: indentedRoute });
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        // dispatch(setLoading(false));
      }
    },
    [appState.common.intendedRoutes, dispatch, intl, onUpdateMedication, openSnackbar],
  );

  const onChangeIsFree = React.useCallback(
    async (medication: some) => {
      try {
        dispatch(setLoading(true));
        medication = {
          ...medication,
          intendedRoute: medication?.intendedRoute?.value,
          indicationRequestId: medication?.indicationRequest?.id,
        };
        const res = await dispatch(
          fetchThunk(
            API_SERVER.medicationUsage.index(medication?.id),
            'put',
            medication,
            'application/json-patch+json',
          ),
        );
        const indentedRoute = {
          value: res.intendedRoute,
          label: appState.common.intendedRoutes.find((v) => v.value === res.intendedRoute)?.label,
        };
        onUpdateMedication({ ...res, indentedRoute: indentedRoute });

        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [appState.common.intendedRoutes, dispatch, intl, onUpdateMedication, openSnackbar],
  );

  return (
    <>
      <Box bgcolor="white" className="mb10">
        <Typography className="title">
          <FormattedMessage id="therapy.detail.label.drugUsage" />
        </Typography>
        <Box padding={1} className="drug-use">
          <SchemaForm
            schema={therapyMedicationFormSchema}
            formData={mappedDataMedicationList}
            medicationCategoryList={medicationCategoryList}
            indicationOptions={indicationOptions}
            onSelectMedication={onSelectMedication}
            onDelete={onDelete}
            onChangeFee={onChangeIsFree}
            onChangeMedicationInfo={onChangeMedicationInfo}
            formId="exam-price"
            formProps={{
              autoComplete: 'nope',
              autoCorrect: 'off',
            }}
            readOnly={!editable}
            noHelperText={true}
          />
        </Box>
      </Box>
    </>
  );
};

export default TherapyMedication;
