import * as React from 'react';
import usePaginationHook from 'modules/common/hook/usePaginationHook';
import { Box, Paper, Typography } from '@mui/material';
import RegisterTherapyDialog from '../component/therapy/RegisterTherapyDialog';
import { API_SERVER } from 'modules/common/api';
import { formatDateFilterField } from 'modules/common/utils';
import { axiosThunk } from 'modules/common/redux/axios';
import { useCallback, useState } from 'react';
import { some } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { FormattedMessage } from 'react-intl';
import TherapyList from '../component/therapy/TherapyList';
import { setLoading } from 'modules/common/redux/commonReducer';
import FilterTherapyBox from '../component/therapy/FilterTherapyBox';
import UpdateTherapyDialog from '../component/therapy/UpdateTherapyDialog';
import { AxiosError } from 'axios';
import DialogCustom from 'modules/common/component/DialogCustom';
import SchemaForm from 'modules/common/SchemaForm';
import { AppState } from '../../../redux/reducer';
import { useSelector } from 'react-redux';
import { useFetch } from 'modules/common/hook';

interface Props {}

const TherapyListPage: React.FunctionComponent<Props> = () => {
  const [openRegisterForm, setOpenRegisterForm] = React.useState(false);
  const orderTherapy = useSelector((appState: AppState) => appState.common.orderTherapy);
  const hookPagination = usePaginationHook({
    defaultFilter: {
      orderBy: orderTherapy,
    },
  });
  const { dispatch, openSnackbar, intl, confirmDialog } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const { params } = hookPagination;
  const [selectedSessionTherapy, setSelectedSessionTherapy] = useState(null);
  const [open, setOpen] = React.useState(false);
  const [openReason, setOpenReason] = React.useState(false);
  const [rowValue, setRowValue] = React.useState<some>();

  const { data, revalidate, isValidating } = useFetch(
    API_SERVER.therapy.getListCashier(formatDateFilterField(params, 'fromDate', 'toDate')),
    { globalLoading: true },
  );

  const onDelete = useCallback(async (value: some) => {
    setRowValue(value);
    setOpen(true);
  }, []);

  const onShowReason = useCallback(async (value: some) => {
    setRowValue(value);
    setOpenReason(true);
  }, []);

  // handle delete therapy session
  const onDeleteSession = React.useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'therapy.therapyDetail.deleteAction.confirmMessage.therapySession' }),
        cancelId: 'no',
        okId: 'yes',
      });
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.therapySession.detail(value?.id).url,
            method: 'delete',
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
            revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({ message: v.message, type: 'error' });
              });
          });
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  // handle submit reason cancel therapy
  const onSubmit = React.useCallback(
    async (value: some) => {
      await dispatch(
        axiosThunk({
          url: API_SERVER.therapy.cancel(rowValue?.id),
          method: 'put',
          data: {
            data: value.reason || undefined,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: 'encounterInfo.cancelSuccess' }) });
          revalidate();
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
        });
      setOpen(false);
      revalidate();
    },
    [dispatch, intl, openSnackbar, revalidate, rowValue?.id],
  );

  const onUpdateTherapySession = async (data) => {
    dispatch(setLoading(true));
    try {
      await dispatch(axiosThunk({ url: API_SERVER.therapy.updateTherapySession(data.id), method: 'put', data }));
      openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.success' }) });
      setSelectedSessionTherapy(null);
      revalidate();
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'therapyList.snackbar.submit.failed' }), type: 'error' });
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <>
      <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
        <FilterTherapyBox
          hookPagination={hookPagination}
          onRegister={() => setOpenRegisterForm(true)}
          pagin={
            <>
              <Typography variant="h6">
                <FormattedMessage id="navMenu.therapyList" />
              </Typography>
              <Typography variant="caption">
                <FormattedMessage
                  id="foundResult"
                  values={{
                    num: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.content?.length || 0}
                      </Typography>
                    ),
                    total: (
                      <Typography variant="inherit" component="span" color="primary">
                        {data?.pagination?.totalElements || 0}
                      </Typography>
                    ),
                  }}
                />
              </Typography>
            </>
          }
          onDataLoading={isValidating}
        />
        <TherapyList
          data={data}
          onUpdate={(sessionTherapy) => setSelectedSessionTherapy(sessionTherapy)}
          onDelete={onDelete}
          onShowReason={onShowReason}
          onDeleteSession={onDeleteSession}
          hookPagination={hookPagination}
        />
      </Paper>
      <RegisterTherapyDialog
        open={openRegisterForm}
        encounterPatientId={{
          mode: 'create-new',
        }}
        close={() => {
          setOpenRegisterForm(false);
        }}
        refresh={revalidate}
      />
      <UpdateTherapyDialog
        open={!!selectedSessionTherapy}
        onClose={() => setSelectedSessionTherapy(null)}
        onSubmit={onUpdateTherapySession}
        data={selectedSessionTherapy}
      />

      <DialogCustom
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { minWidth: 480 },
        }}
        title={'encounterInfo.cancelTherapy'}
      >
        <Box padding={2}>
          <SchemaForm
            onSubmit={onSubmit}
            hideSubmitButton
            initialData={{
              status: 'complete',
            }}
            schema={{
              fields: ({ formProps: { intl }, valuesField }) => {
                return {
                  reason: {
                    type: 'text-field',
                    label: intl.formatMessage({ id: 'encounterDetail.inCompleteReason' }),
                    multiline: true,
                    rows: 3,
                    noHelperText: true,
                    register: {
                      required: true,
                    },
                  },
                  cancel: {
                    type: 'button',
                    label: intl.formatMessage({ id: 'cancel' }),
                    variant: 'outlined',
                    style: { width: 100 },
                    propsWrapper: { xs: undefined },
                    onClick: () => {
                      setOpen(false);
                    },
                  },
                  save: {
                    type: 'submitButton',
                    label: intl.formatMessage({ id: 'save' }),
                    style: { width: 100 },
                    propsWrapper: { xs: undefined },
                  },
                };
              },
              ui: () => {
                return [
                  {
                    id: 'default',
                    fields: ['reason'],
                  },
                  {
                    id: 'footer',
                    propsGridContainer: { justifyContent: 'flex-end' },
                    fields: ['cancel', 'save'],
                  },
                ];
              },
            }}
          />
        </Box>
      </DialogCustom>
      <DialogCustom
        open={openReason}
        onClose={() => setOpenReason(false)}
        PaperProps={{
          style: { minWidth: 480 },
        }}
        title={'cancelReason'}
      >
        <Box padding={2}>{rowValue?.note}</Box>
      </DialogCustom>
    </>
  );
};

export default TherapyListPage;
