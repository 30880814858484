import { Grid, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import * as React from 'react';
import Stack from '@mui/material/Stack';
import { Encounter } from 'modules/schema';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { useSpecializedDepartmentPIC } from 'modules/common/hook';
import { LAB_DEPARTMENT_CODE } from 'modules/common/apiConstants';

interface Props {
  encounter: Encounter;
}

const DoctorSign = ({ encounter }: Props) => {
  const { appState } = useGeneralHook();
  const picLab = useSpecializedDepartmentPIC(LAB_DEPARTMENT_CODE);
  return (
    <Stack direction="row" justifyContent="space-between" marginTop={2} marginBottom={5}>
      <Grid item xs={6} textAlign="center">
        <Typography variant="body1" fontStyle="italic">
          <FormattedMessage
            id="printForm.date"
            values={{
              hour: new Date().getHours(),
              min: new Date().getMinutes(),
              day: new Date().getDate(),
              month: new Date().getMonth() + 1,
              year: new Date().getFullYear(),
            }}
          />
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="printForm.labDoctor" />
        </Typography>
        <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
          <FormattedMessage id="medicalPrint.helperText" />
        </Typography>
        <Typography variant="body1">{picLab?.employeeFullName}</Typography>
      </Grid>
      <Grid item xs={6} textAlign="center">
        <Typography variant="body1" fontStyle="italic">
          <FormattedMessage
            id="printForm.date"
            values={{
              hour: new Date().getHours(),
              min: new Date().getMinutes(),
              day: new Date().getDate(),
              month: new Date().getMonth() + 1,
              year: new Date().getFullYear(),
            }}
          />
        </Typography>
        <Typography variant="h6">
          <FormattedMessage id="printForm.labDoctor2" />
        </Typography>
        <Typography variant="body1" color="textSecondary" fontStyle="italic" style={{ marginBottom: 64 }}>
          <FormattedMessage id="medicalPrint.helperText" />
        </Typography>
        <Typography variant="body1">{appState.authen?.user?.employee_name}</Typography>
      </Grid>
    </Stack>
  );
};

export default DoctorSign;
