import { z } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { MEDICATION_UNIT } from 'modules/common/apiConstants';

export const UnitConverterSchema = z
  .object({
    id: z.number(),
    fromUnit: z.string(),
    factor: z.number(),
    price: z.number(),
    basicUnit: createObjectKeysEnumSchema(MEDICATION_UNIT),
  })
  .partial();

export type UnitConverter = z.infer<typeof UnitConverterSchema>;
