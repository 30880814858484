import { Column } from '../table/type';
import IconButton from '../IconButton';
import ListAltIcon from '@mui/icons-material/ListAlt';
import { useDialog } from 'modules/common/hook';
import SelectColumnDialog from '../SelectColumnDialog';

interface Props<T> {
  columns: Column<T>[];
  onSave: (selectedColumns: Column<T>[]) => void;
  localStorageKey: string;
}

export function SelectColumnButton<T>({ columns, onSave, localStorageKey }: Props<T>) {
  const [openDialog, onOpenDialog, onCloseDialog] = useDialog();

  return (
    <>
      <IconButton icon={<ListAltIcon sx={{ color: '#307BFF' }} />} onClick={onOpenDialog} />
      <SelectColumnDialog
        columns={columns}
        onClose={onCloseDialog}
        open={openDialog}
        onSave={onSave}
        localStorageKey={localStorageKey}
      />
    </>
  );
}
