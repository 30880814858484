import { z } from 'zod';

export const MedicationKnowledgeSchema = z
  .object({
    id: z.number(),
    packaging: z.string(),
    productType: z.string(),
    intendedRoute: z.string(),
    expiration: z.string(),
    doseForm: z.string(),
  })
  .partial();

export type MedicationKnowledge = z.infer<typeof MedicationKnowledgeSchema>;
