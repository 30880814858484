import { API_SERVER } from 'modules/common/api';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { useFetch } from 'modules/common/hook';
import { Prescription, PrescriptionDetail } from 'modules/schema';

const COLUMNS: Column<PrescriptionDetail>[] = [
  {
    title: 'patient.patientDetail.prescriptionHistory.title.medicationName',
    render: (record) => record.medication?.name,
  },
  {
    title: 'patient.patientDetail.prescriptionHistory.title.ingredient',
    render: 'intendedRoute',
  },
  {
    title: 'patient.patientDetail.prescriptionHistory.title.dosage',
    render: (record) => record.medication?.dose,
  },
  {
    title: 'patient.patientDetail.prescriptionHistory.title.amount',
    render: 'quantity',
  },
  {
    title: 'patient.patientDetail.prescriptionHistory.title.unit',
    render: (record) => record.medication?.numeratorUnit,
  },
];

interface Props {
  prescription: Prescription;
}

export const PrescriptionDetailTable = ({ prescription }: Props) => {
  const { data: prescriptionDetails } = useFetch(
    API_SERVER.prescriptionDetail.getPrescriptionDetails(prescription.id!),
  );
  return <Table columns={COLUMNS} data={prescriptionDetails} dataKey={'id'} subTableStyle />;
};
