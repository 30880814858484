import { BE_DATE_TIME_FORMAT } from 'modules/common/constants';
import { z } from 'zod';
import { createDateTimeSchema } from './common';

export const StorageResourceSchema = z
  .object({
    id: z.number(),
    type: z.string(),
    fileName: z.string(),
    uri: z.string(),
    size: z.number(),
    description: z.string(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
  })
  .partial();

export type StorageResource = z.infer<typeof StorageResourceSchema>;
