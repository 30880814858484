import { Box, InputAdornment, Tooltip } from '@mui/material';
import * as React from 'react';
import { IDENTITY_API_SERVER } from '../../../common/api';
import { ROLES, some } from '../../../common/constants';
import useGeneralHook from '../../../common/hook/useGeneralHook';
import { setLoading } from '../../../common/redux/commonReducer';
import { fetchThunk } from '../../../common/redux/thunk';
import { EMAIL_REGEX } from '../../../common/regex';
import SchemaForm from '../../../common/SchemaForm';
import { MEDI_LICENSE_TYPE } from '../../constants';
import { PAYMENT_METHOD } from './constants';
import { axiosThunk } from '../../../common/redux/axios';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { useFetch, useUserRoles } from 'modules/common/hook';

interface Props {}

const ClinicsInfo: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, intl, appState } = useGeneralHook();
  const isAdmin = appState.authen?.user?.role?.some((role) => role === 'ADMIN');
  const [urlValue, setUrlValue] = React.useState('');
  const doctors = useUserRoles(
    ROLES.DOCTOR.value,
    ROLES.GENERAL_DOCTOR.value,
    ROLES.RADIOLOGIST.value,
    ROLES.CLINICAL_PATHOLOGIST.value,
  );
  const doctorOptions = React.useMemo(
    () =>
      doctors?.map((doctor) => ({
        value: doctor.id,
        label: `${doctor.employeeFullName} - ${doctor.email || ''} - ${doctor.roleName}`,
      })),
    [doctors],
  );

  const { data, isValidating } = useFetch(IDENTITY_API_SERVER.group.getGroup());
  const formData = React.useMemo(() => {
    if (!data) {
      return null;
    }

    /* Enhanced from: https://codepen.io/trongthanh/pen/rmYBdX */
    let str = data.name;

    // Chuyển hết sang chữ thường
    str = str?.toLowerCase();

    // xóa dấu
    str = str
      ?.normalize('NFD') // chuyển chuỗi sang unicode tổ hợp
      .replace(/[\u0300-\u036f]/g, ''); // xóa các ký tự dấu sau khi tách tổ hợp

    // Thay ký tự đĐ
    str = str?.replace(/[đĐ]/g, 'd');

    // Xóa ký tự đặc biệt
    str = str?.replace(/([^0-9a-z-\s])/g, '');

    // Xóa khoảng trắng thay bằng ký tự -
    str = str?.replace(/(\s+)/g, '-');

    // Xóa ký tự - liên tiếp
    str = str?.replace(/-+/g, '-');

    // xóa phần dư - ở đầu & cuối
    str = str?.replace(/^-+|-+$/g, '');

    const currentHost = `${window.location.protocol}//${window.location.hostname}`;
    const url = `${currentHost}/main/appointment-register/${data.id}/${str}`;
    localStorage.setItem('qrcode', url);
    setUrlValue(url);

    return {
      name: data?.name,
      organizationName: data?.organizationName,
      organizationId: data?.organizationId,
      phoneNumber: data?.phoneNumber,
      email: data?.email,
      address: data?.address,
      groupPayment: PAYMENT_METHOD.find((m) => m.value === data?.groupPayment),
      version: data?.version,
      pic: data?.pic || null,
      avatarId: data?.avatarId,
      insuranceCode: data?.insuranceCode,
      licenseNumber: data?.licenseNumber,
      website: data?.website,
      fanpage: data?.fanpage,
      currency: { value: data?.currency ? data?.currency : appState.common.groupCurrency },
      url,
    };
  }, [appState.common.groupCurrency, data]);

  const onSubmit = React.useCallback(
    async (value: some) => {
      try {
        dispatch(setLoading(true));
        value.groupPayment = value.groupPayment?.value;
        await dispatch(
          fetchThunk(IDENTITY_API_SERVER.group.update, 'put', { ...value, currency: value.currency.value }),
        );

        const updateOrganizationName = {
          name: value.organizationName,
        };
        await dispatch(
          axiosThunk({
            url: IDENTITY_API_SERVER.group.updateOrganizationName(formData?.organizationId),
            method: 'put',
            data: updateOrganizationName,
          }),
        );

        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e: any) {
        if (e?.errors) {
          e?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, formData, intl, openSnackbar],
  );

  // const doctorOptions = React.useMemo(() => {
  //   if (!combinedData) {
  //     return [];
  //   }
  //   const [, doctorsRes] = combinedData;

  //   return doctorsRes.map((entry) => {
  //     const email = entry.email;
  //     const name = entry.employeeFullName;
  //     const role = USER_ROLE_LIST.find((r) => r.value === entry.roleName)?.label;
  //     return { label: `${name} - ${email} - ${intl.formatMessage({ id: role })}`, value: entry.id };
  //   });
  // }, [combinedData, intl]);

  // handle copy url
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(urlValue);
      openSnackbar({ message: intl.formatMessage({ id: 'clinics.info.message.copySuccess' }) });
    } catch (err) {
      console.error('Unable to copy to clipboard.', err);
      alert('Copy to clipboard failed.');
    }
  };

  React.useEffect(() => {
    dispatch(setLoading(isValidating));
  }, [dispatch, isValidating]);

  React.useEffect(() => {
    return () => {
      localStorage.removeItem('qrcode');
      localStorage.removeItem('avatarId');
    };
  }, []);

  return (
    <Box width={'80%'}>
      <SchemaForm
        hideSubmitButton
        onSubmit={onSubmit}
        formData={formData}
        propsWrapper={{ spacing: 4 }}
        schema={{
          fields: ({ formProps: { intl }, valuesField }) => {
            return {
              organizationName: {
                type: 'text-field',
                readOnly: !isAdmin,
                label: intl.formatMessage({ id: 'clinics.mainCompanyName' }),
                placeholder: intl.formatMessage({ id: 'clinics.mainCompanyNameEnter' }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              name: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.name' }),
                placeholder: intl.formatMessage({ id: 'clinics.nameEnter' }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              address: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.address' }),
                placeholder: intl.formatMessage({ id: 'clinics.addressEnter' }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              licenseNumber: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.licenseNumber' }),
                placeholder: intl.formatMessage({ id: 'clinics.licenseNumberEnter' }),
                register: {},
                propsWrapper: { xs: 6 },
              },
              phoneNumber: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.hotline' }),
                placeholder: intl.formatMessage({ id: 'clinics.hotlineEnter' }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              pic: {
                type: 'select',
                label: intl.formatMessage({ id: 'clinics.doctor' }),
                placeholder: intl.formatMessage({ id: 'clinics.doctorEnter' }),
                register: {
                  required: true,
                },
                options: doctorOptions,
                rawOptions: true,
                propsWrapper: { xs: 6 },
              },
              groupPayment: {
                type: 'auto-complete',
                label: intl.formatMessage({ id: 'clinics.paymentMethod' }),
                placeholder: intl.formatMessage({ id: 'clinics.paymentMethodEnter' }),
                options: PAYMENT_METHOD,
                getOptionLabel: (option) => intl.formatMessage({ id: `clinic.paymentMethod.${option.label}` }),
                // isOptionEqualToValue: (opt: some, select: some) => opt.value === select,
                propsWrapper: { xs: 6 },
              },
              insuranceCode: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.insuranceCode' }),
                placeholder: intl.formatMessage({ id: 'clinics.insuranceCodeEnter' }),
                propsWrapper: { xs: 6 },
              },
              email: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.email' }),
                placeholder: intl.formatMessage({ id: 'clinics.emailEnter' }),
                register: {
                  required: true,
                  pattern: {
                    value: EMAIL_REGEX,
                    message: intl.formatMessage({ id: 'emailValid' }),
                  },
                },
                propsWrapper: { xs: 6 },
              },

              website: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'website' }),
                placeholder: intl.formatMessage({ id: 'websiteEnter' }),
                propsWrapper: { xs: 6 },
              },
              fanpage: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'fanPage' }),
                placeholder: intl.formatMessage({ id: 'fanPageEnter' }),
                propsWrapper: { xs: 6 },
              },
              version: {
                type: 'select',
                label: intl.formatMessage({ id: 'clinics.mediLicenseType' }),
                placeholder: intl.formatMessage({ id: 'clinics.chooseMediLicenseType' }),
                propsWrapper: { xs: 6 },
                options: MEDI_LICENSE_TYPE,
                readOnly: true,
              },
              currency: {
                type: 'auto-complete',
                label: intl.formatMessage({ id: 'clinics.currency' }),
                placeholder: intl.formatMessage({ id: 'clinics.chooseCurrency' }),
                propsWrapper: { xs: 6 },
                options: appState.common.currencyOptions,
                getOptionLabel: (option: any) => option.value,
                isOptionEqualToValue: (option, selected) => option.value === selected,
              },
              url: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'clinics.info.label.url' }),
                placeholder: intl.formatMessage({ id: 'clinics.info.label.url.enter' }),
                propsWrapper: { xs: 6 },
                readOnly: true,
                InputProps: {
                  endAdornment: (
                    <Tooltip title={intl.formatMessage({ id: 'clinics.info.tooltip.copyUrl' })}>
                      <InputAdornment position="end" sx={{ cursor: 'pointer' }}>
                        <FileCopyIcon />
                      </InputAdornment>
                    </Tooltip>
                  ),
                  style: { borderRadius: 4, overflow: 'hidden' },
                },
                onClick: () => {
                  handleCopyClick();
                },
              },
              avatarId: {
                type: 'uploadImage',
                label: intl.formatMessage({ id: 'clinics.avatar' }),
                placeholder: intl.formatMessage({ id: 'clinics.avatarEnter' }),
                avatarStyle: { height: 271, width: 271 },
              },
              submit: {
                type: 'submitButton',
                fullWidth: true,
                label: intl.formatMessage({ id: 'update' }),
                propsWrapper: { xs: 12 },
              },
            };
          },
          ui: [
            {
              id: 'default',
              propsWrapper: { xs: 8 },
              fields: [
                'organizationName',
                'name',
                'address',
                'licenseNumber',
                'phoneNumber',
                'pic',
                'groupPayment',
                'insuranceCode',
                'email',
                'website',
                'fanpage',
                'version',
                'currency',
                'url',
                'submit',
                '',
                '',
              ],
            },
            {
              id: 'avatarId',
              propsWrapper: { xs: 4 },
              fields: ['avatarId'],
            },
          ],
        }}
      />
    </Box>
  );
};

export default ClinicsInfo;
