import { useDeleteMutate, useFetch } from 'modules/common/hook';
import PatientDetailSession from '../PatientDetailSession';
import { PatientSessionProps } from '../type';
import { API_SERVER } from 'modules/common/api';
import { Box, Button, IconButton, Stack, styled } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { StorageResource } from 'modules/schema';
import { useCallback } from 'react';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import DocumentBox from 'modules/examDoctor/component/detail/attachedDocumentEncounter/DocumentBox';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { ClockHistory } from 'svg';
import useUploadFile from 'modules/common/hook/useUploadFile';
import { STORAGE_RESOURCE_TYPE } from 'modules/common/apiConstants';

interface Props {
  patientId: number;
  revalidateFiles: () => void;
}

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadFile: React.FunctionComponent<Props> = ({ revalidateFiles, patientId }) => {
  const { openNotify } = useGeneralHook();
  const uploadFileToDoc = useUploadFile(revalidateFiles);

  return (
    <IconButton
      sx={{
        padding: 0,
        '&:hover': {
          backgroundColor: 'unset',
        },
      }}
    >
      <Button
        component="label"
        role={undefined}
        variant="contained"
        tabIndex={-1}
        startIcon={<FileUploadIcon />}
        color="inherit"
      >
        <FormattedMessage id={'uploadFile'} />
        <VisuallyHiddenInput
          type="file"
          accept={'.doc,.docx,application/pdf,jpeg,jpg,png,image/*,.svg'}
          onChange={(file) => {
            const files = file.target.files;
            if (files && Array.from(files).every((file) => file.size <= 5000000)) {
              uploadFileToDoc({
                docRefId: patientId,
                type: STORAGE_RESOURCE_TYPE.PATIENT_ATTACHED_DOCUMENT,
                file: files[0],
              });
            } else {
              openNotify({ message: 'attachedDocument.fileValidSize' });
            }
          }}
        />
      </Button>
    </IconButton>
  );
};

const NoData = () => {
  return (
    <Stack height="300px" justifyContent="center" alignItems="center">
      <Stack sx={{ margin: '0 auto' }} justifyContent="center" alignItems="center" gap="20px">
        <ClockHistory height="80px" />
        <FormattedMessage id="noData" />
      </Stack>
    </Stack>
  );
};

const PatientAttachments = ({ patient }: PatientSessionProps) => {
  const { intl } = useGeneralHook();
  const { data: dataFiles, revalidate: revalidateFiles } = useFetch(
    API_SERVER.storageResource.getList({
      type: STORAGE_RESOURCE_TYPE.PATIENT_ATTACHED_DOCUMENT,
      referenceId: patient.id,
    }),
  );

  const deleteFileMutate = useDeleteMutate({ onSuccess: revalidateFiles });
  const deleteFile = useCallback(
    async (docRef) => {
      if (docRef.id) {
        await deleteFileMutate(
          {
            url: API_SERVER.storageResource.delete(docRef.id),
            method: 'DELETE',
          },
          {
            confirmMessage: intl.formatMessage({ id: 'confirmDelete.new' }, { name: docRef.name }),
            okeText: 'common.title.confirm',
          },
        );
      }
    },
    [deleteFileMutate, intl],
  );

  return (
    <PatientDetailSession
      titleKey="patient.patientDetail.session.title.attachment"
      rightAction={<UploadFile revalidateFiles={revalidateFiles} patientId={patient.id!} />}
      allowCollapse
    >
      {dataFiles && dataFiles?.length !== 0 ? (
        <Box>
          {dataFiles?.map((v: StorageResource, index: number) => (
            <DocumentBox key={index} data={v} onDeleteFile={() => deleteFile(v)} readOnly={true} enableDelete={true} />
          ))}
        </Box>
      ) : (
        <NoData />
      )}
    </PatientDetailSession>
  );
};

export default PatientAttachments;
