import DialogCustom from '../../../../../common/component/DialogCustom';
import PrintDialogContent from './PrintDialogContent';
import { some } from '../../../../../common/constants';

interface Props {
  open: boolean;
  onClose: () => void;
  therapy: some;
  therapySession: some;
}
const PrintTherapySessionDialog = ({ open, onClose, therapy, therapySession }: Props) => {
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="therapy.exam.therapyDetail.label.printForm"
      PaperProps={{
        style: { width: '75vw' },
      }}
      maxWidth="xl"
    >
      <PrintDialogContent onClose={onClose} therapy={therapy} therapySession={therapySession} />
    </DialogCustom>
  );
};

export default PrintTherapySessionDialog;
