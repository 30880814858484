import { Alert, Stack, Typography } from '@mui/material';
import { SunEditorReactProps } from 'suneditor-react/dist/types/SunEditorReactProps';
import { memo } from 'react';
import { useIntl } from 'react-intl';

export function extractHTMLContent(html) {
  if (!html) {
    return null;
  }
  return new DOMParser().parseFromString(html, 'text/html').documentElement.textContent;
}

export interface LabelElementProps extends Omit<SunEditorReactProps, 'defaultValue' | 'onChange'> {
  label?: string;
}

interface Props extends LabelElementProps {}

const AlertWarningElement = (props: Props) => {
  const { label } = props;
  const intl = useIntl();
  return (
    <Stack sx={{ width: '100%' }} spacing={2}>
      <Alert severity="warning" sx={{ flexDirection: 'row' }}>
        <Typography dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: label }) }}></Typography>
      </Alert>
    </Stack>
  );
};

export default memo(AlertWarningElement);
