import { Search } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Collapse, Divider, InputAdornment } from '@mui/material';
import { ILayoutFields, ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import { THERAPY_STATUS } from 'modules/reception/constants';
import moment from 'moment/moment';
import { ORDER_BE } from '../../../../common/constants';
import { Sort } from '../../../../../svg';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps, methods }) => {
  const { intl, clearParams, state, setState, onDataLoading } = formProps || {};
  const { setValue } = methods;

  return {
    patientName: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'sellingProduct.title.customerName' }),
      placeholder: intl.formatMessage({ id: 'sale.customerEnter' }),
      showClearBtn: true,
      propsWrapper: { xs: true },
    },
    patientMobilePhone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Search color="inherit" />
          </InputAdornment>
        ),
      },
      noHelperText: true,
      propsWrapper: { xs: true },
    },
    status: {
      type: 'select',
      hasAllOptions: true,
      label: intl.formatMessage({ id: 'status' }),
      options: THERAPY_STATUS,
      noHelperText: true,
      propsWrapper: { xs: true },
    },
    patientCode: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'therapy.label.customer.code' }),
      placeholder: intl.formatMessage({ id: 'therapy.label.customer.enterCode' }),
      noHelperText: true,
      propsWrapper: { xs: true },
    },
    fromDate: {
      type: 'datePicker',
      label: intl.formatMessage({ id: 'fromDate' }),
      propsWrapper: { xs: true, paddingBottom: 1 },
      maxDate: valuesField.toDate && moment(valuesField.toDate).toDate(),
      register: {
        validate: (value) => {
          return value && moment(value).isAfter(valuesField?.toDate)
            ? intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
      },
    },
    toDate: {
      type: 'datePicker',
      label: intl.formatMessage({ id: 'toDate' }),
      propsWrapper: { xs: true },
      minDate: valuesField.fromDate && moment(valuesField.fromDate).toDate(),
      register: {
        validate: (value) => {
          return value && moment(value).isBefore(valuesField?.fromDate)
            ? intl.formatMessage({ id: 'validation.invalidDate' })
            : true;
        },
      },
    },
    search: {
      type: 'submitButton',
      color: 'inherit',
      label: intl.formatMessage({ id: 'search' }),
      propsWrapper: { xs: undefined },
    },
    expand: {
      type: 'button',
      endIcon: (
        <ExpandLessIcon
          style={{
            transition: '0.2s all',
            transform: valuesField?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      ),
      color: 'primary',
      variant: 'text',
      onClick: () => setState({ expand: !state.expand }),
      label: intl.formatMessage({ id: 'advance' }),
      propsWrapper: { xs: undefined },
    },
    reset: {
      type: 'button',
      onClick: () => clearParams && clearParams(),
      color: 'inherit',
      label: intl.formatMessage({ id: 'reset' }),
      propsWrapper: { xs: undefined },
      style: { minWidth: 120, marginTop: 20 },
    },
    orderBy: {
      type: 'submitButton',
      onClick: () => {
        if (!valuesField?.orderBy || valuesField.orderBy === ORDER_BE.asc) {
          setValue('orderBy', ORDER_BE.desc);
          return;
        }
        setValue('orderBy', ORDER_BE.asc);
      },
      disabled: onDataLoading,
      label: intl.formatMessage({ id: 'encounterList.label.input.sort' }),
      style: { minWidth: 120 },
      propsWrapper: { xs: undefined },
      color: 'inherit',
      startIcon: (
        <Box
          sx={{
            transition: 'all 0.3s ease',
            transform: valuesField.orderBy === ORDER_BE.asc ? 'rotate(180deg)' : 'rotate(0deg)',
            '& path': {
              fill: onDataLoading ? '#A8A8AA' : '#0962FF',
            },
          }}
        >
          <Sort />
        </Box>
      ),
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'layout',
      propsGridContainer: { spacing: 1, justifyContent: 'flex-end' },
      fields: ['patientMobilePhone', 'search', 'orderBy', 'expand'],
    },
    {
      id: 'expand',
      fields: ['status', 'patientName', 'patientCode', 'fromDate', 'toDate', 'reset'],
    },

    { id: 'submit', fields: ['save'] },
  ];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement, formProps } = props;
  const { state } = formProps;
  return (
    <>
      <Box display="flex">
        <Box flex={1}>{formProps?.pagin}</Box>
        <Box flex={1}>{listElement[0]}</Box>
      </Box>
      <Collapse in={state?.expand} style={{ marginTop: 16 }}>
        {listElement[1]}
        <Divider style={{ marginBottom: 16, marginTop: 16 }} />
      </Collapse>
    </>
  );
};

export const schemaFilterTherapyForm: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
