import { z } from 'zod';

export const ObservationSchema = z
  .object({
    id: z.number(),
    encounterId: z.number(),
    bodyTemperature: z.number(),
    heartRate: z.number(),
    respiratoryRate: z.number(),
    systolicBloodPressure: z.number(),
    diastolicBloodPressure: z.number(),
    bodyHeight: z.number(),
    bodyWeight: z.number(),
    spo2: z.number(),
    symptom: z.string(),
  })
  .partial()
  .transform((observation) => {
    return {
      ...observation,
      ...(observation?.bodyHeight && observation?.bodyWeight
        ? {
            bodyBMI: (
              observation?.bodyWeight /
              ((observation?.bodyHeight / 100) * (observation?.bodyHeight / 100))
            ).toFixed(2),
          }
        : {}),
    };
  });

export type Observation = z.infer<typeof ObservationSchema>;
