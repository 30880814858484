import { Button, ClickAwayListener, Fade, IconButton, Paper, Popper, Typography } from '@mui/material';
import * as React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { AppState } from '../../../redux/reducer';
import iconFlagIndo from '../../../svg/flag-indonesia.svg';
import iconFlagEn from '../../../svg/flag_en.svg';
import iconFlagVi from '../../../svg/flag_vi.svg';
import { PopoverState } from '../../common/component/elements';
import { some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { setLocale } from '../redux/intlReducer';

const DATA = [
  { src: iconFlagVi, value: 'vi', label: 'Tiếng Việt' },
  { src: iconFlagEn, value: 'en', label: 'English' },
  { src: iconFlagIndo, value: 'id', label: 'Bahasa' },
];

interface Props {
  style?: React.CSSProperties;
}
const LanguageSelect: React.FC<Props> = ({ style }) => {
  const { dispatch } = useGeneralHook();
  const locale = useSelector((state: AppState) => state.intl.locale, shallowEqual);
  const [flag, setFlag] = React.useState<some>(DATA.find((one) => one.value === locale) || DATA[0]);

  return (
    <>
      <PopoverState>
        {({ anchorEl, handleClick, handleClose, open }) => (
          <>
            <IconButton
              style={{
                padding: 8,
                paddingLeft: 0,
                overflow: 'hidden',
              }}
              color="inherit"
              onClick={handleClick}
            >
              <img
                src={flag.src}
                alt=""
                style={{
                  width: 24,
                  height: 24,
                  borderRadius: 24,
                  objectFit: 'cover',
                }}
              />
            </IconButton>
            <Popper open={open} anchorEl={anchorEl} placement="bottom-end" transition style={{ zIndex: 100 }}>
              {({ TransitionProps }) => (
                <ClickAwayListener onClickAway={handleClose}>
                  <Fade {...TransitionProps} timeout={350}>
                    <Paper
                      style={{
                        overflow: 'hidden',
                        padding: '8px 12px',
                      }}
                      variant="outlined"
                    >
                      {DATA.map((v: some, index: number) => {
                        return (
                          <Button
                            variant="text"
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-right',
                              justifyContent: 'flex-start',
                              padding: 1,
                              borderRadius: 0.5,
                              whiteSpace: 'nowrap',
                              cursor: 'pointer',
                            }}
                            fullWidth
                            key={index}
                            onClick={() => {
                              setFlag(v);
                              dispatch(setLocale(v.value));
                            }}
                          >
                            <img src={v.src} alt="" style={{ width: '38px', height: '25px', objectFit: 'cover' }} />
                            &nbsp;&nbsp;&nbsp;
                            <Typography variant="body2">{v.label}</Typography>
                          </Button>
                        );
                      })}
                    </Paper>
                  </Fade>
                </ClickAwayListener>
              )}
            </Popper>
          </>
        )}
      </PopoverState>
    </>
  );
};

export default LanguageSelect;
