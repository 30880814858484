import styled from '@emotion/styled';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  titleKey: string;
}

const PrintFormTitleStyled = styled(Typography)({
  color: '#263238',
  textAlign: 'center',
  fontSize: '28px',
  fontFamily: 'Roboto',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '36px',
  textTransform: 'uppercase',
});

const PrintFormTitle = ({ titleKey }: Props) => {
  return (
    <Stack>
      <PrintFormTitleStyled>
        <FormattedMessage id={titleKey} />
      </PrintFormTitleStyled>
    </Stack>
  );
};

export default PrintFormTitle;
