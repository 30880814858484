import Search from '@mui/icons-material/Search';
import { Box, Checkbox, Collapse, Divider, FormControlLabel, Grid, InputAdornment, Typography } from '@mui/material';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { BE_DATE_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import { VN_CODE } from 'modules/common/redux/commonReducer';
import { EMAIL_REGEX } from 'modules/common/regex';
import {
  FieldsType,
  ILayoutFields,
  ISchemaFields,
  ISchemaForm,
  IUiFields,
  SchemaType,
} from 'modules/common/SchemaForm/utils';
import { formatPatientFormData } from 'modules/reception/component/utils';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Stack from '@mui/material/Stack';
import { ENCOUNTER_STATUS, GENDER } from 'modules/common/apiConstants';
import { axiosThunk } from 'modules/common/redux/axios';
import { API_SERVER } from 'modules/common/api';
import { z } from 'zod';
import { Encounter, IndicationRequest, Patient, PatientSchema } from 'modules/schema';
import { isEmpty, sum, trim } from 'lodash';
import PatientOption from './PatientOption';
import { GroupOption, Option } from 'modules/common/type';
import { EncounterFormMode } from './type';
import { NumericFormatText } from 'modules/common/utils';
import IndicationRequestService from 'modules/common/component/RegisterEncounter/IndicationRequestService';

const SearchIcon = () => (
  <InputAdornment position="start" style={{ marginLeft: '12px', marginRight: '-4px' }}>
    <Search />
  </InputAdornment>
);

type OptionCustom = Option & {
  status: string;
};

interface SchemaProps {
  doctorOptions: Option[];
  serviceOptions: (GroupOption & { parentId: number; price: number })[];
  systemCategoriesOptions: Option[];
  physicsRoomOptions: OptionCustom[];
  encounterMode: EncounterFormMode;
  encounter?: Encounter;
  onRefund(formData): void;
  indicationRequestData: IndicationRequest[];
  setIndicationRequestData: (item) => void;
}

export const fieldSchema: ISchemaFields<SchemaProps> = ({ valuesField, methods, formProps }) => {
  const {
    intl,
    appState,
    doctorOptions,
    systemCategoriesOptions,
    serviceOptions,
    physicsRoomOptions,
    dispatch,
    encounterMode,
    encounter,
  } = formProps || {};
  const { setValue, reset, trigger } = methods;
  const filterServiceOptions = valuesField?.systemCategory
    ? serviceOptions?.filter((option) => option?.parentId === valuesField?.systemCategory?.value)
    : serviceOptions;
  const patientInfoEditable =
    encounterMode === 'CREATE' ||
    (encounterMode === 'UPDATE' && encounter?.status !== ENCOUNTER_STATUS?.FINISHED.value);
  const encounterInfoEditable = encounterMode === 'CREATE' || encounterMode === 'CREATE_WITH_OLD_PATIENT';
  const doctorSelectable = encounter?.status !== ENCOUNTER_STATUS?.FINISHED.value;
  const servicePrice = valuesField?.service?.price || 0;

  return {
    id: {
      type: 'hidden',
    },
    patientId: {
      type: 'hidden',
    },
    search: {
      type: 'auto-complete',
      autoFocus: true,
      placeholder: intl.formatMessage({ id: 'findPatientsByName' }),
      loadOptions: async (name: string) => {
        if (!trim(name)) return;
        const { data } = await dispatch(
          axiosThunk({
            url: API_SERVER.patient.searchByMobilePhoneAndName({ name }).url,
          }),
        );
        const parsedPatients = z.array(PatientSchema).safeParse(data);
        return parsedPatients?.success ? parsedPatients?.data : [];
      },
      defaultValue: null,
      onChange: (value) => {
        if (value) {
          reset({ ...valuesField, ...formatPatientFormData(value, appState) });
          trigger();
        } else {
          reset();
        }
      },
      onChangeInput: (e) => {
        setValue('name', e.currentTarget.value, { shouldValidate: true });
      },
      startAdornment: <SearchIcon />,
      renderOption: (props, option: Patient) => <PatientOption patient={option} otherProps={props} />,
      propsWrapper: { xs: 6 },
    },
    searchByTel: {
      type: 'auto-complete',
      autoFocus: true,
      placeholder: intl.formatMessage({ id: 'findPatientsByTel' }),
      loadOptions: async (mobilePhone: string) => {
        if (!trim(mobilePhone)) return;
        const { data } = await dispatch(
          axiosThunk({
            url: API_SERVER.patient.searchByMobilePhoneAndName({ mobilePhone }).url,
          }),
        );
        const parsedPatients = z.array(PatientSchema).safeParse(data);
        return parsedPatients?.success ? parsedPatients?.data : [];
      },
      defaultValue: null,
      onChange: (value) => {
        if (value) {
          reset({ ...valuesField, ...formatPatientFormData(value, appState) });
          trigger();
        } else {
          reset();
        }
      },
      onChangeInput: (e) => {
        setValue('mobilePhone', e.currentTarget.value, { shouldValidate: true });
      },
      startAdornment: <SearchIcon />,
      renderOption: (props, option) => {
        return <PatientOption patient={option} otherProps={props} />;
      },
      propsWrapper: { xs: 6 },
    },
    name: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'enterPatientName' }),
      label: intl.formatMessage({ id: 'patientName' }),
      propsWrapper: { xs: 4 },
      register: {
        required: true,
      },
      readOnly: !patientInfoEditable,
    },
    dob: {
      type: 'datePicker',
      placeholder: intl.formatMessage({ id: 'birthdayForm' }),
      label: intl.formatMessage({ id: 'birthdayAndAge' }),
      propsWrapper: { xs: 2.5 },
      disableFuture: true,
      openTo: 'year',
      register: {
        validate: (value) => {
          return !value ||
            (moment(value, BE_DATE_FORMAT, true).isValid() &&
              moment(value, BE_DATE_FORMAT, true).isBefore(moment().endOf('day')))
            ? true
            : intl.formatMessage({ id: 'validation.invalidDate' });
        },
        required: {
          value: !valuesField.age && !valuesField.month,
          message: intl.formatMessage({ id: 'encounter.registration.label.birthday.required' }),
        },
      },
      readOnly: !patientInfoEditable || valuesField.age || valuesField.month,
      tooltipError: true,
    },
    age: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'enterAge' }),
      label: '',
      propsWrapper: { xs: 1.5 },
      readOnly: valuesField.dob,
      inputType: 'number',
      required: !patientInfoEditable || (!valuesField.dob && !valuesField.month),
      register: {
        required: {
          value: !valuesField.dob && !valuesField.month,
          message: intl.formatMessage({ id: 'encounter.registration.label.birthday.required' }),
        },
      },
      sx: {
        '& .MuiInputLabel-asterisk': {
          visibility: 'hidden',
        },
      },
      tooltipError: true,
      className: 'label-age-encounter',
    },
    month: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'enterMonth' }),
      label: '',
      propsWrapper: { xs: 1.5 },
      readOnly: valuesField.dob,
      inputType: 'number',
      required: !patientInfoEditable || (!valuesField.dob && !valuesField.age),
      register: {
        required: {
          value: !valuesField.dob && !valuesField.age,
          message: intl.formatMessage({ id: 'encounter.registration.label.birthday.required' }),
        },
        validate: (value) => {
          return !value || value < 99 ? true : intl.formatMessage({ id: 'validation.invalid' });
        },
      },
      sx: {
        '& .MuiInputLabel-asterisk': {
          visibility: 'hidden',
        },
      },
      tooltipError: true,
      className: 'label-age-encounter',
    },
    gender: {
      type: 'select',
      placeholder: intl.formatMessage({ id: 'pickGender' }),
      label: intl.formatMessage({ id: 'gender' }),
      propsWrapper: { xs: 4 },
      options: [GENDER.FEMALE, GENDER.MALE],
      register: {
        required: true,
      },
      readOnly: !patientInfoEditable,
    },
    job: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'job' }),
      placeholder: intl.formatMessage({ id: 'jobEnter' }),
      propsWrapper: { xs: 4 },
      readOnly: !patientInfoEditable,
    },
    identifierCode: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'enterIdentification' }),
      label: intl.formatMessage({ id: 'identification' }),
      propsWrapper: { xs: 6 },
      defaultValue: '',
      inputType: 'number',
      readOnly: !patientInfoEditable,
    },
    mobilePhone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      label: intl.formatMessage({ id: 'telephone' }),
      propsWrapper: { xs: 4 },
      register: {
        required: true,
      },
      inputType: 'number',
      readOnly: !patientInfoEditable,
    },
    email: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'emailAddress' }),
      placeholder: intl.formatMessage({ id: 'enterEmail' }),
      propsWrapper: { xs: 6 },
      required: false,
      register: {
        pattern: {
          value: EMAIL_REGEX,
          message: intl.formatMessage({ id: 'emailValid' }),
        },
      },
      readOnly: !patientInfoEditable,
    },
    jobAddress: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'jobAddressEnter' }),
      label: intl.formatMessage({ id: 'jobAddress' }),
      propsWrapper: { xs: 4 },
      readOnly: !patientInfoEditable,
    },
    ethnic: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'ethnic' }),
      placeholder: intl.formatMessage({ id: 'ethnicEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.ethnicList,
      onChange: (value) => {
        if (value === '55') {
          setValue('nation', null);
        }
      },
      readOnly: !patientInfoEditable,
    },
    nationality: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'nation' }),
      placeholder: intl.formatMessage({ id: 'nationEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.countryList.filter((country) => {
        return valuesField.ethnic?.value === '55' ? country.value !== VN_CODE : true;
      }),
      defaultValue: appState.common.countryList?.find((v) => v.value === VN_CODE),
      readOnly: !patientInfoEditable,
    },
    province: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'province' }),
      placeholder: intl.formatMessage({ id: 'provinceEnter' }),
      propsWrapper: { xs: 4 },
      options: appState.common.provinceData,
      onChange: () => {
        setValue('district', null);
      },
      hotKeys: 'alt+t',
      readOnly: !patientInfoEditable,
    },
    district: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'district' }),
      placeholder: intl.formatMessage({ id: 'districtEnter' }),
      propsWrapper: { xs: 4 },
      options: valuesField.province?.districts,
      hotKeys: 'alt+q',
      readOnly: !patientInfoEditable,
    },
    address: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'addressEnter' }),
      label: intl.formatMessage({ id: 'address' }),
      propsWrapper: { xs: 6.5 },
      hotKeys: 'alt+a',
      readOnly: !patientInfoEditable,
    },
    // Contact info
    contactName: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'contactNameEnter' }),
      label: intl.formatMessage({ id: 'contactName' }),
      propsWrapper: { xs: 4 },
      readOnly: !patientInfoEditable,
    },
    contactTelephone: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'contactTelephoneEnter' }),
      label: intl.formatMessage({ id: 'contactTelephone' }),
      propsWrapper: { xs: 4 },
      readOnly: !patientInfoEditable,
    },
    contactAddress: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'contactAddressEnter' }),
      label: intl.formatMessage({ id: 'contactAddress' }),
      propsWrapper: { xs: 4 },
      readOnly: !patientInfoEditable,
    },
    systemCategory: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'encounterList.groupPriceType' }),
      placeholder: intl.formatMessage({ id: 'encounterList.groupPriceTypeEnter' }),
      propsWrapper: { xs: 4 },
      options: systemCategoriesOptions,
      rawOptions: true,
      onChange: () => {
        setValue('service', null);
      },
      readOnly: !encounterInfoEditable,
    },
    service: {
      type: 'auto-complete',
      groupBy: (option) => option.group,
      label: intl.formatMessage({ id: 'encounterList.serviceName' }),
      placeholder: intl.formatMessage({ id: 'encounterList.serviceNameEnter' }),
      options: filterServiceOptions,
      rawOptions: true,
      getOptionDisabled: (option) => option?.value === valuesField?.service?.value,
      propsWrapper: { xs: 4 },
      onChange: (option) => {
        setValue(
          'systemCategory',
          systemCategoriesOptions?.find((systemcategoty) => systemcategoty?.value === option?.parentId),
        );
      },
      register: {
        required: true,
      },
      readOnly: !encounterInfoEditable,
    },
    servicePrice: {
      type: 'label',
      label: intl.formatMessage({ id: 'exam.price' }),
      propsWrapper: { xs: 4 },
      value: <Typography fontSize={'14px'}>{NumericFormatText(servicePrice)}</Typography>,
    },
    physicsRoom: {
      type: 'select',
      label: intl.formatMessage({ id: 'encounterList.room' }),
      propsWrapper: { xs: 4 },
      options: physicsRoomOptions?.filter((item) => item.status === 'ACTIVE'),
      rawOptions: true,
      register: {
        required: true,
      },
      defaultValue: physicsRoomOptions[0]?.value,
      readOnly: !encounterInfoEditable,
    },
    doctor: {
      type: 'select',
      label: intl.formatMessage({ id: 'examDoctor' }),
      placeholder: intl.formatMessage({ id: 'examDoctorPick' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 4 },
      options: doctorOptions,
      readOnly: encounterMode === 'UPDATE' && !doctorSelectable,
      rawOptions: true,
      defaultValue: doctorOptions?.length === 1 ? doctorOptions[0]?.value : null,
    },
    paymentPlan: {
      type: 'select',
      label: intl.formatMessage({ id: 'paymentCategory' }),
      placeholder: intl.formatMessage({ id: 'paymentCategoryPick' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 4 },
      defaultValue: PAYMENT_PLAN.FEE.value,
      options: [PAYMENT_PLAN.FEE, PAYMENT_PLAN.FREE, PAYMENT_PLAN.OTHER],
      readOnly: !encounterInfoEditable,
    },
    reason: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'encounterList.reason' }),
      placeholder: intl.formatMessage({ id: 'encounterList.reasonEnter' }),
      propsWrapper: { xs: 12 },
      multiline: true,
      rows: 3,
      readOnly: encounterMode === 'UPDATE' && encounter?.status === ENCOUNTER_STATUS.FINISHED.value,
    },
  } as SchemaType;
};

const ui: IUiFields = ({ valuesField }) => {
  return [
    {
      id: 'search',
      fields: ['search', 'searchByTel'],
    },
    {
      id: 'patientInfo',
      title: (
        <Stack marginBottom={2} direction="row" justifyContent="space-between">
          <Typography variant="h6" style={{ marginRight: 24 }}>
            <FormattedMessage id="basicInfo" />
          </Typography>
          {valuesField?.code && (
            <Typography color="textSecondary">
              <FormattedMessage id="patientCodeShort" />
              :&nbsp;{valuesField?.code}
            </Typography>
          )}
        </Stack>
      ),
      fields: ['patientCode', 'name', 'mobilePhone', 'gender', 'dob', 'age', 'month', 'address'],
    },
    {
      id: 'moreInfo1',
      fields: ['email', 'identifierCode'],
    },
    {
      id: 'moreInfo2',
      fields: ['job', 'jobAddress', 'ethnic', 'nationality', 'province', 'district'],
    },
    {
      id: 'contactInfo',
      fields: ['contactName', 'contactTelephone', 'contactAddress'],
    },
    {
      id: 'encounterInfo',
      fields: ['systemCategory', 'service', 'servicePrice', 'physicsRoom', 'doctor', 'paymentPlan', 'reason'],
    },
  ] as FieldsType[];
};

const LayoutSchema: ILayoutFields<SchemaProps> = (props) => {
  const { listElement, formProps, fields, methods, valuesField } = props;
  const indicationRequestData = formProps?.indicationRequestData;
  const servicePrice = valuesField?.service?.price || 0;
  const { setValue } = methods;
  const { state, setState, encounterMode } = formProps;
  const showCreateConsecutivelyCheckbox = encounterMode === 'CREATE' || encounterMode === 'CREATE_WITH_OLD_PATIENT';
  const hiddenSearch = encounterMode === 'CREATE_WITH_OLD_PATIENT' || encounterMode === 'UPDATE';

  const totalPrice = () => {
    if (!isEmpty(indicationRequestData)) {
      if (servicePrice) {
        return NumericFormatText(
          sum(indicationRequestData.filter((i) => !i.isFree).map((i) => i.price)) + servicePrice,
        );
      } else {
        return NumericFormatText(sum(indicationRequestData.filter((i) => !i.isFree).map((i) => i.price)));
      }
    }
    return NumericFormatText(servicePrice);
  };

  const patientPayPrice = () => {
    if (valuesField.paymentPlan !== PAYMENT_PLAN.FREE.value) {
      if (!isEmpty(indicationRequestData)) {
        if (servicePrice) {
          return NumericFormatText(
            sum(indicationRequestData.filter((i) => !i.isFree).map((i) => i.price)) + servicePrice,
          );
        } else {
          return NumericFormatText(sum(indicationRequestData.filter((i) => !i.isFree).map((i) => i.price)));
        }
      }
      return NumericFormatText(servicePrice);
    } else {
      return NumericFormatText(sum(indicationRequestData.filter((i) => !i.isFree).map((i) => i.price)));
    }
  };

  return (
    <>
      <Box padding={2}>
        {!hiddenSearch && listElement[0]}
        <Box marginBottom={2} display="flex" alignItems="baseline">
          <Typography variant="h6" style={{ marginRight: 24 }}>
            <FormattedMessage id="patientInfoUppercase" />
          </Typography>
        </Box>
        <Box className="box-info">
          {listElement[1]}
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography variant="h6">
                <FormattedMessage id="moreInfo" />
              </Typography>
              <Divider style={{ flex: 1, marginLeft: 16 }} />
              <IconButtonTitle
                onClick={() => {
                  setState((old) => ({ ...old, moreInfoCollapse: !old.moreInfoCollapse }));
                }}
              >
                <KeyboardArrowDownIcon
                  style={{
                    transform: state.moreInfoCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 200ms',
                    fontSize: 24,
                  }}
                />
              </IconButtonTitle>
            </Box>
            <Collapse in={state.moreInfoCollapse}>
              <Box>{listElement[2]}</Box>
              <Box paddingTop={2}>{listElement[3]}</Box>
            </Collapse>
          </Box>
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2} className="contact-info-person">
              <Typography variant="h6">
                <FormattedMessage id="contactInfoPerson" />
              </Typography>
              <Divider style={{ flex: 1, marginLeft: 16 }} />
              <IconButtonTitle
                onClick={() => {
                  setState((old) => ({
                    ...old,
                    contactInfoPersonCollapse: !old.contactInfoPersonCollapse,
                  }));
                }}
              >
                <KeyboardArrowDownIcon
                  style={{
                    transform: state.contactInfoPersonCollapse ? 'rotate(180deg)' : 'rotate(0deg)',
                    transition: 'all 200ms',
                    fontSize: 24,
                  }}
                />
              </IconButtonTitle>
            </Box>
            <Collapse in={state.contactInfoPersonCollapse}>{listElement[4]}</Collapse>
          </Box>
        </Box>
        <Box marginTop={2}>
          <Box display="flex" alignItems="center" marginBottom={2}>
            <Typography variant="h6">
              <FormattedMessage id="encounterInfoUppercase" />
            </Typography>
          </Box>
          <Box className="box-info">
            <Box display="flex" alignItems="baseline">
              {listElement[5]}
            </Box>
          </Box>
        </Box>
        {showCreateConsecutivelyCheckbox && (
          <Box marginTop={2}>
            <Box display="flex" alignItems="center" marginBottom={2}>
              <Typography variant="h6" textTransform={'uppercase'}>
                <FormattedMessage id="serviceDesignation" />
              </Typography>
            </Box>
            <Box className="box-info">
              <IndicationRequestService
                indicationRequestData={indicationRequestData}
                setIndicationRequestData={formProps?.setIndicationRequestData}
              />
            </Box>
          </Box>
        )}
        <Box marginTop={2}>
          <Box display="flex" justifyContent="flex-end">
            <Grid container spacing={1} style={{ width: '50%', alignSelf: 'flex-end' }}>
              <Grid item xs={6}>
                <Typography variant="subtitle1" color="primary">
                  <FormattedMessage id="totalPrice" />
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'end' }}>
                <Typography variant="subtitle1">{totalPrice()}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" color="primary">
                  <FormattedMessage id="patientPay" />
                </Typography>
              </Grid>
              <Grid item xs={6} style={{ textAlign: 'end' }}>
                <Typography variant="subtitle1">{patientPayPrice()}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                marginTop={2}
                style={{ display: 'flex', justifyContent: 'space-between', textAlign: 'end', gap: 16 }}
              >
                {fields?.footerCS?.refund}
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box padding={2} paddingTop={0} display="flex" gap={2} justifyContent={'flex-end'}>
        <Grid container spacing={1}>
          <Grid item xs={3}>
            {showCreateConsecutivelyCheckbox && (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => {
                      setValue('createConsecutively', e.target.checked);
                    }}
                  />
                }
                label={<FormattedMessage id="encounter.register.label.createConsecutively" />}
              />
            )}
          </Grid>
          <Grid item xs={9} display={'flex'} justifyContent={'flex-end'}>
            <Box>{fields.footer.cancel}</Box>
            <Box marginLeft={'20px'}>{fields.footer.default}</Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export const receivePatientFormSchema: ISchemaForm<SchemaProps> = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
