import React, { useCallback } from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import DoctorSVG from '../../../svg/login_image.jpg';
import { FormattedMessage } from 'react-intl';
import SchemaForm from '../../common/SchemaForm';
import LanguageSelect from '../../intl/component/LanguageSelect';
import { setLoading } from '../../common/redux/commonReducer';
import useGeneralHook from '../../common/hook/useGeneralHook';
import { axiosThunk } from '../../common/redux/axios';
import { IDENTITY_API_SERVER } from '../../common/api';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { RawLink } from '../../common/component/elements';
import { ROUTES } from '../../layout/router';
import usePaginationHook from '../../common/hook/usePaginationHook';
import CancelIcon from '@mui/icons-material/Cancel';
import useSWR from 'swr';

export interface ResetForm {
  organizationId: string;
  username: string;
}

const ResetPasswordPage: React.FC<{}> = () => {
  const { dispatch } = useGeneralHook();
  const [requested, setRequested] = React.useState(false);
  const [verifyStatus, setVerifyStatus] = React.useState<boolean | undefined>();
  const { params } = usePaginationHook();
  const { verify } = params;

  const resetPassword = useCallback(
    async (data: ResetForm) => {
      try {
        dispatch(setLoading(true));
        await dispatch(
          axiosThunk({
            url: IDENTITY_API_SERVER.user.resetPassword,
            method: 'post',
            data,
          }),
        ).finally(() => {
          setRequested(true);
        });
        dispatch(setLoading(false));
      } catch (e: any) {
        setRequested(true);
      }
      dispatch(setLoading(false));
    },
    [dispatch],
  );

  useSWR(
    IDENTITY_API_SERVER.user.verifyResetPassword,
    async (url) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url,
          method: 'post',
          data: {
            token: verify,
          },
        }),
      )
        .then((e) => {
          setVerifyStatus(true);
        })
        .catch((ex) => {
          setVerifyStatus(false);
        });
      dispatch(setLoading(false));
    },
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  return (
    <Box display="flex" alignItems="stretch" minHeight="100vh">
      <Box flex={1} display="flex" justifyContent="flex-end" alignItems="center">
        <img src={DoctorSVG} alt="" width={550} />
      </Box>
      <Box flex={1} display="flex" alignItems="center">
        {!requested && !verify && (
          <>
            <Box width={300} marginLeft={10}>
              <Typography style={{ minWidth: '400px' }} variant="h5">
                <FormattedMessage id="resetPassword.requested.title" />
              </Typography>
              {/*<Typography variant="subtitle2" color="primary" style={{ marginTop: 24, marginBottom: 8 }}>*/}
              {/*  <FormattedMessage id="login.welcome" />*/}
              {/*</Typography>*/}
              {/*<LogoSVG style={{ height: 46, width: 'auto' }} />*/}
              <Box marginTop="42px">
                <SchemaForm
                  onSubmit={resetPassword}
                  hideSubmitButton
                  schema={{
                    fields: ({ formProps: { intl, state, setState } }) => ({
                      organizationCode: {
                        type: 'text-field',
                        label: <FormattedMessage id="clinicCompanyID" />,
                        placeholder: intl.formatMessage({ id: 'clinicCompanyIDEnter' }),
                        register: {
                          required: true,
                        },
                      },
                      username: {
                        type: 'text-field',
                        label: <FormattedMessage id="username" />,
                        placeholder: intl.formatMessage({ id: 'usernameEnter' }),
                        register: {
                          required: true,
                        },
                      },
                      submit: {
                        type: 'submitButton',
                        fullWidth: true,
                        label: <FormattedMessage id="resetPassword.request.label" />,
                      },
                      resetPassword: {
                        type: () => {
                          return (
                            <Box display={'flex'} justifyContent="center">
                              <RawLink to={{ pathname: ROUTES.login }}>
                                <Typography variant="subtitle1" sx={{ color: '#307BFF' }}>
                                  <FormattedMessage id="returnLoginPage.label" />
                                </Typography>
                              </RawLink>
                            </Box>
                          );
                        },
                      },
                    }),
                  }}
                />
              </Box>
            </Box>
          </>
        )}

        {(requested || verify) && (
          <>
            <Box width={450} marginLeft={10}>
              <Typography variant="h5">
                <FormattedMessage id={verify ? 'resetPassword.verify.title' : 'resetPassword.requested.title'} />
              </Typography>
              <Box marginTop="42px" sx={{ background: '#E8F5E9', borderRadius: 2, minWidth: '450px' }}>
                <Grid container xs={12} spacing={1}>
                  <Grid item xs={1.5} sx={{ alignSelf: 'center' }}>
                    {(requested || (verify && verifyStatus)) && (
                      <CheckCircleIcon sx={{ color: '#43A047', width: '100%' }} />
                    )}
                    {verify && verifyStatus !== undefined && !verifyStatus && (
                      <CancelIcon sx={{ color: '#E53935', width: '100%' }} />
                    )}
                  </Grid>
                  <Grid item xs={10.5}>
                    {requested && (
                      <>
                        <Typography variant="subtitle1" sx={{ color: '#43A047' }}>
                          <FormattedMessage id="resetPassword.requested.success" />
                        </Typography>
                        <Typography variant="body1" sx={{ margin: '8px' }}>
                          <FormattedMessage id="resetPassword.requested.email.check" />
                        </Typography>
                      </>
                    )}
                    {verify && verifyStatus && (
                      <>
                        <Typography variant="subtitle1" sx={{ color: '#43A047' }}>
                          <FormattedMessage id="resetPassword.verify.success.title" />
                        </Typography>
                        <Typography variant="body1" sx={{ margin: '8px' }}>
                          <FormattedMessage id="resetPassword.verify.success.content" />
                        </Typography>
                      </>
                    )}
                    {verify && verifyStatus !== undefined && !verifyStatus && (
                      <>
                        <Typography variant="subtitle1" sx={{ color: '#E53935' }}>
                          <FormattedMessage id="resetPassword.verify.failed.title" />
                        </Typography>
                        <Typography variant="body1" sx={{ margin: '8px' }}>
                          <FormattedMessage id="resetPassword.verify.failed.content" />
                        </Typography>
                      </>
                    )}
                  </Grid>
                </Grid>
              </Box>
              <Box display={'flex'} justifyContent="center">
                <RawLink to={{ pathname: ROUTES.login }}>
                  <Button type="submit" style={{ marginTop: '15px', width: '380px' }}>
                    <FormattedMessage id="resetPassword.verify.label.returnLoginPage" />
                  </Button>
                </RawLink>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box position={'absolute'} top={10} right={40}>
        <LanguageSelect />
      </Box>
    </Box>
  );
};

export default ResetPasswordPage;
