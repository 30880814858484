import { z } from 'zod';

export const UnitQuantitySchema = z
  .object({
    unit: z.string(),
    quantity: z.number(),
  })
  .partial();

export type UnitQuantity = z.infer<typeof UnitQuantitySchema>;
