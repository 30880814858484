import {
  BE_DATE_FORMAT,
  BE_DATE_TIME_FORMAT,
  FE_DATE_FORMAT,
  FE_DATE_TIME_FORMAT,
  PAYMENT_PLAN,
  some,
} from '../../../../common/constants';
import * as React from 'react';
import { Box, Button, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import moment from 'moment/moment';
import { History } from '@mui/icons-material';
import { PAYMENT_STATUS } from 'modules/reception/constants';

interface Props {
  data?: some;

  setOpenPaymentHistoryFormDialog: (open: boolean) => void;
}

const BasicEOCInfo: React.FunctionComponent<Props> = (props) => {
  const { data, setOpenPaymentHistoryFormDialog } = props;
  const paymentStatus = PAYMENT_STATUS.find((s) => s.value === data?.payment?.status);
  return (
    <>
      <Grid container spacing={1}>
        <Grid item xl={3} md={4}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="textSecondary" noWrap width={125}>
              <FormattedMessage id="therapy.code" />
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {data ? data.code : <Skeleton width={100} />}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Typography variant="body2" color="textSecondary" noWrap width={125}>
              <FormattedMessage id="therapy.label.customer.code" />
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {data ? data.patient?.code?.split('-')?.[1] : <Skeleton width={100} />}
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={3} md={4}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="textSecondary" noWrap width={125}>
              <FormattedMessage id="birthdayShort" />
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {data ? moment(data.patient?.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT) : <Skeleton width={100} />}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Typography variant="body2" color="textSecondary" noWrap width={125}>
              <FormattedMessage id="age" />
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {data ? (
                data.patient?.dob && `${moment().diff(data.patient?.dob, 'years', false)}`
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </Box>
        </Grid>{' '}
        <Grid item xl={3} md={4}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="textSecondary" noWrap width={125}>
              <FormattedMessage id="gender" />
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {data ? (
                data?.patient?.gender && <FormattedMessage id={data?.patient?.gender?.toLowerCase()} />
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Typography variant="body2" color="textSecondary" noWrap width={125}>
              <FormattedMessage id="telephone" />
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {data ? data.patient?.mobilePhone : <Skeleton width={100} />}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={true}>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="textSecondary" noWrap width={125}>
              <FormattedMessage id="address" />
            </Typography>
            <Typography variant="subtitle2" noWrap>
              {data ? (
                data.patient?.patientAddressList?.find((a) => a.type === 'HOME')?.address
              ) : (
                <Skeleton width={100} />
              )}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" marginTop={1}>
            <Typography variant="body2" color="textSecondary" noWrap width={125}>
              <FormattedMessage id="paymentCategory" />
            </Typography>
            <Typography variant="subtitle2" noWrap>
              <FormattedMessage id={PAYMENT_PLAN?.[data?.paymentPlan]?.label || ' '} />
            </Typography>
          </Box>
        </Grid>
        <Grid item xl={12} md={12}>
          <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
            <Stack direction="row" justifyContent="start" alignItems="center" gap={2}>
              <Typography variant="subtitle1" color="primary" fontSize="21px" noWrap>
                {data?.patient?.name}
              </Typography>
              <Typography variant="subtitle1" color="primary" fontSize="21px" noWrap>
                {`-`}
              </Typography>
              <Box display="flex">
                <Typography variant="subtitle2" color="textSecondary" noWrap>
                  <FormattedMessage id="returnResultIndication.date" />
                  &nbsp;
                </Typography>
                <Typography variant="subtitle2" noWrap>
                  {data?.createdTime && moment(data?.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_TIME_FORMAT)}
                </Typography>
              </Box>
              <Typography variant="subtitle1" color="primary" fontSize="21px" noWrap>
                {`-`}
              </Typography>
              <Typography noWrap sx={{ color: paymentStatus?.sx || 'primary.main', fontWeight: '500' }}>
                <FormattedMessage id={paymentStatus?.label || ' '} />
              </Typography>
            </Stack>
            <Button
              startIcon={<History />}
              onClick={() => {
                setOpenPaymentHistoryFormDialog(true);
              }}
            >
              <FormattedMessage id="therapy.cashier.dialog.label.paymentHistory" />
            </Button>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default BasicEOCInfo;
