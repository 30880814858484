import { Box } from '@mui/material';
import DialogCustom from '../../../common/component/DialogCustom';
import AttachedDocumentBox from '../../../examDoctor/component/detail/attachedDocument/AttachedDocumentBox';
import { Encounter } from 'modules/schema';

interface Props {
  open: boolean;
  onClose: () => void;
  encounter: Encounter;
}

const DocumentDialog = (props: Props) => {
  const { encounter, open, onClose } = props;

  return (
    <DialogCustom open={open} onClose={onClose} maxWidth="lg" title={'labDoctor.document'}>
      <Box padding={2}>
        <AttachedDocumentBox encounter={encounter} editable />
      </Box>
    </DialogCustom>
  );
};
export default DocumentDialog;
