import { Box, Button, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Typography } from '@mui/material';
import DialogCustom from 'modules/common/component/DialogCustom';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { Patient, RequestAppointment } from 'modules/schema';
import { Controller, useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';

const DialogContent = ({
  requestAppointment,
  onClose,
  patients,
  approveAppointment,
}: {
  requestAppointment: RequestAppointment;
  onClose(): void;
  patients: Patient[];
  approveAppointment(requestAppointment: RequestAppointment, patientResourceId?: string): void;
}) => {
  const form = useForm({ defaultValues: { patientResourceId: '' } });
  const { intl } = useGeneralHook();
  return (
    <Box p={2}>
      <Typography marginBottom={'15px'}>
        <FormattedMessage id="encounter.calendar.warning.phoneNumber" />
        <b>{requestAppointment?.phoneNumber}</b>
        <FormattedMessage id="encounter.calendar.warning.phoneNumber.otherMessage" />
      </Typography>
      <form
        onSubmit={form.handleSubmit(async (data) => {
          localStorage.setItem('patientId', data?.patientResourceId);
          await approveAppointment(requestAppointment, data?.patientResourceId);
          onClose();
        })}
      >
        <FormControl fullWidth>
          <Controller
            control={form.control}
            name="patientResourceId"
            render={({ field: { onChange, value } }) => (
              <RadioGroup defaultValue={value} onChange={onChange}>
                <FormControlLabel
                  sx={{ background: 'rgb(0 0 0 / 8%)', margin: '0 0 15px' }}
                  value=""
                  control={<Radio />}
                  label={intl.formatMessage({ id: 'appointment.request-appointment.input.new-patient' })}
                />
                {patients?.map((patient) => (
                  <FormControlLabel
                    sx={{ background: 'rgb(0 0 0 / 8%)', margin: '0 0 15px' }}
                    value={patient?.id}
                    control={<Radio />}
                    label={`${patient?.name} - ${patient?.mobilePhone}`}
                    key={patient?.id}
                  />
                ))}
              </RadioGroup>
            )}
          />
        </FormControl>
        <Paper
          sx={{
            display: 'flex',
            flexDirection: 'row',
            gap: 2,
            justifyContent: 'flex-end',
            boxShadow: 'none',
          }}
        >
          <Button variant="outlined" type="button" onClick={onClose}>
            <FormattedMessage id="destroy" />
          </Button>
          <Button variant="contained" type="submit">
            <FormattedMessage id="encounter.calendar.label.createExamSchedule" />
          </Button>
        </Paper>
      </form>
    </Box>
  );
};

const ApproveDialog = ({
  open,
  onClose,
  requestAppointment,
  patients,
  approveAppointment,
}: {
  open: boolean;
  onClose(): void;
  requestAppointment: RequestAppointment;
  patients: Patient[];
  approveAppointment(requestAppointment: RequestAppointment, patientResourceId?: string): void;
}) => {
  return (
    <DialogCustom
      open={open}
      maxWidth="md"
      keepMounted={false}
      onClose={onClose}
      PaperProps={{
        style: { width: 500 },
      }}
      title={
        <Typography variant="h6" color="primary">
          <FormattedMessage id="encounter.calendar.title.confirmApprove" />
        </Typography>
      }
    >
      <DialogContent
        requestAppointment={requestAppointment}
        onClose={onClose}
        patients={patients}
        approveAppointment={approveAppointment}
      />
    </DialogCustom>
  );
};

export default ApproveDialog;
