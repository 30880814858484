import { Paper } from '@mui/material';
import * as React from 'react';
import GroupSupplyFormDialog from '../component/supply/GroupSupplyFormDialog';
import SupplyFormDialog from '../component/supply/SupplyFormDialog';
import SupplyListTable from '../component/supply/SupplyListTable';
import useHookSupplyAdmin from '../component/supply/UserHookSupplyAdmin';

interface Props {}

const SupplyAdminPage: React.FunctionComponent<Props> = (props) => {
  const {
    data,
    revalidate,
    isValidating,
    state: { searchParams, setSearchParams, onDelete, formData, setFormData, formDataGroup },
    fnc: { setFormDataGroup, onDeleteGroup, onDeleteServices, onSubmitForm, onSubmitFormGroup },
  } = useHookSupplyAdmin();

  return (
    <Paper style={{ flex: 1, overflow: 'auto' }}>
      <SupplyListTable
        loading={isValidating}
        setFormData={setFormData}
        onDelete={onDelete}
        setFormDataGroup={setFormDataGroup}
        onDeleteGroup={onDeleteGroup}
        onDeleteServices={onDeleteServices}
        data={data}
        searchParams={searchParams}
        setSearchParams={setSearchParams}
        revalidate={revalidate}
      />
      <SupplyFormDialog
        open={!!formData}
        formData={formData}
        onSubmit={onSubmitForm}
        onClose={() => {
          setFormData(undefined);
        }}
      />
      <GroupSupplyFormDialog
        open={!!formDataGroup}
        formData={formDataGroup}
        onSubmit={onSubmitFormGroup}
        onClose={() => {
          setFormDataGroup(undefined);
        }}
      />
      {/* <PriceListFormDialog
        open={!!categoryListFormData}
        formData={categoryListFormData}
        onSubmit={onSubmitCategoryPlanDefinitionForm}
        onClose={() => {
          setCategoryListFormData(undefined);
        }}
        categoryList={categoryList}
      /> */}
    </Paper>
  );
};

export default SupplyAdminPage;
