import { z, ZodTypeAny } from 'zod';
import { createObjectKeysEnumSchema } from './common';
import { SYSTEM_CATEGORY_SCOPE, SYSTEM_CATEGORY_TYPE } from 'modules/common/apiConstants';

export const SystemCategorySchema = z
  .object({
    id: z.number(),
    parentId: z.number(),
    name: z.string(),
    code: z.string(),
    scope: createObjectKeysEnumSchema(SYSTEM_CATEGORY_SCOPE),
    type: createObjectKeysEnumSchema(SYSTEM_CATEGORY_TYPE),
    items: z.array(z.any()),
    resourceId: z.string(),
  })
  .partial();

export function createSystemCategorySchema<T extends ZodTypeAny>(itemSchema: T) {
  return z
    .object({
      id: z.number(),
      parentId: z.number(),
      name: z.string(),
      code: z.string(),
      scope: createObjectKeysEnumSchema(SYSTEM_CATEGORY_SCOPE),
      type: createObjectKeysEnumSchema(SYSTEM_CATEGORY_TYPE),
      items: z.array(itemSchema),
      resourceId: z.string(),
      label: z.string(),
    })
    .partial();
}

export type SystemCategory = z.infer<typeof SystemCategorySchema>;
