import { Box } from '@mui/material';
import * as React from 'react';
import PrescriptionFormDataDetail from './PrescriptionFormDataDetail';
import { EncounterDetailCommonProps } from 'modules/examDoctor/type';

const PrescriptionContent: React.FunctionComponent<EncounterDetailCommonProps> = (props) => {
  return (
    <Box marginTop={-2}>
      <PrescriptionFormDataDetail {...props} />
    </Box>
  );
};

export default PrescriptionContent;
