import { Box } from '@mui/system';
import DialogCustom from 'modules/common/component/DialogCustom';
import SchemaForm from 'modules/common/SchemaForm';
import { useDiagnosisDialogSelector, useDiagnosisDialogState } from './state';

const DiagnosisNoteDialog = () => {
  const openDialog = useDiagnosisDialogSelector.use.diagnosisNoteDialogOpen();
  const onDialogClose = useDiagnosisDialogSelector.use.onDiagnosisNoteDialogClose();
  const setDentalDiagnosisNote = useDiagnosisDialogSelector.use.setDentalDiagnosisNote();
  const note = useDiagnosisDialogState((state) => state.notes[state.selectedDentalDiagnosisId!]);

  return (
    <DialogCustom
      open={openDialog}
      onClose={onDialogClose}
      title="dental.dentalDetail.statusAndDiagnosis.dialog.title"
      PaperProps={{
        style: { width: '500px' },
      }}
      maxWidth="xl"
      sx={{ '& .MuiBox-root.dialog_header': { backgroundColor: '#094EFF', h6: { color: '#E0ECFF' } } }}
    >
      <Box padding={2}>
        <SchemaForm
          schema={{
            fields: ({ formProps }) => {
              const { intl } = formProps || {};
              return {
                note: {
                  type: 'text-field',
                  label: intl.formatMessage({ id: 'note' }),
                  placeholder: intl.formatMessage({ id: 'noteEnter' }),
                  multiline: true,
                  rows: 5,
                  register: {
                    maxLength: {
                      value: 255,
                      message: intl.formatMessage({ id: 'common.maxLength.text' }, { length: '255' }),
                    },
                  },
                },
              };
            },
          }}
          onSubmit={(data) => {
            setDentalDiagnosisNote(data.note);
            onDialogClose();
          }}
          formId="note-form"
          formData={{ note }}
        />
      </Box>
    </DialogCustom>
  );
};

export default DiagnosisNoteDialog;
