import { Columns } from '../../../../../common/component/TableCustom';

export type INDICATION_REQUEST_PRINT_TYPE = 'GROUP' | 'SEPARATE';

export const LOCAL_STORAGE_INDICATION_PRINT_TYPE = 'LOCAL_STORAGE_INDICATION_PRINT_TYPE';

export const LOCAL_STORAGE_INDICATION_COMPACT_MODE = 'LOCAL_STORAGE_INDICATION_COMPACT_MODE';

export const INDICATION_WITHOUT_COMPACT_MODE_DETAIL_COLUMNS: Columns[] = [
  {
    dataIndex: 'serviceName',
    title: 'therapy.therapyDetail.indicationRequest.printForm.content.title',
    default: true,
  },
  {
    dataIndex: 'physicsRoomName',
    title: 'therapy.therapyDetail.indicationRequest.printForm.location.title',
    default: true,
  },
  {
    dataIndex: 'picName',
    title: 'therapy.therapyDetail.indicationRequest.printForm.picName.title',
    default: true,
  },
  {
    dataIndex: 'note',
    title: 'therapy.therapyDetail.indicationRequest.printForm.note.title',
    props: { sx: { wordBreak: 'break-all' } },
    default: true,
  },
];
