import styled from '@emotion/styled';
import { Box, TableCell as TableCellRaw, TableContainer, TableRow as TableRowRaw, Theme } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { PRIMARY } from '../../../../colors';
import './styles.scss';

export const Col = styled(Box)`
  display: flex;
  flex-direction: column;
`;

export const TableCustomRow = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: theme.palette.primary[50],
    '&:hover > td': {
      backgroundColor: theme.palette.primary[100],
    },
  },
}))(TableRowRaw);

export const TableHeaderCell = withStyles((theme: Theme) => ({
  root: {
    borderBottom: 'none',
    color: PRIMARY.main,
    fontWeight: 500,
    fontSize: 12,
    padding: 8,
    zIndex: 3,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.primary[50],
  },
}))(TableCellRaw);

export const TableCustomCell = withStyles((theme: Theme) => ({
  root: {
    borderTop: '4px solid white',
    borderBottom: 'none',
    padding: 8,
    fontSize: 16,
    backgroundColor: theme.palette.primary[50],
    maxWidth: 300,
    wordBreak: 'break-all',
  },
}))(TableCellRaw);

export const TableCustomContainer = withStyles((theme: Theme) => ({
  root: {
    position: 'relative',
    flex: 1,
    height: '100%',
  },
}))(TableContainer);
