import * as React from 'react';
import { some } from '../../../../common/constants';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import SchemaForm from '../../../../common/SchemaForm';
import { schemaFilterEncounterForm } from './schema';

interface Props {
  hookPagination: HookPaginationProps;
  pagin?: React.ReactNode;
  selectionIndication: some[];
  onPrint: () => void;
}

const FilterIndicationBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, pagin, onPrint, selectionIndication } = props;
  const { params, setParams, clearParams } = hookPagination;

  return (
    <SchemaForm
      schema={schemaFilterEncounterForm}
      onSubmit={setParams}
      formData={params}
      clearParams={clearParams}
      hideSubmitButton
      pagin={pagin}
      selectionIndication={selectionIndication}
      onPrint={onPrint}
    />
  );
};

export default FilterIndicationBox;
