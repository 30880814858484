import * as React from 'react';
import { API_SERVER } from 'modules/common/api';
import { some, SYSTEM_CATEGORY_SCOPE, SYSTEM_CATEGORY_TYPE } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import { axiosThunk } from 'modules/common/redux/axios';
import { AxiosError } from 'axios';
import { useFetch } from 'modules/common/hook';
import { Service } from 'modules/schema';

const useHookSupplyAdmin = () => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const { promptConfirmation, close } = confirmDialog;
  const [searchParams, setSearchParams] = React.useState<some>({});
  const [formData, setFormData] = React.useState<some | undefined>();
  const [formDataGroup, setFormDataGroup] = React.useState<some | undefined>();

  const { data, revalidate, isValidating } = useFetch(API_SERVER.medicalSupplies.getList(searchParams));

  // delete services multiple
  const onDeleteServices = React.useCallback(
    async (services: Service[]) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content:
          services?.length === 1
            ? intl.formatMessage({ id: 'confirmDelete' }, { name: services[0]?.name })
            : intl.formatMessage({ id: 'confirm.delete.multiple' }, { quantity: services?.length }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(
            axiosThunk({
              url: API_SERVER.medicalSupplies.deleteMultiple,
              method: 'delete',
              data: {
                data: services?.map((i) => i.id),
              },
            }),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e: any) {
          let err = e?.response?.data?.errors;
          if (err) {
            err.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar, setFormData],
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'supply.deleteTitle' }),
        content: intl.formatMessage({ id: 'supply.deleteContent' }, { name: value.name }),
      });
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.medicalSupplies.delete(value?.id),
            method: 'delete',
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
            // revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({
                  message: v.message,
                  type: 'error',
                });
              });
          })
          .finally(() => {
            dispatch(setLoading(false));
            revalidate();
          });
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );
  const onDeleteGroup = React.useCallback(
    async (value: some) => {
      const confirm = await promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'supply.deleteGroupTitle' }),
        content: intl.formatMessage({ id: 'confirmDeleteServicePrice' }, { name: value.name }),
      });
      if (confirm) {
        await dispatch(
          axiosThunk({
            url: API_SERVER.systemCategory.delete(value?.id),
            method: 'delete',
          }),
        )
          .then((e) => {
            openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
            // revalidate();
          })
          .catch((e: AxiosError<some>) => {
            if (e.response)
              e.response?.data?.errors.forEach((v) => {
                openSnackbar({
                  message: v.message,
                  type: 'error',
                });
              });
          })
          .finally(() => {
            dispatch(setLoading(false));
            revalidate();
          });
      }
      close();
    },
    [close, dispatch, intl, openSnackbar, promptConfirmation, revalidate],
  );

  const onSubmitForm = React.useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: value?.id
            ? API_SERVER.medicalSupplies.update(value?.id)
            : API_SERVER.medicalSupplies.create(value?.listId),
          method: value?.id ? 'put' : 'post',
          data: {
            ...value,
            // specializedDepartmentId: value.specializedDepartment?.id
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
          // revalidate();
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({
                message: v.message,
                type: 'error',
              });
            });
        })
        .finally(() => {
          dispatch(setLoading(false));
          setFormData(undefined);
          revalidate();
        });
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onSubmitFormGroup = React.useCallback(
    async (value: some) => {
      dispatch(setLoading(true));
      await dispatch(
        axiosThunk({
          url: value?.id ? API_SERVER.systemCategory.update(value?.id) : API_SERVER.systemCategory.create(),
          method: value?.id ? 'put' : 'post',
          data: {
            ...value,
            scope: SYSTEM_CATEGORY_SCOPE.medicalSupplies,
            type: SYSTEM_CATEGORY_TYPE.group,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: value?.id ? 'updateSuccess' : 'createSuccess' }) });
          // revalidate();
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({
                message: v.message,
                type: 'error',
              });
            });
        })
        .finally(() => {
          dispatch(setLoading(false));
          setFormDataGroup(undefined);
          revalidate();
        });
    },
    [dispatch, intl, openSnackbar, revalidate],
  );
  return {
    data: data,
    revalidate,
    isValidating: isValidating && !data,
    state: { searchParams, setSearchParams, onDelete, formData, setFormData, formDataGroup },
    fnc: { setFormDataGroup, onDeleteGroup, onDeleteServices, onSubmitForm, onSubmitFormGroup },
  };
};

export default useHookSupplyAdmin;
