import { Paper } from '@mui/material';
import { MedicalExaminationForm } from 'modules/schema/MedicalExaminationForm';
import * as React from 'react';
import SchemaForm from '../../../common/SchemaForm';

interface Props {
  formData: MedicalExaminationForm;
  onSubmit: (value: MedicalExaminationForm) => void;
  listTemplate?: MedicalExaminationForm[];
}

const FormEncounter: React.FunctionComponent<Props> = ({ formData, listTemplate, onSubmit }) => {
  return (
    <Paper style={{ padding: 16, flex: 2, height: '100%', overflow: 'auto' }}>
      <SchemaForm
        formData={formData}
        onSubmit={onSubmit}
        schema={{
          fields: ({ formProps }) => {
            const { intl } = formProps;
            return {
              name: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'encounterForm.name' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
                register: {
                  required: true,
                  validate: (value) => {
                    if (
                      listTemplate
                        ?.filter((v) => v.id !== formData?.id)
                        ?.findIndex((v) => v.name?.toLowerCase()?.trim() === value?.toLowerCase()?.trim()) !== -1
                    ) {
                      return intl.formatMessage({ id: 'uniqName' });
                    }
                    return true;
                  },
                },
              },
              illnessesHistory: {
                type: 'text-editor',
                fullOption: true,
                label: intl.formatMessage({ id: 'encounterDetail.illnessHistory' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
              },
              medicalHistory: {
                type: 'text-editor',
                fullOption: true,
                label: intl.formatMessage({ id: 'encounterDetail.medicalHistory' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
              },
              symptom: {
                type: 'text-editor',
                fullOption: true,
                label: intl.formatMessage({ id: 'encounterDetail.clinicalSymptoms' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
              },
              provisionalDiagnosis: {
                type: 'text-editor',
                fullOption: true,
                label: intl.formatMessage({ id: 'preliminaryDiagnosis' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
              },
              differentialDiagnosis: {
                type: 'text-editor',
                fullOption: true,
                label: intl.formatMessage({ id: 'encounterDetail.diagnosis' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
              },
              note: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'note' }),
                placeholder: intl.formatMessage({ id: 'noteEnter' }),
              },
            };
          },
        }}
      />
    </Paper>
  );
};

export default FormEncounter;
