import { z } from 'zod';

export const TherapyDefinitionIndicationSchema = z
  .object({
    id: z.number(),
    serviceName: z.string(),
    serviceScope: z.string(),
    serviceId: z.number(),
    price: z.number(),
  })
  .partial();

export type TherapyDefinitionIndication = z.infer<typeof TherapyDefinitionIndicationSchema>;
