import { Add, Search } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { Box, Collapse, Divider, InputAdornment, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import { ILayoutFields, ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';
import { EMPLOYEE_STATUS } from '../../../constants';

export interface FilterParamsUser {
  name?: string;
  email?: string;
  phoneNumber?: string;
  status?: string;
  branch?: string;
}

export const fieldSchema: ISchemaFields = ({ valuesField, methods, formProps }) => {
  const { intl, onAddNew, state, setState, clearParams } = formProps || {};
  return {
    name: {
      type: 'text-field',
      placeholder: intl.formatMessage({ id: 'employee.name' }),
      InputProps: {
        startAdornment: (
          <InputAdornment position="start">
            <Search color="inherit" />
          </InputAdornment>
        ),
      },
      showClearBtn: true,
      propsWrapper: { xs: true },
    },
    status: {
      type: 'select',
      label: intl.formatMessage({ id: 'employee.status' }),
      hasAllOptions: true,
      options: [
        { value: EMPLOYEE_STATUS.active, label: 'employee.active' },
        { value: EMPLOYEE_STATUS.inactive, label: 'employee.deactive' },
      ],
      propsWrapper: { xs: 2 },
    },
    phoneNumber: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'telephone' }),
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      propsWrapper: { xs: 2 },
    },
    branch: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'employee.unit' }),
      placeholder: intl.formatMessage({ id: 'employee.unitEnter' }),
      propsWrapper: { xs: 2 },
    },
    search: {
      type: 'submitButton',
      color: 'inherit',
      label: intl.formatMessage({ id: 'search' }),
      propsWrapper: { xs: undefined },
    },
    expand: {
      type: 'button',
      endIcon: (
        <ExpandLessIcon
          style={{
            transition: '0.2s all',
            transform: valuesField?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
      ),
      color: 'primary',
      variant: 'text',
      onClick: () => setState({ expand: !state.expand }),
      label: intl.formatMessage({ id: 'advance' }),
      propsWrapper: { xs: undefined },
    },
    add: {
      type: 'button',
      startIcon: <Add />,
      onClick: onAddNew,
      color: 'primary',
      label: intl.formatMessage({ id: 'add' }),
      propsWrapper: { xs: undefined },
    },
    reset: {
      type: 'button',
      onClick: () => clearParams(),
      color: 'inherit',
      label: intl.formatMessage({ id: 'reset' }),
      propsWrapper: { xs: undefined },
      style: { minWidth: 120, marginTop: 20 },
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'layout',
      propsGridContainer: { spacing: 1 },
      fields: ['name', 'search', 'expand', 'add'],
    },
    {
      id: 'expand',
      fields: ['status', 'phoneNumber', 'branch', 'reset'],
    },

    { id: 'submit', fields: ['save'] },
  ];
};

const LayoutSchema: ILayoutFields = (props) => {
  const { listElement, formProps } = props;
  const { state } = formProps;
  return (
    <>
      <Box display="flex">
        <Box flex={1.5}>
          <Typography variant="h6">
            <FormattedMessage id="employee.list" />
          </Typography>
          {formProps?.pagin}
        </Box>
        <Box flex={1}>{listElement[0]}</Box>
      </Box>
      <Collapse in={state?.expand} style={{ marginTop: 16 }}>
        {listElement[1]}
        <Divider style={{ marginBottom: 16 }} />
      </Collapse>
    </>
  );
};

export const schemaUserForm: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
  layout: LayoutSchema,
};
