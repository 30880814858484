import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router';
import { AppState } from '../../../redux/reducer';
import { ROUTES } from '../../layout/router';

interface IHomePageProps {}

const HomePage: React.FunctionComponent<IHomePageProps> = () => {
  const roles = useSelector((state: AppState) => state.authen.user?.role);
  return <Redirect to={roles && roles.length > 0 ? ROUTES.encounterList.value : '/404'} />;
};

export default HomePage;
