import { Box, Stack, Typography } from '@mui/material';
import DateInput from 'modules/common/component/form/DateInput';
import moment from 'moment';
import React, { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import Filter from 'modules/common/component/filter';
import Report from 'modules/common/component/report';
import { useDialog, useFetch } from 'modules/common/hook';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, PAYMENT_PLAN } from 'modules/common/constants';
import {
  CARE_PLAN_TYPE,
  ENCOUNTER_STATUS,
  GENDER,
  PATIENT_TYPE,
  SPECIALIZED_REPORT_TYPE,
  THERAPY_STATUS,
} from 'modules/common/apiConstants';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { FormattedMessage, useIntl } from 'react-intl';
import parse from 'html-react-parser';
import { ReportFilterOption } from 'modules/common/component/report/type';
import { DEFAULT_CONFIG } from 'modules/common/component/filter/const';
import { DEFAULT_VALUES, FILTER_CONFIGS } from 'modules/common/page/report/work/medicalExam/consts';
import { FormSchema, SearchParams } from 'modules/common/page/report/work/medicalExam/type';
import SelectInput from 'modules/common/component/form/SelectInput';
import { formatEndDate, formatStartDate, isValidDate } from 'modules/common/utils';
import { Option, ValueType } from 'modules/common/type';
import { ExamReport } from 'modules/schema';
import { find, intersection } from 'lodash';
import { WORK_API } from 'modules/common/service';
import { LightTooltip } from 'modules/common/component/table/TableBodyRow';
import { extractHTMLContent } from 'modules/common/SchemaForm/element/text-editor/TextEditorElement';
import { VIEW_ENCOUNTER, VIEW_PATIENT } from '../../const';
import { ROUTES } from 'modules/layout/router';
import { duplicateTab } from 'modules/common/component/Encounter/utils';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';

export const FILTER_KEY = 'ENCOUNTER_REPORT_FILTER';
export const FILTER_KEY_VALUE = 'ENCOUNTER_REPORT_FILTER_VALUE';
export const SELECT_COLUMNS_KEY = 'ENCOUNTER_REPORT_SELECT_COLUMNS';

interface Props {
  typeOption: ValueType<typeof SPECIALIZED_REPORT_TYPE>[];
}

type OptionCustom = Option & { color?: string };

const EncounterReportTable: React.FunctionComponent<Props> = ({ typeOption }) => {
  const filterConfig = JSON.parse(localStorage.getItem(FILTER_KEY_VALUE)!);
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const { page, pageSize, paginationProps, resetPage } = useTablePagination();
  const intl = useIntl();
  const [searchParams, setSearchParams] = React.useState<SearchParams>(DEFAULT_VALUES);
  const [filterReportOptions, setFilterReportOptions] = React.useState<ReportFilterOption[]>([]);
  const roles = useSelector((state: AppState) => state.authen.user?.role);
  const form = useForm<FormSchema>({
    defaultValues: {
      fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
      toDate: formatEndDate(moment()),
      keyword: '',
      type: typeOption[0],
    },
    mode: 'onChange',
  });
  const type = form.watch('type');
  const fromDate = formatStartDate(form.watch('fromDate'));
  const toDate =
    moment(form.watch('toDate')) < moment()
      ? formatEndDate(moment(form.watch('toDate')).add(-1, 'day'))
      : formatEndDate(moment());

  const allOptions = intl.formatMessage({ id: 'all' });

  const formatSearchParams = useMemo(() => {
    return {
      ...searchParams,
      page: page,
      pageSize: pageSize,
      fromDate: fromDate,
      toDate: toDate,
      type: type?.value ? type?.value : typeOption[0].value,
    };
  }, [fromDate, page, pageSize, searchParams, toDate, type?.value, typeOption]);

  const { data: reportPage } = useFetch(WORK_API.getMedicalExamPaging(formatSearchParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'TABLE'),
  });

  const { data: printDataPage } = useFetch(
    WORK_API.getMedicalExamPaging({ ...formatSearchParams, page: 0, pageSize: 100000 }),
    {
      globalLoading: true,
      revalidateOnFocus: false,
      enabled: openPrintDialog && isValidDate(fromDate, toDate, 'TABLE'),
    },
  );

  const exportUrl = React.useMemo(
    () =>
      WORK_API.getMedicalExamExcel({
        ...formatSearchParams,
        page: 0,
        pageSize: 100000,
        type: type?.value ? type?.value : typeOption[0].value,
      }),
    [formatSearchParams, type, typeOption],
  );

  React.useEffect(() => {
    setFilterReportOptions([
      { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      {
        title: DEFAULT_CONFIG.SERVICE.title,
        value: type?.label ? intl.formatMessage({ id: type.label }) : allOptions,
      },
      {
        title: DEFAULT_CONFIG.ENCOUNTER_STATUS.title,
        value:
          filterConfig?.ENCOUNTER_STATUS?.length === 0
            ? allOptions
            : filterConfig?.ENCOUNTER_STATUS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
      },
      {
        title: DEFAULT_CONFIG.PAYMENT_PLAN.title,
        value:
          filterConfig?.PAYMENT_PLAN?.length === 0
            ? allOptions
            : filterConfig?.PAYMENT_PLAN.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
      },
      {
        title: DEFAULT_CONFIG.PATIENT_TYPE.title,
        value:
          filterConfig?.PATIENT_TYPE?.length === 0
            ? allOptions
            : filterConfig?.PATIENT_TYPE.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
      },
      {
        title: DEFAULT_CONFIG.EMPLOYEE.title,
        value:
          filterConfig?.EMPLOYEE?.length === 0
            ? allOptions
            : filterConfig?.EMPLOYEE.map((ele) => ele.employeeFullName).join(', '),
      },
      {
        title: DEFAULT_CONFIG.ICD10.title,
        value: filterConfig?.ICD10?.length === 0 ? allOptions : filterConfig?.ICD10.map((ele) => ele.label).join(', '),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOptions, fromDate, intl, toDate, type]);

  const COLUMNS: Column<ExamReport>[] = [
    { type: 'INDEX', width: '2%', sticky: true },
    {
      title: 'encounterDate',
      render: (record) => record.createdTime?.format(FE_DATE_FORMAT),
      width: '8%',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'encounterId',
      render: (record) => {
        if (intersection(VIEW_ENCOUNTER, roles).length > 0) {
          return (
            <Typography
              fontSize={'14px'}
              onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                event.preventDefault();
                const type = form.getValues('type')?.value;
                if (type === 'THERAPY') {
                  duplicateTab(ROUTES.therapyDetail.gen(record.id!));
                } else if (type === 'DENTAL') {
                  duplicateTab(ROUTES.dentalDetail.gen(record.id!, record.patientId!));
                } else {
                  duplicateTab(ROUTES.encounterDetail.gen(record.id!, record.patientId!));
                }
              }}
              color={'primary'}
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {record.examCode}
            </Typography>
          );
        } else {
          return <Typography fontSize={'14px'}> {record.examCode}</Typography>;
        }
      },
      width: '8%',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'patientId',
      render: (record) => {
        if (intersection(VIEW_PATIENT, roles).length > 0) {
          return (
            <Typography
              fontSize={'14px'}
              onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
                event.preventDefault();
                duplicateTab(ROUTES.patientProfile.gen(record?.patientId!));
              }}
              color={'primary'}
              sx={{
                fontStyle: 'normal',
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '24px',
                letterSpacing: '0.5px',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
            >
              {record.formatCode}
            </Typography>
          );
        } else {
          return <Typography fontSize={'14px'}> {record.formatCode}</Typography>;
        }
      },
      width: '8%',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'patientName',
      render: 'patientName',
      width: '10%',
      maxWidth: '120px',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
      fieldName: 'patientName',
      enableEllipsis: true,
    },
    {
      title: 'filter.popup.title.patientType',
      render: (record) => record.patientType && <FormattedMessage id={PATIENT_TYPE[record.patientType!]?.label} />,
      width: '8%',
      sticky: true,
      selectColumnConfig: {
        disabled: true,
        defaultChecked: true,
      },
    },
    {
      title: 'common.title.encounterService',
      render: 'serviceName',
      width: '8%',
      maxWidth: '100px',
      selectColumnConfig: {
        defaultChecked: true,
      },
      fieldName: 'serviceName',
      enableEllipsis: true,
    },
    {
      title: 'common.title.icd10',
      render: (record) => (
        <LightTooltip title={record.icdDiagnosis || ''} placement="bottom-start">
          <Typography
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              cursor: 'pointer',
              maxWidth: '100px',
              fontSize: '14px',
            }}
          >
            {record.icdDiagnosis}
          </Typography>
        </LightTooltip>
      ),
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'common.title.differentialDiagnosis',
      render: (record) => {
        const differentialDiagnosis = extractHTMLContent(record.differentialDiagnosis || '');
        return (
          <LightTooltip title={differentialDiagnosis || ''} placement="bottom-start">
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'pointer',
                maxWidth: '100px',
                fontSize: '14px',
              }}
            >
              {differentialDiagnosis}
            </Typography>
          </LightTooltip>
        );
      },
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'common.title.clinicalExamination',
      render: (record) => {
        const clinicalExamination = extractHTMLContent(record.clinicalExamination || '');
        return (
          <LightTooltip title={clinicalExamination || ''} placement="bottom-start">
            <Typography
              sx={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                cursor: 'pointer',
                maxWidth: '120px',
                fontSize: '14px',
              }}
            >
              {clinicalExamination}
            </Typography>
          </LightTooltip>
        );
      },
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'common.title.patientTarget',
      render: (record) => <FormattedMessage id={PAYMENT_PLAN[record.paymentPlan!].label} />,
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'encounterBook.status',
      render: (record) => {
        const arrayStatus: OptionCustom[] = [
          THERAPY_STATUS.NEW,
          THERAPY_STATUS.CANCELLED,
          THERAPY_STATUS.FINISHED,
          THERAPY_STATUS.PROCESSING,
          ENCOUNTER_STATUS.ARRIVED,
          ENCOUNTER_STATUS.CANCELLED,
          ENCOUNTER_STATUS.ERROR,
          ENCOUNTER_STATUS.INPROGRESS,
        ];
        const status = find(arrayStatus, { value: record.status });
        return (
          <Typography fontSize={14} color={status?.color}>
            <FormattedMessage id={status?.label} />
          </Typography>
        );
      },
      width: '10%',
      selectColumnConfig: {
        defaultChecked: true,
      },
    },
    {
      title: 'common.title.doctor',
      render: 'picName',
      selectColumnConfig: {
        defaultChecked: false,
      },
      width: '10%',
    },
    {
      title: 'birthdayShort',
      render: (record) => moment(record.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT),
      selectColumnConfig: {
        defaultChecked: false,
      },
      width: '10%',
    },
    {
      title: 'gender',
      render: (record) => <FormattedMessage id={GENDER[record.gender!].label} />,
      selectColumnConfig: {
        defaultChecked: false,
      },
      width: '10%',
    },
    {
      title: 'telephone',
      render: 'mobilePhone',
      selectColumnConfig: {
        defaultChecked: false,
      },
      width: '10%',
    },
    {
      title: 'email',
      render: 'email',
      selectColumnConfig: {
        defaultChecked: false,
      },
      width: '10%',
    },
    {
      title: 'encounterInfo.solution',
      render: (record) => {
        return <FormattedMessage id={CARE_PLAN_TYPE[record?.carePlanType!]?.label || ' '} />;
      },
      selectColumnConfig: {
        defaultChecked: false,
      },
      width: '10%',
    },
    {
      title: 'encounterBook.note',
      render: (record) => parse(record.carePlanNote || ''),
      selectColumnConfig: {
        defaultChecked: false,
      },
      width: '10%',
    },
  ];
  const [selectedColumns, setSelectedColumn] = React.useState<Column<ExamReport>[]>(
    COLUMNS?.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );

  return (
    <Stack direction="column" gap={2}>
      <Report.Title
        title="report.workReport.tab.title.encounterReport"
        showSubTitle
        foundResultAmount={reportPage?.content.length || 0}
        totalElementAmount={reportPage?.pagination.totalElements}
        renderRight={
          <Stack direction="row" alignItems="center" justifyContent="center" gap="16px" paddingTop={2.5}>
            <Report.SearchInput
              form={form}
              name="keyword"
              onSearch={() => {
                setSearchParams((prev) => ({ ...prev, keyword: form.getValues('keyword') }));
                resetPage();
              }}
            />
          </Stack>
        }
      />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue, filterConfig) => {
          setSearchParams((prev) => ({
            ...prev,
            icd10Codes: filterValue.ICD10,
            paymentPlans: filterValue.PAYMENT_PLAN,
            picUserIds: filterValue.EMPLOYEE,
            serviceIds: filterValue.SERVICE,
            statuses: filterValue.ENCOUNTER_STATUS,
            patientTypes: filterValue.PATIENT_TYPE,
          }));
          setFilterReportOptions([
            { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            {
              title: DEFAULT_CONFIG.SERVICE.title,
              value: type?.label ? intl.formatMessage({ id: type.label }) : allOptions,
            },
            {
              title: DEFAULT_CONFIG.ENCOUNTER_STATUS.title,
              value:
                filterConfig.ENCOUNTER_STATUS?.length === 0
                  ? allOptions
                  : filterConfig.ENCOUNTER_STATUS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
            {
              title: DEFAULT_CONFIG.PAYMENT_PLAN.title,
              value:
                filterConfig.PAYMENT_PLAN?.length === 0
                  ? allOptions
                  : filterConfig.PAYMENT_PLAN.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
            {
              title: DEFAULT_CONFIG.PATIENT_TYPE.title,
              value:
                filterConfig.PATIENT_TYPE?.length === 0
                  ? allOptions
                  : filterConfig.PATIENT_TYPE.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
            {
              title: DEFAULT_CONFIG.EMPLOYEE.title,
              value:
                filterConfig.EMPLOYEE?.length === 0
                  ? allOptions
                  : filterConfig.EMPLOYEE.map((ele) => ele.employeeFullName).join(', '),
            },
            {
              title: DEFAULT_CONFIG.ICD10.title,
              value:
                filterConfig.ICD10?.length === 0 ? allOptions : filterConfig.ICD10.map((ele) => ele.label).join(', '),
            },
          ]);
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" justifyContent="center" gap="13px">
              <SelectInput
                form={form}
                name="type"
                options={typeOption}
                getValue="value"
                renderLabel="label"
                label="serviceTypes"
                placeholder="serviceTypes"
                onChange={resetPage}
              />
              <DateInput
                form={form}
                name="fromDate"
                label="fromDate"
                mode="start"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment() && moment(toDate)}
                type="TABLE"
                onChange={resetPage}
              />
              <DateInput
                form={form}
                name="toDate"
                label="toDate"
                mode="end"
                required
                format={FE_DATE_FORMAT}
                maxDate={moment()}
                minDate={moment(fromDate).add(+1, 'day')}
                type="TABLE"
                onChange={resetPage}
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() => {
                    form.reset();
                    setSearchParams(DEFAULT_VALUES);
                  }}
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="end" gap="8px" minWidth="26%">
            <Filter.Button />
            <Report.SelectColumnButton
              columns={COLUMNS}
              onSave={setSelectedColumn}
              localStorageKey={SELECT_COLUMNS_KEY}
            />
            <Report.ExportDataButton exportUrl={exportUrl} />
            <Report.PrintDataButton
              title="report.workReport.tab.title.encounterReport"
              openDialog={openPrintDialog}
              onOpenDialog={onOpenPrintDialog}
              onCloseDialog={onClosePrintDialog}
              content={
                <Stack direction="column" gap={1}>
                  <Report.FilterPrintFormat filterOptions={filterReportOptions} />
                  <Table
                    data={printDataPage?.content}
                    columns={selectedColumns}
                    dataKey="id"
                    rowProps={{ sx: { pageBreakAfter: 'auto', td: { wordBreak: 'break-word' } } }}
                  />
                </Stack>
              }
            />
          </Stack>
        </Stack>
      </Filter>
      <Table
        columns={selectedColumns}
        dataKey="id"
        data={reportPage?.content}
        paginationProps={paginationProps}
        totalElements={reportPage?.pagination.totalElements}
        stickyHeader
        maxHeigth="100%"
        rowProps={{ sx: { td: { wordBreak: 'break-word' } } }}
      />
    </Stack>
  );
};

export default EncounterReportTable;
