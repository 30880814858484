import { useFetch } from 'modules/common/hook';
import useFilterContext from 'modules/common/component/filter/useFilterContext';
import { API_SERVER } from 'modules/common/api';
import { FilterProps, MedicationCategoryFilter } from 'modules/common/component/filter/type';
import { Stack, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import SelectInput from 'modules/common/component/form/SelectInput';
import { MEDICATION_CATEGORY_SCOPE } from 'modules/common/apiConstants';
import ChipList from '../CustomeChip';

const MedicationCategory = ({ filter: { placeholder, type } }: FilterProps<MedicationCategoryFilter>) => {
  const { form } = useFilterContext();
  const { data: medicationCategories } = useFetch(
    API_SERVER.medicationCategory.getCategoryByClinicId({ scope: MEDICATION_CATEGORY_SCOPE.CLINIC }),
    {
      revalidateOnFocus: false,
    },
  );
  const selectedMedicationCategories = form.watch(type);
  return (
    <Stack direction="column" gap="10px" flex={1}>
      <SelectInput
        form={form}
        name={type}
        options={medicationCategories}
        getValue="id"
        renderLabel="name"
        rawOptionLabel
        placeholder={placeholder}
        multiple
        hideError
        renderValue={() => (
          <Typography
            sx={{
              color: '#AEB3B5',
              fontFamily: 'Roboto',
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: 400,
              letterSpacing: '0.15px',
              lineHeight: 'unset',
            }}
          >
            <FormattedMessage id={placeholder} />
          </Typography>
        )}
      />
      {selectedMedicationCategories && selectedMedicationCategories.length > 0 && (
        <ChipList
          items={selectedMedicationCategories}
          labelKey="name"
          valueKey="id"
          onDelete={(data) =>
            form.setValue(
              type,
              selectedMedicationCategories.filter((ele) => ele.id !== data),
            )
          }
        />
      )}
    </Stack>
  );
};

export default MedicationCategory;
