import { Box, Button, InputLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { PRIMARY } from 'colors';
import { some } from 'modules/common/constants';
import SchemaFormMain from 'modules/common/SchemaForm';
import ProductList, { getTotalPrice } from './ProductList';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { POSITIVE_DECIMAL_NUMBER_REGEX } from 'modules/common/regex';
import { NumericFormatText } from 'modules/common/utils';
import DoctorPrescriptionSpecialistDialog from './DoctorPrescriptionSpecialistDialog';
import { axiosThunk } from 'modules/common/redux/axios';
import { API_SERVER } from 'modules/common/api';
import { PrescriptionSchema, SaleReceipt } from 'modules/schema';
import { useSelector } from 'react-redux';
import { AppState } from 'redux/reducer';
import { SPECIALIST } from 'modules/layout/constants';
import {
  PAYMENT_METHOD,
  PRESCRIPTION_DETAIL_TYPE,
  PRESCRIPTION_TYPE,
  RECEIPT_TYPES,
} from 'modules/common/apiConstants';

export const DISCOUNT_TYPE = [
  { value: 'CASH', label: 'discount.cash' },
  { value: 'PERCENT', label: '%' },
];

interface Props {
  onSubmit: (value: some, method: any) => void;
  receiptDetail?: SaleReceipt;
}

const OrderForm: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, receiptDetail } = props;
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = React.useState<some>();
  const { appState, confirmDialog, intl, dispatch } = useGeneralHook();
  const { currentLocation } = appState.authen;
  const { promptConfirmation, close } = confirmDialog;
  const group_specialization = {
    isDental: useSelector((state: AppState) => state.authen.user?.group_specialization === SPECIALIST.DENTAL),
    isEncounter: useSelector((state: AppState) => state.authen.user?.group_specialization === SPECIALIST.ENCOUNTER),
  };

  const onCancel = React.useCallback(
    async (methods) => {
      const confirm = await promptConfirmation({
        warning: false,
        title: intl.formatMessage({ id: 'common.title.confirm' }),
        content: intl.formatMessage({ id: 'sellingProduct.title.cancel.confirm' }),
        cancelId: 'no',
        okId: 'yes',
      });
      if (confirm) {
        methods.reset({
          paymentMethod: PAYMENT_METHOD.CASH.value,
          product: null,
          groupProduct: null,
          customerName: intl.formatMessage({ id: 'calendar.person' }),
          phoneNumber: null,
          medicalFacility: null,
          doctor: null,
          encounterId: null,
          note: null,
          discount: null,
        });
      }
      close();
    },
    [promptConfirmation, intl, close],
  );

  // add prescription
  const onAddPrescription = React.useCallback(
    async (value: some) => {
      const { data } = await dispatch(
        axiosThunk({
          url: API_SERVER.sale.prescriptionDetail({
            id: value?.id,
            type: PRESCRIPTION_TYPE.ENCOUNTER,
          }).url,
        }),
      );
      const paresedPrescription = PrescriptionSchema.safeParse(data);

      if (paresedPrescription?.success) {
        setFormData({
          paymentMethod: PAYMENT_METHOD.CASH.value,
          referenceId: data?.referenceId,
          referenceType: group_specialization.isDental ? PRESCRIPTION_TYPE.DENTAL_EXAM : PRESCRIPTION_TYPE.ENCOUNTER,
          customerName: value?.name,
          phoneNumber: value?.mobilePhone,
          doctor: value?.picName,
          medicalFacility: currentLocation?.name,
          productList: paresedPrescription.data?.prescriptionDetailList
            ?.filter((item) => item.type !== PRESCRIPTION_DETAIL_TYPE.MEDICATION_EXTERNAL)
            ?.map((item) => {
              return {
                id: item?.medication?.id,
                medicationId: item?.medication?.id,
                name: item?.medication?.name,
                quantity: item?.quantity,
              };
            }),
        });
        setOpen(false);
      }
    },
    [currentLocation?.name, dispatch, group_specialization.isDental],
  );

  // update data duplicate receipt detail
  useEffect(() => {
    if (receiptDetail) {
      setFormData({
        ...receiptDetail,
        paymentMethod: `${receiptDetail?.paymentMethod}`,
        productList: receiptDetail?.productList?.map((item) => {
          return {
            ...item,
            id: item.medicationId,
            name: item.medicationName,
            unit: {
              value: item.unit,
              label: item.unit && intl.formatMessage({ id: item.unit }),
              factor: item.factor,
              unitPrice: item.unitPrice,
            },
          };
        }),
      });
    }
  }, [intl, receiptDetail]);

  return (
    <>
      <Button
        sx={{ width: 'fit-content', mb: 2 }}
        onClick={() => {
          setOpen(true);
        }}
      >
        <FormattedMessage id="sale.pickDoctorPrescription" />
      </Button>
      <DoctorPrescriptionSpecialistDialog
        onClose={() => setOpen(false)}
        open={open}
        onSubmit={onAddPrescription}
        group_specialization={group_specialization}
      />
      <SchemaFormMain
        onSubmit={onSubmit}
        formData={formData}
        schema={{
          fields: ({ formProps: { intl, appState }, valuesField, methods }) => {
            const isDoctorPrescription = valuesField?.encounterId;
            return {
              customerName: {
                type: isDoctorPrescription
                  ? () => {
                      return (
                        <div style={{ height: 53 }}>
                          <InputLabel>
                            <FormattedMessage id="sale.customer" />
                          </InputLabel>
                          <Typography mt={1.5}>{valuesField?.customerName}</Typography>
                        </div>
                      );
                    }
                  : 'text-field',
                label: intl.formatMessage({ id: 'sale.customer' }),
                placeholder: intl.formatMessage({ id: 'sale.customerEnter' }),
                propsWrapper: {
                  xs: 3,
                },
                noHelperText: true,
                defaultValue: intl.formatMessage({ id: 'calendar.person' }),
              },
              phoneNumber: {
                type: isDoctorPrescription
                  ? () => {
                      return (
                        <div style={{ height: 53 }}>
                          <InputLabel>
                            <FormattedMessage id="telephone" />
                          </InputLabel>
                          <Typography mt={1.5}>{valuesField?.phoneNumber}</Typography>
                        </div>
                      );
                    }
                  : 'text-field',
                label: intl.formatMessage({ id: 'telephone' }),
                placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
                propsWrapper: {
                  xs: 3,
                },
                noHelperText: true,
              },
              created: {
                type: () => {
                  return (
                    <div style={{ height: 53 }}>
                      <InputLabel>
                        <FormattedMessage id="sale.created" />
                      </InputLabel>
                      <Typography mt={1.5}>{appState.authen.user?.employee_name}</Typography>
                    </div>
                  );
                },
                unregister: true,
                propsWrapper: {
                  xs: 3,
                },
              },
              type: {
                type: () => {
                  return (
                    <div style={{ height: 53 }}>
                      <InputLabel>
                        <FormattedMessage id="sale.type" />
                      </InputLabel>
                      <Typography mt={1.5} variant="subtitle1" color="success.main">
                        {valuesField?.receiptType === RECEIPT_TYPES.DOCTOR ? (
                          <FormattedMessage id={'sale.doctorPres'} />
                        ) : (
                          <FormattedMessage id={'retail'} />
                        )}
                      </Typography>
                    </div>
                  );
                },
                unregister: true,
                propsWrapper: {
                  xs: 3,
                },
              },
              medicalFacility: {
                type: isDoctorPrescription
                  ? () => {
                      return (
                        <div style={{ height: 53 }}>
                          <InputLabel>
                            <FormattedMessage id="sale.infrastructure" />
                          </InputLabel>
                          <Typography mt={1.5}>{valuesField?.medicalFacility}</Typography>
                        </div>
                      );
                    }
                  : 'text-field',
                label: intl.formatMessage({ id: 'sale.infrastructure' }),
                placeholder: intl.formatMessage({ id: 'sale.infrastructureEnter' }),
                propsWrapper: {
                  xs: 6,
                },
                noHelperText: true,
              },
              doctor: {
                type: isDoctorPrescription
                  ? () => {
                      return (
                        <div style={{ height: 53 }}>
                          <InputLabel>
                            <FormattedMessage id="sale.doctor" />
                          </InputLabel>
                          <Typography mt={1.5}>{valuesField?.doctor}</Typography>
                        </div>
                      );
                    }
                  : 'text-field',
                label: intl.formatMessage({ id: 'sale.doctor' }),
                placeholder: intl.formatMessage({ id: 'sale.doctorEnter' }),
                propsWrapper: {
                  xs: 6,
                },
                noHelperText: true,
              },

              productList: {
                type: 'array',
                valuesField,
                rawComponent: ProductList,
                hideInitialElement: true,
                keyName: 'key',
              },
              note: {
                type: 'text-field',
                label: intl.formatMessage({ id: 'sale.note' }),
                placeholder: intl.formatMessage({ id: 'enter' }),
                rows: 3,
                multiline: true,
              },
              paymentMethod: {
                type: 'select',
                defaultValue: PAYMENT_METHOD.CASH.value,
                placeholder: intl.formatMessage({ id: 'select' }),
                options: Object.values(PAYMENT_METHOD).filter((item) => item.value !== PAYMENT_METHOD.PREPAYMENT.value),
                disableClearBtn: true,
                style: { width: 248 },
                register: { required: true },
                noHelperText: true,
              },
              discount: {
                type: 'text-field',
                inputType: 'number',
                placeholder: intl.formatMessage({ id: 'sale.discount' }),
                propsWrapper: { xs: true },
                style: { width: 140 },
                register: {
                  max: {
                    value: 100,
                    message: intl.formatMessage({ id: 'validation.invalid' }),
                  },
                  pattern: {
                    value: POSITIVE_DECIMAL_NUMBER_REGEX,
                    message: intl.formatMessage({ id: 'validation.invalid' }),
                  },
                },
              },
              percentLabel: {
                type: () => '%',
                propsWrapper: { xs: undefined },
                unregister: true,
              },
              cancel: {
                type: 'button',
                variant: 'outlined',
                label: intl.formatMessage({ id: 'cancelled' }),
                propsWrapper: { xs: undefined },
                onClick: () => {
                  onCancel(methods);
                },
              },
              submit: {
                type: 'submitButton',
                label: intl.formatMessage({ id: 'pay' }),
                propsWrapper: { xs: undefined },
              },
            };
          },
          ui: [
            {
              id: 'main',
              paper: true,
              paperProps: { sx: { backgroundColor: PRIMARY[50], py: 1, px: 2, border: 'none' } },
              fields: ['customerName', 'phoneNumber', 'created', 'type', 'infrastructure', 'medicalFacility', 'doctor'],
            },
            { id: 'productList', fields: ['productList'] },
            { id: 'left', fields: ['doctorNote', 'note'], propsWrapper: { xs: 6 } },
            { id: 'right', fields: ['paymentMethod', 'discount', 'percentLabel'], propsWrapper: { xs: 6 } },
            {
              id: 'submit',
              fields: ['cancel', 'submit'],
              propsGridContainer: { justifyContent: 'flex-end' },
            },
          ],
          layout: ({ listElement, valuesField, fields }) => {
            const totalPrice = valuesField.productList?.reduce((v, c) => v + getTotalPrice(c), 0) || 0;
            const discount = (Number(valuesField.discount || 0) * totalPrice) / 100;
            const finalPrice = totalPrice - discount > 0 ? totalPrice - discount : 0;

            return (
              <>
                {listElement[0]}
                <Box mt={2}>{listElement[1]}</Box>
                <Box display={'flex'} gap={2} mt={2}>
                  {listElement[2]}
                  <Box flex={0.3} />
                  <Box flex={1} gap={1} display="flex" flexDirection={'column'}>
                    <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
                      <Typography variant="subtitle1">
                        <FormattedMessage id="sale.totalPrice" />
                        &nbsp;
                        <Typography variant="body1" component={'span'}>
                          ({valuesField.productList?.length}&nbsp;
                          <FormattedMessage id="sale.products" />)
                        </Typography>
                      </Typography>
                      <Typography variant="subtitle1">{NumericFormatText(totalPrice)}</Typography>
                    </Box>
                    <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
                      <Typography variant="body1">
                        <FormattedMessage id="cashier.paymentMethod" />
                      </Typography>
                      {fields.right.paymentMethod}
                    </Box>
                    <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
                      <Typography variant="body1">
                        <FormattedMessage id="cashier.totalDiscountBill" />
                      </Typography>
                      <Box display={'flex'} gap={1}>
                        {fields.right.discount}
                        {fields.right.percentLabel}
                      </Box>
                    </Box>
                    <Box display={'flex'} justifyContent="space-between" alignItems={'center'}>
                      <Typography variant="subtitle1">
                        <FormattedMessage id="sale.customerPay" />
                      </Typography>
                      <Typography variant="h6">{NumericFormatText(finalPrice)}</Typography>
                    </Box>
                  </Box>
                </Box>
                <Box style={{ marginTop: '15px' }}>{listElement[4]}</Box>
              </>
            );
          },
          changeDataBeforeSubmit: (values) => {
            return {
              ...values,
              productList: values.productList?.map((item) => {
                return {
                  medicationId: item.id,
                  lot: item.lot?.lot,
                  discount: item.discount,
                  discountType: item.discountType || 'PERCENT', //todo dont know why discountType undefined
                  quantity: item.quantity,
                  unit: item.unit?.value,
                  unitPrice: item.unitPrice,
                  vat: item.vat,
                };
              }),
            };
          },
        }}
      />
    </>
  );
};

export default OrderForm;
