import { z } from 'zod';
import { createDateTimeSchema, createObjectKeysEnumSchema } from './common';
import { BE_DATE_TIME_FORMAT } from '../common/constants';
import { SaleReceiptDetailSchema } from './SaleReceiptDetail';
import { UnitConverterSchema } from './UnitConverter';
import { PAYMENT_METHOD } from 'modules/common/apiConstants';

export const SaleReceiptSchema = z
  .object({
    id: z.number(),
    clinicId: z.number(),
    customerName: z.string(),
    code: z.string(),
    phoneNumber: z.string(),
    medicalFacility: z.string(),
    doctor: z.string(),
    totalAmount: z.number(),
    paymentMethod: createObjectKeysEnumSchema(PAYMENT_METHOD),
    discountType: z.string(),
    discount: z.number(),
    amountPaid: z.number(),
    note: z.string(),
    encounterId: z.string(),
    clinicName: z.string(),
    receiptStatus: z.string(),
    cancelReason: z.string(),
    receiptType: z.string(),
    prescriptionDate: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    productList: z.array(SaleReceiptDetailSchema),
    salesBy: z.string(),
    createdTime: createDateTimeSchema(BE_DATE_TIME_FORMAT),
    unitConverterList: z.array(UnitConverterSchema),
    resourceId: z.string(),
    medicationCategory: z.string(),
  })
  .partial();

export type SaleReceipt = z.infer<typeof SaleReceiptSchema>;
