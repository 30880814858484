import React, { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { DEFAULT_CONFIG, FilterContext, FormSchema } from './const';
import { useForm } from 'react-hook-form';
import FilterPopup from './FilterPopup';
import { useDialog } from 'modules/common/hook';
import FilterButton from './FilterButton';
import { FilterType, FormFilter } from './type';
import { formatFilterValue, getDefaultValue, getFilterValue } from './utils';
import FilterView from './FilterView';

interface Props {
  children: ReactNode;
  filters: FilterType[];
  onSave: (filterValue: ReturnType<typeof formatFilterValue>, filter: FormFilter) => void;
  readonly localStorageKey: string;
  popupWidth?: CSSProperties['width'];
}

interface CompoundProps {
  Button: typeof FilterButton;
  View: typeof FilterView;
}

const Filter: React.FC<Props> & CompoundProps = ({ children, filters, onSave, localStorageKey, popupWidth }) => {
  const [openPopup, onOpenPopup, onClosePopup] = useDialog();
  const defaultValues = useMemo(() => getDefaultValue(filters), [filters]);
  const form = useForm<FormFilter>({
    defaultValues,
  });

  useEffect(() => {
    if (localStorageKey) {
      const data = localStorage.getItem(localStorageKey);
      if (data) {
        const parsedData = FormSchema.safeParse(JSON.parse(data));
        if (parsedData.success) {
          form.reset(parsedData.data);
          setFilterValue(getFilterValue(parsedData.data));
          onSave(formatFilterValue(parsedData.data), parsedData.data);
        }
      }
    }
    // Run 1 time
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [filterValue, setFilterValue] = useState<FormFilter | null>(null);

  const onFilterChange = useCallback(
    (filterData: FormFilter) => {
      form.reset(filterData);
      const filterValue = getFilterValue(filterData);
      setFilterValue(filterValue);
      onSave(formatFilterValue(filterValue), filterValue);
      if (localStorageKey) {
        if (filterData.isSaveFilter || filterData.isSaveFilter === null) {
          localStorage.setItem(localStorageKey, JSON.stringify(FormSchema.parse(filterData)));
          localStorage.setItem(`${localStorageKey}_VALUE`, JSON.stringify(FormSchema.parse(filterValue)));
        } else {
          localStorage.removeItem(localStorageKey);
          localStorage.removeItem(`${localStorageKey}_VALUE`);
        }
      }
    },
    [form, localStorageKey, onSave],
  );

  const formatedFilters = useMemo(
    () => filters.map((filter) => Object.assign({}, DEFAULT_CONFIG[filter.type], filter)),
    [filters],
  );

  return (
    <FilterContext.Provider
      value={{
        onOpenPopup,
        form,
        filters: formatedFilters,
        filterValue,
        onFilterChange,
        defaultValues,
      }}
    >
      {children}
      <FilterPopup onClose={onClosePopup} open={openPopup} popupWidth={popupWidth} />
    </FilterContext.Provider>
  );
};

Filter.Button = FilterButton;
Filter.View = FilterView;

export default Filter;
