import { Box, useMediaQuery, useTheme } from '@mui/material';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router';
import { AppState } from '../../redux/reducer';
import Header from './Header';
import {
  ADMIN_ROUTES,
  BASIC_ADMIN_ROUTES,
  CASHIER_ROUTES,
  COMMON_ROUTES,
  EXAM_DOCTOR_ROUTES,
  FRONT_OFFICE_ROUTES,
  GENERAL_DOCTOR_ROUTES,
  IMAGE_DOCTOR_ROUTES,
  LAB_ROUTES,
  NURSE_ROUTES,
  PHARMACIST_ROUTES,
  RECEPTION_ROUTES,
} from './router';
import { flatRoutes, RoutesTabType } from './utils';
import { SPECIALIST } from './constants';
import { GROUP_VERSION } from 'modules/common/apiConstants';
import { ROLES } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

interface IMainPageProps {}

const DefaultLayout: React.FunctionComponent<IMainPageProps> = () => {
  const location = useLocation();
  const [openAside, setOpenAside] = React.useState(true);
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('lg'));
  const user = useSelector((state: AppState) => state.authen.user);
  const { intl } = useGeneralHook();

  const {
    isReceptionist,
    isExamDoctor,
    isAdmin,
    isLabTechnologist,
    isLabDoctor,
    isImageDoctor,
    isImageTechnologist,
    isCashier,
    isPharmacist,
    isNurse,
    isFrontOffice,
    isGeneral,
    isDental,
  } = React.useMemo(() => {
    const hasRole = (roleKey: keyof typeof ROLES) => user?.role?.includes(roleKey);
    return {
      isReceptionist: hasRole(ROLES.RECEPTIONIST.value),
      isExamDoctor: hasRole(ROLES.DOCTOR.value),
      isAdmin: hasRole(ROLES.ADMIN.value),
      isLabTechnologist: hasRole(ROLES.LABORATORY_TECHNOLOGIST.value),
      isLabDoctor: hasRole(ROLES.CLINICAL_PATHOLOGIST.value),
      isCashier: hasRole(ROLES.CASHIER.value),
      isImageDoctor: hasRole(ROLES.RADIOLOGIST.value),
      isImageTechnologist: hasRole(ROLES.RADIOGRAPHER.value),
      isPharmacist: hasRole(ROLES.PHARMACIST.value),
      isNurse: hasRole(ROLES.NURSE.value),
      isFrontOffice: hasRole(ROLES.COORDINATOR.value),
      isGeneral: hasRole(ROLES.GENERAL_DOCTOR.value),
      isDental: user?.group_specialization === SPECIALIST.DENTAL,
    };
  }, [user]);

  const roleKey = useSelector((state: AppState) => state.authen.user?.role?.[0]);
  React.useEffect(() => {
    if (roleKey) {
      const role = intl.formatMessage({ id: ROLES[roleKey].label || ' ' });
      document.title = intl.formatMessage({ id: 'document.title' }, { role });
    }
  }, [intl, roleKey]);

  const listRouter = React.useMemo(() => {
    let routesByRole = FRONT_OFFICE_ROUTES;
    // map routes by role
    const routesByRoleMap = (routes) => {
      if (isDental) {
        routesByRole = routes.map((item) => {
          const subMenuCustom = item?.subMenu?.filter((subMenuItem) => subMenuItem.dental !== false);
          return {
            ...item,
            subMenu: subMenuCustom,
          };
        });
      } else {
        routesByRole = routes.map((item) => {
          const subMenuCustom = item?.subMenu?.filter((subMenuItem) => subMenuItem.dental !== true);
          return {
            ...item,
            subMenu: subMenuCustom,
          };
        });
      }
    };
    if (isReceptionist) {
      routesByRoleMap(RECEPTION_ROUTES);
    }
    if (isExamDoctor) {
      routesByRoleMap(EXAM_DOCTOR_ROUTES);
    }
    if (isAdmin) {
      if (user?.group_version === GROUP_VERSION.BASIC) {
        routesByRoleMap(BASIC_ADMIN_ROUTES);
      } else {
        routesByRoleMap(ADMIN_ROUTES);
      }
    }
    if (isLabDoctor || isLabTechnologist) {
      routesByRoleMap(LAB_ROUTES);
    }
    if (isImageDoctor || isImageTechnologist) {
      routesByRoleMap(IMAGE_DOCTOR_ROUTES);
    }
    if (isCashier) {
      routesByRoleMap(CASHIER_ROUTES);
    }
    if (isPharmacist) {
      routesByRoleMap(PHARMACIST_ROUTES);
    }
    if (isNurse) {
      routesByRoleMap(NURSE_ROUTES);
    }
    if (isFrontOffice) {
      routesByRoleMap(FRONT_OFFICE_ROUTES);
    }
    if (isGeneral) {
      routesByRoleMap(GENERAL_DOCTOR_ROUTES);
    }
    return [...(routesByRole || []), ...COMMON_ROUTES] as RoutesTabType[];
  }, [
    isReceptionist,
    isExamDoctor,
    isAdmin,
    isLabDoctor,
    isLabTechnologist,
    isImageDoctor,
    isImageTechnologist,
    isCashier,
    isPharmacist,
    isNurse,
    isFrontOffice,
    isGeneral,
    isDental,
    user?.group_version,
  ]);

  const flatList = React.useMemo(() => {
    return flatRoutes(listRouter);
  }, [listRouter]);

  const redirect = flatList.find((v) => v.isRedirect);

  React.useEffect(() => {
    setOpenAside(matches);
  }, [matches]);

  return (
    <Box minHeight="100vh" style={{ height: '100%' }} className={'abc'}>
      <Box flexGrow={1} flex={1} display="flex" flexDirection="column" bgcolor="background.default" minHeight="100vh">
        <Header listRouter={listRouter} openAside={openAside} setOpenAside={setOpenAside} />
        <Switch location={location}>
          {flatList
            .filter((ele) => ele.component)
            .map((route: RoutesTabType, index) => (
              <Route
                key={route.title || '' + index}
                exact={route.exact}
                path={route.path}
                component={route.component}
              />
            ))}
          {redirect?.path && <Redirect key={'redirect'} to={redirect.path} />}
        </Switch>
      </Box>
    </Box>
  );
};

export default DefaultLayout;
