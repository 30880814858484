import { Box, Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';

interface Props {
  titleKey: string;
  pagination?: {
    size: number;
    totalElements: number;
  };
}

const PageHeader = ({ titleKey, pagination }: Props) => {
  return (
    <Box>
      <Typography variant="h6">
        <FormattedMessage id={titleKey} />
      </Typography>
      {pagination && (
        <Typography variant="caption">
          <FormattedMessage
            id="foundResult"
            values={{
              num: (
                <Typography variant="inherit" component="span" color="primary">
                  {pagination.size}
                </Typography>
              ),
              total: (
                <Typography variant="inherit" component="span" color="primary">
                  {pagination.totalElements}
                </Typography>
              ),
            }}
          />
        </Typography>
      )}
    </Box>
  );
};

export default PageHeader;
