import DialogCustom from 'modules/common/component/DialogCustom';
import DialogContent from './DialogContent';

interface Props {
  isHospitalTranfer: boolean;
  open: boolean;
  onClose(): void;
}

const DentalPrintForm = ({ isHospitalTranfer = false, open, onClose }: Props) => {
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="dental.dentalDetail.dialog.title"
      PaperProps={{
        style: { width: '1000px' },
      }}
      maxWidth="xl"
    >
      <DialogContent isHospitalTranfer={isHospitalTranfer} />
    </DialogCustom>
  );
};

export default DentalPrintForm;
