import { IconButtonProps, PropTypes, Tooltip, TooltipProps, IconButton as IconButtonRaw } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props extends Omit<IconButtonProps, 'title'> {
  loadingColor?: PropTypes.Color;
  tooltipProps?: TooltipProps;
  title?: React.ReactNode;
  placement?: TooltipProps['placement'];
}

const IconButtonTitle = (props: Props) => {
  const { tooltipProps, children, title, placement, ...rest } = props;
  if ((!title && !tooltipProps?.title) || rest.disabled) {
    return <IconButtonRaw {...rest}>{children}</IconButtonRaw>;
  }
  return (
    <Tooltip
      arrow
      placement={placement || 'top'}
      {...tooltipProps}
      title={typeof title === 'string' ? <FormattedMessage id={title} /> : tooltipProps?.title || ' '}
    >
      <IconButtonRaw {...rest}>{children}</IconButtonRaw>
    </Tooltip>
  );
};
export default IconButtonTitle;
