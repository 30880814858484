import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../../../redux/reducer';
import { API_SERVER } from '../../../../common/api';
import { some } from '../../../../common/constants';
import { axiosThunk } from '../../../../common/redux/axios';

export async function sendToBackend(
  data: some[],
  dispatch: ThunkDispatch<AppState, null, AnyAction>,
  appState: AppState,
  categoryId: number,
) {
  await dispatch(
    axiosThunk({
      url: API_SERVER.service.import(categoryId),
      method: 'post',
      data: { data: data },
    }),
  );
}
