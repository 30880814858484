import * as React from 'react';
import useGeneralHook from '../../../../common/hook/useGeneralHook';
import { HookPaginationProps } from '../../../../common/hook/usePaginationHook';
import SchemaForm from '../../../../common/SchemaForm';
import { setOrderTherapy } from '../../../../common/redux/commonReducer';
import { schemaFilterDentalForm } from './schema';

interface Props {
  pagin?: React.ReactNode;
  onRegister?: () => void;
  hookPagination: HookPaginationProps;
  onDataLoading: boolean;
}

const FilterDentalBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, pagin, onDataLoading, onRegister } = props;
  const { params, setParams, clearParams } = hookPagination;
  const { dispatch, appState } = useGeneralHook();

  return (
    <SchemaForm
      schema={schemaFilterDentalForm}
      onSubmit={(value) => {
        dispatch(setOrderTherapy(value.orderBy));
        setParams(value);
      }}
      formData={params}
      clearParams={clearParams}
      hideSubmitButton
      pagin={pagin}
      onRegister={onRegister}
      onDataLoading={onDataLoading}
      role={appState.authen.user?.role}
    />
  );
};

export default FilterDentalBox;
