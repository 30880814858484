import { FormattedMessage } from 'react-intl';
import { FieldsType, ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import moment from 'moment';
import { GENDER } from 'modules/common/apiConstants';

export const fieldSchema: ISchemaFields = ({ formProps, valuesField }) => {
  const { intl, dataListDoctor } = formProps || {};

  return {
    customerName: {
      type: 'text-field',
      label: <FormattedMessage id="sellingProduct.title.customerName" />,
      placeholder: intl.formatMessage({ id: 'sale.customerEnter' }),
      register: {
        required: true,
      },
      propsWrapper: { xs: 8 },
    },
    gender: {
      type: 'select',
      placeholder: intl.formatMessage({ id: 'pickGender' }),
      label: intl.formatMessage({ id: 'gender' }),
      options: [GENDER.OTHER, GENDER.FEMALE, GENDER.MALE],
      propsWrapper: { xs: 4 },
    },
    phoneNumber: {
      type: 'text-field',
      label: <FormattedMessage id="sellingProduct.title.phoneNumber" />,
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      inputType: 'number',
      register: {
        required: true,
      },
      propsWrapper: { xs: 8 },
    },
    dob: {
      type: 'datePicker',
      label: intl.formatMessage({ id: 'birthdayShort' }),
      propsWrapper: { xs: 4 },
      disableFuture: true,
    },
    bookingDate: {
      type: 'datePicker',
      label: <FormattedMessage id="encounterDate" />,
      placeholder: intl.formatMessage({ id: 'telephoneEnter' }),
      minDate: moment().toDate(),
      register: {
        required: true,
        validate: (value) => {
          return moment(value).isSameOrAfter(moment().startOf('day'))
            ? true
            : intl.formatMessage({ id: 'page.registerMedicalExam.message.datePast' });
        },
      },
    },
    bookingTimeStart: {
      type: 'timePicker',
      label: <FormattedMessage id="therapy.indication.request.printForm.startTime" />,
      placeholder: intl.formatMessage({ id: 'therapy.indication.request.printForm.startTime' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
    bookingTimeEnd: {
      type: 'timePicker',
      label: <FormattedMessage id="encounter.calendar.label.endTime" />,
      placeholder: intl.formatMessage({ id: 'encounter.calendar.label.endTime' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
        validate: (value) => {
          return value
            ? value > valuesField.bookingTimeStart
              ? true
              : intl.formatMessage({ id: 'encounter.calendar.error.bookingTimeEnd' })
            : true;
        },
      },
    },
    picUserId: {
      type: 'select',
      label: intl.formatMessage({ id: 'encounter.encounterDetail.editPIC.newPIC.placeholder' }),
      placeholder: intl.formatMessage({ id: 'examDoctorPick' }),
      options: dataListDoctor,
      rawOptions: true,
    },
    note: {
      type: 'text-field',
      label: <FormattedMessage id="note" />,
      placeholder: intl.formatMessage({ id: 'room.noteEnter' }),
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'contactInfo',
      fields: [
        'customerName',
        'gender',
        'phoneNumber',
        'dob',
        'bookingDate',
        'bookingTimeStart',
        'bookingTimeEnd',
        'picUserId',
        'note',
      ],
    },
    {
      id: 'footer',
      fields: ['cancel', 'save'],
      propsGridContainer: { sx: { justifyContent: 'end' } },
    },
  ] as FieldsType[];
};

const schema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};

export default schema;
