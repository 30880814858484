import * as React from 'react';
import { Service } from 'modules/schema';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { setLoading } from 'modules/common/redux/commonReducer';
import { axiosThunk } from 'modules/common/redux/axios';
import { API_SERVER } from 'modules/common/api';

interface Props {
  revalidate: () => void;
  setFormData: (formData) => void;
}

const usePriceManage = (props: Props) => {
  const { revalidate, setFormData } = props;
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();

  // delete services multiple
  const onDeleteServices = React.useCallback(
    async (services: Service[]) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content:
          services?.length === 1
            ? intl.formatMessage({ id: 'confirmDelete' }, { name: services[0]?.name })
            : intl.formatMessage({ id: 'confirm.delete.multiple' }, { quantity: services?.length }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(
            axiosThunk({
              url: API_SERVER.service.deleteMultiple,
              method: 'delete',
              data: {
                data: services?.map((i) => i.id),
              },
            }),
          );
          revalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
        } catch (e: any) {
          let err = e?.response?.data?.errors;
          if (err) {
            err.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
          } else {
            openSnackbar({ message: intl.formatMessage({ id: 'deleteFail' }), type: 'error' });
          }
        } finally {
          dispatch(setLoading(false));
          setFormData(undefined);
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, intl, dispatch, revalidate, openSnackbar, setFormData],
  );

  return {
    onDeleteServices,
  };
};

export default usePriceManage;
