import { ExpandLess, GridOn, Search } from '@mui/icons-material';
import { Box, Collapse, InputAdornment } from '@mui/material';
import moment from 'moment';
import * as React from 'react';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import SchemaForm from '../../../common/SchemaForm';
import { axiosThunk } from '../../../common/redux/axios';
import fileDownload from 'js-file-download';
import useGeneralHook from '../../../common/hook/useGeneralHook';

interface Props {
  pagin?: React.ReactNode;
  hookPagination: HookPaginationProps;
}

const FilterBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, pagin } = props;
  const { params, setParams, clearParams } = hookPagination;
  const { dispatch, API_SERVER, openSnackbar, intl } = useGeneralHook();

  const exportExcelReport = async (valueFields) => {
    openSnackbar({ message: intl.formatMessage({ id: 'common.message.processing' }) });
    try {
      const response = await dispatch(
        axiosThunk({
          url: API_SERVER.inventory.exportReport(params),
          method: 'get',
          responseType: 'blob',
        }),
      );
      let fileName = response.headers['content-disposition']?.split('filename=')[1]?.split(';')[0];
      const effectiveFileName = fileName || 'inventory_export_report.xlsx';
      fileDownload(response.data, effectiveFileName);
    } catch (error) {
      openSnackbar({ message: intl.formatMessage({ id: 'report.downloadFail' }), type: 'error' });
    }
  };

  return (
    <SchemaForm
      schema={{
        fields: ({ formProps: { intl, clearParams, state, setState }, valuesField }) => {
          return {
            name: {
              type: 'text-field',
              placeholder: intl.formatMessage({ id: 'inventory.search' }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="inherit" />
                  </InputAdornment>
                ),
              },
              showClearBtn: true,
              propsWrapper: { xs: true },
            },
            search: {
              type: 'submitButton',
              color: 'inherit',
              label: intl.formatMessage({ id: 'search' }),
              propsWrapper: { xs: undefined },
            },
            export: {
              type: 'button',
              label: intl.formatMessage({ id: 'revenueReport.exportExcel' }),
              propsWrapper: { xs: undefined },
              startIcon: <GridOn />,
              onClick: () => {
                setParams(valuesField);
                exportExcelReport(valuesField);
              },
            },
            expand: {
              type: 'button',
              endIcon: (
                <ExpandLess
                  style={{
                    transition: '0.2s all',
                    transform: state?.expand ? 'rotate(0deg)' : 'rotate(180deg)',
                  }}
                />
              ),
              color: 'primary',
              variant: 'text',
              onClick: () => setState({ expand: !state.expand }),
              label: intl.formatMessage({ id: 'advance' }),
              propsWrapper: { xs: undefined },
            },
            reset: {
              type: 'button',
              onClick: () => clearParams && clearParams(),
              color: 'inherit',
              label: intl.formatMessage({ id: 'reset' }),
              propsWrapper: { xs: undefined },
              style: { minWidth: 120, marginTop: 20 },
            },
            status: {
              type: 'select',
              label: intl.formatMessage({ id: 'inventory' }),
              placeholder: intl.formatMessage({ id: 'select' }),
              defaultValue: 'STOCKING',
              options: [
                {
                  value: 'STOCKING',
                  label: 'inventory.remainingProduct',
                },
                {
                  value: 'OUT_OF_STOCK',
                  label: 'inventory.emptyProduct',
                },
              ],
              propsWrapper: { xs: true },
            },
            lot: {
              type: 'text-field',
              label: intl.formatMessage({ id: 'inventory.lot' }),
              placeholder: intl.formatMessage({ id: 'inventory.lotEnter' }),
              propsWrapper: { xs: true },
            },
            expiredDateFrom: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'expiredDateFrom' }),
              propsWrapper: { xs: true, paddingBottom: 1 },
              maxDate: valuesField.expiredDateTo && moment(valuesField.expiredDateTo).toDate(),
              register: {
                validate: (value) => {
                  return value && moment(value).isAfter(valuesField?.expiredDateTo)
                    ? intl.formatMessage({ id: 'validation.invalidDate' })
                    : true;
                },
              },
            },
            expiredDateTo: {
              type: 'datePicker',
              label: intl.formatMessage({ id: 'expiredDateTo' }),
              propsWrapper: { xs: true, paddingBottom: 1 },
              minDate: valuesField.expiredDateFrom && moment(valuesField.expiredDateFrom).toDate(),
              register: {
                validate: (value) => {
                  return value && moment(value).isBefore(valuesField?.expiredDateFrom)
                    ? intl.formatMessage({ id: 'validation.invalidDate' })
                    : true;
                },
              },
            },
          };
        },
        ui: () => {
          return [
            {
              id: 'layout',
              propsGridContainer: { spacing: 1, justifyContent: 'flex-end' },
              fields: ['name', 'search', 'expand', 'create', 'export'],
            },
            {
              id: 'expand',
              fields: ['status', 'lot', 'expiredDateFrom', 'expiredDateTo', 'reset'],
            },
          ];
        },
        layout: ({ formProps: { state }, listElement }) => {
          return (
            <>
              <Box display="flex" justifyContent={'space-between'}>
                <Box>{pagin}</Box>
                <Box width={750}>{listElement[0]}</Box>
              </Box>
              <Collapse in={state?.expand} style={{ marginTop: 16 }}>
                {listElement[1]}
              </Collapse>
            </>
          );
        },
      }}
      onSubmit={setParams}
      formData={params}
      clearParams={clearParams}
      hideSubmitButton
    />
  );
};

export default FilterBox;
