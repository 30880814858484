import { ISchemaFields, ISchemaForm, IUiFields } from 'modules/common/SchemaForm/utils';
import { ImportIcon } from 'svg';

export const fieldSchema: ISchemaFields = ({ formProps }) => {
  const { intl, onDownLoadFile } = formProps || {};
  return {
    download: {
      type: 'button',
      label: intl.formatMessage({ id: 'inventory.label.download' }),
      startIcon: <ImportIcon />,
      onClick: () => onDownLoadFile(),
      color: 'inherit',
      propsWrapper: { xs: undefined },
    },
  };
};

const ui: IUiFields = () => {
  return [
    {
      id: 'layout',
      propsGridContainer: { spacing: 2 },
      fields: ['download'],
    },
  ];
};

export const importFileDialogSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
