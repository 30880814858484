import { Box, Stack, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { QuestionMarkCircle } from '../../../../../../svg';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { some } from '../../../../../common/constants';
import SchemaElement from '../../../../../common/SchemaForm/SchemaElement';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { useFormContext } from 'react-hook-form';

const QuestionStyled = styled(Typography)({
  color: '#263238',
  fontFeatureSettings: "'clig' off, 'liga' off",
  fontFamily: 'Roboto',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 400,
  lineHeight: '18px',
  letterSpacing: '0.25px',
});

interface Props {
  onCancelButtonClick: () => void;
  onSaveButtonClick: (data: some) => void;
  nextSession: some;
  picOptions: some[];
}

const DialogContent = ({ onCancelButtonClick, onSaveButtonClick, nextSession, picOptions }: Props) => {
  const { getValues } = useFormContext();

  const { intl } = useGeneralHook();
  return (
    <Stack direction="row" justifyContent="space-between" padding="23px 16px 10px 16px" gap={2}>
      <QuestionMarkCircle />
      <Box>
        <QuestionStyled>
          <FormattedMessage id="therapy.therapyDetail.editNextSession.dialog.question1.title" />
          &nbsp;
          <Typography component="span" color="success.main" fontWeight={600}>
            <FormattedMessage id="therapySession.management.status.finished" />
          </Typography>
        </QuestionStyled>

        <QuestionStyled sx={{ marginTop: '14px' }}>
          <FormattedMessage id="therapy.therapyDetail.editNextSession.dialog.question2.title" />
          &nbsp;
          <Typography component="span" fontWeight={500}>
            {nextSession?.name}
          </Typography>
        </QuestionStyled>
        <Box paddingTop={1}>
          <SchemaElement
            fieldName="nextTherapySessionTime"
            propsElement={{
              type: 'section',
              schema: {
                fields: ({ methods }) => {
                  return {
                    time: {
                      type: 'timePicker',
                      propsWrapper: { xs: 5 },
                      label: intl.formatMessage({ id: 'therapy.therapyDetail.nextSession.setTimeNextSession.title' }),
                    },
                    date: {
                      type: 'datePicker',
                      minDate: new Date(),
                      propsWrapper: { xs: 5, sx: { marginTop: '22px' } },
                      InputProps: { readOnly: true },
                    },
                    picUserId: {
                      type: 'select',
                      label: intl.formatMessage({ id: 'therapy.therapyDetail.nextSession.setNextPic.title' }),
                      placeholder: intl.formatMessage({ id: 'therapyDetail.generalInfo.form.title.pic' }),
                      options: picOptions,
                      rawOptions: true,
                      propsWrapper: { xs: 10 },
                      onChange: (value) => {
                        const pic = value ? picOptions?.find((ele) => ele.value === value) : null;
                        methods.setValue('nextTherapySessionTime.picName', pic ? pic.label : null);
                        methods.setValue('nextTherapySessionTime.picResourceId', pic ? pic?.resourceId : null);
                      },
                    },
                    picName: {
                      type: 'hidden',
                    },
                    picResourceId: {
                      type: 'hidden',
                    },
                    cancel: {
                      type: 'button',
                      variant: 'outlined',
                      label: intl.formatMessage({ id: 'therapy.therapyDetail.editNextSession.dialog.cancel.title' }),
                      propsWrapper: { xs: 3 },
                      fullWidth: true,
                      onClick: onCancelButtonClick,
                    },
                    save: {
                      type: 'button',
                      label: intl.formatMessage({ id: 'therapy.therapyDetail.editNextSession.dialog.save.title' }),
                      propsWrapper: { xs: 3 },
                      fullWidth: true,
                      onClick: () => onSaveButtonClick(getValues()),
                    },
                  };
                },
                ui: [
                  {
                    id: 'input',
                    fields: ['time', 'date', 'picUserId'],
                  },
                  {
                    id: 'button',
                    fields: ['cancel', 'save'],
                    propsGridContainer: { sx: { justifyContent: 'flex-end' } },
                  },
                ],
              },
              propsWrapper: {
                sx: {
                  '.MuiPaper-root': {
                    boxShadow: 'none',
                    padding: '0',
                  },
                },
              },
            }}
          />
        </Box>
      </Box>
    </Stack>
  );
};

export default DialogContent;
