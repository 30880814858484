import { Box, Button, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import MediCard from 'modules/common/component/MediCard';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { some } from 'modules/common/constants';
import { NumericFormatText } from 'modules/common/utils';

interface Props {
  data?: some;
  setFormData: (item: some) => void;
  setFormDataGroup: (item: some) => void;
  onDelete: (item: some) => void;
  onDeleteGroup: (item: some) => void;
  loading?: boolean;
  enableDeleteGroup?: boolean;
}

const ImageIndicationCSVTable: React.FunctionComponent<Props> = (props) => {
  const { data, setFormData, setFormDataGroup, onDelete, onDeleteGroup, enableDeleteGroup, loading } = props;

  const columns = React.useMemo(() => {
    return [
      {
        title: 'serviceCode',
        dataIndex: 'code',
      },
      {
        title: 'serviceName',
        dataIndex: 'name',
        render: (record) => <Typography color={record?.name?.length > 255 ? 'red' : ''}>{record?.name}</Typography>,
        width: '20%',
      },
      {
        title: 'departmentCode',
        dataIndex: 'specializedDepartmentId',
      },
      {
        title: 'indication.room',
        dataIndex: 'physicsRoomId',
      },

      {
        title: 'indication.device',
        dataIndex: 'medicalEquipmentId',
      },
      {
        title: 'price',
        align: 'right',
        dataIndex: 'price',
        render: (record) => record.price && NumericFormatText(record.price),
      },
      {
        title: 'action',
        align: 'right',
        width: 120,
        render: (record) => {
          return (
            <>
              <IconButtonTitle
                title="update"
                size="small"
                onClick={() => {
                  setFormData({ ...record, listId: data?.id });
                }}
                style={{ margin: '0px 8px' }}
              >
                <EditIcon color={record.validation === false ? 'error' : undefined} />
              </IconButtonTitle>
              <IconButtonTitle
                title="delete"
                size="small"
                onClick={() => {
                  onDelete({ ...record, listId: data?.id });
                }}
              >
                <DeleteIcon />
              </IconButtonTitle>
            </>
          );
        },
      },
    ] as Columns[];
  }, [setFormData, data?.id, onDelete]);

  if (!data) {
    return null;
  }

  return (
    <MediCard
      style={{ margin: '8px 0px', color: 'white' }}
      title={
        <Box display="flex" alignItems="center">
          <Box flex={1}>
            <Typography variant="subtitle1" color="inherit">
              {data.name}
            </Typography>
          </Box>
          <Box display="flex">
            <IconButtonTitle
              title="create"
              size="small"
              color="inherit"
              onClick={() =>
                setFormData({
                  listId: data.id,
                  listData: data.services,
                  allItems: data.allItems,
                })
              }
            >
              <NoteAddIcon />
            </IconButtonTitle>
            <IconButtonTitle
              title="update"
              size="small"
              color="inherit"
              onClick={() => setFormDataGroup(data)}
              style={{ marginRight: 8, marginLeft: 8 }}
            >
              <EditIcon />
            </IconButtonTitle>
            <IconButtonTitle
              title="delete"
              size="small"
              color="inherit"
              disabled={!!data.services?.length && !enableDeleteGroup}
              onClick={() => {
                onDeleteGroup(data);
              }}
            >
              <DeleteIcon />
            </IconButtonTitle>
          </Box>
        </Box>
      }
    >
      <Box padding={1}>
        <TableCustom
          dataSource={data?.services}
          loading={loading}
          columns={columns}
          caption={
            <Button
              size="large"
              fullWidth
              variant="text"
              startIcon={<NoteAddIcon />}
              color="primary"
              onClick={() =>
                setFormData({
                  listId: data.id,
                  listData: data.services,
                  allItems: data.allItems,
                })
              }
            >
              <Typography variant="subtitle2">
                <FormattedMessage id="imageIndication.addNewInList" />
              </Typography>
            </Button>
          }
        />
      </Box>
    </MediCard>
  );
};

export default ImageIndicationCSVTable;
