import { Box, BoxProps, Skeleton } from '@mui/material';
import * as React from 'react';
import { API_SERVER } from '../api';
import useSWR from 'swr';
import { ThunkDispatch } from 'redux-thunk';
import { AppState } from '../../../redux/reducer';
import { axiosThunk } from '../redux/axios';
import { AnyAction } from 'redux';
import { useDispatch } from 'react-redux';

interface IAuthImageProps extends BoxProps {
  src?: string;
  alt?: string;
}

const LogoImage: React.FunctionComponent<IAuthImageProps> = (props) => {
  const { src } = props;
  const dispatch: ThunkDispatch<AppState, null, AnyAction> = useDispatch();

  const { data } = useSWR(
    src ? API_SERVER.s3Resource.download(src) : null,
    async (url: string) => {
      const blob = await dispatch(axiosThunk({ url, responseType: 'blob' }));
      return blob?.data;
    },
    { dedupingInterval: 2000, revalidateOnFocus: false },
  );

  const urlObj = React.useMemo(() => {
    if (data) {
      return URL.createObjectURL(data as any);
    } else {
      return null;
    }
  }, [data]);

  React.useEffect(() => {
    return () => {
      if (urlObj) {
        URL.revokeObjectURL(urlObj);
      }
    };
  }, [urlObj]);

  return !urlObj ? (
    <Skeleton
      variant="rectangular"
      style={{
        ...props.style,
      }}
    />
  ) : (
    <Box component={'img'} {...props} alt={props.alt || 'img'} src={urlObj} />
  );
};

export default LogoImage;
