import { Box, DialogTitle, IconButton, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';

interface Props {
  close(): void;
  mode?: string;
}

const NewDentalExamWizardTitle: React.FunctionComponent<Props> = ({ close, mode }) => {
  return (
    <DialogTitle sx={{ bgcolor: 'primary.50' }} style={{ position: 'sticky', top: 0, padding: 16, zIndex: 2 }}>
      <>
        <Box>
          <Typography variant="h6" color="primary">
            <FormattedMessage id={mode === 'edit' ? 'encounterList.editEncounter' : 'encounterList.addEncounter'} />
          </Typography>
        </Box>
        <Box marginTop={1} lineHeight="16px">
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="dental.label.register.subTitle" />
          </Typography>
        </Box>
      </>
      <Box position="absolute" top={6} right={0} marginX={2} marginY={1}>
        <IconButton onClick={close} size="small">
          <Close fontSize="small" />
        </IconButton>
      </Box>
    </DialogTitle>
  );
};

export default NewDentalExamWizardTitle;
