import { Add, Search } from '@mui/icons-material';
import { InputAdornment } from '@mui/material';
import * as React from 'react';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import SchemaForm from '../../../common/SchemaForm';

interface Props {
  hookPagination: HookPaginationProps;
  onAddNew: () => void;
}

const FilterSupplierBox: React.FunctionComponent<Props> = (props) => {
  const { hookPagination, onAddNew } = props;
  const { params, setParams } = hookPagination;
  return (
    <SchemaForm
      onSubmit={setParams}
      formData={params}
      hideSubmitButton
      formProps={{
        style: {
          flex: 1,
        },
      }}
      schema={{
        fields: ({ formProps }) => {
          const { intl } = formProps || {};
          return {
            search: {
              type: 'text-field',
              placeholder: intl.formatMessage({ id: 'supplier.search' }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <Search color="inherit" />
                  </InputAdornment>
                ),
              },
              showClearBtn: true,
              noHelperText: true,
              propsWrapper: { xs: true },
            },
            btn: {
              type: 'submitButton',
              color: 'inherit',
              label: intl.formatMessage({ id: 'search' }),
              propsWrapper: { xs: undefined },
            },
            add: {
              type: 'button',
              startIcon: <Add />,
              onClick: onAddNew,
              color: 'primary',
              label: intl.formatMessage({ id: 'supplier.create' }),
              propsWrapper: { xs: undefined },
            },
          };
        },
      }}
    />
  );
};

export default FilterSupplierBox;
