import { Box, Button, Paper } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';
import { API_SERVER } from 'modules/common/api';
import useSWR from 'swr';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { fetchThunk } from 'modules/common/redux/thunk';
import { ROLES, some } from 'modules/common/constants';
import { isEmpty } from 'lodash';
import NoDataBox from 'modules/common/component/NoDataBox';
import 'modules/examDoctor/component/therapy/style.scss';
import TherapyPatientInfo from 'modules/examDoctor/component/therapy/TherapyPatientInfo';
import DialogCustom from 'modules/common/component/DialogCustom';
import SchemaForm from 'modules/common/SchemaForm';
import { AxiosError } from 'axios';
import '../component/therapy/style.scss';
import { axiosThunk } from 'modules/common/redux/axios';
import * as React from 'react';
import TherapySessionWrapper from 'modules/examDoctor/component/therapy/TherapySessionDetail/TherapySessionWrapper';
import { setLoading } from 'modules/common/redux/commonReducer';
import 'modules/examDoctor/component/therapy/style.scss';
import { useCreateMutate, useDialog, useUserRoles } from 'modules/common/hook';
import { ROLE_TYPE } from 'modules/common/apiConstants';

interface Props {}

const TherapyDetail: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, intl, confirmDialog, appState } = useGeneralHook();
  const roles = appState.authen.user?.role || [];
  const roleAdmin = roles.indexOf(ROLE_TYPE.ADMIN.value) > -1;
  const { therapyId } = useParams<{ therapyId }>();
  const [openAddDialog, onOpenAddDialog, onCloseAddDialog] = useDialog();
  const [open, setOpen] = React.useState(false);
  const [openReason, setOpenReason] = React.useState(false);
  const [rowValue, setRowValue] = React.useState<some>();

  // get data therapy detail
  const {
    data: dataTherapyDetail,
    revalidate,
    mutate: therapyMutate,
  } = useSWR(
    API_SERVER.therapy.detail(therapyId).url,
    async (url) => {
      return dispatch(fetchThunk(url, 'get'));
    },
    { revalidateOnMount: true, revalidateOnFocus: false },
  );

  const nurseAndDoctors = useUserRoles(
    ROLES.DOCTOR.value,
    ROLES.GENERAL_DOCTOR.value,
    ROLES.NURSE.value,
    ROLES.RADIOLOGIST.value,
    ROLES.LABORATORY_TECHNOLOGIST.value,
    ROLES.RADIOGRAPHER.value,
    ROLES.CLINICAL_PATHOLOGIST.value,
  );

  const picOptions = useMemo(
    () =>
      nurseAndDoctors?.map((ele) => ({ value: ele?.id, label: ele?.employeeFullName, resourceId: ele?.resourceId })) ||
      [],
    [nurseAndDoctors],
  );

  const handlerSaveTherapySession = useCallback(
    async (therapySession: some) =>
      therapyMutate(
        {
          ...dataTherapyDetail,
          therapySessionList: dataTherapyDetail?.therapySessionList?.map((ele) => {
            if (therapySession?.id === ele?.id) {
              return { ...therapySession, open: true };
            }
            return ele;
          }),
        },
        false,
      ),

    [dataTherapyDetail, therapyMutate],
  );

  // handle submit create therapy session
  const createTherapySessionMutate = useCreateMutate({
    onSuccess: () => {
      revalidate();
      onCloseAddDialog();
    },
    globalLoading: true,
  });
  const onSubmit = useCallback(
    async (value: some) => {
      await createTherapySessionMutate({
        url: API_SERVER.therapySession.create(),
        method: 'post',
        data: {
          name: value.name || undefined,
          therapyId: parseInt(therapyId),
        },
      });
    },
    [createTherapySessionMutate, therapyId],
  );

  // delete therapy session
  const handleDeleteTherapySession = useCallback(
    async (therapySession) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'therapy.therapyDetail.deleteAction.confirmMessage.therapySession' }),
        cancelId: 'no',
        okId: 'yes',
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.therapySession.delete(therapySession?.id), method: 'delete' }));
          openSnackbar({ message: intl.formatMessage({ id: 'deleteSuccess' }) });
          await revalidate();
        } catch (e: any) {
          openSnackbar({
            message: e?.response?.data?.errors[0]?.message || intl.formatMessage({ id: 'deleteFail' }),
            type: 'error',
          });
        } finally {
          dispatch(setLoading(false));
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, revalidate],
  );

  // finish therapy
  const handleFinishTherapy = async () => {
    const confirm = await confirmDialog.promptConfirmation({
      title: intl.formatMessage({ id: 'confirmCompleteTitle' }),
      content: intl.formatMessage({ id: 'confirmComplete' }, { name: dataTherapyDetail?.name }),
      okId: intl.formatMessage({ id: 'serviceRequest.completed' }),
    });
    if (confirm) {
      try {
        dispatch(setLoading(true));
        await dispatch(axiosThunk({ url: API_SERVER.therapy.finish(therapyId), method: 'put' }));
        openSnackbar({ message: intl.formatMessage({ id: 'therapy.detail.label.done' }) });
        await revalidate();
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'therapy.detail.label.fail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    }
    confirmDialog.close();
  };

  const handleCancelTherapy = () => {
    setRowValue(dataTherapyDetail);
    setOpen(true);
  };

  // handle submit reason cancel therapy
  const onCancelTherapy = React.useCallback(
    async (value: some) => {
      await dispatch(
        axiosThunk({
          url: API_SERVER.therapy.cancel(rowValue?.id),
          method: 'put',
          data: {
            data: value.reason || undefined,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: 'encounterInfo.cancelSuccess' }) });
          revalidate();
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors.forEach((v) => {
              openSnackbar({ message: v.message, type: 'error' });
            });
        });
      setOpen(false);
      revalidate();
    },
    [dispatch, intl, openSnackbar, revalidate, rowValue?.id],
  );

  return (
    <>
      <Paper className="therapy-detail">
        {/* thông tin bệnh nhân */}
        <TherapyPatientInfo
          dataTherapyDetail={dataTherapyDetail}
          handleFinishTherapy={handleFinishTherapy}
          handleCancelTherapy={handleCancelTherapy}
        />
        {!isEmpty(dataTherapyDetail?.therapySessionList) ? (
          dataTherapyDetail?.therapySessionList
            ?.filter((ele) => ele?.status !== 'CANCELLED')
            ?.sort((a, b) => a?.orderNumber || 0 - b?.orderNumber || 0)
            ?.map((item: some, index: number) => {
              return (
                <TherapySessionWrapper
                  key={item?.id}
                  therapySession={item}
                  therapy={dataTherapyDetail}
                  handlerSaveTherapySession={handlerSaveTherapySession}
                  picOptions={picOptions}
                  handleDeleteTherapySession={handleDeleteTherapySession}
                  roleAdmin={roleAdmin}
                />
              );
            })
        ) : (
          <NoDataBox />
        )}
        {dataTherapyDetail?.status !== 'CANCELLED' && !roleAdmin && (
          <Button
            variant="contained"
            color="inherit"
            style={{ height: 40, marginTop: '10px' }}
            onClick={onOpenAddDialog}
          >
            <FormattedMessage id="therapy.addSession" />
          </Button>
        )}
        <DialogCustom
          open={openAddDialog}
          onClose={onCloseAddDialog}
          PaperProps={{
            style: { minWidth: 480 },
          }}
          title={'therapy.addSession'}
        >
          <Box padding={2}>
            <SchemaForm
              onSubmit={onSubmit}
              hideSubmitButton
              initialData={{
                status: 'complete',
              }}
              schema={{
                fields: ({ formProps: { intl }, valuesField }) => {
                  return {
                    name: {
                      type: 'text-field',
                      label: intl.formatMessage({ id: 'therapy.sessionName' }),
                      placeholder: intl.formatMessage({ id: 'therapy.session.new' }),
                      multiline: true,
                      rows: 1,
                      noHelperText: true,
                      register: {
                        required: true,
                      },
                    },
                    cancel: {
                      type: 'button',
                      label: intl.formatMessage({ id: 'cancel' }),
                      variant: 'outlined',
                      style: { width: 100 },
                      propsWrapper: { xs: undefined },
                      onClick: () => onCloseAddDialog(),
                    },
                    save: {
                      type: 'submitButton',
                      label: intl.formatMessage({ id: 'save' }),
                      style: { width: 100 },
                      propsWrapper: { xs: undefined },
                    },
                  };
                },
                ui: () => {
                  return [
                    {
                      id: 'default',
                      fields: ['name'],
                    },
                    {
                      id: 'footer',
                      propsGridContainer: { justifyContent: 'flex-end' },
                      fields: ['cancel', 'save'],
                    },
                  ];
                },
              }}
            />
          </Box>
        </DialogCustom>
      </Paper>
      <DialogCustom
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: { minWidth: 480 },
        }}
        title={'encounterInfo.cancelTherapy'}
      >
        <Box padding={2}>
          <SchemaForm
            onSubmit={onCancelTherapy}
            hideSubmitButton
            initialData={{
              status: 'complete',
            }}
            schema={{
              fields: ({ formProps: { intl }, valuesField }) => {
                return {
                  reason: {
                    type: 'text-field',
                    label: intl.formatMessage({ id: 'encounterDetail.inCompleteReason' }),
                    multiline: true,
                    rows: 3,
                    noHelperText: true,
                    register: {
                      required: true,
                    },
                  },
                  cancel: {
                    type: 'button',
                    label: intl.formatMessage({ id: 'cancel' }),
                    variant: 'outlined',
                    style: { width: 100 },
                    propsWrapper: { xs: undefined },
                    onClick: () => {
                      setOpen(false);
                    },
                  },
                  save: {
                    type: 'submitButton',
                    label: intl.formatMessage({ id: 'save' }),
                    style: { width: 100 },
                    propsWrapper: { xs: undefined },
                  },
                };
              },
              ui: () => {
                return [
                  {
                    id: 'default',
                    fields: ['reason'],
                  },
                  {
                    id: 'footer',
                    propsGridContainer: { justifyContent: 'flex-end' },
                    fields: ['cancel', 'save'],
                  },
                ];
              },
            }}
          />
        </Box>
      </DialogCustom>
      <DialogCustom
        open={openReason}
        onClose={() => setOpenReason(false)}
        PaperProps={{
          style: { minWidth: 480 },
        }}
        title={'cancelReason'}
      >
        <Box padding={2}>{rowValue?.note}</Box>
      </DialogCustom>
    </>
  );
};

export default TherapyDetail;
