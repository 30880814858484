import { Stack, Switch, Typography } from '@mui/material';
import TableCustom, { Columns } from 'modules/common/component/TableCustom';
import { FE_DATE_FORMAT, INDICATION_REQUEST_STATUS, some, SYSTEM_CATEGORY_SCOPE_LABEL } from 'modules/common/constants';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { IndicationRequest } from 'modules/schema';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';
import DeleteIcon from '@mui/icons-material/Delete';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import { API_SERVER } from 'modules/common/api';
import { mutate } from 'swr';
import { useFetchIndicationRequest } from '../../common/fetcher';
import * as React from 'react';
import { useCallback, useMemo } from 'react';
import { MedicineBold } from 'svg';
import { NumericFormatText } from 'modules/common/utils';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { AxiosError } from 'axios';
import { useDeleteMutate, useUpdateMutate } from 'modules/common/hook/useMutate';
import { useIndicationNoteSelectors } from './state';
import IndicationNoteDialog from './IndicationNoteDialog';
import LabResultDialog from 'modules/common/component/IndicationViewResult/LabResultDialog';
import ImageResultDialog from 'modules/common/component/IndicationViewResult/ImageResultDialog';
import { axiosThunk } from 'modules/common/redux/axios';
import { setLoading } from 'modules/common/redux/commonReducer';
import ProcedureDetailDialog from 'modules/common/component/IndicationViewResult/ProcedureDetailDialog';
import DescriptionSharpIcon from '@mui/icons-material/DescriptionSharp';
import InsertDriveFileSharpIcon from '@mui/icons-material/InsertDriveFileSharp';
import { fetchThunk } from '../../../../../../common/redux/thunk';
import { useDentalDetailStateSelector } from '../../state';
import { SYSTEM_CATEGORY_SCOPE } from 'modules/common/apiConstants';

const IndicationsTable = () => {
  const { dentalSessionId } = useParams<{ dentalSessionId }>();
  const { intl, dispatch, openSnackbar } = useGeneralHook();
  const { data: indicationRequests, revalidate } = useFetchIndicationRequest(dentalSessionId);
  const indicationRequestData = useMemo(
    () => indicationRequests?.filter((ele) => ele.status !== INDICATION_REQUEST_STATUS.DELETED.value) || [],
    [indicationRequests],
  );
  const onOpenIndicationNoteDialog = useIndicationNoteSelectors.use.onOpenDialog();
  const readOnly = useDentalDetailStateSelector.use.readonly();

  const revalidateIndications = useCallback(
    () => mutate(API_SERVER.indicationRequest.getIndicationRequestByReferenceId(dentalSessionId, 'DENTAL_EXAM').url),
    [dentalSessionId],
  );
  const [labResultIndication, setLabResultIndication] = React.useState<some>();
  const [imageResultIndication, setImageResultIndication] = React.useState<some>();
  const [procedureIndication, setProcedureIndication] = React.useState<some>();

  const onCompleteIndicationMutate = useUpdateMutate({
    confirmMessage: 'dental.dentalDetail.indicationTable.completeIndication.message',
    onSuccess: revalidateIndications,
  });

  const onRequestIndicationMutate = useUpdateMutate({
    confirmMessage: 'dental.dentalDetail.indicationTable.completeIndication.message',
    onSuccess: revalidateIndications,
  });

  const onDeleteIndicationMutate = useDeleteMutate({
    confirmMessage: 'dental.dentalDetail.indicationTable.deleteIndication.message',
    onSuccess: revalidateIndications,
  });

  const onSubmitIndicationProcedure = React.useCallback(
    async (indicationProcedure: some) => {
      await dispatch(
        axiosThunk({
          url: API_SERVER.indicationProcedures.update,
          method: 'put',
          data: {
            ...indicationProcedure,
          },
        }),
      )
        .then((e) => {
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        })
        .catch((e: AxiosError<some>) => {
          if (e.response)
            e.response?.data?.errors?.forEach((v) => {
              openSnackbar({
                message: v.message,
                type: 'error',
              });
            });
        })
        .finally(() => {
          revalidate();
          dispatch(setLoading(false));
          setProcedureIndication(undefined);
        });
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  const onChangeIsFree = React.useCallback(
    async (indication: some) => {
      try {
        await dispatch(
          fetchThunk(
            API_SERVER.indicationRequest.updateIndicationRequest(indication?.id),
            'put',
            indication,
            'application/json-patch+json',
          ),
        );
        await revalidateIndications();

        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({
          message: intl.formatMessage({ id: 'updateFail' }),
          type: 'error',
        });
      }
    },
    [dispatch, revalidateIndications, intl, openSnackbar],
  );

  const COLUMNS: Columns<IndicationRequest>[] = [
    {
      title: 'dental.dentalDetail.indicationTable.service.label',
      render: (record) => {
        return (
          <Typography
            sx={{
              fontStyle: 'normal',
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '24px',
              letterSpacing: '0.5px',
              textDecoration: record.scope !== SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value ? 'underline' : 'none',
              cursor: record.scope !== SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value ? 'pointer' : 'auto',
            }}
            color={record.scope !== SYSTEM_CATEGORY_SCOPE.OTHER_SERVICE.value ? 'primary' : ''}
            onClick={() => {
              if (!readOnly) {
                if (record.scope === SYSTEM_CATEGORY_SCOPE.LABORATORY_SERVICE.value) {
                  setLabResultIndication(record);
                } else if (record.scope === SYSTEM_CATEGORY_SCOPE.RADIOLOGY_SERVICE.value) {
                  setImageResultIndication(record);
                } else if (record.scope === SYSTEM_CATEGORY_SCOPE.SURGICAL_SERVICE.value) {
                  setProcedureIndication(record);
                }
              }
            }}
          >
            {record.serviceName}
          </Typography>
        );
      },
    },
    {
      title: 'dental.dentalDetail.indicationTable.serviceType.label',
      render: (record) => {
        const label = SYSTEM_CATEGORY_SCOPE_LABEL.find((v) => v.value === record.scope)?.label;
        return <Typography>{intl.formatMessage({ id: label || ' ' })}</Typography>;
      },
    },
    {
      title: 'dental.dentalDetail.indicationTable.serviceDate.label',
      render: (record) => {
        return <Typography>{record.createdTime?.format(FE_DATE_FORMAT)}</Typography>;
      },
    },
    {
      title: 'dental.dentalDetail.indicationTable.price.label',
      render: (record) => {
        return <Typography variant="body1">{NumericFormatText(record.price)}</Typography>;
      },
    },
    {
      title: 'dental.dentalDetail.indicationTable.fee.label',
      align: 'center',
      dataIndex: 'isFree',
      render: (record) => {
        return (
          <Switch
            checked={!record.isFree}
            disabled={record.isPaid || readOnly}
            onChange={(e) =>
              onChangeIsFree({
                ...record,
                isFree: e.target.checked ? false : true,
              })
            }
          />
        );
      },
    },
    {
      title: 'dental.dentalDetail.indicationTable.status.label',
      render: (record) => {
        const status = INDICATION_REQUEST_STATUS[record.status!];
        return (
          <Typography
            noWrap
            sx={{
              color: status?.color || 'primary.main',
              fontWeight: '500',
            }}
          >
            <FormattedMessage id={status?.label || ' '} />
          </Typography>
        );
      },
    },
    {
      title: 'dental.dentalDetail.indicationTable.action.label',
      align: 'center',
      width: '80px',
      render: (record) => {
        return (
          <Stack direction="row" alignItems="center" justifyContent="flex-end">
            {record.status === INDICATION_REQUEST_STATUS.WAIT.value && (
              <IconButtonTitle
                onClick={() => {
                  onRequestIndicationMutate({
                    url: API_SERVER.indicationRequest.requestIndication(record.id!),
                    method: 'put',
                  }).then(() =>
                    onChangeIsFree({
                      // Auto change to charge fee for dental exam
                      ...record,
                      isFree: false,
                    }),
                  );
                }}
                title="request"
                disabled={readOnly}
              >
                <MedicineBold />
              </IconButtonTitle>
            )}
            <IconButtonTitle
              size="small"
              onClick={() =>
                onDeleteIndicationMutate({
                  url: API_SERVER.indicationRequest.deleteIndicationRequest(record.id!),
                  method: 'delete',
                })
              }
              disabled={
                record.status === INDICATION_REQUEST_STATUS.FINISHED.value ||
                record.status === INDICATION_REQUEST_STATUS.CANCELLED.value ||
                record.isPaid ||
                readOnly
              }
              title="delete"
            >
              <DeleteIcon />
            </IconButtonTitle>
            <IconButtonTitle
              size="small"
              title="note"
              onClick={() => onOpenIndicationNoteDialog(record)}
              disabled={readOnly}
            >
              {record.note ? <DescriptionSharpIcon fontSize="small" /> : <InsertDriveFileSharpIcon fontSize="small" />}
            </IconButtonTitle>
            <IconButtonTitle
              size="small"
              onClick={() =>
                onCompleteIndicationMutate({
                  url: API_SERVER.indicationRequest.finishIndicationRequest(record.id!),
                  method: 'put',
                })
              }
              title="complete"
              disabled={
                record.status === INDICATION_REQUEST_STATUS.FINISHED.value ||
                record.status === INDICATION_REQUEST_STATUS.CANCELLED.value ||
                readOnly
              }
              sx={{
                '& svg':
                  INDICATION_REQUEST_STATUS.FINISHED.value === record.status
                    ? { color: INDICATION_REQUEST_STATUS.FINISHED.color }
                    : {},
              }}
            >
              <AssignmentTurnedInIcon />
            </IconButtonTitle>
          </Stack>
        );
      },
    },
  ];

  return (
    <>
      <TableCustom
        containerProps={{
          sx: {
            '& th,tr,td': {
              padding: '4px 8px',
              backgroundColor: '#F4F8FF',
              color: 'unset',
              lineHeight: '16px',
            },
            '& th': {
              fontSize: '12px',
              fontStyle: 'normal',
              fontWeight: 500,
              letterSpacing: '0.4px',
              color: '#0052E0',
            },
            width: '100%',
            overflow: 'visible',
          },
        }}
        dataSource={indicationRequestData}
        columns={COLUMNS}
      />
      <IndicationNoteDialog />
      {!!labResultIndication && (
        <LabResultDialog
          onClose={() => {
            setLabResultIndication(undefined);
          }}
          open={!!labResultIndication}
          indication={labResultIndication}
        ></LabResultDialog>
      )}
      {!!imageResultIndication && (
        <ImageResultDialog
          onClose={() => {
            setImageResultIndication(undefined);
          }}
          indication={imageResultIndication}
          open={!!imageResultIndication}
        ></ImageResultDialog>
      )}
      {!!procedureIndication && (
        <ProcedureDetailDialog
          onClose={() => {
            setProcedureIndication(undefined);
          }}
          formData={procedureIndication}
          open={!!procedureIndication}
          onSubmit={onSubmitIndicationProcedure}
        ></ProcedureDetailDialog>
      )}
    </>
  );
};

export default IndicationsTable;
