import { BE_DATE_FORMAT, BE_DATE_TIME_FORMAT, FE_DATE_FORMAT, some } from '../../../common/constants';
import { HookPaginationProps } from '../../../common/hook/usePaginationHook';
import * as React from 'react';
import { useHistory } from 'react-router';
import { Typography } from '@mui/material';
import { FormattedMessage } from 'react-intl';
import TableCustom, { Columns } from '../../../common/component/TableCustom';
import { PAYMENT_STATUS } from '../../../reception/constants';
import moment from 'moment/moment';
import { ROUTES } from '../../../layout/router';

interface Props {
  data?: some;
  loading: boolean;
  hookPagination: HookPaginationProps;
}

const CashierDentalList: React.FunctionComponent<Props> = ({ data, loading, hookPagination }) => {
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const history = useHistory();

  const columns = React.useMemo(() => {
    return [
      {
        title: 'cashierPrint.encounterId',
        dataIndex: 'code',
        align: 'left',
      },
      {
        title: 'therapy.label.customer.code.kh',
        render: (record) => <Typography>{record.patient?.code?.split('-')?.[1]}</Typography>,
      },
      {
        title: 'therapy.label.customer.name',
        dataIndex: 'patient.name',
      },
      {
        title: 'birthdayShort',
        render: (record) => (
          <Typography>
            {record.patient?.dob && moment(record.patient.dob, BE_DATE_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
      },

      {
        title: 'therapyList.header.label.mobilePhone',
        dataIndex: 'patient.mobilePhone',
      },

      {
        title: 'encounterDate',
        render: (record) => (
          <Typography>
            {record.createdTime && moment(record.createdTime, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT)}
          </Typography>
        ),
      },
      {
        title: 'status',
        render: (record) => {
          const status = PAYMENT_STATUS.find((v) => v.value === record?.payment?.status);
          return (
            <Typography
              noWrap
              sx={{
                color: status?.sx || 'primary.main',
                fontWeight: '500',
              }}
            >
              <FormattedMessage id={status?.label || ' '} />
            </Typography>
          );
        },
      },
    ] as Columns[];
  }, []);

  return (
    <>
      <TableCustom
        dataSource={data?.content}
        loading={loading}
        columns={columns}
        paginationProps={{
          count: data?.pagination?.totalElements,
          page: pagination.page,
          rowsPerPage: pagination.pageSize,
          onPageChange: onPageChange,
          onRowsPerPageChange: onRowsPerPageChange,
        }}
        rowProps={(record: some) => ({
          onClick: async () => {
            if (record?.payment?.status !== 'CANCELLED') {
              history.push(ROUTES.dentalPaymentDetail.gen(record?.id, record?.payment?.id));
            }
          },

          style: { cursor: 'pointer' },
        })}
      />
    </>
  );
};

export default CashierDentalList;
