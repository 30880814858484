import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { createSelectors } from 'modules/common/utils';
import { IndicationRequest } from 'modules/schema';

interface DialogState {
  open: boolean;
  onCloseDialog: () => void;
  onOpenDialog: (selectedIndication: IndicationRequest) => void;
  selectedIndication: IndicationRequest | null;
}

export const useIndicationNoteState = create(
  immer<DialogState>((set) => ({
    open: false,
    selectedIndication: null,
    onCloseDialog: () =>
      set((state) => {
        state.open = false;
        state.selectedIndication = null;
      }),
    onOpenDialog: (indicationRequest) =>
      set((state) => {
        state.open = true;
        state.selectedIndication = indicationRequest;
      }),
  })),
);

export const useIndicationNoteSelectors = createSelectors(useIndicationNoteState);
