import React, { useEffect, useMemo, useState } from 'react';
import { SaleRevenueReport } from 'modules/schema';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import { FormattedMessage, useIntl } from 'react-intl';
import { ReportFilterOption } from 'modules/common/component/report/type';
import { Box, Stack, Typography } from '@mui/material';
import Report from 'modules/common/component/report';
import { useDialog, useFetch } from 'modules/common/hook';
import { SALE_API } from 'modules/common/service';
import { formatEndDate, formatStartDate, isValidDate, maxDateTable, NumericFormatText } from 'modules/common/utils';
import moment from 'moment';
import { DEFAULT_VALUES, FILTER_CONFIGS, FILTER_KEY, FormSchema, SELECT_COLUMNS_KEY } from './const';
import { useForm } from 'react-hook-form';
import Filter from 'modules/common/component/filter';
import { BE_DATE_TIME_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { DEFAULT_CONFIG } from 'modules/common/component/filter/const';
import DateInput from 'modules/common/component/form/DateInput';
import Table, { TableOptions } from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { SaleRevenueDetailTableSub } from './SaleRevenueDetailTableSub';
import { PAYMENT_METHOD, RECEIPT_TYPE } from 'modules/common/apiConstants';
import SelectInput from '../../../../component/form/SelectInput';
import { map } from 'lodash';

interface Props {}

const COLUMNS: Column<SaleRevenueReport>[] = [
  {
    type: 'SUB_ROW',
    allowCollapse: true,
    width: '10px',
    collapseProps: {
      unmountOnExit: true,
    },
    render: (record) => <SaleRevenueDetailTableSub saleRevenue={record} />,
  },
  { type: 'INDEX', width: '30px', sticky: true },
  {
    title: 'sellingProduct.title.createdDate',
    fieldName: 'createdTime',
    render: (record) => record.createdTime?.format(FE_DATE_FORMAT),
    selectColumnConfig: {
      disabled: true,
      defaultChecked: true,
    },
  },
  {
    title: 'sellingProduct.title.receiptCode',
    fieldName: 'code',
    render: (record) => record?.code,
    selectColumnConfig: {
      disabled: true,
      defaultChecked: true,
    },
  },
  {
    title: 'sellingProduct.title.receiptType',
    fieldName: 'receiptType',
    minWidth: 130,
    render: (record) => record?.receiptType && <FormattedMessage id={RECEIPT_TYPE[record.receiptType]?.label} />,
    selectColumnConfig: {
      disabled: true,
      defaultChecked: true,
    },
  },
  {
    title: 'sellingProduct.title.customerName',
    fieldName: 'customerName',
    render: (record) => record?.customerName,
    selectColumnConfig: {
      disabled: false,
      defaultChecked: true,
    },
  },
  {
    title: 'sellingProduct.title.phoneNumber',
    fieldName: 'phoneNumber',
    render: (record) => record?.phoneNumber,
    selectColumnConfig: {
      disabled: false,
      defaultChecked: true,
    },
  },
  {
    title: 'sellingProduct.title.print.total.discount',
    fieldName: 'discount',
    render: (record) => {
      return record.discountType === 'PERCENT'
        ? (record?.discount || 0) + '%'
        : NumericFormatText(record.discount || 0);
    },
    selectColumnConfig: {
      disabled: false,
      defaultChecked: true,
    },
  },
  {
    title: 'sellingProduct.title.print.paid.amount',
    fieldName: 'amountPaid',
    render: (record) => NumericFormatText(record.amountPaid || 0),
    selectColumnConfig: {
      disabled: false,
      defaultChecked: true,
    },
  },
  {
    title: 'sellingProduct.title.print.paymentMethod',
    fieldName: 'paymentMethod',
    render: (record) => {
      return (
        <Typography variant="body1">
          <FormattedMessage id={PAYMENT_METHOD[record?.paymentMethod!]?.label || ''} />
        </Typography>
      );
    },
    selectColumnConfig: {
      disabled: false,
      defaultChecked: true,
    },
  },
];

const SaleRevenueTable: React.FunctionComponent<Props> = () => {
  const [filterReportOptions, setFilterReportOptions] = React.useState<ReportFilterOption[]>([]);
  const [summaryData, setSummaryData] = useState<SaleRevenueReport>({});
  const { page, pageSize, paginationProps, resetPage } = useTablePagination();
  const [openPrintDialog, onOpenPrintDialog, onClosePrintDialog] = useDialog();
  const intl = useIntl();
  const allOptions = intl.formatMessage({ id: 'all' });

  const form = useForm<FormSchema>({
    defaultValues: DEFAULT_VALUES,
    mode: 'onChange',
  });

  const [searchParams, setSearchParams] = React.useState<FormSchema>(DEFAULT_VALUES);

  const fromDateOrigin = form.watch('fromDate');
  const toDateOrigin = form.watch('toDate');
  const fromDate = formatStartDate(fromDateOrigin);
  const toDate =
    moment(form.watch('toDate')) < moment()
      ? formatEndDate(moment(form.watch('toDate')).add(-1, 'day'))
      : formatEndDate(moment());
  const receiptType = form.watch('receiptType');

  const searchPageParams = useMemo(() => {
    return {
      ...searchParams,
      receiptTypes: receiptType ? map(receiptType, 'value') : [],
      fromDate,
      toDate,
      page: page,
      pageSize: pageSize,
    };
  }, [fromDate, page, pageSize, searchParams, toDate, receiptType]);

  const { data: reportPage, isValidating } = useFetch(SALE_API.getRevenuePaging(searchPageParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: isValidDate(fromDate, toDate, 'TABLE'),
  });

  useEffect(() => {
    if (reportPage && reportPage.summary && Object.keys(reportPage.summary).length > 0 && !isValidating) {
      setSummaryData(reportPage?.summary);
    }
  }, [reportPage, isValidating]);

  useEffect(() => {
    setFilterReportOptions([
      {
        title: 'sellingProduct.title.receiptType',
        value: receiptType ? receiptType.map((ele) => intl.formatMessage({ id: ele.label })).join(', ') : allOptions,
      },
      { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
      { title: DEFAULT_CONFIG.PAYMENT_METHODS.title, value: allOptions },
      { title: 'sale.products', value: allOptions },
    ]);
  }, [fromDate, toDate, receiptType, allOptions, intl]);

  const [selectedColumns, setSelectedColumn] = useState<Column<SaleRevenueReport>[]>(
    COLUMNS.filter((column) => !column.selectColumnConfig || column.selectColumnConfig.defaultChecked),
  );

  const exportUrl = React.useMemo(() => SALE_API.getRevenueExcel(searchPageParams), [searchPageParams]);

  const { data: printDataPage } = useFetch(SALE_API.getRevenueList(searchPageParams), {
    globalLoading: true,
    revalidateOnFocus: false,
    enabled: openPrintDialog && isValidDate(fromDate, toDate, 'TABLE'),
  });

  const TABLE_OPTIONS = useMemo(() => {
    let tableOptions: TableOptions = {
      position: 'top',
      startIndex: COLUMNS.length === selectedColumns.length ? 4 : 0,
      useRender: ['debtAmount'],
      record: summaryData,
    };
    return tableOptions;
  }, [summaryData, selectedColumns.length]);

  return (
    <Stack direction="column" gap={2}>
      <Report.Title
        title="report.sale.revenue.label"
        showSubTitle
        foundResultAmount={reportPage?.content.length || 0}
        totalElementAmount={reportPage?.pagination.totalElements}
        renderRight={
          <Stack direction="row" alignItems="center" justifyContent="center" gap="16px" paddingTop={2.5}>
            <Report.SearchInput
              form={form}
              name="keyword"
              onSearch={() => {
                setSearchParams((prev) => ({ ...prev, keyword: form.getValues('keyword') }));
                resetPage();
              }}
            />
          </Stack>
        }
      />
      <Filter
        filters={FILTER_CONFIGS}
        onSave={(filterValue, filterConfig) => {
          if (fromDate && toDate) {
            setSearchParams((prev: any) => ({
              ...prev,
              paymentMethods: filterValue.PAYMENT_METHODS,
              medicationIds: filterValue.MEDICATION,
            }));
            resetPage();
          }
          setFilterReportOptions([
            {
              title: 'sellingProduct.title.receiptType',
              value: receiptType
                ? receiptType.map((ele) => intl.formatMessage({ id: ele.label })).join(', ')
                : allOptions,
            },
            { title: 'fromDate', value: moment(fromDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            { title: 'toDate', value: moment(toDate, BE_DATE_TIME_FORMAT).format(FE_DATE_FORMAT) },
            {
              title: DEFAULT_CONFIG.PAYMENT_METHODS.title,
              value:
                filterConfig.PAYMENT_METHODS?.length === 0
                  ? allOptions
                  : filterConfig.PAYMENT_METHODS.map((ele) => intl.formatMessage({ id: ele.label })).join(', '),
            },
            {
              title: 'sale.products',
              value:
                filterConfig.MEDICATION?.length === 0
                  ? allOptions
                  : filterConfig.MEDICATION.map((ele) => ele.name).join(', '),
            },
          ]);
        }}
        localStorageKey={FILTER_KEY}
      >
        <Stack direction="row" alignItems="center" justifyContent="space-between">
          <Stack direction="column" gap="10px">
            <Stack direction="row" alignItems="center" gap="13px">
              <SelectInput
                form={form}
                name="receiptType"
                options={Object.values(RECEIPT_TYPE)}
                getValue="value"
                multiple
                renderLabel="label"
                label="sellingProduct.title.receiptType"
                placeholder="sellingProduct.title.receiptType"
                onChange={resetPage}
                showCheckBox
              />
              <DateInput
                label="fromDate"
                form={form}
                name="fromDate"
                mode="start"
                required
                maxDate={moment() && moment(toDate)}
                format={FE_DATE_FORMAT}
                type="TABLE"
                onChange={resetPage}
              />
              <DateInput
                required
                label="toDate"
                form={form}
                name="toDate"
                mode="end"
                maxDate={maxDateTable(fromDate, fromDateOrigin, toDateOrigin)}
                minDate={moment(fromDate).add(+1, 'day')}
                format={FE_DATE_FORMAT}
                type="TABLE"
                onChange={resetPage}
              />
              <Box paddingTop={'11px'}>
                <Report.RefreshButton
                  onClick={() => {
                    form.reset();
                    setSearchParams(DEFAULT_VALUES);
                  }}
                />
              </Box>
            </Stack>
            <Filter.View />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center" gap="8px">
            <Filter.Button />
            <Report.SelectColumnButton
              columns={COLUMNS}
              onSave={setSelectedColumn}
              localStorageKey={SELECT_COLUMNS_KEY}
            />
            <Report.ExportDataButton exportUrl={exportUrl} />
            <Report.PrintDataButton
              title="report.sale.revenue.label"
              openDialog={openPrintDialog}
              onOpenDialog={onOpenPrintDialog}
              onCloseDialog={onClosePrintDialog}
              content={
                <Stack direction="column" gap={1}>
                  <Report.FilterPrintFormat filterOptions={filterReportOptions} />
                  <Table
                    data={printDataPage}
                    columns={selectedColumns}
                    dataKey="id"
                    rowProps={{ sx: { pageBreakAfter: 'auto' } }}
                  />
                </Stack>
              }
            />
          </Stack>
        </Stack>
      </Filter>
      <Table
        columns={selectedColumns}
        options={TABLE_OPTIONS}
        dataKey="id"
        data={reportPage?.content}
        paginationProps={paginationProps}
        totalElements={reportPage?.pagination.totalElements}
        stickyHeader
        maxHeigth="100%"
      />
    </Stack>
  );
};

export default SaleRevenueTable;
