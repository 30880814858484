import { PATIENT_TYPE, PAYMENT_STATUS } from 'modules/common/apiConstants';
import { FilterType } from 'modules/common/component/filter/type';
import { BE_DATE_TIME_FORMAT, PAYMENT_PLAN, ROLES } from 'modules/common/constants';
import { formatEndDate } from 'modules/common/utils';
import moment from 'moment';

export const FILTER_CONFIGS: FilterType[] = [
  {
    type: 'PAYMENT_STATUS',
    options: [PAYMENT_STATUS.PAID, PAYMENT_STATUS.UNPAID],
    xs: 5,
  },
  {
    type: 'EMPLOYEE',
    title: 'therapyList.filter.label.picName',
    roles: [
      ROLES.DOCTOR.value,
      ROLES.GENERAL_DOCTOR.value,
      ROLES.NURSE.value,
      ROLES.RADIOGRAPHER.value,
      ROLES.LABORATORY_TECHNOLOGIST.value,
      ROLES.RADIOLOGIST.value,
      ROLES.CLINICAL_PATHOLOGIST.value,
    ],
  },
  {
    type: 'PATIENT_TYPE',
    options: [PATIENT_TYPE.RE_EXAMINATION, PATIENT_TYPE.NEW],
    xs: 5,
  },
  {
    type: 'PAYMENT_PLAN',
    options: [PAYMENT_PLAN.FEE, PAYMENT_PLAN.FREE, PAYMENT_PLAN.OTHER],
    title: 'therapy.monthlyReport.label.paymentCategory',
  },
];

export const DEFAULT_VALUES = {
  fromDate: moment().add(-1, 'month').format(BE_DATE_TIME_FORMAT),
  toDate: formatEndDate(moment()),
  keyword: '',
  serviceType: [],
};

export const FILTER_KEY = 'REVENUE_REPORT_FILTER';
export const SELECT_COLUMNS_KEY = 'REVENUE_REPORT_SELECT_COLUMNS';
export const PAYMENT_DETAIL_TYPE = {
  THERAPY: { value: 'THERAPY', label: 'therapy.cashier.paymentType.label.therapy' },
  THERAPY_SESSION: { value: 'THERAPY_SESSION', label: 'therapy.cashier.paymentType.label.therapySession' },
  THERAPY_SESSION_OUT_PACKAGE: {
    value: 'THERAPY_SESSION_OUT_PACKAGE',
    label: 'therapy.cashier.paymentType.label.therapySessionOutPackage',
  },
  MEDICATION_USAGE: { value: 'MEDICATION_USAGE', label: 'payment.detail.type.report.medication.usage' },
  MEDICAL_SUPPLIES_USAGE: {
    value: 'MEDICAL_SUPPLIES_USAGE',
    label: 'payment.detail.type.report.medication.supplies.usage',
  },
  INDICATION_REQUEST: { value: 'INDICATION_REQUEST', label: 'payment.detail.type.report.indication.request' },
  INDICATION_MEDICATION: {
    value: 'INDICATION_MEDICATION',
    label: 'therapy.cashier.paymentType.label.indicationMedication',
  },
  INDICATION_MEDICAL_SUPPLIES: {
    value: 'INDICATION_MEDICAL_SUPPLIES',
    label: 'therapy.cashier.paymentType.label.indicationMedicalSupplies',
  },
  DENTAL: {
    value: 'DENTAL',
    label: 'cashier.paymentType.label.dental',
  },
  ENCOUNTER: {
    value: 'ENCOUNTER',
    label: 'cashier.paymentType.label.encounter',
  },
};
