import { Box } from '@mui/system';
import * as React from 'react';
import { INDICATION_REQUEST_TYPE, some } from '../../../../../common/constants';
import LoadingIcon from '../../../../../common/component/LoadingIcon';
import NoDataBox from '../../../../../common/component/NoDataBox';
import { API_SERVER } from '../../../../../common/api';
import LabTable from './LabTable';
import { useFetch } from 'modules/common/hook';

interface Props {
  referenceId: number;
  type: keyof typeof INDICATION_REQUEST_TYPE;
}

const IndicationLab = ({ referenceId, type }: Props) => {
  const { data, isValidating } = useFetch(API_SERVER.indicationLaboratory.getListByRefereceId({ referenceId, type }));

  if (isValidating) {
    return <LoadingIcon />;
  }

  if (!data || data?.length === 0) {
    return (
      <Box display="flex" justifyContent={'center'}>
        <NoDataBox />
      </Box>
    );
  }

  return (
    <>
      {data?.map((item: some, index: number) => {
        return <LabTable key={item.value} data={item} />;
      })}
    </>
  );
};

export default IndicationLab;
