import { Box } from '@mui/system';
import DialogCustom from 'modules/common/component/DialogCustom';
import ImagePreview from 'modules/common/component/ImagePreview';
import { useDiagnosisDialogSelector } from './state';

const Preview = () => {
  const openDialog = useDiagnosisDialogSelector.use.previewDialogOpen();
  const onCloseDialog = useDiagnosisDialogSelector.use.onClosePreviewDialog();
  const selectedImageId = useDiagnosisDialogSelector.use.selectedImageId();
  return (
    <Box>
      <DialogCustom
        open={openDialog}
        onClose={onCloseDialog}
        PaperProps={{
          sx: {
            minWidth: 'unset',
            maxWidth: 'unset',
          },
        }}
      >
        <ImagePreview imageId={selectedImageId || undefined} />
      </DialogCustom>
    </Box>
  );
};

export default Preview;
