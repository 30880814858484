import { z } from 'zod';
import { IndicationRequestSchema } from './IndicationRequest';
import { MEDICAL_SUPPLIES_USAGE_TYPE } from 'modules/common/constants';
import { createObjectKeysEnumSchema } from './common';

export const MedicalSuppliesUsageSchema = z
  .object({
    id: z.number(),
    referenceId: z.number(),
    type: createObjectKeysEnumSchema(MEDICAL_SUPPLIES_USAGE_TYPE),
    medicalSuppliesId: z.number(),
    medicalSuppliesName: z.string(),
    medicalSuppliesUnit: z.string(),
    medicalSuppliesGroup: z.string(),
    isFree: z.boolean(),
    quantity: z.number(),
    indicationRequestId: z.number(),
    isPaid: z.boolean(),
    indicationRequest: IndicationRequestSchema,
    price: z.number(),
    vat: z.number(),
    isDisable: z.boolean(),
    paymentDetailId: z.number(),
    amountPaid: z.number(),
    totalAmount: z.number(),
    vatAmount: z.number(),
  })
  .partial();

export type MedicalSuppliesUsage = z.infer<typeof MedicalSuppliesUsageSchema>;
