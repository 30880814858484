import { Paper, Typography } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { IDENTITY_API_SERVER } from '../../common/api';
import { some } from '../../common/constants';
import useGeneralHook from '../../common/hook/useGeneralHook';
import usePaginationHook from '../../common/hook/usePaginationHook';
import { setLoading } from '../../common/redux/commonReducer';
import { fetchThunk } from '../../common/redux/thunk';
import { generateRoleValue } from '../../layout/utils';
import EmployeeTable from '../component/employee/EmployeeTable';
import FormDialog from '../component/employee/FormDialog';
import SearchEmployeeAdmin from '../component/employee/SearchEmployeeAdmin';
import { mapSearchDataPractitioner } from '../utils';
import { EMPLOYEE_STATUS } from '../constants';
import { useFetch } from 'modules/common/hook';

interface Props {}

const EmployeeAdminPage: React.FunctionComponent<Props> = (props) => {
  const { dispatch, openSnackbar, intl } = useGeneralHook();
  const [dataUser, setDataUser] = React.useState<some | undefined>();
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;

  const { data, isValidating, revalidate } = useFetch(IDENTITY_API_SERVER.employee.getList({ ...params }));

  const dataMapping = React.useMemo(() => {
    return data && { total: data?.pagination.totalElements, items: mapSearchDataPractitioner(data?.content) };
  }, [data]);

  const onSubmit = React.useCallback(
    async (value: some) => {
      const isEdit = !!value?.id;
      try {
        dispatch(setLoading(true));
        await dispatch(
          fetchThunk(
            value?.id ? IDENTITY_API_SERVER.employee.update(value?.id) : IDENTITY_API_SERVER.employee.create,
            isEdit ? 'put' : 'post',
            {
              id: value?.id,
              status: value.active ? EMPLOYEE_STATUS.active : EMPLOYEE_STATUS.inactive,
              fullName: value.name,
              email: value.email,
              address: value.address,
              code: value.code,
              phoneNumber: value.phoneNumber,
              contractInfo: value.contractInfo,
              branch: value.branch,
              position: value.position,
            },
          ),
        );

        openSnackbar({ message: intl.formatMessage({ id: isEdit ? 'updateSuccess' : 'createSuccess' }) });
        setDataUser(undefined);
      } catch (e: any) {
        if (e?.errors) {
          e?.errors.forEach((v) => {
            openSnackbar({ message: v.message, type: 'error' });
          });
        } else {
          openSnackbar({ message: intl.formatMessage({ id: isEdit ? 'updateFail' : 'createFail' }), type: 'error' });
        }
      } finally {
        dispatch(setLoading(false));
        //Comment out because we need keep form user input if validate fail
        //setDataUser(undefined);
        revalidate();
      }
    },
    [dispatch, intl, openSnackbar, revalidate],
  );

  return (
    <Paper elevation={1} style={{ padding: 16, flex: 1, overflow: 'hidden' }}>
      <SearchEmployeeAdmin
        onAddNew={() => {
          setDataUser({ active: true });
        }}
        hookPagination={hookPagination}
        pagin={
          <Typography variant="caption">
            <FormattedMessage
              id="foundResult"
              values={{
                num: (
                  <Typography variant="inherit" component="span" color="primary">
                    {params.page * params.pageSize + (data?.content?.length || 0)}
                  </Typography>
                ),
                total: (
                  <Typography variant="inherit" component="span" color="primary">
                    {data ? data?.pagination?.totalElements : 0}
                  </Typography>
                ),
              }}
            />
          </Typography>
        }
      />
      <FormDialog
        open={!!dataUser}
        formData={dataUser}
        onClose={() => {
          setDataUser(undefined);
        }}
        onSubmit={onSubmit}
      />
      <EmployeeTable
        data={dataMapping}
        hookPagination={hookPagination}
        loading={isValidating}
        onEdit={(value) => {
          setDataUser({
            ...value,
            role: generateRoleValue(value.role),
          });
        }}
      />
    </Paper>
  );
};

export default EmployeeAdminPage;
