import { Box, LinearProgress } from '@mui/material';
import { API_SERVER } from 'modules/common/api';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { useFetch } from 'modules/common/hook';
import { NumericFormatText } from 'modules/common/utils';
import { SaleReceiptDetail, SaleRevenueReport } from 'modules/schema';

const COLUMNS: Column<SaleReceiptDetail>[] = [
  { type: 'INDEX', width: '30px', sticky: true },
  {
    title: 'sellingProduct.title.productName',
    render: (record) => record?.medicationName,
  },
  {
    title: 'inventory.lot',
    render: (record) => record?.lot,
  },
  {
    title: 'inventory.quantity',
    render: (record) => record.quantity,
  },
  {
    title: 'sellingProduct.title.unitPrice',
    render: (record) => record.unitPrice,
  },
  {
    title: 'sellingProduct.title.discount',
    render: (record) => {
      return record.discountType === 'PERCENT'
        ? (record?.discount || 0) + '%'
        : NumericFormatText(record.discount || 0);
    },
  },
  {
    title: 'sellingProduct.title.VAT',
    render: (record) => record?.vat || 0 + '%',
  },
  {
    title: 'sellingProduct.title.totalAmount',
    render: (record) => NumericFormatText(record.totalAmount || 0),
  },
  {
    title: 'sellingProduct.title.print.total.discount',
    render: (record) => NumericFormatText(record.invoiceDiscountAmount || 0),
  },
  {
    title: 'sellingProduct.title.print.paid.amount',
    render: (record) => NumericFormatText(record.amountPaid || 0),
  },
];

interface Props {
  saleRevenue: SaleRevenueReport;
}

export const SaleRevenueDetailTableSub = ({ saleRevenue }: Props) => {
  const { data, isValidating } = useFetch(API_SERVER.receiptManagement.getDetail(saleRevenue?.id!), {
    enabled: saleRevenue.id,
  });
  if (isValidating) {
    return (
      <Box sx={{ width: '100%' }}>
        <LinearProgress />
      </Box>
    );
  }

  return <Table columns={COLUMNS} data={data?.productList} dataKey={'id'} subTableStyle />;
};
