import { AnyAction } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { ActionType, createCustomAction, getType } from 'typesafe-actions';
import { AppState } from '../../../redux/reducer';
import { ROLES, SS_TOKEN, some } from '../../common/constants';
import { GROUP_VERSION } from 'modules/common/apiConstants';
import { fetchThunk } from 'modules/common/redux/thunk';
import { IDENTITY_API_SERVER } from 'modules/common/api';

export interface JwtDecoded {
  token_id: string;
  token_type: string;
  organization_id: number;
  organization_code: string;
  organization_name: string;
  group_specialization: string;
  group_id: number;
  group_name: string;
  group_type: string;
  user_id: number;
  user_name: string;
  role: (keyof typeof ROLES)[];
  sub: string;
  iat: string;
  exp: string;
  group_currency: string;
  employee_id: string;
  employee_name: string;
  email: string;
  group_version: keyof typeof GROUP_VERSION;
}
export interface AuthenState {
  authen: boolean;
  user?: JwtDecoded;
  locations: some;
  currentLocation?: some;
}

export const authenIn = createCustomAction('authen/in');
export const authenOut = createCustomAction('authen/out');

export const saveUserInfo = createCustomAction('authen/saveUserInfo', (user: JwtDecoded, locations: some) => ({
  user,
  locations,
}));

export const setCurrentLocation = createCustomAction('authen/setCurrentLocation', (location: some) => ({
  location,
}));

export function logoutThunk(): ThunkAction<void, AppState, null, AnyAction> {
  return async (dispatch) => {
    await dispatch(fetchThunk(IDENTITY_API_SERVER.user.logout, 'post')).then(() => sessionStorage.removeItem(SS_TOKEN));
    dispatch(authenOut());
  };
}

const actions = {
  authenIn,
  authenOut,
  saveUserInfo,
  setCurrentLocation,
};

type ActionT = ActionType<typeof actions>;

export default function authenReducer(
  state: AuthenState = { authen: false, locations: [] },
  action: ActionT,
): AuthenState {
  switch (action.type) {
    case getType(authenIn):
      return { ...state, authen: true };
    case getType(authenOut):
      return { ...state, authen: false };
    case getType(saveUserInfo):
      const tmp = action.locations;
      const locations = {
        id: tmp.resourceId,
        name: tmp.name,
        phone: tmp.phoneNumber,
        email: tmp.email,
        website: tmp.website,
        address: tmp.address,
        insuranceNumber: tmp.insuranceCode,
        avatarId: tmp.avatarId,
      };
      return {
        ...state,
        user: action.user,
        locations,
        currentLocation: locations,
      };
    case getType(setCurrentLocation):
      return { ...state, currentLocation: action.location };
    default:
      return state;
  }
}
