import { DayView as DayViewBase } from '@devexpress/dx-react-scheduler';
import { Box } from '@mui/material';
import { NUMBER_PATIENT_COLUMN_ID } from '../constants';
import React from 'react';

const TimeTableCellComponent = ({ groupingInfo }: DayViewBase.TimeTableCellProps) => {
  return (
    <Box
      component="td"
      sx={{
        height: '48px',
        borderRight: '1px solid rgba(183, 183, 183, 1)',
        borderBottom: '0.5px solid #e0e0e0',
        display: 'table-cell',
        boxSizing: 'border-box',
        '&:hover': { backgroundColor: '#eff5ff' },
        cursor: 'grab',
        width: groupingInfo?.[0]?.id === NUMBER_PATIENT_COLUMN_ID ? '55px' : '200px',
      }}
    ></Box>
  );
};

export default TimeTableCellComponent;
