import { z } from 'zod';

export const InventoryAlertSettingSchema = z
  .object({
    id: z.number(),
    medicationId: z.number(),
    unit: z.string(),
    quantity: z.number(),
    remainingDays: z.number(),
  })
  .partial();

export type InventoryAlertSetting = z.infer<typeof InventoryAlertSettingSchema>;
