import { Close, Delete } from '@mui/icons-material';
import { Box, Button, Dialog, IconButton, Typography } from '@mui/material';
import React, { useCallback, useState } from 'react';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import IconButtonTitle from 'modules/common/component/IconButtonTitle';
import { some } from 'modules/common/constants';
import ImageIcon from '@mui/icons-material/Image';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DownloadIcon from '@mui/icons-material/Download';
import useGeneralHook from 'modules/common/hook/useGeneralHook';
import { PRIMARY } from 'colors';
import ArticleIcon from '@mui/icons-material/Article';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { API_SERVER } from 'modules/common/api';
import { axiosThunk } from 'modules/common/redux/axios';
import { AxiosError } from 'axios';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import LoadingIcon from 'modules/common/component/LoadingIcon';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { setLoading } from 'modules/common/redux/commonReducer';
import { useDownloadFile } from 'modules/common/hook';

interface Props {
  data: some;
  onDeleteFile: () => void;
  readOnly?: boolean;
}
const DocumentBox = (props: Props) => {
  const { data, readOnly, onDeleteFile } = props;
  const [open, setOpen] = useState(false);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [dataImg, setDataImg] = useState<any>();
  const { openSnackbar, dispatch, intl } = useGeneralHook();

  // display icon file type
  const isImageType =
    data?.fileName.includes('png') || data?.fileName.includes('jpg') || data?.fileName.includes('jpeg');
  const fileType = (fileName: string) => {
    if (fileName.includes('pdf')) {
      return <PictureAsPdfIcon color="primary" />;
    }
    if (fileName.includes('doc') || fileName.includes('docx')) {
      return <ArticleIcon color="primary" />;
    }
    if (fileName.includes('png') || fileName.includes('jpg') || fileName.includes('jpeg')) {
      return <ImageIcon color="primary" />;
    }
    return <AttachFileIcon color="primary" />;
  };

  // handle display image file
  const displayImg = async () => {
    if (!isImageType) {
      return;
    }
    dispatch(setLoading(true));
    await dispatch(
      axiosThunk({
        url: API_SERVER.storageResource.getFile(data?.id),
        method: 'get',
        responseType: 'blob',
      }),
    )
      .then((response) => {
        setDataImg(response.data);
        return response.data;
      })
      .catch((e: AxiosError<some>) => {
        openSnackbar({
          message: intl.formatMessage({ id: 'report.downloadFail' }),
          type: 'error',
        });
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
    setOpen(true);
  };

  const onDocumentLoadSuccess = useCallback(({ numPages }) => {
    setNumPages(numPages);
    setPageNumber(1);
  }, []);

  return (
    <>
      <Box
        display="flex"
        borderBottom={1}
        borderColor="divider"
        alignItems="center"
        sx={{
          '&:hover': {
            bgcolor: PRIMARY[50],
          },
        }}
      >
        <Box
          display="flex"
          alignItems="center"
          flex={1}
          style={{ cursor: !isImageType ? 'unset' : 'pointer' }}
          onClick={() => displayImg()}
        >
          {fileType(data?.fileName)}
          <Typography variant="body1" noWrap style={{ flex: 1, marginLeft: 8 }}>
            {data.fileName}
          </Typography>
        </Box>
        <IconButtonTitle
          title="download"
          size="small"
          onClick={useDownloadFile(API_SERVER.storageResource.doawloadFile(props?.data?.id!))}
        >
          <DownloadIcon />
        </IconButtonTitle>
        {!readOnly && (
          <IconButtonTitle title="delete" size="small" onClick={onDeleteFile}>
            <Delete />
          </IconButtonTitle>
        )}
      </Box>
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        maxWidth="xl"
        PaperProps={{
          style: { overflow: 'auto' },
        }}
      >
        <IconButton
          style={{ position: 'absolute', top: 8, right: 8, zIndex: 100, background: 'white' }}
          onClick={() => {
            setOpen(false);
          }}
        >
          <Close />
        </IconButton>
        {data?.fileName.includes('pdf') ? (
          <Box position="relative">
            <Box padding={2} paddingBottom={0}>
              <Document
                loading={<LoadingIcon style={{ width: 500, height: 500 }} />}
                file={dataImg}
                onLoadSuccess={onDocumentLoadSuccess}
                onLoadError={(e) => console.log(e)}
                options={{
                  cMapUrl: 'cmaps/',
                  cMapPacked: true,
                }}
              >
                <Page pageNumber={pageNumber} />
              </Document>
            </Box>
            <Box
              sx={{
                textAlign: 'center',
                bgcolor: 'background.paper',
                position: 'sticky',
                bottom: 0,
                paddingX: 2,
                paddingY: 1,
              }}
            >
              <Typography variant="body1">
                {pageNumber || (numPages ? 1 : '--')} / {numPages || '--'}
              </Typography>
              <Button
                type="button"
                disabled={pageNumber <= 1}
                onClick={() => {
                  setPageNumber((prevPageNumber) => prevPageNumber - 1);
                }}
              >
                <ArrowBackIcon />
              </Button>
              <Button
                type="button"
                style={{ marginLeft: 12 }}
                disabled={pageNumber >= numPages}
                onClick={() => {
                  setPageNumber((prevPageNumber) => prevPageNumber + 1);
                }}
              >
                <ArrowForwardIcon />
              </Button>
            </Box>
          </Box>
        ) : !isImageType ? (
          <></>
        ) : (
          <img
            src={dataImg ? URL.createObjectURL(dataImg) : ''}
            alt={data.fileName}
            style={{ objectFit: 'contain', height: '100%', maxHeight: '100%' }}
          ></img>
        )}
      </Dialog>
    </>
  );
};
export default React.memo(DocumentBox);
