import Delete from '@mui/icons-material/Delete';
import { Box } from '@mui/material';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { API_SERVER, IDENTITY_API_SERVER } from '../../../../../common/api';
import IconButtonTitle from '../../../../../common/component/IconButtonTitle';
import LoadingIcon from '../../../../../common/component/LoadingIcon';
import TableCustom from '../../../../../common/component/TableCustom';
import { some } from '../../../../../common/constants';
import useGeneralHook from '../../../../../common/hook/useGeneralHook';
import { setLoading } from '../../../../../common/redux/commonReducer';
import SchemaForm from '../../../../../common/SchemaForm';
import { ROLE_INFO } from '../../../../../layout/utils';
import { axiosThunk } from '../../../../../common/redux/axios';
import { useMemo } from 'react';
import { keyBy, sortBy } from 'lodash';
import { useFetch } from 'modules/common/hook';

interface Props {
  id: number;
}

const PARTICIPANTS = [ROLE_INFO.examDoctor, ROLE_INFO.labDoctor, ROLE_INFO.laboratory, ROLE_INFO.nurse];

const ParticipantTab: React.FunctionComponent<Props> = ({ id }) => {
  const { dispatch, openSnackbar, confirmDialog, intl } = useGeneralHook();
  const { data: users } = useFetch(
    IDENTITY_API_SERVER.user.getListUserByRoles({
      roles: PARTICIPANTS.map((ele) => ele.roleKey).join(','),
      status: 'ACTIVE',
    }),
  );
  const participantOptions = useMemo(() => {
    if (users && users.length > 0) {
      const roleMap = keyBy(PARTICIPANTS, 'roleKey');
      return sortBy(
        users
          ?.filter((ele) => ele?.practiceCertificate)
          ?.map((ele) => {
            return {
              id: ele?.id,
              name: ele?.employeeFullName,
              roleName: ele?.roleName,
              group: intl.formatMessage({ id: roleMap[ele?.roleName!]?.label }),
            };
          }),
        'group',
      );
    }
    return [];
  }, [intl, users]);

  const {
    data: participants,
    revalidate: participantsRevalidate,
    isValidating,
  } = useFetch(API_SERVER.indicationAttendPerson.get(id));

  const participantsMapped = useMemo(() => {
    return participants?.map((person) => ({
      ...person,
      roleLabel: PARTICIPANTS.find((ele) => ele?.roleKey === person?.roleName)?.label
        ? intl.formatMessage({ id: PARTICIPANTS.find((ele) => ele?.roleKey === person?.roleName)?.label })
        : '',
    }));
  }, [intl, participants]);

  const addParticipant = React.useCallback(
    async (value: some) => {
      try {
        dispatch(setLoading(true));
        await dispatch(
          axiosThunk({
            url: API_SERVER.indicationAttendPerson.create(),
            method: 'post',
            data: {
              indicationRequestId: id,
              roleName: value?.roleName,
              userId: value?.id,
              employeeName: value?.name,
            },
          }),
        );
        await participantsRevalidate();
        openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
      } catch (e) {
        openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
      } finally {
        dispatch(setLoading(false));
      }
    },
    [dispatch, id, intl, openSnackbar, participantsRevalidate],
  );

  const deletePerformer = React.useCallback(
    async (value) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: 'confirmDeleteTitle' }),
        content: intl.formatMessage({ id: 'confirmDelete' }, { name: value.name }),
      });
      if (confirm) {
        try {
          dispatch(setLoading(true));
          await dispatch(axiosThunk({ url: API_SERVER.indicationAttendPerson.delete(value?.id), method: 'delete' }));
          await participantsRevalidate();
          openSnackbar({ message: intl.formatMessage({ id: 'updateSuccess' }) });
        } catch (e) {
          openSnackbar({ message: intl.formatMessage({ id: 'updateFail' }), type: 'error' });
        } finally {
          dispatch(setLoading(false));
        }
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, openSnackbar, participantsRevalidate],
  );

  if (isValidating) {
    return <LoadingIcon />;
  }

  return (
    <>
      <SchemaForm
        hideSubmitButton
        submitLabel={<FormattedMessage id="status.finished" />}
        schema={{
          fields: ({ formProps: { intl }, valuesField }) => {
            const nameOptions = valuesField?.role
              ? participantOptions?.filter((ele) => ele?.roleName === valuesField?.role?.roleKey)
              : participantOptions;
            return {
              role: {
                type: 'auto-complete',
                label: intl.formatMessage({ id: 'role' }),
                placeholder: intl.formatMessage({ id: 'roleEnter' }),
                options: PARTICIPANTS,
                getOptionLabel: (role) => {
                  return intl.formatMessage({ id: role.label });
                },
                propsWrapper: { xs: 5 },
              },
              name: {
                type: 'auto-complete',
                label: intl.formatMessage({ id: 'employeeName' }),
                placeholder: intl.formatMessage({ id: 'employeeEnter' }),
                propsWrapper: { xs: 7 },
                options: nameOptions,
                groupBy: (option) => option.group,
                getOptionLabel: (opt) => {
                  return opt.name;
                },
                getOptionDisabled: (opt) => {
                  return !!participantsMapped?.find((ele) => ele?.userId === opt?.id);
                },
                onChange: (v) => {
                  if (!!v) {
                    addParticipant(v);
                  }
                },
              },
            };
          },
        }}
      />
      <Box marginTop={1}>
        <TableCustom
          dataSource={participantsMapped}
          columns={[
            {
              title: 'role',
              dataIndex: 'roleLabel',
              width: '30%',
            },
            {
              title: 'employeeName',
              dataIndex: 'employeeName',
            },
            {
              title: 'action',
              align: 'right',
              render: (record) => (
                <>
                  <IconButtonTitle title="delete" size="small" onClick={() => deletePerformer(record)}>
                    <Delete />
                  </IconButtonTitle>
                </>
              ),
            },
          ]}
        />
      </Box>
    </>
  );
};

export default ParticipantTab;
