import { HighchartsReact } from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useIntl } from 'react-intl';
import { Box } from '@mui/material';

interface Props {
  series: Highcharts.Options['series'];
  xAxisTitle: string;
  yAxisTitle: string;
}

const ReportPieChart = ({ series }: Props) => {
  const intl = useIntl();
  return (
    <Box sx={{ pageBreakAfter: 'always', '@page': { size: '1920px 1080px' } }}>
      <HighchartsReact
        highcharts={Highcharts}
        options={{
          exporting: {
            buttons: {
              contextButton: {
                menuItems: ['viewFullscreen', 'downloadPNG', 'downloadJPEG', 'downloadPDF', 'downloadSVG'],
              },
            },
          },
          credits: {
            enabled: false,
          },
          title: {
            text: '',
          },
          chart: {
            type: 'pie',
          },
          legend: {
            enabled: false,
          },
          lang: {
            contextButtonTitle: intl.formatMessage({ id: 'report.contextButtonTitle' }),
            _titleKey: 'customerRevenueReport',
          },
          accessibility: {
            point: {
              valueSuffix: '%',
            },
          },
          plotOptions: {
            pie: {
              allowPointSelect: true,
              cursor: 'pointer',
              dataLabels: {
                enabled: true,
                format: '{point.name}- {point.percentage:.1f} %',
              },
            },
          },
          tooltip: {
            valueDecimals: 2,
            valueSuffix: ' %',
          },
          series,
        }}
      />
    </Box>
  );
};

export default ReportPieChart;
