export const scrollInfoElement = (id: string) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  }
};

export interface SchemaFormDataPrescription {
  prescription: {
    id: number;
    prescriptionId: number;
    medicationId: number;
    medicationName: string;
    quantity: number;
    unit: string;
    dayNumber: number;
    type: string;
    unitPerDay: number;
    intendedRoute: string;
    usage: string;
    referenceId: number;
  }[];
}

export interface SchemaFormDataMedicineInUse {
  medicineInUse: {
    id: number;
    referenceId: number;
    type: string;
    medicationId: number;
    medicationName: string;
    medicationIngredient: string;
    medicationUnit: string;
    quantity: number;
    cancellationQuantity: number;
    cancellationReason: string;
    isFree: boolean;
    intendedRoute: string;
    note: string;
    indicationRequestId: number;
    isPaid: boolean;
    indicationRequestName: string;
    basePrice: number;
  }[];
}

export const duplicateTab = (url: string) => {
  const newTab = window.open('', '_blank');
  if (newTab) {
    newTab.location.href = url;
  }
};
