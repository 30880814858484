import DialogCustom from 'modules/common/component/DialogCustom';
import PrintDialogContent from './PrintDialogContent';
import { IndicationRequest } from 'modules/schema';

interface Props {
  open: boolean;
  onClose: () => void;
  selectedIndicationRequests: IndicationRequest[];
  dentalExamId?: number;
}
const IndicationPrintForm = ({ open, onClose, selectedIndicationRequests, dentalExamId }: Props) => {
  return (
    <DialogCustom
      open={open}
      onClose={onClose}
      title="indication.indicationList.lab.printForm.title"
      PaperProps={{
        style: { width: '1000px' },
      }}
      maxWidth="xl"
    >
      <PrintDialogContent
        onClose={onClose}
        selectedIndicationRequests={selectedIndicationRequests}
        dentalExamId={dentalExamId}
      />
    </DialogCustom>
  );
};

export default IndicationPrintForm;
