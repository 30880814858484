import { Stack, Typography } from '@mui/material';
import PatientDetailSession from 'modules/common/component/Patient/PatientDetailSession';
import { PatientSessionProps } from 'modules/common/component/Patient/type';
import { FormattedMessage } from 'react-intl';
import moment from 'moment';
import { BE_DATE_FORMAT, FE_DATE_FORMAT } from 'modules/common/constants';
import { useFetch } from 'modules/common/hook';
import { API_SERVER } from 'modules/common/api';
import { ROLE_TYPE } from 'modules/common/apiConstants';
import { TherapyHistory } from 'modules/schema/TherapyHistory';
import { useTablePagination } from 'modules/common/component/table/useTablePagination';
import Table from 'modules/common/component/table';
import { Column } from 'modules/common/component/table/type';
import { ClockHistory } from 'svg';
import { ROUTES } from 'modules/layout/router';
import useGeneralHook from 'modules/common/hook/useGeneralHook';

// const statusWidth = (record: Therapy, status: keyof typeof THERAPY_STATUS) => {
//   const amount = record?.therapySessionList?.filter((i) => i?.status === status)?.length || 0;
//   return `${(amount / (record?.therapySessionList?.length || 1)) * 100}%`;
// };

// const STATUSES = [THERAPY_STATUS.NEW, THERAPY_STATUS.PROCESSING, THERAPY_STATUS.FINISHED];
// const PatientTherapyHistoryStatus = () => {
//   return (
//     <Box sx={{ display: 'flex' }}>
//       {STATUSES?.map((status) => (
//         <TitleStatus key={status.value}>
//           <StatusCircle sx={{ backgroundColor: status.color }}></StatusCircle>
//           <StatusLabel>
//             <FormattedMessage id={status.label} />
//           </StatusLabel>
//         </TitleStatus>
//       ))}
//     </Box>
//   );
// };

const NoData = () => {
  return (
    <Stack height="500px" justifyContent="center" alignItems="center">
      <Stack sx={{ margin: '0 auto' }} justifyContent="center" alignItems="center" gap="20px">
        <ClockHistory height="80px" />
        <FormattedMessage id="patient.patientDetail.therapyHistory.title.empty" />
      </Stack>
    </Stack>
  );
};

const PatientTherapyHistory = ({ patient }: PatientSessionProps) => {
  const { appState } = useGeneralHook();
  const roles = appState.authen.user?.role || [];
  const { page, pageSize, paginationProps } = useTablePagination({ defaultPageSize: 5 });
  const { data: therapyHistoryPage } = useFetch(
    API_SERVER.therapy.searchTherapy({ patientId: patient.id!, page, pageSize }),
  );
  const COLUMNS = [
    {
      title: 'therapy.code',
      render: (record) => {
        if (roles.indexOf(ROLE_TYPE.RECEPTIONIST.value) > -1 || roles.indexOf(ROLE_TYPE.COORDINATOR.value) > -1) {
          return <Typography fontSize={'14px'}>{record?.code}</Typography>;
        } else {
          return (
            <Typography
              fontSize={'14px'}
              color={'#094EFF'}
              sx={{ cursor: 'pointer' }}
              onClick={() => window.open(ROUTES.therapyDetail.gen(record?.id!), '_blank')}
            >
              {record?.code}
            </Typography>
          );
        }
      },
    },
    {
      title: 'therapy.name',
      render: 'name',
    },
    {
      title: 'indicationDate',
      render: (record) => moment(record?.createdTime, BE_DATE_FORMAT).format(FE_DATE_FORMAT),
    },
    // {
    //   title: 'therapy.quantity',
    //   render: (record) => {
    //     return (
    //       <>
    //         {record?.numberSession && (
    //           <Typography fontSize={'12px'} fontWeight={700}>
    //             {record?.numberSessionCompleted}/{record?.numberSession}
    //           </Typography>
    //         )}
    //         <Stack sx={{ width: '100%', flexDirection: 'row', color: '#D9D9D9' }}>
    //           <LinearProgress
    //             color="success"
    //             variant="determinate"
    //             sx={{
    //               height: '8px',
    //               width: statusWidth(record, 'FINISHED'),
    //             }}
    //           />
    //           <LinearProgress
    //             color="warning"
    //             variant="determinate"
    //             sx={{
    //               height: '8px',
    //               width: statusWidth(record, 'PROCESSING'),
    //             }}
    //           />
    //           <LinearProgress
    //             color="inherit"
    //             variant="determinate"
    //             sx={{
    //               height: '8px',
    //               width: statusWidth(record, 'NEW'),
    //             }}
    //           />
    //         </Stack>
    //       </>
    //     );
    //   },
    // },
  ] as Column<TherapyHistory>[];

  return (
    <PatientDetailSession
      titleKey="patient.patientDetail.session.title.therapy"
      // rightAction={therapyHistoryPage?.content.length === 0 ? null : <PatientTherapyHistoryStatus />}
    >
      {therapyHistoryPage?.content.length === 0 ? (
        <NoData />
      ) : (
        <Table
          columns={COLUMNS}
          data={therapyHistoryPage?.content}
          paginationProps={{ ...paginationProps, rowsPerPageOptions: [5, 10] }}
          dataKey="id"
          totalElements={therapyHistoryPage?.pagination.totalElements}
        />
      )}
    </PatientDetailSession>
  );
};

export default PatientTherapyHistory;
