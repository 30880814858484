import { ISchemaFields, ISchemaForm, IUiFields } from '../../../../common/SchemaForm/utils';

export const fieldSchema: ISchemaFields = ({ valuesField, formProps }) => {
  const { intl, roomsData, departments, isInsurance } = formProps || {};
  const list = valuesField?.listData?.filter((v) => v.id !== valuesField?.id) || [];

  return {
    code: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'serviceCode' }),
      placeholder: intl.formatMessage({ id: 'serviceCodeEnter' }),
      propsWrapper: { xs: 6 },
      autoFocus: true,
      register: {
        required: isInsurance,
      },
      required: isInsurance,
    },
    name: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'indication.name' }),
      placeholder: intl.formatMessage({ id: 'indication.nameEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
        validate: (value) =>
          list.find((v) => v.code === value) ? intl.formatMessage({ id: 'indication.duplicateCode' }) : true,
      },
    },
    department: {
      type: 'select',
      label: intl.formatMessage({ id: 'departmentCode' }),
      placeholder: intl.formatMessage({ id: 'departmentCodeEnter' }),
      propsWrapper: { xs: 6 },
      rawOptions: true,
      options: departments,
      register: {
        required: true,
      },
      required: true,
    },
    internationalCode: {
      type: 'text-field',
      label: intl.formatMessage({ id: 'internationalCode' }),
      placeholder: intl.formatMessage({ id: 'internationalCodeEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: isInsurance,
      },
      required: isInsurance,
    },
    room: {
      type: 'auto-complete',
      label: intl.formatMessage({ id: 'indication.room' }),
      placeholder: intl.formatMessage({ id: 'indication.pickRoom' }),
      options: roomsData,
      propsWrapper: { xs: 6 },
      isOptionEqualToValue: (options, selected) => options.value === selected.value,
    },
    price: {
      type: 'text-field',
      inputType: 'number',
      label: intl.formatMessage({ id: 'indication.price' }),
      placeholder: intl.formatMessage({ id: 'indication.priceEnter' }),
      propsWrapper: { xs: 6 },
      register: {
        required: true,
      },
    },
  };
};

const ui: IUiFields = ({ formProps }) => {
  const { intl } = formProps || {};
  return [
    {
      id: 'info',
      title: intl.formatMessage({ id: 'info' }),
      fields: ['code', 'name', 'department', 'internationalCode', 'room'],
    },
    {
      id: 'price',
      title: intl.formatMessage({ id: 'price' }),
      fields: ['price'],
    },
  ];
};

export const procedureFormSchema: ISchemaForm = {
  fields: fieldSchema,
  ui: ui,
};
